/**
 * Order Component
 * Handles the payment of subscription, invoice or legal letter
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import { Modal } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import InfoMessage from 'components/shared/messages/InfoMessage';
import { InternalStripeContextProvider } from 'includes/contexts/InternalStripeContext';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { redirect } from 'includes/utils';
import { ORDER_TYPE } from '../../../includes/constants';
import { useCurrentRoleSlug } from '../../../includes/utils/hooks';
import PlanPayment from '../Payments/PlanPayment';
import CustomPlanNoPlanUpgradeMessage from 'components/CustomPlanNoPlanUpgradeMessage';

/**
 * Order Component
 */
const CreateOrder = props => {
    const [isLoadingPayButton, setIsLoadingPayButton] = useState(false);

    const [errorMessage, setErrorMessage] = useState();

    const { t } = useTranslation();
    const { contactId, invoiceId } = props.match.params;
    const organisationId = useOrganisationId();
    const currentRoleSlug = useCurrentRoleSlug();
    const siteSettings = useSelector(state => state.settings.siteSettings);
    const debtorPortalClientUrl = get(siteSettings, 'debtor_portal_client_url');

    useEffect(() => {
        if (shouldRedirectToNewDebtorPortal()) {
            setTimeout(() => {
                redirect(`${debtorPortalClientUrl}invoices/payment/${organisationId}/${contactId}/${invoiceId}/`, true);
            }, 2000);
        }
    }, []);

    const shouldRedirectToNewDebtorPortal = () =>
        debtorPortalClientUrl && props.action === ORDER_TYPE.INVOICE && !currentRoleSlug;

    const accountDetails = useSelector(state => state.account.accountDetails);

    const subscription = get(accountDetails, 'subscription');

    if (subscription?.plan?.is_custom) return <CustomPlanNoPlanUpgradeMessage />;

    return (
        <>
            <Modal
                className="my_profile_modal organisation-reauthorization-modal"
                closable={false}
                maskClosable={false}
                visible={shouldRedirectToNewDebtorPortal()}
                footer={null}
            >
                <InfoMessage showLoader={true} message={t('sharedMessages.invoicePayment.newDebtorPortal.message')} />
            </Modal>

            {shouldRedirectToNewDebtorPortal() ? null : (
                <InternalStripeContextProvider>
                    <PlanPayment
                        {...props}
                        setIsLoadingPayButton={setIsLoadingPayButton}
                        isLoadingPayButton={isLoadingPayButton}
                        setErrorMessage={setErrorMessage}
                        paymentGatewayError={errorMessage}
                    />
                </InternalStripeContextProvider>
            )}
        </>
    );
};

// Prop types
CreateOrder.propTypes = {
    action: PropTypes.string,
    match: PropTypes.object,
};

export default CreateOrder;

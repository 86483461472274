/**
 * Redux admin dashboard actions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

import * as actionTypes from '../../../constants/actionTypes';

/**
 * Get the admin dashboard data Action
 *
 * @returns {object}                        Get admin dashboard data action
 */
export const getAdminDashboardData = () => ({
    type: actionTypes.GET_ADMIN_DASHBOARD_DATA_REQUEST,
});

/**
 * Transactions component
 *
 * @since 2.8.0
 */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import Table from 'components/shared/ScrollableTable';
import { BANK_ACCOUNT_TRANSACTIONS_COLUMNS } from 'includes/constants/columns';
import { formatNumberAsCurrency } from 'includes/utils';
import TransactionTypeLabel from './components/TransactionTypeLabel';

const { Text } = Typography;

/**
 * Component responsible for rendering bank account transactions table
 *
 * @since 2.8.0
 */
export default function Transactions({
    fetchBankAccountTransactions,
    transactions: _transactions,
    isLoading,
    paginationData,
}) {
    /**
     * Memoized transactions with formatted UI to be displayed in the table
     */
    const transactions = useMemo(() => {
        if (_transactions.length === 0) return [];

        return _transactions.map(tr => ({
            ...tr,
            description: (
                <div className="flex flex-col">
                    <Text className="text-lg">{tr.original_description}</Text>
                    <Text type="secondary">{tr.simple_description}</Text>
                </div>
            ),
            type: <TransactionTypeLabel type={tr.base_type} />,
            amount: formatNumberAsCurrency(tr.amount, tr.currency),
        }));
    }, [_transactions]);

    return (
        <Table
            columns={BANK_ACCOUNT_TRANSACTIONS_COLUMNS}
            dataSource={transactions}
            dataValues={[]}
            getDataMethod={fetchBankAccountTransactions}
            isSearching={false}
            loading={isLoading}
            paginationData={paginationData}
            rowClassName="pointer"
            rowKey="id"
            size="middle"
        />
    );
}

Transactions.propTypes = {
    /**
     * Loading status
     */
    isLoading: PropTypes.bool,
    /**
     * Transactions list
     */
    transactions: PropTypes.arrayOf(PropTypes.any),
    /**
     * Pagination data
     */
    paginationData: PropTypes.any,
    /**
     * Method to pass to `Table` component as `getDataMethod` prop
     */
    fetchBankAccountTransactions: PropTypes.func,
};

/**
 * Campaign Slice
 *
 * @since 2.0.0
 */

import { CAMPAIGN, CAMPAIGNS, CAMPAIGN_NOTIFICATIONS } from '../constants/mappings/success';
import { get, isFunction } from 'lodash';
import { getPaginationData } from '../utils';
import { showApiErrors, showApiSuccess } from '../utils/api';
import CampaignApi from '../services/campaigns';
import createAppSlice from './base';
import { CAMPAIGN_CONTACT_TYPE_WITH_ANOTHER_CAMPAIGN_ERROR } from 'includes/constants/mappings/errors';

// set the initial state
const initialState = {
    campaign: {},
    campaignNotifications: [],
    campaignNotificationsPagination: {},
    campaigns: [],
    campaignsPagination: {},
    loadingCampaign: false,
    loadingCampaignAddEdit: false,
    loadingCampaignDelete: false,
    loadingCampaignNotificationAddEdit: false,
    loadingCampaignNotificationDelete: false,
    loadingCampaignNotifications: false,
    loadingCampaigns: false,
};

// define the slice
const CampaignSlice = createAppSlice('campaignSlice', initialState);
const { setData } = CampaignSlice.actions;

/**
 * Add a campaign
 *
 * @param {string} organisationId Organisation Id.
 * @param {object} payload Payload
 * @param {Function} callback Callback
 * @param {Function} handleConfirmCallback Handle confirm callback
 *
 * @since 2.0.0
 */
export const addCampaign = (organisationId, payload, callback, handleConfirmCallback) => async dispatch => {
    try {
        dispatch(setData('loadingCampaignAddEdit', true));
        const result = await CampaignApi.addCampaign(organisationId, payload);
        const campaign = get(result, CAMPAIGN);
        dispatch(setData('campaign', campaign));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback(campaign?.id, campaign?.campaign_type, campaign?.payment_plan_id);
        }
    } catch (errors) {
        const contactTypeWithAnotherCampaignError = get(errors, CAMPAIGN_CONTACT_TYPE_WITH_ANOTHER_CAMPAIGN_ERROR);
        if (handleConfirmCallback && isFunction(handleConfirmCallback) && contactTypeWithAnotherCampaignError) {
            handleConfirmCallback(contactTypeWithAnotherCampaignError);
        } else {
            dispatch(showApiErrors(errors, 'CAMPAIGN_FORM_NAME', 'CAMPAIGN'));
        }
    } finally {
        dispatch(setData('loadingCampaignAddEdit'));
    }
};

/**
 * Update the campaign notification
 *
 * @param {string} organisationId Organisation Id.
 * @param {string} campaignId Campaign id
 * @param {object} payload Payload
 * @param {Function} callback Callback
 *
 * @since 2.0.0
 */
export const addCampaignNotification = (organisationId, campaignId, payload, callback) => async dispatch => {
    try {
        dispatch(setData('loadingCampaignNotificationAddEdit', true));
        const result = await CampaignApi.addCampaignNotification(organisationId, campaignId, payload);
        dispatch(setData('campaignNotifications', get(result, CAMPAIGN_NOTIFICATIONS)));
        dispatch(setData('campaignNotificationsPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback(campaignId);
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'CAMPAIGN_NOTIFICATION_FORM_NAME', 'CAMPAIGN_NOTIFICATION'));
    } finally {
        dispatch(setData('loadingCampaignNotificationAddEdit'));
    }
};

/**
 * Delete campaign
 *
 * @param {string} organisationId Organisation Id.
 * @param {string} campaignId Campaign id
 * @param {Function} callback Callback
 *
 * @since 2.0.0
 */
export const deleteCampaign = (organisationId, campaignId, callback) => async dispatch => {
    try {
        dispatch(setData('loadingCampaignDelete', true));
        const result = await CampaignApi.deleteCampaign(organisationId, campaignId);
        dispatch(setData('campaigns', get(result, CAMPAIGNS)));
        dispatch(setData('campaignsPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'CAMPAIGNS'));
    } finally {
        dispatch(setData('loadingCampaignDelete'));
    }
};

/**
 * Delete campaign notification
 *
 * @param {string} organisationId Organisation Id.
 * @param {string} campaignId Campaign id
 * @param {string} campaignNotificationId Campaign notification id
 * @param {object} payload Payload
 * @param {Function} callback Callback
 *
 * @since 2.0.0
 */
export const deleteCampaignNotification = (organisationId, campaignId, campaignNotificationId) => async dispatch => {
    try {
        dispatch(setData('loadingCampaignNotificationDelete', true));
        const result = await CampaignApi.deleteCampaignNotification(organisationId, campaignId, campaignNotificationId);
        dispatch(setData('campaignNotifications', get(result, CAMPAIGN_NOTIFICATIONS)));
        dispatch(setData('campaignNotificationsPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'CAMPAIGN_NOTIFICATION'));
    } finally {
        dispatch(setData('loadingCampaignNotificationDelete'));
    }
};

/**
 * Get the campaign
 *
 * @param {string} organisationId Organisation Id
 * @param {string} campaignId Campaign id
 *
 * @since 2.0.0
 */
export const getCampaign = (organisationId, campaignId) => async dispatch => {
    try {
        dispatch(setData('loadingCampaign', true));
        const result = await CampaignApi.getCampaign(organisationId, campaignId);
        dispatch(setData('campaign', get(result, CAMPAIGN)));
    } finally {
        dispatch(setData('loadingCampaign'));
    }
};

/**
 * Get campaign notifications
 *
 * @param {string} organisationId Organisation Id
 * @param {string} campaignId Campaign Id
 * @param {object} searchParams Search Params. Default {}
 *
 * @since 2.0.0
 */
export const getCampaignNotifications = (organisationId, campaignId, searchParams = {}) => async dispatch => {
    try {
        dispatch(setData('loadingCampaignNotifications', true));
        const result = await CampaignApi.getCampaignNotifications(organisationId, campaignId, searchParams);
        dispatch(setData('campaignNotifications', get(result, CAMPAIGN_NOTIFICATIONS)));
        dispatch(setData('campaignNotificationsPagination', getPaginationData({ result })));
    } finally {
        dispatch(setData('loadingCampaignNotifications'));
    }
};

/**
 * Get campaigns
 *
 * @param {string} organisationId Organisation Id
 * @param {object} searchParams Search Params. Default {}
 *
 * @since 2.0.0
 */
export const getCampaigns = (organisationId, searchParams = {}) => async dispatch => {
    try {
        dispatch(setData('loadingCampaigns', true));
        const result = await CampaignApi.getCampaigns(organisationId, searchParams);
        dispatch(setData('campaigns', get(result, CAMPAIGNS)));
        dispatch(setData('campaignsPagination', getPaginationData({ result })));
    } finally {
        dispatch(setData('loadingCampaigns'));
    }
};

/**
 * Update the campaign
 *
 * @param {string} organisationId Organisation Id.
 * @param {string} campaignId Campaign id
 * @param {object} payload Payload
 * @param {Function} callback Callback
 * @param {Function} handleConfirmCallback Handle confirm callback
 *
 * @since 2.0.0
 */
export const updateCampaign = (
    organisationId,
    campaignId,
    payload,
    callback,
    handleConfirmCallback
) => async dispatch => {
    try {
        dispatch(setData('loadingCampaignAddEdit', true));
        const result = await CampaignApi.updateCampaign(organisationId, campaignId, payload);
        dispatch(setData('campaign', get(result, CAMPAIGN)));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback(campaignId, payload?.campaign_type, payload?.payment_plan_id);
        }
    } catch (errors) {
        const contactTypeWithAnotherCampaignError = get(errors, CAMPAIGN_CONTACT_TYPE_WITH_ANOTHER_CAMPAIGN_ERROR);
        if (handleConfirmCallback && isFunction(handleConfirmCallback) && contactTypeWithAnotherCampaignError) {
            handleConfirmCallback(contactTypeWithAnotherCampaignError);
        } else {
            dispatch(showApiErrors(errors, 'CAMPAIGN_FORM_NAME', 'CAMPAIGN'));
        }
    } finally {
        dispatch(setData('loadingCampaignAddEdit'));
    }
};

/**
 * Update the campaign notification
 *
 * @param {string} organisationId Organisation Id.
 * @param {string} campaignId Campaign id
 * @param {string} campaignNotificationId Campaign notification id
 * @param {object} payload Payload
 * @param {Function} callback Callback
 *
 * @since 2.0.0
 */
export const updateCampaignNotification = (
    organisationId,
    campaignId,
    campaignNotificationId,
    payload,
    callback
) => async dispatch => {
    try {
        dispatch(setData('loadingCampaignNotificationAddEdit', true));
        const result = await CampaignApi.updateCampaignNotification(
            organisationId,
            campaignId,
            campaignNotificationId,
            payload
        );
        dispatch(setData('campaignNotifications', get(result, CAMPAIGN_NOTIFICATIONS)));
        dispatch(setData('campaignNotificationsPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback(campaignId);
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'CAMPAIGN_NOTIFICATION_FORM_NAME', 'CAMPAIGN_NOTIFICATION'));
    } finally {
        dispatch(setData('loadingCampaignNotificationAddEdit'));
    }
};

// export the reducer
export default CampaignSlice.reducer;

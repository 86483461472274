/**
 * Taxes breadcrumb items
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import AccountsBreadCrumbItems from '../Accounts';
import OrganisationsBreadCrumbItems from '../Organisations';
import PropTypes from 'prop-types';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TaxesBreadCrumbItems = props => {
    const accountId = useAccountId();
    const isAdminOrManager = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    const { t } = useTranslation();

    const isAdd = props.action === 'add';
    const taxDetails = useSelector(state => state.taxes.tax);

    /**
     * Get the taxes details link
     *
     * @param {string} taxId Tax id
     *
     * @returns {string} Details link
     */
    const getDetailsLink = taxId =>
        taxId
            ? isAdminOrManager
                ? `/admin/accounts/organisation/taxes/tax/${accountId}/${organisationId}/${taxId}`
                : `/taxes/tax/${taxId}`
            : '#';

    /**
     * Get the taxes list link
     *
     * @returns {string} List link
     */
    const getListLink = () =>
        isAdminOrManager ? `/admin/accounts/organisation/taxes/${accountId}/${organisationId}` : `/taxes`;

    /**
     * Render the component
     */
    return (
        <>
            {isAdminOrManager ? (
                <>
                    <AccountsBreadCrumbItems showListBreadCrumb />
                    <OrganisationsBreadCrumbItems showListBreadCrumb />
                </>
            ) : null}
            {props.showListBreadCrumb ? (
                <Breadcrumb.Item>
                    <Link to={getListLink()}>{t('messages.breadCrumbs.taxes.list')}</Link>
                </Breadcrumb.Item>
            ) : null}
            {props.showDetailsBreadCrumb ? (
                <Breadcrumb.Item>
                    {props.showDetailsBreadCrumbAsLink ? (
                        <Link to={getDetailsLink(get(taxDetails, 'id'))}>
                            {get(taxDetails, 'name', t('messages.breadCrumbs.taxes.details'))}
                        </Link>
                    ) : (
                        get(taxDetails, 'name', t('messages.breadCrumbs.taxes.details'))
                    )}
                </Breadcrumb.Item>
            ) : null}
            {props.showAddEditBreadCrumb ? (
                <Breadcrumb.Item>{t(`messages.breadCrumbs.taxes.${isAdd ? 'add' : 'edit'}`)}</Breadcrumb.Item>
            ) : null}
        </>
    );
};

TaxesBreadCrumbItems.propTypes = {
    action: PropTypes.string,
    showAddEditBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumbAsLink: PropTypes.bool,
    showListBreadCrumb: PropTypes.bool,
};

export default TaxesBreadCrumbItems;

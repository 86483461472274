/**
 * Follow up Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useCheckEditPermission, useUpdateBusinessName } from 'includes/utils/hooks';
import NotesList from 'components/Notes/List';
import useOrganisationRequired from 'includes/hooks/useOrganisationRequired';

const FollowUps = props => {
    // check if user has updated the business name
    useUpdateBusinessName();

    // check if user has permission
    useCheckEditPermission();

    useOrganisationRequired();

    return (
        <div className="home-content-wrapper reminders-wrapper tab-wrapper">
            <header className="reminders-header">
                <h2 className="page-title" style={{ marginBottom: 20 }}>
                    {props.t(`titleAndMetas.${props.routeSlug}.title`)}
                </h2>
            </header>
            <section className="reminders-container full-wrapper box-wrapper white-box-wrapper">
                <NotesList followUps />
            </section>
        </div>
    );
};

FollowUps.propTypes = {
    routeSlug: PropTypes.string,
    t: PropTypes.func,
};

export default withTranslation()(FollowUps);

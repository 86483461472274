import usePaymentMethodSchemes from 'includes/hooks/usePaymentMethodSchemes';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

/**
 * Payment method status tag
 *
 * Render a tag indicating the status of payment method
 *
 * takes in `id` prop which is the status id
 *
 * @since 2.8.0
 */
export default function PaymentScheme({ id }) {
    const { data: paymentSchemes } = usePaymentMethodSchemes();

    const scheme = useMemo(() => {
        if (!id || !paymentSchemes || paymentSchemes.length === 0) return null;

        return paymentSchemes.find(sc => sc.id === id);
    }, [id, paymentSchemes]);

    return (
        <span className={`bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full `}>
            {scheme?.scheme}
        </span>
    );
}

PaymentScheme.propTypes = {
    id: PropTypes.string,
};

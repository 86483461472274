/**
 * Placeholder Modal component
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

// import required modules
import { React, connect, PropTypes, withTranslation } from '../../../includes/exports/react';
import PlaceholderForm from '../forms/PlaceholderForm';
import { addPlaceholder, updatePlaceholder } from '../../../includes/redux/actions/shared/placeholder';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';
import { ID } from '../../../includes/constants/keys/response';

/**
 * render the component
 */
const AddEditPlaceholder = props => {
    const isAdd = !get(props.initialValues, ID);

    /**
     * Handle the placeholder add/edit form submit
     *
     * @param {object} values Form values
     */
    const handleSubmit = values => {
        if (values.id && values.is_custom) {
            props.updatePlaceholder({ ...values, organisation_id: props.organisationId }, () =>
                props.setListView(true)
            );
        } else {
            props.addPlaceholder({ ...values, organisation_id: props.organisationId }, () => props.setListView(true));
        }
    };

    return (
        <div className="my-profile-detail settings-edit-form edit-default-settings">
            <div className="tab-arrow-link settings-tab-arrow">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <span
                            onClick={() => {
                                props.setListView(true);
                            }}
                            className="crud-link"
                        >
                            {props.t(`customerMessages.placeholders.breadCrumb.placeholders`)}
                        </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {props.t(`customerMessages.placeholders.breadCrumb.${isAdd ? 'add' : 'edit'}`)}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <PlaceholderForm {...props} onSubmit={handleSubmit} />
        </div>
    );
};

/**
 * Proptypes
 */
AddEditPlaceholder.propTypes = {
    addPlaceholder: PropTypes.func,
    deletePlaceholder: PropTypes.func,
    initialValues: PropTypes.object,
    isAddingPlaceholder: PropTypes.bool,
    isUpdatingPlaceholder: PropTypes.bool,
    organisationId: PropTypes.string,
    setListView: PropTypes.func,
    t: PropTypes.func,
    updatePlaceholder: PropTypes.func,
};

export default connect(
    state => ({
        isAddingPlaceholder: state.placeholder.isAddingPlaceholder,
        isPlaceholdersLoading: state.placeholder.isPlaceholdersLoading,
        isUpdatingPlaceholder: state.placeholder.isUpdatingPlaceholder,
    }),
    {
        addPlaceholder,
        updatePlaceholder,
    }
)(withTranslation()(AddEditPlaceholder));

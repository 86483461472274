/**
 * useChartOfAccountTypes
 * Fetch chart of account types and caches it
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import ChartOfAccountsApi from 'includes/services/chartOfAccounts';
import useCachedData from './useCachedData';
import { CACHE_CHART_OF_ACCOUNT_TYPES_KEY } from 'includes/constants';
import { CHART_OF_ACCOUNT_TYPES } from 'includes/constants/mappings/success';
import { get } from 'lodash';

/**
 * Fetches chart of account types if cached else call the API and caches the result
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useChartOfAccountTypes = () => {
    return useCachedData(CACHE_CHART_OF_ACCOUNT_TYPES_KEY, () => ChartOfAccountsApi.getChartOfAccountTypes(), {
        selector: res => get(res, CHART_OF_ACCOUNT_TYPES),
        dependsOnOrg: false,
    });
};

export default useChartOfAccountTypes;

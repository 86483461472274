/**
 * Communication log history details component
 * Shows more details report of the communicatio log history
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Breadcrumb, Card, Col, Form, List, Row } from 'antd';
import { find, get } from 'lodash';
import { useTranslation } from 'react-i18next';

import EmailCommunicationLogHistoryFields from './Email/EmailFields';
import IvrCommunicationLogHistoryFields from './IVR/IvrFields';
import Loader from '../Loader';
import SMSCommunicationLogHistoryFields from './SMS/SMSFields';
import { getDefaultImageClass, getImageFromData } from '../../../includes/utils';
import { useCheckEditPermission, useDataFromStore } from '../../../includes/utils/hooks';
import { getCommunicationLogStatuses } from '../../../includes/redux/actions/shared/communication';
import './styles.scss';
import useIsAdmin from 'includes/hooks/useIsAdmin';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useAccountId from 'includes/hooks/useAccountId';
import useCommunicationTypes from 'includes/hooks/useCommunicationTypes';

/**
 * Communication log history details component
 */
const CommunicationLogHistoryDetails = props => {
    const { t } = useTranslation();
    const contactAvatar45Key = 'CONTACT_AVATAR_45X45';
    const contactAvatarOriginalKey = 'CONTACT_AVATAR_ORIGINAL';
    const { breadcrumb, fetchLogDetails, pageTitle } = props;
    const organisationId = useOrganisationId();
    const isAdmin = useIsAdmin();
    const accountId = useAccountId();

    const communicationLogHistoryDetails = useSelector(state => state.communication.communicationLogHistoryDetails);
    const isCommunicationLogHistoryDetailsLoading = useSelector(
        state => state.communication.isCommunicationLogHistoryDetailsLoading
    );

    // check if has permission
    useCheckEditPermission();

    // get the communication log history details
    useEffect(() => {
        fetchLogDetails();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const { data: communicationTypes, isLoading: isCommunicationTypesLoading } = useCommunicationTypes();

    // get the communication log statuses
    const [communicationLogStatuses] = useDataFromStore({
        reducer: 'communication',
        dataKey: 'communicationLogStatuses',
        loadingKey: 'isCommunicationLogStatusesLoading',
        action: getCommunicationLogStatuses,
    });

    /**
     * Get the value from the communication log history details
     *
     * @param {string} key Key for which we need to retrieve the value
     *
     * @returns {string} key Key for which we need to retrieve the value
     */
    const getValueFromCommunicationLogHistoryDetails = key => {
        return get(communicationLogHistoryDetails, key);
    };

    /**
     * Get the notification type
     */
    const notificationType = find(communicationTypes, {
        id: getValueFromCommunicationLogHistoryDetails('type_id'),
    });

    /**
     * Get the form fields by notification type
     */
    const getFormFieldsByNotificationType = () => {
        const type = get(notificationType, 'slug', '');
        switch (type) {
            case 'email':
                return <EmailCommunicationLogHistoryFields renderField={renderField} />;
            case 'ivr':
                return <IvrCommunicationLogHistoryFields renderField={renderField} />;
            case 'sms':
            case 'whatsapp':
                return <SMSCommunicationLogHistoryFields renderField={renderField} />;
            default:
                return null;
        }
    };

    /**
     * Get localised text
     *
     * @param {string} path
     *
     * @returns {string} Localised string
     */
    const getLocaleText = path => t(`sharedMessages.communicationLogHistory.${path}`);

    /**
     * Render the contact
     */
    const renderContact = () => {
        const contactUrlSlug = getValueFromCommunicationLogHistoryDetails('contact.is_manager')
            ? 'contact-manager'
            : 'contact';
        return (
            <Row gutter={16} className="communication-log-history-field">
                <Col>
                    <label htmlFor="name">{getLocaleText(`details.fieldName.to`)}: </label>
                    <img
                        className={
                            getDefaultImageClass(
                                getValueFromCommunicationLogHistoryDetails('contact'),
                                contactAvatar45Key,
                                contactAvatarOriginalKey,
                                '45x45'
                            ) + ' logo logo-45 reminder-details-avatar'
                        }
                        src={getImageFromData(
                            getValueFromCommunicationLogHistoryDetails('contact'),
                            contactAvatar45Key,
                            contactAvatarOriginalKey
                        )}
                        alt={get(props, 'reminderHistoryDetails.contact.name')}
                    />
                    <Link
                        className="crud-link"
                        to={
                            isAdmin
                                ? `/admin/accounts/organisation/${contactUrlSlug}/${accountId}/${organisationId}/${getValueFromCommunicationLogHistoryDetails(
                                      'contact.id'
                                  )}`
                                : `/${contactUrlSlug}/${getValueFromCommunicationLogHistoryDetails('contact.id')}`
                        }
                    >
                        {`${getValueFromCommunicationLogHistoryDetails('contact.name')} `}
                    </Link>
                    <b>{`<${getValueFromCommunicationLogHistoryDetails('to')}>`}</b>
                </Col>
            </Row>
        );
    };

    /**
     * Render content as link
     *
     * @param {string} value Value that needs to be shown
     *
     * @returns content
     */
    const renderLink = value => {
        return (
            <a href={value} rel="noopener noreferrer" target="_blank">
                {value}
            </a>
        );
    };

    /**
     * Render content as list
     *
     * @param {Array} values Value that needs to be shown as list
     * @param {Array} relatedFieldValues Related fields values to be shown when showing the current value
     * @param {boolean} isLink Whether the content is link or not
     * @param {boolean} basicList Whether we need to render basic list or advanced list
     *
     * @returns content
     */
    const renderList = (values, relatedFieldValues, isLink = false, basicList = false) => {
        let listData = [];
        if (values) {
            listData = values.map((value, index) => {
                let relatedFieldValue = get(relatedFieldValues, index);
                return {
                    value: value,
                    relatedFieldValue: relatedFieldValue,
                };
            });
        }
        return basicList ? (
            <List
                className="basic-list"
                size="small"
                bordered
                dataSource={listData}
                renderItem={item => <List.Item>{isLink ? renderLink(item.value) : item.value}</List.Item>}
            />
        ) : (
            <List
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg: 4,
                    xl: 6,
                    xxl: 3,
                }}
                dataSource={listData}
                renderItem={item => (
                    <List.Item key={item.value}>
                        <Card title={item.value}>{item.relatedFieldValue}</Card>
                    </List.Item>
                )}
            />
        );
    };

    /**
     * Render the field
     *
     * @param {string} key Key for which the field is to be shown
     * @param {boolean} isHtml Whether the content is HTML or plain text. Default false
     * @param {boolean} onSameLine Whether we need to show content in same line or different lines. Default true
     * @param {boolean} showIfEmpty Whether we need to show the field even if content is empty. Default false
     * @param {boolean} isList Whether the content should be rendered as list. Default false
     * @param {string} relatedField Related field key. Default ''
     * @param {boolean} isLink Whether the content is link or not. Default false
     * @param {boolean} basicList Whether we need to render basic list or advanced list. Default false
     * @param {string} customLabelKey Custom label key. Default ''
     *
     * @returns Content
     */
    const renderField = ({
        key,
        isHtml = false,
        onSameLine = true,
        showIfEmpty = false,
        isList = false,
        relatedField = '',
        isLink = false,
        basicList = false,
        customLabelKey = '',
    }) => {
        const value = getValueFromCommunicationLogHistoryDetails(key);
        const relatedFieldValue = relatedField ? getValueFromCommunicationLogHistoryDetails(relatedField) : '';
        const showField = value ? true : !!showIfEmpty;
        return (
            showField && (
                <Row gutter={16} className="communication-log-history-field">
                    <Col>
                        <label htmlFor="name">
                            {getLocaleText(`details.fieldName.${customLabelKey ? customLabelKey : key}`)}:{' '}
                        </label>
                        {onSameLine ? (
                            <b>{isLink ? renderLink(value) : value}</b>
                        ) : isList ? (
                            renderList(value, relatedFieldValue, isLink, basicList)
                        ) : (
                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-form-item-control-wrapper">
                                    <div className="ant-form-item-control">
                                        <div className="ant-form-item-children">
                                            {isLink ? renderLink(value) : isHtml ? ReactHtmlParser(value) : value}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            )
        );
    };

    return (
        <div className="home-content-wrapper edit-campaign-wrapper">
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    {isAdmin && (
                        <Breadcrumb.Item>
                            <Link to={`/admin/accounts/organisations/${accountId}`}>
                                {getLocaleText('breadcrumb.organisations')}
                            </Link>
                        </Breadcrumb.Item>
                    )}
                    {breadcrumb}
                </Breadcrumb>
            </div>
            <h2 className="page-title">{pageTitle}</h2>
            <div className="white-box-wrapper box-wrapper mid-wrapper">
                <div className="edit-reminder-wrapper">
                    <Form layout="vertical" className="">
                        {isCommunicationLogHistoryDetailsLoading || isCommunicationTypesLoading ? (
                            <Loader />
                        ) : notificationType ? (
                            <>
                                <section>
                                    {renderField({ key: 'created_date', onSameLine: true })}
                                    <Row gutter={16} className="communication-log-history-field">
                                        <Col>
                                            <label htmlFor="notificationType">Notification Type: </label>
                                            <div className="tag-linker table-button-wrapper">
                                                <button className={get(notificationType, 'slug')}>
                                                    {get(notificationType, 'type')}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                    {renderField({ key: 'from', onSameLine: true })}
                                    {renderContact()}
                                    {renderField({ key: 'user.name', customLabelKey: 'sent_by' })}
                                    <Row gutter={16} className="communication-log-history-field">
                                        <Col>
                                            <>
                                                <label htmlFor="status">Status: </label>
                                                <b>
                                                    {get(
                                                        find(communicationLogStatuses, {
                                                            id: getValueFromCommunicationLogHistoryDetails('status_id'),
                                                        }),
                                                        'status'
                                                    )}
                                                </b>
                                            </>
                                        </Col>
                                    </Row>
                                    {renderField({ key: 'status_response' })}
                                </section>
                                {getFormFieldsByNotificationType()}
                            </>
                        ) : (
                            <section>{getLocaleText('noDataToShow')}</section>
                        )}
                    </Form>
                </div>
            </div>
        </div>
    );
};

CommunicationLogHistoryDetails.propTypes = {
    breadcrumb: PropTypes.arrayOf(PropTypes.node),
    fetchLogDetails: PropTypes.func.isRequired,
    onDemandCampaign: PropTypes.bool,
    pageTitle: PropTypes.string,
    showBreadCrumb: PropTypes.bool,
    t: PropTypes.func,
};

export default CommunicationLogHistoryDetails;

/**
 * Admin accounts reducer
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';
import { get } from 'lodash';
import * as successMappings from '../../../../constants/mappings/success';
import { createPermissionDict, getLocalStorageValue, getPaginationData, replaceValueInArray } from '../../../../utils';
import * as constants from '../../../../constants';

const INITIAL_STATE = {
    accountDetails: {},
    accountPermissions: {},
    accounts: [],
    coupons: [],
    currentAccountId: getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY),
    isAccountDetailsLoading: false,
    isAccountDetailsUpdating: false,
    isAddingCouponToAccount: false,
    isAdmin: false,
    isCouponsLoading: false,
    isDetailsLoading: false,
    isLoading: true,
    isRemovingCouponFromAccount: false,
    isSendingTestEmail: false,
    isTryingDemoCompany: false,
    paginationAccounts: {},
    paginationCouponAccounts: {},
    paginationCoupons: {},
    userAccountDetails: {},
    userAccountId: '',
};

export default function accountsReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case actionTypes.ADD_COUPON_TO_ACCOUNT_FAILURE:
            return {
                ...state,
                isAddingCouponToAccount: false,
            };
        case actionTypes.ADD_COUPON_TO_ACCOUNT_REQUEST:
            return {
                ...state,
                isAddingCouponToAccount: true,
            };
        case actionTypes.ADD_COUPON_TO_ACCOUNT_SUCCESS:
            return {
                ...state,
                isAddingCouponToAccount: false,
            };
        case actionTypes.GET_ACCOUNT_COUPONS_FAILURE:
            return {
                ...state,
                isCouponsLoading: false,
                coupons: [],
            };
        case actionTypes.GET_ACCOUNT_COUPONS_REQUEST:
            return {
                ...state,
                isCouponsLoading: false,
                coupons: [],
            };
        case actionTypes.GET_ACCOUNT_COUPONS_SUCCESS:
            return {
                ...state,
                isCouponsLoading: true,
                coupons: get(action.result, successMappings.GET_ACCOUNT_COUPONS),
            };
        case actionTypes.GET_ACCOUNT_DETAILS_FAILURE:
            return {
                ...state,
                isAccountDetailsLoading: false,
            };
        case actionTypes.GET_ACCOUNT_DETAILS_FROM_INITIAL_DATA_SUCCESS: {
            return {
                ...state,
                accountDetails: get(action, successMappings.GET_ACCOUNT_DETAILS_FROM_INITIAL_DATA),
                accountPermissions: createPermissionDict(
                    get(action, successMappings.GET_ACCOUNT_PERMISSIONS_FROM_INITIAL_DATA)
                ),
            };
        }
        case actionTypes.GET_ACCOUNT_DETAILS_REQUEST:
            return {
                ...state,
                isAccountDetailsLoading: true,
            };
        case actionTypes.GET_ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                isAccountDetailsLoading: false,
                accountDetails: get(action.result, successMappings.GET_ACCOUNT_DETAILS),
                accountPermissions: createPermissionDict(get(action.result, successMappings.GET_ACCOUNT_PERMISSIONS)),
            };
        case actionTypes.SET_ACCOUNT_PERMISSIONS:
            return {
                ...state,
                accountPermissions: action.permissions,
            };

        case actionTypes.GET_ACCOUNTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                paginationAccounts: {},
            };
        case actionTypes.GET_ACCOUNTS_REQUEST:
            return {
                ...state,
                isLoading: true,
                paginationAccounts: {},
            };
        case actionTypes.GET_ACCOUNTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                accounts: get(action.result, successMappings.GET_ACCOUNTS),
                paginationAccounts: getPaginationData(action),
            };
        case actionTypes.GET_ACCOUNT_TYPES_FAILURE:
            return {
                ...state,
                isTypesLoading: false,
            };
        case actionTypes.GET_ACCOUNT_TYPES_REQUEST:
            return {
                ...state,
                isTypesLoading: true,
            };
        case actionTypes.GET_ACCOUNT_TYPES_SUCCESS:
            return {
                ...state,
                isTypesLoading: false,
                accountTypes: action.accountTypes,
            };
        case actionTypes.GET_COUPON_ACCOUNTS_FAILURE:
            return {
                ...state,
                isCouponAccountsLoading: false,
                paginationCouponAccounts: {},
            };
        case actionTypes.GET_COUPON_ACCOUNTS_REQUEST:
            return {
                ...state,
                isCouponAccountsLoading: true,
                paginationCouponAccounts: {},
            };
        case actionTypes.GET_COUPON_ACCOUNTS_SUCCESS:
            return {
                ...state,
                isCouponAccountsLoading: false,
                couponAccounts: get(action.result, successMappings.GET_COUPONS),
                paginationCouponAccounts: getPaginationData(action),
            };
        case actionTypes.GET_COUPONS_FAILURE:
            return {
                ...state,
                isCouponsLoading: false,
                paginationCoupons: {},
            };
        case actionTypes.GET_COUPONS_REQUEST:
            return {
                ...state,
                isCouponsLoading: true,
                paginationCoupons: {},
            };
        case actionTypes.GET_COUPONS_SUCCESS:
            return {
                ...state,
                isCouponsLoading: false,
                coupons: get(action.result, successMappings.GET_COUPONS),
                paginationCoupons: getPaginationData(action),
            };
        case actionTypes.GET_USER_ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                isAccountDetailsLoading: false,
                userAccountDetails: get(action.result, successMappings.GET_ACCOUNT_DETAILS),
            };
        case actionTypes.GET_USER_PROFILE_DETAILS_FROM_INITIAL_DATA_SUCCESS:
            return {
                ...state,
                isAdmin:
                    get(action, ['roles', INITIAL_STATE.currentAccountId, 'slug'], '') === 'admin' ||
                    get(action, ['roles', INITIAL_STATE.currentAccountId, 'slug'], '') === 'admin-manager',
            };
        case actionTypes.REMOVE_COUPON_FROM_ACCOUNT_FAILURE:
            return {
                ...state,
                isRemovingCouponFromAccount: false,
            };
        case actionTypes.REMOVE_COUPON_FROM_ACCOUNT_REQUEST:
            return {
                ...state,
                isRemovingCouponFromAccount: true,
            };
        case actionTypes.REMOVE_COUPON_FROM_ACCOUNT_SUCCESS:
            return {
                ...state,
                couponAccounts: get(action.result, successMappings.GET_COUPONS),
                paginationCouponAccounts: getPaginationData(action),
                isRemovingCouponFromAccount: false,
            };
        case actionTypes.RESET_USER_ACCOUNT_DETAILS_REQUEST: {
            return {
                ...state,
                userAccountDetails: {},
            };
        }
        case actionTypes.SEND_TEST_EMAIL_FAILURE: {
            return {
                ...state,
                isSendingTestEmail: false,
            };
        }
        case actionTypes.SEND_TEST_EMAIL_REQUEST: {
            return {
                ...state,
                isSendingTestEmail: true,
            };
        }
        case actionTypes.SEND_TEST_EMAIL_SUCCESS: {
            return {
                ...state,
                isSendingTestEmail: false,
            };
        }
        case actionTypes.SET_CURRENT_ACCOUNT_ID:
            return {
                ...state,
                currentAccountId: action.accountId,
            };
        case actionTypes.SET_USER_ACCOUNT_ID:
            return {
                ...state,
                userAccountId: action.accountId,
            };
        case actionTypes.TRY_DEMO_COMPANY_FAILURE:
            return {
                ...state,
                isTryingDemoCompany: false,
            };
        case actionTypes.TRY_DEMO_COMPANY_REQUEST:
            return {
                ...state,
                isTryingDemoCompany: true,
            };
        case actionTypes.TRY_DEMO_COMPANY_SUCCESS:
            return {
                ...state,
                isAccountDetailsLoading: false,
                accountDetails: get(action.result, successMappings.GET_ACCOUNT_DETAILS),
                isTryingDemoCompany: false,
            };
        case actionTypes.UPDATE_ACCOUNT_DETAILS_FAILURE:
            return {
                ...state,
                isAccountDetailsUpdating: false,
            };
        case actionTypes.UPDATE_ACCOUNT_DETAILS_REQUEST:
            return {
                ...state,
                isAccountDetailsUpdating: true,
            };
        case actionTypes.UPDATE_ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                isAccountDetailsUpdating: false,
                accountDetails: get(action.result, successMappings.UPDATE_ACCOUNT_DETAILS),
            };
        case actionTypes.UPDATE_USER_ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                isAccountDetailsUpdating: false,
                userAccountDetails: get(action.result, successMappings.UPDATE_ACCOUNT_DETAILS),
            };
        case actionTypes.USER_LOGIN_SUCCESS:
            return {
                ...state,
                accountDetails: get(
                    action,
                    replaceValueInArray(
                        successMappings.GET_USER_ACCOUNT_DETAILS,
                        constants.ACCOUNT_ID_PLACEHOLDER,
                        Object.keys(action.result.data.data.user_details.accounts)[0]
                    )
                ),
                accountPermissions: createPermissionDict(
                    get(action, successMappings.GET_ACCOUNT_PERMISSIONS_FROM_INITIAL_DATA)
                ),
                isAdmin: !!action.isAdmin,
            };
        case actionTypes.USER_REGISTRATION_SUCCESS:
            return {
                ...state,
                accountDetails: get(
                    action,
                    replaceValueInArray(
                        successMappings.GET_USER_ACCOUNT_DETAILS,
                        constants.ACCOUNT_ID_PLACEHOLDER,
                        Object.keys(action.result.data.data.user_details.accounts)[0]
                    )
                ),
                isAdmin:
                    get(action, ['roles', INITIAL_STATE.currentAccountId, 'slug'], '') === 'admin' ||
                    get(action, ['roles', INITIAL_STATE.currentAccountId, 'slug'], '') === 'admin-manager',
            };
        default:
            return {
                ...state,
            };
    }
}

/**
 * Separator Block
 *
 * This component renders a block that separates list of permissions into groups
 * the component has contols to-
 * 1. show/hide permissions belonging to the group
 * 2. move all permission of the group to the other column
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ChevronUpIcon } from 'assets/images/chevron-up.svg';
import { ReactComponent as ChevronDoubleRightIcon } from 'assets/images/chevron-double-right.svg';

const Separator = ({ columnId, groupId, handleClick, isActive, label, toggleAll }) => {
    const isAllowed = columnId === 'allowed';
    return (
        <div className="grid grid-cols-3 grid-flow-col rounded-md shadow-sm p-4 mt-6 transition duration-300 w-full bg-gray-100 mx-auto">
            <div className=" flex flex-col gap-2 col-span-2">
                <h3 className="text-md text-left font-thin">{label}</h3>
            </div>
            <div className="flex justify-end gap-2">
                <button
                    type="button"
                    className={`text-center transition duration-300 rounded-lg`}
                    onClick={() => toggleAll(columnId, groupId)}
                >
                    <ChevronDoubleRightIcon
                        width={15}
                        title={isAllowed ? 'Restrict all' : 'Allow all'}
                        className={`text-gray-700 hover:text-gray-500 ${isAllowed ? 'transform rotate-180' : ''}`}
                    />
                </button>
                <button
                    type="button"
                    className={`text-center transition duration-300 rounded-lg`}
                    onClick={() => handleClick(columnId, groupId)}
                >
                    <ChevronUpIcon
                        width={20}
                        className={`transition duration-300 transform text-gray-700 hover:text-gray-500 ${
                            isActive ? '' : 'rotate-180'
                        }`}
                    />
                </button>
            </div>
        </div>
    );
};

Separator.propTypes = {
    columnId: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    label: PropTypes.string,
    toggleAll: PropTypes.func.isRequired,
};

export default Separator;

/**
 * useCurrencies
 * Get the currencies
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { CACHE_CURRENCIES_KEY } from 'includes/constants';
import { CURRENCIES } from 'includes/constants/mappings/success';
import { get } from 'lodash';
import CurrenciesApi from 'includes/services/currencies';
import useCachedData from './useCachedData';

/**
 * Get the currencies
 *
 * @param {boolean} disableApiCall Whether to disable api call or not. Default false
 *
 * @returns object Currencies
 */
const useCurrencies = (disableApiCall = false) => {
    return useCachedData(CACHE_CURRENCIES_KEY, () => CurrenciesApi.getCurrencies(), {
        selector: res => get(res, CURRENCIES),
        disableApiCall: disableApiCall,
        dependsOnOrg: false,
    });
};

export default useCurrencies;

/**
 * Home Component
 * Handles the home page view and functions related to loading home page
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import the required modules
import { React } from '../../../includes/exports/react';
import './styles.scss';
import RedirectingPageLoaderSection from 'components/RedirectingPageLoaderSection';
import useRedirectToAuthPortalRoute from 'includes/hooks/useRedirectToAuthPortalRoute';

/**
 * Home Component
 */
const Home = () => {
    useRedirectToAuthPortalRoute();
    /**
     * Render the Home page
     *
     * @returns    {string}    Home view
     */
    return (
        <section className="home-wrapper">
            <RedirectingPageLoaderSection />
        </section>
    );
};

export default Home;

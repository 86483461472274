/**
 * Loader Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import React from 'react';
import { Spin } from 'antd';
import './styles.scss';

const Loader = props => <Spin className="payce-loader" {...props} />;

export default Loader;

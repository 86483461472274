/**
 * Send Test Email form Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import { React } from '../../../../includes/exports/react';
import { Form, Row, Col } from 'antd';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import * as formValidations from '../../../../includes/utils/form';
import Button from '../../Button';
import * as constants from '../../../../includes/constants';
import FormField from '../../FormField';

const SendTestEmailForm = props => {
    return (
        <Form layout="vertical" className="" onSubmit={props.handleSubmit}>
            <Row gutter={16}>
                <Col span={12}>
                    <Field
                        type="email"
                        name="email"
                        label="Email ID"
                        className="form-control"
                        component={FormField}
                        validate={[formValidations.required, formValidations.email]}
                    />
                </Col>
            </Row>
            <Button htmlType="submit" big loading={props.loading} submittingButtonLabel="Sending...">
                Send
            </Button>
            {props.error ? (
                <div className="has-error default-form-app-error">
                    <div className="ant-form-explain">{props.error}</div>
                </div>
            ) : (
                ''
            )}
        </Form>
    );
};

/**
 * Proptypes
 */
SendTestEmailForm.propTypes = {
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    loading: PropTypes.bool,
    t: PropTypes.func,
};

export default reduxForm({ form: constants.SEND_TEST_EMAIL_FORM })(SendTestEmailForm);

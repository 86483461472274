/**
 * Organisation Details Component
 * Handles the invoice details page view and functions related to loading dashboard
 *
 * @version 1.0
 */

//import required modules
import { React, connect, useEffect, Link } from '../../../includes/exports/react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { Icon, Breadcrumb, Tabs } from 'antd';

import { getAccountingSoftwares } from '../../../includes/redux/actions/shared/accountingSoftware';
import Loader from '../../shared/Loader';
import { getOrganisationDetails } from '../../../includes/redux/actions/customer/organisation';
import Button from '../../shared/Button';
import * as responseKeys from '../../../includes/constants/keys/response';
import { useDataFromStore } from '../../../includes/utils/hooks';
import { ACCOUNTING_SOFTWARE_DEEP_LINKS } from '../../../includes/constants/deepLinks';
import { multiStringReplace, redirect } from '../../../includes/utils';
import { MANUAL } from '../../../includes/constants';
import PermissionGuard from 'components/PermissionGuard';
import { AUDIT_LOG, ORGANISATION } from 'includes/constants/permissions';
import AuditLogList from 'pages/AuditLogs/List';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';

const { TabPane } = Tabs;

/**
 * Render the organisation details
 */
const OrganisationDetails = props => {
    const { accountId, organisationId } = props.match.params;
    const [accountingSoftwares, isAccountingSoftwareLoading] = useDataFromStore({
        reducer: 'accountingSoftware',
        dataKey: 'accountingSoftwares',
        loadingKey: 'isLoading',
        action: getAccountingSoftwares,
    });

    const { getOrganisationDetails, organisationDetails, isAdmin } = props;

    // effect to fetch organisation details
    useEffect(() => {
        if (!isAdmin) {
            getOrganisationDetails(organisationId);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the organisation logo
     * Checks if the organisation has a logo of the specified dimension, if not check for original organisation logo
     * if not get the current default specified
     */
    const getOrganisationLogo = () => {
        return (
            get(props.organisationDetails, responseKeys.ORGANISATION_LOGO_254x254) ||
            get(props.organisationDetails, responseKeys.ORGANISATION_LOGO_ORIGINAL) ||
            require('../../../assets/images/organisation_avatar.svg')
        );
    };

    /**
     * Check if the default image is loaded
     *
     *
     * @returns  boolean true|false
     */
    const isDefaultImageLoaded = () => {
        return !get(
            props.organisationDetails,
            responseKeys.ORGANISATION_LOGO_254x254,
            get(props.organisationDetails, responseKeys.ORGANISATION_LOGO_ORIGINAL)
        );
    };

    const getLocaleText = path => props.t(`customerMessages.organisation.details.${path}`);

    const accountingSoftwareSlug = get(accountingSoftwares, [props.organisationDetails.accounting_software_id, 'slug']);

    /**
     * Render the component
     */
    return (
        <section>
            <div className="home-content-wrapper synced-name details-wrapper">
                {isAdmin && (
                    <div className="tab-arrow-link breadcrumb-spec">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to={`/admin/accounts/organisations/${accountId}`}>Organisations</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Organisation Details</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                )}
                <h2 className="page-title">Organisation</h2>
                <div
                    className={
                        isAdmin
                            ? 'white-box-wrapper full-wrapper breadcrumb-spec'
                            : 'white-box-wrapper mid-wrapper breadcrumb-spec'
                    }
                >
                    {props.isDetailsLoading || isAccountingSoftwareLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <section className="details-list left-align">
                                <div
                                    className={
                                        'details-profile-pic-wrapper' +
                                        (isDefaultImageLoaded() ? ' is-default-image-loaded' : '')
                                    }
                                >
                                    <img
                                        src={getOrganisationLogo()}
                                        className={
                                            !isEmpty(get(organisationDetails, 'logo'))
                                                ? 'org-avatar'
                                                : 'org-avatar logo-placeholder'
                                        }
                                        alt="profile-pic"
                                    />
                                </div>
                                {get(organisationDetails, 'external_short_code') &&
                                    get(ACCOUNTING_SOFTWARE_DEEP_LINKS, [
                                        props.accountingSoftwareSlug,
                                        'organisation',
                                    ]) && (
                                        <div className="center-align">
                                            <a
                                                className="accounting-software-external-link"
                                                href={multiStringReplace(
                                                    get(ACCOUNTING_SOFTWARE_DEEP_LINKS, [
                                                        props.accountingSoftwareSlug,
                                                        'organisation',
                                                    ]),
                                                    { short_code: get(organisationDetails, 'external_short_code') }
                                                )}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                View in {props.accountingSoftwareName}
                                            </a>
                                        </div>
                                    )}
                                <div className="center-align">
                                    <PermissionGuard requiredPermission={ORGANISATION.EDIT}>
                                        <Button
                                            className="green-bg-button"
                                            filled
                                            big
                                            onClick={() =>
                                                redirect(
                                                    isAdmin
                                                        ? `/admin/accounts/edit-organisation/${accountId}/${props.match.params.organisationId}`
                                                        : `/edit-organisation/${props.match.params.organisationId}`
                                                )
                                            }
                                        >
                                            Edit
                                        </Button>
                                    </PermissionGuard>
                                </div>
                            </section>
                            <section className="details-list right-align">
                                <div className="full-wrapper">
                                    <h3 className="profile-name">
                                        {get(organisationDetails, 'name')}
                                        {get(organisationDetails, 'is_default') && (
                                            <span title="Default Organisation. This organisation will be loaded by default, when you login">
                                                <Icon
                                                    type="check"
                                                    style={{
                                                        color: 'green',
                                                        fontSize: '30px',
                                                    }}
                                                />
                                            </span>
                                        )}
                                    </h3>
                                    <div className="sub-detail no-text-indent">
                                        {get(organisationDetails, 'line_of_business')}
                                    </div>
                                </div>
                                <section className="detail-summary">
                                    <ul className="upper-right-detail">
                                        <li>
                                            <header>{getLocaleText('header.legalName')}</header>
                                            <div>{get(organisationDetails, 'name')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleText('header.contact')}</header>
                                            <div>{get(organisationDetails, 'mobile_number')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleText('header.email')}</header>
                                            <div>{get(organisationDetails, 'email')}</div>
                                        </li>
                                        {get(organisationDetails, 'is_manual') && (
                                            <li>
                                                <header>{getLocaleText('header.external_id')}</header>
                                                <div>{get(organisationDetails, 'external_id')}</div>
                                            </li>
                                        )}
                                        <li>
                                            <header>{getLocaleText('header.financialYearEnd')}</header>
                                            <div>{get(organisationDetails, 'financial_year_end')}</div>
                                        </li>
                                        <li className="address">
                                            <header>{getLocaleText('header.postalAddress')}</header>
                                            <div>{get(organisationDetails, 'postal_address')}</div>
                                        </li>
                                        <li className="address">
                                            <header>{getLocaleText('header.physicalAddress')}</header>
                                            <div>{get(organisationDetails, 'physical_address')}</div>
                                        </li>
                                        {accountingSoftwareSlug !== MANUAL && (
                                            <li>
                                                <header>{getLocaleText('header.accountingSoftware')}</header>
                                                <div>
                                                    {get(accountingSoftwares, [
                                                        props.organisationDetails.accounting_software_id,
                                                        'name',
                                                    ])}
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </section>
                            </section>
                        </>
                    )}
                </div>

                <div className="full-wrapper box-wrapper white-box-wrapper tab-wrapper bottom-section">
                    <Tabs>
                        <TabPane key="audit-logs" tab="Audit Logs">
                            <PermissionGuardLayout requiredPermission={AUDIT_LOG.LIST} layout="section">
                                <AuditLogList customDataValues={{ view_details: 1 }} showTitle={false} />
                            </PermissionGuardLayout>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </section>
    );
};

/**
 * Proptypes
 */
OrganisationDetails.propTypes = {
    accountingSoftwareName: PropTypes.string,
    accountingSoftwareSlug: PropTypes.string,
    defaultOrganisationLogo: PropTypes.string,
    getAccountingSoftwares: PropTypes.func,
    getOrganisationDetails: PropTypes.func,
    isDetailsLoading: PropTypes.bool,
    match: PropTypes.object,
    organisationDetails: PropTypes.object,
    t: PropTypes.func,
    isAdmin: PropTypes.bool,
};

// connect to store
export default connect(
    state => ({
        defaultOrganisationLogo: get(state.settings.siteSettings, responseKeys.DEFAULT_ORGANISATION_LOGO_45X45),
        isDetailsLoading: state.organisation.isDetailsLoading,
        organisationDetails: state.organisation.organisationDetails,
        accountingSoftwareName: get(state.organisation, ['organisationDetails', 'accounting_software_data', 'name']),
        accountingSoftwareSlug: get(state.organisation, ['organisationDetails', 'accounting_software_data', 'slug']),
        roles: state.user.roles,
        isAdmin: state.account.isAdmin,
    }),
    {
        getOrganisationDetails,
    }
)(withTranslation()(OrganisationDetails));

/**
 * Default Layout
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import the required modules
import { React } from '../includes/exports/react';
import { PropTypes } from 'prop-types';
import { Layout } from 'antd';
import FeatureAvailableOnlyForManualOrganisation from 'components/FeatureAvailableOnlyForManualOrganisation';

const { Content } = Layout;

export const ChildrenContentLayout = ({ children, ...props }) => {
    const { featureAvailableForManualOrganisationsOnly } = props;
    return (
        <Content>
            <FeatureAvailableOnlyForManualOrganisation
                featureAvailableForManualOrganisationsOnly={featureAvailableForManualOrganisationsOnly}
            >
                {children}
            </FeatureAvailableOnlyForManualOrganisation>
        </Content>
    );
};

ChildrenContentLayout.propTypes = {
    children: PropTypes.any,
    featureAvailableForManualOrganisationsOnly: PropTypes.bool,
};

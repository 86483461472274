/**
 * Invoice campaign history component
 *
 * @author Aravind JR <aravind@paycepaid.com.au>
 * @version 1.0
 */
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { get } from 'lodash';

import CommunicationLogHistory from 'components/shared/CommunicationLog';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdmin from 'includes/hooks/useIsAdmin';
import { getCommunicationLogHistory } from 'includes/redux/actions/shared/communication';
import { Breadcrumb } from 'antd';
import { getInvoiceDetails } from 'includes/slices/invoice';

const InvoiceCampaignHistory = () => {
    const { t } = useTranslation();
    const invoiceDetails = useSelector(state => state.invoices.invoiceDetails);
    const organisationId = useOrganisationId();
    const accountId = useAccountId();
    const isAdmin = useIsAdmin();
    const dispatch = useDispatch();
    const location = useLocation();
    const { invoiceId, contactId } = useParams();

    /**
     * Get localised text
     *
     * @param {string} path
     */
    const getLocaleText = path => t(`sharedMessages.communicationLogHistory.${path}`);

    // fetch invoice details to show the invoice number in breadcrumb
    useEffect(() => {
        dispatch(getInvoiceDetails(organisationId, contactId, invoiceId));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Fetch the communication log history
     */
    const fetchCommunicationHistoryLog = useCallback(
        // eslint-disable-next-line no-unused-vars
        (data = {}) => {
            if (organisationId) {
                dispatch(
                    getCommunicationLogHistory(organisationId, {
                        // on_demand_campaign: true,
                        invoice_id: invoiceId,
                        ...data,
                    })
                );
            }
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // get log's details link
    const getDetailsLink = useCallback(
        log => ({
            pathname: isAdmin
                ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}/on-demand-campaign/history/${log.id}`
                : `/invoices/invoice/${contactId}/${invoiceId}/campaign/history/${log.id}`,
            state: {
                from: location.pathname,
                reminderFrom: get(
                    location,
                    'state.from',
                    isAdmin ? `/admin/accounts/organisation/reminders/${accountId}/${organisationId}` : `/reminders`
                ),
            },
        }),
        [isAdmin, location, accountId, organisationId] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const breadcrumb = [
        <Breadcrumb.Item key="invoices">
            <Link to={isAdmin ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}` : `/invoices`}>
                {getLocaleText('breadcrumb.invoices')}
            </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key="invoice-number">
            <Link
                to={
                    isAdmin
                        ? `/admin/accounts/organisation/invoices/invoice/${accountId}/${organisationId}/${contactId}/${invoiceId}`
                        : `/invoices/invoice/${contactId}/${invoiceId}`
                }
            >
                {get(invoiceDetails, 'invoice_number', 'Invoice')}
            </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key="invoice-campaign-history">
            {getLocaleText('breadcrumb.invoiceCampaignHistory')}
        </Breadcrumb.Item>,
    ];
    return (
        <CommunicationLogHistory
            getDataMethod={fetchCommunicationHistoryLog}
            getDetailsLink={getDetailsLink}
            pageTitle={getLocaleText(`pageTitle.invoiceCampaignHistory`)}
            breadcrumb={breadcrumb}
        />
    );
};

export default InvoiceCampaignHistory;

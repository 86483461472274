/**
 * Edit Contact Form
 * Renders the edit user form
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

// import required modules
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Form, Row, Col, Modal, Tag } from 'antd';
import { find, get, trim } from 'lodash';
import { parsePhoneNumber } from 'react-phone-number-input';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import InfoMessage from '../messages/InfoMessage';
import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import Button from '../Button';
import FormField from '../FormField';
import AddEditContactPersons from './AddEditContactPersons';

const { info } = Modal;

const EditContactForm = reduxForm({
    form: constants.EDIT_CONTACT_FORM_NAME,
})(props => {
    const [showInfoForEmail, setShowInfoForEmail] = useState(false);
    const [showInfoForMobileNumber, setShowInfoForMobileNumber] = useState(false);

    const { t } = useTranslation();

    /**
     * Generate the info locale
     *
     * @param {string} field  Field for which locale is to be generated
     *
     * @returns {string} Generated Locale info
     */
    const localeInfo = field => t(`messages.contacts.addEdit.form.input.info.${field}`);

    // effect to watch change in input mobile number
    useEffect(() => {
        if (!props.isDemoMobileNumberUpdated) {
            setShowInfoForMobileNumber(true);
        } else if (
            props.inputMobileNumber &&
            props.organisationPhoneCode &&
            !props.isTestAccount &&
            (!props.allow_international_calls || !props.allow_international_sms)
        ) {
            const parsedMobileNumber = parsePhoneNumber(props.inputMobileNumber);
            if (parsedMobileNumber) {
                const countryCallingCode = get(parsedMobileNumber, 'countryCallingCode');
                if (countryCallingCode === props.organisationPhoneCode) {
                    setShowInfoForMobileNumber(false);
                } else {
                    setShowInfoForMobileNumber(true);
                }
            }
        }
    }, [props.inputMobileNumber]); // eslint-disable-line react-hooks/exhaustive-deps

    // effect to watch change in input email
    useEffect(() => {
        if (!props.isDemoEmailUpdated) {
            setShowInfoForEmail(true);
        }
    }, [props.inputEmail]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Ge the contact info message for email field
     */
    const getContactInfoMessageForEmail = () => {
        if (showInfoForEmail && !props.isTestAccount && !props.isDemoEmailUpdated) {
            return <span onClick={showContactInfoMessageForEmail}>Reminder will not be send. Click to see why?</span>;
        }
        return null;
    };

    /**
     * Ge the contact info message for mobile number field
     */
    const getContactInfoMessageForMobileNumber = () => {
        if (
            showInfoForMobileNumber &&
            !props.isTestAccount &&
            (!props.allow_international_calls || !props.allow_international_sms || !props.isDemoMobileNumberUpdated)
        ) {
            return (
                <span onClick={showContactInfoMessageForMobileNumber}>
                    Reminder will not be send. Click to see why?
                </span>
            );
        }
        return null;
    };

    /**
     * Show the contact info message for email
     */
    const showContactInfoMessageForEmail = () => {
        let message = '';
        if (!props.isTestAccount && !props.isDemoEmailUpdated) {
            if (!props.isDemoEmailUpdated) {
                message = t(`customerMessages.editContact.form.input.email.demoEmailNotUpdated`);
            }
            if (message) {
                info({
                    title: 'Info',
                    content: message,
                });
            }
        }
    };

    /**
     * Show the contact info message for mobile number field
     */
    const showContactInfoMessageForMobileNumber = () => {
        let message = '';
        if (
            !props.isTestAccount &&
            (!props.allow_international_calls || !props.allow_international_sms || !props.isDemoMobileNumberUpdated)
        ) {
            if (!props.isDemoMobileNumberUpdated) {
                message = t(`customerMessages.editContact.form.input.mobileNumber.demoMobileNumberNotUpdated`);
            } else if (!props.allow_international_calls && !props.allow_international_sms) {
                message = t(`customerMessages.editContact.form.input.mobileNumber.noInternationalCallsAndSmsAllowed`);
            } else if (props.allow_international_calls) {
                message = t(`customerMessages.editContact.form.input.mobileNumber.noInternationalCallsAllowed`);
            } else if (props.allow_international_sms) {
                message = t(`customerMessages.editContact.form.input.mobileNumber.noInternationalSmsAllowed`);
            }
            if (message) {
                info({
                    title: 'Info',
                    content: message,
                });
            }
        }
    };

    /**
     * Generate the label from language file
     *
     * @param   {string}    field   Field for which locale is to be generated
     *
     * @returns {string}    Generated Locale label
     */
    const localeLabel = field => t(`customerMessages.editContact.form.input.label.${field}`);

    /**
     * Validate the contract start date
     */
    const validateContractStartDate = useCallback((date, allValues) => {
        if (get(allValues, requestKeys.CONTRACT_END_DATE)) {
            const isAfter = moment(date).isSameOrAfter(moment(allValues[requestKeys.CONTRACT_END_DATE]), 'day');
            return isAfter ? t('fieldErrors.common.invalid.contract_start_date_greater_than_end_date') : undefined;
        }
        return undefined;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Validate the contract end date
     */
    const validateContractEndDate = useCallback((date, allValues) => {
        if (get(allValues, requestKeys.CONTRACT_START_DATE)) {
            const isBefore = moment(date).isSameOrBefore(moment(allValues[requestKeys.CONTRACT_START_DATE]), 'day');
            return isBefore ? t('fieldErrors.common.invalid.contract_end_date_less_than_end_date') : undefined;
        }
        return undefined;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const { contacts, initialValues } = props;

    // inject initial value if not present in the list
    const contactManagerOptions = useMemo(() => {
        const options = contacts.map(contact => ({
            name: trim(`${contact.first_name} ${contact.last_name}`) || contact.business_name,
            value: contact.id,
        }));
        const initialParentContact = get(initialValues, 'parent_contact_init_option.value', '');
        if (initialParentContact && !find(options, { value: initialParentContact })) {
            options.push(initialValues.parent_contact_init_option);
        }
        return options;
    }, [contacts]); // eslint-disable-line react-hooks/exhaustive-deps

    const formattedContactsOptions = useMemo(
        () =>
            contacts.map(contact => ({
                name: trim(`${contact.first_name} ${contact.last_name}`) || contact.business_name,
                value: contact.id,
            })),
        [contacts]
    );

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <div className="profile-detail-upper">
                    <Field
                        type="avatar"
                        name={requestKeys.AVATAR}
                        label={localeLabel(requestKeys.AVATAR)}
                        imageName={t(
                            `customerMessages.editContact.form.input.label.${
                                props.isContactManager ? 'contact_manager_avatar' : 'contact_avatar'
                            }`
                        )}
                        reduxChange={props.change}
                        hasRemovedKey={requestKeys.HAS_DELETED_AVATAR}
                        isModalVisible={props.imagePickerVisible}
                        onSubmit={props.onImageSubmit}
                        toggleImagePicker={props.toggleImagePicker}
                        hasFeedback
                        component={FormField}
                        validate={[]}
                        imageSourceSizeKey={constants.IMAGE_254x254}
                        imageSourceOriginalKey={constants.IMAGE_ORIGINAL}
                        defaultImage={props.defaultImage}
                    />
                    <ul className="upper-right-detail">
                        <li>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Field
                                        type="text"
                                        name={requestKeys.FIRST_NAME}
                                        label={localeLabel(requestKeys.FIRST_FULL_NAME)}
                                        hasFeedback
                                        component={FormField}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Field
                                        type="text"
                                        name={requestKeys.LAST_NAME}
                                        label={localeLabel(requestKeys.LAST_NAME)}
                                        hasFeedback
                                        component={FormField}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Field
                                        type="text"
                                        name={requestKeys.BUSINESS_NAME}
                                        label={localeLabel(requestKeys.BUSINESS_NAME)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Field
                                        type="text"
                                        name={requestKeys.EMAIL}
                                        label={localeLabel(requestKeys.EMAIL)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.email]}
                                        info={getContactInfoMessageForEmail()}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12} className="phone-field-wrapper">
                                    <Field
                                        type="phone"
                                        name={requestKeys.MOBILE_NUMBER}
                                        label={localeLabel(requestKeys.MOBILE_NUMBER)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.phone]}
                                        reduxChange={props.change}
                                        onChange={value =>
                                            props.change(requestKeys.MOBILE_NUMBER, value ? value : null)
                                        }
                                        info={getContactInfoMessageForMobileNumber()}
                                    />
                                </Col>
                                <Col span={12} className="phone-field-wrapper">
                                    <Field
                                        type="phone"
                                        name={requestKeys.PHONE_NUMBER}
                                        label={localeLabel(requestKeys.PHONE_NUMBER)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.phone]}
                                        reduxChange={props.change}
                                        onChange={value => props.change(requestKeys.PHONE_NUMBER, value ? value : null)}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Field
                                        type="date"
                                        name={requestKeys.CONTACT_DATE_OF_BIRTH}
                                        label={localeLabel(requestKeys.CONTACT_DATE_OF_BIRTH)}
                                        className="form-control"
                                        component={FormField}
                                        reduxChange={props.change}
                                        validate={[formValidations.date]}
                                        disabledDate={current => {
                                            if (current) {
                                                return moment() <= current;
                                            }
                                        }}
                                    />
                                </Col>
                                <Col span={12} className="phone-field-wrapper">
                                    <Field
                                        type="select"
                                        name={requestKeys.CONTACT_TYPE}
                                        label={localeLabel(requestKeys.CONTACT_TYPE)}
                                        hasFeedback
                                        className="form-control"
                                        options={props.contactTypes.map(t => ({
                                            name: t.type,
                                            value: t.id,
                                        }))}
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        required
                                    />
                                </Col>
                            </Row>
                            {!props.isManualOrganisation ? (
                                <Row gutter={16}>
                                    <Col span={12} className="phone-field-wrapper">
                                        <Field
                                            type="text"
                                            name={requestKeys.EXTERNAL_ID}
                                            label={localeLabel(requestKeys.EXTERNAL_ID)}
                                            hasFeedback
                                            className="form-control"
                                            component={FormField}
                                        />
                                    </Col>
                                </Row>
                            ) : null}
                            {props.isManualOrganisation && (
                                <>
                                    <Row gutter={16}>
                                        <Col span={12} className="phone-field-wrapper">
                                            <Field
                                                type="text"
                                                name={requestKeys.EXTERNAL_ID}
                                                label={localeLabel(requestKeys.EXTERNAL_ID)}
                                                hasFeedback
                                                className="form-control"
                                                component={FormField}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Field
                                                type="text"
                                                name={requestKeys.REGISTRATION_NUMBER}
                                                label={localeLabel(requestKeys.REGISTRATION_NUMBER)}
                                                hasFeedback
                                                className="form-control"
                                                component={FormField}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Field
                                                type="date"
                                                name={requestKeys.CONTRACT_START_DATE}
                                                label={localeLabel(requestKeys.CONTRACT_START_DATE)}
                                                className="form-control"
                                                component={FormField}
                                                reduxChange={props.change}
                                                validate={[validateContractStartDate]}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Field
                                                type="date"
                                                name={requestKeys.CONTRACT_END_DATE}
                                                label={localeLabel(requestKeys.CONTRACT_END_DATE)}
                                                className="form-control"
                                                component={FormField}
                                                reduxChange={props.change}
                                                validate={[validateContractEndDate]}
                                            />
                                        </Col>
                                    </Row>
                                    {props.isContactManager && (
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Field
                                                    type="switch"
                                                    name={requestKeys.CC_CONTACT}
                                                    label={localeLabel(requestKeys.CC_CONTACT)}
                                                    hasFeedback
                                                    className="form-control"
                                                    component={FormField}
                                                    info={t(`customerMessages.editContact.form.input.info.cc_contact`)}
                                                    style={{ position: 'relative', float: 'none', left: 0 }}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </>
                            )}
                        </li>
                    </ul>
                </div>
                <hr />
                <section className="profile-detail-lower">
                    <div className="details-wrapper">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Field
                                    type="textarea"
                                    name={requestKeys.PHYSICAL_ADDRESS}
                                    label={localeLabel(requestKeys.PHYSICAL_ADDRESS)}
                                    hasFeedback
                                    className="form-group"
                                    component={FormField}
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="textarea"
                                    name={requestKeys.POSTAL_ADDRESS}
                                    label={localeLabel(requestKeys.POSTAL_ADDRESS)}
                                    hasFeedback
                                    className="form-group"
                                    component={FormField}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className="details-wrapper">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Field
                                    type="textarea"
                                    name={requestKeys.PAYMENT_INSTRUCTIONS}
                                    label={localeLabel(requestKeys.PAYMENT_INSTRUCTIONS)}
                                    hasFeedback
                                    className="form-group"
                                    component={FormField}
                                    info={localeInfo(requestKeys.PAYMENT_INSTRUCTIONS)}
                                    infoAsToolTip
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    name={requestKeys.PAYMENT_TERMS}
                                    label={localeLabel(requestKeys.PAYMENT_TERMS)}
                                    hasFeedback
                                    className="form-group"
                                    component={FormField}
                                    info={localeInfo(requestKeys.PAYMENT_TERMS)}
                                    infoAsToolTip
                                />
                            </Col>
                        </Row>
                    </div>
                </section>
                <hr />

                <section className="profile-detail-lower">
                    <div className="details-wrapper contact-persons-wrapper assigned-contacts-wrapper">
                        <Row gutter={8}>
                            <Col span={12}>
                                {props.isContactManager ? (
                                    <Field
                                        type="select"
                                        mode="multiple"
                                        name={requestKeys.CHILD_CONTACTS}
                                        label={localeLabel(requestKeys.CHILD_CONTACTS)}
                                        options={formattedContactsOptions}
                                        filterOption={false}
                                        onSearch={value => props.autoSuggestContacts(value)}
                                        component={FormField}
                                        validate={[]}
                                    />
                                ) : (
                                    <Field
                                        type="autocomplete"
                                        name={requestKeys.CONTACT_MANAGER_ID}
                                        label={localeLabel(requestKeys.CONTACT_MANAGER_ID)}
                                        hasFeedback
                                        options={contactManagerOptions}
                                        onSearch={value => props.autoSuggestContacts(value)}
                                        component={FormField}
                                        validate={[]}
                                    />
                                )}
                            </Col>
                            <Col span={12}>
                                {props.isContactManager && props.assignedContactsValue
                                    ? props.assignedContactsValue
                                          .filter(val => val !== '')
                                          .map(val => (
                                              <Tag
                                                  key={val}
                                                  color="#8ace7d"
                                                  closable
                                                  onClose={() =>
                                                      props.change(
                                                          requestKeys.CHILD_CONTACTS,
                                                          [...props.assignedContactsValue].filter(id => id !== val)
                                                      )
                                                  }
                                              >
                                                  {props.assignedContactsNameMap[val]}
                                              </Tag>
                                          ))
                                    : null}
                            </Col>
                        </Row>
                    </div>
                </section>

                <section className="profile-detail-lower">
                    <div className="details-wrapper contact-persons-wrapper">
                        <>
                            <h2>{t(`customerMessages.editContact.form.label.contactPersons.header`)}</h2>
                            <div className="contact-person-info-message">
                                <InfoMessage
                                    message={t(`customerMessages.editContact.form.label.contactPersons.info`)}
                                />
                            </div>
                            <FieldArray name="contact_persons" component={AddEditContactPersons} />
                        </>
                    </div>
                </section>
            </Form>
            <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={props.isDetailsUpdating}
                        submittingButtonLabel={t(
                            `customerMessages.editContact.form.submit.buttonSubmittingLabel.${
                                props.isAdd ? 'add' : 'edit'
                            }`
                        )}
                        className="register-btn"
                    >
                        {t(`customerMessages.editContact.form.submit.buttonLabel.${props.isAdd ? 'add' : 'edit'}`)}
                    </Button>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
});

EditContactForm.propTypes = {
    allow_international_calls: PropTypes.bool,
    allow_international_sms: PropTypes.bool,
    assignedContactsNameMap: PropTypes.object,
    assignedContactsValue: PropTypes.array,
    autoSuggestContacts: PropTypes.func,
    change: PropTypes.func,
    contacts: PropTypes.array,
    contactTypes: PropTypes.array,
    defaultImage: PropTypes.string,
    error: PropTypes.any,
    fields: PropTypes.array,
    handleSubmit: PropTypes.func,
    imagePickerVisible: PropTypes.bool,
    initialValues: PropTypes.object,
    inputEmail: PropTypes.string,
    inputMobileNumber: PropTypes.string,
    inputParentContact: PropTypes.string,
    isAdd: PropTypes.bool,
    isContactManager: PropTypes.bool,
    isDemoEmailUpdated: PropTypes.bool,
    isDemoMobileNumberUpdated: PropTypes.bool,
    isDetailsUpdating: PropTypes.bool,
    isManualOrganisation: PropTypes.bool,
    isTestAccount: PropTypes.bool,
    onImageSubmit: PropTypes.func,
    organisationPhoneCode: PropTypes.string,
    t: PropTypes.func,
    toggleImagePicker: PropTypes.func,
};

export default EditContactForm;

/**
 * Invoice Details Component
 * Handles the invoice details page view and functions related to loading dashboard
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { find, get, isEmpty } from 'lodash';
import { Dropdown, Icon, Modal, Tabs, Menu, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/Button';
import InvoicePaymentsBlock from 'components/customer/InvoicePayments/payments';
import Loader from 'components/shared/Loader';
import Reminders from 'components/customer/Reminders/list';
import Invoices from 'pages/Invoices/List/components/Table';
import ExportButton from 'components/shared/Exports/button';
import { multiStringReplace, redirect } from 'includes/utils';
import { getInvoiceDetails, getInvoicePdfDownloadUrl, getInvoices, deleteInvoice } from 'includes/slices/invoice';
import { resetReminderTypeAndDateFilters } from 'includes/redux/actions/customer/reminder';
import { useCurrentRoleSlug, useOrderTypes } from 'includes/utils/hooks';
import { ACCOUNTING_SOFTWARE_DEEP_LINKS } from 'includes/constants/deepLinks';
import InvoiceLineItems from './lineItems';
import {
    INVOICE_STATUS_DRAFT_SLUG,
    INVOICE_STATUS_SETTLED,
    INVOICE_STATUS_VOIDED_SLUG,
    INVOICE_STATUS_WRITE_OFF_SLUG,
    ORDER_TYPE,
    USER_ROLE_ADMIN,
    USER_ROLE_ADMIN_MANAGER,
} from 'includes/constants';
import NotesList from 'components/Notes/List';
import InvoicesBreadCrumbItems from 'components/BreadCrumbItems/Invoices';
import PreviewModal from '../PreviewModal';
import { formatRepeatingDueOnText, formatRepeatsText } from '../utils';
import './styles.scss';
import useInvoiceLinkGenerator from 'includes/hooks/useInvoiceLinkGenerator';
import OnDemandCampaignModal from 'components/OnDemandCampaign';
import useToFEDateFormatter from 'includes/hooks/useToFEDateFormatter';
import PermissionGuard from 'components/PermissionGuard';
import { AUDIT_LOG, INVOICE, NOTE, RECURRING_INVOICE } from 'includes/constants/permissions';
import useHasRequiredPermission from 'includes/hooks/useHasRequiredPermission';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import useIsDebtor from 'includes/hooks/useIsDebtor';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import { CURRENCY_CODE } from 'includes/constants/keys/response';
import PaymentArrangementsList from 'pages/PaymentArrangements/List';
import AuditLogList from 'pages/AuditLogs/List';
import OrdersTable from 'components/shared/Orders/Table';
import { getOrders } from 'includes/slices/orders';
import InvoiceStatusTag from 'components/InvoiceStatusTag';
import useInvoiceStatuses from 'includes/hooks/useInvoiceStatuses';
const { TabPane } = Tabs;
const { confirm } = Modal;

/**
 * Invoice details Component
 */
const InvoiceDetails = () => {
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const params = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const isDebtor = useIsDebtor();
    const [invoiceIds, setInvoiceIds] = useState([]);
    const [tableFilters, setTableFilters] = useState([]);
    const [showOnDemandCampaignModal, setShowOnDemandCampaignModal] = useState(false);
    const getInvoiceLink = useInvoiceLinkGenerator();
    const { t } = useTranslation();
    const convertDateToFEFormat = useToFEDateFormatter(true);
    const { data: currencies, isLoading: isOrganisationCurrenciesLoading } = useOrganisationCurrencies();

    const { accountId, contactId, invoiceId } = params;

    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);
    const accountingSoftwareName = useSelector(state =>
        get(state.organisation, ['selectedOrganisation', 'accounting_software_data', 'name'])
    );
    const accountingSoftwareSlug = useSelector(state =>
        get(state.organisation, ['selectedOrganisation', 'accounting_software_data', 'slug'])
    );
    const invoiceDetails = useSelector(state => state.invoices.invoiceDetails);
    const isAdmin = useSelector(state => state.account.isAdmin);
    const isLoading = useSelector(state => state.invoices.isInvoiceDetailsLoading);
    const isInvoicePdfDownloading = useSelector(state => state.invoices.isInvoicePdfDownloading);
    const _organisationId = useSelector(state => state.organisation.selectedOrganisationId);
    const communicationTypes = useSelector(state => state.communication.communicationTypes);
    const hasInvoiceListViewPermission = useHasRequiredPermission(INVOICE.PAYMENT.LIST);
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    const organisationId = isAdmin ? params.organisationId : _organisationId;

    // get invoice details API action
    useEffect(() => {
        dispatch(getInvoiceDetails(organisationId, contactId, invoiceId));
    }, [invoiceId]); // eslint-disable-line react-hooks/exhaustive-deps

    // effect to reset the reminder filters when the invoice details module is unmounted
    useEffect(
        () => () => {
            if (!(get(history, 'location.pathname').indexOf('/reminders/') !== -1)) {
                dispatch(resetReminderTypeAndDateFilters());
            }
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Format number with invoice currency
     *
     * @param {string|number} number  Number
     *
     * @returns {string} Formatted Number
     */
    const getNumberFormattedWithInvoiceCurrency = number =>
        getNumberFormattedAsCurrency(
            number,
            false,
            false,
            get(find(currencies, { id: get(invoiceDetails, 'currency_id') }), CURRENCY_CODE)
        );

    const { data: invoiceStatuses, isLoading: isInvoiceStatusesLoading } = useInvoiceStatuses();

    const invoiceStatusSlug = get(find(invoiceStatuses, { id: get(invoiceDetails, 'status') }), 'slug');

    const isDraftInvoice = invoiceStatusSlug === INVOICE_STATUS_DRAFT_SLUG;
    const isVoidedInvoice = invoiceStatusSlug === INVOICE_STATUS_VOIDED_SLUG;
    const isWrittenOffInvoice = invoiceStatusSlug === INVOICE_STATUS_WRITE_OFF_SLUG;
    const isSettledInvoice = invoiceStatusSlug === INVOICE_STATUS_SETTLED;

    const currentRoleSlug = useCurrentRoleSlug();

    /**
     * Get contact view link based on logged in user's role
     *
     * @param {object} contact contact object
     */
    const getContactViewLink = () => {
        const urlNamespace = get(invoiceDetails, 'contact_id.is_manager') ? 'contact-manager' : 'contact';
        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return `/admin/accounts/organisation/${urlNamespace}/${accountId}/${organisationId}/${get(
                    invoiceDetails,
                    'contact_id.id'
                )}`;
            default:
                return `/${urlNamespace}/${get(invoiceDetails, 'contact_id.id')}`;
        }
    };

    /**
     * Get invoice contact view link based on logged in user's role
     */
    const getInvoiceContactViewLink = () => {
        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return `/admin/accounts/organisation/contact/${accountId}/${organisationId}/${get(
                    invoiceDetails,
                    'invoice_contact_id.id'
                )}`;
            default:
                return `/contact/${get(invoiceDetails, 'invoice_contact_id.id')}`;
        }
    };

    const isRepeatingInvoice = invoiceDetails.is_repeating_invoice || false;

    const renderEditButton = () => {
        const amountPaid = get(invoiceDetails, 'amount_paid.amount', 0);
        const isManual = get(invoiceDetails, 'is_manual', false);

        //restrict the user from editing the invoice
        if (
            amountPaid > 0 || // a payment has already been made
            !isManual || // invoice is not manual
            isVoidedInvoice ||
            isWrittenOffInvoice ||
            isSettledInvoice
        ) {
            return null;
        }

        return (
            <PermissionGuard requiredPermission={isRepeatingInvoice ? RECURRING_INVOICE.EDIT : INVOICE.EDIT}>
                <Button
                    className="green-bg-button"
                    big
                    filled
                    onClick={() => redirect(getInvoiceLink(contactId, invoiceId, true, isRepeatingInvoice))}
                >
                    Edit
                </Button>
            </PermissionGuard>
        );
    };

    const fetchChildInvoices = (searchParams = {}) =>
        dispatch(getInvoices(organisationId, { ...searchParams, parent_invoice_id: invoiceId }));

    /**
     * Delete invoice
     *
     * @param {string} invoiceContactId Id of the invoice contact
     * @param {string} invoiceId Id of the invoice to be deleted
     */
    const dispatchDeleteInvoice = (invoiceContactId, childInvoiceId) => {
        confirm({
            title: t('customerMessages.invoice.confirm.deleteInvoice'),
            content: '',
            onOk: () => {
                dispatch(
                    deleteInvoice(organisationId, invoiceContactId, childInvoiceId, {
                        parent_invoice_id: invoiceId,
                    })
                );
            },
        });
    };

    const [orderTypes] = useOrderTypes();

    /**
     * Find and memoise subscription order type
     */
    const orderType = useMemo(() => {
        if (orderTypes.length === 0) return null;

        return find(orderTypes, { slug: ORDER_TYPE.INVOICE });
    }, [orderTypes]);

    /**
     * Get data method - to be passed in to the orders table
     */
    const getDataMethod = useCallback(
        params => {
            if (!orderType) return;

            const newParams = {
                ...params,
                invoice_id: invoiceId,
                organisation_id: organisationId,
            };

            if (isAdmin) newParams['account_id'] = accountId;

            dispatch(getOrders(orderType.id, { ...newParams }));
        },
        [accountId, orderType, contactId]
    );

    const renderTabPanes = () => {
        const tabPanes = [];
        if (!isEmpty(invoiceDetails) && !isEmpty(invoiceStatuses)) {
            if (isRepeatingInvoice && !isDraftInvoice)
                tabPanes.push(
                    <TabPane tab="Child Invoices" key="child-invoices">
                        <div className="invoice-listing-wrapper invoices-wrapper child-invoices-listing-wrapper">
                            <ExportButton
                                exportType={contactId ? 'contact-invoices' : 'invoices'}
                                contactId={contactId}
                                queryParams={{ invoice_ids: invoiceIds, ...tableFilters }}
                            />
                            <Invoices
                                invoiceIds={invoiceIds}
                                setInvoiceIds={setInvoiceIds}
                                fetchInvoices={fetchChildInvoices}
                                deleteInvoice={dispatchDeleteInvoice}
                                setTableFilters={setTableFilters}
                                tableFilters={tableFilters}
                                contactId={contactId}
                                organisation={organisationId}
                                accountId={accountId}
                                showOnDemandCampaign={false}
                                showActionButtons={true}
                            />
                        </div>
                    </TabPane>
                );
            else {
                if (!isDraftInvoice) {
                    if (!isDebtor) {
                        tabPanes.push(
                            <TabPane tab="Reminders" key="reminders">
                                <Reminders
                                    mode="invoice"
                                    invoiceId={invoiceId}
                                    organisation={organisationId}
                                    accountId={accountId}
                                    key="invoice_reminders_list"
                                    showBreadCrumb={false}
                                />
                            </TabPane>
                        );
                    }
                    if (hasInvoiceListViewPermission && !isVoidedInvoice) {
                        tabPanes.push(
                            <TabPane tab="Payment History" key="payment-history">
                                <InvoicePaymentsBlock
                                    showActions={get(invoiceDetails, 'is_manual') && !isDebtor}
                                    invoiceStatus={get(find(invoiceStatuses, { id: invoiceDetails.status }), 'slug')}
                                />
                            </TabPane>
                        );
                    }
                }
            }
            if (!isDebtor) {
                tabPanes.push(
                    <TabPane key="notes" tab="Notes">
                        <PermissionGuardLayout requiredPermission={NOTE.LIST}>
                            <NotesList />
                        </PermissionGuardLayout>
                    </TabPane>
                );
            }
            if (!isRepeatingInvoice && !isDebtor) {
                tabPanes.push(
                    <TabPane key="payment-arrangement" tab="Payment Arrangements">
                        <PaymentArrangementsList
                            contactId={contactId}
                            invoiceId={invoiceId}
                            showTitle={false}
                            currentPaymentPlanType="arrangement"
                        />
                    </TabPane>
                );
                tabPanes.push(
                    <TabPane key="payment-extension" tab="Payment Extensions">
                        <PaymentArrangementsList
                            contactId={contactId}
                            invoiceId={invoiceId}
                            showTitle={false}
                            currentPaymentPlanType="extension"
                        />
                    </TabPane>
                );
            }
            tabPanes.push(
                <TabPane key="audit-logs" tab="Audit Logs">
                    <PermissionGuardLayout requiredPermission={AUDIT_LOG.LIST} layout="section" showFallbackUI>
                        <AuditLogList customDataValues={{ invoice_id: invoiceId }} showTitle={false} />
                    </PermissionGuardLayout>
                </TabPane>
            );
            tabPanes.push(
                <TabPane key="invoice-orders" tab="Orders">
                    <OrdersTable
                        getDataMethod={getDataMethod}
                        isSearching={false}
                        setIsSearching={() => {}}
                        typeSlug={ORDER_TYPE.INVOICE}
                    />
                </TabPane>
            );
        }
        return tabPanes;
    };

    const {
        contact_id: { id: invoiceContactId, business_name: contactName } = {},
        contact_cc: contactCC = [],
    } = invoiceDetails;

    const campaignMenu = (
        <Menu>
            <Menu.Item>
                <PermissionGuard requiredPermission={INVOICE.SEND} fullWidth>
                    <span onClick={() => setShowOnDemandCampaignModal(true)}>Send</span>
                </PermissionGuard>
            </Menu.Item>
            <Menu.Item>
                <PermissionGuard requiredPermission={INVOICE.ON_DEMAND_CAMPAIGN.HISTORY} fullWidth>
                    <span
                        onClick={() =>
                            redirect(
                                isAdmin
                                    ? `/admin/accounts/organisation/invoices/invoice/${accountId}/${organisationId}/${invoiceContactId}/${invoiceId}/on-demand-campaign/history/`
                                    : `/invoices/invoice/${invoiceContactId}/${invoiceId}/campaign/history`
                            )
                        }
                    >
                        History
                    </span>
                </PermissionGuard>
            </Menu.Item>
        </Menu>
    );

    const previewMenu = (
        <Menu>
            <Menu.Item>
                <PermissionGuard fullWidth requiredPermission={INVOICE.PREVIEW}>
                    <span onClick={() => setIsPreviewOpen(true)}>Preview</span>
                </PermissionGuard>
            </Menu.Item>
            <Menu.Item>
                <PermissionGuard
                    requiredPermission={isRepeatingInvoice ? RECURRING_INVOICE.DOWNLOAD : INVOICE.DOWNLOAD}
                >
                    <span
                        onClick={() => {
                            dispatch(getInvoicePdfDownloadUrl(organisationId, contactId, invoiceId));
                        }}
                    >
                        Download PDF
                    </span>
                </PermissionGuard>
            </Menu.Item>
        </Menu>
    );

    /**
     * Returns the to string for CampaignModal
     *
     * @param {string} notificationType
     * @returns {string}
     */
    const getToText = notificationType => {
        const contactDetails = get(invoiceDetails, 'contact_id', {});
        const notificationTypeSlug = get(find(communicationTypes, { id: notificationType }), 'slug');
        if (!notificationTypeSlug) return contactDetails.name;

        const contactInfo = {
            email: contactDetails.email,
            ivr: contactDetails.mobile_number,
            sms: contactDetails.mobile_number,
        }[notificationTypeSlug];

        return `${!isEmpty(contactDetails.name) ? contactDetails.name : contactDetails.business_name} ${
            contactInfo ? `<${contactInfo}>` : ''
        }`;
    };

    const lineItems = useMemo(() => get(invoiceDetails, 'line_items', []), [invoiceDetails]);

    return (
        <div className="details-wrapper invoice-details-wrapper">
            <PreviewModal
                isOpen={isPreviewOpen}
                onClose={() => setIsPreviewOpen(false)}
                invoiceDetails={invoiceDetails}
            />
            <OnDemandCampaignModal
                contactCC={contactCC}
                contactIds={[invoiceContactId]}
                contactNames={[contactName]}
                fillDefaultTemplateDetails
                getFilterParams={() => ({})} // intentionally left empty
                getToText={getToText}
                invoiceIds={[invoiceId]}
                organisationId={organisationId}
                setInvoiceIds={() => {}} // intentionally left empty
                setShowOnDemandCampaignModal={setShowOnDemandCampaignModal}
                showOnDemandCampaignModal={showOnDemandCampaignModal}
                totalCount={1}
            />
            <div className="container tab-arrow-link breadcrumb-spec mx-auto">
                <InvoicesBreadCrumbItems showListBreadCrumb showDetailsBreadCrumb />
            </div>
            <div className="home-content-wrapper synced-name details-wrapper">
                <h2 className="page-title">Invoice</h2>
                <div className="white-box-wrapper mid-wrapper breadcrumb-spec">
                    {isLoading || isInvoiceStatusesLoading || isOrganisationCurrenciesLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <section className="details-list left-align">
                                <div className="details-profile-pic-wrapper">
                                    <img
                                        src={require('../../../assets/images/invoice_avatar.svg')}
                                        className="org-avatar logo-placeholder"
                                        alt="profile-pic"
                                    />
                                </div>
                                {get(selectedOrganisation, 'external_short_code') &&
                                    get(ACCOUNTING_SOFTWARE_DEEP_LINKS, [accountingSoftwareSlug, 'invoice']) &&
                                    get(invoiceDetails, 'external_id') && (
                                        <div className="center-align">
                                            <a
                                                className="accounting-software-external-link"
                                                href={multiStringReplace(
                                                    get(ACCOUNTING_SOFTWARE_DEEP_LINKS, [
                                                        accountingSoftwareSlug,
                                                        'invoice',
                                                    ]),
                                                    {
                                                        short_code: get(selectedOrganisation, 'external_short_code'),
                                                        invoice_id: get(invoiceDetails, 'external_id'),
                                                    }
                                                )}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                View in {accountingSoftwareName}
                                            </a>
                                        </div>
                                    )}
                                <div className="center-align">{renderEditButton()}</div>
                                <div className="center-align">
                                    <Dropdown overlay={previewMenu}>
                                        <Button
                                            className="blue-bg-button"
                                            filled
                                            loading={isInvoicePdfDownloading}
                                            submittingButtonLabel={
                                                isInvoicePdfDownloading ? 'Downloading PDF...' : 'Opening Preview...'
                                            }
                                        >
                                            <Icon type="eye" />
                                            Preview
                                            <Icon type="caret-down" />
                                        </Button>
                                    </Dropdown>
                                </div>
                                {isRepeatingInvoice ||
                                isDebtor ||
                                isVoidedInvoice ||
                                isDraftInvoice ||
                                isWrittenOffInvoice ? null : (
                                    <Dropdown overlay={campaignMenu}>
                                        <Button className="blue-bg-button" big filled>
                                            <Icon type="mail" />
                                            Send
                                            <Icon type="caret-down" />
                                        </Button>
                                    </Dropdown>
                                )}
                            </section>
                            <section className="details-list right-align">
                                <div className="full-wrapper">
                                    <h3 className="profile-name">{get(invoiceDetails, 'invoice_number')}</h3>
                                    <div className="sub-detail no-text-indent">
                                        {get(invoiceDetails, 'line_of_business')}
                                    </div>
                                </div>
                                <section className="detail-summary">
                                    <ul className="upper-right-detail">
                                        <li>
                                            <header>Contact Name</header>
                                            <div>
                                                <Link to={getContactViewLink()}>
                                                    {get(invoiceDetails, 'contact_id.name')}
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <header>Business Name</header>
                                            <div>
                                                <Link to={getContactViewLink()}>
                                                    {get(invoiceDetails, 'contact_id.business_name')}
                                                </Link>
                                            </div>
                                        </li>
                                        {!isEmpty(get(invoiceDetails, 'invoice_contact_id')) && (
                                            <li>
                                                <header>Invoice Contact</header>
                                                <div>
                                                    <Link to={getInvoiceContactViewLink()}>
                                                        {get(invoiceDetails, 'invoice_contact_id.name') ||
                                                            get(invoiceDetails, 'invoice_contact_id.business_name')}
                                                    </Link>
                                                </div>
                                            </li>
                                        )}
                                        {isRepeatingInvoice ? (
                                            <>
                                                <li>
                                                    <header>Next Invoice Date</header>
                                                    <div>
                                                        {convertDateToFEFormat(
                                                            get(invoiceDetails, 'next_invoice_date')
                                                        )}
                                                    </div>
                                                </li>
                                                <li>
                                                    <header>Repeats</header>
                                                    <div>
                                                        {formatRepeatsText(
                                                            invoiceDetails.repeating_period_type,
                                                            invoiceDetails.repeating_period,
                                                            isRepeatingInvoice
                                                        )}
                                                    </div>
                                                </li>
                                                <li>
                                                    <header>Due On</header>
                                                    <div>
                                                        {formatRepeatingDueOnText(
                                                            invoiceDetails.repeating_due_date_type,
                                                            invoiceDetails.repeating_due_date_day,
                                                            isRepeatingInvoice
                                                        )}
                                                    </div>
                                                </li>
                                                <li>
                                                    <header>End Date</header>
                                                    <div>
                                                        {convertDateToFEFormat(invoiceDetails.repeating_end_date)}
                                                    </div>
                                                </li>
                                            </>
                                        ) : (
                                            <>
                                                <li>
                                                    <header>Issued Date</header>
                                                    <div>
                                                        {convertDateToFEFormat(get(invoiceDetails, 'issued_date'))}
                                                    </div>
                                                </li>
                                                <li>
                                                    <header>Due Date</header>
                                                    <div>{convertDateToFEFormat(get(invoiceDetails, 'due_date'))}</div>
                                                </li>
                                                <li>
                                                    <header>Days overdue</header>
                                                    <div>{get(invoiceDetails, 'days_overdue')}</div>
                                                </li>
                                                <li>
                                                    <header>Total amount</header>
                                                    <div>
                                                        <span
                                                            title={getNumberFormattedWithInvoiceCurrency(
                                                                get(invoiceDetails, ['total_amount', 'amount'])
                                                            )}
                                                        >
                                                            {getNumberFormattedWithInvoiceCurrency(
                                                                get(invoiceDetails, ['total_amount', 'amount_short'])
                                                            )}
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <header>Amount paid</header>
                                                    <div>
                                                        <span
                                                            title={getNumberFormattedWithInvoiceCurrency(
                                                                get(invoiceDetails, ['amount_paid', 'amount'])
                                                            )}
                                                        >
                                                            {getNumberFormattedWithInvoiceCurrency(
                                                                get(invoiceDetails, ['amount_paid', 'amount_short'])
                                                            )}
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <header>Amount Due</header>
                                                    <div>
                                                        <span
                                                            title={getNumberFormattedWithInvoiceCurrency(
                                                                get(invoiceDetails, ['amount_due', 'amount'])
                                                            )}
                                                        >
                                                            {getNumberFormattedWithInvoiceCurrency(
                                                                get(invoiceDetails, ['amount_due', 'amount_short'])
                                                            )}
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <header>Status</header>
                                                    <div className=" flex flex-col items-start gap-2">
                                                        <InvoiceStatusTag id={invoiceDetails.status} />
                                                        {invoiceDetails?.in_payment_arrangement && (
                                                            <Tag color="blue">In Payment Arrangement</Tag>
                                                        )}
                                                        {invoiceDetails?.in_payment_extension && (
                                                            <Tag color="blue">In Payment Extension</Tag>
                                                        )}
                                                    </div>
                                                </li>
                                            </>
                                        )}
                                        {get(selectedOrganisation, 'is_manual', false) && (
                                            <>
                                                <li>
                                                    <header>External ID</header>
                                                    <div>{get(invoiceDetails, 'external_id')}</div>
                                                </li>
                                                <li>
                                                    <header>Purchase Order</header>
                                                    <div>{get(invoiceDetails, 'purchase_order')}</div>
                                                </li>
                                            </>
                                        )}
                                    </ul>
                                </section>
                            </section>
                            {!isEmpty(get(invoiceDetails, 'line_items')) ? (
                                <section className="full-wrapper">
                                    <InvoiceLineItems
                                        invoiceLineItems={lineItems}
                                        getNumberFormattedWithInvoiceCurrency={getNumberFormattedWithInvoiceCurrency}
                                    />
                                </section>
                            ) : (
                                ''
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className="full-wrapper box-wrapper white-box-wrapper tab-wrapper bottom-section">
                <Tabs destroyInactiveTabPane>{renderTabPanes()}</Tabs>
            </div>
        </div>
    );
};

export default InvoiceDetails;

/**
 * User Subscription Details Component
 * Handles the user subscription details view and functions related to loading user subscription details
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules

import { React, connect, Link, useEffect, useState, useMemo, useCallback } from '../../../includes/exports/react';
import { Breadcrumb, Col, Icon, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';
import { getSubscriptionDetails } from '../../../includes/redux/actions/customer/subscriptionPlan';
import Button from '../../shared/Button';
import './styles.scss';
import Loader from '../../shared/Loader';
import { getDefaultImageClass, getImageFromData } from '../../../includes/utils';
import CommunicationMethodMetrics from './components/CommunicationMethodMetrics';
import CommunicationMethodLabel from './components/CommunicationMethodLabel';

const { Option } = Select;

/**
 * *  User Subscription Details Component
 */
const UserSubscriptionDetails = props => {
    const accountLogo254Key = 'ACCOUNT_LOGO_254X254';
    const accountLogoOriginalKey = 'ACCOUNT_LOGO_ORIGINAL';
    //state to manage date for filtering
    const [subscriptionPlanUsageGroup, setSubscriptionPlanUsageGroup] = useState();
    const { subscriptionId } = props.match.params;

    const isAdmin = props.isAdmin;

    const { subscriptionDetails, getSubscriptionDetails } = props;

    //effect to fetch subscription details
    useEffect(() => {
        subscriptionId && getSubscriptionDetails(subscriptionId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    //effect to set initial date
    useEffect(() => {
        setSubscriptionPlanUsageGroup(get(subscriptionDetails, 'subscription_usages.0.id'));
    }, [subscriptionDetails]);

    const getDateOptions = useCallback(
        () =>
            get(subscriptionDetails, 'subscription_usages', []).map(group => (
                <Option value={group.id} key={group.id}>
                    {`${group.start_date} - ${group.end_date}`}
                </Option>
            )),
        [subscriptionDetails]
    );

    const dateOptions = useMemo(() => getDateOptions(), [getDateOptions]);

    const subscriptionPlanUsageDetails = find(get(subscriptionDetails, 'subscription_usages', []), {
        id: subscriptionPlanUsageGroup,
    });

    return (
        <div className="home-content-wrapper user-subscription-wrapper subscription-details-wrapper">
            <div className="tab-arrow-link">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={isAdmin ? '/admin/subscriptions' : '/subscription'}>User Subscription</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Details</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h2 className="page-title">Subscription Details</h2>
            {props.isDetailsLoading ? (
                <div className="white-box-wrapper box-wrapper full-wrapper">
                    <Loader />
                </div>
            ) : (
                <>
                    <section className="white-box-wrapper box-wrapper full-wrapper user-subs-summary">
                        <summary className="left-pane">
                            <div className="profile-pic subscription-account-logo">
                                <img
                                    className={
                                        getDefaultImageClass(
                                            get(props, 'subscriptionDetails.account'),
                                            accountLogo254Key,
                                            accountLogoOriginalKey,
                                            '77x77'
                                        ) + ' logo logo-45 '
                                    }
                                    src={getImageFromData(
                                        get(props, 'subscriptionDetails.account'),
                                        accountLogo254Key,
                                        accountLogoOriginalKey
                                    )}
                                    alt={get(props, 'subscriptionDetails.account.name')}
                                />
                            </div>
                            <div className="user-detail">
                                <div className="profile-name">{get(props, 'subscriptionDetails.account.name', '')}</div>
                                <div className="summary">
                                    <Icon type="calendar" />
                                    Plan subscribed on: {get(props, 'subscriptionDetails.subscription_start_date', '')}
                                </div>
                            </div>
                        </summary>
                        <summary className="right-align right-pane">
                            <div className="alert-info">
                                {get(props, 'subscriptionDetails.expires_in')
                                    ? 'This subscription will expire on'
                                    : 'This subscription has expired on'}{' '}
                                - {get(props, 'subscriptionDetails.subscription_end_date', '')}
                                {!isAdmin && (
                                    <Button filled className="blue-bg-button upgrade-btn">
                                        <Link to="/plans">Upgrade</Link>
                                    </Button>
                                )}
                            </div>
                            <div className="info">{get(props, 'subscriptionDetails.plan.name')}</div>
                        </summary>
                    </section>
                    <section className="white-box-wrapper box-wrapper full-wrapper">
                        <div className="right-align-text">
                            <span className="menu">
                                Filter by Date Range{' '}
                                <Select
                                    onChange={val => setSubscriptionPlanUsageGroup(val)}
                                    value={subscriptionPlanUsageGroup}
                                >
                                    {dateOptions}
                                </Select>
                            </span>
                        </div>
                        <div className="details-wrapper">
                            <section className="detail-summary">
                                <ul className="upper-right-detail">
                                    <li>
                                        <header>Start Date</header>
                                        <div>{get(props, 'subscriptionDetails.subscription_start_date', '')}</div>
                                    </li>
                                    <li>
                                        <header>End Date</header>
                                        <div>{get(props, 'subscriptionDetails.subscription_end_date', '')}</div>
                                    </li>
                                </ul>
                                {subscriptionPlanUsageDetails ? (
                                    <Row gutter={32} type="flex" style={{ margin: '20px 0' }} justify="start">
                                        <Col lg={4} md={12} xs={24}>
                                            <CommunicationMethodMetrics
                                                isLoading={props.isDetailsLoading}
                                                attemptedCount={subscriptionPlanUsageDetails.total_call_attempted}
                                                failedCount={subscriptionPlanUsageDetails.total_call_failed}
                                                successfulCount={subscriptionPlanUsageDetails.total_call_successful}
                                                methodLabel={
                                                    <CommunicationMethodLabel btnClassName="ivr">
                                                        IVR
                                                    </CommunicationMethodLabel>
                                                }
                                            />
                                        </Col>
                                        <Col lg={4} md={12} xs={24}>
                                            <CommunicationMethodMetrics
                                                isLoading={props.isDetailsLoading}
                                                attemptedCount={subscriptionPlanUsageDetails.total_email_attempted}
                                                failedCount={subscriptionPlanUsageDetails.total_email_failed}
                                                successfulCount={subscriptionPlanUsageDetails.total_email_successful}
                                                methodLabel={
                                                    <CommunicationMethodLabel btnClassName="email">
                                                        Email
                                                    </CommunicationMethodLabel>
                                                }
                                            />
                                        </Col>
                                        <Col lg={4} md={12} xs={24}>
                                            <CommunicationMethodMetrics
                                                isLoading={props.isDetailsLoading}
                                                attemptedCount={subscriptionPlanUsageDetails.total_sms_attempted}
                                                failedCount={subscriptionPlanUsageDetails.total_sms_failed}
                                                successfulCount={subscriptionPlanUsageDetails.total_sms_successful}
                                                methodLabel={
                                                    <CommunicationMethodLabel btnClassName="sms">
                                                        SMS
                                                    </CommunicationMethodLabel>
                                                }
                                            />
                                        </Col>
                                        <Col lg={4} md={12} xs={24}>
                                            <CommunicationMethodMetrics
                                                isLoading={props.isDetailsLoading}
                                                attemptedCount={subscriptionPlanUsageDetails.total_whatsapp_attempted}
                                                failedCount={subscriptionPlanUsageDetails.total_whatsapp_failed}
                                                successfulCount={subscriptionPlanUsageDetails.total_whatsapp_successful}
                                                methodLabel={
                                                    <CommunicationMethodLabel btnClassName="whatsapp">
                                                        WhatsApp
                                                    </CommunicationMethodLabel>
                                                }
                                            />
                                        </Col>
                                    </Row>
                                ) : null}
                            </section>
                        </div>
                    </section>
                </>
            )}
        </div>
    );
};

/**
 * Proptypes
 */
UserSubscriptionDetails.propTypes = {
    getSubscriptionDetails: PropTypes.func,
    isDetailsLoading: PropTypes.bool,
    match: PropTypes.object,
    subscriptionDetails: PropTypes.object,
    isAdmin: PropTypes.bool,
};

// connect to store
export default connect(
    state => ({
        subscriptionDetails: state.subscription.subscriptionDetails,
        isDetailsLoading: state.subscription.isDetailsLoading,
        userDetails: state.user.userDetails,
        accountDetails: state.account.accountDetails,
        roles: state.user.roles,
        isAdmin: state.account.isAdmin,
    }),
    {
        getSubscriptionDetails,
    }
)(UserSubscriptionDetails);

import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/Button';
import PermissionGuard from 'components/PermissionGuard';
import { PAYMENT_METHOD } from 'includes/constants/permissions';

/**
 * Description section component of payment methods
 *
 * @since 2.8.0
 */
export default function Description({ onAddCard }) {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col gap-3">
            <div> Manage you credit cards and payment options.</div>
            <PermissionGuard requiredPermission={PAYMENT_METHOD.ADD}>
                <Button onClick={onAddCard} className="mb-2 mx-auto">
                    {t('customerMessages.paymentMethod.action.addNewPaymentMethod')}
                </Button>
            </PermissionGuard>
        </div>
    );
}

Description.propTypes = {
    onAddCard: PropTypes.func,
};

/**
 * Audit log list component
 *
 * @version 2.2.0
 */

// import required modules
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AUDIT_LOG_TYPES, AUDIT_LOG_TYPES_ACCOUNT } from 'includes/constants';
import { Breadcrumb, Cascader, DatePicker, Modal, Tag } from 'antd';
import { getAuditLogs } from 'includes/slices/logs';
import { getAuditLogTableColumns } from 'includes/tableColumns/auditLogs';
import { PropTypes } from 'prop-types';
import { get } from 'lodash';
import { TABLE_NO_AUDIT_LOGS_FOUND } from 'includes/constants/messages/errors';
import { useDispatch } from 'react-redux';
import { useIsMount } from 'includes/utils/hooks';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AuditLogDetails from 'pages/AuditLogs/Details/component';
import AuditLogsBreadCrumbItems from 'components/BreadCrumbItems/AuditLogs';
import CustomizedButton from 'components/Button';
import Loader from 'components/Loader';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import useAccountId from 'includes/hooks/useAccountId';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { auditLogStatusTagColors } from 'includes/constants/tagColors';
import { AUDIT_LOG } from 'includes/constants/permissions';
import PermissionGuard from 'components/PermissionGuard';
import useOrganisationRequired from 'includes/hooks/useOrganisationRequired';
import './styles.scss';

const { RangePicker } = DatePicker;

const AuditLogList = props => {
    const [auditLogDetails, setAuditLogDetails] = useState('');
    const [auditLogType, setAuditLogType] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [isSearching, setIsSearching] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [showAuditLogDetailsModal, setShowAuditLogDetailsModal] = useState(false);
    const { t } = useTranslation();
    const accountId = useAccountId(false);
    const auditLogs = useSelector(state => state.logs.auditLogs);
    const auditLogsPagination = useSelector(state => state.logs.auditLogsPagination);
    const dispatch = useDispatch();
    const loadingAuditLogs = useSelector(state => state.logs.loadingAuditLogs);
    const organisationId = useOrganisationId();
    const { includeAccountId, includeOrganisationId } = props;
    const isMount = useIsMount();

    useOrganisationRequired(includeOrganisationId ? true : false);

    useEffect(() => {
        if (isMount) {
            if (!loadingAuditLogs) {
                getTheAuditLogs();
            }
        }
    }, [includeAccountId, includeOrganisationId, dateRange, auditLogType]);
    /**
     * Format the audit logs data
     */
    const formatAuditLogsData = useCallback(
        () =>
            auditLogs.map(auditLog => {
                return {
                    ...auditLog,
                    actions: (
                        <div className="blue-bg-button">
                            <PermissionGuard requiredPermission={AUDIT_LOG.LIST}>
                                <span>
                                    <CustomizedButton
                                        className="green-bg-button blue-bg-button"
                                        filled
                                        onClick={() => {
                                            setAuditLogDetails(auditLog);
                                            setShowAuditLogDetailsModal(true);
                                        }}
                                    >
                                        {t('messages.auditLogs.list.table.fieldName.view_details')}
                                    </CustomizedButton>
                                </span>
                            </PermissionGuard>
                        </div>
                    ),
                    success_status: (
                        <Tag color={get(auditLogStatusTagColors, auditLog.success_status ? 1 : 2, 0)}>
                            {t(
                                `messages.auditLogs.list.table.fieldName.${
                                    auditLog.success_status ? 'success' : 'failed'
                                }`
                            )}
                        </Tag>
                    ),
                };
            }),
        [auditLogs]
    );

    /**
     * Get the custom data values
     *
     * @returns {object} customDataValues Custom data values
     */
    const getCustomDataValues = () => {
        const customDataValues = { ...props.customDataValues };

        if (includeOrganisationId && organisationId) {
            customDataValues['organisation_id'] = organisationId;
        }
        return customDataValues;
    };

    /***
     * Get the audit logs
     *
     * @param {object} searchParams Search params
     */
    const getTheAuditLogs = (searchParams = {}) => {
        if (dateRange[0]) {
            searchParams.start_date = dateRange[0].format('YYYY-MM-DD');
        }

        if (dateRange[1]) {
            searchParams.end_date = dateRange[1].format('YYYY-MM-DD');
        }

        if (get(auditLogType, 0)) {
            searchParams.type = auditLogType[0];
        }

        const customDataValues = { ...searchParams, ...getCustomDataValues() };

        if (includeOrganisationId && (!organisationId || organisationId === 'undefined')) {
            return;
        }

        dispatch(getAuditLogs(includeAccountId ? accountId : '', customDataValues));
    };

    /**
     * Memoized audit logs data
     */
    const memoizedAuditLogsData = useMemo(() => formatAuditLogsData(), [formatAuditLogsData]);

    return (
        <div className="home-content-wrapper accounts-wrapper">
            {props.showTitle ? (
                <>
                    <h2 className="page-title">{t(`titleAndMetas.auditLogs.title`)}</h2>
                    <div className="tab-arrow-link breadcrumb-spec">
                        <Breadcrumb>
                            <AuditLogsBreadCrumbItems />
                        </Breadcrumb>
                    </div>
                </>
            ) : null}
            <div
                className={`search-filter-export-wrapper full-wrapper box-wrapper audit-log-list-wrapper ${
                    props.showTitle ? 'white-box-wrapper' : ''
                }`}
            >
                <section>
                    <ul className="selector-field">
                        <li>
                            <div className="date-picker-wrapper left-align">
                                <span
                                    className="date-range-label"
                                    style={{ float: 'left', marginTop: '15px', marginBottom: '5px' }}
                                >
                                    {t('messages.auditLogs.list.table.fieldName.date_range')}
                                </span>
                                <RangePicker
                                    value={dateRange}
                                    onChange={range => setDateRange(range)}
                                    size="small"
                                    disabled={loadingAuditLogs}
                                    format="DD-MM-YYYY"
                                />
                            </div>
                        </li>
                        <li>
                            <div className="right-align">
                                <SearchBlock
                                    getDataMethod={getTheAuditLogs}
                                    isSearching={isSearching}
                                    placeholder={t('messages.auditLogs.search.placeholder')}
                                    setIsSearching={setIsSearching}
                                    setSearchParam={setSearchParam}
                                />
                            </div>
                        </li>
                    </ul>
                    {props.showFilter ? (
                        <ul className="selector-field">
                            <li>
                                <div className="invoice-menu left-align">
                                    <div className="selector-title">
                                        {t('messages.auditLogs.list.table.fieldName.type_filter')}
                                    </div>
                                    <Cascader
                                        value={auditLogType}
                                        options={props.useAccountFilter ? AUDIT_LOG_TYPES_ACCOUNT : AUDIT_LOG_TYPES}
                                        onChange={val => setAuditLogType(val)}
                                        disabled={loadingAuditLogs}
                                    />{' '}
                                </div>
                            </li>
                        </ul>
                    ) : null}
                </section>
                <Table
                    className="table-1200"
                    columns={getAuditLogTableColumns}
                    dataSource={memoizedAuditLogsData}
                    getDataMethod={getTheAuditLogs}
                    isSearching={isSearching}
                    loading={loadingAuditLogs}
                    localeMessage={TABLE_NO_AUDIT_LOGS_FOUND}
                    paginationData={auditLogsPagination}
                    rowClassName="pointer"
                    rowKey="id"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    size="middle"
                    {...props}
                />
                <Modal
                    className="my_profile_modal organisation-reauthorization-modal on-demand-campaign-modal "
                    visible={showAuditLogDetailsModal}
                    footer={null}
                    closable
                    onClose={() => {
                        setAuditLogDetails('');
                    }}
                    onCancel={() => {
                        setShowAuditLogDetailsModal(false);
                    }}
                >
                    {auditLogDetails ? (
                        <div className="audit-log-details">
                            <AuditLogDetails auditLogDetails={auditLogDetails} />
                        </div>
                    ) : (
                        <Loader />
                    )}
                </Modal>
            </div>
        </div>
    );
};

AuditLogList.defaultProps = {
    customDataValues: {},
    includeAccountId: true,
    includeOrganisationId: true,
    showFilter: false,
    showTitle: true,
    useAccountFilter: false,
};

AuditLogList.propTypes = {
    customDataValues: PropTypes.object,
    includeAccountId: PropTypes.bool,
    includeOrganisationId: PropTypes.bool,
    showFilter: PropTypes.bool,
    showTitle: PropTypes.bool,
    useAccountFilter: PropTypes.bool,
};

export default AuditLogList;

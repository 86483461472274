/**
 * Chart of accounts listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import { Breadcrumb, Icon, Modal } from 'antd';
import { find, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import ChartOfAccountsBreadCrumbItems from 'components/BreadCrumbItems/ChartOfAccounts';
import InfoMessage from 'components/shared/messages/InfoMessage';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import useChartOfAccounts from 'includes/hooks/useChartOfAccounts';
import useChartOfAccountTypes from 'includes/hooks/useChartOfAccountTypes';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { deleteChartOfAccount, getChartOfAccounts } from 'includes/slices/chartOfAccounts';
import { getChartOfAccountsTableColumns } from 'includes/tableColumns/chartOfAccounts';
import { TABLE_NO_CHART_OF_ACCOUNTS_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import PermissionGuard from 'components/PermissionGuard';
import { redirect } from 'includes/utils';
import { CHART_OF_ACCOUNT } from 'includes/constants/permissions';

const { confirm } = Modal;

const ChartOfAccountsList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    useUpdateBusinessName();
    const { accountId } = params;
    const { data: chartOfAccountTypes, isLoading: loadingChartOfAccountTypes } = useChartOfAccountTypes();
    const chartOfAccounts = useSelector(state => state.chartOfAccounts.chartOfAccounts);
    const chartOfAccountsPagination = useSelector(state => state.chartOfAccounts.chartOfAccountsPagination);
    const isAdmin = useSelector(state => state.account.isAdmin);
    const loadingChartOfAccountDelete = useSelector(state => state.chartOfAccounts.loadingChartOfAccountDelete);
    const loadingChartOfAccounts = useSelector(state => state.chartOfAccounts.loadingChartOfAccounts);
    const organisationId = useOrganisationId();

    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const { reset: clearChartOfAccountsCache } = useChartOfAccounts(true);

    /**
     * Clear chart of accounts cache
     */
    const clearAccountsCache = () => {
        clearChartOfAccountsCache();
    };

    /**
     * Delete the chart of account
     *
     * @param {string} chartOfAccountId Chart of Account Id
     */
    const deleteAccount = chartOfAccountId => {
        confirm({
            title: t(`messages.chartOfAccounts.confirm.delete.title`),
            content: '',
            onOk: () => {
                dispatch(deleteChartOfAccount(chartOfAccountId, organisationId, clearAccountsCache));
            },
        });
    };

    /**
     * Get the chart of account details add link
     *
     * @returns {string} Add Link
     */
    const getAddLink = () =>
        isAdmin
            ? `/admin/accounts/organisation/chart-of-accounts/${accountId}/${organisationId}/add`
            : `/chart-of-accounts/add`;

    /**
     * Get the locale text
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @returns {string} Locale test
     */
    const getLocaleText = path => t(`messages.chartOfAccounts.${path}`);

    /**
     * Get the chart of account details view link
     *
     * @param {string} chartOfAccountId Chart of account id
     *
     * @returns {string} View Link
     */
    const getViewLink = chartOfAccountId =>
        isAdmin
            ? `/admin/accounts/organisation/chart-of-accounts/chart-of-account/${accountId}/${organisationId}/${chartOfAccountId}`
            : `/chart-of-accounts/chart-of-account/${chartOfAccountId}`;

    /***
     * Get transaction account type
     *
     * @param {number} transactionType Transaction type
     *
     * @returns {string} Transaction type value
     */
    const getTransactionAccountType = transactionType =>
        transactionType === 1 ? 'Payment' : transactionType === 2 ? 'Sales' : '';

    /**
     * Format the chart of accounts data
     */
    const formatChartOfAccountsData = useCallback(
        () =>
            chartOfAccounts.map(chartOfAccount => {
                return {
                    ...chartOfAccount,
                    name: (
                        <div className="business-name-avatar-wrapper">
                            <div className="action-wrapper">
                                {chartOfAccount.name}
                                {chartOfAccount.is_default_payment_account && (
                                    <span title={getLocaleText('list.title.name')}>
                                        <Icon
                                            type="check"
                                            style={{
                                                color: 'green',
                                                marginLeft: '10px',
                                                fontSize: '25px',
                                            }}
                                        />
                                    </span>
                                )}
                                <span className="crud-wrapper">
                                    <PermissionGuard requiredPermission={CHART_OF_ACCOUNT.VIEW}>
                                        <button
                                            className="crud-link"
                                            onClick={() => redirect(getViewLink(chartOfAccount.id))}
                                        >
                                            {getLocaleText('crud.view')}
                                        </button>
                                    </PermissionGuard>
                                    <PermissionGuard requiredPermission={CHART_OF_ACCOUNT.DELETE}>
                                        <button className="crud-link" onClick={() => deleteAccount(chartOfAccount.id)}>
                                            {getLocaleText('crud.delete')}
                                        </button>
                                    </PermissionGuard>
                                </span>
                            </div>
                        </div>
                    ),
                    account_type_id:
                        get(find(chartOfAccountTypes, { id: chartOfAccount.account_type_id }), 'type') || '',
                    transaction_type: getTransactionAccountType(chartOfAccount.transaction_type),
                    total_credit_amount: get(chartOfAccount, 'total_credit_amount'),
                    total_debit_amount: get(chartOfAccount, 'total_debit_amount'),
                };
            }),
        [chartOfAccounts, chartOfAccountTypes] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Memoized chart of account data
     */
    const memoizedChartOfAccountData = useMemo(() => formatChartOfAccountsData(), [formatChartOfAccountsData]);

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
            <h2 className="page-title">{t(`titleAndMetas.chartOfAccounts.title`)}</h2>
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <ChartOfAccountsBreadCrumbItems showListBreadCrumb={isAdmin} />
                </Breadcrumb>
            </div>
            <div className="search-filter-export-wrapper contact-list-container full-wrapper box-wrapper white-box-wrapper">
                <section className="right-menu-wrapper">
                    <div className="right-align">
                        <PermissionGuard requiredPermission={CHART_OF_ACCOUNT.ADD}>
                            <Button big filled onClick={() => redirect(getAddLink())}>
                                {getLocaleText('crud.add')}
                            </Button>
                        </PermissionGuard>
                    </div>
                </section>
                <ul className="selector-field show-elem invoices-wrapper">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>
                        <div className="invoices-search">
                            <SearchBlock
                                dataValues={[organisationId]}
                                dispatchMethod={true}
                                getDataMethod={getChartOfAccounts}
                                isSearching={isSearching}
                                placeholder={getLocaleText('search.placeholder')}
                                setIsSearching={setIsSearching}
                                setSearchParam={setSearchParam}
                            />
                        </div>
                    </li>
                </ul>
                <Table
                    className="table-1200"
                    columns={getChartOfAccountsTableColumns}
                    dataSource={memoizedChartOfAccountData}
                    dataValues={[organisationId]}
                    dispatchMethod={true}
                    getDataMethod={getChartOfAccounts}
                    isSearching={isSearching}
                    loading={loadingChartOfAccounts || loadingChartOfAccountTypes}
                    localeMessage={TABLE_NO_CHART_OF_ACCOUNTS_FOUND_MESSAGE}
                    mobileClassName="table-1200"
                    paginationData={chartOfAccountsPagination}
                    rowClassName="pointer"
                    rowKey="id"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    size="middle"
                />
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={loadingChartOfAccountDelete}
                >
                    <InfoMessage message={t('messages.chartOfAccounts.modal.message.delete')} showLoader={true} />
                </Modal>
            </div>
        </div>
    );
};

export default ChartOfAccountsList;

/**
 * Organisations API class.
 * Handles all api requests related to organisations
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class OrganisationApi extends BaseApiHandler {
    /**
     * Call the arrangement due data API
     *
     * @returns {object} API Response
     */
    getArrangementDueData = ({ organisationId, ...restPayload }) =>
        this.axios.get(this.getApiUrl('ORGANISATIONS_GET_ARRANGEMENT_DUE_DATA', { organisation_id: organisationId }), {
            params: { ...restPayload },
        });

    /**
     * Call the cash collected due data API
     *
     * @returns {object} API Response
     */
    getCashCollectedData = ({ organisationId, ...restPayload }) =>
        this.axios.get(this.getApiUrl('ORGANISATIONS_GET_CASH_COLLECTED_DATA', { organisation_id: organisationId }), {
            params: { ...restPayload },
        });

    /**
     * Call the contact and invoice status and type data API
     *
     * @returns {object} API Response
     */
    getContactInvoiceStatusTypeData = ({ organisationId, ...restPayload }) =>
        this.axios.get(
            this.getApiUrl('ORGANISATIONS_GET_CONTACT_INVOICE_STATUS_TYPE_DATA', { organisation_id: organisationId }),
            {
                params: { ...restPayload },
            }
        );

    /**
     * Call the dashboard data API
     *
     * @returns {object} API Response
     */
    getDashboardData = payload =>
        this.axios.get(this.getApiUrl('ORGANISATIONS_GET_DASHBOARD_DATA', { organisation_id: payload.organisationId }));

    /**
     * Call the debt band and debt age data API
     *
     * @returns {object} API Response
     */
    getDebtAgeAndDebtBandData = payload =>
        this.axios.get(
            this.getApiUrl('ORGANISATIONS_GET_DEBT_AGE_DEBT_BAND_DATA', { organisation_id: payload.organisationId })
        );

    /**
     * Call the debtor accounts API
     *
     * @returns {object} API Response
     */
    getDebtorAccounts = ({ organisationId, searchParams }) =>
        this.axios.get(this.getApiUrl('ORGANISATIONS_GET_DEBTOR_ACCOUNTS', { organisation_id: organisationId }), {
            params: { ...searchParams },
        });

    /**
     * Call the organisation listing API
     *
     * @returns {object} API Response
     */
    getOrganisations = payload => this.axios.get(this.getApiUrl('ORGANISATIONS_GET_ALL'), { params: { ...payload } });
    /**
     * Call the organisation listing API
     *
     * @returns {object} API Response
     */
    getOrganisationsByAccId = ({ searchParams, ...restPayload }) =>
        this.axios.get(this.getApiUrl('GET_ORGANISATIONS_BY_ACC_ID', restPayload), { params: { ...searchParams } });

    /**
     * Call the organisation details API
     *
     * @returns {object} API Response
     */
    getOrganisationDetails = payload =>
        this.axios.get(this.getApiUrl('ORGANISATIONS_GET_ORGANISATION', { organisation_id: payload.organisationId }));

    /**
     * Call the organisation update API
     *
     * @returns {object} API Response
     */
    updateOrganisation = ({ organisation_id, organisationDetails }) =>
        this.axios.patch(this.getApiUrl('ORGANISATIONS_EDIT_ORGANISATION', { organisation_id }), organisationDetails);

    /**
     * Call the organisation delete API
     *
     * @returns {object} API Response
     */
    deleteOrganisation = ({ organisation_id }) =>
        this.axios.delete(this.getApiUrl('ORGANISATIONS_DELETE_ORGANISATION', { organisation_id }));

    /* Call the organisation add API
     *
     * @returns {object} API Response
     */
    addOrganisation = ({ organisationDetails }) =>
        this.axios.post(this.getApiUrl('ORGANISATIONS_ADD_ORGANISATION'), organisationDetails);

    /**
     * Call the organisation countries API
     *
     * @returns {object} API Response
     */
    getCountries = payload =>
        this.axios.get(this.getApiUrl('ORGANISATION_COUNTRIES_GET_ALL'), { params: { ...payload } });

    /**
     * Call the organisation export API
     *
     */
    exportOrganisation = payload => {
        return this.axios.get(this.getApiUrl('EXPORT_ORGANISATION'), { params: { ...payload } });
    };

    /**
     * Call the organisation export API
     *
     */
    exportAccountOrganisations = ({ accountId, params }) =>
        this.axios.get(this.getApiUrl('EXPORT_ACCOUNT_ORGANISATION', { account_id: accountId }), {
            params: { ...params },
        });

    /**
     * Call the sync organisation API
     *
     * @returns {object} API Response
     */
    syncOrganisation = payload =>
        this.axios.post(this.getApiUrl('ORGANISATION_SYNC_ORGANISATION', { organisation_id: payload.organisationId }));

    /**
     * Call the update organisation settings API
     *
     * @returns {object} API Response
     */
    updateOrganisationSettings = ({ organisation_id, ...restPayload }) =>
        this.axios.post(this.getApiUrl('ORGANISATION_UPDATE_ORGANISATION_SETTINGS', { organisation_id }), restPayload);

    /**
     * Call the get currency API
     *
     * @returns {object} API Response
     */
    getCurrencies = payload => this.axios.get(this.getApiUrl('GET_CURRENCIES'), { params: { ...payload } });
}

export default new OrganisationApi();

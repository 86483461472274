/**
 * User actions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import action types
import * as actionTypes from '../../../constants/actionTypes';

/**
 * Change the user status
 *
 * @param {string} userId Id of the user
 * @param {string} accountId Id of the account
 * @param {status} status User Status
 *
 * @returns Change user status action
 */
export const changeUserStatus = (userId, accountId, status) => ({
    type: actionTypes.CHANGE_USER_STATUS_REQUEST,
    payload: {
        status,
        user_id: userId,
        account_id: accountId,
    },
});

/**
 * Change user Password action
 *
 * @returns {object}            Change Password action
 */
export const changeUserPassword = obj => ({
    payload: obj,
    type: actionTypes.USER_CHANGE_PASSWORD_REQUEST,
});

/**
 * Forgot user password action
 *
 * @returns {object}            Forgot user password action
 */
export const forgotUserPassword = obj => ({
    payload: obj,
    type: actionTypes.FORGOT_USER_PASSWORD_REQUEST,
});

/**
 * Clear forgot user password messages action
 *
 * @returns {object}            Forgot user password messages action
 */
export const clearForgotUserPasswordMessages = () => ({
    type: actionTypes.CLEAR_FORGOT_USER_PASSWORD_MESSAGES,
});

/**
 * Get user profile details action
 *
 * @returns {object}            User Profile details action
 */
export const getUserProfileDetails = () => ({
    type: actionTypes.GET_USER_PROFILE_DETAILS_REQUEST,
});

/**
 * User Login Action
 *
 * @param   {string}    obj     Payload
 *
 * @returns {object}            User Login action
 */
export const loginUser = (obj, silent = false, callback) => ({
    type: actionTypes.USER_LOGIN_REQUEST,
    payload: obj,
    silent,
    callback,
});

/**
 * User Logout Action
 *
 * @param   {string}    obj     Payload
 *
 * @returns {object}    User Logout action
 */
export const logoutUser = (obj = {}) => ({
    type: actionTypes.USER_LOGOUT_REQUEST,
    payload: obj,
});

/**
 * User Account Switch Action
 *
 * @returns {object}    User Logout action
 */
export const switchAccount = id => ({
    type: actionTypes.USER_SWITCH_ACCOUNT,
    payload: {
        id,
    },
});

/**
 * User Registration Action
 *
 * @param   {string}    obj     Payload
 * @param {Function} setIsInSecondStep Set whether the user is in second step
 *
 * @returns {object}            User Registration action
 */
export const registerUser = (obj, setIsInSecondStep) => ({
    type: actionTypes.USER_REGISTRATION_REQUEST,
    payload: obj,
    setIsInSecondStep: setIsInSecondStep,
});

export const registerUserMeetingNotScheduled = () => ({
    type: actionTypes.USER_REGISTRATION_MEETING_NOT_SCHEDULED,
});
/**
 * Resend verification email action
 *
 * @returns {object}            Resend verification email action
 */
export const resendVerificationEmail = () => ({
    type: actionTypes.RESEND_VERIFICATION_EMAIL_REQUEST,
});

/**
 * Resend verification sms action
 *
 * @param {string} phoneNumber User Phone Number
 *
 * @returns {object}            Resend verification sms action
 */
export const resendVerificationSms = (phoneNumber = '') => ({
    type: actionTypes.RESEND_VERIFICATION_SMS_REQUEST,
    payload: { phone_number: phoneNumber },
});

/**
 * User Password Reset
 *
 * @param   {string}    obj     Payload
 *
 * @returns {object}            User Password Reset action
 */
export const resetPassword = payload => ({
    type: actionTypes.USER_RESET_PASSWORD_REQUEST,
    payload,
});

/**
 * Update user profile action
 *
 * @param   {object}    obj         Input Data Object
 * @param   {string}    accountId   Account Id
 * @param   {string}    userId      User Id
 *
 * @returns {object}            User profile update action
 */
export const updateUserProfile = (userDetails, accountId, userId, isAdmin) => ({
    payload: {
        userDetails,
        accountId,
        userId,
        isAdmin,
    },
    type: actionTypes.USER_PROFILE_UPDATE_REQUEST,
});

/**
 * Verify Phone Number action
 *
 * @param   {object}    obj     Input Data Object
 *
 * @returns {object}            Verify Phone Number action
 */
export const verifyPhoneNumber = obj => ({
    payload: obj,
    type: actionTypes.VERIFY_PHONE_NUMBER_REQUEST,
});

export const getUserRoles = roleNamePattern => ({
    type: actionTypes.GET_USER_ROLES,
    payload: {
        ...roleNamePattern,
        page_size: 0,
    },
});

export const getUserAccountRoles = roleNamePattern => ({
    type: actionTypes.GET_USER_ACCOUNT_ROLES,
    payload: {
        ...roleNamePattern,
        page_size: 0,
    },
});

/**
 * Verify Email action
 *
 * @returns {object}            Verify Email action
 */
export const verifyEmail = obj => ({
    type: actionTypes.VERIFY_EMAIL_ADDRESS_REQUEST,
    payload: obj,
});

/**
 *
 * @param {string} organisationId
 * @param {object} obj
 */

export const addUser = (userDetails, accountId, nonFieldInputs = [], isRole) => ({
    type: actionTypes.ADD_USER_REQUEST,
    payload: {
        userDetails,
        accountId,
        isRole,
    },
    nonFieldInputs,
});
export const updateUser = (userDetails, accountId, userId, nonFieldInputs = [], isRole) => ({
    type: actionTypes.USER_UPDATE_REQUEST,
    payload: {
        userDetails,
        accountId,
        userId,
        isRole,
    },
    nonFieldInputs,
});
export const getUsers = (accountId, searchParams) => ({
    type: actionTypes.GET_USERS_REQUEST,
    payload: {
        account_id: accountId,
        searchParams,
    },
});
export const getUserDetails = (accountId, userId) => ({
    type: actionTypes.GET_USER_DETAILS_REQUEST,
    payload: {
        accountId,
        userId,
    },
});

export const deleteUser = (accountId, userId) => ({
    type: actionTypes.DELETE_USER_REQUEST,
    payload: {
        accountId,
        userId,
    },
});

export const requestQuote = (payload, callback) => ({
    type: actionTypes.REQUEST_QUOTE_REQUEST,
    payload,
    callback,
});

export const getFAQ = () => ({
    type: actionTypes.GET_FAQ_REQUEST,
});

export const resetErrors = () => ({
    type: actionTypes.RESET_ADD_USER_ERRORS,
});

// set account password
export const activateAccount = payload => ({
    type: actionTypes.ACTIVATE_ACCOUNT_REQUEST,
    payload,
});

/**
 * Resend the activation email to the user
 *
 * @param {string} userId Id of the user
 * @param {string} accountId Id of the account
 *
 * @returns Resend the activation email action
 */
export const resendActivationEmail = (userId, accountId) => ({
    type: actionTypes.RESEND_ACTIVATION_EMAIL_REQUEST,
    payload: {
        user_id: userId,
        account_id: accountId,
    },
});

/**
 * Reset two factor authentication
 *
 * @param {string} userId Id of the user
 *
 * @returns Resend the activation email action
 */
export const resetTwoFactorAuthentication = userId => ({
    type: actionTypes.RESET_TWO_FACTOR_AUTHENTICATION_REQUEST,
    payload: {
        user_id: userId,
        two_factor_auth_disable: true,
    },
});

/**
 * Validate the reset password uuid and code before showing the password reset page
 *
 * @param   {string} payload  Payload
 *
 * @returns {object} User Password Reset action
 */
export const validateResetPassword = payload => ({
    type: actionTypes.USER_VALIDATE_RESET_PASSWORD_REQUEST,
    payload,
});

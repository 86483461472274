import { Dropdown, Icon } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import './styles.scss';

/**
 * Payment method
 *
 * Responsible for rendering a Card UI
 *
 * @since 2.8.0
 */
export default function PaymentMethodBase({ accountHolderName, description, icon, overlayMenu, tags, validity }) {
    return (
        <div className="relative border border-solid border-gray-200 rounded-lg shadow-lg px-3 py-2 flex flex-col gap-2 text-left text-gray-500 payment-method">
            <div className="flex flex-row gap-2 items-center">
                <div className="flex flex-row gap-2 items-center">
                    {icon}
                    <div>
                        <div className="text-md">{description}</div>
                        <div className="flex justify-start items-center gap-2 text-xs text-gray-400 ">
                            {accountHolderName}
                        </div>
                        <div className="text-sm text-gray-400 ">{validity}</div>
                    </div>
                </div>

                <div className="ml-auto self-start">
                    <Dropdown overlay={overlayMenu}>
                        <span className="cursor-pointer">
                            <Icon type="more" />
                        </span>
                    </Dropdown>
                </div>

                {tags ? <div className="absolute top-0 right-3 flex flex-row gap-2 tags-wrapper">{tags}</div> : null}
            </div>
        </div>
    );
}

PaymentMethodBase.propTypes = {
    accountHolderName: PropTypes.node,
    brand: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    expiryDate: PropTypes.string,
    icon: PropTypes.node,
    isDefault: PropTypes.bool,
    number: PropTypes.string,
    overlayMenu: PropTypes.node,
    tags: PropTypes.node,
    validity: PropTypes.node,
};

/**
 * Users Component
 * Handles the users listing view and functions related to loading users
 *
 * @version 1.0
 */

//import required modules
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { Modal, Breadcrumb } from 'antd';
import { withTranslation } from 'react-i18next';

import * as errorMessages from '../../../includes/constants/messages/errors';
import * as responseKeys from '../../../includes/constants/keys/response';
import Button from '../../shared/Button';
import HasEditPermission from '../../HasEditPermission';
import PermissionGuard from '../../PermissionGuard';
import SearchBlock from '../../shared/SearchBlock';
import Table from '../../shared/ScrollableTable';
import {
    changeUserStatus,
    deleteUser,
    getUsers,
    getUserRoles,
    getUserAccountRoles,
    resendActivationEmail,
    resetTwoFactorAuthentication,
} from '../../../includes/redux/actions/shared/user';
import { getDefaultImageClass, getImageFromData, redirect } from '../../../includes/utils';
import { USERS_TABLE } from '../../../includes/constants/columns';
import InfoMessage from '../../shared/messages/InfoMessage';
import { useRestrictCustomerManager, useUpdateBusinessName } from '../../../includes/utils/hooks';
import Loader from '../../shared/Loader';
import { USER } from 'includes/constants/permissions';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import { USER_ROLE_ADMIN, USER_ROLE_CUSTOMER } from 'includes/constants';

const { confirm } = Modal;

/**
 * Users Listing Component
 * Render the users listing component
 *
 * @returns    {string}    Users listing view
 */
const Users = props => {
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [isActivatingUser, setIsActivatingUser] = useState(false);
    const [isLoadingComponent, setIsLoadingComponent] = useState(true);
    const { currentAccountId, getUserAccountRoles, isAdmin, userAccountRoles, userRoles, users } = props;
    const accountId = props.match.params.accountId || currentAccountId;
    const userAvatar45Key = 'USER_AVATAR_45X45';
    const userAvatarOriginalKey = 'USER_AVATAR_ORIGINAL';
    const isAdminOrManager = useIsAdminOrManager();

    // check if user has updated the business name
    useUpdateBusinessName();

    useRestrictCustomerManager();

    /**
     * Use Effect to get user roles on load
     */
    useEffect(() => {
        getUserAccountRoles({
            accountId,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use Effect to set loading component as true on account id change
     */
    useEffect(() => {
        if (!isLoadingComponent) {
            setIsLoadingComponent(true);
        }
    }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use Effect to set loading component as false on component load
     */
    useEffect(() => {
        setIsLoadingComponent(false);
    }, [isLoadingComponent]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Check whether the current user has the option to activate or deactivate the user
     *
     * @param {object} user User object
     *
     * @returns {boolean} true|false
     */
    const canActivateOrDeactivateUser = user => {
        const currentUserRoleSlug = userRoles.find(s => s.id === get(props, 'userDetails.id', ''), 'slug', '');
        const userRoleSlug = get(
            userRoles.find(s => s.id === user.role_id),
            'slug',
            ''
        );
        return !(
            (currentUserRoleSlug === USER_ROLE_ADMIN && userRoleSlug === USER_ROLE_ADMIN) ||
            (currentUserRoleSlug === USER_ROLE_CUSTOMER && userRoleSlug === USER_ROLE_CUSTOMER) ||
            get(props, 'userDetails.id', '') === get(user, 'id', '')
        );
    };

    /**
     * Change the user status from active or inactive
     *
     * @param {string} userId Id of the user
     * @param {boolean} status Changing status of the user
     */
    const changeUserStatus = (userId, status) => {
        setIsActivatingUser(status);
        props.changeUserStatus(userId, accountId, status);
    };

    /**
     * Delete the user
     */
    const deleteUser = userId => {
        confirm({
            title: 'Are you sure you want to delete this user?',
            content: '',
            onOk: () => {
                props.deleteUser(accountId, userId);
            },
        });
    };

    /**
     * Check whether the user is admin or customer
     *
     * @param {object} user User object
     *
     * @returns {boolean} true|false
     */
    const isAdminOrCustomer = user =>
        [USER_ROLE_ADMIN, USER_ROLE_CUSTOMER].includes(
            get(
                userRoles.find(s => s.id === user.role_id),
                'slug',
                ''
            )
        );

    /**
     * Resend the activation email to the user
     *
     * @param {string} userId Id of the user
     */
    const resendActivationEmail = userId => {
        props.resendActivationEmail(userId, accountId, userId);
    };

    /**
     * Reset the two factor authentication
     *
     * @param {string} userId Id of the user
     */
    const resetTwoFactorAuthentication = userId => {
        props.resetTwoFactorAuthentication(userId, accountId, userId);
    };

    /**
     * Format user data
     */
    const formatUsersData = useCallback(() => {
        return users.map(user => ({
            ...user,
            name: (
                <div className="business-name-avatar-wrapper">
                    <img
                        className={
                            getDefaultImageClass(user, userAvatar45Key, userAvatarOriginalKey, '45x45') +
                            ' logo logo-45 '
                        }
                        src={getImageFromData(user, userAvatar45Key, userAvatarOriginalKey)}
                        alt={user.id}
                    />
                    <span className="wrapped-content-text">
                        {user.first_name} {user.last_name}
                        {user.id !== get(props, 'userDetails.id', '') && (
                            <span className="crud-wrapper">
                                <PermissionGuard requiredPermission={USER.EDIT}>
                                    <span
                                        className="crud-link"
                                        onClick={() =>
                                            redirect(
                                                isAdmin
                                                    ? props.path === 'accounts'
                                                        ? `/admin/accounts/user/${accountId}/${user.id}`
                                                        : `/admin/users/${accountId}/${user.id}`
                                                    : `user/${user.id}`
                                            )
                                        }
                                    >
                                        Edit
                                    </span>
                                </PermissionGuard>
                                {!isAdminOrCustomer(user) && (
                                    <PermissionGuard requiredPermission={USER.DELETE}>
                                        <span className="crud-link" onClick={() => deleteUser(user.id)}>
                                            Delete
                                        </span>
                                    </PermissionGuard>
                                )}
                                {canActivateOrDeactivateUser(user) && (
                                    <HasEditPermission
                                        className="crud-link"
                                        onClick={() => changeUserStatus(user.id, !user.is_active)}
                                    >
                                        {user.is_active ? 'Deactivate' : 'Activate'}
                                    </HasEditPermission>
                                )}
                                {!user.has_activated_account && (
                                    <HasEditPermission
                                        className="crud-link"
                                        onClick={() => resendActivationEmail(user.id)}
                                    >
                                        Resend activation email
                                    </HasEditPermission>
                                )}
                                {isAdminOrManager && (
                                    <HasEditPermission
                                        className="crud-link"
                                        onClick={() => resetTwoFactorAuthentication(user.id)}
                                    >
                                        Reset two factor authentication
                                    </HasEditPermission>
                                )}
                            </span>
                        )}
                    </span>
                </div>
            ),
            role: get(
                userAccountRoles.find(s => s.id === user.role_id),
                'role',
                ''
            ),
        }));
    }, [users, userAccountRoles, accountId]); // eslint-disable-line react-hooks/exhaustive-deps

    //memoized data
    const usersData = useMemo(() => formatUsersData(), [formatUsersData]);

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper listing-wrapper">
            {isAdmin && props.path === 'accounts' && (
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={`/admin/accounts/`}>Accounts</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Users</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            <h2 className="page-title">{props.t('sharedMessages.users.title')}</h2>
            <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                {isLoadingComponent ? (
                    <Loader />
                ) : (
                    <>
                        <section className="right-menu-wrapper">
                            <SearchBlock
                                dataValues={[accountId]}
                                getDataMethod={props.getUsers}
                                isSearching={isSearching}
                                placeholder={props.t('sharedMessages.users.search.placeholder')}
                                setIsSearching={setIsSearching}
                                setSearchParam={setSearchParam}
                            />
                            <div className="right-align">
                                <PermissionGuard requiredPermission={USER.ADD}>
                                    <Button
                                        big
                                        filled
                                        onClick={() =>
                                            redirect(
                                                isAdmin
                                                    ? props.path === 'accounts'
                                                        ? `/admin/accounts/add-user/${accountId}`
                                                        : `/admin/users/add-user`
                                                    : `/add-user`
                                            )
                                        }
                                    >
                                        Add User
                                    </Button>
                                </PermissionGuard>
                            </div>
                        </section>
                        <Table
                            columns={USERS_TABLE}
                            dataSource={usersData}
                            dataValues={[accountId]}
                            getDataMethod={props.getUsers}
                            isSearching={isSearching}
                            loading={props.isLoading}
                            localeMessage={errorMessages.TABLE_NO_USERS_FOUND_MESSAGE}
                            paginationData={props.paginationUsers}
                            rowClassName="pointer"
                            rowKey="id"
                            searchParam={searchParam}
                            setIsSearching={setIsSearching}
                            size="middle"
                            {...props}
                        />
                        <Modal
                            footer={[]}
                            className="my_profile_modal organisation-reauthorization-modal"
                            visible={props.isChangingUserStatus || props.isResendingActivationEmail}
                        >
                            <InfoMessage
                                message={props.t(
                                    `sharedMessages.users.status.popup.message.${
                                        props.isResendingActivationEmail
                                            ? 'resendingActivationEmail'
                                            : isActivatingUser
                                            ? 'activating'
                                            : 'deactivating'
                                    }`
                                )}
                                showLoader={true}
                            />
                        </Modal>
                        <Modal
                            footer={[]}
                            className="my_profile_modal organisation-reauthorization-modal"
                            visible={props.isResettingTwoFactorAuthentication}
                        >
                            <InfoMessage
                                message={props.t(`sharedMessages.users.resettingTwoFactorAuthentication`)}
                                showLoader={true}
                            />
                        </Modal>
                    </>
                )}
            </div>
        </div>
    );
};

// Prop types
Users.propTypes = {
    changeUserStatus: PropTypes.func,
    currentAccountId: PropTypes.string,
    defaultUserLogo: PropTypes.string,
    deleteUser: PropTypes.func,
    getUserAccountRoles: PropTypes.func,
    getUserRoles: PropTypes.func,
    getUsers: PropTypes.func,
    isAdmin: PropTypes.bool,
    isChangingUserStatus: PropTypes.bool,
    isResendingActivationEmail: PropTypes.bool,
    isResettingTwoFactorAuthentication: PropTypes.bool,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    organisationId: PropTypes.string,
    paginationUsers: PropTypes.object,
    path: PropTypes.string,
    resendActivationEmail: PropTypes.func,
    resetTwoFactorAuthentication: PropTypes.func,
    t: PropTypes.func,
    userDetails: PropTypes.object,
    userAccountRoles: PropTypes.array,
    userRoles: PropTypes.array,
    users: PropTypes.array,
};

// connect to store
export default connect(
    state => ({
        currentAccountId: state.account.currentAccountId,
        defaultUserLogo: get(state.settings.siteSettings, responseKeys.DEFAULT_USER_AVATAR_ORIGINAL),
        isAdmin: state.account.isAdmin,
        isChangingUserStatus: state.user.isChangingUserStatus,
        isResendingActivationEmail: state.user.isResendingActivationEmail,
        isResettingTwoFactorAuthentication: state.user.isResettingTwoFactorAuthentication,
        isLoading: state.user.isLoading,
        organisationId: state.organisation.selectedOrganisationId,
        paginationUsers: state.user.paginationUsers,
        roles: state.user.roles,
        userDetails: state.user.userDetails,
        userRoles: state.user.userRoles,
        userAccountRoles: state.user.userAccountRoles,
        users: state.user.users,
    }),
    {
        changeUserStatus,
        deleteUser,
        getUsers,
        getUserRoles,
        getUserAccountRoles,
        resendActivationEmail,
        resetTwoFactorAuthentication,
    }
)(withTranslation()(Users));

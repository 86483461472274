/**
 * On Demand campaign form
 * Renders the on demand campaign form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */
//import required modules
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
import { get, find, isEmpty } from 'lodash';

import Button from 'components/Button';
import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import FormField from 'components/shared/FormField';
import * as requestKeys from 'includes/constants/keys/request';
import EmailFields from 'components/shared/Templates/Email/EmailFields';
import IvrFields from 'components/shared/Templates/Ivr/IvrFields';
import SmsFields from 'components/shared/Templates/Sms/SmsFields';
import Preview from 'components/shared/Templates/Preview';
import {
    getCommunicationTemplateDetails,
    getCommunicationTemplatePreview,
} from 'includes/redux/actions/shared/communication';
import useAccountId from 'includes/hooks/useAccountId';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { ZENDESK_SETTINGS } from 'includes/constants/keys/response';
import ZendeskEventTypeFields from 'pages/Zendesk/Settings/components/Form/components/EventTypeFields';
import useAllowZendeskIntegration from 'includes/hooks/useAllowZendeskIntegration';
import { ZENDESK_EVENT_TYPE_FIELDS, ZENDESK_TAGS } from 'includes/constants/keys/request';
import useHasRequiredPermission from 'includes/hooks/useHasRequiredPermission';
import { ZENDESK } from 'includes/constants/permissions';
import useCommunicationTemplateTypes from 'includes/hooks/useCommunicationTemplateTypes';
import useCommunicationTypes from 'includes/hooks/useCommunicationTypes';
import useCommunicationTemplates from 'includes/hooks/useCommunicationTemplates';
import WhatsAppFields from 'components/shared/Templates/WhatsApp/WhatsAppFields';

const fieldValueSelector = formValueSelector(constants.ON_DEMAND_CAMPAIGN_FORM_NAME);

const OnDemandCampaign = reduxForm({
    form: constants.ON_DEMAND_CAMPAIGN_FORM_NAME,
})(props => {
    const [message, setMessage] = useState('');
    const [openPreview, setOpenPreview] = useState(false);
    const allowZendeskIntegration = useAllowZendeskIntegration();

    const isLoading = useSelector(state => state.communication.isLoadingDetails);
    const isLoadingPreview = useSelector(state => state.communication.isLoadingPreview);
    const isSendingOnDemandCampaign = useSelector(state => state.reminder.isSendingOnDemandCampaign);
    const isInvoiceUpdating = useSelector(state => state.invoices.isInvoiceUpdating);
    const isInvoiceAddLoading = useSelector(state => state.invoices.isInvoiceAddLoading);
    const preview = useSelector(state => state.communication.preview);
    const templateDetails = useSelector(state => state.communication.templateDetails);

    const { data: communicationTemplateTypes } = useCommunicationTemplateTypes();
    const { data: communicationTypes } = useCommunicationTypes();
    const { data: communicationTemplates } = useCommunicationTemplates();

    // values from form
    const notificationType = useSelector(state => fieldValueSelector(state, requestKeys.NOTIFICATION_TYPE_ID));
    const selectedNotificationTypeId = useSelector(state =>
        fieldValueSelector(state, requestKeys.NOTIFICATION_TYPE_ID)
    );
    const selectedTemplateTypeId = useSelector(state =>
        fieldValueSelector(state, requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID)
    );
    const selectedTemplateId = useSelector(state => fieldValueSelector(state, requestKeys.COMMUNICATION_TEMPLATE_ID));

    const organisationId = useOrganisationId();

    // fetch communication template details when `selectedTemplateId` changes
    useEffect(() => {
        if (selectedTemplateId) dispatch(getCommunicationTemplateDetails(organisationId, selectedTemplateId));
    }, [organisationId, selectedTemplateId]); // eslint-disable-line react-hooks/exhaustive-deps

    const dispatch = useDispatch();

    const accountId = useAccountId();

    const hasZendeskPermission = useHasRequiredPermission(ZENDESK.SETTINGS.VIEW);

    const { change, contactCC, customSubmitHandler, handleSubmit, sendButtonText } = props;

    // call back to change the current form values on template change
    const changeCurrentValues = useCallback(() => {
        change(requestKeys.SUBJECT, get(templateDetails, 'subject', ''));
        change(requestKeys.MESSAGE, get(templateDetails, 'message', ''));
        setMessage(get(templateDetails, 'message', ''));
    }, [templateDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    // effect to change form values on communication template change
    useEffect(() => {
        changeCurrentValues();
    }, [changeCurrentValues]);

    const { t } = useTranslation();

    /**
     * Get the locale
     *
     * @param {string} field Field for which locale is needed
     *
     * @returns {string} Localised content
     */
    const localeLabel = field => t(`customerMessages.campaign.onDemandCampaign.form.input.label.${field}`);

    /**
     * Returns the notification type slug
     *
     * @returns {string} notificationTypeSlug
     */
    const getNotificationTypeSlug = typeId => get(find(communicationTypes, { id: typeId }), 'slug', '');

    /**
     * Get the zendesk form fields by notification type
     */
    const getZendeskFormFieldsByNotificationType = () => {
        if (!hasZendeskPermission) return null;

        if (allowZendeskIntegration && selectedNotificationTypeId && selectedTemplateTypeId && selectedTemplateId) {
            const notificationTypeSlug = getNotificationTypeSlug(selectedNotificationTypeId);
            if (notificationTypeSlug) {
                return (
                    <>
                        <hr className="mrgn-tp-25" />
                        <div className="zendesk-event-type-settings-form-section">
                            <FormSection name={ZENDESK_SETTINGS}>
                                <ZendeskEventTypeFields eventType={notificationTypeSlug} includeTagsField />
                            </FormSection>
                        </div>
                    </>
                );
            }
        }
    };

    /**
     * Show the Preview of the current on demand campaign
     */
    const showPreview = () => {
        if (selectedTemplateTypeId && selectedTemplateId && message) {
            setOpenPreview(true);
            dispatch(
                getCommunicationTemplatePreview({
                    account_id: accountId,
                    contact_id: props.contactIds.length === 1 ? props.contactIds[0] : '',
                    invoice_id: props.invoiceIds.length === 1 ? props.invoiceIds[0] : '',
                    organisation_id: organisationId,
                    body: message,
                    type: getNotificationTypeSlug(selectedNotificationTypeId),
                })
            );
        }
    };

    /**
     * Get the form fields for email, ivr or sms
     */
    const getFormFields = () => {
        if (selectedNotificationTypeId && selectedTemplateTypeId && selectedTemplateId) {
            const type = getNotificationTypeSlug(selectedNotificationTypeId);
            const fieldProps = {
                change: change,
                disableBody: props.disableBody,
                isLoading: isLoading,
                message: message,
                setMessage: setMessage,
                showPreview: showPreview,
            };
            switch (type) {
                case 'email':
                    return <EmailFields {...fieldProps} contactCC={contactCC} />;
                case 'ivr':
                    return <IvrFields {...fieldProps} />;
                case 'sms':
                    return <SmsFields {...fieldProps} />;
                case 'whatsapp':
                    return <WhatsAppFields {...fieldProps} />;
                default:
                    return null;
            }
        }
    };

    const getSubmitButtonProps = () => {
        const buttonProps = {
            name: 'save',
            htmlType: 'submit',
            filled: true,
            className: 'green-bg-button',
            loading: isSendingOnDemandCampaign || isInvoiceUpdating || isInvoiceAddLoading,
            submittingButtonLabel: sendButtonText
                ? sendButtonText
                : t(`customerMessages.campaign.onDemandCampaign.form.submit.send.buttonSubmittingLabel`),
        };

        // if customSubmitHandler is present do not submit form, instead call the customSubmitHandler
        if (customSubmitHandler) {
            buttonProps.onClick = handleSubmit(customSubmitHandler);
            buttonProps.htmlType = 'button';
        }

        return buttonProps;
    };

    /**
     * Get the template types that has communication templates
     *
     * @returns {Array}  Template Types
     */
    const getTemplateTypesWithTemplates = () => {
        return (
            props.templateTypes ||
            communicationTemplateTypes
                .filter(x => {
                    const templateTypeTemplates = get(communicationTemplates, [selectedNotificationTypeId, x.id]);
                    return (
                        !isEmpty(templateTypeTemplates) &&
                        x.template_type === constants.COMMUNICATION_TEMPLATE_TYPE_INVOICE_TEMPLATE_TYPE
                    );
                })
                .map(x => {
                    return {
                        value: x.id,
                        name: x.type,
                    };
                })
        );
    };

    /**
     * Get zendesk settings form values
     */
    const setZendeskSettingsFormValues = () => {
        const zendeskInitialValues = {};
        if (allowZendeskIntegration && props.zendeskSettings && selectedNotificationTypeId) {
            if (props.zendeskSettings.connected) {
                change(ZENDESK_SETTINGS, {});
                const notificationTypeSlug = getNotificationTypeSlug(selectedNotificationTypeId);
                if (notificationTypeSlug) {
                    // eslint-disable-next-line array-callback-return
                    ZENDESK_EVENT_TYPE_FIELDS.map(eventTypeField => {
                        const fieldName = notificationTypeSlug + '_' + eventTypeField;
                        zendeskInitialValues[eventTypeField] = get(props.zendeskSettings, fieldName);
                    });
                    zendeskInitialValues[ZENDESK_TAGS] = get(props.zendeskSettings, ZENDESK_TAGS);
                    change(ZENDESK_SETTINGS, zendeskInitialValues);
                }
            }
        }
        return zendeskInitialValues;
    };

    return (
        <div className="edit-reminder-wrapper">
            <Form layout="vertical" className="" onSubmit={props.handleSubmit}>
                <Row gutter={16} className="send-to">
                    <b>To: </b>
                    {props.getToText(notificationType)}
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="select"
                            className="form-control"
                            name={requestKeys.NOTIFICATION_TYPE_ID}
                            label={localeLabel(requestKeys.NOTIFICATION_TYPE)}
                            options={communicationTypes.map(x => ({ value: x.id, name: x.type }))}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            onChange={value => {
                                if (getNotificationTypeSlug(value) === 'email') {
                                    change(
                                        requestKeys.CC,
                                        props.contactCC.map(c => c.email)
                                    );
                                } else {
                                    change(requestKeys.CC, undefined);
                                }
                                change(requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID, '');
                                change(requestKeys.COMMUNICATION_TEMPLATE_ID, '');
                                change(requestKeys.COMMUNICATION_TEMPLATE_ID, '');
                                change(requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID, '');
                                change(requestKeys.SUBJECT, '');
                                change(requestKeys.MESSAGE, '');
                                setMessage('');
                            }}
                        />
                    </Col>
                    {selectedNotificationTypeId && (
                        <Col span={12}>
                            <Field
                                type="select"
                                className="form-control"
                                name={requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID}
                                label={localeLabel(requestKeys.TEMPLATE_TYPE)}
                                hasFeedback
                                options={getTemplateTypesWithTemplates()}
                                component={FormField}
                                validate={[formValidations.required]}
                                onChange={() => {
                                    change(requestKeys.SUBJECT, '');
                                    change(requestKeys.MESSAGE, '');
                                    setMessage('');
                                    change(requestKeys.COMMUNICATION_TEMPLATE_ID, '');
                                }}
                            />
                        </Col>
                    )}
                </Row>
                <Row gutter={16}>
                    {selectedNotificationTypeId && selectedTemplateTypeId && (
                        <Col span={12}>
                            <Field
                                type="select"
                                className="form-control"
                                name={requestKeys.COMMUNICATION_TEMPLATE_ID}
                                label={localeLabel(requestKeys.COMMUNICATION_TEMPLATE)}
                                options={get(
                                    communicationTemplates,
                                    [selectedNotificationTypeId, selectedTemplateTypeId],
                                    []
                                ).map(x => ({
                                    value: x.id,
                                    name: x.name,
                                }))}
                                onChange={id => {
                                    if (!id) {
                                        change(requestKeys.SUBJECT, '');
                                        change(requestKeys.MESSAGE, '');
                                        setMessage('');
                                    }
                                    setZendeskSettingsFormValues();
                                }}
                                hasFeedback
                                component={FormField}
                                validate={[formValidations.required]}
                            />
                        </Col>
                    )}
                </Row>
                {getFormFields()}
                {getZendeskFormFieldsByNotificationType()}
                {selectedNotificationTypeId && selectedTemplateTypeId && selectedTemplateId && (
                    <Form.Item
                        className={`campaign-btn-wrapper ${props.error ? ' has-error default-form-app-error' : ''}`}
                    >
                        {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                        <Button {...getSubmitButtonProps()}>
                            {sendButtonText
                                ? sendButtonText
                                : t(`customerMessages.campaign.onDemandCampaign.form.submit.send.buttonLabel`)}
                        </Button>
                    </Form.Item>
                )}
            </Form>
            <Preview
                preview={preview}
                isLoadingPreview={isLoadingPreview}
                openPreview={openPreview}
                setOpenPreview={setOpenPreview}
            />
        </div>
    );
});

OnDemandCampaign.propTypes = {
    contactCC: PropTypes.arrayOf(PropTypes.object),
    contactIds: PropTypes.array,
    contactNames: PropTypes.array,
    customSubmitHandler: PropTypes.func,
    change: PropTypes.func,
    disable: PropTypes.bool,
    disableBody: PropTypes.bool,
    error: PropTypes.any,
    getToText: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    invoiceIds: PropTypes.array,
    sendButtonText: PropTypes.string,
    templateTypes: PropTypes.array,
    zendeskSettings: {},
};

export default OnDemandCampaign;

/**
 * Taxes listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import { Breadcrumb, Modal } from 'antd';
import { find, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import InfoMessage from 'components/shared/messages/InfoMessage';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import TaxesBreadCrumbItems from 'components/BreadCrumbItems/Taxes';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useTaxes from 'includes/hooks/useTaxes';
import useTaxTypes from 'includes/hooks/useTaxTypes';
import { deleteTax, getTaxes } from 'includes/slices/taxes';
import { getTaxTableColumns } from 'includes/tableColumns/taxes';
import { TABLE_NO_TAXES_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import { redirect } from 'includes/utils';
import PermissionGuard from 'components/PermissionGuard';
import { TAX } from 'includes/constants/permissions';

const { confirm } = Modal;

const TaxesList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    const { data: taxTypes, isLoading: loadingTaxTypes } = useTaxTypes();

    // check if user has updated the business name
    useUpdateBusinessName();

    const { reset: clearTaxesCache } = useTaxes(true);
    const organisationId = useOrganisationId();
    const { accountId } = params;
    const isAdmin = useSelector(state => state.account.isAdmin);
    const loadingTaxDelete = useSelector(state => state.taxes.loadingTaxDelete);
    const loadingTaxes = useSelector(state => state.taxes.loadingTaxes);
    const taxes = useSelector(state => state.taxes.taxes);
    const taxesPagination = useSelector(state => state.taxes.taxesPagination);
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    /**
     * Delete the tax item
     *
     * @param {string} taxId Tax Id
     */
    const deleteTaxItem = taxId => {
        confirm({
            title: t(`messages.taxes.confirm.delete.title`),
            content: '',
            onOk: () => {
                dispatch(deleteTax(taxId, organisationId, clearTaxesCache));
            },
        });
    };

    /**
     * Get the tax details add link
     *
     * @returns {string} Add Link
     */
    const getAddLink = () =>
        isAdmin ? `/admin/accounts/organisation/taxes/${accountId}/${organisationId}/add` : `/taxes/add`;

    /**
     * Get the locale text
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @returns {string} Locale test
     */
    const getLocaleText = path => t(`messages.taxes.${path}`);

    /**
     * Get the tax details view link
     *
     * @param {string} taxId Tax id
     *
     * @returns {string} View Link
     */
    const getViewLink = taxId =>
        isAdmin
            ? `/admin/accounts/organisation/taxes/tax/${accountId}/${organisationId}/${taxId}`
            : `/taxes/tax/${taxId}`;

    /**
     * Format the tax data
     */
    const formatTaxData = useCallback(
        () =>
            taxes.map(tax => {
                return {
                    ...tax,
                    name: (
                        <div className="business-name-avatar-wrapper">
                            <div className="action-wrapper">
                                {tax.name}
                                <span className="crud-wrapper">
                                    <PermissionGuard requiredPermission={TAX.VIEW}>
                                        <span onClick={() => redirect(getViewLink(tax.id))} className="crud-link">
                                            {getLocaleText('crud.view')}
                                        </span>
                                    </PermissionGuard>
                                    <PermissionGuard requiredPermission={TAX.DELETE}>
                                        <span onClick={() => deleteTaxItem(tax.id)} className="crud-link">
                                            {getLocaleText('crud.delete')}
                                        </span>
                                    </PermissionGuard>
                                </span>
                            </div>
                        </div>
                    ),
                    rate: tax.rate ? `${tax.rate}%` : '',
                    tax_type_id: get(find(taxTypes, { id: tax.tax_type_id }), 'type') || '',
                };
            }),
        [taxes, taxTypes] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Memoized tax data
     */
    const memoizedTaxData = useMemo(() => formatTaxData(), [formatTaxData]);

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
            <h2 className="page-title">{t(`titleAndMetas.taxes.title`)}</h2>
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <TaxesBreadCrumbItems showListBreadCrumb={isAdmin} />
                </Breadcrumb>
            </div>
            <div className="search-filter-export-wrapper contact-list-container full-wrapper box-wrapper white-box-wrapper">
                <section className="right-menu-wrapper">
                    <div className="right-align">
                        <PermissionGuard requiredPermission={TAX.ADD}>
                            <Button big filled onClick={() => redirect(getAddLink())}>
                                {getLocaleText('crud.add')}
                            </Button>
                        </PermissionGuard>
                    </div>
                </section>
                <ul className="selector-field show-elem invoices-wrapper">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>
                        <div className="invoices-search">
                            <SearchBlock
                                dataValues={[organisationId]}
                                dispatchMethod={true}
                                getDataMethod={getTaxes}
                                isSearching={isSearching}
                                placeholder={getLocaleText('search.placeholder')}
                                setIsSearching={setIsSearching}
                                setSearchParam={setSearchParam}
                            />
                        </div>
                    </li>
                </ul>
                <Table
                    className="table-1200"
                    columns={getTaxTableColumns}
                    dataSource={memoizedTaxData}
                    dataValues={[organisationId]}
                    dispatchMethod={true}
                    getDataMethod={getTaxes}
                    isSearching={isSearching}
                    loading={loadingTaxes || loadingTaxTypes}
                    localeMessage={TABLE_NO_TAXES_FOUND_MESSAGE}
                    mobileClassName="table-1200"
                    paginationData={taxesPagination}
                    rowClassName="pointer"
                    rowKey="id"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    size="middle"
                />
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={loadingTaxDelete}
                >
                    <InfoMessage message={t('messages.taxes.modal.message.delete')} showLoader={true} />
                </Modal>
            </div>
        </div>
    );
};

export default TaxesList;

/**
 * Action Type Constants
 * Specifies the action types for redux actions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */
//A
export const ACTIVATE_ACCOUNT_FAILURE = 'activateAccountFailure';
export const ACTIVATE_ACCOUNT_REQUEST = 'activateAccountRequest';
export const ACTIVATE_ACCOUNT_SUCCESS = 'activateAccountSuccess';
export const ADD_CAMPAIGN_FAILURE = 'addCampaignFailure';
export const ADD_CAMPAIGN_REQUEST = 'addCampaignRequest';
export const ADD_CAMPAIGN_SUCCESS = 'addCampaignSuccess';
export const ADD_COMMUNICATION_TEMPLATE_FAILURE = 'addCommunicationTemplateFailure';
export const ADD_COMMUNICATION_TEMPLATE_REQUEST = 'addCommunicationTemplateRequest';
export const ADD_COMMUNICATION_TEMPLATE_SUCCESS = 'addCommunicationTemplateSuccess';
export const ADD_COUPON_TO_ACCOUNT_FAILURE = 'addCouponToAccountFailure';
export const ADD_COUPON_TO_ACCOUNT_REQUEST = 'addCouponToAccountRequest';
export const ADD_COUPON_TO_ACCOUNT_SUCCESS = 'addCouponToAccountSuccess';
export const ADD_INVOICE_FAILURE = 'addInvoiceFailure';
export const ADD_INVOICE_PAYMENT_FAILURE = 'addInvoicePaymentFailure';
export const ADD_INVOICE_PAYMENT_REQUEST = 'addInvoicePaymentRequest';
export const ADD_INVOICE_PAYMENT_SUCCESS = 'addInvoicePaymentSuccess';
export const ADD_INVOICE_REQUEST = 'addInvoiceRequest';
export const ADD_INVOICE_SUCCESS = 'addInvoiceSuccess';
export const ADD_PLACEHOLDER_FAILURE = 'addPlaceholderFailure';
export const ADD_PLACEHOLDER_REQUEST = 'addPlaceholderRequest';
export const ADD_PLACEHOLDER_SUCCESS = 'addPlaceholderSuccess';
export const ADD_SUBSCRIPTION_PLAN_FAILURE = 'addSubscriptionPlanFailure';
export const ADD_SUBSCRIPTION_PLAN_REQUEST = 'addSubscriptionPlanRequest';
export const ADD_SUBSCRIPTION_PLAN_SUCCESS = 'addSubscriptionPlanSuccess';
export const ADD_USER_FAILURE = 'addUserFailure';
export const ADD_USER_REQUEST = 'addUserRequest';
export const ADD_USER_SUCCESS = 'addUserSuccess';
export const ADD_ACCOUNT_FAILURE = 'addAccountFailure';
export const ADD_ACCOUNT_REQUEST = 'addAccountRequest';
export const ADD_ACCOUNT_SUCCESS = 'addAccountSuccess';
export const APPLY_CONTACT_TYPE_FAILURE = 'applyContactTypeFailure';
export const APPLY_CONTACT_TYPE_REQUEST = 'applyContactTypeRequest';
export const APPLY_CONTACT_TYPE_SUCCESS = 'applyContactTypeSuccess';
export const APPLY_INVOICE_TYPE_FAILURE = 'applyInvoiceTypeFailure';
export const APPLY_INVOICE_TYPE_REQUEST = 'applyInvoiceTypeRequest';
export const APPLY_INVOICE_TYPE_SUCCESS = 'applyInvoiceTypeSuccess';
export const AUTHENTICATE_FAT_ZEBRA_FAILURE = 'applyInvoiceTypeFailure';
export const AUTHENTICATE_FAT_ZEBRA_REQUEST = 'applyInvoiceTypeRequest';
export const AUTHENTICATE_FAT_ZEBRA_SUCCESS = 'applyInvoiceTypeSuccess';

// C
export const CANCEL_ACCOUNT_SUBSCRIPTION_FAILURE = 'cancelAccountSubscriptionFailure';
export const CANCEL_ACCOUNT_SUBSCRIPTION_REQUEST = 'cancelAccountSubscriptionRequest';
export const CANCEL_ACCOUNT_SUBSCRIPTION_SUCCESS = 'cancelAccountSubscriptionSuccess';
export const CARD_ADD_REQUEST = 'cardAddRequest';
export const CARD_DELETE_FAILURE = 'cardDeleteFailure';
export const CARD_DELETE_REQUEST = 'cardDeleteRequest';
export const CARD_DELETE_SUCCESS = 'cardDeleteSuccess';
export const CHANGE_CAMPAIGN_STATUS_FAILURE = 'changeCampaignStatusFailure';
export const CHANGE_CAMPAIGN_STATUS_REQUEST = 'changeCampaignStatusRequest';
export const CHANGE_CAMPAIGN_STATUS_SUCCESS = 'changeCampaignStatusSuccess';
export const CHANGE_USER_STATUS_FAILURE = 'changeUserStatusFailure';
export const CHANGE_USER_STATUS_REQUEST = 'changeUserStatusRequest';
export const CHANGE_USER_STATUS_SUCCESS = 'changeUserStatusSuccess';
export const CLEAR_ALL_NOTIFICATIONS_REQUEST = 'clearAllNotificationsRequest';
export const CLEAR_CARD_UPDATE_MESSAGE = 'clearCardUpdateMessage';
export const CLEAR_CONNECT_STATE = 'clearConnectState';
export const CLEAR_FORGOT_USER_PASSWORD_MESSAGES = 'clearForgotUserPasswordMessages';
export const CLEAR_SUCCESS_MESSAGES_FROM_STORE = 'clearSuccessMessagesFromStore';
export const CONTACT_ADD_FAILURE = 'contactAddFailure';
export const CONTACT_ADD_REQUEST = 'contactAddRequest';
export const CONTACT_ADD_SUCCESS = 'contactAddSuccess';
export const CONTACT_PROFILE_UPDATE_FAILURE = 'contactProfileUpdateFailure';
export const CONTACT_PROFILE_UPDATE_REQUEST = 'contactProfileUpdateRequest';
export const CONTACT_PROFILE_UPDATE_SUCCESS = 'contactProfileUpdateSuccess';
export const CREATE_ORDER_FAILURE = 'createOrderFailure';
export const CREATE_ORDER_REQUEST = 'createOrderRequest';
export const CREATE_ORDER_SUCCESS = 'createOrderSuccess';
export const CREATE_SUBSCRIPTION_FOR_ACCOUNT_FAILURE = 'createSubscriptionForAccountFailure';
export const CREATE_SUBSCRIPTION_FOR_ACCOUNT_REQUEST = 'createSubscriptionForAccountRequest';
export const CREATE_SUBSCRIPTION_FOR_ACCOUNT_SUCCESS = 'createSubscriptionForAccountSuccess';

//D
export const DELETE_ALL_NOTIFICATIONS_REQUEST = 'deleteAllNotificationsRequest';
export const DELETE_COMMUNICATION_TEMPLATE_FAILURE = 'deleteCommunicationTemplateFailure';
export const DELETE_COMMUNICATION_TEMPLATE_REQUEST = 'deleteCommunicationTemplateRequest';
export const DELETE_COMMUNICATION_TEMPLATE_SUCCESS = 'deleteCommunicationTemplateSuccess';
export const DELETE_CONTACT_FAILURE = 'deleteContactFailure';
export const DELETE_CONTACT_REQUEST = 'deleteContactRequest';
export const DELETE_CONTACT_SUCCESS = 'deleteContactSuccess';
export const DELETE_EVENTS_FAILURE = 'deleteEventsFailure';
export const DELETE_EVENTS_REQUEST = 'deleteEventsRequest';
export const DELETE_EVENTS_SUCCESS = 'deleteEventsSuccess';
export const DELETE_INVOICE_FAILURE = 'deleteInvoiceFailure';
export const DELETE_INVOICE_PAYMENT_FAILURE = 'deleteInvoicePaymentFailure';
export const DELETE_INVOICE_PAYMENT_REQUEST = 'deleteInvoicePaymentRequest';
export const DELETE_INVOICE_PAYMENT_SUCCESS = 'deleteInvoicePaymentSuccess';
export const DELETE_INVOICE_REQUEST = 'deleteInvoiceRequest';
export const DELETE_INVOICE_SUCCESS = 'deleteInvoiceSuccess';
export const DELETE_PLACEHOLDER_FAILURE = 'deletePlaceholderFailure';
export const DELETE_PLACEHOLDER_REQUEST = 'deletePlaceholderRequest';
export const DELETE_PLACEHOLDER_SUCCESS = 'deletePlaceholderSuccess';
export const DELETE_SUBSCRIPTION_PLANS_FAILURE = 'deleteSubscriptionPlansFailure';
export const DELETE_SUBSCRIPTION_PLANS_REQUEST = 'deleteSubscriptionPlansRequest';
export const DELETE_SUBSCRIPTION_PLANS_SUCCESS = 'deleteSubscriptionPlansSuccess';
export const DELETE_USER_FAILURE = 'deleteUserFailure';
export const DELETE_USER_REQUEST = 'deleteUserRequest';
export const DELETE_USER_SUCCESS = 'deleteUserSuccess';
export const DOWNLOAD_EXPORTED_FILE_FAILURE = 'downloadExportedFileFailure';
export const DOWNLOAD_EXPORTED_FILE_REQUEST = 'downloadExportedFileRequest';
export const DOWNLOAD_EXPORTED_FILE_SUCCESS = 'downloadExportedFileSuccess';
export const DOWNLOAD_ORDER_INVOICE_FAILURE = 'downloadOrderInvoiceFailure';
export const DOWNLOAD_ORDER_INVOICE_REQUEST = 'downloadOrderInvoiceRequest';
export const DOWNLOAD_ORDER_INVOICE_SUCCESS = 'downloadOrderInvoiceSuccess';
export const DOWNLOAD_TEMPLATE_FAILURE = 'downloadTemplateFailure';
export const DOWNLOAD_TEMPLATE_REQUEST = 'downloadTemplateRequest';
export const DOWNLOAD_TEMPLATE_SUCCESS = 'downloadTemplateSuccess';

//E
export const EXPORT_ACCOUNT_ORGANISATIONS_FAILURE = 'exportAccountOrganisationsFailure';
export const EXPORT_ACCOUNT_ORGANISATIONS_REQUEST = 'exportAccountOrganisationsRequest';
export const EXPORT_ACCOUNT_ORGANISATIONS_SUCCESS = 'exportAccountOrganisationsSuccess';
export const EXPORT_ALL_INVOICE_PAYMENTS_FAILURE = 'exportAllInvoicePaymentsFailure';
export const EXPORT_ALL_INVOICE_PAYMENTS_REQUEST = 'exportAllInvoicePaymentsRequest';
export const EXPORT_ALL_INVOICE_PAYMENTS_SUCCESS = 'exportAllInvoicePaymentsSuccess';
export const EXPORT_ALL_SUBSCRIPTIONS_FAILURE = 'exportAllSubscriptionsFailure';
export const EXPORT_ALL_SUBSCRIPTIONS_REQUEST = 'exportAllSubscriptionsRequest';
export const EXPORT_ALL_SUBSCRIPTIONS_SUCCESS = 'exportAllSubscriptionsSuccess';
export const EXPORT_CONTACTS_FAILURE = 'exportContactsFailure';
export const EXPORT_CONTACTS_REQUEST = 'exportContactsRequest';
export const EXPORT_CONTACTS_SUCCESS = 'exportContactsSuccess';
export const EXPORT_INVOICE_PAYMENTS_FAILURE = 'exportInvoicePaymentsFailure';
export const EXPORT_INVOICE_PAYMENTS_REQUEST = 'exportInvoicePaymentsRequest';
export const EXPORT_INVOICE_PAYMENTS_SUCCESS = 'exportInvoicePaymentsSuccess';
export const EXPORT_INVOICES_FAILURE = 'exportInvoicesFailure';
export const EXPORT_INVOICES_REQUEST = 'exportInvoicesRequest';
export const EXPORT_INVOICES_SUCCESS = 'exportInvoicesSuccess';
export const EXPORT_ORGANISATION_FAILURE = 'exportOrganisationFailure';
export const EXPORT_ORGANISATION_REQUEST = 'exportOrganisationRequest';
export const EXPORT_ORGANISATION_SUCCESS = 'exportOrganisationSuccess';

// F
export const FILE_UPLOAD_ERROR = 'fileUploadError';
export const FORCE_RE_RENDER_COMPONENT_FAILURE = 'forceReRenderFailure';
export const FORCE_RE_RENDER_COMPONENT_REQUEST = 'forceReRenderRequest';
export const FORCE_RE_RENDER_COMPONENT_SUCCESS = 'forceReRenderSuccess';
export const FORGOT_USER_PASSWORD_FAILURE = 'forgotUserPasswordFailure';
export const FORGOT_USER_PASSWORD_REQUEST = 'forgotUserPasswordRequest';
export const FORGOT_USER_PASSWORD_SUCCESS = 'forgotUserPasswordSuccess';

// G
export const GET_ACCOUNT_COUPONS_FAILURE = 'getAccountCouponsFailure';
export const GET_ACCOUNT_COUPONS_REQUEST = 'getAccountCouponsRequest';
export const GET_ACCOUNT_COUPONS_SUCCESS = 'getAccountCouponsSuccess';
export const GET_ACCOUNT_DETAILS_FAILURE = 'getAccountDetailsFailure';
export const GET_ACCOUNT_DETAILS_FROM_INITIAL_DATA_SUCCESS = 'getAccountDetailsFromInitialDataSuccess';
export const GET_ACCOUNT_DETAILS_REQUEST = 'getAccountDetailsRequest';
export const GET_ACCOUNT_DETAILS_SUCCESS = 'getAccountDetailsSuccess';
export const GET_ACCOUNT_TYPES_FAILURE = 'getAccountTypesFailure';
export const GET_ACCOUNT_TYPES_REQUEST = 'getAccountTypesRequest';
export const GET_ACCOUNT_TYPES_SUCCESS = 'getAccountTypesSuccess';
export const GET_ACCOUNTING_SOFTWARE_AUTH_LINK_FAILURE = 'getAccountingSoftwareAuthLinkFailure';
export const GET_ACCOUNTING_SOFTWARE_AUTH_LINK_REQUEST = 'getAccountingSoftwareAuthLinkRequest';
export const GET_ACCOUNTING_SOFTWARE_AUTH_LINK_SUCCESS = 'getAccountingSoftwareAuthLinkSuccess';
export const GET_ACCOUNTING_SOFTWARE_FAILURE = 'getAccountingSoftwareFailure';
export const GET_ACCOUNTING_SOFTWARE_REQUEST = 'getAccountingSoftwareRequest';
export const GET_ACCOUNTING_SOFTWARE_SUCCESS = 'getAccountingSoftwareSuccess';
export const GET_ACCOUNTS_FAILURE = 'getAccountsFailure';
export const GET_ACCOUNTS_REQUEST = 'getAccountsRequest';
export const GET_ACCOUNTS_SUCCESS = 'getAccountsSuccess';
export const GET_ADMIN_DASHBOARD_DATA_FAILURE = 'getAdminDashboardDataFailure';
export const GET_ADMIN_DASHBOARD_DATA_REQUEST = 'getAdminDashboardDataRequest';
export const GET_ADMIN_DASHBOARD_DATA_SUCCESS = 'getAdminDashboardDataSuccess';
export const GET_ADMIN_SETTINGS_FAILURE = 'getAdminSettingsFailure';
export const GET_ADMIN_SETTINGS_REQUEST = 'getAdminSettingsRequest';
export const GET_ADMIN_SETTINGS_SUCCESS = 'getAdminSettingsSuccess';
export const GET_ALL_COMMUNICATION_TEMPLATES_FAILURE = 'getAllCommunicationTemplatesFailure';
export const GET_ALL_COMMUNICATION_TEMPLATES_REQUEST = 'getAllCommunicationTemplatesRequest';
export const GET_ALL_COMMUNICATION_TEMPLATES_SUCCESS = 'getAllCommunicationTemplatesSuccess';
export const GET_ALL_CONTACT_INVOICES_FAILURE = 'getAllContactInvoicesFailure';
export const GET_ALL_CONTACT_INVOICES_REQUEST = 'getAllContactInvoicesRequest';
export const GET_ALL_CONTACT_INVOICES_SUCCESS = 'getAllContactInvoicesSuccess';
export const GET_ALL_INVOICE_PAYMENTS_FAILURE = 'getAllInvoicePaymentsFailure';
export const GET_ALL_INVOICE_PAYMENTS_REQUEST = 'getAllInvoicePaymentsRequest';
export const GET_ALL_INVOICE_PAYMENTS_SUCCESS = 'getAllInvoicePaymentsSuccess';
export const GET_ALL_PERMISSIONS_FAILURE = 'getAllPermissionsFailure';
export const GET_ALL_PERMISSIONS_REQUEST = 'getAllPermissionsRequest';
export const GET_ALL_PERMISSIONS_SUCCESS = 'getAllPermissionsSuccess';
export const GET_ALL_PLACEHOLDERS_FAILURE = 'getAllPlaceholdersFailure';
export const GET_ALL_PLACEHOLDERS_REQUEST = 'getAllPlaceholdersRequest';
export const GET_ALL_PLACEHOLDERS_SUCCESS = 'getAllPlaceholdersSuccess';
export const GET_ARRANGEMENT_DUE_DATA_FAILURE = 'getArrangementDueDataFailure';
export const GET_ARRANGEMENT_DUE_DATA_REQUEST = 'getArrangementDueDataRequest';
export const GET_ARRANGEMENT_DUE_DATA_SUCCESS = 'getArrangementDueDataSuccess';
export const GET_BANK_ACCOUNT_DETAILS_FAILURE = 'getBankAccountDetailsFailure';
export const GET_BANK_ACCOUNT_DETAILS_REQUEST = 'getBankAccountDetailsRequest';
export const GET_BANK_ACCOUNT_DETAILS_SUCCESS = 'getBankAccountDetailsSuccess';
export const GET_CAMPAIGN_DAYS_FAILURE = 'getCampaignDaysFailure';
export const GET_CAMPAIGN_DAYS_REQUEST = 'getCampaignDaysRequest';
export const GET_CAMPAIGN_DAYS_SUCCESS = 'getCampaignDaysSuccess';
export const GET_CAMPAIGN_DETAILS_FAILURE = 'getCampaignDetailsFailure';
export const GET_CAMPAIGN_DETAILS_REQUEST = 'getCampaignDetailsRequest';
export const GET_CAMPAIGN_DETAILS_SUCCESS = 'getCampaignDetailsSuccess';
export const GET_CAMPAIGNS_FAILURE = 'getCampaignsFailure';
export const GET_CAMPAIGNS_REQUEST = 'getCampaignsRequest';
export const GET_CAMPAIGNS_SUCCESS = 'getCampaignsSuccess';
export const GET_CASH_COLLECTED_DATA_FAILURE = 'getCashCollectedDataFailure';
export const GET_CASH_COLLECTED_DATA_REQUEST = 'getCashCollectedDataRequest';
export const GET_CASH_COLLECTED_DATA_SUCCESS = 'getCashCollectedDataSuccess';
export const GET_COMMUNICATION_LOG_HISTORY_DETAILS_FAILURE = 'getCommunicationLogHistoryDetailsFailure';
export const GET_COMMUNICATION_LOG_HISTORY_DETAILS_REQUEST = 'getCommunicationLogHistoryDetailsRequest';
export const GET_COMMUNICATION_LOG_HISTORY_DETAILS_SUCCESS = 'getCommunicationLogHistoryDetailsSuccess';
export const GET_COMMUNICATION_LOG_HISTORY_FAILURE = 'getCommunicationLogHistoryFailure';
export const GET_COMMUNICATION_LOG_HISTORY_REQUEST = 'getCommunicationLogHistoryRequest';
export const GET_COMMUNICATION_LOG_HISTORY_SUCCESS = 'getCommunicationLogHistorySuccess';
export const GET_COMMUNICATION_LOG_STATUSES_FAILURE = 'getCommunicationLogStatusesFailure';
export const GET_COMMUNICATION_LOG_STATUSES_REQUEST = 'getCommunicationLogStatusesRequest';
export const GET_COMMUNICATION_LOG_STATUSES_SUCCESS = 'getCommunicationLogStatusesSuccess';
export const GET_COMMUNICATION_TEMPLATE_DETAILS_FAILURE = 'getCommunicationTemplateDetailsFailure';
export const GET_COMMUNICATION_TEMPLATE_DETAILS_REQUEST = 'getCommunicationTemplateDetailsRequest';
export const GET_COMMUNICATION_TEMPLATE_DETAILS_SUCCESS = 'getCommunicationTemplateDetailsSuccess';
export const GET_COMMUNICATION_TEMPLATE_PREVIEW_FAILURE = 'getCommunicationTemplatePreviewFailure';
export const GET_COMMUNICATION_TEMPLATE_PREVIEW_REQUEST = 'getCommunicationTemplatePreviewRequest';
export const GET_COMMUNICATION_TEMPLATE_PREVIEW_SUCCESS = 'getCommunicationTemplatePreviewSuccess';
export const GET_COMMUNICATION_TEMPLATE_TYPES_FAILURE = 'getCommunicationTemplateTypesFailure';
export const GET_COMMUNICATION_TEMPLATE_TYPES_REQUEST = 'getCommunicationTemplateTypesRequest';
export const GET_COMMUNICATION_TEMPLATE_TYPES_SUCCESS = 'getCommunicationTemplateTypesSuccess';
export const GET_COMMUNICATION_TEMPLATES_FAILURE = 'getCommunicationTemplatesFailure';
export const GET_COMMUNICATION_TEMPLATES_REQUEST = 'getCommunicationTemplatesRequest';
export const GET_COMMUNICATION_TEMPLATES_SUCCESS = 'getCommunicationTemplatesSuccess';
export const GET_COMMUNICATION_TYPES_FAILURE = 'getCommunicationTypesFailure';
export const GET_COMMUNICATION_TYPES_REQUEST = 'getCommunicationTypesRequest';
export const GET_COMMUNICATION_TYPES_SUCCESS = 'getCommunicationTypesSuccess';
export const GET_CONTACT_DETAILS_FAILURE = 'getContactDetailsFailure';
export const GET_CONTACT_DETAILS_REQUEST = 'getContactDetailsRequest';
export const GET_CONTACT_DETAILS_SUCCESS = 'getContactDetailsSuccess';
export const GET_CONTACT_INVOICE_STATUS_TYPE_DATA_FAILURE = 'getContactInvoiceStatusTypeDataFailure';
export const GET_CONTACT_INVOICE_STATUS_TYPE_DATA_REQUEST = 'getContactInvoiceStatusTypeDataRequest';
export const GET_CONTACT_INVOICE_STATUS_TYPE_DATA_SUCCESS = 'getContactInvoiceStatusTypeDataSuccess';
export const GET_CONTACT_INVOICES_FAILURE = 'getContactInvoicesFailure';
export const GET_CONTACT_INVOICES_REQUEST = 'getContactInvoicesRequest';
export const GET_CONTACT_INVOICES_SUCCESS = 'getContactInvoicesSuccess';
export const GET_CONTACT_ORDER_DETAILS_FAILURE = 'getContactOrderDetailsFailure';
export const GET_CONTACT_ORDER_DETAILS_REQUEST = 'getContactOrderDetailsRequest';
export const GET_CONTACT_ORDER_DETAILS_SUCCESS = 'getContactOrderDetailsSuccess';
export const GET_CONTACT_ORDER_STATUS_FAILURE = 'getContactOrderStatusFailure';
export const GET_CONTACT_ORDER_STATUS_REQUEST = 'getContactOrderStatusRequest';
export const GET_CONTACT_ORDER_STATUS_SUCCESS = 'getContactOrderStatusSuccess';
export const GET_CONTACT_TYPES_FAILURE = 'getContactTypesFailure';
export const GET_CONTACT_TYPES_REQUEST = 'getContactTypesRequest';
export const GET_CONTACT_TYPES_SUCCESS = 'getContactTypesSuccess';
export const GET_CONTACTS_FAILURE = 'getContactsFailure';
export const GET_CONTACTS_REQUEST = 'getContactsRequest';
export const GET_CONTACTS_SUCCESS = 'getContactsSuccess';
export const GET_COUPON_ACCOUNTS_FAILURE = 'getCouponAccountFailure';
export const GET_COUPON_ACCOUNTS_REQUEST = 'getCouponAccountRequest';
export const GET_COUPON_ACCOUNTS_SUCCESS = 'getCouponAccountSuccess';
export const GET_COUPONS_FAILURE = 'getCouponsFailure';
export const GET_COUPONS_REQUEST = 'getCouponsRequest';
export const GET_COUPONS_SUCCESS = 'getCouponsSuccewss';
export const GET_CURRENCIES_FAILURE = 'getCurrenciesFailure';
export const GET_CURRENCIES_REQUEST = 'getCurrenciesRequest';
export const GET_CURRENCIES_SUCCESS = 'getCurrenciesSuccess';
export const GET_DASHBOARD_DATA_FAILURE = 'getDashboardDataFailure';
export const GET_DASHBOARD_DATA_REQUEST = 'getDashboardDataRequest';
export const GET_DASHBOARD_DATA_SUCCESS = 'getDashboardDataSuccess';
export const GET_DEBT_AGE_DEBT_BAND_DATA_FAILURE = 'getDebtAgeDebtBandDataFailure';
export const GET_DEBT_AGE_DEBT_BAND_DATA_REQUEST = 'getDebtAgeDebtBandDataRequest';
export const GET_DEBT_AGE_DEBT_BAND_DATA_SUCCESS = 'getDebtAgeDebtBandDataSuccess';
export const GET_DEBTOR_ACCOUNTS_FAILURE = 'getDebtorAccountsFailure';
export const GET_DEBTOR_ACCOUNTS_REQUEST = 'getDebtorAccountsRequest';
export const GET_DEBTOR_ACCOUNTS_SUCCESS = 'getDebtorAccountsSuccess';
export const GET_EVENTS_FAILURE = 'getEventsFailure';
export const GET_EVENTS_REQUEST = 'getEventsRequest';
export const GET_EVENTS_SUCCESS = 'getEventsSuccess';
export const GET_EXPORTS_LIST_FAILURE = 'getExportsListFailure';
export const GET_EXPORTS_LIST_REQUEST = 'getExportsListRequest';
export const GET_EXPORTS_LIST_SUCCESS = 'getExportsListSuccess';
export const GET_FAQ_FAILURE = 'getFAQFailure';
export const GET_FAQ_REQUEST = 'getFAQRequest';
export const GET_FAQ_SUCCESS = 'getFAQSuccess';
export const GET_INITIAL_DATA_FAILURE = 'getInitialDataFailure';
export const GET_INITIAL_DATA_REQUEST = 'getInitialDataRequest';
export const GET_INITIAL_DATA_STOP_LOADING = 'getInitialDataStopLoading';
export const GET_INITIAL_DATA_SUCCESS = 'getInitialDataSuccess';
export const GET_INVOICE_DETAILS_FAILURE = 'getInvoiceDetailsFailure';
export const GET_INVOICE_DETAILS_REQUEST = 'getInvoiceDetailsRequest';
export const GET_INVOICE_DETAILS_SUCCESS = 'getInvoiceDetailsSuccess';
export const GET_INVOICE_PAYMENTS_FAILURE = 'getInvoicePaymentsFailure';
export const GET_INVOICE_PAYMENTS_REQUEST = 'getInvoicePaymentsRequest';
export const GET_INVOICE_PAYMENTS_SUCCESS = 'getInvoicePaymentsSuccess';
export const GET_INVOICE_PDF_DOWNLOAD_URL_FAILURE = 'getInvoicePdfDownloadUrlFailure';
export const GET_INVOICE_PDF_DOWNLOAD_URL_REQUEST = 'getInvoicePdfDownloadUrlRequest';
export const GET_INVOICE_PDF_DOWNLOAD_URL_SUCCESS = 'getInvoicePdfDownloadUrlSuccess';
export const GET_INVOICE_STATUSES_FAILURE = 'getInvoiceStatusesFailure';
export const GET_INVOICE_STATUSES_REQUEST = 'getInvoiceStatusesRequest';
export const GET_INVOICE_STATUSES_SUCCESS = 'getInvoiceStatusesSuccess';
export const GET_INVOICE_TYPES_FAILURE = 'getInvoiceTypeFailure';
export const GET_INVOICE_TYPES_REQUEST = 'getInvoiceTypeRequest';
export const GET_INVOICE_TYPES_SUCCESS = 'getInvoiceTypeSuccess';
export const GET_INVOICES_FAILURE = 'getInvoicesFailure';
export const GET_INVOICES_REQUEST = 'getInvoicesRequest';
export const GET_INVOICES_SUCCESS = 'getInvoicesSuccess';
export const GET_NOTIFICATIONS_FAILURE = 'getNotificationsFailure';
export const GET_NOTIFICATIONS_REQUEST = 'getNotificationsRequest';
export const GET_NOTIFICATIONS_SUCCESS = 'getNotificationsSuccess';
export const GET_ORDER_DETAILS_FAILURE = 'getOrderDetailsFailure';
export const GET_ORDER_DETAILS_REQUEST = 'getOrderDetailsRequest';
export const GET_ORDER_DETAILS_SUCCESS = 'getOrderDetailsSuccess';
export const GET_ORDER_STATUS_FAILURE = 'getOrderStatusFailure';
export const GET_ORDER_STATUS_REQUEST = 'getOrderStatusRequest';
export const GET_ORDER_STATUS_SUCCESS = 'getOrderStatusSuccess';
export const GET_ORDER_STATUSES_FAILURE = 'getOrderStatusesFailure';
export const GET_ORDER_STATUSES_REQUEST = 'getOrderStatusesRequest';
export const GET_ORDER_STATUSES_SUCCESS = 'getOrderStatusesSuccess';
export const GET_ORDER_TYPES_FAILURE = 'getOrderTypesFailure';
export const GET_ORDER_TYPES_REQUEST = 'getOrderTypesRequest';
export const GET_ORDER_TYPES_SUCCESS = 'getOrderTypesSuccess';
export const GET_ORDERS_FAILURE = 'getOrdersFailure';
export const GET_ORDERS_REQUEST = 'getOrdersRequest';
export const GET_ORDERS_SUCCESS = 'getOrdersSuccess';
export const GET_ORGANISATION_BY_ACCID_FAILURE = 'getOrganisationBYAccIdFailure';
export const GET_ORGANISATION_BY_ACCID_REQUEST = 'getOrganisationBYAccIdRequest';
export const GET_ORGANISATION_BY_ACCID_SUCCESS = 'getOrganisationBYAccIdSuccess';
export const GET_ORGANISATION_COUNTRIES_FAILURE = 'getOrganisationCountriesFailure';
export const GET_ORGANISATION_COUNTRIES_REQUEST = 'getOrganisationCountriesRequest';
export const GET_ORGANISATION_COUNTRIES_SUCCESS = 'getOrganisationCountriesSuccess';
export const GET_ORGANISATION_DETAILS_FAILURE = 'getOrganisationDetailsFailure';
export const GET_ORGANISATION_DETAILS_REQUEST = 'getOrganisationDetailsRequest';
export const GET_ORGANISATION_DETAILS_SUCCESS = 'getOrganisationDetailsSuccess';
export const GET_ORGANISATION_SETTINGS_FAILURE = 'getOrganisationSettingsFailure';
export const GET_ORGANISATION_SETTINGS_REQUEST = 'getOrganisationSettingsRequest';
export const GET_ORGANISATION_SETTINGS_SUCCESS = 'getOrganisationSettingsSuccess';
export const GET_ORGANISATIONS_FAILURE = 'getOrganisationsFailure';
export const GET_ORGANISATIONS_REQUEST = 'getOrganisationsRequest';
export const GET_ORGANISATIONS_SUCCESS = 'getOrganisationsSuccess';
export const GET_PAYMENT_ACCOUNTS_FAILURE = 'getPaymentAccountsFailure';
export const GET_PAYMENT_ACCOUNTS_REQUEST = 'getPaymentAccountsRequest';
export const GET_PAYMENT_ACCOUNTS_SUCCESS = 'getPaymentAccountsSuccess';
export const GET_PAYMENT_STATUSES_FAILURE = 'getPaymentStatusesFailure';
export const GET_PAYMENT_STATUSES_REQUEST = 'getPaymentStatusesRequest';
export const GET_PAYMENT_STATUSES_SUCCESS = 'getPaymentStatusesSuccess';
export const GET_PLACEHOLDERS_FAILURE = 'getPlaceholdersFailure';
export const GET_PLACEHOLDERS_REQUEST = 'getPlaceholdersRequest';
export const GET_PLACEHOLDERS_SUCCESS = 'getPlaceholdersSuccess';
export const GET_PLAN_ACCOUNTS_FAILURE = 'getPlanAccountsFailure';
export const GET_PLAN_ACCOUNTS_REQUEST = 'getPlanAccountsRequest';
export const GET_PLAN_ACCOUNTS_SUCCESS = 'getPlanAccountsSuccess';
export const GET_PLAN_QUOTES_FAILURE = 'getPlanQuotesFailure';
export const GET_PLAN_QUOTES_REQUEST = 'getPlanQuotesRequest';
export const GET_PLAN_QUOTES_SUCCESS = 'getPlanQuotesSuccess';
export const GET_REAUTHORIZE_ORGANISATIONS_COUNT_SUCCESS = 'getReauthorizeOrganisationsCountSuccess';
export const GET_REMINDER_DETAILS_FAILURE = 'getReminderDetailsFailure';
export const GET_REMINDER_DETAILS_REQUEST = 'getReminderDetailsRequest';
export const GET_REMINDER_DETAILS_SUCCESS = 'getReminderDetailsSuccess';
export const GET_REMINDER_TYPES_FAILURE = 'getReminderTypesFailure';
export const GET_REMINDER_TYPES_REQUEST = 'getReminderTypesRequest';
export const GET_REMINDER_TYPES_SUCCESS = 'getReminderTypesSuccess';
export const GET_REMINDERS_FAILURE = 'getRemindersFailure';
export const GET_REMINDERS_REQUEST = 'getRemindersRequest';
export const GET_REMINDERS_SUCCESS = 'getRemindersSuccess';
export const GET_SELECTED_ORGANISATION_SUCCESS = 'getSelectedOrganisationSuccess';
export const GET_SETTINGS_SUCCESS = 'getSettingsSuccess';
export const GET_SUBSCRIPTION_DETAILS_FAILURE = 'getSubscriptionDetailsFailure';
export const GET_SUBSCRIPTION_DETAILS_REQUEST = 'getSubscriptionDetailsRequest';
export const GET_SUBSCRIPTION_DETAILS_SUCCESS = 'getSubscriptionDetailsSuccess';
export const GET_SUBSCRIPTION_PLAN_DETAILS_FAILURE = 'getSubscriptionPlanDetailsFailure';
export const GET_SUBSCRIPTION_PLAN_DETAILS_REQUEST = 'getSubscriptionPlanDetailsRequest';
export const GET_SUBSCRIPTION_PLAN_DETAILS_SUCCESS = 'getSubscriptionPlanDetailsSuccess';
export const GET_SUBSCRIPTION_PLANS_FAILURE = 'getSubscriptionPlansFailure';
export const GET_SUBSCRIPTION_PLANS_REQUEST = 'getSubscriptionPlansRequest';
export const GET_SUBSCRIPTION_PLANS_SUCCESS = 'getSubscriptionPlansSuccess';
export const GET_SUBSCRIPTION_STATUSES_FAILURE = 'getSubscriptionStatusesFailure';
export const GET_SUBSCRIPTION_STATUSES_REQUEST = 'getSubscriptionStatusesRequest';
export const GET_SUBSCRIPTION_STATUSES_SUCCESS = 'getSubscriptionStatusesSuccess';
export const GET_SUBSCRIPTIONS_HISTORY_FAILURE = 'getSubscriptionsHistoryFailure';
export const GET_SUBSCRIPTIONS_HISTORY_REQUEST = 'getSubscriptionsHistoryRequest';
export const GET_SUBSCRIPTIONS_HISTORY_SUCCESS = 'getSubscriptionsHistorySuccess';
export const GET_TEMPLATE_TYPES_FAILURE = 'getTemplateTypesFailure';
export const GET_TEMPLATE_TYPES_REQUEST = 'getTemplateTypesRequest';
export const GET_TEMPLATE_TYPES_SUCCESS = 'getTemplateTypesSuccess';
export const GET_USER_ACCOUNT_DETAILS_FAILURE = 'getUserAccountDetailsFailure';
export const GET_USER_ACCOUNT_DETAILS_REQUEST = 'getUserAccountDetailsRequest';
export const GET_USER_ACCOUNT_DETAILS_SUCCESS = 'getUserAccountDetailsSuccess';
export const GET_USER_DETAILS_FAILURE = 'getUserDetailsFailure';
export const GET_USER_DETAILS_REQUEST = 'getUserDetailsRequest';
export const GET_USER_DETAILS_SUCCESS = 'getUserDetailsSuccess';
export const GET_USER_PROFILE_DETAILS_FAILURE = 'getUserProfileDetailsFailure';
export const GET_USER_PROFILE_DETAILS_FROM_INITIAL_DATA_SUCCESS = 'getUserProfileDetailsFromInitialDataSuccess';
export const GET_USER_PROFILE_DETAILS_REQUEST = 'getUserProfileDetailsRequest';
export const GET_USER_PROFILE_DETAILS_SUCCESS = 'getUserProfileDetailsSuccess';
export const GET_USER_ROLES = 'getUserRoles';
export const GET_USER_ROLES_FAILURE = 'getUserRolesFailure';
export const GET_USER_ROLES_SUCCESS = 'getUserRolesSuccess';
export const GET_USER_ACCOUNT_ROLES = 'getUserAccountRoles';
export const GET_USER_ACCOUNT_ROLES_FAILURE = 'getUserAccountRolesFailure';
export const GET_USER_ACCOUNT_ROLES_SUCCESS = 'getUserAccountRolesSuccess';
export const GET_USERS_FAILURE = 'getUsersFailure';
export const GET_USERS_REQUEST = 'getUsersRequest';
export const GET_USERS_SUCCESS = 'getUsersSuccess';

//I
export const IMPORT_CONTACTS_FAILURE = 'importContactsFailure';
export const IMPORT_CONTACTS_REQUEST = 'importContactsRequest';
export const IMPORT_CONTACTS_SUCCESS = 'importContactsSuccess';
export const IMPORT_INVOICES_FAILURE = 'importInvoicesFailure';
export const IMPORT_INVOICES_REQUEST = 'importInvoicesRequest';
export const IMPORT_INVOICES_SUCCESS = 'importInvoicesSuccess';
export const IMPORT_PAYMENTS_FAILURE = 'importPaymentsFailure';
export const IMPORT_PAYMENTS_REQUEST = 'importPaymentsRequest';
export const IMPORT_PAYMENTS_SUCCESS = 'importPaymentsSuccess';

// L
export const LOCAL_STORAGE_VALUE_CHANGE = 'localStorageValueChange';
export const LOCAL_STORAGE_VALUE_CHANGED = 'localStorageValueChanged';

// N
export const NOTIFICATION_MODAL_CLOSED = 'notificationModalClosed';

// O
export const ORGANISATION_ADD_FAILURE = 'organisationAddFailure';
export const ORGANISATION_ADD_REQUEST = 'organisationAddRequest';
export const ORGANISATION_ADD_SUCCESS = 'organisationAddSuccess';
export const ORGANISATION_DATA_FETCHING_COMPLETE = 'organisationDataFetchingComplete';
export const ORGANISATION_DELETE_FAILURE = 'organisationDeleteFailure';
export const ORGANISATION_DELETE_REQUEST = 'organisationDeleteRequest';
export const ORGANISATION_DELETE_SUCCESS = 'organisationDeleteSuccess';
export const ORGANISATION_REAUTHORIZATION_REQUIRED = 'organisationReauthorizationRequired';
export const ORGANISATION_SELECTED_ORGANISATION_DATA_FETCHING_UPDATE =
    'organisationSelectedOrganisationDataFetchingUpdate';
export const ORGANISATION_UPDATE_FAILURE = 'organisationUpdateFailure';
export const ORGANISATION_UPDATE_REQUEST = 'organisationUpdateRequest';
export const ORGANISATION_UPDATE_SUCCESS = 'organisationUpdateSuccess';

// R
export const RECEIVED_DATA_FROM_SERVER = 'receivedDataFromServer';
export const RECEIVED_NOTIFICATION_FROM_SERVER = 'receivedNotificationFromServer';
export const RECEIVED_NOTIFICATIONS_FROM_SERVER = 'receivedNotificationsFromServer';
export const REMOVE_COUPON_FROM_ACCOUNT_FAILURE = 'removeCouponFromAccountFailure';
export const REMOVE_COUPON_FROM_ACCOUNT_REQUEST = 'removeCouponFromAccountRequest';
export const REMOVE_COUPON_FROM_ACCOUNT_SUCCESS = 'removeCouponFromAccountSuccess';
export const REQUEST_QUOTE_FAILURE = 'requestQuoteFailure';
export const REQUEST_QUOTE_REQUEST = 'requestQuoteRequest';
export const REQUEST_QUOTE_SUCCESS = 'requestQuoteSuccess';
export const RESEND_ACTIVATION_EMAIL_FAILURE = 'resendActivationEmailFailure';
export const RESEND_ACTIVATION_EMAIL_REQUEST = 'resendActivationEmailRequest';
export const RESEND_ACTIVATION_EMAIL_SUCCESS = 'resendActivationEmailSuccess';
export const RESEND_VERIFICATION_EMAIL_FAILURE = 'resendVerificationEmailFailure';
export const RESEND_VERIFICATION_EMAIL_REQUEST = 'resendVerificationEmailRequest';
export const RESEND_VERIFICATION_EMAIL_SUCCESS = 'resendVerificationEmailSuccess';
export const RESEND_VERIFICATION_SMS_FAILURE = 'resendVerificationSmsFailure';
export const RESEND_VERIFICATION_SMS_REQUEST = 'resendVerificationSmsRequest';
export const RESEND_VERIFICATION_SMS_SUCCESS = 'resendVerificationSmsSuccess';
export const RESET_ADD_USER_ERRORS = 'resetAddUserErrors';
export const RESET_ERRORS = 'resetErrors';
export const RESET_ORGANISATION_RELATED_DATA = 'resetOrganisationRelatedData';
export const RESET_REMINDER_FILTER = 'resetReminderFilter';
export const RESET_REMINDER_TYPE_AND_DATE_FILTER = 'resetReminderTypeAndDateFilter';
export const RESET_SELECTED_ORGANISATION_REQUEST = 'resetSelectedOrganisationRequest';
export const RESET_TWO_FACTOR_AUTHENTICATION_REQUEST = 'resetTwoFactorAuthenticationRequest';
export const RESET_TWO_FACTOR_AUTHENTICATION_SUCCESS = 'resetTwoFactorAuthenticationSuccess';
export const RESET_TWO_FACTOR_AUTHENTICATION_FAILURE = 'resetTwoFactorAuthenticationFailure';
export const RESET_USER_ACCOUNT_DETAILS_REQUEST = 'resetUserAccountDetailsRequest';
export const RESTORE_CONTACT_FAILURE = 'restoreContactFailure';
export const RESTORE_CONTACT_REQUEST = 'restoreContactRequest';
export const RESTORE_CONTACT_SUCCESS = 'restoreContactSuccess';
export const RESTORE_USER_FAILURE = 'restoreUserFailure';
export const RESTORE_USER_REQUEST = 'restoreUserRequest';
export const RESTORE_USER_SUCCESS = 'restoreUserSuccess';
export const RETRY_ORDER_PAYMENT_FAILURE = 'retryOrderPaymentFailure';
export const RETRY_ORDER_PAYMENT_REQUEST = 'retryOrderPaymentRequest';
export const RETRY_ORDER_PAYMENT_SUCCESS = 'retryOrderPaymentSuccess';

// S
export const SAVE_EVENTS_FAILURE = 'saveEventsFailure';
export const SAVE_EVENTS_REQUEST = 'saveEventsRequest';
export const SAVE_EVENTS_SUCCESS = 'saveEventsSuccess';
export const SAVE_MANUAL_REMINDER_REQUEST = 'saveManualReminderRequest';
export const SAVE_SEND_MANUAL_REMINDER_REQUEST = 'saveSendManualReminderRequest';
export const SAVE_USER_CARD_FAILURE = 'saveUserCardFailure';
export const SAVE_USER_CARD_REQUEST = 'saveUserCardRequest';
export const SAVE_USER_CARD_SUCCESS = 'saveUserCardSuccess';
export const SEND_DATA_TO_SERVER = 'sendDataToServer';
export const SEND_ON_DEMAND_CAMPAIGN_FAILURE = 'sendOnDemandCampaignFailure';
export const SEND_ON_DEMAND_CAMPAIGN_REQUEST = 'sendOnDemandCampaignRequest';
export const SEND_ON_DEMAND_CAMPAIGN_SUCCESS = 'sendOnDemandCampaignSuccess';
export const SEND_PASSWORD_RESET_EMAIL = 'resetPasswordEmail';
export const SAVE_PAYMENT_ACCOUNT_FAILURE = 'savePaymentAccountFailure';
export const SAVE_PAYMENT_ACCOUNT_REQUEST = 'savePaymentAccountRequest';
export const SAVE_PAYMENT_ACCOUNT_SUCCESS = 'savePaymentAccountSuccess';
export const SAVE_ZENDESK_SETTINGS_FAILURE = 'saveZendeskSettingsFailure';
export const SAVE_ZENDESK_SETTINGS_REQUEST = 'saveZendeskSettingsRequest';
export const SAVE_ZENDESK_SETTINGS_SUCCESS = 'saveZendeskSettingsSuccess';
export const SEND_REMINDER_FAILURE = 'sendReminderFailure';
export const SEND_REMINDER_REQUEST = 'sendReminderRequest';
export const SEND_REMINDER_SUCCESS = 'sendReminderSuccess';
export const SEND_TEST_EMAIL_FAILURE = 'sendTestEmailFailure';
export const SEND_TEST_EMAIL_REQUEST = 'sendTestEmailRequest';
export const SEND_TEST_EMAIL_SUCCESS = 'sendTestEmailSuccess';
export const SET_ACCOUNT_PERMISSIONS = 'setAccountPermissions';
export const SET_CURRENT_ACCOUNT_ID = 'setCurrentAccountId';
export const SET_REMINDER_FILTER = 'setReminderFilter';
export const SET_SELECTED_ORGANISATION_AFTER_DELETE_SUCCESS = 'setSelectedOrganisationAfterDeleteSuccess';
export const SET_SELECTED_ORGANISATION_FAILURE = 'setSelectedOrganisationFailure';
export const SET_SELECTED_ORGANISATION_REQUEST = 'setSelectedOrganisationRequest';
export const SET_SELECTED_ORGANISATION_SUCCESS = 'setSelectedOrganisationSuccess';
export const SET_SUBSCRIPTION_PLAN_ID = 'setSubscriptionPlanId';
export const SET_USER_ACCOUNT_ID = 'setUserAccountId';
export const SHOW_APP_NOTIFICATION_MESSAGE = 'showAppNotificationMessage';
export const SHOW_NOTIFICATION_MODAL = 'showNotificationModal';
export const SIDEBAR_COLLAPSE_TOGGLE = 'sidebarCollapseToggle';
export const SWITCHING_ORGANISATION_REQUEST = 'switchingOrganisationRequest';
export const SYNC_ORGANISATION_COMPLETE = 'syncOrganisationComplete';
export const SYNC_ORGANISATION_FAILURE = 'syncOrganisationFailure';
export const SYNC_ORGANISATION_REQUEST = 'syncOrganisationRequest';
export const SYNC_ORGANISATION_SUCCESS = 'syncOrganisationSuccess';

// T
export const TRY_DEMO_COMPANY_FAILURE = 'tryDemoCompanyFailure';
export const TRY_DEMO_COMPANY_REQUEST = 'tryDemoCompanyRequest';
export const TRY_DEMO_COMPANY_SUCCESS = 'tryDemoCompanySuccess';

// U
export const UPDATE_ACCOUNT_DETAILS_FAILURE = 'updateAccountDetailsFailure';
export const UPDATE_ACCOUNT_DETAILS_REQUEST = 'updateAccountDetailsRequest';
export const UPDATE_ACCOUNT_DETAILS_SUCCESS = 'updateAccountDetailsSuccess';
export const UPDATE_ADMIN_SETTINGS_FAILURE = 'adminSettingsUpdateFailure';
export const UPDATE_ADMIN_SETTINGS_REQUEST = 'adminSettingsUpdateRequest';
export const UPDATE_ADMIN_SETTINGS_SUCCESS = 'adminSettingsUpdateSuccess';
export const UPDATE_ALL_NOTIFICATIONS_REQUEST = 'updateAllNotificationsRequest';
export const UPDATE_ALL_NOTIFICATIONS_SUCCESS = 'updateAllNotificationsSuccess';
export const UPDATE_BANK_ACCOUNT_DETAILS_FAILURE = 'updateBankAccountDetailsFailure';
export const UPDATE_BANK_ACCOUNT_DETAILS_REQUEST = 'updateBankAccountDetailsRequest';
export const UPDATE_BANK_ACCOUNT_DETAILS_SUCCESS = 'updateBankAccountDetailsSuccess';
export const UPDATE_CAMPAIGN_FAILURE = 'updateCampaignFailure';
export const UPDATE_CAMPAIGN_REQUEST = 'updateCampaignRequest';
export const UPDATE_CAMPAIGN_SUCCESS = 'updateCampaignSuccess';
export const UPDATE_CARD_FAILURE = 'updateCardFailure';
export const UPDATE_CARD_REQUEST = 'updateCardRequest';
export const UPDATE_CARD_SUCCESS = 'updateCardSuccess';
export const UPDATE_COMMUNICATION_TEMPLATE_FAILURE = 'updateCommunicationTemplateFailure';
export const UPDATE_COMMUNICATION_TEMPLATE_REQUEST = 'updateCommunicationTemplateRequest';
export const UPDATE_COMMUNICATION_TEMPLATE_SUCCESS = 'updateCommunicationTemplateSuccess';
export const UPDATE_INVOICE_FAILURE = 'updateInvoiceFailure';
export const UPDATE_INVOICE_PAYMENT_FAILURE = 'updateInvoicePaymentFailure';
export const UPDATE_INVOICE_PAYMENT_REQUEST = 'updateInvoicePaymentRequest';
export const UPDATE_INVOICE_PAYMENT_SUCCESS = 'updateInvoicePaymentSuccess';
export const UPDATE_INVOICE_REQUEST = 'updateInvoiceRequest';
export const UPDATE_INVOICE_SUCCESS = 'updateInvoiceSuccess';
export const UPDATE_NOTIFICATIONS_REQUEST = 'updateNotificationsRequest';
export const UPDATE_NOTIFICATIONS_SUCCESS = 'updateNotificationsSuccess';
export const UPDATE_ORDER_FAILURE = 'updateOrderFailure';
export const UPDATE_ORDER_REQUEST = 'updateOrderRequest';
export const UPDATE_ORDER_SUCCESS = 'updateOrderSuccess';
export const UPDATE_ORGANISATION_SETTINGS_FAILURE = 'updateOrganisationSettingsFailure';
export const UPDATE_ORGANISATION_SETTINGS_REQUEST = 'updateOrganisationSettingsRequest';
export const UPDATE_ORGANISATION_SETTINGS_SUCCESS = 'updateOrganisationSettingsSuccess';
export const UPDATE_PLACEHOLDER_FAILURE = 'updatePlaceholderFailure';
export const UPDATE_PLACEHOLDER_REQUEST = 'updatePlaceholderRequest';
export const UPDATE_PLACEHOLDER_SUCCESS = 'updatePlaceholderSuccess';
export const UPDATE_SUBSCRIPTION_PLAN_FAILURE = 'updateSubscriptionPlanFailure';
export const UPDATE_SUBSCRIPTION_PLAN_REQUEST = 'updateSubscriptionPlanRequest';
export const UPDATE_SUBSCRIPTION_PLAN_SUCCESS = 'updateSubscriptionPlanSuccess';
export const UPDATE_USER_ACCOUNT_DETAILS_SUCCESS = 'updateUserAccountDetailsSuccess';
export const USER_CHANGE_PASSWORD_FAILURE = 'changePasswordFailure';
export const USER_CHANGE_PASSWORD_REQUEST = 'changePasswordRequest';
export const USER_CHANGE_PASSWORD_SUCCESS = 'changePasswordSuccess';
export const USER_LOGIN_FAILED = 'userLoginFailed';
export const USER_LOGIN_REQUEST = 'userLoginRequest';
export const USER_LOGIN_SUCCESS = 'userLoginSuccess';
export const USER_LOGIN_SUCCESS_MULTIPLE_ACCOUNTS = 'userLoginSuccessMultipleAccounts';
export const USER_LOGOUT_REQUEST = 'userLogoutRequest';
export const USER_PROFILE_UPDATE_FAILURE = 'userProfileUpdateFailure';
export const USER_PROFILE_UPDATE_REQUEST = 'userProfileUpdateRequest';
export const USER_PROFILE_UPDATE_SUCCESS = 'userProfileUpdateSuccess';
export const USER_REGISTRATION_FAILED = 'userRegistrationFailure';
export const USER_REGISTRATION_MEETING_NOT_SCHEDULED = 'userRegistrationMeetingNotScheduled';
export const USER_REGISTRATION_REQUEST = 'userRegistrationRequest';
export const USER_REGISTRATION_SUCCESS = 'userRegistrationSuccess';
export const USER_RESET_PASSWORD_FAILURE = 'doPasswordResetFailure';
export const USER_RESET_PASSWORD_REQUEST = 'doPasswordResetRequest';
export const USER_RESET_PASSWORD_SUCCESS = 'doPasswordResetSuccess';
export const USER_SWITCH_ACCOUNT = 'userSwitchAccount';
export const USER_UPDATE_FAILURE = 'userUpdateSuccess';
export const USER_UPDATE_REQUEST = 'userUpdateRequest';
export const USER_UPDATE_SUCCESS = 'userUpdateSuccess';
export const USER_VALIDATE_RESET_PASSWORD_FAILURE = 'userValidatePasswordResetFailure';
export const USER_VALIDATE_RESET_PASSWORD_REQUEST = 'userValidatePasswordResetRequest';
export const USER_VALIDATE_RESET_PASSWORD_SUCCESS = 'userValidatePasswordResetSuccess';

// V
export const VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_FAILURE = 'verifyAccountingSoftwareAuthorizationFailure';
export const VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_REQUEST = 'verifyAccountingSoftwareAuthorizationRequest';
export const VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_SUCCESS = 'verifyAccountingSoftwareAuthorizationSuccess';
export const VERIFY_EMAIL_ADDRESS_FAILURE = 'verifyEmailAddressFailure';
export const VERIFY_EMAIL_ADDRESS_REQUEST = 'verifyEmailAddressRequest';
export const VERIFY_EMAIL_ADDRESS_SUCCESS = 'verifyEmailAddressSuccess';
export const VERIFY_ORGANISATION_ACCOUNTING_SOFTWARE_AUTHORIZATION_SUCCESS =
    'verifyOrganisationAccountingSoftwareAuthorizationSuccess';
export const VERIFY_PHONE_NUMBER_FAILURE = 'verifyPhoneNumberFailure';
export const VERIFY_PHONE_NUMBER_REQUEST = 'verifyPhoneNumberRequest';
export const VERIFY_PHONE_NUMBER_SUCCESS = 'verifyPhoneNumberSuccess';

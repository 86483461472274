import { Skeleton } from 'antd';
import { find, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { CARD_BRAND_NAME_MAP } from 'components/customer/PaymentInformation/constants';
import { MONTHLY, ORDER_TYPE, YEARLY } from 'includes/constants';
import useCountries from 'includes/hooks/useCountries';
import useCurrencies from 'includes/hooks/useCurrencies';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useSubscriptionPlans from 'includes/hooks/useSubscriptionPlan';
import { getOrderCharges } from 'includes/slices/orders';
import { getDefaultImageClass, getImageFromData } from 'includes/utils';
import { useOrderTypes } from 'includes/utils/hooks';
import ApplyCouponDialog from '../ApplyCouponDialog';
import CouponCode from '../CouponCode';
import './styles.scss';

const DEFAULT_COUNTRY_CODE = 'AU';

const OrderBreakdownEntry = ({ amount, currencyCode, description, netEffectSymbol = '+', subText }) => {
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    /**
     * Get number formatted with default currency
     *
     * @param {string|number} number Number
     *
     * @returns {string} Formatted number
     */
    const getNumberFormattedWithPlanCurrency = number =>
        getNumberFormattedAsCurrency(number, false, false, currencyCode);

    if (!amount) return null;

    return (
        <>
            <div className="flex justify-between">
                <div>
                    <div>
                        {description}
                        {subText ? <div className="text-sm text-gray-400">{subText}</div> : null}
                    </div>
                </div>

                <span className="right-align break-all ml-2">{`${netEffectSymbol} ${getNumberFormattedWithPlanCurrency(
                    amount
                )}`}</span>
            </div>
            <hr />
        </>
    );
};

OrderBreakdownEntry.propTypes = {
    amount: PropTypes.number,
    currencyCode: PropTypes.string,
    description: PropTypes.string,
    netEffectSymbol: PropTypes.oneOf(['+', '-', '']),
    subText: PropTypes.string,
};

/**
 * Retry order payment summary
 * The component fetches and renders the summary of an order
 *
 * @since 2.8.0
 */
export default function OrderSummary({
    appliedCoupon,
    paymentGatewayCountryId,
    paymentMethodCountryId,
    onApplyCoupon,
    paymentGatewayId,
    paymentMethodId,
    planName,
    planType,
    selectedPlan,
}) {
    const { t } = useTranslation();

    const [isCouponDialogOpen, setIsCouponDialogOpen] = useState(false);

    const [orderTypes] = useOrderTypes();

    const { data: subscriptionPlans } = useSubscriptionPlans();

    const orderCharges = useSelector(state => state.orders.orderCharges);

    const isGettingOrderCharges = useSelector(state => state.orders.isGettingOrderCharges);

    const coupons = useSelector(state => state.account.coupons);

    const { data: currencies } = useCurrencies();

    const dispatch = useDispatch();

    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    const { data: countries } = useCountries();

    const defaultCountry = useMemo(() => {
        if (countries.length === 0) return null;

        return find(countries, { code: DEFAULT_COUNTRY_CODE });
    }, [countries]);

    /**
     * Selected plans details
     */
    const selectedPlanDetails = useMemo(() => {
        if (!selectedPlan || !subscriptionPlans || subscriptionPlans.length === 0) return null;

        return find(subscriptionPlans, { id: selectedPlan });
    }, [selectedPlan, subscriptionPlans]);

    /**
     * Selected plan's currency code
     */
    const selectedPlanCurrencyCode = useMemo(() => {
        if (currencies.length === 0 || !selectedPlanDetails) return '';

        return get(find(currencies, { id: selectedPlanDetails?.currency_id }), 'code');
    }, [currencies, selectedPlanDetails]);

    /**
     * Get number formatted with default currency
     *
     * @param {string|number} number Number
     *
     * @returns {string} Formatted number
     */
    const getNumberFormattedWithPlanCurrency = number =>
        getNumberFormattedAsCurrency(number, false, false, selectedPlanCurrencyCode);

    const defaultPlanImage = require('assets/images/starter-plan.png');
    const planImage254Key = '_254x254';
    const planImagOriginalKey = '_ORIGINAL';

    useEffect(() => {
        const subscriptionOrderTypeId = get(find(orderTypes, { slug: ORDER_TYPE.SUBSCRIPTION }), 'id');
        if (!subscriptionOrderTypeId || !planType || !selectedPlan || !paymentGatewayId) return;

        dispatch(
            getOrderCharges(subscriptionOrderTypeId, {
                country_id: paymentGatewayCountryId ?? defaultCountry?.id,
                ...(paymentMethodId ? { payment_method_id: paymentMethodId } : {}),
                plan_type: (planType ?? '').toLowerCase(),
                plan_id: selectedPlan,
                payment_gateway_id: paymentGatewayId,
                ...(appliedCoupon ? { coupon_id: appliedCoupon } : {}),
            })
        );
    }, [
        appliedCoupon,
        defaultCountry,
        orderTypes,
        paymentGatewayId,
        paymentMethodId,
        planType,
        selectedPlan,
        paymentGatewayCountryId,
        paymentMethodCountryId,
    ]);

    const appliedCouponDetails = useMemo(() => (appliedCoupon ? find(coupons, { id: appliedCoupon }) : null), [
        appliedCoupon,
        coupons,
    ]);

    return (
        <>
            <ApplyCouponDialog
                currencyCode={selectedPlanCurrencyCode}
                isOpen={isCouponDialogOpen}
                onClose={() => setIsCouponDialogOpen(false)}
                onApply={coupon => {
                    onApplyCoupon(coupon);
                    setIsCouponDialogOpen(false);
                }}
                selectedCoupon={appliedCoupon}
            />
            <section className="text-left">
                <div className="flex md:flex-row justify-between">
                    <div>
                        <h5 className="uppercase font-semibold">{planName}</h5>
                        <h1 className="text-5xl mt-5 text-gray-500">
                            {!orderCharges?.amount ? (
                                <Skeleton title={false} paragraph={{ rows: 1, style: { height: '30px' } }} />
                            ) : (
                                getNumberFormattedWithPlanCurrency(orderCharges?.amount)
                            )}
                        </h1>
                        {planType === YEARLY && (
                            <div className="text-sm mt-2 text-gray-500">
                                ({getNumberFormattedWithPlanCurrency(selectedPlanDetails?.yearly_per_month_cost)} * 12
                                months)
                            </div>
                        )}
                    </div>
                    <img
                        className={
                            getDefaultImageClass(
                                selectedPlanDetails?.image,
                                planImage254Key,
                                planImagOriginalKey,
                                '210x210'
                            ) + ' logo logo-210 right-align hidden lg:block'
                        }
                        src={getImageFromData(
                            selectedPlanDetails?.image,
                            planImage254Key,
                            planImagOriginalKey,
                            defaultPlanImage
                        )}
                        alt="Plan"
                    />
                </div>
            </section>

            {isGettingOrderCharges || !orderCharges.amount ? (
                <div className="flex flex-col gap-8">
                    <Skeleton paragraph={{ rows: 1, width: '100%' }} title={false} />
                    <Skeleton paragraph={{ rows: 1, width: '100%' }} title={false} />
                    <Skeleton paragraph={{ rows: 1, width: '100%' }} title={false} />
                    <Skeleton paragraph={{ rows: 1, width: '100%' }} title={false} />
                </div>
            ) : (
                <div className="charges-split-up-wrapper mt-5">
                    <OrderBreakdownEntry
                        amount={orderCharges?.amount}
                        currencyCode={selectedPlanCurrencyCode}
                        description={t('sharedMessages.orders.details.label.subtotal')}
                        netEffectSymbol=""
                    />

                    <div className="">
                        {t('sharedMessages.orders.details.label.coupon')}
                        <button
                            type="button"
                            className="bg-blue-100 hover:bg-blue-200 transition-all duration-300 text-sm text-blue-600 py-1 px-2 rounded-3xl mx-2"
                            onClick={() => setIsCouponDialogOpen(true)}
                        >
                            {t(`sharedMessages.orders.details.label.${appliedCoupon ? 'changeCoupon' : 'applyCoupon'}`)}
                        </button>
                        {appliedCoupon ? (
                            <CouponCode
                                name={`${appliedCouponDetails.code} ${
                                    appliedCouponDetails.discount_percentage
                                        ? `(${appliedCouponDetails.discount_percentage}%)`
                                        : ''
                                }`}
                                onRemove={() => onApplyCoupon('')}
                            />
                        ) : null}
                        <span className="right-align">
                            {`- ${getNumberFormattedWithPlanCurrency(orderCharges?.coupon_discount)}`}
                        </span>
                    </div>
                    <hr />

                    <OrderBreakdownEntry
                        amount={orderCharges.service_charge}
                        currencyCode={selectedPlanCurrencyCode}
                        description={t('sharedMessages.orders.details.label.serviceCharge')}
                        subText={t('sharedMessages.orders.details.label.serviceChargeSubText', {
                            charge: orderCharges?.order_charges?.service_charge,
                        })}
                    />

                    <OrderBreakdownEntry
                        amount={orderCharges.transaction_charge_without_additional_charges}
                        currencyCode={selectedPlanCurrencyCode}
                        description={t('sharedMessages.orders.details.label.transactionCharge')}
                        subText={t('sharedMessages.orders.details.label.transactionChargeSubText', {
                            charge: orderCharges?.order_charges?.total_transaction_charge_without_additional_charges,
                        })}
                    />

                    <OrderBreakdownEntry
                        amount={orderCharges.additional_high_value_transaction_charge}
                        currencyCode={selectedPlanCurrencyCode}
                        description={t('sharedMessages.orders.details.label.highValueTransactionCharge')}
                        subText={t('sharedMessages.orders.details.label.highValueTransactionChargeSubText', {
                            amount: getNumberFormattedWithPlanCurrency(
                                orderCharges?.order_charges.minimum_high_value_transaction_amount
                            ),
                            percentage: orderCharges?.order_charges?.additional_high_value_transaction_charge,
                        })}
                    />

                    <OrderBreakdownEntry
                        amount={orderCharges.additional_payment_method_brand_transaction_charge}
                        currencyCode={selectedPlanCurrencyCode}
                        description={t('sharedMessages.orders.details.label.additionalTransactionCharge')}
                        subText={t('sharedMessages.orders.details.label.additionalTransactionChargeSubText', {
                            brand: CARD_BRAND_NAME_MAP[orderCharges.order_charges.brand_name] ?? '',
                            percentage: orderCharges.order_charges.additional_payment_method_brand_transaction_charge,
                        })}
                    />

                    <OrderBreakdownEntry
                        amount={orderCharges.gst}
                        currencyCode={selectedPlanCurrencyCode}
                        description={t('sharedMessages.orders.details.label.gst')}
                        subText={t('sharedMessages.orders.details.label.gstSubText', {
                            charge: orderCharges?.order_charges?.gst,
                        })}
                    />

                    <div className="payment-data text-lg font-semibold">
                        {t('sharedMessages.orders.details.label.total')}
                        <span className="right-align text-green-500 font-mono">
                            {getNumberFormattedWithPlanCurrency(orderCharges?.total_amount)}
                        </span>
                    </div>
                </div>
            )}
        </>
    );
}

OrderSummary.propTypes = {
    appliedCoupon: PropTypes.string,
    onApplyCoupon: PropTypes.func,
    paymentGatewayCountryId: PropTypes.string,
    paymentGatewayId: PropTypes.string,
    paymentMethodCountryId: PropTypes.string,
    paymentMethodId: PropTypes.string,
    planName: PropTypes.string,
    planType: PropTypes.oneOf([MONTHLY, YEARLY]),
    selectedPlan: PropTypes.string,
};

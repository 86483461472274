import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'components/Button';
import { PAYMENT_SERVICE_SLUG, PAYMENT_SERVICE } from 'components/PaymentGateways/constants';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import PaymentServiceFormModal from '../FormModal';
import { addPaymentService } from 'includes/slices/paymentGateways';
import { useTranslation } from 'react-i18next';
import OAuthConnectPaymentServiceModal from '../OAuthConnectPaymentServiceModal';
import PermissionGuard from 'components/PermissionGuard';
import { PAYMENT_GATEWAY } from 'includes/constants/permissions';

/**
 * Service Card component
 *
 * Renders service details and allows user register to the services
 *
 * @since 2.8.0
 */
export default function ServiceCard({
    isOauthEnabled,
    serviceName,
    slug,
    paymentServiceId,
    poweredBy,
    RenderFieldsComponent,
}) {
    const { t } = useTranslation();

    const [isVisibleOauthModal, setIsVisibleOauthModal] = useState(false);

    const [isAddPaymentServiceModalOpen, setIsAddPaymentServiceModalOpen] = useState(false);

    const dispatch = useDispatch();

    const isAdding = useSelector(state => state.paymentGateways.isAdding);

    const organisationId = useOrganisationId();

    /**
     * Handle setup button click
     *
     * opens OAuth modal if `isOauthEnabled` is true, else renders a setting form
     */
    const onClickSetup = () => {
        if (isOauthEnabled) {
            setIsVisibleOauthModal(true);
        } else {
            setIsAddPaymentServiceModalOpen(true);
        }
    };

    /**
     * Handle add button click event
     *
     * @param {object} values payment service details
     */
    const onAddSubmit = values => {
        dispatch(addPaymentService(organisationId, values, () => setIsAddPaymentServiceModalOpen(false)));
    };

    const paymentGateway = PAYMENT_SERVICE[slug];

    return (
        <div
            className="flex flex-col bg-white p-6 shadow-xl sm:max-w-lg rounded-lg min-w-min text-left gap-2"
            style={{ borderTop: `5px solid ${paymentGateway?.accentColor}` }}
        >
            <div className="flex flex-row justify-between items-center mb-2">
                <div>
                    <h4>{serviceName}</h4>
                    <div className="text-xs text-gray-400 mt-1">{poweredBy}</div>
                </div>
                <div>
                    <img src={paymentGateway?.logo} style={{ height: 50, width: 50 }} />
                </div>
            </div>

            <p className="font-thin text-gray-400 pt-2">{paymentGateway.description}</p>

            <div className="flex">
                <PermissionGuard requiredPermission={PAYMENT_GATEWAY.ADD}>
                    <Button filled onClick={onClickSetup}>
                        Setup
                    </Button>
                </PermissionGuard>
            </div>

            <OAuthConnectPaymentServiceModal
                description={paymentGateway.connectDescription}
                loginLabel={paymentGateway.loginLabel}
                signUpLabel={paymentGateway.signupLabel}
                serviceId={paymentServiceId}
                setIsOpen={setIsVisibleOauthModal}
                title={paymentGateway.connectTitle}
                visible={isVisibleOauthModal}
            />

            <PaymentServiceFormModal
                loading={isAdding}
                initialValues={{
                    parent_gateway_id: paymentServiceId,
                    is_active: true,
                    pay_button_text: 'Pay now',
                    ...(paymentGateway.defaultPaymentLabel ? { label: paymentGateway.defaultPaymentLabel } : {}),
                    ...(paymentGateway.defaultSetupLabel ? { setup_label: paymentGateway.defaultSetupLabel } : {}),
                }}
                open={isAddPaymentServiceModalOpen}
                onCancel={() => setIsAddPaymentServiceModalOpen(false)}
                onOk={onAddSubmit}
                okLabel={t('customerMessages.paymentServices.form.action.add')}
                paymentServiceSlug={slug}
                provider={null}
                RenderFieldsComponent={RenderFieldsComponent}
            />
        </div>
    );
}

ServiceCard.propTypes = {
    description: PropTypes.string,
    isOauthEnabled: PropTypes.bool,
    onAddSubmit: PropTypes.func,
    paymentServiceId: PropTypes.string,
    poweredBy: PropTypes.string,
    RenderFieldsComponent: PropTypes.elementType,
    serviceName: PropTypes.string,
    slug: PropTypes.oneOf(Object.values(PAYMENT_SERVICE_SLUG)),
};

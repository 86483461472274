import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

const CacheContext = createContext({});

const CacheProvider = ({ children }) => {
    const [state, dispatch] = useReducer((state, action) => {
        const { payload } = action;
        switch (action.type) {
            case 'SET': {
                return {
                    ...state,
                    [payload.key]: payload.value,
                };
            }
            case 'REMOVE': {
                const newState = { ...state };
                delete newState[payload.key];
                return newState;
            }
            case 'REMOVE_ALL': {
                return {};
            }
            default:
                return { ...state };
        }
    }, {});

    const getCachedValue = key => state[key];

    const setCache = (key, value) => dispatch({ type: 'SET', payload: { key, value } });

    const clearCache = key => {
        if (key) dispatch({ type: 'REMOVE', payload: { key } });
        else dispatch({ type: 'REMOVE_ALL' });
    };

    return (
        <CacheContext.Provider
            value={{
                getCachedValue,
                setCache,
                clearCache,
                state,
            }}
        >
            {children}
        </CacheContext.Provider>
    );
};

CacheProvider.propTypes = {
    children: PropTypes.node,
};

const useCacheContext = () => {
    const context = useContext(CacheContext);

    if (context === undefined) {
        throw new Error('useAppContext must be used with an CacheProvider');
    }

    return context;
};

export { CacheProvider, useCacheContext };

import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Renders inactive badge if a payment service is inactive
 *
 * @since 2.8.0
 */
export default function PaymentServiceInactiveBadge() {
    const { t } = useTranslation();

    return (
        <span className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded w-min bg-gray-300 text-gray-500`}>
            {t(`customerMessages.paymentServices.status.inactive`)}
        </span>
    );
}

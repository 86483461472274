/**
 * Form field component renders Antd components based on type
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input';
import {
    Input,
    Form,
    Select,
    DatePicker,
    TimePicker,
    Checkbox,
    AutoComplete,
    Button as AntButton,
    Slider,
    Switch,
    Radio,
    Tooltip,
    InputNumber,
} from 'antd';
import 'react-phone-number-input/style.css';
import { Icon } from 'antd';
import { get, isString, isEmpty, groupBy } from 'lodash';

import UploadInput from './upload';
import ImagePicker from '../ImagePicker';
import Button from '../Button';
import './styles.scss';
import { PHONE_NUMBER_ALLOWED_COUNTRIES } from 'includes/constants';

const { Option } = Select;
const { RangePicker } = DatePicker;
let imageUrl = '';

/**
 * Get image source
 *
 * @param       {any}    image   image source passed
 *
 * @returns     {any}    image source object URL or URL from server
 */
const getImageSource = image => {
    if (image.value && isString(image.value)) {
        imageUrl = image.value;
    } else if (image.value instanceof File) {
        window.URL.revokeObjectURL(imageUrl);
        imageUrl = window.URL.createObjectURL(image.value);
    } else {
        imageUrl = get(
            image.value,
            image.imageSourceSizeKey,
            get(image.value, image.imageSourceOriginalKey, image.defaultImage)
        );
    }

    return imageUrl;
};

/**
 * Check if the default image is loaded
 *
 * @param       {any}    image   image source passed
 *
 * @returns     {boolean}           true|false
 */
const isDefaultImageLoaded = image => {
    if (image.value && isString(image.value)) {
        imageUrl = image.value;
    } else if (image.value instanceof File) {
        window.URL.revokeObjectURL(imageUrl);
        imageUrl = window.URL.createObjectURL(image.value);
    } else {
        imageUrl = get(image.value, image.imageSourceSizeKey, get(image.value, image.imageSourceOriginalKey));
    }
    return !imageUrl;
};

const getDate = (val, format = 'DD-MM-YYYY') => {
    if (val) {
        if (isString(val)) return moment(val, format);
        else return val;
    } else return null;
};

const renderAutoCompleteOptions = options =>
    options.map(({ value, name }) => (
        <AutoComplete.Option key={value} value={value}>
            {name}
        </AutoComplete.Option>
    ));

/**
 * Get input component based on the type passed
 *
 * @param       {string}    type        type of the input componentto be rendered
 * @param       {object}    inputProps  props to be passed to the input component
 *
 * @returns     {any}       input component based on type
 */
const getInput = (type, inputProps) => {
    let component;
    let { reduxChange, ...restProps } = inputProps;
    switch (type) {
        case 'textarea':
            component = <Input.TextArea {...inputProps} />;
            break;
        case 'password':
            component = <Input.Password {...inputProps} />;
            break;
        case 'file':
            component = <UploadInput {...inputProps} />;
            break;
        case 'select': {
            const isMultiple = ['multiple', 'tags'].includes(get(inputProps, 'mode', ''));
            if (isMultiple && typeof inputProps.value === 'string') {
                inputProps.value = [];
            }
            component = (
                <Select
                    {...inputProps}
                    className={inputProps.className ? `${inputProps.className} formfield-select` : 'formfield-select'}
                >
                    {!['multiple', 'tags'].includes(get(inputProps, 'mode', '')) && !inputProps.disableEmptyOption && (
                        <Option value="">--Select--</Option>
                    )}
                    {inputProps.options.map(({ value, name }) => (
                        <Option key={value} value={value}>
                            {name}
                        </Option>
                    ))}
                </Select>
            );
            break;
        }
        case 'date': {
            const value = getDate(inputProps.value, 'DD-MM-YYYY');
            component = (
                <DatePicker
                    {...inputProps}
                    value={value}
                    onChange={value => {
                        reduxChange && reduxChange(inputProps.name, value);
                    }}
                    allowClear={true}
                    format="DD-MM-YYYY"
                />
            );
            break;
        }
        case 'dateRange': {
            component = (
                <RangePicker
                    {...inputProps}
                    onChange={value => {
                        reduxChange && reduxChange(inputProps.name, value);
                    }}
                    format="DD-MM-YYYY"
                    allowClear={true}
                />
            );
            break;
        }
        case 'time':
            // eslint-disable-next-line no-case-declarations
            const value = getDate(inputProps.value, inputProps.timeFormat);
            component = (
                <TimePicker
                    {...inputProps}
                    value={value}
                    onChange={value => {
                        reduxChange && reduxChange(inputProps.name, value.format(inputProps.timeFormat));
                    }}
                    format={inputProps.timeFormat}
                    allowClear
                />
            );
            break;
        case 'phone':
            component = (
                <PhoneInput
                    {...restProps}
                    onChange={value => {
                        reduxChange && reduxChange(inputProps.name, value || '');
                    }}
                    countries={get('countries', restProps, PHONE_NUMBER_ALLOWED_COUNTRIES)}
                    international
                />
            );
            break;
        case 'avatar':
            component = (
                <div className="profile-pic-edit">
                    <div
                        className={
                            'circular--landscape-big ' +
                            (isDefaultImageLoaded(inputProps) ? ' is-default-image-loaded' : '')
                        }
                    >
                        <img src={getImageSource(inputProps)} alt="profile-pic" />
                    </div>
                    {(!isEmpty(inputProps.value) || inputProps.value instanceof Blob) && (
                        <AntButton
                            type="link"
                            onClick={() => {
                                reduxChange(inputProps.name, null);
                                reduxChange(inputProps.hasRemovedKey, true);
                            }}
                        >
                            <Icon type="delete" /> Remove
                        </AntButton>
                    )}
                    <Button className="edit-add-profile-pic" onClick={inputProps.toggleImagePicker}>
                        Change
                        <br />
                        {inputProps.imageName}
                    </Button>
                    <ImagePicker
                        visible={inputProps.isModalVisible}
                        onSubmit={file => {
                            reduxChange(inputProps.hasRemovedKey, false);
                            inputProps.onSubmit(file);
                        }}
                        onCancel={inputProps.toggleImagePicker}
                    />
                </div>
            );
            break;
        case 'image':
            component = (
                <div className="admin-pic-edit">
                    <label>Choose {inputProps.imageName}</label>
                    <div className="wrapper">
                        <img src={getImageSource(inputProps)} alt="profile-pic" />
                        <Button className="edit-add-profile-pic" onClick={inputProps.toggleImagePicker}>
                            Choose
                        </Button>
                        <ImagePicker
                            visible={inputProps.isModalVisible}
                            onSubmit={inputProps.onSubmit}
                            onCancel={inputProps.toggleImagePicker}
                        />
                    </div>
                </div>
            );
            break;
        case 'checkbox':
            component = <Checkbox {...inputProps}>{inputProps.label}</Checkbox>;
            break;
        case 'radio':
            component = (
                <Radio.Group {...inputProps} buttonStyle="solid" defaultValue={inputProps.defaultValue} size="large">
                    {inputProps.options.map(({ value, name }) => (
                        <Radio.Button key={value} value={value}>
                            {name}
                        </Radio.Button>
                    ))}
                </Radio.Group>
            );
            break;
        case 'autocomplete': {
            let options = [];
            if (inputProps.groupBy) {
                const grouped = groupBy(inputProps.options, inputProps.groupBy);
                Object.entries(grouped).forEach(([type, values]) => {
                    options.push(
                        <AutoComplete.OptGroup key={type} label={type}>
                            {renderAutoCompleteOptions(values)}
                        </AutoComplete.OptGroup>
                    );
                });
            } else {
                options = renderAutoCompleteOptions(inputProps.options);
            }
            component = <AutoComplete {...inputProps}>{options}</AutoComplete>;
            break;
        }
        case 'switch': {
            if (get(inputProps, 'value', false)) {
                inputProps.defaultChecked = true;
            }
            component = <Switch {...inputProps} />;
            break;
        }
        case 'slider':
            component = (
                <Slider
                    {...inputProps}
                    onChange={value => {
                        reduxChange && reduxChange(inputProps.name, value);
                    }}
                />
            );
            break;
        case 'inputNumber':
            component = <InputNumber {...inputProps} />;
            break;
        case 'text':
        default:
            component = <Input {...inputProps} type={type} />;
    }
    return component;
};
/**
 * Show the field info message
 *
 * @param {string} info Info Message
 * @param {boolean} infoAsToolTip Whether to show info as tooltip. Default false
 */
const showFieldInfoMessage = (info, infoAsToolTip = false) => {
    return info ? (
        infoAsToolTip ? (
            <Tooltip title={info}>
                <span style={{ marginLeft: 5, color: '#92959a' }}>
                    <Icon type="info-circle" theme="filled" />
                </span>
            </Tooltip>
        ) : (
            <p style={{ marginTop: 2, fontSize: 12 }}>{info}</p>
        )
    ) : null;
};

const FormField = ({
    input,
    label,
    type,
    hasFeedback,
    className,
    options,
    loading,
    dynamicField,
    info,
    infoAsToolTip,
    required,
    meta: { touched, error, warning },
    ...restProps
}) => {
    const fieldError = (touched || dynamicField) && (error || warning);
    const validateStatus = fieldError ? 'error' : touched ? 'success' : loading ? 'validating' : '';
    return (
        <>
            {!['avatar', 'checkbox'].includes(type) ? (
                <>
                    {label ? (
                        <label htmlFor="name">
                            {label}
                            {required ? <span className="required-star">*</span> : null}
                            {infoAsToolTip ? showFieldInfoMessage(info, infoAsToolTip) : null}
                        </label>
                    ) : null}
                    <Form.Item validateStatus={validateStatus} help={fieldError} hasFeedback={hasFeedback}>
                        {getInput(type, { input, ...input, ...restProps, options, className })}
                        {!infoAsToolTip ? showFieldInfoMessage(info) : null}
                    </Form.Item>
                </>
            ) : (
                <>
                    {getInput(type, { input, ...input, ...restProps, options, className, label })}
                    {!infoAsToolTip ? showFieldInfoMessage(info) : null}
                </>
            )}
        </>
    );
};

FormField.propTypes = {
    className: PropTypes.string,
    dynamicField: PropTypes.bool,
    hasFeedback: PropTypes.bool,
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    infoAsToolTip: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.string,
    loading: PropTypes.bool,
    meta: PropTypes.object,
    options: PropTypes.arrayOf(PropTypes.object),
    required: PropTypes.bool,
    type: PropTypes.string,
};

FormField.defaultProps = {
    options: [],
};

export default FormField;

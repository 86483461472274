import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

export default function PageTitle({ children }) {
    return (
        <div className="flex justify-center">
            <h2 className="page-title-header inline-block">{children}</h2>
        </div>
    );
}

PageTitle.propTypes = {
    children: PropTypes.string,
};

/**
 * Defines success messages used across the app
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */
//import translation module to translate success messages based on language selection
import i18n from '../../i18n';

//A
export const ACCOUNTING_SOFTWARE_INTEGRATION_SUCCESS = i18n.t('successMessages.accountingSoftwareIntegrationSuccess');

// R
export const REQUEST_PROCESSED_SUCCESSFULLY = i18n.t('successMessages.requestProcessedSuccessfully');

//U
export const USER_LOGGED_IN_SUCCESS = i18n.t('successMessages.userLoginSuccess');
export const USER_LOGGED_OUT_SUCCESS = i18n.t('successMessages.userLogoutSuccess');
export const USER_REGISTRATION_SUCCESS = i18n.t('successMessages.userRegistrationSuccess');
export const USER_REGISTRATION_WITH_ACCOUNTING_SOFTWARE_SUCCESS = i18n.t(
    'successMessages.userRegistrationWithAccountingSoftwareSuccess'
);

// V
export const VERIFY_PHONE_NUMBER_SUCCESS = i18n.t('successMessages.verifyPhoneNumberSuccess');

/**
 * Coupons component
 * Handles the coupons view and functions related to accounts
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

// import required modules
import { React, connect, Link, PropTypes, useCallback, useMemo, useState } from '../../../includes/exports/react';
import * as errorMessages from '../../../includes/constants/messages/errors';
import SearchBlock from '../../shared/SearchBlock';
import Table from '../../shared/ScrollableTable';
import { COUPONS_TABLE_COLUMNS } from '../../../includes/constants/columns';
import { getCoupons } from '../../../includes/redux/actions/shared/account';
import { withTranslation } from 'react-i18next';

/**
 * Coupons component
 */
const Coupons = props => {
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const { coupons } = props;

    /**
     * Format the coupons data
     */
    const formatCouponsData = useCallback(
        () =>
            coupons.map(coupon => ({
                ...coupon,
                code: (
                    <div className="wrapped-content-text">
                        {coupon.code}
                        <span className="crud-wrapper">
                            <Link className="crud-link" to={`/admin/coupons/${coupon.id}`}>
                                View Accounts
                            </Link>
                            <Link className="crud-link" to={`/admin/coupons/add-accounts/${coupon.id}`}>
                                Add Accounts
                            </Link>
                        </span>
                    </div>
                ),
            })),
        [coupons]
    );

    /**
     * Get memoized coupon data
     */
    const memoizedCouponData = useMemo(() => formatCouponsData(), [formatCouponsData]);

    return (
        <div className="home-content-wrapper accounts-wrapper">
            <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                <section>
                    <ul className="selector-field">
                        <li>
                            <div className="right-align">
                                <SearchBlock
                                    dataValues={[]}
                                    getDataMethod={props.getCoupons}
                                    isSearching={isSearching}
                                    placeholder={props.t('adminMessages.coupons.search.placeholder')}
                                    setIsSearching={setIsSearching}
                                    setSearchParam={setSearchParam}
                                />
                            </div>
                        </li>
                    </ul>
                </section>
                <Table
                    columns={COUPONS_TABLE_COLUMNS}
                    dataSource={!props.isCouponsLoading ? memoizedCouponData : []}
                    dataValues={[]}
                    getDataMethod={props.getCoupons}
                    isSearching={isSearching}
                    loading={props.isCouponsLoading}
                    localeMessage={errorMessages.TABLE_NO_COUPONS_FOUND_MESSAGE}
                    paginationData={props.paginationCoupons}
                    rowClassName="pointer"
                    rowKey="id"
                    size="middle"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    {...props}
                />
            </div>
        </div>
    );
};
/**
 *Prop types
 */
Coupons.propTypes = {
    assignCouponToAccounts: PropTypes.func,
    coupons: PropTypes.array,
    getAccounts: PropTypes.func,
    getCoupons: PropTypes.func,
    history: PropTypes.object,
    isCouponsLoading: PropTypes.bool,
    paginationCoupons: PropTypes.object,
    t: PropTypes.func,
};
export default connect(
    state => ({
        coupons: state.account.coupons,
        isCouponsLoading: state.account.isCouponsLoading,
        paginationCoupons: state.account.paginationCoupons,
    }),
    {
        getCoupons,
    }
)(withTranslation()(Coupons));

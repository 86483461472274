import i18n from 'includes/i18n';

/**
 * Get payment arrangement invoices columns
 *
 * @param {string} planType Plan type
 */
export const getPaymentArrangementInvoicesColumns = planType => [
    {
        title: i18n.t('messages.paymentArrangement.invoices.list.label.invoice_number'),
        dataIndex: 'invoice_number',
        key: 'invoice_number',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.paymentArrangement.invoices.list.label.due_date'),
        dataIndex: 'due_date',
        key: 'due_date',
    },
    {
        title: i18n.t('messages.paymentArrangement.invoices.list.label.days_overdue'),
        dataIndex: 'days_overdue',
        key: 'days_overdue',
    },
    {
        title: i18n.t('messages.paymentArrangement.invoices.list.label.amount', { planType: planType }),
        dataIndex: 'total_amount',
        key: 'total_amount',
    },
    {
        title: i18n.t('messages.paymentArrangement.invoices.list.label.amount_due'),
        dataIndex: 'amount_due',
        key: 'amount_due',
    },
    {
        title: i18n.t('messages.paymentArrangement.invoices.list.label.status'),
        dataIndex: 'status',
        key: 'status',
    },
];

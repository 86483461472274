/**
 * User API class.
 * Handles all api requests related to user details
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class UserApi extends BaseApiHandler {
    /**
     * Call the change password API for changing user password
     *
     * @param {object}    userDetails     User details
     *
     * @returns {object}                    API Response
     */
    changePassword = userDetails => this.axios.post(this.getApiUrl('USER_CHANGE_PASSWORD'), userDetails);

    /**
     * Call the change user status API
     *
     * @param {boolean} status Status to change
     * @param {object} restPayload Payload
     *
     * @returns {object}                    API Response
     */
    changeUserStatus = ({ status, ...restPayload }) =>
        this.axios.post(this.getApiUrl('USER_CHANGE_STATUS', { ...restPayload }), { status });

    /**
     * Call the edit user API for updating user
     *
     * @param {object}    userDetails     User details
     *
     * @returns {object}                    API Response
     */
    editUser = ({ userDetails, accountId, userId }) =>
        this.axios.patch(this.getApiUrl('USER_EDIT_USER', { account_id: accountId, user_id: userId }), userDetails);

    /**
     * Call the Forgot Password Email Sending API
     *
     * @param {object}    userEmail     User details
     *
     * @returns {object}                    API Response
     */
    forgotPassword = userEmail => this.axios.post(this.getApiUrl('USER_FORGOT_PASSWORD'), userEmail);

    /**
     * Fetch the user profile details
     *
     * @returns {object}                    API Response
     */
    getUserProfileDetails = () => this.axios.get(this.getApiUrl('USER_GET_PROFILE'));

    /**
     * Fetch the user details
     *
     * @returns {object}                    API Response
     */
    getUserDetails = ({ accountId, userId }) =>
        this.axios.get(this.getApiUrl('USER_GET_USER', { account_id: accountId, user_id: userId }));
    /**
     * delete user
     */
    deleteUser = ({ accountId, userId }) =>
        this.axios.delete(this.getApiUrl('USER_GET_USER', { account_id: accountId, user_id: userId }));
    /**
     * Call the Login API
     *
     * @param {string}    credentials     User Credentials
     *
     * @returns {object}                    API Response
     */
    login = credentials => this.axios.post(this.getApiUrl('USER_LOGIN'), credentials);

    /**
     * Call the Register API
     *
     * @param {string}    userDetails     User details
     *
     * @returns {object}                    API Response
     */
    registerUser = userDetails => this.axios.post(this.getApiUrl('USER_REGISTER'), userDetails);

    /**
     * Call the resend activation email API
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    resendActivationEmail = payload => this.axios.post(this.getApiUrl('USER_RESEND_ACTIVATION_EMAIL', { ...payload }));

    /**
     * Call the resend verification email API
     *
     * @returns {object}                    API Response
     */
    resendVerificationEmail = () => this.axios.post(this.getApiUrl('USER_RESEND_VERIFICATION_EMAIL'));

    /**
     * Call the resend verification sms API
     *
     * @returns {object}                    API Response
     */
    resendVerificationSms = payload => this.axios.post(this.getApiUrl('USER_RESEND_VERIFICATION_SMS'), payload);

    /**
     * Call the Password reset API
     *
     * @param {object}    payload     User details
     *
     * @returns {object}                    API Response
     */
    resetPassword = payload => this.axios.post(this.getApiUrl('USER_RESET_PASSWORD'), payload);

    /**
     * Call the reset two factor authentication API
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    resetTwoFactorAuthentication = payload =>
        this.axios.delete(this.getApiUrl('USER_RESET_TWO_FACTOR_AUTHENTICATION'), { params: payload });

    /**
     * Call the Verify Phone Number API
     *
     * @param {object}    otp     Verification OTP
     *
     * @returns {object}            API Response
     */
    verifyPhoneNumber = otp => this.axios.post(this.getApiUrl('USER_VERIFY_PHONE_NUMBER'), otp);

    getUserRoles = payload => this.axios.get(this.getApiUrl('GET_USER_ROLES'), { params: { ...payload } });

    getUserAccountRoles = ({ accountId, ...payload }) =>
        this.axios.get(this.getApiUrl('GET_USER_ACCOUNT_ROLES', { account_id: accountId }), { params: { ...payload } });

    /**
     * Call the Verify Email Address API
     *
     * @param {object}    obj     Email address activation code
     *
     * @returns {object}            API Response
     */
    verifyEmailAddress = obj => this.axios.post(this.getApiUrl('USER_VERIFY_EMAIL'), obj);

    /**
     * Call the add user API
     */
    addUser = ({ userDetails, accountId }) =>
        this.axios.post(this.getApiUrl('GET_CUSTOMER_MANAGER_USER', { account_id: accountId }), userDetails);
    /**
     * Call the users liting API
     */
    getUsers = ({ searchParams, ...restPayload }) =>
        this.axios.get(this.getApiUrl('GET_CUSTOMER_MANAGER_USER', restPayload), { params: { ...searchParams } });

    /**
     * restore user
     */
    requestQuote = payload => this.axios.post(this.getApiUrl('REQUEST_QUOTE'), payload);

    /**
     * Get FAQ and answers
     */
    getFAQ = () => this.axios(this.getWpApiUrl('GET_FAQ'));

    /**
     * Logout API
     *
     * @param {string}    authorizationToken      Authorization token
     * @param {string}    locale                  Locale
     */
    logout = (authorizationToken, locale) => {
        this.axios.defaults.headers.common['Accept-Language'] = locale;
        this.axios.defaults.headers.common['Authorization'] = `Token ${authorizationToken}`;
        this.axios.post(this.getApiUrl('USER_LOGOUT'));
    };

    /**
     * activate account API
     */
    activateAccount = payload => this.axios.post(this.getApiUrl('ACTIVATE_ACCOUNT'), payload);
}

export default new UserApi();

/**
 * Common Button
 * Common button with style used throughout the app
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

import { React } from '../../../includes/exports/react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const CustomizedButton = props => {
    const { className, big, filled, submittingButtonLabel, loading, forwardedRef, ...restProps } = props; //eslint-disable-line
    return (
        <Button
            {...restProps}
            loading={props.loading}
            ref={forwardedRef}
            className={`${props.big ? 'big-btn' : ''} ${props.filled ? 'green-bg-button' : 'white-bg-button'} ${
                props.className
            }`}
        >
            {props.loading && props.submittingButtonLabel ? props.submittingButtonLabel : props.children}
        </Button>
    );
};

CustomizedButton.propTypes = {
    big: PropTypes.bool,
    filled: PropTypes.bool,
    children: PropTypes.any,
    className: PropTypes.string,
    loading: PropTypes.bool,
    submittingButtonLabel: PropTypes.string,
};

CustomizedButton.defaultProps = {
    big: false,
    filled: false,
    className: '',
};

export default CustomizedButton;

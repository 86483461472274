/**
 * Accounting Software Saga
 * Handles the operations related to accounting softwares
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import the required modules
import * as actionTypes from '../../../../constants/actionTypes';
import * as successMappings from '../../../../constants/mappings/success';
import AccountingSoftwareApi from '../../../../services/shared/accoutingSoftware';
import BaseSagaHandler from '../../core/base';
import { call, takeLatest, all, put } from 'redux-saga/effects';
import { get } from 'lodash';

/**
 * Accounting Software Saga Class. Handles the accounting software related operations
 * */
class AccountingSoftware extends BaseSagaHandler {
    /**
     * The Accounting software Watcher Saga
     * Watches the redux actions related to accounting software and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *accountingSoftwareWatchers() {
        let context = this;
        yield all([yield takeLatest(actionTypes.GET_ACCOUNTING_SOFTWARE_REQUEST, [context, 'getAccountingSoftwares'])]);
    }

    /**
     * Get the accounting softwares
     *
     * @param   {object}    action     Action
     *
     * @yields {object} Accounting softwares response
     */
    *getAccountingSoftwares(action) {
        try {
            const result = yield call(AccountingSoftwareApi.getAccountingSoftwares, action.payload);
            let accountingSoftwareResponse = get(result, successMappings.GET_ACCOUNTING_SOFTWARES);
            let accountingSoftwares = {};
            accountingSoftwareResponse.forEach(element => {
                accountingSoftwares[element.id] = element;
            });
            yield put({ type: actionTypes.GET_ACCOUNTING_SOFTWARE_SUCCESS, accountingSoftwares });
        } catch (errors) {
            yield put({ type: actionTypes.GET_ACCOUNTING_SOFTWARE_FAILURE, errors });
        }
    }
}

//export the accounting software saga
export default new AccountingSoftware().forkAllWatcherFunctions();

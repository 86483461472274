/**
 * useZendeskTicketOptions
 * Fetch the zendesk ticket options and caches it
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { CACHE_ZENDESK_TICKET_OPTIONS_KEY } from '../constants';
import { get } from 'lodash';
import { ZENDESK_TICKET_OPTIONS } from 'includes/constants/mappings/success';
import useAllowZendeskIntegration from 'includes/hooks/useAllowZendeskIntegration';
import useCachedData from './useCachedData';
import ZendeskApi from 'includes/services/zendesk';

/**
 * Fetches zendesk ticket options
 * Return cached data if exists else call the API and caches the result
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useZendeskTicketOptions = () => {
    const allowZendeskIntegration = useAllowZendeskIntegration();
    return useCachedData(CACHE_ZENDESK_TICKET_OPTIONS_KEY, () => ZendeskApi.getTicketOptions(), {
        selector: res => get(res, ZENDESK_TICKET_OPTIONS),
        disableApiCall: !allowZendeskIntegration,
    });
};

export default useZendeskTicketOptions;

import { Breadcrumb, Tabs } from 'antd';
import { find } from 'lodash';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import BankMandateSVG from 'assets/images/bank_mandate.svg';
import PageTitle from 'components/PageTitle';
import WhiteBoxWrapper from 'components/WhiteBoxWrapper';
import { CARD_BRAND_LOGO_MAP, GENERIC_CARD_LOGO } from 'components/customer/PaymentInformation/constants';
import Loader from 'components/shared/Loader';
import OrdersTable from 'components/shared/Orders/Table';
import { ORDER_TYPE, PAYMENT_METHOD_TYPE } from 'includes/constants';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import usePaymentMethodTypes from 'includes/hooks/usePaymentMethodTypes';
import { getOrders } from 'includes/slices/orders';
import { getPaymentGateways, getPaymentMethodDetails } from 'includes/slices/paymentGateways';
import { useOrderTypes } from 'includes/utils/hooks';
import PaymentMethodDetailsBase from './components/PaymentMethodDetailsBase';

/**
 * Payment method details page
 *
 * Fetches and renders payment method details
 */
export default function PaymentMethodDetails() {
    const { paymentMethodId } = useParams();

    const { data: paymentMethodTypes } = usePaymentMethodTypes();

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const isAdmin = useIsAdminOrManager();

    const accountId = useAccountId();

    const paymentMethodDetails = useSelector(state => state.paymentGateways.paymentMethodDetails);

    const isLoadingPaymentMethodDetails = useSelector(state => state.paymentGateways.isLoadingPaymentMethodDetails);

    useEffect(() => {
        dispatch(getPaymentMethodDetails(paymentMethodId));
    }, [paymentMethodId]);

    useEffect(() => {
        dispatch(getPaymentGateways());
    }, []);

    /**
     * Find the corresponding payment method type for this payment method id
     */
    const methodType = useMemo(() => {
        if (!paymentMethodDetails || paymentMethodTypes.length === 0) return null;

        return find(paymentMethodTypes, { id: paymentMethodDetails.method_type_id });
    }, [paymentMethodTypes, paymentMethodDetails]);

    /**
     * If we have the valid method type, render the view with appropriate props,
     * else render Loader
     *
     * @returns Payment method details view
     */
    const renderPaymentMethodDetails = () => {
        if (!methodType || isLoadingPaymentMethodDetails) return <Loader />;

        switch (methodType.slug) {
            case PAYMENT_METHOD_TYPE.CREDIT_CARD:
            case PAYMENT_METHOD_TYPE.DEBIT_CARD: {
                const icon = CARD_BRAND_LOGO_MAP[paymentMethodDetails.brand_name] ?? GENERIC_CARD_LOGO;
                const accountNumber = t('customerMessages.paymentMethod.card.description', {
                    number: paymentMethodDetails.account_number.slice(-4),
                    brand: paymentMethodDetails.brand_name,
                });
                const countryId = paymentMethodDetails.country_id;
                return (
                    <PaymentMethodDetailsBase
                        accountHolderName={paymentMethodDetails.account_holder_name}
                        accountNumberDescription={accountNumber}
                        countryId={countryId}
                        endDate={paymentMethodDetails.end_date}
                        icon={icon}
                        isDefault={paymentMethodDetails.is_default}
                        statusId={paymentMethodDetails.status_id}
                    />
                );
            }
            case PAYMENT_METHOD_TYPE.MANDATE: {
                const accountNumber = t('customerMessages.paymentMethod.mandate.description', {
                    number: paymentMethodDetails.account_number.slice(-2),
                });
                const countryId = paymentMethodDetails.country_id;
                return (
                    <PaymentMethodDetailsBase
                        accountHolderName={paymentMethodDetails.account_holder_name}
                        accountNumberDescription={accountNumber}
                        bank={paymentMethodDetails.brand_name}
                        countryId={countryId}
                        icon={BankMandateSVG}
                        isDefault={paymentMethodDetails.is_default}
                        schemeId={paymentMethodDetails.scheme_id}
                        startDate={paymentMethodDetails.start_date}
                        statusId={paymentMethodDetails.status_id}
                    />
                );
            }
        }
    };

    const [orderTypes] = useOrderTypes();

    /**
     * Find and memoise subscription order type
     */
    const orderType = useMemo(() => {
        if (orderTypes.length === 0) return null;

        return find(orderTypes, { slug: ORDER_TYPE.SUBSCRIPTION });
    }, [orderTypes]);

    /**
     * Get data method - to be passed in to the orders table
     */
    const getDataMethod = useCallback(
        params => {
            if (!orderType) return;

            const newParams = {
                ...params,
                payment_method_id: paymentMethodId,
            };

            if (isAdmin) newParams['account_id'] = accountId;

            dispatch(getOrders(orderType.id, { ...newParams }));
        },
        [accountId, orderType, paymentMethodId]
    );

    return (
        <div className="container mx-auto">
            <div className="mt-8 text-black breadcumb-spec border-0">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/payment-information">Payment Information</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Payment method details</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="my-7">
                <PageTitle>Payment method details</PageTitle>
            </div>

            <section>
                <WhiteBoxWrapper>{renderPaymentMethodDetails()}</WhiteBoxWrapper>
            </section>

            <section className="mt-12">
                <WhiteBoxWrapper>
                    <Tabs>
                        <Tabs.TabPane key={'payment-method-orders-table'} tab="Orders">
                            <OrdersTable
                                getDataMethod={getDataMethod}
                                isSearching={false}
                                setIsSearching={() => {}}
                                typeSlug={ORDER_TYPE.SUBSCRIPTION}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </WhiteBoxWrapper>
            </section>
        </div>
    );
}

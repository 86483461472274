/**
 * Placeholder Saga
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import the required modules
import * as actionTypes from '../../../../constants/actionTypes';
import * as constants from '../../../../constants';
import BaseSagaHandler from '../../core/base';
import PlaceholderApi from '../../../../services/shared/placeholder';
import { call, takeLatest, all, put } from 'redux-saga/effects';
import { isFunction } from 'lodash';

/**
 * Placeholder Saga Class. Handles the placeholder related operations
 * */
class Placeholder extends BaseSagaHandler {
    /**
     * The Placeholder Watcher Saga
     * Watches the redux actions related to communication and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *placeholderWatchers() {
        let context = this;
        yield all([
            yield takeLatest(actionTypes.ADD_PLACEHOLDER_REQUEST, [context, 'addPlaceholder']),
            yield takeLatest(actionTypes.DELETE_PLACEHOLDER_REQUEST, [context, 'deletePlaceholder']),
            yield takeLatest(actionTypes.GET_ALL_PLACEHOLDERS_REQUEST, [context, 'getPlaceHolders']),
            yield takeLatest(actionTypes.GET_PLACEHOLDERS_REQUEST, [context, 'getPlaceHolders']),
            yield takeLatest(actionTypes.UPDATE_PLACEHOLDER_REQUEST, [context, 'updatePlaceholder']),
        ]);
    }

    /**
     * Add a placeholder
     *
     * @param {object} payload Payload
     * @param {Function} callback Callback function
     *
     * @yields {object} Add placeholder response
     */
    *addPlaceholder({ payload, callback }) {
        try {
            const result = yield call(PlaceholderApi.addPlaceholder, payload);
            if (callback && isFunction(callback)) {
                callback && callback();
            }
            yield put({ type: actionTypes.ADD_PLACEHOLDER_SUCCESS, result });
            yield this.showNotificationModal(result, true);
        } catch (errors) {
            yield put({ type: actionTypes.ADD_PLACEHOLDER_FAILURE, errors });
            yield this.stopFormSubmit(
                errors,
                constants.ADD_EDIT_PLACEHOLDER_FORM,
                constants.ADD_PLACEHOLDER_DEFAULT_ERROR,
                constants.ADD_PLACEHOLDER_DEFAULT_ERROR
            );
        }
    }

    /**
     * Delete a placeholder
     *
     * @param {object} payload Payload
     *
     * @yields {object} Delete placeholder response
     */
    *deletePlaceholder({ payload }) {
        try {
            const result = yield call(PlaceholderApi.deletePlaceholder, payload);
            yield put({ type: actionTypes.DELETE_PLACEHOLDER_SUCCESS, result });
            yield this.showNotificationModal(result, true);
        } catch (errors) {
            yield this.showNotificationModal(
                errors,
                false,
                constants.DELETE_PLACEHOLDER_DEFAULT_ERROR,
                constants.DELETE_PLACEHOLDER_DEFAULT_ERROR
            );
            yield put({ type: actionTypes.DELETE_PLACEHOLDER_FAILURE, errors });
        }
    }

    /**
     * Get the placeholders
     *
     * @param {object} action Action
     *
     * @yields {object} Get placeholders response
     */
    *getPlaceHolders(action) {
        try {
            const result = yield call(PlaceholderApi.getPlaceholders, action.payload);
            yield put({
                type:
                    action.type === actionTypes.GET_ALL_PLACEHOLDERS_REQUEST
                        ? actionTypes.GET_ALL_PLACEHOLDERS_SUCCESS
                        : actionTypes.GET_PLACEHOLDERS_SUCCESS,
                result,
            });
        } catch (errors) {
            yield put({
                type:
                    action.type === actionTypes.GET_ALL_PLACEHOLDERS_REQUEST
                        ? actionTypes.GET_ALL_PLACEHOLDERS_FAILURE
                        : actionTypes.GET_PLACEHOLDERS_FAILURE,
                errors,
            });
        }
    }

    /**
     * Update a placeholder
     *
     * @param {object} payload Payload
     * @param {Function} callback Callback function
     *
     * @yields {object} Get placeholders response
     */
    *updatePlaceholder({ payload, callback }) {
        try {
            const result = yield call(PlaceholderApi.updatePlaceholder, payload);
            if (callback && isFunction(callback)) {
                callback && callback();
            }
            yield put({ type: actionTypes.UPDATE_PLACEHOLDER_SUCCESS, result });
            yield this.showNotificationModal(result, true);
        } catch (errors) {
            yield put({ type: actionTypes.UPDATE_PLACEHOLDER_FAILURE, errors });
            yield this.stopFormSubmit(
                errors,
                constants.ADD_EDIT_PLACEHOLDER_FORM,
                constants.UPDATE_PLACEHOLDER_DEFAULT_ERROR,
                constants.UPDATE_PLACEHOLDER_DEFAULT_ERROR
            );
        }
    }
}
//export the communication saga
export default new Placeholder().forkAllWatcherFunctions();

/**
 * Invoice Slice
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */
import { createSlice } from '@reduxjs/toolkit';
import { get, isFunction } from 'lodash';
import { getPaginationData } from '../utils';
import { showApiErrors, showApiSuccess } from '../utils/api';
import * as successMappings from '../constants/mappings/success';
import * as constants from '../constants';
import * as actionTypes from '../constants/actionTypes';
import { UNKNOWN_ERROR } from '../constants/messages/errors';
import InvoiceApi from '../services/customer/invoice';
import { LINE_ITEMS, PAID_ON, SEND_SETTINGS } from '../constants/keys/request';
import { setContactDetails } from './contact';
import { showNotificationModal } from './appNotifications';
import { stopSubmit } from 'redux-form';
import { SEND_SETTINGS_ERROR } from 'includes/constants/mappings/errors';

const initialState = {
    detailsUpdatedMessage: '',
    invoiceDetails: {},
    invoices: [],
    invoicePayments: [],
    invoiceStatuses: [],
    isDeletingInvoice: false,
    isDeletingInvoicePayment: false,
    isInvoiceDetailsLoading: false,
    isExportLoading: false,
    isExportingInvoicePayments: false,
    isLoading: false,
    isInvoiceStatusesLoading: false,
    isInvoiceAddLoading: false,
    isInvoicePaymentCreating: false,
    isInvoicePaymentsLoading: false,
    isInvoicePaymentUpdating: false,
    isInvoicePdfDownloading: false,
    isInvoiceUpdating: false,
    isNextInvoiceNumberLoading: false,
    isPreviewLoading: false,
    isTypesLoading: false,
    nextInvoiceNumber: '',
    paginationInvoicePayments: {},
    paginationInvoices: {},
    preview: '',
    types: [],
    isInvoicePaymentDetailsLoading: false,
    invoicePaymentDetails: null,
};

const invoicesSlice = createSlice({
    name: 'invoicesSlice',
    initialState,
    reducers: {
        setLoader(state, action) {
            state.isLoading = action.payload;
        },
        setInvoices(state, action) {
            state.invoices = action.payload.value;
            state.paginationInvoices = action.payload.pagination;
        },
        setInvoicePayments(state, action) {
            state.invoicePayments = action.payload.value;
            state.paginationInvoicePayments = action.payload.pagination;
        },
        setInvoiceStatuses(state, action) {
            state.invoiceStatuses = action.payload;
        },
        setInvoiceStatusLoader(state, action) {
            state.isInvoiceStatusesLoading = action.payload;
        },
        setDeleteInvoiceLoader(state, action) {
            state.isDeletingInvoice = action.payload;
        },
        setInvoiceDetailsLoader(state, action) {
            state.isInvoiceDetailsLoading = action.payload;
        },
        setInvoiceAddLoader(state, action) {
            state.isInvoiceAddLoading = action.payload;
        },
        setInvoicePaymentCreateLoader(state, action) {
            state.isInvoicePaymentCreating = action.payload;
        },
        setInvoicePaymentUpdateLoader(state, action) {
            state.isInvoicePaymentUpdating = action.payload;
        },
        setInvoiceUpdateLoader(state, action) {
            state.isInvoiceUpdating = action.payload;
        },
        setDetailsUpdatedMessage(state, action) {
            state.detailsUpdatedMessage = action.payload;
        },
        setDeleteInvoicePaymentLoader(state, action) {
            state.isDeletingInvoicePayment = action.payload;
        },
        setExportInvoicePaymentsLoader(state, action) {
            state.isExportingInvoicePayments = action.payload;
        },
        setInvoicePaymentLoader(state, action) {
            state.isInvoicePaymentsLoading = action.payload;
        },
        setInvoicePdfLoader(state, action) {
            state.isInvoicePdfDownloading = action.payload;
        },
        setInvoiceTypesLoader(state, action) {
            state.isTypesLoading = action.payload;
        },
        setIsExporting(state, action) {
            state.isExportLoading = action.payload;
        },
        setInvoiceDetails(state, action) {
            state.invoiceDetails = action.payload;
        },
        setInvoiceTypes(state, action) {
            state.types = action.payload;
        },
        setNextInvoiceNumber(state, action) {
            state.nextInvoiceNumber = action.payload;
        },
        setNextInvoiceNumberLoading(state, action) {
            state.isNextInvoiceNumberLoading = action.payload;
        },
        setPreview(state, action) {
            state.preview = action.payload;
        },
        setPreviewLoader(state, action) {
            state.isPreviewLoading = action.payload;
        },
        setIsInvoicePaymentDetailsLoading(state, action) {
            state.isInvoicePaymentDetailsLoading = action.payload;
        },
        setInvoicePaymentDetails(state, action) {
            state.invoicePaymentDetails = action.payload;
        },
    },
});

export const {
    setLoader,
    setInvoices,
    setInvoicePayments,
    setInvoiceAddLoader,
    setInvoicePaymentCreateLoader,
    setInvoicePaymentUpdateLoader,
    setInvoiceDetailsLoader,
    setInvoicePaymentLoader,
    setInvoicePdfLoader,
    setInvoiceStatusLoader,
    setInvoiceTypesLoader,
    setInvoiceUpdateLoader,
    setDeleteInvoiceLoader,
    setDeleteInvoicePaymentLoader,
    setDetailsUpdatedMessage,
    setExportInvoicePaymentsLoader,
    setIsExporting,
    setInvoiceDetails,
    setInvoiceStatuses,
    setInvoiceTypes,
    setNextInvoiceNumber,
    setNextInvoiceNumberLoading,
    setPreview,
    setPreviewLoader,
    setInvoicePaymentDetails,
    setIsInvoicePaymentDetailsLoading,
} = invoicesSlice.actions;

/*
 * Add invoice action
 *
 * @param {string} organisationId Organisation id
 * @param {string} contactId Contact id
 * @param {object} invoiceDetails Invoice details
 * @callback Callback function
 * @callback callback that closes the campaign modal
 *
 * @returns {object} Add invoice action
 */
export const addInvoice = (
    organisationId,
    contactId,
    invoiceDetails,
    callback,
    closeCampaignModal = () => {}
) => async dispatch => {
    try {
        dispatch(setInvoiceAddLoader(true));
        const result = await InvoiceApi.addInvoice({
            organisation_id: organisationId,
            contact_id: contactId,
            invoiceDetails,
        });
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            const invoiceId = get(result, successMappings.GET_INVOICE_DETAILS_ID);
            callback(contactId, invoiceId);
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'INVOICE_FORM_NAME', 'INVOICE', '', '', '', [LINE_ITEMS, SEND_SETTINGS]));
        const sendSettingsError = get(errors, SEND_SETTINGS_ERROR);
        if (sendSettingsError) {
            dispatch(stopSubmit(constants.ON_DEMAND_CAMPAIGN_FORM_NAME, sendSettingsError));
        } else {
            closeCampaignModal();
        }
    } finally {
        dispatch(setInvoiceAddLoader(false));
    }
};

/*
 * Add invoice payment action
 *
 * @param {string} organisationId Organisation id
 * @param {string} contactId Contact id
 * @param {string} invoiceId Invoice id
 * @param {object} values Values
 * @param {Function} callback Callback function
 *
 * @returns {object} Add invoice payment action
 */
export const addInvoicePayment = (organisationId, contactId, invoiceId, values, callback) => async dispatch => {
    try {
        dispatch(setInvoicePaymentCreateLoader(true));
        const result = await InvoiceApi.addInvoicePayment({ organisationId, contactId, invoiceId, ...values });
        dispatch(setInvoiceDetails(get(result, successMappings.GET_INVOICE_DETAILS)));
        dispatch(
            setInvoicePayments({
                value: get(result, successMappings.GET_PAYMENTS_HISTORY),
                pagination: getPaginationData({ result }),
            })
        );
        dispatch(showApiSuccess(result));
        callback && callback();
    } catch (errors) {
        dispatch(showApiErrors(errors, 'EDIT_PAYMENT_HISTORY_FORM_NAME', 'INVOICE_PAYMENT_ADD', '', [PAID_ON]));
    } finally {
        dispatch(setInvoicePaymentCreateLoader(false));
    }
};

/**
 * Delete invoice action
 *
 * @param {string} organisationId Organisation Id
 * @param {string} contactId Contact Id
 * @param {string} invoiceId Invoice Id
 * @param {object} urlParams Url Params
 *
 * @returns {object} Delete invoice action
 */
export const deleteInvoice = (organisationId, contactId, invoiceId, urlParams) => async dispatch => {
    try {
        dispatch(setDeleteInvoiceLoader(true));
        const result = await InvoiceApi.deleteInvoice({
            organisation_id: organisationId,
            contact_id: contactId,
            invoice_id: invoiceId,
            ...urlParams,
        });
        dispatch(
            setInvoices({
                value: get(result, successMappings.GET_INVOICES),
                pagination: getPaginationData({ result }),
            })
        );
        dispatch(showApiSuccess(result));
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'INVOICE_DELETE'));
    } finally {
        dispatch(setDeleteInvoiceLoader(false));
    }
};

/**
 * Delete the invoice payment action
 *
 * @param {string} organisationId Organisation id
 * @param {string} contactId Contact id
 * @param {string} invoiceId Invoice id
 * @param {string} paymentId Payment id
 *
 * @returns Delete Invoice Payment action
 */
export const deleteInvoicePayment = (organisationId, contactId, invoiceId, paymentId) => async dispatch => {
    try {
        dispatch(setDeleteInvoicePaymentLoader(true));
        const result = await InvoiceApi.deleteInvoicePayment({
            organisation_id: organisationId,
            contact_id: contactId,
            invoice_id: invoiceId,
            payment_id: paymentId,
        });
        dispatch(setInvoiceDetails(get(result, successMappings.GET_INVOICE_DETAILS)));
        dispatch(
            setInvoicePayments({
                value: get(result, successMappings.GET_PAYMENTS_HISTORY),
                pagination: getPaginationData({ result }),
            })
        );
        dispatch(showApiSuccess(result));
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'INVOICE_PAYMENT'));
    } finally {
        dispatch(setDeleteInvoicePaymentLoader(false));
    }
};

/*
 * Export all invoice payments of an organisation action
 *
 * @param {string} organisationId Organisation id
 * @param {object} searchParams Search params
 *
 * @returns {object} Export all invoice payments action
 */
export const exportAllInvoicePayments = (organisationId, searchParams) => async dispatch => {
    try {
        dispatch(setExportInvoicePaymentsLoader(true));
        const result = await InvoiceApi.exportAllInvoicePayments({
            organisation_id: organisationId,
            searchParams,
        });
        window.open(get(result, successMappings.EXPORT_FILE), '_blank');
    } catch (errors) {
        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: UNKNOWN_ERROR,
            messageType: constants.ERROR,
        });
    } finally {
        dispatch(setExportInvoicePaymentsLoader(false));
    }
};

/*
 * Export invoice payments action
 *
 * @param {string} organisationId Organisation id
 * @param {string} contactId Contact id
 * @param {string} invoiceId Invoice id
 * @param {object} searchParams Search params
 *
 * @returns {object} Export invoices action
 */
export const exportInvoicePayments = (organisationId, contactId, invoiceId, searchParams) => async dispatch => {
    try {
        dispatch(setExportInvoicePaymentsLoader(true));
        const result = await InvoiceApi.exportInvoicePayments({
            organisation_id: organisationId,
            contact_id: contactId,
            invoice_id: invoiceId,
            searchParams,
        });
        window.open(get(result, successMappings.EXPORT_FILE), '_blank');
    } catch (errors) {
        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: UNKNOWN_ERROR,
            messageType: constants.ERROR,
        });
    } finally {
        dispatch(setExportInvoicePaymentsLoader(false));
    }
};

/*
 * Export invoices action
 *
 * @param {string} organisationId Organisation id
 * @param {string} contactId Contact id
 * @param {object} params Params
 * @param {Array} invoiceIds Invoice ids to export
 *
 * @returns {object} Export invoices action
 */
export const exportInvoices = (organisationId, contactId, params, invoiceIds) => async dispatch => {
    try {
        dispatch(setIsExporting(true));
        const apiMethod = contactId ? InvoiceApi.exportInvoices : InvoiceApi.exportAllInvoices,
            payload = {
                organisationId,
                contactId,
                params,
                invoiceIds,
            };

        const result = await apiMethod(payload);
        window.open(get(result, successMappings.EXPORT_FILE, ''), '_self');
        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
            messageType: constants.SUCCESS,
        });
    } catch (errors) {
        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: UNKNOWN_ERROR,
            messageType: constants.ERROR,
        });
    } finally {
        dispatch(setIsExporting(false));
    }
};

/*
 * Get all invoices of a contact action
 *
 * @param {string} organisationId Organisation id
 * @param {string} contactId Contact id
 * @param {string} invoiceId Invoice id
 *
 * @returns {object} Get all contact invoices action
 */
export const getAllContactInvoices = (organisationId, contactId, invoiceId) => async dispatch => {
    try {
        dispatch(setLoader(true));
        const result = await InvoiceApi.getAllContactInvoices({
            organisation_id: organisationId,
            contact_id: contactId,
            invoice_id: invoiceId,
        });
        dispatch(setContactDetails(get(result, successMappings.GET_CONTACT_DETAILS)));

        // for setting organisation details, custom brancding in user reducer
        dispatch({ type: actionTypes.GET_ALL_CONTACT_INVOICES_SUCCESS, result });
        dispatch(
            setInvoices({
                value: get(result, successMappings.GET_INVOICES),
                pagination: getPaginationData({ result }),
            })
        );
        dispatch(setLoader(false));
    } catch (errors) {
        dispatch(setLoader(false));
    }
};

/**
 * Get All invoice payments corresponding to an organisation action
 *
 * @param {string} organisationId Organisation id
 * @param {object} searchParams Search params
 *
 * @returns {object} Get all invoice payments action
 */
export const getAllInvoicePayments = (organisationId, searchParams) => async dispatch => {
    try {
        dispatch(setInvoicePaymentLoader(true));
        dispatch(setInvoicePayments({ value: [], pagination: {} }));
        const result = await InvoiceApi.getAllInvoicePayments({ organisation_id: organisationId, searchParams });
        dispatch(
            setInvoicePayments({
                value: get(result, successMappings.GET_PAYMENTS_HISTORY),
                pagination: getPaginationData({ result }),
            })
        );

        dispatch(setInvoicePaymentLoader(false));
    } catch (errors) {
        dispatch(setInvoicePaymentLoader(false));
    }
};

/**
 * Get contact invoices action
 *
 * @param {string} organisationId Organisation ID
 * @param {string} contactId Contact ID
 * @param {object} searchParams Search params
 *
 * @returns {object} Get Contact Invoices action
 */
export const getContactInvoices = (organisationId, contactId, searchParams) => async dispatch => {
    try {
        dispatch(setLoader(true));
        dispatch(
            setInvoices({
                value: [],
                pagination: {},
            })
        );
        const result = await InvoiceApi.getContactInvoices({
            organisation_id: organisationId,
            contact_id: contactId,
            searchParams,
        });
        dispatch(
            setInvoices({
                value: get(result, successMappings.GET_INVOICES),
                pagination: getPaginationData({ result }),
            })
        );
        dispatch(setLoader(false));
    } catch (errors) {
        dispatch(setLoader(false));
    }
};

/**
 * Get invoice details action
 *
 * @param {string} organisationId Organisation ID
 * @param {string} contactId Contact ID
 * @param {string} invoiceId Invoice ID
 *
 * @returns {object} Get Invoices action
 */
export const getInvoiceDetails = (organisationId, contactId, invoiceId, callback = () => {}) => async dispatch => {
    try {
        dispatch(setInvoiceDetailsLoader(true));
        const result = await InvoiceApi.getInvoiceDetails({
            organisation_id: organisationId,
            contact_id: contactId,
            invoice_id: invoiceId,
        });
        const details = get(result, successMappings.GET_INVOICE_DETAILS);
        dispatch(setInvoiceDetails(details));
        callback(details);
    } catch (errors) {
        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: UNKNOWN_ERROR,
            messageType: constants.ERROR,
        });
    } finally {
        dispatch(setInvoiceDetailsLoader(false));
    }
};

/**
 * Get invoice payments action
 *
 * @param {string} organisationId Organisation ID
 * @param {string} contactId Contact ID
 * @param {string} invoiceId Invoice ID
 * @param {object} searchParams Search params
 *
 * @returns {object} Get invoice payments action
 */
export const getInvoicePayments = (organisationId, contactId, invoiceId, searchParams) => async dispatch => {
    try {
        dispatch(setInvoicePaymentLoader(true));
        dispatch(setInvoicePayments({ value: [], pagination: {} }));
        const result = await InvoiceApi.getInvoicePayments({
            organisation_id: organisationId,
            contact_id: contactId,
            invoice_id: invoiceId,
            searchParams,
        });
        dispatch(
            setInvoicePayments({
                value: get(result, successMappings.GET_PAYMENTS_HISTORY),
                pagination: getPaginationData({ result }),
            })
        );
        dispatch(setInvoicePaymentLoader(false));
    } catch (errors) {
        dispatch(setInvoicePaymentLoader(false));
    }
};

/**
 * Get invoice payments action
 *
 * @param {string} organisationId Organisation ID
 * @param {string} contactId Contact ID
 * @param {string} invoiceId Invoice ID
 * @param {string} invoicePaymentId Invoice payment ID
 *
 * @returns {object} Get invoice payments action
 */
export const getInvoicePaymentDetails = (organisationId, contactId, invoiceId, invoicePaymentId) => async dispatch => {
    try {
        dispatch(setIsInvoicePaymentDetailsLoading(true));
        dispatch(setInvoicePayments({ value: [], pagination: {} }));
        const result = await InvoiceApi.getInvoicePaymentDetails({
            organisation_id: organisationId,
            contact_id: contactId,
            invoice_id: invoiceId,
            invoice_payment_id: invoicePaymentId,
        });
        dispatch(setInvoicePaymentDetails(get(result, successMappings.GET_INVOICE_PAYMENT_DETAILS)));
        dispatch(setIsInvoicePaymentDetailsLoading(false));
    } catch (errors) {
        dispatch(setIsInvoicePaymentDetailsLoading(false));
    }
};

/**
 * Get the Invoice PDF download URL
 *
 * @param {string} organisationId Organisation Id
 * @param {string} contactId Contact Id
 * @param {string} invoiceId Invoice Id
 *
 * @returns {object} Get the invoice pdf download url action
 */
export const getInvoicePdfDownloadUrl = (organisationId, contactId, invoiceId) => async dispatch => {
    try {
        dispatch(setInvoicePdfLoader(true));
        const result = await InvoiceApi.getInvoicePdfDownloadUrl({
            organisation_id: organisationId,
            contact_id: contactId,
            invoice_id: invoiceId,
        });
        const invoicePdfDownloadUrl = get(result, successMappings.INVOICE_PDF_DOWNLOAD_URL, '');
        if (invoicePdfDownloadUrl) {
            window.open(get(result, successMappings.INVOICE_PDF_DOWNLOAD_URL, ''), '_blank');
            showNotificationModal(result, true);
        } else {
            throw actionTypes.GET_INVOICE_PDF_DOWNLOAD_URL_FAILURE;
        }
    } catch (errors) {
        showNotificationModal(errors, false, 'GET_INVOICE_PDF_DOWNLOAD_URL_ERROR');
    } finally {
        dispatch(setInvoicePdfLoader(false));
    }
};

/**
 * Get invoices action
 *
 * @param {string} organisationId Organisation ID
 * @param {object} searchParams Search Params
 *
 * @returns {object} Get Invoices action
 */
export const getInvoices = (organisationId, searchParams) => async dispatch => {
    try {
        dispatch(setLoader(true));
        const result = await InvoiceApi.getInvoices({
            organisation_id: organisationId,
            searchParams,
        });
        // yield put({ type: actionTypes.GET_INVOICES_SUCCESS, result });
        dispatch(
            setInvoices({
                value: get(result, successMappings.GET_INVOICES),
                pagination: getPaginationData({ result }),
            })
        );
        dispatch(setLoader(false));
    } catch (errors) {
        dispatch(setLoader(false));
    }
};

/**
 * Get invoice statuses action
 *
 * @returns {object} Get Invoice statuses action
 */
export const getInvoiceStatuses = () => async dispatch => {
    try {
        dispatch(setInvoiceStatusLoader(true));
        const result = await InvoiceApi.getInvoiceStatuses({ page_size: 0 });
        let invoiceStatuses = get(result, successMappings.GET_INVOICE_STATUSES);
        invoiceStatuses = invoiceStatuses.map(status => ({ ...status, label: status.status, value: status.id }));
        dispatch(setInvoiceStatuses(invoiceStatuses));
        dispatch(setInvoiceStatusLoader(false));
    } catch (errors) {
        dispatch(setInvoiceStatusLoader(false));
    }
};

/**
 * Get invoice types action
 *
 * @returns {object} Get invoice types action
 */
export const getInvoiceTypes = () => async dispatch => {
    try {
        dispatch(setInvoiceTypesLoader(true));
        const result = await InvoiceApi.getInvoiceTypes({ page_size: 0 });
        let invoiceTypes = get(result, successMappings.GET_INVOICE_TYPES);
        invoiceTypes = invoiceTypes.map(type => ({ ...type, label: type.type, value: type.id }));
        // yield put({ type: actionTypes.GET_INVOICE_TYPES_SUCCESS, invoiceTypes });
        dispatch(setInvoiceTypes(invoiceTypes));
        dispatch(setInvoiceTypesLoader(false));
    } catch (errors) {
        dispatch(setInvoiceTypesLoader(false));
    }
};

/**
 * Get next invoice number
 *
 * @param {string} organisationId Organisation ID
 * @param {object} params Params. Default {}
 *
 * @returns {object} Get Next invoice number action
 */
export const getNextInvoiceNumber = (organisationId, params = {}) => async dispatch => {
    try {
        dispatch(setNextInvoiceNumber(''));
        dispatch(setNextInvoiceNumberLoading(true));
        const result = await InvoiceApi.getNextInvoiceNumber(
            {
                organisation_id: organisationId,
            },
            params
        );
        const nextInvoiceNumber = get(result, successMappings.INVOICE_NEXT_INVOICE_NUMBER);
        dispatch(setNextInvoiceNumber(nextInvoiceNumber));
        // eslint-disable-next-line no-empty
    } catch (errors) {
    } finally {
        dispatch(setNextInvoiceNumberLoading(false));
    }
};

/**
 * Remove invoices loading Action
 *
 * @returns {object} Remove invoices loading action
 */
export const removeInvoicesLoading = () => async dispatch => {
    dispatch(setLoader(false));
};

/**
 * Remove invoice statuses loading action
 *
 * @returns {object} Remove invoice statuses loading action
 */
export const removeInvoiceStatusesLoading = () => async dispatch => {
    dispatch(setInvoiceStatusLoader(false));
};

/* Update invoice action
 *
 * @param {string} organisationId Organisation Id
 * @param {string} contactId Contact Id
 * @param {string} invoiceId Invoice Id
 * @param {object} invoiceDetails Invoice details
 * @callback Callback function
 * @callback callback that closes the campaign modal
 *
 * @returns {object} Update Invoice action
 */
export const updateInvoice = (
    organisationId,
    contactId,
    invoiceId,
    invoiceDetails,
    callback,
    closeCampaignModal = () => {}
) => async dispatch => {
    try {
        dispatch(setInvoiceUpdateLoader(true));
        const result = await InvoiceApi.updateInvoice({
            organisation_id: organisationId,
            contact_id: contactId,
            invoice_id: invoiceId,
            invoiceDetails,
        });
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            const newContactId = get(result, successMappings.GET_INVOICE_DETAILS_CONTACT_ID);
            callback(newContactId);
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'INVOICE_FORM_NAME', 'INVOICE', '', '', '', [LINE_ITEMS, SEND_SETTINGS]));
        const sendSettingsError = get(errors, SEND_SETTINGS_ERROR);
        if (sendSettingsError) {
            dispatch(stopSubmit(constants.ON_DEMAND_CAMPAIGN_FORM_NAME, sendSettingsError));
        } else {
            closeCampaignModal();
        }
    } finally {
        dispatch(setInvoiceUpdateLoader(false));
    }
};

/* Update invoice payment action
 *
 * @param {string} organisationId Organisation Id
 * @param {string} contactId Contact Id
 * @param {string} invoiceId Invoice Id
 * @param {object} values Values to update
 * @param {Function} callback Callback function
 *
 * @returns {object} Update Invoice payment action
 */
export const updateInvoicePayment = (
    organisationId,
    contactId,
    invoiceId,
    paymentId,
    values,
    callback
) => async dispatch => {
    try {
        dispatch(setInvoicePaymentUpdateLoader(true));
        const result = await InvoiceApi.updateInvoicePayment({
            organisationId,
            contactId,
            invoiceId,
            paymentId,
            ...values,
        });
        // dispatch(setInvoicePaymentUpdateSuccessResponse(result));
        dispatch(setInvoiceDetails(get(result, successMappings.GET_INVOICE_DETAILS)));
        dispatch(
            setInvoicePayments({
                value: get(result, successMappings.GET_PAYMENTS_HISTORY),
                pagination: getPaginationData({ result }),
            })
        );
        dispatch(showApiSuccess(result));
        callback && callback();
    } catch (errors) {
        dispatch(showApiErrors(errors, 'EDIT_PAYMENT_HISTORY_FORM_NAME', 'INVOICE_PAYMENT', '', [PAID_ON]));
    } finally {
        dispatch(setInvoicePaymentUpdateLoader(false));
    }
};

/**
 * Get invoice preview action
 *
 * @param {string} organisationId organisation id
 * @param {object} invoiceDetails invoice details
 * @callback openPreviewModal callback that opens the preview modal
 *
 * @returns get invoice preview action
 */
export const getInvoicePreview = (organisationId, invoiceDetails) => async dispatch => {
    dispatch(setPreviewLoader(true));
    try {
        const res = await InvoiceApi.getInvoicePreview({ organisationId, invoiceDetails });
        dispatch(setPreview(res.data));
    } catch (errors) {
        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: UNKNOWN_ERROR,
            messageType: constants.ERROR,
        });
    } finally {
        dispatch(setPreviewLoader(false));
    }
};

/*
 * Update the invoices status
 *
 * @param {string} organisationId Organisation id
 * @param {object} invoicesData invoices data
 * @param {string} contactId Contact id. Default ''
 */
export const updateInvoicesStatus = (organisationId, invoicesData, contactId = '') => async dispatch => {
    try {
        dispatch(setLoader(true));
        dispatch(setDetailsUpdatedMessage(''));
        const response = await InvoiceApi.updateInvoicesStatus({
            organisation_id: organisationId,
            contact_id: contactId,
            invoicesData,
        });
        dispatch(setDetailsUpdatedMessage(get(response, successMappings.API_RESPONSE_MESSAGE_STRUCTURE)));
        dispatch(showApiSuccess(response));
    } catch (errors) {
        dispatch(setLoader(false));
        dispatch(setDetailsUpdatedMessage(''));
        dispatch(showApiErrors(errors, '', 'INVOICE_STATUS_UPDATE', '', ['status', 'ids', 'invoice_cat_type']));
    }
};

/*
 * Update the invoices type
 *
 * @param {string} organisationId Organisation id
 * @param {object} invoicesData invoices data
 * @param {string} contactId Contact id. Default ''
 */
export const updateInvoicesType = (organisationId, invoicesData, contactId = '') => async dispatch => {
    try {
        dispatch(setLoader(true));
        dispatch(setDetailsUpdatedMessage(''));
        const response = await InvoiceApi.updateInvoicesType({
            organisation_id: organisationId,
            contact_id: contactId,
            invoicesData,
        });
        dispatch(setDetailsUpdatedMessage(get(response, successMappings.API_RESPONSE_MESSAGE_STRUCTURE)));
        dispatch(showApiSuccess(response));
    } catch (errors) {
        dispatch(setLoader(false));
        dispatch(setDetailsUpdatedMessage(''));
        dispatch(showApiErrors(errors, '', 'INVOICE_TYPE_UPDATE', '', ['type', 'ids', 'invoice_cat_type']));
    }
};

export default invoicesSlice.reducer;

/**
 * Payment arrangement API class.
 * Handles all api requests related to payment arrangement
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import base api handler
import BaseApiHandler from './base/api';

class PaymentArrangementApi extends BaseApiHandler {
    /**
     * Add Payment plan
     *
     * @param {string} organisationId Organisation id.
     * @param {object} payload Payload data to update
     *
     * @returns {object} API Response
     */
    addPaymentPlan = (organisationId, payload) =>
        this.axios.post(this.getApiUrl('PAYMENT_PLANS_CRUD', { organisation_id: organisationId }), payload);

    /**
     * Add Payment arrangement note
     *
     * @param {string} organisationId Organisation id.
     * @param {string} paymentArrangementId Payment arrangement id
     * @param {object} payload Payload data to update
     *
     * @returns {object} API Response
     */
    addPaymentArrangementNote = (organisationId, paymentArrangementId, payload) =>
        this.axios.post(
            this.getApiUrl('PAYMENT_ARRANGEMENT_NOTES_CRUD', {
                organisation_id: organisationId,
                payment_arrangement_id: paymentArrangementId,
            }),
            payload
        );

    /**
     * Cancel payment arrangement installment
     *
     * @param {string} organisationId Organisation ID
     * @param {string} paymentArrangementId Payment arrangement id
     * @param {string} paymentArrangementInstallmentId Payment arrangement installment id
     *
     * @returns {object} API Response
     */
    cancelPaymentArrangementInstallment = (organisationId, paymentArrangementId, paymentArrangementInstallmentId) =>
        this.axios.delete(
            this.getApiUrl('PAYMENT_ARRANGEMENT_INSTALLMENT_CRUD', {
                organisation_id: organisationId,
                payment_arrangement_id: paymentArrangementId,
                payment_arrangement_installment_id: paymentArrangementInstallmentId,
            })
        );

    /**
     * Delete Payment arrangement note
     *
     * @param {string} organisationId Organisation id.
     * @param {string} paymentArrangementId Payment arrangement id
     * @param {string} paymentArrangementNoteId Payment arrangement note id
     *
     * @returns {object} API Response
     */
    deletePaymentArrangementNote = (organisationId, paymentArrangementId, paymentArrangementNoteId) =>
        this.axios.delete(
            this.getApiUrl('PAYMENT_ARRANGEMENT_NOTE_CRUD', {
                organisation_id: organisationId,
                payment_arrangement_id: paymentArrangementId,
                payment_arrangement_note_id: paymentArrangementNoteId,
            })
        );
    /**
     * Delete payment plan
     *
     * @param {string} organisationId organisation Id.
     * @param {string} paymentPlanId Payment plan Id
     *
     * @returns {object} API Response
     */
    deletePaymentPlan = (organisationId, paymentPlanId) =>
        this.axios.delete(
            this.getApiUrl('PAYMENT_PLAN_CRUD', {
                payment_plan_id: paymentPlanId,
                organisation_id: organisationId,
            })
        );

    /**
     * Get payment arrangement
     *
     * @param {string} organisationId Organisation ID
     * @param {string} paymentArrangementId Payment arrangement id
     *
     * @returns {object} API Response
     */
    getPaymentArrangement = (organisationId, paymentArrangementId) =>
        this.axios.get(
            this.getApiUrl('PAYMENT_ARRANGEMENT_CRUD', {
                organisation_id: organisationId,
                payment_arrangement_id: paymentArrangementId,
            })
        );

    /** Get payment arrangement installment
     *
     * @param {string} organisationId Organisation ID
     * @param {string} paymentArrangementId Payment arrangement id
     * @param {string} paymentArrangementInstallmentId Payment arrangement installment id
     *
     * @returns {object} API Response
     */
    getPaymentArrangementInstallment = (organisationId, paymentArrangementId, paymentArrangementInstallmentId) =>
        this.axios.get(
            this.getApiUrl('PAYMENT_ARRANGEMENT_INSTALLMENT_CRUD', {
                organisation_id: organisationId,
                payment_arrangement_id: paymentArrangementId,
                payment_arrangement_installment_id: paymentArrangementInstallmentId,
            })
        );

    /**
     * Get payment arrangement installments
     *
     * @param {string} organisationId Organisation ID
     * @param {string} paymentArrangementId Payment arrangement id
     * @param {object} searchParams Search Params
     *
     * @returns {object} API Response
     */
    getPaymentArrangementInstallments = (organisationId, paymentArrangementId, searchParams) =>
        this.axios.get(
            this.getApiUrl('PAYMENT_ARRANGEMENT_INSTALLMENTS_CRUD', {
                organisation_id: organisationId,
                payment_arrangement_id: paymentArrangementId,
            }),
            {
                params: searchParams,
            }
        );

    /**
     * Get payment arrangement notes
     *
     * @param {string} organisationId Organisation ID
     * @param {string} paymentArrangementId Payment arrangement id
     * @param {object} searchParams Search Params
     *
     * @returns {object} API Response
     */
    getPaymentArrangementNotes = (organisationId, paymentArrangementId, searchParams) =>
        this.axios.get(
            this.getApiUrl('PAYMENT_ARRANGEMENT_NOTES_CRUD', {
                organisation_id: organisationId,
                payment_arrangement_id: paymentArrangementId,
            }),
            {
                params: searchParams,
            }
        );

    /**
     * Get the payment arrangements
     *
     * @param {string} organisationId Organisation ID
     * @param {object} searchParams Search Params
     *
     * @returns {object} API Response
     */
    getPaymentArrangements = (organisationId, searchParams) =>
        this.axios.get(
            this.getApiUrl('PAYMENT_ARRANGEMENTS_CRUD', {
                organisation_id: organisationId,
            }),
            {
                params: searchParams,
            }
        );

    /**
     * Get the payment plan
     *
     * @param {string} organisationId organisation Id.
     * @param {string} paymentPlanId Payment plan Id
     *
     * @returns {object} API Response
     */
    getPaymentPlan = (organisationId, paymentPlanId) =>
        this.axios.get(
            this.getApiUrl('PAYMENT_PLAN_CRUD', {
                payment_plan_id: paymentPlanId,
                organisation_id: organisationId,
            })
        );

    /**
     * Get the payment plans
     *
     * @param {string} organisationId Organisation ID
     * @param {object} searchParams Search Params
     *
     * @returns {object} API Response
     */
    getPaymentPlans = (organisationId, searchParams) =>
        this.axios.get(
            this.getApiUrl('PAYMENT_PLANS_CRUD', {
                organisation_id: organisationId,
            }),
            {
                params: searchParams,
            }
        );

    /**
     * Pay payment arrangement in full
     *
     * @param {string} organisationId Organisation ID
     * @param {string} paymentArrangementId Payment arrangement id
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    payPaymentArrangementInFull = (organisationId, paymentArrangementId, payload) =>
        this.axios.patch(
            this.getApiUrl('PAYMENT_ARRANGEMENT_PAY_IN_FULL_CRUD', {
                organisation_id: organisationId,
                payment_arrangement_id: paymentArrangementId,
            }),
            payload
        );

    /**
     * Regenerate payment arrangement installments
     *
     * @param {string} organisationId Organisation ID
     * @param {string} paymentArrangementId Payment arrangement id
     *
     * @returns {object} API Response
     */
    regeneratePaymentArrangementInstallments = (organisationId, paymentArrangementId) =>
        this.axios.patch(
            this.getApiUrl('PAYMENT_ARRANGEMENT_INSTALLMENTS_CRUD', {
                organisation_id: organisationId,
                payment_arrangement_id: paymentArrangementId,
            })
        );

    /**
     * Update payment arrangement
     *
     * @param {string} organisationId Organisation ID
     * @param {string} paymentArrangementId Payment arrangement id
     * @param {object} payload Payload data to update
     *
     * @returns {object} API Response
     */
    updatePaymentArrangement = (organisationId, paymentArrangementId, payload) =>
        this.axios.patch(
            this.getApiUrl('PAYMENT_ARRANGEMENT_CRUD', {
                organisation_id: organisationId,
                payment_arrangement_id: paymentArrangementId,
            }),
            payload
        );

    /**
     * Update payment arrangement installment
     *
     * @param {string} organisationId Organisation ID
     * @param {string} paymentArrangementId Payment arrangement id
     * @param {string} paymentArrangementInstallmentId Payment arrangement installment id
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    updatePaymentArrangementInstallment = (
        organisationId,
        paymentArrangementId,
        paymentArrangementInstallmentId,
        payload
    ) =>
        this.axios.patch(
            this.getApiUrl('PAYMENT_ARRANGEMENT_INSTALLMENT_CRUD', {
                organisation_id: organisationId,
                payment_arrangement_id: paymentArrangementId,
                payment_arrangement_installment_id: paymentArrangementInstallmentId,
            }),
            payload
        );

    /**
     * Update Payment arrangement note
     *
     * @param {string} organisationId Organisation id.
     * @param {string} paymentArrangementId Payment arrangement id
     * @param {string} paymentArrangementNoteId Payment arrangement note id
     * @param {object} payload Payload data to update
     *
     * @returns {object} API Response
     */
    updatePaymentArrangementNote = (organisationId, paymentArrangementId, paymentArrangementNoteId, payload) =>
        this.axios.patch(
            this.getApiUrl('PAYMENT_ARRANGEMENT_NOTE_CRUD', {
                organisation_id: organisationId,
                payment_arrangement_id: paymentArrangementId,
                payment_arrangement_note_id: paymentArrangementNoteId,
            }),
            payload
        );

    /**
     * Update the payment plan
     *
     * @param {string} organisationId Organisation id
     * @param {string} paymentPlanId Payment plan id
     * @param {object} payload Payload data to update
     *
     * @returns {object} API Response
     */
    updatePaymentPlan = (organisationId, paymentPlanId, payload) =>
        this.axios.patch(
            this.getApiUrl('PAYMENT_PLAN_CRUD', {
                payment_plan_id: paymentPlanId,
                organisation_id: organisationId,
            }),
            payload
        );
}

export default new PaymentArrangementApi();

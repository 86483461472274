/**
 * Reminder Saga
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import the required modules
import * as actionTypes from '../../../../constants/actionTypes';
import * as constants from '../../../../constants';
import * as errorMappings from '../../../../constants/mappings/errors';
import * as errorMessages from '../../../../constants/messages/errors';
import * as requestKeys from '../../../../constants/keys/request';
import * as successMappings from '../../../../constants/mappings/success';
import BaseSagaHandler from '../../core/base';
import ReminderApi from '../../../../services/customer/reminder';
import { API_RESPONSE_MESSAGE_STRUCTURE } from '../../../../constants/mappings/success';
import { get, isEmpty, isFunction } from 'lodash';
import { redirect } from '../../../../utils';
import { stopSubmit } from 'redux-form';
import { takeLatest, all, put, call } from 'redux-saga/effects';
import { ZENDESK_SETTINGS } from 'includes/constants/keys/response';

/**
 * Reminder Saga Class. Handles the notification related operations
 * */
class ReminderSaga extends BaseSagaHandler {
    /**
     * The Reminder Watcher Saga
     * Watches the redux actions related to reminders and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *reminderWatchers() {
        let context = this;
        yield all([
            yield takeLatest(actionTypes.ADD_CAMPAIGN_REQUEST, [context, 'addCampaign']),
            yield takeLatest(actionTypes.CHANGE_CAMPAIGN_STATUS_REQUEST, [context, 'changeCampaignStatus']),
            yield takeLatest(actionTypes.GET_CAMPAIGN_DAYS_REQUEST, [context, 'getCampaignDays']),
            yield takeLatest(actionTypes.GET_CAMPAIGN_DETAILS_REQUEST, [context, 'getCampaignDetails']),
            yield takeLatest(actionTypes.GET_CAMPAIGNS_REQUEST, [context, 'getCampaigns']),
            yield takeLatest(actionTypes.GET_REMINDER_DETAILS_REQUEST, [context, 'getReminderDetails']),
            yield takeLatest(actionTypes.GET_REMINDERS_REQUEST, [context, 'getReminders']),
            yield takeLatest(actionTypes.SEND_ON_DEMAND_CAMPAIGN_REQUEST, [context, 'sendOnDemandCampaign']),
            yield takeLatest(actionTypes.SEND_REMINDER_REQUEST, [context, 'sendReminder']),
            yield takeLatest(actionTypes.UPDATE_CAMPAIGN_REQUEST, [context, 'updateCampaign']),
        ]);
    }

    /**
     * Add a campaign
     *
     * @param {object} payload Payload
     * @param {string} redirectUrl Redirect url
     * @param {Function} callback Callback function
     *
     * @yields {object} Add campaign response
     */
    *addCampaign({ payload, redirectUrl, callback }) {
        try {
            const result = yield call(ReminderApi.addCampaign, payload);
            yield put({ type: actionTypes.ADD_CAMPAIGN_SUCCESS, result });
            yield this.showNotificationModal(result, true);
            redirect(redirectUrl);
        } catch (errors) {
            yield put({ type: actionTypes.ADD_CAMPAIGN_FAILURE, errors });
            // check if there is any subscription errors in the response
            const campaignExistsError = get(errors, errorMappings.CAMPAIGN_ADD_CAMPAIGN_EXISTS_ERROR);
            if (!isEmpty(campaignExistsError) && callback && isFunction(callback)) {
                callback(campaignExistsError);
            } else {
                yield put(
                    stopSubmit(
                        constants.ADD_EDIT_SCHEDULER_FORM_NAME,
                        this.getApiErrors(
                            errors,
                            constants.ADD_CAMPAIGN_DEFAULT_ERROR,
                            constants.ADD_CAMPAIGN_DEFAULT_ERROR
                        )
                    )
                );
            }
        }
    }

    /**
     * Change the campaign status
     *
     * @param {object} payload Payload
     *
     * @yields {object} Change campaign status response
     */
    *changeCampaignStatus({ payload }) {
        try {
            const result = yield call(ReminderApi.changeCampaignStatus, payload);
            yield put({ type: actionTypes.CHANGE_CAMPAIGN_STATUS_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            yield put({ type: actionTypes.CHANGE_CAMPAIGN_STATUS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(
                    errors,
                    errorMappings.CHANGE_CAMPAIGN_STATUS,
                    errorMessages.CHANGE_CAMPAIGN_STATUS_DEFAULT_ERROR
                ),
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get the campaign days
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get campaign days response
     */
    *getCampaignDays({ payload }) {
        try {
            //call the API and get the results
            const result = yield call(ReminderApi.getCampaignDays, payload);
            yield put({ type: actionTypes.GET_CAMPAIGN_DAYS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_CAMPAIGN_DAYS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(errors, errorMappings.GET_CAMPAIGN_DAYS, errorMessages.GET_CAMPAIGN_DAYS_DEFAULT_ERROR),
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get the campaign details
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get campaign details response
     */
    *getCampaignDetails({ payload }) {
        try {
            const result = yield call(ReminderApi.getCampaignDetails, payload);
            yield put({ type: actionTypes.GET_CAMPAIGN_DETAILS_SUCCESS, result });
            yield put({ type: actionTypes.GET_COMMUNICATION_TEMPLATE_DETAILS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_CAMPAIGN_DETAILS_FAILURE, errors });
            yield put({ type: actionTypes.GET_COMMUNICATION_TEMPLATE_DETAILS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(
                    errors,
                    errorMappings.GET_CAMPAIGN_DETAILS,
                    errorMessages.GET_CAMPAIGN_DETAILS_DEFAULT_ERROR
                ),
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get the campaigns
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get campaigns response
     */
    *getCampaigns({ payload }) {
        try {
            const result = yield call(ReminderApi.getCampaigns, payload);
            yield put({ type: actionTypes.GET_CAMPAIGNS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_CAMPAIGNS_FAILURE, errors });

            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(errors, errorMappings.GET_CAMPAIGNS, errorMessages.GET_CAMPAIGNS_DEFAULT_ERROR),
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get the reminder details
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get Reminder details response
     */
    *getReminderDetails({ payload }) {
        try {
            const result = yield call(ReminderApi.getReminderDetails, payload);
            yield put({ type: actionTypes.GET_REMINDER_DETAILS_SUCCESS, result });
            yield put({ type: actionTypes.GET_COMMUNICATION_TEMPLATE_DETAILS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_COMMUNICATION_TEMPLATE_DETAILS_FAILURE, errors });
            yield put({ type: actionTypes.GET_REMINDER_DETAILS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: errorMessages.UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get the reminders
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get Reminders response
     */
    *getReminders({ payload }) {
        try {
            const result = yield call(ReminderApi.getReminders, payload);
            yield put({ type: actionTypes.GET_REMINDERS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_REMINDERS_FAILURE, errors });
        }
    }

    /**
     * Send the on-demand campaign
     *
     * @param {object} payload Payload
     * @param {Function} callback Callback function
     * @param {Function} callback1 Callback function
     *
     * @yields {object} Send on-demand campaign response
     */
    *sendOnDemandCampaign({ payload, callback, callback1 }) {
        try {
            const result = yield call(ReminderApi.sendOnDemandCampaign, payload);
            yield put({ type: actionTypes.SEND_ON_DEMAND_CAMPAIGN_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            if (isFunction(callback)) {
                callback();
            }
        } catch (errors) {
            yield put({ type: actionTypes.SEND_ON_DEMAND_CAMPAIGN_FAILURE, errors });
            // check if there is any subscription errors in the response
            const invoicesConfirmError = get(errors, errorMappings.ON_DEMAND_CAMPAIGN_INVOICES_CONFIRM_ERROR);
            if (!isEmpty(invoicesConfirmError) && callback1 && isFunction(callback)) {
                callback1(invoicesConfirmError);
            } else {
                const formFields = [
                    requestKeys.NOTIFICATION_TYPE_ID,
                    requestKeys.COMMUNICATION_TEMPLATE_ID,
                    requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID,
                    requestKeys.SUBJECT,
                    requestKeys.MESSAGE,
                    requestKeys.CC,
                ];
                let apiErrors = this.getApiErrors(
                    errors,
                    constants.SEND_ON_DEMAND_CAMPAIGN_DEFAULT_ERROR,
                    constants.SEND_ON_DEMAND_CAMPAIGN_DEFAULT_ERROR
                );

                if (apiErrors) {
                    let key = '';

                    // loop through api errors and if any error has 0 index errors, add it to the default error key
                    for (key in apiErrors) {
                        if (!formFields.includes(key)) {
                            yield put({
                                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                                message: get(apiErrors, key),
                                messageType: constants.ERROR,
                            });
                            apiErrors._error = '';
                            break;
                        }
                    }
                }
                yield put(stopSubmit(constants.ON_DEMAND_CAMPAIGN_FORM_NAME, apiErrors));
            }
        }
    }

    /**
     * Send the reminder
     *
     * @param {object} payload Payload
     * @param {string} redirectUrl Redirect url
     *
     * @yields {object} Send reminder response
     */
    *sendReminder({ payload, redirectUrl }) {
        try {
            const result = yield call(ReminderApi.sendReminder, payload);
            yield put({ type: actionTypes.SEND_REMINDER_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            redirect(redirectUrl + '?reminder_sent=true', false);
        } catch (errors) {
            const formFields = [
                requestKeys.NOTIFICATION_TYPE_ID,
                requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID,
                requestKeys.COMMUNICATION_TEMPLATE_ID,
                requestKeys.SUBJECT,
                requestKeys.MESSAGE,
                requestKeys.CC,
                ZENDESK_SETTINGS,
            ];
            let apiErrors = this.getApiErrors(
                errors,
                constants.SEND_REMINDER_DEFAULT_ERROR,
                constants.SEND_REMINDER_DEFAULT_ERROR
            );

            if (apiErrors) {
                let key = '';

                // loop through api errors and if any error has 0 index errors, add it to the default error key
                for (key in apiErrors) {
                    if (!formFields.includes(key)) {
                        yield put({
                            type: actionTypes.SHOW_NOTIFICATION_MODAL,
                            message: get(apiErrors, key),
                            messageType: constants.ERROR,
                        });
                        apiErrors._error = '';
                        break;
                    }
                }
            }
            yield put(stopSubmit(constants.SEND_REMINDER_FORM, apiErrors));
            yield put({ type: actionTypes.SEND_REMINDER_FAILURE, errors });
        }
    }

    /**
     * Update the campaign
     *
     * @param {object} payload Payload
     * @param {string} redirectUrl Redirect url
     * @param {Function} callback Callback function
     *
     * @yields {object} Updated campaign response
     */
    *updateCampaign({ payload, redirectUrl, callback }) {
        try {
            const result = yield call(ReminderApi.updateCampaign, payload);
            yield put({ type: actionTypes.UPDATE_CAMPAIGN_SUCCESS, result });
            yield this.showNotificationModal(result, true);
            redirect(redirectUrl);
        } catch (errors) {
            yield put({ type: actionTypes.UPDATE_CAMPAIGN_FAILURE, errors });
            // check if there is any subscription errors in the response
            const campaignExistsError = get(errors, errorMappings.CAMPAIGN_UPDATE_CAMPAIGN_EXISTS_ERROR);
            if (!isEmpty(campaignExistsError) && callback && isFunction(callback)) {
                callback(campaignExistsError);
            } else {
                yield put(
                    stopSubmit(
                        constants.ADD_EDIT_SCHEDULER_FORM_NAME,
                        this.getApiErrors(
                            errors,
                            constants.UPDATE_CAMPAIGN_DEFAULT_ERROR,
                            constants.UPDATE_CAMPAIGN_DEFAULT_ERROR
                        )
                    )
                );
            }
        }
    }
}

//export the Reminder saga
export default new ReminderSaga().forkAllWatcherFunctions();

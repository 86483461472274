//import english translations
import appCommonErrorMessagesEn from './includes/en/common/errors';
import appCommonMessageEn from './includes/en/common/message';
import customerMessagesEn from './includes/en/customer';
import sharedMessagesEn from './includes/en/shared';
import adminMessagesEn from './includes/en/admin';

import fieldErrorMessagesEn from './includes/en/fieldErrors';
import messagesEn from './includes/en/messages';
import successMessageEn from './includes/en/success';
import titleAndMetasEn from './includes/en/titlesAndMetas';

export const translationObject = {
    en: {
        common: {
            appCommonErrorMessages: appCommonErrorMessagesEn,
            appCommonMessages: appCommonMessageEn,
            customerMessages: customerMessagesEn,
            sharedMessages: sharedMessagesEn,
            adminMessages: adminMessagesEn,
            fieldErrors: fieldErrorMessagesEn,
            messages: messagesEn,
            successMessages: successMessageEn,
            titleAndMetas: titleAndMetasEn,
        },
    },
};

/**
 * useTaxes
 * Fetch taxes and caches it
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import TaxesAPI from 'includes/services/taxes';
import useCachedData from './useCachedData';
import useOrganisationId from './useOrganisationId';
import { get } from 'lodash';
import { TAXES } from 'includes/constants/mappings/success';
import { CACHE_TAXES_KEY } from '../constants';

/**
 * Fetches taxes if cached else call the API and caches the result
 *
 * @param {boolean} disableApiCall should disable API call
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useTaxes = (disableApiCall = false) => {
    const organisationId = useOrganisationId();
    return useCachedData(CACHE_TAXES_KEY, () => TaxesAPI.getTaxes({ page_size: 0 }, organisationId), {
        selector: res => get(res, TAXES),
        disableApiCall,
        dependsOnOrg: true,
    });
};

export default useTaxes;

/**
 * Reminder API class.
 * Handles all api requests related to notifications
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class ReminderApi extends BaseApiHandler {
    /**
     * Call the add campaign API
     *
     * @param {string} organisationId Organisation Id
     * @param {object} values Values to update
     *
     * @returns {object} API Response
     */
    addCampaign = ({ organisationId, ...values }) =>
        this.axios.post(
            this.getApiUrl(organisationId ? 'SCHEDULERS_ORGANISATION_SCHEDULERS' : 'SCHEDULERS', {
                organisation_id: organisationId,
            }),
            values
        );

    /**
     * Call the campaign status change api
     *
     * @param {string} organisationId Organisation id
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    changeCampaignStatus = ({ organisationId, ...payload }) => {
        return this.axios.post(
            this.getApiUrl(organisationId ? 'CAMPAIGN_ORGANISATION_CHANGE_STATUS' : 'CAMPAIGN_CHANGE_STATUS', {
                organisation_id: organisationId,
            }),
            payload
        );
    };

    /**
     * Call the campaign days api
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getCampaignDays = payload => this.axios.get(this.getApiUrl('CAMPAIGN_GET_DAYS'), { params: { ...payload } });

    /**
     * Call the campaign details api
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getCampaignDetails = payload => {
        return this.axios.get(
            this.getApiUrl(
                payload.organisation_id ? 'SCHEDULER_ORGANISATION_GET_DETAILS' : 'SCHEDULER_GET_DETAILS',
                payload
            )
        );
    };

    /**
     * Call the campaigns api
     *
     * @param {string} organisation_id Organisation id
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getCampaigns = ({ organisation_id, ...payload }) => {
        return this.axios.get(
            this.getApiUrl(organisation_id ? 'SCHEDULERS_ORGANISATION_SCHEDULERS' : 'SCHEDULERS', { organisation_id }),
            { params: { ...payload } }
        );
    };

    /**
     * Call the reminder details API
     *
     * @param {string} organisationId Organisation Id
     * @param {string} reminderId Reminder Id
     *
     * @returns {object} API Response
     */
    getReminderDetails = ({ organisationId, reminderId }) =>
        this.axios.get(
            this.getApiUrl('REMINDERS_GET_DETAILS', { organisation_id: organisationId, reminder_id: reminderId })
        );

    /**
     * Call the reminder listing API
     *
     * @param {string} organisation_id Organisation id
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getReminders = ({ organisation_id, ...payload }) =>
        this.axios.get(this.getApiUrl('REMINDERS_GET_ALL', { organisation_id }), { params: { ...payload } });

    /**
     * Call the send on-demand campaign API
     *
     * @param {object} onDemandCampaignDetails On Demand Campaign details details
     * @param {object} urlParams Url params
     * @param {object} queryParams Query Params
     *
     * @returns {object} API Response
     */
    sendOnDemandCampaign = ({ onDemandCampaignDetails, urlParams, queryParams }) =>
        this.axios.post(
            this.getApiUrl('REMINDERS_SEND_ON_DEMAND_CAMPAIGN', { ...urlParams }),
            onDemandCampaignDetails,
            { params: { ...queryParams } }
        );

    /**
     * Call the send reminder API
     *
     * @param {object} reminderDetails Reminder details
     * @param {object} urlParams Url params
     *
     * @returns {object} API Response
     */
    sendReminder = ({ reminderDetails, urlParams }) =>
        this.axios.post(this.getApiUrl('REMINDERS_SEND_REMINDER', { ...urlParams }), reminderDetails);

    /**
     * Call the update campaign API
     *
     * @param {string} campaignId Campaign Id
     * @param {string} organisationId Organisation Id
     * @param {object} values Values to update
     *
     * @returns {object} API Response
     */
    updateCampaign = ({ campaignId, organisationId, ...values }) =>
        this.axios.patch(
            this.getApiUrl(organisationId ? 'SCHEDULER_ORGANISATION_GET_DETAILS' : 'SCHEDULER_GET_DETAILS', {
                scheduler_id: campaignId,
                organisation_id: organisationId,
            }),
            values
        );
}

export default new ReminderApi();

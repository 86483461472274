/**
 * Reminders Component
 *
 * @version 1.0
 */

//import required modules
import {
    React,
    connect,
    Link,
    PropTypes,
    useCallback,
    useEffect,
    useMemo,
    useState,
    withRouter,
    withTranslation,
} from '../../../includes/exports/react';
import * as constants from '../../../includes/constants';
import CustomButton from '../../shared/Button';
import InfoMessage from '../../shared/messages/InfoMessage';
import Loader from '../../shared/Loader';
import moment from 'moment';
import Table from '../../shared/ScrollableTable';
import { get, find } from 'lodash';
import { getReminders, setReminderFilter } from '../../../includes/redux/actions/customer/reminder';
import { getDefaultImageClass, getImageFromData, redirect } from '../../../includes/utils';
import ReactHtmlParser from 'react-html-parser';
import { REMINDERS_TABLE_COLUMNS } from '../../../includes/constants/columns';
import { Tabs, Tooltip, DatePicker, Button, Icon, Select, Breadcrumb } from 'antd';
import { useCheckEditPermission, useUpdateBusinessName } from '../../../includes/utils/hooks';
import './styles.scss';
import useToBEDateFormatter from 'includes/hooks/useToBEDateFormatter';
import useToFEDateFormatter from 'includes/hooks/useToFEDateFormatter';
import SearchBlock from 'components/shared/SearchBlock';
import PermissionGuard from 'components/PermissionGuard';
import { REMINDER } from 'includes/constants/permissions';
import useContactTypes from 'includes/hooks/useContactTypes';
import useCampaignNotificationDays from 'includes/hooks/useCampaignNotificationDays';
import useCommunicationTemplateTypes from 'includes/hooks/useCommunicationTemplateTypes';
import useCommunicationTypes from 'includes/hooks/useCommunicationTypes';
import useCampaigns from 'includes/hooks/useCampaigns';
import useCampaignNotificationDayDescription from 'includes/hooks/useCampaignNotificationDayDescription';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

/**
 * Reminders listing component
 */
const Reminders = props => {
    // default states
    const [activatedTabs, setActivatedTabs] = useState([]);
    const [callApiFromTable, setCallApiFromTable] = useState(true);
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [showContactHaveDemoMobileNumberOrEmailMessage, setShowContactHaveDemoMobileNumberOrEmailMessage] = useState(
        false
    );
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const contactAvatar45Key = 'CONTACT_AVATAR_45X45';
    const contactAvatarOriginalKey = 'CONTACT_AVATAR_ORIGINAL';
    const {
        campaignId,
        campaignType,
        contactId,
        contactType,
        date,
        dateFilter,
        dateRange,
        getReminders,
        invoiceId,
        isAdmin,
        mode,
        paymentArrangementId,
        reminders,
        setReminderFilter,
        type,
    } = props;

    const isDefaultMode = mode === 'default';
    const organisationId = props.organisationId || props.match.params.organisationId;
    const accountId = isAdmin ? props.accountId || props.match.params.accountId : '';

    // set filters to store
    const setDateFilter = type => setReminderFilter(constants.REMINDER_DATE_FILTER_TYPE_KEY, type);
    const setDateRange = range => setReminderFilter(constants.REMINDER_DATE_RANGE_FILTER_KEY, range);
    const setDate = date => setReminderFilter(constants.REMINDER_DATE_FILTER_KEY, date);
    const setContactType = type => setReminderFilter(constants.REMINDER_CONTACT_TYPE_FILTER_KEY, type);
    const setCampaignId = campaignId => setReminderFilter(constants.REMINDER_CAMPAIGN_FILTER_KEY, campaignId);
    const setType = type => setReminderFilter(constants.REMINDER_TYPE_FILTER, type);
    const toggleDatePicker = () => setDatePickerOpen(!datePickerOpen);
    const datePickerStatus = status => !status && toggleDatePicker();
    const convertDateToBEFormat = useToBEDateFormatter();
    const convertDateToFEFormat = useToFEDateFormatter();
    const shouldDisableApisWithoutOrganisationId = !organisationId ? true : false;

    // check if user has updated the business name
    useUpdateBusinessName();

    // check if user has permission
    useCheckEditPermission();

    const { data: campaignNotificationDays, isLoading: isCampaignDaysLoading } = useCampaignNotificationDays(
        shouldDisableApisWithoutOrganisationId
    );
    const getCampaignNotificationDayDescription = useCampaignNotificationDayDescription();
    const {
        data: communicationTemplateTypes,
        isLoading: isCommunicationTemplateTypesLoading,
    } = useCommunicationTemplateTypes();
    const { data: communicationTypes, isLoading: isCommunicationTypesLoading } = useCommunicationTypes();
    const { data: contactTypes, isLoading: isContactTypesLoading } = useContactTypes(
        shouldDisableApisWithoutOrganisationId
    );
    const { data: campaigns, isLoading: isCampaignsLoading } = useCampaigns(shouldDisableApisWithoutOrganisationId);

    /**
     * Fetch the reminders
     */
    const fetchTableData = useCallback(
        (key = '', data = {}) => {
            let searchParams = { ...data };
            if (key && key !== 'all') searchParams.notification_type = key;
            if (dateFilter === 'date') {
                date && (searchParams.date = date.isSame(moment(), 'day') ? '' : convertDateToBEFormat(date));
            } else {
                dateRange[0] && (searchParams.from_date = convertDateToBEFormat(dateRange[0]));
                dateRange[1] && (searchParams.to_date = convertDateToBEFormat(dateRange[1]));
            }

            searchParams.campaign_id = campaignId;

            switch (mode) {
                case 'contact':
                    searchParams.contact = contactId;
                    break;
                case 'invoice':
                    searchParams.invoice = invoiceId;
                    break;
                case 'payment_arrangement':
                    searchParams.payment_arrangement_id = paymentArrangementId;
                    searchParams.campaign_type = campaignType;
                    searchParams.campaign_id = '';
                    break;
                default:
                    searchParams.contact_type_id = contactType;
            }
            if (searchParam) {
                searchParams.search_param = searchParam;
            }

            setShowContactHaveDemoMobileNumberOrEmailMessage(false);
            if (organisationId) {
                getReminders({ ...searchParams, organisation_id: organisationId });
            }
        },
        [date, campaignId, contactType, dateFilter, dateRange, searchParam] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // use effect to set the activated tabs and fetch the data
    useEffect(() => {
        if (organisationId && type) {
            if (activatedTabs.includes(type)) {
                fetchTableData(type);
                setCallApiFromTable(true);
            } else if (props.forceCallApiFromTable) {
                setActivatedTabs([...activatedTabs, type]);
                setCallApiFromTable(true);
            } else {
                setActivatedTabs([...activatedTabs, type]);
            }
        }
    }, [type, fetchTableData]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get localised text
     *
     * @param {string} path
     */
    const getLocaleText = path => props.t(`customerMessages.reminders.${path}`);

    /**
     * Format the table data
     */
    const getFormattedTableData = useCallback(
        () =>
            reminders.map(n => {
                const notificationType = find(communicationTypes, { id: n.notification_type_id });
                const notificationDay = find(campaignNotificationDays, { id: n.day_id });
                const contact = n.contact;
                const contactUrlSlug = get(n, 'contact.is_manager') ? 'contact-manager' : 'contact';
                const contactTypeDetails = find(contactTypes, { slug: contact?.contact_type_slug });
                if (!get(contact, 'is_demo_mobile_number_updated')) {
                    setShowContactHaveDemoMobileNumberOrEmailMessage(true);
                }
                if (!get(contact, 'is_demo_email_updated')) {
                    setShowContactHaveDemoMobileNumberOrEmailMessage(true);
                }
                return {
                    ...n,
                    name: (
                        <span className="table-name-align">
                            <img
                                className={
                                    getDefaultImageClass(
                                        contact,
                                        contactAvatar45Key,
                                        contactAvatarOriginalKey,
                                        '45x45'
                                    ) + ' logo logo-45 '
                                }
                                src={getImageFromData(contact, contactAvatar45Key, contactAvatarOriginalKey)}
                                alt={contact.name}
                            />
                            <div>
                                <Link
                                    className="crud-link"
                                    to={
                                        isAdmin
                                            ? `/admin/accounts/organisation/${contactUrlSlug}/${accountId}/${organisationId}/${contact.id}`
                                            : `/${contactUrlSlug}/${contact.id}`
                                    }
                                >
                                    {contact.name}
                                </Link>
                            </div>
                        </span>
                    ),
                    task: (
                        <Tooltip title={n?.reason_for_rescheduling}>
                            <div className="summary-plan-details">
                                <div className="plan-days">
                                    {getCampaignNotificationDayDescription(
                                        notificationDay,
                                        get(find(campaigns, { id: get(n, 'campaign_id') }), 'campaign_type')
                                    )}
                                    {n?.reason_for_rescheduling ? (
                                        <Icon
                                            type="info-circle"
                                            theme="outlined"
                                            style={{
                                                marginLeft: '5px',
                                                verticalAlign: 'middle',
                                                color: 'rgb(206 184 143)',
                                            }}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </Tooltip>
                    ),
                    notification_date: (
                        <Tooltip title={n?.cannot_send_reason}>
                            <span>
                                {n.notification_date}
                                {contactTypeDetails?.auto_reminders && n.can_send === false && (
                                    <Icon
                                        type="info-circle"
                                        theme="outlined"
                                        style={{
                                            marginLeft: '5px',
                                            verticalAlign: 'middle',
                                            color: 'rgb(206 184 143)',
                                        }}
                                    />
                                )}
                            </span>
                        </Tooltip>
                    ),
                    notificationType: (
                        <div className="tag-linker table-button-wrapper">
                            <button className={get(notificationType, 'slug')}>{get(notificationType, 'type')}</button>
                        </div>
                    ),
                    actions: (
                        <div className="blue-bg-button">
                            {n.is_active && (
                                <span>
                                    <PermissionGuard requiredPermission={REMINDER.SEND}>
                                        <CustomButton
                                            className="green-bg-button blue-bg-button"
                                            filled
                                            onClick={() =>
                                                redirect({
                                                    pathname: isAdmin
                                                        ? `/admin/accounts/organisation/reminders/${accountId}/${organisationId}/${n.id}`
                                                        : `/reminders/${n.id}`,
                                                    state: { from: props.location.pathname },
                                                })
                                            }
                                        >
                                            View & Send
                                        </CustomButton>
                                    </PermissionGuard>
                                </span>
                            )}
                            <span>
                                <PermissionGuard requiredPermission={REMINDER.HISTORY}>
                                    <CustomButton
                                        className="green-bg-button blue-bg-button"
                                        filled
                                        style={{ marginTop: '10px' }}
                                        onClick={() =>
                                            redirect({
                                                pathname: isAdmin
                                                    ? `/admin/accounts/organisation/reminders/${accountId}/${organisationId}/${n.id}/history`
                                                    : `/reminders/${n.id}/history`,
                                                state: { from: props.location.pathname },
                                            })
                                        }
                                    >
                                        View History
                                    </CustomButton>
                                </PermissionGuard>
                            </span>
                        </div>
                    ),
                    templateType: get(
                        find(communicationTemplateTypes, { id: n.communication_template_type_id }),
                        'type'
                    ),
                };
            }),
        [reminders, communicationTypes, accountId, organisationId] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Memoized table data
     */
    const memoizedReminderTableData = useMemo(() => getFormattedTableData(), [getFormattedTableData]);

    const getCampaignsFilter = () => {
        return (
            <span className="menu right-align" style={{ width: 'auto' }}>
                {getLocaleText('filterByCampaigns')}
                <Select value={campaignId} onChange={id => setCampaignId(id)} style={{ width: 'auto' }}>
                    <option value="">All</option>
                    {campaigns.map(campaign => (
                        <Option value={campaign.id} key={campaign.id}>
                            {campaign.name}
                        </Option>
                    ))}
                </Select>
            </span>
        );
    };
    /**
     * Render table
     */
    const renderTable = () => {
        return (
            <Table
                rowKey="id"
                columns={REMINDERS_TABLE_COLUMNS}
                dataSource={memoizedReminderTableData}
                size="middle"
                loading={
                    props.isLoading ||
                    isContactTypesLoading ||
                    isCommunicationTemplateTypesLoading ||
                    isCommunicationTypesLoading ||
                    isCampaignDaysLoading ||
                    isCampaignsLoading
                }
                paginationData={props.paginationReminders}
                dataValues={[type]}
                getDataMethod={callApiFromTable || props.forceCallApiFromTable ? fetchTableData : () => {}}
                className="table-1200"
                mobileClassName="table-1200"
                searchParam={searchParam}
                setIsSearching={setIsSearching}
                {...props}
            />
        );
    };

    return (
        <div>
            {isAdmin && props.showBreadCrumb && (
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={`/admin/accounts/organisations/${accountId}`}>
                                {getLocaleText('breadcrumb.organisations')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item> {getLocaleText('breadcrumb.reminders')}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            <div className="home-content-wrapper reminders-wrapper tab-wrapper">
                <header className={`reminders-header ${isDefaultMode ? '' : 'contact-reminders'}`}>
                    {isDefaultMode && <h2 className="page-title">{getLocaleText('pageTitle')}</h2>}

                    <div className="left-align">
                        <div className="date-picker-wrapper">
                            <span className="menu">
                                {getLocaleText('filterBy')}
                                <Select
                                    value={dateFilter}
                                    onChange={filter => setDateFilter(filter)}
                                    disabled={props.isLoading}
                                >
                                    <Option value="date">{getLocaleText('dateOptions.date')}</Option>
                                    <Option value="date-range">{getLocaleText('dateOptions.dateRange')}</Option>
                                </Select>
                            </span>
                            {dateFilter === 'date' ? (
                                <>
                                    <span className="menu">
                                        <Button
                                            type="link"
                                            onClick={() => setDate(moment(date).subtract(1, 'day'))}
                                            // disabled={date.isSameOrBefore(startDate, 'day')}
                                        >
                                            {'<<'}
                                        </Button>
                                        <span>
                                            <b>
                                                {datePickerOpen ? (
                                                    <DatePicker
                                                        onChange={newdate => {
                                                            toggleDatePicker();
                                                            setDate(newdate);
                                                        }}
                                                        value={date}
                                                        showToday
                                                        onOpenChange={datePickerStatus}
                                                        open={datePickerOpen}
                                                        disabled={props.isLoading}
                                                    />
                                                ) : date.isSame(moment(), 'day') ? (
                                                    getLocaleText('today')
                                                ) : (
                                                    <span onClick={toggleDatePicker}>
                                                        {convertDateToFEFormat(date)}
                                                    </span>
                                                )}
                                            </b>
                                        </span>
                                        <Button
                                            type="link"
                                            onClick={() => setDate(moment(date).add(1, 'days'))}
                                            // disabled={date.isSameOrAfter(endDate, 'day')}
                                        >
                                            {'>>'}
                                        </Button>
                                        <Button type="link" onClick={toggleDatePicker} className="calender-button">
                                            <Icon type="calendar" />
                                        </Button>
                                    </span>
                                </>
                            ) : (
                                <RangePicker
                                    value={dateRange}
                                    onChange={range => setDateRange(range)}
                                    size="small"
                                    disabled={props.isLoading}
                                    format="DD-MM-YYYY"
                                />
                            )}
                        </div>
                        {isDefaultMode ? (
                            <span className="menu right-align">
                                {getLocaleText('filterByContactType')}
                                <Select value={contactType} onChange={type => setContactType(type)}>
                                    <option value="">All</option>
                                    {contactTypes.map(type => (
                                        <Option value={type.id} key={type.id}>
                                            {type.type}
                                        </Option>
                                    ))}
                                </Select>
                            </span>
                        ) : mode !== 'payment_arrangement' ? (
                            getCampaignsFilter()
                        ) : null}
                    </div>
                    {isDefaultMode ? <div className="left-align">{getCampaignsFilter()}</div> : null}
                </header>
                <section
                    className={`reminders-container full-wrapper box-wrapper ${
                        isDefaultMode ? 'white-box-wrapper' : ''
                    }`}
                >
                    {communicationTypes && campaignNotificationDays && communicationTemplateTypes ? (
                        <>
                            {showContactHaveDemoMobileNumberOrEmailMessage && (
                                <InfoMessage
                                    message={ReactHtmlParser(
                                        props.t(
                                            'customerMessages.reminders.info.message.demoMobileNumberOrEmailNotUpdated'
                                        )
                                    )}
                                />
                            )}
                            <section>
                                <ul className="selector-field">
                                    <li>
                                        <div className="right-align">
                                            <SearchBlock
                                                dataValues={[type]}
                                                getDataMethod={fetchTableData}
                                                isSearching={isSearching}
                                                placeholder={props.t('customerMessages.reminders.search.placeholder')}
                                                setIsSearching={setIsSearching}
                                                setSearchParam={setSearchParam}
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </section>
                            <Tabs activeKey={type} onChange={key => setType(key)}>
                                <TabPane
                                    key="all"
                                    tab={`${getLocaleText('tabs.all')}(${get(props, 'reminderCounts.0', 0)})`}
                                >
                                    {renderTable()}
                                </TabPane>
                                {communicationTypes.map(n => {
                                    return (
                                        <TabPane
                                            key={n.id}
                                            tab={`${getLocaleText(`tabs.${n.slug}`)}(${get(
                                                props,
                                                ['reminderCounts', n.id],
                                                0
                                            )})`}
                                        >
                                            {renderTable()}
                                        </TabPane>
                                    );
                                })}
                            </Tabs>
                        </>
                    ) : (
                        <Loader />
                    )}
                </section>
            </div>
        </div>
    );
};

// prop types
Reminders.propTypes = {
    accountId: PropTypes.string,
    campaignDays: PropTypes.array,
    campaignId: PropTypes.string,
    campaignType: PropTypes.string,
    communicationTypes: PropTypes.array,
    contactId: PropTypes.string,
    contactType: PropTypes.string,
    contactTypes: PropTypes.array,
    date: PropTypes.object,
    dateFilter: PropTypes.string,
    dateRange: PropTypes.array,
    forceCallApiFromTable: PropTypes.bool,
    getCommunicationTemplates: PropTypes.func,
    getReminders: PropTypes.func,
    invoiceId: PropTypes.string,
    isAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    mode: PropTypes.string,
    organisationId: PropTypes.string,
    paginationReminders: PropTypes.object,
    path: PropTypes.string,
    paymentArrangementId: PropTypes.string,
    reminders: PropTypes.array,
    setReminderFilter: PropTypes.func,
    showBreadCrumb: PropTypes.bool,
    t: PropTypes.func,
    templateTypes: PropTypes.array,
    type: PropTypes.string,
};

// default props
Reminders.defaultProps = {
    mode: 'default',
    showBreadCrumb: true,
};

// connect to store
export default connect(
    state => ({
        ...state.reminder,
        communicationTemplates: state.communication.communicationTemplates,
        communicationTypes: state.communication.communicationTypes,
        campaignId: state.reminder[constants.REMINDER_CAMPAIGN_FILTER_KEY],
        contactType: state.reminder[constants.REMINDER_CONTACT_TYPE_FILTER_KEY],
        contactTypes: state.contacts.types,
        date: state.reminder[constants.REMINDER_DATE_FILTER_KEY],
        dateFilter: state.reminder[constants.REMINDER_DATE_FILTER_TYPE_KEY],
        dateRange: state.reminder[constants.REMINDER_DATE_RANGE_FILTER_KEY],
        isAdmin: state.account.isAdmin,
        organisationId: state.organisation.selectedOrganisationId,
        templateTypes: state.communication.templateTypes,
        type: state.reminder[constants.REMINDER_TYPE_FILTER],
    }),
    {
        getReminders,
        setReminderFilter,
    }
)(withRouter(withTranslation()(Reminders)));

/**
 * Invoice Details Component
 * Handles the invoice details page view and functions related to loading invoice details view
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import React, { useEffect } from 'react';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loader from '../../shared/Loader';
import { getInvoiceDetails } from 'includes/slices/invoice';
import './styles.scss';

/**
 * Invoice details Component
 */
const InvoiceDetails = () => {
    const { organisationId, contactId, invoiceId } = useParams();
    const dispatch = useDispatch();

    // const organisationId = useSelector(state => state.organisation.selectedOrganisationId);
    const isInvoiceStatusesLoading = useSelector(state => state.invoices.isInvoiceStatusesLoading);
    const isLoading = useSelector(state => state.invoices.isInvoiceDetailsLoading);
    const invoiceDetails = useSelector(state => state.invoices.invoiceDetails);

    /**
     * Use effect to fetch invoice details
     */
    useEffect(() => {
        dispatch(getInvoiceDetails(organisationId, contactId, invoiceId));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Render the invoice details
     */
    return (
        <div className="details-wrapper invoice-details-wrapper">
            <div className="home-content-wrapper synced-name details-wrapper">
                <h2 className="page-title">Invoice</h2>
                <div className="white-box-wrapper mid-wrapper breadcrumb-spec">
                    {isLoading || isInvoiceStatusesLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <section className="details-list left-align">
                                <div className="details-profile-pic-wrapper">
                                    <img
                                        src={require('../../../assets/images/invoice_avatar.svg')}
                                        className="org-avatar logo-placeholder"
                                        alt="profile-pic"
                                    />
                                </div>
                            </section>
                            <section className="details-list right-align">
                                <div className="full-wrapper">
                                    <h3 className="profile-name">{get(invoiceDetails, 'invoice_number')}</h3>
                                    <div className="sub-detail no-text-indent">
                                        {get(invoiceDetails, 'line_of_business')}
                                    </div>
                                </div>
                                <section className="detail-summary">
                                    <ul className="upper-right-detail">
                                        <li>
                                            <header>Contact Business Name</header>
                                            <div>{get(invoiceDetails, 'contact_id.business_name')}</div>
                                        </li>
                                        <li>
                                            <header>Contact Name</header>
                                            <div>{get(invoiceDetails, 'contact_id.name')}</div>
                                        </li>
                                        <li>
                                            <header>Contact Email</header>
                                            <div>{get(invoiceDetails, 'contact_id.email')}</div>
                                        </li>
                                        <li>
                                            <header>Due Date</header>
                                            <div>{get(invoiceDetails, 'due_date')}</div>
                                        </li>
                                        <li>
                                            <header>Days overdue</header>
                                            <div>{get(invoiceDetails, 'days_overdue')}</div>
                                        </li>
                                        <li>
                                            <header>Total amount</header>
                                            <div>
                                                <span title={get(invoiceDetails, ['total_amount', 'amount'])}>
                                                    {get(invoiceDetails, ['total_amount', 'amount_short'])}
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <header>Amount paid</header>
                                            <div>
                                                <span title={get(invoiceDetails, ['amount_paid', 'amount'])}>
                                                    {get(invoiceDetails, ['amount_paid', 'amount_short'])}
                                                </span>
                                            </div>
                                        </li>
                                        <li>
                                            <header>Amount Due</header>
                                            <div>
                                                <span title={get(invoiceDetails, ['amount_due', 'amount'])}>
                                                    {get(invoiceDetails, ['amount_due', 'amount_short'])}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </section>
                            </section>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default InvoiceDetails;

/**
 * Invoices add/edit utils
 *
 * Contains a set of helper methods used for invoice calculations so that the logic
 * can be reused in the main and item form
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import { find, upperFirst } from 'lodash';

import { INVOICE_LINE_ITEM_FORM, REPEATING_DUE_DATE_TYPE, REPEAT_FREQUENCY_UNIT_OPTIONS } from 'includes/constants';

/**
 *
 * @param {number} val
 * @returns {number}
 */
export const roundTo2Decimal = val => parseFloat(parseFloatOrZero(val).toFixed(2));

/**
 * Returns flot parsed value of the passed in string, if no value is passed, returns 0
 *
 * @param {any} val
 * @returns {number}
 */
export const parseFloatOrZero = val => (val ? parseFloat(val) : 0.0);

/**
 * Calculates and returns the tax amount based on `line_amount_type` value
 *
 * @param {number} subTotal
 * @param {1 | 2} taxType
 * @param {number} taxRate
 * @param {number} taxAmount
 * @param {1 | 2 | 3} lineAmountType
 *
 * @returns {number} tax amount
 */
const getTaxAmount = (subTotal, taxType, taxId, taxAmount = 0, lineAmountType, taxes) => {
    // if the taxType is 1 (amount) then return taxAmount as it is
    if (taxType === 1) return roundTo2Decimal(taxAmount);

    let _taxAmount = 0.0;
    const { rate: taxRate = 0 } = find(taxes, { id: taxId }) || {};
    switch (lineAmountType) {
        case 1:
            _taxAmount = subTotal * (taxRate / 100);
            break;
        case 2:
            _taxAmount = subTotal * (taxRate / (100 + taxRate));
            break;
        default:
            break;
    }
    return roundTo2Decimal(_taxAmount);
};

/**
 * Calculates and returns the discounted amount
 *
 * @param {1 | 2} discountType if the line item's discount type is percentage(1) amount(2)
 * @param {number} discount line item discount value
 * @param {number} subTotal line item subTotal (quantity * unitPrice)
 *
 * @returns {number} line item discounted amount
 */
const getDiscountAmount = (discountType, discount, subTotal) => {
    if (discountType === 1) return roundTo2Decimal((subTotal * parseFloatOrZero(discount)) / 100); // `discountType` is percentage
    return roundTo2Decimal(discount); // `discountType` is amount or any other case
};

/**
 * Calculates and returns the total of the line item
 *
 * @param {object} lineItemDetails line item details
 * @param {1 | 2 | 3} lineAmountType if the line item is tax exclusive(1) inclusive(2)
 *
 * @returns {object} line item subtotal, discount and tax amount
 */

export const getItemTotalSplitUp = (
    {
        quantity = 0,
        unit_price: unitPrice = 0,
        discount_type: discountType = 1,
        discount = 0,
        tax_id: taxId,
        tax_type: taxType = 2,
        tax_amount: taxAmount = 0,
    },
    lineAmountType = 1,
    taxes
) => {
    const subTotal = parseFloatOrZero(quantity) * parseFloatOrZero(unitPrice);
    const discountAmount = getDiscountAmount(discountType, discount, subTotal);
    const tax = getTaxAmount(subTotal - discountAmount, taxType, taxId, taxAmount, lineAmountType, taxes);

    return {
        subTotal,
        discount: discountAmount,
        tax,
    };
};

/**
 * Calculates the total amount based on `lineAmountType`
 *
 * @param {number} subTotal line item subTotal (quantity * unitPrice)
 * @param {number} discount line item discount amount
 * @param {number} taxAmount line item tax amount
 * @param {number} lineAmountType if the line item is tax exclusive(1) inclusive(2)
 *
 * @returns {number} line item total amount
 */
export const getTotalAmount = (subTotal, discount, taxAmount, lineAmountType) => {
    const subTotalWithDiscount = subTotal - discount;
    if (lineAmountType === 1) return subTotalWithDiscount + taxAmount;
    return subTotalWithDiscount;
};

/**
 * Utility function to parse string to array
 * this method is used to convert redux-form number fields to Number type as they store values as string
 *
 * @param {string} val
 *
 * @returns {number} converted value
 */
export const numberParse = val => (val ? Number(val) : val);

/**
 * Returns the line item form name by combinig the default text and unique id of the line item
 *
 * @param {string} id
 *
 * @returns {string}
 */
export const getLineItemFormName = id => `${INVOICE_LINE_ITEM_FORM}_${id}`;

/**
 * Formats and returns a human readable string for repeating invoice repeat frequency
 *
 * @param {number} repeatingPeriodType
 * @param {number} repeatingPeriod
 * @param {boolean} isRepeatingInvoices
 * @returns {string}
 */
export const formatRepeatsText = (repeatingPeriodType, repeatingPeriod, isRepeatingInvoices) => {
    if (!isRepeatingInvoices) return 'N/A';
    let text = 'Every ';
    const label = repeatingPeriodType ? find(REPEAT_FREQUENCY_UNIT_OPTIONS, { id: repeatingPeriodType }).label : '';
    if (repeatingPeriodType === 5) text = label;
    else text += `${repeatingPeriod} ${label}`;
    return text;
};

/**
 * Formats and returns a human readable string for repeating invoice Due On text
 *
 * @param {number} repeatingPeriodType
 * @param {number} repeatingPeriod
 * @param {boolean} isRepeatingInvoices
 * @returns {string}
 */
export const formatRepeatingDueOnText = (repeatingPeriodType, repeatingPeriod, isRepeatingInvoices) => {
    if (!isRepeatingInvoices) return 'N/A';
    const label = repeatingPeriodType ? find(REPEATING_DUE_DATE_TYPE, { id: repeatingPeriodType }).label : '';
    if (repeatingPeriodType === 4) return upperFirst(label);
    return `${repeatingPeriod} ${label}`;
};

/**
 * Formats the passed in number to currency format
 * Currently supports formatting only to AUD (Australian Dollars)
 *
 * @param {number} amount amount to be formatted
 * @returns {string}
 */
export const formatAmountWithCurrency = amount =>
    Number(amount).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });

import { React, Link } from '../../../includes/exports/react';
import { Icon } from 'antd';

import './styles.scss';

const Unauthorized = () => (
    <div className="page-wrapper">
        <div className="paycepaid-logo">
            <Link to="/">
                <img src={require('../../../assets/images/logo-paycepaid.png')} alt="Paycepaid" />
            </Link>
        </div>

        <div className="error-page">
            <span className="error-bg">
                <img src={require('../../../assets/images/error-bg.png')} alt="error-bg" />
            </span>
            <span className="error-msg-wrapper right-align">
                <p className="error-msg-title">
                    Unauthorized <Icon type="lock" className="fnt-sz-50" theme="filled" />
                </p>
                <p className="error-msg">
                    You are not authorized to view this page. If you believe that this is an error please contact us at{' '}
                    <a href="mailto:something@paycepaid.au">
                        <b>something@paycepaid.au</b>
                    </a>
                </p>
            </span>
        </div>
    </div>
);

export default Unauthorized;

import { Modal, Typography } from 'antd';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import WhiteBoxWrapper from 'components/WhiteBoxWrapper';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { openNotificationModal } from 'includes/slices/appNotifications';
import { updatePaymentService } from 'includes/slices/paymentGateways';
import PaymentServiceFormModal from './components/FormModal';
import LoaderModal, { LOADER_MODAL_STATUS } from './components/LoaderModal';
import OAuthConnectPaymentServiceModal from './components/OAuthConnectPaymentServiceModal';
import RegisteredServiceCard from './components/RegisteredServiceCard';
import { PAYMENT_SERVICE, PAYMENT_SERVICE_RENDER_FIELD_MAP } from './constants';
import useGetPermissionWrappedFunction from 'includes/hooks/useGetPermissionWrappedFunction';
import { PAYMENT_GATEWAY } from 'includes/constants/permissions';
import { showPermissionDeniedModal } from 'components/PermissionGuard/component';

const { confirm } = Modal;
const { Title } = Typography;

/**
 * Payment Gateways
 *
 * Responsible for rendering payment gateways UI, edit form and deleting
 *
 * @since 2.8.0
 */
export default function PaymentGateways({
    formFields,
    handlePaymentServiceFormSubmit,
    handleView,
    isDeleting,
    isUpdating,
    onDelete,
    paymentGateways,
    selectedPaymentServiceForEditing,
    setSelectedPaymentServiceForEditing,
}) {
    const [selectedServiceForReauthorization, setSelectedServiceForReauthorization] = useState(null);

    const [loaderModalStatus, setLoaderModalStatus] = useState(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const organisationId = useOrganisationId();

    const paymentGateway = PAYMENT_SERVICE[selectedServiceForReauthorization?.parent_slug] ?? null;

    const formInitialValues = useMemo(() => {
        if (!selectedPaymentServiceForEditing) return null;

        let selectedPaymentGateway = find(paymentGateways, { id: selectedPaymentServiceForEditing });

        if (selectedPaymentGateway?.provider) {
            const provider = {
                id: selectedPaymentGateway.provider?.id,
                authentication_parameters: {},
                currency_id: selectedPaymentGateway?.extra_data?.currency_id,
                logo: selectedPaymentGateway?.provider?.logo,
            };

            Object.keys(selectedPaymentGateway.provider?.authentication_parameters).forEach(key => {
                provider.authentication_parameters[key] =
                    selectedPaymentGateway?.extra_data?.authentication_parameters?.[key] ?? '';
            });
            selectedPaymentGateway = {
                ...selectedPaymentGateway,
                provider,
            };
        }

        return selectedPaymentGateway;
    }, [paymentGateways, selectedPaymentServiceForEditing]);

    /**
     * Render confirm modal and on confirm, delete the payment gateway
     *
     * @param {string} paymentGatewayId payment gateway ID
     */
    const handleDelete = useGetPermissionWrappedFunction(
        PAYMENT_GATEWAY.DELETE,
        paymentGatewayId => {
            confirm({
                okButtonProps: { loading: isDeleting },
                cancelText: t('customerMessages.paymentServices.deletePaymentGatewayModal.cancelText'),
                content: t('customerMessages.paymentServices.deletePaymentGatewayModal.content'),
                okText: t('customerMessages.paymentServices.deletePaymentGatewayModal.okText'),
                title: t('customerMessages.paymentServices.deletePaymentGatewayModal.title'),
                onOk: () => {
                    setLoaderModalStatus(LOADER_MODAL_STATUS.DELETING);
                    onDelete(paymentGatewayId, () => setLoaderModalStatus(null));
                },
            });
        },
        showPermissionDeniedModal
    );

    /**
     * Toggle payment service status
     *
     * @param {object} gateway gateway details
     */
    const togglePaymentServiceStatus = useGetPermissionWrappedFunction(
        PAYMENT_GATEWAY.EDIT,
        gateway => {
            const toggleAction = () => {
                setLoaderModalStatus(
                    gateway.is_active ? LOADER_MODAL_STATUS.DEACTIVATING : LOADER_MODAL_STATUS.ACTIVATING
                );
                const paymentServiceId = gateway.id;

                dispatch(
                    updatePaymentService(
                        paymentServiceId,
                        organisationId,
                        { ...gateway, is_active: !gateway.is_active, toggle_disable: true },
                        () => {
                            setTimeout(() => {
                                setLoaderModalStatus(null);
                                dispatch(
                                    openNotificationModal(
                                        gateway.is_active ? 'Payment service disabled' : 'Payment service enabled'
                                    )
                                );
                            }, 500);
                        },
                        { disableApiSuccessNotification: true, setLoaderFalse: () => setLoaderModalStatus(null) }
                    )
                );
            };

            if (gateway.is_active) {
                Modal.confirm({
                    title: t('customerMessages.paymentServices.togglePaymentServiceStatus.confirmDisable.title'),
                    content: t('customerMessages.paymentServices.togglePaymentServiceStatus.confirmDisable.body'),
                    onOk: toggleAction,
                });
                return;
            }

            toggleAction();
        },
        showPermissionDeniedModal
    );

    const handleEdit = useGetPermissionWrappedFunction(
        PAYMENT_GATEWAY.EDIT,
        gatewayId => setSelectedPaymentServiceForEditing(gatewayId),
        showPermissionDeniedModal
    );

    const handleReauthorize = useGetPermissionWrappedFunction(
        PAYMENT_GATEWAY.EDIT,
        gateway => {
            if (gateway.connected_via_oauth) {
                setSelectedServiceForReauthorization(gateway);
                return;
            }
            setSelectedPaymentServiceForEditing(gateway.id);
        },
        showPermissionDeniedModal
    );

    return (
        <WhiteBoxWrapper>
            {paymentGateways.length > 0 ? (
                <>
                    <PaymentServiceFormModal
                        connectedViaOauth={formInitialValues?.connected_via_oauth}
                        formFields={formFields}
                        handleReauthorize={() => handleReauthorize(formInitialValues)}
                        initialValues={formInitialValues}
                        isEdit
                        loading={isUpdating}
                        open={Boolean(selectedPaymentServiceForEditing)}
                        onCancel={() => setSelectedPaymentServiceForEditing(null)}
                        onOk={handlePaymentServiceFormSubmit}
                        okLabel={t('customerMessages.paymentServices.form.action.save')}
                        paymentServiceSlug={formInitialValues?.parent_slug}
                        provider={formInitialValues?.provider}
                        RenderFieldsComponent={PAYMENT_SERVICE_RENDER_FIELD_MAP[formInitialValues?.parent_slug] ?? null}
                    />

                    <LoaderModal status={loaderModalStatus} />

                    <OAuthConnectPaymentServiceModal
                        description={paymentGateway?.connectDescription}
                        loginLabel={paymentGateway?.loginLabel}
                        signUpLabel={paymentGateway?.signupLabel}
                        serviceId={selectedServiceForReauthorization?.parent_gateway_id}
                        setIsOpen={() => setSelectedServiceForReauthorization(null)}
                        title={paymentGateway?.connectTitle}
                        visible={Boolean(selectedServiceForReauthorization)}
                    />

                    <Title className="text-left pb-4" level={4}>
                        {t('customerMessages.paymentServices.connectedPaymentServices')}
                    </Title>

                    <div className="grid sm:grid-cols-3 gap-4 mx-4">
                        {paymentGateways.map(gateway => (
                            <RegisteredServiceCard
                                account={gateway.account_name}
                                handleEdit={() => handleEdit(gateway.id)}
                                handleDelete={() => handleDelete(gateway.id)}
                                handleReauthorize={() => handleReauthorize(gateway)}
                                handleToggleStatus={() => togglePaymentServiceStatus(gateway)}
                                handleView={() => handleView(gateway.id)}
                                isActive={gateway.is_active}
                                isNoLongerSupported={
                                    !gateway.parent_is_active ||
                                    (gateway?.provider ? !gateway?.provider?.is_active : false)
                                }
                                key={gateway.id}
                                label={gateway.label}
                                provider={gateway.provider}
                                reauthorize={gateway.reauthorize}
                                slug={gateway.parent_slug}
                            />
                        ))}
                    </div>
                </>
            ) : (
                'No  payment services connected'
            )}
        </WhiteBoxWrapper>
    );
}

PaymentGateways.propTypes = {
    formFields: PropTypes.shape({
        COMMON: PropTypes.array,
        PAYMENT_SERVICE_CHARGES: PropTypes.array,
        PAYMENT_SERVICE_SPECIFIC: PropTypes.object,
    }),
    handlePaymentServiceFormSubmit: PropTypes.func,
    handleView: PropTypes.func,
    isDeleting: PropTypes.bool,
    isUpdating: PropTypes.bool,
    onDelete: PropTypes.func,
    paymentGateways: PropTypes.array,
    selectedPaymentServiceForEditing: PropTypes.string,
    setSelectedPaymentServiceForEditing: PropTypes.func,
};

import i18n from 'includes/i18n';

/**
 * Get organisation currencies columns
 *
 * @param {string} currencyCode Currency Code. Default ''
 */
export const getOrganisationCurrenciesTableColumns = (currencyCode = '') => [
    {
        title: i18n.t('messages.organisationCurrencies.list.label.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.organisationCurrencies.list.label.exchange_rate', {
            currencyCode: currencyCode,
        }),
        dataIndex: 'exchange_rate',
        key: 'exchange_rate',
    },
    {
        title: i18n.t('messages.organisationCurrencies.list.label.inverse_exchange_rate', {
            currencyCode: currencyCode,
        }),
        dataIndex: 'inverse_exchange_rate',
        key: 'inverse_exchange_rate',
    },
    {
        title: i18n.t('messages.organisationCurrencies.list.label.last_updated_date'),
        dataIndex: 'last_updated_date',
        key: 'last_updated_date',
    },
];

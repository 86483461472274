/**
 * Nav Header Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import the required modules
import { React, Link, useState, useEffect, connect, useRef } from '../../../../includes/exports/react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Icon } from 'antd';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import * as responseKeys from '../../../../includes/constants/keys/response';
import Notification from './Notification';
// import { logoutUser } from '../../../../includes/redux/actions/shared/user';
import './styles.scss';
import './rightNavStyles.scss';
import '../../navigation/AppHeaderPrimaryNav/styles.scss';
import '../../navigation/AppHeaderSecondarynav/styles.scss';
import { useCurrentRoleSlug } from '../../../../includes/utils/hooks';
import { USER_ROLE_ADMIN, USER_ROLE_ADMIN_MANAGER } from '../../../../includes/constants';
import PermissionGuard from 'components/PermissionGuard';
import HasEditPermission from '../../../HasEditPermission';
import { redirect } from 'includes/utils';
import useAccountId from 'includes/hooks/useAccountId';

const NavHeader = props => {
    const {
        menuItemSlugs,
        sideNavs,
        setOpenPopover,
        openPopover,
        setNavVisibleStatus,
        navVisibleStatus,
        userAvatar,
        accountMenu,
    } = props;

    const menuRef = useRef(null);

    const hamburgerRef = useRef(null);

    const location = useLocation();
    const currentRoleSlug = useCurrentRoleSlug();

    const roles = useSelector(state => state.user.roles);

    const accountId = useAccountId(false);

    const currentRolePermissions = roles[accountId]?.permissions ?? [];

    const [currentNav, setCurrentNav] = useState(location.pathname.substr(1));

    useEffect(() => {
        let pathname = location.pathname.substr(1);
        pathname = pathname.substr(0, pathname.includes('/') ? pathname.indexOf('/') : pathname.length);
        let selectedNav = menuItemSlugs.find(
            x => x.slug.includes(pathname) || get(x, 'subMenu', []).find(s => s.slug.includes(pathname))
        );
        if (!selectedNav) {
            sideNavs.forEach(nav => {
                selectedNav = nav.includes(pathname) ? nav : selectedNav;
            });
        }
        if (get(selectedNav, 'slug')) {
            setCurrentNav(selectedNav.slug);
        }
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Check if the default image is loaded
     *
     *
     * @returns  boolean true|false
     */
    const isDefaultImageLoaded = () => {
        return !get(
            props.userDetails,
            responseKeys.USER_AVATAR_45X45,
            get(props.userDetails, responseKeys.USER_AVATAR_ORIGINAL)
        );
    };

    /**
     * Show/hide mobile menu
     */
    const toggleMobPopup = menuSlug => {
        setOpenPopover(menuSlug === openPopover ? '' : menuSlug);
    };

    // handle closing of sider
    const handleClickOutside = event => {
        // debugger;
        if (hamburgerRef.current !== event.target && menuRef.current && !menuRef.current.contains(event.target)) {
            setNavVisibleStatus(false);
        }
    };

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get dashboard link
     *
     * @returns {string} Dashboard link
     */
    const getDashboardLink = () => {
        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return `/admin/dashboard`;
            default:
                return `/dashboard`;
        }
    };

    /**
     * Get notification link
     *
     * @returns {string} Notification link
     */
    const getNotificationLink = () => {
        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return `/admin/notifications`;
            default:
                return `/notifications`;
        }
    };

    const renderSubMenuItem = menuItem => {
        if (menuItem.children) {
            return menuItem.children;
        } else if (menuItem.requiredPermission) {
            return (
                <li key={menuItem.slug}>
                    <PermissionGuard requiredPermission={menuItem.requiredPermission} fullWidth>
                        <span onClick={() => redirect(menuItem.path)}>{menuItem.title}</span>
                    </PermissionGuard>
                </li>
            );
        } else if (menuItem.hasEditPermissionCheck) {
            return (
                <li key={menuItem.slug}>
                    <HasEditPermission type="link" link={menuItem.path}>
                        {menuItem.title}
                    </HasEditPermission>
                </li>
            );
        } else {
            return (
                <li key={menuItem.slug}>
                    <Link to={menuItem.path}>{menuItem.title}</Link>
                </li>
            );
        }
    };

    // filter out menu and submenu that the user doesn't have access to
    const memoizedMenuItems = useMemo(() => {
        const _menuItems = menuItemSlugs.filter(m =>
            m.requiredPermission ? currentRolePermissions.includes(m.requiredPermission) : true
        );
        return _menuItems.map(m => ({
            ...m,
            subMenu: m.subMenu.filter(sm =>
                sm.requiredPermission ? currentRolePermissions.includes(sm.requiredPermission) : true
            ),
        }));
    }, [menuItemSlugs, currentRolePermissions]);

    return (
        <div className="home-header-wrapper">
            <section className="common-header-wrapper">
                <h1>
                    <Link className="logo" to={getDashboardLink()} alt="logo" />
                </h1>
                <section className="custom-menu-wrapper">
                    <img
                        ref={hamburgerRef}
                        className="hamburger-menu-icon"
                        alt="organisations"
                        src={require(`../../../../assets/images/hamburger-icon.png`)}
                        onClick={() => setNavVisibleStatus(!navVisibleStatus)}
                    />
                    <div
                        ref={menuRef}
                        className={navVisibleStatus ? 'show-element header-comp' : 'hide-element header-comp'}
                    >
                        <img
                            className="close-menu-icon"
                            alt="organisations"
                            src={require(`../../../../assets/images/close-icon.svg`)}
                            onClick={() => {
                                setNavVisibleStatus(!navVisibleStatus);
                            }}
                        />
                        <ul className="home-menu-wrapper center-nav custom-responsive-nav">
                            {memoizedMenuItems.map(menu => (
                                <li
                                    className={`menu-item ${menu.slug}-icon ${
                                        currentNav === menu.slug ? 'custom-menu-selected' : ''
                                    }`}
                                    key={menu.slug}
                                >
                                    {!menu.subMenu.length > 0 ? (
                                        <div className="non-popover-menu">
                                            <PermissionGuard requiredPermission={menu.requiredPermission} fullWidth>
                                                <span
                                                    onClick={() => {
                                                        setNavVisibleStatus(false);
                                                        redirect(menu.path);
                                                    }}
                                                >
                                                    <div className="menu-border mob-only">
                                                        <img
                                                            className="menu-item mob-only inline"
                                                            alt={menu.slug}
                                                            src={require(`../../../../assets/images/${menu.slug}-highlight-icon.svg`)}
                                                        />
                                                    </div>
                                                    <div className="icon-bg">
                                                        <img
                                                            className="menu-item no-mob inline"
                                                            alt={menu.slug}
                                                            src={require(`../../../../assets/images/${menu.slug}-icon.svg`)}
                                                        />
                                                        <img
                                                            className={`menu-item active-icon desktop-highlight ${menu.slug}-highlight inline`}
                                                            alt={menu.slug}
                                                            src={require(`../../../../assets/images/${menu.slug}-highlight-icon.svg`)}
                                                        />
                                                    </div>
                                                    <div className="title">{menu.title}</div>
                                                    <div className="underline"></div>
                                                </span>
                                            </PermissionGuard>
                                        </div>
                                    ) : (
                                        <div className="menu-popover-wrapper">
                                            <section className="menu-popover" onClick={() => toggleMobPopup(menu.slug)}>
                                                <div className="menu-border mob-only">
                                                    <img
                                                        className="menu-item mob-only inline"
                                                        alt={menu.slug}
                                                        src={require(`../../../../assets/images/${menu.slug}-highlight-icon.svg`)}
                                                    />
                                                </div>
                                                <div className="icon-bg">
                                                    <img
                                                        className="menu-item no-mob inline"
                                                        alt={menu.slug}
                                                        src={require(`../../../../assets/images/${menu.slug}-icon.svg`)}
                                                    />
                                                    <img
                                                        className={`menu-item active-icon desktop-highlight ${menu.slug}-highlight inline`}
                                                        alt={menu.slug}
                                                        src={require(`../../../../assets/images/${menu.slug}-highlight-icon.svg`)}
                                                    />
                                                </div>
                                                <div className="title">
                                                    {menu.title}
                                                    <Icon
                                                        type={`${
                                                            openPopover === menu.slug ? 'caret-up' : 'caret-down'
                                                        }`}
                                                    />
                                                </div>
                                                <div className="underline"></div>
                                            </section>
                                            <div className={`sub-menu-custom ${menu.slug}-popover`}>
                                                <ul className="sub-menu-list">{menu.subMenu.map(renderSubMenuItem)}</ul>
                                            </div>
                                            <div
                                                className={`${
                                                    openPopover === menu.slug ? 'show-element' : 'hide-element'
                                                } sub-menu-mob ${menu.slug}-popover`}
                                            >
                                                <ul className="sub-menu-list">
                                                    {menu.subMenu.map(s =>
                                                        s.children ? (
                                                            s.children
                                                        ) : (
                                                            <li key={s.slug} onClick={() => setNavVisibleStatus(false)}>
                                                                <Link to={s.path}>{s.title}</Link>
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                        <ul className="home-menu-wrapper right-nav custom-responsive-nav">
                            {/* Notification icon start */}
                            <li className="menu-item">
                                <span className="notification-menu profile-detail menu-popover-wrapper">
                                    <div className="menu-popover" onClick={() => toggleMobPopup('notifications')}>
                                        <span className="menu-border mob-only">
                                            <div>
                                                <Badge count={props.unreadCount}>
                                                    <img
                                                        className="menu-item mob-only inline"
                                                        alt="notifications"
                                                        src={require(`../../../../assets/images/notification-highlight-icon.svg`)}
                                                    />
                                                </Badge>
                                            </div>
                                        </span>
                                        <div className="icon-bg">
                                            <Badge count={props.unreadCount}>
                                                <img
                                                    className="menu-item no-mob inline"
                                                    alt="notifications"
                                                    src={require(`../../../../assets/images/notification-icon.svg`)}
                                                />
                                                <img
                                                    className="menu-item active-icon desktop-highlight notification-highlight inline"
                                                    alt="notifications"
                                                    src={require(`../../../../assets/images/notification-highlight-icon.svg`)}
                                                />
                                            </Badge>
                                        </div>
                                        <div className="title">
                                            Notifications
                                            <Icon
                                                type={`${openPopover === 'notifications' ? 'caret-up' : 'caret-down'}`}
                                            />
                                        </div>
                                        <div className="underline"></div>
                                    </div>
                                    <div className="sub-menu-custom right-menu-popover notifications-popover">
                                        <Notification />
                                    </div>
                                    <div
                                        className={`${
                                            openPopover === 'notifications' ? 'show-element' : 'hide-element'
                                        } sub-menu-mob notifications-popover`}
                                    >
                                        <div className="see-all-link">
                                            <Link to={getNotificationLink()} onClick={() => setNavVisibleStatus(false)}>
                                                See All
                                            </Link>
                                        </div>
                                    </div>
                                </span>
                            </li>
                            {/* Notification icon end */}
                            {/* My account icon begin */}
                            <li className="menu-item">
                                <span className="menu-popover-wrapper">
                                    <div className="menu-popover" onClick={() => toggleMobPopup('accounts')}>
                                        <span className="profile-detail">
                                            <div
                                                className={
                                                    'menu-border mob-only user-header-profile-avatar' +
                                                    (isDefaultImageLoaded() ? ' is-default-image-loaded' : '')
                                                }
                                            >
                                                <img src={userAvatar} alt="img" />
                                            </div>
                                            <div
                                                className={
                                                    'icon-bg no-mob user-header-profile-avatar' +
                                                    (isDefaultImageLoaded() ? ' is-default-image-loaded' : '')
                                                }
                                            >
                                                <img src={userAvatar} alt="img" />
                                            </div>
                                            <div className="profile-name title">My Account</div>
                                            <div className="underline"></div>
                                        </span>
                                    </div>
                                    <div className="sub-menu-custom right-menu-popover accounts-popover">
                                        {accountMenu}
                                    </div>
                                    <div
                                        className={`${
                                            openPopover === 'accounts' ? 'show-element' : 'hide-element'
                                        } sub-menu-mob accounts-popover`}
                                    >
                                        {accountMenu}
                                    </div>
                                </span>
                            </li>
                        </ul>
                        {/* <AppHeaderPrimaryNav selectedNav={currentNav} />    
                        <AppHeaderSecondaryNav selectedNav={currentNav} /> */}
                    </div>
                </section>
            </section>
        </div>
    );
};

NavHeader.propTypes = {
    accountDetails: PropTypes.object,
    getOrganisations: PropTypes.func,
    location: PropTypes.object,
    logoutUser: PropTypes.func,
    organisations: PropTypes.array,
    selectedOrganisation: PropTypes.object,
    selectedOrganisationId: PropTypes.string,
    t: PropTypes.func,
    userDetails: PropTypes.object,
    isAdmin: PropTypes.bool,
    unreadCount: PropTypes.number,
    menuItemSlugs: PropTypes.array,
    sideNavs: PropTypes.array,
    openPopover: PropTypes.string,
    setOpenPopover: PropTypes.func,
    setNavVisibleStatus: PropTypes.func,
    navVisibleStatus: PropTypes.bool,
    userAvatar: PropTypes.any,
    accountMenu: PropTypes.object,
};

//Connect to store
export default connect(
    state => ({
        ...state.user,
        accountDetails: state.account.accountDetails,
        selectedOrganisation: state.organisation.selectedOrganisation,
        selectedOrganisationId: state.organisation.selectedOrganisationId,
        defaultOrganisationLogo: get(state.settings.siteSettings, responseKeys.DEFAULT_ORGANISATION_LOGO_45X45),
        defaultUserAvatar: get(state.settings.siteSettings, responseKeys.DEFAULT_USER_AVATAR_45X45),
        unreadCount: state.notification.unreadCount,
        isAdmin: state.account.isAdmin,
    }),
    {
        // logoutUser,
    }
)(NavHeader);

/**
 * On demand campaign history component
 *
 * @author Aravind JR <aravind@paycepaid.com.au>
 * @version 1.0
 */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { get } from 'lodash';

import CommunicationLogHistory from 'components/shared/CommunicationLog';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdmin from 'includes/hooks/useIsAdmin';
import { getCommunicationLogHistory } from 'includes/redux/actions/shared/communication';
import { Breadcrumb } from 'antd';

const OnDemandCampaignHistory = () => {
    const { t } = useTranslation();
    const organisationId = useOrganisationId();
    const accountId = useAccountId();
    const isAdmin = useIsAdmin();
    const dispatch = useDispatch();
    const location = useLocation();

    /**
     * Get localised text
     *
     * @param {string} path
     */
    const getLocaleText = path => t(`sharedMessages.communicationLogHistory.${path}`);

    /**
     * Fetch the communication log history
     */
    const fetchCommunicationHistoryLog = useCallback(
        // eslint-disable-next-line no-unused-vars
        (data = {}) => {
            if (organisationId) {
                dispatch(
                    getCommunicationLogHistory(organisationId, {
                        on_demand_campaign: true,
                        ...data,
                    })
                );
            }
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const getDetailsLink = useCallback(
        log => ({
            pathname: isAdmin
                ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}/on-demand-campaign/history/${log.id}`
                : `/invoices/on-demand-campaign/history/${log.id}/`,
            state: {
                from: location.pathname,
                reminderFrom: get(
                    location,
                    'state.from',
                    isAdmin ? `/admin/accounts/organisation/reminders/${accountId}/${organisationId}` : `/reminders`
                ),
            },
        }),
        [isAdmin, location, organisationId, accountId]
    );

    const breadcrumb = [
        <Breadcrumb.Item key="1">
            <Link to={isAdmin ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}` : `/invoices`}>
                {getLocaleText('breadcrumb.invoices')}
            </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key="2">{getLocaleText('breadcrumb.onDemandHistory')}</Breadcrumb.Item>,
    ];
    return (
        <CommunicationLogHistory
            getDataMethod={fetchCommunicationHistoryLog}
            getDetailsLink={getDetailsLink}
            pageTitle={t(`sharedMessages.communicationLogHistory.pageTitle.onDemandHistory`)}
            breadcrumb={breadcrumb}
        />
    );
};

export default OnDemandCampaignHistory;

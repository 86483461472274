/**
 * Shadow Root
 * A wrapper component that will scope the text html styles.
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ShadowRoot = ({ htmlContent, className }) => {
    const ref = useRef();
    const shadowRef = useRef();

    useEffect(() => {
        if (ref.current) {
            if (!shadowRef.current) {
                shadowRef.current = ref.current.attachShadow({ mode: 'closed' });
            }
            shadowRef.current.innerHTML = htmlContent;
        }
    }, [ref.current, htmlContent]); // eslint-disable-line react-hooks/exhaustive-deps

    return <div className={className} ref={ref} />;
};

ShadowRoot.propTypes = {
    className: PropTypes.string,
    htmlContent: PropTypes.string.isRequired,
};

ShadowRoot.defaultProps = {
    className: '',
};

export default ShadowRoot;

import i18n from 'includes/i18n';

/**
 * Payment arrangement installment invoices columns
 */
export const paymentArrangementInstallmentInvoicesColumns = [
    {
        title: i18n.t('messages.paymentArrangement.invoices.list.label.invoice_number'),
        dataIndex: 'invoice_number',
        key: 'invoice_number',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.paymentArrangement.invoices.list.label.total_amount'),
        dataIndex: 'amount',
        key: 'amount',
    },
];

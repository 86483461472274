import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Button from 'components/Button';
import paymentGatewayServices from 'includes/services/shared/paymentGateways';
import { Modal } from 'antd';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { get } from 'lodash';
import { GET_PAYMENT_SERVICE_AUTHORIZATION_URL } from 'includes/constants/mappings/success';

const InitialView = {
    Login: 'login',
    Signup: 'signup',
};

export default function OAuthConnectPaymentServiceModal({
    description,
    loginLabel,
    signUpLabel,
    serviceId,
    setIsOpen,
    title,
    visible,
}) {
    const [loadingAuthorizationUrlView, setLoadingAuthorizationUrl] = useState(false);

    const organisationId = useOrganisationId();

    /**
     * Handle auth click
     *
     * fetches authorization url and opens it
     *
     * @param {('login'|'signup')} view initial view to be passed into the API
     */
    const onClickAuthButton = async (view = InitialView.Login) => {
        setLoadingAuthorizationUrl(view);
        // setSelectedInitialView(view);
        try {
            const result = await paymentGatewayServices.getPaymentServiceAuthorizationUrl(serviceId, organisationId, {
                initial_view: view,
            });

            const authorizationUrl = get(result, GET_PAYMENT_SERVICE_AUTHORIZATION_URL);
            if (authorizationUrl) window.open(authorizationUrl, '_self');
        } catch (err) {
            setIsOpen(false);
            setLoadingAuthorizationUrl(null);
            Modal.error({
                title: 'Something went wrong',
                content: err?.response?.data?.errors?.authorization_url?.[0],
            });
        }
    };
    return (
        <Modal title={title} visible={visible} onCancel={() => setIsOpen(false)} footer={null} width={600}>
            <div className="text-left font-thin text-gray-400 mb-4">{description}</div>
            <div className="flex flex-col sm:flex-row justify-end gap-1">
                <Button
                    loading={loadingAuthorizationUrlView === InitialView.Signup}
                    onClick={() => onClickAuthButton(InitialView.Signup)}
                >
                    {/* {t('customerMessages.paymentServices.action.signup')} */}
                    {signUpLabel}
                </Button>
                <Button
                    loading={loadingAuthorizationUrlView === InitialView.Login}
                    filled
                    onClick={() => onClickAuthButton(InitialView.Login)}
                >
                    {/* {t('customerMessages.paymentServices.action.login')} */}
                    {loginLabel}
                </Button>
            </div>
        </Modal>
    );
}

OAuthConnectPaymentServiceModal.propTypes = {
    description: PropTypes.node,
    loginLabel: PropTypes.string,
    serviceId: PropTypes.string,
    setIsOpen: PropTypes.func,
    signUpLabel: PropTypes.string,
    title: PropTypes.string,
    visible: PropTypes.bool,
};

/**
 * useAccountId
 * extracts and returns the account id from params or from url
 * if it doesn't exist then returns the value `state.account.currentAccountId` from store
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

/**
 * Get the account Id
 * Checks if the account id exists in params if not get from store based on the input param
 *
 * @param {boolean} fromParamsOnly Whether to take account id from params only and not from the store. Default true
 *
 * @returns {string} Account Id
 */
const useAccountId = (fromParamsOnly = true) => {
    const { accountId: accountIdFromParams } = useParams();
    const accountIdFromStore = useSelector(state => state.account.currentAccountId);
    return fromParamsOnly ? accountIdFromParams : accountIdFromParams || accountIdFromStore;
};

export default useAccountId;

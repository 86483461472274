/**
 * Defines error mapping keys in API response
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// _
export const _DEFAULT_API_ERROR_RESPONSE_STRUCTURE = ['response', 'data', 'errors'];

// A
export const ACCOUNT = ['account', 0];
export const ACCOUNT_EDIT_DEFAULT_ERROR = ['update_account', 0];
export const ADD_CAMPAIGN_DEFAULT_ERROR = ['add_scheduler', 0];
export const ADD_CARD_ERROR_MESSAGE = ['add_card', 0];
export const ADD_PLACEHOLDER_DEFAULT_ERROR = ['add_placeholder', 0];
export const ADD_TEMPLATE_DEFAULT_ERROR = ['add_template', 0];
export const API_INACTIVE_AUTHORIZATION_TOKEN_ERROR_RESPONSE_STRUCTURE = [
    'response',
    'data',
    'errors',
    'inactive_authorization_token',
];
export const API_INVALID_AUTHORIZATION_TOKEN_ERROR_RESPONSE_STRUCTURE = [
    'response',
    'data',
    'errors',
    'invalid_authorization_token',
];
export const API_PERMISSION_ERROR_RESPONSE_STRUCTURE = ['response', 'data', 'errors', 'permission'];
export const AUTHORIZATION_FAILED_ERROR = ['payload', 'authorizationFailed'];
export const AUTHORIZATION_FAILED_ERROR_MESSAGE = ['payload', 'authorizationFailedMessage'];

// B
export const BANK_ACCOUNT_DETAILS_UPDATE_DEFAULT_ERROR = ['bank_accounts', 0];

// C
export const CAMPAIGN = ['campaign', 0];
export const CAMPAIGN_CONTACT_TYPE_WITH_ANOTHER_CAMPAIGN_ERROR = [
    ..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE,
    'contact_type_ids',
    'contact_type_another_campaign',
];
export const CAMPAIGN_NOTIFICATION = ['campaign_notification', 0];
export const CAMPAIGN_ADD_CAMPAIGN_EXISTS_ERROR = [
    ..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE,
    'add_scheduler',
    0,
    'scheduler_exists',
];
export const CAMPAIGN_UPDATE_CAMPAIGN_EXISTS_ERROR = [
    ..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE,
    'update_scheduler',
    0,
    'scheduler_exists',
];
export const CAMPAIGNS = ['campaigns', 0];
export const CARD_UPDATE_DEFAULT_ERROR = ['update_card', 0];
export const CHANGE_CAMPAIGN_STATUS = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'change_scheduler_status', 0];
export const CHANGE_USER_STATUS_DEFAULT_ERROR = ['change_user_status', 0];
export const CHART_OF_ACCOUNT = ['chart_of_account', 0];
export const CHART_OF_ACCOUNTS = ['chart_of_accounts', 0];
export const CONTACT_ADD_DEFAULT_ERROR = ['add_contact', 0];
export const CONTACT_EDIT_DEFAULT_ERROR = ['contact_details', 0];
export const CONTACT_DELETE_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'delete_contact', 0];
export const CONTACT_RESTORE_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'restore_contact', 0];
export const CONTACT_TYPE_UPDATE_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'contact_type_update', 0];
export const CONTACT_TYPES = ['contact_types', 0];
export const CREATE_ORDER_DEFAULT_ERROR = ['create_order', 0];
export const COUPON_ADD_TO_ACCOUNT_ACCOUNT_ID_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'account_id', 0];
export const COUPON_ADD_TO_ACCOUNT_COUPON_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'coupon', 0];
export const COUPON_ADD_TO_ACCOUNT_DEFAULT_ERROR = [
    ..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE,
    'coupon_add_to_account',
    0,
];
export const COUPON_REMOVE_FROM_ACCOUNT_COUPON_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'coupon', 0];
export const COUPON_REMOVE_FROM_ACCOUNT_ACCOUNT_ID_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'account_id', 0];
export const COUPON_REMOVE_FROM_ACCOUNT_DEFAULT_ERROR = [
    ..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE,
    'remove_coupon_from_account',
    0,
];
export const CREATE_SUBSCRIPTION_DEFAULT_ERROR = ['create_subscription', 0];

// D
export const DEFAULT_API_ERROR_RESPONSE_STRUCTURE = ['response', 'data', 'errors'];
export const DEFAULT_API_REDUCER_ERROR_RESPONSE_STRUCTURE = ['errors', ...DEFAULT_API_ERROR_RESPONSE_STRUCTURE];
export const DELETE_CARD_ERROR_MESSAGE = ['delete_card', 0];
export const DELETE_INVOICE_ERROR_RESPONSE_STRUCTURE = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'invoice_delete'];
export const DELETE_INVOICE_PAYMENT_ERROR_RESPONSE_STRUCTURE = [
    ...DEFAULT_API_ERROR_RESPONSE_STRUCTURE,
    'invoice_payment_delete',
];
export const DELETE_PLACEHOLDER_DEFAULT_ERROR = ['delete_placeholder', 0];
export const DELETE_PLAN_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'delete_plan', 0];
export const DELETE_TEMPLATE_DEFAULT_ERROR = ['delete_template', 0];
export const DELETE_USER_ERROR_RESPONSE_STRUCTURE = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'delete_manager'];

// E
export const EVENTS = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'events', 0];
export const EVENTS_CRUD = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'event_crud', 0];
export const EXPORT_DELETE_ERROR = ['export_delete', 0];
export const EXPORT_DOWNLOAD_LINK_ERROR = ['export_download_link', 0];
export const EXPORT_ERROR = ['export', 0];

// F
export const FAT_ZEBRA_VERIFICATION_TOKEN_ERROR_MESSAGE = ['verification_token', 0];

// G
export const GET_CAMPAIGN_DAYS = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'scheduler_days', 0];
export const GET_CAMPAIGN_DETAILS = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'scheduler', 0];
export const GET_INVOICE_PDF_DOWNLOAD_URL_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'invoice_pdf_link', 0];
export const GET_SOFTWARE_AUTHORIZATION_URL_FAILED = [
    ...DEFAULT_API_REDUCER_ERROR_RESPONSE_STRUCTURE,
    'authorization_url',
    0,
];
export const GET_CAMPAIGNS = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'schedulers', 0];
export const GET_TEMPLATE_DEFAULT_ERROR = ['communication_template', 0];

// I
export const IMPORT_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'import', 0];
export const IMPORT_TEMPLATE_DOWNLOAD_LINK_ERROR = ['import_template_link', 0];
export const INVENTORY_ITEM = ['inventory_item', 0];
export const INVENTORY_ITEMS = ['inventory_items', 0];
export const INVOICE = ['invoice', 0];
export const INVOICE_ADD_DEFAULT_ERROR = ['add_invoice', 0];
export const INVOICE_DELETE = ['invoice_delete', 0];
export const INVOICE_PAYMENT = ['invoice_payment', 0];
export const INVOICE_PAYMENT_ADD = ['invoice_payment_add', 0];
export const INVOICE_PAYMENT_DELETE = ['invoice_payment_delete', 0];
export const INVOICE_PAYMENT_UPDATE = ['invoice_payment_update', 0];
export const INVOICE_STATUS_UPDATE = ['invoice_status_update', 0];
export const INVOICE_TYPE_UPDATE = ['invoice_type_update', 0];
export const INVOICE_UPDATE_DEFAULT_ERROR = ['invoice_update', 0];

// N
export const NOTES = ['notes', 0];

// O
export const ON_DEMAND_CAMPAIGN_INVOICES_CONFIRM_ERROR = [
    ..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE,
    'send_on_demand_campaign',
    0,
    'invoices_confirm',
];
export const ORDER_CREATE_ERROR_MESSAGE = ['order', 0];
export const ORDER_DETAILS_ERROR_MESSAGE = ['order_details', 0];
export const ORDER_DOWNLOAD_INVOICE_ERROR_MESSAGE = ['order_pdf_link', 0];
export const ORDER_RETRY_ERROR_MESSAGE = ['update_order', 0];
export const ORDER_STATUS_ERROR_MESSAGE = ['status', 0];
export const ORDERS_ERROR_MESSAGE = ['orders', 0];
export const ORDER_SUBSCRIPTION_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'subscription'];
export const ORDER_EXISTING_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'order_existing'];
export const ORDER_TRANSACTION_CHARGE_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'transaction_charge_error'];
export const ORGANISATION_ADD_DEFAULT_ERROR = ['add_organisation', 0];
export const ORGANISATION_CURRENCIES_ERROR = ['organisation_currencies', 0];
export const ORGANISATION_DELETE_ERROR = [..._DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'delete_organisation', 0];
export const ORGANISATION_EDIT_DEFAULT_ERROR = ['edit_organisation', 0];
export const ORGANISATION_SETTINGS_DEFAULT_ERROR = ['organisation_settings', 0];

// p
export const PAYMENT_ACCOUNT_SAVE_ERROR_MESSAGE = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'payment_accounts'];
export const PAYMENT_ARRANGEMENT = ['payment_arrangement', 0];
export const PAYMENT_ARRANGEMENT_INSTALLMENT = ['payment_arrangement_installment', 0];
export const PAYMENT_ARRANGEMENT_INSTALLMENTS = ['payment_arrangement_installments', 0];
export const PAYMENT_ARRANGEMENT_NOTE = ['payment_arrangement_note', 0];
export const PAYMENT_ARRANGEMENT_NOTES = ['payment_arrangement_notes', 0];
export const PAYMENT_PLAN = ['payment_plan', 0];
export const PAYMENT_PLANS = ['payment_plans', 0];
export const PAYMENT_BANK_ACCOUNT_SAVE_ERROR = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'bank_accounts'];
export const PAYMENT_GATEWAY_SET_DEFAULT_ERROR_MESSAGE = [
    ...DEFAULT_API_ERROR_RESPONSE_STRUCTURE,
    'payment_gateway_default',
];
export const PAYMENT_GATEWAY_CRUD = ['payment_gateway', 0];
export const PAYMENT_METHODS = ['payment_methods', 0];
export const PAYMENT_METHOD = ['payment_method', 0];

// R
export const RESEND_ACTIVATION_EMAIL_DEFAULT_ERROR = ['resend_activation_email', 0];
export const RESEND_VERIFICATION_EMAIL_ERROR = ['resend_verification_email', 0];
export const RESEND_VERIFICATION_SMS_ERROR = ['resend_verification_sms', 0];
export const RETRY_ORDER_PAYMENT_ERROR_MESSAGE = ['retry_order_payment', 0];
export const REMINDER_PLAN_SEND_ERROR = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'reminder_plan'];
export const REPORT_EXPORT = ['report_export', 0];
export const RESET_PASSWORD_ERROR = ['reset_password', 0];
export const RESET_TWO_FACTOR_AUTHENTICATION_DEFAULT_ERROR = ['disable_two_factor_authentication', 0];

// S
export const SEND_ON_DEMAND_CAMPAIGN_DEFAULT_ERROR = ['send_on_demand_campaign', 0];
export const SEND_REMINDER_DEFAULT_ERROR = ['send_reminder', 0];
export const SEND_SETTINGS_ERROR = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'send_settings'];
export const SEND_TEST_MAIL_ERROR = ['send_test_email', 0];
export const SUBSCRIPTION_CANCEL_ACTIVE_SUBSCRIPTION_ERROR = [
    ...DEFAULT_API_ERROR_RESPONSE_STRUCTURE,
    'cancel_subscription',
    0,
    'subscription',
    0,
];
export const SUBSCRIPTION_CANCEL_ERROR = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'cancel_subscription', 0];
export const SUBSCRIPTION_CREATE_ACCOUNT_ERROR = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'account_id', 0];
export const SUBSCRIPTION_CREATE_DEFAULT_ERROR = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'create_subscription', 0];
export const SUBSCRIPTION_CREATE_ERROR = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'subscription', 0];
export const SUBSCRIPTION_CREATE_PLAN_ERROR = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'plan_id', 0];
export const SUBSCRIPTION_PLAN_ADD_DEFAULT_ERROR = ['create_plan', 0];
export const SUBSCRIPTION_PLAN_EDIT_DEFAULT_ERROR = ['update_plan', 0];
export const SYNC_ORGANISATION_ERROR_MESSAGE = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'organisation_sync', 0];
export const SYNC_ORGANISATION_REAUTHORIZATION_NEEDED_ERROR_MESSAGE = [
    ...DEFAULT_API_ERROR_RESPONSE_STRUCTURE,
    'organisation_sync',
    0,
    'reauthorization_needed',
];

// T
export const TAX = ['tax', 0];
export const TAXES = ['taxes', 0];
export const TRY_DEMO_COMPANY_DEFAULT_ERROR = ['try_demo_company', 0];

// U
export const UPDATE_CAMPAIGN_DEFAULT_ERROR = ['update_scheduler', 0];
export const UPDATE_CARD_ERROR_MESSAGE = ['update_card', 0];
export const UPDATE_PLACEHOLDER_DEFAULT_ERROR = ['update_placeholder', 0];
export const USER_ADD_DEFAULT_ERROR = ['add_user', 0];
export const USER_CHANGE_PASSWORD_DEFAULT_ERROR = ['change_password', 0];
export const USER_FORGOT_PASSWORD_DEFAULT_ERROR = ['forgot_password', 0];
export const USER_LOGIN_DEFAULT_ERROR = ['login', 0];
export const USER_UPDATE_DEFAULT_ERROR = ['edit_user', 0];
export const USER_REGISTRATION_DEFAULT_ERROR = ['registration', 0];
export const UPDATE_ORDER_DEFAULT_ERROR = ['update_order', 0];
export const UPDATE_TEMPLATE_DEFAULT_ERROR = ['update_template', 0];

// V
export const VERIFY_EMAIL_ERROR = ['verify_email', 0];
export const VERIFY_SOFTWARE_AUTHORIZATION = [
    ...DEFAULT_API_REDUCER_ERROR_RESPONSE_STRUCTURE,
    'verify_user_authorization',
    0,
];
export const VERIFY_PHONE_NUMBER_ERROR = [...DEFAULT_API_ERROR_RESPONSE_STRUCTURE, 'verify_phone_number', 0];
export const VERIFY_PHONE_NUMBER_DEFAULT_ERROR = ['verify_phone_number', 0];

// Z
export const ZENDESK_SETTINGS = ['zendesk_settings', 0];
export const ZENDESK_TICKET = ['zendesk_ticket', 0];
export const ZENDESK_TICKETS = ['zendesk_tickets', 0];

/**
 * useToFEDateFormatter
 * converts the date string passed to FE format (DD-MM-YYYY or based on organisation details)
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import { convertDateStringFormat } from 'includes/utils';

/**
 * Get date formatter method
 *
 * @param {boolean} isLongFormat Optional. Whether output date format should be in long or short format. Default false
 * @param {string} requiredFormat Optional. If provided, overrides the required FE format. Default null
 * @param {boolean} isConvertingFEFormat Optional. Whether we are converting a date in FE format to another format.
 * Default false
 *
 * @returns {(dateString: string) => string | null}
 */
const useToFEDateFormatter = (isLongFormat = false, requiredFormat = null, isConvertingFEFormat = false) => {
    const requiredFEFormat = requiredFormat || (isLongFormat ? 'ddd, DD MMM, YYYY' : 'DD-MM-YYYY');
    const orgBEFormat = isConvertingFEFormat ? 'DD-MM-YYYY' : 'YYYY-MM-DD';
    return dateString => convertDateStringFormat(dateString, orgBEFormat, requiredFEFormat);
};

export default useToFEDateFormatter;

/**
 * Settings Form
 * Renders the settings form
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

// import required modules
import { React, connect } from '../../../includes/exports/react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Button from '../../shared/Button';
import { Form, Row } from 'antd';
import FormField from '../../shared/FormField';
import { Field, reduxForm } from 'redux-form';
import * as formValidations from '../../../includes/utils/form';
import * as constants from '../../../includes/constants';
import * as requestKeys from '../../../includes/constants/keys/request';
import '../Settings/styles.scss';

const Settings = props => {
    const showDependentFields = item => {
        const value = get(props, ['formValues', item.id]);
        if (value) {
            return get(item, ['dependent_fields', value], []).map(q => renderField(q));
        }
        return;
    };
    /**
     * Generate the label from language file
     *
     * @param   {string}    field   Field for which locale is to be generated
     *
     * @returns {string}    Generated Locale label
     */
    const localeLabel = field => props.t(`adminMessages.settings.form.input.label.${field}`);
    const renderField = field => {
        switch (field.type) {
            case 'text': {
                return (
                    <Field
                        key={field.id}
                        id={field.id}
                        type={field.type}
                        label={field.label}
                        component={FormField}
                        name={field.id}
                        validate={[formValidations.required]}
                        placeholder={field.label}
                    />
                );
            }
            case 'select': {
                return (
                    <>
                        <Field
                            key={field.id}
                            name={field.id}
                            id={field.id}
                            type={field.type}
                            label={field.label}
                            component={FormField}
                            validate={[formValidations.required]}
                            options={Object.entries(field.dependent_values).map(([field, value]) => ({
                                value: field,
                                name: value,
                            }))}
                        />
                        {showDependentFields(field)}
                    </>
                );
            }
            case 'checkbox': {
                return (
                    <Field
                        key={field.id}
                        name={field.id}
                        id={field.id}
                        type={field.type}
                        label={field.label}
                        component={FormField}
                    />
                );
            }
            case 'image': {
                return (
                    <Field
                        key={field.id}
                        type="avatar"
                        name={requestKeys.AVATAR}
                        label={localeLabel(requestKeys.AVATAR)}
                        imageName={field.label}
                        isModalVisible={props.imagePickerVisible}
                        onSubmit={props.onImageSubmit}
                        toggleImagePicker={props.toggleImagePicker}
                        hasFeedback
                        component={FormField}
                        imageSourceSizeKey={constants.IMAGE_45x45}
                        imageSourceOriginalKey={constants.IMAGE_ORIGINAL}
                        defaultImage={field.value}
                    />
                );
            }
            default:
                return null;
        }
    };
    return (
        <div className="box-wrapper full-wrapper white-box-wrapper">
            <h2 className="page-title">{props.t('adminMessages.settings.title')}</h2>
            <Form className="" onSubmit={props.handleSubmit}>
                {props.settings &&
                    Object.keys(props.settings).map(key => {
                        return (
                            <section className="settings-content-wrapper" key={key}>
                                <Row gutter={64} key={key}>
                                    <h3 className="page-title">{key}</h3>
                                    {Object.values(props.settings[key]).map(item => {
                                        return (
                                            <Row key={item.id} span={8} className="full-wrapper">
                                                {renderField(item)}
                                            </Row>
                                        );
                                    })}
                                </Row>
                            </section>
                        );
                    })}
                <Button
                    htmlType="submit"
                    big
                    filled
                    submittingButtonLabel={props.t('adminMessages.settings.buttonSubmittingLabel')}
                    className="register-btn"
                >
                    {props.t('adminMessages.settings.buttonLabel')}
                </Button>
            </Form>
        </div>
    );
};

/**
 *Prop types
 */

Settings.propTypes = {
    t: PropTypes.func,
    settings: PropTypes.object,
    handleSubmit: PropTypes.func,
    imagePickerVisible: PropTypes.bool,
    isDetailsUpdating: PropTypes.bool,
    onImageSubmit: PropTypes.func,
    toggleImagePicker: PropTypes.func,
};
/**
 * redux-form wrapper
 */
const reduxFormWrappedSettings = reduxForm({
    form: constants.SETTINGS_FORM_NAME,
})(withTranslation()(Settings));

export default connect(
    state => ({
        formValues: get(state, ['form', constants.SETTINGS_FORM_NAME, 'values']),
    }),
    null
)(reduxFormWrappedSettings);

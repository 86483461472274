/**
 * Add user Component
 * Handles the add user view and related functions for add user
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

// import required modules
import { React, connect, Link, useState, useEffect } from '../../../includes/exports/react';
import { Breadcrumb } from 'antd';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { get, isString } from 'lodash';
import { change } from 'redux-form';

import AddEditUserForm from '../../shared/forms/AddUserForm';
import { addUser, getUserDetails, updateUser, resetErrors } from '../../../includes/redux/actions/shared/user';
import * as requestKeys from '../../../includes/constants/keys/request';
import * as responseKeys from '../../../includes/constants/keys/response';
import * as constants from '../../../includes/constants';
import { getLocalStorageValue } from '../../../includes/utils';
import { objectToFormData } from '../../../includes/utils/form';
import { getAccountTypes } from '../../../includes/redux/actions/shared/account';
import { useCheckEditPermission, useDataFromStore, useRestrictCustomerManager } from '../../../includes/utils/hooks';
import { getUserRoles } from '../../../includes/redux/actions/shared/user';

/**
 * Add user Component
 */
const AddEditUser = props => {
    const isAdd = props.action === 'add';
    const { userId } = props.match.params;

    const [imagePickerVisible, setImagePickerVisible] = useState(false);
    const toggleImagePicker = () => setImagePickerVisible(!imagePickerVisible);
    const currentAccountId = getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY);
    const accountId = props.match.params.accountId || currentAccountId;
    const { isAdmin, getUserDetails } = props;

    useCheckEditPermission();

    useRestrictCustomerManager();

    useDataFromStore({
        reducer: 'account',
        dataKey: 'accountTypes',
        loadingKey: 'isTypesLoading',
        action: getAccountTypes,
    });

    useEffect(() => {
        userId && !isAdd && getUserDetails(accountId, userId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handle the add/edit user form submit
     * Dispatches the add/edit user form submit action
     *
     * @param   {Array}    values   Array of form values
     */
    const handleSubmit = values => {
        if (requestKeys.AVATAR in values && isString(get(values, requestKeys.AVATAR))) {
            delete values[requestKeys.AVATAR];
        }

        if (isAdd) {
            props.addUser(
                objectToFormData(values),
                accountId,
                ['user_exists', 'max_users', 'add_manager', 'duplicate_manager', 'user_not_exist_in_account'],
                !!get(values, requestKeys.ROLE, '')
            );
        } else {
            props.updateUser(
                objectToFormData(values),
                accountId,
                userId,
                ['user_exists', 'max_users', 'add_manager', 'duplicate_manager', 'user_not_exist_in_account'],
                !!get(values, requestKeys.ROLE, '')
            );
        }
    };

    const getLocale = path => props.t(`sharedMessages.addUser.${path}`);
    /**
     * Render the add user form
     *
     * @returns {*}
     */
    return (
        <div className="home-content-wrapper edit-profile-wrapper">
            <div className="tab-arrow-link">
                {isAdmin ? (
                    props.path === 'accounts' ? (
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to={`/admin/accounts`}>{getLocale('breadcrumb.accounts')}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/admin/accounts/user/${accountId}`}>{getLocale('breadcrumb.users')}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{getLocale(`breadcrumb.${props.action}User`)}</Breadcrumb.Item>
                        </Breadcrumb>
                    ) : (
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to={`/admin/users`}>{getLocale('breadcrumb.users')}</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{getLocale(`breadcrumb.${props.action}User`)}</Breadcrumb.Item>
                        </Breadcrumb>
                    )
                ) : (
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={`/users`}>{getLocale('breadcrumb.users')}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{getLocale(`breadcrumb.${props.action}User`)}</Breadcrumb.Item>
                    </Breadcrumb>
                )}
            </div>
            <h2 className="page-title">{getLocale(`pageTitle.${props.action}`)}</h2>
            <div className={isAdmin ? 'white-box-wrapper full-wrapper' : 'white-box-wrapper mid-wrapper'}>
                <AddEditUserForm
                    initialValues={props.editUserDetails}
                    onSubmit={handleSubmit}
                    onImageSubmit={image => {
                        props.setField(constants.ADD_EDIT_USER_FORM, requestKeys.AVATAR, image);
                        toggleImagePicker();
                    }}
                    onCancel={toggleImagePicker}
                    imagePickerVisible={imagePickerVisible}
                    toggleImagePicker={toggleImagePicker}
                    defaultImage={require('../../../assets/images/user-avatar.svg')}
                    isAdd={isAdd}
                    {...props}
                />
            </div>
        </div>
    );
};

AddEditUser.propTypes = {
    addUser: PropTypes.func,
    action: PropTypes.string,
    t: PropTypes.func,
    userNotExistInAccount: PropTypes.bool,
    setField: PropTypes.func,
    defaultUserAvatar: PropTypes.string,
    getUserDetails: PropTypes.func,
    getUserRoles: PropTypes.func,
    editUserDetails: PropTypes.object,
    match: PropTypes.object,
    updateUser: PropTypes.func,
    getAccounts: PropTypes.func,
    path: PropTypes.string,
    isAdmin: PropTypes.bool,
    userRoles: PropTypes.array,
    isUserRolesLoading: PropTypes?.bool,
};

// connect the component to the store
export default connect(
    state => ({
        isDetailsUpdating: state.user.isDetailsUpdating,
        defaultUserAvatar: get(state.settings.siteSettings, responseKeys.DEFAULT_USER_AVATAR_254x254),
        editUserDetails: state.user.editUserDetails,
        userDetails: state.user.userDetails,
        isDetailsLoading: state.user.isDetailsLoading,
        accounts: state.account.accounts,
        accountTypes: state.account.accountTypes,
        isAdmin: state.account.isAdmin,
        userRoles: state.user.userRoles,
        isUserRolesLoading: state?.user?.isUserRolesLoading,
    }),
    {
        addUser,
        getUserDetails,
        updateUser,
        setField: change,
        resetErrors,
        getUserRoles,
    }
)(withTranslation()(AddEditUser));

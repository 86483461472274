import { padString } from 'includes/utils';
import PropTypes from 'prop-types';
import React from 'react';
/**
 * Select button
 *
 * A stylized button component used to render options when the options are limited
 * or when options can be rendered as a list
 *
 * When `isSelected` is `true`, the component will have a green border and white background,
 * and text scaled else gray background
 *
 * @since 2.8.0
 */
export default function SelectButton({ children, className = '', isSelected, ...restProps }) {
    let styleClassNames =
        'border-2 border-solid rounded-lg px-3 py-2 cursor-pointer hover:border-lime-300 transition-all duration-300';

    if (isSelected) styleClassNames += padString('border-lime-500 bg-lime-200 bg-opacity-20', ' ', 1);
    else styleClassNames += padString('border-gray-200 bg-white', ' ', 1);

    styleClassNames += padString(className, ' ', 1);

    return (
        <button type="button" className={styleClassNames} {...restProps}>
            {children}
        </button>
    );
}

SelectButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isSelected: PropTypes.bool,
};

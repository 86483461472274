import { useSelector } from 'react-redux';
/**
 * useUserHasAccessPermission
 *
 * Checks if the user has access to a specific feature
 *
 * @param {string} requiredPermission Permission to be checked
 * @returns {boolean}
 */
export default function useUserHasAccessPermission(requiredPermission) {
    const roles = useSelector(state => state.user.roles);

    const accountId = useSelector(state => state.account.currentAccountId);

    const currentRolePermissions = roles[accountId]?.permissions ?? [];

    if (Array.isArray(requiredPermission)) {
        return !!requiredPermission.find(permission => currentRolePermissions.includes(permission));
    }

    return currentRolePermissions.includes(requiredPermission);
}

/**
 * No Sidebar Home Layout
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import the required modules
import React from 'react';
import { Layout } from 'antd';
import { PropTypes } from 'prop-types';
import { ChildrenContentLayout } from './Children';
import PermissionGuardLayout from './PermissionGuardLayout';

const { Header, Footer } = Layout;

export const NoSidebarHomeLayout = ({
    children,
    featureAvailableForManualOrganisationsOnly,
    requiredPermission,
    ...sections
}) => {
    return (
        <Layout className="main-layout home-layout" style={{ minHeight: '100vh' }}>
            <Header>{sections.header && sections.header}</Header>
            <PermissionGuardLayout requiredPermission={requiredPermission}>
                <ChildrenContentLayout
                    featureAvailableForManualOrganisationsOnly={featureAvailableForManualOrganisationsOnly}
                >
                    {children}
                </ChildrenContentLayout>
            </PermissionGuardLayout>
            <Footer>{sections.footer && sections.footer}</Footer>
        </Layout>
    );
};

NoSidebarHomeLayout.propTypes = {
    children: PropTypes.any,
    featureAvailableForManualOrganisationsOnly: PropTypes.bool,
    requiredPermission: PropTypes.string,
};

/**
 * Try Demo company
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { React, connect, PropTypes, withRouter, withTranslation } from '../../../../includes/exports/react';
import Button from '../../Button';
import HasEditPermission from '../../../HasEditPermission';
import { tryDemoCompany } from '../../../../includes/redux/actions/shared/account';
import { Modal } from 'antd';
import { useState } from 'react';
import InfoMessage from '../../messages/InfoMessage';
import { get } from 'lodash';
import ReactHtmlParser from 'react-html-parser';

const { confirm, info } = Modal;

/**
 * Try Demo company component
 */
const TryDemoCompany = props => {
    // set the initial state
    const [isDemoCompanyModalOpen, setIsDemoCompanyModalOpen] = useState(true);
    const showTry = get(props.demoCompanyOptions, 'show_try', false);
    const organisationId = props.organisationId || props.match.params.organisationId;

    /**
     * Show the loading modal when try demo company is clicked
     */
    const showLoadingModal = () =>
        info({
            className: 'demo-company-please-wait-modal',
            content: <InfoMessage message={props.t('sharedMessages.demoCompany.pleaseWait')} showLoader={true} />,
            icon: <></>,
            okButtonProps: { disabled: true, className: 'demo-company-modal-ok-hidden' },
            closable: false,
            maskClosable: false,
            keyboard: false,
        });

    const tryDemoCompany = () => {
        if (organisationId) {
            info({
                icon: <></>,
                content: ReactHtmlParser(props.t('sharedMessages.demoCompany.confirmBeforeTry')),
                cancelText: '',
                onOk: () => {
                    tryDemoCompanyConfirm();
                },
                closable: false,
                maskClosable: false,
                keyboard: false,
                className: 'demo-company-confirm-before-try',
            });
        } else {
            tryDemoCompanyConfirm();
        }
    };

    /**
     * Try Demo company handler
     * Handles the click on try demo company button
     */
    const tryDemoCompanyConfirm = () => {
        confirm({
            title: props.t('sharedMessages.demoCompany.title'),
            content: props.t('sharedMessages.demoCompany.content'),
            okText: props.t('sharedMessages.demoCompany.button.try'),
            cancelText: props.t(`sharedMessages.demoCompany.button.later`),
            visible: isDemoCompanyModalOpen,
            onOk: () => {
                const loadingModal = showLoadingModal();
                props.tryDemoCompany(props.currentAccountId, true, false, loadingModal);
                setIsDemoCompanyModalOpen(false);
            },
            onCancel: () => {
                const loadingModal = showLoadingModal();
                props.tryDemoCompany(
                    props.currentAccountId,
                    false,
                    true,
                    loadingModal,
                    props.subscriptionExpiresIn,
                    !!showTry
                );
                setIsDemoCompanyModalOpen(false);
            },
            closable: false,
            maskClosable: false,
            keyboard: false,
        });
    };

    return (
        <>
            <li key="try-demo-company" className="plan-details acc-software try-demo-company-list">
                <HasEditPermission onClick={() => tryDemoCompany()}>
                    <Button filled className="blue-bg-button popover-action-btn" loading={props.isTryingDemoCompany}>
                        {props.t('sharedMessages.demoCompany.button.tryDemoCompany')}
                    </Button>
                </HasEditPermission>
            </li>
        </>
    );
};

TryDemoCompany.defaultProps = {
    demoCompanyOptions: {},
};

TryDemoCompany.propTypes = {
    currentAccountId: PropTypes.string,
    demoCompanyOptions: PropTypes.object,
    history: PropTypes.object,
    isTryingDemoCompany: PropTypes.bool,
    match: PropTypes.object,
    organisationId: PropTypes.string,
    subscriptionExpiresIn: PropTypes.string,
    tryDemoCompany: PropTypes.func,
    t: PropTypes.func,
};

//Connect to store
export default connect(
    state => ({
        currentAccountId: get(state.account, 'currentAccountId'),
        demoCompanyOptions: get(state.account.accountDetails, ['demo_company']),
        isTryingDemoCompany: state.isTryingDemoCompany,
        organisationId: state.organisation.selectedOrganisationId,
        subscriptionExpiresIn: get(state.account.accountDetails, ['subscription', 'expires_in']),
    }),
    {
        tryDemoCompany,
    }
)(withTranslation()(withRouter(TryDemoCompany)));

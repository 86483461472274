/**
 * useToBEDateFormatter
 * converts the date string passed to BE acceptable format (YYYY-MM-DD)
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import { convertDateStringFormat } from 'includes/utils';

/**
 * Get date formatter method
 *
 * @param {string} currentFormat Optional. Current Frontend format
 * If provided, overrides the default FE format. Default null
 *
 * @returns {(dateString: string) => string | null}
 */
const useToBEDateFormatter = (currentFormat = null) => {
    const requiredBEFormat = 'YYYY-MM-DD';
    const orgFEFormat = currentFormat || 'DD-MM-YYYY';
    return dateString => convertDateStringFormat(dateString, orgFEFormat, requiredBEFormat);
};

export default useToBEDateFormatter;

import i18n from 'includes/i18n';

/**
 * Get audit log columns
 */
export const getAuditLogTableColumns = [
    {
        title: i18n.t('messages.auditLogs.list.table.fieldName.account'),
        dataIndex: 'account.name',
        key: 'account',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.auditLogs.list.table.fieldName.activity_info'),
        dataIndex: 'activity_info',
        key: 'activity_info',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.auditLogs.list.table.fieldName.activity_time'),
        dataIndex: 'performed_at',
        key: 'performed_at',
        sorter: true,
    },
    {
        title: i18n.t('messages.auditLogs.list.table.fieldName.ip_address'),
        dataIndex: 'ip_address',
        key: 'ip_address',
    },
    {
        title: i18n.t('messages.auditLogs.list.table.fieldName.performed_by'),
        dataIndex: 'performed_by.name',
        key: 'performed_by',
    },
    {
        title: i18n.t('messages.auditLogs.list.table.fieldName.status'),
        dataIndex: 'success_status',
        key: 'success_status',
        sorter: true,
        filterMultiple: false,
        filters: [
            { id: '', label: i18n.t('messages.auditLogs.list.table.fieldName.all') },
            { id: false, label: i18n.t('messages.auditLogs.list.table.fieldName.failed') },
            { id: true, label: i18n.t('messages.auditLogs.list.table.fieldName.success') },
        ].map(status => ({
            text: status.label,
            value: status.id,
        })),
    },
    {
        title: i18n.t('messages.auditLogs.list.table.fieldName.actions'),
        dataIndex: 'actions',
        key: 'actions',
        className: 'audit-actions',
    },
];

/**
 * Notes API class.
 * Handles all api requests related to notes
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import base api handler
import BaseApiHandler from './base/api';

class NotesApi extends BaseApiHandler {
    /**
     * Add a note
     *
     * @param {object} values Form Values
     * @param {string} accountId Account Id
     * @param {string} organisationId Organisation Id
     * @param {string} contactId Contact Id
     * @param {string} invoiceId Invoice Id
     * @param {object} searchParams Search params. Default {}
     *
     * @returns {object} API Response
     */
    addNote = (values, accountId, organisationId = '', contactId = '', invoiceId = '', searchParams = {}) =>
        this.axios.post(this._getNotesApiUrl(accountId, organisationId, contactId, invoiceId), values, {
            params: searchParams,
        });

    /**
     * Delete a note
     *
     * @param {string} noteId Note Id
     * @param {string} accountId Account Id
     * @param {string} organisationId Organisation Id
     * @param {string} contactId Contact Id
     * @param {string} invoiceId Invoice Id
     *
     * @returns {object} API Response
     */
    deleteNote = (noteId, accountId, organisationId = '', contactId = '', invoiceId = '') =>
        this.axios.delete(this._getNotesApiUrl(accountId, organisationId, contactId, invoiceId, noteId));

    /**
     * Get the notes
     *
     * @param {string} accountId Account Id
     * @param {string} organisationId Organisation Id
     * @param {string} contactId Contact Id
     * @param {string} invoiceId Invoice Id
     * @param {object} searchParams Search Params
     *
     * @returns {object} API Response
     */
    getNotes = (accountId, organisationId = '', contactId = '', invoiceId = '', searchParams) =>
        this.axios.get(this._getNotesApiUrl(accountId, organisationId, contactId, invoiceId), { params: searchParams });

    /**
     * Get the note statuses
     *
     * @returns {object} API Response
     */
    getNoteStatuses = () => this.axios.get(this.getApiUrl('NOTE_STATUSES'));

    /**
     * Get the note types
     *
     * @returns {object} API Response
     */
    getNoteTypes = () => this.axios.get(this.getApiUrl('NOTE_TYPES'));

    /**
     * Update a note
     *
     * @param {object} values Form Values
     * @param {string} noteId Note Id
     * @param {string} accountId Account Id
     * @param {string} organisationId Organisation Id
     * @param {string} contactId Contact Id
     * @param {string} invoiceId Invoice Id
     * @param {object} searchParams Search params. Default {}
     *
     * @returns {object} API Response
     */
    updateNote = (values, noteId, accountId, organisationId = '', contactId = '', invoiceId = '', searchParams = {}) =>
        this.axios.patch(this._getNotesApiUrl(accountId, organisationId, contactId, invoiceId, noteId), values, {
            params: searchParams,
        });

    /**
     * Get notes api url
     *
     * @param {string} accountId Account Id
     * @param {string} organisationId Organisation Id
     * @param {string} contactId Contact Id
     * @param {string} invoiceId Invoice Id
     * @param {string} noteId Note Id
     *
     * @returns {string} apiUrl Notes API URL
     */
    _getNotesApiUrl = (accountId, organisationId = '', contactId = '', invoiceId = '', noteId = '') => {
        let apiUrl = '';
        if (invoiceId) {
            apiUrl = noteId
                ? 'NOTES_NOTE_ACCOUNT_ORGANISATION_CONTACT_INVOICE'
                : 'NOTES_ACCOUNT_ORGANISATION_CONTACT_INVOICE';
        } else if (contactId) {
            apiUrl = noteId ? 'NOTES_NOTE_ACCOUNT_ORGANISATION_CONTACT' : 'NOTES_ACCOUNT_ORGANISATION_CONTACT';
        } else if (organisationId) {
            apiUrl = noteId ? 'NOTES_NOTE_ACCOUNT_ORGANISATION' : 'NOTES_ACCOUNT_ORGANISATION';
        } else {
            apiUrl = noteId ? 'NOTES_NOTE_ACCOUNT' : 'NOTES_ACCOUNT';
        }

        apiUrl = this.getApiUrl(apiUrl, {
            account_id: accountId,
            organisation_id: organisationId,
            contact_id: contactId,
            invoice_id: invoiceId,
            note_id: noteId,
        });

        return apiUrl;
    };
}

export default new NotesApi();

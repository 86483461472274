/**
 * Filter component for Scrollable table
 * This is a reusable component which can be used to render scrollable data in a table
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

import { React, useState } from '../../../includes/exports/react';
import { Radio, Button } from 'antd';
import PropTypes from 'prop-types';

const Filter = props => {
    const [value, setValue] = useState(props.value);

    return (
        <ul className="custom-drop-down" ref={props.forwardRef}>
            <li className="custom-dd-list">
                <Radio.Group
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    options={props.options.map(f => ({ ...f, label: f.text }))}
                />
            </li>
            <li className="last-confirm-row custom-dd-list">
                <Button className="align-left" type="link" onClick={() => props.onSubmit(value)}>
                    OK
                </Button>
                <Button
                    className="align-right"
                    type="link"
                    onClick={() => {
                        props.onReset();
                        setValue();
                    }}
                >
                    Reset
                </Button>
            </li>
        </ul>
    );
};

Filter.propTypes = {
    forwardRef: PropTypes.object,
    onReset: PropTypes.func,
    onSubmit: PropTypes.func,
    options: PropTypes.array,
    value: PropTypes.string,
};

export default Filter;

/**
 * Notification actions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import action types
import * as actionTypes from '../../../constants/actionTypes';

/**
 * Notification modal closed action
 *
 * @returns {object} Notification modal closed action
 */
export const notificationModalClosed = () => ({
    type: actionTypes.NOTIFICATION_MODAL_CLOSED,
});

/**
 * Show app notification message action
 *
 * @param {string} type Message type
 * @param {string} message Message
 *
 * @returns {object} Show app notification message action
 */
export const showAppNotificationMessage = (type, message) => ({
    type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
    message: message,
    messageType: type,
});

/**
 * Show app notification modal action
 *
 * @param {string} type Message type
 * @param {string} message Message
 *
 * @returns {object} Show app notification message action
 */
export const showAppNotificationModal = (type, message) => ({
    type: actionTypes.SHOW_NOTIFICATION_MODAL,
    message: message,
    messageType: type,
});

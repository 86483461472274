/**
 * useCommunicationTemplates
 * Get the communication templates
 *
 * @since 2.0.0
 */

import { CACHE_COMMUNICATION_TEMPLATES_KEY } from 'includes/constants';
import { get } from 'lodash';
import CommunicationApi from 'includes/services/shared/communication';
import useCachedData from './useCachedData';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { COMMUNICATION_TEMPLATES } from 'includes/constants/mappings/success';

/**
 * Get the communication templates
 *
 * @param {boolean} disableApiCall Whether to disable api call or not. Default false
 *
 * @returns object communication templates data
 */
const useCommunicationTemplates = (disableApiCall = false) => {
    const organisationId = useOrganisationId();
    return useCachedData(
        CACHE_COMMUNICATION_TEMPLATES_KEY,
        () =>
            CommunicationApi.getCommunicationTemplates({
                organisation_id: organisationId,
                searchParams: { page_size: 0, group_by_template_type_and_notification_type: true },
            }),
        {
            selector: res => get(res, COMMUNICATION_TEMPLATES),
            disableApiCall: disableApiCall,
            dependsOnOrg: true,
        }
    );
};

export default useCommunicationTemplates;

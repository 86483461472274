/**
 * useOrganisationCurrencies
 * Get the organisation currencies
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { CACHE_ORGANISATION_CURRENCIES_KEY } from 'includes/constants';
import { get } from 'lodash';
import { ORGANISATION_CURRENCIES } from 'includes/constants/mappings/success';
import CurrenciesApi from 'includes/services/currencies';
import useCachedData from './useCachedData';
import useOrganisationId from 'includes/hooks/useOrganisationId';

/**
 * Get the organisation currencies
 *
 * @param {boolean} disableApiCall Whether to disable api call or not. Default false
 *
 * @returns object Currencies
 */
const useOrganisationCurrencies = (disableApiCall = false) => {
    const organisationId = useOrganisationId();
    return useCachedData(
        CACHE_ORGANISATION_CURRENCIES_KEY,
        () => CurrenciesApi.getOrganisationCurrencies(organisationId),
        {
            selector: res => get(res, ORGANISATION_CURRENCIES),
            disableApiCall: disableApiCall,
            dependsOnOrg: true,
        }
    );
};

export default useOrganisationCurrencies;

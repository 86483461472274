/**
 * Countries API class.
 * Handles all api requests related to countries
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import base api handler
import BaseApiHandler from './base/api';

class CountriesApi extends BaseApiHandler {
    /**
     * Get the countries
     *
     * @returns {object} API Response
     */
    getCountries = (params = {}) => this.axios.get(this.getApiUrl('COUNTRIES_CRUD'), { params });

    /**
     * Get country by IP
     *
     * @returns {object} API Response
     */
    getCountryByIp = () => this.axios.get(this.getApiUrl('COUNTRY_BY_IP'));
}

export default new CountriesApi();

/**
 * Permission Manager
 * UI to manage permissions that a subscription plan has
 *
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 * @version 1.0
 */
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { capitalize, groupBy } from 'lodash';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import './styles.scss';
// import Separator from './components/Separator';
import DroppableColumn from './components/DroppableColumn';
import { getMissingPermissionLabels, snakeCaseToWords } from 'includes/utils';
import PermissionDependencyModalContentTable from 'components/PermissionDependencyModalContentTable';

const { info } = Modal;

const COLUMNS = {
    ALLOWED: { id: 'allowed', label: 'Allowed' },
    RESTRICTED: { id: 'restricted', label: 'Restricted' },
};

/**
 * Returns a new list(sorted by group) with separators injected in between
 *
 * @param {Array} list array of permission list to which separators are to be injected
 * @param {string} columnId ID of the column
 * @returns {Array}
 */
const injectSeparators = (list, columnId) => {
    const updatedList = [];
    const groupedPermissions = groupBy(list, 'group');

    Object.keys(groupedPermissions)
        .sort()
        .forEach(groupId => {
            updatedList.push({
                type: 'separator',
                label: capitalize(snakeCaseToWords(groupId)),
                id: `${columnId}-${groupId}-separator`,
                group: groupId,
            });
            updatedList.push(...groupedPermissions[groupId]);
        });
    return updatedList;
};

/**
 * Returns a new array after stripping away the separators
 *
 * @param {Array} list array of permissions with separators
 * @returns {Array}
 */
const stripSeparators = list => list.filter(x => x.type !== 'separator');

const PermissionManager = ({ permissionSet, allowed, updateAllowed }) => {
    const setColumns = columns => {
        updateAllowed(stripSeparators(columns[COLUMNS.ALLOWED.id].items).map(p => p.id));
    };

    const { t } = useTranslation();

    const columns = useMemo(() => {
        const { allowedPermissions, restrictedPermissions } = groupBy(permissionSet, perm =>
            allowed.includes(perm.id) ? 'allowedPermissions' : 'restrictedPermissions'
        );
        return {
            [COLUMNS.RESTRICTED.id]: {
                name: COLUMNS.RESTRICTED.label,
                items: injectSeparators(restrictedPermissions, COLUMNS.RESTRICTED.id),
                activeGroup: 'reminder',
            },
            [COLUMNS.ALLOWED.id]: {
                name: COLUMNS.ALLOWED.label,
                items: injectSeparators(allowedPermissions, COLUMNS.ALLOWED.id),
                activeGroup: null,
            },
        };
    }, [permissionSet, allowed]);

    const onDragEnd = result => {
        if (!result.destination) return;
        const { source, destination } = result;

        togglePermission(source.droppableId, destination.droppableId, source.index, destination.index);
    };

    const warnIfDependentPermissionMissing = (dependentPermissions, allowedPermissions) => {
        const missingPermissionLabels = getMissingPermissionLabels(
            dependentPermissions,
            allowedPermissions,
            permissionSet
        );

        if (Object.values(missingPermissionLabels).length > 0)
            info({
                title: t('adminMessages.plans.message.dependentPermissionTitle'),
                content: (
                    <div>
                        There are some permission that dependent on other permissions that you have not enabled. Please
                        enable them and continue.
                        <div>
                            <PermissionDependencyModalContentTable permissionLabels={missingPermissionLabels} />
                        </div>
                    </div>
                ),
            });
    };

    /**
     * Toggles the permission between 2 columns
     *
     * @param {string} sourceColId The source column ID
     * @param {string} destColId The destination column ID
     * @param {number} sourceIndex Index of the permissions to be toggled in the source column
     * @param {number} destinationIndex Index to which permission is to be inserted in the destination column
     */
    const togglePermission = (sourceColId, destColId, sourceIndex, destinationIndex = 0) => {
        if (sourceColId !== destColId) {
            const sourceColumn = columns[sourceColId];
            const destColumn = columns[destColId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(sourceIndex, 1);
            destItems.splice(destinationIndex, 0, removed);

            // if a permission is being granted then check if dependent permissions are also allowed
            if (destColId === 'allowed') {
                const { dependent_permission: dependentPermission = [] } = removed;

                warnIfDependentPermissionMissing(
                    dependentPermission,
                    stripSeparators(destItems).map(x => x.permission)
                );
            } else {
                if (removed.has_all_plan_access_by_default) {
                    Modal.info({
                        title: 'Default permission',
                        content: 'You cannot remove default permission.',
                    });
                    return;
                }
            }

            setColumns({
                ...columns,
                [sourceColId]: {
                    ...sourceColumn,
                    items: injectSeparators(stripSeparators(sourceItems), sourceColId),
                },
                [destColId]: {
                    ...destColumn,
                    items: injectSeparators(stripSeparators(destItems), destColId),
                    // activeGroup: removed.group,
                },
            });
            // setActive(removed.group);
        } else {
            const column = columns[sourceColId];
            const copiedItems = [...column.items];
            const [removed] = copiedItems.splice(sourceIndex, 1);
            copiedItems.splice(destinationIndex, 0, removed);

            setColumns({
                ...columns,
                [sourceColId]: {
                    ...column,
                    items: injectSeparators(stripSeparators(copiedItems), sourceColId),
                },
            });
        }
    };

    /**
     * Toggles all permission belonging to a certain group
     *
     * @param {string} sourceColId The source column ID
     * @param {string} groupId Group Id
     */
    const toggleAllPermissionOfGroup = (sourceColId, groupId) => {
        const destColId = Object.keys(columns).filter(x => x !== sourceColId)[0];

        // strip separators and remove all items of the same group
        const sourceItems = stripSeparators([...columns[sourceColId].items]).filter(x => x.group !== groupId);
        const destItems = stripSeparators([...columns[destColId].items]).filter(x => x.group !== groupId);
        const groupItems = permissionSet.filter(x => x.group === groupId);
        let groupPermissionsWithoutDefaultPermissions = groupItems,
            defaultPermissionsOfGroup = [];

        if (destColId === 'allowed') {
            const { dependentPermissions, groupItemSlugs } = groupItems.reduce(
                (acc, curr) => ({
                    dependentPermissions: [...acc.dependentPermissions, ...curr.dependent_permission],
                    groupItemSlugs: [...acc.groupItemSlugs, curr.permission],
                }),
                { dependentPermissions: [], groupItemSlugs: [] }
            );

            warnIfDependentPermissionMissing(dependentPermissions, [
                ...destItems.map(x => x.permission),
                ...groupItemSlugs,
            ]);
        } else {
            const grouped = groupBy(groupItems, item =>
                item.has_all_plan_access_by_default
                    ? 'defaultPermissionsOfGroup'
                    : 'groupPermissionsWithoutDefaultPermissions'
            );

            groupPermissionsWithoutDefaultPermissions = grouped.groupPermissionsWithoutDefaultPermissions ?? [];
            defaultPermissionsOfGroup = grouped.defaultPermissionsOfGroup ?? [];
        }

        // insert all items except default of the group to dest col
        destItems.push(...groupPermissionsWithoutDefaultPermissions);

        // push all default permissions back to the source col
        sourceItems.push(...defaultPermissionsOfGroup);

        setColumns({
            ...columns,
            [sourceColId]: {
                ...columns[sourceColId],
                items: injectSeparators(sourceItems),
            },
            [destColId]: {
                ...columns[destColId],
                items: injectSeparators(destItems),
            },
        });
    };

    const handleClick = (sourceColId, sourceIndex) => {
        const destColumnId = Object.keys(columns).filter(x => x !== sourceColId)[0];
        togglePermission(sourceColId, destColumnId, sourceIndex);
    };

    /**
     * Grants all default permission to the Plan
     */
    const grantAllDefaultPermissions = () => {
        const defaultPermissions = permissionSet.filter(p => p.is_default);
        const allowedPermissions = stripSeparators(columns.allowed.items);
        const restrictedPermissions = stripSeparators(columns.restricted.items);
        const indexMap = new Map();
        const indicesToBeRemoved = [];
        let startIndex = 0;

        // loop through each default permission
        defaultPermissions.forEach(perm => {
            // check if already in indexMap
            if (indexMap.has(perm.permission)) {
                // copy the permission object to allowedPermissions and add it to indicesToBeRemoved array
                const removed = restrictedPermissions[indexMap.get(perm.permission)];
                indicesToBeRemoved.push(indexMap.get(perm.permission));
                allowedPermissions.splice(0, 0, removed);
                return;
            } else {
                // loop through restricetedPermissions from startIndex
                for (let i = startIndex; i < restrictedPermissions.length; i++) {
                    // add entry to indexMap and update startIndex
                    indexMap.set(restrictedPermissions[i].permission, i);
                    startIndex = i + 1;
                    // if it is the required permission add it to allowedPermission and add it to indicesToBeRemoved
                    if (restrictedPermissions[i].permission === perm.permission) {
                        const removed = restrictedPermissions[i];
                        indicesToBeRemoved.push(i);
                        allowedPermissions.splice(0, 0, removed);
                        break;
                    }
                }
            }
        });

        setColumns({
            allowed: {
                ...columns.allowed,
                items: injectSeparators(allowedPermissions),
            },
            restricted: {
                ...columns.restricted,
                items: injectSeparators(
                    restrictedPermissions.filter((_, index) => !indicesToBeRemoved.includes(index))
                ),
            },
        });
    };

    return (
        <div className="flex justify-center h-100 flex-col">
            <div className="flex justify-end">
                <button className="font-thin px-5 add-defaults-btn" onClick={grantAllDefaultPermissions} type="button">
                    Grant all default permissions
                </button>
            </div>
            <DragDropContext
                onDragEnd={onDragEnd}
                // onDragUpdate={onDragUpdate}
            >
                <div className="grid grid-cols-2">
                    <DroppableColumn
                        columnId={COLUMNS.RESTRICTED.id}
                        column={columns[COLUMNS.RESTRICTED.id]}
                        handleClick={handleClick}
                        // handleSeparatorToggleButtonClick={handleSeparatorToggleButtonClick}
                        toggleAllPermissionOfGroup={toggleAllPermissionOfGroup}
                    />
                    <DroppableColumn
                        columnId={COLUMNS.ALLOWED.id}
                        column={columns[COLUMNS.ALLOWED.id]}
                        handleClick={handleClick}
                        // handleSeparatorToggleButtonClick={handleSeparatorToggleButtonClick}
                        toggleAllPermissionOfGroup={toggleAllPermissionOfGroup}
                    />
                </div>
            </DragDropContext>
        </div>
    );
};

PermissionManager.propTypes = {
    allowed: PropTypes.arrayOf(PropTypes.string).isRequired,
    permissionSet: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateAllowed: PropTypes.func.isRequired,
};

export default PermissionManager;

import { Breadcrumb } from 'antd';
import { find } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import Button from 'components/Button';
import DetailsPageDetailSection from 'components/DetailsPageDetailSection';
import Loader from 'components/Loader';
import PageTitle from 'components/PageTitle';
import WhiteBoxWrapper from 'components/WhiteBoxWrapper';
import useCurrencies from 'includes/hooks/useCurrencies';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import { getInvoicePaymentDetails } from 'includes/slices/invoice';
import { redirect } from 'includes/utils';
import CopyToClipboard from 'components/CopyToClipboard';
import useAccountId from 'includes/hooks/useAccountId';

/**
 * Payment details page
 *
 * Fetches and renders payment details
 */
export default function PaymentDetails() {
    const dispatch = useDispatch();

    const isAdmin = useSelector(state => state.account.isAdmin);

    const accountId = useAccountId();

    const { organisationId, contactId, invoiceId, invoicePaymentId } = useParams();

    const invoicePaymentDetails = useSelector(state => state.invoices.invoicePaymentDetails);

    const isInvoicePaymentDetailsLoading = useSelector(state => state.invoices.isInvoicePaymentDetailsLoading);

    const { data: currencies } = useCurrencies();

    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    const currentCurrency = useMemo(() => {
        if (!invoicePaymentDetails || currencies.length === 0) return null;

        return find(currencies, { id: invoicePaymentDetails.currency_id });
    }, [invoicePaymentDetails, currencies]);

    useEffect(() => {
        dispatch(getInvoicePaymentDetails(organisationId, contactId, invoiceId, invoicePaymentId));
    }, [organisationId, contactId, invoiceId, invoicePaymentId]);

    const handleOnViewOrderButtonClick = () => {
        if (isAdmin) {
            redirect(
                `/admin/accounts/organisation/orders/${accountId}/${organisationId}/${invoicePaymentDetails?.order?.type_id}/${invoicePaymentDetails?.order?.id}`
            );
            return;
        }

        redirect(`/orders/order/${invoicePaymentDetails?.order?.type_id}/${invoicePaymentDetails?.order?.id}`);
    };

    /**
     * Get invoice details link based on if role is admin or not
     *
     * @param {string} invoiceId Invoice ID
     * @param {string} contactId contact ID
     * @returns invoice details link based on the role
     */
    const getInvoiceDetailsLink = (invoiceId, contactId) => {
        if (isAdmin)
            return `/admin/accounts/organisation/invoices/invoice/${accountId}/${organisationId}/${contactId}/${invoiceId}`;

        return `/invoices/invoice/${contactId}/${invoiceId}`;
    };

    return (
        <div className="container mx-auto">
            <div className="mt-8 text-black breadcumb-spec border-0">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link
                            to={
                                isAdmin
                                    ? `/admin/accounts/organisation/payments/${accountId}/${organisationId}`
                                    : '/payments'
                            }
                        >
                            Payments
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{invoicePaymentDetails?.unique_id}</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="my-7">
                <PageTitle>Payment details</PageTitle>
            </div>

            <section>
                <WhiteBoxWrapper
                    topActionSection={
                        invoicePaymentDetails?.order?.id ? (
                            <div className="flex justify-end">
                                <Button filled onClick={handleOnViewOrderButtonClick}>
                                    View order details
                                </Button>
                            </div>
                        ) : null
                    }
                >
                    {isInvoicePaymentDetailsLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <CopyToClipboard
                                valueClassName="font-mono font-semibold"
                                value={invoicePaymentDetails?.unique_id}
                            />
                            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 text-left my-5">
                                <DetailsPageDetailSection
                                    label="Invoice number"
                                    value={
                                        <Link
                                            to={getInvoiceDetailsLink(
                                                invoicePaymentDetails?.invoice?.id,
                                                invoicePaymentDetails?.invoice?.invoice_contact_id
                                            )}
                                        >
                                            {invoicePaymentDetails?.invoice?.number}
                                        </Link>
                                    }
                                />
                                <DetailsPageDetailSection
                                    label="Amount paid"
                                    value={getNumberFormattedAsCurrency(
                                        invoicePaymentDetails?.amount_paid,
                                        undefined,
                                        undefined,
                                        currentCurrency?.code
                                    )}
                                />
                                <DetailsPageDetailSection
                                    label="Paid on"
                                    value={moment(invoicePaymentDetails?.paid_on, 'YYYY-MM-DD').format(
                                        'ddd, MMM DD, YYYY'
                                    )}
                                />
                                <DetailsPageDetailSection
                                    label="Reference number"
                                    value={
                                        <CopyToClipboard
                                            valueClassName="w-40 font-mono"
                                            value={invoicePaymentDetails?.reference_number}
                                        />
                                    }
                                />
                                <DetailsPageDetailSection label="Reference" value={invoicePaymentDetails?.reference} />
                            </div>
                        </>
                    )}
                </WhiteBoxWrapper>
            </section>
        </div>
    );
}

/**
 * Payment arrangement installment detail form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com>
 */

// import required modules
import React, { useEffect } from 'react';
import { Row, Col, Tag, Icon, Tooltip, Modal } from 'antd';
import { find, get } from 'lodash';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
    PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_OPTIONS,
    PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_PENDING,
    PAYMENT_ARRANGEMENT_OR_INSTALLMENT_PAYMENT_STATUS_OPTIONS,
    PAYMENT_ARRANGEMENT_PAYMENT_TYPE_UPFRONT,
} from 'includes/constants';
import {
    paymentArrangementInstallmentStatusTagColors,
    paymentArrangementInstallmentTypeTagColors,
    paymentArrangementOrInstallmentPaymentStatusTagColors,
} from 'includes/constants/tagColors';
import { useDispatch } from 'react-redux';
import { getPaymentArrangementInstallment } from 'includes/slices/paymentArrangement';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader';
import PaymentArrangementInstallmentInvoices from 'pages/PaymentArrangements/Details/components/Installments/components/Invoices';
import { Link } from 'react-router-dom';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useAccountId from 'includes/hooks/useAccountId';
import Button from 'components/Button';

const PaymentArrangementInstallmentDetailsModal = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const organisationId = useOrganisationId();
    const paymentArrangement = useSelector(state => state.paymentArrangement.paymentArrangement);
    const paymentArrangementInstallment = useSelector(state => state.paymentArrangement.paymentArrangementInstallment);
    const loadingPaymentArrangementInstallment = useSelector(
        state => state.paymentArrangement.loadingPaymentArrangementInstallment
    );
    const isAdmin = useIsAdminOrManager();

    const { isOpen, paymentArrangementInstallmentId, setSelectedPaymentArrangementInstallmentId } = props;

    useEffect(() => {
        if (isOpen && paymentArrangementInstallmentId) {
            dispatch(
                getPaymentArrangementInstallment(organisationId, paymentArrangement.id, paymentArrangementInstallmentId)
            );
        }
    }, [isOpen, paymentArrangementInstallmentId]);

    /**
     * Get value from payment arrangement installment details by key
     *
     * @param {string} key Key for which value is to be retrieved
     * @param {*} defaultValue. Default value
     *
     * @returns {string} Value
     */
    const getValue = (key, defaultValue = '') => get(paymentArrangementInstallment, key, defaultValue);

    /**
     * Locale label
     *
     * @param {string} key Key to grt localized text
     *
     * @returns {string} Localized text
     */
    const localeLabel = key => t(`messages.paymentArrangement.arrangement.installments.list.label.${key}`);

    /**
     * Render the field
     *
     * @param {string} key Key for which the field is to be shown
     * @param {string} options Options to find value from. Default ''
     * @param {string} customValue Custom Value. Default ''
     * @param {boolean} isAmount. Whether value is amount. Default false
     * @param {number} span Col span. Default 12
     *
     * @returns JSX.Element
     */
    const renderField = (key, options = '', customValue = '', isAmount = false) => {
        let value = customValue
            ? customValue
            : options
            ? get(
                  find(options, {
                      id: getValue(key),
                  }),
                  'label'
              )
            : getValue(key);
        if (isAmount) {
            value = props.getNumberFormattedWithPaymentArrangementCurrency(value);
        }
        return (
            <Col sm={24} md={8}>
                <label htmlFor="name">{localeLabel(key)}:</label>
                <div className="ant-row ant-form-item">
                    <div className="ant-col ant-form-item-control-wrapper">
                        <div className="ant-form-item-control">
                            <div className="ant-form-item-children">{value}</div>
                        </div>
                    </div>
                </div>
            </Col>
        );
    };

    return (
        <Modal
            footer={[]}
            className="custom-form-modal text-left custom-pp-modal"
            visible={props.isOpen}
            onCancel={props.onCancel}
            destroyOnClose
            title={
                <div className="flex justify-between items-center  pr-5">
                    <header className="text-left" style={{ fontSize: '22px' }}>
                        {t(`messages.paymentArrangement.arrangement.installments.list.label.detailsHeader`)}
                    </header>
                    {paymentArrangementInstallment?.order?.id ? (
                        <Link
                            to={`/${
                                isAdmin
                                    ? `/admin/accounts/organisation/orders/${accountId}/${organisationId}`
                                    : 'orders/order'
                            }/${paymentArrangementInstallment?.order?.type_id}/${
                                paymentArrangementInstallment?.order?.id
                            }`}
                            target="_blank"
                        >
                            <Button type="link" filled>
                                {localeLabel('view_order')}
                            </Button>
                        </Link>
                    ) : null}
                </div>
            }
        >
            <div className="my-profile-detail">
                <div className="edit-form">
                    {loadingPaymentArrangementInstallment ? (
                        <Loader />
                    ) : (
                        <>
                            <Row gutter={16} style={{ padding: '10px 0' }}>
                                <Col span={12}>
                                    <Row gutter={16}>
                                        <Col span={24}>
                                            <Tag
                                                color={get(
                                                    paymentArrangementInstallmentTypeTagColors,
                                                    getValue('payment_type')
                                                )}
                                                style={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {localeLabel(
                                                    getValue('payment_type') ===
                                                        PAYMENT_ARRANGEMENT_PAYMENT_TYPE_UPFRONT
                                                        ? 'payment_type.upfront'
                                                        : 'payment_type.installment'
                                                )}
                                            </Tag>
                                        </Col>
                                        <Col span={24}>
                                            <Tooltip
                                                title={`# ${getValue('installment_number')} of ${
                                                    paymentArrangement?.total_installments
                                                }`}
                                            >
                                                # {getValue('installment_number')} of{' '}
                                                {paymentArrangement?.total_installments}
                                                {getValue('reschedule_reason') ? (
                                                    <Icon
                                                        type="info-circle"
                                                        theme="outlined"
                                                        style={{
                                                            marginLeft: '5px',
                                                            verticalAlign: 'middle',
                                                            color: 'rgb(206 184 143)',
                                                        }}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </Tooltip>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <div className="flex flex-col items-end gap-2 ">
                                        <Tag
                                            className="flex-grow-0"
                                            color={get(
                                                paymentArrangementInstallmentStatusTagColors,
                                                getValue('status')
                                            )}
                                            style={{
                                                fontWeight: 'bold',
                                                textAlign: 'right',
                                                float: 'right',
                                            }}
                                        >
                                            {get(
                                                find(PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_OPTIONS, {
                                                    id: getValue('status'),
                                                }),
                                                'label'
                                            )}
                                        </Tag>
                                        {getValue('status') !== 1 ? (
                                            <Tag
                                                className="flex-grow-0"
                                                color={get(
                                                    paymentArrangementOrInstallmentPaymentStatusTagColors,
                                                    getValue('payment_status'),
                                                    0
                                                )}
                                                style={{
                                                    fontWeight: 'bold',
                                                    textAlign: 'right',
                                                    float: 'right',
                                                }}
                                            >
                                                {get(
                                                    find(PAYMENT_ARRANGEMENT_OR_INSTALLMENT_PAYMENT_STATUS_OPTIONS, {
                                                        id: getValue('payment_status'),
                                                    }),
                                                    'label'
                                                )}
                                            </Tag>
                                        ) : null}
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ padding: '10px 0' }}>
                                <Col span={24}>
                                    <label htmlFor="name">{localeLabel('amount')}:</label>
                                    <div style={{ fontSize: '40px', fontWeight: 'bold' }}>
                                        {props.getNumberFormattedWithPaymentArrangementCurrency(
                                            getValue('total_amount')
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Row gutter={12} style={{ padding: '10px 0' }}>
                                {renderField('fee', '', '', true)}
                                {renderField('payment_date', '', '', false, 8)}
                                {getValue('payment_received_date')
                                    ? renderField('payment_received_date', '', '', false, 8)
                                    : null}
                                {getValue('status') === PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_PENDING
                                    ? renderField('plan_cancellation_date', '', '', false, 8)
                                    : null}
                            </Row>
                            <Row gutter={16} style={{ padding: '10px 0' }}>
                                <Col span={24}>
                                    <PaymentArrangementInstallmentInvoices
                                        getNumberFormattedWithPaymentArrangementCurrency={
                                            props.getNumberFormattedWithPaymentArrangementCurrency
                                        }
                                        invoices={paymentArrangementInstallment?.invoices || []}
                                    />
                                </Col>
                            </Row>
                            <div className="flex justify-end gap-2">
                                {!paymentArrangementInstallment?.previous_installment_id ? (
                                    <Button
                                        primary
                                        onClick={() =>
                                            setSelectedPaymentArrangementInstallmentId(
                                                paymentArrangementInstallment?.previous_installment_id
                                            )
                                        }
                                    >
                                        <Icon type="left" />
                                    </Button>
                                ) : null}
                                {!paymentArrangementInstallment?.next_installment_id ? (
                                    <Button
                                        type="link"
                                        primary
                                        style={{ float: 'right', fontSize: '14px' }}
                                        onClick={() => {
                                            setSelectedPaymentArrangementInstallmentId(
                                                paymentArrangementInstallment?.next_installment_id
                                            );
                                        }}
                                    >
                                        <Icon type="right" />
                                    </Button>
                                ) : null}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Modal>
    );
};

PaymentArrangementInstallmentDetailsModal.propTypes = {
    getNumberFormattedWithPaymentArrangementCurrency: PropTypes.func,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    paymentArrangementInstallmentId: PropTypes.string,
    setSelectedPaymentArrangementInstallmentId: PropTypes.func,
};
export default PaymentArrangementInstallmentDetailsModal;

/**
 * Subscription Plan Saga
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import the required modules
import * as actionTypes from '../../../../constants/actionTypes';
import * as constants from '../../../../constants';
import * as errorMappings from '../../../../constants/mappings/errors';
import * as successMappings from '../../../../constants/mappings/success';
import BaseSagaHandler from '../../core/base';
import get from 'lodash/get';
import SubscriptionPlanApi from '../../../../services/customer/subscriptionPlan';
import { takeLatest, all, put, call } from 'redux-saga/effects';
import { UNKNOWN_ERROR } from '../../../../constants/messages/errors';
import { redirect } from '../../../../utils';

/**
 * Subscription Plan Saga Class. Handles the subscription related operations
 * */
class SubscriptionPlanSaga extends BaseSagaHandler {
    /**
     * The Subscription plan Watcher Saga
     * Watches the redux actions related to subscription plans and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *subscriptionPlanWatchers() {
        let context = this;
        yield all([
            yield takeLatest(actionTypes.ADD_SUBSCRIPTION_PLAN_REQUEST, [context, 'addSubscriptionPlan']),
            yield takeLatest(actionTypes.CANCEL_ACCOUNT_SUBSCRIPTION_REQUEST, [context, 'cancelAccountSubscription']),
            yield takeLatest(actionTypes.CREATE_SUBSCRIPTION_FOR_ACCOUNT_REQUEST, [
                context,
                'createSubscriptionForAccount',
            ]),
            yield takeLatest(actionTypes.DELETE_SUBSCRIPTION_PLANS_REQUEST, [context, 'deleteSubscriptionPlan']),
            yield takeLatest(actionTypes.EXPORT_ALL_SUBSCRIPTIONS_REQUEST, [context, 'exportAllSubscriptions']),
            yield takeLatest(actionTypes.GET_PLAN_ACCOUNTS_REQUEST, [context, 'getPlanAccounts']),
            yield takeLatest(actionTypes.GET_PLAN_QUOTES_REQUEST, [context, 'getPlanQuotes']),
            yield takeLatest(actionTypes.GET_SUBSCRIPTION_DETAILS_REQUEST, [context, 'getSubscriptionDetails']),
            yield takeLatest(actionTypes.GET_SUBSCRIPTION_PLAN_DETAILS_REQUEST, [
                context,
                'getSubscriptionPlanDetails',
            ]),
            yield takeLatest(actionTypes.GET_SUBSCRIPTION_PLANS_REQUEST, [context, 'getSubscriptionPlans']),
            yield takeLatest(actionTypes.GET_SUBSCRIPTION_STATUSES_REQUEST, [context, 'getSubscriptionStatuses']),
            yield takeLatest(actionTypes.GET_SUBSCRIPTIONS_HISTORY_REQUEST, [context, 'getSubscriptionsHistory']),
            yield takeLatest(actionTypes.UPDATE_SUBSCRIPTION_PLAN_REQUEST, [context, 'updateSubscriptionPlan']),
        ]);
    }

    /**
     * Add Subscription Plan
     *
     * @param {object} payload Payload data
     *
     * @yields  {object}    Add subscription plan response
     */
    *addSubscriptionPlan({ payload }) {
        try {
            const result = yield call(SubscriptionPlanApi.addSubscriptionPlan, payload);
            yield put({
                type: actionTypes.ADD_SUBSCRIPTION_PLAN_SUCCESS,
                result,
            });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            // redirect to plans page
            redirect('/admin/plans/plans/', false, { preventPlansApiCall: true });
        } catch (errors) {
            yield this.stopFormSubmit(
                errors,
                constants.ADD_EDIT_PLAN_FORM,
                constants.SUBSCRIPTION_PLAN_EDIT_DEFAULT_ERROR,
                constants.SUBSCRIPTION_PLAN_EDIT_DEFAULT_ERROR
            );
            yield put({ type: actionTypes.ADD_SUBSCRIPTION_PLAN_FAILURE, errors });
        }
    }

    /**
     * Cancel account subscription
     *
     * @param   {string}    subscriptionId      Subscription Id
     * @param   {Function}  callback            Callback function
     *
     * @yields  {object}    Cancel subscription response
     */
    *cancelAccountSubscription({ payload, callback }) {
        try {
            const result = yield call(SubscriptionPlanApi.cancelSubscription, payload);
            yield put({ type: actionTypes.CANCEL_ACCOUNT_SUBSCRIPTION_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            const subscriptionError = get(errors, errorMappings.SUBSCRIPTION_CANCEL_ACTIVE_SUBSCRIPTION_ERROR);
            if (subscriptionError && callback) {
                callback(subscriptionError);
            } else {
                const cancelSubscriptionError = get(errors, errorMappings.SUBSCRIPTION_CANCEL_ERROR, UNKNOWN_ERROR);
                yield put({
                    type: actionTypes.SHOW_NOTIFICATION_MODAL,
                    message: cancelSubscriptionError,
                    messageType: constants.ERROR,
                });
            }
            yield put({ type: actionTypes.CANCEL_ACCOUNT_SUBSCRIPTION_FAILURE, errors });
        }
    }

    /**
     * Create Subscription for the account
     *
     * @param {object} payload Payload data
     * @param {Function} callback Callback function
     *
     * @yields  {object}    Create subscription response
     */
    *createSubscriptionForAccount({ payload, callback }) {
        try {
            const result = yield call(SubscriptionPlanApi.createSubscription, payload);
            yield put({ type: actionTypes.CREATE_SUBSCRIPTION_FOR_ACCOUNT_SUCCESS });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            // redirect to plan accounts list page
            redirect(`/admin/plans/plans/accounts/view/${payload.plan_id}`);
        } catch (errors) {
            const subscriptionError = get(errors, errorMappings.SUBSCRIPTION_CREATE_ERROR);
            if (subscriptionError && callback) {
                callback(subscriptionError);
            } else {
                yield this.stopFormSubmit(
                    errors,
                    constants.CREATE_SUBSCRIPTION_FORM,
                    constants.CREATE_SUBSCRIPTION_DEFAULT_ERROR,
                    constants.CREATE_SUBSCRIPTION_DEFAULT_ERROR
                );
            }
            yield put({ type: actionTypes.CREATE_SUBSCRIPTION_FOR_ACCOUNT_FAILURE, errors });
        }
    }

    /**
     * Delete plan
     *
     * @param   {string}    planId     Plan Id
     *
     * @yields {object}   Delete plan response
     */
    *deleteSubscriptionPlan({ planId }) {
        try {
            const result = yield call(SubscriptionPlanApi.deleteSubscriptionPlan, planId);
            yield put({
                type: actionTypes.DELETE_SUBSCRIPTION_PLANS_SUCCESS,
                result,
            });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            yield put({ type: actionTypes.DELETE_SUBSCRIPTION_PLANS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(errors, errorMappings.DELETE_PLAN_ERROR, UNKNOWN_ERROR),
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Export all subscriptions
     *
     * @param {object} payload Payload
     *
     * @yields {object} Export all subscriptions response
     */
    *exportAllSubscriptions({ payload }) {
        try {
            const result = yield call(SubscriptionPlanApi.exportAllSubscriptions, payload);
            yield put({
                type: actionTypes.EXPORT_ALL_SUBSCRIPTIONS_SUCCESS,
                result,
            });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            yield put({ type: actionTypes.EXPORT_ALL_SUBSCRIPTIONS_FAILURE, errors });
        }
    }

    /**
     * Get plan accounts
     *
     * @param   {object}    action     Action
     *
     * @yields {object} Plan accounts response
     */
    *getPlanAccounts(action) {
        try {
            const result = yield call(SubscriptionPlanApi.getPlanAccounts, action.payload);
            yield put({ type: actionTypes.GET_PLAN_ACCOUNTS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_PLAN_ACCOUNTS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get plan quotes
     *
     * @param   {object}    action     Action
     *
     * @yields {object} Plan quotes response
     */
    *getPlanQuotes(action) {
        try {
            const result = yield call(SubscriptionPlanApi.getPlanQuotes, action.payload);
            yield put({ type: actionTypes.GET_PLAN_QUOTES_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_PLAN_QUOTES_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get subscription details
     *
     * @param   {object}    action     Action
     *
     * @yields {object} Subscription details response
     */
    *getSubscriptionDetails(action) {
        try {
            const result = yield call(SubscriptionPlanApi.getSubscriptionDetails, action.payload);
            yield put({ type: actionTypes.GET_SUBSCRIPTION_DETAILS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_SUBSCRIPTION_DETAILS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get subscription plan details
     *
     * @param   {object}    action     Action
     *
     * @yields {object} Subscription plan details response
     */
    *getSubscriptionPlanDetails(action) {
        try {
            const result = yield call(SubscriptionPlanApi.getSubscriptionPlanDetails, action.payload);
            yield put({ type: actionTypes.GET_SUBSCRIPTION_PLAN_DETAILS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_SUBSCRIPTION_PLAN_DETAILS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get subscription plans
     *
     * @param   {object}    action     Action
     *
     * @yields {object} Subscription plans response
     */
    *getSubscriptionPlans(action) {
        try {
            const result = yield call(SubscriptionPlanApi.getSubscriptionPlans, action.payload);
            yield put({ type: actionTypes.GET_SUBSCRIPTION_PLANS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_SUBSCRIPTION_PLANS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get subscription statuses
     *
     * @param {object} payload Payload
     *
     * @yields {object} Subscription statuses response
     */
    *getSubscriptionStatuses({ payload }) {
        try {
            const result = yield call(SubscriptionPlanApi.getSubscriptionStatuses, payload);
            yield put({ type: actionTypes.GET_SUBSCRIPTION_STATUSES_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_SUBSCRIPTION_STATUSES_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get subscription history
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get subscription history response
     */
    *getSubscriptionsHistory({ payload }) {
        try {
            const result = yield call(SubscriptionPlanApi.getSubscriptionsHistory, payload);
            yield put({ type: actionTypes.GET_SUBSCRIPTIONS_HISTORY_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_SUBSCRIPTIONS_HISTORY_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Update subscription plan
     *
     * @param {object} payload Payload
     *
     * @yields {object} Update subscription plan response
     */
    *updateSubscriptionPlan({ payload }) {
        try {
            const result = yield call(SubscriptionPlanApi.updateSubscriptionPlan, payload);
            yield put({
                type: actionTypes.UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
                result,
            });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            // redirect to plans page
            redirect('/admin/plans/plans/');
        } catch (errors) {
            yield this.stopFormSubmit(
                errors,
                constants.ADD_EDIT_PLAN_FORM,
                constants.SUBSCRIPTION_PLAN_EDIT_DEFAULT_ERROR,
                constants.SUBSCRIPTION_PLAN_EDIT_DEFAULT_ERROR
            );
            yield put({ type: actionTypes.UPDATE_SUBSCRIPTION_PLAN_FAILURE, errors });
        }
    }
}

//export the Subscription plan saga
export default new SubscriptionPlanSaga().forkAllWatcherFunctions();

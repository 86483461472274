/**
 * SMS communication log fields to show
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import React from 'react';
import PropTypes from 'prop-types';

const SMSCommunicationLogHistoryFields = ({ renderField }) => {
    return (
        <section>
            {renderField({ key: 'message', onSameLine: false })}
            {renderField({ key: 'delivered_date' })}
            {renderField({ key: 'read_date' })}
        </section>
    );
};

SMSCommunicationLogHistoryFields.propTypes = {
    renderField: PropTypes.func,
};

export default SMSCommunicationLogHistoryFields;

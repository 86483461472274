/**
 * Exports Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { createSlice } from '@reduxjs/toolkit';
import ExportsApi from '../services/shared/exports';
import { showNotificationModal } from './appNotifications';
import { get } from 'lodash';
import { EXPORT_DOWNLOAD_LINK, EXPORTS } from '../constants/mappings/success';
import { getPaginationData } from '../utils';

// set the initial state
const initialState = {
    exports: [],
    exportsPagination: {},
    loadingDeleteExport: false,
    loadingDownloadExportLink: false,
    loadingExport: false,
    loadingExports: false,
};

// define the slice
const ExportsSlice = createSlice({
    name: 'exportsSlice',
    initialState,
    reducers: {
        endExportLoading(state) {
            state.loadingExport = false;
        },
        endExportDeleteLoading(state) {
            state.loadingDeleteExport = false;
        },
        endExportDownloadLinkLoading(state) {
            state.loadingDownloadExportLink = false;
        },
        endExportsLoading(state) {
            state.loadingExports = false;
        },
        setExportLoading(state) {
            state.loadingExport = true;
        },
        setExportDeleteLoading(state) {
            state.loadingDeleteExport = true;
        },
        setExportDownloadLinkLoading(state) {
            state.loadingDownloadExportLink = true;
        },
        setExports(state, action) {
            state.exports = action.payload;
        },
        setExportsLoading(state) {
            state.loadingExports = true;
        },
        setExportsPagination(state, action) {
            state.exportsPagination = getPaginationData(action, true);
        },
    },
});

/**
 * Delete an export
 *
 * @param {string} exportId Export Id
 */
export const deleteExport = exportId => async dispatch => {
    try {
        dispatch(ExportsSlice.actions.setExportDeleteLoading());
        const result = await ExportsApi.deleteExport(exportId);
        dispatch(ExportsSlice.actions.setExports(get(result, EXPORTS)));
        dispatch(showNotificationModal(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, 'EXPORT_DELETE_ERROR'));
    } finally {
        dispatch(ExportsSlice.actions.endExportDeleteLoading());
    }
};

/**
 * Export the file
 *
 * @param {string} exportType Export Type
 * @param {string} accountId Account Id
 * @param {string} organisationId Organisation Id
 * @param {string} contactId Contact Id
 * @param {string} invoiceId Invoice Id
 * @param {object} queryParams Query Params
 */
export const exportFile = (
    exportType,
    accountId,
    organisationId,
    contactId,
    invoiceId,
    queryParams
) => async dispatch => {
    try {
        dispatch(ExportsSlice.actions.setExportLoading());
        const result = await ExportsApi.exportFile(
            exportType,
            accountId,
            organisationId,
            contactId,
            invoiceId,
            queryParams ? queryParams : {}
        );
        dispatch(showNotificationModal(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, 'EXPORT_ERROR'));
    } finally {
        dispatch(ExportsSlice.actions.endExportLoading());
    }
};

/**
 * Get the export download link
 *
 * @param {string} exportId Export Id
 */
export const getExportDownloadLink = exportId => async dispatch => {
    try {
        dispatch(ExportsSlice.actions.setExportDownloadLinkLoading());
        const result = await ExportsApi.getExportDownloadLink(exportId);
        const downloadLink = get(result, EXPORT_DOWNLOAD_LINK);
        if (downloadLink) {
            window.open(downloadLink, '_blank');
            dispatch(showNotificationModal(result));
        } else {
            dispatch(showNotificationModal('', false, 'EXPORT_DOWNLOAD_LINK_ERROR'));
        }
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, 'EXPORT_DOWNLOAD_LINK_ERROR'));
    } finally {
        dispatch(ExportsSlice.actions.endExportDownloadLinkLoading());
    }
};

/**
 * Get the exports
 *
 * @param {string} accountId Account Id
 */
export const getExports = accountId => async dispatch => {
    try {
        dispatch(ExportsSlice.actions.setExportsLoading());
        const result = await ExportsApi.getExports(accountId);
        dispatch(ExportsSlice.actions.setExports(get(result, EXPORTS)));
        dispatch(ExportsSlice.actions.setExportsPagination(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, 'EXPORT_ERROR'));
    } finally {
        dispatch(ExportsSlice.actions.endExportsLoading());
    }
};

// export the reducer
export default ExportsSlice.reducer;

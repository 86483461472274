/**
 * Base App File
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import the required modules
import { React, Component } from './includes/exports/react';
import { Spin } from 'antd';
import { I18nextProvider } from 'react-i18next';
import { CacheProvider } from 'includes/contexts/cacheContext';
//import { isEmpty } from 'lodash';

import AppRouter from './includes/routes/core';
//import { logoutUser } from './includes/redux/actions/shared/user';
import AppNotification from './components/shared/AppNotification';
import AppModalNotification from './components/shared/AppNotification/modal';
//import { buildQueryParamsObject, setDataInLocalStorage } from './includes/utils';
//import store from './includes/redux/store';
import i18n from './includes/i18n';
import './styles/output.css';

Spin.setDefaultIndicator(
    <object
        style={{ width: '150px', height: 'auto' }}
        type="image/svg+xml"
        data={require('./assets/images/loader-new.svg')}
        alt="loader"
    />
);

/**
 * App Class
 */
class App extends Component {
    render() {
        return (
            <div className={`App ${window.location.pathname.includes('/admin') === true ? 'admin-bg' : ''}`}>
                <I18nextProvider i18n={i18n}>
                    <CacheProvider>
                        <AppRouter />
                        <AppNotification />
                        <AppModalNotification />
                    </CacheProvider>
                </I18nextProvider>
            </div>
        );
    }
}

export default App;

/**
 * Organisation currencies breadcrumb items
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import AccountsBreadCrumbItems from '../Accounts';
import OrganisationsBreadCrumbItems from '../Organisations';
import PropTypes from 'prop-types';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OrganisationCurrenciesBreadCrumbItems = props => {
    const accountId = useAccountId();
    const isAdminOrManager = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    const { t } = useTranslation();

    /**
     * Get the organisation currencies list link
     *
     * @returns {string} List link
     */
    const getListLink = () =>
        isAdminOrManager ? `/admin/accounts/organisation/currencies/${accountId}/${organisationId}` : `/currencies`;

    /**
     * Render the component
     */
    return (
        <>
            {isAdminOrManager ? (
                <>
                    <AccountsBreadCrumbItems showListBreadCrumb />
                    <OrganisationsBreadCrumbItems showListBreadCrumb />
                </>
            ) : null}
            {props.showListBreadCrumb ? (
                <Breadcrumb.Item>
                    <Link to={getListLink()}>{t('messages.breadCrumbs.organisationCurrencies.list')}</Link>
                </Breadcrumb.Item>
            ) : null}
        </>
    );
};

OrganisationCurrenciesBreadCrumbItems.propTypes = {
    action: PropTypes.string,
    showListBreadCrumb: PropTypes.bool,
};

export default OrganisationCurrenciesBreadCrumbItems;

/**
 * Contact Reducer
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';
import { get } from 'lodash';
import * as successMappings from '../../../../constants/mappings/success';
import { getPaginationData } from '../../../../utils';
import { DELETE_SUBSCRIPTION_PLANS_REQUEST } from '../../../../constants/actionTypes';
import { DELETE_SUBSCRIPTION_PLANS_FAILURE } from '../../../../constants/actionTypes';
import { DELETE_SUBSCRIPTION_PLANS_SUCCESS } from '../../../../constants/actionTypes';

const INITIAL_STATE = {
    isCancellingSubscription: false,
    isCreatingSubscriptionPlan: false,
    isDeletingPlan: false,
    isDetailsLoading: false,
    isLoading: false,
    isSubscriptionsHistoryLoading: false,
    isUpdating: false,
    paginationPlanAccounts: {},
    paginationPlanQuotes: {},
    paginationSubscriptionsHistory: {},
    paymentGateway: {},
    planAccounts: [],
    planQuotes: [],
    subscriptionDetails: {},
    subscriptionPlanDetails: {},
    subscriptionPlans: [],
    subscriptionsHistory: [],
};

/**
 * Subscription plan Reducer Function
 * Handles the state changes
 *
 * @param   {object}    state   Current State
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function subscriptionPlanReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case actionTypes.ADD_SUBSCRIPTION_PLAN_FAILURE:
            return {
                ...state,
                isUpdating: false,
            };
        case actionTypes.ADD_SUBSCRIPTION_PLAN_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case actionTypes.ADD_SUBSCRIPTION_PLAN_SUCCESS:
            return {
                ...state,
                subscriptionPlanDetails: get(action.result, successMappings.GET_SUBSCRIPTION_PLAN_DETAILS),
                isUpdating: false,
            };
        case actionTypes.CANCEL_ACCOUNT_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                isCancellingSubscription: false,
            };
        case actionTypes.CANCEL_ACCOUNT_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                isCancellingSubscription: true,
            };
        case actionTypes.CANCEL_ACCOUNT_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                planAccounts: get(action.result, successMappings.CANCEL_ACCOUNT_SUBSCRIPTION),
                isCancellingSubscription: false,
            };
        case actionTypes.CREATE_SUBSCRIPTION_FOR_ACCOUNT_FAILURE:
            return {
                ...state,
                isCreatingSubscriptionPlan: false,
            };
        case actionTypes.CREATE_SUBSCRIPTION_FOR_ACCOUNT_REQUEST:
            return {
                ...state,
                isCreatingSubscriptionPlan: true,
            };
        case actionTypes.CREATE_SUBSCRIPTION_FOR_ACCOUNT_SUCCESS:
            return {
                ...state,
                subscriptionPlanDetails: get(action.result, successMappings.GET_SUBSCRIPTION_PLAN_DETAILS),
                isCreatingSubscriptionPlan: false,
            };
        case DELETE_SUBSCRIPTION_PLANS_FAILURE:
            return {
                ...state,
                isDeletingPlan: false,
            };
        case DELETE_SUBSCRIPTION_PLANS_REQUEST:
            return {
                ...state,
                isDeletingPlan: true,
            };
        case DELETE_SUBSCRIPTION_PLANS_SUCCESS:
            return {
                ...state,
                subscriptionPlans: get(action.result, successMappings.GET_SUBSCRIPTION_PLANS),
                paymentGateway: get(action.result, successMappings.GET_SUBSCRIPTION_PLANS_ADDITIONAL_CHARGES),
                paginationSubscriptionPlans: getPaginationData(action),
                isDeletingPlan: false,
            };
        case actionTypes.EXPORT_ALL_SUBSCRIPTIONS_FAILURE:
            return {
                ...state,
                isExportingAllSubscriptions: false,
            };
        case actionTypes.EXPORT_ALL_SUBSCRIPTIONS_REQUEST:
            return {
                ...state,
                isExportingAllSubscriptions: true,
            };
        case actionTypes.EXPORT_ALL_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                isExportingAllSubscriptions: false,
            };
        case actionTypes.GET_PLAN_ACCOUNTS_FAILURE:
            return {
                ...state,
                paginationPlanAccounts: {},
                isPlanAccountsLoading: false,
            };
        case actionTypes.GET_PLAN_ACCOUNTS_REQUEST:
            return {
                ...state,
                paginationPlanAccounts: {},
                planAccounts: [],
                isPlanAccountsLoading: true,
            };
        case actionTypes.GET_PLAN_ACCOUNTS_SUCCESS:
            return {
                ...state,
                planAccounts: get(action.result, successMappings.PLAN_ACCOUNTS),
                paginationPlanAccounts: getPaginationData(action),
                isPlanAccountsLoading: false,
            };
        case actionTypes.GET_PLAN_QUOTES_FAILURE:
            return {
                ...state,
                planAccounts: [],
                paginationPlanQuotes: {},
                isQuotesLoading: false,
            };
        case actionTypes.GET_PLAN_QUOTES_REQUEST:
            return {
                ...state,
                paginationPlanQuotes: {},
                isQuotesLoading: true,
            };
        case actionTypes.GET_PLAN_QUOTES_SUCCESS:
            return {
                ...state,
                planQuotes: get(action.result, successMappings.PLAN_QUOTES),
                paginationPlanQuotes: getPaginationData(action),
                isQuotesLoading: false,
            };
        case actionTypes.GET_SUBSCRIPTION_DETAILS_FAILURE:
            return {
                ...state,
                subscriptionDetails: {},
                isDetailsLoading: false,
            };
        case actionTypes.GET_SUBSCRIPTION_DETAILS_REQUEST:
            return {
                ...state,
                subscriptionDetails: {},
                isDetailsLoading: true,
            };
        case actionTypes.GET_SUBSCRIPTION_DETAILS_SUCCESS:
            return {
                ...state,
                subscriptionDetails: get(action.result, successMappings.GET_SUBSCRIPTION_DETAILS),
                isDetailsLoading: false,
            };
        case actionTypes.GET_SUBSCRIPTION_PLAN_DETAILS_FAILURE:
            return {
                ...state,
                subscriptionPlanDetails: {},
                isDetailsLoading: false,
            };
        case actionTypes.GET_SUBSCRIPTION_PLAN_DETAILS_REQUEST:
            return {
                ...state,
                subscriptionPlanDetails: {},
                isDetailsLoading: true,
            };
        case actionTypes.GET_SUBSCRIPTION_PLAN_DETAILS_SUCCESS:
            return {
                ...state,
                subscriptionPlanDetails: get(action.result, successMappings.GET_SUBSCRIPTION_PLAN_DETAILS),
                isDetailsLoading: false,
            };
        case actionTypes.GET_SUBSCRIPTION_PLANS_FAILURE:
            return {
                ...state,
                paginationSubscriptionPlans: {},
                isLoading: false,
            };
        case actionTypes.GET_SUBSCRIPTION_PLANS_REQUEST:
            return {
                ...state,
                paginationSubscriptionPlans: {},
                isLoading: true,
            };
        case actionTypes.GET_SUBSCRIPTION_PLANS_SUCCESS:
            return {
                ...state,
                subscriptionPlans: get(action.result, successMappings.GET_SUBSCRIPTION_PLANS),
                paymentGateway: get(action.result, successMappings.GET_SUBSCRIPTION_PLANS_ADDITIONAL_CHARGES),
                paginationSubscriptionPlans: getPaginationData(action),
                isLoading: false,
            };
        case actionTypes.GET_SUBSCRIPTION_STATUSES_FAILURE:
            return {
                ...state,
                isSubscriptionStatusesLoading: false,
            };
        case actionTypes.GET_SUBSCRIPTION_STATUSES_REQUEST:
            return {
                ...state,
                subscriptionStatuses: get(action.result, successMappings.GET_SUBSCRIPTION_STATUSES),
                isSubscriptionStatusesLoading: true,
            };
        case actionTypes.GET_SUBSCRIPTION_STATUSES_SUCCESS:
            return {
                ...state,
                subscriptionStatuses: get(action.result, successMappings.GET_SUBSCRIPTION_STATUSES),
                isSubscriptionStatusesLoading: false,
            };
        case actionTypes.GET_SUBSCRIPTIONS_HISTORY_FAILURE:
            return {
                ...state,
                paginationSubscriptionsHistory: {},
                isSubscriptionsHistoryLoading: false,
            };
        case actionTypes.GET_SUBSCRIPTIONS_HISTORY_REQUEST:
            return {
                ...state,
                paginationSubscriptionsHistory: {},
                isSubscriptionsHistoryLoading: true,
            };
        case actionTypes.GET_SUBSCRIPTIONS_HISTORY_SUCCESS:
            return {
                ...state,
                subscriptionsHistory: get(action.result, successMappings.GET_SUBSCRIPTIONS_HISTORY),
                paginationSubscriptionsHistory: getPaginationData(action),
                isSubscriptionsHistoryLoading: false,
            };
        case actionTypes.SET_SUBSCRIPTION_PLAN_ID:
            return {
                ...state,
                selectedPlanId: action.planId,
            };
        case actionTypes.UPDATE_SUBSCRIPTION_PLAN_FAILURE:
            return {
                ...state,
                isUpdating: false,
            };
        case actionTypes.UPDATE_SUBSCRIPTION_PLAN_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case actionTypes.UPDATE_SUBSCRIPTION_PLAN_SUCCESS:
            return {
                ...state,
                subscriptionPlanDetails: get(action.result, successMappings.GET_SUBSCRIPTION_PLAN_DETAILS),
                isUpdating: false,
            };
        default:
            return state;
    }
}

/**
 * Imports API class.
 * Handles all api requests related to import
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class ImportsApi extends BaseApiHandler {
    /**
     * Call the import template download link API
     *
     * @param {string} importType importType
     *
     * @returns {object} API Response
     */
    getImportTemplateDownloadLink = importType =>
        this.axios.get(this.getApiUrl('IMPORT_GET_TEMPLATE_DOWNLOAD_LINK', { import_type: importType }));

    /**
     * Call the import file API
     *
     * @param {string} organisationId Organisation Id
     * @param {FormData} formData File to import
     * @param {string} importType. File Import Type
     *
     * @returns {object} API Response
     */
    importFile = (organisationId, formData, importType) =>
        this.axios.post(
            this.getApiUrl('IMPORT_FILE', { import_type: importType, organisation_id: organisationId }),
            formData
        );
}

export default new ImportsApi();

/**
 * Add/Edit Communication template
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import { React, connect, PropTypes, withTranslation, Link } from '../../../includes/exports/react';
import TemplateForm from '../../shared/forms/TemplateForm';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';
import { getCommunicationTemplatePreview } from '../../../includes/redux/actions/shared/communication';
import { usePlaceholderDataFromStore } from '../../../includes/utils/hooks';
import { ORGANISATION_ID } from '../../../includes/constants/keys/request';
import useCommunicationTemplates from 'includes/hooks/useCommunicationTemplates';

/**
 * Add/Edit communication template
 */
const AddEditTemplate = props => {
    const { accountId } = props.match.params;
    const organisationId = props.organisationId || props.match.params.organisationId;
    const { isAdmin, type } = props;
    const { reset: clearCommunicationTemplatesCache } = useCommunicationTemplates(false);
    // get the placeholders
    usePlaceholderDataFromStore(organisationId);

    /**
     * Handle the submit of form
     *
     * @param {object} values Form values
     */
    const handleSubmit = values => {
        values[ORGANISATION_ID] = organisationId;
        if (values.id) {
            props.updateCommunicationTemplate(values, () => {
                clearCommunicationTemplatesCache();
                props.setListView(true);
            });
        } else {
            props.addCommunicationTemplate(organisationId, values, () => {
                clearCommunicationTemplatesCache();
                props.setListView(true);
            });
        }
    };

    return (
        <div className="my-profile-detail settings-edit-form edit-default-settings">
            <div className={isAdmin ? 'admin-crud-spec' : ''}>
                <div className="tab-arrow-link settings-tab-arrow">
                    <Breadcrumb>
                        {isAdmin && organisationId && (
                            <Breadcrumb.Item>
                                <Link to={`/admin/accounts/organisations/${accountId}`}>
                                    {props.t(`sharedMessages.communication.templates.breadcrumb.organisations`)}
                                </Link>
                            </Breadcrumb.Item>
                        )}
                        <Breadcrumb.Item>
                            <span
                                onClick={() => {
                                    props.setListView(true);
                                }}
                                className="crud-link"
                            >
                                {props.t(`sharedMessages.communication.templates.${type}.templates`)}
                            </span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {props.t(
                                `sharedMessages.communication.templates.${type}.${
                                    get(props, ['initialValues', 'id'], '') ? 'edit' : 'add'
                                }`
                            )}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            <TemplateForm {...props} onSubmit={handleSubmit} type={type} />
        </div>
    );
};

// prop types
AddEditTemplate.propTypes = {
    addCommunicationTemplate: PropTypes.func,
    getCommunicationTemplateDetails: PropTypes.func,
    isAdmin: PropTypes.bool,
    match: PropTypes.object,
    organisationId: PropTypes.string,
    setListView: PropTypes.func,
    t: PropTypes.string,
    type: PropTypes.string,
    types: PropTypes.array,
    updateCommunicationTemplate: PropTypes.func,
};

// connect to store
export default connect(
    state => ({
        allPlaceholders: state.placeholder.allPlaceholders,
        isAdmin: state.account.isAdmin,
        isAddingCommunicationTemplate: state.communication.isAddingCommunicationTemplate,
        isUpdatingCommunicationTemplate: state.communication.isUpdatingCommunicationTemplate,
        isLoadingPreview: state.communication.isLoadingPreview,
        organisationId: state.organisation.selectedOrganisationId,
        preview: state.communication.preview,
    }),
    { getCommunicationTemplatePreview }
)(withTranslation()(AddEditTemplate));

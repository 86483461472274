/**
 * Home Component
 * Handles the home page view and functions related to loading home page
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import the required modules
import { React, useEffect, useState } from '../../../includes/exports/react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';

import './style.scss';
import Button from '../../shared/Button';
import * as constants from '../../../includes/constants';
import {
    getAccountingSoftwareAuthLink,
    verifyAccountingSoftwareAuthorization,
} from '../../../includes/redux/actions/customer/connect';
import { getAccountingSoftwares } from '../../../includes/redux/actions/shared/accountingSoftware';
import history from '../../../includes/exports/history';
import * as requestKeys from '../../../includes/constants/keys/request';
import * as utils from '../../../includes/utils';
import Loader from '../../shared/Loader';
import { useDataFromStore, useUpdateBusinessName, useCheckEditPermission } from '../../../includes/utils/hooks';
import { redirect } from '../../../includes/utils';
import { ENABLE_XERO_CONSENT } from '../../../includes/constants/config';

/**
 * Connect Component
 */
const Connect = ({ ...props }) => {
    const [accountingSoftwares, isAccountingSoftwareLoading] = useDataFromStore({
        reducer: 'accountingSoftware',
        dataKey: 'accountingSoftwares',
        loadingKey: 'isLoading',
        action: getAccountingSoftwares,
    });

    useCheckEditPermission();

    const [showAccountingSoftwareSelect, setAccountingSoftwareSelectShowHide] = useState(
        !utils.getLocalStorageValue(constants.USER_ACCOUNTING_SOFTWARE_ID_KEY)
    );

    const [accountingSoftwareId, setAccountingSoftwareId] = useState(
        utils.getLocalStorageValue(constants.USER_ACCOUNTING_SOFTWARE_ID_KEY)
    );

    const changeAccountingSoftware = () => {
        utils.removeLocalStorage(constants.USER_ACCOUNTING_SOFTWARE_ID_KEY);
        setAccountingSoftwareSelectShowHide(true);
        setAccountingSoftwareId('');
    };

    /**
     * Use effect hook called on mount
     */
    useEffect(() => {
        let userReauthorizingOrganisation = utils.getLocalStorageValue(constants.USER_REAUTHORIZING_ORGANISATION_KEY);
        let accountingSoftwareId = utils.getLocalStorageValue(constants.USER_ACCOUNTING_SOFTWARE_ID_KEY);
        if (userReauthorizingOrganisation && accountingSoftwareId) {
            let locationSearchProps = props.location.search;
            if (locationSearchProps) {
                history.replace(`/organisations${props.location.search}`);
            } else {
                utils.removeLocalStorage(constants.USER_REAUTHORIZING_ORGANISATION_KEY);
                utils.removeLocalStorage(constants.USER_ACCOUNTING_SOFTWARE_ID_KEY);
            }
        }
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use effect hook for verifying/getting authorization link
     */
    useEffect(() => {
        let userReauthorizingOrganisation = utils.getLocalStorageValue(constants.USER_REAUTHORIZING_ORGANISATION_KEY);
        if (!isAccountingSoftwareLoading && !showAccountingSoftwareSelect && !userReauthorizingOrganisation) {
            //build the json object from url
            let urlParams = utils.buildQueryParamsObject(props.location.search);

            // add code to oauth token
            urlParams.oauth_token = urlParams.code;

            // check if oauth token exists in the url params
            if (urlParams.oauth_token && utils.getLocalStorageValue(constants.USER_ACCOUNTING_SOFTWARE_ID_KEY)) {
                urlParams[requestKeys.ACCOUNTING_SOFTWARE_ID] = utils.getLocalStorageValue(
                    constants.USER_ACCOUNTING_SOFTWARE_ID_KEY
                );
                props.verifyAccountingSoftwareAuthorization(urlParams);
                history.replace('/connect');
            }
        }
    }, [isAccountingSoftwareLoading]); //eslint-disable-line react-hooks/exhaustive-deps

    useUpdateBusinessName();

    /**
     * Get title and meta tags for the component
     *
     * @returns  {object}
     */
    const getAccountingSoftwareName = () => ({
        accountingSoftware: get(accountingSoftwares, [accountingSoftwareId, 'name']),
    });

    const getAccountingSoftwareSlug = () => get(accountingSoftwares, [accountingSoftwareId, 'slug']);

    /**
     * Get the authorization link
     */
    const getAuthorizationLink = () =>
        getAccountingSoftwareSlug() === 'xero' && ENABLE_XERO_CONSENT
            ? redirect('/connect/xero/consent')
            : props.getAccountingSoftwareAuthLink({ accounting_software_id: accountingSoftwareId });

    /**
     * Set the accounting software on selection
     *
     * @param {object} e    Event
     */
    const setAccountingSoftware = e => {
        let data = {};
        data[constants.USER_ACCOUNTING_SOFTWARE_ID_KEY] = e.currentTarget.getAttribute('data-attr-id');

        //set the data in local storage
        utils.setDataInLocalStorage(data);

        //change the state
        setAccountingSoftwareSelectShowHide(false);
        setAccountingSoftwareId(e.currentTarget.getAttribute('data-attr-id'));
    };

    /**
     * Render the Connect page
     */
    return (
        <section className="connect-wrapper box-wrapper white-box-wrapper">
            {Object.values(accountingSoftwares).length > 0 ? (
                !showAccountingSoftwareSelect ? (
                    <>
                        <h2 className="welcome-paycepaid-text">{props.t('customerMessages.connect.welcomeText')}</h2>
                        <p>
                            {props.t('customerMessages.connect.letsConnectMessagePart1')}
                            {<b>{getAccountingSoftwareName().accountingSoftware}</b>}
                            {props.t('customerMessages.connect.letsConnectMessagePart2')}
                            {props.t('customerMessages.connect.letsConnectMessagePart3')}
                        </p>
                        <ul className="xero-paycepaid-logo">
                            <li>
                                <img
                                    src={require(`../../../assets/images/accounting_software/${getAccountingSoftwareSlug()}.png`)}
                                    alt="group"
                                />
                            </li>
                            <li>
                                <img src={require('../../../assets/images/rectangle.png')} alt="group" />
                            </li>
                            <li>
                                <img
                                    src={require('../../../assets/images/paycepaid.svg')}
                                    style={{ width: 70 }}
                                    alt="group"
                                />
                            </li>
                        </ul>
                        {props.accountingSoftwareErrorMessage ? (
                            <div className="has-error">
                                <p className="ant-form-explain">{props.accountingSoftwareErrorMessage}</p>
                            </div>
                        ) : (
                            ''
                        )}
                        <Button
                            loading={props.isLoading}
                            onClick={getAuthorizationLink}
                            big
                            filled
                            submittingButtonLabel={
                                props.connectToAccountingSoftwareLoading
                                    ? props.t(
                                          'customerMessages.connect.getAuthorizationSubmittingButtonLabel',
                                          getAccountingSoftwareName()
                                      )
                                    : props.verifyAccountingSoftwareAuthorizationLoading
                                    ? props.t('customerMessages.connect.verifyAuthorizationSubmittingButtonLabel')
                                    : ''
                            }
                        >
                            {props.t('customerMessages.connect.submitButtonLabel', getAccountingSoftwareName())}
                        </Button>
                        <div className="inner-desc">
                            <span>{props.t('customerMessages.connect.notUsing', getAccountingSoftwareName())}</span>
                            <span className="link" onClick={changeAccountingSoftware}>
                                {' '}
                                {props.t('customerMessages.connect.chooseAnother')}
                            </span>
                        </div>
                    </>
                ) : (
                    <>
                        <h2 className="welcome-paycepaid-text">{props.t('customerMessages.connect.welcomeText')}</h2>
                        <p className="app-tip-message">
                            {props.t('customerMessages.connect.selectAccountingSoftware')}
                        </p>
                        <ul>
                            {Object.values(accountingSoftwares).map(
                                accountingSoftware =>
                                    accountingSoftware.is_external === true && (
                                        <li
                                            onClick={setAccountingSoftware}
                                            key={accountingSoftware.slug}
                                            data-attr-id={accountingSoftware.id}
                                        >
                                            <img
                                                src={require(`../../../assets/images/accounting_software/${accountingSoftware.slug}.png`)}
                                                alt={`${accountingSoftware.slug}-logo`}
                                            />
                                        </li>
                                    )
                            )}
                        </ul>
                    </>
                )
            ) : (
                <Loader tip={props.t('appCommonMessages.loadingMessage')} />
            )}
        </section>
    );
};

/**
 * Proptypes
 */
Connect.propTypes = {
    getAccountingSoftwareAuthLink: PropTypes.func,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    verifyAccountingSoftwareAuthorization: PropTypes.func,
    t: PropTypes.func,
    connectToAccountingSoftwareLoading: PropTypes.bool,
    doingAccountingSoftwareRequest: PropTypes.bool,
    verifyAccountingSoftwareAuthorizationLoading: PropTypes.bool,
    accountingSoftwareErrorMessage: PropTypes.string,
    accountingSoftwares: PropTypes.object,
    showAccountingSoftwareSelect: PropTypes.bool,
    localStorage: PropTypes.any,
};

//Connect to store
export default connect(
    state => ({
        isLoading: state.connect.loading,
        connectToAccountingSoftwareLoading: state.connect.connectToAccountingSoftwareLoading,
        doingAccountingSoftwareRequest: state.accountingSoftware.doingAccountingSoftwareRequest,
        verifyAccountingSoftwareAuthorizationLoading: state.connect.verifyAccountingSoftwareAuthorizationLoading,
        accountingSoftwareErrorMessage: state.connect.accountingSoftwareErrorMessage,
        localStorage: state.localStorage,
    }),
    {
        getAccountingSoftwareAuthLink,
        verifyAccountingSoftwareAuthorization,
    }
)(withTranslation()(Connect));

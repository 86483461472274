/**
 * useDateToMoment
 * Converts the date string to moment
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import moment from 'moment';

/**
 * Get the method to convert date to moment
 *
 * @returns {(dateString: string) => moment}
 */
const useDateToMoment = () => {
    const orgFEFormat = 'DD-MM-YYYY';
    return date => moment(date, orgFEFormat);
};

export default useDateToMoment;

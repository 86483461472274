import i18n from 'includes/i18n';

/**
 * Get debtor accounts table columns
 */
export const getDebtorAccountsTableColumns = [
    {
        title: i18n.t('messages.debtorAccounts.list.label.accountName'),
        dataIndex: 'account_name',
        key: 'account_name',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('messages.debtorAccounts.list.label.accountEmail'),
        dataIndex: 'account_email',
        key: 'account_email',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('messages.debtorAccounts.list.label.contact'),
        dataIndex: 'contact',
        key: 'contact',
        sorter: true,
    },
    {
        title: i18n.t('messages.debtorAccounts.list.label.organisation'),
        dataIndex: 'organisation',
        key: 'organisation',
    },
    {
        title: i18n.t('messages.debtorAccounts.list.label.createdDate'),
        dataIndex: 'created_date',
        key: 'created_date',
        sorter: true,
    },
];

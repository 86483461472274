/**
 * Subscription Plan API class.
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class SubscriptionPlanApi extends BaseApiHandler {
    /**
     * Call the plan quotes API
     *
     * @returns {object} API Response
     */
    addSubscriptionPlan = payload => this.axios.post(this.getApiUrl('SUBSCRIPTION_PLAN_GET_ALL'), payload);

    /**
     * Call the plan quotes API
     *
     * @param {string}    subscriptionId    Subscription Id
     * @param {object} payload      Payload
     *
     * @returns {object} API Response
     */
    cancelSubscription = ({ subscription_id, ...payload }) => {
        return this.axios.delete(this.getApiUrl('SUBSCRIPTION_DETAILS', { subscription_id: subscription_id }), {
            data: payload,
        });
    };

    /**
     * Call the plan quotes API
     *
     * @param {object}    payload    Payload to be sent
     *
     * @returns {object} API Response
     */
    createSubscription = payload => this.axios.post(this.getApiUrl('SUBSCRIPTIONS_GET_ALL'), payload);

    /**
     * Call the Delete subscription plan API
     *
     * @param {string}    planId    Plan Id
     *
     * @returns {object} API Response
     */
    deleteSubscriptionPlan = planId =>
        this.axios.delete(this.getApiUrl('SUBSCRIPTION_PLAN_GET_DETAILS', { plan_id: planId }));

    /**
     * Call the Export subscription API
     *
     * @returns {object} API Response
     */
    exportAllSubscriptions = () => this.axios.get(this.getApiUrl('EXPORT_ALL_SUBSCRIPTIONS'));

    /**
     * Call the plan accounts API
     *
     * @returns {object} API Response
     */
    getPlanAccounts = ({ planId, ...restPayload }) =>
        this.axios.get(this.getApiUrl('SUBSCRIPTION_PLAN_ACCOUNTS_GET_ALL', { plan_id: planId }), {
            params: { ...restPayload },
        });

    /**
     * Call the plan quotes API
     *
     * @returns {object} API Response
     */
    getPlanQuotes = ({ searchParams }) =>
        this.axios.get(this.getApiUrl('REQUEST_QUOTE_LIST'), { params: { ...searchParams } });

    /**
     * Call the subscription details API
     *
     * @returns {object} API Response
     */
    getSubscriptionDetails = payload => this.axios.get(this.getApiUrl('SUBSCRIPTION_DETAILS', payload));

    /**
     * Call the subscription plan details API
     *
     * @returns {object} API Response
     */
    getSubscriptionPlanDetails = payload => this.axios.get(this.getApiUrl('SUBSCRIPTION_PLAN_GET_DETAILS', payload));

    /**
     * Call the subscription plan listing API
     *
     * @param {object}    searchParams    Search Params
     *
     * @returns {object} API Response
     */
    getSubscriptionPlans = ({ searchParams }) =>
        this.axios.get(this.getApiUrl('SUBSCRIPTION_PLAN_GET_ALL'), { params: { ...searchParams } });

    /**
     * Call the subscription status API
     *
     * @returns {object} API Response
     */
    getSubscriptionStatuses = payload =>
        this.axios.get(this.getApiUrl('SUBSCRIPTION_STATUS_GET_ALL'), { params: payload });

    /**
     * Call the subscription history listing API
     *
     * @returns {object} API Response
     */
    getSubscriptionsHistory = payload => this.axios.get(this.getApiUrl('SUBSCRIPTIONS_GET_ALL'), { params: payload });

    /**
     * Call the update subscription plan API
     *
     * @returns {object} API Response
     */
    updateSubscriptionPlan = ({ plan_id, planDetails }) =>
        this.axios.patch(this.getApiUrl('SUBSCRIPTION_PLAN_GET_DETAILS', { plan_id: plan_id }), planDetails);
}

export default new SubscriptionPlanApi();

/**
 * useOrganisationCurrency
 * Get the organisation currency from store
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { get } from 'lodash';
import { useSelector } from 'react-redux';

/**
 * Get the organisation currency
 *
 * @param {string} key Key to be retrieved from currency. Default ''
 *
 * @returns {*} currency Currency details
 */
const useOrganisationCurrency = (key = '', defaultValue = '') => {
    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);
    const currency = get(selectedOrganisation, 'currency');
    if (key) {
        return get(currency, key, defaultValue);
    }
    return currency;
};

export default useOrganisationCurrency;

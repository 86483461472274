/**
 * Plan Payment Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { formValueSelector, getFormSubmitErrors, submit } from 'redux-form';

import { MONTHLY, SUBSCRIPTION_PAYMENT_FORM_NAME, YEARLY } from '../../../../includes/constants';
import { getAccountCoupons } from '../../../../includes/redux/actions/shared/account';
import { getOrderDetails } from '../../../../includes/slices/orders';
import { useCurrentRoleSlug } from '../../../../includes/utils/hooks';
import PlanPaymentForm from '../../../shared/forms/Payments/PlanPayment';

// Plan payment component
const PlanPayment = props => {
    const { orderId, orderType } = props.match.params;

    const dispatch = useDispatch();

    const isRetry = orderId && orderType;

    const { getAccountCoupons, accountId } = props;
    const orderDetails = useSelector(state => state.orders.orderDetails);

    // get current user's role slug
    const currentRoleSlug = useCurrentRoleSlug();

    useEffect(() => {
        getAccountCoupons(accountId);
        if (isRetry) {
            dispatch(getOrderDetails(orderType, orderId, currentRoleSlug));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handle the payment submit
     *
     * @param {object} values Form Values
     */
    const handleSubmit = () => {
        // props.handleCreateOrder(ORDER_TYPE.SUBSCRIPTION, SUBSCRIPTION_PAYMENT_FORM_NAME, values, stripe, elements);
    };

    /**
     * Set the initial values for the form
     */
    const initialValues = orderId
        ? {
              id: orderId,
              plan_id: get(orderDetails, 'plan.id'),
              plan_type: get(orderDetails, 'plan.is_yearly') ? YEARLY : MONTHLY,
          }
        : { plan_type: YEARLY, plan_id: get(props, 'location.state.plan_id', '') };

    return (
        <PlanPaymentForm
            {...props}
            enableReinitialize
            initialValues={initialValues}
            isLoadingPayButton={props.isLoadingPayButton}
            orderType={orderType}
            onSubmit={handleSubmit}
            paymentGatewayError={props.paymentGatewayError}
        />
    );
};

// Prop types
PlanPayment.propTypes = {
    accountId: PropTypes.string,
    getAccountCoupons: PropTypes.func,
    handleCreateOrder: PropTypes.func,
    isLoadingPayButton: PropTypes.bool,
    match: PropTypes.object,
    paymentGatewayError: PropTypes.string,
    retryOrderPayment: PropTypes.func,
};

// connect to store
export default connect(
    state => {
        const selector = formValueSelector(SUBSCRIPTION_PAYMENT_FORM_NAME);
        const submitErrorsSelector = getFormSubmitErrors(SUBSCRIPTION_PAYMENT_FORM_NAME);
        return {
            accountId: state.account.currentAccountId,
            coupons: state.account.coupons,
            selectedPlan: selector(state, 'plan_id'),
            planType: selector(state, 'plan_type'),
            appliedCoupon: selector(state, 'coupon'),
            submitErrors: submitErrorsSelector(state),
        };
    },
    {
        submit,
        getAccountCoupons,
    }
)(PlanPayment);

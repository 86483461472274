/**
 * Redux admin settings actions
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

import { GET_ADMIN_SETTINGS_REQUEST, SIDEBAR_COLLAPSE_TOGGLE } from '../../../constants/actionTypes';

/**
 * Get settings data
 *
 * @returns {object}    Get Invoice statuses action
 */
export const getSettings = () => ({
    type: GET_ADMIN_SETTINGS_REQUEST,
});

/**
 * update admin settings
 *
 */
export const updateSettings = () => ({
    type: GET_ADMIN_SETTINGS_REQUEST,
});

/**
 * Toggle sidebar view
 *
 */
export const toggleSidebar = visible => {
    return {
        type: SIDEBAR_COLLAPSE_TOGGLE,
        visible,
    };
};

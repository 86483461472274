/**
 * Notifications link Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import { React } from '../../../includes/exports/react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

/**
 * Notification link Listing Component
 * Render the notification link component
 *
 * @returns    {string}    Notification link
 */
const NotificationLink = props => {
    /**
     * Render the component
     */
    return props.link ? (
        <Link to={props.link} onClick={() => props.forceReRenderComponent(props.link)}>
            {props.children}
        </Link>
    ) : (
        <>{props.children}</>
    );
};

/**
 * Proptypes
 */
NotificationLink.propTypes = {
    children: PropTypes.any,
    forceReRenderComponent: PropTypes.func,
    link: PropTypes.string,
    location: PropTypes.object,
};

export default NotificationLink;

import { Card, Col, Collapse, Divider, Modal, Row, Tooltip, Typography } from 'antd';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPaymentAnalysis, getPaymentAnalysisCategories } from 'includes/slices/openBanking';
import { PropTypes } from 'includes/exports/react';
import Loader from 'components/shared/Loader';
import { useTranslation } from 'react-i18next';
import { OPEN_BANKING_PAYMENT_BREAK_DURATION } from 'includes/constants';
import { useState } from 'react';
import './styles.scss';
import { get } from 'lodash';

const { Title } = Typography;

const PaymentAnalysis = ({
    analysisId = '',
    organisationId = '',
    analysisType = 1,
    getNumberFormattedWithPaymentArrangementCurrency,
    isAccountDeleted,
}) => {
    const dispatch = useDispatch();

    const [activeCollapse, setActiveCollapse] = useState([0]);
    const [activeInnerCategory, setActiveInnerCategory] = useState([0]);

    const [selectedCategory, setSelectedCategory] = useState({});

    const [isOpen, setIsOpen] = useState(false);

    const { t } = useTranslation();

    const formatCurrency = amount => getNumberFormattedWithPaymentArrangementCurrency(amount);

    /**
     * Get the localized label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.paymentArrangement.arrangement.addEdit.form.input.label.${field}`);

    const paymentAnalysis = useSelector(state => state?.openBanking?.paymentAnalysis);
    const isPaymentAnalysisLoading = useSelector(state => state?.openBanking?.isPaymentAnalysisLoading);

    const paymentAnalysisCategories = useSelector(state => state?.openBanking?.paymentAnalysisCategories);
    const isPaymentAnalysisCategoriesLoading = useSelector(
        state => state?.openBanking?.isPaymentAnalysisCategoriesLoading
    );

    const paymentFrequencyType = useMemo(() => {
        return OPEN_BANKING_PAYMENT_BREAK_DURATION.find(item => item?.id === paymentAnalysis?.payment_frequency_type)
            ?.label;
    }, [paymentAnalysis]);

    useEffect(() => {
        dispatch(getPaymentAnalysis(organisationId, analysisId));
        if (analysisType === 2) {
            dispatch(getPaymentAnalysisCategories(organisationId, analysisId));
        }
    }, []);

    /**
     * Show detail categories modal
     */
    const handleModalOpen = category => () => {
        setActiveInnerCategory([0]);
        setSelectedCategory(category);
        setIsOpen(true);
    };

    /**
     * Close detail categories modal
     */
    const handleModalClose = () => {
        setSelectedCategory({});
        setIsOpen(false);
    };

    return (
        <div>
            <Modal
                title={get(selectedCategory, 'name', '')}
                visible={isOpen}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                centered={false}
                width={1400}
            >
                <Collapse
                    className="text-left"
                    expandIconPosition="right"
                    accordion
                    onChange={pan => setActiveInnerCategory(pan)}
                    activeKey={activeInnerCategory}
                >
                    {get(selectedCategory, 'categories', []).map((category, i) => (
                        <Collapse.Panel
                            header={category?.name === '' ? 'Other' : category?.name}
                            key={i}
                            extra={
                                <div className="green-text font-semibold">{formatCurrency(category?.total_amount)}</div>
                            }
                        >
                            <div className=" flex flex-col items-end ml-auto expense-category-values">
                                <h6 className="flex gap-2 justify-between w-full">
                                    Avg Expense
                                    <span className="font-semibold">{formatCurrency(category?.avg_amount)}</span>
                                    <span>/ {paymentFrequencyType}</span>
                                </h6>
                                <h6 className="flex gap-2 justify-between w-full">
                                    Excepted
                                    <span className="font-semibold">{category?.exception_percentage}%</span>
                                </h6>
                                <h6 className="flex gap-2 justify-between w-full">
                                    <Tooltip title="Available funds are calculated by subtracting expenses from the total amount and then further deducting buffer">
                                        Calculated Amount
                                    </Tooltip>
                                    <span className="font-semibold">
                                        {formatCurrency(category?.avg_recommended_amount)}
                                    </span>
                                    <span>/ {paymentFrequencyType}</span>
                                </h6>
                            </div>
                        </Collapse.Panel>
                    ))}
                </Collapse>
            </Modal>
            {isPaymentAnalysisLoading ? (
                <Loader />
            ) : (
                <div>
                    <Title level={4}>{getLocaleLabel('payment_analysis')}</Title>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Card className="rounded-md">
                                <h6>Repayment amount</h6>
                                <div className="flex items-baseline	justify-evenly max-w-max mx-auto ">
                                    <Title level={4}>{formatCurrency(paymentAnalysis?.amount?.amount_short)}</Title>
                                    <p>/ {paymentFrequencyType}</p>
                                </div>
                            </Card>
                        </Col>

                        <Col span={18}>
                            <div>
                                <h6 className="flex justify-between">
                                    {getLocaleLabel('average_income')}
                                    <span className="text-lg">
                                        {formatCurrency(paymentAnalysis?.extra_data?.avg_income?.amount_short)}
                                        <span className="text-sm"> / {paymentFrequencyType}</span>
                                    </span>
                                </h6>
                                <Divider className="m-0" />
                                <h6 className="flex justify-between">
                                    {getLocaleLabel('average_expense')}
                                    <span className="text-lg">
                                        {formatCurrency(paymentAnalysis?.extra_data?.avg_expense?.amount_short)}
                                        <span className="text-sm"> / {paymentFrequencyType}</span>
                                    </span>
                                </h6>
                                {paymentAnalysis?.extra_data?.avg_fixed_expense && (
                                    <>
                                        <Divider className="m-0" />
                                        <h6 className="flex justify-between">
                                            Average Fixed Expense
                                            <span className="text-lg">
                                                {formatCurrency(
                                                    paymentAnalysis?.extra_data?.avg_fixed_expense?.amount_short
                                                )}
                                                <span className="text-sm"> / {paymentFrequencyType}</span>
                                            </span>
                                        </h6>
                                    </>
                                )}
                                {paymentAnalysis?.extra_data?.avg_flexible_expense && (
                                    <>
                                        <Divider className="m-0" />
                                        <h6 className="flex justify-between">
                                            {getLocaleLabel('average_flexible_expense')}
                                            <span className="text-lg">
                                                {formatCurrency(
                                                    paymentAnalysis?.extra_data?.avg_flexible_expense?.amount_short
                                                )}
                                                <span className="text-sm"> / {paymentFrequencyType}</span>
                                            </span>
                                        </h6>
                                    </>
                                )}

                                {paymentAnalysis?.extra_data?.recommended_avg_flexible_expense && (
                                    <>
                                        <Divider className="m-0" />
                                        <h6 className="flex justify-between">
                                            {getLocaleLabel('recommended_avg_expense')}
                                            <span className="text-lg">
                                                {formatCurrency(
                                                    paymentAnalysis?.extra_data?.recommended_avg_flexible_expense
                                                        ?.amount_short
                                                )}
                                                <span className="text-sm"> / {paymentFrequencyType}</span>
                                            </span>
                                        </h6>
                                    </>
                                )}

                                {paymentAnalysis?.extra_data?.recommended_reduction && (
                                    <>
                                        <Divider className="m-0" />
                                        <h6 className="flex justify-between">
                                            Recommended Reduction
                                            <span className="text-lg">
                                                {formatCurrency(
                                                    paymentAnalysis?.extra_data?.recommended_reduction?.amount_short
                                                )}
                                                <span className="text-sm"> / {paymentFrequencyType}</span>
                                            </span>
                                        </h6>
                                    </>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <div>
                        <div className="my-4">
                            <Title level={4}>Survey</Title>
                            {get(paymentAnalysis, 'surveys', []).map((item, i) => (
                                <div key={i} className="text-left text-sm ">
                                    <h6>Q: {item.question}</h6>
                                    <p className="my-2">A: {item.option}</p>
                                    <Divider className="m-0  " />
                                </div>
                            ))}
                        </div>
                        {analysisType === 2 &&
                            !isAccountDeleted &&
                            (isPaymentAnalysisCategoriesLoading ? (
                                <Loader />
                            ) : (
                                <div>
                                    <Title level={4}>Expense</Title>
                                    <Collapse
                                        className="text-left"
                                        expandIconPosition="right"
                                        accordion
                                        onChange={pan => setActiveCollapse(pan)}
                                        activeKey={activeCollapse}
                                    >
                                        {get(paymentAnalysisCategories, 'categories', []).map((category, i) => (
                                            <Collapse.Panel
                                                header={category?.name === '' ? 'Other' : category?.name}
                                                key={i}
                                            >
                                                <Row>
                                                    {get(category, 'categories', []).map((innerCategory, j) => (
                                                        <Col span={8} className="m-2" key={j}>
                                                            <Card
                                                                onClick={handleModalOpen(innerCategory)}
                                                                className="expense-category-card"
                                                            >
                                                                <h4>
                                                                    {innerCategory?.name === ''
                                                                        ? 'Other'
                                                                        : innerCategory?.name}
                                                                </h4>
                                                                <div className="mt-2 text-sm font-normal">
                                                                    <p>
                                                                        Expense
                                                                        <span>
                                                                            {' '}
                                                                            {formatCurrency(
                                                                                innerCategory?.total_amount
                                                                            )}
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        Calculated Amount
                                                                        <span>
                                                                            {' '}
                                                                            {formatCurrency(
                                                                                innerCategory?.avg_recommended_amount
                                                                            )}
                                                                        </span>
                                                                        <span> / {paymentFrequencyType}</span>
                                                                    </p>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Collapse.Panel>
                                        ))}
                                    </Collapse>
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
};

PaymentAnalysis.propTypes = {
    getNumberFormattedWithPaymentArrangementCurrency: PropTypes.func,
    organisationId: PropTypes.string,
    analysisId: PropTypes.string,
    analysisType: PropTypes.number,
    isAccountDeleted: PropTypes.boolean,
};

export default PaymentAnalysis;

/**
 * On-Demand Campaign Drop down Component
 *
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 * @version 1.0
 */

//import required modules
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Icon, Menu } from 'antd';

import Button from 'components/Button';
import { redirect } from 'includes/utils';
import PermissionGuard from 'components/PermissionGuard';
import { INVOICE } from 'includes/constants/permissions';

/**
 * On Demand Campaign Dropdown component
 *
 * @returns    {string}    Export menus view
 */
const OnDemandCampaignDropDown = props => {
    /**
     * Define the export menus
     */
    const menus = (
        <Menu>
            <Menu.Item key="send-on-demand-campaign">
                <PermissionGuard requiredPermission={INVOICE.ON_DEMAND_CAMPAIGN.SEND}>
                    <span onClick={() => props.showModal(true)}>Send</span>
                </PermissionGuard>
            </Menu.Item>
            <Menu.Item key="on-demand-campaign-history">
                <PermissionGuard requiredPermission={INVOICE.ON_DEMAND_CAMPAIGN.HISTORY}>
                    <span
                        onClick={() =>
                            redirect(
                                props.isAdmin
                                    ? `/admin/accounts/organisation/invoices/${props.accountId}/${props.organisationId}/on-demand-campaign/history/`
                                    : `/invoices/on-demand-campaign/history`
                            )
                        }
                    >
                        History
                    </span>
                </PermissionGuard>
            </Menu.Item>
        </Menu>
    );

    return (
        <PermissionGuard requiredPermission={[INVOICE.ON_DEMAND_CAMPAIGN.SEND, INVOICE.ON_DEMAND_CAMPAIGN.HISTORY]}>
            <Dropdown overlay={menus}>
                <Button big filled>
                    On Demand Campaign
                    <Icon type="down" />
                </Button>
            </Dropdown>
        </PermissionGuard>
    );
};

OnDemandCampaignDropDown.propTypes = {
    accountId: PropTypes.string,
    isAdmin: PropTypes.bool,
    organisationId: PropTypes.string,
    showModal: PropTypes.func,
};

export default OnDemandCampaignDropDown;

import PropTypes from 'prop-types';
import { CARD_BRAND_LOGO_MAP, GENERIC_CARD_LOGO } from 'components/customer/PaymentInformation/constants';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

/**
 * Order payment method
 */
export default function OrderPaymentMethod({ brand, id, number: _number, type }) {
    const number = useMemo(() => _number.replaceAll('*', ''), [_number]);

    return (
        <div className={'flex flex-row  items-center gap-2 w-100'}>
            <img
                height={30}
                width={30}
                style={{ height: 30, width: 30 }}
                src={CARD_BRAND_LOGO_MAP[brand] ?? GENERIC_CARD_LOGO}
            />

            {id ? (
                <Link to={`/payment-information/payment-method/${id}`}>
                    {`${type} ending with`} <span className="font-mono">{number}</span>
                </Link>
            ) : (
                <div>
                    {`${type} ending with`} <span className="font-mono">{number}</span>
                </div>
            )}
        </div>
    );
}

OrderPaymentMethod.propTypes = {
    brand: PropTypes.string,
    id: PropTypes.string,
    number: PropTypes.string,
    type: PropTypes.string,
};

/**
 * Communication Reducer
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';
import { get } from 'lodash';
import * as successMappings from '../../../../constants/mappings/success';
import { getPaginationData } from '../../../../utils';

const INITIAL_STATE = {
    allCommunicationTemplates: {},
    communicationLogHistory: [],
    communicationLogHistoryDetails: {},
    communicationLogStatuses: [],
    communicationTemplates: [],
    communicationTypes: [],
    isAddingCommunicationTemplate: false,
    isAllCommunicationTemplatesLoading: false,
    isCommunicationLogHistoryDetailsLoading: false,
    isCommunicationLogHistoryLoading: false,
    isCommunicationLogStatusesLoading: false,
    isLoading: true,
    isLoadingDetails: true,
    isTypesLoading: false,
    paginationCommunicationLogHistory: {},
    paginationCommunicationTemplates: {},
    preview: '<html><body>No preview available</body></html>',
    templateDetails: {},
    templateTypes: [],
};

/**
 * Communication Reducer Function
 * Handles the state changes in communication
 *
 * @param   {object}    state   Current State
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function communicationReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case actionTypes.ADD_COMMUNICATION_TEMPLATE_FAILURE:
            return {
                ...state,
                isAddingCommunicationTemplate: false,
            };
        case actionTypes.ADD_COMMUNICATION_TEMPLATE_REQUEST:
            return {
                ...state,
                isAddingCommunicationTemplate: true,
            };
        case actionTypes.ADD_COMMUNICATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                message: get(action.result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                allCommunicationTemplates: {},
                communicationTemplates: get(action.result, successMappings.COMMUNICATION_TEMPLATES),
                paginationCommunicationTemplates: getPaginationData(action),
                isAddingCommunicationTemplate: false,
            };
        case actionTypes.DELETE_COMMUNICATION_TEMPLATE_FAILURE:
            return {
                ...state,
                isDeletingCommunicationTemplate: false,
            };
        case actionTypes.DELETE_COMMUNICATION_TEMPLATE_REQUEST:
            return {
                ...state,
                isDeletingCommunicationTemplate: true,
            };
        case actionTypes.DELETE_COMMUNICATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                message: get(action.result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                allCommunicationTemplates: {},
                communicationTemplates: get(action.result, successMappings.COMMUNICATION_TEMPLATES),
                paginationCommunicationTemplates: getPaginationData(action),
                isDeletingCommunicationTemplate: false,
            };
        case actionTypes.GET_ALL_COMMUNICATION_TEMPLATES_FAILURE:
            return {
                ...state,
                allCommunicationTemplates: {},
                isAllCommunicationTemplatesLoading: false,
            };
        case actionTypes.GET_ALL_COMMUNICATION_TEMPLATES_REQUEST:
            return {
                ...state,
                allCommunicationTemplates: {},
                isAllCommunicationTemplatesLoading: true,
            };
        case actionTypes.GET_ALL_COMMUNICATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                allCommunicationTemplates: get(action.result, successMappings.COMMUNICATION_TEMPLATES),
                isAllCommunicationTemplatesLoading: false,
            };
        case actionTypes.GET_COMMUNICATION_LOG_HISTORY_DETAILS_FAILURE:
            return {
                ...state,
                isCommunicationLogHistoryDetailsLoading: false,
            };
        case actionTypes.GET_COMMUNICATION_LOG_HISTORY_DETAILS_REQUEST:
            return {
                ...state,
                isCommunicationLogHistoryDetailsLoading: true,
            };
        case actionTypes.GET_COMMUNICATION_LOG_HISTORY_DETAILS_SUCCESS:
            return {
                ...state,
                communicationLogHistoryDetails: get(action.result, successMappings.GET_COMMUNICATION_LOG_HISTORY),
                isCommunicationLogHistoryDetailsLoading: false,
            };
        case actionTypes.GET_COMMUNICATION_LOG_HISTORY_FAILURE:
            return {
                ...state,
                isCommunicationLogHistoryLoading: false,
            };
        case actionTypes.GET_COMMUNICATION_LOG_HISTORY_REQUEST:
            return {
                ...state,
                isCommunicationLogHistoryLoading: true,
            };
        case actionTypes.GET_COMMUNICATION_LOG_HISTORY_SUCCESS:
            return {
                ...state,
                communicationLogHistory: get(action.result, successMappings.GET_COMMUNICATION_LOG_HISTORY),
                paginationCommunicationLogHistory: getPaginationData(action),
                isCommunicationLogHistoryLoading: false,
            };
        case actionTypes.GET_COMMUNICATION_LOG_STATUSES_FAILURE:
            return {
                ...state,
                isCommunicationLogStatusesLoading: false,
            };
        case actionTypes.GET_COMMUNICATION_LOG_STATUSES_REQUEST:
            return {
                ...state,
                isCommunicationLogStatusesLoading: true,
            };
        case actionTypes.GET_COMMUNICATION_LOG_STATUSES_SUCCESS:
            return {
                ...state,
                communicationLogStatuses: get(action.result, successMappings.COMMUNICATION_LOG_STATUSES),
                isCommunicationLogStatusesLoading: false,
            };
        case actionTypes.GET_COMMUNICATION_TEMPLATE_DETAILS_FAILURE:
            return {
                ...state,
                isLoadingDetails: false,
            };
        case actionTypes.GET_COMMUNICATION_TEMPLATE_DETAILS_REQUEST:
            return {
                ...state,
                isLoadingDetails: true,
            };
        case actionTypes.GET_COMMUNICATION_TEMPLATE_DETAILS_SUCCESS:
            return {
                ...state,
                templateDetails: get(action.result, successMappings.COMMUNICATION_TEMPLATE),
                isLoadingDetails: false,
            };
        case actionTypes.GET_COMMUNICATION_TEMPLATE_PREVIEW_FAILURE:
            return {
                ...state,
                isLoadingPreview: false,
                preview: '<html><body>No preview available</body></html>',
            };
        case actionTypes.GET_COMMUNICATION_TEMPLATE_PREVIEW_REQUEST:
            return {
                ...state,
                isLoadingPreview: true,
            };
        case actionTypes.GET_COMMUNICATION_TEMPLATE_PREVIEW_SUCCESS:
            return {
                ...state,
                isLoadingPreview: false,
                preview: get(action.result, successMappings.DATA),
            };
        case actionTypes.GET_COMMUNICATION_TEMPLATES_FAILURE:
            return {
                ...state,
                paginationCommunicationTemplates: {},
                communicationTemplates: [],
                isLoading: false,
            };
        case actionTypes.GET_COMMUNICATION_TEMPLATES_REQUEST:
            return {
                ...state,
                paginationCommunicationTemplates: {},
                communicationTemplates: [],
                isLoading: true,
            };
        case actionTypes.GET_COMMUNICATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                message: get(action.result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                communicationTemplates: get(action.result, successMappings.COMMUNICATION_TEMPLATES),
                paginationCommunicationTemplates: getPaginationData(action),
                isLoading: false,
            };
        case actionTypes.GET_COMMUNICATION_TEMPLATE_TYPES_FAILURE:
            return {
                ...state,
                isTemplateTypesLoading: false,
            };
        case actionTypes.GET_COMMUNICATION_TEMPLATE_TYPES_REQUEST:
            return {
                ...state,
                isTemplateTypesLoading: true,
            };
        case actionTypes.GET_COMMUNICATION_TEMPLATE_TYPES_SUCCESS:
            return {
                ...state,
                templateTypes: action.templateTypes,
                isTemplateTypesLoading: false,
            };
        case actionTypes.GET_COMMUNICATION_TYPES_REQUEST:
            return {
                ...state,
                isTypesLoading: true,
                communicationTypes: [],
            };
        case actionTypes.GET_COMMUNICATION_TYPES_SUCCESS:
            return {
                ...state,
                isTypesLoading: false,
                communicationTypes: get(action.result, successMappings.COMMUNICATION_TYPES),
            };
        case actionTypes.GET_COMMUNICATION_TYPES_FAILURE:
            return {
                ...state,
                isTypesLoading: false,
                communicationTypes: [],
            };
        case actionTypes.RESET_ORGANISATION_RELATED_DATA:
            return {
                ...state,
                allCommunicationTemplates: {},
                communicationTemplates: [],
                isAllCommunicationTemplatesLoading: false,
                paginationCommunicationTemplates: {},
                templateDetails: {},
            };
        case actionTypes.UPDATE_COMMUNICATION_TEMPLATE_FAILURE:
            return {
                ...state,
                isUpdatingCommunicationTemplate: false,
            };
        case actionTypes.UPDATE_COMMUNICATION_TEMPLATE_REQUEST:
            return {
                ...state,
                isUpdatingCommunicationTemplate: true,
            };
        case actionTypes.UPDATE_COMMUNICATION_TEMPLATE_SUCCESS:
            return {
                ...state,
                message: get(action.result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                allCommunicationTemplates: {},
                communicationTemplates: get(action.result, successMappings.COMMUNICATION_TEMPLATES),
                paginationCommunicationTemplates: getPaginationData(action),
                isUpdatingCommunicationTemplate: false,
            };
        default:
            return state;
    }
}

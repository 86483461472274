import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ChargesTable({ additionalRows, columns, rows, totalAmount }) {
    const { t } = useTranslation();

    return (
        <table className="w-full table-auto">
            <thead>
                <tr className="text-gray-400 uppercase text-sm leading-normal border-b border-solid border-gray-200">
                    {columns.map(({ id, className, name }) => (
                        <th key={id} className={`py-3 px-6 ${className}`}>
                            {name}
                        </th>
                    ))}
                </tr>
            </thead>

            <tbody className="font-light">
                {rows.map(row => (
                    <tr key={row.number} className="border-b border-solid border-gray-200">
                        {columns.map(col => (
                            <td key={col.id} className={`py-4 px-6 ${col.className}`}>
                                {row[col.id]}
                            </td>
                        ))}
                    </tr>
                ))}

                {additionalRows}

                <tr className="font-semibold text-lg">
                    {Array.from({ length: columns.length - 2 }).map((_, index) => (
                        <td key={index} className="py-4 px-6" />
                    ))}
                    <td className="py-4 px-6 text-right">{t('sharedMessages.orders.details.label.total')}</td>
                    <td className="py-4 px-6 text-right font-mono">{totalAmount}</td>
                </tr>
            </tbody>
        </table>
    );
}

ChargesTable.propTypes = {
    additionalRows: PropTypes.node,
    columns: PropTypes.arrayOf(PropTypes.object),
    rows: PropTypes.arrayOf(PropTypes.object),
    totalAmount: PropTypes.string,
};

import PropTypes from 'prop-types';
import React from 'react';

/**
 * Content of updated order charge confirmation modal
 *
 * @param {object} props Props
 * @param {Function} props.formatCurrency Format currency function
 * @param {Function} props.t Translation function
 * @param {object} props.transactionCharges transaction charges received from the backend
 *
 * @since 2.8.0
 */
export default function OrderChargesUpdatedConfirmModalContent({
    errorMessage,
    formatCurrency,
    t,
    transactionCharges,
}) {
    const renderIfValueGreaterThanZero = (value, label, netEffectSymbol = '+') => {
        const parsedValue = parseFloat(value || 0);
        if (parsedValue && parsedValue > 0)
            return (
                <div>
                    <div className="flex justify-between">
                        <div>{label}</div>
                        <div className="right-align">{`${netEffectSymbol} ${formatCurrency(parsedValue)}`}</div>
                    </div>
                    <hr className="h-0" />
                </div>
            );
        return null;
    };

    return (
        <div>
            <div className="mb-3">{errorMessage}</div>
            <hr className="h-0" />
            <div className="mt-2">
                {renderIfValueGreaterThanZero(
                    transactionCharges.amount,
                    t('sharedMessages.orders.details.label.subtotal'),
                    ''
                )}
                {renderIfValueGreaterThanZero(
                    transactionCharges.coupon_discount,
                    t('sharedMessages.orders.details.label.coupon'),
                    '-'
                )}
                {renderIfValueGreaterThanZero(
                    transactionCharges.service_charge,
                    t('sharedMessages.orders.details.label.serviceCharge', {
                        charge: transactionCharges.order_charges.service_charge,
                    })
                )}
                {renderIfValueGreaterThanZero(
                    transactionCharges.total_transaction_charge_without_additional_charges,
                    t('sharedMessages.orders.details.label.transactionCharge', {
                        charge: transactionCharges.order_charges.total_transaction_charge_without_additional_charges,
                    })
                )}
                {renderIfValueGreaterThanZero(
                    transactionCharges.additional_high_value_transaction_charge,
                    t('sharedMessages.orders.details.label.highValueTransactionCharge', {
                        charge: transactionCharges.order_charges.additional_high_value_transaction_charge,
                    })
                )}
                {renderIfValueGreaterThanZero(
                    transactionCharges.additional_payment_method_brand_transaction_charge,
                    t('sharedMessages.orders.details.label.additionalTransactionCharge', {
                        charge: transactionCharges.order_charges.additional_payment_method_brand_transaction_charge,
                    })
                )}
                {renderIfValueGreaterThanZero(
                    transactionCharges.gst,
                    t('sharedMessages.orders.details.label.gst', {
                        charge: transactionCharges.order_charges.gst,
                    })
                )}
                <div className="flex justify-between text-lg font-semibold">
                    <div>{t('sharedMessages.orders.details.label.total')}</div>
                    <div className="right-align text-green-500 font-mono">
                        {formatCurrency(transactionCharges.total_amount)}
                    </div>
                </div>
            </div>
        </div>
    );
}

OrderChargesUpdatedConfirmModalContent.propTypes = {
    errorMessage: PropTypes.string,
    formatCurrency: PropTypes.func,
    t: PropTypes.func,
    transactionCharges: PropTypes.shape({
        additional_high_value_transaction_charge: PropTypes.number,
        additional_payment_method_brand_transaction_charge: PropTypes.number,
        amount: PropTypes.string,
        coupon_discount: PropTypes.string,
        gst: PropTypes.string,
        order_charges: PropTypes.shape({
            additional_high_value_transaction_charge: PropTypes.string,
            additional_payment_method_brand_transaction_charge: PropTypes.string,
            gst: PropTypes.string,
            service_charge: PropTypes.string,
            total_transaction_charge_without_additional_charges: PropTypes.string,
        }),
        service_charge: PropTypes.string,
        total_amount: PropTypes.string,
        total_transaction_charge_without_additional_charges: PropTypes.string,
    }),
};

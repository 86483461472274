/**
 * App Notification Reducer
 * Handles all the state changes actions in notification
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';

/**
 * App Notification Reducer Function
 * Handles the state changes
 *
 * @param   {object}    state   Current State
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function appNotificationReducer(state = {}, action = {}) {
    const { type, ...restParams } = action;
    switch (type) {
        case actionTypes.SHOW_APP_NOTIFICATION_MESSAGE:
            return {
                ...state,
                appNotification: {
                    ...restParams,
                },
            };
        case actionTypes.SHOW_NOTIFICATION_MODAL:
            return {
                ...state,
                isNotificationModalOpen: true,
                modalNotification: {
                    ...restParams,
                },
            };
        case actionTypes.NOTIFICATION_MODAL_CLOSED:
            return {
                ...state,
                isNotificationModalOpen: false,
            };
        default:
            return state;
    }
}

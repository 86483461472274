/**
 * useRedirectToAuthPortalRoute
 * Redirect to auth portal
 *
 * @version 2.2.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com>
 */

import { getAuthPortalUrl } from 'includes/utils';
import { useEffect } from 'react';

/**
 * Redirects to counterpart in auth portal
 *
 * @param {string} route path to redirect to
 */
export default function useRedirectToAuthPortalRoute(route = '') {
    useEffect(() => {
        window.open(`${getAuthPortalUrl()}${route}`, '_self');
    }, []);
}

/**
 * Debtor accounts listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import React, { useCallback, useMemo, useState } from 'react';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Breadcrumb } from 'antd';
import { getDebtorAccountsTableColumns } from 'includes/tableColumns/debtorAccounts';
import { TABLE_NO_DEBTOR_ACCOUNTS_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import DebtorAccountsBreadCrumbItems from 'components/BreadCrumbItems/DebtorAccounts';
import { getDebtorAccounts } from 'includes/redux/actions/customer/organisation';
import HasEditPermission from 'components/HasEditPermission';
import useOrganisationRequired from 'includes/hooks/useOrganisationRequired';

const DebtorAccountsList = () => {
    const { t } = useTranslation();

    // check if user has updated the business name
    useUpdateBusinessName();

    useOrganisationRequired();

    const organisationId = useOrganisationId();
    const accountId = useAccountId();
    const isAdmin = useIsAdminOrManager();
    const loadingDebtorAccounts = useSelector(state => state.organisation.loadingDebtorAccounts);
    const debtorAccounts = useSelector(state => state.organisation.debtorAccounts);
    const debtorAccountsPagination = useSelector(state => state.organisation.debtorAccountsPagination);
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    /**
     * Get the account details view link
     *
     * @param {string} accountId Account Id id
     *
     * @returns {string} View Link
     */
    const getAccountDetailsViewLink = accountId => `/admin/accounts/${accountId}`;

    /**
     * Get the contact details view link
     *
     * @param {string} contactId Contact id
     *
     * @returns {string} View Link
     */
    const getContactDetailsViewLink = contactId =>
        isAdmin
            ? `/admin/accounts/organisation/contact/${accountId}/${organisationId}/${contactId}`
            : `/contact/${contactId}`;

    /**
     * Get the contact manager details view link
     *
     * @param {string} contactId Contact id
     *
     * @returns {string} View Link
     */
    const getContactManagerDetailsViewLink = contactId =>
        isAdmin
            ? `/admin/accounts/organisation/contact-manager/${accountId}/${organisationId}/${contactId}`
            : `/contact-manager/${contactId}`;

    /**
     * Get the locale text
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @returns {string} Locale test
     */
    const getLocaleText = path => t(`messages.debtorAccounts.${path}`);

    /**
     * Get the organisation details view link
     *
     * @returns {string} View Link
     */
    const getOrganisationDetailsViewLink = () =>
        isAdmin ? `/admin/accounts/organisation/${accountId}/${organisationId}` : `/organisation/${organisationId}`;

    /**
     * Get the view wrapper
     *
     * @param {string} name Name
     * @param {string} id ID
     * @param {Function} getViewLinkFunction Function to be called to get view link
     * @param {boolean} renderViewLink Whether to render view link or not. Default true
     *
     * @returns View Wrapper
     */
    const getViewWrapper = (name, id, getViewLinkFunction, renderViewLink = true) => (
        <div className="business-name-avatar-wrapper">
            <div className="action-wrapper">
                {name}
                {renderViewLink ? (
                    <span className="crud-wrapper">
                        <HasEditPermission type="link" link={getViewLinkFunction(id)} className="crud-link">
                            {getLocaleText('crud.view')}
                        </HasEditPermission>
                    </span>
                ) : null}
            </div>
        </div>
    );

    /**
     * Format the debtor accounts data
     */
    const formatDebtorAccountsData = useCallback(
        () =>
            debtorAccounts.map(debtorAccount => {
                return {
                    ...debtorAccount,
                    account_name: getViewWrapper(
                        debtorAccount.account.name,
                        debtorAccount.account.id,
                        getAccountDetailsViewLink,
                        isAdmin
                    ),
                    account_email: debtorAccount.account.email,
                    contact: getViewWrapper(
                        debtorAccount.contact.name || debtorAccount.contact.email || debtorAccount.contact.external_id,
                        debtorAccount.contact.id,
                        debtorAccount.contact.is_manager ? getContactManagerDetailsViewLink : getContactDetailsViewLink
                    ),
                    organisation: getViewWrapper(debtorAccount.organisation.name, '', getOrganisationDetailsViewLink),
                };
            }),
        [debtorAccounts] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Memoized debtor accounts data
     */
    const memoizedDebtorAccountsData = useMemo(() => formatDebtorAccountsData(), [formatDebtorAccountsData]);

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
            <h2 className="page-title">{t(`titleAndMetas.debtorAccounts.title`)}</h2>
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <DebtorAccountsBreadCrumbItems />
                </Breadcrumb>
            </div>
            <div className="search-filter-export-wrapper contact-list-container full-wrapper box-wrapper white-box-wrapper">
                <ul className="selector-field show-elem invoices-wrapper">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>
                        <div className="invoices-search">
                            <SearchBlock
                                dataValues={[organisationId]}
                                dispatchMethod={true}
                                getDataMethod={organisationId ? getDebtorAccounts : null}
                                isSearching={isSearching}
                                placeholder={getLocaleText('search.placeholder')}
                                setIsSearching={setIsSearching}
                                setSearchParam={setSearchParam}
                            />
                        </div>
                    </li>
                </ul>
                <Table
                    className="table-1200"
                    columns={getDebtorAccountsTableColumns}
                    dataSource={memoizedDebtorAccountsData}
                    dataValues={[organisationId]}
                    dispatchMethod={true}
                    getDataMethod={organisationId ? getDebtorAccounts : null}
                    isSearching={isSearching}
                    loading={loadingDebtorAccounts}
                    localeMessage={TABLE_NO_DEBTOR_ACCOUNTS_FOUND_MESSAGE}
                    mobileClassName="table-1200"
                    paginationData={debtorAccountsPagination}
                    rowClassName="pointer"
                    rowKey="id"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    size="middle"
                />
            </div>
        </div>
    );
};

export default DebtorAccountsList;

/**
 * Page Not Found Component
 * Handles the page not found view. This component gets rendered whenever a router matching files for the requested url
 * This component is a shared component which can be used in different modules
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modules
import { React, Link } from '../../../includes/exports/react';
import Button from '../Button';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import { USER_ROLE_ADMIN, USER_ROLE_ADMIN_MANAGER } from '../../../includes/constants';
import { useCurrentRoleSlug } from '../../../includes/utils/hooks';

/**
 *
 * Render the page not found view
 *
 * @returns    {JSX}     Rendered content
 */
const PageNotFound = props => {
    const { t } = props;
    const currentRoleSlug = useCurrentRoleSlug();

    /**
     * Get dashboard link
     *
     * @returns {string} Dashboard link
     */
    const getHomeLink = () => {
        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return `/admin/dashboard`;
            default:
                return `/dashboard`;
        }
    };

    return (
        <div className="error-page">
            <span className="error-bg">
                <img alt="error" src={require('../../../assets/images/error-bg.png')} />
            </span>
            <span className="error-msg-wrapper right-align">
                <p className="error-msg-title">Page Not Found :(</p>
                <p className="error-msg">Error Code: 404</p>
                <Link to={getHomeLink()} className="text-white font-weight-medium">
                    <Button className="green-bg-button" big filled>
                        {t('sharedMessages.pageNotFound.backToHomeLinkText')}
                    </Button>
                </Link>
            </span>
        </div>
    );
};

// prop types
PageNotFound.propTypes = {
    t: PropTypes.func,
};

export default withTranslation()(PageNotFound);

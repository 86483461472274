import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import Button from 'components/Button';
import { useInternalStripeContext } from 'includes/contexts/InternalStripeContext';
import { SAVE_CARD_FOR_LATER_USE } from 'includes/constants/keys/request';
import { Checkbox } from 'antd';
import useInternalStripeDetails from 'includes/hooks/useInternalStripeDetails';

/**
 * This callback type is called `requestCallback` and is displayed as a global symbol.
 *
 * @callback handleSubmitCallback
 * @param {object} e form submit event
 * @param {object} stripe Stripe object returned by `useStripe` hook of Stripe. Refer https://stripe.com/docs/stripe-js/react#usestripe-hook
 * @param {object} elements Elements object returned by `useElements` hook of Stripe. Refer https://stripe.com/docs/stripe-js/react#useelements-hook
 */
/**
 * Component to render Stripe's form
 *
 * @param {object} props
 * @param {handleSubmitCallback} props.handleSubmit
 * @returns
 */
const StripeForm = forwardRef(function StripeForm(
    { buttonLabel, externalSubmitButton, handleSubmit, isLoading, mode },
    stripeRef
) {
    const [saveCardForLaterUse, setSaveCardForLaterUse] = useState(false);

    const stripe = useStripe();

    const elements = useElements();

    const { setElements, setStripe } = useInternalStripeContext();

    const { stripeDetails } = useInternalStripeDetails();

    const [isElementsReady, setIsElementsReady] = useState(false);

    useEffect(() => {
        if (elements && stripe && setElements && setStripe) {
            setElements(elements);
            setStripe(stripe);
        }
    }, [elements, stripe]);

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                handleSubmit(
                    e,
                    stripe,
                    elements,
                    saveCardForLaterUse ? { [SAVE_CARD_FOR_LATER_USE]: true } : undefined
                );
            }}
        >
            <PaymentElement onReady={() => setIsElementsReady(true)} />
            {isElementsReady && stripeDetails?.allow_saving_card && mode !== 'setup' ? (
                <div className="mt-2">
                    <Checkbox
                        checked={saveCardForLaterUse}
                        name={SAVE_CARD_FOR_LATER_USE}
                        onChange={e => setSaveCardForLaterUse(e.target.checked)}
                    >
                        Save card for future use
                    </Checkbox>
                </div>
            ) : null}
            <div className={externalSubmitButton ? 'hidden' : ''}>
                {isElementsReady ? (
                    <Button
                        forwardedRef={stripeRef}
                        className={`mt-3 ${externalSubmitButton ? 'hidden' : ''}`}
                        loading={isLoading || !isElementsReady}
                        htmlType="submit"
                        disabled={!isElementsReady}
                    >
                        {buttonLabel ?? 'Submit'}
                    </Button>
                ) : null}
            </div>
        </form>
    );
});

StripeForm.propTypes = {
    buttonLabel: PropTypes.string,
    externalSubmitButton: PropTypes.bool,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    mode: PropTypes.string,
};

export default StripeForm;

/**
 * Invoice currency breakdown graph Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import React from 'react';
import { PropTypes } from 'prop-types';
import { get, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

import Loader from '../../Loader';
import { invoiceCurrencyBreakdownGraphOptions } from '../../../../includes/charts/invoiceCurrencyBreakdown';
import { ORGANISATION_IS_FETCHING_DATA } from '../../../../includes/constants/keys/response';
import { DASHBOARD } from 'includes/constants/permissions';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';

/**
 * Payment overview graph Component
 */
const InvoiceCurrencyBreakdown = props => {
    const getNumberFormattedAsCurrency = useCurrencyFormatter();
    /**
     * Get the invoice currency breakdown graph options
     * Adds the data points
     **/
    const getInvoiceCurrencyBreakdownGraphOptions = () => {
        const invoiceCurrencyBreakdownData = get(props.invoiceCurrencyBreakdown, 'total_invoices_amount_by_currency');

        if (!isEmpty(invoiceCurrencyBreakdownData)) {
            invoiceCurrencyBreakdownGraphOptions.data[0].dataPoints = Object.keys(invoiceCurrencyBreakdownData).map(
                function(key) {
                    return {
                        name: invoiceCurrencyBreakdownData[key].name,
                        y: parseFloat(invoiceCurrencyBreakdownData[key].total_amount_base),
                        totalAmount: parseFloat(invoiceCurrencyBreakdownData[key].total_amount),
                    };
                }
            );
            invoiceCurrencyBreakdownGraphOptions.toolTip.content = function(arg) {
                const data = arg.entries[0];
                return `<b>${data?.dataPoint?.name}:</b> ${getNumberFormattedAsCurrency(
                    data?.dataPoint?.totalAmount
                )} (${getNumberFormattedAsCurrency(data?.dataPoint?.y)})`;
            };
        }

        return invoiceCurrencyBreakdownGraphOptions;
    };

    /**
     * Check organisation data fetching status
     *
     * @returns {boolean}
     */
    const isFetchingData = () => !!get(props.selectedOrganisation, ORGANISATION_IS_FETCHING_DATA);

    const renderGraphView = () => {
        if (props.isGraphDataLoading) {
            return <Loader />;
        }
        if (isEmpty(props.invoiceCurrencyBreakdown)) {
            return (
                <props.infoMessage
                    message={
                        isFetchingData()
                            ? props.t('customerMessages.dashboard.fetchingDataMessage')
                            : props.t('appCommonMessages.noDataToShow')
                    }
                    showLoader={isFetchingData()}
                />
            );
        }
        return <props.canvasJSChart options={getInvoiceCurrencyBreakdownGraphOptions()} />;
    };

    return (
        <div className="box-wrapper white-box-wrapper half-wrapper">
            <div className="box-title-wrapper">
                <h3 className="page-title">Invoice Currency Breakdown</h3>
            </div>
            <PermissionGuardLayout requiredPermission={DASHBOARD.GRAPH.INVOICE_CURRENCY_BREAKDOWN} layout="section">
                {renderGraphView()}
            </PermissionGuardLayout>
        </div>
    );
};

/**
 * Proptypes
 */
InvoiceCurrencyBreakdown.propTypes = {
    canvasJsChart: PropTypes.func,
    collectedText: PropTypes.string,
    customClass: PropTypes.string,
    isGraphDataLoading: PropTypes.bool,
    infoMessage: PropTypes.func,
    invoiceCurrencyBreakdown: PropTypes.object,
    selectedOrganisation: PropTypes.object,
    t: PropTypes.func,
};

export default withTranslation()(InvoiceCurrencyBreakdown);

/**
 * Audit logs breadcrumb items
 *
 * @version 2.2.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import React from 'react';
import AccountsBreadCrumbItems from '../Accounts';
import OrganisationsBreadCrumbItems from '../Organisations';
import PropTypes from 'prop-types';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';

const AuditLogsBreadCrumbItems = () => {
    const accountId = useAccountId();
    const isAdminOrManager = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    const { t } = useTranslation();

    /**
     * Render the component
     */
    return (
        <>
            {isAdminOrManager ? (
                <>
                    {accountId ? <AccountsBreadCrumbItems showListBreadCrumb /> : null}
                    {organisationId ? <OrganisationsBreadCrumbItems showListBreadCrumb /> : null}
                </>
            ) : null}
            {accountId ? <Breadcrumb.Item>{t('messages.breadCrumbs.auditLogs.list')}</Breadcrumb.Item> : null}
        </>
    );
};

AuditLogsBreadCrumbItems.propTypes = {
    action: PropTypes.string,
};

export default AuditLogsBreadCrumbItems;

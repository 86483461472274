import {
    GOCARDLESS_FORM_MODE,
    GoCardlessMandateDropinHookWrapper,
    GoCardlessMandateForm,
} from 'components/customer/PaymentInformation/components/PaymentMethods/components/AddMandateUsingGoCardless';
import { GO_CARDLESS_MANDATE_ADD_FORM, PAYMENT_GATEWAY, PAYMENT_METHOD_TYPE } from 'includes/constants';
import {
    COUNTRY_ID,
    MANDATE_NAME,
    PAYMENT_GATEWAY_ID,
    PAYMENT_METHOD_TYPE_ID,
    SCHEME_ID,
} from 'includes/constants/keys/request';
import { ORDER_GET_DETAILS } from 'includes/constants/mappings/success';
import useInternalGoCardlessDetails from 'includes/hooks/useInternalGoCardlessDetails';
import usePaymentMethodTypes from 'includes/hooks/usePaymentMethodTypes';
import { setOrderDetails } from 'includes/slices/orders';
import { redirect } from 'includes/utils';
import { showApiErrors, showApiSuccess } from 'includes/utils/api';
import { find, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formValueSelector, reset } from 'redux-form';

const fieldValueSelector = formValueSelector(GO_CARDLESS_MANDATE_ADD_FORM);

// const formValuesSelector = getFormValues(GO_CARDLESS_MANDATE_ADD_FORM);

/**
 * Add mandate GoCardless wrapper
 *
 * Component responsible for fetching `billingRequestFlowID` and rendering the `AddMandateUsingGoCardless` conditionally to initialize the drop-in UI
 *
 * @since 2.8.0
 */
export default function GoCardlessAddMandateAndPay({
    createOrder,
    handleError,
    handlePaymentThroughSavedMethod,
    isLoading,
    onClose,
    orderTypeId,
    setIsLoading,
    setSelectedMethod,
}) {
    // const [additionalParams, setAdditionalParams] = useState();
    const orderApiParamsRef = useRef();

    const setAdditionalParams = params => {
        orderApiParamsRef.current = params;
    };

    const { data: paymentMethodTypes } = usePaymentMethodTypes();

    const mandateMethodType = useMemo(() => {
        if (!paymentMethodTypes || paymentMethodTypes.length === 0) return;

        return find(paymentMethodTypes, { slug: PAYMENT_METHOD_TYPE.MANDATE });
    }, [paymentMethodTypes]);

    const dispatch = useDispatch();

    const [billingRequestFlowID, setBillingRequestFlowID] = useState();

    const formValues = useSelector(state => fieldValueSelector(state, COUNTRY_ID, MANDATE_NAME, SCHEME_ID));

    const { goCardlessDetails } = useInternalGoCardlessDetails();

    const handleSubmit = async additionalParams => {
        if (!goCardlessDetails) return;

        setIsLoading(true);

        setAdditionalParams(additionalParams);

        // if token is present then there is no need to invoke gocardless UI again
        if (additionalParams?.token) {
            onGoCardlessSuccess(additionalParams.token);
            return;
        }

        let res = null;

        try {
            res = await createOrder(handleSubmit, null, {
                method_type_id: mandateMethodType.id,
                payment_gateway_id: goCardlessDetails.id,
                ...formValues,
                ...(additionalParams ? additionalParams : {}),
            });
        } catch (errors) {
            dispatch(
                showApiErrors(errors, null, 'PAYMENT_METHODS', '', [
                    COUNTRY_ID,
                    MANDATE_NAME,
                    PAYMENT_GATEWAY_ID,
                    PAYMENT_GATEWAY,
                    PAYMENT_METHOD_TYPE_ID,
                    SCHEME_ID,
                ])
            );
            setIsLoading(false);
            return;
        }

        if (res.error) return;

        const clientSecret = res?.response?.data?.data?.order_details?.next_action;

        setBillingRequestFlowID(clientSecret);
    };

    /**
     * `onSuccess` handler for GoCardless drop-in
     *
     * @param {*} billingRequest Billing request details from GoCardless
     */
    const onGoCardlessSuccess = async billingRequest => {
        const { response, error: createOrderError } = await createOrder(
            additionalParams => handleSubmit(additionalParams),
            billingRequest.id,
            { ...orderApiParamsRef.current, ...formValues }
        );

        if (createOrderError) {
            return;
        }

        const orderDetails = get(response, ORDER_GET_DETAILS, {});

        const {
            id: orderId,
            error_message: createOrderErrorMessage,
            payment_error: paymentErrorMessage,
        } = orderDetails;

        dispatch(setOrderDetails(get(response, ORDER_GET_DETAILS, {})));

        // if an error occurred during create order, set error and exit
        if (createOrderErrorMessage || paymentErrorMessage) {
            handleError(createOrderErrorMessage ? createOrderErrorMessage : paymentErrorMessage, true);
            redirect(`/orders/order/${orderTypeId}/${orderId}`);
            return;
        }
        redirect(`/orders/order/${orderTypeId}/${orderId}`);

        dispatch(showApiSuccess(response));
    };

    /**
     * `onExit` handler for GoCardless drop-in
     */
    const onGoCardlessExit = () => {
        dispatch(reset(GO_CARDLESS_MANDATE_ADD_FORM));
        setIsLoading(false);
        onClose();
    };

    return (
        <div className="flex flex-col text-left gap-3">
            <GoCardlessMandateForm
                buttonLabel={goCardlessDetails.pay_button_text}
                handlePaymentThroughSavedMethod={handlePaymentThroughSavedMethod}
                isLoading={isLoading}
                mode={GOCARDLESS_FORM_MODE.PAYMENT}
                onSubmit={() => handleSubmit()}
                setSelectedMethod={setSelectedMethod}
            />
            {billingRequestFlowID ? (
                <GoCardlessMandateDropinHookWrapper
                    billingRequestFlowID={billingRequestFlowID}
                    environment={goCardlessDetails.environment}
                    onSuccess={onGoCardlessSuccess}
                    onExit={onGoCardlessExit}
                />
            ) : null}
        </div>
    );
}

GoCardlessAddMandateAndPay.propTypes = {
    createOrder: PropTypes.func,
    handleError: PropTypes.func,
    handlePaymentThroughSavedMethod: PropTypes.func,
    isLoading: PropTypes.bool,
    onClose: PropTypes.func,
    orderTypeId: PropTypes.string,
    setIsLoading: PropTypes.func,
    setSelectedMethod: PropTypes.func,
};

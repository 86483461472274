/**
 * useChartOfAccounts
 * Fetch chart of accounts and caches it
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import ChartOfAccountsApi from 'includes/services/chartOfAccounts';
import useCachedData from './useCachedData';
import useOrganisationId from './useOrganisationId';
import {
    CACHE_CHART_OF_ACCOUNTS_KEY,
    CACHE_CHART_OF_ACCOUNTS_PURCHASE_KEY,
    CACHE_CHART_OF_ACCOUNTS_SALES_KEY,
    CACHE_PAYMENT_CHART_OF_ACCOUNTS_KEY,
} from 'includes/constants';
import { CHART_OF_ACCOUNTS } from 'includes/constants/mappings/success';
import { get } from 'lodash';

/**
 * Fetches chart of accounts if cached else call the API and caches the result
 *
 * @param {boolean} disableApiCall should disable API call
 * @param {number} transactionType Transaction type. Default 0
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useChartOfAccounts = (disableApiCall = false, transactionType = 0) => {
    const organisationId = useOrganisationId();
    let apiParam = { page_size: 0 };
    let cacheKey = CACHE_CHART_OF_ACCOUNTS_KEY;
    if (transactionType === 1) {
        cacheKey = CACHE_PAYMENT_CHART_OF_ACCOUNTS_KEY;
        apiParam['transaction_type'] = 1;
    } else if (transactionType === 2) {
        cacheKey = CACHE_CHART_OF_ACCOUNTS_SALES_KEY;
        apiParam['transaction_type'] = 2;
    } else if (transactionType === 3) {
        cacheKey = CACHE_CHART_OF_ACCOUNTS_PURCHASE_KEY;
        apiParam['transaction_type'] = 3;
    }
    return useCachedData(cacheKey, () => ChartOfAccountsApi.getChartOfAccounts(apiParam, organisationId), {
        selector: res => get(res, CHART_OF_ACCOUNTS),
        disableApiCall,
        dependsOnOrg: true,
        additionalResetKeys: [
            CACHE_CHART_OF_ACCOUNTS_KEY,
            CACHE_PAYMENT_CHART_OF_ACCOUNTS_KEY,
            CACHE_CHART_OF_ACCOUNTS_SALES_KEY,
            CACHE_CHART_OF_ACCOUNTS_PURCHASE_KEY,
        ],
    });
};

export default useChartOfAccounts;

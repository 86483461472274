/**
 * Event API class.
 * Handles all api requests related to api
 *
 * @version 2.2.0
 */

import BaseApiHandler from './base/api';

class EventsApi extends BaseApiHandler {
    /**
     * Add events
     *
     * @param {object} payload Payload
     * @param {str} organisationId Organisation Id. Default ''
     *
     * @returns {object} API Response
     * */
    addEvents = (payload, organisationId = '') => this.axios.post(this._getApiUrl(organisationId), payload);

    /**
     * Delete events
     *
     *  @param {Array} eventIds Event Ids
     * @param {str} organisationId Organisation Id. Default ''
     *
     * @returns {object} API Response
     * */
    deleteEvents = (eventIds, organisationId = '') =>
        this.axios.delete(this._getApiUrl(organisationId), { params: { event_ids: eventIds } });

    /**
     * Get the events
     *
     * @param {str} organisationId Organisation Id. Default ''
     * @param {object} searchParams Search Params. Default {}
     *
     * @returns {object} API Response
     * */
    getEvents = (organisationId = '', searchParams = {}) =>
        this.axios.get(this._getApiUrl(organisationId), { params: searchParams });

    /**
     * Update events
     *
     * @param {object} payload Payload
     * @param {str} organisationId Organisation Id. Default ''
     *
     * @returns {object} API Response
     * */
    updateEvents = (payload, organisationId = '') => this.axios.patch(this._getApiUrl(organisationId), payload);

    /**
     * Get the events API url
     *
     * @param {str} organisationId Organisation Id. Default ''
     *
     * @returns {str} Event API URL
     * */

    _getApiUrl = (organisationId = '') =>
        this.getApiUrl(organisationId ? 'EVENTS_ORGANISATION' : 'EVENTS', { organisation_id: organisationId });
}

export default new EventsApi();

/**
 * Connect Reducer
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';
import * as errorMappings from '../../../../constants/mappings/errors';
import * as errorMessages from '../../../../constants/messages/errors';
import { get } from 'lodash';

/**
 * Connect Reducer Function
 * Handles the state changes
 *
 * @param   {object}    state   Current State
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function connectReducer(state = {}, action = {}) {
    switch (action.type) {
        case actionTypes.CLEAR_CONNECT_STATE:
            return {
                ...state,
                connectToAccountingSoftwareLoading: false,
                accountingSoftwareErrorMessage: '',
                loading: false,
                organisationReauthorizationSuccess: false,
                verifyAccountingSoftwareAuthorizationLoading: false,
            };
        case actionTypes.GET_ACCOUNTING_SOFTWARE_AUTH_LINK_FAILURE:
            return {
                ...state,
                loading: false,
                connectToAccountingSoftwareLoading: false,
                accountingSoftwareErrorMessage:
                    get(action, errorMappings.GET_SOFTWARE_AUTHORIZATION_URL_FAILED) ||
                    get(action, errorMappings.GET_SOFTWARE_AUTHORIZATION_URL_FAILED) ||
                    errorMessages.GET_SOFTWARE_AUTHORIZATION_URL_DEFAULT_ERROR,
            };
        case actionTypes.GET_ACCOUNTING_SOFTWARE_AUTH_LINK_REQUEST:
            return {
                ...state,
                connectToAccountingSoftwareLoading: true,
                accountingSoftwareErrorMessage: '',
                loading: true,
                organisationReauthorizationSuccess: false,
            };
        case actionTypes.GET_ACCOUNTING_SOFTWARE_AUTH_LINK_SUCCESS:
            return {
                ...state,
                accountingSoftwareErrorMessage: '',
                loading: true,
            };
        case actionTypes.VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_FAILURE:
            return {
                ...state,
                loading: false,
                verifyAccountingSoftwareAuthorizationLoading: false,
                accountingSoftwareErrorMessage:
                    get(action, errorMappings.VERIFY_SOFTWARE_AUTHORIZATION) ||
                    get(action, errorMappings.VERIFY_SOFTWARE_AUTHORIZATION) ||
                    errorMessages.VERIFY_SOFTWARE_AUTHORIZATION_DEFAULT_ERROR,
            };
        case actionTypes.VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_REQUEST:
            return {
                ...state,
                loading: true,
                verifyAccountingSoftwareAuthorizationLoading: true,
                organisationReauthorizationSuccess: false,
            };
        case actionTypes.VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_SUCCESS:
            return {
                ...state,
                loading: false,
                verifyAccountingSoftwareAuthorizationLoading: false,
            };
        case actionTypes.VERIFY_ORGANISATION_ACCOUNTING_SOFTWARE_AUTHORIZATION_SUCCESS:
            return {
                ...state,
                loading: false,
                organisationReauthorizationSuccess: true,
                accountingSoftwareErrorMessage: '',
            };
        default:
            return state;
    }
}

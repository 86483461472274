import { Breadcrumb } from 'antd';
import PageTitle from 'components/PageTitle';
import PaymentServiceDetailsSection from 'components/PaymentServiceDetailsSection';
import { ORDER_TYPE } from 'includes/constants';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { getOrganisationPaymentGatewayDetails } from 'includes/slices/paymentGateways';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

/**
 * Payment service details
 *
 * Used as customer route
 *
 * @since 2.8.0
 */
export default function PaymentServiceDetails() {
    const { paymentServiceId } = useParams();

    const organisationId = useOrganisationId();

    const dispatch = useDispatch();

    const organisationPaymentGatewayDetails = useSelector(
        state => state.paymentGateways.organisationPaymentGatewayDetails
    );

    const loadingOrganisationPaymentGatewayDetails = useSelector(
        state => state.paymentGateways.loadingOrganisationPaymentGatewayDetails
    );

    useEffect(() => {
        dispatch(getOrganisationPaymentGatewayDetails(paymentServiceId, organisationId));
    }, [organisationId, paymentServiceId]);

    return (
        <div className="container mx-auto">
            <div className="tab-arrow-link breadcumb-spec">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/payment-services">Payment services</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Payment service details</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="my-4">
                <PageTitle>Payment service details</PageTitle>
            </div>
            <PaymentServiceDetailsSection
                orderTypeSlug={ORDER_TYPE.INVOICE}
                organisationId={organisationId}
                paymentServiceId={paymentServiceId}
                paymentServiceDetails={organisationPaymentGatewayDetails}
                isLoading={loadingOrganisationPaymentGatewayDetails}
            />
        </div>
    );
}

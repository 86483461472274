import PageTitle from 'components/PageTitle';
import WhiteBoxWrapper from 'components/WhiteBoxWrapper';
import { INVOICE_STATUSES_COLUMNS } from 'includes/constants/columns';
import useInvoiceStatuses from 'includes/hooks/useInvoiceStatuses';
import React, { useMemo } from 'react';
import Table from 'components/shared/ScrollableTable';
import InvoiceStatusTag from 'components/InvoiceStatusTag';

/**
 * Invoice statuses page
 *
 * @since 2.8.0
 */
export default function InvoiceStatuses() {
    const { data: invoiceStatuses, isLoading } = useInvoiceStatuses();

    const formattedTableData = useMemo(() => {
        if (invoiceStatuses.length === 0) return [];

        return invoiceStatuses.map(({ id, ...rest }) => ({
            ...rest,
            status: <InvoiceStatusTag id={id} />,
        }));
    }, [invoiceStatuses]);

    return (
        <section>
            <div className="my-8">
                <PageTitle>Invoice Statuses</PageTitle>
            </div>

            <WhiteBoxWrapper>
                <div className="flex ">
                    <Table
                        columns={INVOICE_STATUSES_COLUMNS}
                        dataSource={formattedTableData}
                        loading={isLoading}
                        localeMessage={'No invoice statuses found'}
                        rowClassName="pointer"
                        rowKey="id"
                        size="middle"
                        searchParam=""
                    />
                </div>
            </WhiteBoxWrapper>
        </section>
    );
}

/**
 * Cash collected graph Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import React from 'react';
import { PropTypes } from 'prop-types';
import { DatePicker } from 'antd';
import { get, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

import Loader from '../../../shared/Loader';
import { cashCollectedGraphOptions } from '../../../../includes/charts/cashCollected';
import { ORGANISATION_IS_FETCHING_DATA, MIN, MAX } from '../../../../includes/constants/keys/response';
import { DASHBOARD } from 'includes/constants/permissions';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import useCurrencySymbol from 'includes/hooks/useCurrencySymbol';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
const { RangePicker } = DatePicker;

/**
 * Cash collected graph Component
 */
const CashCollected = props => {
    const getCurrencySymbol = useCurrencySymbol();
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    /**
     * Get the cash collected disabled dates to prevent the user from picking the dates from range picker
     **/
    const getCashCollectedDisabledDates = current => {
        return (
            props.countDataAvailableMinDate &&
            current &&
            (current <= props.countDataAvailableMinDate * 1000 || current > new Date())
        );
    };

    /**
     * Get the cash collected graph options
     * Adds the data points
     **/
    const getCashCollectedGraphOptions = () => {
        const currencySymbol = getCurrencySymbol();
        if (!isEmpty(props.cashCollectedPerDay)) {
            if (!isEmpty(props.cashCollectedPerDayViewPortMaxMinDates)) {
                let maxDate = get(props.cashCollectedPerDayViewPortMaxMinDates, MAX);
                let minDate = get(props.cashCollectedPerDayViewPortMaxMinDates, MIN);
                if (maxDate) {
                    cashCollectedGraphOptions.axisX.viewportMaximum = maxDate;
                }
                if (minDate) {
                    cashCollectedGraphOptions.axisX.viewportMinimum = minDate;
                }
            }
            cashCollectedGraphOptions.title.text = `Average payment days: ${props.averagePaymentDays} ${
                props.averagePaymentDays > 1 ? 'days' : 'day'
            }`;
            cashCollectedGraphOptions.data[0].dataPoints = Object.keys(props.cashCollectedPerDay).map(function(key) {
                return {
                    x: parseInt(key),
                    y: parseFloat(props.cashCollectedPerDay[key].amount),
                    accounts: parseInt(props.cashCollectedPerDay[key].count),
                    amountShort: props.cashCollectedPerDay[key].amount_short,
                };
            });
            cashCollectedGraphOptions.toolTip.content = `${currencySymbol}{amountShort} from {accounts} accounts on {x}`;
        }
        return cashCollectedGraphOptions;
    };

    /**
     * Check organisation data fetching status
     *
     * @returns {boolean}
     */
    const isFetchingData = () => !!get(props.selectedOrganisation, ORGANISATION_IS_FETCHING_DATA);

    /**
     * Load the cash collected graph
     * Updates the data points
     **/
    const loadCashCollectedGraph = (date, dateList) => {
        props.getCashCollectedData(props.selectedOrganisationId, dateList);
    };

    const renderGraphView = () => {
        if (props.isGraphDataLoading || props.isCashCollectedDataLoading) {
            return <Loader />;
        }
        if (isEmpty(props.cashCollectedPerDay)) {
            return (
                <props.infoMessage
                    message={
                        isFetchingData()
                            ? props.t('customerMessages.dashboard.fetchingDataMessage')
                            : props.t('appCommonMessages.noDataToShow')
                    }
                    showLoader={isFetchingData()}
                />
            );
        }
        return (
            <>
                <props.canvasJSChart options={getCashCollectedGraphOptions()} />
                <div className="alert-info">
                    Total Cash Collected:{' '}
                    <span
                        title={`${getNumberFormattedAsCurrency(get(props, 'cashCollectedTotal.amount', 0))} from ${get(
                            props,
                            'cashCollectedTotal.count',
                            0
                        )} accounts`}
                    >
                        {`${getNumberFormattedAsCurrency(get(props, 'cashCollectedTotal.amount_short', 0))} from ${get(
                            props,
                            'cashCollectedTotal.count',
                            0
                        )} accounts`}
                    </span>
                </div>
            </>
        );
    };

    return (
        <div className="box-wrapper white-box-wrapper half-wrapper total-cash-wrapper">
            <div className="box-title-wrapper">
                <h3 className="page-title">Total Cash Collected</h3>
                {!props.isGraphDataLoading && !isFetchingData() ? (
                    <div className="right-align">
                        <h5>Date</h5>
                        <RangePicker
                            onChange={loadCashCollectedGraph}
                            disabledDate={getCashCollectedDisabledDates}
                            format="DD-MM-YYYY"
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
            <PermissionGuardLayout requiredPermission={DASHBOARD.GRAPH.TOTAL_CASH_COLLECTED} layout="section">
                {renderGraphView()}
            </PermissionGuardLayout>
        </div>
    );
};

/**
 * Proptypes
 */
CashCollected.propTypes = {
    averagePaymentDays: PropTypes.number,
    canvasJsChart: PropTypes.func,
    isGraphDataLoading: PropTypes.bool,
    infoMessage: PropTypes.func,
    cashCollectedPerDay: PropTypes.object,
    cashCollectedPerDayViewPortMaxMinDates: PropTypes.object,
    countDataAvailableMinDate: PropTypes.number,
    getCashCollectedData: PropTypes.func,
    isCashCollectedDataLoading: PropTypes.bool,
    selectedOrganisation: PropTypes.object,
    selectedOrganisationId: PropTypes.string,
    t: PropTypes.func,
};

export default withTranslation()(CashCollected);

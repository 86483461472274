/**
 * Invoice Payment Header Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import the required modules
import { React, connect, Link } from '../../../../includes/exports/react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { get } from 'lodash';
import * as responseKeys from '../../../../includes/constants/keys/response';
import { WORDPRESS_URL } from '../../../../includes/constants/config';

/**
 * App Header Component
 */
const InvoicePaymentHeader = props => {
    /**
     * Get the organisation logo
     * Checks if the organisation has a logo of the specified dimension, if not check for original organisation logo
     * if not get the current default specified
     */
    const getOrganisationLogo = () => {
        return (
            get(props.organisationDetails, responseKeys.ORGANISATION_LOGO_45X45) ||
            get(props.organisationDetails, responseKeys.ORGANISATION_LOGO_ORIGINAL) ||
            require('../../../../assets/images/organisation_avatar.svg')
        );
    };

    return (
        <section className="header-signin-register-wrapper common-header-wrapper">
            <h1>
                {props.customBranding ? (
                    <a href={WORDPRESS_URL}>
                        <img
                            className="payment-organisation-logo logo-45"
                            src={getOrganisationLogo()}
                            alt={props.organisationDetails.name}
                        />
                    </a>
                ) : (
                    <Link className="logo" to="/" />
                )}
            </h1>
        </section>
    );
};

/**
 * Proptypes
 */
InvoicePaymentHeader.propTypes = {
    customBranding: PropTypes.bool,
    organisationDetails: PropTypes.object,
    t: PropTypes.func,
};

export default connect(state => ({
    customBranding: state.user.customBranding,
    organisationDetails: state.organisation.organisationDetails,
}))(withTranslation()(InvoicePaymentHeader));

import PropTypes from 'prop-types';
import { Tag, Tooltip } from 'antd';
import { invoiceStatusTagColors } from 'includes/constants/tagColors';
import useInvoiceStatuses from 'includes/hooks/useInvoiceStatuses';
import { find, get } from 'lodash';
import React, { useMemo } from 'react';

/**
 * Renders a tag specifying the invoice status
 * Takes in ID of the status as prop
 */
export default function InvoiceStatusTag({ id }) {
    const { data: invoiceStatuses } = useInvoiceStatuses(true);

    const status = useMemo(() => {
        if (invoiceStatuses.length === 0) return null;

        return find(invoiceStatuses, { id });
    }, [invoiceStatuses, id]);

    return (
        <Tooltip title={status?.description}>
            <Tag style={{ color: '#FFFFFF' }} color={get(invoiceStatusTagColors, status?.slug)}>
                {status?.status}
            </Tag>
        </Tooltip>
    );
}

InvoiceStatusTag.propTypes = {
    id: PropTypes.string,
};

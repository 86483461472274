/**
 * Payment History Component.
 *
 * @version 1.0
 */

//import required modules
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get, find, isEmpty } from 'lodash';

import * as errorMessages from '../../../includes/constants/messages/errors';
import Button from '../../shared/Button';
import EditPaymentForm from '../../shared/forms/EditPaymentForm';
import InfoMessage from '../../shared/messages/InfoMessage';
import Table from 'components/shared/ScrollableTable';
import { multiStringReplace, redirect } from '../../../includes/utils';
import { getInvoicePaymentTableColumns } from '../../../includes/constants/columns';
import { Modal, DatePicker, Icon, Dropdown, Menu } from 'antd';
import {
    addInvoicePayment,
    deleteInvoicePayment,
    updateInvoicePayment,
    getAllInvoicePayments,
    getInvoicePayments,
} from 'includes/slices/invoice';
import './styles.scss';
import { ACCOUNTING_SOFTWARE_DEEP_LINKS } from '../../../includes/constants/deepLinks';
import ExportButton from '../../shared/Exports/button';
import { getOrganisationDetails } from '../../../includes/redux/actions/customer/organisation';
import { INVOICE_STATUS_VOIDED_SLUG, INVOICE_STATUS_WRITE_OFF_SLUG } from '../../../includes/constants';
import useToFEDateFormatter from 'includes/hooks/useToFEDateFormatter';
import useToBEDateFormatter from 'includes/hooks/useToBEDateFormatter';
import SearchBlock from 'components/shared/SearchBlock';
import useEnforceAddOrganisation from 'includes/hooks/useEnforceAddOrganisation';
import PermissionGuard from 'components/PermissionGuard';
import { INVOICE } from 'includes/constants/permissions';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import { CURRENCY_CODE } from 'includes/constants/keys/response';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import useOrganisationCurrency from 'includes/hooks/useOrganisationCurrency';
import { CURRENCY_ID, ID } from 'includes/constants/keys/request';
import useGetPermissionWrappedFunction from 'includes/hooks/useGetPermissionWrappedFunction';
import { showPermissionDeniedModal } from 'components/PermissionGuard/component';

const { RangePicker } = DatePicker;
const { confirm } = Modal;

/**
 * Invoice payment blocks component
 */
const InvoicePaymentsBlock = props => {
    const dispatch = useDispatch();
    const params = useParams();
    const { t } = useTranslation();

    const accountingSoftwareName = useSelector(state =>
        get(state.organisation, ['selectedOrganisation', 'accounting_software_data', 'name'])
    );
    const accountingSoftwareSlug = useSelector(state =>
        get(state.organisation, ['selectedOrganisation', 'accounting_software_data', 'slug'])
    );
    const currentOrganisation = useSelector(state => state.organisation.selectedOrganisation);
    const invoiceDetails = useSelector(state => state.invoices.invoiceDetails);
    const invoicePayments = useSelector(state => state.invoices.invoicePayments);
    const isAdmin = useSelector(state => state.account.isAdmin);
    const isDeletingInvoicePayment = useSelector(state => state.invoices.isDeletingInvoicePayment);
    // const isExportingInvoicePayments = useSelector(state => state.invoices.isExportingInvoicePayments);
    const isInvoicePaymentCreating = useSelector(state => state.invoices.isInvoicePaymentCreating);
    const isInvoicePaymentUpdating = useSelector(state => state.invoices.isInvoicePaymentUpdating);
    const isInvoicePaymentsLoading = useSelector(state => state.invoices.isInvoicePaymentsLoading);
    const _organisationId = useSelector(state => state.organisation.selectedOrganisationId);
    const paginationInvoicePayments = useSelector(state => state.invoices.paginationInvoicePayments);
    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);

    const organisationId = _organisationId || props.organisation || params.organisationId;
    const shouldDisableApisWithoutOrganisationId = !organisationId ? true : false;
    const accountId = params.accountId;
    const [paymentId, setPaymentId] = useState('');
    const [openModal, setOpenModal] = useState(false);
    // const [showActionColumn, setShowActionColumn] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [tableFilters, setTableFilters] = useState({});

    const convertDateStringToFEFormat = useToFEDateFormatter();
    const convertDateStringToFEFormatList = useToFEDateFormatter(true);
    const convertDateStringToBEFormat = useToBEDateFormatter();
    const getNumberFormattedAsCurrency = useCurrencyFormatter();
    const organisationCurrencyId = useOrganisationCurrency(ID);
    const invoiceCurrencyId = get(invoiceDetails, CURRENCY_ID);

    const { data: organisationCurrencies, isLoading: isOrganisationCurrenciesLoading } = useOrganisationCurrencies(
        shouldDisableApisWithoutOrganisationId
    );
    const invoiceCurrencyDetails = find(organisationCurrencies, { id: invoiceCurrencyId }, '');

    const { contactId, invoiceId } = params;

    // state to handle date value for filtering
    const [dateRange, setDateRange] = useState([null, null]);

    const closeModalPopup = () => setOpenModal(false);

    /**
     * Delete payment.
     *
     * @param {string} paymentId Id of the payment to be deleted.
     */
    const deletePayment = paymentId => {
        confirm({
            title: t('customerMessages.invoice.payment.confirm.deletePayment'),
            content: '',
            onOk: () => {
                dispatch(deleteInvoicePayment(organisationId, contactId, invoiceId, paymentId));
            },
        });
    };

    const handleSubmit = values => {
        const cleanedDetails = {
            ...values,
            paid_on: convertDateStringToBEFormat(values.paid_on),
        };
        paymentId
            ? dispatch(
                  updateInvoicePayment(organisationId, contactId, invoiceId, paymentId, cleanedDetails, () =>
                      setOpenModal(false)
                  )
              )
            : dispatch(
                  addInvoicePayment(organisationId, contactId, invoiceId, cleanedDetails, () => setOpenModal(false))
              );
    };

    /**
     * Fetch payment history.
     *
     * @param   {string}    key
     */
    const fetchTableData = useCallback(
        useEnforceAddOrganisation((data = {}) => {
            let searchParams = { ...data };

            dateRange[0] && (searchParams.start_date = dateRange[0].format('YYYY-MM-DD'));
            dateRange[1] && (searchParams.end_date = dateRange[1].format('YYYY-MM-DD'));

            if (organisationId) {
                invoiceId && contactId // check if its history corresponding to a specific invoice
                    ? dispatch(getInvoicePayments(organisationId, contactId, invoiceId, searchParams))
                    : dispatch(getAllInvoicePayments(organisationId, searchParams));
            }
        }),
        [dateRange] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
        get(selectedOrganisation, 'id') !== organisationId && dispatch(getOrganisationDetails(organisationId));
    }, [organisationId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchTableData();
    }, [dateRange]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Renders table.
     */
    const renderTable = () => (
        <Table
            rowKey="id"
            columns={getInvoicePaymentTableColumns(organisationCurrencyId === invoiceCurrencyId)}
            dataSource={memoizedReminderTableData}
            size="middle"
            loading={isInvoicePaymentsLoading || isOrganisationCurrenciesLoading}
            paginationData={paginationInvoicePayments}
            getDataMethod={fetchTableData}
            localeMessage={errorMessages.TABLE_NO_INVOICE_PAYMENTS_FOUND_MESSAGE}
            searchParam={searchParam}
            setIsSearching={setIsSearching}
            setTableFilters={setTableFilters}
            {...props}
        />
    );

    // const shouldShowActionColumn = useCallback(
    //     payment =>
    //         get(selectedOrganisation, 'external_short_code') &&
    //         get(ACCOUNTING_SOFTWARE_DEEP_LINKS, [accountingSoftwareSlug, 'payment']) &&
    //         get(payment, 'external_id'),
    //     [selectedOrganisation, accountingSoftwareSlug] // eslint-disable-line react-hooks/exhaustive-deps
    // );

    // useEffect(() => {
    //     for (let i = 0; i < invoicePayments.length; i++) {
    //         if (shouldShowActionColumn(invoicePayments[i]) && !shouldShowActionColumn) {
    //             setShowActionColumn(true);
    //             break;
    //         }
    //     }
    // }, [invoicePayments, shouldShowActionColumn]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get localised text.
     *
     * @param {string} path
     */
    const getLocaleText = path => {
        return t(`customerMessages.paymentsHistory.${path}`);
    };

    const handleOnClickPaymentView = useGetPermissionWrappedFunction(
        INVOICE.PAYMENT.VIEW,
        (invoiceId, invoiceContactId, paymentId) => {
            if (isAdmin) {
                redirect(
                    `/admin/accounts/organisation/invoices/payments/${accountId}/${organisationId}/${invoiceContactId}/${invoiceId}/${paymentId}`
                );
                return;
            }
            redirect(`/invoices/payments/${organisationId}/${invoiceContactId}/${invoiceId}/${paymentId}/`);
        },
        showPermissionDeniedModal
    );

    // format table data
    const getFormattedTableData = useCallback(
        () =>
            invoicePayments.map(p => {
                // const shouldRenderViewLink = shouldShowActionColumn(p);
                return {
                    ...p,
                    amount_paid_formatted: (
                        <span
                            title={getNumberFormattedAsCurrency(
                                p.amount_paid_formatted.amount,
                                false,
                                false,
                                get(find(organisationCurrencies, { id: get(p, 'currency_id') }), CURRENCY_CODE)
                            )}
                        >
                            {getNumberFormattedAsCurrency(
                                p.amount_paid_formatted.amount_short,
                                false,
                                false,
                                get(find(organisationCurrencies, { id: get(p, 'currency_id') }), CURRENCY_CODE)
                            )}
                        </span>
                    ),
                    paid_on: convertDateStringToFEFormatList(p.paid_on),
                    invoice_number: (
                        <div className="wrapped-content-text">
                            <span className="wrapped-content-text crud-wrapper">{p.invoice.number}</span>
                            {!invoiceId && !contactId && (
                                <span className="crud-wrapper">
                                    <Link
                                        className="crud-link"
                                        to={
                                            isAdmin
                                                ? `/admin/accounts/organisation/invoices/invoice/${accountId}/${organisationId}/${p.invoice.invoice_contact_id}/${p.invoice.id}`
                                                : `invoices/invoice/${p.invoice.invoice_contact_id}/${p.invoice.id}`
                                        }
                                    >
                                        {getLocaleText('action.viewInvoice')}
                                    </Link>
                                </span>
                            )}
                        </div>
                    ),
                    actions: (
                        <div className="cursor-pointer text-right">
                            <Dropdown
                                overlay={
                                    <Menu>
                                        <Menu.Item
                                            onClick={() =>
                                                handleOnClickPaymentView(
                                                    p.invoice.id,
                                                    p.invoice.invoice_contact_id,
                                                    p.id
                                                )
                                            }
                                            className="flex flex-row flex-start align-middle items-center"
                                        >
                                            <Icon type="eye" />
                                            <span>{t('customerMessages.paymentMethod.action.view')}</span>
                                        </Menu.Item>

                                        {get(invoiceDetails, 'is_manual') &&
                                        props.invoiceStatus &&
                                        ![INVOICE_STATUS_WRITE_OFF_SLUG, INVOICE_STATUS_VOIDED_SLUG].includes(
                                            props.invoiceStatus
                                        ) ? (
                                            <Menu.Item
                                                onClick={() => {
                                                    setOpenModal(true);
                                                    setPaymentId(p.id);
                                                }}
                                                className="flex flex-row flex-start align-middle"
                                            >
                                                <Icon type="edit" />
                                                <span>{t('customerMessages.paymentMethod.action.edit')}</span>
                                            </Menu.Item>
                                        ) : null}

                                        <Menu.Item
                                            onClick={() => {
                                                deletePayment(p.id);
                                            }}
                                            className="flex flex-row flex-start"
                                        >
                                            <Icon type="delete" />
                                            <span>{t('customerMessages.paymentMethod.action.delete')}</span>
                                        </Menu.Item>

                                        {get(selectedOrganisation, 'external_short_code') &&
                                        get(ACCOUNTING_SOFTWARE_DEEP_LINKS, [accountingSoftwareSlug, 'payment']) &&
                                        get(p, 'external_id') ? (
                                            <Menu.Item
                                                onClick={() => {
                                                    redirect(
                                                        multiStringReplace(
                                                            get(ACCOUNTING_SOFTWARE_DEEP_LINKS, [
                                                                accountingSoftwareSlug,
                                                                'payment',
                                                            ]),
                                                            {
                                                                short_code: get(
                                                                    selectedOrganisation,
                                                                    'external_short_code'
                                                                ),
                                                                payment_id: get(p, 'external_id'),
                                                            }
                                                        ),
                                                        true
                                                    );
                                                }}
                                                className="flex flex-row flex-start"
                                            >
                                                <Icon type="eye" />
                                                <span>View in {accountingSoftwareName}</span>
                                            </Menu.Item>
                                        ) : null}
                                    </Menu>
                                }
                            >
                                <Icon type="more" />
                            </Dropdown>
                        </div>
                    ),
                    // actions:
                    //     get(invoiceDetails, 'is_manual') &&
                    //     props.showActions &&
                    //     ![INVOICE_STATUS_WRITE_OFF_SLUG, INVOICE_STATUS_VOIDED_SLUG].includes(props.invoiceStatus) ? (
                    //         <div className="blue-bg-button">
                    //             <span>
                    //                 <PermissionGuard requiredPermission={INVOICE.PAYMENT.EDIT}>
                    //                     <Button
                    //                         className="green-bg-button blue-bg-button"
                    //                         filled
                    //                         onClick={() => {
                    //                             setOpenModal(true);
                    //                             setPaymentId(p.id);
                    //                         }}
                    //                     >
                    //                         View & Edit
                    //                     </Button>
                    //                 </PermissionGuard>
                    //             </span>
                    //             <span className="delete-button">
                    //                 <PermissionGuard requiredPermission={INVOICE.PAYMENT.DELETE}>
                    //                     <Button
                    //                         className="green-bg-button blue-bg-button"
                    //                         filled
                    //                         onClick={() => {
                    //                             deletePayment(p.id);
                    //                         }}
                    //                     >
                    //                         Delete
                    //                     </Button>
                    //                 </PermissionGuard>
                    //             </span>
                    //         </div>
                    //     ) : (
                    //         shouldRenderViewLink && (
                    //             <div className="center-align payment-page">
                    //                 <a
                    //                     className="accounting-software-external-link"
                    //                     href={multiStringReplace(
                    //                         get(ACCOUNTING_SOFTWARE_DEEP_LINKS, [accountingSoftwareSlug, 'payment']),
                    //                         {
                    //                             short_code: get(selectedOrganisation, 'external_short_code'),
                    //                             payment_id: get(p, 'external_id'),
                    //                         }
                    //                     )}
                    //                     target="_blank"
                    //                     rel="noreferrer noopener"
                    //                 >
                    //                     View in {accountingSoftwareName}
                    //                 </a>
                    //             </div>
                    //         )
                    //     ),
                };
            }),
        [invoicePayments, organisationCurrencies] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const memoizedReminderTableData = useMemo(() => getFormattedTableData(), [getFormattedTableData]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the invoice payment detail
     */
    const paymentDetails = useMemo(() => {
        const paymentDetail = find(invoicePayments, { id: paymentId });
        return !paymentId
            ? {
                  exchange_rate: get(invoiceCurrencyDetails, 'exchange_rates.exchange_rate', 1),
              }
            : {
                  ...paymentDetail,
                  paid_on: convertDateStringToFEFormat(paymentDetail?.paid_on),
                  invoice_number: get(paymentDetail, ['invoice', 'number'], ''),
              };
    });

    return (
        <>
            <div className="home-content-wrapper payment-history-wrapper">
                <div className="search-filter-export-wrapper">
                    <section>
                        <ul className="selector-field">
                            <li>
                                <div className="action-wrapper">
                                    <>
                                        {get(selectedOrganisation, 'is_manual', '') &&
                                            !contactId &&
                                            !invoiceId &&
                                            ![INVOICE_STATUS_WRITE_OFF_SLUG, INVOICE_STATUS_VOIDED_SLUG].includes(
                                                props.invoiceStatus
                                            ) && (
                                                <PermissionGuard requiredPermission={INVOICE.PAYMENT.IMPORT}>
                                                    <Button
                                                        onClick={() =>
                                                            redirect(
                                                                isAdmin
                                                                    ? `/admin/accounts/organisation/import/invoice-payments/${accountId}/${organisationId}/`
                                                                    : '/invoice-payments-import'
                                                            )
                                                        }
                                                        className="green-bg-button"
                                                        filled
                                                    >
                                                        {getLocaleText('action.importPayments')} <Icon type="upload" />
                                                    </Button>
                                                </PermissionGuard>
                                            )}
                                        {organisationId && (
                                            <ExportButton
                                                exportType={invoiceId ? 'invoice-invoice-payments' : 'invoice-payments'}
                                                contactId={contactId}
                                                invoiceId={invoiceId}
                                                queryParams={{
                                                    ...tableFilters,
                                                    ...(dateRange[0]
                                                        ? { start_date: dateRange[0].format('YYYY-MM-DD') }
                                                        : {}),
                                                    ...(dateRange[1]
                                                        ? { end_date: dateRange[1].format('YYYY-MM-DD') }
                                                        : {}),
                                                }}
                                            />
                                        )}
                                        {contactId &&
                                            invoiceId &&
                                            currentOrganisation.is_manual &&
                                            ![INVOICE_STATUS_WRITE_OFF_SLUG, INVOICE_STATUS_VOIDED_SLUG].includes(
                                                props.invoiceStatus
                                            ) && (
                                                <PermissionGuard requiredPermission={INVOICE.PAYMENT.ADD}>
                                                    <Button
                                                        big
                                                        filled
                                                        className="align-right"
                                                        onClick={() => {
                                                            setOpenModal(true);
                                                            setPaymentId('');
                                                        }}
                                                    >
                                                        {getLocaleText('addPayment')}
                                                    </Button>
                                                </PermissionGuard>
                                            )}
                                    </>
                                </div>
                            </li>
                        </ul>
                    </section>
                    <section>
                        <ul className="selector-field">
                            <li>
                                <li>
                                    <div className="date-picker-wrapper left-align">
                                        <span className="date-range-label">{getLocaleText('dateRange')}</span>
                                        <RangePicker
                                            value={dateRange}
                                            onChange={range => setDateRange(range)}
                                            size="small"
                                            disabled={props.isLoading}
                                            format="DD-MM-YYYY"
                                        />
                                    </div>
                                </li>
                            </li>
                            <li>
                                <div className="right-align">
                                    <SearchBlock
                                        getDataMethod={fetchTableData}
                                        isSearching={isSearching}
                                        placeholder={getLocaleText('placeholder.search')}
                                        setIsSearching={setIsSearching}
                                        setSearchParam={setSearchParam}
                                        setTableFilters={setTableFilters}
                                    />
                                </div>
                            </li>
                        </ul>
                    </section>
                </div>
                <section className="full-wrapper">{renderTable()}</section>
            </div>
            <Modal
                footer={[]}
                className="custom-form-modal text-left"
                visible={openModal}
                onCancel={closeModalPopup}
                destroyOnClose
            >
                <header>{getLocaleText(!isEmpty(paymentId) ? 'editPayment' : 'addPayment')}</header>
                <EditPaymentForm
                    closeModalPopup={closeModalPopup}
                    initialValues={paymentDetails}
                    onSubmit={handleSubmit}
                    loading={isInvoicePaymentCreating || isInvoicePaymentUpdating}
                    exchangeRateText={
                        !paymentId ? get(invoiceCurrencyDetails, 'exchange_rates.last_updated_date', '') : ''
                    }
                />
            </Modal>
            <Modal
                footer={[]}
                className="my_profile_modal organisation-reauthorization-modal"
                visible={isDeletingInvoicePayment}
            >
                <InfoMessage
                    message={t('customerMessages.invoice.payment.loading.message.deletingPayment')}
                    showLoader={true}
                />
            </Modal>
        </>
    );
};

// prop types
InvoicePaymentsBlock.propTypes = {
    fromInvoicePage: PropTypes.bool,
    invoiceStatus: PropTypes.string,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    organisation: PropTypes.string,
    showActions: PropTypes.bool,
};

export default InvoicePaymentsBlock;

import PropTypes from 'prop-types';
import { Icon, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard as ReactCopyToClipboard } from 'react-copy-to-clipboard';

/**
 * Copy to clipboard wrapper
 *
 * Renders the content with a copy icon to the right
 * NOTE: Width has to be manually set using css class through `valueClassName` prop
 * It can also be used to apply other styles as well to the text
 */
export default function CopyToClipboard({ valueClassName = '', value }) {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 1000);
        }
    }, [copied]);

    if (!value) return null;

    return (
        <ReactCopyToClipboard text={value} onCopy={() => setCopied(true)}>
            <Tooltip title={copied ? 'Copied' : 'Click to copy'}>
                <button className="flex flex-row items-center gap-2">
                    <div className={`whitespace-nowrap overflow-hidden overflow-ellipsis ${valueClassName}`}>
                        {value}
                    </div>
                    <Icon type={copied ? 'check-circle' : 'copy'} theme="twoTone" twoToneColor="#8ace7d" />
                </button>
            </Tooltip>
        </ReactCopyToClipboard>
    );
}

CopyToClipboard.propTypes = {
    value: PropTypes.string,
    valueClassName: PropTypes.string,
};

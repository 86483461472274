/**
 * Admin settings reducer
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';

const INITIAL_STATE = {
    isLoading: false,
    siderCollapsed: !(window.innerWidth > 767), // collapse set to 'false' if window width > 767, else set to 'true'
};

export default function adminSettingsReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case actionTypes.GET_ADMIN_SETTINGS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case actionTypes.GET_ADMIN_SETTINGS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.GET_ADMIN_SETTINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                settings: action.settings,
                initialValues: action.initialValues,
            };
        case actionTypes.SIDEBAR_COLLAPSE_TOGGLE:
            return {
                ...state,
                siderCollapsed: action.visible,
            };
        default:
            return {
                ...state,
            };
    }
}

import { get } from 'lodash';
import { CACHE_PAYMENT_METHOD_TYPES_KEY } from 'includes/constants';
import { GET_PAYMENT_METHOD_TYPES } from 'includes/constants/mappings/success';
import PaymentGatewaysApi from 'includes/services/shared/paymentGateways';
import useCachedData from './useCachedData';

/**
 * Get the payment types
 *
 * @param {boolean} disableApiCall Whether to disable api call or not. Default false
 *
 * @returns object payment method types
 */
const usePaymentMethodTypes = (disableApiCall = false) => {
    return useCachedData(
        CACHE_PAYMENT_METHOD_TYPES_KEY,
        () => PaymentGatewaysApi.getPaymentMethodTypes(),
        {
            selector: res => get(res, GET_PAYMENT_METHOD_TYPES),
            disableApiCall: disableApiCall,
        },
        []
    );
};

export default usePaymentMethodTypes;

/**
 * Notifications Listing Component
 * Handles the notification list view and functions related to loading account notifications listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import { React, connect, useMemo, useCallback } from '../../../includes/exports/react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Badge, Modal } from 'antd';
import { cloneDeep, findIndex, get } from 'lodash';

import { NOTIFICATION_TABLE_COLUMNS } from '../../../includes/constants/columns';
import * as errorMessages from '../../../includes/constants/messages/errors';
import Table from '../../shared/ScrollableTable';
import {
    getNotifications,
    sendDataToServer,
    updateAllNotifications,
    updateNotifications,
} from '../../../includes/redux/actions/shared/notification';
import { forceReRenderComponentRequest } from '../../../includes/redux/actions/shared/settings';
import NotificationLink from './link';
import { NOTIFICATION_PRIORITY_BADGE_MAPPING } from '../../../includes/constants/mappings/success';
import * as requestKeys from '../../../includes/constants/keys/request';
import * as responseKeys from '../../../includes/constants/keys/response';
import ReactHtmlParser from 'react-html-parser';
import './styles.scss';
import { WS_ACTION } from 'includes/constants';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useZendeskSettings from 'includes/hooks/useZendeskSettings';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';

const { error, info } = Modal;

/**
 * Notifications Listing Component
 * Render the notifications listing component
 *
 * @returns    {string}    Organisation listing view
 */
const Notifications = props => {
    const { getNotifications, selectedOrganisationId, currentAccountId } = props;
    const isAdmin = useIsAdminOrManager();
    const { reset: clearZendeskSettingsCache } = useZendeskSettings(true);
    const { reset: clearOrganisationCurrenciesCache } = useOrganisationCurrencies(true);

    /* Fetch notifications
     *
     * @param   {object}    data
     */
    const fetchNotifications = (data = {}) => {
        let searchParams = { ...data };
        getNotifications({
            organisation_id: selectedOrganisationId,
            account_id: currentAccountId,
            searchParams: searchParams,
        });
    };

    /**
     * Force component rerender action request
     * Makes the request to set the prop to make the component rerender
     *
     * @param   {string}    link    Link to redirect
     */
    const forceReRenderComponent = link => {
        // send the request only if the current path and the link are same
        // if they are different, the component will automatically rerender and we don't need to force
        if (props.location.pathname === link) {
            props.forceReRenderComponentRequest();
        }
    };

    /**
     * Get the notification content
     */
    const getNotificationContent = notification => {
        let link = notification.link;
        if (!link) {
            if (
                notification.action === 'organisation_data_fetching_complete' ||
                notification.action === 'organisation_sync_complete'
            ) {
                link = '/dashboard';
            } else if (notification.action === 'reminder_scheduling_complete') {
                link = '/reminders';
            }
        }
        return (
            <NotificationLink link={link} forceReRenderComponent={forceReRenderComponent}>
                <ul className="notifications-dropdown" onClick={() => handleNotificationClick(notification)}>
                    <li>
                        <span className="details">
                            <p className="notification-title">
                                {ReactHtmlParser(get(notification, responseKeys.NOTIFICATION_TITLE))}
                            </p>
                            <p className="notification-description">
                                {ReactHtmlParser(get(notification, responseKeys.NOTIFICATION_DESCRIPTION))}
                            </p>
                        </span>
                    </li>
                </ul>
            </NotificationLink>
        );
    };

    /**
     * Get the notification content
     */
    const getNotificationImageContent = notification => {
        const notificationImage =
            get(notification, [responseKeys.NOTIFICATION_IMAGE, responseKeys._45X45]) ||
            require('../../../assets/images/notification-icon.svg');

        let notificationBadgeType = get(
            NOTIFICATION_PRIORITY_BADGE_MAPPING,
            get(notification, [responseKeys.NOTIFICATION_TYPE, responseKeys.NOTIFICATION_TYPE_SLUG]),
            'default'
        );

        return (
            <NotificationLink link={notification.link} forceReRenderComponent={forceReRenderComponent}>
                <Badge status={notificationBadgeType}>
                    <div className="notification-img-wrapper">
                        <img src={notificationImage} alt="Notification" />
                    </div>
                </Badge>
            </NotificationLink>
        );
    };

    const { allNotifications } = props;

    const formatNotificationsData = useCallback(
        () =>
            allNotifications.slice(0, 10).map(notification => ({
                ...notification,
                content: getNotificationContent(notification),
                imageBadge: getNotificationImageContent(notification),
            })), // eslint-disable-next-line
        [allNotifications]
    );

    /**
     * Handle the click on the notification row
     *
     * @param   {object}    notification    Notification
     *
     * @returns  {boolean}   true
     */
    const handleNotificationClick = notification => {
        let notificationId = get(notification, responseKeys.NOTIFICATION_ID);
        let hasRead = get(notification, responseKeys.NOTIFICATION_HAS_READ);
        if (notificationId && !hasRead) {
            let allNotifications = cloneDeep(props.allNotifications);
            let allNotificationIndex = findIndex(allNotifications, { id: notificationId });
            let allNotification = get(allNotifications, allNotificationIndex);

            let notifications = cloneDeep(props.notifications);
            let notificationIndex = findIndex(notifications, { id: notificationId });
            let notification = get(notifications, notificationIndex);

            let unreadCount = props.unreadCount > 0 ? props.unreadCount - 1 : 0;

            if (allNotificationIndex !== -1) {
                // check if the notification status is read or not
                // move forward only if the notification status is unread
                if (!get(allNotification, responseKeys.NOTIFICATION_HAS_READ)) {
                    allNotifications[allNotificationIndex][responseKeys.NOTIFICATION_HAS_READ] = true;

                    props.sendDataToServer({
                        notification_id: notificationId,
                        type: requestKeys.NOTIFICATION_MARK_AS_READ,
                    });

                    props.updateAllNotifications(allNotifications, unreadCount);
                }
            }

            if (notification) {
                // check if the notification status is read or not
                // move forward only if the notification status is unread
                if (!get(notification, responseKeys.NOTIFICATION_HAS_READ)) {
                    notifications[notificationIndex][responseKeys.NOTIFICATION_HAS_READ] = true;
                    props.updateNotifications(notifications, unreadCount);
                }
            }
        }
        renderNotificationPopup(notification);
    };

    // render notification popup
    const renderNotificationPopup = notification => {
        // handle corresponding action
        let notificationProps = {
            title: ReactHtmlParser(notification.title),
            content: ReactHtmlParser(notification.description),
            centered: true,
            maskClosable: true,
        };
        let notificationShownFlag = false;
        switch (notification.action) {
            case WS_ACTION.ORGANISATION_DATA_FETCHING_COMPLETE:
            case WS_ACTION.ORGANISATION_SYNC_COMPLETE:
            case WS_ACTION.ORGANISATION_DATA_SYNCING_COMPLETE:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Refresh data',
                    onOk: () => {
                        clearOrganisationCurrenciesCache();
                        props.history.push('/dashboard');
                        forceReRenderComponent('/dashboard');
                    },
                };
                break;
            case WS_ACTION.ACCOUNT_SUBSCRIPTION_CANCELLED:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Reload',
                    onOk: () => window.location.replace('/'),
                };
                break;
            case WS_ACTION.ACCOUNT_SUBSCRIPTION_LIMIT_REACHED:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Upgrade subscription',
                    onOk: () => props.history.push('/plans'),
                };
                break;
            case WS_ACTION.ACCOUNT_SUBSCRIPTION_UPDATE_COMPLETE:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Reload',
                    onOk: () => window.location.replace('/'),
                };
                break;
            case WS_ACTION.ORGANISATION_REAUTHORIZATION_REQUIRED:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Reauthorize',
                    onOk: () =>
                        props.history.push({
                            pathname: '/organisations/reauthorize',
                            state: {
                                reauthorize: true,
                                organisation: props.selectedOrganisation,
                            },
                        }),
                };
                break;
            case WS_ACTION.ORGANISATION_LEGAL_PAYMENT_COMPLETE:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Orders',
                    onOk: () => props.history.push({ pathname: '/orders', state: { typeSlug: 'legal-letter' } }),
                };
                break;
            case WS_ACTION.ORGANISATION_INVOICE_PAYMENT_RECEIVED:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Orders',
                    onOk: () =>
                        props.history.push({
                            pathname: '/orders',
                            state: { typeSlug: 'invoice' },
                        }),
                };
                break;
            case WS_ACTION.ORGANISATION_PAYMENT_ARRANGEMENT_REQUEST:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Payment arrangements',
                    onOk: () => props.history.push('/payment-arrangements'),
                };
                break;
            case WS_ACTION.ORGANISATION_PAYMENT_EXTENSION_REQUEST:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Payment extensions',
                    onOk: () => props.history.push('/payment-extensions'),
                };
                break;
            case WS_ACTION.FILE_EXPORTED_SUCCESSFULLY:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Exports',
                    onOk: () => props.history.push(isAdmin ? '/admin/exports' : '/exports'),
                };
                break;
            case WS_ACTION.FILE_EXPORT_FAILED:
                error(notificationProps);
                notificationShownFlag = true;
                break;
            case WS_ACTION.ORGANISATION_ZENDESK_CONNECTION_FAILURE:
                clearZendeskSettingsCache();
                error(notificationProps);
                notificationShownFlag = true;
                break;
            case WS_ACTION.REMINDER_SCHEDULING_COMPLETE:
                notificationProps = {
                    ...notificationProps,
                    okText: 'See Reminders',
                    onOk: () => {
                        props.history.push('/reminders');
                        forceReRenderComponent('/reminders');
                    },
                };
                break;
            default:
                notificationProps = { ...notificationProps, okText: 'Dismiss' };
        }
        !notificationShownFlag && info(notificationProps);
    }; // eslint-disable-line react-hooks/exhaustive-deps

    // memoized data
    const notificationsData = useMemo(() => formatNotificationsData(), [formatNotificationsData]);

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper recent-notifications-wrapper">
            <h2 className="page-title">{props.t('sharedMessages.notifications.title')}</h2>
            <section className="full-wrapper box-wrapper white-box-wrapper">
                <Table
                    columns={NOTIFICATION_TABLE_COLUMNS}
                    dataSource={notificationsData}
                    getDataMethod={fetchNotifications}
                    loading={props.isLoading}
                    localeMessage={errorMessages.TABLE_NO_NOTIFICATIONS_FOUND_MESSAGE}
                    rowClassName={notification =>
                        `pointer ${
                            !get(notification, responseKeys.NOTIFICATION_HAS_READ, false) ? 'unread-notification' : ''
                        }`
                    }
                    paginationData={props.paginationNotifications}
                    rowKey="id"
                    size="middle"
                    onRow={record => ({
                        onClick: () => {
                            handleNotificationClick(record);
                        }, // click row
                    })}
                    {...props}
                />
            </section>
        </div>
    );
};

/**
 * Proptypes
 */
Notifications.propTypes = {
    allNotifications: PropTypes.array,
    currentAccountId: PropTypes.string,
    defaultOrganisationLogo: PropTypes.string,
    forceNotificationUpdate: PropTypes.bool,
    forceReRenderComponentRequest: PropTypes.func,
    getNotifications: PropTypes.func,
    history: PropTypes.object,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    notifications: PropTypes.array,
    paginationNotifications: PropTypes.object,
    sendDataToServer: PropTypes.func,
    selectedOrganisation: PropTypes.object,
    selectedOrganisationId: PropTypes.string,
    t: PropTypes.func,
    unreadCount: PropTypes.number,
    updateAllNotifications: PropTypes.func,
    updateNotifications: PropTypes.func,
};

// connect to store
export default connect(
    state => ({
        ...state.notification,
        defaultOrganisationLogo: state.settings.defaultOrganisationLogo,
        selectedOrganisation: state.organisation.selectedOrganisation,
        selectedOrganisationId: state.organisation.selectedOrganisationId,
        currentAccountId: state.account.currentAccountId,
    }),
    {
        forceReRenderComponentRequest,
        getNotifications,
        sendDataToServer,
        updateAllNotifications,
        updateNotifications,
    }
)(withTranslation()(Notifications));

import React from 'react';
import PropTypes from 'prop-types';

import { PAYMENT_SERVICE, PAYMENT_SERVICE_SLUG } from 'components/PaymentGateways/constants';

const SERVICE_CLASSNAME_MAP = {
    [PAYMENT_SERVICE_SLUG.CustomPaymentURL]: 'bg-red-100 text-red-800',
    [PAYMENT_SERVICE_SLUG.FatZebra]: 'bg-blue-200 text-blue-700',
    [PAYMENT_SERVICE_SLUG.GoCardless]: 'bg-yellow-100 text-yellow-500',
    [PAYMENT_SERVICE_SLUG.Monoova]: 'bg-indigo-200 text-indigo-900',
    [PAYMENT_SERVICE_SLUG.PayFurl]: 'bg-purple-200 text-purple-600',
    [PAYMENT_SERVICE_SLUG.PaymentInstructions]: 'bg-green-100 text-green-700',
    [PAYMENT_SERVICE_SLUG.Stripe]: 'bg-indigo-200 text-indigo-500',
};

/**
 * Renders a badge indicating the service based on the slug
 *
 * @since 2.8.0
 */
export default function ServiceLabel({ slug }) {
    return (
        <span
            className={`w-min text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 ${SERVICE_CLASSNAME_MAP[slug]}`}
        >
            {PAYMENT_SERVICE[slug]?.name ?? slug}
        </span>
    );
}

ServiceLabel.propTypes = {
    /**
     * slug of the payment service
     */
    slug: PropTypes.oneOf(Object.values(PAYMENT_SERVICE_SLUG)),
};

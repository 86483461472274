/**
 * Orders API class.
 * Handles all api requests related to order
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com>
 */

//import base api handler
import BaseApiHandler from './base/api';

class OrdersApi extends BaseApiHandler {
    /**
     * Create Order
     *
     * @param {string} orderTypeId Order Type Id
     * @param {object} payload Payload. Default {}
     *
     * @returns {object} API Response
     */
    createOrder = (orderTypeId, payload = {}) => this.axios.post(this._getOrdersApiUrl(orderTypeId), payload);

    /**
     * Cancel Order
     *
     * @param {string} orderTypeId Order Type Id
     * @param {string} orderId Order Id.
     *
     * @returns {object} API Response
     */
    cancelOrder = (orderTypeId, orderId, params = {}) =>
        this.axios.delete(this._getOrdersApiUrl(orderTypeId, orderId), { params });

    /**
     * Download Order
     *
     * @param {string} orderTypeId Order Type id
     * @param {string} orderId Order Id. Default
     * @param {string} organisationId Organisation Id. Default ''
     * @param {object} searchParams Search params. Default {}
     *
     * @returns {object} API Response
     */
    downloadOrderInvoice = (orderTypeId, orderId, searchParams = {}) =>
        this.axios.get(this._getOrdersApiUrl(orderTypeId, orderId, true), { params: searchParams });

    /**
     * Get Order Details
     *
     * @param {string} orderTypeId Order Type Id
     * @param {string} orderId Order Id. Default
     * @param {object} searchParams Search params. Default {}
     *
     * @returns {object} API Response
     */
    getOrderDetails = (orderTypeId, orderId, searchParams = {}) =>
        this.axios.get(this._getOrdersApiUrl(orderTypeId, orderId), { params: searchParams });

    /**
     * Get Order status
     *
     * @param {string} orderTypeId Order Type id
     * @param {string} orderId Order Id. Default
     * @param {object} searchParams Search params. Default {}
     *
     * @returns {object} API Response
     */
    getOrderStatus = (orderTypeId, orderId, searchParams = {}) =>
        this.axios.get(this._getOrdersApiUrl(orderTypeId, orderId, false, true), {
            params: searchParams,
        });

    /**
     * Get Orders
     *
     * @param {string} orderTypeId Order Type Id
     * @param {object} searchParams Search params. Default {}
     *
     * @returns {object} API Response
     */
    getOrders = (orderTypeId, searchParams = {}) => {
        return this.axios.get(this._getOrdersApiUrl(orderTypeId), {
            params: searchParams,
        });
    };

    /**
     * Get Order Types
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getOrderTypes = payload => this.axios.get(this.getApiUrl('ORDER_TYPES'), { params: { ...payload } });

    /**
     * Get Order Statuses
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getOrderStatuses = payload => this.axios.get(this.getApiUrl('ORDER_STATUSES'), { params: { ...payload } });

    /**
     * Retry an order
     *
     * @param {string} orderTypeId Order Type Id
     * @param {string} orderId Order Id
     * @param {object} restPayload Payload. Default {}
     *
     * @returns {object} API Response
     */
    retryOrder = (orderTypeId, orderId, payload = {}) =>
        this.axios.put(this._getOrdersApiUrl(orderTypeId, orderId), payload);

    /**
     * Retry an order
     *
     * @param {string} orderTypeId Order Type Id
     * @param {string} orderId Order Id
     * @param {object} restPayload Payload. Default {}
     *
     * @returns {object} API Response
     */
    retryOrderPayment = (orderTypeId, orderId, payload = {}) =>
        this.axios.patch(
            this.getApiUrl('ORDER_RETRY_PAYMENT', { order_type_id: orderTypeId, order_id: orderId }),
            payload
        );

    /**
     * Update an Order
     *
     * @param {string} orderTypeId Order Type Id
     * @param {string} orderId Order Id
     * @param {object} restPayload Payload. Default {}
     *
     * @returns {object} API Response
     */
    updateOrder = (orderTypeId, orderId, payload = {}) =>
        this.axios.patch(this._getOrdersApiUrl(orderTypeId, orderId), {
            ...payload,
        });

    /**
     * Get order charges
     *
     * @param {string} orderTypeId order type ID
     * @param {object} paymentDetails Payment order details
     *
     * @returns {object} API Response
     */
    getOrderCharges = (orderTypeId, paymentDetails) =>
        this.axios.post(this.getApiUrl('ORDER_CHARGES', { order_type_id: orderTypeId }), paymentDetails);

    /**
     * Confirm order
     *
     * @param {string} orderTypeId order type ID
     * @param {object} orderId order ID
     *
     * @returns {object} API Response
     */
    confirmOrder = (orderTypeId, orderId, body) =>
        this.axios.patch(this.getApiUrl('ORDER_CONFIRM', { order_type_id: orderTypeId, order_id: orderId }), body);

    /**
     * Get orders api url
     *
     * @param {string} orderTypeId Order type id
     * @param {string} accountId Account Id
     * @param {string} orderId Order Id
     *
     * @returns {string} apiUrl Notes API URL
     */
    _getOrdersApiUrl = (orderTypeId, orderId = '', isDownloadUrl = false, isStatusUrl = false) => {
        let apiUrl = 'ORDER';

        if (!orderId) {
            apiUrl += 'S';
        }

        if (isDownloadUrl) {
            apiUrl += '_DOWNLOAD';
        } else if (isStatusUrl) {
            apiUrl += '_STATUS';
        } else {
            apiUrl += '_CRUD';
        }

        return this.getApiUrl(apiUrl, {
            order_type_id: orderTypeId,
            order_id: orderId,
        });
    };
}

export default new OrdersApi();

/**
 * Customer module routing
 * Handles the routing across the customer module
 *
 * @since 1.0
 */

//import required modules
import ActivateAccount from '../../../components/shared/ActivateAccount';
import AddEditContact from '../../../components/customer/Contacts/addEdit';
// import AddEditInvoice from 'pages/Invoices/Details/addEdit';
import AddEditOrganisation from '../../../components/customer/OrganisationDetails/addEdit';
import AddEditInvoice from 'pages/Invoices/AddEdit';
import AppFooter from '../../../components/shared/footer/AppFooter';
import AppHeader from '../../../components/shared/header/AppHeader';
import AuditLogsList from 'pages/AuditLogs/List';
import BusinessInfoEdit from '../../../components/customer/MyAccount/BusinessInfoEdit';
import CampaignsList from 'pages/Campaigns/List';
import Connect from '../../../components/customer/Connect';
import ContactDetails from '../../../components/customer/Contacts/details';
import Contacts from '../../../components/customer/Contacts';
import CustomerNavHeader from '../../../components/shared/header/CustomerNavHeader';
import Dashboard from '../../../components/customer/Dashboard';
import EditProfile from '../../../components/shared/MyProfile/edit';
import Exports from '../../../components/shared/Exports';
import Home from '../../../components/customer/Home';
import Import from '../../../components/Import';
import InvoiceCampaignHistory from 'pages/InvoiceCampaignHistory/List';
import InvoiceDetails from 'pages/Invoices/Details';
import InvoicePaymentHeader from '../../../components/shared/header/InvoicePaymentHeader';
import Invoices from 'pages/Invoices/List';
import Login from '../../../components/shared/LoginPage';
import LoginHeader from '../../../components/shared/header/LoginHeader';
import CreateOrder from '../../../components/shared/Orders/CreateOrder';
import MyAccount from '../../../components/customer/MyAccount';
import MyProfile from '../../../components/shared/MyProfile';
import Notifications from '../../../components/shared/Notifications/';
import OrderDetails from '../../../components/shared/Orders/OrderDetails';
import Orders from '../../../components/shared/Orders';
import OrganisationDetails from '../../../components/customer/OrganisationDetails';
import Organisations from '../../../components/customer/Organisations';
import OrganisationsSettings from '../../../components/customer/Organisations/settings';
import PageNotFound from '../../../components/shared/PageNotFound';
import InvoicePayments from '../../../components/customer/InvoicePayments';
import Plans from '../../../components/customer/Plans';
import Reminders from '../../../components/customer/Reminders';
import RequestQuote from '../../../components/customer/RequestQuote';
import ResetPassword from '../../../components/shared/ResetPassword';
import RouteValidators from '../validators';
import Unauthorized from '../../../components/shared/Unauthorized';
import UnderMaintenance from '../../../components/shared/UnderMaintenance';
import UserSubscription from '../../../components/customer/Subscription';
import UserSubscriptionDetail from '../../../components/customer/Subscription/detail';
import { NoSidebarLayout } from 'layouts/NoSidebar';
import XeroConsent from '../../../components/customer/Connect/Xero/consent';
import FollowUps from 'pages/FollowUps';
import InventoryList from 'pages/Inventory/List';
import InventoryItemAddEdit from 'pages/Inventory/AddEdit';
import TaxesList from 'pages/Taxes/List';
import ChartOfAccountsList from 'pages/ChartOfAccounts/List';
import ChartOfAccountDetails from 'pages/ChartOfAccounts/Details';
import InventoryDetails from 'pages/Inventory/Details';
import ChartOfAccountAddEdit from 'pages/ChartOfAccounts/AddEdit';
import TaxDetails from 'pages/Taxes/Details';
import TaxAddEdit from 'pages/Taxes/AddEdit';
import DebtorAccountsList from 'pages/DebtorAccounts/List';
import OnDemandCampaignHistory from 'pages/OnDemandCampaignHistory/List';
import OnDemandCampaignHistoryDetails from 'pages/OnDemandCampaignHistory/Details/component';
import InvoiceCampaignHistoryDetails from 'pages/InvoiceCampaignHistory/Details/component';
import ZendeskTicketsList from 'pages/Zendesk/Tickets/List';
import ZendeskTicketDetails from 'pages/Zendesk/Tickets/Details';
import ZendeskSettings from 'pages/Zendesk/Settings';
import PaymentInformation from 'components/customer/PaymentInformation';
import {
    ACCOUNTING_SOFTWARE,
    ACCOUNT_INFORMATION,
    AUDIT_LOG,
    CAMPAIGN,
    CHART_OF_ACCOUNT,
    CONTACT,
    CONTACT_MANAGER,
    ORGANISATION_CURRENCY,
    DASHBOARD,
    DEBTOR_ACCOUNT,
    EXPORT,
    FOLLOWUPS,
    INVENTORY,
    INVOICE,
    ORGANISATION,
    RECURRING_INVOICE,
    REMINDER,
    REPORTING,
    SUBSCRIPTION,
    TAX,
    ZENDESK,
    CONTACT_TYPE,
    PAYMENT_ARRANGEMENT,
    PAYMENT_EXTENSION,
    ORDER,
    PAYMENT_METHOD,
    PAYMENT_GATEWAY,
} from 'includes/constants/permissions';
import ReportingExport from 'pages/Reporting/Export';
import OrganisationCurrenciesList from 'pages/OrganisationCurrencies/List';
import PaymentPlansList from 'pages/PaymentPlans/List';
import PaymentPlanAddEdit from 'pages/PaymentPlans/AddEdit';
import PaymentPlanDetails from 'pages/PaymentPlans/Details';
import PaymentArrangementsList from 'pages/PaymentArrangements/List';
import PaymentArrangementDetails from 'pages/PaymentArrangements/Details';
import { NoSidebarHomeLayout } from 'layouts/NoSidebarHome';
import ContactTypesList from 'pages/ContactTypes/List';
import CampaignDetails from 'pages/Campaigns/Details';
import CampaignAddEdit from 'pages/Campaigns/AddEdit';
import Logout from 'pages/Logout';
import Loader from 'components/Loader';
import EventsCalendar from 'pages/EventsCalendar/List';
import PaymentServices from 'pages/PaymentServices';
import OrganisationOrders from 'pages/OrganisationOrders';
import PaymentMethodDetails from 'pages/PaymentMethodDetails';
import PaymentDetails from 'pages/PaymentDetails';
import PaymentServiceDetails from 'pages/PaymentServiceDetails';
import NoOrganisation from 'pages/NoOrganisation';
import InvoiceStatuses from 'pages/InvoiceStatuses';

export const defaultAppFooter = AppFooter;
export const defaultAppHeader = CustomerNavHeader;
export const defaultAppLayout = NoSidebarLayout;
export const defaultAppPageNotFound = PageNotFound;
export const defaultAppSidebar = '';
export const defaultAppValidator = [RouteValidators.isTokenExists];

export const routes = [
    {
        path: '/',
        bypassEmailVerifiedValidation: true,
        bypassPhoneNumberVerifiedValidation: true,
        component: Home,
        exact: true,
        header: AppHeader,
        isPublic: true,
        slug: 'home',
        validators: [RouteValidators.isUserLoggedIn],
        layout: NoSidebarHomeLayout,
    },
    {
        path: '/auth',
        slug: 'authorizingPage',
        isPublic: true,
        component: Loader,
    },
    {
        path: '/add-contact',
        component: AddEditContact,
        componentProps: {
            action: 'add',
        },
        requiredPermission: CONTACT.ADD,
        isPrivate: true,
        slug: 'addContact',
    },
    {
        path: '/add-contact-manager',
        component: AddEditContact,
        componentProps: {
            action: 'add',
            isContactManager: true,
        },
        isPrivate: true,
        requiredPermission: CONTACT_MANAGER.ADD,
        slug: 'addContactManager',
    },
    {
        path: '/audit-logs/:organisationId',
        component: AuditLogsList,
        componentProps: {
            showFilter: true,
        },
        isPrivate: true,
        slug: 'auditLogs',
        exact: true,
        requiredPermission: AUDIT_LOG.LIST,
    },
    {
        path: '/invoices/add',
        component: AddEditInvoice,
        isPrivate: true,
        componentProps: {
            action: 'add',
        },
        requiredPermission: INVOICE.ADD,
        slug: 'addInvoice',
        isPublic: true,
    },
    {
        path: '/invoices/recurring/:contactId/:invoiceId/edit',
        component: AddEditInvoice,
        isPrivate: true,
        componentProps: {
            action: 'edit',
            isRecurring: true,
        },
        slug: 'addInvoice',
        isPublic: true,
    },
    {
        path: '/invoices/recurring/add',
        component: AddEditInvoice,
        isPrivate: true,
        componentProps: {
            action: 'add',
            isRecurring: true,
        },
        requiredPermission: RECURRING_INVOICE.ADD,
        slug: 'addInvoice',
        isPublic: true,
    },
    {
        path: '/invoices/invoice/:contactId/:invoiceId/edit',
        component: AddEditInvoice,
        isPrivate: true,
        componentProps: {
            action: 'edit',
        },
        requiredPermission: INVOICE.EDIT,
        slug: 'editInvoice',
    },
    {
        path: '/invoices/invoice/:contactId/:invoiceId/campaign/history',
        component: InvoiceCampaignHistory,
        isPrivate: true,
        exact: true,
        requiredPermission: INVOICE.ON_DEMAND_CAMPAIGN.HISTORY,
        slug: 'invoiceCampaignHistory',
    },
    {
        path: '/invoices/invoice/:contactId/:invoiceId/campaign/history/:communicationLogHistoryId',
        component: InvoiceCampaignHistoryDetails,
        isPrivate: true,
        exact: true,
        slug: 'invoiceCampaignHistoryDetails',
    },
    {
        path: '/campaigns',
        component: CampaignsList,
        isPrivate: true,
        requiredPermission: CAMPAIGN.LIST,
        slug: 'campaigns',
        exact: true,
    },
    {
        path: '/campaigns/campaign/:campaignId/edit',
        component: CampaignAddEdit,
        slug: 'campaignEdit',
        isPrivate: true,
        requiredPermission: CAMPAIGN.EDIT,
        exact: true,
    },
    {
        path: '/campaigns/campaign/:campaignId',
        component: CampaignDetails,
        slug: 'campaign',
        isPrivate: true,
        requiredPermission: CAMPAIGN.VIEW,
        exact: true,
    },
    {
        path: '/payment-plans/plan/:paymentPlanId/campaigns/campaign/:campaignId',
        component: CampaignDetails,
        slug: 'campaignDetails',
        isPrivate: true,
        //requiredPermission: CHART_OF_ACCOUNT.EDIT,
        exact: true,
    },
    {
        path: '/payment-plans/plan/:paymentPlanId/campaigns/campaign/:campaignId/edit',
        component: CampaignAddEdit,
        slug: 'campaignEdit',
        isPrivate: true,
        //requiredPermission: CHART_OF_ACCOUNT.EDIT,
        exact: true,
    },

    {
        path: '/connect/xero/consent',
        component: XeroConsent,
        validators: [RouteValidators.isTokenExists],
        slug: 'xero-connect-consent',
    },
    {
        path: '/connect',
        component: Connect,
        validators: [RouteValidators.isTokenExists],
        requiredPermission: ACCOUNTING_SOFTWARE.CONNECT,
        slug: 'connect',
    },
    {
        path: '/contact-import',
        component: Import,
        componentProps: {
            action: 'contacts',
        },
        isPrivate: true,
        requiredPermission: CONTACT.IMPORT,
        slug: 'importContacts',
    },
    {
        path: '/contact-manager-import',
        component: Import,
        componentProps: {
            action: 'contact-managers',
        },
        isPrivate: true,
        requiredPermission: CONTACT_MANAGER.IMPORT,
        slug: 'importContactManagers',
    },
    {
        path: '/contact/:contactId',
        component: ContactDetails,
        isPrivate: true,
        slug: 'contactDetails',
        requiredPermission: CONTACT.VIEW,
    },
    {
        path: '/contact-manager/:contactId',
        component: ContactDetails,
        componentProps: {
            isContactManager: true,
        },
        isPrivate: true,
        requiredPermission: CONTACT_MANAGER.VIEW,
        slug: 'contactDetails',
    },
    {
        path: '/contacts',
        component: Contacts,
        isPrivate: true,
        requiredPermission: CONTACT.LIST,
        slug: 'contacts',
    },
    {
        path: '/contact-managers',
        component: Contacts,
        componentProps: {
            isContactManager: true,
        },
        isPrivate: true,
        requiredPermission: CONTACT_MANAGER.LIST,
        slug: 'contact-managers',
    },
    {
        path: '/contact-types',
        component: ContactTypesList,
        isPrivate: true,
        requiredPermission: CONTACT_TYPE.LIST,
        slug: 'contact-types',
    },
    {
        path: '/currencies',
        component: OrganisationCurrenciesList,
        isPrivate: true,
        requiredPermission: ORGANISATION_CURRENCY.LIST,
        slug: 'organisationCurrencies',
    },
    {
        path: '/dashboard',
        component: Dashboard,
        isPrivate: true,
        validators: [RouteValidators.isTokenExists],
        requiredPermission: DASHBOARD.VIEW,
        slug: 'dashboard',
    },
    {
        path: '/edit-business-info',
        component: BusinessInfoEdit,
        isPrivate: true,
        slug: 'editBusinessInfo',
    },
    {
        path: '/edit-contact/:contactId',
        component: AddEditContact,
        isPrivate: true,
        slug: 'editContact',
        requiredPermission: CONTACT.EDIT,
    },
    {
        path: '/edit-contact-manager/:contactId',
        component: AddEditContact,
        componentProps: {
            isContactManager: true,
        },
        isPrivate: true,
        requiredPermission: CONTACT_MANAGER.EDIT,
        slug: 'editContact',
    },
    {
        path: '/edit-organisation/:organisationId',
        component: AddEditOrganisation,
        isPrivate: true,
        slug: 'editOrganisation',
        requiredPermission: ORGANISATION.EDIT,
    },
    {
        path: '/edit-profile',
        component: EditProfile,
        isPrivate: true,
        slug: 'editProfile',
    },
    {
        path: '/event-calendar',
        component: EventsCalendar,
    },
    {
        path: '/exports',
        component: Exports,
        isPrivate: true,
        requiredPermission: EXPORT.LIST,
        slug: 'exports',
    },
    {
        path: '/invoice-import',
        component: Import,
        componentProps: {
            action: 'invoices',
            link: 'invoices',
        },
        requiredPermission: INVOICE.IMPORT,
        isPrivate: true,
        slug: 'importInvoice',
    },
    {
        path: '/invoice-statuses',
        component: InvoiceStatuses,
        isPrivate: true,
        slug: 'invoiceStatuses',
    },
    {
        path: '/invoice-payments-import',
        component: Import,
        componentProps: {
            action: 'invoice-payments',
        },
        requiredPermission: INVOICE.PAYMENT.IMPORT,
        isPrivate: true,
        slug: 'importInvoicePayments',
    },
    {
        path: '/invoice/:orderType/organisation/:organisationId/:contactId/order/:orderId/details',
        component: OrderDetails,
        componentProps: {
            isDebtorInvoicePaymentPage: true,
        },
        header: InvoicePaymentHeader,
        slug: 'orderDetails',
        isPublic: true,
    },
    {
        path: '/invoices/invoice/:contactId/:invoiceId',
        component: InvoiceDetails,
        isPrivate: true,
        requiredPermission: INVOICE.VIEW,
        slug: 'invoiceDetails',
    },
    {
        path: '/invoices/payments/:organisationId/:contactId/:invoiceId/:invoicePaymentId',
        component: PaymentDetails,
        isPrivate: true,
        requiredPermissions: INVOICE.PAYMENT.VIEW,
        slug: 'invoicePaymentDetails',
    },
    {
        path: '/invoices/payment/:orderType/:orderId/:organisationId/:contactId/:invoiceId/retry-payment',
        bypassEmailVerifiedValidation: true,
        bypassPhoneNumberVerifiedValidation: true,
        componentProps: {
            action: 'invoice',
            isDebtorInvoicePaymentPage: true,
        },
        component: CreateOrder,
        header: InvoicePaymentHeader,
        slug: 'retryInvoicePayment',
        isPublic: true,
    },
    {
        path: '/invoices/payment/:organisationId/:contactId/:invoiceId/',
        bypassEmailVerifiedValidation: true,
        bypassPhoneNumberVerifiedValidation: true,
        componentProps: {
            action: 'invoice',
            isDebtorInvoicePaymentPage: true,
        },
        component: CreateOrder,
        header: InvoicePaymentHeader,
        slug: 'invoicePayment',
        isPublic: true,
    },
    {
        path: '/invoices/on-demand-campaign/history/:communicationLogHistoryId',
        component: OnDemandCampaignHistoryDetails,
        slug: 'OnDemandCampaignHistoryDetails',
        componentProps: {
            onDemandCampaign: true,
        },
    },
    {
        path: '/invoices/on-demand-campaign/history',
        component: OnDemandCampaignHistory,
        slug: 'communicationLogHistory',
        requiredPermission: INVOICE.ON_DEMAND_CAMPAIGN.HISTORY,
        componentProps: {
            onDemandCampaign: true,
        },
    },
    {
        path: '/invoices',
        component: Invoices,
        isPrivate: true,
        slug: 'invoices',
    },
    {
        path: '/invoice-orders',
        component: OrganisationOrders,
        isPrivate: true,
        slug: 'invoice-orders',
        requiredPermission: ORDER.INVOICE.LIST,
    },
    // {
    //     path: '/legal/:orderType/:orderId/retry-payment',
    //     componentProps: {
    //         action: 'legal-letter',
    //     },
    //     component: CreateOrder,
    //     isPrivate: true,
    //     slug: 'retryLegalLetter',
    // },
    // {
    //     path: '/legal',
    //     componentProps: {
    //         action: 'legal-letter',
    //     },
    //     component: CreateOrder,
    //     isPrivate: true,
    //     slug: 'legalLetter',
    // },
    {
        path: '/login',
        bypassEmailVerifiedValidation: true,
        bypassPhoneNumberVerifiedValidation: true,
        component: Login,
        exact: true,
        header: LoginHeader,
        slug: 'login',
        validators: [RouteValidators.isUserLoggedIn],
        layout: NoSidebarHomeLayout,
    },
    {
        path: '/my-account',
        component: MyAccount,
        slug: 'myAccount',
        requiredPermission: ACCOUNT_INFORMATION.VIEW,
        validators: [RouteValidators.isTokenExists],
        isPrivate: true,
    },
    {
        path: '/payment-information/payment-method/:paymentMethodId',
        component: PaymentMethodDetails,
        slug: 'paymentMethodDetails',
        requiredPermission: PAYMENT_METHOD.VIEW,
        validators: [RouteValidators.isTokenExists],
        isPrivate: true,
    },
    {
        path: '/payment-information',
        component: PaymentInformation,
        slug: 'paymentInformation',
        requiredPermission: ACCOUNT_INFORMATION.VIEW,
        validators: [RouteValidators.isTokenExists],
        isPrivate: true,
    },
    {
        path: '/my-profile',
        component: MyProfile,
        isPrivate: true,
        slug: 'myProfile',
    },
    {
        path: '/notifications',
        component: Notifications,
        isPrivate: true,
        slug: 'notifications',
    },
    {
        path: '/orders/order/:orderType/:orderId',
        component: OrderDetails,
        isPrivate: true,
        slug: 'orderDetails',
    },
    {
        path: '/billings',
        component: Orders,
        isPrivate: true,
        slug: 'billings',
    },
    {
        path: '/organisation/add',
        component: AddEditOrganisation,
        componentProps: {
            action: 'add',
        },
        requiredPermission: ORGANISATION.ADD,
        slug: 'addOrganisation',
    },
    {
        path: '/organisation/settings',
        component: OrganisationsSettings,
        isPrivate: true,
        slug: 'organisationSettings',
        requiredPermission: ORGANISATION.SETTINGS.EDIT,
    },
    {
        path: '/organisation/:organisationId',
        component: OrganisationDetails,
        isPrivate: true,
        requiredPermission: ORGANISATION.VIEW,
        slug: 'organisationDetails',
    },
    {
        path: '/organisations/:reauthorize',
        component: Organisations,
        isPrivate: true,
        slug: 'organisations',
    },
    {
        path: '/organisations',
        component: Organisations,
        isPrivate: true,
        requiredPermission: ORGANISATION.LIST,
        slug: 'organisations',
    },
    {
        path: '/page-not-found',
        component: PageNotFound,
        slug: 'pageNotFound',
        isPublic: true,
    },
    {
        path: '/payments',
        component: InvoicePayments,
        isPrivate: true,
        requiredPermission: INVOICE.PAYMENT.LIST,
        slug: 'payments',
    },
    {
        path: '/plan/:orderType/:orderId/retry-payment',
        componentProps: {
            action: 'subscription',
        },
        component: CreateOrder,
        isPrivate: true,
        slug: 'retryPayment',
        requiredPermission: ORDER.SUBSCRIPTION.ADD,
    },
    {
        path: '/plan/buy',
        componentProps: {
            action: 'subscription',
        },
        component: CreateOrder,
        isPrivate: true,
        slug: 'buyPlan',
        requiredPermission: ORDER.SUBSCRIPTION.ADD,
    },
    {
        path: '/plans',
        component: Plans,
        isPrivate: true,
        slug: 'plans',
        requiredPermission: ORDER.SUBSCRIPTION.ADD,
    },
    {
        path: '/reminders/followups',
        component: FollowUps,
        isPrivate: true,
        requiredPermission: FOLLOWUPS.LIST,
        slug: 'followups',
    },
    {
        path: '/reminders/:reminderId/history/:communicationLogHistoryId',
        component: Reminders,
        isPrivate: true,
        slug: 'reminder-communication-log',
    },
    {
        path: '/reminders/:reminderId/history',
        component: Reminders,
        isPrivate: true,
        requiredPermission: REMINDER.HISTORY,
        slug: 'reminders',
    },
    {
        path: '/reminders/:reminderId',
        component: Reminders,
        isPrivate: true,
        requiredPermission: REMINDER.SEND,
        slug: 'reminders',
    },
    {
        path: '/reminders',
        component: Reminders,
        isPrivate: true,
        requiredPermission: REMINDER.LIST,
        slug: 'reminders',
    },
    {
        path: '/request-quote',
        component: RequestQuote,
        isPrivate: true,
        slug: 'requestQuote',
    },
    {
        path: '/subscription',
        component: UserSubscription,
        isPrivate: true,
        requiredPermission: SUBSCRIPTION.LIST,
        slug: 'subscription',
    },
    {
        path: '/subscriptions/:subscriptionId',
        component: UserSubscriptionDetail,
        isPrivate: true,
        requiredPermission: SUBSCRIPTION.VIEW,
        slug: 'subscriptionDetails',
    },
    {
        path: '/unauthorized',
        component: Unauthorized,
        noHeader: true,
        slug: 'unauthorized',
        isPublic: true,
    },
    {
        path: '/under-maintenance',
        component: UnderMaintenance,
        noHeader: true,
        slug: 'underMaintenance',
        isPublic: true,
    },
    {
        path: '/user/registration-confirmation',
        component: ActivateAccount,
        header: AppHeader,
        slug: 'activateAccount',
        isPublic: true,
    },
    {
        path: '/user/reset-password',
        component: ResetPassword,
        header: AppHeader,
        isPublic: false,
        slug: 'resetPassword',
        validators: [],
        layout: NoSidebarHomeLayout,
    },
    {
        path: '/no-organisation',
        component: NoOrganisation,
        header: AppHeader,
        isPublic: false,
        slug: 'noOrganisation',
        layout: NoSidebarHomeLayout,
    },
    {
        path: '/payment-arrangements',
        component: PaymentArrangementsList,
        slug: 'paymentArrangements',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'arrangement', key: 'paymentArrangements' },
        requiredPermission: PAYMENT_ARRANGEMENT.LIST,
        exact: true,
    },
    {
        path: '/payment-service/:paymentServiceId',
        component: PaymentServiceDetails,
        slug: 'paymentServiceDetails',
        isPrivate: true,
        requiredPermission: PAYMENT_GATEWAY.VIEW,
        exact: true,
    },
    {
        path: '/payment-services',
        component: PaymentServices,
        slug: 'paymentServices',
        isPrivate: true,
        requiredPermission: PAYMENT_GATEWAY.LIST,
        exact: true,
    },
    {
        path: '/payment-service/connect/oauth/:paymentServiceSlug',
        component: PaymentServices,
        slug: 'paymentServiceOauthHandler',
        isPrivate: true,
    },
    {
        path: '/payment-arrangements/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'arrangement' },
        requiredPermission: PAYMENT_ARRANGEMENT.VIEW,
        exact: true,
    },
    {
        path: '/payment-arrangements/plan/:paymentPlanId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'arrangement' },
        requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/payment-arrangements/invoice/:contactId/:invoiceId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'arrangement' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/payment-arrangements/contact/:contactId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'arrangement' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/payment-extensions',
        component: PaymentArrangementsList,
        slug: 'paymentExtensions',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'extension', key: 'paymentExtensions' },
        requiredPermission: PAYMENT_EXTENSION.LIST,
        exact: true,
    },
    {
        path: '/payment-extensions/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'extension' },
        requiredPermission: PAYMENT_EXTENSION.VIEW,
        exact: true,
    },
    {
        path: '/payment-extensions/plan/:paymentPlanId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'extension' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/payment-extensions/invoice/:contactId/:invoiceId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'extension' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/payment-extensions/contact/:contactId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'extension' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/payment-plans',
        component: PaymentPlansList,
        slug: 'paymentPlans',
        isPrivate: true,
        exact: true,
    },
    {
        path: '/payment-plans/:planType/add',
        component: PaymentPlanAddEdit,
        componentProps: { action: 'add' },
        slug: 'paymentPlanAdd',
        isPrivate: true,
        exact: true,
    },
    {
        path: '/payment-plans/plan/:paymentPlanId/edit',
        component: PaymentPlanAddEdit,
        componentProps: { action: 'edit' },
        slug: 'paymentPlanEdit',
        isPrivate: true,
        exact: true,
    },
    {
        path: '/payment-plans/plan/:paymentPlanId',
        component: PaymentPlanDetails,
        slug: 'paymentPlan',
        isPrivate: true,
        exact: true,
    },
    {
        path: '/chart-of-accounts',
        component: ChartOfAccountsList,
        slug: 'chartOfAccounts',
        isPrivate: true,
        featureAvailableForManualOrganisationsOnly: true,
        requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/chart-of-accounts/add',
        component: ChartOfAccountAddEdit,
        componentProps: { action: 'add' },
        slug: 'chartOfAccountAdd',
        isPrivate: true,
        featureAvailableForManualOrganisationsOnly: true,
        requiredPermission: CHART_OF_ACCOUNT.ADD,
        exact: true,
    },
    {
        path: '/chart-of-accounts/chart-of-account/:chartOfAccountId/edit',
        component: ChartOfAccountAddEdit,
        componentProps: { action: 'edit' },
        slug: 'chartOfAccountEdit',
        isPrivate: true,
        featureAvailableForManualOrganisationsOnly: true,
        requiredPermission: CHART_OF_ACCOUNT.EDIT,
        exact: true,
    },
    {
        path: '/chart-of-accounts/chart-of-account/:chartOfAccountId',
        component: ChartOfAccountDetails,
        slug: 'chartOfAccount',
        isPrivate: true,
        featureAvailableForManualOrganisationsOnly: true,
        exact: true,
    },
    {
        path: '/debtor-accounts',
        component: DebtorAccountsList,
        slug: 'debtorAccounts',
        isPrivate: true,
        requiredPermission: DEBTOR_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/inventory',
        component: InventoryList,
        slug: 'inventory',
        isPrivate: true,
        featureAvailableForManualOrganisationsOnly: true,
        requiredPermission: INVENTORY.LIST,
        exact: true,
    },
    {
        path: '/inventory/add',
        component: InventoryItemAddEdit,
        componentProps: { action: 'add' },
        slug: 'inventoryItemAdd',
        isPrivate: true,
        featureAvailableForManualOrganisationsOnly: true,
        requiredPermission: INVENTORY.ADD,
        exact: true,
    },
    {
        path: '/inventory/inventory-item/:inventoryItemId/edit',
        component: InventoryItemAddEdit,
        componentProps: { action: 'edit' },
        slug: 'inventoryItemEdit',
        isPrivate: true,
        featureAvailableForManualOrganisationsOnly: true,
        requiredPermission: INVENTORY.EDIT,
        exact: true,
    },
    {
        path: '/inventory/inventory-item/:inventoryItemId',
        component: InventoryDetails,
        slug: 'inventoryItem',
        isPrivate: true,
        featureAvailableForManualOrganisationsOnly: true,
        requiredPermission: INVENTORY.VIEW,
        exact: true,
    },
    {
        path: '/reporting',
        component: ReportingExport,
        slug: 'reporting',
        exact: true,
        isPrivate: true,
        requiredPermission: REPORTING.LIST,
    },
    {
        path: '/taxes',
        component: TaxesList,
        slug: 'taxes',
        exact: true,
        isPrivate: true,
        featureAvailableForManualOrganisationsOnly: true,
        requiredPermission: TAX.LIST,
    },
    {
        path: '/taxes/add',
        component: TaxAddEdit,
        componentProps: { action: 'add' },
        slug: 'taxAdd',
        exact: true,
        requiredPermission: TAX.ADD,
        isPrivate: true,
        featureAvailableForManualOrganisationsOnly: true,
    },
    {
        path: '/taxes/tax/:taxId',
        component: TaxDetails,
        slug: 'tax',
        exact: true,
        isPrivate: true,
        featureAvailableForManualOrganisationsOnly: true,
        requiredPermission: TAX.VIEW,
    },
    {
        path: '/taxes/tax/:taxId/edit',
        component: TaxAddEdit,
        componentProps: { action: 'edit' },
        slug: 'taxEdit',
        exact: true,
        isPrivate: true,
        requiredPermission: TAX.EDIT,
        featureAvailableForManualOrganisationsOnly: true,
    },
    {
        path: '/zendesk-settings',
        component: ZendeskSettings,
        slug: 'zendeskSettings',
        exact: true,
        // requiredPermission: ZENDESK.SETTINGS.VIEW,
        isPrivate: true,
    },
    {
        path: '/zendesk-tickets',
        component: ZendeskTicketsList,
        slug: 'zendeskTickets',
        exact: true,
        requiredPermission: ZENDESK.TICKET.LIST,
        isPrivate: true,
    },
    {
        path: '/zendesk-tickets/zendesk-ticket/:ticketId',
        component: ZendeskTicketDetails,
        slug: 'zendeskTicketDetails',
        exact: true,
        requiredPermission: ZENDESK.TICKET.VIEW,
        isPrivate: true,
    },
    {
        path: '/logout',
        component: Logout,
        slug: 'logout',
        isPrivate: false,
        isPublic: true,
    },
    //this route object should be the last one in the route for page not found to work correctly
    //add all the other routes above this route object
    {
        path: '',
        component: defaultAppPageNotFound,
        isPublic: true,
        slug: 'pageNotFound',
    },
];

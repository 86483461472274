/**
 * App Title and Metas Component
 * Sets the title and meta tags for the page using react-helmet
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import the required modules
import { React, connect, withRouter } from '../../../includes/exports/react';
import { DEFAULT_TITLE_SEPARATOR } from '../../../includes/constants';
import { get } from 'lodash';
import { PropTypes } from 'prop-types';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';

/**
 * App Notification Component
 */
const AppTitleAndMetas = props => {
    const { t, routeSlug } = props;
    const pageTitleAndMetasKey = `titleAndMetas.${routeSlug}`;
    const pageTitle = t([`${pageTitleAndMetasKey}.title`, '']);
    const pageMetas = t([`${pageTitleAndMetasKey}.metas`, ''], { returnObjects: true });
    const siteTagline = get(props, 'site_tagline', '');

    /**
     * Render the title and meta tags to be added in header
     */
    return (
        <Helmet>
            <title>
                {pageTitle ? `${pageTitle} ${get(props, 'title_separator', DEFAULT_TITLE_SEPARATOR)} ` : ''}
                {get(props, 'site_title', props.t('titleAndMetas.defaultTitle'))}
                {siteTagline ? ` ${get(props, 'title_separator', DEFAULT_TITLE_SEPARATOR)} ${siteTagline}` : ''}
            </title>
            {pageMetas
                ? Object.keys(pageMetas).map(keyName => (
                      <meta key={keyName} property={keyName} content={get(pageMetas, [keyName, 'content'])} />
                  ))
                : ''}
        </Helmet>
    );
};

/**
 * Proptypes
 */
AppTitleAndMetas.propTypes = {
    routeSlug: PropTypes.string,
    t: PropTypes.func,
};

//Connect to store
export default withRouter(
    connect(
        state => ({
            ...state.settings.siteSettings,
        }),
        {}
    )(withTranslation()(AppTitleAndMetas))
);

/**
 * Accounts details component
 * Handles the accounts view and functions related to account details
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

// import required modules
import { React, connect, Link, PropTypes } from '../../../includes/exports/react';
import Button from '../../shared/Button';
import get from 'lodash/get';
import Loader from '../../shared/Loader';
import { Breadcrumb } from 'antd';
import { getAccountDetails } from '../../../includes/redux/actions/shared/account';
import { getDefaultImageClass, getImageFromData } from '../../../includes/utils';

/**
 * Account details component
 */
const AccountDetails = props => {
    const accountId = get(props.match.params, 'accountId', '') || props.currentAccountId;
    const accountLogo254Key = 'ACCOUNT_LOGO_254X254';
    const accountLogoOriginalKey = 'ACCOUNT_LOGO_ORIGINAL';
    const userAccountDetails = props.currentAccountId === accountId ? props.accountDetails : props.userAccountDetails;

    return (
        <div>
            <div className="tab-arrow-link breadcumb-spec">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/admin/accounts">Account</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Account Details</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <section className="white-box-wrapper box-wrapper full-wrapper">
                {props.isAccountDetailsLoading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="synced-name details-wrapper">
                            <section className="details-list left-align">
                                <div className="details-profile-pic-wrapper">
                                    <img
                                        src={getImageFromData(
                                            userAccountDetails,
                                            accountLogo254Key,
                                            accountLogoOriginalKey
                                        )}
                                        className={
                                            'profile-pic logo-placeholder ' +
                                            getDefaultImageClass(
                                                userAccountDetails,
                                                accountLogo254Key,
                                                accountLogoOriginalKey,
                                                '254x254'
                                            )
                                        }
                                        alt="profile-pic"
                                    />
                                </div>
                                <div className="center-align">
                                    <Button className="green-bg-button" big filled>
                                        <Link to={`/admin/accounts/edit/${accountId}`}>Edit</Link>
                                    </Button>
                                </div>
                            </section>
                            <section className="details-list right-align">
                                <div className="full-wrapper">
                                    <h3 className="profile-name">{get(userAccountDetails, 'name')}</h3>
                                </div>
                                <section className="detail-summary">
                                    <ul className="upper-right-detail">
                                        <li>
                                            <header>Paycepaid Id</header>
                                            <div>{get(userAccountDetails, 'unique_id')}</div>
                                        </li>
                                        <li>
                                            <header>Primary Contact</header>
                                            <div>{`${get(userAccountDetails, 'primary_contact_first_name')} ${get(
                                                userAccountDetails,
                                                'primary_contact_last_name'
                                            )}`}</div>
                                        </li>
                                        <li>
                                            <header>Primary Contact Email</header>
                                            <div>{`${get(userAccountDetails, 'primary_contact_email')}`}</div>
                                        </li>
                                        <li>
                                            <header>Primary Contact Phone Number</header>
                                            <div>{`${get(userAccountDetails, 'primary_contact_phone_number')}`}</div>
                                        </li>
                                        <li>
                                            <header>Postal Address</header>
                                            <div>{get(userAccountDetails, 'postal_address', 'N/A') || 'N/A'}</div>
                                        </li>
                                    </ul>
                                </section>
                            </section>
                        </div>
                    </>
                )}
            </section>
        </div>
    );
};

AccountDetails.propTypes = {
    accountDetails: PropTypes.object,
    accountId: PropTypes.string,
    currentAccountId: PropTypes.string,
    getAccountDetails: PropTypes.func,
    isAccountDetailsLoading: PropTypes.bool,
    match: PropTypes.object,
    organisations: PropTypes.array,
    userAccountDetails: PropTypes.object,
};

export default connect(
    state => ({
        accountDetails: state.account.accountDetails,
        currentAccountId: state.account.currentAccountId,
        isAccountDetailsLoading: state.account.isAccountDetailsLoading,
        userAccountDetails: state.account.userAccountDetails,
    }),
    {
        getAccountDetails,
    }
)(AccountDetails);

import { createSlice } from '@reduxjs/toolkit';
import { stopSubmit } from 'redux-form';
import get from 'lodash/get';

import { getPaginationData } from '../utils';
import { getApiErrors } from '../utils/api';
import * as successMappings from '../constants/mappings/success';
import * as constants from '../constants';
import * as actionTypes from '../constants/actionTypes';
import {
    CONTACT_ARCHIVE_DEFAULT_ERROR,
    CONTACT_DELETE_DEFAULT_ERROR,
    CONTACT_RESTORE_DEFAULT_ERROR,
    UNKNOWN_ERROR,
} from '../constants/messages/errors';
import { CONTACT_DELETE_ERROR, CONTACT_RESTORE_ERROR, CONTACT_TYPE_UPDATE_ERROR } from '../constants/mappings/errors';
import ContactApi from '../services/customer/contact';
import { isFunction } from 'lodash';
import { showApiErrors, showApiSuccess } from 'includes/utils/api';

const initialState = {
    isLoading: true,
    isContactTypeAddUpdateLoading: false,
    isContactTypeDeleteLoading: false,
    isChildContactsLoading: false,
    isContactTypesLoading: false,
    isDeletingContact: false,
    isDetailsLoading: false,
    isDetailsUpdating: false,
    isExportLoading: false,
    isRestoringContact: false,
    childContacts: [],
    contacts: [],
    contactTypes: [],
    contactDetails: {},
    paginationContacts: {},
    paginationContactTypes: {},
    detailsUpdatedMessage: '',
};

const contactSlice = createSlice({
    name: 'contactsSlice',
    initialState,
    reducers: {
        setIsLoadingStart(state) {
            state.isLoading = true;
            state.message = '';
            state.contacts = [];
            state.paginationContacts = {};
        },
        setIsLoadingEnd(state) {
            state.isLoading = false;
        },
        setContacts(state, action) {
            state.contacts = get(action.payload, successMappings.GET_CONTACTS);
            state.message = get(action.payload, successMappings.API_RESPONSE_MESSAGE_STRUCTURE);
            state.paginationContacts = getPaginationData(action, true);
        },
        setDetailsLoadingStart(state) {
            state.isDetailsLoading = true;
        },
        setDetailsLoadingEnd(state) {
            state.isDetailsLoading = false;
        },
        setApplyContactTypeLoadingStart(state) {
            state.isLoading = true;
        },
        setApplyContactTypeLoadingEnd(state) {
            state.isLoading = false;
        },
        setDeletingContactLoadingStart(state) {
            state.isDeletingContact = true;
        },
        setDeletingContactLoadingEnd(state) {
            state.isDeletingContact = false;
        },
        contactDeleteSuccess(state, action) {
            state.contacts = get(action.payload, successMappings.GET_CONTACTS);
            state.paginationContacts = getPaginationData(action, true);
            state.isLoading = false;
        },
        setIsChildContactsLoadingStart(state) {
            state.isChildContactsLoading = true;
        },
        setIsChildContactsLoadingEnd(state) {
            state.isChildContactsLoading = false;
        },
        setChildContacts(state, action) {
            state.childContacts = action.payload;
        },
        setIsContactTypeAddUpdateLoadingStart(state) {
            state.isContactTypeAddUpdateLoading = true;
        },
        setIsContactTypeAddUpdateLoadingEnd(state) {
            state.isContactTypeAddUpdateLoading = false;
        },
        setIsContactTypeDeleteLoadingStart(state) {
            state.isContactTypeDeleteLoading = true;
        },
        setIsContactTypeDeleteLoadingEnd(state) {
            state.isContactTypeDeleteLoading = false;
        },
        setIsExportLoadingStart(state) {
            state.isExportLoading = true;
        },
        setIsExportLoadingEnd(state) {
            state.isExportLoading = false;
        },
        setIsDetailsUpdatingStart(state) {
            state.isDetailsUpdating = true;
        },
        setIsDetailsUpdatingEnd(state) {
            state.isDetailsUpdating = false;
        },
        setContactDetails(state, action) {
            state.contactDetails = action.payload;
        },
        setIsContactTypesLoadingStart(state) {
            state.isContactTypesLoading = true;
        },
        setIsContactTypesLoadingEnd(state) {
            state.isContactTypesLoading = false;
        },
        setContactTypes(state, action) {
            state.contactTypes = get(action.payload, successMappings.GET_CONTACT_TYPES, []);
            state.paginationContactsTypes = getPaginationData(action, true);
        },
        setIsRestoreContactLoadingStart(state) {
            state.isRestoringContact = true;
        },
        setIsRestoreContactLoadingEnd(state) {
            state.isRestoringContact = false;
        },
    },
});

export const {
    setIsLoadingStart,
    setIsLoadingEnd,
    setDetailsLoadingStart,
    setDetailsLoadingEnd,
    setApplyContactTypeLoadingStart,
    setApplyContactTypeLoadingEnd,
    setDeletingContactLoadingStart,
    setDeletingContactLoadingEnd,
    contactDeleteSuccess,
    setIsExportLoadingStart,
    setIsExportLoadingEnd,
    setIsDetailsUpdatingStart,
    setIsDetailsUpdatingEnd,
    setContactDetails,
    setContacts,
    setIsContactTypesLoadingStart,
    setIsContactTypesLoadingEnd,
    setContactTypes,
    setIsRestoreContactLoadingStart,
    setIsRestoreContactLoadingEnd,
    setIsChildContactsLoadingStart,
    setIsChildContactsLoadingEnd,
    setChildContacts,
    setIsContactTypeAddUpdateLoadingStart,
    setIsContactTypeAddUpdateLoadingEnd,
    setIsContactTypeDeleteLoadingStart,
    setIsContactTypeDeleteLoadingEnd,
} = contactSlice.actions;

/**
 * Add a contact
 *
 * @param {object} contactDetails Contact Details
 * @param {string} organisationId Contact Details
 * @param {Function} callback Callback function
 */
export const addContact = (contactDetails, organisationId, callback = () => {}) => async dispatch => {
    try {
        dispatch(setIsDetailsUpdatingStart());
        await ContactApi.addContact({ contactDetails, organisation_id: organisationId });
        callback();
    } catch (errors) {
        dispatch(
            stopSubmit(
                constants.EDIT_CONTACT_FORM_NAME,
                getApiErrors(errors, constants.CONTACT_ADD_DEFAULT_ERROR, constants.CONTACT_ADD_DEFAULT_ERROR)
            )
        );
    } finally {
        dispatch(setIsDetailsUpdatingEnd());
    }
};

/**
 * Add a contact type
 *
 * @param {string} organisationId Organisation id
 * @param {object} payload Payload
 * @param {Function} callback Callback function
 */
export const addContactType = (organisationId, payload, callback = () => {}) => async dispatch => {
    try {
        dispatch(setIsContactTypeAddUpdateLoadingStart());
        const result = await ContactApi.addContactType(organisationId, payload);
        dispatch(setContactTypes(result));
        if (callback && isFunction(callback)) {
            callback();
        }
        dispatch(showApiSuccess(result));
    } catch (errors) {
        dispatch(showApiErrors(errors, 'CONTACT_TYPE_FORM_NAME', 'CONTACT_TYPES'));
    } finally {
        dispatch(setIsContactTypeAddUpdateLoadingEnd());
    }
};

/**
 * Apply contact type on a contact or contacts
 *
 * @param {string} organisationId Organisation Id
 * @param {Array} contacts Contacts
 * @param {Function} callback Callback function
 */
export const applyContactType = (organisationId, contacts, callback) => async dispatch => {
    try {
        dispatch(setApplyContactTypeLoadingStart());
        const response = await ContactApi.applyContactType({ organisation_id: organisationId, contacts });
        dispatch(setApplyContactTypeLoadingEnd(get(response, successMappings.API_RESPONSE_MESSAGE_STRUCTURE)));
        dispatch(setContacts(response));
        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: get(response, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
            messageType: constants.SUCCESS,
        });
        callback();
    } catch (errors) {
        dispatch(setApplyContactTypeLoadingEnd(''));
        const contactTypeUpdateErrors = get(errors, CONTACT_TYPE_UPDATE_ERROR);
        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: contactTypeUpdateErrors || UNKNOWN_ERROR,
            messageType: constants.ERROR,
        });
    }
};

/**
 * Delete contact action
 *
 * @param {string} organisationId Organisation Id
 * @param {string} contactId Contact Id
 * @param {boolean} forceDelete Whether to force delete or not
 */
export const deleteContact = (organisationId, contactId, forceDelete = false) => async dispatch => {
    try {
        dispatch(setDeletingContactLoadingStart());
        const result = await ContactApi.deleteContact({
            contact_id: contactId,
            organisation_id: organisationId,
            force_delete: forceDelete,
        });
        dispatch(contactDeleteSuccess(result));

        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
            messageType: constants.SUCCESS,
        });
    } catch (errors) {
        const errorMessage = get(
            errors,
            CONTACT_DELETE_ERROR,
            forceDelete ? CONTACT_DELETE_DEFAULT_ERROR : CONTACT_ARCHIVE_DEFAULT_ERROR
        );
        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: errorMessage,
            messageType: constants.ERROR,
        });
    } finally {
        dispatch(setDeletingContactLoadingEnd());
    }
};

/**
 * Delete contact type
 *
 * @param {string} organisationId Organisation id
 * @param {string} contactTypeId Contact type id
 * @param {Function} callback Callback function
 */
export const deleteContactType = (organisationId, contactTypeId, callback = () => {}) => async dispatch => {
    try {
        dispatch(setIsContactTypeDeleteLoadingStart());
        const result = await ContactApi.deleteContactType(organisationId, contactTypeId);
        if (callback && isFunction(callback)) {
            callback();
        }
        dispatch(setContactTypes(result));
        dispatch(showApiSuccess(result));
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'CONTACT_TYPES'));
    } finally {
        dispatch(setIsContactTypeDeleteLoadingEnd());
    }
};

/**
 * Export contacts action
 *
 * @param {string}  organisationId Organisation Id
 * @param {object}  params Params
 * @param {Array} contactIds Contact Ids
 */
export const exportContacts = (organisationId, params, contactIds = []) => async dispatch => {
    try {
        dispatch(setIsExportLoadingStart());
        const result = await ContactApi.exportContacts({ organisationId, params, contactIds });
        dispatch(setIsExportLoadingEnd());

        // open the url
        window.open(get(result, successMappings.EXPORT_FILE, ''), '_blank');

        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
            messageType: constants.SUCCESS,
        });
    } catch (errors) {
        dispatch(setIsExportLoadingEnd());
    }
};

/**
 * Get child contacts of contact manager action
 *
 * @param {object} organisationId Organisation ID
 * @param {object} contactManagerId Contact Manager ID
 * @param {object} searchParams Search Params
 *
 * @returns {object} Get child contacts action
 */
export const getChildContacts = (organisationId, contactManagerId, searchParams) => async dispatch => {
    try {
        dispatch(setIsChildContactsLoadingStart());
        const result = await ContactApi.getContacts({
            organisation_id: organisationId,
            searchParams: { parent_contact_id: contactManagerId, search_param: searchParams },
        });
        dispatch(setChildContacts(get(result, successMappings.GET_CONTACTS, [])));
    } finally {
        dispatch(setIsChildContactsLoadingEnd());
    }
};

/**
 * Get contact details Action
 *
 * @param {string} organisationId Organisation ID
 * @param {string} contactId Contact ID
 */
export const getContactDetails = (organisationId, contactId) => async dispatch => {
    try {
        dispatch(setDetailsLoadingStart());
        const result = await ContactApi.getContactDetails({
            contact_id: contactId,
            organisation_id: organisationId,
        });
        dispatch(setContactDetails(get(result, successMappings.GET_CONTACT_DETAILS)));
    } catch (errors) {
        // dispatch({
        //     type: actionTypes.SHOW_NOTIFICATION_MODAL,
        //     message: UNKNOWN_ERROR,
        //     messageType: constants.ERROR,
        // });
    } finally {
        dispatch(setDetailsLoadingEnd());
    }
};

/**
 * Get contacts Action
 *
 * @param {string} organisationId Organisation ID
 * @param {object} searchParams Search Params
 */
export const getContacts = (organisationId, searchParams = {}) => async dispatch => {
    try {
        dispatch(setIsLoadingStart());
        const result = await ContactApi.getContacts({ organisation_id: organisationId, searchParams });
        dispatch(setContacts(result));
        dispatch(setIsLoadingEnd());
    } catch (errors) {
        dispatch(setIsLoadingEnd());
    }
};

/**
 * Get contact type
 *
 * @param {string} organisationId Organisation ID
 * @param {object} searchParams Search Params
 */
export const getContactTypes = (organisationId, searchParams = {}) => async dispatch => {
    try {
        dispatch(setIsContactTypesLoadingStart());
        const result = await ContactApi.getContactTypes(organisationId, searchParams);
        dispatch(setContactTypes(result));
    } finally {
        dispatch(setIsContactTypesLoadingEnd());
    }
};

/**
 * Remove contacts loading Action
 *
 */
export const removeContactsLoading = () => dispatch => {
    dispatch(setIsLoadingEnd());
};

/**
 * Restore contact action
 *
 * @param {string} contactId Contact Id
 * @param {string} organisationId Organisation Id
 *
 * @returns {object} Contact update action
 */
export const restoreContact = (organisationId, contactId) => async dispatch => {
    try {
        dispatch(setIsRestoreContactLoadingStart());
        const result = await ContactApi.restoreContact({
            contact_id: contactId,
            organisation_id: organisationId,
            restore_contact: true,
        });
        dispatch(setContacts(result));

        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
            messageType: constants.SUCCESS,
        });
    } catch (errors) {
        const errorMessage = get(errors, CONTACT_RESTORE_ERROR, CONTACT_RESTORE_DEFAULT_ERROR);
        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: errorMessage,
            messageType: constants.ERROR,
        });
    } finally {
        dispatch(setIsRestoreContactLoadingEnd());
    }
};

/**
 * Update contact details action
 *
 * @param {object} contactDetails Input Data Object
 * @param {string} contactId Contact Id
 * @param {string} organisationId Organisation Id
 * @param {Function} callback Callback function
 *
 * @returns {object} Contact update action
 */
export const updateContactDetails = (
    contactDetails,
    contactId,
    organisationId,
    callback = () => {}
) => async dispatch => {
    try {
        dispatch(setIsDetailsUpdatingStart());
        const result = await ContactApi.updateContactDetails({
            contactDetails,
            contact_id: contactId,
            organisation_id: organisationId,
        });
        dispatch({
            type: actionTypes.SHOW_NOTIFICATION_MODAL,
            message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
            messageType: constants.SUCCESS,
        });
        callback();
    } catch (errors) {
        const apiErrors = getApiErrors(
            errors,
            constants.CONTACT_EDIT_DEFAULT_ERROR,
            constants.CONTACT_EDIT_DEFAULT_ERROR
        );
        const contactPersonsNonFieldError = get(apiErrors, 'contact_persons.non_field_error');
        if (contactPersonsNonFieldError) {
            dispatch({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: contactPersonsNonFieldError,
                messageType: constants.ERROR,
            });
        }
        dispatch(stopSubmit(constants.EDIT_CONTACT_FORM_NAME, apiErrors));
    } finally {
        dispatch(setIsDetailsUpdatingEnd());
    }
};

/**
 * Update contact type
 *
 * @param {string} organisationId Organisation id
 * @param {string} contactTypeId Contact type id
 * @param {object} payload Payload
 * @param {Function} callback Callback function
 */
export const updateContactType = (organisationId, contactTypeId, payload, callback = () => {}) => async dispatch => {
    try {
        dispatch(setIsContactTypeAddUpdateLoadingStart());
        const result = await ContactApi.updateContactType(organisationId, contactTypeId, payload);
        dispatch(setContactTypes(result));
        if (callback && isFunction(callback)) {
            callback();
        }
        dispatch(showApiSuccess(result));
    } catch (errors) {
        dispatch(showApiErrors(errors, 'CONTACT_TYPE_FORM_NAME', 'CONTACT_TYPES'));
    } finally {
        dispatch(setIsContactTypeAddUpdateLoadingEnd());
    }
};

export default contactSlice.reducer;

import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Default tag/chip component
 *
 * Renders a tag/chip to indicate that the payment method/bank account is set as default
 *
 * @since 2.8.0
 */
export default function DefaultLabel() {
    const { t } = useTranslation();
    return (
        <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2 rounded dark:bg-green-900 dark:text-green-300">
            {t('customerMessages.paymentMethod.info.default')}
        </span>
    );
}

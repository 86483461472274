import React from 'react';

import OrganisationPaymentGateways from 'components/PaymentGateways/components/OrganisationPaymentGateways';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Breadcrumb } from 'antd';
import AccountsBreadCrumbItems from 'components/BreadCrumbItems/Accounts';
import OrganisationsBreadCrumbItems from 'components/BreadCrumbItems/Organisations';
import { redirect } from 'includes/utils';
import useAccountId from 'includes/hooks/useAccountId';

/**
 * Account organisation payment gateways
 *
 * Renders an account's organisation's payment gateways
 *
 * @since 2.8.0
 */
export default function AccountOrganisationPaymentGateways() {
    const organisationId = useOrganisationId(true);

    const accountId = useAccountId(true);

    /**
     * Handles payment service ID
     *
     * @param {string} paymentServiceId Payment service ID
     */
    const handlePaymentServiceView = paymentServiceId => {
        redirect(`/admin/accounts/organisation/payment-gateways/${accountId}/${organisationId}/${paymentServiceId}`);
    };

    return (
        <div className="mt-5">
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <AccountsBreadCrumbItems showListBreadCrumb />
                    <OrganisationsBreadCrumbItems showListBreadCrumb />
                    <Breadcrumb.Item>Payment gateways</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <OrganisationPaymentGateways handleView={handlePaymentServiceView} organisationId={organisationId} />
        </div>
    );
}

/**
 * Redux login action
 * These are the Xero related actions
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import action types
import {
    CLEAR_CONNECT_STATE,
    GET_ACCOUNTING_SOFTWARE_AUTH_LINK_REQUEST,
    VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_REQUEST,
} from '../../../constants/actionTypes';

/**
 * Clear connect state Action
 *
 * @returns {object} Clear connect state action
 */
export const clearConnectState = () => ({
    type: CLEAR_CONNECT_STATE,
});

/**
 * Get accounting software authenticate URL Action
 *
 * @returns {object}      Get Accounting software auth link action
 */
export const getAccountingSoftwareAuthLink = payload => ({
    type: GET_ACCOUNTING_SOFTWARE_AUTH_LINK_REQUEST,
    payload,
});

/**
 * Send the accounting software token to the server
 */
export const verifyAccountingSoftwareAuthorization = payload => ({
    type: VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_REQUEST,
    payload,
});

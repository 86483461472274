/**
 * Success Message Component
 * Handles the success view
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modules
import { React } from '../../../includes/exports/react';
import PropTypes from 'prop-types';

/**
 * Success Message Component
 */
const SuccessMessage = props => {
    /**
     * Render the login form
     *
     * @returns {*}
     */
    return (
        <div className="ant-form-explain success-message-wrapper">
            <img src={require('../../../assets/images/tick-icon.svg')} alt="tick-icon" className="inline" />
            {props.message}
        </div>
    );
};

/**
 * Prop Types
 */
SuccessMessage.propTypes = {
    message: PropTypes.string,
};

export default SuccessMessage;

/**
 * Defines default constants used in the app
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */
//import translation module to translate error messages based on language selection
import i18n from '../i18n';
import BankMandateSVG from 'assets/images/bank_mandate_icon.svg';
import CreditCardIconSVG from 'assets/images/credit_card_icon.svg';

// _
export const _ERROR = '_error';

// A
export const ACCESS_TOKEN = 'accessToken';
export const ACCEPTED_IMAGE_MIME_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];
export const ACCEPTED_IMAGE_TYPES = ['.jpg', '.jpeg', '.png'];
export const ACCOUNT_EDIT_DEFAULT_ERROR = 'ACCOUNT_EDIT_DEFAULT_ERROR';
export const ACCOUNT_ID_PLACEHOLDER = '%account_id%';
export const ACCOUNTING_SOFTWARE_OTHER = 'other';
export const ACCOUNTING_SOFTWARE_OTHER_LABEL = 'Other';
export const ACCOUNTING_SOFTWARE_OTHER_VALUE = 'other';
export const ACTIVATE_ACCOUNT_FORM = 'activate_account_form';
export const ACTIVATE_USER_DEFAULT_ERROR = 'ACTIVATE_USER_DEFAULT_ERROR';
export const ADD_ACCOUNT_FORM = 'ADD_ACCOUNT_FORM';
export const ADD_CAMPAIGN_DEFAULT_ERROR = 'ADD_CAMPAIGN_DEFAULT_ERROR';
export const ADD_CARD_ERROR_MESSAGE = 'ADD_CARD_ERROR_MESSAGE';
export const ADD_EDIT_PLACEHOLDER_FORM = 'Add_edit_placeholder_form';
export const ADD_EDIT_PLAN_FORM = 'add_edit_plan_form';
export const ADD_EDIT_SCHEDULER_FORM_NAME = 'add_edit_scheduler_form';
export const ADD_EDIT_TEMPLATE_FORM = 'add_edit_template_form';
export const ADD_EDIT_USER_FORM = 'Add_edit_user_form';
export const ADD_PLACEHOLDER_DEFAULT_ERROR = 'ADD_PLACEHOLDER_DEFAULT_ERROR';
export const ADD_TEMPLATE_DEFAULT_ERROR = 'ADD_TEMPLATE_DEFAULT_ERROR';
export const ADDITIONAL_TRANSACTION_CHARGES = 'additional_transaction_charges';
export const ADDITIONAL_CHARGE_APPLY_FOR_OPTIONS = [
    {
        value: 'domestic',
        label: 'Domestic Transactions',
    },
    { value: 'international', label: 'International Transactions' },
];
export const AUDIT_LOG_TYPES = [
    { value: 'campaign', label: 'Campaign' },
    { value: 'currency', label: 'Currency' },
    { value: 'chartofaccount', label: 'Chart of Account' },
    { value: 'contact', label: 'Contact' },
    { value: 'contacttype', label: 'Contact Type' },
    { value: 'inventory', label: 'Inventory' },
    { value: 'invoice', label: 'Invoice' },
    { value: 'organisation', label: 'Organisation' },
    { value: 'paymentarrangement', label: 'Payment Arrangement' },
    { value: 'paymentgateway', label: 'Payment Gateway' },
    { value: 'paymentplan', label: 'Payment Plan' },
    { value: 'tax', label: 'Tax' },
];
export const AUDIT_LOG_TYPES_ACCOUNT = [{ value: 'account', label: 'Account' }, ...AUDIT_LOG_TYPES];

// B
export const BANK_ACCOUNT_DETAILS_UPDATE_DEFAULT_ERROR = 'BANK_ACCOUNT_DETAILS_UPDATE_DEFAULT_ERROR';
export const BANK_ACCOUNT_FORM_NAME = 'bank_account_form';
export const BUSINESS_FORM_NAME = 'business_form';

// C
export const CAMPAIGN_FORM_NAME = 'campaign_form';
export const CAMPAIGN_NOTIFICATION_FORM_NAME = 'campaign_notification_form';
export const CAMPAIGN_TYPE_BROKEN = 2;
export const CAMPAIGN_TYPE_REMINDER = 1;
export const CACHE_ACCOUNT_USERS_KEY = 'accountUsers';
export const CACHE_CAMPAIGN_NOTIFICATION_DAYS_KEY = 'campaignNotificationDays';
export const CACHE_CAMPAIGNS_KEY = 'campaigns';
export const CACHE_CHART_OF_ACCOUNTS_KEY = 'chartOfAccounts';
export const CACHE_CHART_OF_ACCOUNTS_PURCHASE_KEY = 'purchaseChartOfAccounts';
export const CACHE_CHART_OF_ACCOUNTS_SALES_KEY = 'salesChartOfAccounts';
export const CACHE_CHART_OF_ACCOUNT_TYPES_KEY = 'chartOfAccountTypes';
export const CACHE_COMMUNICATION_TEMPLATES_KEY = 'communicationTemplates';
export const CACHE_COMMUNICATION_TEMPLATE_TYPES_KEY = 'communicationTemplateTypes';
export const CACHE_COMMUNICATION_TYPES_KEY = 'communicationTypes';
export const CACHE_CONTACT_TYPES_KEY = 'contactTypes';
export const CACHE_COUNTRIES_KEY = 'countries';
export const CACHE_ALL_COUNTRIES_KEY = 'countries_all';
export const CACHE_CURRENCIES_KEY = 'currencies';
export const CACHE_ORGANISATION_CURRENCIES_KEY = 'organisation_currencies';
export const CACHE_PAYMENT_CHART_OF_ACCOUNTS_KEY = 'paymentChartOfAccounts';
export const CACHE_PAYMENT_METHOD_STATUSES = 'paymentMethodStatuses';
export const CACHE_PAYMENT_METHOD_TYPES_KEY = 'paymentMethodTypes';
export const CACHE_PAYMENT_METHOD_SCHEMES = 'paymentMethodSchemes';
export const CACHE_INTERNAL_PAYMENT_GATEWAYS_KEY = 'internalPaymentGateways';
export const CACHE_INVOICE_STATUSES_KEY = 'invoiceStatuses';
export const CACHE_INVOICE_TYPES_KEY = 'invoiceTypes';
export const CACHE_NOTE_STATUSES_KEY = 'noteStatuses';
export const CACHE_NOTE_TYPES_KEY = 'noteTypes';
export const CACHE_SUBSCRIPTION_PLANS_KEY = 'subscriptionPlans';
export const CACHE_TAXES_KEY = 'taxes';
export const CACHE_TAX_TYPES_KEY = 'taxTypes';
export const CACHE_ZENDESK_SETTINGS_KEY = 'zendesk_settings';
export const CACHE_ZENDESK_TICKET_OPTIONS_KEY = 'zendesk_ticket_options';
export const CAMPAIGN_BROKEN_CAMPAIGN_TYPE_OPTION = {
    id: 2,
    label: 'Broken arrangement campaign',
};
export const CAMPAIGN_REMINDER_CAMPAIGN_TYPE_OPTION = {
    id: 1,
    label: 'Reminder campaign',
};
export const CAMPAIGN_TYPE_OPTIONS = [CAMPAIGN_REMINDER_CAMPAIGN_TYPE_OPTION, CAMPAIGN_BROKEN_CAMPAIGN_TYPE_OPTION];
export const CARD_EDIT_FORM_NAME = 'edit-card-form';
export const CARD_NAME_MAPPING = {
    visa: 'Visa',
    mastercard: 'MasterCard',
    amex: 'AmericanExpress',
    discover: 'Discover',
    diners: 'DinersClub',
    jcb: 'JCB',
    unionpay: 'UnionPay',
    unknown: '',
};
export const CARD_UPDATE_DEFAULT_ERROR = 'CARD_UPDATE_DEFAULT_ERROR';
export const CHANGE_PASSWORD_FORM_NAME = 'change_password_form';
export const CHANGE_USER_STATUS_DEFAULT_ERROR = 'CHANGE_USER_STATUS_DEFAULT_ERROR';
export const CHART_OF_ACCOUNT_FORM = 'chart_of_account_form';
export const COMMUNICATION_TEMPLATE_TYPE_INVOICE_TEMPLATE_TYPE = 2;
export const COMMUNICATION_TEMPLATE_TYPE_PAYMENT_ARRANGEMENT_TEMPLATE_TYPE = 3;
export const COMMUNICATION_TEMPLATE_TYPE_FOLLOW_UP_TEMPLATE_TYPE = 4;
export const CONFIRM = 'confirm';
export const CONTACT_ADD_DEFAULT_ERROR = 'CONTACT_ADD_DEFAULT_ERROR';
export const CONTACT_EDIT_DEFAULT_ERROR = 'CONTACT_EDIT_DEFAULT_ERROR';
export const CONTACT_TYPE_FORM_NAME = 'contact_type_form';
export const CREATE_ORDER_DEFAULT_ERROR = 'CREATE_ORDER_DEFAULT_ERROR';
export const CREATE_SUBSCRIPTION_DEFAULT_ERROR = 'CREATE_SUBSCRIPTION_DEFAULT_ERROR';
export const CREATE_SUBSCRIPTION_FORM = 'create_subscription_form';
export const CUSTOM = 'custom';
export const CUSTOM_TEMPLATE = 'custom-template';
export const CUSTOMER_ACCOUNT_TYPE_SLUG = 'customer-account';

// D
export const DEACTIVATE_USER_DEFAULT_ERROR = 'DEACTIVATE_USER_DEFAULT_ERROR';
export const DEBTOR_ACCOUNT_TYPE_SLUG = 'debtor-account';
export const DEFAULT_API_ERROR_RESPONSE_STRUCTURE = 'DEFAULT_API_ERROR_RESPONSE_STRUCTURE';
export const DEFAULT_APP_MODAL_CANCEL_TEXT = 'Cancel';
export const DEFAULT_APP_MODAL_OK_TEXT = 'OK';
export const DEFAULT_APP_NOTIFICATION_DURATION = 4;
export const DEFAULT_APP_NOTIFICATION_MESSAGE_TYPE = 'notification';
export const DEFAULT_APP_NOTIFICATION_TITLE = i18n.t('appCommonMessages.defaultAppNotificationTitle');
export const DEFAULT_APP_NOTIFICATION_TYPE = 'info';
export const DEFAULT_CURRENCY_CODE = 'AUD';
export const DEFAULT_SETTINGS_FORM = 'default_settings_form';
export const DEFAULT_TITLE_SEPARATOR = '-';
export const DELETE_CARD_ERROR_MESSAGE = 'DELETE_CARD_ERROR_MESSAGE';
export const DELETE_PLACEHOLDER_DEFAULT_ERROR = 'DELETE_PLACEHOLDER_DEFAULT_ERROR';
export const DELETE_TEMPLATE_DEFAULT_ERROR = 'DELETE_TEMPLATE_DEFAULT_ERROR';
export const DOLLAR_SYMBOL = '$';
export const DUE_DATE_MONTH_OPTIONS = [
    {
        id: 'following_month',
        label: 'of the following month',
    },
    {
        id: 'after_invoice_date',
        label: 'day(s) after invoice date',
    },
    {
        id: 'after_invoice_month_end',
        label: 'day(s) after the end of the invoice month',
    },
    {
        id: 'current_month',
        label: 'of the current month',
    },
];

// E
export const EDIT_ACCOUNT_FORM_NAME = 'edit_account_form';
export const EDIT_CALENDAR_EVENT_FORM_NAME = 'edit-calendar_event_form';
export const EDIT_CONTACT_FORM_NAME = 'edit_contact_form';
export const EDIT_ORDER_FORM_NAME = 'edit_order_form';
export const EDIT_ORGANISATION_FORM_NAME = 'edit_organisation_form';
export const EDIT_PAYMENT_HISTORY_FORM_NAME = 'edit_payment_history_form';
export const EDIT_SETTINGS_FORM = 'edit_settings_form';
export const EDIT_USER_FORM_NAME = 'edit_user_form';
export const EMAIL_TEMPLATE_FORM = 'email_template_form';
export const ERROR = 'error';
export const EVENT_CALENDAR_FORM = 'event_calendar_form';

// F
export const FIELDS = 'fields';
export const FORGOT_PASSWORD_FORM_NAME = 'forgot_password_form';
export const FORM = 'form';
export const FAT_ZEBRA_CARD_FORM = 'fat_zebra_card_form';
export const FAT_ZEBRA_VERIFICATION_TOKEN_ERROR_MESSAGE = 'FAT_ZEBRA_VERIFICATION_TOKEN_ERROR_MESSAGE';

// G
export const getDiscountTypeOptions = (currencySymbol = '') => [
    {
        id: 1,
        label: 'Percentage (%)',
    },
    {
        id: 2,
        label: `Amount ${currencySymbol ? `(${currencySymbol})` : ''}`,
    },
];
export const GET_TEMPLATE_DEFAULT_ERROR = 'GET_TEMPLATE_DEFAULT_ERROR';
export const GO_CARDLESS_ENVIRONMENT = process.env.NODE_ENV === 'production' ? 'live' : 'sandbox';
export const GO_CARDLESS_MANDATE_ADD_FORM = 'GOCARDLESS_MANDATE_ADD_FORM';

// I
export const IMAGE_254x254 = '254x254';
export const IMAGE_45x45 = '45x45';
export const IMAGE_ORIGINAL = 'original';
export const IMPORT_TYPE_CONTACT_MANAGERS = 'contact-managers';
export const IMPORT_TYPE_CONTACTS = 'contacts';
export const IMPORT_TYPE_INVOICE_PAYMENTS = 'invoice-payments';
export const IMPORT_TYPE_INVOICES = 'invoices';
export const INVALID_FIELD_ALPHANUMERIC_ERROR_KEY = 'alphanumeric';
export const INVALID_FIELD_ALPHANUMERIC_HYPHEN_UNDERSCORE_ERROR_KEY = 'alphanumeric_hyphen_underscore';
export const INVALID_FIELD_ALPHANUMERIC_LOWER_CASE_UNDERSCORE_ERROR_KEY = 'alphanumeric_lowercase_underscore';
export const INVALID_FIELD_ERROR_KEY = 'invalid';
export const INVALID_FIELD_MAX_FILES_ERROR_KEY = 'max_files';
export const INVALID_FIELD_MAX_LENGTH_ERROR_KEY = 'max_length';
export const INVALID_FIELD_MAX_VALUE_ERROR_KEY = 'max_value';
export const INVALID_FIELD_MIN_FILES_ERROR_KEY = 'min_files';
export const INVALID_FIELD_MIN_LENGTH_ERROR_KEY = 'min_length';
export const INVALID_FIELD_MIN_MAX_ERROR_KEY = 'min_max';
export const INVALID_FIELD_MIN_VALUE_ERROR_KEY = 'min_value';
export const INVALID_FIELD_NUMBER_ERROR_KEY = 'not_number';
export const INVALID_FIELD_PASSWORD_DO_NOT_MATCH_ERROR_KEY = 'do_not_match';
export const INVALID_FIELD_PASSWORD_ERROR_KEY = 'password';
export const INVALID_FIELD_PASSWORD_LOWER_CASE_ERROR_KEY = 'lower_case';
export const INVALID_FIELD_PASSWORD_NUMBER_ERROR_KEY = 'number';
export const INVALID_FIELD_PASSWORD_SPECIAL_CHARACTER_ERROR_KEY = 'special_character';
export const INVALID_FIELD_PASSWORD_UPPER_CASE_ERROR_KEY = 'upper_case';
export const INVALID_ONLY_ALPHANUMERIC_ALLOWED = 'only_alphanumeric';
export const INVALID_PLACEHOLDER_KEY = 'placeholder_key';
export const INVALID_SUBDOMAIN_CANNOT_BE = 'subdomain_cannot_be';
export const INVENTORY_FORM_NAME = 'inventory_form';
export const INVOICE_ADD_DEFAULT_ERROR = 'INVOICE_ADD_DEFAULT_ERROR';
export const INVOICE_FORM_NAME = 'invoice_form';
export const INVOICE_LINE_ITEM_FORM = 'invoice_line_item_form';
export const INVOICE_LINE_ITEM_TAX_TYPE_OPTIONS = [
    { name: 'Amount', value: 1 },
    { name: 'Predefined List', value: 2 },
];
export const INVOICE_PAYMENT_DEFAULT_ERROR = 'INVOICE_PAYMENT_DEFAULT_ERROR';
export const INVOICE_PAYMENT_UPDATE_DEFAULT_ERROR = 'INVOICE_PAYMENT_UPDATE_DEFAULT_ERROR';
export const INVOICE_PAYMENT_FORM_NAME = 'invoice_payment_form';
export const INVOICE_STATUS_DRAFT_SLUG = 'draft';
export const INVOICE_STATUS_OPEN_SLUG = 'open';
export const INVOICE_STATUS_PAID_SLUG = 'paid';
export const INVOICE_STATUS_VOIDED_SLUG = 'voided';
export const INVOICE_STATUS_WRITE_OFF_SLUG = 'write-off';
export const INVOICE_STATUS_SETTLED = 'settled';
export const INVOICE_UPDATE_DEFAULT_ERROR = 'INVOICE_UPDATE_DEFAULT_ERROR';
export const IS_ADMIN = 'is_admin';
export const IVR_TEMPLATE_FORM = 'ivr_template_form';

// L
export const LEGAL_STATUS_PAYMENT_FORM = 'legal_status_payment_form';
export const LOGIN_FORM_NAME = 'loginForm';

// M
export const MANUAL = 'manual';
export const MANUAL_ACCOUNTING_SOFTWARE_SLUG = 'manual';
export const MONTHLY = 'monthly';
export const MY_PROFILE_FORM_NAME = 'my_profile_form';
export const MYOB_ESSENTIALS_SLUG = 'myob-essentials';

// N
export const NOTE_FORM_NAME = 'note_form';
export const NOT_SUPPORTED_FIELD_ERROR_KEY = 'not_supported';

// O
export const ON_DEMAND_CAMPAIGN_FORM_NAME = 'on_demand_campaign_form';
export const ORGANISATION_CURRENCY_FORM = 'organisation_currency_form';
export const ORGANISATION_ADD_DEFAULT_ERROR = 'ORGANISATION_ADD_DEFAULT_ERROR';
export const ORGANISATION_EDIT_DEFAULT_ERROR = 'ORGANISATION_EDIT_DEFAULT_ERROR';
export const ORGANISATION_PAYMENT_ACCOUNT_POPUP_SHOWN_KEY = 'pp_organisation_payment_account_popup_shown';
export const ORGANISATION_SETTINGS_DEFAULT_ERROR = 'ORGANISATION_SETTINGS_DEFAULT_ERROR';

export const ORDER_TYPE = {
    INVOICE: 'invoice',
    LEGAL_LETTER: 'legal-letter',
    SUBSCRIPTION: 'subscription',
};

export const OPEN_BANKING_CALCULATION_TYPE = [
    {
        value: 1,
        label: 'Flat Calculation',
    },
    {
        value: 2,
        label: 'Category Based Calculation',
    },
];
export const OPEN_BANKING_PAYMENT_BREAK_DURATION = [
    {
        id: 'd',
        label: 'Daily',
    },
    {
        id: 'w',
        label: 'Weekly',
    },
    {
        id: 'f',
        label: 'Fortnightly',
    },
    {
        id: 'm',
        label: 'Monthly',
    },
    {
        id: 'y',
        label: 'Yearly',
    },
];

// P
export const PAGINATION_PAGE_SIZE = 5;
export const PARTNER_REFERRAL_CODE_KEY = 'pp_partner_referral_code';
export const PASSWORD_MAX_LENGTH = 30;
export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_SPECIAL_CHARACTERS = '!@#$&*_.-';
export const PAYMENT_ARRANGEMENT_FORM = 'payment_arrangement_form';
export const PAYMENT_ARRANGEMENT_INSTALLMENT_FORM_NAME = 'payment_arrangement_installment_form';
export const PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_OPTIONS = [
    { id: 1, label: 'Cancelled' },
    { id: 2, label: 'Paid' },
    { id: 3, label: 'Pending' },
];
export const PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_PENDING = 3;
export const PAYMENT_ARRANGEMENT_PAY_IN_FULL_FORM_NAME = 'payment_arrangement_pay_in_full_form';
export const PAYMENT_ARRANGEMENT_OR_INSTALLMENT_PAYMENT_STATUS_OPTIONS = [
    {
        id: 1,
        label: 'Payment pending',
    },
    {
        id: 2,
        label: 'Payment processing',
    },
    {
        id: 3,
        label: 'Payment success',
    },
    {
        id: 4,
        label: 'Payment failed',
    },
];
export const PAYMENT_ARRANGEMENT_PAYMENT_TYPE_INSTALLMENT = 2;
export const PAYMENT_ARRANGEMENT_PAYMENT_TYPE_UPFRONT = 1;
export const PAYMENT_ARRANGEMENT_PLAN_TYPE_ARRANGEMENT = 2;
export const PAYMENT_ARRANGEMENT_PLAN_TYPE_EXTENSION = 1;
export const PAYMENT_ARRANGEMENT_STATUS_APPROVED = 1;
export const PAYMENT_ARRANGEMENT_STATUS_CANCELLED = 2;
export const PAYMENT_ARRANGEMENT_STATUS_COMPLETED = 5;
export const PAYMENT_ARRANGEMENT_STATUS_OPTIONS = [
    {
        id: 1,
        label: 'Approved',
    },
    {
        id: 2,
        label: 'Cancelled',
    },
    {
        id: 3,
        label: 'Pending',
    },
    {
        id: 4,
        label: 'Rejected',
    },
    {
        id: 5,
        label: 'Completed',
    },
];
export const PAYMENT_ARRANGEMENT_STATUS_PENDING = 3;
export const PAYMENT_ARRANGEMENT_STATUS_REJECTED = 4;
export const PAYMENT_METHOD_TYPE = {
    CREDIT_CARD: 'credit-card',
    DEBIT_CARD: 'debit-card',
    MANDATE: 'mandate',
};
export const PAYMENT_PLAN_FORM = 'payment_plan_form';
export const PAYMENT_PLAN_FORM_CHANGE_ALL_CATEGORY = 'payment_plan_change_all_category_form';
export const PAYMENT_PLAN_FREQUENCY_DAY_TYPE_OPTION = [
    {
        id: 'd',
        label: 'Day(s)',
    },
];
export const PAYMENT_PLAN_FREQUENCY_TYPE_OPTIONS = [
    {
        id: 'd',
        label: 'Day(s)',
    },
    {
        id: 'w',
        label: 'Week(s)',
    },
    {
        id: 'm',
        label: 'Month(s)',
    },
    {
        id: 'y',
        label: 'Year(s)',
    },
];
export const PAYMENT_PLAN_OVERDUE_TYPE_OPTIONS = [
    {
        id: 'le',
        label: 'Less than or equal to',
    },
    {
        id: 'b',
        label: 'Between',
    },
    {
        id: 'ge',
        label: 'Greater than  or equal to',
    },
];
export const PAYMENT_PLAN_PAYMENT_FREQUENCY_TYPE_OPTIONS = [
    {
        id: 'd',
        label: 'Daily',
    },
    {
        id: 'w',
        label: 'Weekly',
    },
    {
        id: 'f',
        label: 'Fortnightly',
    },
    {
        id: 'm',
        label: 'Monthly',
    },
    {
        id: 'y',
        label: 'Yearly',
    },
];
export const PAYMENT_PLAN_UNPAID_INSTALLMENT_CANCEL_TYPE_OPTIONS = [
    {
        id: 'd',
        label: 'Days after the installment',
    },
];
export const PAYMENT_GATEWAY = {
    STRIPE: 'stripe',
    GOCARDLESS: 'gocardless',
};
export const PAYMENT_GATEWAY_FORM = 'payment_gateway_form';
export const PAYMENT_GATEWAY_MONOOVA_SLUG = 'monoova';
export const PAYMENT_GATEWAY_SET_DEFAULT_ERROR_MESSAGE = 'PAYMENT_GATEWAY_SET_DEFAULT_ERROR_MESSAGE';
export const PAYMENT_INFORMATION_FORM = 'payment_information_form';
export const PAYMENT_METHOD_FORM = 'payment_method_form';
export const PAYMENT_METHOD_STATUS = {
    ACTIVE: 'active',
    CANCELLED: 'cancelled',
    EXPIRED: 'expired',
    FAILED: 'failed',
    PENDING_SUBMISSION: 'resubmission-requested',
    REMOVED: 'removed',
    RESUBMISSION_REQUESTED: 'pending-submission',
    SUBMITTED: 'submitted',
    SUSPENDED_BY_PAYER: 'suspended-by-payer',
};
export const PAYMENT_METHOD_TYPE_ICON_MAP = {
    [PAYMENT_METHOD_TYPE.CREDIT_CARD]: CreditCardIconSVG,
    [PAYMENT_METHOD_TYPE.DEBIT_CARD]: CreditCardIconSVG,
    [PAYMENT_METHOD_TYPE.MANDATE]: BankMandateSVG,
};

export const PAYMENT_SERVICE_FORM = 'payment_service_form';
export const PHONE_NUMBER_ALLOWED_COUNTRIES = ['AU', 'CA', 'FJ', 'GB', 'HK', 'IE', 'IN', 'NZ', 'SG', 'US'];
export const PORTAL_AUTH_SLUG = 'auth-portal';
export const PORTAL_CUSTOMER_SLUG = 'customer-portal';
export const PORTAL_DEBT_COLLECTION_SLUG = 'debt-collector-portal';
export const PAYMENT_STATUS = {
    CANCELLED: 'cancelled',
    FAILED: 'failed',
    NOT_COLLECTED: 'not-collected',
    PARTIALLY_REFUNDED: 'partially-refunded',
    PROCESSING: 'processing',
    REFUNDED: 'refunded',
    REQUIRES_ACTION: 'requires_action',
    REQUIRES_CONFIRMATION: 'requires_confirmation',
    REQUIRES_PAYMENT_METHOD: 'requires_payment_method',
    SUCCEEDED: 'succeeded',
    PENDING: 'pending',
};

// R
export const REDIRECT_URL_AFTER_VERIFY = 'pp_redirect_url_after_verify';
export const REGISTRATION_FORM_NAME = 'registrationForm';
export const REMINDER_CAMPAIGN_FILTER_KEY = 'campaignFilter';
export const REMINDER_CONTACT_TYPE_FILTER_KEY = 'contactTypeFilter';
export const REMINDER_DATE_FILTER_KEY = 'dateFilter';
export const REMINDER_DATE_FILTER_TYPE_KEY = 'dateFilterType';
export const REMINDER_DATE_RANGE_FILTER_KEY = 'dateRangeFilter';
export const REMINDER_HISTORY_DETAILS_FORM = 'reminder_history_details_form';
export const REMINDER_TYPE_FILTER = 'reminderTypeFilter';
export const REPEATING_CHILD_STATE_OPTIONS = [
    { value: 1, name: 'Save as Draft' },
    { value: 2, name: 'Save' },
    { value: 3, name: 'Save and Send' },
];
export const REPEAT_FREQUENCY_UNIT_OPTIONS = [
    {
        id: 1,
        label: 'Day(s)',
    },
    {
        id: 2,
        label: 'Week(s)',
    },
    {
        id: 3,
        label: 'Month(s)',
    },
    {
        id: 4,
        label: 'Year(s)',
    },
    {
        id: 5,
        label: 'End of every month',
    },
];
export const REPEATING_DUE_DATE_TYPE = [
    {
        id: 1,
        label: 'of the invoice month',
    },
    {
        id: 2,
        label: 'day(s) after the invoice date',
    },
    {
        id: 3,
        label: 'day(s) before the invoice date',
    },
    {
        id: 4,
        label: 'same day of the invoice issued date',
    },
    {
        id: 5,
        label: 'day(s) after the end of the invoice month',
    },
];
export const REPORT_EXPORT_FORM = 'report_export_form';
export const REQUEST_QUOTE_FORM_NAME = 'request_quote_form';
export const REQUIRED_FIELD_ERROR_KEY = 'required';
export const RESEND_ACTIVATION_EMAIL_DEFAULT_ERROR = 'RESEND_ACTIVATION_EMAIL_DEFAULT_ERROR';
export const RESEND_VERIFICATION_EMAIL_ERROR = 'RESEND_VERIFICATION_EMAIL_ERROR';
export const RESEND_VERIFICATION_SMS_ERROR = 'RESEND_VERIFICATION_SMS_ERROR';
export const RESET_PASSWORD_FORM_NAME = 'reset_password_form';
export const RESET_TWO_FACTOR_AUTHENTICATION_DEFAULT_ERROR = 'RESET_TWO_FACTOR_AUTHENTICATION_DEFAULT_ERROR';

// S
export const SEND_ON_DEMAND_CAMPAIGN_DEFAULT_ERROR = 'SEND_ON_DEMAND_CAMPAIGN_DEFAULT_ERROR';
export const SEND_REMINDER_DEFAULT_ERROR = 'SEND_REMINDER_DEFAULT_ERROR';
export const SEND_REMINDER_FORM = 'send_reminder_form';
export const SEND_TEST_EMAIL_FORM = 'send_test_email_form';
export const SETTINGS_FORM_NAME = 'settingsForm';
export const SMS_TEMPLATE_FORM = 'sms_template_form';
export const SUBSCRIPTION_PAYMENT_FORM_NAME = 'subscription_payment_form';
export const SUBSCRIPTION_PLAN = {
    STARTER_PLAN: 'starter-plan',
    KICKBACK_PLAN: 'kickback-plan',
    FOCUS_PLAN: 'focus-plan',
    ENTERPRISE_PLAN: 'enterprise-plan',
};
export const SUCCESS = 'success';
export const SEND_TEST_MAIL_ERROR = 'SEND_TEST_MAIL_ERROR';
export const SUBSCRIPTION_PLAN_ADD_DEFAULT_ERROR = 'SUBSCRIPTION_PLAN_ADDDEFAULT_ERROR';
export const SUBSCRIPTION_PLAN_EDIT_DEFAULT_ERROR = 'SUBSCRIPTION_PLAN_EDIT_DEFAULT_ERROR';
export const SUBMIT_ERRORS = 'submitErrors';
export const SYNC_ORGANISATION_DEFAULT_ERROR = 'SYNC_ORGANISATION_DEFAULT_ERROR';

// T
export const TAX_FORM_NAME = 'tax_form';
export const TAX_STATUS_OPTIONS = [
    { id: 1, label: 'Tax Exclusive' },
    { id: 2, label: 'Tax Inclusive' },
    { id: 3, label: 'No Tax' },
];
export const TRY_DEMO_COMPANY_DEFAULT_ERROR = 'TRY_DEMO_COMPANY_DEFAULT_ERROR';

// U
export const UPDATE_CAMPAIGN_DEFAULT_ERROR = 'UPDATE_CAMPAIGN_DEFAULT_ERROR';
export const UPDATE_CARD_ERROR_MESSAGE = 'UPDATE_CARD_ERROR_MESSAGE';
export const UPDATE_ORDER_DEFAULT_ERROR = 'UPDATE_ORDER_DEFAULT_ERROR';
export const UPLOAD_PICTURE_LIST_TYPE = 'picture';
export const UPDATE_PLACEHOLDER_DEFAULT_ERROR = 'UPDATE_PLACEHOLDER_DEFAULT_ERROR';
export const USER_ACCOUNT_TOKENS_KEY = 'pp_user_account_tokens';
export const USER_ACCOUNTING_SOFTWARE_ID_KEY = 'pp_user_accounting_software_id';
export const USER_CHANGE_PASSWORD_DEFAULT_ERROR = 'USER_CHANGE_PASSWORD_DEFAULT_ERROR';
export const USER_CURRENT_ACCOUNT_KEY = 'pp_user_current_account';
export const USER_FORGOT_PASSWORD_DEFAULT_ERROR = 'USER_FORGOT_PASSWORD_DEFAULT_ERROR';
export const USER_LOCALE_KEY = 'i18nextLng';
export const USER_LOGIN_DEFAULT_ERROR = 'USER_LOGIN_DEFAULT_ERROR';
export const USER_REAUTHORIZING_ORGANISATION_KEY = 'pp_user_reauthorizing_organisation';
export const USER_REGISTRATION_DEFAULT_ERROR = 'USER_REGISTRATION_DEFAULT_ERROR';
export const USER_REGISTRATION_MEETING_NOT_SCHEDULED_ERROR = 'USER_REGISTRATION_MEETING_NOT_SCHEDULED_ERROR';
export const USER_ROLE_ADMIN = 'admin';
export const USER_ROLE_ADMIN_MANAGER = 'admin-manager';
export const USER_ROLE_CUSTOMER = 'customer-admin';
export const USER_ROLE_CUSTOMER_MANAGER = 'customer-manager';
export const USER_ROLE_DEBTOR = 'debtor';
export const USER_ROLE_DEBT_COLLECTOR = 'debt-collector';
export const USER_ROLES_KEY = 'pp_user_roles_key';
export const USER_SELECTED_ORGANISATION_ID_KEY = 'pp_organisation_id';
export const USER_TOKEN_KEY = 'pp_user_token';
export const USER_UPDATE_DEFAULT_ERROR = 'USER_UPDATE_DEFAULT_ERROR';
export const UPDATE_TEMPLATE_DEFAULT_ERROR = 'UPDATE_TEMPLATE_DEFAULT_ERROR';

// V
export const VERIFY_EMAIL_ERROR = 'VERIFY_EMAIL_ERROR';
export const VERIFY_PHONE_NUMBER_DEFAULT_ERROR = 'VERIFY_PHONE_NUMBER_DEFAULT_ERROR';
export const VERIFY_PHONE_NUMBER_FORM = 'verify_phone_number_form';

// W
export const WEEK_DAYS_OPTIONS = [
    {
        id: '0',
        label: 'Monday',
    },
    {
        id: '1',
        label: 'Tuesday',
    },
    {
        id: '2',
        label: 'Wednesday',
    },
    {
        id: '3',
        label: 'Thursday',
    },
    {
        id: '4',
        label: 'Friday',
    },
    {
        id: '5',
        label: 'Saturday',
    },
    {
        id: '6',
        label: 'Sunday',
    },
];
export const WS_ACTION = {
    ACCOUNT_SUBSCRIPTION_CANCELLED: 'account_subscription_cancelled',
    ACCOUNT_SUBSCRIPTION_LIMIT_REACHED: 'account_subscription_limit_reached',
    ACCOUNT_SUBSCRIPTION_UPDATE_COMPLETE: 'account_subscription_update_complete',
    FILE_EXPORT_FAILED: 'file_export_failed',
    FILE_EXPORTED_SUCCESSFULLY: 'file_exported_successfully',
    ORGANISATION_DATA_FETCHING_COMPLETE: 'organisation_data_fetching_complete',
    ORGANISATION_DATA_SYNCING_COMPLETE: 'organisation_data_syncing_complete',
    ORGANISATION_INVOICE_PAYMENT_RECEIVED: 'organisation_invoice_payment_received',
    ORGANISATION_LEGAL_PAYMENT_COMPLETE: 'organisation_legal_payment_complete',
    ORGANISATION_PAYMENT_ACCOUNT_NOT_FOUND: 'organisation_payment_account_not_found',
    ORGANISATION_PAYMENT_ARRANGEMENT_REQUEST: 'organisation_payment_arrangement_request',
    ORGANISATION_PAYMENT_EXTENSION_REQUEST: 'organisation_payment_extension_request',
    ORGANISATION_REAUTHORIZATION_REQUIRED: 'organisation_reauthorization_required',
    ORGANISATION_SYNC_COMPLETE: 'organisation_sync_complete',
    ORGANISATION_ZENDESK_CONNECTION_FAILURE: 'organisation_zendesk_connection_failed',
    REMINDER_SCHEDULING_COMPLETE: 'reminder_scheduling_complete',
};

// X
export const XERO_ACCOUNTING_SOFTWARE_SLUG = 'xero';

// Y
export const YEARLY = 'yearly';

// Z
export const ZENDESK_CREATE_TICKET_DEFAULT_VALUE = true;
export const ZENDESK_SETTINGS_FORM_NAME = 'zendesk_settings_form';
export const ZENDESK_TICKET_DEFAULT_TAG = 'paycepaid';
export const ZENDESK_TICKET_DEFAULT_TAG_BY_TYPE = 'paycepaid_email, paycepaid_ivr, paycepaid_sms';
export const ZENDESK_TICKET_DEFAULT_TAG_BY_TYPES = {
    email: 'paycepaid_email',
    ivr: 'paycepaid_ivr',
    sms: 'paycepaid_sms',
    whatsapp: 'paycepaid_whatsapp',
};
export const ZENDESK_TICKET_DEFAULT_PRIORITY = 3;
export const ZENDESK_TICKET_DEFAULT_STATUS = {
    email: 2,
    default: 5,
};
export const ZENDESK_TICKET_DEFAULT_TYPE = 3;
export const TRANSACTIONS_BASE_TYPE = {
    CREDIT: 'CREDIT',
    DEBIT: 'DEBIT',
};

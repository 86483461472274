/**
 * Order Details Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import OrderPaymentDetails from './../Payment';
import { find } from 'lodash';
import { getDefaultImageClass, getImageFromData } from '../../../../../includes/utils';
import { Field } from 'redux-form';
import FormField from '../../../FormField';
import { required } from '../../../../../includes/utils/form';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import { ORDER_TYPE } from 'includes/constants';

/**
 * Invoice details component
 */
const OrderTypeDetails = props => {
    const { orderDetails, isEditAction } = props;
    const orderTypeSlug = get(find(props.orderTypes, { id: orderDetails.type_id }), 'slug');
    const planImages = get(orderDetails, 'plan.plan_images');
    const defaultPlanImage = require('../../../../../assets/images/starter-plan.png');
    const planImage254Key = '_254x254';
    const planImagOriginalKey = '_ORIGINAL';
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    /**
     * Format the number as currency based on the order type
     *
     * @returns {string} Formatted number
     */
    const getNumberFormattedAsCurrencyByOrderType = number =>
        getNumberFormattedAsCurrency(number, false, false, get(orderDetails, 'currency.code'));

    return (
        <>
            <h3 className="page-title">{props.getLocalisedContent(`${orderTypeSlug}.title`)}</h3>
            <section className="order-summary-details">
                <ul>
                    {orderTypeSlug === ORDER_TYPE.SUBSCRIPTION ? (
                        <li className="rate">{get(orderDetails, 'plan.type', '')}</li>
                    ) : null}
                    <li className="rate">
                        {getNumberFormattedAsCurrencyByOrderType(get(orderDetails, 'total_amount', 0))}
                    </li>
                </ul>
                {orderTypeSlug === ORDER_TYPE.SUBSCRIPTION ? (
                    <img
                        className={
                            getDefaultImageClass(planImages, planImage254Key, planImagOriginalKey, '210x210') +
                            ' logo logo-210 right-align'
                        }
                        src={getImageFromData(planImages, planImage254Key, planImagOriginalKey, defaultPlanImage)}
                        alt="Plan"
                    />
                ) : (
                    <img
                        alt="invoice"
                        className="right-align"
                        src={require(`../../../../../assets/images/invoice_avatar.svg`)}
                    />
                )}
            </section>
            <ul>
                <li className="custom-form-like-field">
                    <header>{props.getLocalisedContent(`name`, true)}</header>
                    {isEditAction ? (
                        <Field
                            type="text"
                            name="name"
                            className="form-control"
                            component={FormField}
                            validate={required}
                        />
                    ) : (
                        <div className="custom-input readonly">{get(orderDetails, 'name')}</div>
                    )}
                </li>
                <li className="custom-form-like-field">
                    <header>{props.getLocalisedContent(`email`, true)}</header>
                    {isEditAction ? (
                        <Field
                            type="text"
                            name="email"
                            className="form-control"
                            component={FormField}
                            validate={required}
                        />
                    ) : (
                        <div className="custom-input readonly">{get(orderDetails, 'email')}</div>
                    )}
                </li>
                {get(orderDetails, 'plan.subscription') && (
                    <li className="custom-form-like-field">
                        <header>{props.getLocalisedContent(`subscription`, true)}</header>
                        <div className="custom-input readonly">{get(orderDetails, 'plan.subscription')}</div>
                    </li>
                )}
                {get(orderDetails, 'organisation.name') && (
                    <li className="custom-form-like-field">
                        <header>{props.getLocalisedContent(`organisation`, true)}</header>
                        <div className="custom-input readonly">{get(orderDetails, 'organisation.name')}</div>
                    </li>
                )}
                {get(orderDetails, 'contact.name') && (
                    <li className="custom-form-like-field">
                        <header>{props.getLocalisedContent(`contact`, true)}</header>
                        <div className="custom-input readonly">{get(orderDetails, 'contact.name')}</div>
                    </li>
                )}
                <OrderPaymentDetails
                    getLocalisedContent={props.getLocalisedContent}
                    orderDetails={orderDetails}
                    paymentGateways={props.paymentGateways}
                />
            </ul>
        </>
    );
};

// prop types
OrderTypeDetails.propTypes = {
    getLocalisedContent: PropTypes.func,
    isEditAction: PropTypes.bool,
    paymentGateways: PropTypes.array,
    orderDetails: PropTypes.object,
    orderTypes: PropTypes.array,
};

export default OrderTypeDetails;

import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

export default function CouponIcon({ iconStyles, iconText }) {
    return (
        <div className="relative">
            <div className={`star_12 ${iconStyles}`} />
            <div className="absolute top-0 left-0 right-0 bottom-0 w-fit-content h-fit-content m-auto text-white">
                {iconText}
            </div>
        </div>
    );
}

CouponIcon.propTypes = {
    iconStyles: PropTypes.string,
    iconText: PropTypes.string,
};

/**
 * Edit Payment Form.
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

// import required modules
import { React, PropTypes, withTranslation } from '../../../includes/exports/react';
import * as constants from '../../../includes/constants';
import * as formValidations from '../../../includes/utils/form';
import * as requestKeys from '../../../includes/constants/keys/request';
import Button from '../Button';
import FormField from '../FormField';
import SuccessMessage from '../messages/SuccessMessage';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';
import { find, get } from 'lodash';
import useOrganisationCurrency from 'includes/hooks/useOrganisationCurrency';
import { CURRENCY_CODE, ID } from 'includes/constants/keys/response';
import { useSelector } from 'react-redux';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';

/**
 * Edit payment form component.
 */
const EditPaymentForm = props => {
    /**
     * Locale the label.
     *
     * @param field Field for which locale is to be generated.
     *
     * @returns    Generated Locale label.
     */
    const localeLabel = field => props.t(`customerMessages.paymentsHistory.form.input.label.${field}`);
    const organisationCurrencyId = useOrganisationCurrency(ID);
    const organisationCurrencyCode = useOrganisationCurrency(CURRENCY_CODE);
    const invoiceDetails = useSelector(state => state.invoices.invoiceDetails);
    const invoiceCurrencyId = get(invoiceDetails, requestKeys.CURRENCY_ID);
    const { data: organisationCurrencies } = useOrganisationCurrencies();
    const invoiceCurrencyCode = get(find(organisationCurrencies, { id: invoiceCurrencyId }, ''), CURRENCY_CODE);

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                {props.detailsUpdatedMessage && <SuccessMessage message={props.detailsUpdatedMessage} />}
                <Row gutter={16}>
                    <Col>
                        <Field
                            step="any"
                            type="number"
                            name={requestKeys.AMOUNT_PAID}
                            label={localeLabel(requestKeys.AMOUNT_PAID)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="date"
                            name={requestKeys.PAID_ON}
                            label={localeLabel(requestKeys.PAID_ON)}
                            hasFeedback
                            reduxChange={props.change}
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required, formValidations.date]}
                            required
                        />
                    </Col>
                </Row>
                <Row gutter={16} className="mb-4">
                    <Col>
                        <Field
                            type="checkbox"
                            name={'settled'}
                            label={'Mark invoice as settled'}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                {invoiceCurrencyId !== organisationCurrencyId ? (
                    <Row gutter={16}>
                        <Col>
                            <Field
                                type="text"
                                name={requestKeys.EXCHANGE_RATE}
                                label={localeLabel(requestKeys.EXCHANGE_RATE)}
                                hasFeedback
                                className="form-control"
                                component={FormField}
                                validate={[formValidations.required, formValidations.number]}
                                required
                                addonBefore={`${organisationCurrencyCode} 1  = ${invoiceCurrencyCode} `}
                                info={props.exchangeRateText}
                            />
                        </Col>
                    </Row>
                ) : null}
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="text"
                            name={requestKeys.EXTERNAL_ID}
                            label={localeLabel(requestKeys.EXTERNAL_ID)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="text"
                            name={requestKeys.REFERENCE_NUMBER}
                            label={localeLabel(requestKeys.REFERENCE_NUMBER)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="textarea"
                            name={requestKeys.REFERENCE}
                            label={localeLabel(requestKeys.REFERENCE)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Form.Item
                    className={`update-button no-margin center-align ${
                        props.error ? ' has-error default-form-app-error' : ''
                    }`}
                >
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={props.loading}
                        submittingButtonLabel={props.t(
                            `customerMessages.editContact.form.submit.buttonSubmittingLabel.${
                                get(props, ['initialValues', 'id']) ? 'edit' : 'add'
                            }`
                        )}
                        className="register-btn"
                    >
                        {props.t(
                            `customerMessages.editContact.form.submit.buttonLabel.${
                                get(props, ['initialValues', 'id']) ? 'edit' : 'add'
                            }`
                        )}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

/**
 * Proptypes.
 */
EditPaymentForm.propTypes = {
    change: PropTypes.func,
    detailsUpdatedMessage: PropTypes.string,
    error: PropTypes.any,
    exchangeRateText: PropTypes.string,
    handleSubmit: PropTypes.func,
    loading: PropTypes.bool,
    t: PropTypes.func,
};

export default reduxForm({
    form: constants.EDIT_PAYMENT_HISTORY_FORM_NAME,
})(withTranslation()(EditPaymentForm));

/**
 * Success Message Component
 * Handles the success view
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modules
import { React } from '../../../includes/exports/react';
import PropTypes from 'prop-types';
import Loader from '../Loader';

/**
 * Info Message Component
 */
const InfoMessage = props => {
    /**
     * Render the login form
     *
     * @returns {*}
     */
    return (
        <div className="ant-form-explain info-message-wrapper">
            {props.showLoader ? <Loader /> : ''}
            {props.message}
            {props.customContent}
        </div>
    );
};

/**
 * Prop Types
 */
InfoMessage.propTypes = {
    customContent: PropTypes.any,
    message: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    showLoader: PropTypes.bool,
};

/**
 * Default props
 */
InfoMessage.defaultProps = {
    showLoader: false,
};

export default InfoMessage;

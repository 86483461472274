/**
 * Notification API class.
 * Handles all api requests related to notifications
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';
import { get } from 'lodash';
class NotificationApi extends BaseApiHandler {
    /**
     * Call the notification listing API
     *
     * @returns {object} API Response
     */
    getNotifications = ({ searchParams, ...restPayload }) => {
        let apiUrl = get(restPayload, 'organisation_id')
            ? 'GET_ALL_ACCOUNT_ORGANISATION_NOTIFICATIONS'
            : 'GET_ALL_NOTIFICATIONS';
        return this.axios.get(this.getApiUrl(apiUrl, { ...restPayload }), { params: { ...searchParams } });
    };
}

export default new NotificationApi();

/**
 * Order details component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { find, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import Loader from '../../Loader';
import {
    useCurrentRoleSlug,
    useOrderStatuses,
    useOrderTypes,
    usePaymentStatuses,
    useUpdateBusinessName,
} from 'includes/utils/hooks';
import './styles.scss';
import OrderTypeDetails from './Details';
import OrderTypeSummary from './Summary';
import { downloadOrderInvoice, getOrderDetails, getOrderStatus, updateOrder } from 'includes/slices/orders';
import EditOrderForm from '../../forms/Orders/EditOrder';
import Button from '../../Button';
import { ORDER_TYPE, USER_ROLE_ADMIN, USER_ROLE_ADMIN_MANAGER } from 'includes/constants';
import { redirect } from 'includes/utils';
import useAccountId from 'includes/hooks/useAccountId';
import useHasRequiredPermission from 'includes/hooks/useHasRequiredPermission';
import { ORDER } from 'includes/constants/permissions';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { getPaymentGateways } from 'includes/slices/paymentGateways';
import useUserHasAccessPermission from 'includes/hooks/useUserHasAccessPermission';
import OrderDetailsSection from 'components/OrderDetails';

const ORDER_VIEW_PERMISSION = {
    subscription: ORDER.SUBSCRIPTION.VIEW,
    invoice: ORDER.INVOICE.VIEW,
    'legal-letter': ORDER.LEGAL_LETTER.VIEW,
};

/**
 * Order details component
 */
const OrderDetails = props => {
    // check if user has updated the account name
    useUpdateBusinessName();

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [orderStatuses, loadingOrderStatuses] = useOrderStatuses();
    const [orderTypes, loadingOrderTypes] = useOrderTypes();
    const [paymentStatuses, loadingPaymentStatuses] = usePaymentStatuses();
    const organisationId = useOrganisationId();
    const { match } = props;
    const { orderType, contactId, orderId } = match.params;
    const isEditAction = props.action === 'edit';
    const accountId = useAccountId();

    const orderTypeSlug = useMemo(() => {
        return get(find(orderTypes, { id: orderType }), 'slug', '');
    }, [orderTypes]); // eslint-disable-line react-hooks/exhaustive-deps

    const hasViewPermission = useHasRequiredPermission(ORDER_VIEW_PERMISSION[orderTypeSlug]);

    // get the data from store
    const orderDetails = useSelector(state => state.orders.orderDetails);
    const isAdmin = useSelector(state => state.account.isAdmin);
    const loadingOrderDetails = useSelector(state => state.orders.loadingOrderDetails);
    const paymentGateways = useSelector(state => state.paymentGateways.paymentGateways);
    const loadingPaymentGateways = useSelector(state => state.paymentGateways.loadingPaymentGateways);
    const loading =
        loadingOrderDetails ||
        loadingOrderStatuses ||
        loadingOrderTypes ||
        loadingPaymentStatuses ||
        loadingPaymentGateways;

    // get current user's role slug
    const currentRoleSlug = useCurrentRoleSlug();

    const fetchPaymentGateways = () =>
        dispatch(
            getPaymentGateways(
                orderTypeSlug === 'subscription' ? '' : organisationId,
                orderTypeSlug === 'subscription' ? '' : accountId
            )
        );

    /**
     * useEffect hook to fetch order details
     */
    useEffect(() => {
        if (orderTypeSlug && hasViewPermission) {
            dispatch(
                getOrderDetails(orderType, orderId, currentRoleSlug, {
                    ...(contactId ? { contact_id: contactId } : {}),
                    organisation_id: organisationId,
                })
            );
        }
        if (orderTypeSlug) {
            fetchPaymentGateways();
        }
    }, [contactId, hasViewPermission, orderTypeSlug, orderType, orderId, organisationId]); // eslint-disable-line react-hooks/exhaustive-deps

    const hasSubscriptionOrderDetailAccess = useUserHasAccessPermission(ORDER.SUBSCRIPTION.VIEW);

    // effect to redirect user to page not found if accessing subscription order details without access
    useEffect(() => {
        if (!hasSubscriptionOrderDetailAccess && orderTypeSlug === ORDER_TYPE.SUBSCRIPTION) {
            redirect('/page-not-found');
        }
    }, [orderTypes]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Check the order status
     */
    const checkOrderStatus = () => {
        if (hasViewPermission)
            dispatch(
                getOrderStatus(orderType, orderId, {
                    contact_id: orderTypeSlug === ORDER_TYPE.SUBSCRIPTION ? '' : contactId,
                    organisation_id: orderTypeSlug === ORDER_TYPE.SUBSCRIPTION ? '' : organisationId,
                })
            );
    };

    /**
     * Download the invoice of an order
     */
    const downloadOrder = () => {
        dispatch(
            downloadOrderInvoice(orderType, orderId, {
                contact_id: orderTypeSlug === ORDER_TYPE.SUBSCRIPTION ? '' : contactId,
                organisation_id: orderTypeSlug === ORDER_TYPE.SUBSCRIPTION ? '' : organisationId,
            })
        );
    };

    /**
     * Get Order View Link
     *
     * @returns {string} Order View Link
     */
    const getOrderViewLink = () => {
        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return `/admin/accounts/orders/${accountId}/${orderType}/${orderId}`;
            default:
                return `/orders/order/${orderType}/${orderId}`;
        }
    };

    /**
     * Get Orders Link
     *
     * @returns {string} Orders Link
     */
    const getOrdersLink = () => {
        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return organisationId
                    ? `/admin/accounts/organisation/orders/${accountId}/${organisationId}`
                    : `/admin/accounts/orders/${accountId}`;
            default:
                return `/billings`;
        }
    };

    /**
     * Handle the submit of the order edit form
     *
     * @param {object} values Form values
     */
    const handleSubmit = values => {
        if (isEditAction && isAdmin) {
            dispatch(updateOrder(accountId, orderType, orderId, values));
        }
    };

    /**
     * Get the localised content
     *
     * @param {string} key Key for which translation is to be retrieved
     * @param {boolean} isLabel Whether to retrieve label or not. Default false
     *
     * @returns {string} Localised content
     */
    const getLocalisedContent = (key, isLabel = false) =>
        t(`sharedMessages.orders.details.${isLabel ? 'label.' : ''}${key}`);

    /**
     * Render order details
     */
    const renderOrderDetails = () => {
        return loading || loadingOrderTypes || !orderDetails ? (
            <Loader />
        ) : (
            <OrderTypeDetails
                getLocalisedContent={getLocalisedContent}
                isEditAction={isEditAction}
                orderDetails={orderDetails}
                orderStatuses={orderStatuses}
                orderTypes={orderTypes}
                paymentGateways={paymentGateways}
                paymentStatuses={paymentStatuses}
            />
        );
    };

    /**
     * Render order summary
     */
    const renderOrderSummary = () => {
        return loading || !orderDetails ? (
            <Loader />
        ) : (
            <OrderTypeSummary
                checkOrderStatus={checkOrderStatus}
                downloadOrder={downloadOrder}
                getLocalisedContent={getLocalisedContent}
                isEditAction={isEditAction}
                orderDetails={orderDetails}
                orderStatuses={orderStatuses}
                orderTypes={orderTypes}
                paymentStatuses={paymentStatuses}
            />
        );
    };

    const pageTitle = getLocalisedContent(orderTypeSlug === ORDER_TYPE.INVOICE ? 'titleOrder' : 'titleBilling');

    const getBreadcrumbs = () => {
        if (isAdmin)
            return (
                <div className="text-left tab-arrow-link breadcumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/admin/accounts">Accounts</Link>
                        </Breadcrumb.Item>
                        {organisationId ? (
                            <Breadcrumb.Item>
                                <Link to={`/admin/accounts/organisations/${accountId}`}>Organisations</Link>
                            </Breadcrumb.Item>
                        ) : null}
                        <Breadcrumb.Item>
                            <Link to={getOrdersLink()}>Orders</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {isEditAction ? <Link to={getOrderViewLink()}>Order Details</Link> : 'Order Details'}
                        </Breadcrumb.Item>
                        {isEditAction && <Breadcrumb.Item>{getLocalisedContent('edit')}</Breadcrumb.Item>}
                    </Breadcrumb>
                </div>
            );

        if (orderTypeSlug === ORDER_TYPE.INVOICE)
            return (
                <div className="text-left tab-arrow-link breadcumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/invoice-orders">Invoice Orders</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{orderDetails?.unique_id}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            );

        return (
            <div className="text-left tab-arrow-link breadcumb-spec">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/billings">Billings</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{orderDetails?.unique_id}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
        );
    };

    return (
        <div className="details-wrapper order-details-wrapper">
            <section>
                <div className="home-content-wrapper container mx-auto">
                    {isAdmin && <h2 className="page-title">{pageTitle}</h2>}
                    {getBreadcrumbs()}
                    {!isAdmin && <h2 className="page-title">{pageTitle}</h2>}
                    {isAdmin && isEditAction ? (
                        <EditOrderForm
                            getLocalisedContent={getLocalisedContent}
                            renderOrderDetails={renderOrderDetails}
                            renderOrderSummary={renderOrderSummary}
                            initialValues={orderDetails}
                            enableReinitialize
                            onSubmit={handleSubmit}
                            {...props}
                        />
                    ) : (
                        <>
                            <PermissionGuardLayout requiredPermission={ORDER_VIEW_PERMISSION[orderTypeSlug]}>
                                <OrderDetailsSection details={orderDetails} isLoading={loadingOrderDetails} />
                                {isAdmin ? (
                                    <Link
                                        to={
                                            organisationId
                                                ? `/admin/accounts/organisation/orders/${accountId}/${organisationId}/${orderType}/${orderId}/edit`
                                                : `/admin/accounts/orders/${accountId}/${orderType}/${orderId}/edit`
                                        }
                                    >
                                        <Button big filled className="register-btn">
                                            {getLocalisedContent('button.edit')}
                                        </Button>
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </PermissionGuardLayout>
                        </>
                    )}
                </div>
            </section>
        </div>
    );
};

OrderDetails.propTypes = {
    action: PropTypes.string,
    match: PropTypes.object,
    t: PropTypes.func,
};

export default OrderDetails;

import PropTypes from 'prop-types';
import React from 'react';

import CountryLabel from 'components/CountryLabel';
import DetailsPageDetailSection from 'components/DetailsPageDetailSection';
import DefaultLabel from 'components/customer/PaymentInformation/components/DefaultLabel';
import PaymentMethodStatus from 'components/customer/PaymentInformation/components/PaymentMethods/components/PaymentMethodStatus';
import PaymentScheme from 'components/customer/PaymentInformation/components/PaymentMethods/components/PaymentScheme';
import usePaymentMethodSchemes from 'includes/hooks/usePaymentMethodSchemes';
import usePaymentMethodStatuses from 'includes/hooks/usePaymentMethodStatuses';

/**
 * Basic structure of payment method details page
 */
export default function PaymentMethodDetailsBase({
    accountHolderName,
    accountNumberDescription,
    bank,
    countryId,
    endDate,
    icon,
    isDefault,
    schemeId,
    startDate,
    statusId,
}) {
    usePaymentMethodStatuses();
    usePaymentMethodSchemes();
    return (
        <div>
            <div className="flex justify-end">
                <div className="flex flex-col gap-2">
                    <div>
                        <PaymentMethodStatus id={statusId} />
                    </div>
                    {isDefault ? (
                        <div>
                            <DefaultLabel />
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 text-left my-5">
                <div className="flex gap-2 items-center">
                    <img src={icon} alt={accountNumberDescription} style={{ width: 50 }} />
                    <DetailsPageDetailSection label={'Account number'} value={accountNumberDescription} />
                </div>

                <DetailsPageDetailSection label={'Name'} value={accountHolderName} />

                {bank ? <DetailsPageDetailSection label={'Bank'} value={bank} /> : null}

                {startDate ? <DetailsPageDetailSection label={'Start date'} value={startDate} /> : null}

                {endDate ? <DetailsPageDetailSection label={'End date'} value={endDate} /> : null}

                {countryId ? (
                    <DetailsPageDetailSection label={'Country'} value={<CountryLabel id={countryId} />} />
                ) : null}

                {schemeId ? (
                    <DetailsPageDetailSection label={'Scheme'} value={<PaymentScheme id={schemeId} />} />
                ) : null}
            </div>
        </div>
    );
}

PaymentMethodDetailsBase.propTypes = {
    accountHolderName: PropTypes.string,
    accountNumberDescription: PropTypes.string,
    bank: PropTypes.string,
    countryId: PropTypes.string,
    endDate: PropTypes.string,
    icon: PropTypes.string,
    isDefault: PropTypes.bool,
    schemeId: PropTypes.string,
    startDate: PropTypes.string,
    statusId: PropTypes.string,
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Select as AntSelect, Form, Input as AntInput, AutoComplete as AntAC, Tag } from 'antd';
import PropTypes from 'prop-types';
import { find, get } from 'lodash';

import InventoryAPI from 'includes/services/inventory';
import { INVENTORY_ITEMS } from 'includes/constants/mappings/success';
import { useCacheContext } from 'includes/contexts/cacheContext';
import { useAutoComplete } from 'includes/utils/hooks';
import { useParams } from 'react-router';
import useTaxes from 'includes/hooks/useTaxes';

const { Option } = AntSelect;

export const Select = ({
    // info,
    options,
    meta: { touched, error, warning },
    editing,
    hasFeedback,
    loading,
    input,
    ...restProps
}) => {
    if (!editing) {
        return <Form.Item>{get(find(options, { value: input.value }), 'name', '')}</Form.Item>;
    }
    const selectProps = { ...input, ...restProps };
    const fieldError = touched && (error || warning);
    const validateStatus = fieldError ? 'error' : touched ? 'success' : loading ? 'validating' : '';
    return (
        <Form.Item validateStatus={validateStatus} help={fieldError} hasFeedback={hasFeedback}>
            <AntSelect {...selectProps}>
                <Option value="">--Select--</Option>
                {options.map(({ value, name }) => (
                    <Option key={value} value={value}>
                        {name}
                    </Option>
                ))}
            </AntSelect>
        </Form.Item>
    );
};

export const Input = ({
    // info,
    label,
    meta: { touched, error, warning },
    editing,
    hasFeedback,
    loading,
    input,
    ...restProps
}) => {
    if (!editing) {
        return <Form.Item>{input.value}</Form.Item>;
    }
    const fieldError = touched && (error || warning);
    const validateStatus = fieldError ? 'error' : touched ? 'success' : loading ? 'validating' : '';
    const inputProps = { ...input, ...restProps };
    return (
        <>
            <label>{label}</label>
            <Form.Item validateStatus={validateStatus} help={fieldError} hasFeedback={hasFeedback}>
                <AntInput {...inputProps} />
            </Form.Item>
        </>
    );
};

export const InputNumber = ({
    // info,
    label,
    meta: { touched, error, warning },
    editing,
    hasFeedback,
    loading,
    input,
    ...restProps
}) => {
    if (!editing) {
        return <Form.Item>{input.value}</Form.Item>;
    }
    const fieldError = touched && (error || warning);
    const validateStatus = fieldError ? 'error' : touched ? 'success' : loading ? 'validating' : '';
    const inputProps = { ...input, ...restProps };
    return (
        <>
            <label htmlFor={input.name}>{label}</label>
            <Form.Item validateStatus={validateStatus} help={fieldError} hasFeedback={hasFeedback}>
                <AntInput {...inputProps} type="number" />
            </Form.Item>
        </>
    );
};

export const AutoComplete = ({
    // info,
    dataSource,
    meta: { touched, error, warning },
    editing,
    hasFeedback,
    loading,
    input,
    ...restProps
}) => {
    if (!editing) {
        return <Form.Item>{get(find(dataSource, { value: input.value }), 'text', input.value)}</Form.Item>;
    }
    const selectProps = { ...input, ...restProps, dataSource };
    const fieldError = touched && (error || warning);
    const validateStatus = fieldError ? 'error' : touched ? 'success' : loading ? 'validating' : '';

    return (
        <Form.Item validateStatus={validateStatus} help={fieldError} hasFeedback={hasFeedback}>
            <AntAC {...selectProps}>
                {/* <Option value="">--Select--</Option>
                {options.map(({ value, name }) => (
                    <Option key={value} value={value}>
                        {name}
                    </Option>
                ))} */}
            </AntAC>
        </Form.Item>
    );
};

export const ItemName = ({
    // info,
    // label,
    meta: { touched, error, warning },
    hasFeedback,
    loading,
    input,
    changeField,
    cacheId,
    required,
    ...restProps
}) => {
    const params = useParams();
    const _organisationId = useSelector(state => state.organisation.selectedOrganisationId);
    const organisationId = params.organisationId || _organisationId;

    const { getCachedValue, setCache } = useCacheContext();

    const cacheKey = `${cacheId}_item_name`;

    const options = getCachedValue(cacheKey) || [];

    const [query] = useAutoComplete(
        searchParam => InventoryAPI.getInventoryItems(organisationId, { search_param: searchParam }),
        {
            selector: res => get(res, INVENTORY_ITEMS),
            callback: res => setCache(cacheKey, res),
            disableInitialTrigger: options.length > 0,
        }
    );

    const selectProps = { ...input, ...restProps };
    const fieldError = touched && (error || warning);
    const validateStatus = fieldError ? 'error' : touched ? 'success' : loading ? 'validating' : '';

    return (
        <>
            <label>Item{required ? <span className="required-star">*</span> : null}</label>
            <Form.Item validateStatus={validateStatus} help={fieldError} hasFeedback={hasFeedback}>
                <AntAC
                    {...selectProps}
                    onChange={() => {}} //hack to fix the component calling onChange from inside the library
                    dataSource={[
                        ...options.map(x => ({
                            value: x.id,
                            text: (
                                <span>
                                    {x.code ? <Tag>{x.code}</Tag> : ''} {x.name}
                                </span>
                            ),
                        })),
                        // {
                        //     value: null,
                        //     text: <button>Add New</button>,
                        // },
                    ]}
                    onSearch={val => {
                        changeField(`name`, val);
                        query(val);
                    }}
                    onSelect={val => {
                        const item = find(options, { id: val });

                        if (!item) return;

                        changeField('inventory_id', item.id);
                        changeField('name', item.name);
                        changeField('description', item.description);
                        changeField('unit_price', item.unit_selling_price);
                        changeField('unit_of_measure', item.unit_of_measure);
                        changeField('tax_id', item.sales_tax_rate_id);
                        changeField('quantity', 1);
                        changeField('discount_type', item.discount_type);
                        changeField('discount', item.discount);
                    }}
                >
                    {/* <Option value="">--Select--</Option>
                {options.map(({ value, name }) => (
                    <Option key={value} value={value}>
                        {name}
                    </Option>
                ))} */}
                </AntAC>
            </Form.Item>
        </>
    );
};

export const TaxID = ({
    // info,
    // label,
    meta: { touched, error, warning },
    hasFeedback,
    loading,
    input,
    ...restProps
}) => {
    const { data: options } = useTaxes();
    const selectProps = { ...input, ...restProps };
    const fieldError = touched && (error || warning);
    const validateStatus = fieldError ? 'error' : touched ? 'success' : loading ? 'validating' : '';

    return (
        <>
            <label>Tax</label>
            <Form.Item validateStatus={validateStatus} help={fieldError} hasFeedback={hasFeedback}>
                <AntAC
                    {...selectProps}
                    dataSource={[
                        ...options.map(x => ({
                            value: x.id,
                            text: `${x.name} (${x.rate}%)`,
                        })),
                        // {
                        //     value: null,
                        //     text: <button>Add New</button>,
                        // },
                    ]}
                    filterOption={(inputValue, option) => {
                        if (typeof option.props.children === 'string')
                            return option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
                        return true;
                    }}
                ></AntAC>
            </Form.Item>
        </>
    );
};

const commonPropTypes = {
    className: PropTypes.string,
    hasFeedback: PropTypes.bool,
    // info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    input: PropTypes.object,
    label: PropTypes.string,
    loading: PropTypes.bool,
    meta: PropTypes.object,
    type: PropTypes.string,
};

Select.propTypes = { ...commonPropTypes, options: PropTypes.array };
Input.propTypes = { ...commonPropTypes };
InputNumber.propTypes = { ...commonPropTypes };
AutoComplete.propTypes = { ...commonPropTypes, dataSource: PropTypes.array };
ItemName.propTypes = { ...commonPropTypes };
TaxID.propTypes = { ...commonPropTypes };

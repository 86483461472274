/**
 * Arrangement due graph Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import React from 'react';
import { PropTypes } from 'prop-types';
import { DatePicker } from 'antd';
import { get, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

import Loader from '../../../shared/Loader';
import { arrangementsDueGraphOptions } from '../../../../includes/charts/arrangementDue';
import { ORGANISATION_IS_FETCHING_DATA, MIN, MAX } from '../../../../includes/constants/keys/response';
import { DASHBOARD } from 'includes/constants/permissions';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import useCurrencySymbol from 'includes/hooks/useCurrencySymbol';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
const { RangePicker } = DatePicker;

/**
 * Arrangement due graph Component
 */
const ArrangementDue = props => {
    const getCurrencySymbol = useCurrencySymbol();
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    /**
     * Get the arrangement due disabled dates to prevent the user from picking the dates from range picker
     **/
    const getArrangementDueDisabledDates = current => {
        let today = new Date();
        let futureMaxDay = today.setDate(today.getDate() + 90);
        return current && (current < new Date() || current > futureMaxDay);
    };

    /**
     * Get the arrangement due graph options
     * Adds the data points
     **/
    const getArrangementDueGraphOptions = () => {
        const currencySymbol = getCurrencySymbol();
        if (!isEmpty(props.arrangementDuePerDay)) {
            if (!isEmpty(props.arrangementDuePerDayViewPortMaxMinDates)) {
                let maxDate = get(props.arrangementDuePerDayViewPortMaxMinDates, MAX);
                let minDate = get(props.arrangementDuePerDayViewPortMaxMinDates, MIN);
                if (maxDate) {
                    arrangementsDueGraphOptions.axisX.viewportMaximum = maxDate;
                }
                if (minDate) {
                    arrangementsDueGraphOptions.axisX.viewportMinimum = minDate;
                }
            }

            arrangementsDueGraphOptions.data[0].dataPoints = Object.keys(props.arrangementDuePerDay).map(function(key) {
                return {
                    x: parseInt(key),
                    y: parseFloat(props.arrangementDuePerDay[key].amount),
                    accounts: parseInt(props.arrangementDuePerDay[key].count),
                    amountShort: props.arrangementDuePerDay[key].amount_short,
                };
            });
            arrangementsDueGraphOptions.toolTip.content = `${currencySymbol}{amountShort} from {accounts} accounts on {x}`;
        }
        return arrangementsDueGraphOptions;
    };

    /**
     * Check organisation data fetching status
     *
     * @returns {boolean}
     */
    const isFetchingData = () => !!get(props.selectedOrganisation, ORGANISATION_IS_FETCHING_DATA);

    /**
     * Load the arrangement due graph
     * Updates the data points
     **/
    const loadArrangementDueGraph = (date, dateList) => {
        props.getArrangementDueData(props.selectedOrganisationId, dateList);
    };

    const renderGraphView = () => {
        if (props.isGraphDataLoading || props.isArrangementDueDataLoading) {
            return <Loader />;
        }
        if (isEmpty(props.arrangementDuePerDay)) {
            return (
                <props.infoMessage
                    message={
                        isFetchingData()
                            ? props.t('customerMessages.dashboard.fetchingDataMessage')
                            : props.t('appCommonMessages.noDataToShow')
                    }
                    showLoader={isFetchingData()}
                />
            );
        }
        return (
            <>
                <props.canvasJSChart options={getArrangementDueGraphOptions()} />
                <div className="alert-info">
                    Arrangements Due:{' '}
                    <span
                        title={`${getNumberFormattedAsCurrency(get(props, 'arrangementDueTotal.amount', 0))} from ${get(
                            props,
                            'arrangementDueTotal.count',
                            0
                        )} accounts`}
                    >
                        {`${getNumberFormattedAsCurrency(get(props, 'arrangementDueTotal.amount_short', 0))} from ${get(
                            props,
                            'arrangementDueTotal.count',
                            0
                        )} accounts`}
                    </span>
                </div>
            </>
        );
    };

    return (
        <div className="box-wrapper white-box-wrapper half-wrapper arrangements-wrapper">
            <div className="box-title-wrapper">
                <h3 className="page-title">Arrangements Due</h3>
                {!props.isGraphDataLoading && !isFetchingData() ? (
                    <div className="right-align">
                        <h5>Date</h5>
                        <RangePicker
                            onChange={loadArrangementDueGraph}
                            disabledDate={getArrangementDueDisabledDates}
                            format="DD-MM-YYYY"
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
            <PermissionGuardLayout requiredPermission={DASHBOARD.GRAPH.ARRANGEMENTS_DUE} layout="section">
                {renderGraphView()}
            </PermissionGuardLayout>
        </div>
    );
};

/**
 * Proptypes
 */
ArrangementDue.propTypes = {
    arrangementDuePerDay: PropTypes.object,
    arrangementDuePerDayViewPortMaxMinDates: PropTypes.object,
    canvasJsChart: PropTypes.func,
    countDataAvailableMinDate: PropTypes.number,
    getArrangementDueData: PropTypes.func,
    isArrangementDueDataLoading: PropTypes.bool,
    isGraphDataLoading: PropTypes.bool,
    infoMessage: PropTypes.func,
    selectedOrganisation: PropTypes.object,
    selectedOrganisationId: PropTypes.string,
    t: PropTypes.func,
};

export default withTranslation()(ArrangementDue);

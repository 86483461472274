/**
 * HOC components for wrapping zendesk components
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAllowZendeskIntegration from 'includes/hooks/useAllowZendeskIntegration';

/**
 * Zendesk Integration enabled HOC
 */
const ZendeskIntegrationEnabled = ({ children, ...props }) => {
    const { t } = useTranslation();
    const allowZendeskIntegration = useAllowZendeskIntegration();

    /**
     * Render the component
     */
    return allowZendeskIntegration ? (
        <>{children}</>
    ) : props.showMessageOnly ? (
        <span className="feature-available-for-manual">{t('messages.zendesk.zendeskIntegrationNotEnabled')}</span>
    ) : (
        <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
            <div className="tab-arrow-link breadcrumb-spec">&nbsp;</div>
            <h2>&nbsp;</h2>
            <div className="white-box-wrapper mid-wrapper breadcrumb-spec details-wrapper">
                <span className="feature-available-for-manual">
                    {t('messages.zendesk.zendeskIntegrationNotEnabled')}
                </span>
            </div>
        </div>
    );
};

ZendeskIntegrationEnabled.propTypes = {
    children: PropTypes.any,
    showMessageOnly: PropTypes.bool,
};

export default ZendeskIntegrationEnabled;

/**
 * Payment plan form Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React, { useEffect } from 'react';
import { addPaymentPlan, getPaymentPlan, updatePaymentPlan } from 'includes/slices/paymentArrangement';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';
import { redirect } from 'includes/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import Loader from 'components/shared/Loader';
import PaymentPlanForm from './components/Form';
import PaymentPlansBreadCrumbItems from 'components/BreadCrumbItems/PaymentPlans';
import PropTypes from 'prop-types';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { PAYMENT_ARRANGEMENT_PLAN_TYPE_ARRANGEMENT } from 'includes/constants';
import { PAYMENT_ARRANGEMENT_PLANS } from 'includes/constants/permissions';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import { getExpenseCategories } from 'includes/slices/openBanking';
import * as requestKeys from 'includes/constants/keys/request';

const PaymentPlanAddEdit = props => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    useUpdateBusinessName();
    const { paymentPlanId, planType } = params;
    const accountId = useAccountId();
    const paymentPlanDetails = useSelector(state => state.paymentArrangement.paymentPlan);
    const isAdd = props.action === 'add';
    const isAdmin = useIsAdminOrManager();
    const loadingPaymentPlan = useSelector(state => state.paymentArrangement.loadingPaymentPlan);
    const loadingExpenseCategories = useSelector(state => state.openBanking.isExpenseCategoriesLoading);
    const organisationId = useOrganisationId();
    const isArrangement = planType
        ? planType === 'arrangement'
        : get(paymentPlanDetails, 'plan_type') === PAYMENT_ARRANGEMENT_PLAN_TYPE_ARRANGEMENT;

    /**
     * Get the payment arrangement plan details on load
     */
    useEffect(() => {
        if (!isAdd) {
            dispatch(getPaymentPlan(organisationId, paymentPlanId));
        }
        // commented out since we disabled open banking
        dispatch(getExpenseCategories());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handle the submit of add/edit of payment arrangement plan
     */
    const handleSubmit = values => {
        if (!values[requestKeys.PAYMENT_PLAN_OPEN_BANKING_ENABLED]) {
            delete values[requestKeys.PAYMENT_PLAN_OPEN_BANKING_CALCULATION_TYPE];
            delete values[requestKeys.PAYMENT_PLAN_OPEN_BANKING_BUFFER];
            delete values[requestKeys.PAYMENT_PLAN_OPEN_BANKING_EXCEPTION_PERCENTAGE_LIST];
        }
        if (isAdd) {
            values['plan_type'] = isArrangement ? 2 : 1;
        }

        if (values?.exception_percentage_list && !Array.isArray(values?.exception_percentage_list)) {
            const exceptionPercentageList = [];
            Object.keys(values?.exception_percentage_list).map(categoryId => {
                if (values?.exceptionCategoriesMap[categoryId] !== values?.exception_percentage_list[categoryId]) {
                    exceptionPercentageList.push({
                        yodlee_category_id: categoryId,
                        exception_percentage: parseInt(values?.exception_percentage_list[categoryId]),
                    });
                }
            });
            values.exception_percentage_list = exceptionPercentageList;
        }
        isAdd
            ? dispatch(
                  addPaymentPlan(organisationId, { ...values, exceptionCategoriesMap: null }, redirectToDetailsPage)
              )
            : dispatch(
                  updatePaymentPlan(
                      organisationId,
                      get(paymentPlanDetails, 'id'),
                      { ...values, exceptionCategoriesMap: null },
                      redirectToDetailsPage
                  )
              );
    };

    /**
     * Redirect to payment arrangement details page
     *
     * @param {string} paymentPlanId Payment plan id
     */
    const redirectToDetailsPage = paymentPlanId => {
        redirect(
            isAdmin
                ? `/admin/accounts/organisation/payment-plans/plan/${accountId}/${organisationId}/${paymentPlanId}`
                : `/payment-plans/plan/${paymentPlanId}`
        );
    };

    return (
        <PermissionGuardLayout
            requiredPermission={
                isArrangement
                    ? isAdd
                        ? PAYMENT_ARRANGEMENT_PLANS.ARRANGEMENT.ADD
                        : PAYMENT_ARRANGEMENT_PLANS.ARRANGEMENT.EDIT
                    : isAdd
                    ? PAYMENT_ARRANGEMENT_PLANS.EXTENSION.ADD
                    : PAYMENT_ARRANGEMENT_PLANS.EXTENSION.EDIT
            }
            layout="section"
        >
            <div className="home-content-wrapper edit-profile-wrapper">
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <PaymentPlansBreadCrumbItems
                            action={props.action}
                            isArrangement={isArrangement}
                            showAddEditBreadCrumb
                            showDetailsBreadCrumb={!isAdd}
                            showDetailsBreadCrumbAsLink
                            showListBreadCrumb
                        />
                    </Breadcrumb>
                </div>
                <h2 className="page-title">{t(`titleAndMetas.paymentPlan${isAdd ? 'Add' : 'Edit'}.pageTitle`)}</h2>
                <div className="white-box-wrapper mid-wrapper">
                    {loadingPaymentPlan || loadingExpenseCategories ? (
                        <Loader tip={t('appCommonMessages.loadingMessage')} />
                    ) : (
                        <PaymentPlanForm
                            enableReinitialize
                            initialValues={
                                isAdd
                                    ? {
                                          allow_notes: true,
                                          is_enabled: true,
                                          max_duration_type: 'y',
                                          max_first_payment_date_duration_type: 'd',
                                          max_payment_date_extension_duration_type: 'd',
                                          max_unpaid_installment_cancel_duration_type: 'd',
                                          max_upfront_amount_duration_type: 'd',
                                          max_upfront_payment_cancel_duration_type: 'd',
                                          overdue_range_type: 'b',
                                          payment_frequency_type: 'd',
                                          upfront_amount: [10, 95],
                                          ...(isArrangement
                                              ? {
                                                    open_banking_enabled: true,
                                                    calculation_type: 2,
                                                }
                                              : {}),
                                      }
                                    : {
                                          upfront_amount: [10, 95],
                                          ...paymentPlanDetails,
                                      }
                            }
                            isAdd={isAdd}
                            onSubmit={handleSubmit}
                            isExtensionPlan={!isArrangement}
                        />
                    )}
                </div>
            </div>
        </PermissionGuardLayout>
    );
};

/**
 * PropTypes
 */
PaymentPlanAddEdit.propTypes = {
    action: PropTypes.string,
};

export default PaymentPlanAddEdit;

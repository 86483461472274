import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { find, get } from 'lodash';

import ServiceCard from '../../components/PaymentGateways/components/ServiceCard';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { getPaymentGateways, setOrganisationPaymentGateways } from 'includes/slices/paymentGateways';
import { PAYMENT_SERVICE, PAYMENT_SERVICE_RENDER_FIELD_MAP } from '../../components/PaymentGateways/constants';
import OrganisationPaymentGateways from '../../components/PaymentGateways/components/OrganisationPaymentGateways';
import Loader from 'components/shared/Loader';
import { buildQueryParamsObject, redirect } from 'includes/utils';
import { showAppNotificationMessage, showAppNotificationModal } from 'includes/redux/actions/shared/appNotification';
import paymentGatewayServices from 'includes/services/shared/paymentGateways';
import { API_RESPONSE_MESSAGE_STRUCTURE, GET_PAYMENT_GATEWAYS } from 'includes/constants/mappings/success';
import { showApiErrors } from 'includes/utils/api';
import PaycepaidLogo from 'assets/images/paycepaid.svg';
import ConnectingArrowSVG from 'assets/images/connecting_arrow_loader.svg';
import WhiteBoxWrapper from 'components/WhiteBoxWrapper';
import useGetPermissionWrappedFunction from 'includes/hooks/useGetPermissionWrappedFunction';
import { PAYMENT_GATEWAY } from 'includes/constants/permissions';
import { showPermissionDeniedModal } from 'components/PermissionGuard/component';

const { Title } = Typography;

const PaymentServiceSlug = {
    GoCardless: 'gocardless',
    Stripe: 'stripe',
};

/**
 * Payment services listing component
 *
 * @since 2.8.0
 */
export default function PaymentServices() {
    const [hasInitializedConnect, setHasInitializedConnect] = useState(false);

    const { t } = useTranslation();

    const organisationId = useOrganisationId();

    const paymentGateways = useSelector(state => state.paymentGateways.paymentGateways);

    const loadingPaymentGateways = useSelector(state => state.paymentGateways.loadingPaymentGateways);

    const { paymentServiceSlug } = useParams();

    const location = useLocation();

    const history = useHistory();

    const [isConnectingModalOpen, setIsConnectingModalOpen] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPaymentGateways());
        if (paymentServiceSlug) setIsConnectingModalOpen(true);
    }, []);

    useEffect(() => {
        async function connectPaymentService() {
            if (!(paymentGateways.length > 0) || !organisationId || !paymentServiceSlug || hasInitializedConnect)
                return;

            const paymentGateway = find(paymentGateways, { slug: paymentServiceSlug });

            const urlParams = buildQueryParamsObject(location.search);

            if (!urlParams.code || !urlParams.state) {
                redirect('/payment-services');
                dispatch(
                    showAppNotificationModal('error', t('customerMessages.paymentServices.oauthHandler.brokenUrl'))
                );
            }

            switch (paymentServiceSlug) {
                case PaymentServiceSlug.Stripe:
                case PaymentServiceSlug.GoCardless: {
                    try {
                        setHasInitializedConnect(true);
                        const result = await paymentGatewayServices.connectPaymentService(
                            { code: urlParams.code, state: urlParams.state },
                            paymentGateway.id,
                            organisationId
                        );
                        dispatch(showAppNotificationMessage('success', get(result, API_RESPONSE_MESSAGE_STRUCTURE)));
                        dispatch(setOrganisationPaymentGateways(get(result, GET_PAYMENT_GATEWAYS)));
                    } catch (errors) {
                        dispatch(
                            showApiErrors(errors, undefined, undefined, undefined, ['code', 'state', 'payment_gateway'])
                        );
                    } finally {
                        setIsConnectingModalOpen(false);
                        history.replace('/payment-services');
                    }
                    break;
                }
            }
        }

        connectPaymentService();
    }, [paymentServiceSlug, paymentGateways, location, organisationId]);

    const connectingPaymentService = useMemo(() => {
        return PAYMENT_SERVICE[paymentServiceSlug];
    }, [paymentServiceSlug]);

    /**
     * Handles view menu click of payment service card
     *
     * @param {string} paymentGatewayId payment service ID
     */
    const handlePaymentServiceView = useGetPermissionWrappedFunction(
        PAYMENT_GATEWAY.VIEW,
        paymentGatewayId => {
            redirect(`/payment-service/${paymentGatewayId}`);
        },
        showPermissionDeniedModal
    );

    return (
        <div className="home-content-wrapper container mx-auto">
            <h2 className="page-title">{t('titleAndMetas.paymentServices.title')}</h2>

            <Modal closable={false} footer={null} maskClosable={false} visible={isConnectingModalOpen}>
                <div className="container grid grid-cols-3 items-center justify-items-center">
                    <img src={connectingPaymentService?.logo} style={{ height: 60, width: 60 }} />
                    <div className="flex flex-col items-center">
                        <object data={ConnectingArrowSVG} width={50} height={50} />
                        <div>{`Connecting to ${connectingPaymentService?.name}...`}</div>
                    </div>
                    <img src={PaycepaidLogo} style={{ height: 60, width: 60 }} />
                </div>
            </Modal>

            <OrganisationPaymentGateways handleView={handlePaymentServiceView} organisationId={organisationId} />

            <WhiteBoxWrapper className="my-8">
                <Title className={'text-left pb-4'} level={4}>
                    {t('customerMessages.paymentServices.addNewPaymentService')}
                </Title>
                {loadingPaymentGateways ? (
                    <Loader />
                ) : (
                    <div className="grid md:grid-cols-3 gap-4 mx-4">
                        {paymentGateways.map(gateway => {
                            return (
                                <ServiceCard
                                    isOauthEnabled={gateway.is_oauth_enabled}
                                    key={gateway.id}
                                    paymentServiceId={gateway.id}
                                    poweredBy={`by ${gateway.name}`}
                                    serviceName={gateway.label}
                                    slug={gateway.parent_slug}
                                    RenderFieldsComponent={PAYMENT_SERVICE_RENDER_FIELD_MAP[gateway.parent_slug]}
                                />
                            );
                        })}
                    </div>
                )}
            </WhiteBoxWrapper>
        </div>
    );
}

import React from 'react';
import PropTypes from 'prop-types';

export default function CommunicationMethodLabel({ children, btnClassName }) {
    return (
        // override width and display in the class but maintain colour, icon background
        <div className="tag-linker table-button-wrapper" style={{ width: '100%', display: 'block' }}>
            <button className={btnClassName} style={{ marginRight: 0 }}>
                {children}
            </button>
        </div>
    );
}

CommunicationMethodLabel.propTypes = {
    children: PropTypes.node.isRequired,
    btnClassName: PropTypes.string.isRequired,
};

/**
 * Admin settings API class.
 * Handles all api requests related to settings
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class SettingsApi extends BaseApiHandler {
    /**
     * Call the settings field listing API
     *
     * @returns {object} API Response
     * */
    getSettings = () => this.axios.get(this.getApiUrl('GET_SETTINGS'));

    /**
     * Call the update settings API
     *
     * @returns {object} API Response
     * */
    updateSettings = () => this.axios.get(this.getApiUrl(''));
}

export default new SettingsApi();

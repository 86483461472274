/**
 * Route validators
 * Handles the functions that needs to be validated during routing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import { React, Redirect } from '../../exports/react';
import * as constants from '../../constants';
import * as utils from '../../utils';
import { ACCESS_TOKEN_COOKIE_NAME } from 'includes/constants/config';

/**
 * Token Validation Class
 * Defines the functions which runs the validations in route
 *
 * When defining the validation function make sure to specify the Key for the return function since react
 * expects key in every mapping returned. Also the return should be either the validator or boolean false
 */
class RouteValidators {
    /**
     * Get the user roles from local storage
     *
     * @returns  {object}    User Roles
     */
    getUserRoles = () => JSON.parse(utils.getLocalStorageValue(constants.USER_ROLES_KEY)) || {};

    /**
     * Check if role is valid
     *
     * @param   {Array}     roles           Roles
     *
     * @returns {function(): (boolean|*)}
     */
    // eslint-disable-next-line react/display-name
    isRoleValid = (roles = []) => () => {
        const userRole = this.getUserRoles()[utils.getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY)];
        const redirectUrl = userRole
            ? [constants.USER_ROLE_ADMIN, constants.USER_ROLE_ADMIN_MANAGER].includes(userRole)
                ? '/admin/page-not-found'
                : '/page-not-found'
            : '/page-not-found';
        return (
            (!userRole || !roles.includes(userRole.slug)) && (
                <Redirect key="isDebtCollector" to={{ pathname: redirectUrl }} />
            )
        );
    };

    /**
     * Validates the token
     * Redirects to login if token not found
     *
     * @returns {boolean|*}
     */
    isTokenExists() {
        let token = utils.getCookieValue(ACCESS_TOKEN_COOKIE_NAME);

        if (!token) {
            const authPortalUrl = new URL(utils.getAuthPortalUrl());

            authPortalUrl.searchParams.append('return_url', encodeURIComponent(window.location.href));

            window.open(authPortalUrl.href, '_self');
        }

        return false;

        // if (!token) return !token && <Redirect key="isTokenExists" to={{ pathname: '/' }} />;
    }

    /**
     * Check if the user is logged in
     * Check for token for user redirect
     * Redirects to dashboard if token exists
     *
     * @returns {boolean|*}
     */
    isUserLoggedIn = () => {
        let token = utils.getCookieValue(ACCESS_TOKEN_COOKIE_NAME);
        const userRole = this.getUserRoles()[utils.getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY)];

        return (
            token && (
                <Redirect
                    key="isUserLoggedIn"
                    to={{
                        pathname: `/${
                            userRole &&
                            [constants.USER_ROLE_ADMIN, constants.USER_ROLE_ADMIN_MANAGER].includes(userRole.slug)
                                ? `admin/`
                                : ''
                        }dashboard`,
                    }}
                />
            )
        );
    };
}

export default new RouteValidators();

export const ACCOUNT_INFORMATION = {
    EDIT: 'account_information:edit',
    VIEW: 'account_information:view',
};

export const ACCOUNTING_SOFTWARE = {
    CONNECT: 'accounting_software:connect',
};

export const AUDIT_LOG = {
    LIST: 'audit_log:list',
};

export const INVOICE = {
    ADD: 'invoice:add',
    DELETE: 'invoice:delete',
    DOWNLOAD: 'invoice:download',
    EDIT: 'invoice:edit',
    EXPORT: 'invoice:export',
    IMPORT: 'invoice:import',
    LIST: 'invoice:list',
    ON_DEMAND_CAMPAIGN: {
        HISTORY: 'invoice:on_demand_campaign:history',
        SEND: 'invoice:on_demand_campaign:send',
    },
    PAYMENT: {
        ADD: 'invoice:payment:add',
        DELETE: 'invoice:payment:delete',
        EDIT: 'invoice:payment:edit',
        EXPORT: 'invoice:payment:export',
        IMPORT: 'invoice:payment:import',
        LIST: 'invoice:payment:list',
        VIEW: 'invoice:payment:view',
    },
    PREVIEW: 'invoice:preview',
    SEND: 'invoice:send',
    VIEW: 'invoice:view',
};

export const ORGANISATION = {
    ADD: 'organisation:add',
    DELETE: 'organisation:delete',
    EDIT: 'organisation:edit',
    EXPORT: 'organisation:export',
    LIST: 'organisation:list',
    VIEW: 'organisation:view',
    SETTINGS: {
        EDIT: 'organisation:settings:edit',
    },
};

export const PAYMENT_ARRANGEMENT = {
    ADD: 'payment_arrangement:add',
    EDIT: 'payment_arrangement:edit',
    LIST: 'payment_arrangement:list',
    VIEW: 'payment_arrangement:view',
    INSTALLMENTS: {
        CANCEL: 'payment_arrangement:installments:cancel',
        PAY: 'payment_arrangement:installments:pay',
        LIST: 'payment_arrangement:installments:list',
        REGENERATE: 'payment_arrangement:installments:regenerate',
        VIEW: 'payment_arrangement:installments:view',
    },
    NOTES: {
        ADD: 'payment_arrangement:notes:add',
        DELETE: 'payment_arrangement:notes:delete',
        EDIT: 'payment_arrangement:notes:edit',
        LIST: 'payment_arrangement:notes:list',
    },
    PAY_FULL: 'payment_arrangement:pay_full',
};

export const PAYMENT_EXTENSION = {
    ADD: 'payment_extension:add',
    EDIT: 'payment_extension:edit',
    LIST: 'payment_extension:list',
    VIEW: 'payment_extension:view',
};

export const PAYMENT_ARRANGEMENT_PLANS = {
    ARRANGEMENT: {
        ADD: 'payment_plan:arrangement:add',
        DELETE: 'payment_plan:arrangement:delete',
        EDIT: 'payment_plan:arrangement:edit',
        LIST: 'payment_plan:arrangement:list',
        VIEW: 'payment_plan:arrangement:view',
    },
    EXTENSION: {
        ADD: 'payment_plan:extension:add',
        DELETE: 'payment_plan:extension:delete',
        EDIT: 'payment_plan:extension:edit',
        LIST: 'payment_plan:extension:list',
        VIEW: 'payment_plan:extension:view',
    },
};

export const PAYMENT_METHOD = {
    ADD: 'payment_method:add',
    DELETE: 'payment_method:delete',
    EDIT: 'payment_method:edit',
    LIST: 'payment_method:list',
    VIEW: 'payment_method:view',
};

export const PAYMENT_BANK_ACCOUNT = {
    ADD: 'payment_bank_account:add',
    DELETE: 'payment_bank_account:delete',
    EDIT: 'payment_bank_account:edit',
    LIST: 'payment_bank_account:list',
};

export const PAYMENT_GATEWAY = {
    ADD: 'payment_gateway:add',
    DELETE: 'payment_gateway:delete',
    EDIT: 'payment_gateway:edit',
    VIEW: 'payment_gateway:view',
    LIST: 'payment_gateway:provider:list',
};

export const REMINDER = {
    HISTORY: 'reminder:history',
    LIST: 'reminder:list',
    PREVIEW: 'reminder:preview',
    SEND: 'reminder:send',
};

export const DASHBOARD = {
    VIEW: 'dashboard:view',
    GRAPH: {
        ARRANGEMENTS_DUE: 'dashboard:graph:arrangements_due',
        PAYMENT_OVERVIEW: 'dashboard:graph:payment_overview',
        INVOICE_CURRENCY_BREAKDOWN: 'dashboard:graph:invoice_currency_breakdown',
        TOTAL_CASH_COLLECTED: 'dashboard:graph:total_cash_collected',
    },
};

export const CONTACT = {
    ADD: 'contact:add',
    DELETE: 'contact:delete',
    EDIT: 'contact:edit',
    EXPORT: 'contact:export',
    IMPORT: 'contact:import',
    LIST: 'contact:list',
    VIEW: 'contact:view',
};

export const CONTACT_TYPE = {
    ADD: 'contact_type:add',
    DELETE: 'contact_type:delete',
    EDIT: 'contact_type:edit',
    LIST: 'contact_type:list',
};

export const CAMPAIGN = {
    ADD: 'campaign:add',
    DELETE: 'campaign:delete',
    EDIT: 'campaign:edit',
    LIST: 'campaign:list',
    ENABLE_DISABLE: 'campaign:disable_enable',
    VIEW: 'campaign:view',
};

export const CHANGE_PASSWORD = 'change_password';

export const CHART_OF_ACCOUNT = {
    ADD: 'chart_of_account:add',
    DELETE: 'chart_of_account:delete',
    EDIT: 'chart_of_account:edit',
    LIST: 'chart_of_account:list',
    VIEW: 'chart_of_account:view',
    TRANSACTION: {
        LIST: 'chart_of_account:transaction:list',
    },
};

export const CONTACT_MANAGER = {
    ADD: 'contact_manager:add',
    DELETE: 'contact_manager:delete',
    EDIT: 'contact_manager:edit',
    EXPORT: 'contact_manager:export',
    IMPORT: 'contact_manager:import',
    LIST: 'contact_manager:list',
    VIEW: 'contact_manager:view',
};

export const ORGANISATION_CURRENCY = {
    ADD: 'currency:add',
    DELETE: 'currency:delete',
    EDIT: 'currency:edit',
    LIST: 'currency:list',
};

export const DEBTOR_ACCOUNT = {
    LIST: 'debtor_account:list',
};

export const EXPORT = {
    DOWNLOAD: 'export:download',
    DELETE: 'export:delete',
    LIST: 'export:list',
};

export const FOLLOWUPS = {
    DELETE: 'followups:delete',
    EDIT: 'followups:edit',
    LIST: 'followups:list',
};

export const NOTE = {
    ADD: 'note:add',
    EDIT: 'note:edit',
    LIST: 'note:list',
};

export const INVENTORY = {
    ADD: 'inventory:add',
    DELETE: 'inventory:delete',
    EDIT: 'inventory:edit',
    LIST: 'inventory:list',
    VIEW: 'inventory:view',
    EXPORT: 'inventory:export',
    TRANSACTION: {
        LIST: 'inventory:transaction:list',
    },
};

export const PLACEHOLDER = {
    ADD: 'placeholder:add',
    DELETE: 'placeholder:delete',
    EDIT: 'placeholder:edit',
    LIST: 'placeholder:list',
};

export const RECURRING_INVOICE = {
    ADD: 'recurring_invoice:add',
    DELETE: 'recurring_invoice:delete',
    DOWNLOAD: 'recurring_invoice:download',
    EDIT: 'recurring_invoice:edit',
    PREVIEW: 'recurring_invoice:preview',
    LIST: 'recurring_invoice:list',
    VIEW: 'recurring_invoice:view',
};

export const REPORTING = {
    DEBT_AGE: {
        EXPORT: 'reporting:debt_age:export',
    },
    LIST: 'reporting:list',
    PAYMENT_TERMS: {
        EXPORT: 'reporting:payment_terms:export',
    },
};

export const SUBSCRIPTION = {
    LIST: 'subscription:list',
    UPGRADE: 'subscription:upgrade',
    VIEW: 'subscription:view',
};

export const TAX = {
    ADD: 'tax:add',
    DELETE: 'tax:delete',
    EDIT: 'tax:edit',
    LIST: 'tax:list',
    VIEW: 'tax:view',
};

export const TEMPLATE = {
    EMAIL: {
        ADD: 'template:email:add',
        DELETE: 'template:email:delete',
        EDIT: 'template:email:edit',
        LIST: 'template:email:list',
    },
    IVR: {
        ADD: 'template:ivr:add',
        DELETE: 'template:ivr:delete',
        EDIT: 'template:ivr:edit',
        LIST: 'template:ivr:list',
    },
    SMS: {
        ADD: 'template:sms:add',
        DELETE: 'template:sms:delete',
        EDIT: 'template:sms:edit',
        LIST: 'template:sms:list',
    },
    WHATSAPP: {
        ADD: 'template:whatsapp:add',
        DELETE: 'template:whatsapp:delete',
        EDIT: 'template:whatsapp:edit',
        LIST: 'template:whatsapp:list',
    },
};

export const USER = {
    ADD: 'user:add',
    DELETE: 'user:delete',
    EDIT: 'user:edit',
    LIST: 'user:list',
};

export const ZENDESK = {
    SETTINGS: {
        SAVE: 'zendesk:settings:save',
        VIEW: 'zendesk:settings:view',
    },
    TICKET: {
        LIST: 'zendesk:ticket:list',
        VIEW: 'zendesk:ticket:view',
    },
};

export const ORDER = {
    INVOICE: {
        LIST: 'order:invoice:list',
        VIEW: 'order:invoice:view',
        DOWNLOAD: 'order:invoice:download',
        DELETE: 'order:invoice:delete',
    },
    LEGAL_LETTER: {
        DOWNLOAD: 'order:legal_letter:download',
        LIST: 'order:legal_letter:list',
        VIEW: 'order:legal_letter:view',
        DELETE: 'order:legal_letter:delete',
    },
    SUBSCRIPTION: {
        DOWNLOAD: 'order:subscription:download',
        LIST: 'order:subscription:list',
        VIEW: 'order:subscription:view',
        ADD: 'order:subscription:add',
        DELETE: 'order:subscription:delete',
    },
};

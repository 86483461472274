/**
 * Inventory Slice
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */
import * as successMappings from 'includes/constants/mappings/success';
import createAppSlice from './base';
import InventoryApi from 'includes/services/inventory';
import { get } from 'lodash';
import { getPaginationData, redirect } from 'includes/utils';
import { ID } from '../constants/keys/response';
import { INVENTORY_ITEM_TRANSACTIONS } from 'includes/constants/mappings/success';
import { showApiErrors, showApiSuccess } from 'includes/utils/api';

const initialState = {
    inventoryItem: {},
    inventoryItems: [],
    inventoryItemsPagination: {},
    inventoryItemTransactions: [],
    inventoryItemTransactionsPagination: {},
    loadingInventoryItem: false,
    loadingInventoryItemAddUpdate: false,
    loadingInventoryItemDelete: false,
    loadingInventoryItems: false,
    loadingInventoryItemTransactions: false,
};

// define the slice
const InventorySlice = createAppSlice('inventorySlice', initialState);
const { setData } = InventorySlice.actions;

/**
 * Add an inventory item
 *
 * @param {string} organisationId Organisation Id
 * @param {object} inventoryDetails Inventory item Details
 * @param {Function} getRedirectUrl Function to get redirect url
 */
export const addInventoryItem = (organisationId, inventoryDetails, getRedirectUrl) => async dispatch => {
    try {
        dispatch(setData('loadingInventoryItemAddUpdate', true));
        const result = await InventoryApi.addInventoryItem(organisationId, inventoryDetails);
        const inventoryId = get(result, [...successMappings.INVENTORY_ITEM, ID]);
        dispatch(showApiSuccess(result));
        redirect(getRedirectUrl(inventoryId));
    } catch (errors) {
        dispatch(showApiErrors(errors, 'INVENTORY_FORM_NAME', 'INVENTORY_ITEM'));
    } finally {
        dispatch(setData('loadingInventoryItemAddUpdate', false));
    }
};

/**
 * Delete an inventory item
 *
 * @param {string} organisationId Organisation Id
 * @param {string} itemId Inventory item Id
 */
export const deleteInventoryItem = (organisationId, itemId) => async dispatch => {
    try {
        dispatch(setData('loadingInventoryItemDelete', true));
        const result = await InventoryApi.deleteInventoryItem(organisationId, itemId);
        dispatch(setData('inventoryItems', get(result, successMappings.INVENTORY_ITEMS)));
        dispatch(setData('inventoryItemsPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'INVENTORY_ITEMS'));
    } finally {
        dispatch(setData('loadingInventoryItemDelete', false));
    }
};

/**
 * Get an inventory item
 *
 * @param {string} organisationId organisation Id
 * @param {string} inventoryItemId Inventory item Id
 */
export const getInventoryItem = (organisationId, inventoryItemId) => async dispatch => {
    try {
        dispatch(setData('loadingInventoryItem', true));
        const result = await InventoryApi.getInventoryItem(organisationId, inventoryItemId);
        dispatch(setData('inventoryItem', get(result, successMappings.INVENTORY_ITEM)));
    } catch (errors) {
        dispatch(showApiErrors(errors, 'INVENTORY_FORM_NAME', 'INVENTORY_ITEM'));
    } finally {
        dispatch(setData('loadingInventoryItem', false));
    }
};

/**
 * Get the inventory items
 *
 * @param {string} organisationId organisation Id
 * @param {object} searchParams search params. Default {}
 */
export const getInventoryItems = (organisationId, searchParams = {}) => async dispatch => {
    try {
        dispatch(setData('loadingInventoryItems', true));
        const result = await InventoryApi.getInventoryItems(organisationId, searchParams);
        dispatch(setData('inventoryItems', get(result, successMappings.INVENTORY_ITEMS)));
        dispatch(setData('inventoryItemsPagination', getPaginationData({ result })));
    } finally {
        dispatch(setData('loadingInventoryItems', false));
    }
};

/**
 * Get the inventory item transactions
 *
 * @param {string} inventoryItemId Inventory Item id.
 * @param {string} organisationId Organisation Id
 * @param {object} searchParams Search Params. Default {}
 */
export const getInventoryItemTransactions = (inventoryItemId, organisationId, searchParams = {}) => async dispatch => {
    try {
        dispatch(setData('loadingInventoryItemTransactions', true));
        const result = await InventoryApi.getInventoryItemsTransactions(inventoryItemId, organisationId, searchParams);
        dispatch(setData('inventoryItemTransactions', get(result, INVENTORY_ITEM_TRANSACTIONS)));
        dispatch(setData('inventoryItemTransactionsPagination', getPaginationData({ result })));
    } finally {
        dispatch(setData('loadingInventoryItemTransactions'));
    }
};

/**
 * Update an inventory item
 *
 * @param {string} organisationId Organisation Id
 * @param {string} inventoryId Inventory Item Id to update
 * @param {object} inventoryDetails Inventory item Details
 * @param {Function} getRedirectUrl Function to get redirect url
 */
export const updateInventoryItem = (
    organisationId,
    inventoryId,
    inventoryDetails,
    getRedirectUrl
) => async dispatch => {
    try {
        dispatch(setData('loadingInventoryItemAddUpdate', true));
        const result = await InventoryApi.updateInventoryItem(organisationId, inventoryId, inventoryDetails);
        dispatch(showApiSuccess(result));
        redirect(getRedirectUrl(inventoryId));
    } catch (errors) {
        dispatch(showApiErrors(errors, 'INVENTORY_FORM_NAME', 'INVENTORY_ITEM'));
    } finally {
        dispatch(setData('loadingInventoryItemAddUpdate', false));
    }
};

export default InventorySlice.reducer;

/**
 * Login page Component
 * Handles the login page view
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import the required modules
import React from 'react';

import './styles.scss';
import RedirectingPageLoaderSection from 'components/RedirectingPageLoaderSection';
import useRedirectToAuthPortalRoute from 'includes/hooks/useRedirectToAuthPortalRoute';

/**
 * Login page Component
 */
const LoginPage = () => {
    useRedirectToAuthPortalRoute('login');

    /**
     * Render the Login page page
     *
     * @returns    {string}    Login page view
     */
    return (
        <section className="home-wrapper">
            <RedirectingPageLoaderSection />
        </section>
    );
};

export default LoginPage;

/**
 * Settings Saga
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import the required modules
import * as actionTypes from '../../../../constants/actionTypes';
import * as constants from '../../../../constants';
import * as responseKeys from '../../../../constants/keys/response';
import * as successMappings from '../../../../constants/mappings/success';
import BaseSagaHandler from '../../core/base';
import SettingsApi from '../../../../services/shared/settings';
import { call, takeLatest, all, put } from 'redux-saga/effects';
import { get, isEmpty } from 'lodash';
import { getLocalStorageValue, redirect, setDataInLocalStorage } from '../../../../utils';
import {
    GET_REAUTHORIZE_ORGANISATIONS_COUNT_SUCCESS,
    GET_SELECTED_ORGANISATION_SUCCESS,
} from '../../../../constants/actionTypes';

/**
 * Settings Saga Class. Handles the settings related operations
 * */
class Settings extends BaseSagaHandler {
    /**
     * The Settings Watcher Saga
     * Watches the redux actions related to settings and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *settingsWatchers() {
        let context = this;
        yield all([
            yield takeLatest(actionTypes.GET_INITIAL_DATA_REQUEST, [context, 'getInitialData']),
            yield takeLatest(actionTypes.GET_ALL_PERMISSIONS_REQUEST, [context, 'getAllPermissions']),
        ]);
    }

    *getAllPermissions() {
        try {
            const result = yield call(SettingsApi.getAllPermissions);

            yield put({ type: actionTypes.GET_ALL_PERMISSIONS_SUCCESS, result });
        } catch (errors) {
            console.log({ errors });
        }
    }

    /**
     * Get the initial data
     *
     * @param   {object}    action    Action
     *
     * @yields {object} Initial data response
     */
    *getInitialData(action) {
        try {
            const result = yield call(SettingsApi.getInitialData, action.payload);
            const roles = get(result, 'data.data.initial_data.user_details.roles', {});

            const accounts = get(result, 'data.data.initial_data.user_details.accounts', {});
            const accountId = Object.keys(accounts)[0];

            const isAdmin = get(result, 'data.data.initial_data.user_details.is_admin', false);

            const dataToBeWrittenToLS = {};
            // accountId = Object.keys(accounts)[0];
            dataToBeWrittenToLS[constants.IS_ADMIN] = JSON.stringify(isAdmin);
            dataToBeWrittenToLS[constants.USER_CURRENT_ACCOUNT_KEY] = accountId;
            dataToBeWrittenToLS[constants.USER_ROLES_KEY] = JSON.stringify(roles);
            setDataInLocalStorage(dataToBeWrittenToLS);

            yield put({ type: actionTypes.GET_USER_PROFILE_DETAILS_FROM_INITIAL_DATA_SUCCESS, result, roles });
            yield put({ type: actionTypes.GET_ACCOUNT_DETAILS_FROM_INITIAL_DATA_SUCCESS, result });

            let selectedOrganisation = get(result, successMappings.GET_SELECTED_ORGANISATION);
            let selectedOrganisationId = get(selectedOrganisation, responseKeys.ID);

            // Check if user is logged in and no organisation and user is of custom role
            if (
                // user is logged in
                accountId &&
                // selectedOrganisation is empty
                isEmpty(selectedOrganisation) &&
                // role is not admin, admin manager, customer or customer-admin
                ![
                    constants.USER_ROLE_ADMIN,
                    constants.USER_ROLE_ADMIN_MANAGER,
                    constants.USER_ROLE_CUSTOMER,
                    constants.USER_ROLE_CUSTOMER_MANAGER,
                ].includes(roles[accountId].slug)
            ) {
                redirect('/no-organisation');
            }
            //const subdomain = get(selectedOrganisation, 'subdomain', '');
            //const defaultDomain = get(result, 'data.data.initial_data.settings.default_domain', '');

            // redirect to subdomain if in main app
            // subdomain &&
            //     !window.location.host.split('.', 1).includes(subdomain) &&
            //     window.open(`${process.env.REACT_APP_PROTOCOL}://${subdomain}.${defaultDomain}`, '_self');
            // if we have the selected organisation id and the current set selected organisation is not equal to the one
            // received from the service, set the new id
            if (
                selectedOrganisationId &&
                getLocalStorageValue(constants.USER_SELECTED_ORGANISATION_ID_KEY) !== selectedOrganisationId
            ) {
                setDataInLocalStorage({
                    [constants.USER_SELECTED_ORGANISATION_ID_KEY]: selectedOrganisationId,
                });
            }

            yield put({
                type: GET_SELECTED_ORGANISATION_SUCCESS,
                selectedOrganisation: selectedOrganisation,
                selectedOrganisationId: selectedOrganisationId,
            });
            yield put({
                type: GET_REAUTHORIZE_ORGANISATIONS_COUNT_SUCCESS,
                reauthorizeOrganisationsCount: get(result, successMappings.GET_REAUTHORIZE_ORGANISATIONS_COUNT),
            });
            yield put({ type: actionTypes.GET_INITIAL_DATA_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_INITIAL_DATA_FAILURE, errors });
        }
    }
}

//export the settings saga
export default new Settings().forkAllWatcherFunctions();

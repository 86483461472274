/**
 * Range filter component for Scrollable table
 * This is a reusable component which can be used to render scrollable data in a table
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import { React, useState } from '../../../includes/exports/react';
import { Button, Input } from 'antd';
import PropTypes from 'prop-types';
import { get } from 'lodash';

/**
 * Range filter component
 */
const RangeFilter = props => {
    const [values, setValues] = useState(props.values);
    const rangeFilterCount = props.rangeFilters.length;

    return (
        <ul className="custom-drop-down range-filter" ref={props.forwardRef}>
            <li className="custom-dd-list" key="list">
                <Input.Group compact>
                    <span
                        style={{
                            width: '100%',
                            pointerEvents: 'none',
                            textAlign: 'center',
                            padding: '5px 0',
                        }}
                    >
                        {props.rangeFilterBetweenLabel || 'Between'}
                    </span>
                    {props.rangeFilters.map((rangeFilter, index) => (
                        <React.Fragment key={index + '_fragment'}>
                            <Input
                                type={props.rangeFilterType}
                                value={get(values, rangeFilter.key)}
                                onChange={e => setValues({ ...values, [rangeFilter.key]: e.target.value })}
                                placeholder={rangeFilter.label}
                                key={index}
                            />
                            {rangeFilterCount > index + 1 && (
                                <Input
                                    className="site-input-split"
                                    style={{
                                        borderLeft: 0,
                                        borderRight: 0,
                                        pointerEvents: 'none',
                                        textAlign: 'center',
                                    }}
                                    key={index + '_separator'}
                                    placeholder="&"
                                    disabled
                                />
                            )}
                        </React.Fragment>
                    ))}
                </Input.Group>
            </li>

            <li className="last-confirm-row custom-dd-list">
                <Button className="align-left" type="link" onClick={() => props.onSubmit(values)}>
                    OK
                </Button>
                <Button
                    className="align-right"
                    type="link"
                    onClick={() => {
                        props.onReset();
                        setValues({});
                    }}
                >
                    Reset
                </Button>
            </li>
        </ul>
    );
};

// default props
RangeFilter.defaultProps = {
    rangeFilters: [],
};

// prop types
RangeFilter.propTypes = {
    forwardRef: PropTypes.object,
    onReset: PropTypes.func,
    onSubmit: PropTypes.func,
    rangeFilterBetweenLabel: PropTypes.string,
    rangeFilters: PropTypes.array,
    rangeFilterType: PropTypes.string,
    values: PropTypes.object,
};

export default RangeFilter;

/**
 * Invoices add/edit item list component
 *
 * this component renders the item list arrayin the invoices form
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { change, formValueSelector, getFormSyncErrors, getFormSubmitErrors, submit } from 'redux-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import { Icon } from 'antd';
import { get } from 'lodash';

import Item from '../ItemForm';
import { INVOICE_FORM_NAME } from 'includes/constants';
import { getLineItemFormName, getTotalAmount } from 'pages/Invoices/utils';

const grid = 8;

const fieldValueSelector = formValueSelector(INVOICE_FORM_NAME);
const formErrorSelector = getFormSyncErrors(INVOICE_FORM_NAME);
const formSubmitErrors = getFormSubmitErrors(INVOICE_FORM_NAME);

const ItemsList = ({
    fields,
    currencySymbol,
    currentEditingKey,
    getNumberFormattedWithCurrency,
    setCurrentEditingKey,
    itemTotals,
    isRecurring,
    touch,
}) => {
    const lineItems = useSelector(state => fieldValueSelector(state, 'line_items'));
    const lineAmountType = useSelector(state => fieldValueSelector(state, 'line_amount_type'));
    const { line_items: lineItemsSyncErrors } = useSelector(state => formErrorSelector(state));
    const { line_items: lineItemsSubmitErrors } = useSelector(state => formSubmitErrors(state));
    const currentLineItemUniqueId = get(lineItems, [currentEditingKey, '_id'], '');
    const currentLineItemFormName = getLineItemFormName(currentLineItemUniqueId);
    const { values: lineItemFormValues } = useSelector(state =>
        state.form && state.form[currentLineItemFormName] ? state.form[currentLineItemFormName] : {}
    );

    const dispatch = useDispatch();

    /**
     * Adds a new line item
     */
    const addNewLineItem = () => {
        setCurrentEditingKey(fields.length);
        fields.push({ _id: uuid(), first_touch: true });
    };

    // validate that at least name is entered
    const checkIfNameExists = () => {
        // if (currentEditingKey === null) return true;
        return Boolean(get(lineItemFormValues, ['name'], ''));
    };

    // to display error field has to be touched
    const touchRequiredFields = () => {
        ['name', 'quantity', 'unit_price'].forEach(f => touch(`line_items.${currentEditingKey}.${f}`));
    };

    const closeCurrentEditingItem = useCallback(() => {
        if (checkIfNameExists() || !lineItemFormValues.first_touch) {
            dispatch(submit(currentLineItemFormName));
        } else {
            fields.remove(currentEditingKey);
            setCurrentEditingKey(null);
            touchRequiredFields();
        }
    }, [checkIfNameExists, setCurrentEditingKey, touchRequiredFields, currentLineItemFormName]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeField = (field, value) => dispatch(change(INVOICE_FORM_NAME, field, value));

    const checkIfLineItemHasError = index => {
        const lineItemSyncErrors = get(lineItemsSyncErrors, index, {});
        const lineItemSubmitErrors = get(lineItemsSubmitErrors, index, {});

        return Object.keys({ ...lineItemSyncErrors, ...lineItemSubmitErrors }).length > 0;
    };

    const getItemStyle = (isDragging, draggableStyle, index) => {
        const hasError = checkIfLineItemHasError(index);
        return {
            // some basic styles to make the items look a bit nicer
            userSelect: 'none',
            padding: grid * 2,
            margin: `0 0 ${grid}px 0`,
            border: `1px solid ${hasError ? '#f5222d' : '#e5e5e5'}`,
            borderRadius: '5px',
            boxShadow: `0 2px 7px 0 ${hasError ? 'rgba(245, 34, 45, 0.3)' : 'rgba(0, 0, 0, 0.1)'}`,

            // change background colour if dragging
            background: isDragging ? '#d3f1cd' : 'white',

            // styles we need to apply on draggables
            ...draggableStyle,
        };
    };

    // const getListStyle = isDraggingOver => {} arg `isDraggingOver` is passed to alter styles
    const getListStyle = () => ({
        background: 'white',
        padding: grid,
        width: '100%',
    });

    const is_recurring = false;
    return (
        <div className="invoice-item-list-wrapper">
            <h2 className="item-heading">Items ({fields.length})</h2>
            <DragDropContext
                onDragEnd={res => {
                    if (res.destination) fields.move(res.source.index, res.destination.index);
                    touchRequiredFields();
                }}
            >
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {fields.map((rec, index) => {
                                    const editing = index === currentEditingKey;
                                    const details = lineItems[index];
                                    const { subTotal, discount, tax } = itemTotals[index];
                                    const itemSubTotal = getNumberFormattedWithCurrency(
                                        getTotalAmount(subTotal, discount, tax, lineAmountType)
                                    );
                                    return (
                                        <Draggable
                                            key={details._id}
                                            draggableId={details._id}
                                            index={index}
                                            isDragDisabled={currentEditingKey !== null}
                                        >
                                            {(provided, snapshot) => (
                                                <div
                                                    className={`invoicing-item-cell ${editing ? 'row-active' : ''}`}
                                                    key={details._id}
                                                    onClick={() => {
                                                        // do nothing if it's the active item block
                                                        if (index === currentEditingKey) return;

                                                        // if it's not a valid entry remove it from item list
                                                        if (!checkIfNameExists() && currentEditingKey !== null) {
                                                            fields.remove(currentEditingKey);
                                                        } else {
                                                            // update it to main form
                                                            changeField(`line_items.${currentEditingKey}`, {
                                                                ...lineItemFormValues,
                                                                first_touch: false,
                                                            });
                                                        }
                                                        // set this item as open
                                                        setCurrentEditingKey(index);
                                                    }}
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style,
                                                        index
                                                    )}
                                                >
                                                    <Item
                                                        rec={rec}
                                                        editing={editing}
                                                        details={details}
                                                        is_recurring={is_recurring}
                                                        changeField={changeField}
                                                        itemSubTotal={itemSubTotal}
                                                        currencySymbol={currencySymbol}
                                                        lineAmountType={lineAmountType}
                                                        fields={fields}
                                                        isRecurring={isRecurring}
                                                        setCurrentEditingKey={setCurrentEditingKey}
                                                        index={index}
                                                        closeCurrentEditingItem={closeCurrentEditingItem}
                                                        getNumberFormattedWithCurrency={getNumberFormattedWithCurrency}
                                                    />
                                                    {checkIfLineItemHasError(index) && currentEditingKey !== index ? (
                                                        <div className="click-to-see-message">
                                                            Click to see the errors
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </DragDropContext>
            <button disabled={currentEditingKey !== null} className="add-item-row" onClick={addNewLineItem}>
                <Icon type="plus" />
                Add New Item
            </button>
        </div>
    );
};

ItemsList.propTypes = {
    currentEditingKey: PropTypes.number,
    currencySymbol: PropTypes.string,
    fields: PropTypes.object,
    getNumberFormattedWithCurrency: PropTypes.func,
    isRecurring: PropTypes.bool,
    itemTotals: PropTypes.array,
    setCurrentEditingKey: PropTypes.func,
    taxStatus: PropTypes.string,
    touch: PropTypes.func,
};

export default ItemsList;

/**
 * App Notification Component
 * Handles the notification show across the app
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import the required modules
import { PropTypes } from 'prop-types';
import { notification, message } from 'antd';
import { get } from 'lodash';
import { React, connect, useEffect } from '../../../includes/exports/react';
import * as constants from '../../../includes/constants';

/**
 * App Notification Component
 */
const AppNotification = props => {
    /**
     * On Component update render the notification
     */
    useEffect(() => {
        if (get(get(props, 'appNotification'), 'message')) {
            renderMsg();
        }
    });

    /**
     * Render the notification message
     */
    const renderMsg = () => {
        const appNotification = get(props, 'appNotification');
        if (
            get(appNotification, 'appNotificationType', constants.DEFAULT_APP_NOTIFICATION_MESSAGE_TYPE) ===
            constants.DEFAULT_APP_NOTIFICATION_MESSAGE_TYPE
        ) {
            notification.open({
                btn: get(appNotification, 'button'),
                className: get(appNotification, 'className'),
                duration: get(appNotification, 'duration', constants.DEFAULT_APP_NOTIFICATION_DURATION),
                icon: get(appNotification, 'icon'),
                key: get(appNotification, 'key'),
                message: get(appNotification, 'message'),
                onClose: get(appNotification, 'onClose'),
                onClick: get(appNotification, 'onClick'),
                style: get(appNotification, 'style'),
                placement: get(appNotification, 'placement'),
                prefixCls: get(appNotification, 'prefixCls'),
                title: get(appNotification, 'title', constants.DEFAULT_APP_NOTIFICATION_TITLE),
                top: get(appNotification, 'top'),
                bottom: get(appNotification, 'bottom'),
                getContainer: get(appNotification, 'getContainer'),
                type: get(appNotification, 'messageType', constants.DEFAULT_APP_NOTIFICATION_TYPE),
            });
        } else {
            message[get(appNotification, 'messageType', constants.DEFAULT_APP_NOTIFICATION_TYPE)](
                get(appNotification, 'message'),
                get(appNotification, 'duration', constants.DEFAULT_APP_NOTIFICATION_DURATION)
            );
        }
    };

    /**
     * Render the notification
     *
     * @returns    {string}    Notification view
     */
    return <React.Fragment />;
};

/**
 * Proptypes
 */
AppNotification.propTypes = {
    appNotification: PropTypes.object,
};

// connect the component to the store
export default connect(
    state => ({ appNotification: state.appNotification.appNotification }),
    null
)(AppNotification);

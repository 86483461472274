import { PAYMENT_METHOD_TYPE } from 'includes/constants';
import usePaymentMethodTypes from 'includes/hooks/usePaymentMethodTypes';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

/**
 * Matches the arg and returns string of tailwind classes
 *
 * @param {string} methodTypeSlug Method type's slug
 * @returns string containing tailwind classes
 */
const getMethodTypeSpecificClassNames = methodTypeSlug => {
    switch (methodTypeSlug) {
        case PAYMENT_METHOD_TYPE.CREDIT_CARD:
        case PAYMENT_METHOD_TYPE.DEBIT_CARD:
            return 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-300';
        case PAYMENT_METHOD_TYPE.MANDATE:
            return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300';
        default:
            return '';
    }
};

/**
 * Payment method type tag
 *
 * Render a tag indicating the type of payment method
 *
 * takes in `id` prop
 *
 * @since 2.8.0
 */
export default function PaymentMethodType({ id }) {
    const { data: paymentMethodTypes } = usePaymentMethodTypes(true);

    const methodType = useMemo(() => {
        if (!id || !paymentMethodTypes || paymentMethodTypes.length === 0) return null;

        return paymentMethodTypes.find(methodType => methodType.id === id);
    }, [id, paymentMethodTypes]);

    return (
        <span
            className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded-full ${getMethodTypeSpecificClassNames(
                methodType?.slug
            )}`}
        >
            {methodType?.label}
        </span>
    );
}

PaymentMethodType.propTypes = {
    id: PropTypes.string,
};

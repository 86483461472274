import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

/**
 * Internal Stripe context
 * Used to handle stripe and elements instance when using internal payment gateway
 */
const InternalStripeContext = createContext({
    elements: null,
    stripe: null,
    setElements: () => {},
    setStripe: () => {},
});

/**
 * Hook to access state of context
 *
 * @returns state
 */
const useInternalStripeContext = () => {
    const context = useContext(InternalStripeContext);

    if (!context) {
        // throw new Error('useInternalStripeContext must be used within an InternalStripeContextProvider');
        console.warn(
            'useInternalStripeContext is intended to be used within an InternalStripeContextProvider. If you are using it otherwise, the setter methods and the values wont be available.'
        );
    }

    return context;
};

/**
 * Provider wrapper to enable use oh context
 *
 * @returns Children wrapped in context provider
 */
const InternalStripeContextProvider = ({ children }) => {
    const [stripe, setStripe] = useState(null);
    const [elements, setElements] = useState(null);

    return (
        <InternalStripeContext.Provider value={{ elements, stripe, setElements, setStripe }}>
            {children}
        </InternalStripeContext.Provider>
    );
};

InternalStripeContextProvider.propTypes = {
    children: PropTypes.node,
};

export { InternalStripeContextProvider, useInternalStripeContext };

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { get, isEmpty, find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Cascader, Modal, Tag } from 'antd';
import { Link } from 'react-router-dom';

import Table from 'components/shared/ScrollableTable';
import Button from 'components/shared/Button';
import useInvoiceTypes from 'includes/hooks/useInvoiceTypes';
import HasEditPermission from 'components/HasEditPermission';
import useInvoiceStatuses from 'includes/hooks/useInvoiceStatuses';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import SearchBlock from 'components/shared/SearchBlock';
import InfoMessage from 'components/shared/messages/InfoMessage';
import { useTranslation } from 'react-i18next';
import { getInvoicesTableColumns } from 'includes/constants/columns';
import { useCurrentRoleSlug } from 'includes/utils/hooks';
import { redirect } from 'includes/utils';
import { TABLE_NO_INVOICES_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import {
    INVOICE_STATUS_DRAFT_SLUG,
    INVOICE_STATUS_OPEN_SLUG,
    INVOICE_STATUS_PAID_SLUG,
    INVOICE_STATUS_VOIDED_SLUG,
    USER_ROLE_ADMIN,
    USER_ROLE_ADMIN_MANAGER,
} from 'includes/constants';
import { updateInvoicesStatus, updateInvoicesType } from 'includes/slices/invoice';
import { formatRepeatingDueOnText, formatRepeatsText } from 'pages/Invoices/utils';
import useInvoiceLinkGenerator from 'includes/hooks/useInvoiceLinkGenerator';
import useAccountId from 'includes/hooks/useAccountId';
import useToFEDateFormatter from 'includes/hooks/useToFEDateFormatter';
import PermissionGuard from 'components/PermissionGuard';
import { CONTACT, INVOICE, RECURRING_INVOICE } from 'includes/constants/permissions';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import { CURRENCY_CODE } from 'includes/constants/keys/response';
import useContactTypes from 'includes/hooks/useContactTypes';
import ContactTypeLabel from 'components/ContactTypeLabel';
import InvoiceStatusTag from 'components/InvoiceStatusTag';

const { confirm, error } = Modal;

const TableComponent = ({
    disableEditControls,
    fetchInvoices,
    invoiceIds,
    isRepeatingInvoices,
    setInvoiceIds,
    setTableFilters,
    showActionButtons,
    showOnDemandCampaign,
    contactId,
}) => {
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [invoiceType, setInvoiceType] = useState([]);
    const [invoiceStatusType, setInvoiceStatusType] = useState([]);

    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);
    const isLoading = useSelector(state => state.invoices.isLoading);
    const paginationInvoices = useSelector(state => state.invoices.paginationInvoices);
    const invoices = useSelector(state => state.invoices.invoices);
    const isDeletingInvoice = useSelector(state => state.invoices.isDeletingInvoice);
    const detailsUpdatedMessage = useSelector(state => state.invoices.detailsUpdatedMessage);

    const dispatch = useDispatch();
    const organisationId = useOrganisationId();
    const shouldDisableApisWithoutOrganisationId = !organisationId ? true : false;

    const { data: invoiceTypes, isLoading: isTypesLoading } = useInvoiceTypes();
    const { data: contactTypes } = useContactTypes();
    const { data: invoiceStatuses, isLoading: isInvoiceStatusesLoading } = useInvoiceStatuses();
    const { data: currencies, isLoading: isOrganisationCurrenciesLoading } = useOrganisationCurrencies(
        shouldDisableApisWithoutOrganisationId
    );
    const currentRoleSlug = useCurrentRoleSlug();
    const { t } = useTranslation();
    const formatDate = useToFEDateFormatter(true);
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    const getLocale = (path, params = {}) => t(`customerMessages.invoice.${path}`, params);

    /**
     * Fetches invoices when one is updated
     * Also responsible for resetting invoiceIds
     */
    useEffect(() => {
        setInvoiceIds([]);
        if (detailsUpdatedMessage) {
            fetchInvoices();
        }
    }, [detailsUpdatedMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    const accountId = useAccountId();

    /**
     * Format number with invoice currency
     *
     * @param {string|number} number  Number
     * @param {object} invoice Invoice
     *
     * @returns {string} Formatted Number
     */
    const getNumberFormattedWithInvoiceCurrency = (number, invoice) =>
        getNumberFormattedAsCurrency(
            number,
            false,
            false,
            get(find(currencies, { id: invoice.currency_id }), CURRENCY_CODE)
        );

    const updateInvoiceStatus = () => {
        const newInvoiceStatus = find(invoiceStatuses, { id: invoiceStatusType[0] });
        switch (newInvoiceStatus.slug) {
            case 'debt-collection':
            case 'legal':
                confirm({
                    title: getLocale(
                        `confirm.${
                            newInvoiceStatus.slug === 'legal' ? 'changeToLegalTitle' : 'changeToDebtCollectionTitle'
                        }`
                    ),
                    content:
                        newInvoiceStatus.slug === 'legal'
                            ? getLocale(`confirm.changeToLegalContent`)
                            : getLocale(`confirm.changeToDebtCollectionContent`),
                    onOk: () => {
                        dispatch(
                            updateInvoicesStatus(
                                organisationId,
                                {
                                    ids: invoiceIds,
                                    status: invoiceStatusType[0],
                                    invoice_cat_type: isRepeatingInvoices ? 'is_repeating_invoice' : '',
                                },
                                contactId
                            )
                        );
                    },
                });
                break;
            default: {
                dispatch(
                    updateInvoicesStatus(
                        organisationId,
                        {
                            ids: invoiceIds,
                            status: invoiceStatusType[0],
                            invoice_cat_type: isRepeatingInvoices ? 'is_repeating_invoice' : '',
                        },
                        contactId
                    )
                );
            }
        }
    };

    const checkIfInvoiceIsPaid = () => {
        const paidStatusId = find(invoiceStatuses, { slug: 'paid' });
        if (!invoiceIds.includes('all')) {
            invoiceIds.some(id => find(invoices, { id }).status === paidStatusId.id)
                ? confirm({
                      title: getLocale('confirm.continueWithAction'),
                      content: (
                          <>
                              Current status of invoice(s) selected is <b>Paid</b>. Do you wish to continue?
                          </>
                      ),
                      onOk: updateInvoiceStatus,
                  })
                : updateInvoiceStatus();
        } else {
            updateInvoiceStatus();
        }
    };

    /**
     * Dispatch the update invoice types action
     */
    const dispatchUpdateInvoicesType = () =>
        dispatch(
            updateInvoicesType(
                organisationId,
                {
                    ids: invoiceIds,
                    type: invoiceType[0],
                    invoice_cat_type: isRepeatingInvoices ? 'is_repeating_invoice' : '',
                },
                contactId
            )
        );

    const handleUpdateInvoice = type => {
        if (invoiceIds.length === 0) {
            error({
                title: '',
                content: getLocale('messages.selectAtleastOneInvoiceTitle'),
            });
            return;
        }
        type === 'status' ? checkIfInvoiceIsPaid() : dispatchUpdateInvoicesType();
    };

    const getInvoiceViewLink = useInvoiceLinkGenerator();

    const getContactViewLink = invoice => {
        const contactUrlSlug = get(invoice, 'contact_id.is_manager') ? 'contact-manager' : 'contact';
        const contact = invoice.contact_id;
        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return `/admin/accounts/organisation/${contactUrlSlug}/${accountId}/${organisationId}/${contact.id}`;
            default:
                return `/${contactUrlSlug}/${contact.id}`;
        }
    };

    const getRequiredPermission = perm => (isRepeatingInvoices ? RECURRING_INVOICE[perm] : INVOICE[perm]);

    const getContactTypeLabel = id => get(find(contactTypes, { id }), 'type');

    /**
     * Modify and return the invoice data
     */
    const getInvoicesData = () => {
        return invoices
            ? invoices.map(invoice => {
                  const contact = invoice.contact_id;
                  return {
                      ...invoice,
                      amount_due: (
                          <span title={getNumberFormattedWithInvoiceCurrency(invoice.amount_due.amount, invoice)}>
                              {getNumberFormattedWithInvoiceCurrency(invoice.amount_due.amount_short, invoice)}
                          </span>
                      ),
                      amount_paid: (
                          <span title={getNumberFormattedWithInvoiceCurrency(invoice.amount_paid.amount, invoice)}>
                              {getNumberFormattedWithInvoiceCurrency(invoice.amount_paid.amount_short, invoice)}
                          </span>
                      ),
                      contact_type_id: <ContactTypeLabel label={getContactTypeLabel(contact.contact_type_id)} />,
                      due_on: formatRepeatingDueOnText(
                          invoice.repeating_due_date_type,
                          invoice.repeating_due_date_day,
                          isRepeatingInvoices
                      ),
                      due_date: formatDate(invoice.due_date),
                      next_invoice_date: formatDate(invoice.next_invoice_date),
                      end_date: formatDate(invoice.repeating_end_date) || '',
                      invoice_number: (
                          <>
                              <div className="business-name-avatar-wrapper">
                                  <img
                                      className="logo logo-45"
                                      src={require('assets/images/invoice_avatar.svg')}
                                      alt="invoice-avatar"
                                  />
                                  <div>
                                      {/* Permission to be changed */}
                                      <PermissionGuard
                                          fallback={invoice.invoice_number}
                                          requiredPermission={getRequiredPermission('VIEW')}
                                      >
                                          <button
                                              onClick={() =>
                                                  redirect(getInvoiceViewLink(invoice.contact_id.id, invoice.id))
                                              }
                                              className="btn-link"
                                          >
                                              {invoice.invoice_number}
                                          </button>
                                      </PermissionGuard>
                                      <span className="crud-wrapper">
                                          <PermissionGuard requiredPermission={getRequiredPermission('VIEW')}>
                                              <span
                                                  className="crud-link"
                                                  onClick={() =>
                                                      redirect(getInvoiceViewLink(invoice.contact_id.id, invoice.id))
                                                  }
                                              >
                                                  View
                                              </span>
                                          </PermissionGuard>
                                      </span>
                                  </div>
                              </div>
                          </>
                      ),
                      name: (
                          <div>
                              <PermissionGuard
                                  requiredPermission={CONTACT.VIEW}
                                  fallback={contact.name || contact.business_name}
                              >
                                  <button className="btn-link" onClick={() => redirect(getContactViewLink(invoice))}>
                                      {contact.name || contact.business_name}
                                  </button>
                              </PermissionGuard>
                          </div>
                      ),
                      type: get(
                          invoiceTypes.find(element => element.value === invoice.type),
                          'label'
                      ),
                      status: (
                          <div className=" flex flex-col items-start gap-2">
                              <InvoiceStatusTag id={invoice.status} />
                              {invoice?.in_payment_arrangement && <Tag>In Payment Arrangement</Tag>}
                              {invoice?.in_payment_extension && <Tag>In Payment Extension</Tag>}
                          </div>
                      ),
                      isPlanManagedInvoice: !isEmpty(invoice.invoice_contact_id) ? 'Yes' : 'No',
                      action: invoice.amount_due.amount > 0 && (
                          <Link to={`/debtor/invoices/payment/${organisationId}/${contact.id}/${invoice.id}`}>Pay</Link>
                      ),
                      repeats: formatRepeatsText(
                          invoice.repeating_period_type,
                          invoice.repeating_period,
                          isRepeatingInvoices
                      ),
                  };
              })
            : [];
    };

    const rowSelection = {
        selectedRowKeys: invoiceIds,
        onChange: selectedRowKeys => {
            setInvoiceIds(selectedRowKeys);
        },
    };

    return (
        <>
            <ul className="selector-field show-elem">
                {disableEditControls || !showActionButtons ? (
                    <>
                        <li>&nbsp;</li>
                        <li>&nbsp;</li>
                        {selectedOrganisation.is_manual && <li>&nbsp;</li>}
                    </>
                ) : (
                    <>
                        {isRepeatingInvoices ? null : (
                            <li>
                                <div className="invoice-menu">
                                    <div className="selector-title">{getLocale('action.invoiceType')}</div>
                                    <Cascader
                                        value={invoiceType}
                                        options={invoiceTypes}
                                        onChange={val => setInvoiceType(val)}
                                        disabled={isTypesLoading}
                                    />
                                </div>
                                <div className="apply-btn-wrapper">
                                    <HasEditPermission onClick={() => handleUpdateInvoice('type')}>
                                        <Button filled className="apply-btn" disabled={invoiceType.length === 0}>
                                            {getLocale('action.applyBtnLabel')}
                                        </Button>
                                    </HasEditPermission>
                                </div>
                            </li>
                        )}
                        <li>
                            <div className="invoice-menu">
                                <div className="selector-title">{getLocale('action.statusType')}</div>
                                <Cascader
                                    value={invoiceStatusType}
                                    options={
                                        invoiceStatuses
                                            ? invoiceStatuses.filter(invoiceStatus => {
                                                  return isRepeatingInvoices
                                                      ? invoiceStatus.slug === INVOICE_STATUS_OPEN_SLUG ||
                                                            invoiceStatus.slug === INVOICE_STATUS_VOIDED_SLUG
                                                      : invoiceStatus.slug !== INVOICE_STATUS_DRAFT_SLUG &&
                                                            invoiceStatus.slug !== INVOICE_STATUS_PAID_SLUG;
                                              })
                                            : []
                                    }
                                    onChange={val => setInvoiceStatusType(val)}
                                    disabled={isInvoiceStatusesLoading}
                                />{' '}
                            </div>
                            <div className="apply-btn-wrapper">
                                <HasEditPermission onClick={() => handleUpdateInvoice('status')}>
                                    <Button filled className="apply-btn" disabled={invoiceStatusType.length === 0}>
                                        {getLocale('action.applyBtnLabel')}
                                    </Button>
                                </HasEditPermission>
                            </div>
                        </li>
                    </>
                )}
                <li>
                    <div className={`invoices-search ${disableEditControls ? 'width-auto' : ''}`}>
                        <SearchBlock
                            getDataMethod={fetchInvoices}
                            isSearching={isSearching}
                            placeholder={t('customerMessages.invoice.search.placeholder')}
                            setIsSearching={setIsSearching}
                            setSearchParam={setSearchParam}
                            setTableFilters={setTableFilters}
                        />
                    </div>
                </li>
            </ul>
            <hr />
            <Table
                enableSelectAll={!!showOnDemandCampaign}
                rowSelection={rowSelection}
                columns={getInvoicesTableColumns({
                    contactTypes,
                    invoiceStatuses,
                    isRepeatingInvoices,
                })}
                dataSource={
                    !(isLoading || isInvoiceStatusesLoading || isOrganisationCurrenciesLoading) ? getInvoicesData() : []
                }
                getDataMethod={fetchInvoices}
                isSearching={isSearching}
                loading={isLoading || isInvoiceStatusesLoading || isOrganisationCurrenciesLoading}
                localeMessage={TABLE_NO_INVOICES_FOUND_MESSAGE}
                paginationData={paginationInvoices}
                rowClassName="pointer"
                rowKey="id"
                searchParam={searchParam}
                setIsSearching={setIsSearching}
                size="middle"
                setTableFilters={setTableFilters}
                className="table-1200"
                mobileClassName="table-1200"
            />
            <Modal
                footer={[]}
                className="my_profile_modal organisation-reauthorization-modal"
                visible={isDeletingInvoice}
            >
                <InfoMessage
                    message={t(
                        `customerMessages.invoice.loading.message.deletingInvoice${invoiceIds.length ? 's' : ''}`
                    )}
                    showLoader={true}
                />
            </Modal>
        </>
    );
};

TableComponent.propTypes = {
    accountId: PropTypes.string,
    contactId: PropTypes.string,
    deleteInvoice: PropTypes.func,
    disableEditControls: PropTypes.bool,
    fetchInvoices: PropTypes.func,
    handleDeleteMultipleInvoices: PropTypes.func,
    invoiceIds: PropTypes.arrayOf(PropTypes.string),
    isRepeatingInvoices: PropTypes.bool,
    setInvoiceIds: PropTypes.func,
    setTableFilters: PropTypes.func,
    showActionButtons: PropTypes.bool,
    showOnDemandCampaign: PropTypes.bool,
};

export default TableComponent;

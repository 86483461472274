/**
 * useCampaignNotificationDayDescription
 * Get the campaign notification day description based on campaign type
 *
 * @since 2.0.0
 */

import { CAMPAIGN_TYPE_BROKEN } from 'includes/constants';
import { useSelector } from 'react-redux';

/**
 * Get the campaign notification day description based on campaign type
 */
const useCampaignNotificationDayDescription = () => {
    const campaign = useSelector(state => state.campaigns.campaign);
    return (campaignNotification, campaignType = null, additionalSuffixText = '') => {
        if (!campaignType) {
            campaignType = campaign?.campaign_type;
        }
        const descriptionSuffix =
            campaignType === CAMPAIGN_TYPE_BROKEN
                ? campaignNotification?.broken_arrangement_description_suffix
                : campaignNotification?.invoice_due_date_description_suffix;
        return campaignNotification?.description.replace('%s', `${descriptionSuffix}${additionalSuffixText}`);
    };
};

export default useCampaignNotificationDayDescription;

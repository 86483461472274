/**
 * useCountries
 * Get the countries
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { CACHE_COUNTRIES_KEY } from 'includes/constants';
import { COUNTRIES } from 'includes/constants/mappings/success';
import { get } from 'lodash';
import CountriesApi from 'includes/services/countries';
import useCachedData from './useCachedData';

/**
 * Get the countries
 *
 * @param {boolean} disableApiCall Whether to disable api call or not. Default false
 *
 * @returns object Countries
 */
const useCountries = (disableApiCall = false) => {
    return useCachedData(CACHE_COUNTRIES_KEY, () => CountriesApi.getCountries(), {
        selector: res => get(res, COUNTRIES),
        disableApiCall: disableApiCall,
        dependsOnOrg: false,
    });
};

export default useCountries;

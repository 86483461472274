/**
 * Inventory listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import { Breadcrumb, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/Button';
import ExportButton from 'components/shared/Exports/button';
import InfoMessage from 'components/shared/messages/InfoMessage';
import InventoryBreadCrumbItems from 'components/BreadCrumbItems/Inventory';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { deleteInventoryItem, getInventoryItems } from 'includes/slices/inventory';
import { getInventoryTableColumns } from 'includes/tableColumns/inventory';
import { TABLE_NO_INVENTORY_ITEMS_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import { redirect } from 'includes/utils';
import PermissionGuard from 'components/PermissionGuard';
import { INVENTORY } from 'includes/constants/permissions';
import { getLocaleNumber } from 'includes/utils';

const { confirm } = Modal;

const InventoryList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params = useParams();
    useUpdateBusinessName();

    const { accountId } = params;
    const inventoryItems = useSelector(state => state.inventory.inventoryItems);
    const inventoryItemsPagination = useSelector(state => state.inventory.inventoryItemsPagination);
    const isAdmin = useSelector(state => state.account.isAdmin);
    const loadingInventoryItemDelete = useSelector(state => state.inventory.loadingInventoryItemDelete);
    const loadingInventoryItems = useSelector(state => state.inventory.loadingInventoryItems);
    const organisationId = useOrganisationId();
    const [selectedInventoryIds, setSelectedInventoryIds] = useState([]);
    const [tableFilters, setTableFilters] = useState({});
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    /**
     * Delete inventory item
     *
     * @param {string} inventoryItemId Inventory item id
     */
    const deleteItem = inventoryItemId => {
        confirm({
            title: t(`messages.inventory.confirm.delete.title`),
            content: '',
            onOk: () => {
                dispatch(deleteInventoryItem(organisationId, inventoryItemId));
            },
        });
    };

    /**
     * Get the chart of account details add link
     *
     * @returns {string} Add Link
     */
    const getAddLink = () =>
        isAdmin ? `/admin/accounts/organisation/inventory/${accountId}/${organisationId}/add` : `/inventory/add`;

    /**
     * Get the locale text
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @returns {string} Locale test
     */
    const getLocaleText = path => t(`messages.inventory.${path}`);

    /**
     * Get the inventory details view link
     *
     * @param {string} inventoryId Inventory id
     *
     * @returns {string} View Link
     */
    const getViewLink = inventoryId =>
        isAdmin
            ? `/admin/accounts/organisation/inventory/inventory-item/${accountId}/${organisationId}/${inventoryId}`
            : `/inventory/inventory-item/${inventoryId}`;

    /**
     * Format the inventory data
     */
    const formatInventoryData = useCallback(
        () =>
            inventoryItems.map(inventoryItem => {
                return {
                    ...inventoryItem,
                    name: (
                        <div className="business-name-avatar-wrapper">
                            <div className="action-wrapper">
                                {inventoryItem.name}
                                <span className="crud-wrapper">
                                    <PermissionGuard requiredPermission={INVENTORY.VIEW}>
                                        <span
                                            className="crud-link"
                                            onClick={() => redirect(getViewLink(inventoryItem.id))}
                                        >
                                            {getLocaleText('crud.view')}
                                        </span>
                                    </PermissionGuard>
                                    <PermissionGuard requiredPermission={INVENTORY.DELETE}>
                                        <span onClick={() => deleteItem(inventoryItem.id)} className="crud-link">
                                            {getLocaleText('crud.delete')}
                                        </span>
                                    </PermissionGuard>
                                </span>
                            </div>
                        </div>
                    ),
                    unit_selling_price: getLocaleNumber(inventoryItem.unit_selling_price),
                };
            }),
        [inventoryItems] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Memoized inventory data
     */
    const memoizedInventoryData = useMemo(() => formatInventoryData(), [formatInventoryData]);

    /**
     * Callback for row selection
     */
    const rowSelection = {
        selectedRowKeys: selectedInventoryIds,
        onChange: selectedRowKeys => {
            setSelectedInventoryIds(selectedRowKeys);
        },
    };

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
            <h2 className="page-title">{t(`titleAndMetas.inventory.title`)}</h2>
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <InventoryBreadCrumbItems showListBreadCrumb={isAdmin} />
                </Breadcrumb>
            </div>
            <div className="search-filter-export-wrapper contact-list-container full-wrapper box-wrapper white-box-wrapper">
                <section className="right-menu-wrapper">
                    <div className="right-align">
                        <ExportButton
                            exportType="inventory"
                            queryParams={{ inventory_ids: selectedInventoryIds, ...tableFilters }}
                        />
                        <PermissionGuard requiredPermission={INVENTORY.ADD}>
                            <Button big filled onClick={() => redirect(getAddLink())}>
                                {getLocaleText('crud.add')}
                            </Button>
                        </PermissionGuard>
                    </div>
                </section>
                <ul className="selector-field show-elem invoices-wrapper">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>
                        <div className="invoices-search">
                            <SearchBlock
                                dataValues={[organisationId]}
                                dispatchMethod={true}
                                getDataMethod={getInventoryItems}
                                isSearching={isSearching}
                                placeholder={getLocaleText('search.placeholder')}
                                setIsSearching={setIsSearching}
                                setSearchParam={setSearchParam}
                                setTableFilters={setTableFilters}
                            />
                        </div>
                    </li>
                </ul>
                <Table
                    className="table-1200"
                    columns={getInventoryTableColumns}
                    dataSource={memoizedInventoryData}
                    dataValues={[organisationId]}
                    dispatchMethod={true}
                    getDataMethod={getInventoryItems}
                    isSearching={isSearching}
                    loading={loadingInventoryItems}
                    localeMessage={TABLE_NO_INVENTORY_ITEMS_FOUND_MESSAGE}
                    mobileClassName="table-1200"
                    paginationData={inventoryItemsPagination}
                    rowClassName="pointer"
                    rowKey="id"
                    rowSelection={rowSelection}
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    setTableFilters={setTableFilters}
                    size="middle"
                />
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={loadingInventoryItemDelete}
                >
                    <InfoMessage message={t('messages.inventory.modal.message.delete')} showLoader={true} />
                </Modal>
            </div>
        </div>
    );
};

export default InventoryList;

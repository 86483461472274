/**
 * Tax Form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React from 'react';
import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import Button from 'components/shared/Button';
import FormField from 'components/shared/FormField';
import PropTypes from 'prop-types';
import useTaxTypes from 'includes/hooks/useTaxTypes';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * Max tax rate 100 validation
 *
 * @returns {Function} Validation function
 */
const maxTaxRate100 = formValidations.maxValue(100);

/**
 * Min tax rate 0 validation
 *
 * @returns {Function} Validation function
 */
const minTaxRate0 = formValidations.minValue(0);

const TaxForm = props => {
    const { t } = useTranslation();
    const loadingTaxAddUpdate = useSelector(state => state.taxes.loadingTaxAddUpdate);
    const { data: taxTypes } = useTaxTypes();

    /**
     * Get the localised label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.taxes.addEdit.form.input.label.${field}`);

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.TAX_NAME}
                            label={getLocaleLabel(requestKeys.TAX_NAME)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="number"
                            name={requestKeys.TAX_RATE}
                            label={getLocaleLabel(requestKeys.TAX_RATE)}
                            hasFeedback
                            component={FormField}
                            min={0}
                            max={100}
                            validate={[formValidations.required, formValidations.number, minTaxRate0, maxTaxRate100]}
                            step="any"
                            required
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="select"
                            name={requestKeys.TAX_TYPE_ID}
                            label={getLocaleLabel(requestKeys.TAX_TYPE_ID)}
                            options={taxTypes.map(t => ({ name: t.type, value: t.id }))}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.EXTERNAL_ID}
                            label={getLocaleLabel(requestKeys.EXTERNAL_ID)}
                            hasFeedback
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="textarea"
                            name={requestKeys.TAX_DESCRIPTION}
                            label={getLocaleLabel(requestKeys.TAX_DESCRIPTION)}
                            hasFeedback
                            component={FormField}
                        />
                    </Col>
                </Row>
            </Form>
            <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={loadingTaxAddUpdate}
                        submittingButtonLabel={t(
                            `messages.taxes.addEdit.form.submit.buttonSubmittingLabel.${props.isAdd ? 'add' : 'edit'}`
                        )}
                        className="register-btn"
                    >
                        {t(`messages.taxes.addEdit.form.submit.buttonLabel.${props.isAdd ? 'add' : 'edit'}`)}
                    </Button>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
};

/**
 * Proptypes
 */
TaxForm.propTypes = {
    change: PropTypes.func,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    isAdd: PropTypes.bool,
};

export default reduxForm({
    form: constants.TAX_FORM_NAME,
})(TaxForm);

/**
 * Base Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { createSlice } from '@reduxjs/toolkit';
import { get } from 'lodash';

/**
 * Base Slice create function
 *
 * @param {string} name Slice Name
 * @param {object} initialState Initial state
 * @param {object} additionalReducers Additional reducers to passed on to the reducer
 *
 * @returns {object} Slice actions and reducers
 */
const createAppSlice = (name, initialState, additionalReducers = {}) =>
    createSlice({
        name: name,
        initialState,
        reducers: {
            setData: {
                reducer: (state, action) => {
                    const { key, data } = action.payload;
                    state[key] = data;
                },
                prepare: (key, value) => ({ payload: { key: key, data: value || get(initialState, key, '') } }),
            },
            ...additionalReducers,
        },
    });

export default createAppSlice;

import React, { useEffect } from 'react';

import Loader from 'components/shared/Loader';
import { clearLSAndRedirectToAuthPortalLogoutPage } from 'includes/utils';

export default function Logout() {
    useEffect(() => {
        clearLSAndRedirectToAuthPortalLogoutPage();
    }, []);

    return <Loader />;
}

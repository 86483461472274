/**
 * Base File
 * All the app requests are routed through this file
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import the required modules
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { React } from './includes/exports/react';
import 'antd/dist/antd.css';
import store from './includes/redux/store';
import App from './App';

import './main.scss';
// import * as serviceWorker from './serviceWorker';

// Render the React DOM
ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import { React, useState, useRef } from '../../../includes/exports/react';
import ReactCrop from 'react-image-crop';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import UploadInput from '../FormField/upload';
import { ACCEPTED_IMAGE_MIME_TYPES, ACCEPTED_IMAGE_TYPES } from '../../../includes/constants';

import Button from '../Button';
import './styles.scss';

let fileUrl = null;
let file = null;
const ImagePicker = props => {
    const [crop, setCrop] = useState({
        unit: 'px',
        width: 300,
        height: 300,
        minWidth: 300,
        minHeight: 300,
    });

    const [src, setSrc] = useState(null);
    const [fileType, setFileType] = useState(null);

    const [croppedImageUrl, setCroppedImageUrl] = useState('');

    const imageRef = useRef(null);

    const onSelectFile = files => {
        if (files && files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setSrc(reader.result);
                setFileType(files[0].type === 'image/png' ? 'png' : 'jpeg');
            });
            reader.readAsDataURL(files[0]);
        }
    };

    const onRemove = () => {
        setSrc(null);
        setCroppedImageUrl('');
    };

    // If you setState the crop in here you should return false.
    // const onImageLoaded = image => {
    //     imageRef = image;
    // };

    const onCropComplete = crop => {
        makeClientCrop(crop);
    };

    const onCropChange = crop => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        setCrop(crop);
    };

    const makeClientCrop = async crop => {
        if (imageRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(imageRef.current, crop, 'newFile.' + fileType);
            setCroppedImageUrl(croppedImageUrl);
        }
    };

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.imageRef.naturalWidth / image.imageRef.width;
        const scaleY = image.imageRef.naturalHeight / image.imageRef.height;
        const cropHeight = crop.height * scaleY;
        const cropWidth = crop.width * scaleX;

        canvas.width = cropWidth;
        canvas.height = cropHeight;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image.imageRef,
            crop.x * scaleX,
            crop.y * scaleY,
            cropWidth,
            cropHeight,
            0,
            0,
            cropWidth,
            cropHeight
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                blob.name = fileName;
                file = new File([blob], fileName);
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
            }, 'image/' + fileType);
        });
    };

    return (
        <Modal
            className="custom-form-modal image-cropper-modal"
            visible={props.visible}
            width="50%"
            footer={[]}
            onCancel={props.onCancel}
        >
            <h1 className="image-picker-modal__header">Choose profile picture</h1>
            <UploadInput
                type="file"
                onChange={onSelectFile}
                onRemove={onRemove}
                accept={ACCEPTED_IMAGE_MIME_TYPES.join(', ')}
                acceptExtensions={ACCEPTED_IMAGE_TYPES}
                minWidth={300}
                minHeight={300}
            />
            <div className="image-picker-modal__wrapper">
                {croppedImageUrl && (
                    <div className="circular--landscape">
                        <img alt="Crop" className="image-picker-modal__preview" src={croppedImageUrl} />
                    </div>
                )}
                {src && (
                    <ReactCrop
                        src={src}
                        crop={crop}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                        ref={imageRef}
                        className="react-crop"
                        minHeight={300}
                        minWidth={300}
                    />
                )}
            </div>
            {croppedImageUrl && (
                <Button
                    big
                    filled
                    className="image-picker-modal__submit"
                    submittingButtonLabel="test"
                    onClick={() => {
                        props.onSubmit(file);
                    }}
                >
                    Done
                </Button>
            )}
        </Modal>
    );
};

ImagePicker.propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    visible: PropTypes.bool,
};

export default ImagePicker;

/**
 * Defines the request keys used in the app
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// A
export const ABN = 'abn';
export const ACCOUNT = 'account';
export const ACCOUNT_NAME = 'account_name';
export const ACCOUNTS = 'accounts';
export const ACCOUNT_ID = 'account_id';
export const ACCOUNT_TYPE = 'type';
export const ACCOUNTING_SOFTWARE = 'accounting_software';
export const ACCOUNTING_SOFTWARE_ID = 'accounting_software_id';
export const ACCOUNTING_SOFTWARE_OTHER = 'other';
export const ADDRESS = 'address';
export const ALLOW_CUSTOM_EMAIL_SETTINGS = 'allow_custom_email_settings';
export const ALLOW_CUSTOM_IVR_SETTINGS = 'allow_custom_ivr_settings';
export const ALLOW_CUSTOM_SMS_SETTINGS = 'allow_custom_sms_settings';
export const ALLOW_CUSTOM_WHATSAPP_SETTINGS = 'allow_custom_whatsapp_settings';
export const AMOUNT_PAID = 'amount_paid';
export const APPLY_FOR = 'apply_for';
export const AVATAR = 'avatar';

// B
export const BANK_DETAIL_ACCOUNT_HOLDER_NAME = 'account_holder_name';
export const BANK_DETAIL_ACCOUNT_HOLDER_TYPE = 'account_holder_type';
export const BANK_DETAIL_ACCOUNT_NUMBER = 'account_number';
export const BANK_DETAIL_BSB_NUMBER = 'bsb_number';
export const BANK_DETAIL_COUNTRY = 'country';
export const BANK_DETAIL_CURRENCY = 'currency';
export const BANK_DETAIL_ROUTING_NUMBER = 'routing_number';
export const BASE_CURRENCY = 'base_currency_id';
export const BRAND_NAME = 'brand_name';
export const BUSINESS_NAME = 'business_name';
export const BUSINESS_NUMBER = 'business_number';

// C
export const CAMPAIGN = 'campaign';
export const CAMPAIGN_ID = 'campaign_id';
export const CAMPAIGN_CAMPAIGN_TYPE = 'campaign_type';
export const CAMPAIGN_CONTACT_TYPE_ID = 'contact_type_id';
export const CAMPAIGN_CONTACT_TYPE_IDS = 'contact_type_ids';
export const CAMPAIGN_DESCRIPTION = 'description';
export const CAMPAIGN_NAME = 'name';
export const CAMPAIGN_PAYMENT_PLAN_ID = 'payment_plan_id';
export const CAMPAIGN_SEND_MULTIPLE_REMINDERS = 'send_multiple_reminders';
export const CARD = 'card';
export const CARD_TOKEN = 'card_token';
export const CARD_HOLDER = 'card_holder';
export const CARD_NUMBER = 'card_number';
export const CC = 'cc';
export const CC_CONTACT = 'cc_contact';
export const CHART_OF_ACCOUNT_ACCOUNT_NUMBER = 'account_number';
export const CHART_OF_ACCOUNT_ACCOUNT_TYPE_ID = 'account_type_id';
export const CHART_OF_ACCOUNT_CODE = 'code';
export const CHART_OF_ACCOUNT_DESCRIPTION = 'description';
export const CHART_OF_ACCOUNT_ENABLE_PAYMENTS = 'enable_payments';
export const CHART_OF_ACCOUNT_IS_DEFAULT_PAYMENT_ACCOUNT = 'is_default_payment_account';
export const CHART_OF_ACCOUNT_NAME = 'name';
export const CHART_OF_ACCOUNT_PAYMENT_ACCOUNT = 'payment';
export const CHART_OF_ACCOUNT_PURCHASE_ACCOUNT = 'purchase';
export const CHART_OF_ACCOUNT_SALES_ACCOUNT = 'sales';
export const CHART_OF_ACCOUNT_TAX = 'tax';
export const CHART_OF_ACCOUNT_TAX_ID = 'tax_id';
export const CHART_OF_ACCOUNT_TRANSACTION_TYPE = 'transaction_type';
export const CHILD_CONTACTS = 'child_contacts';
export const CLIENT_ID = 'client_id';
export const COMMUNICATION_SETTINGS = 'communication_settings';
export const COMMUNICATION_TEMPLATE = 'communication_template';
export const COMMUNICATION_TEMPLATE_ID = 'communication_template_id';
export const COMMUNICATION_TEMPLATE_TYPE_ID = 'communication_template_type_id';
export const CONTACT_MANAGER = 'parent_contact';
export const CONTACT_MANAGER_ID = 'parent_contact_id';
export const COMPANY_NAME = 'company_name';
export const CONFIRM_PASSWORD = 'confirm_password';
export const CONTACT = 'contact';
export const CONTACT_DATE_OF_BIRTH = 'date_of_birth';
export const CONTACT_ID = 'contact_id';
export const CONTACT_PERSONS = 'contact_persons';
export const CONTACT_TYPE = 'type';
export const CONTACT_TYPE_AUTO_REMINDERS = 'auto_reminders';
export const CONTACT_TYPE_DESCRIPTION = 'description';
export const CONTRACT_END_DATE = 'contract_end_date';
export const CONTRACT_START_DATE = 'contract_start_date';
export const COUNTRY = 'country';
export const COUNTRY_ID = 'country_id';
export const COUPON = 'coupon';
export const COUPON_ID = 'coupon_id';
export const CURRENCY = 'currency';
export const CURRENCY_CODE = 'code';
export const CURRENCY_ID = 'currency_id';
export const CURRENT_PASSWORD = 'current_password';
export const CVV = 'cvv';

// D
export const DATE = 'date';
export const DAY = 'day';
export const DAY_ID = 'day_id';
export const DAYS_TO_ENFORCE_AUTH = 'days_to_enforce_auth';
export const DEFAULT = 'default';
export const DEFAULT_CURRENCY = 'default_currency';
export const DEFAULT_CURRENCY_ID = 'default_currency_id';

// E
export const EMAIL = 'email';
export const EMAIL_FROM = 'communication_settings.email_from_identifier';
export const EMAIL_FROM_NAME = 'communication_settings.email_from_name';
export const EMAIL_FROM_USERNAME = 'communication_settings.email_from_username';
export const EMAIL_PRIVATE_KEY = 'communication_settings.email_private_key';
export const EMAIL_SECRET_KEY = 'communication_settings.email_secret_key';
export const EMAIL_SETTING_TYPE = 'communication_settings.email_setting_type';
export const EMAIL_SMTP_AUTHENTICATION = 'communication_settings.email_smtp_authentication';
export const EMAIL_SMTP_HOST = 'communication_settings.email_smtp_host';
export const EMAIL_SMTP_PASSWORD = 'communication_settings.email_smtp_password';
export const EMAIL_SMTP_PORT = 'communication_settings.email_smtp_port';
export const EMAIL_SMTP_USERNAME = 'communication_settings.email_smtp_username';
export const EMAIL_TEMPLATE = 'email_template';
export const EMAIL_VERIFIED = 'email_verified';
export const ESTIMATED_VOLUME = 'estimated_volume';
export const EVENT_AUTO_SEND_REMINDERS = 'auto_send_reminders';
export const EVENT_AUTO_COLLECT_PAYMENTS = 'auto_collect_payments';
export const EVENT_DESCRIPTION = 'description';
export const EVENT_IS_HOLIDAY = 'is_holiday';
export const EVENT_NAME = 'name';
export const EVENT_TITLE = 'title';
export const EXCHANGE_RATE = 'exchange_rate';
export const EXCHANGE_RATES = 'exchange_rates';
export const EXPIRES_IN = 'expires_in';
export const EXPIRY_MONTH = 'expiry_month';
export const EXPIRY_YEAR = 'expiry_year';
export const EXPORT_FILE_API_MAPPINGS = {
    account: 'EXPORT_FILE_ACCOUNT',
    organisations: 'EXPORT_FILE_ORGANISATIONS',
    'contact-invoices': 'EXPORT_FILE_CONTACT_INVOICES',
    'contact-managers': 'EXPORT_FILE_CONTACT_MANAGERS',
    contacts: 'EXPORT_FILE_CONTACTS',
    inventory: 'EXPORT_FILE_INVENTORY',
    invoices: 'EXPORT_FILE_INVOICES',
    'invoice-payments': 'EXPORT_FILE_INVOICE_PAYMENTS',
    'invoice-invoice-payments': 'EXPORT_FILE_INVOICE_INVOICE_PAYMENTS',
    subscriptions: 'EXPORT_FILE_SUBSCRIPTIONS',
    default: 'EXPORT_FILE_ACCOUNT_ORGANISATION',
};
export const EXPORT_FILE_TYPE_MAPPINGS = {
    'contact-invoices': 'invoices',
    'invoice-invoice-payments': 'invoice-payments',
};
export const EXTERNAL_ID = 'external_id';

// F
export const FAX_NUMBER = 'fax_number';
export const FINANCIAL_YEAR_END = 'financial_year_end';
export const FINANCIAL_YEAR_START = 'financial_year_start';
export const FIRST_FULL_NAME = 'first_full_name';
export const FIRST_NAME = 'first_name';
export const FORCE_DELETE = 'force_delete';
export const FREQUENCY = 'frequency';
export const FREQUENCY_UNIT = 'frequency_unit';
export const FZ_EXPIRY_MONTH = 'expiry_month';
export const FZ_EXPIRY_YEAR = 'expiry_year';

// G
export const GST = 'gst';

// H
export const HAS_DELETED_AVATAR = 'has_deleted_avatar';
export const HAS_DELETED_IMAGE = 'has_deleted_image';
export const HAS_DELETED_LOGO = 'has_deleted_logo';

// I
export const ID = 'id';
export const INCLUDE_IN_EMAILS = 'include_in_emails';
export const INVENTORY_ITEM_DESCRIPTION = 'description';
export const INVENTORY_ITEM_CODE = 'code';
export const INVENTORY_ITEM_IS_SOLD = 'is_sold';
export const INVENTORY_ITEM_UNIT_SELLING_PRICE = 'unit_selling_price';
export const INVENTORY_ITEM_NAME = 'name';
export const INVENTORY_ITEM_UNIT_OF_MEASURE = 'unit_of_measure';
export const INVENTORY_ITEM_SALES_ACCOUNT = 'sales_account';
export const INVENTORY_ITEM_SALES_ACCOUNT_ID = 'sales_account_id';
export const INVENTORY_ITEM_SALES_DESCRIPTION = 'sales_description';
export const INVENTORY_ITEM_SALES_TAX_RATE = 'sales_tax_rate';
export const INVENTORY_ITEM_SALES_TAX_RATE_ID = 'sales_tax_rate_id';
export const INVENTORY_ITEM_PURCHASE_ACCOUNT_ID = 'purchase_account_id';
export const INVENTORY_ITEM_PURCHASE_DESCRIPTION = 'purchase_description';
export const INVENTORY_ITEM_PURCHASE_TAX_RATE_ID = 'purchase_tax_rate_id';
export const INVENTORY_ITEM_DISCOUNT = 'discount';
export const INVENTORY_ITEM_DISCOUNT_TYPE = 'discount_type';
export const INVOICE_AMOUNT_CREDITED = 'amount_credited';
export const INVOICE_AMOUNT_DUE = 'amount_due';
export const INVOICE_AMOUNT_PAID = 'amount_paid';
export const INVOICE_CONTACT = 'invoice_contact';
export const INVOICE_CONTACT_ID = 'invoice_contact_id';
export const INVOICE_DUE_DATE = 'due_date';
export const INVOICE_END_DATE = 'end_date';
export const INVOICE_EXTERNAL_ID = 'external_id';
export const INVOICE_IDS = 'invoice_ids';
export const INVOICE_ISSUED_DATE = 'issued_date';
export const INVOICE_LINE_ITEM_DESCRIPTION = 'description';
export const INVOICE_LINE_ITEM_DISCOUNT = 'discount';
export const INVOICE_LINE_ITEM_DISCOUNT_REASON = 'discount_reason';
export const INVOICE_LINE_ITEM_DISCOUNT_TYPE = 'discount_type';
export const INVOICE_LINE_ITEM_NAME = 'name';
export const INVOICE_LINE_ITEM_QUANTITY = 'quantity';
export const INVOICE_LINE_ITEM_UNIT_PRICE = 'unit_price';
export const INVOICE_LINE_ITEM_START_DATE = 'start_date';
export const INVOICE_LINE_ITEM_END_DATE = 'end_date';
export const INVOICE_LINE_ITEM_TAX_AMOUNT = 'tax_amount';
export const INVOICE_LINE_ITEM_TAX_ID = 'tax_id';
export const INVOICE_LINE_ITEM_TAX_TYPE = 'tax_type';
export const INVOICE_LINE_ITEM_UNIT_OF_MEASURE = 'unit_of_measure';
export const INVOICE_NEXT_INVOICE_DATE = 'next_invoice_date';
export const INVOICE_NUMBER = 'invoice_number';
export const INVOICE_PURCHASE_ORDER = 'purchase_order';
export const INVOICE_REPEATING_CHILD_INVOICE_NUMBER_PREFIX = 'child_invoice_number_prefix';
export const INVOICE_REPEATING_CHILD_STATE = 'child_invoice_state';
export const INVOICE_REPEATING_DUE_DATE_DAY = 'repeating_due_date_day';
export const INVOICE_REPEATING_DUE_DATE_DAY_TYPE = 'repeating_due_date_type';
export const INVOICE_REPEATING_END_DATE = 'repeating_end_date';
export const INVOICE_REPEATING_PERIOD = 'repeating_period';
export const INVOICE_REPEATING_PERIOD_TYPE = 'repeating_period_type';
export const INVOICE_TOTAL_AMOUNT = 'total_amount';
export const IS_ACTIVE = 'is_active';
export const IS_CUSTOM = 'is_custom';
export const IS_DEBT_COLLECTION_ENABLED = 'is_debt_collection_enabled';
export const IS_DEFAULT = 'is_default';
export const IS_OAUTH_ENABLED = 'is_oauth_enabled';
export const IS_RECURRING = 'is_recurring';
export const IS_TEST_ACCOUNT = 'is_test_account';
export const IS_TEST_USER = 'is_test_user';
export const IS_TRIAL_PLAN = 'is_trial_plan';
export const IVR = 'ivr';
export const IVR_FROM_NUMBER = 'communication_settings.ivr_from_identifier';
export const IVR_FROM_USERNAME = 'communication_settings.ivr_from_username';
export const IVR_PRIVATE_KEY = 'communication_settings.ivr_private_key';
export const IVR_SECRET_KEY = 'communication_settings.ivr_secret_key';
export const IVR_SETTINGS = 'ivr_settings';

// K
export const KEY = 'key';

// L
export const LABEL = 'label';
export const LAST_NAME = 'last_name';
export const LEGAL_NAME = 'legal_name';
export const LINE_AMOUNT_TYPE = 'line_amount_type';
export const LINE_ITEMS = 'line_items';
export const LOCATION = 'location';
export const LOGIN_REGISTRATION_ENABLED = 'login_registration_enabled';
export const LOGO = 'logo';

// M
export const MANDATE_NAME = 'mandate_name';
export const MERCHANT_ID = 'merchant_id';
export const MESSAGE = 'message';
export const MOBILE_NUMBER = 'mobile_number';
export const MODE_OF_CONTACT = 'mode_of_contact';

// N
export const NAME = 'name';
export const NOTE_ACTION = 'action';
export const NOTE_DATE = 'date';
export const NOTE_DESCRIPTION = 'description';
export const NOTE_FOLLOW_UP_DATE = 'follow_up_date';
export const NOTE_STARRED = 'starred';
export const NOTE_TITLE = 'title';
export const NOTE_STATUS_ID = 'status_id';
export const NOTE_TYPE = 'type';
export const NOTE_TYPE_ID = 'type_id';
export const NOTE_USER_ID = 'user_id';
export const NOTIFICATION_CLEAR_ALL = 'notification_clear_all';
export const NOTIFICATION_DELETE_ALL = 'notification_delete_all';
export const NOTIFICATION_MARK_ALL_AS_READ = 'notification_mark_all_as_read';
export const NOTIFICATION_MARK_ALL_AS_UNREAD = 'notification_mark_all_as_unread';
export const NOTIFICATION_MARK_AS_READ = 'notification_mark_as_read';
export const NOTIFICATION_TYPE = 'notification_type';
export const NOTIFICATION_TYPE_ID = 'notification_type_id';

// O
export const OFFICE_PHONE_NUMBER = 'office_phone_number';
export const ORDER = 'order';
export const ORDER_TYPE = 'order_type';
export const ORGANISATION_ACCOUNTING_SOFTWARE_DATA = 'accounting_software_data';
export const ORGANISATION_BUSINESS_DAYS = 'business_days';
export const ORGANISATION_AUTO_COLLECT_PAYMENTS_ON_NON_BUSINESS_DAYS = 'auto_collect_payments_on_non_business_days';
export const ORGANISATION_AUTO_SEND_REMINDERS_ON_NON_BUSINESS_DAYS = 'auto_send_reminders_on_non_business_days';
export const ORGANISATION_CURRENCIES = 'organisation_currencies';
export const ORGANISATION_CURRENCY_EXCHANGE_RATE = 'exchange_rate';
export const ORGANISATION_CURRENCY_ID = 'currency_id';
export const ORGANISATION_CURRENCY_USER_DEFINED_EXCHANGE_RATE = 'user_defined_exchange_rate';
export const ORGANISATION_ID = 'organisation_id';
export const ORGANISATION_TYPE = 'organisation_type';
export const OTHER_ACCOUNTING_SOFTWARE = 'accounting_software_other';
export const OTP = 'otp';

// P
export const PAGE = 'page';
export const PAGE_SIZE = 'page_size';
export const PAID_ON = 'paid_on';
export const PARENT_CONTACT = 'parent_contact';
export const PASSWORD = 'password';
export const PAY_BUTTON_TEXT = 'pay_button_text';
export const PAYMENT_ACCOUNT = 'payment_account';
export const PAYMENT_ACCOUNT_ID = 'payment_account_id';
export const PAYMENT_ARRANGEMENT_BROKEN_DATE = 'broken_date';
export const PAYMENT_ARRANGEMENT_INSTALLMENT_PAID_DATE = 'paid_date';
export const PAYMENT_ARRANGEMENT_INSTALLMENT_PAYMENT_RECEIVED_DATE = 'payment_received_date';
export const PAYMENT_ARRANGEMENT_IS_BROKEN = 'is_broken';
export const PAYMENT_ARRANGEMENT_NOTE = 'note';
export const PAYMENT_ARRANGEMENT_NOTE_NOTIFY_DEBTOR = 'notify_debtor';
export const PAYMENT_ARRANGEMENT_PAID_DATE = 'paid_date';
export const PAYMENT_ARRANGEMENT_FULL_PAYMENT_RECEIVED_DATE = 'full_payment_received_date';
export const PAYMENT_ARRANGEMENT_STATUS = 'status';
export const PAYMENT_ARRANGEMENT_TOTAL_AMOUNT = 'total_amount';
export const PAYMENT_GATEWAY = 'payment_gateway';
export const PAYMENT_GATEWAY_ID = 'payment_gateway_id';
export const PAYMENT_METHOD_TYPE_ID = 'method_type_id';
export const PAYMENT_METHOD_ID = 'payment_method_id';
export const PAYMENT_PLAN_ALLOW_NOTES = 'allow_notes';
export const PAYMENT_PLAN_AUTO_APPROVE = 'auto_approve';
export const PAYMENT_PLAN_FEE = 'fee';
export const PAYMENT_PLAN_IS_ENABLED = 'is_enabled';
export const PAYMENT_PLAN_MAX_FIRST_PAYMENT_DATE_DURATION = 'max_first_payment_date_duration';
export const PAYMENT_PLAN_MAX_FIRST_PAYMENT_DATE_DURATION_TYPE = 'max_first_payment_date_duration_type';
export const PAYMENT_PLAN_MAX_DURATION = 'max_duration';
export const PAYMENT_PLAN_MAX_DURATION_TYPE = 'max_duration_type';
export const PAYMENT_PLAN_MAX_PAYMENT_DATE_EXTENSION_DURATION = 'max_payment_date_extension_duration';
export const PAYMENT_PLAN_MAX_PAYMENT_DATE_EXTENSION_DURATION_TYPE = 'max_payment_date_extension_duration_type';
export const PAYMENT_PLAN_MAX_UNPAID_INSTALLMENT_CANCEL_DURATION = 'max_unpaid_installment_cancel_duration';
export const PAYMENT_PLAN_MAX_UNPAID_INSTALLMENT_CANCEL_DURATION_TYPE = 'max_unpaid_installment_cancel_duration_type';
export const PAYMENT_PLAN_MAX_UPFRONT_AMOUNT_DURATION = 'max_upfront_amount_duration';
export const PAYMENT_PLAN_MAX_UPFRONT_AMOUNT_DURATION_TYPE = 'max_upfront_amount_duration_type';
export const PAYMENT_PLAN_NAME = 'name';
export const PAYMENT_PLAN_OVERDUE_GREATER_THAN = 'overdue_greater_than';
export const PAYMENT_PLAN_OVERDUE_LESS_THAN = 'overdue_less_than';
export const PAYMENT_PLAN_OVERDUE_RANGE_FROM = 'overdue_range_from';
export const PAYMENT_PLAN_OVERDUE_RANGE_TO = 'overdue_range_to';
export const PAYMENT_PLAN_OVERDUE_RANGE_TYPE = 'overdue_range_type';
export const PAYMENT_PLAN_PAYMENT_FREQUENCY = 'payment_frequency';
export const PAYMENT_PLAN_PAYMENT_FREQUENCY_TYPE = 'payment_frequency_type';
export const PAYMENT_PLAN_PAYMENT_FREQUENCY_TYPES = 'payment_frequency_types';
export const PAYMENT_PLAN_UPFRONT_AMOUNT = 'upfront_amount';
export const PAYMENT_PLAN_OPEN_BANKING_ENABLED = 'open_banking_enabled';
export const PAYMENT_PLAN_OPEN_BANKING_BUFFER = 'buffer';
export const PAYMENT_PLAN_OPEN_BANKING_CALCULATION_TYPE = 'calculation_type';
export const PAYMENT_PLAN_OPEN_BANKING_PAYMENT_BREAK_DURATION = 'payment_break_duration';
export const PAYMENT_PLAN_OPEN_BANKING_PAYMENT_BREAK_DURATION_TYPE = 'payment_break_duration_type';
export const PAYMENT_PLAN_OPEN_BANKING_EXCEPTION_PERCENTAGE_LIST = 'exception_percentage_list';
export const PAYMENT_INSTRUCTIONS = 'payment_instructions';
export const PAYMENT_TERMS = 'payment_terms';
export const PERMISSIONS = 'permissions';
export const PHONE_NUMBER = 'phone_number';
export const PHONE_NUMBER_VERIFIED = 'phone_number_verified';
export const PHYSICAL_ADDRESS = 'physical_address';
export const PLAN = 'plan';
export const PLAN_ALLOW_INTERNATIONAL_CALLS = 'allow_international_calls';
export const PLAN_ALLOW_INTERNATIONAL_SMS = 'allow_international_sms';
export const PLAN_ALLOW_ZENDESK_INTEGRATION = 'allow_zendesk_integration';
export const PLAN_CAN_PURCHASE = 'can_purchase';
export const PLAN_COST = 'cost';
export const PLAN_COUNTRY_ID = 'country_id';
export const PLAN_CURRENCY_ID = 'currency_id';
export const PLAN_YEARLY_COST = 'yearly_cost';
export const PLAN_CUSTOM_BRANDING = 'custom_branding';
export const PLAN_DESCRIPTION = 'description';
export const PLAN_FREE_CALL = 'free_call';
export const PLAN_FREE_EMAIL = 'free_email';
export const PLAN_FREE_SMS = 'free_sms';
export const PLAN_FREE_WHATSAPP = 'free_whatsapp';
export const PLAN_GRACE_PERIOD = 'grace_period';
export const PLAN_ID = 'plan_id';
export const PLAN_IMAGE = 'image';
export const PLAN_IS_GLOBAL = 'is_global';
export const PLAN_IS_MOST_POPULAR = 'is_most_popular';
export const PLAN_LABEL = 'label';
export const PLAN_MAX_AUTO_SCHEDULE_CONTACTS = 'max_auto_schedule_contacts';
export const PLAN_MAX_CONTACT_TYPES = 'max_contact_types';
export const PLAN_MAX_ORGANISATIONS = 'max_organisations';
export const PLAN_MAX_PAYMENT_ARRANGEMENT_PLANS = 'max_payment_arrangement_plans';
export const PLAN_MAX_PAYMENT_ARRANGEMENTS = 'max_payment_arrangements';
export const PLAN_MAX_PAYMENT_EXTENSION_PLANS = 'max_payment_extension_plans';
export const PLAN_MAX_PAYMENT_EXTENSIONS = 'max_payment_extensions';
export const PLAN_MAX_USERS = 'max_users';
export const PLAN_NAME = 'plan_name';
export const PLAN_SUBTITLE = 'subtitle';
export const PLAN_PLAN_TYPE = 'plan_type';
export const PLAN_TYPE = 'type';
export const PLAN_VALIDITY = 'validity';
export const POSTAL_ADDRESS = 'postal_address';
export const PRIMARY_CONTACT_EMAIL_ADDRESS = 'primary_contact_email';
export const PRIMARY_CONTACT_FIRST_NAME = 'primary_contact_first_name';
export const PRIMARY_CONTACT_LAST_NAME = 'primary_contact_last_name';
export const PRIMARY_CONTACT_PHONE_NUMBER = 'primary_contact_phone_number';
export const PRIVATE_KEY = 'private_key';
export const PUBLIC_KEY = 'public_key';

// R
export const RECURRING_SUBSCRIPTION = 'recurring_subscription';
export const REDIRECT_TO_ORGANISATION = 'redirect_to_organisation';
export const REFERENCE = 'reference';
export const REFERENCE_NUMBER = 'reference_number';
export const REGISTRATION_NUMBER = 'registration_number';
export const REMINDER_NOTIFICATION_TIME = 'reminder_notification_time';
export const REMINDER_TIMEZONE = 'reminder_time_zone';
export const RETRY_ORDER_PAYMENT = 'retry_order_payment';
export const ROLE = 'role';
export const ROLES = 'roles';
export const REPORTING_DATE_RANGE = 'reporting_date_range';
export const REPORT_TYPE = 'report_type';

// S
export const SCHEME_ID = 'scheme_id';
export const SAVE_CARD_FOR_LATER_USE = 'save_card_for_later_use';
export const SEARCH_PARAM = 'search_param';
export const SEND_FOLLOW_UP_REMINDER = 'send_follow_up_task_reminder';
export const SEND_SETTINGS = 'send_settings';
export const SERVICE_CHARGE = 'service_charge';
export const SMS = 'sms';
export const SMS_FROM_NUMBER = 'communication_settings.sms_from_identifier';
export const SMS_FROM_USERNAME = 'communication_settings.sms_from_username';
export const SMS_PRIVATE_KEY = 'communication_settings.sms_private_key';
export const SMS_SECRET_KEY = 'communication_settings.sms_secret_key';
export const SMS_SENDER_ID = 'communication_settings.sms_from_name';
export const SMS_TEMPLATE = 'sms_template';
export const SMS_SETTINGS = 'sms_settings';
export const SORT_FIELD = 'sort_field';
export const SORT_ORDER = 'sort_order';
export const SUBJECT = 'subject';
export const SUBSCRIPTION = 'subscription';
export const SUBSCRIPTION_END_DATE = 'subscription_end_date';

// T
export const TAX_NAME = 'name';
export const TAX_DESCRIPTION = 'description';
export const TAX_RATE = 'rate';
export const TAX_TYPE_ID = 'tax_type_id';
export const TEMPLATE_TYPE = 'template_type';
export const TEMPLATE_TYPE_ID = 'template_type_id';
export const TIME = 'time';
export const TIMEZONE = 'time_zone';
export const TOKEN = 'token';
export const TRANSACTION_CHARGE = 'transaction_charge';
export const TRANSACTION_CHARGE_PERCENTAGE = 'transaction_charge_percentage';
export const TYPE_ID = 'type_id';

// U
export const UNIQUE_ID = 'unique_id';
export const USERNAME = 'username';
export const UPGRADE_SUBSCRIPTION = 'upgrade_subscription';

// V
export const VALUE = 'value';

// W
export const WEBHOOK_SECRET_KEY = 'webhook_secret_key';
export const WEBSITE = 'website';
export const WHATSAPP = 'whatsapp';
export const WHATSAPP_SETTINGS = 'whatsapp_settings';
export const WHATSAPP_FROM_IDENTIFIER = 'communication_settings.whatsapp_from_identifier';
export const WHATSAPP_FROM_NAME = 'communication_settings.whatsapp_from_name';
export const WHATSAPP_FROM_USERNAME = 'communication_settings.whatsapp_from_username';
export const WHATSAPP_PRIVATE_KEY = 'communication_settings.whatsapp_private_key';
export const WHATSAPP_SECRET_KEY = 'communication_settings.whatsapp_secret_key';

// Z
export const ZENDESK_ADD_COMMENT = 'add_comment';
export const ZENDESK_CONNECTED = 'connected';
export const ZENDESK_CONNECTION_FAILED = 'connection_failed';
export const ZENDESK_CREATE_TICKET = 'create_ticket';
export const ZENDESK_EMAIL = 'email';
export const ZENDESK_ERROR_MESSAGE = 'error_message';
export const ZENDESK_EVENT_TYPES = 'event_types';
export const ZENDESK_NOT_CONNECTED = 'not_connected';
export const ZENDESK_SUBDOMAIN = 'subdomain';
export const ZENDESK_TAGS = 'tags';
export const ZENDESK_TAGS_BY_EVENT_TYPE = 'tagsByEventType';
export const ZENDESK_TICKET_IS_PUBLIC = 'ticket_is_public';
export const ZENDESK_TICKET_PRIORITIES = 'ticket_priorities';
export const ZENDESK_TICKET_PRIORITY = 'ticket_priority';
export const ZENDESK_TICKET_STATUS = 'ticket_status';
export const ZENDESK_TICKET_STATUSES = 'ticket_statuses';
export const ZENDESK_TICKET_TYPE = 'ticket_type';
export const ZENDESK_TICKET_TYPES = 'ticket_types';
export const ZENDESK_TOKEN = 'token';
export const ZENDESK_EVENT_TYPE_FIELDS = [
    ZENDESK_CREATE_TICKET,
    ZENDESK_ADD_COMMENT,
    ZENDESK_TICKET_IS_PUBLIC,
    ZENDESK_TICKET_PRIORITY,
    ZENDESK_TICKET_STATUS,
    ZENDESK_TICKET_TYPE,
];

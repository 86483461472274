/**
 * Accounting Software Reducer
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';

const INITIAL_STATE = {
    accountingSoftwares: {},
    isLoading: false,
    doingAccountingSoftwareRequest: false,
};

/**
 * Accounting Software Reducer Function
 * Handles the state changes in accounting software
 *
 * @param   {object}    state   Current State
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function accountingSoftwareReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case actionTypes.GET_ACCOUNTING_SOFTWARE_FAILURE:
            return {
                ...state,
                doingAccountingSoftwareRequest: false,
                isLoading: false,
            };
        case actionTypes.GET_ACCOUNTING_SOFTWARE_REQUEST:
            return {
                ...state,
                doingAccountingSoftwareRequest: true,
                isLoading: true,
            };
        case actionTypes.GET_ACCOUNTING_SOFTWARE_SUCCESS:
            return {
                ...state,
                accountingSoftwares: action.accountingSoftwares,
                doingAccountingSoftwareRequest: false,
                isLoading: false,
            };
        default:
            return state;
    }
}

/**
 * Chart of accounts Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import ChartOfAccountsApi from '../services/chartOfAccounts';
import createAppSlice from './base';
import {
    CHART_OF_ACCOUNT,
    CHART_OF_ACCOUNT_TRANSACTIONS,
    CHART_OF_ACCOUNT_TYPES,
    CHART_OF_ACCOUNTS,
} from '../constants/mappings/success';
import { get, isFunction } from 'lodash';
import { getPaginationData } from '../utils';
import { showApiErrors, showApiSuccess } from '../utils/api';
import { ID } from '../constants/keys/response';

// set the initial state
const initialState = {
    chartOfAccount: {},
    chartOfAccounts: [],
    chartOfAccountsPagination: {},
    chartOfAccountTransactions: [],
    chartOfAccountTransactionsPagination: {},
    chartOfAccountTypes: [],
    getChartOfAccount: {},
    loadingChartOfAccount: false,
    loadingChartOfAccountAddEdit: false,
    loadingChartOfAccountDelete: false,
    loadingChartOfAccounts: false,
    loadingChartOfAccountTransactions: false,
    loadingChartOfAccountTypes: false,
};

// define the slice
const ChartOfAccountsSlice = createAppSlice('chartOfAccountsSlice', initialState);
const { setData } = ChartOfAccountsSlice.actions;

/**
 * Add a chart of account
 *
 * @param {object} payload Payload
 * @param {Function} callback Callback
 * @param {string} organisationId Organisation Id. Default ''
 */
export const addChartOfAccount = (payload, callback, organisationId = '') => async dispatch => {
    try {
        dispatch(setData('loadingChartOfAccountAddEdit', true));
        const result = await ChartOfAccountsApi.addChartOfAccount(payload, organisationId);
        const chartOfAccountId = get(result, [...CHART_OF_ACCOUNT, ID]);
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback(chartOfAccountId);
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'CHART_OF_ACCOUNT_FORM', 'CHART_OF_ACCOUNT'));
    } finally {
        dispatch(setData('loadingChartOfAccountAddEdit'));
    }
};

/**
 * Delete chart of account
 *
 * @param {string} chartOfAccountId Chart of account Id
 * @param {string} organisationId Organisation Id. Default ''
 * @param {Function} callback Callback Function. Default () => {}
 */
export const deleteChartOfAccount = (chartOfAccountId, organisationId = '', callback = () => {}) => async dispatch => {
    try {
        dispatch(setData('loadingChartOfAccountDelete', true));
        const result = await ChartOfAccountsApi.deleteChartOfAccount(chartOfAccountId, organisationId);
        dispatch(setData('chartOfAccounts', get(result, CHART_OF_ACCOUNTS)));
        dispatch(setData('chartOfAccountsPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'CHART_OF_ACCOUNTS'));
    } finally {
        dispatch(setData('loadingChartOfAccountDelete', false));
    }
};

/**
 * Get the chart of account
 *
 * @param {string} chartOfAccountId Chart of account id
 * @param {string} organisationId Organisation Id
 */
export const getChartOfAccount = (chartOfAccountId, organisationId = '') => async dispatch => {
    try {
        dispatch(setData('loadingChartOfAccount', true));
        const result = await ChartOfAccountsApi.getChartOfAccount(chartOfAccountId, organisationId);
        dispatch(setData('chartOfAccount', get(result, CHART_OF_ACCOUNT)));
    } catch (errors) {
        dispatch(showApiErrors(errors, 'CHART_OF_ACCOUNT_FORM', 'CHART_OF_ACCOUNT'));
    } finally {
        dispatch(setData('loadingChartOfAccount'));
    }
};

/**
 * Get the chart of accounts
 *
 * @param {string} organisationId Organisation Id. Default ''
 * @param {object} searchParams Search Params. Default {}
 * @param {Function} callback Callback function. Default ()=>{}
 */
export const getChartOfAccounts = (organisationId = '', searchParams = {}, callback = () => {}) => async dispatch => {
    try {
        dispatch(setData('loadingChartOfAccounts', true));
        const result = await ChartOfAccountsApi.getChartOfAccounts(searchParams, organisationId);
        dispatch(setData('chartOfAccounts', get(result, CHART_OF_ACCOUNTS)));
        dispatch(setData('chartOfAccountsPagination', getPaginationData({ result })));
        if (callback && isFunction(callback)) {
            callback(get(result, CHART_OF_ACCOUNTS));
        }
    } finally {
        dispatch(setData('loadingChartOfAccounts'));
    }
};

/**
 * Get the chart of account transactions
 *
 * @param {string} chartOfAccountId Chart of account id.
 * @param {string} organisationId Organisation Id
 * @param {object} searchParams Search Params. Default {}
 */
export const getChartOfAccountTransactions = (
    chartOfAccountId,
    organisationId,
    searchParams = {}
) => async dispatch => {
    try {
        dispatch(setData('loadingChartOfAccountTransactions', true));
        const result = await ChartOfAccountsApi.getChartOfAccountTransactions(
            chartOfAccountId,
            organisationId,
            searchParams
        );
        dispatch(setData('chartOfAccountTransactions', get(result, CHART_OF_ACCOUNT_TRANSACTIONS)));
        dispatch(setData('chartOfAccountTransactionsPagination', getPaginationData({ result })));
    } finally {
        dispatch(setData('loadingChartOfAccountTransactions'));
    }
};

/**
 * Get the chart of account types
 */
export const getChartOfAccountTypes = () => async dispatch => {
    try {
        dispatch(setData('loadingChartOfAccountTypes', true));
        const result = await ChartOfAccountsApi.getChartOfAccountTypes();
        dispatch(setData('chartOfAccountTypes', get(result, CHART_OF_ACCOUNT_TYPES)));
    } finally {
        dispatch(setData('loadingChartOfAccountTypes', false));
    }
};

/**
 * Update the chart of account
 *
 * @param {string} chartOfAccountId Chart of account id
 * @param {object} payload Payload
 * @param {Function} callback Callback
 * @param {string} organisationId Organisation Id. Default ''
 */
export const updateChartOfAccount = (chartOfAccountId, payload, callback, organisationId = '') => async dispatch => {
    try {
        dispatch(setData('loadingChartOfAccountAddEdit', true));
        const result = await ChartOfAccountsApi.updateChartOfAccount(chartOfAccountId, payload, organisationId);
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback(chartOfAccountId);
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'CHART_OF_ACCOUNT_FORM', 'CHART_OF_ACCOUNT'));
    } finally {
        dispatch(setData('loadingChartOfAccountAddEdit'));
    }
};

// export the reducer
export default ChartOfAccountsSlice.reducer;

/**
 * Xero Consent component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import the required modules
import { React, connect, PropTypes, useEffect, withTranslation } from '../../../../includes/exports/react';
import { get } from 'lodash';
import Button from '../../../shared/Button';
import * as constants from '../../../../includes/constants';
import { getAccountingSoftwareAuthLink } from '../../../../includes/redux/actions/customer/connect';
import { getAccountingSoftwares } from '../../../../includes/redux/actions/shared/accountingSoftware';
import * as utils from '../../../../includes/utils';
import { useDataFromStore, useUpdateBusinessName, useCheckEditPermission } from '../../../../includes/utils/hooks';
import { redirect } from '../../../../includes/utils';
import '../style.scss';

/**
 * Xero consent Component
 */
const XeroConsent = ({ ...props }) => {
    const [accountingSoftwares] = useDataFromStore({
        reducer: 'accountingSoftware',
        dataKey: 'accountingSoftwares',
        loadingKey: 'isLoading',
        action: getAccountingSoftwares,
    });

    useCheckEditPermission();
    useUpdateBusinessName();

    const accountingSoftwareId = utils.getLocalStorageValue(constants.USER_ACCOUNTING_SOFTWARE_ID_KEY);

    const getAccountingSoftwareSlug = () => get(accountingSoftwares, [accountingSoftwareId, 'slug']);

    /**
     * Use effect hook called on mount
     */
    useEffect(() => {
        // if the accounting software chosen is not xero, redirect to connect page
        if (getAccountingSoftwareSlug() !== 'xero') {
            redirect('/connect');
        }
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get title and meta tags for the component
     *
     * @returns {object} Account software name object
     */
    const getAccountingSoftwareName = () => ({
        accountingSoftware: get(accountingSoftwares, [accountingSoftwareId, 'name']),
    });

    /**
     * Get the authorization link
     */
    const getAuthorizationLink = () =>
        props.getAccountingSoftwareAuthLink({ accounting_software_id: accountingSoftwareId });

    return (
        <section className="connect-wrapper box-wrapper white-box-wrapper">
            <h2 className="welcome-paycepaid-text">Authorize Xero Connection</h2>
            <ul className="xero-paycepaid-logo">
                <li>
                    <img src={require(`../../../../assets/images/accounting_software/xero.png`)} alt="group" />
                </li>
                <li>
                    <img src={require('../../../../assets/images/rectangle.png')} alt="group" />
                </li>
                <li>
                    <img src={require('../../../../assets/images/paycepaid.svg')} style={{ width: 70 }} alt="group" />
                </li>
            </ul>
            <p className="app-tip-message">
                In order to receive payments for Invoices entered into <b>Xero</b>, you will need to authorise{' '}
                <b>Paycepaid</b> access to the following items and actions:
            </p>
            <ul className="consent-list">
                <li>View and manage organisation details and settings</li>
                <li>View and manage your Contacts, Invoices, Invoice Payments</li>
                <li>View and manage Business transactions</li>
                <li>View and manage Attachments</li>
                <li>View your Reports and General ledger</li>
                <li>View your name, email, and user profile.</li>
            </ul>
            <p style={{ marginBottom: '20px' }}>
                You will not be charged for this connection, but Xero may receive a fee from <b>Paycepaid</b> for
                providing this data connection service. Your credentials are encrypted, and we will never store your
                password
            </p>
            <Button
                loading={props.isLoading}
                onClick={getAuthorizationLink}
                big
                filled
                submittingButtonLabel={
                    props.connectToAccountingSoftwareLoading
                        ? props.t(
                              'customerMessages.connect.getAuthorizationSubmittingButtonLabel',
                              getAccountingSoftwareName()
                          )
                        : ''
                }
            >
                {props.t('customerMessages.connect.authorizeAndConnectXero')}
            </Button>
            <Button
                onClick={() => {
                    utils.removeLocalStorage(constants.USER_ACCOUNTING_SOFTWARE_ID_KEY);
                    redirect('/connect');
                }}
                big
                filled
                className="consent-cancel-button"
            >
                {props.t('customerMessages.connect.cancel')}
            </Button>
        </section>
    );
};

/**
 * Proptypes
 */
XeroConsent.propTypes = {
    accountingSoftwares: PropTypes.object,
    connectToAccountingSoftwareLoading: PropTypes.bool,
    getAccountingSoftwareAuthLink: PropTypes.func,
    isLoading: PropTypes.bool,
    localStorage: PropTypes.any,
    location: PropTypes.object,
    t: PropTypes.func,
};

//Connect to store
export default connect(
    state => ({
        isLoading: state.connect.loading,
        connectToAccountingSoftwareLoading: state.connect.connectToAccountingSoftwareLoading,
        accountingSoftwareErrorMessage: state.connect.accountingSoftwareErrorMessage,
        localStorage: state.localStorage,
    }),
    {
        getAccountingSoftwareAuthLink,
    }
)(withTranslation()(XeroConsent));

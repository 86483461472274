/**
 * Default Application Configuration
 * Sets up constants based on values specified in .env file
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//API versions and URL's
export const ACCESS_TOKEN_COOKIE_NAME = process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_VERSION_1 = process.env.REACT_APP_API_VERSION1;
export const CALENDLY_URL = process.env.REACT_APP_CALENDLY_URL;
export const COMMUNICATION_HISTORY_MINIMUM_AVAILABLE_DATE = process.env.REACT_APP_COMMUNICATION_HISTORY_AVAILABLE_DATE;
export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const ENABLE_IMPACT_TRACKING = process.env.REACT_APP_ENABLE_IMPACT_TRACKING;
export const ENABLE_XERO_CONSENT = process.env.REACT_APP_ENABLE_XERO_CONSENT;
export const SCHEDULE_MEETING_ON_REGISTRATION = process.env.REACT_APP_SCHEDULE_MEETING_ON_REGISTRATION;
export const SEGMENT_ANALYTICS_ID = process.env.REACT_APP_SEGMENT_ANALYTICS_ID;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
export const WORDPRESS_URL = process.env.REACT_APP_WORDPRESS_URL;

/**
 * Plan account add component
 * Handles creating subscription of account for the plan
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import {
    React,
    connect,
    Link,
    PropTypes,
    useCallback,
    useMemo,
    useState,
    withTranslation,
} from '../../../../includes/exports/react';
import * as errorMessages from '../../../../includes/constants/messages/errors';
import ReactHtmlParser from 'react-html-parser';
import SearchBlock from '../../../shared/SearchBlock';
import Table from '../../../shared/ScrollableTable';
import { ACCOUNT_TYPE } from '../../../../includes/constants/keys/request';
import { Breadcrumb, Modal } from 'antd';
import { createSubscriptionForAccount } from '../../../../includes/redux/actions/customer/subscriptionPlan';
import { CUSTOMER_ACCOUNT_TYPE_SLUG } from '../../../../includes/constants';
import { getAccounts } from '../../../../includes/redux/actions/shared/account';
import { getDefaultImageClass, getImageFromData } from '../../../../includes/utils';
import { PLAN_ACCOUNTS_ADD_LIST_TABLE_COLUMNS } from '../../../../includes/constants/columns';
import CreateSubscriptionForm from './components/CreateSubscriptionForm';
import useToBEDateFormatter from 'includes/hooks/useToBEDateFormatter';

const { confirm } = Modal;

/**
 * Plan accounts add component
 */
const PlanAccountsAdd = props => {
    const [showCreateSubscriptionModal, setsShowCreateSubscriptionModal] = useState(false);
    const [subscriptionAccountId, setSubscriptionAccountId] = useState('');
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const accountLogo45Key = 'ACCOUNT_LOGO_45X45';
    const accountLogoOriginalKey = 'ACCOUNT_LOGO_ORIGINAL';
    const { accounts } = props;
    const { planId } = props.match.params;
    const convertDateStringToBEFormat = useToBEDateFormatter();
    /**
     * Add Plan to account
     * Call the API for adding plan to the account
     *
     * @param   {string}    accountId    Account Id
     */
    const createSubscription = values => {
        const payload = { plan_id: planId, account_id: subscriptionAccountId, ...values };
        payload['subscription_end_date'] = values?.subscription_end_date
            ? convertDateStringToBEFormat(values.subscription_end_date)
            : null;
        props.createSubscriptionForAccount(payload, subscriptionError => {
            confirm({
                cancelText: props.t('appCommonMessages.no'),
                content: ReactHtmlParser(subscriptionError),
                okText: props.t('appCommonMessages.yes'),
                title: props.t('adminMessages.planAccounts.subscription.createSubscription'),
                onOk: () => {
                    props.createSubscriptionForAccount({
                        ...payload,
                        confirm: true,
                    });
                },
            });
        });
    };

    /**
     * Format the plan accounts add table data
     */
    const formatPlanAccountsAddTableData = useCallback(
        () =>
            accounts
                ? accounts.map(account => ({
                      ...account,
                      name: (
                          <>
                              <div className="table-name-align">
                                  <img
                                      className={
                                          getDefaultImageClass(
                                              account,
                                              accountLogo45Key,
                                              accountLogoOriginalKey,
                                              '45x45'
                                          ) + ' logo logo-45 '
                                      }
                                      src={getImageFromData(account, accountLogo45Key, accountLogoOriginalKey)}
                                      alt={account.name}
                                  />
                                  <div className="wrapped-content-text">
                                      <span className="wrapped-content-text crud-wrapper">{account.name}</span>
                                      <span className="crud-wrapper">
                                          <span
                                              className="crud-link"
                                              onClick={() => {
                                                  setSubscriptionAccountId(account.id);
                                                  setsShowCreateSubscriptionModal(true);
                                              }}
                                              title={props.t(
                                                  'adminMessages.planAccounts.subscription.createSubscriptionTooltip'
                                              )}
                                          >
                                              {props.t('adminMessages.planAccounts.subscription.createSubscription')}
                                          </span>
                                      </span>
                                  </div>
                              </div>
                          </>
                      ),
                  }))
                : [],
        [accounts] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Memoized plan accounts data
     */
    const memoizedPlanAccountsAddData = useMemo(() => formatPlanAccountsAddTableData(), [
        formatPlanAccountsAddTableData,
    ]);

    return (
        <div className="home-content-wrapper accounts-wrapper admin-accounts">
            <div className="tab-arrow-link">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={'/admin/plans/plans'}>{props.t('adminMessages.planAccounts.breadcrumb.plans')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {props.t(`adminMessages.planAccounts.breadcrumb.addPlanAccounts`)}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                <section>
                    <div className="right-align">
                        <SearchBlock
                            customDataValues={{ [ACCOUNT_TYPE]: CUSTOMER_ACCOUNT_TYPE_SLUG }}
                            getDataMethod={props.getAccounts}
                            isSearching={isSearching}
                            placeholder={props.t('adminMessages.accounts.search.placeholder')}
                            setIsSearching={setIsSearching}
                            setSearchParam={setSearchParam}
                        />
                    </div>
                </section>
                <Table
                    columns={PLAN_ACCOUNTS_ADD_LIST_TABLE_COLUMNS}
                    customDataValues={{ [ACCOUNT_TYPE]: CUSTOMER_ACCOUNT_TYPE_SLUG }}
                    dataSource={memoizedPlanAccountsAddData}
                    getDataMethod={props.getAccounts}
                    isSearching={isSearching}
                    loading={props.isLoading}
                    localeMessage={errorMessages.TABLE_NO_ACCOUNTS_FOUND_MESSAGE}
                    paginationData={props.paginationAccounts}
                    rowClassName="pointer"
                    rowKey="id"
                    size="middle"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    {...props}
                />
                <Modal
                    className="custom-form-modal text-left"
                    visible={showCreateSubscriptionModal}
                    footer={null}
                    onCancel={() => {
                        setSubscriptionAccountId('');
                        setsShowCreateSubscriptionModal(false);
                    }}
                >
                    <header>{props.t('adminMessages.planAccounts.subscription.createSubscription')}</header>
                    <CreateSubscriptionForm onSubmit={createSubscription} />
                </Modal>
            </div>
        </div>
    );
};
/**
 *Prop types
 */
PlanAccountsAdd.propTypes = {
    accounts: PropTypes.array,
    createSubscriptionForAccount: PropTypes.func,
    getAccounts: PropTypes.func,
    history: PropTypes.object,
    isCreatingSubscriptionPlan: PropTypes.bool,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    paginationAccounts: PropTypes.object,
    t: PropTypes.func,
};
export default connect(
    state => ({
        ...state.account,
        isCreatingSubscriptionPlan: state.subscription.isCreatingSubscriptionPlan,
        isLoading: state.account.isLoading,
    }),
    {
        createSubscriptionForAccount,
        getAccounts,
    }
)(withTranslation()(PlanAccountsAdd));

/**
 * Based router class
 * Handles the routing across the app
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import { React, Component, ConnectedRouter, Route, Switch } from '../../exports/react';
import history from '../../exports/history';
import * as CustomerAppRoutes from '../customer';
import * as AdminAppRoutes from '../admin';
import * as DebtCollectorRoutes from '../debtCollector';
import { get, isEmpty, isArray } from 'lodash';
import MainLayout from '../../../layouts/Main';

//this is done so that you can import multiple routes from different modules and add it in the array
//so that we don't need to change the parsing function
let ApplicationRoutes = [DebtCollectorRoutes, AdminAppRoutes, CustomerAppRoutes];

/**
 * App Router
 * Handles the routing in the app
 */
export default class AppRouter extends Component {
    /**
     * Render the routes
     *
     * @returns {*}
     */
    render() {
        return (
            <ConnectedRouter history={history}>
                <Switch>
                    {ApplicationRoutes.map(AppRoutes =>
                        AppRoutes.routes.map(AppRoute => {
                            let defaultAppRoutePrefix = get(AppRoutes, 'defaultAppRoutePrefix', '');
                            return (
                                <Route
                                    key="appRoute"
                                    path={defaultAppRoutePrefix + AppRoute.path}
                                    exact={AppRoute.exact}
                                    render={props => {
                                        let AppLayout = AppRoute.layout || AppRoutes.defaultAppLayout;
                                        if (
                                            !get(AppRoute, 'isPublic', false) &&
                                            get(AppRoutes, 'defaultAppValidator') &&
                                            get(AppRoute, 'preventDefaultValidation', false) === false
                                        ) {
                                            //if validators is not an array, make it an array
                                            if (!isArray(AppRoute.validators)) {
                                                AppRoute.validators = [];
                                            }

                                            AppRoute.validators = AppRoute.validators.concat(
                                                AppRoutes.defaultAppValidator
                                            );
                                        }

                                        //get the defined validators for the current route path
                                        let routeValidators =
                                            AppRoute.validators &&
                                            AppRoute.validators.map(function(validatorFunction) {
                                                return validatorFunction(props);
                                            });

                                        //filter to remove any false validators
                                        routeValidators =
                                            routeValidators &&
                                            routeValidators.filter(routeValidator => routeValidator !== false);

                                        //we take the first validation from the route validator specified
                                        //this is done in-order to render the validators in the order specified.
                                        //once the first validation specified in the router is success, the second one specified will become the first one after applying filter
                                        routeValidators = routeValidators && routeValidators[0];

                                        return (
                                            (AppRoute.validators && !isEmpty(routeValidators) && routeValidators) || (
                                                <MainLayout
                                                    {...props}
                                                    routeSlug={AppRoute.slug}
                                                    layout={AppLayout}
                                                    isPrivate={get(AppRoute, 'isPrivate', false)}
                                                    featureAvailableForManualOrganisationsOnly={get(
                                                        AppRoute,
                                                        'featureAvailableForManualOrganisationsOnly',
                                                        false
                                                    )}
                                                    requiredPermission={AppRoute.requiredPermission}
                                                    header={
                                                        !AppRoute.noHeader &&
                                                        ((AppRoute.header && <AppRoute.header {...props} />) ||
                                                            (AppRoutes.defaultAppHeader && (
                                                                <AppRoutes.defaultAppHeader {...props} />
                                                            )))
                                                    }
                                                    sidebar={
                                                        !AppRoute.noSidebar &&
                                                        ((AppRoute.sidebar && <AppRoute.sidebar {...props} />) ||
                                                            (AppRoutes.defaultAppSidebar && (
                                                                <AppRoutes.defaultAppSidebar {...props} />
                                                            )))
                                                    }
                                                    footer={
                                                        !AppRoute.noFooter &&
                                                        ((AppRoute.footer && <AppRoute.footer {...props} />) ||
                                                            (AppRoutes.defaultAppFooter && (
                                                                <AppRoutes.defaultAppFooter {...props} />
                                                            )))
                                                    }
                                                >
                                                    <AppRoute.component
                                                        {...props}
                                                        routeSlug={AppRoute.slug}
                                                        {...get(AppRoute, 'componentProps', {})}
                                                    />
                                                </MainLayout>
                                            )
                                        );
                                    }}
                                />
                            );
                        })
                    )}
                </Switch>
            </ConnectedRouter>
        );
    }
}

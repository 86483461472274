/**
 * Note types hooks
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { CACHE_NOTE_TYPES_KEY } from 'includes/constants';
import { get } from 'lodash';
import { GET_NOTE_TYPES } from 'includes/constants/mappings/success';
import NotesApi from 'includes/services/notes';
import useCachedData from './useCachedData';

/**
 * Fetches note types.
 * If cached get the data from cache else call the API and caches the result
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useNoteTypes = () => {
    return useCachedData(CACHE_NOTE_TYPES_KEY, () => NotesApi.getNoteTypes(), {
        selector: res => get(res, GET_NOTE_TYPES),
    });
};

export default useNoteTypes;

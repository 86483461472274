/**
 * Plan Quotes component
 * Handles the Quotes view and functions related to Quotes
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

// import required modules
import { React, connect, PropTypes, withTranslation } from '../../../includes/exports/react';
import * as errorMessages from '../../../includes/constants/messages/errors';
import Table from '../../shared/ScrollableTable';
import { getPlanQuotes } from '../../../includes/redux/actions/customer/subscriptionPlan';
import { PLAN_QUOTES_TABLE_COLUMNS } from '../../../includes/constants/columns';

/**
 * Plan quotes component
 */
const PlanQuotes = props => {
    return (
        <div className="home-content-wrapper accounts-wrapper admin-accounts">
            <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                <Table
                    columns={PLAN_QUOTES_TABLE_COLUMNS}
                    dataSource={props.planQuotes}
                    dataValues={[]}
                    getDataMethod={props.getPlanQuotes}
                    loading={props.isQuotesLoading}
                    localeMessage={errorMessages.TABLE_NO_PLAN_QUOTES_FOUND_MESSAGE}
                    paginationData={props.paginationPlanQuotes}
                    rowClassName="pointer"
                    rowKey="id"
                    size="middle"
                    {...props}
                />
            </div>
        </div>
    );
};
/**
 *Prop types
 */
PlanQuotes.propTypes = {
    getPlanQuotes: PropTypes.func,
    isQuotesLoading: PropTypes.bool,
    paginationPlanQuotes: PropTypes.object,
    planQuotes: PropTypes.array,
    t: PropTypes.func,
};
export default connect(
    state => ({
        isQuotesLoading: state.subscription.isQuotesLoading,
        paginationPlanQuotes: state.subscription.paginationPlanQuotes,
        planQuotes: state.subscription.planQuotes,
    }),
    {
        getPlanQuotes,
    }
)(withTranslation()(PlanQuotes));

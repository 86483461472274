/**
 * User Reducer
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';
import { get } from 'lodash';
import * as errorMessages from '../../../../constants/messages/errors';
import * as successMappings from '../../../../constants/mappings/success';

const initialState = {
    errorMessage: '',
    forceReRenderComponent: false,
    initialDataLoading: true,
    siteSettings: {},
    permissions: [],
};
/**
 * Settings Reducer Function
 *
 * @param   {object}    state   Current State       Default is initialState
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function userReducer(state = initialState, action = {}) {
    switch (action.type) {
        case actionTypes.FORCE_RE_RENDER_COMPONENT_REQUEST:
            return {
                ...state,
                forceReRenderComponent: true,
            };
        case actionTypes.FORCE_RE_RENDER_COMPONENT_FAILURE:
        case actionTypes.FORCE_RE_RENDER_COMPONENT_SUCCESS:
            return {
                ...state,
                forceReRenderComponent: false,
            };
        case actionTypes.GET_ALL_PERMISSIONS_SUCCESS:
            return {
                ...state,
                permissions: get(action.result, successMappings.GET_ALL_PERMISSIONS),
            };
        case actionTypes.GET_INITIAL_DATA_FAILURE:
            return {
                ...state,
                initialDataLoading: false,
                errorMessage: errorMessages.GET_INITIAL_DATA_FAILED,
            };
        case actionTypes.GET_INITIAL_DATA_REQUEST:
            return {
                ...state,
                initialDataLoading: true,
                errorMessage: '',
            };
        case actionTypes.GET_INITIAL_DATA_SUCCESS:
            return {
                ...state,
                initialDataLoading: false,
                errorMessage: '',
                siteSettings: get(action.result, successMappings.GET_SETTINGS_FROM_INITIAL_DATA),
            };
        case actionTypes.GET_INITIAL_DATA_STOP_LOADING:
            return {
                ...state,
                initialDataLoading: false,
                errorMessage: '',
            };
        case actionTypes.GET_SETTINGS_SUCCESS:
            return {
                ...state,
                initialDataLoading: false,
                errorMessage: '',
                siteSettings: get(action.result, successMappings.GET_SETTINGS),
            };
        default:
            return state;
    }
}

import { Icon, Menu } from 'antd';
import { find } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PaymentMethodDefaultSVG from 'assets/images/payment-method-default.svg';
import PaymentMethodType from 'components/PaymentGateway/components/PaymentMethodType';
import {
    CARD_BRAND_LOGO_MAP,
    CARD_BRAND_NAME_MAP,
    GENERIC_CARD_LOGO,
} from 'components/customer/PaymentInformation/constants';
import { PAYMENT_METHOD_STATUS } from 'includes/constants';
import usePaymentMethodStatuses from 'includes/hooks/usePaymentMethodStatuses';
import PaymentMethodBase from '../PaymentMethodBase';
import PaymentMethodStatus from '../PaymentMethodStatus';
import CountryLabel from 'components/CountryLabel';

/**
 * Payment method card
 *
 * Responsible for rendering a Card UI of a credit/debit card
 *
 * @since 2.8.0
 */
export default function PaymentMethodCard({
    accountHolderName,
    brand,
    countryId,
    expiryDate: _expiryDate,
    isDefault,
    number: _number,
    onDelete,
    onEdit,
    onMakeDefault,
    onViewDetails,
    statusId,
    typeId,
}) {
    // Memoise the card's expiry status and formatted string
    const { isExpired, expiryDate } = useMemo(() => {
        const date = moment(_expiryDate, 'YYYY-MM-DD').endOf('month');
        return { isExpired: date.isBefore(moment(new Date())), expiryDate: date.format('MM/YYYY') };
    }, [_expiryDate]);

    const { data: paymentMethodStatuses } = usePaymentMethodStatuses(true);

    const status = useMemo(() => {
        if (!paymentMethodStatuses || paymentMethodStatuses.length === 0) return null;

        return find(paymentMethodStatuses, { id: statusId });
    }, [statusId]);

    const { t } = useTranslation();

    const number = useMemo(() => _number.slice(-4), [_number]);

    const icon = (
        <img
            height={50}
            width={50}
            style={{ height: 50, width: 50 }}
            src={CARD_BRAND_LOGO_MAP[brand] ?? GENERIC_CARD_LOGO}
            alt={brand}
        />
    );

    const description = t('customerMessages.paymentMethod.card.description', {
        brand: CARD_BRAND_NAME_MAP[brand],
        number,
    });

    const validThruSection = (
        <div className={`flex flex-col ${isExpired ? 'text-red-500' : ''}`}>
            <div className="text-xs">
                {isExpired
                    ? t('customerMessages.paymentMethod.card.expiredOn', { date: expiryDate })
                    : t('customerMessages.paymentMethod.card.validThru', { date: expiryDate })}
            </div>
        </div>
    );
    const tags = (
        <>
            <PaymentMethodType id={typeId} />
            <PaymentMethodStatus id={statusId} />
        </>
    );

    return (
        <PaymentMethodBase
            accountHolderName={
                <>
                    {accountHolderName}
                    <CountryLabel id={countryId} />
                </>
            }
            description={description}
            icon={icon}
            overlayMenu={
                <Menu>
                    {status?.slug === PAYMENT_METHOD_STATUS.ACTIVE && !isDefault ? (
                        <Menu.Item
                            onClick={() => onMakeDefault()}
                            disabled={isExpired}
                            className="flex flex-row gap-1 flex-start align-middle items-center"
                        >
                            <img src={PaymentMethodDefaultSVG} />
                            <span>{t('customerMessages.paymentMethod.action.makeDefault')}</span>
                        </Menu.Item>
                    ) : null}
                    <Menu.Item onClick={onViewDetails} className="flex flex-row flex-start align-middle items-center">
                        <Icon type="eye" />
                        <span>{t('customerMessages.paymentMethod.action.view')}</span>
                    </Menu.Item>
                    <Menu.Item
                        onClick={onEdit}
                        disabled={isExpired}
                        className="flex flex-row flex-start align-middle items-center"
                    >
                        <Icon type="edit" />
                        <span>{t('customerMessages.paymentMethod.action.edit')}</span>
                    </Menu.Item>
                    <Menu.Item onClick={onDelete} className="flex flex-row flex-start items-center">
                        <Icon type="delete" />
                        <span>{t('customerMessages.paymentMethod.action.delete')}</span>
                    </Menu.Item>
                </Menu>
            }
            tags={tags}
            validity={validThruSection}
        />
    );
}

PaymentMethodCard.propTypes = {
    accountHolderName: PropTypes.string,
    brand: PropTypes.string,
    countryId: PropTypes.string,
    expiryDate: PropTypes.string,
    isDefault: PropTypes.bool,
    isExpired: PropTypes.bool,
    number: PropTypes.string,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onMakeDefault: PropTypes.func,
    onViewDetails: PropTypes.func,
    statusId: PropTypes.string,
    typeId: PropTypes.string,
};

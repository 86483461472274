/**
 * Form field component renders Antd components based on type
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import { Link, React, useDispatch, useEffect, useSelector, withTranslation } from '../../../includes/exports/react';
import PropTypes from 'prop-types';
import { Breadcrumb, Modal } from 'antd';

import { getPaymentGateways, deletePaymentGateway } from '../../../includes/slices/paymentGateways';
import { PAYMENT_GATEWAYS_TABLE } from '../../../includes/constants/columns';
import { TABLE_NO_PAYMENT_GATEWAYS_FOUND_MESSAGE } from '../../../includes/constants/messages/errors';
import Table from '../../shared/ScrollableTable';
import InfoMessage from '../../shared/messages/InfoMessage';
import { useParams } from 'react-router';

const { confirm } = Modal;

const PaymentGateways = props => {
    const paymentGateways = useSelector(state => state.paymentGateways.paymentGateways);

    const dispatch = useDispatch();

    const { accountId, organisationId } = useParams();

    const fetchPaymentGateways = () => dispatch(getPaymentGateways(organisationId, accountId));

    const isDeleting = useSelector(state => state.paymentGateways.loadingDeletePaymentGateway);

    useEffect(() => {
        fetchPaymentGateways();
    }, [accountId, organisationId]); //eslint-disable-line react-hooks/exhaustive-deps

    const onDeletePaymentGateway = id =>
        confirm({
            title: props.t('adminMessages.paymentGateways.messages.deleteTitle'),
            content: props.t('adminMessages.paymentGateways.messages.deletePrompt'),
            cancelText: props.t('appCommonMessages.no'),
            okText: props.t('appCommonMessages.yes'),
            onOk: () => {
                dispatch(deletePaymentGateway(id));
            },
        });

    const formattedTableData = paymentGateways.map(p => ({
        ...p,
        is_active: props.t(`customerMessages.table.verbose.${p.is_active ? 'yes' : 'no'}`),
        allow_saving_card: props.t(`customerMessages.table.verbose.${p.allow_saving_card ? 'yes' : 'no'}`),
        name: (
            <div>
                <div>{p.name}</div>
                <span className="crud-wrapper">
                    <Link
                        className="crud-link"
                        to={
                            accountId
                                ? organisationId
                                    ? `/admin/accounts/organisation/payment-gateways/${accountId}/${organisationId}/${p.id}`
                                    : `/admin/accounts/payment-gateways/${accountId}/${p.id}`
                                : `/admin/payment-gateway/${p.id}`
                        }
                    >
                        Edit
                    </Link>
                    {accountId ? null : (
                        <span className="crud-link" onClick={() => onDeletePaymentGateway(p.id)}>
                            Delete
                        </span>
                    )}
                </span>
            </div>
        ),
    }));

    return (
        <div className="home-content-wrapper">
            {/* <h2 className="page-title">{props.t('customerMessages.invoice.title')}</h2> */}
            <div className="tab-arrow-link breadcumb-spec">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/admin/accounts">Accounts</Link>
                    </Breadcrumb.Item>
                    {organisationId ? (
                        <Breadcrumb.Item>
                            <Link to={`/admin/accounts/organisations/${accountId}`}>Organisations</Link>
                        </Breadcrumb.Item>
                    ) : null}
                    <Breadcrumb.Item>Payment Gateways</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div
                className={`search-filter-export-wrapper ${
                    !props.contactId ? 'full-wrapper box-wrapper white-box-wrapper' : ''
                }`}
            >
                <Table
                    columns={PAYMENT_GATEWAYS_TABLE}
                    dataSource={formattedTableData}
                    dataValues={[]}
                    getDataMethod={fetchPaymentGateways}
                    localeMessage={TABLE_NO_PAYMENT_GATEWAYS_FOUND_MESSAGE}
                    rowClassName="pointer"
                    rowKey="id"
                    size="middle"
                    className="table-1200"
                    mobileClassName="table-1200"
                    {...props}
                />
                <Modal footer={[]} className="my_profile_modal organisation-reauthorization-modal" visible={isDeleting}>
                    <InfoMessage
                        message={props.t('adminMessages.paymentGateways.messages.deleting')}
                        showLoader={true}
                    />
                </Modal>
            </div>
        </div>
    );
};

PaymentGateways.propTypes = {
    contactId: PropTypes.string,
    t: PropTypes.func,
};

export default withTranslation()(PaymentGateways);

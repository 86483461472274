/**
 * useAllowZendeskIntegration
 * Check if zendesk integration is allowed for the account
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { get } from 'lodash';
import { useSelector } from 'react-redux';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';

/**
 * Check if zendesk integration is allowed for the account
 *
 * @returns {boolean} true|false
 */
const useAllowZendeskIntegration = () => {
    const isAdminOrManager = useIsAdminOrManager();
    const accountDetails = useSelector(state => state.account.accountDetails);
    const userAccountDetails = useSelector(state => state.account.userAccountDetails);

    return get(
        isAdminOrManager ? userAccountDetails : accountDetails,
        'subscription.plan.allow_zendesk_integration',
        false
    );
};

export default useAllowZendeskIntegration;

import React from 'react';
import Loader from 'components/Loader';

import './styles.scss';

export default function RedirectingPageLoaderSection() {
    return (
        <section className="white-box-wrapper box-wrapper full-wrapper redirecting-page-loader-wrapper">
            <Loader />
        </section>
    );
}

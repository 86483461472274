import { useMemo } from 'react';
import { find } from 'lodash';

import useInternalPaymentGateways from './useInternalPaymentGateways';
import { PAYMENT_GATEWAY } from 'includes/constants';

/**
 * Internal GoCardless details hook
 * Used to retrieve internal GoCardless payment gateway details
 *
 * @returns object containing keys `goCardlessDetails` which will contain the internal Stripe payment gateway details and `isLoading` with the loading status
 *
 * @since 2.8.0
 */
export default function useInternalGoCardlessDetails() {
    const { data: paymentGateways, isLoading } = useInternalPaymentGateways();

    const goCardlessDetails = useMemo(() => {
        if (!paymentGateways) return null;

        return find(paymentGateways, { slug: PAYMENT_GATEWAY.GOCARDLESS });
    }, [paymentGateways]);

    return { goCardlessDetails, isLoading };
}

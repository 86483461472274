/**
 * Organisation currencies listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import {
    addOrganisationCurrency,
    deleteOrganisationCurrency,
    getOrganisationCurrencies,
    updateOrganisationCurrency,
} from 'includes/slices/currencies';
import { Breadcrumb, Icon, Modal } from 'antd';
import { ORGANISATION_CURRENCY } from 'includes/constants/permissions';
import { CURRENCY_CODE, ID } from 'includes/constants/keys/response';
import { EXCHANGE_RATES } from 'includes/constants/keys/request';
import { find, get, isEmpty } from 'lodash';
import { getOrganisationCurrenciesTableColumns } from 'includes/tableColumns/organisationCurrencies';
import { TABLE_NO_ORGANISATION_CURRENCIES_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import Button from 'components/Button';
import InfoMessage from 'components/shared/messages/InfoMessage';
import OrganisationCurrenciesBreadCrumbItems from 'components/BreadCrumbItems/OrganisationCurrencies';
import OrganisationCurrencyForm from 'pages/OrganisationCurrencies/List/components/Form';
import PermissionGuard from 'components/PermissionGuard';
import Table from 'components/shared/ScrollableTable';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useIsManualOrganisation from 'includes/hooks/useIsManualOrganisation';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import useOrganisationCurrency from 'includes/hooks/useOrganisationCurrency';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useOrganisationRequired from 'includes/hooks/useOrganisationRequired';

const OrganisationCurrenciesList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAdmin = useIsAdminOrManager();
    const isManualOrganisation = useIsManualOrganisation();
    const organisationDefaultCurrency = useOrganisationCurrency();
    const organisationId = useOrganisationId();
    useUpdateBusinessName();
    useOrganisationRequired();

    const loadingOrganisationCurrencies = useSelector(state => state.currencies.loadingOrganisationCurrencies);
    const loadingOrganisationCurrencyDelete = useSelector(state => state.currencies.loadingOrganisationCurrencyDelete);
    const organisationCurrencies = useSelector(state => state.currencies.organisationCurrencies);
    const organisationCurrenciesPagination = useSelector(state => state.currencies.organisationCurrenciesPagination);

    const [currencyId, setCurrencyId] = useState();
    const [openFormModal, setOpenFormModal] = useState(false);
    const { reset: clearOrganisationCurrenciesCache } = useOrganisationCurrencies(true);

    /**
     * Clear organisation currencies cache and close the form
     */
    const clearCacheAndCloseForm = () => {
        clearOrganisationCurrenciesCache();
        closeFormModal();
    };

    /**
     * Close Form modal
     */
    const closeFormModal = () => setOpenFormModal(false);

    /**
     * Get the details of the organisation currency which is edited
     */
    const currencyDetails = useMemo(() => {
        const organisationCurrency = find(organisationCurrencies, { id: currencyId });
        return organisationCurrency
            ? {
                  currency_id: organisationCurrency.id,
                  exchange_rate: get(organisationCurrency, 'exchange_rates.is_user_defined', false)
                      ? get(organisationCurrency, 'exchange_rates.exchange_rate', null)
                      : null,
              }
            : {};
    });

    /**
     * Delete the organisation currency
     *
     * @param {string} organisationCurrencyId
     */
    const deleteSelectedOrganisationCurrency = organisationCurrencyId => {
        if (!isManualOrganisation) {
            return;
        }
        dispatch(deleteOrganisationCurrency(organisationId, organisationCurrencyId, clearOrganisationCurrenciesCache));
    };

    /**
     * Get the locale text
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @returns {string} Locale test
     */
    const getLocaleText = path => t(`messages.organisationCurrencies.${path}`);

    /**
     * Format the organisation currencies data
     */
    const formatOrganisationCurrenciesData = useCallback(
        () =>
            organisationCurrencies.map(organisationCurrency => {
                const isDefaultOrganisationCurrencyId =
                    get(organisationDefaultCurrency, ID) === organisationCurrency.id ? true : false;
                return {
                    ...organisationCurrency,
                    exchange_rate: isDefaultOrganisationCurrencyId
                        ? ''
                        : get(organisationCurrency, 'exchange_rates.exchange_rate'),
                    inverse_exchange_rate: isDefaultOrganisationCurrencyId
                        ? ''
                        : get(organisationCurrency, 'exchange_rates.inverse_exchange_rate'),
                    last_updated_date: isDefaultOrganisationCurrencyId
                        ? ''
                        : get(organisationCurrency, 'exchange_rates.last_updated_date'),
                    name: (
                        <div className="business-name-avatar-wrapper">
                            <div className="action-wrapper">
                                {`${organisationCurrency.name} [${organisationCurrency.code}]`}
                                {isDefaultOrganisationCurrencyId ? (
                                    <span title="Organisation Default Currency">
                                        <Icon
                                            type="check"
                                            style={{
                                                color: 'green',
                                                marginLeft: '10px',
                                                fontSize: '30px',
                                                fontWeight: 'bold',
                                            }}
                                        />
                                    </span>
                                ) : null}
                                <span className="crud-wrapper">
                                    {!isDefaultOrganisationCurrencyId ? (
                                        <>
                                            <PermissionGuard requiredPermission={ORGANISATION_CURRENCY.EDIT}>
                                                <button
                                                    className="crud-link"
                                                    onClick={() => {
                                                        setCurrencyId(organisationCurrency.id);
                                                        setOpenFormModal(true);
                                                    }}
                                                >
                                                    {getLocaleText('crud.edit')}
                                                </button>
                                            </PermissionGuard>
                                            {isManualOrganisation ? (
                                                <PermissionGuard requiredPermission={ORGANISATION_CURRENCY.DELETE}>
                                                    <button
                                                        className="crud-link"
                                                        onClick={() =>
                                                            deleteSelectedOrganisationCurrency(organisationCurrency.id)
                                                        }
                                                    >
                                                        {getLocaleText('crud.delete')}
                                                    </button>
                                                </PermissionGuard>
                                            ) : null}
                                        </>
                                    ) : null}
                                </span>
                            </div>
                        </div>
                    ),
                };
            }),
        [organisationCurrencies] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const handleSubmit = values => {
        if (EXCHANGE_RATES) {
            delete values[EXCHANGE_RATES];
        }

        currencyId
            ? dispatch(updateOrganisationCurrency(organisationId, currencyId, values, clearCacheAndCloseForm))
            : dispatch(addOrganisationCurrency(organisationId, values, clearCacheAndCloseForm));
    };

    /**
     * Memoized organisation currencies data
     */
    const memoizedOrganisationCurrenciesData = useMemo(() => formatOrganisationCurrenciesData(), [
        formatOrganisationCurrenciesData,
    ]);

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
            <h2 className="page-title">{t(`titleAndMetas.organisationCurrencies.title`)}</h2>
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <OrganisationCurrenciesBreadCrumbItems showListBreadCrumb={isAdmin} />
                </Breadcrumb>
            </div>
            <div className="search-filter-export-wrapper contact-list-container full-wrapper box-wrapper white-box-wrapper">
                {isManualOrganisation ? (
                    <section className="right-menu-wrapper">
                        <div className="right-align">
                            <PermissionGuard requiredPermission={ORGANISATION_CURRENCY.ADD}>
                                <Button
                                    big
                                    filled
                                    onClick={() => {
                                        setCurrencyId('');
                                        setOpenFormModal(true);
                                    }}
                                >
                                    {getLocaleText('crud.add')}
                                </Button>
                            </PermissionGuard>
                        </div>
                    </section>
                ) : null}
                <div className="reminders-container">
                    <InfoMessage message={getLocaleText('list.info.supportedCurrenciesMessage')} />
                </div>
                <Table
                    className="table-1200"
                    columns={getOrganisationCurrenciesTableColumns(get(organisationDefaultCurrency, CURRENCY_CODE))}
                    dataSource={memoizedOrganisationCurrenciesData}
                    dataValues={[organisationId]}
                    dispatchMethod={true}
                    getDataMethod={organisationId ? getOrganisationCurrencies : null}
                    loading={loadingOrganisationCurrencies}
                    localeMessage={TABLE_NO_ORGANISATION_CURRENCIES_FOUND_MESSAGE}
                    mobileClassName="table-1200"
                    paginationData={organisationCurrenciesPagination}
                    rowClassName="pointer"
                    rowKey="id"
                    size="middle"
                />
                <Modal
                    footer={[]}
                    className="custom-form-modal text-left"
                    visible={openFormModal}
                    onCancel={closeFormModal}
                    destroyOnClose
                >
                    <header>{getLocaleText(!isEmpty(currencyDetails) ? 'addEdit.edit' : 'addEdit.add')}</header>
                    <OrganisationCurrencyForm
                        closeModalPopup={closeFormModal}
                        initialValues={currencyDetails}
                        onSubmit={handleSubmit}
                        isAdd={currencyId ? false : true}
                    />
                </Modal>
                {isManualOrganisation ? (
                    <Modal
                        footer={[]}
                        className="my_profile_modal organisation-reauthorization-modal"
                        visible={loadingOrganisationCurrencyDelete}
                    >
                        <InfoMessage
                            message={t('messages.organisationCurrencies.modal.message.delete')}
                            showLoader={true}
                        />
                    </Modal>
                ) : null}
            </div>
        </div>
    );
};

export default OrganisationCurrenciesList;

/**
 * useCommunicationTypes
 * Get the communication types
 *
 * @since 2.0.0
 */

import { CACHE_COMMUNICATION_TYPES_KEY } from 'includes/constants';
import { COMMUNICATION_TYPES } from 'includes/constants/mappings/success';
import { get } from 'lodash';
import CommunicationApi from 'includes/services/shared/communication';
import useCachedData from './useCachedData';

/**
 * Get the communication types
 *
 * @param {boolean} disableApiCall Whether to disable api call or not. Default false
 *
 * @returns object Communication types data
 */
const useCommunicationTypes = (disableApiCall = false) => {
    return useCachedData(CACHE_COMMUNICATION_TYPES_KEY, () => CommunicationApi.getCommunicationTypes(), {
        selector: res => get(res, COMMUNICATION_TYPES),
        disableApiCall: disableApiCall,
        dependsOnOrg: true,
    });
};

export default useCommunicationTypes;

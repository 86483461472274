/**
 * Change Password Form
 * Renders the change password form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modules
import { React } from '../../../includes/exports/react';
import { Form, Row, Col } from 'antd';
import Button from '../Button';
import * as constants from '../../../includes/constants';
import * as formValidations from '../../../includes/utils/form';
import FormField from '../FormField';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import * as requestKeys from '../../../includes/constants/keys/request';

/**
 * Change Password Form
 * Renders the change password form
 */

const ChangePassword = props => {
    /**
     * Generate the label from language file
     *
     * @param   {string}    field   Field for which locale is to be generated
     *
     * @returns {string}    Generated Locale label
     */
    const localeLabel = field => props.t(`sharedMessages.changePassword.form.input.label.${field}`);

    return (
        <Form layout="vertical" className="myaccount-form myaccount-business-info-form" onSubmit={props.handleSubmit}>
            <Row gutter={16}>
                <Col>
                    <Field
                        type="password"
                        name={requestKeys.CURRENT_PASSWORD}
                        label={localeLabel(requestKeys.CURRENT_PASSWORD)}
                        hasFeedback
                        className="form-control"
                        component={FormField}
                        validate={[formValidations.required]}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col>
                    <Field
                        type="password"
                        name={requestKeys.PASSWORD}
                        label={localeLabel(requestKeys.PASSWORD)}
                        hasFeedback
                        component={FormField}
                        validate={[
                            formValidations.required,
                            formValidations.passwordLength,
                            formValidations.passwordLowerCaseCheck,
                            formValidations.passwordNumberCheck,
                            formValidations.passwordUpperCaseCheck,
                            formValidations.passwordSpecialCharacterCheck,
                        ]}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col>
                    <Field
                        type="password"
                        name={requestKeys.CONFIRM_PASSWORD}
                        label={localeLabel(requestKeys.CONFIRM_PASSWORD)}
                        hasFeedback
                        component={FormField}
                        validate={[formValidations.required, formValidations.confirmPassword]}
                    />
                </Col>
            </Row>
            <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                <Button
                    htmlType="submit"
                    big
                    filled
                    loading={props.changePasswordLoading}
                    submittingButtonLabel={props.t('sharedMessages.changePassword.form.submit.buttonSubmittingLabel')}
                    className="register-btn"
                >
                    {props.t('sharedMessages.changePassword.form.submit.buttonLabel')}
                </Button>
            </Form.Item>
            <div className="clearfix" />
        </Form>
    );
};

/**
 * Proptypes
 */
ChangePassword.propTypes = {
    changePasswordLoading: PropTypes.bool,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    t: PropTypes.func,
};

export default reduxForm({
    form: constants.CHANGE_PASSWORD_FORM_NAME,
})(withTranslation()(ChangePassword));

/**
 * Send Reminder Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { find, get, isArray, isEmpty } from 'lodash';

import OnDemandCampaignForm from './components/ReduxForm';
import { useCheckEditPermission, usePlaceholderDataFromStore } from 'includes/utils/hooks';
import { sendOnDemandCampaign } from 'includes/redux/actions/customer/reminder';
import { INVOICE_IDS, NOTIFICATION_TYPE_ID, ORGANISATION_ID } from 'includes/constants/keys/request';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useZendeskSettings from 'includes/hooks/useZendeskSettings';
import Loader from 'components/shared/Loader';
import useCommunicationTemplateTypes from 'includes/hooks/useCommunicationTemplateTypes';
import useCommunicationTypes from 'includes/hooks/useCommunicationTypes';
import useCommunicationTemplates from 'includes/hooks/useCommunicationTemplates';

const { confirm } = Modal;

/**
 * On Demand Campaign component
 */
const OnDemandCampaign = props => {
    const {
        contactCC,
        contactIds,
        contactNames,
        customSubmitHandler,
        fillDefaultTemplateDetails,
        getFilterParams,
        getToText,
        initialValues: initialValuesFromProps,
        invoiceIds,
        rowSelection,
        setInvoiceIds,
        setShowOnDemandCampaignModal,
        templateTypes,
        totalCount,
        sendButtonText,
    } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const organisationId = useOrganisationId();
    const { data: zendeskSettings } = useZendeskSettings();
    const communicationTypes = useSelector(state => state.communication.communicationTypes);
    const {
        data: communicationTemplateTypes,
        isLoading: isCommunicationTemplateTypesLoading,
    } = useCommunicationTemplateTypes();
    const { isLoading: isCommunicationTypesLoading } = useCommunicationTypes();
    const { data: communicationTemplates, isLoading: isCommunicationTemplatesLoading } = useCommunicationTemplates();

    // check if user has permission
    useCheckEditPermission();

    // get the placeholders data
    usePlaceholderDataFromStore(organisationId);

    /**
     * Close the on-demand campaign modal
     * Also resets the selected invoice ids
     */
    const closeOnDemandCampaignModal = () => {
        setInvoiceIds([]);
        if (rowSelection) {
            rowSelection.onChange([]);
        }
        setShowOnDemandCampaignModal(false);
    };

    /**
     * Initial value to passed to the form
     *
     * if initial value is present in the props, it'll use it,
     * if not it'll check for the `fillDefaultTemplateDetails` in the props, and if its present,
     * default values are passed or else undefined
     */
    const initialValues = useMemo(() => {
        if (!isEmpty(initialValuesFromProps)) {
            return initialValuesFromProps;
        } else if (!fillDefaultTemplateDetails) {
            return undefined;
        }
        const emailTypeId = get(find(communicationTypes, { slug: 'email' }), 'id');
        const friendlyReminderTemplateTypeId = get(
            find(communicationTemplateTypes, { slug: 'friendly-reminder' }),
            'id'
        );
        const newInvoiceFriendlyReminder = find(
            get(communicationTemplates, [emailTypeId, friendlyReminderTemplateTypeId]),
            { slug: 'new-invoice-reminder-email' }
        );

        if (!newInvoiceFriendlyReminder) {
            return undefined;
        }

        return {
            notification_type_id: emailTypeId,
            communication_template_type_id: friendlyReminderTemplateTypeId,
            communication_template_id: newInvoiceFriendlyReminder.id,
            cc: isArray(contactCC)
                ? contactCC.flatMap(c => (c.email && c.include_in_emails === true ? c.email : '')).filter(e => e)
                : '',
        };
    }, [
        initialValuesFromProps,
        fillDefaultTemplateDetails,
        communicationTypes,
        communicationTemplateTypes,
        communicationTemplates,
    ]);

    /**
     * Send On Demand Campaign to the invoices
     *
     * @param {object} values Form Values
     */
    const handleSubmit = values => {
        confirm({
            title: t('customerMessages.campaign.onDemandCampaign.form.submit.send.popup.title'),
            content: t('customerMessages.campaign.onDemandCampaign.form.submit.send.popup.content', {
                count: invoiceIds.includes('all') ? totalCount : invoiceIds.length,
                notificationType: get(find(communicationTypes, { id: values[NOTIFICATION_TYPE_ID] }), 'slug', ''),
            }),
            onOk: () => {
                values[ORGANISATION_ID] = organisationId;
                values[INVOICE_IDS] = invoiceIds;
                dispatch(
                    sendOnDemandCampaign(
                        values,
                        {
                            organisation_id: organisationId,
                        },
                        closeOnDemandCampaignModal,
                        getFilterParams(),
                        onDemandCampaignError => {
                            confirm({
                                title: 'Are you sure?',
                                content: onDemandCampaignError,
                                onOk: () => {
                                    dispatch(
                                        sendOnDemandCampaign(
                                            { ...values, confirm: true },
                                            {
                                                organisation_id: organisationId,
                                            },
                                            closeOnDemandCampaignModal,
                                            getFilterParams()
                                        )
                                    );
                                },
                            });
                        }
                    )
                );
            },
        });
    };

    return isCommunicationTemplateTypesLoading || isCommunicationTemplatesLoading || isCommunicationTypesLoading ? (
        <Loader />
    ) : (
        <OnDemandCampaignForm
            allPlaceholders={get(props, 'allPlaceholders')}
            contactCC={contactCC}
            contactIds={contactIds}
            contactNames={contactNames}
            customSubmitHandler={customSubmitHandler}
            enableReinitialize
            getToText={getToText}
            initialValues={initialValues}
            invoiceIds={invoiceIds}
            onSubmit={handleSubmit}
            sendButtonText={sendButtonText}
            templateTypes={templateTypes}
            zendeskSettings={zendeskSettings}
        />
    );
};

OnDemandCampaign.propTypes = {
    contactCC: PropTypes.arrayOf(PropTypes.object),
    contactIds: PropTypes.arrayOf(PropTypes.string),
    contactNames: PropTypes.arrayOf(PropTypes.string),
    customSubmitHandler: PropTypes.func,
    fillDefaultTemplateDetails: PropTypes.bool,
    getFilterParams: PropTypes.func,
    getToText: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    invoiceIds: PropTypes.array,
    rowSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    sendButtonText: PropTypes.string,
    setInvoiceIds: PropTypes.func,
    setShowOnDemandCampaignModal: PropTypes.func,
    templateTypes: PropTypes.arrayOf(PropTypes.object),
    totalCount: PropTypes.number,
};

export default OnDemandCampaign;

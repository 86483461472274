import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { PAYMENT_METHOD_TYPE } from 'includes/constants';
import usePaymentMethodTypes from 'includes/hooks/usePaymentMethodTypes';
import PaymentMethodCard from '../PaymentMethodCard';
import PaymentMethodMandate from '../PaymentMethodMandate';

/**
 * Payment method component
 * takes in type of payment method and displays the appropriate card or mandate details
 *
 * @since 2.8.0
 */
export default function PaymentMethod({ details, onDelete, onEdit, onMakeDefault, onViewDetails, typeId }) {
    const { data: paymentMethodTypes } = usePaymentMethodTypes(true);

    const type = useMemo(() => {
        if (!paymentMethodTypes) return null;

        return find(paymentMethodTypes, { id: typeId });
    }, [paymentMethodTypes, typeId]);

    switch (type?.slug) {
        case PAYMENT_METHOD_TYPE.CREDIT_CARD:
        case PAYMENT_METHOD_TYPE.DEBIT_CARD:
            return (
                <PaymentMethodCard
                    accountHolderName={details.name}
                    brand={details.brand_name}
                    countryId={details.country_id}
                    expiryDate={details.end_date}
                    isDefault={details.is_default}
                    number={details.account_number}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onMakeDefault={onMakeDefault}
                    onViewDetails={onViewDetails}
                    statusId={details.status_id}
                    typeId={typeId}
                />
            );
        case PAYMENT_METHOD_TYPE.MANDATE:
            return (
                <PaymentMethodMandate
                    accountHolderName={details.account_holder_name}
                    bankName={details.brand_name}
                    countryId={details.country_id}
                    expiryDate={details.end_date}
                    isDefault={details.is_default}
                    mandateName={details.name}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onMakeDefault={onMakeDefault}
                    onViewDetails={onViewDetails}
                    schemeId={details.scheme_id}
                    statusId={details.status_id}
                    typeId={typeId}
                />
            );
        default:
            return null;
    }
}

PaymentMethod.propTypes = {
    details: PropTypes.shape({
        account_holder_name: PropTypes.string,
        account_number: PropTypes.string,
        brand_name: PropTypes.string,
        country_id: PropTypes.string,
        end_date: PropTypes.string,
        is_default: PropTypes.bool,
        name: PropTypes.string,
        scheme_id: PropTypes.string,
        status_id: PropTypes.string,
    }).isRequired,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onMakeDefault: PropTypes.func,
    onViewDetails: PropTypes.func,
    typeId: PropTypes.string.isRequired,
};

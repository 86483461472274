/**
 * Intercom component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */
//import the required modules
import { connect, useEffect, useMemo } from '../../../includes/exports/react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { PORTAL_CUSTOMER_SLUG, USER_ROLE_CUSTOMER } from 'includes/constants';

/**
 * LiveChat component
 */
const LiveChat = props => {
    const siteSettings = useSelector(state => state.settings.siteSettings);
    const customerPortalDetails = get(siteSettings, ['portals', PORTAL_CUSTOMER_SLUG]);

    /**
     * Get the  user attributes
     *
     * @returns  {object}    user attributes
     */
    const getUserAttributes = () => {
        let intercomUserAttributes = {};
        if (props.token && get(props.accountDetails, 'unique_id', '')) {
            intercomUserAttributes = {
                connected_accounting_software_name: get(props.accountDetails, 'connected_accounting_software', ''),
                connected_to_accounting_software: !!get(props.accountDetails, 'connected_accounting_software', false),
                email: props.userDetails.email,
                name: props.userDetails.full_name,
                organisations_added: !!get(props.selectedOrganisation, 'unique_id', false),
                user_account_name: props.accountDetails.name,
                user_account_unique_identifier: props.accountDetails.unique_id,
                user_type: get(props.userDetails, 'roles.' + props.accountDetails.id + '.role'),
                user_unique_identifier: props.userDetails.unique_id,
            };

            if (get(props.accountDetails, 'subscription.plan_name', '')) {
                intercomUserAttributes.plan_name = get(props.accountDetails, 'subscription.plan_name');
                intercomUserAttributes.is_under_custom_plan = get(
                    props.accountDetails,
                    'subscription.plan.is_custom',
                    false
                );
            }

            if (get(props.accountDetails, 'subscription.expiry_date', '')) {
                intercomUserAttributes.subscription_expiry_date = get(props.accountDetails, 'subscription.expiry_date');
            }

            if (get(props.selectedOrganisation, 'has_invoice_with_due', false)) {
                intercomUserAttributes.invoice_scheduled = true;
            }
        }
        return intercomUserAttributes;
    };

    const isCustomer = useMemo(
        () => Object.values(props.userDetails?.roles ?? {}).some(role => role.slug === USER_ROLE_CUSTOMER),
        [props.userDetails]
    );

    /**
     * Check if live chat is allowed for the user
     *
     * @returns boolean true|false
     */
    const isLiveChatAllowed = useMemo(() => {
        return (
            get(customerPortalDetails, 'live_chat_app_id', '') &&
            isCustomer &&
            get(customerPortalDetails, 'live_chat_enabled', false)
        );
    }, [customerPortalDetails, isCustomer]);

    const loadChatScriptByURL = liveChatId => {
        const url = `//js-na1.hs-scripts.com/${liveChatId}.js`;
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        const _hsq = (window._hsq = window._hsq || []);
        _hsq.push(['identify', getUserAttributes()]);
        _hsq.push(['trackPageView']);
    };

    useEffect(() => {
        if (isLiveChatAllowed) {
            loadChatScriptByURL(get(customerPortalDetails, 'live_chat_app_id', ''));
        }
    }, [isLiveChatAllowed, customerPortalDetails]);

    return null;
};

/**
 * Prop types
 */
LiveChat.propTypes = {
    accountDetails: PropTypes.object,
    selectedOrganisation: PropTypes.object,
    token: PropTypes.string,
    userDetails: PropTypes.object,
};

//Connect to store
export default connect(
    state => ({
        accountDetails: state.account.accountDetails,
        token: state.user.token,
        selectedOrganisation: state.organisation.selectedOrganisation,
        userDetails: state.user.userDetails,
    }),
    {}
)(LiveChat);

/**
 * User Reducer
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';
import * as errorMappings from '../../../../constants/mappings/errors';
import * as errorMessages from '../../../../constants/messages/errors';
import { get } from 'lodash';
import * as successMappings from '../../../../constants/mappings/success';
import { getCookieValue, getPaginationData } from '../../../../utils';
import { ACCESS_TOKEN_COOKIE_NAME } from 'includes/constants/config';

const initialState = {
    customBranding: false,
    editUserDetails: null,
    emailVerified: true,
    FAQs: [],
    isActivatingAccount: false,
    isChangingUserStatus: false,
    isResendingActivationEmail: false,
    isResettingPassword: false,
    isResettingTwoFactorAuthentication: false,
    isUserRolesLoading: false,
    isValidatingResetPassword: false,
    isVerifyingEmail: true,
    message: '',
    paginationUserRoles: {},
    paginationUsers: {},
    roles: {},
    token: getCookieValue(ACCESS_TOKEN_COOKIE_NAME),
    userDetails: null,
    userDetailsErrorMessage: '',
    userDetailsLoading: false,
    userNotExistInAccount: false,
    userRoles: [],
    userAccountRoles: [],
    users: [],
};
/**
 * User Reducer Function
 *
 * @param   {object}    state   Current State       Default is initialState
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function userReducer(state = initialState, action = {}) {
    switch (action.type) {
        case actionTypes.CHANGE_USER_STATUS_FAILURE:
            return {
                ...state,
                isChangingUserStatus: false,
            };
        case actionTypes.CHANGE_USER_STATUS_REQUEST:
            return {
                ...state,
                isChangingUserStatus: true,
            };
        case actionTypes.CHANGE_USER_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                paginationUsers: getPaginationData(action),
                users: get(action.result, successMappings.GET_USERS),
                editUserDetails: null,
                isChangingUserStatus: false,
            };
        case actionTypes.FORGOT_USER_PASSWORD_FAILURE:
            return {
                ...state,
                forgotPasswordLoading: false,
                message: '',
            };
        case actionTypes.FORGOT_USER_PASSWORD_REQUEST:
            return {
                ...state,
                forgotPasswordLoading: true,
                message: '',
            };
        case actionTypes.FORGOT_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotPasswordLoading: false,
                message: get(action.result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
            };
        case actionTypes.GET_ALL_CONTACT_INVOICES_SUCCESS: {
            return {
                ...state,
                customBranding: get(action.result, successMappings.GET_CUSTOM_BRANDING, false),
            };
        }
        case actionTypes.GET_USER_PROFILE_DETAILS_FAILURE:
            return {
                ...state,
                userDetailsLoading: false,
                userDetailsErrorMessage: errorMessages.GET_USER_DETAILS_FAILED,
            };
        case actionTypes.GET_USER_PROFILE_DETAILS_REQUEST:
            return {
                ...state,
                userDetailsLoading: true,
                userDetailsErrorMessage: '',
            };
        case actionTypes.GET_USER_PROFILE_DETAILS_SUCCESS:
            return {
                ...state,
                userDetailsLoading: false,
                userDetails: action.result.data.data.user_details,
                userDetailsErrorMessage: '',
            };
        case actionTypes.GET_USER_PROFILE_DETAILS_FROM_INITIAL_DATA_SUCCESS:
            return {
                ...state,
                userDetails: get(action, successMappings.GET_USER_DETAILS_FROM_INITIAL_DATA),
                roles: action.roles,
            };
        case actionTypes.RESEND_VERIFICATION_EMAIL_FAILURE:
            return {
                ...state,
                hasError: true,
                isResendingEmail: false,
                resendEmail: false,
            };
        case actionTypes.RESEND_VERIFICATION_EMAIL_REQUEST:
            return {
                ...state,
                hasError: false,
                isResendingEmail: true,
                message: '',
                resendEmail: false,
            };
        case actionTypes.RESEND_VERIFICATION_EMAIL_SUCCESS:
            return {
                ...state,
                hasError: false,
                isResendingEmail: false,
                message: action.result.data.message,
                resendEmail: true,
            };
        case actionTypes.RESEND_VERIFICATION_SMS_FAILURE:
            return {
                ...state,
                hasError: true,
                isResendingSms: false,
                message: '',
                resendSms: false,
            };
        case actionTypes.RESEND_VERIFICATION_SMS_REQUEST:
            return {
                ...state,
                hasError: false,
                isResendingSms: true,
                message: '',
                resendSms: false,
            };
        case actionTypes.RESEND_VERIFICATION_SMS_SUCCESS:
            return {
                ...state,
                hasError: false,
                isResendingSms: false,
                message: action.result.data.message,
                resendSms: true,
            };
        case actionTypes.USER_CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordLoading: false,
                passwordUpdated: false,
                passwordUpdatedMessage: '',
            };
        case actionTypes.USER_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                changePasswordLoading: true,
                passwordUpdated: false,
                passwordUpdatedMessage: '',
            };
        case actionTypes.USER_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordLoading: false,
                passwordUpdated: true,
                passwordUpdatedMessage: action.result.data.message,
            };
        case actionTypes.USER_LOGIN_FAILED:
            return {
                ...state,
                loginLoading: false,
            };
        case actionTypes.USER_LOGIN_REQUEST:
            return {
                ...state,
                loginLoading: true,
            };
        case actionTypes.USER_LOGIN_SUCCESS:
            return {
                ...state,
                loginLoading: false,
                token: get(action, successMappings.USER_LOGIN_GET_ACCESS_TOKEN),
                userDetails: get(action, successMappings.GET_USER_DETAILS),
                roles: action.roles,
            };
        case actionTypes.USER_LOGIN_SUCCESS_MULTIPLE_ACCOUNTS:
            return {
                ...state,
                accountTokens: action.tokens,
                userAccounts: action.accounts,
            };
        case actionTypes.USER_LOGOUT_REQUEST:
            return {
                ...state,
                token: undefined,
                loginSuccess: undefined,
            };
        case actionTypes.USER_PROFILE_UPDATE_FAILURE:
            return {
                ...state,
                profileUpdateLoading: false,
            };
        case actionTypes.USER_PROFILE_UPDATE_REQUEST:
            return {
                ...state,
                profileUpdateLoading: true,
            };
        case actionTypes.USER_PROFILE_UPDATE_SUCCESS:
            return {
                ...state,
                profileUpdateLoading: false,
                userDetails: action.result.data.data.user_details,
            };
        case actionTypes.USER_REGISTRATION_REQUEST:
            return {
                ...state,
                registrationLoading: true,
            };
        case actionTypes.USER_REGISTRATION_FAILED:
            return {
                ...state,
                registrationLoading: false,
            };
        case actionTypes.USER_REGISTRATION_SUCCESS:
            return {
                ...state,
                token: get(action, successMappings.USER_LOGIN_GET_ACCESS_TOKEN),
                userDetails: get(action, successMappings.GET_USER_DETAILS),
                registrationLoading: false,
                roles: action.roles,
            };
        case actionTypes.VERIFY_PHONE_NUMBER_FAILURE:
            return {
                ...state,
                isSubmitting: false,
                hasError: true,
                message: get(
                    action.errors,
                    errorMappings.VERIFY_PHONE_NUMBER_ERROR,
                    errorMessages.VERIFY_PHONE_NUMBER_DEFAULT_ERROR
                ),
            };
        case actionTypes.VERIFY_PHONE_NUMBER_REQUEST:
            return {
                ...state,
                hasError: false,
                message: '',
                isSubmitting: true,
            };
        case actionTypes.VERIFY_PHONE_NUMBER_SUCCESS:
            return {
                ...state,
                hasError: false,
                message: '',
                userDetails: action.result.data.data.user_details,
                isSubmitting: false,
            };
        case actionTypes.CLEAR_FORGOT_USER_PASSWORD_MESSAGES: {
            return {
                ...state,
                message: '',
            };
        }
        case actionTypes.GET_USER_ROLES: {
            return {
                ...state,
                isUserRolesLoading: true,
                paginationUserRoles: {},
            };
        }
        case actionTypes.GET_USER_ROLES_SUCCESS: {
            return {
                ...state,
                isUserRolesLoading: false,
                userRoles: get(action.result, successMappings.GET_USER_ROLES_DETAILS),
                paginationUserRoles: getPaginationData(action),
            };
        }
        case actionTypes.GET_USER_ROLES_FAILURE: {
            return {
                ...state,
                isUserRolesLoading: false,
                paginationUserRoles: {},
            };
        }

        case actionTypes.GET_USER_ACCOUNT_ROLES: {
            return {
                ...state,
                isUserAccountRolesLoading: true,
                paginationUserRoles: {},
            };
        }
        case actionTypes.GET_USER_ACCOUNT_ROLES_SUCCESS: {
            return {
                ...state,
                isUserAccountRolesLoading: false,
                userAccountRoles: get(action.result, successMappings.GET_USER_ROLES_DETAILS),
                paginationUserRoles: getPaginationData(action),
            };
        }
        case actionTypes.GET_USER_ACCOUNT_ROLES_FAILURE: {
            return {
                ...state,
                isUserAccountRolesLoading: false,
                paginationUserRoles: {},
            };
        }
        case actionTypes.VERIFY_EMAIL_ADDRESS_REQUEST:
            return {
                ...state,
                isVerifyingEmail: true,
                hasError: false,
            };
        case actionTypes.VERIFY_EMAIL_ADDRESS_SUCCESS:
            return {
                ...state,
                isVerifyingEmail: false,
                emailVerifiedMessage: get(action.result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                userDetails: get(action, successMappings.GET_USER_DETAILS),
                hasError: false,
            };
        case actionTypes.VERIFY_EMAIL_ADDRESS_FAILURE:
            return {
                ...state,
                isVerifyingEmail: false,
                emailVerifiedMessage: '',
                hasError: true,
            };
        case actionTypes.ADD_USER_REQUEST:
            return {
                ...state,
                isDetailsUpdating: true,
            };
        case actionTypes.ADD_USER_SUCCESS:
            return {
                ...state,
                isDetailsUpdating: false,
            };
        case actionTypes.ADD_USER_FAILURE:
            return {
                ...state,
                isDetailsUpdating: false,
            };
        case actionTypes.USER_UPDATE_REQUEST:
            return {
                ...state,
                isDetailsUpdating: true,
            };
        case actionTypes.USER_UPDATE_SUCCESS:
            return {
                ...state,
                isDetailsUpdating: false,
            };
        case actionTypes.USER_UPDATE_FAILURE:
            return {
                ...state,
                isDetailsUpdating: false,
            };
        case actionTypes.GET_USERS_REQUEST:
            return {
                ...state,
                isLoading: true,
                paginationUsers: {},
            };
        case actionTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                paginationUsers: getPaginationData(action),
                users: get(action.result, successMappings.GET_USERS),
                editUserDetails: null,
            };
        case actionTypes.GET_USERS_FAILURE:
            return {
                ...state,
                isLoading: true,
                paginationUsers: {},
            };
        case actionTypes.GET_USER_DETAILS_REQUEST: {
            return {
                ...state,
                isDetailsLoading: true,
            };
        }
        case actionTypes.GET_USER_DETAILS_SUCCESS: {
            return {
                ...state,
                isDetailsLoading: false,
                editUserDetails: action.userDetails,
            };
        }
        case actionTypes.GET_USER_DETAILS_FAILURE: {
            return {
                ...state,
                isDetailsLoading: false,
            };
        }
        case actionTypes.DELETE_USER_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case actionTypes.DELETE_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                paginationUsers: getPaginationData(action),
                users: get(action.result, successMappings.GET_USERS),
            };
        }
        case actionTypes.DELETE_USER_FAILURE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case actionTypes.REQUEST_QUOTE_REQUEST:
            return {
                ...state,
                isRequestingQuote: true,
            };
        case actionTypes.REQUEST_QUOTE_FAILURE:
        case actionTypes.REQUEST_QUOTE_SUCCESS:
            return {
                ...state,
                isRequestingQuote: false,
            };
        case actionTypes.GET_FAQ_REQUEST:
            return {
                ...state,
                isFAQLoading: true,
            };
        case actionTypes.GET_FAQ_SUCCESS:
            return {
                ...state,
                isFAQLoading: false,
                FAQs: action.result.data,
            };
        case actionTypes.GET_FAQ_FAILURE:
            return {
                ...state,
                isFAQLoading: false,
            };
        case actionTypes.RESET_ADD_USER_ERRORS: {
            return { ...state, userNotExistInAccount: false };
        }
        case actionTypes.ACTIVATE_ACCOUNT_REQUEST:
            return {
                ...state,
                isActivatingAccount: true,
            };
        case actionTypes.ACTIVATE_ACCOUNT_FAILURE:
        case actionTypes.ACTIVATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isActivatingAccount: false,
            };
        case actionTypes.RESEND_ACTIVATION_EMAIL_FAILURE:
        case actionTypes.RESEND_ACTIVATION_EMAIL_SUCCESS:
            return {
                ...state,
                isResendingActivationEmail: false,
            };
        case actionTypes.RESEND_ACTIVATION_EMAIL_REQUEST:
            return {
                ...state,
                isResendingActivationEmail: true,
            };

        case actionTypes.RESET_TWO_FACTOR_AUTHENTICATION_FAILURE:
        case actionTypes.RESET_TWO_FACTOR_AUTHENTICATION_SUCCESS:
            return {
                ...state,
                isResettingTwoFactorAuthentication: false,
            };
        case actionTypes.RESET_TWO_FACTOR_AUTHENTICATION_REQUEST:
            return {
                ...state,
                isResettingTwoFactorAuthentication: true,
            };
        case actionTypes.USER_RESET_PASSWORD_FAILURE:
        case actionTypes.USER_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isResettingPassword: false,
            };
        case actionTypes.USER_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                isResettingPassword: true,
            };
        case actionTypes.USER_VALIDATE_RESET_PASSWORD_FAILURE:
        case actionTypes.USER_VALIDATE_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isValidatingResetPassword: false,
            };
        case actionTypes.USER_VALIDATE_RESET_PASSWORD_REQUEST:
            return {
                ...state,
                isValidatingResetPassword: true,
            };
        default:
            return state;
    }
}

import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

import Loader from 'components/Loader';
/**
 * Status to be displayed in the loader menu
 */
export const LOADER_MODAL_STATUS = {
    DELETING: 'deleting',
    ACTIVATING: 'activating',
    DEACTIVATING: 'deactivating',
};

const MODAL_STATUS_MESSAGE_MAP = {
    [LOADER_MODAL_STATUS.ACTIVATING]: 'Enabling payment gateway...',
    [LOADER_MODAL_STATUS.DEACTIVATING]: 'Disabling payment gateway...',
    [LOADER_MODAL_STATUS.DELETING]: 'Deleting payment gateway...',
};

export default function LoaderModal({ status }) {
    return (
        <Modal footer={null} visible={Boolean(status)} closable={false}>
            <div className="flex flex-col">
                <Loader />
                <div>{MODAL_STATUS_MESSAGE_MAP[status]}</div>
            </div>
        </Modal>
    );
}

LoaderModal.propTypes = {
    status: PropTypes.oneOf([...Object.values(LOADER_MODAL_STATUS), null]),
};

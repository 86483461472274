/**
 * Logout Component
 * Handles the user logout
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import { React, Component, connect } from '../../../includes/exports/react';
import Button from '../Button';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import * as userActions from '../../../includes/redux/actions/shared/user';
import { clearLSAndRedirectToAuthPortalLogoutPage } from 'includes/utils';

/**
 * Logout Component
 * Handles the user logout
 */
class Logout extends Component {
    /**
     * Proptypes
     */
    static propTypes = {
        logoutUser: PropTypes.func,
        t: PropTypes.func,
    };

    /**
     * Perform the user logout
     */
    logoutUser = () => {
        clearLSAndRedirectToAuthPortalLogoutPage();
    };

    /**
     * Renders the logout view
     *
     * @returns    {string}     Logout link
     */
    render() {
        return (
            <Button onClick={this.logoutUser} big className="sign-in header-right-content">
                {this.props.t('sharedMessages.navigation.menuLogout')}
            </Button>
        );
    }
}

//connect the component to the store
export default connect(
    state => ({
        ...state.user,
    }),
    {
        ...userActions,
    }
)(withTranslation()(Logout));

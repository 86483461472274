/**
 * Payment Gateways Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { get, isEmpty, isFunction } from 'lodash';
import { stopSubmit } from 'redux-form';
import PaymentGatewaysApi from '../services/shared/paymentGateways';
import * as successMappings from '../constants/mappings/success';
import {
    ADD_CARD_ERROR_MESSAGE,
    DELETE_CARD_ERROR_MESSAGE,
    PAYMENT_SERVICE_FORM,
    UPDATE_CARD_ERROR_MESSAGE,
} from '../constants';
import { showNotificationModal } from './appNotifications';
import { CARD_TOKEN } from '../constants/keys/request';
import createAppSlice from './base';
import { showAppNotificationMessage } from 'includes/redux/actions/shared/appNotification';
import { getApiErrors, showApiErrors, showApiSuccess } from 'includes/utils/api';

// set the initial state
const initialState = {
    defaultPaymentGatewayIdForRecurringPayments: '',
    loading: false,
    loadingAddCard: false,
    loadingCards: false,
    loadingDeleteCard: false,
    loadingDeletePaymentGateway: false,
    loadingPaymentGatewayDetails: false,
    loadingPaymentGatewayDetailsUpdate: false,
    loadingOrganisationPaymentGateways: false,
    loadingPaymentGateways: false,
    loadingDefaultPaymentGatewayForRecurringPayments: false,
    loadingUpdateCard: false,
    organisationPaymentGateways: [],
    organisationPaymentGatewayDetails: null,
    paymentGatewayDetails: {},
    paymentGateways: [],
    savedCards: [],
    secretToken: '',
    authorizationUrl: null,
    loadingPaymentServiceAuthorizationUrl: false,
    isConnecting: false,
    isUpdating: false,
    isAdding: false,
    isDeleting: false,
    isLoadingProviders: false,
    isUpdatingPaymentMethods: false,
    isFetchingPaymentMethodTypes: false,
    isInternalPaymentGatewaysLoading: false,
    paymentServiceGatewayProviders: [],
    paymentMethods: [],
    paymentMethodTypes: [],
    internalPaymentGateways: [],
};

// define the slice
const PaymentGatewaysSlice = createAppSlice('paymentGatewaysSlice', initialState, {
    endAddCardLoading(state) {
        state.loadingAddCard = false;
    },
    endCardsLoading(state) {
        state.loadingCards = false;
    },
    endDeleteCardLoading(state) {
        state.loadingDeleteCard = false;
    },
    endDeletePaymentGatewayLoading(state) {
        state.loadingDeletePaymentGateway = false;
    },
    endOrganisationPaymentGatewaysLoading(state) {
        state.loadingOrganisationPaymentGateways = false;
    },
    endPaymentGatewayDetailsLoading(state) {
        state.loadingPaymentGatewayDetails = false;
    },
    endPaymentGatewayDetailsUpdateLoading(state) {
        state.loadingPaymentGatewayDetailsUpdate = false;
    },
    endPaymentGatewaysLoading(state) {
        state.loadingPaymentGateways = false;
    },
    endDefaultPaymentGatewayForRecurringPaymentsLoading(state) {
        state.loadingDefaultPaymentGatewayForRecurringPayments = false;
    },
    endUpdateCardLoading(state) {
        state.loadingUpdateCard = false;
    },
    resetSecretToken(state) {
        state.secretToken = '';
    },
    setAddCardLoading(state) {
        state.loadingAddCard = true;
    },
    setCardsLoading(state) {
        state.loadingCards = true;
    },
    setDefaultPaymentGatewayIdForRecurringPayments(state, action) {
        state.defaultPaymentGatewayIdForRecurringPayments = action.payload;
    },
    setDefaultPaymentGatewayForRecurringPaymentsLoading(state) {
        state.loadingDefaultPaymentGatewayForRecurringPayments = true;
    },
    setDeleteCardLoading(state) {
        state.loadingDeleteCard = true;
    },
    setDeletePaymentGatewayLoading(state) {
        state.loadingDeletePaymentGateway = true;
    },
    setPaymentGatewayDetails(state, action) {
        state.paymentGatewayDetails = action.payload;
    },
    setPaymentGatewayDetailsLoading(state) {
        state.loadingPaymentGatewayDetails = true;
    },
    setPaymentGatewayDetailsUpdateLoading(state) {
        state.loadingPaymentGatewayDetailsUpdate = true;
    },
    setPaymentGateways(state, action) {
        state.paymentGateways = action.payload;
    },
    setOrganisationPaymentGateways(state, action) {
        state.organisationPaymentGateways = action.payload;
    },
    setPaymentGatewaysLoading(state) {
        state.loadingPaymentGateways = true;
    },
    setOrganisationPaymentGatewaysLoading(state) {
        state.loadingOrganisationPaymentGateways = true;
    },
    setSavedCards(state, action) {
        state.savedCards = action.payload;
    },
    setSecretToken(state, action) {
        state.secretToken = action.payload;
    },
    setUpdateCardLoading(state) {
        state.loadingUpdateCard = true;
    },
    setOrganisationPaymentGatewayDetailsLoading(state, action) {
        state.loadingOrganisationPaymentGatewayDetails = action.payload;
    },
    setOrganisationPaymentGatewayDetails(state, action) {
        state.organisationPaymentGatewayDetails = action.payload;
    },
});

const { setData } = PaymentGatewaysSlice.actions;

/**
 * Get the payment gateways
 *
 * @param {string} organisationId Organisation Id
 * @param {string} accountId Account Id
 */
export const getPaymentGateways = (organisationId, accountId, params) => async dispatch => {
    try {
        dispatch(PaymentGatewaysSlice.actions.setPaymentGatewaysLoading());
        const result = await PaymentGatewaysApi.getPaymentGateways(organisationId, accountId, params);
        dispatch(PaymentGatewaysSlice.actions.setPaymentGateways(get(result, successMappings.GET_PAYMENT_GATEWAYS)));
        dispatch(
            PaymentGatewaysSlice.actions.setDefaultPaymentGatewayIdForRecurringPayments(
                get(result, successMappings.GET_PAYMENT_GATEWAY_DEFAULT_FOR_RECURRING_PAYMENTS)
            )
        );
    } finally {
        dispatch(PaymentGatewaysSlice.actions.endPaymentGatewaysLoading());
    }
};

/**
 * Get organisation payment gateways
 *
 * @param {string} organisationId Organisation Id
 * @param {string} accountId Account Id
 */
export const getOrganisationPaymentGateways = (organisationId, accountId, params) => async dispatch => {
    try {
        dispatch(PaymentGatewaysSlice.actions.setOrganisationPaymentGatewaysLoading());
        const result = await PaymentGatewaysApi.getPaymentGateways(organisationId, accountId, params);
        dispatch(
            PaymentGatewaysSlice.actions.setOrganisationPaymentGateways(
                get(result, successMappings.GET_PAYMENT_GATEWAYS)
            )
        );
    } finally {
        dispatch(PaymentGatewaysSlice.actions.endOrganisationPaymentGatewaysLoading());
    }
};

/**
 * Get the user saved cards
 *
 * @param {string} paymentGatewayId Payment Gateway ID
 * @param {Function} callback Callback function
 */
export const getSavedCards = (paymentGatewayId, callback) => async dispatch => {
    let hasUserSavedCards = false;
    try {
        dispatch(PaymentGatewaysSlice.actions.setCardsLoading());
        const result = await PaymentGatewaysApi.getSavedCards(paymentGatewayId);
        const savedCards = get(result, successMappings.GET_SAVED_CARDS);
        hasUserSavedCards = !isEmpty(savedCards);
        dispatch(PaymentGatewaysSlice.actions.setSavedCards(savedCards));
    } finally {
        dispatch(PaymentGatewaysSlice.actions.endCardsLoading());
        if (callback && isFunction(callback)) {
            callback(hasUserSavedCards);
        }
    }
};

/**
 * Add a card
 *
 * @param {string} paymentGatewayId Payment Gateway ID
 * @param {string} cardToken Card Token
 * @param {Function} callback Callback function
 */
export const addCard = (paymentGatewayId, cardToken, callback) => async dispatch => {
    try {
        dispatch(PaymentGatewaysSlice.actions.setAddCardLoading());
        const result = await PaymentGatewaysApi.addCard(paymentGatewayId, {
            [CARD_TOKEN]: cardToken,
        });
        dispatch(PaymentGatewaysSlice.actions.setSavedCards(get(result, successMappings.GET_SAVED_CARDS)));
        if (callback && isFunction(callback)) {
            callback();
        }
        dispatch(showNotificationModal(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, ADD_CARD_ERROR_MESSAGE));
    } finally {
        dispatch(PaymentGatewaysSlice.actions.endAddCardLoading());
    }
};

/**
 * Delete a card
 *
 * @param {string} paymentGatewayId Payment Gateway Id
 * @param {string} cardId Card Id
 */
export const deleteCard = (paymentGatewayId, cardId) => async dispatch => {
    try {
        dispatch(PaymentGatewaysSlice.actions.setDeleteCardLoading());
        const result = await PaymentGatewaysApi.deleteCard(paymentGatewayId, cardId);
        dispatch(PaymentGatewaysSlice.actions.setSavedCards(get(result, successMappings.GET_SAVED_CARDS)));
        dispatch(showNotificationModal(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, DELETE_CARD_ERROR_MESSAGE));
    } finally {
        dispatch(PaymentGatewaysSlice.actions.endDeleteCardLoading());
    }
};

/**
 * Set Default Payment Gateway for recurring payments
 *
 * @param {string} paymentGatewayId Payment Gateway Id
 * @param {object} payload Payload
 */
export const setDefaultPaymentGatewayForRecurringPayments = (paymentGatewayId, payload) => async dispatch => {
    try {
        dispatch(PaymentGatewaysSlice.actions.setDefaultPaymentGatewayForRecurringPaymentsLoading());
        const result = await PaymentGatewaysApi.saveDefaultPaymentGatewayForRecurringPayments(
            paymentGatewayId,
            payload
        );
        dispatch(
            PaymentGatewaysSlice.actions.setDefaultPaymentGatewayIdForRecurringPayments(
                get(result, successMappings.GET_PAYMENT_GATEWAY_DEFAULT_FOR_RECURRING_PAYMENTS)
            )
        );
        dispatch(showNotificationModal(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, UPDATE_CARD_ERROR_MESSAGE));
    } finally {
        dispatch(PaymentGatewaysSlice.actions.endDefaultPaymentGatewayForRecurringPaymentsLoading());
    }
};

/**
 * Update a card
 *
 * @param {string} paymentGatewayId Payment Gateway Id
 * @param {string} cardId Card Id
 * @param {object} payload Payload
 * @param {Function} callback Callback function
 */
export const updateCard = (paymentGatewayId, cardId, payload, callback) => async dispatch => {
    try {
        dispatch(PaymentGatewaysSlice.actions.setUpdateCardLoading());
        const result = await PaymentGatewaysApi.updateCard(paymentGatewayId, cardId, payload);
        dispatch(PaymentGatewaysSlice.actions.setSavedCards(get(result, successMappings.GET_SAVED_CARDS)));
        if (callback && isFunction(callback)) {
            callback('');
        }
        dispatch(showNotificationModal(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, UPDATE_CARD_ERROR_MESSAGE));
    } finally {
        dispatch(PaymentGatewaysSlice.actions.endUpdateCardLoading());
    }
};

/**
 * Get Payment Gateway details
 *
 * @param {string} paymentGatewayId Payment Gateway Id
 * @param {string} accountId AccountOd Id
 * @param {string} organisationId Organisation id. Default ''
 */
export const getPaymentGatewayDetails = (paymentGatewayId, accountId, organisationId = '') => async dispatch => {
    try {
        dispatch(PaymentGatewaysSlice.actions.setPaymentGatewayDetailsLoading());
        const result = await PaymentGatewaysApi.getPaymentGatewayDetails(paymentGatewayId, accountId, organisationId);
        dispatch(
            PaymentGatewaysSlice.actions.setPaymentGatewayDetails(
                get(result, successMappings.GET_PAYMENT_GATEWAY_DETAILS)
            )
        );
        // dispatch(showNotificationModal(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, UPDATE_CARD_ERROR_MESSAGE));
    } finally {
        dispatch(PaymentGatewaysSlice.actions.endPaymentGatewayDetailsLoading());
    }
};

/**
 * Get Organisation Payment Gateway details
 *
 * @param {string} paymentGatewayId Payment Gateway Id
 * @param {string} organisationId Organisation id.
 */
export const getOrganisationPaymentGatewayDetails = (paymentGatewayId, organisationId = '') => async dispatch => {
    try {
        dispatch(PaymentGatewaysSlice.actions.setOrganisationPaymentGatewayDetailsLoading(true));
        const result = await PaymentGatewaysApi.getOrganisationPaymentGatewayDetails(paymentGatewayId, organisationId);
        dispatch(
            PaymentGatewaysSlice.actions.setOrganisationPaymentGatewayDetails(
                get(result, successMappings.GET_PAYMENT_GATEWAY_DETAILS)
            )
        );
        // dispatch(showNotificationModal(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, UPDATE_CARD_ERROR_MESSAGE));
    } finally {
        dispatch(PaymentGatewaysSlice.actions.setOrganisationPaymentGatewayDetailsLoading(false));
    }
};

/**
 * Update Payment Gateway details
 *
 * @param {string} paymentGatewayId Payment Gateway Id
 * @param {string} accountId Account Id
 * @param {string} organisationId Organisation Id
 * @param {object} payload Payment Gateway details object
 * @param {Function} callback Callback function
 */
export const updatePaymentGatewayDetails = (
    paymentGatewayId,
    accountId,
    organisationId,
    payload,
    callback
) => async dispatch => {
    try {
        dispatch(PaymentGatewaysSlice.actions.setPaymentGatewayDetailsUpdateLoading());
        const result = await PaymentGatewaysApi.updatePaymentGatewayDetails(
            paymentGatewayId,
            accountId,
            organisationId,
            payload
        );
        dispatch(PaymentGatewaysSlice.actions.setPaymentGateways(get(result, successMappings.GET_PAYMENT_GATEWAYS)));
        if (callback && isFunction(callback)) {
            callback();
        }

        dispatch(showNotificationModal(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, UPDATE_CARD_ERROR_MESSAGE));
    } finally {
        dispatch(PaymentGatewaysSlice.actions.endPaymentGatewayDetailsUpdateLoading());
    }
};

/**
 * Delete Payment Gateway
 *
 * @param {string} paymentGatewayId Payment Gateway Id
 * @param {object} payload Payment Gateway details onject
 */
export const deletePaymentGateway = paymentGatewayId => async dispatch => {
    try {
        dispatch(PaymentGatewaysSlice.actions.setDeletePaymentGatewayLoading());
        const result = await PaymentGatewaysApi.deletePaymentGateway(paymentGatewayId);
        dispatch(PaymentGatewaysSlice.actions.setPaymentGateways(get(result, successMappings.GET_PAYMENT_GATEWAYS)));
        dispatch(showNotificationModal(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, UPDATE_CARD_ERROR_MESSAGE));
    } finally {
        dispatch(PaymentGatewaysSlice.actions.endDeletePaymentGatewayLoading());
    }
};

/**
 * Get Payment Service Authorization URL
 */
export const getPaymentServiceAuthorizationUrl = () => async dispatch => {
    try {
        dispatch(setData('loadingPaymentServiceAuthorizationUrl', true));
        const result = await PaymentGatewaysApi.getPaymentServiceAuthorizationUrl();
        dispatch(setData('authorizationUrl', get(result, successMappings.GET_PAYMENT_SERVICE_AUTHORIZATION_URL)));
    } finally {
        dispatch(setData('loadingPaymentServiceAuthorizationUrl'));
    }
};

/**
 * Connect Payment Service
 *
 * @param {string} code code received from GoCardless
 */
export const connectPaymentService = code => async dispatch => {
    try {
        dispatch(setData('isConnecting', true));
        const result = await PaymentGatewaysApi.connectPaymentService(code);
        dispatch(setData('authorizationUrl', get(result, successMappings.GET_PAYMENT_SERVICE_AUTHORIZATION_URL)));
    } finally {
        dispatch(setData('isConnecting'));
    }
};

/**
 * Update Payment Service
 *
 * @param {string} paymentServiceId payment service ID
 * @param {string} organisationId organisation ID
 * @param {string} details details
 * @callback callback callback after API success
 * @param {object} config config
 * @param {boolean} config.disableApiSuccessNotification disable api success notification or not
 * @param {Function} config.setLoaderFalse set loader false function
 */
export const updatePaymentService = (
    paymentServiceId,
    organisationId,
    details,
    callback = () => {},
    config = { disableApiSuccessNotification: false, setLoaderFalse: () => {} }
) => async dispatch => {
    try {
        dispatch(setData('isUpdating', true));
        const result = await PaymentGatewaysApi.updatePaymentGateway(paymentServiceId, organisationId, details);
        dispatch(
            PaymentGatewaysSlice.actions.setOrganisationPaymentGateways(
                get(result, successMappings.GET_PAYMENT_GATEWAYS)
            )
        );
        callback();
        if (!config.disableApiSuccessNotification)
            dispatch(
                showAppNotificationMessage('success', get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE))
            );
    } catch (errors) {
        dispatch(stopSubmit(PAYMENT_SERVICE_FORM, getApiErrors(errors)));
        dispatch(showApiErrors(errors, 'PAYMENT_SERVICE_FORM', 'PAYMENT_GATEWAY_CRUD', 'PAYMENT_GATEWAY_CRUD'));
        config.setLoaderFalse();
    } finally {
        dispatch(setData('isUpdating'));
    }
};

/**
 * Add Payment Service
 *
 * @param {string} organisationId organisation ID
 * @param {string} details details
 * @callback callback callback to be invoked after API success
 */
export const addPaymentService = (organisationId, details, callback) => async dispatch => {
    try {
        dispatch(setData('isAdding', true));
        const result = await PaymentGatewaysApi.addPaymentGateway(details, organisationId);
        dispatch(
            PaymentGatewaysSlice.actions.setOrganisationPaymentGateways(
                get(result, successMappings.GET_PAYMENT_GATEWAYS)
            )
        );
        callback();
        dispatch(showAppNotificationMessage('success', get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE)));
    } catch (errors) {
        dispatch(stopSubmit(PAYMENT_SERVICE_FORM, getApiErrors(errors)));
        dispatch(showApiErrors(errors, 'PAYMENT_SERVICE_FORM', 'PAYMENT_GATEWAY_CRUD', 'PAYMENT_GATEWAY_CRUD'));
    } finally {
        dispatch(setData('isAdding'));
    }
};

/**
 * Delete Payment Service
 *
 * @param {string} organisationId organisation ID
 * @param {string} paymentGatewayId payment gateway ID
 * @callback callback callback to be invoked after API success
 */
export const deletePaymentService = (organisationId, paymentGatewayId, callback = () => {}) => async dispatch => {
    try {
        dispatch(setData('isDeleting', true));
        const result = await PaymentGatewaysApi.deletePaymentService(organisationId, paymentGatewayId);
        dispatch(
            PaymentGatewaysSlice.actions.setOrganisationPaymentGateways(
                get(result, successMappings.GET_PAYMENT_GATEWAYS)
            )
        );
        callback();
        dispatch(showAppNotificationMessage('success', get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE)));
    } catch (errors) {
        dispatch(showApiErrors(errors, undefined, 'PAYMENT_GATEWAY_CRUD', 'PAYMENT_GATEWAY_CRUD', ['payment_gateway']));
    } finally {
        dispatch(setData('isDeleting', false));
    }
};

/**
 * Get payment service providers
 *
 * @param {string} paymentServiceId Payment service ID
 */
export const getPaymentServiceGatewayProviders = paymentServiceId => async dispatch => {
    try {
        dispatch(setData('isLoadingProviders', true));
        const result = await PaymentGatewaysApi.getPaymentServiceGatewayProviders(paymentServiceId);
        dispatch(
            setData(
                'paymentServiceGatewayProviders',
                get(result, successMappings.GET_PAYMENT_SERVICE_GATEWAY_PROVIDERS)
            )
        );
    } finally {
        dispatch(setData('isLoadingProviders'));
    }
};

/**
 * Get payment methods
 *
 * @param {string | null} paymentServiceId Payment service ID
 * @param {object} params params
 */
export const getPaymentMethods = (paymentServiceId = null, params = {}) => async dispatch => {
    try {
        dispatch(setData('isLoadingPaymentMethods', true));
        const result = await PaymentGatewaysApi.getPaymentMethods(paymentServiceId, params);
        dispatch(setData('paymentMethods', get(result, successMappings.GET_PAYMENT_METHODS)));
    } finally {
        dispatch(setData('isLoadingPaymentMethods'));
    }
};

/**
 * Get payment method details
 *
 * @param {string} paymentMethodId Payment method ID
 */
export const getPaymentMethodDetails = paymentMethodId => async dispatch => {
    try {
        dispatch(setData('isLoadingPaymentMethodDetails', true));
        const result = await PaymentGatewaysApi.getPaymentMethodDetails(paymentMethodId);
        dispatch(setData('paymentMethodDetails', get(result, successMappings.GET_PAYMENT_METHOD_DETAILS)));
    } finally {
        dispatch(setData('isLoadingPaymentMethodDetails'));
    }
};

/**
 * Update payment method
 *
 * @param {string} paymentMethodId Payment method ID
 * @param {object} details Payment method details
 * @callback onSuccessCallback success callback
 * @callback onErrorCallback error callback
 */
export const updatePaymentMethod = (
    paymentMethodId,
    details,
    onSuccessCallback = () => {},
    onErrorCallback = () => {}
) => async dispatch => {
    try {
        dispatch(setData('isUpdatingPaymentMethods', true));
        const result = await PaymentGatewaysApi.updatePaymentMethod(paymentMethodId, details);
        // fetch payment methods again to reflect the changes made
        dispatch(getPaymentMethods());
        dispatch(showApiSuccess(result));
        onSuccessCallback();
    } catch (errors) {
        dispatch(
            showApiErrors(
                errors,
                'PAYMENT_METHOD_FORM',
                'PAYMENT_METHOD',
                null,
                ['payment_gateway_id', 'is_default'],
                undefined,
                undefined,
                ['is_default']
            )
        );
        onErrorCallback();
    } finally {
        dispatch(setData('isUpdatingPaymentMethods', false));
    }
};

/**
 * Set payment method as default
 *
 * Just a wrapper of `updatePaymentMethod` function with setting is
 *
 * @param {string} paymentMethodId Payment method ID
 * @param {object} details Payment method details
 * @callback onSuccessCallback success callback
 * @callback onErrorCallback error callback
 */
export const setPaymentMethodAsDefault = (
    paymentMethodId,
    details,
    onSuccessCallback = () => {},
    onErrorCallback = () => {}
) => async (dispatch, getState) => {
    try {
        dispatch(setData('isUpdatingPaymentMethods', true));
        const result = await PaymentGatewaysApi.updatePaymentMethod(paymentMethodId, details);
        const updatedPaymentMethods = [...getState().paymentGateways.paymentMethods];
        const previousDefaultPaymentMethodIndex = updatedPaymentMethods.findIndex(method => method.is_default);
        const currentDefaultPaymentMethodIndex = updatedPaymentMethods.findIndex(
            method => method.id === paymentMethodId
        );

        if (previousDefaultPaymentMethodIndex >= 0)
            updatedPaymentMethods[previousDefaultPaymentMethodIndex] = {
                ...updatedPaymentMethods[previousDefaultPaymentMethodIndex],
                is_default: false,
            };

        updatedPaymentMethods[currentDefaultPaymentMethodIndex] = get(
            result,
            successMappings.GET_PAYMENT_METHOD_DETAILS
        );

        dispatch(setData('paymentMethods', updatedPaymentMethods));
        onSuccessCallback();
    } catch (errors) {
        dispatch(
            showApiErrors(
                errors,
                'PAYMENT_METHOD_FORM',
                'PAYMENT_METHOD',
                null,
                ['payment_gateway_id', 'is_default'],
                undefined,
                undefined,
                ['is_default']
            )
        );
        onErrorCallback();
    } finally {
        dispatch(setData('isUpdatingPaymentMethods', false));
    }
};

/**
 * Delete payment methods
 *
 * @param {string} paymentMethodId Payment method ID
 */
export const deletePaymentMethod = paymentMethodId => async dispatch => {
    try {
        dispatch(setData('isDeletingPaymentMethods', true));
        const result = await PaymentGatewaysApi.deletePaymentMethod(paymentMethodId);
        dispatch(setData('paymentMethods', get(result, successMappings.GET_PAYMENT_METHODS)));
    } catch (errors) {
        dispatch(showApiErrors(errors, null, 'PAYMENT_METHOD', null));
    } finally {
        dispatch(setData('isDeletingPaymentMethods'));
    }
};

/**
 * Get payment method types
 */
export const getPaymentMethodTypes = () => async dispatch => {
    try {
        dispatch(setData('isFetchingPaymentMethodTypes', true));
        const result = await PaymentGatewaysApi.getPaymentMethodTypes();
        dispatch(setData('paymentMethodTypes', get(result, successMappings.GET_PAYMENT_METHOD_TYPES)));
    } finally {
        dispatch(setData('isFetchingPaymentMethodTypes', false));
    }
};

export const setOrganisationPaymentGateways = paymentGateways => dispatch => {
    dispatch(PaymentGatewaysSlice.actions.setOrganisationPaymentGateways(paymentGateways));
};

// export the secret token action
export const { resetSecretToken, setSecretToken } = PaymentGatewaysSlice.actions;

// export the reducer
export default PaymentGatewaysSlice.reducer;

/**
 * Coupon Accounts component
 * Handles the Coupon accounts view and functions related to Coupon accounts
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

// import required modules
import {
    React,
    connect,
    PropTypes,
    useCallback,
    useMemo,
    useState,
    withTranslation,
} from '../../../includes/exports/react';
import * as errorMessages from '../../../includes/constants/messages/errors';
import Loader from '../../shared/Loader';
import SearchBlock from '../../shared/SearchBlock';
import Table from '../../shared/ScrollableTable';
import { COUPON_ACCOUNT_TABLE_COLUMNS } from '../../../includes/constants/columns';
import { getCouponAccounts, removeCouponFromAccount } from '../../../includes/redux/actions/shared/account';
import { getDefaultImageClass, getImageFromData } from '../../../includes/utils';
import { Modal } from 'antd';

/**
 * Coupon accounts component
 */
const CouponAccounts = props => {
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const accountLogo45Key = 'ACCOUNT_LOGO_45X45';
    const accountLogoOriginalKey = 'ACCOUNT_LOGO_ORIGINAL';
    const { couponAccounts } = props;
    const { couponId } = props.match.params;

    /**
     * Format the coupon accounts table data
     */
    const formatCouponAccountsTableData = useCallback(
        () =>
            couponAccounts
                ? couponAccounts.map(couponAccount => ({
                      ...couponAccount,
                      name: (
                          <>
                              <div className="table-name-align">
                                  <img
                                      className={
                                          getDefaultImageClass(
                                              couponAccount.account,
                                              accountLogo45Key,
                                              accountLogoOriginalKey,
                                              '45x45'
                                          ) + ' logo logo-45 '
                                      }
                                      src={getImageFromData(
                                          couponAccount.account,
                                          accountLogo45Key,
                                          accountLogoOriginalKey
                                      )}
                                      alt={couponAccount.account.name}
                                  />
                                  <div className="wrapped-content-text">
                                      <span className="wrapped-content-text crud-wrapper">
                                          {couponAccount.account.name}
                                      </span>
                                      {!couponAccount.redeemed_date && (
                                          <span className="crud-wrapper">
                                              <button
                                                  className="crud-link"
                                                  onClick={() => removeCoupon(couponAccount.id)}
                                                  title={props.t(
                                                      'adminMessages.couponAccounts.coupon.removeCouponTooltip'
                                                  )}
                                              >
                                                  {props.t('adminMessages.couponAccounts.coupon.removeCoupon')}
                                              </button>
                                          </span>
                                      )}
                                  </div>
                              </div>
                          </>
                      ),
                  }))
                : [],
        [couponAccounts] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Memoized coupon accounts data
     */
    const memoizedCouponAccountsData = useMemo(() => formatCouponAccountsTableData(), [formatCouponAccountsTableData]);

    /**
     * Remove the coupon from the account
     *
     * @param   {string}    couponAccountId      Coupon Account Id
     */
    const removeCoupon = couponAccountId => {
        props.removeCouponFromAccount({ coupon_account_id: couponAccountId, coupon_id: couponId });
    };

    return (
        <div className="home-content-wrapper accounts-wrapper">
            <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                <section>
                    <ul className="selector-field">
                        <li>
                            <div className="right-align">
                                <SearchBlock
                                    dataValues={[couponId]}
                                    getDataMethod={props.getCouponAccounts}
                                    isSearching={isSearching}
                                    placeholder={props.t('adminMessages.accounts.search.placeholder')}
                                    setIsSearching={setIsSearching}
                                    setSearchParam={setSearchParam}
                                />
                            </div>
                        </li>
                    </ul>
                </section>
                <Table
                    columns={COUPON_ACCOUNT_TABLE_COLUMNS}
                    dataSource={memoizedCouponAccountsData}
                    dataValues={[couponId]}
                    getDataMethod={props.getCouponAccounts}
                    isSearching={isSearching}
                    loading={props.isCouponAccountsLoading}
                    localeMessage={errorMessages.TABLE_NO_ACCOUNTS_FOUND_MESSAGE}
                    paginationData={props.paginationCouponAccounts}
                    rowClassName="pointer"
                    rowKey="id"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    size="middle"
                    {...props}
                />
                <Modal
                    className="my_profile_modal organisation-reauthorization-modal"
                    closable={false}
                    maskClosable={false}
                    visible={props.isRemovingCouponFromAccount}
                    footer={null}
                >
                    <Loader tip={props.t('adminMessages.couponAccounts.coupon.loader.removeCoupon')} />
                </Modal>
            </div>
        </div>
    );
};
/**
 *Prop types
 */
CouponAccounts.propTypes = {
    couponAccounts: PropTypes.array,
    getCouponAccounts: PropTypes.func,
    getCoupons: PropTypes.func,
    isCouponAccountsLoading: PropTypes.bool,
    isRemovingCouponFromAccount: PropTypes.bool,
    match: PropTypes.object,
    paginationCouponAccounts: PropTypes.object,
    removeCouponFromAccount: PropTypes.func,
    t: PropTypes.func,
};
export default connect(
    state => ({
        ...state.account,
        couponAccounts: state.account.couponAccounts,
        isCouponAccountsLoading: state.account.isCouponAccountsLoading,
        isRemovingCouponFromAccount: state.account.isRemovingCouponFromAccount,
        paginationCouponAccounts: state.account.paginationCouponAccounts,
    }),
    {
        getCouponAccounts,
        removeCouponFromAccount,
    }
)(withTranslation()(CouponAccounts));

/**
 * Edit Contact Form
 * Renders the edit user form
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

// import required modules
import { React, PropTypes } from '../../../includes/exports/react';
import * as formValidations from '../../../includes/utils/form';
import * as requestKeys from '../../../includes/constants/keys/request';
import Button from '../Button';
import FormField from '../FormField';
import { Field } from 'redux-form';
import { Row, Col, Icon } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { get } from 'lodash';

const AddEditContactPersons = props => {
    /**
     * Locale label
     *
     * @param {string}  field Field for which locale is to be retrieved
     *
     * @returns {string}  Field locale
     */
    const localeLabel = field => props.t(`customerMessages.editContact.form.input.label.${field}`);

    return (
        <>
            {props.fields.map((contact_person, index) => (
                <Row gutter={24} key={index}>
                    <Col span={6}>
                        <Field
                            type="text"
                            key={index}
                            name={`${contact_person}.${requestKeys.FIRST_NAME}`}
                            label={localeLabel(requestKeys.FIRST_NAME)}
                            className="form-group"
                            component={FormField}
                        />
                    </Col>
                    <Col span={6}>
                        <Field
                            type="text"
                            name={`${contact_person}.${requestKeys.LAST_NAME}`}
                            label={localeLabel(requestKeys.LAST_NAME)}
                            className="form-group"
                            component={FormField}
                        />
                    </Col>
                    <Col span={6}>
                        <Field
                            type="email"
                            name={`${contact_person}.${requestKeys.EMAIL}`}
                            label={localeLabel(requestKeys.EMAIL)}
                            hasFeedback
                            className="form-group"
                            component={FormField}
                            validate={[formValidations.email]}
                        />
                    </Col>
                    <Col span={4}>
                        <Field
                            type="switch"
                            name={`${contact_person}.${requestKeys.INCLUDE_IN_EMAILS}`}
                            label={localeLabel(requestKeys.INCLUDE_IN_EMAILS)}
                            className="form-group"
                            component={FormField}
                        />
                    </Col>
                    <Col span={2}>
                        <Icon
                            className="remove-contact-person"
                            type="close"
                            onClick={() => props.fields.remove(index)}
                            title="Remove Contact Person"
                        />
                    </Col>
                </Row>
            ))}
            <Row gutter={24} key="add_contact_person_button">
                <Col span={12}>
                    <Button
                        big
                        className="small-add-button"
                        disabled={props.fields.length >= 5}
                        onClick={() => props.fields.length < 5 && props.fields.push({})}
                    >
                        Add Contact Person
                    </Button>
                </Col>
            </Row>
        </>
    );
};

/**
 * Proptypes
 */
AddEditContactPersons.propTypes = {
    accountingSoftwareSlug: PropTypes.string,
    fields: PropTypes.object || PropTypes,
    t: PropTypes.func,
};

export default connect(
    state => ({
        accountingSoftwareSlug: get(state.organisation.selectedOrganisation, 'accounting_software_data.slug'),
    }),
    {}
)(withTranslation()(AddEditContactPersons));

/**
 * Component to check if the user has edit permission based on current subscription
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import { React, connect, Link } from '../../includes/exports/react';
import { Modal, Icon } from 'antd';
import { PropTypes } from 'prop-types';
import get from 'lodash/get';
import { withTranslation } from 'react-i18next';

import * as constants from '../../includes/constants';
import { getLocalStorageValue, redirect } from '../../includes/utils';
import './style.scss';

const { confirm } = Modal;

/**
 * Permission Wrapper Component
 *
 * @returns    {string}    Permission wrapper
 */
const HasEditPermission = props => {
    const currentAccountId = getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY);

    const currentAccountRole = get(props, ['roles', currentAccountId, 'slug'], '');

    const hasValidSubscription = [constants.USER_ROLE_ADMIN, constants.USER_ROLE_ADMIN_MANAGER].includes(
        currentAccountRole
    )
        ? true
        : props.subscriptionDetails.has_subscription || props.subscriptionDetails.under_grace_period;

    // get locale messages
    const getLocaleText = path => props.t(`sharedMessages.upgradeRequired.${path}`);

    // render upgrade required popup
    const showError = () =>
        confirm({
            icon: <Icon type="close-circle" className="red-icon-text" />,
            title: getLocaleText('title'),
            content: getLocaleText('content'),
            centered: true,
            okText: getLocaleText('ok'),
            onOk: () => redirect('/plans'),
            cancelButtonProps: { className: 'hide-cancel-btn' },
        });

    /**
     * Renders the export menus view
     */
    return (
        <>
            {hasValidSubscription ? (
                props.type === 'link' ? (
                    <Link to={props.link} className={props.className}>
                        {props.children}
                    </Link>
                ) : (
                    <span className={props.className} onClick={props.onClick}>
                        {props.children}
                    </span>
                )
            ) : (
                <span className={props.className} onClick={showError}>
                    {props.children}
                </span>
            )}
        </>
    );
};

/**
 * Default props
 */
HasEditPermission.defaultProps = {
    type: 'button',
    className: '',
};

/**
 * Proptypes
 */
// Custom prop type validation to check either "link" or "onClick" prop is provided
const linkOrOnClickValidation = props => {
    if (!props.link && !props.onClick) return new Error('One among "link" or "onClick" prop must be provided');
};

HasEditPermission.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    className: PropTypes.string,
    link: linkOrOnClickValidation,
    onClick: linkOrOnClickValidation,
    subscriptionDetails: PropTypes.object,
    t: PropTypes.func,
    type: PropTypes.string,
};

export default connect(
    state => ({
        subscriptionDetails: get(state, 'account.accountDetails.subscription', {}),
        roles: state.user.roles,
    }),
    null
)(withTranslation()(HasEditPermission));

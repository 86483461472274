/**
 * useIsManualOrganisation
 * Checks if the organisation is manual or not
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com>
 */

import { get } from 'lodash';
import { useSelector } from 'react-redux';

/**
 * Checks if the organisation is manual or not
 *
 * @returns {boolean} Is manual organisation or not
 */
const useIsManualOrganisation = () => {
    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);
    return get(selectedOrganisation, 'is_manual', false);
};

export default useIsManualOrganisation;

/**
 * Redux Subscription Plan action
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import action types
import * as actionTypes from '../../../constants/actionTypes';

/**
 * Add Subscription Plan Action
 *
 * @param   {object}    payload    Payload data
 */
export const addSubscriptionPlan = payload => ({
    type: actionTypes.ADD_SUBSCRIPTION_PLAN_REQUEST,
    payload: payload,
});

/**
 * Cancel account subscription
 *
 * @param   {object}    payload     Payload data
 * @param   {Function}  callback    Callback function
 */
export const cancelAccountSubscription = (payload, callback) => ({
    type: actionTypes.CANCEL_ACCOUNT_SUBSCRIPTION_REQUEST,
    payload: payload,
    callback: callback,
});

/**
 * Create subscription for an account
 *
 * @param   {object}    payload     Payload data
 * @param   {Function}  callback    Callback function
 */
export const createSubscriptionForAccount = (payload, callback) => ({
    type: actionTypes.CREATE_SUBSCRIPTION_FOR_ACCOUNT_REQUEST,
    payload: payload,
    callback: callback,
});

/**
 * Delete subscription plan action
 *
 * @param   {string}    planId    Plan Id
 */
export const deleteSubscriptionPlan = planId => ({
    type: actionTypes.DELETE_SUBSCRIPTION_PLANS_REQUEST,
    planId,
});

/**
 * Export all subscriptions
 */
export const exportAllSubscriptions = () => ({
    type: actionTypes.EXPORT_ALL_SUBSCRIPTIONS_REQUEST,
});

/**
 * Get Plan accounts Action
 */
export const getPlanAccounts = (planId, searchParams) => ({
    type: actionTypes.GET_PLAN_ACCOUNTS_REQUEST,
    payload: { ...planId, ...searchParams },
});

/**
 * Get Plan Quotes Action
 */
export const getPlanQuotes = searchParams => ({
    type: actionTypes.GET_PLAN_QUOTES_REQUEST,
    payload: { searchParams },
});

/**
 * Get Subscription details Action
 *
 * @param   {string}    id     Subscription id
 */
export const getSubscriptionDetails = id => ({
    type: actionTypes.GET_SUBSCRIPTION_DETAILS_REQUEST,
    payload: { subscription_id: id },
});

/**
 * Get Subscription plan details Action
 *
 * @param   {string}    id     Plan id
 */
export const getSubscriptionPlanDetails = id => ({
    type: actionTypes.GET_SUBSCRIPTION_PLAN_DETAILS_REQUEST,
    payload: { plan_id: id },
});

/**
 * Get Subscription Plans Action
 *
 * @param   {object}    searchParams    Search Params
 */
export const getSubscriptionPlans = searchParams => ({
    type: actionTypes.GET_SUBSCRIPTION_PLANS_REQUEST,
    payload: { searchParams },
});

/**
 * Get Subscription History Action
 *
 * @param   {object}    searchParams    Search Params
 */
export const getSubscriptionsHistory = searchParams => ({
    type: actionTypes.GET_SUBSCRIPTIONS_HISTORY_REQUEST,
    payload: searchParams,
});

/**
 * Get Subscription statuses Action
 */
export const getSubscriptionStatuses = () => ({
    type: actionTypes.GET_SUBSCRIPTION_STATUSES_REQUEST,
    payload: { page_size: 0 },
});

/**
 * Set Subscription Plan ID Action
 *
 * @param   {string}    id     Subscription Plan id
 */
export const setSubscriptionPlanId = id => ({
    type: actionTypes.SET_SUBSCRIPTION_PLAN_ID,
    planId: id,
});

/**
 * Update Subscription Plan Action
 *
 * @param   {object}    planDetails    Payload data
 * @param   {string}    plan_id        Plan Id
 */
export const updateSubscriptionPlan = (planDetails, plan_id) => ({
    type: actionTypes.UPDATE_SUBSCRIPTION_PLAN_REQUEST,
    payload: { planDetails, plan_id },
});

/**
 * Reminder actions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import action types
import * as actionTypes from '../../../constants/actionTypes';

/**
 * Add campaign action
 *
 * @param {string} organisationId Organisation Id
 * @param {object} campaignDetails Campaign Details to add
 * @param {string} redirectUrl Redirect url
 * @param {Function} callback Callback function
 *
 * @returns {object}  Add campaign action
 */
export const addCampaign = (organisationId, campaignDetails, redirectUrl, callback) => ({
    type: actionTypes.ADD_CAMPAIGN_REQUEST,
    payload: {
        organisationId,
        ...campaignDetails,
    },
    redirectUrl,
    callback,
});

/**
 * Change the campaign status action
 *
 * @returns {object}  Get change campaign status action
 */
export const changeCampaignStatus = (campaignId, status, organisationId) => ({
    type: actionTypes.CHANGE_CAMPAIGN_STATUS_REQUEST,
    payload: {
        status,
        scheduler_id: campaignId,
        organisationId,
    },
});

/**
 * Get campaign days action
 *
 * @returns {object}  Get campaign days action
 */
export const getCampaignDays = () => ({
    type: actionTypes.GET_CAMPAIGN_DAYS_REQUEST,
    payload: { page_size: 0 },
});

/**
 * Get Campaign details action
 *
 * @param {string} organisationId Organisation Id
 * @param {string} schedulerId Scheduler Id
 *
 * @returns {object}  Get Campaign details action
 */
export const getCampaignDetails = (schedulerId, organisationId) => ({
    type: actionTypes.GET_CAMPAIGN_DETAILS_REQUEST,
    payload: {
        scheduler_id: schedulerId,
        organisation_id: organisationId,
    },
});

/**
 * Get Campaigns action
 *
 * @param   {object}  payload Payload
 *
 * @returns {object}  Get Campaigns action
 */
export const getCampaigns = (payload = {}) => ({
    type: actionTypes.GET_CAMPAIGNS_REQUEST,
    payload,
});

/**
 * Get reminder details
 *
 * @param {string} organisationId Organisation Id
 * @param {string} reminderId Reminder Id
 *
 * @returns {object}  Get Reminders details action
 */
export const getReminderDetails = (organisationId, reminderId) => ({
    type: actionTypes.GET_REMINDER_DETAILS_REQUEST,
    payload: {
        organisationId,
        reminderId,
    },
});

/**
 * Get Reminders action
 *
 * @param {object} payload Payload
 *
 * @returns {object}  Get Reminders action
 */
export const getReminders = (payload = {}) => ({
    type: actionTypes.GET_REMINDERS_REQUEST,
    payload,
});

/**
 * Reset filters action
 *
 * @returns {object}  Reset filters action
 */
export const resetFilters = () => ({
    type: actionTypes.RESET_REMINDER_FILTER,
});

/**
 * Reset reminder type and date filter action
 *
 * @returns {object}  Reset reminder typ and date filter action
 */
export const resetReminderTypeAndDateFilters = () => ({
    type: actionTypes.RESET_REMINDER_TYPE_AND_DATE_FILTER,
});

/**
 * Send On-Demand Campaign action
 *
 * @param {object} onDemandCampaignDetails On Demand Campaign details
 * @param {object} urlParams Url Params
 * @param {Function} callback Callback function
 * @param {object} queryParams Query Params
 * @param {Function} callback1 Another callback function
 *
 * @returns {object}  Send Reminder action
 */
export const sendOnDemandCampaign = (onDemandCampaignDetails, urlParams, callback, queryParams, callback1) => ({
    type: actionTypes.SEND_ON_DEMAND_CAMPAIGN_REQUEST,
    payload: {
        onDemandCampaignDetails,
        urlParams,
        queryParams,
    },
    callback,
    callback1,
});

/**
 * Send reminder action
 *
 * @param {object} reminderDetails Reminder details
 * @param {object} urlParams Url Params
 * @param {string} redirectUrl Redirect url
 *
 * @returns {object}  Send Reminder action
 */
export const sendReminder = (reminderDetails, urlParams, redirectUrl) => ({
    type: actionTypes.SEND_REMINDER_REQUEST,
    payload: {
        reminderDetails,
        urlParams,
    },
    redirectUrl,
});

/**
 * Set reminder filters action
 *
 * @param {object} type Type
 * @param {object} value Value
 *
 * @returns {object}  Set reminder filters action
 */
export const setReminderFilter = (type, value) => ({
    type: actionTypes.SET_REMINDER_FILTER,
    payload: { type, value },
});

/**
 * Update campaign action
 *
 * @param {string} campaignId Campaign Id
 * @param {string} organisationId Organisation Id
 * @param {object} campaignDetails Campaign Details to update
 * @param {string} redirectUrl Redirect url
 * @param {Function} callback Callback function
 *
 * @returns {object}  Update campaign action
 */
export const updateCampaign = (campaignId, organisationId, campaignDetails, redirectUrl, callback) => ({
    type: actionTypes.UPDATE_CAMPAIGN_REQUEST,
    payload: {
        campaignId,
        organisationId,
        ...campaignDetails,
    },
    redirectUrl,
    callback,
});

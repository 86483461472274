import React from 'react';
import PropTypes from 'prop-types';
import CanvasJSReact from '../../../../../assets/js/canvasjs.react';
import Loader from 'components/Loader';
import { Col, Row } from 'antd';
let CanvasJS = CanvasJSReact.CanvasJS;
let CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet('communicationMetricsColorSet', ['#A2FF86', '#EF6262']);

export default function CommunicationMethodMetrics({
    methodLabel,
    attemptedCount,
    successfulCount,
    failedCount,
    isLoading = false,
}) {
    if (isLoading) return <Loader />;

    const canvasJSChartOptions = {
        height: 200,
        colorSet: 'communicationMetricsColorSet',
        subtitles: [
            {
                text: `Attempted: ${attemptedCount}`,
                verticalAlign: 'center',
                fontSize: 12,
                fontFamily: 'verdana',
                dockInsidePlotArea: true,
            },
        ],
        data: [
            {
                type: 'doughnut',
                dataPoints: [
                    { name: 'successful', y: successfulCount },
                    { name: 'failed', y: failedCount },
                ],
            },
        ],
    };

    return (
        <Row type="flex" justify="start">
            <Col span={24}>{methodLabel}</Col>
            <Col span={24}>
                <CanvasJSChart options={canvasJSChartOptions} />
            </Col>
        </Row>
    );
}

CommunicationMethodMetrics.propTypes = {
    attemptedCount: PropTypes.number.isRequired,
    successfulCount: PropTypes.number.isRequired,
    failedCount: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    methodLabel: PropTypes.element.isRequired,
};

import { useGoCardlessDropin } from '@gocardless/react-dropin';
import { Form } from 'antd';
import { find, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, change, formValueSelector, reduxForm, reset } from 'redux-form';
import { useTranslation } from 'react-i18next';

import BankMandateSVG from 'assets/images/bank_mandate.svg';
import Button from 'components/Button';
import PaymentMethodOption from 'components/PaymentGateway/components/PaymentMethodOption';
import SelectButton from 'components/SelectButton';
import FormField from 'components/shared/FormField';
import { GO_CARDLESS_MANDATE_ADD_FORM, PAYMENT_METHOD_STATUS } from 'includes/constants';
import {
    COUNTRY_ID,
    MANDATE_NAME,
    PAYMENT_GATEWAY,
    PAYMENT_GATEWAY_ID,
    PAYMENT_METHOD_TYPE_ID,
    SCHEME_ID,
} from 'includes/constants/keys/request';
import useCountries from 'includes/hooks/useCountries';
import useInternalGoCardlessDetails from 'includes/hooks/useInternalGoCardlessDetails';
import usePaymentMethodSchemes from 'includes/hooks/usePaymentMethodSchemes';
import PaymentGatewaysApi from 'includes/services/shared/paymentGateways';
import { showApiErrors } from 'includes/utils/api';
import * as formValidations from 'includes/utils/form';
import usePaymentMethodStatuses from 'includes/hooks/usePaymentMethodStatuses';

export const GOCARDLESS_FORM_MODE = {
    PAYMENT: 'payment',
    SETUP: 'setup',
};

const fieldValueSelector = formValueSelector(GO_CARDLESS_MANDATE_ADD_FORM);

const onChangeScheme = value => change(GO_CARDLESS_MANDATE_ADD_FORM, SCHEME_ID, value);

const onChangeMandateName = value => change(GO_CARDLESS_MANDATE_ADD_FORM, MANDATE_NAME, value);

/**
 * Scheme Selection component used in the GoCardless mandate form
 *
 * @since 2.8.0
 */
function SchemeSelectionComponent({ label, options }) {
    const selectedScheme = useSelector(state => fieldValueSelector(state, SCHEME_ID));

    const dispatch = useDispatch();

    return (
        <div className="mb-4">
            <div>{label}</div>
            {options.length > 0 ? (
                <div className="flex flex-row justify-start gap-2">
                    {options.map(o => (
                        <SelectButton
                            key={o.id}
                            onClick={() => dispatch(onChangeScheme(o.id))}
                            isSelected={o.id === selectedScheme}
                        >
                            {o.scheme}
                        </SelectButton>
                    ))}
                </div>
            ) : (
                'No schemes available for the selected country'
            )}
        </div>
    );
}

SchemeSelectionComponent.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
};

/**
 * Add GoCardless Mandate form
 *
 * @since 2.8.0
 */
export const GoCardlessMandateForm = reduxForm({ form: GO_CARDLESS_MANDATE_ADD_FORM })(function({
    buttonLabel,
    handlePaymentThroughSavedMethod,
    handleSubmit,
    isLoading,
    mode = GOCARDLESS_FORM_MODE.SETUP,
    setSelectedMethod,
}) {
    const [existingPaymentMethodUnderSelectedScheme, setExistingPaymentMethodUnderSelectedScheme] = useState(null);

    const { data: paymentMethodStatuses } = usePaymentMethodStatuses();

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { data: paymentSchemes } = usePaymentMethodSchemes();

    const { data: countries } = useCountries();

    const selectedCountryId = useSelector(state => fieldValueSelector(state, 'country_id'));

    const selectedSchemeId = useSelector(state => fieldValueSelector(state, 'scheme_id'));

    const paymentMethods = useSelector(state => state.paymentGateways.paymentMethods);

    const supportedSchemes = useMemo(() => {
        if (!selectedCountryId || paymentSchemes.length === 0) return [];

        return paymentSchemes.filter(
            scheme => scheme.supported_countries.includes(selectedCountryId) && scheme.mandate_required
        );
    }, [paymentSchemes, selectedCountryId]);

    /**
     * Reset scheme and mandate name when `supportedSchemes` is updated
     */
    useEffect(() => {
        dispatch(onChangeScheme(''));
        dispatch(onChangeMandateName(''));
    }, [supportedSchemes]);

    /**
     * Payment method active status ID
     * Used to find payment method which is active
     */
    const paymentMethodActiveStatusId = useMemo(() => {
        if (paymentMethodStatuses.length === 0) return null;

        return get(find(paymentMethodStatuses, { slug: PAYMENT_METHOD_STATUS.ACTIVE }), 'id', null);
    }, [paymentMethodStatuses]);

    /**
     * Find and update if a payment method already exists for the selected scheme
     */
    useEffect(() => {
        if (selectedSchemeId && !isEmpty(paymentMethods)) {
            // Find existing payment method under selected scheme
            const existingPaymentMethodUnderScheme = find(paymentMethods, {
                scheme_id: selectedSchemeId,
                status_id: paymentMethodActiveStatusId,
            });
            if (existingPaymentMethodUnderScheme) {
                setExistingPaymentMethodUnderSelectedScheme(existingPaymentMethodUnderScheme);
                if (setSelectedMethod) setSelectedMethod(existingPaymentMethodUnderScheme);
            } else {
                setExistingPaymentMethodUnderSelectedScheme(null);
            }
            return;
        }
        setExistingPaymentMethodUnderSelectedScheme(null);
    }, [selectedCountryId, selectedSchemeId, paymentMethods]);

    /**
     * Renders one of selected payment method, mandate name field and null based on various condition
     */
    const renderMandateNameSection = () => {
        if (!selectedSchemeId) return null;

        if (existingPaymentMethodUnderSelectedScheme) {
            return (
                <PaymentMethodOption
                    key={existingPaymentMethodUnderSelectedScheme?.id}
                    {...{
                        countryId: existingPaymentMethodUnderSelectedScheme?.country_id,
                        description: t('customerMessages.paymentMethod.mandate.description', {
                            number: existingPaymentMethodUnderSelectedScheme.account_number.slice(-4),
                        }),
                        icon: BankMandateSVG,
                        isSelected: true,
                        onSelect: () => {},
                        schemeId: existingPaymentMethodUnderSelectedScheme.scheme_id,
                        title: existingPaymentMethodUnderSelectedScheme.name,
                        type: 'mandate',
                    }}
                />
            );
        }

        return (
            <div>
                <Field
                    type="text"
                    component={FormField}
                    name={MANDATE_NAME}
                    label="Mandate name"
                    validate={[formValidations.required]}
                />
            </div>
        );
    };

    /**
     * Render form submit button based on various condition
     */
    const renderFormSubmitButton = () => {
        if (!selectedSchemeId) return null;

        if (mode === GOCARDLESS_FORM_MODE.SETUP && existingPaymentMethodUnderSelectedScheme) return null;

        if (mode === GOCARDLESS_FORM_MODE.PAYMENT && existingPaymentMethodUnderSelectedScheme) {
            return (
                <div className="flex-grow-0 self-center mt-3">
                    <Button loading={isLoading} htmlType="button" onClick={handlePaymentThroughSavedMethod}>
                        {buttonLabel}
                    </Button>
                </div>
            );
        }

        return (
            <div className="flex-grow-0 self-center">
                <Button loading={isLoading} htmlType="submit">
                    {buttonLabel}
                </Button>
            </div>
        );
    };

    return (
        <Form className="flex flex-col text-left" onSubmit={handleSubmit}>
            <div>
                <Field
                    type="select"
                    component={FormField}
                    name={COUNTRY_ID}
                    label="Country"
                    options={countries.map(country => ({
                        name: country.name,
                        value: country.id,
                    }))}
                    validate={[formValidations.required]}
                />
            </div>

            {selectedCountryId ? (
                <Field
                    component={SchemeSelectionComponent}
                    name={SCHEME_ID}
                    label="Scheme"
                    options={supportedSchemes}
                    validate={[formValidations.required]}
                />
            ) : null}

            {renderMandateNameSection()}

            {renderFormSubmitButton()}
        </Form>
    );
});

GoCardlessMandateForm.propTypes = {
    buttonLabel: PropTypes.string,
    handlePaymentThroughSavedMethod: PropTypes.func,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    mode: PropTypes.oneOf(Object.values(GOCARDLESS_FORM_MODE)),
    setSelectedMethod: PropTypes.func,
};

/**
 * GoCardless Dropin component wrapper
 *
 * Since `billingRequestFlowID` is required to initialize the drop-in, wrap it into a component(renders nothing but a React fragment),
 * and render it only after fetching the `billingRequestFlowID` from the backend
 *
 * @since 2.8.0
 */
export function GoCardlessMandateDropinHookWrapper({ billingRequestFlowID, environment, onSuccess, onExit }) {
    const config = {
        billingRequestFlowID,
        environment,
        onSuccess,
        onExit,
    };
    const { open, ready } = useGoCardlessDropin(config);

    useEffect(() => {
        if (billingRequestFlowID && ready) open();
    }, [billingRequestFlowID, ready]);

    return <></>;
}

GoCardlessMandateDropinHookWrapper.propTypes = {
    billingRequestFlowID: PropTypes.string,
    environment: PropTypes.string,
    onExit: PropTypes.func,
    onSuccess: PropTypes.func,
};

/**
 * Add mandate GoCardless wrapper
 *
 * Component responsible for fetching `billingRequestFlowID` and rendering the `AddMandateUsingGoCardless` conditionally to initialize the drop-in UI
 *
 * @since 2.8.0
 */
export default function AddMandateUsingGoCardless({ paymentMethodTypeId, onClose }) {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [billingRequestFlowID, setBillingRequestFlowID] = useState();

    const formValues = useSelector(state => fieldValueSelector(state, COUNTRY_ID, MANDATE_NAME, SCHEME_ID));

    const { goCardlessDetails } = useInternalGoCardlessDetails();

    const handleSubmit = async values => {
        if (!goCardlessDetails) return;

        setIsLoading(true);

        let res = null;

        try {
            res = await PaymentGatewaysApi.getClientSecret({
                ...values,
                method_type_id: paymentMethodTypeId,
                payment_gateway_id: goCardlessDetails.id,
            });
        } catch (errors) {
            dispatch(
                showApiErrors(errors, null, 'PAYMENT_METHODS', '', [
                    COUNTRY_ID,
                    MANDATE_NAME,
                    PAYMENT_GATEWAY_ID,
                    PAYMENT_GATEWAY,
                    PAYMENT_METHOD_TYPE_ID,
                    SCHEME_ID,
                ])
            );
            setIsLoading(false);
            return;
        }

        const clientSecret = res?.data?.data?.payment_method?.next_action;

        setBillingRequestFlowID(clientSecret);
    };

    /**
     * `onSuccess` handler for GoCardless drop-in
     *
     * @param {*} billingRequest Billing request details from GoCardless
     */
    const onGoCardlessSuccess = async billingRequest => {
        try {
            await PaymentGatewaysApi.addPaymentMethod({
                ...formValues,
                token: billingRequest.id,
                payment_gateway_id: goCardlessDetails.id,
                method_type_id: paymentMethodTypeId,
            });
        } catch (errors) {
            dispatch(
                showApiErrors(errors, null, 'PAYMENT_METHODS', '', [
                    COUNTRY_ID,
                    MANDATE_NAME,
                    PAYMENT_GATEWAY_ID,
                    PAYMENT_GATEWAY,
                    PAYMENT_METHOD_TYPE_ID,
                    SCHEME_ID,
                ])
            );
        } finally {
            setIsLoading(false);
            onClose();
        }
    };

    /**
     * `onExit` handler for GoCardless drop-in
     */
    const onGoCardlessExit = () => {
        dispatch(reset(GO_CARDLESS_MANDATE_ADD_FORM));
        setIsLoading(false);
        onClose();
    };

    return (
        <div className="flex flex-col text-left gap-3">
            <GoCardlessMandateForm buttonLabel={'Add mandate'} onSubmit={handleSubmit} isLoading={isLoading} />
            {billingRequestFlowID ? (
                <GoCardlessMandateDropinHookWrapper
                    billingRequestFlowID={billingRequestFlowID}
                    environment={goCardlessDetails.environment}
                    onSuccess={onGoCardlessSuccess}
                    onExit={onGoCardlessExit}
                />
            ) : null}
        </div>
    );
}

AddMandateUsingGoCardless.propTypes = {
    onClose: PropTypes.func,
    paymentMethodTypeId: PropTypes.string,
};

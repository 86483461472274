/**
 * Plan Add/Edit Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb, Modal } from 'antd';
import { change } from 'redux-form';
import { find, get, isString } from 'lodash';
import { useTranslation } from 'react-i18next';

import * as constants from 'includes/constants';
import * as requestKeys from 'includes/constants/keys/request';
import AddEditPlanForm from '../../../shared/forms/AddEditPlan';
import Loader from '../../../shared/Loader';
import {
    addSubscriptionPlan,
    getSubscriptionPlanDetails,
    updateSubscriptionPlan,
} from 'includes/redux/actions/customer/subscriptionPlan';
import { objectToFormData } from 'includes/utils/form';
import { getAllPermissions } from 'includes/redux/actions/shared/settings';
import { getMissingPermissionLabels } from 'includes/utils';
import PermissionDependencyModalContentTable from 'components/PermissionDependencyModalContentTable';

const { info } = Modal;

/**
 * Add Edit plan component
 */
const AddEditPlan = props => {
    const [imagePickerVisible, setImagePickerVisible] = useState(false);
    const isAdd = props.action === 'add';
    const toggleImagePicker = () => setImagePickerVisible(!imagePickerVisible);

    const { planId } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isDetailsLoading = useSelector(state => state.subscription.isDetailsLoading);
    const subscriptionPlanDetails = useSelector(state => state.subscription.subscriptionPlanDetails);
    const allPermissions = useSelector(state => state.settings.permissions || []);

    // effect to fetch plan details
    useEffect(() => {
        if (get(subscriptionPlanDetails, 'id') !== planId && !isAdd) dispatch(getSubscriptionPlanDetails(planId));
        dispatch(getAllPermissions());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const defaultPermissions = useMemo(
        () => allPermissions.filter(x => x.is_default || x.has_all_plan_access_by_default).map(x => x.id),
        [allPermissions]
    );

    /**
     * Get the locale
     *
     * @param   {string}    path    Path
     *
     * @returns  {string}            Locale Message
     */
    const getLocale = path => t(`adminMessages.plans.form.${path}`);

    /**
     * Submit handler function for handling editing plan
     *
     * @param   {object}    values     Form values
     */
    const handleSubmit = values => {
        //the values object may contain the url of the plan image if no new image is uploaded,
        //we don't need it to submit with the form remove it from the object
        if (requestKeys.PLAN_IMAGE in values && isString(get(values, requestKeys.PLAN_IMAGE))) {
            delete values[requestKeys.PLAN_IMAGE];
        }

        //find the allowed permissions and check if the dependent permissions are also allowed
        const allowedPermissions = values.permissions
            .filter(x => find(allPermissions, { id: x }))
            .map(x => find(allPermissions, { id: x }));
        const allowedPermissionsSlugs = values.permissions.map(x => get(find(allPermissions, { id: x }), 'permission'));
        const dependentPermissions = allowedPermissions.reduce(
            (acc, curr) => [...acc, ...curr.dependent_permission],
            []
        );

        const missingPermissionLabels = getMissingPermissionLabels(
            dependentPermissions,
            allowedPermissionsSlugs,
            allPermissions
        );

        if (Object.values(missingPermissionLabels).length > 0) {
            info({
                title: t('adminMessages.plans.message.dependentPermissionTitle'),
                content: (
                    <div>
                        <PermissionDependencyModalContentTable permissionLabels={missingPermissionLabels} />
                    </div>
                ),
            });
            return;
        }

        if (isAdd) {
            dispatch(
                addSubscriptionPlan(
                    objectToFormData({
                        ...values,
                    })
                )
            );
        } else {
            dispatch(
                updateSubscriptionPlan(
                    objectToFormData({
                        ...values,
                    }),
                    get(subscriptionPlanDetails, 'id')
                )
            );
        }
    };

    return (
        <div className="home-content-wrapper edit-profile-wrapper">
            <div className="tab-arrow-link">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={'/admin/plans/plans'}>{getLocale('breadcrumb.plans')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{getLocale(`breadcrumb.${isAdd ? 'add' : 'edit'}Plan`)}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h2 className="page-title">{getLocale(`pageTitle.${isAdd ? 'add' : 'edit'}`)}</h2>
            <div className="white-box-wrapper full-wrapper">
                {isDetailsLoading ? (
                    <Loader />
                ) : (
                    <AddEditPlanForm
                        defaultImage={require('../../../../assets/images/enterprise-plan.png')}
                        enableReinitialize
                        imagePickerVisible={imagePickerVisible}
                        initialValues={isAdd ? { permissions: defaultPermissions } : subscriptionPlanDetails}
                        isAdd={isAdd}
                        onCancel={toggleImagePicker}
                        onImageSubmit={image => {
                            dispatch(change(constants.ADD_EDIT_PLAN_FORM, requestKeys.PLAN_IMAGE, image));
                            toggleImagePicker();
                        }}
                        onSubmit={handleSubmit}
                        toggleImagePicker={toggleImagePicker}
                    />
                )}
            </div>
        </div>
    );
};

/**
 * Proptypes
 */
AddEditPlan.propTypes = {
    action: PropTypes.string,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    isAdmin: PropTypes.bool,
    path: PropTypes.string,
    planId: PropTypes.string,
    subscriptionPlanDetails: PropTypes.object,
    t: PropTypes.func,
};

export default AddEditPlan;

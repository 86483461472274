/**
 * Zendesk ticket component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import React, { useEffect } from 'react';
import { Col, Form, Row, Tag } from 'antd';
import { get, isArray, startCase } from 'lodash';
import { getTicket } from 'includes/slices/zendesk';
import { useCheckEditPermission } from 'includes/utils/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    ZENDESK_EVENT_TYPES,
    ZENDESK_TICKET_PRIORITIES,
    ZENDESK_TICKET_STATUSES,
    ZENDESK_TICKET_TYPES,
} from 'includes/constants/keys/request';
import CustomButton from 'components/Button';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Skeleton from 'components/Skeleton';
import useAllowZendeskIntegration from 'includes/hooks/useAllowZendeskIntegration';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useZendeskSettings from 'includes/hooks/useZendeskSettings';
import useZendeskTicketOptions from 'includes/hooks/useZendeskTicketOptions';

/**
 * Zendesk ticket component
 */
const ZendeskTicket = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { ticketId } = props;
    const organisationId = useOrganisationId();
    const allowZendeskIntegration = useAllowZendeskIntegration();
    const ticket = useSelector(state => state.zendesk.ticket);
    const loadingTicket = useSelector(state => state.zendesk.loadingTicket);
    const { data: ticketOptions, isLoading: loadingTicketOptions } = useZendeskTicketOptions();
    const { data: zendeskSetting, isLoading: loadingSettings } = useZendeskSettings();

    // check if has permission
    useCheckEditPermission();

    // get the ticket details
    useEffect(() => {
        if (allowZendeskIntegration) {
            dispatch(getTicket(organisationId, ticketId));
        }
    }, [ticketId]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the value from the ticket details
     *
     * @param {string} key Key for which we need to retrieve the value
     *
     * @returns {string} Value
     */
    const getValueFromTicketDetails = key => get(ticket, key);

    /**
     * Get Yes or No based on boolean value
     *
     * @param {string} key Key for which we need to retrieve the value
     *
     * @returns {string} Value
     */
    const getYesOrNo = key => getLocaleText(get(ticket, key) ? 'yes' : 'no');

    /**
     * Get localized text
     *
     * @param {string} path
     *
     * @returns {string} Localized string
     */
    const getLocaleText = path => t(`messages.zendesk.details.fieldName.${path}`);

    /**
     * Get value from ticket options
     *
     * @param {string} optionKey Option Key to take from ticket options
     * @param {string} key Key for which value is to be retrieved
     *
     * @returns {string} Ticket Option Value
     */
    const getValueFromTicketOptions = (optionKey, key) => {
        const ticketTypeOptions = get(ticketOptions, optionKey);
        return startCase(get(ticketTypeOptions, key, ''));
    };

    /**
     * Get the zendesk ticket link
     *
     * @param {number} ticketId Ticket id
     *
     * @returns {string} Zendesk Ticket Link
     */
    const getZendeskTicketLink = ticketId =>
        `https://${zendeskSetting.subdomain}.zendesk.com/agent/tickets/${ticketId}`;

    /**
     * Render tags
     *
     * @param {Array} values Value that needs to be shown as tags
     *
     * @returns {Array} content
     */
    const renderTags = values => {
        if (isArray(values)) {
            return values.map((value, index) => (
                <Tag key={index} color="blue">
                    {value}
                </Tag>
            ));
        }
    };

    /**
     * Render the field
     *
     * @param {string} key Key for which the field is to be shown
     * @param {boolean} isHtml Whether the content is HTML or plain text. Default false
     * @param {boolean} onSameLine Whether we need to show content in same line or different lines. Default true
     * @param {boolean} showIfEmpty Whether we need to show the field even if content is empty. Default false
     * @param {boolean} isTags Whether the content should be rendered as tags. Default false
     * @param {string} ticketOptionKey Ticket option key. If specified, get the value from the ticket options  Default ''
     *
     * @returns Content
     */
    const renderField = ({
        key,
        onSameLine = true,
        showIfEmpty = false,
        isTags = false,
        isBoolean = false,
        isHtml = false,
        ticketOptionKey = '',
    }) => {
        let value = isBoolean ? getYesOrNo(key) : getValueFromTicketDetails(key);
        if (ticketOptionKey) {
            value = getValueFromTicketOptions(ticketOptionKey, value);
        }
        const showField = value ? true : !!showIfEmpty;
        return (
            showField && (
                <Row gutter={16} className="custom-content-details-wrapper">
                    <Col>
                        <label htmlFor="name">{getLocaleText(key)}: </label>
                        {isTags ? (
                            renderTags(value)
                        ) : onSameLine ? (
                            <b>{isHtml ? ReactHtmlParser(value) : value}</b>
                        ) : (
                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-form-item-control-wrapper">
                                    <div className="ant-form-item-control">
                                        <div
                                            className="ant-form-item-children"
                                            style={{ marginTop: 10, lineHeight: '35px' }}
                                        >
                                            <div style={{ whiteSpace: 'pre-line' }}>
                                                <b>{isHtml ? ReactHtmlParser(value) : value}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            )
        );
    };

    /**
     * Get the ticket event type
     */
    const ticketEventType = getValueFromTicketOptions(
        ZENDESK_EVENT_TYPES,
        getValueFromTicketDetails('ticket_event_type')
    );

    return (
        <div className="edit-reminder-wrapper">
            <Form layout="vertical" className="">
                {loadingTicket || loadingSettings || loadingTicketOptions ? (
                    <Skeleton title={false} active paragraph={{ rows: 6 }} />
                ) : ticketEventType ? (
                    <>
                        <section>
                            <Row gutter={16} className="custom-content-details-wrapper">
                                <Col>
                                    <label htmlFor="notificationType">{getLocaleText('eventType')}: </label>
                                    <div className="tag-linker table-button-wrapper">
                                        <button className={ticketEventType?.toLowerCase()}>{ticketEventType}</button>
                                    </div>
                                </Col>
                            </Row>
                            {renderField({ key: 'subject' })}
                            {renderField({ key: 'content', onSameLine: false, isHtml: true })}
                            {renderField({ key: 'tags', isTags: true })}
                            {renderField({ key: 'is_public', isBoolean: true })}
                            {renderField({
                                key: 'ticket_priority',
                                ticketOptionKey: ZENDESK_TICKET_PRIORITIES,
                            })}
                            {renderField({ key: 'ticket_status', ticketOptionKey: ZENDESK_TICKET_STATUSES })}
                            {renderField({ key: 'ticket_type', ticketOptionKey: ZENDESK_TICKET_TYPES })}
                            {renderField({ key: 'ticket_id' })}
                            {renderField({ key: 'audit_id' })}
                            {renderField({ key: 'requester_id' })}
                            {renderField({ key: 'ticket_created_or_updated_date' })}
                            <div style={{ marginTop: 10 }}>
                                <a
                                    className="accounting-software-external-link"
                                    href={getZendeskTicketLink(ticket.ticket_id)}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <CustomButton className="green-bg-button blue-bg-button" filled>
                                        {getLocaleText('viewInZendesk')}
                                    </CustomButton>
                                </a>
                            </div>
                        </section>
                    </>
                ) : (
                    <section>{getLocaleText('noDataToShow')}</section>
                )}
            </Form>
        </div>
    );
};

ZendeskTicket.propTypes = {
    ticketId: PropTypes.string,
};

export default ZendeskTicket;

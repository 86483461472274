/**
 * Payment arrangements and extensions listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Tag } from 'antd';
import { find, get } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import {
    TABLE_NO_PAYMENT_ARRANGEMENTS_FOUND_MESSAGE,
    TABLE_NO_PAYMENT_EXTENSIONS_FOUND_MESSAGE,
} from 'includes/constants/messages/errors';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import { redirect } from 'includes/utils';
import useAccountId from 'includes/hooks/useAccountId';
import { getPaymentArrangements } from 'includes/slices/paymentArrangement';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
// import { CHART_OF_ACCOUNT } from 'includes/constants/permissions';
// import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import { PropTypes } from 'includes/exports/react';
import {
    PAYMENT_ARRANGEMENT_PLAN_TYPE_EXTENSION,
    PAYMENT_ARRANGEMENT_STATUS_OPTIONS,
    PAYMENT_PLAN_PAYMENT_FREQUENCY_TYPE_OPTIONS,
} from 'includes/constants';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import { getPaymentArrangementsTableColumns } from 'includes/tableColumns/paymentArrangements';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import { CURRENCY_CODE } from 'includes/constants/keys/response';
import Loader from 'components/shared/Loader';
import PaymentArrangementBreadCrumbItems from 'components/BreadCrumbItems/PaymentArrangement';
import { paymentArrangementBrokenTagColor, paymentArrangementStatusTagColors } from 'includes/constants/tagColors';
import { PAYMENT_EXTENSION } from 'includes/constants/permissions';
import PermissionGuard from 'components/PermissionGuard';
import useOrganisationRequired from 'includes/hooks/useOrganisationRequired';
import InvoicesListModal from './components/InvoicesListModal';

const PaymentArrangementsList = props => {
    useUpdateBusinessName();
    useOrganisationRequired();

    const { t } = useTranslation();
    const accountId = useAccountId();
    const organisationId = useOrganisationId();
    const isAdmin = useIsAdminOrManager();
    const shouldDisableApisWithoutOrganisationId = !organisationId ? true : false;
    const getNumberFormattedAsCurrency = useCurrencyFormatter();
    const paymentArrangements = useSelector(state => state.paymentArrangement.paymentArrangements);
    const paymentArrangementsPagination = useSelector(state => state.paymentArrangement.paymentArrangementsPagination);
    const loadingPaymentArrangements = useSelector(state => state.paymentArrangement.loadingPaymentArrangements);
    const { data: currencies, isLoading: loadingOrganisationCurrencies } = useOrganisationCurrencies(
        shouldDisableApisWithoutOrganisationId
    );
    const currentPaymentPlanType = props?.currentPaymentPlanType;

    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [selectedPaymentArrangementId, setSelectedPaymentArrangementId] = useState('');

    /**
     * Format the payment plans data
     */
    const formatPaymentArrangementsData = useCallback(
        () =>
            paymentArrangements.map(paymentArrangement => {
                return {
                    ...paymentArrangement,
                    contact: (
                        <div className="business-name-avatar-wrapper">
                            <div className="action-wrapper">
                                <Link to={getContactViewLink(paymentArrangement)} target="_blank">
                                    {paymentArrangement.contact.name}
                                </Link>
                                <span className="crud-wrapper">
                                    <PermissionGuard requiredPermission={PAYMENT_EXTENSION.VIEW}>
                                        <button
                                            className="crud-link"
                                            onClick={() =>
                                                redirect(
                                                    getPlanLink(
                                                        paymentArrangement.id,
                                                        paymentArrangement?.payment_plan?.type
                                                    )
                                                )
                                            }
                                        >
                                            {getLocaleText('crud.view')}
                                        </button>
                                    </PermissionGuard>
                                    <button
                                        className="crud-link"
                                        onClick={() => redirect(getContactViewLink(paymentArrangement))}
                                    >
                                        {getLocaleText('crud.view_contact')}
                                    </button>
                                    <button
                                        className="crud-link"
                                        onClick={() => setSelectedPaymentArrangementId(paymentArrangement.id)}
                                    >
                                        {getLocaleText('crud.view_invoices')}
                                    </button>
                                </span>
                            </div>
                        </div>
                    ),
                    next_payment_amount: getNumberFormattedWithInvoiceCurrency(
                        paymentArrangement.next_payment_amount,
                        paymentArrangement
                    ),
                    payment_frequency: get(
                        find(PAYMENT_PLAN_PAYMENT_FREQUENCY_TYPE_OPTIONS, {
                            id: paymentArrangement.payment_frequency,
                        }),
                        'label'
                    ),
                    status: (
                        <>
                            <Tag color={get(paymentArrangementStatusTagColors, paymentArrangement.status, 0)}>
                                {get(
                                    find(PAYMENT_ARRANGEMENT_STATUS_OPTIONS, {
                                        id: paymentArrangement.status,
                                    }),
                                    'label'
                                )}
                            </Tag>
                            {paymentArrangement?.is_broken ? (
                                <Tag color={paymentArrangementBrokenTagColor}>
                                    {t('messages.paymentArrangement.arrangement.list.label.broken')}
                                </Tag>
                            ) : null}
                        </>
                    ),
                    upfront_amount: getNumberFormattedWithInvoiceCurrency(
                        paymentArrangement.upfront_amount,
                        paymentArrangement
                    ),
                };
            }),
        [paymentArrangements] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Get the contact view link
     *
     * @param {object} paymentArrangement Payment arrangement
     *
     * @returns {string} Contact View Link
     */
    const getContactViewLink = paymentArrangement => {
        const urlNamespace = paymentArrangement.contact.is_manager ? 'contact-manager' : 'contact';
        return isAdmin
            ? `/admin/accounts/organisation/${urlNamespace}/${accountId}/${organisationId}/${paymentArrangement.contact.id}`
            : `/${urlNamespace}/${paymentArrangement.contact.id}`;
    };

    /**
     * Format number with invoice currency
     *
     * @param {string|number} number  Number
     * @param {object} paymentArrangement payment arrangement
     *
     * @returns {string} Formatted Number
     */
    const getNumberFormattedWithInvoiceCurrency = (number, paymentArrangement) =>
        getNumberFormattedAsCurrency(
            number,
            false,
            false,
            get(find(currencies, { id: paymentArrangement.currency_id }), CURRENCY_CODE)
        );

    /**
     * Get the locale text
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @returns {string} Locale test
     */
    const getLocaleText = path => t(`messages.paymentArrangement.arrangement.${path}`);

    /**
     * Get the payment arrangement plan details view link
     *
     * @param {string} paymentArrangementId Payment arrangement id
     * @param {number} paymentPlanType Payment plan type
     *
     * @returns {string} View Link
     */
    const getPlanLink = (paymentArrangementId, paymentPlanType) => {
        let planUrl = isAdmin ? '/admin/accounts/organisation' : '';
        let slash = '/';
        planUrl += `/payment-${
            paymentPlanType === PAYMENT_ARRANGEMENT_PLAN_TYPE_EXTENSION ? 'extensions' : 'arrangements'
        }`;
        const adminUrlNameSpace = isAdmin ? `${accountId}/${organisationId}/` : '';
        if (props.paymentPlanId) {
            planUrl += `/plan/${adminUrlNameSpace}${props.paymentPlanId}`;
        } else if (props.invoiceId) {
            planUrl += `/invoice/${adminUrlNameSpace}${props.contactId}/${props.invoiceId}`;
        } else if (props.contactId) {
            planUrl += `/contact/${adminUrlNameSpace}${props.contactId}`;
        } else {
            planUrl += `${slash}${adminUrlNameSpace}`;
            slash = '';
        }
        planUrl += `${slash}${paymentArrangementId}`;

        return planUrl;
    };

    /**
     * Memoized payment plans data
     */
    const memoizedPaymentArrangementsData = useMemo(() => formatPaymentArrangementsData(), [
        formatPaymentArrangementsData,
    ]);

    const renderTable = () => (
        <Table
            key={currentPaymentPlanType}
            className="table-1200"
            columns={getPaymentArrangementsTableColumns(currentPaymentPlanType)}
            dataSource={memoizedPaymentArrangementsData}
            dataValues={[organisationId]}
            dispatchMethod={true}
            getDataMethod={organisationId ? getPaymentArrangements : null}
            isSearching={isSearching}
            loading={loadingPaymentArrangements || loadingOrganisationCurrencies}
            localeMessage={
                currentPaymentPlanType === 'arrangement'
                    ? TABLE_NO_PAYMENT_ARRANGEMENTS_FOUND_MESSAGE
                    : TABLE_NO_PAYMENT_EXTENSIONS_FOUND_MESSAGE
            }
            mobileClassName="table-1200"
            paginationData={paymentArrangementsPagination}
            rowClassName="pointer"
            rowKey="id"
            searchParam={searchParam}
            setIsSearching={setIsSearching}
            size="middle"
            customDataValues={{
                contact_id: props.contactId,
                invoice_id: props.invoiceId,
                payment_plan_id: props.paymentPlanId,
                plan_type: currentPaymentPlanType,
            }}
        />
    );

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
            {props.showTitle ? (
                <>
                    <h2 className="page-title">
                        {t(
                            `titleAndMetas.${
                                currentPaymentPlanType === 'arrangement' ? 'paymentArrangement' : 'paymentExtension'
                            }.title`
                        )}
                    </h2>
                    <div className="tab-arrow-link breadcrumb-spec">
                        <Breadcrumb>
                            <PaymentArrangementBreadCrumbItems showListBreadCrumb={isAdmin} />
                        </Breadcrumb>
                    </div>
                </>
            ) : null}
            <div
                className={`search-filter-export-wrapper contact-list-container full-wrapper box-wrapper ${
                    !props.showTitle ? props.customClasses : 'white-box-wrapper'
                }`}
            >
                <ul className="selector-field show-elem invoices-wrapper">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>
                        <div className="invoices-search">
                            <SearchBlock
                                key={currentPaymentPlanType}
                                dataValues={[organisationId]}
                                dispatchMethod={true}
                                getDataMethod={organisationId ? getPaymentArrangements : null}
                                isSearching={isSearching}
                                placeholder={t(`messages.paymentArrangement.arrangement.search.placeholder`, {
                                    tabType: currentPaymentPlanType,
                                })}
                                setIsSearching={setIsSearching}
                                setSearchParam={setSearchParam}
                                customDataValues={{
                                    contact_id: props.contactId,
                                    invoice_id: props.invoiceId,
                                    payment_plan_id: props.paymentPlanId,
                                    plan_type: currentPaymentPlanType,
                                }}
                            />
                        </div>
                    </li>
                </ul>
                {loadingOrganisationCurrencies ? <Loader /> : renderTable()}
            </div>
            <InvoicesListModal
                paymentArrangementId={selectedPaymentArrangementId}
                isOpen={Boolean(selectedPaymentArrangementId)}
                planType={currentPaymentPlanType}
                onClose={() => setSelectedPaymentArrangementId('')}
            />
        </div>
    );
};

PaymentArrangementsList.defaultProps = {
    contactId: '',
    currentPaymentPlanType: 'arrangement',
    customClasses: '',
    invoiceId: '',
    paymentPlanId: '',
    showTitle: true,
};

PaymentArrangementsList.propTypes = {
    contactId: PropTypes.string,
    currentPaymentPlanType: PropTypes.string,
    customClasses: PropTypes.string,
    invoiceId: PropTypes.string,
    paymentPlanId: PropTypes.string,
    planType: PropTypes.string,
    showTitle: PropTypes.bool,
};

export default PaymentArrangementsList;

/**
 * Inventory Item Form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import Button from 'components/Button';
import FormField from 'components/shared/FormField';
import useChartOfAccounts from 'includes/hooks/useChartOfAccounts';
import useTaxes from 'includes/hooks/useTaxes';

/**
 * Max length validation
 *
 * @returns {Function} Validation function
 */
const maxLength7 = formValidations.maxLength(7);

/**
 * Min value 0 validation
 *
 * @returns {Function} Validation function
 */
const minValue0 = formValidations.minValue(0);

const InventoryItemForm = props => {
    const { t } = useTranslation();
    const loadingInventoryItemAddUpdate = useSelector(state => state.inventory.loadingInventoryItemAddUpdate);

    const { data: taxes } = useTaxes();
    const { data: chartOfAccounts } = useChartOfAccounts(false, 2);

    /**
     * Get the localised label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.inventory.addEdit.form.input.label.${field}`);

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.INVENTORY_ITEM_NAME}
                            label={getLocaleLabel(requestKeys.INVENTORY_ITEM_NAME)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.INVENTORY_ITEM_CODE}
                            label={getLocaleLabel(requestKeys.INVENTORY_ITEM_CODE)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="textarea"
                            name={requestKeys.INVENTORY_ITEM_DESCRIPTION}
                            label={getLocaleLabel(requestKeys.INVENTORY_ITEM_DESCRIPTION)}
                            hasFeedback
                            component={FormField}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="textarea"
                            name={requestKeys.INVENTORY_ITEM_SALES_DESCRIPTION}
                            label={getLocaleLabel(requestKeys.INVENTORY_ITEM_SALES_DESCRIPTION)}
                            hasFeedback
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="number"
                            name={requestKeys.INVENTORY_ITEM_UNIT_SELLING_PRICE}
                            label={getLocaleLabel(requestKeys.INVENTORY_ITEM_UNIT_SELLING_PRICE)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required, formValidations.number]}
                            step="any"
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            className="form-control with-pre-tab"
                            name={requestKeys.INVENTORY_ITEM_DISCOUNT}
                            label={getLocaleLabel(requestKeys.INVENTORY_ITEM_DISCOUNT)}
                            type="number"
                            addonBefore={
                                <Field
                                    name={requestKeys.INVENTORY_ITEM_DISCOUNT_TYPE}
                                    type="select"
                                    component={FormField}
                                    disableEmptyOption
                                    options={constants.getDiscountTypeOptions().map(o => ({
                                        name: o.label,
                                        value: o.id,
                                    }))}
                                />
                            }
                            validate={[minValue0, formValidations.number]}
                            component={FormField}
                            min={0}
                            step="any"
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.INVENTORY_ITEM_UNIT_OF_MEASURE}
                            label={getLocaleLabel(requestKeys.INVENTORY_ITEM_UNIT_OF_MEASURE)}
                            hasFeedback
                            component={FormField}
                            maxLength="7"
                            validate={[maxLength7]}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.EXTERNAL_ID}
                            label={getLocaleLabel(requestKeys.EXTERNAL_ID)}
                            hasFeedback
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="select"
                            name={requestKeys.INVENTORY_ITEM_SALES_ACCOUNT_ID}
                            options={chartOfAccounts.map(x => ({ value: x.id, name: x.name }))}
                            label={getLocaleLabel(requestKeys.INVENTORY_ITEM_SALES_ACCOUNT_ID)}
                            component={FormField}
                            hasFeedback
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="select"
                            name={requestKeys.INVENTORY_ITEM_SALES_TAX_RATE_ID}
                            label={getLocaleLabel(requestKeys.INVENTORY_ITEM_SALES_TAX_RATE_ID)}
                            options={taxes.map(t => ({ name: t.name, value: t.id }))}
                            component={FormField}
                        />
                    </Col>
                </Row>
            </Form>
            <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={loadingInventoryItemAddUpdate}
                        submittingButtonLabel={t(
                            `messages.inventory.addEdit.form.submit.buttonSubmittingLabel.${
                                props.isAdd ? 'add' : 'edit'
                            }`
                        )}
                        className="register-btn"
                    >
                        {t(`messages.inventory.addEdit.form.submit.buttonLabel.${props.isAdd ? 'add' : 'edit'}`)}
                    </Button>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
};

/**
 * Proptypes
 */
InventoryItemForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isAdd: PropTypes.bool,
};

export default reduxForm({
    form: constants.INVENTORY_FORM_NAME,
})(InventoryItemForm);

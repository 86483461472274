import { Icon } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const COUNTRY_CODE_GO_CARDLESS_PRICING_URL_MAP = {
    AU: 'https://gocardless.com/en-au/pricing/',
    CA: 'https://gocardless.com/en-ca/pricing/',
    DK: 'https://gocardless.com/da-dk/priser/',
    FR: 'https://gocardless.com/fr-fr/tarifs/',
    DE: 'https://gocardless.com/de-de/preise/',
    IE: 'https://gocardless.com/en-ie/pricing/',
    NL: 'https://gocardless.com/nl-nl/pricing/',
    NZ: 'https://gocardless.com/en-nz/pricing/',
    ES: 'https://gocardless.com/es-es/tarifas/',
    SE: 'https://gocardless.com/sv-se/prissattning/',
    US: 'https://gocardless.com/en-us/pricing/',
    GB: 'https://gocardless.com/pricing/',
};

/**
 * GoCardless OAuth connect modal description component
 *
 * @since 2.8.0
 */
export default function GoCardlessConnectDescription() {
    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);

    const goCardlessPros = useMemo(() => {
        let goCardlessPricingLink =
            COUNTRY_CODE_GO_CARDLESS_PRICING_URL_MAP[selectedOrganisation?.country?.code] ??
            COUNTRY_CODE_GO_CARDLESS_PRICING_URL_MAP.GB; // Default to UK if no match found

        return [
            {
                description: (
                    <>
                        Save on transaction costs with domestic transaction fees. See our{' '}
                        <a href={goCardlessPricingLink} target="_blank" rel="noreferrer">
                            pricing page
                        </a>{' '}
                        for details.
                    </>
                ),
                icon: <Icon type="dollar" style={{ fontSize: 30 }} />,
                title: 'Low cost',
            },
            {
                description: <>Collect payments automatically for your invoices as soon as they&apos;re due.</>,
                icon: <Icon type="calendar" style={{ fontSize: 30 }} />,
                title: 'Get paid on time',
            },
            {
                description: <>Easy bank reconciliation with GoCardless.</>,
                icon: <Icon type="user-delete" style={{ fontSize: 30 }} />,
                title: 'Reduce admin',
            },
        ];
    }, [selectedOrganisation]);

    return (
        <div className="flex flex-col gap-3">
            {goCardlessPros.map(pro => (
                <div key={pro.title} className="flex items-center">
                    <div className="p-4">{pro.icon}</div>
                    <div>
                        <h4>{pro.title}</h4>
                        <div className="mt-0">{pro.description}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

/**
 * Scrollable table Component
 * This is a reusable component which can be used to render scrollable data in a table
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

import {
    React,
    PropTypes,
    useMemo,
    useCallback,
    useState,
    useEffect,
    useRef,
    withTranslation,
} from '../../../includes/exports/react';
import * as requestKeys from '../../../includes/constants/keys/request';
import Loader from '../Loader';
import MobileFilterSortView from './MobileFilterSortView';
import MobileTableCollapse from './MobileTableCollapse';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import { get, isEmpty, isFunction, isObject, remove } from 'lodash';
import { Pagination } from 'antd';
import { useDispatch } from 'react-redux';

const SORT_DIRECTION = {
    0: 'descend',
    1: 'ascend',
};

const ScrollableTable = props => {
    const { setTableFilters, showSizeChanger, pageSizeOptions, isStatic, filterEmpty, defaultColumnFilters } = props;
    const { currentPage, pageSize, totalCount, totalPages } = props.paginationData;

    const [activeFilterPanel, setActiveFilterPanel] = useState('');

    const [filters, setFilters] = useState(defaultColumnFilters || {});
    const [rangeFilters, setRangeFilters] = useState({});

    const [sortField, setSortField] = useState('');

    const [sortOrder, setSortOrder] = useState(1);
    const [showSelectAllMessage, setShowSelectAllMessage] = useState(false);

    const ref = useRef(null);
    const dispatch = useDispatch();

    const mobFilterRef = useRef(null);
    const {
        rowSelection,
        columns,
        dataSource,
        rowKey,
        searchRowKey,
        search,
        loading,
        localeMessage,
        enableSelectAll,
        selectAllCustomMessage,
    } = props;

    // handle closing of filter popover
    const handleClickOutside = event =>
        [ref].reduce((acc, currentVal) => {
            return acc && currentVal.current && !currentVal.current.contains(event.target);
        }, true) && setActiveFilterPanel('');

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const { searchParam, getDataMethod, dataValues, customDataValues } = props;
    const handleTableChange = (page = 1) => {
        // if the search is already initiated return
        if (props.isSearching) {
            if (isFunction(props.setIsSearching)) {
                props.setIsSearching(false);
            }
            return true;
        }

        let data = {};
        data[requestKeys.PAGE] = page;

        // if the hasSearched state is true, add the search param to the data
        if (searchParam) {
            data['search_param'] = searchParam;
        }

        if (sortField) {
            data.sort_field = sortField;
            data.sort_order = SORT_DIRECTION[sortOrder];
        }

        // add the filters
        if (filters) {
            Object.entries(filters).forEach(([key, value]) => {
                data[key] = value;
            });
        }

        // add the range filters
        if (rangeFilters) {
            // eslint-disable-next-line no-unused-vars
            Object.entries(rangeFilters).forEach(([mainKey, values]) => {
                if (isObject(values)) {
                    Object.entries(values).forEach(([key, value]) => {
                        data[key] = value;
                    });
                }
            });
        }

        if (customDataValues) {
            data = { ...data, ...customDataValues };
        }

        if (setTableFilters && isFunction(setTableFilters)) {
            setTableFilters(data);
        }

        if (!isStatic) {
            if (getDataMethod) {
                let functionParams = filterEmpty ? dataValues.filter(el => !!el) : dataValues;
                if (props.dispatchMethod) {
                    dispatch(getDataMethod(...functionParams, data));
                } else {
                    getDataMethod(...functionParams, data);
                }

                // reset the row selection
                if (props.rowSelection) {
                    props.rowSelection.onChange([]);
                }

                if (enableSelectAll) {
                    setShowSelectAllMessage(false);
                }
            }
        }
    };

    // effect to handle row selection changes
    useEffect(() => {
        if (rowSelection) {
            if (isEmpty(rowSelection.selectedRowKeys) && enableSelectAll) {
                setShowSelectAllMessage(false);
            }
        }
    }, [rowSelection]); // eslint-disable-line react-hooks/exhaustive-deps

    // effect to fetch table data when page or any filters is changed
    useEffect(() => {
        handleTableChange();
    }, [filters, rangeFilters, sortOrder, sortField, searchParam, isStatic]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the pagination data for the table
     *
     * @returns  {object|boolean}    Table Pagination Data if total number of pages greater than one else false
     */
    const getPaginationData = useCallback(() => {
        const paginationData = {
            position: 'both',
            onChange: handleTableChange,
        };

        if (totalPages > 1) {
            return {
                total: totalCount,
                current: currentPage,
                pageSize: pageSize,
                showSizeChanger: showSizeChanger,
                pageSizeOptions: pageSizeOptions,
                ...paginationData,
            };
        } else if (showSizeChanger) {
            return {
                pageSize: pageSize,
                showSizeChanger: showSizeChanger,
                pageSizeOptions: pageSizeOptions,
                ...paginationData,
            };
        } else {
            return false;
        }
    }, [totalPages, totalCount, currentPage, pageSize, showSizeChanger, pageSizeOptions]); // eslint-disable-line react-hooks/exhaustive-deps

    const paginationData = useMemo(() => getPaginationData(), [getPaginationData]);

    const filterColumns = useMemo(() => columns.filter(c => c.filters), [columns]);

    const sorterColumns = useMemo(() => columns.filter(c => c.sorter), [columns]);

    const collapseHeaderKeys = useMemo(() => columns.filter(c => c.collapseHeader).map(c => c.dataIndex), [columns]);

    const rangFilterColumns = useMemo(() => columns.filter(c => c.rangeFilters), [columns]);

    // get all record keys
    const getAllKeys = useCallback(() => dataSource.map(data => data.id), [dataSource]);

    const allkeys = useMemo(() => getAllKeys(), [getAllKeys]);

    // select all rows
    const selectAllRows = () => {
        if (totalCount > pageSize) {
            setShowSelectAllMessage(true);
        }
        return props.rowSelection.onChange(allkeys);
    };

    // filter table data based on search
    const getTableData = useCallback(
        () =>
            searchRowKey
                ? dataSource.filter(data =>
                      get(data, [searchRowKey], '')
                          .toLowerCase()
                          .includes(search.toLowerCase())
                  )
                : dataSource,
        [dataSource, searchRowKey, search]
    );

    const tableData = useMemo(() => getTableData(), [getTableData]);

    const handleCheckboxClick = e => {
        let currentSelectedRowKeys = rowSelection.selectedRowKeys;
        if (enableSelectAll) {
            setShowSelectAllMessage(false);
            currentSelectedRowKeys = remove(currentSelectedRowKeys, function(n) {
                return n !== 'all';
            });
        }
        let newSelectedRowKeys = [...currentSelectedRowKeys, e.target.id];
        if (!e.target.checked) {
            newSelectedRowKeys = newSelectedRowKeys.filter(x => x !== e.target.id);
        }
        rowSelection.onChange && rowSelection.onChange(newSelectedRowKeys);
    };

    const getCommonProps = () => ({
        filters,
        rangeFilters,
        setFilters,
        setRangeFilters,
        sortField,
        setSortField,
        sortOrder,
        setSortOrder,
        activeFilterPanel,
        setActiveFilterPanel,
    });

    /**
     * Clear all selected items
     **/
    const clearSelectedItems = () => {
        if (enableSelectAll && showSelectAllMessage) {
            props.rowSelection.onChange([]);
            setShowSelectAllMessage(false);
        }
    };

    /**
     * Get the total number of items in the page
     * */
    const getItemsInPage = () => dataSource.length;

    /**
     * Check if the all items across pages are selected
     **/
    const hasSelectedAllItems = () =>
        enableSelectAll && showSelectAllMessage && rowSelection.selectedRowKeys.includes('all');

    /**
     * Select all items across all pages
     **/
    const selectAllItems = () => {
        enableSelectAll && showSelectAllMessage && props.rowSelection.onChange([...allkeys, 'all']);
    };

    return (
        <section className="cs-table-wrapper">
            <MobileFilterSortView
                filterColumns={filterColumns}
                rangFilterColumns={rangFilterColumns}
                sorterColumns={sorterColumns}
                activeFilterPanel={activeFilterPanel}
                mobFilterRef={mobFilterRef}
                mobileClassName={props.mobileClassName}
                {...getCommonProps()}
            />
            <div className="custom-scrollble-table">
                {paginationData && !loading && (
                    <Pagination size="small" {...paginationData} className="table-pagination" />
                )}
                {loading ? ( // To show empty table with loader
                    <>
                        <table className={`full ${props.className}`}>
                            <TableHeader
                                rowSelection={rowSelection}
                                allkeys={allkeys}
                                columns={columns}
                                dataLength={dataSource.length}
                                selectAllRows={selectAllRows}
                                forwardRef={ref}
                                {...getCommonProps()}
                            />
                        </table>
                        <Loader />
                    </>
                ) : (
                    <>
                        <span className="select-all-items-pages">
                            {enableSelectAll && showSelectAllMessage && totalPages > 1 && (
                                <>
                                    {hasSelectedAllItems() ? (
                                        <>
                                            <span>
                                                {props.t('sharedMessages.table.selectedAllItems', {
                                                    totalItems: totalCount,
                                                })}
                                            </span>
                                            <span onClick={clearSelectedItems} className="select-all-items">
                                                {props.t('sharedMessages.table.clearSelection')}
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span>
                                                {props.t('sharedMessages.table.selectAll', {
                                                    itemsInPage: getItemsInPage(),
                                                })}
                                            </span>
                                            <span onClick={selectAllItems} className="select-all-items">
                                                {props.t('sharedMessages.table.selectAllItems', {
                                                    totalItems: totalCount,
                                                })}
                                            </span>
                                        </>
                                    )}
                                    {selectAllCustomMessage && (
                                        <span className="select-all-custom-message">{selectAllCustomMessage}</span>
                                    )}
                                </>
                            )}
                        </span>
                        <table
                            className={`full ${props.className} ${!props.showMobileTableView ? 'no-mobile-view' : ''}`}
                        >
                            <TableHeader
                                rowSelection={rowSelection}
                                allkeys={allkeys}
                                columns={columns}
                                dataLength={dataSource.length}
                                selectAllRows={selectAllRows}
                                setShowSelectAllMessage={setShowSelectAllMessage}
                                forwardRef={ref}
                                {...getCommonProps()}
                            />
                            <TableBody
                                handleCheckboxClick={handleCheckboxClick}
                                tableData={tableData}
                                isStatic={isStatic}
                                columns={columns}
                                rowKey={rowKey}
                                rowSelection={rowSelection}
                                localeMessage={localeMessage}
                            />
                        </table>
                    </>
                )}

                {/* Mobile view begin */}
                {props.showMobileTableView ? (
                    <MobileTableCollapse
                        columns={columns}
                        loading={loading}
                        tableData={tableData}
                        rowKey={rowKey}
                        rowSelection={rowSelection}
                        collapseHeaderKeys={collapseHeaderKeys}
                        handleCheckboxClick={handleCheckboxClick}
                        localeMessage={localeMessage}
                        mobileClassName={props.mobileClassName}
                        {...getCommonProps()}
                    />
                ) : null}
                {/* Mobile view end */}
                {paginationData && !loading && (
                    <Pagination size="small" {...paginationData} className="table-pagination" />
                )}
            </div>
        </section>
    );
};

/**default props */
ScrollableTable.defaultProps = {
    className: '',
    customDataValues: {},
    defaultColumnFilters: {},
    dataValues: [],
    filterEmpty: true,
    isSearching: false,
    isStatic: false,
    localeMessage: 'No data available.',
    mobileClassName: '',
    pagination: false,
    paginationData: {},
    search: '',
    selectAllCustomMessage: '',
    showMobileTableView: true,
};

/**PropTypes validation */
ScrollableTable.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.array.isRequired,
    currentPage: PropTypes.number,
    customDataValues: PropTypes.object,
    defaultColumnFilters: PropTypes.object,
    dataSource: PropTypes.array.isRequired,
    dataValues: PropTypes.array,
    dispatchMethod: PropTypes.bool,
    enableSelectAll: PropTypes.bool,
    filterEmpty: PropTypes.bool,
    forwardRef: PropTypes.object,
    getDataMethod: PropTypes.func,
    isSearching: PropTypes.bool,
    isStatic: PropTypes.bool,
    loading: PropTypes.bool,
    localeMessage: PropTypes.node,
    mobileClassName: PropTypes.string,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.array,
    pagination: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    paginationData: PropTypes.object,
    rowKey: PropTypes.string.isRequired,
    rowSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    search: PropTypes.string,
    searchParam: PropTypes.string,
    searchRowKey: PropTypes.string,
    selectAllCustomMessage: PropTypes.string,
    setIsSearching: PropTypes.func,
    showMobileTableView: PropTypes.bool,
    setSearchParam: PropTypes.func,
    setTableFilters: PropTypes.func,
    showSizeChanger: PropTypes.bool,
    t: PropTypes.func,
    totalCount: PropTypes.number,
    totalPages: PropTypes.number,
};

export default withTranslation()(ScrollableTable);

/**
 * On Demand campaign modal component
 *
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 * @version 1.0
 */

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Modal } from 'antd';

import OnDemandCampaign from './components/Form';
import './styles.scss';

const OnDemandCampaignModal = props => {
    const {
        contactCC,
        contactIds,
        contactNames,
        customSubmitHandler,
        fillDefaultTemplateDetails,
        getFilterParams,
        getToText,
        initialValues,
        invoiceIds,
        setInvoiceIds,
        setShowOnDemandCampaignModal,
        showOnDemandCampaignModal,
        templateTypes,
        totalCount,
        sendButtonText,
    } = props;

    return (
        <Modal
            footer={[]}
            className="my_profile_modal organisation-reauthorization-modal on-demand-campaign-modal"
            visible={showOnDemandCampaignModal}
            onCancel={() => setShowOnDemandCampaignModal(false)}
        >
            {isEmpty(invoiceIds) ? (
                'Please select at-least one invoice'
            ) : (
                <OnDemandCampaign
                    contactCC={contactCC}
                    contactIds={contactIds}
                    contactNames={contactNames}
                    customSubmitHandler={customSubmitHandler}
                    fillDefaultTemplateDetails={fillDefaultTemplateDetails}
                    getFilterParams={getFilterParams}
                    getToText={getToText}
                    initialValues={initialValues}
                    invoiceIds={invoiceIds}
                    setInvoiceIds={setInvoiceIds}
                    setShowOnDemandCampaignModal={setShowOnDemandCampaignModal}
                    templateTypes={templateTypes}
                    totalCount={totalCount}
                    sendButtonText={sendButtonText}
                />
            )}
        </Modal>
    );
};

OnDemandCampaignModal.propTypes = {
    contactCC: PropTypes.arrayOf(PropTypes.object),
    contactIds: PropTypes.arrayOf(PropTypes.string),
    contactNames: PropTypes.arrayOf(PropTypes.string),
    customSubmitHandler: PropTypes.func,
    fillDefaultTemplateDetails: PropTypes.bool,
    getFilterParams: PropTypes.func.isRequired,
    getToText: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    invoiceIds: PropTypes.array,
    sendButtonText: PropTypes.string,
    setInvoiceIds: PropTypes.func,
    setShowOnDemandCampaignModal: PropTypes.func,
    showOnDemandCampaignModal: PropTypes.bool,
    templateTypes: PropTypes.arrayOf(PropTypes.object),
    totalCount: PropTypes.number,
};

export default OnDemandCampaignModal;

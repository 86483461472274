/**
 * Import Component
 * Handles import of contacts, contact managers, invoices and invoice payments
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React, { useEffect, useState } from 'react';
import Button from '../Button';
import Loader from '../Loader';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { get, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { Steps, Icon, Breadcrumb, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { getImportTemplateDownloadLink, importFile, resetImportErrors } from '../../includes/slices/imports';
import {
    IMPORT_TYPE_CONTACT_MANAGERS,
    IMPORT_TYPE_CONTACTS,
    IMPORT_TYPE_INVOICE_PAYMENTS,
    IMPORT_TYPE_INVOICES,
} from '../../includes/constants';
import { useUpdateBusinessName, useCheckEditPermission } from '../../includes/utils/hooks';
import './styles.scss';

const { confirm } = Modal;
const { Step } = Steps;

/**
 * Import
 * Renders the import
 */
const Import = props => {
    // set the initial state
    const [currentStep, setCurrentStep] = useState(0);

    const dispatch = useDispatch();

    // get the values from state
    const organisationId =
        useSelector(state => state.organisation.selectedOrganisationId) || props.match.params.organisationId;
    const organisation = useSelector(state => state.organisation.selectedOrganisation);
    const importErrors = useSelector(state => state.imports.importErrors);
    const loadingImportFile = useSelector(state => state.imports.loadingImportFile);
    const loadingImportTemplateDownloadLink = useSelector(state => state.imports.loadingImportTemplateDownloadLink);
    const siteSettings = useSelector(state => state.settings.siteSettings);
    const isAdmin = useSelector(state => state.account.isAdmin);
    const { accountId } = props.match.params;

    useUpdateBusinessName();
    useCheckEditPermission();

    /**
     * Reset form errors on load
     */
    useEffect(() => {
        dispatch(resetImportErrors());
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Show the import errors
     */
    const showImportErrors = !isEmpty(importErrors) && (
        <div className="import-error-wrapper">
            {ReactHtmlParser(props.t(`customerMessages.import.errorHeading`, { errorCount: importErrors.length }))}
            <>
                <Icon type="warning" />
                <div className="error-details import-errors">
                    <ul>
                        {importErrors.map((value, index) => (
                            <li key={index}>{ReactHtmlParser(value)}</li>
                        ))}
                    </ul>
                </div>
            </>
        </div>
    );

    /**
     * Download the import the template download link
     */
    const downloadTemplateDownloadLink = () => {
        let importType = '';

        switch (props.action) {
            case 'contact-managers':
                importType = IMPORT_TYPE_CONTACT_MANAGERS;
                break;
            case 'contacts':
                importType = IMPORT_TYPE_CONTACTS;
                break;
            case 'invoice-payments':
                importType = IMPORT_TYPE_INVOICE_PAYMENTS;
                break;
            case 'invoices':
                importType = IMPORT_TYPE_INVOICES;
                break;
            default:
                break;
        }

        if (importType) {
            dispatch(getImportTemplateDownloadLink(importType));
        }
    };

    /**
     * Get the redirect URL after upload
     */
    const getRedirectUrl = () => {
        let redirectUrl = '';

        if (props.action === IMPORT_TYPE_CONTACT_MANAGERS) {
            redirectUrl = isAdmin
                ? `/admin/accounts/organisation/contact-managers/${accountId}/${organisationId}`
                : '/contact-managers';
        } else if (props.action === IMPORT_TYPE_CONTACTS) {
            redirectUrl = isAdmin
                ? `/admin/accounts/organisation/contacts/${accountId}/${organisationId}`
                : '/contacts';
        } else if (props.action === IMPORT_TYPE_INVOICE_PAYMENTS) {
            redirectUrl = isAdmin
                ? `/admin/accounts/organisation/payments/${accountId}/${organisationId}`
                : '/payments';
        } else if (props.action === IMPORT_TYPE_INVOICES) {
            redirectUrl = isAdmin
                ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}`
                : '/invoices';
        }

        return redirectUrl;
    };

    /**
     * Handles the file change
     */
    const handleFileChange = e => {
        const data = new FormData();

        let importType = '';
        let fileKey = '';
        let redirectUrl = getRedirectUrl();

        if (props.action === IMPORT_TYPE_CONTACT_MANAGERS) {
            fileKey = 'contact_managers_upload_file';
            importType = IMPORT_TYPE_CONTACT_MANAGERS;
        } else if (props.action === IMPORT_TYPE_CONTACTS) {
            fileKey = 'contacts_upload_file';
            importType = IMPORT_TYPE_CONTACTS;
        } else if (props.action === IMPORT_TYPE_INVOICE_PAYMENTS) {
            fileKey = 'invoice_payments_upload_file';
            importType = IMPORT_TYPE_INVOICE_PAYMENTS;
        } else if (props.action === IMPORT_TYPE_INVOICES) {
            fileKey = 'invoices_upload_file';
            importType = IMPORT_TYPE_INVOICES;
        }

        data.append(fileKey, e.target.files[0]);

        if (importType && fileKey) {
            confirm({
                title: props.t(`customerMessages.import.confirm`, {
                    importType: props.t(`customerMessages.import.${props.action}.label`, { count: 0 }),
                    organisationName: get(
                        organisation,
                        'legal_name',
                        get(organisation, 'name', props.t('customerMessages.import.currentOrganisation'))
                    ),
                }),
                content: '',
                onOk: () => {
                    dispatch(importFile(organisationId, data, importType, redirectUrl));
                },
            });
        }
    };

    /**
     * Set the Steps
     */
    const steps = [
        {
            title: props.t(`customerMessages.import.step1.title`),
            content: (
                <div className="import-step-content">
                    <header>{props.t(`customerMessages.import.step1.header`)}</header>
                    <p>{props.t(`customerMessages.import.step1.info`)}</p>
                    <Button
                        loading={loadingImportTemplateDownloadLink}
                        submittingButtonLabel={props.t(`customerMessages.import.step1.buttonLabel.downloading`)}
                        onClick={() => downloadTemplateDownloadLink()}
                    >
                        <Icon type="file-text" /> {props.t(`customerMessages.import.step1.buttonLabel.download`)}
                    </Button>
                </div>
            ),
        },
        {
            title: props.t(`customerMessages.import.step2.title`),
            content: (
                <div className="import-step-content">
                    <header>
                        {props.t(`customerMessages.import.step2.header`, {
                            content: props.t(`customerMessages.import.${props.action}.label`, { count: 0 }),
                        })}
                    </header>
                    <p>
                        {props.t(`customerMessages.import.step2.info`, {
                            content: props.t(`customerMessages.import.${props.action}.label`, { count: 0 }),
                        })}
                    </p>
                </div>
            ),
        },
        {
            title: props.t(`customerMessages.import.step3.title`),
            content: (
                <div className="import-step-content">
                    <header>{props.t(`customerMessages.import.step3.header`)}</header>
                    <p>{props.t(`customerMessages.import.step3.info`)}</p>
                    <p style={{ fontSize: 16 }}>
                        {ReactHtmlParser(
                            props.t(`customerMessages.import.step3.additionalInfo.info1`, {
                                content: props.t(`customerMessages.import.${props.action}.label`, { count: 1 }),
                                matchingType: props.t(`customerMessages.import.matchingType.${props.action}`),
                            })
                        )}
                    </p>
                    <p style={{ fontSize: 16 }}>
                        {props.t(`customerMessages.import.step3.additionalInfo.info2`, {
                            content: props.t(`customerMessages.import.${props.action}.label`, { count: 1 }),
                        })}
                    </p>
                    <p style={{ fontWeight: 'bold', fontSize: 16 }}>
                        {ReactHtmlParser(
                            props.t(`customerMessages.import.step3.additionalInfo.info3`, {
                                maxImportRecords: get(siteSettings, 'max_import_records'),
                            })
                        )}
                    </p>
                    <input
                        type="file"
                        id="upload_file"
                        accept=".xlsx"
                        onClick={e => {
                            e.target.value = null;
                        }}
                        onChange={e => handleFileChange(e)}
                    />
                    <Icon type="upload" />
                    <label htmlFor="upload_file" className="upload-btn">
                        {props.t(`customerMessages.import.chooseFile`)}
                    </label>
                </div>
            ),
        },
    ];

    return (
        <div className="home-content-wrapper">
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={getRedirectUrl()}>
                            {props.t(`customerMessages.import.breadcrumb.${props.action}.title`)}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {props.t(`customerMessages.import.breadcrumb.${props.action}.import`)}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h2 className="page-title">{props.t(`customerMessages.import.${props.action}.title`)}</h2>
            <section className="white-box-wrapper mid-wrapper">
                {loadingImportFile ? (
                    <div>
                        <p style={{ fontWeight: 'bold', fontSize: 16, marginBottom: 15 }}>
                            {props.t(`customerMessages.import.importingFile`, {
                                importType: props.t(`customerMessages.import.${props.action}.label`, { count: 0 }),
                            })}
                        </p>
                        <Loader />
                    </div>
                ) : (
                    <div>
                        <Steps current={currentStep}>
                            {steps.map(item => (
                                <Step key={item.title} title={item.title} />
                            ))}
                        </Steps>
                        <div className="steps-content">{steps[currentStep].content}</div>
                        {importErrors ? <div>{showImportErrors}</div> : ''}
                        <div className="steps-action">
                            {currentStep > 0 && (
                                <Button
                                    className="green-bg-button"
                                    big
                                    filled
                                    onClick={() => setCurrentStep(currentStep - 1)}
                                >
                                    {props.t(`customerMessages.import.previous`)}
                                </Button>
                            )}
                            {currentStep < steps.length - 1 && (
                                <Button
                                    className="green-bg-button mrgn-left-15"
                                    big
                                    onClick={() => setCurrentStep(currentStep + 1)}
                                >
                                    {props.t(`customerMessages.import.next`)}
                                </Button>
                            )}
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
};

/**
 * Prop types
 */
Import.propTypes = {
    action: PropTypes.string,
    match: PropTypes.object,
    t: PropTypes.func,
};

export default withTranslation()(Import);

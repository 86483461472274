/**
 * Dashboard API class.
 * Handles all api requests related to admin dashboard
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class DashboardApi extends BaseApiHandler {
    /**
     * Call the accounts listing API
     *
     * @returns {object} API Response
     * */
    getPlanData = () => this.axios.get(this.getApiUrl('GET_ADMIN_PLAN_DATA'));
}

export default new DashboardApi();

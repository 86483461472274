/**
 * Accounts component
 * Handles the accounts view and functions related to accounts
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

// import required modules
import {
    React,
    connect,
    PropTypes,
    Link,
    useCallback,
    useMemo,
    useState,
    withTranslation,
} from '../../../includes/exports/react';
import * as errorMessages from '../../../includes/constants/messages/errors';
import SearchBlock from '../../shared/SearchBlock';
import Table from '../../shared/ScrollableTable';
import { getAccountListColumns } from '../../../includes/constants/columns';
import { exportAccountOrganisations } from '../../../includes/redux/actions/customer/organisation';
import { get, find } from 'lodash';
import { getAccounts, getAccountTypes } from '../../../includes/redux/actions/shared/account';
import { getDefaultImageClass, getImageFromData, redirect } from '../../../includes/utils';
import { showAppNotificationMessage } from '../../../includes/redux/actions/shared/appNotification';
import { useDataFromStore } from '../../../includes/utils/hooks';
import './styles.scss';
import ExportButton from '../../shared/Exports/button';
import Button from '../../shared/Button';
import { deleteAccount } from 'includes/slices/account';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/Loader';

/**
 * Render the accounts list
 */
const Accounts = props => {
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const accountLogo45Key = 'ACCOUNT_LOGO_45X45';
    const accountLogoOriginalKey = 'ACCOUNT_LOGO_ORIGINAL';
    const { accounts } = props;
    const [accountTypes] = useDataFromStore({
        reducer: 'account',
        dataKey: 'accountTypes',
        loadingKey: 'isTypesLoading',
        action: getAccountTypes,
    });

    const dispatch = useDispatch();

    const handleDeleteAccount = accountId => {
        Modal.confirm({
            title: 'Are you sure you wish to delete the account?',
            content: 'You cannot recover this account once its deleted.',
            okText: 'Delete',
            onOk: () => {
                dispatch(
                    deleteAccount(accountId, () =>
                        props.getAccounts({
                            type: 'customer-account',
                        })
                    )
                );
            },
        });
    };

    const isDeletingAccount = useSelector(state => state.account.isDeletingAccount);

    /**
     * Format the accounts data
     */
    const formatAccountsData = useCallback(() => {
        return accounts.map(account => ({
            ...account,
            name: (
                <>
                    <div className="table-name-align">
                        <img
                            className={
                                getDefaultImageClass(account, accountLogo45Key, accountLogoOriginalKey, '45x45') +
                                ' logo logo-45 '
                            }
                            src={getImageFromData(account, accountLogo45Key, accountLogoOriginalKey)}
                            alt={account.name}
                        />
                        <div className="wrapped-content-text">
                            <span className="wrapped-content-text crud-wrapper">{account.name}</span>
                            <span className="crud-wrapper">
                                <Link className="crud-link" to={`/admin/accounts/${account.id}`}>
                                    Details
                                </Link>
                                <Link className="crud-link" to={`/admin/accounts/billings/${account.id}`}>
                                    Billings
                                </Link>
                                {get(find(accountTypes, { id: account.type }), 'slug') === 'customer-account' && (
                                    <Link className="crud-link" to={`/admin/accounts/orders/${account.id}`}>
                                        Orders
                                    </Link>
                                )}
                                <Link className="crud-link" to={`/admin/accounts/organisations/${account.id}`}>
                                    Organisation
                                </Link>
                                <Link className="crud-link" to={`/admin/accounts/user/${account.id}`}>
                                    Users
                                </Link>
                                {get(find(accountTypes, { id: account.type }), 'slug') === 'customer-account' && (
                                    <>
                                        <ExportButton type="link" exportType="account" accountId={account.id} />
                                        <Link className="crud-link" to={`/admin/accounts/audit-logs/${account.id}`}>
                                            Account Audit Logs
                                        </Link>
                                    </>
                                )}

                                <span
                                    className="crud-link"
                                    onClick={() => {
                                        handleDeleteAccount(account.id);
                                    }}
                                >
                                    Delete
                                </span>
                            </span>
                        </div>
                    </div>
                </>
            ),
            type: get(find(accountTypes, { id: account.type }), 'type'),
        }));
    }, [accounts, accountTypes]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the memoized accounts data
     */
    const memoizedAccountsData = useMemo(() => formatAccountsData(), [formatAccountsData]);

    return (
        <div className="home-content-wrapper accounts-wrapper admin-accounts">
            {/* Deleting loader */}
            <Modal footer={null} visible={isDeletingAccount} closable={false}>
                <div className="flex flex-col">
                    <Loader />
                    <div>Deleting account...</div>
                </div>
            </Modal>
            {/* Deleting loader */}
            <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                <section className="right-menu-wrapper">
                    <SearchBlock
                        dataValues={[]}
                        getDataMethod={props.getAccounts}
                        isSearching={isSearching}
                        placeholder={props.t('adminMessages.accounts.search.placeholder')}
                        setIsSearching={setIsSearching}
                        setSearchParam={setSearchParam}
                    />
                    <div className="right-align">
                        <Button big filled onClick={() => redirect(`/admin/accounts/add-account`)}>
                            Add Account
                        </Button>
                    </div>
                </section>
                <Table
                    columns={getAccountListColumns(accountTypes)}
                    dataSource={!props.isLoading ? memoizedAccountsData : []}
                    dataValues={[]}
                    getDataMethod={props.getAccounts}
                    isSearching={isSearching}
                    loading={props.isLoading}
                    localeMessage={errorMessages.TABLE_NO_ACCOUNTS_FOUND_MESSAGE}
                    paginationData={props.paginationAccounts}
                    rowClassName="pointer"
                    rowKey="id"
                    size="middle"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    defaultColumnFilters={{
                        type: 'customer-account',
                    }}
                    {...props}
                />
            </div>
        </div>
    );
};
/**
 *Prop types
 */
Accounts.propTypes = {
    accounts: PropTypes.array,
    exportAccountOrganisations: PropTypes.func,
    getAccounts: PropTypes.func,
    getAccountTypes: PropTypes.func,
    history: PropTypes.object,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    paginationAccounts: PropTypes.object,
    showAppNotificationMessage: PropTypes.func,
    t: PropTypes.func,
};
export default connect(
    state => ({
        ...state.account,
        isLoading: state.account.isLoading || state.account.isTypesLoading,
    }),
    {
        exportAccountOrganisations,
        getAccounts,
        showAppNotificationMessage,
    }
)(withTranslation()(Accounts));

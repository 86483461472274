/**
 * useInvoiceStatuses
 * Fetches invoice statuses and caches it
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import { get } from 'lodash';

import { GET_INVOICE_STATUSES } from 'includes/constants/mappings/success';
import { CACHE_INVOICE_STATUSES_KEY } from '../constants';
import InvoicesAPI from 'includes/services/customer/invoice';
import useCachedData from './useCachedData';

/**
 * Returns invoice statuses if cached else call the API and caches the result
 *
 * @param {boolean} disableApiCall should disable API call
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useInvoiceStatuses = (disableApiCall = false) => {
    return useCachedData(CACHE_INVOICE_STATUSES_KEY, () => InvoicesAPI.getInvoiceStatuses({ page_size: 0 }), {
        selector: res => {
            let invoiceStatuses = get(res, GET_INVOICE_STATUSES);
            invoiceStatuses = invoiceStatuses.map(status => ({
                ...status,
                label: status.status,
                originalLabel: status.label,
                value: status.id,
            }));
            return invoiceStatuses;
        },
        disableApiCall,
        dependsOnOrg: false,
    });
};

export default useInvoiceStatuses;

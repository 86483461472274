import PropTypes from 'prop-types';
import { BANK_ACCOUNT_TYPE } from 'components/customer/PaymentInformation/constants';
import React from 'react';

const TYPE_STYLE_MAP = {
    [BANK_ACCOUNT_TYPE.COMPANY]:
        'bg-yellow-100 text-yellow-800 dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300',
    [BANK_ACCOUNT_TYPE.INDIVIDUAL]:
        'bg-blue-100 text-blue-800 dark:bg-gray-700 dark:text-blue-400 border border-blue-400',
};

const TYPE_NAME_MAP = {
    [BANK_ACCOUNT_TYPE.COMPANY]: 'Company',
    [BANK_ACCOUNT_TYPE.INDIVIDUAL]: 'Individual',
};

/**
 * Bank account type
 *
 * Renders a tag/chip to indicate the bank account type based on the slug passed
 *
 * @since 2.8.0
 */
export default function BankAccountType({ type }) {
    return (
        <span className={`text-sm font-medium me-2 px-2 rounded ${TYPE_STYLE_MAP[type]}`}>{TYPE_NAME_MAP[type]}</span>
    );
}

BankAccountType.propTypes = {
    type: PropTypes.oneOf(Object.values(BANK_ACCOUNT_TYPE)),
};

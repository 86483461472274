/**
 * Orders Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import { useDispatch } from 'react-redux';

import OrdersTable from 'components/shared/Orders/Table';
import { useOrderTypes, useUpdateBusinessName } from 'includes/utils/hooks';
import { getOrders } from 'includes/slices/orders';
import { ORDER_TYPE } from 'includes/constants';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useAccountId from 'includes/hooks/useAccountId';
import { getPaymentGateways } from 'includes/slices/paymentGateways';
import SearchBlock from 'components/shared/SearchBlock';

/**
 * Orders  Component
 *
 * @since 2.8.0
 */
const OrganisationOrders = props => {
    const [isSearching, setIsSearching] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const dispatch = useDispatch();

    // check if user has updated the business name
    useUpdateBusinessName();

    const isAdmin = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    const accountId = useAccountId();

    useEffect(() => {
        const fetchPaymentGateways = () => dispatch(getPaymentGateways());
        fetchPaymentGateways();
    }, []);

    /**
     * Get the locale
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @returns {string} Localised text
     */
    const getLocale = path => props.t(`sharedMessages.orders.list.${path}`);

    const [orderTypes] = useOrderTypes();

    const orderType = useMemo(() => {
        if (orderTypes.length === 0) return null;

        return find(orderTypes, { slug: ORDER_TYPE.INVOICE });
    }, [orderTypes]);

    const getDataMethod = useCallback(
        params => {
            if (!orderType) return;

            const newParams = { ...params };

            if (isAdmin) newParams['account_id'] = accountId;

            dispatch(
                getOrders(orderType.id, {
                    ...newParams,
                    organisation_id: organisationId,
                })
            );
        },
        [orderType]
    );

    return (
        <div className="home-content-wrapper">
            {isAdmin ? (
                <div className="tab-arrow-link breadcumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/admin/accounts">Accounts</Link>
                        </Breadcrumb.Item>
                        {isAdmin && organisationId ? (
                            <Breadcrumb.Item>
                                <Link to={`/admin/accounts/organisations/${accountId}`}>Organisations</Link>
                            </Breadcrumb.Item>
                        ) : null}
                        <Breadcrumb.Item>
                            <span>Orders</span>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            ) : null}
            <h2 className="page-title">{'Orders'}</h2>
            <div className=" full-wrapper box-wrapper white-box-wrapper p-7">
                <>
                    <ul className={`selector-field show-elem`}>
                        <li>
                            <div className="right-align">
                                <SearchBlock
                                    getDataMethod={getDataMethod}
                                    isSearching={isSearching}
                                    placeholder={getLocale('search.placeholder')}
                                    setIsSearching={setIsSearching}
                                    setSearchParam={setSearchParam}
                                    filterEmpty={false}
                                />
                            </div>
                        </li>
                    </ul>
                    <div>
                        <OrdersTable
                            getDataMethod={getDataMethod}
                            isSearching={isSearching}
                            searchParam={searchParam}
                            setIsSearching={setIsSearching}
                            typeSlug={ORDER_TYPE.INVOICE}
                        />
                    </div>
                </>
            </div>
        </div>
    );
};

/**
 * Default props
 */
OrganisationOrders.defaultProps = {
    contactId: '',
};

/**
 * Proptypes
 */
OrganisationOrders.propTypes = {
    isAdmin: PropTypes.bool,
    location: PropTypes.object,
    t: PropTypes.func,
};

export default withTranslation()(OrganisationOrders);

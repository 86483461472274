/**
 * Redux Accounting Software actions
 * These are the accounting software related actions that send data from the application to the store
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import action types
import * as actionTypes from '../../../constants/actionTypes';

/**
 * Get All Accounting Softwares action
 *
 * @returns {object}            User Login action
 */
export const getAccountingSoftwares = () => ({
    type: actionTypes.GET_ACCOUNTING_SOFTWARE_REQUEST,
    payload: { page_size: 0 },
});

import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { renderPaymentServiceFormField } from 'components/PaymentGateways/constants';
import useCountries from 'includes/hooks/useCountries';
import * as formValidations from 'includes/utils/form';

/**
 * Country select Form field component to be used in payment services form
 *
 * @since 2.8.0
 */
export default function CountrySelectFormField({ disabled = false }) {
    const { data: countries } = useCountries();

    const { t } = useTranslation();

    return (
        <>
            {renderPaymentServiceFormField({
                name: 'country_id',
                instructions: null,
                label: t('customerMessages.paymentServices.form.label.country'),
                type: 'select',
                validate: [formValidations.required],
                additionalProps: {
                    options: countries?.map(o => ({
                        name: o.name,
                        value: o.id,
                    })),
                    disabled,
                    info: disabled ? (
                        <span>
                            Contact <a href="mailto:support@paycepaid.com">support@paycepaid.com</a> to change the
                            country.
                        </span>
                    ) : (
                        <span>You cannot change country at a later stage.</span>
                    ),
                },
            })}
        </>
    );
}

CountrySelectFormField.propTypes = {
    disabled: PropTypes.bool,
};

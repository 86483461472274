/**
 * Organisation settings Component
 * Handles the organisation settings view and functions related to organisation settings
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import { React, connect, Link, PropTypes, withTranslation } from '../../../includes/exports/react';
import { Breadcrumb } from 'antd';
import { updateOrganisationSettings } from '../../../includes/redux/actions/customer/organisation';
import DefaultSettings from '../../shared/DefaultSettings';

/**
 * Organisation settings Component
 */
const OrganisationSettings = props => {
    const { organisationId, accountId } = props.match.params;

    /**
     * Get Locale
     *
     * @param   {string}    path    Path for which locale is to be retrieved
     *
     * @returns  {string}            Locale Message
     */
    const getLocale = path => props.t(`adminMessages.organisationSettings.${path}`);

    return (
        <div>
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/admin/accounts">{getLocale('breadcrumb.accounts')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/admin/accounts/organisations/${accountId}`}>
                            {getLocale('breadcrumb.organisations')}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{getLocale('breadcrumb.settings')}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="white-box-wrapper box-wrapper full-wrapper">
                <DefaultSettings {...props} organisationId={organisationId} isAdmin={true} />
            </div>
        </div>
    );
};
/**
 * Prop Types
 */
OrganisationSettings.propTypes = {
    accountDetails: PropTypes.object,
    currentAccountId: PropTypes.string,
    match: PropTypes.object,
    t: PropTypes.func,
};
export default connect(
    state => ({
        accountDetails: state.account.userAccountDetails,
        currentAccountId: state.account.currentAccountId,
        defaultEmailDomain: state.settings.siteSettings.default_email_domain,
        defaultSettings: state.organisation.selectedOrganisation,
        isDetailsLoading: state.organisation.isDetailsLoading,
        isOrganisationSettingsUpdating: state.organisation.isOrganisationSettingsUpdating,
    }),
    {
        updateOrganisationSettings,
    }
)(withTranslation()(OrganisationSettings));

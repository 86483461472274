/**
 * Open banking API class.
 * Handles all api requests related to open banking
 *
 * @since 2.8.0
 * @author Aravind Rajan <aravind@paycepaid.com>
 */

//import base api handler
import BaseApiHandler from './base/api';

class OpenBankingApi extends BaseApiHandler {
    /**
     * Get bank accounts
     *
     * @param {string} organisationId Organisation ID
     * @param {object} searchParams. Search params. Default {}
     *
     * @returns {object} API Response
     */
    getBankAccounts = (organisationId = '', searchParams = {}) =>
        this.axios.get(this.getApiUrl('BANK_ACCOUNTS', { organisation_id: organisationId }), {
            params: searchParams,
        });

    /**
     * Get bank account transactions
     *
     * @param {string} organisationId Organisation ID
     * @param {object} searchParams. Search params. Default {}
     *
     * @returns {object} API Response
     */
    getBankAccountTransactions = (organisationId = '', searchParams = {}) =>
        this.axios.get(this.getApiUrl('BANK_ACCOUNT_TRANSACTIONS', { organisation_id: organisationId }), {
            params: searchParams,
        });

    /**
     * Get expense categories and percentages
     *
     * @returns {object} API Response
     */
    getExpenseCategories = () => this.axios.get(this.getApiUrl('OPEN_BANKING_GET_EXPENSE_CATEGORIES'));

    /**
     * Get Payment arrangement payment analysis
     *
     * @param {string} organisationId Organisation ID
     * @param {string} analysisId Analysis ID
     * @param {object} searchParams. Search params. Default {}
     *
     * @returns {object} API Response
     */
    getPaymentAnalysis = (organisationId = '', analysisId = '', searchParams = {}) =>
        this.axios.get(
            this.getApiUrl('OPEN_BANKING_GET_PAYMENT_ANALYSIS', {
                organisation_id: organisationId,
                analysis_id: analysisId,
            }),
            {
                params: searchParams,
            }
        );

    /**
     * Get Payment arrangement payment analysis categories
     *
     * @param {string} organisationId Organisation ID
     * @param {string} analysisId Analysis ID
     * @param {object} searchParams. Search params. Default {}
     *
     * @returns {object} API Response
     */
    getPaymentAnalysisCategories = (organisationId = '', analysisId = '', searchParams = {}) =>
        this.axios.get(
            this.getApiUrl('OPEN_BANKING_GET_PAYMENT_ANALYSIS_CATEGORIES', {
                organisation_id: organisationId,
                analysis_id: analysisId,
            }),
            {
                params: searchParams,
            }
        );
}

export default new OpenBankingApi();

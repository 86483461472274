/**
 * Notes listing Component.
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { get, find } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { addNote, deleteNote, getNotes, updateNote } from 'includes/slices/notes';
import { replaceSpaces } from 'includes/utils';
import { getNotesColumns } from 'includes/tableColumns/notes';
import { TABLE_NO_NOTES_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useCurrentRoleSlug } from 'includes/utils/hooks';
import { USER_ROLE_ADMIN, USER_ROLE_ADMIN_MANAGER } from 'includes/constants';
import AddEditNoteFrom from '../AddEditForm';
import Button from 'components/Button';
import DateAndRange from 'components/DateAndRange/DateAndRange';
import InfoMessage from 'components/shared/messages/InfoMessage';
import Loader from 'components/Loader';
// import NoteDetails from 'components/Notes/Details';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import useAccountId from 'includes/hooks/useAccountId';
import useInvoiceLinkGenerator from 'includes/hooks/useInvoiceLinkGenerator';
import useNoteStatuses from 'includes/hooks/useNoteStatuses';
import useNoteTypes from 'includes/hooks/useNoteTypes';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useToBEDateFormatter from 'includes/hooks/useToBEDateFormatter';
import useToFEDateFormatter from 'includes/hooks/useToFEDateFormatter';
import useAccountUsers from 'includes/hooks/useAccountUsers';
import PermissionGuard from 'components/PermissionGuard';
import { FOLLOWUPS, NOTE } from 'includes/constants/permissions';

const { confirm } = Modal;

/**
 * Notes listing component
 */
const NotesList = props => {
    const { data: accountUsers, isLoading: loadingUsers } = useAccountUsers();
    const { data: noteStatuses, isLoading: loadingNoteStatuses } = useNoteStatuses();
    const { data: noteTypes, isLoading: loadingNoteTypes } = useNoteTypes();
    const { t } = useTranslation();
    const accountId = useAccountId(false);
    const convertDateStringToFEFormat = useToFEDateFormatter();
    const convertDateStringToFEFormatList = useToFEDateFormatter(true);
    const convertDateToBEFormat = useToBEDateFormatter();
    const currentRoleSlug = useCurrentRoleSlug();
    const dispatch = useDispatch();
    const getInvoiceDetailsLink = useInvoiceLinkGenerator();
    const organisationId = useOrganisationId();
    const params = useParams();

    const [date, setDate] = useState();
    const [dateRange, setDateRange] = useState([null, null]);
    const [isSearching, setIsSearching] = useState(false);
    const [noteId, setNoteId] = useState('');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [tableFilters, setTableFilters] = useState({});

    const { contactId, invoiceId } = params;
    const loadingNoteCrud = useSelector(state => state.notes.loadingNoteCrud);
    const loadingNotes = useSelector(state => state.notes.loadingNotes);
    const notes = useSelector(state => state.notes.notes);
    const notesPagination = useSelector(state => state.notes.notesPagination);
    const noteParams = { accountId, organisationId, contactId, invoiceId };

    /**
     * Close delete modal
     */
    const closeDeleteModal = () => {
        setOpenDeleteModal(false);
    };

    /**
     * Close the modal
     */
    const closeModal = () => setOpenModal(false);

    /**
     * Delete a note.
     *
     * @param {string} noteId Id of the note to be deleted.
     */
    const deleteCurrentNote = noteId => {
        if (!props.followUps) {
            confirm({
                title: t('messages.notes.confirm.message.delete'),
                content: '',
                onOk: () => {
                    setOpenDeleteModal(true);
                    dispatch(deleteNote(noteId, noteParams, closeDeleteModal));
                },
            });
        }
    };

    /**
     * Handle the submit of the payment
     *
     * @param {object} values Form Values
     */
    const handleSubmit = values => {
        const cleanedDetails = {
            ...values,
            date: convertDateToBEFormat(values.date),
            follow_up_date: convertDateToBEFormat(values.follow_up_date),
        };
        dispatch(
            noteId
                ? updateNote(cleanedDetails, noteId, noteParams, closeModal, getSearchParams())
                : addNote(cleanedDetails, noteParams, closeModal, getSearchParams())
        );
    };

    /**
     * Fetch the notes data.
     */
    const fetchNotesData = useCallback(
        (data = {}) => {
            const searchParams = getSearchParams(data);
            dispatch(getNotes(noteParams, searchParams));
        },
        [date, dateRange, accountId, organisationId, contactId, invoiceId] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Get the contact view link
     *
     * @param {object} contact Contact
     *
     * @returns {string} Contact view link
     */
    const getContactViewLink = contact => {
        const contactUrlSlug = get(contact, 'is_manager') ? 'contact-manager' : 'contact';
        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return `/admin/accounts/organisation/${contactUrlSlug}/${accountId}/${organisationId}/${contact.id}`;
            default:
                return `/${contactUrlSlug}/${contact.id}`;
        }
    };

    /**
     * Format the table data
     */
    const getFormattedTableData = useCallback(
        () =>
            notes.map(note => {
                return {
                    ...note,
                    title: (
                        <div className="business-name-avatar">
                            <div>{note.title}</div>
                            <span className="crud-wrapper">
                                {note.contact ? (
                                    <Link className="crud-link" to={getContactViewLink(note.contact)}>
                                        {t('messages.notes.list.label.view.contact')}
                                    </Link>
                                ) : (
                                    ''
                                )}
                                {note.invoice ? (
                                    <Link
                                        className="crud-link"
                                        to={getInvoiceDetailsLink(
                                            note.invoice.contact_id,
                                            note.invoice.id,
                                            false,
                                            note.invoice.is_repeating_invoice
                                        )}
                                    >
                                        {t('messages.notes.list.label.view.invoice')}
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </span>
                        </div>
                    ),
                    date: convertDateStringToFEFormatList(note.date),
                    follow_up_date: convertDateStringToFEFormatList(note.follow_up_date),
                    starred: note.starred ? t('messages.notes.list.label.yes') : t('messages.notes.list.label.no'),
                    status: get(noteStatuses, note.status_id),
                    type: (
                        <div className="table-button-wrapper">
                            <button className={replaceSpaces(get(noteTypes, note.type_id))}>
                                {get(noteTypes, note.type_id)}
                            </button>
                        </div>
                    ),
                    user: get(find(accountUsers, { id: note.user_id }), 'full_name'),
                    actions: (
                        <div className="blue-bg-button">
                            <span>
                                <PermissionGuard requiredPermission={props.followUps ? FOLLOWUPS.EDIT : NOTE.EDIT}>
                                    <Button
                                        className="green-bg-button blue-bg-button"
                                        filled
                                        onClick={() => {
                                            setOpenModal(true);
                                            setNoteId(note.id);
                                        }}
                                    >
                                        {t('messages.notes.crud.edit')}
                                    </Button>
                                </PermissionGuard>
                            </span>
                            {!props.followUps ? (
                                <span className="delete-button">
                                    <PermissionGuard requiredPermission={FOLLOWUPS.DELETE}>
                                        <Button
                                            className="green-bg-button blue-bg-button"
                                            filled
                                            onClick={() => {
                                                deleteCurrentNote(note.id);
                                            }}
                                        >
                                            {t('messages.notes.crud.delete')}
                                        </Button>
                                    </PermissionGuard>
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                    ),
                };
            }),
        [notes] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Get search params
     *
     * @param {object} data
     *
     * @returns {object} Search Params
     */
    const getSearchParams = data => {
        let searchParams = { ...tableFilters, ...data };
        if (date || dateRange) {
            if (date) {
                searchParams.start_date = convertDateToBEFormat(date);
            } else if (dateRange[0]) {
                searchParams.start_date = convertDateToBEFormat(dateRange[0]);
                searchParams.end_date = convertDateToBEFormat(dateRange[1]);
            }
        }

        if (props.followUps) {
            searchParams['follow_ups'] = props.followUps;
        }

        return searchParams;
    };
    /**
     * Get memoized notes table data
     */
    const memoizedNotesTableData = useMemo(() => getFormattedTableData(), [getFormattedTableData]);

    /**
     * Get the note details
     */
    const noteDetails = useMemo(() => {
        const note = find(notes, { id: noteId });
        return {
            ...note,
            date: convertDateStringToFEFormat(note?.date),
            follow_up_date: convertDateStringToFEFormat(note?.follow_up_date),
        };
    });

    //effect to fetch notes
    useEffect(() => {
        fetchNotesData();
    }, [fetchNotesData]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="home-content-wrapper payment-history-wrapper">
                {loadingNoteTypes || loadingNoteStatuses || loadingUsers ? (
                    <Loader />
                ) : (
                    <>
                        <div className="search-filter-export-wrapper">
                            <section>
                                <ul className="selector-field">
                                    <li>&nbsp;</li>
                                    {!props.followUps ? (
                                        <li>
                                            <div className="right-align action-wrapper">
                                                <PermissionGuard requiredPermission={NOTE.ADD}>
                                                    <Button
                                                        big
                                                        filled
                                                        className="align-right"
                                                        onClick={() => {
                                                            setNoteId('');
                                                            setOpenModal(true);
                                                        }}
                                                    >
                                                        {t('messages.notes.crud.add')}
                                                    </Button>
                                                </PermissionGuard>
                                            </div>
                                        </li>
                                    ) : null}
                                </ul>
                                <ul className="selector-field">
                                    <li>
                                        <div className="date-picker-wrapper left-align">
                                            <div className="date-range-label text-left" style={{ marginBottom: 10 }}>
                                                {t(`messages.notes.list.label.dateFollowUpDateRange`)}
                                            </div>
                                            <DateAndRange
                                                date={date}
                                                dateRange={dateRange}
                                                setDate={setDate}
                                                setDateRange={setDateRange}
                                                filterType={props.followUps ? 'date' : 'date-range'}
                                            />
                                        </div>
                                    </li>
                                    <li>
                                        <div className="right-align action-wrapper">
                                            <SearchBlock
                                                getDataMethod={fetchNotesData}
                                                isSearching={isSearching}
                                                placeholder={t('messages.notes.list.search.placeholder')}
                                                setIsSearching={setIsSearching}
                                                setSearchParam={setSearchParam}
                                                setTableFilters={setTableFilters}
                                            />
                                        </div>
                                    </li>
                                </ul>
                            </section>
                        </div>
                        <section className="full-wrapper">
                            <Table
                                columns={getNotesColumns(noteTypes, noteStatuses, accountUsers)}
                                dataSource={memoizedNotesTableData}
                                getDataMethod={fetchNotesData}
                                loading={loadingNotes || loadingNoteTypes || loadingNoteStatuses}
                                localeMessage={TABLE_NO_NOTES_FOUND_MESSAGE}
                                paginationData={notesPagination}
                                rowKey="id"
                                searchParam={searchParam}
                                setIsSearching={setIsSearching}
                                setTableFilters={setTableFilters}
                                size="middle"
                                {...props}
                            />
                        </section>
                    </>
                )}
            </div>
            <Modal
                footer={[]}
                className="custom-form-modal text-left on-demand-campaign-modal"
                visible={openModal}
                onCancel={closeModal}
                destroyOnClose
                maskClosable={false}
                keyboard={false}
            >
                <header>{t(`messages.notes.crud.${get(noteDetails, 'title') ? 'edit' : 'add'}`)}</header>
                {/* {props.followUps ? (
                    <NoteDetails noteDetails={noteDetails} /> //leaving it commented out in case we need it
                ) : ( */}
                <AddEditNoteFrom
                    {...props}
                    initialValues={noteDetails}
                    onSubmit={handleSubmit}
                    loading={loadingNoteCrud}
                />
                {/* )} */}
            </Modal>
            <Modal
                footer={[]}
                className="my_profile_modal organisation-reauthorization-modal"
                visible={openDeleteModal}
                onCancel={closeDeleteModal}
            >
                <InfoMessage message={t('messages.notes.confirm.message.deleting')} showLoader={true} />
            </Modal>
        </>
    );
};

NotesList.defaultProps = {
    followUps: false,
};

NotesList.propTypes = {
    followUps: PropTypes.bool,
    match: PropTypes.object,
    t: PropTypes.func,
};

export default NotesList;

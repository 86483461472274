/**
 * useIsUserRole
 * extracts and returns the user role status from store
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { useSelector } from 'react-redux';
import { get } from 'lodash';

/**
 * Get the current user role status from store
 *
 * @param {string} userRoleKey User Role Key
 *
 * @returns {boolean} true|false
 */
const useIsUserRole = userRoleKey => {
    const userDetails = useSelector(state => state.user.userDetails);
    return get(userDetails, userRoleKey, false);
};

export default useIsUserRole;

/**
 * Logs API class.
 * Handles all api requests related to logs
 *
 * @version 2.2.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com>
 */

//import base api handler
import BaseApiHandler from './base/api';

class LogsApi extends BaseApiHandler {
    /**
     * Get the audit logs
     *
     * @param {string} accountId Account Id. Default ''
     * @param {object} searchParams. Search params. Default {}
     *
     * @returns {object} API Response
     */
    getAuditLogs = (accountId = '', searchParams = {}) =>
        this.axios.get(this.getApiUrl(accountId ? 'AUDIT_LOGS_ACCOUNT' : 'AUDIT_LOGS', { account_id: accountId }), {
            params: searchParams,
        });
}

export default new LogsApi();

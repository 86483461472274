/**
 * Websocket component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */
//import the required modules
import { React, connect, useRef, useState } from '../../../includes/exports/react';
import * as notificationActions from '../../../includes/redux/actions/shared/notification';
import * as responseKeys from '../../../includes/constants/keys/response';
import * as utils from '../../../includes/utils';
import PropTypes from 'prop-types';
import Websocket from 'react-websocket';
import { WEBSOCKET_URL } from '../../../includes/constants/config';
import { get } from 'lodash';
import {
    organisationDataFetchingComplete,
    organisationReauthorizationRequired,
    syncOrganisationComplete,
} from '../../../includes/redux/actions/customer/organisation';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { USER_CURRENT_ACCOUNT_KEY, WS_ACTION } from '../../../includes/constants';

/**
 * WebSocket component
 */
const WebSocket = props => {
    const [isWebSocketConnected, setWebSocketConnected] = useState(false);
    const webSocket = useRef(null);

    const { sendData } = props;

    /**
     * useEffect hook to call which will be invoked on data change on send data to server
     */
    useEffect(() => {
        sendMessage(sendData);
    }, [sendData]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the websocket URL
     */
    const getWebSocketUrl = () => {
        let websocketUrl = WEBSOCKET_URL;
        let organisationId = get(props, 'selectedOrganisationId', '');
        const accountId = utils.getLocalStorageValue(USER_CURRENT_ACCOUNT_KEY);

        if (!organisationId) {
            websocketUrl = websocketUrl.replace('organisationId', '');
        }

        return utils.multiStringReplace(websocketUrl, {
            authToken: props.token,
            accountId: accountId,
            organisationId: organisationId,
        });
    };

    /**
     * Process the received message and take actions based on the message
     *
     * @param   {object}    notificationMessage     Notification Message
     */
    const processReceivedMessage = notificationMessage => {
        if (notificationMessage.action === WS_ACTION.ORGANISATION_REAUTHORIZATION_REQUIRED) {
            props.organisationReauthorizationRequired(notificationMessage.organisation);
        } else if (
            notificationMessage.action === WS_ACTION.ORGANISATION_SYNC_COMPLETE ||
            notificationMessage.action === WS_ACTION.ORGANISATION_DATA_SYNCING_COMPLETE
        ) {
            props.syncOrganisationComplete(notificationMessage.organisation);
        } else if (notificationMessage.action === WS_ACTION.ORGANISATION_DATA_FETCHING_COMPLETE) {
            props.organisationDataFetchingComplete(notificationMessage.organisation);
        }
    };

    /**
     * Handles the message received from the websocket
     *
     * @param   {string}    message     Json message
     */
    const receiveMessage = message => {
        let result = JSON.parse(message);
        if (get(result, responseKeys.NOTIFICATION)) {
            processReceivedMessage(get(result, responseKeys.NOTIFICATION));
            props.receivedNotificationFromServer(
                get(result, responseKeys.NOTIFICATION),
                get(result, responseKeys.NOTIFICATIONS_UNREAD_COUNT)
            );
        } else if (get(result, responseKeys.NOTIFICATIONS)) {
            props.receivedNotificationsFromServer(
                get(result, responseKeys.NOTIFICATIONS),
                get(result, responseKeys.NOTIFICATIONS_UNREAD_COUNT)
            );
        }
    };

    /**
     * Send the message to the server  using the connected websocket
     *
     * @param   {*}     data    Data to be send to the server
     */
    const sendMessage = data => {
        // send message only if websocket is connected
        if (isWebSocketConnected && webSocket.current) {
            webSocket.current.sendMessage(JSON.stringify(data));
        }
    };

    /**
     * Render the component
     *
     * @returns {*}
     */
    return props.token && !props.isSwitchingOrganisation ? (
        <Websocket
            url={getWebSocketUrl()}
            onMessage={receiveMessage}
            ref={webSocket}
            reconnect={!!props.token}
            onOpen={() => setWebSocketConnected(true)}
            onClose={() => setWebSocketConnected(false)}
        />
    ) : (
        ''
    );
};

/**
 * Prop types
 */
WebSocket.propTypes = {
    isSwitchingOrganisation: PropTypes.bool,
    organisationDataFetchingComplete: PropTypes.func,
    organisationReauthorizationRequired: PropTypes.func,
    receivedNotificationFromServer: PropTypes.func,
    receivedNotificationsFromServer: PropTypes.func,
    receivedData: PropTypes.any,
    selectedOrganisationId: PropTypes.string,
    sendData: PropTypes.any,
    syncOrganisationComplete: PropTypes.func,
    token: PropTypes.string,
};

//Connect to store
export default connect(
    state => ({
        ...state.notification,
        isSwitchingOrganisation: state.organisation.isSwitchingOrganisation,
        selectedOrganisationId: state.organisation.selectedOrganisationId,
        token: state.user.token,
    }),
    {
        ...notificationActions,
        organisationDataFetchingComplete,
        organisationReauthorizationRequired,
        syncOrganisationComplete,
    }
)(withTranslation()(WebSocket));

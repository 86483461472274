/**
 * Edit order form
 *
 * @version 1.o
 * @author  Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import { React, PropTypes, withTranslation } from '../../../../includes/exports/react';
import { Form } from 'antd';
import { reduxForm } from 'redux-form';
import * as constants from '../../../../includes/constants';
import Button from '../../Button';
import { useSelector } from 'react-redux';

/**
 * Edit order form component
 */
const EditOrderForm = props => {
    const loadingOrderDetails = useSelector(state => state.orders.loadingOrderDetails);
    const loadingUpdateOrder = useSelector(state => state.orders.loadingUpdateOrder);

    return (
        <div className="home-content-wrapper make-payment-wrapper">
            <h2 className="page-title">{props.getLocalisedContent('editOrder')}</h2>
            <Form layout="vertical" className="" onSubmit={props.handleSubmit}>
                <div className="grid-align edit-order-form">
                    <section className="half-wrapper box-wrapper white-box-wrapper plan-details-form">
                        {props.renderOrderDetails()}
                    </section>
                    <section className="half-wrapper box-wrapper white-box-wrapper order-summary right-align">
                        {props.renderOrderSummary()}
                    </section>
                </div>
                {!loadingOrderDetails && (
                    <>
                        <Form.Item
                            className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}
                        >
                            {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                            <Button
                                htmlType="submit"
                                big
                                filled
                                loading={loadingUpdateOrder}
                                submittingButtonLabel={props.getLocalisedContent(
                                    `form.submit.buttonSubmittingLabel.edit`
                                )}
                                className="register-btn"
                            >
                                {props.getLocalisedContent(`form.submit.buttonLabel.edit`)}
                            </Button>
                        </Form.Item>
                        <div className="clearfix" />
                    </>
                )}
            </Form>
        </div>
    );
};

EditOrderForm.propTypes = {
    error: PropTypes.any,
    getLocalisedContent: PropTypes.func,
    handleSubmit: PropTypes.func,
    renderOrderDetails: PropTypes.func,
    renderOrderSummary: PropTypes.func,
    t: PropTypes.func,
};

export default reduxForm({
    form: constants.EDIT_ORDER_FORM_NAME,
})(withTranslation()(EditOrderForm));

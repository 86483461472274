/**
 * Base Sage Handler Class
 * Registers the Sags Functions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import fork module
import { fork, put } from 'redux-saga/effects';
import { get, isArray, isEmpty, isObject } from 'lodash';
import * as errorMappings from '../../../../constants/mappings/errors';
import * as errorMessages from '../../../../constants/messages/errors';
import { _ERROR } from '../../../../constants';
import * as constants from '../../../../constants';
import { SHOW_NOTIFICATION_MODAL } from '../../../../constants/actionTypes';
import * as successMappings from '../../../../constants/mappings/success';
import { UNKNOWN_ERROR } from '../../../../constants/messages/errors';
import * as actionTypes from '../../../../constants/actionTypes';
import { stopSubmit } from 'redux-form';
import * as utils from '../../../../utils';
import ReactHtmlParser from 'react-html-parser';
export default class BaseSagaHandler {
    /**
     * Check if a function is watcher function or not
     * A function is considered as watcher if the function name ends with Watcher keyword
     *
     * @param   {string}    fn    Function Name
     *
     * @returns {boolean}   true|false
     */
    static isWatcher(fn) {
        return fn.endsWith('Watchers');
    }

    /**
     * Fork all the Watcher Functions
     *
     * @returns {Array}
     */
    forkAllWatcherFunctions() {
        let sagaFunctions = [];
        let functions = Object.getOwnPropertyNames(this.constructor.prototype);

        functions.forEach(func => {
            if (BaseSagaHandler.isWatcher(String(func))) {
                sagaFunctions.push(fork([this, this[func]]));
            }
        });

        return sagaFunctions;
    }

    /**
     * Get all the errors in the API response
     * If there is no error response in the API, checks if any default error is set up for the API response in the client
     *
     * @param {object} errors API errors Default {}
     * @param {string} defaultApiErrorKey Default API Error Key Default ''
     * @param {string} defaultErrorKey Default Error Key Default ''
     * @param {Array} errorKeysToAddInDefaultError Error Keys to add in Default Error. Default []
     * @param {string} apiErrorKey API Error Key Default ''
     * @param {boolean} hasHtmlError Whether there is HTML error or not Default false
     *
     * @returns {object}    apiErrors           API Errors
     */
    getApiErrors(
        errors = {},
        defaultApiErrorKey = '',
        defaultErrorKey = '',
        errorKeysToAddInDefaultError = [],
        apiErrorKey = '',
        hasHtmlError = false
    ) {
        let apiDefaultError = '';

        if (!apiErrorKey) {
            apiErrorKey = constants.DEFAULT_API_ERROR_RESPONSE_STRUCTURE;
        }

        // get the api errors
        let apiErrors = get(errors, get(errorMappings, apiErrorKey, {}));
        let key = '';

        // loop through api errors and if any error has 0 index errors, add it to the default error key
        for (key in apiErrors) {
            // eslint-disable-next-line no-prototype-builtins
            if (apiErrors.hasOwnProperty(key)) {
                if (isArray(apiErrors[key][0])) {
                    apiErrors._error = apiErrors[key][0];
                }
            }
        }

        if (errorKeysToAddInDefaultError && !get(apiErrors, _ERROR)) {
            let errorKeyToAddInDefaultError = '';
            for (errorKeyToAddInDefaultError of errorKeysToAddInDefaultError) {
                let errorKeyError = get(apiErrors, [errorKeyToAddInDefaultError, 0]);
                if (errorKeyError) {
                    apiErrors._error = errorKeyError;
                    break;
                }
            }
        }

        // get the api default error
        if (defaultApiErrorKey) {
            if (hasHtmlError) {
                apiDefaultError = ReactHtmlParser(get(apiErrors, get(errorMappings, defaultApiErrorKey), ''));
            } else {
                apiDefaultError = get(apiErrors, get(errorMappings, defaultApiErrorKey), '');
            }
        }

        //if we have an error but the server returned no errors, set a default one
        if (isEmpty(apiErrors) && isEmpty(apiDefaultError)) {
            apiDefaultError = defaultErrorKey
                ? get(errorMessages, defaultErrorKey, errorMessages.UNKNOWN_ERROR)
                : errorMessages.UNKNOWN_ERROR;
        }

        if (apiDefaultError) {
            if (!isObject(apiErrors)) apiErrors = {};

            apiErrors._error = apiDefaultError;
        }
        return apiErrors;
    }

    /**
     * Show the notification error modal
     * Get the error from the response and show in a notification modal
     * If the current error is related to permission, we don't need to invoke the notification modal error
     * since that will pop up an another notification modal from the client middle ware
     *
     * @param {object} response API Response Default {}
     * @param {boolean} success Whether to show success or error modal. Default false
     * @param {string} key Key from which message needs to be taken Default ''
     * @param {string} defaultKey Default Key for message if the passed key does not have message Default ''
     * @param {string} apiErrorKey api key from which error needs to be taken Default ''
     * @param {object} customNotificationProps. Custom Notification props. Default {}
     *
     * @yields Notification modal
     */
    *showNotificationModal(
        response = {},
        success = false,
        key = '',
        defaultKey = '',
        apiErrorKey = '',
        customNotificationProps = {}
    ) {
        let message = '';

        if (success) {
            message = get(response, successMappings.API_RESPONSE_MESSAGE_STRUCTURE);
        } else {
            if (!this.hasPermissionError(response)) {
                if (!apiErrorKey) {
                    apiErrorKey = constants.DEFAULT_API_ERROR_RESPONSE_STRUCTURE;
                }
                message = get(
                    get(response, get(errorMappings, apiErrorKey, {})),
                    get(errorMappings, key),
                    get(errorMessages, defaultKey, UNKNOWN_ERROR)
                );
            }
        }

        if (message) {
            yield put({
                type: SHOW_NOTIFICATION_MODAL,
                message: message,
                messageType: success ? constants.SUCCESS : constants.ERROR,
                ...customNotificationProps,
            });
        }
    }

    /**
     * Stop the form submit
     * Get the error from the response and sends the errors to the form
     * If the current error is related to permission, we don't need to invoke the notification modal error
     * since that will pop up an another notification modal from the client middle ware
     * If there are any form fields specified and if there are any errors not in form fields,
     * show in notification modal instead of sending it to the form
     *
     * @param {object} errors API errors Default {}
     * @param {string} formName Name of the form. Default ''
     * @param {string} defaultApiErrorKey Default API Error Key Default ''
     * @param {string} defaultErrorKey Default Error Key Default ''
     * @param {Array} errorKeysToAddInDefaultError Error Keys to add in Default Error. Default []
     * @param {string} apiErrorKey API Error Key Default ''
     *
     * @yields Notification modal
     */
    *stopFormSubmit(
        errors = {},
        formName = '',
        defaultApiErrorKey = '',
        defaultErrorKey = '',
        errorKeysToAddInDefaultError = [],
        apiErrorKey = ''
    ) {
        if (!this.hasPermissionError(errors)) {
            let apiErrors = this.getApiErrors(
                errors,
                defaultApiErrorKey,
                defaultErrorKey,
                errorKeysToAddInDefaultError,
                apiErrorKey
            );

            // get the form fields from store
            let formFields = utils.getValueFormStore([constants.FORM, formName, constants.FIELDS]);
            formFields = formFields ? Object.keys(formFields) : [];

            if (apiErrors && formFields && isArray(formFields)) {
                let key = '';

                // loop through api errors and if any error has 0 index errors, add it to the default error key
                for (key in apiErrors) {
                    if (!formFields.includes(key)) {
                        yield put({
                            type: actionTypes.SHOW_NOTIFICATION_MODAL,
                            message: get(apiErrors, key),
                            messageType: constants.ERROR,
                        });
                        apiErrors._error = '';
                        break;
                    }
                }
            }

            yield put(stopSubmit(formName, apiErrors));
        }
    }

    /**
     * Check if there is any permission error in the response
     *
     * @param {object} response Response
     *
     * @returns {boolean} true or false
     */
    hasPermissionError(response) {
        return !!get(response, errorMappings.API_PERMISSION_ERROR_RESPONSE_STRUCTURE);
    }
}

/**
 * Zendesk Tickets listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { get, startCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CustomButton from 'components/shared/Button';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import useAccountId from 'includes/hooks/useAccountId';
import useAllowZendeskIntegration from 'includes/hooks/useAllowZendeskIntegration';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useZendeskSettings from 'includes/hooks/useZendeskSettings';
import ZendeskTicket from 'components/Zendesk/Tickets/Details';
import { getTickets } from 'includes/slices/zendesk';
import { getTicketsTableColumns } from 'includes/tableColumns/zendesk';
import { TABLE_NO_ZENDESK_TICKETS_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import {
    CONTACT_ID,
    ZENDESK_EVENT_TYPES,
    ZENDESK_TICKET_PRIORITIES,
    ZENDESK_TICKET_STATUSES,
    ZENDESK_TICKET_TYPES,
} from 'includes/constants/keys/request';
import useZendeskTicketOptions from 'includes/hooks/useZendeskTicketOptions';
import PermissionGuard from 'components/PermissionGuard';
import { redirect } from 'includes/utils';
import { ZENDESK } from 'includes/constants/permissions';

const ZendeskTickets = props => {
    const { contactId } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // check if user has updated the business name
    useUpdateBusinessName();

    const organisationId = useOrganisationId();
    const { data: ticketOptions, isLoading: loadingTicketOptions } = useZendeskTicketOptions();
    const { data: zendeskSetting, isLoading: loadingSettings } = useZendeskSettings();
    const allowZendeskIntegration = useAllowZendeskIntegration();
    const accountId = useAccountId();
    const isAdmin = useSelector(state => state.account.isAdmin);
    const loadingTickets = useSelector(state => state.zendesk.loadingTickets);
    const tickets = useSelector(state => state.zendesk.tickets);
    const ticketsPagination = useSelector(state => state.zendesk.ticketsPagination);
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [organisationTicketId, setOrganisationTicketId] = useState();
    const [showTicketDetailsModal, setShowTicketDetailsModal] = useState(false);

    /**
     * Get the locale text
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @returns {string} Locale test
     */
    const getLocaleText = path => t(`messages.zendesk.${path}`);

    /**
     * Get organisation tickets
     *
     * @param {object} searchParams Search Params
     */
    const getOrganisationTickets = searchParams => {
        if (allowZendeskIntegration) {
            if (contactId) {
                searchParams[CONTACT_ID] = contactId;
            }
            dispatch(getTickets(organisationId, searchParams));
        }
    };

    /**
     * Get the ticket details link
     *
     * @param {string} ticketId Ticket id
     *
     * @returns {string} Ticket Details Link
     */
    const getTicketDetailsLink = ticketId =>
        isAdmin
            ? `/admin/accounts/organisation/zendesk-tickets/zendesk-ticket/${accountId}/${organisationId}/${ticketId}`
            : `/zendesk-tickets/zendesk-ticket/${ticketId}`;

    /**
     * Get value from ticket options
     *
     * @param {string} optionKey Option Key to take from ticket options
     * @param {string} key Key for which value is to be retrieved
     *
     * @returns {string} Ticket Option Value
     */
    const getValueFromTicketOptions = (optionKey, key) => {
        const ticketTypeOptions = get(ticketOptions, optionKey);
        return startCase(get(ticketTypeOptions, key, ''));
    };

    /**
     * Get the zendesk ticket link
     *
     * @param {number} ticketId Ticket id
     *
     * @returns {string} Zendesk Ticket Link
     */
    const getZendeskTicketLink = ticketId =>
        `https://${zendeskSetting.subdomain}.zendesk.com/agent/tickets/${ticketId}`;

    /**
     * Format the tickets data
     */
    const formatTicketsData = useCallback(
        () =>
            tickets.map(ticket => {
                return {
                    ...ticket,
                    ticket_priority: getValueFromTicketOptions(ZENDESK_TICKET_PRIORITIES, ticket.ticket_priority),
                    ticket_status: getValueFromTicketOptions(ZENDESK_TICKET_STATUSES, ticket.ticket_status),
                    ticket_type: getValueFromTicketOptions(ZENDESK_TICKET_TYPES, ticket.ticket_type),
                    ticket_event_type: (
                        <div className="tag-linker table-button-wrapper">
                            <button
                                className={getValueFromTicketOptions(
                                    ZENDESK_EVENT_TYPES,
                                    ticket.ticket_event_type
                                )?.toLowerCase()}
                                style={{ textTransform: 'uppercase' }}
                            >
                                {getValueFromTicketOptions(ZENDESK_EVENT_TYPES, ticket.ticket_event_type)}
                            </button>
                        </div>
                    ),
                    actions: (
                        <div className="blue-bg-button" style={{ textAlign: 'center' }}>
                            <div>
                                <PermissionGuard requiredPermission={ZENDESK.TICKET.VIEW}>
                                    <CustomButton
                                        className="green-bg-button blue-bg-button"
                                        filled
                                        onClick={() => {
                                            if (contactId) {
                                                showTicketDetails(ticket.id);
                                            } else {
                                                redirect(getTicketDetailsLink(ticket.id));
                                            }
                                        }}
                                    >
                                        {getLocaleText('crud.view')}
                                    </CustomButton>
                                </PermissionGuard>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <a
                                    className="accounting-software-external-link"
                                    href={getZendeskTicketLink(ticket.ticket_id)}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <CustomButton className="green-bg-button blue-bg-button" filled>
                                        {getLocaleText('crud.viewInZendesk')}
                                    </CustomButton>
                                </a>
                            </div>
                        </div>
                    ),
                };
            }),
        [tickets, ticketOptions] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Memoized tickets data
     */
    const memoizedTicketsData = useMemo(() => formatTicketsData(), [formatTicketsData]);

    /**
     * Show the ticket details
     *
     * @param {string}} ticketId Ticket Id
     */
    const showTicketDetails = ticketId => {
        setOrganisationTicketId(ticketId);
        setShowTicketDetailsModal(true);
    };

    /**
     * Render the component
     */
    return (
        <div className="zendesk-tickets-wrapper">
            <ul className="selector-field show-elem invoices-wrapper">
                <li>&nbsp;</li>
                <li>&nbsp;</li>
                <li>
                    <div className="invoices-search">
                        <SearchBlock
                            getDataMethod={getOrganisationTickets}
                            isSearching={isSearching}
                            placeholder={getLocaleText('search.placeholder')}
                            setIsSearching={setIsSearching}
                            setSearchParam={setSearchParam}
                        />
                    </div>
                </li>
            </ul>
            <Table
                className="table-1200"
                columns={getTicketsTableColumns}
                dataSource={memoizedTicketsData}
                getDataMethod={getOrganisationTickets}
                isSearching={isSearching}
                loading={loadingTickets || loadingSettings || loadingTicketOptions}
                localeMessage={TABLE_NO_ZENDESK_TICKETS_FOUND_MESSAGE}
                mobileClassName="table-1200"
                paginationData={ticketsPagination}
                rowClassName="pointer"
                rowKey="id"
                searchParam={searchParam}
                setIsSearching={setIsSearching}
                size="middle"
            />
            <Modal
                visible={showTicketDetailsModal}
                onOk={() => setShowTicketDetailsModal(false)}
                onCancel={() => setShowTicketDetailsModal(false)}
                footer={[]}
                className="ticket-details-modal"
            >
                <ZendeskTicket ticketId={organisationTicketId} />
            </Modal>
        </div>
    );
};

ZendeskTickets.propTypes = {
    contactId: PropTypes.string,
    showBreadCrumb: PropTypes.bool,
    showTitle: PropTypes.bool,
};
export default ZendeskTickets;

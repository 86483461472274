/**
 * Contact types breadcrumb items
 *
 * @since 2.0.0
 */

//import required modules
import React from 'react';
import AccountsBreadCrumbItems from '../Accounts';
import OrganisationsBreadCrumbItems from '../Organisations';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';

const ContactTypesBreadCrumbItems = () => {
    const isAdminOrManager = useIsAdminOrManager();

    /**
     * Render the component
     */
    return (
        <>
            {isAdminOrManager ? (
                <>
                    <AccountsBreadCrumbItems showListBreadCrumb />
                    <OrganisationsBreadCrumbItems showListBreadCrumb />
                </>
            ) : null}
        </>
    );
};

export default ContactTypesBreadCrumbItems;

/**
 * My Profile Component
 * Renders the my profile
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modules
import { React } from '../../../includes/exports/react';

import { useCheckEditPermission } from '../../../includes/utils/hooks';
import RedirectingPageLoaderSection from 'components/RedirectingPageLoaderSection';
import useRedirectToAuthPortalRoute from 'includes/hooks/useRedirectToAuthPortalRoute';

/**
 * Edit Profile
 * Renders the edit profile form
 */
const EditProfile = () => {
    useRedirectToAuthPortalRoute('my-profile');
    useCheckEditPermission();

    /**
     * Render the component
     *
     * @returns {*}
     */
    return (
        <div className="home-content-wrapper edit-profile-wrapper">
            <RedirectingPageLoaderSection />
        </div>
    );
};

//Connect to store
export default EditProfile;

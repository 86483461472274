/**
 * Payment arrangement installments listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com>
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import { find, get } from 'lodash';
import {
    cancelPaymentArrangementInstallment,
    getPaymentArrangementInstallments,
    regeneratePaymentArrangementInstallments,
    updatePaymentArrangementInstallment,
} from 'includes/slices/paymentArrangement';
import { getPaymentArrangementInstallmentsTableColumns } from 'includes/tableColumns/paymentArrangementInstallments';
import {
    PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_OPTIONS,
    PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_PENDING,
    PAYMENT_ARRANGEMENT_OR_INSTALLMENT_PAYMENT_STATUS_OPTIONS,
    PAYMENT_ARRANGEMENT_PAYMENT_TYPE_UPFRONT,
    PAYMENT_ARRANGEMENT_STATUS_APPROVED,
    PAYMENT_ARRANGEMENT_STATUS_PENDING,
} from 'includes/constants';
import { PropTypes } from 'includes/exports/react';
import {
    TABLE_NO_PAYMENT_ARRANGEMENT_INSTALLMENTS_FOUND_MESSAGE,
    TABLE_NO_PAYMENT_ARRANGEMENT_PENDING_INSTALLMENTS_FOUND_MESSAGE,
} from 'includes/constants/messages/errors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import Button from 'components/Button';
import InfoMessage from 'components/shared/messages/InfoMessage';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Button as AntdButton, Dropdown, Icon, Menu, Modal, Tag, Tooltip } from 'antd';
import PaymentArrangementInstallmentDetailsModal from 'pages/PaymentArrangements/Details/components/Installments/components/Details/component';
import PaymentArrangementInstallmentEditForm from 'pages/PaymentArrangements/Details/components/Installments/components/Form';
import { ID } from 'includes/constants/keys/response';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import { CURRENCY_ID } from 'includes/constants/keys/request';
import {
    paymentArrangementInstallmentLatePaidTagColor,
    paymentArrangementInstallmentStatusTagColors,
    paymentArrangementOrInstallmentPaymentStatusTagColors,
} from 'includes/constants/tagColors';
import PermissionGuard from 'components/PermissionGuard';
import { PAYMENT_ARRANGEMENT } from 'includes/constants/permissions';

const { confirm } = Modal;

const PaymentArrangementInstallments = props => {
    useUpdateBusinessName();

    const { t } = useTranslation();
    const organisationId = useOrganisationId();
    const { data: organisationCurrencies } = useOrganisationCurrencies();
    const paymentArrangement = useSelector(state => state.paymentArrangement.paymentArrangement);
    const paymentArrangementCurrencyId = get(paymentArrangement, CURRENCY_ID);
    const paymentArrangementCurrencyDetails = find(organisationCurrencies, { id: paymentArrangementCurrencyId }, '');
    const paymentArrangementInstallments = useSelector(
        state => state.paymentArrangement.paymentArrangementInstallments
    );
    const paymentArrangementInstallmentsPagination = useSelector(
        state => state.paymentArrangement.paymentArrangementInstallmentsPagination
    );
    const paymentArrangementLatestPendingInstallmentId = useSelector(
        state => state.paymentArrangement.paymentArrangementLatestPendingInstallmentId
    );
    const loadingPaymentArrangementInstallments = useSelector(
        state => state.paymentArrangement.loadingPaymentArrangementInstallments
    );
    const loadingPaymentArrangementInstallmentCancel = useSelector(
        state => state.paymentArrangement.loadingPaymentArrangementInstallmentCancel
    );
    const loadingPaymentArrangementInstallmentsRegeneration = useSelector(
        state => state.paymentArrangement.loadingPaymentArrangementInstallmentsRegeneration
    );

    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [paymentArrangementInstallmentDetails, setPaymentArrangementInstallmentDetails] = useState();
    const [
        selectedPaymentArrangementInstallmentIdForDetailsModal,
        setSelectedPaymentArrangementInstallmentIdForDetailsModal,
    ] = useState(null);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [infoMessage, setInfoMessage] = useState();
    const [openMessageModal, setOpenMessageModal] = useState(false);
    const dispatch = useDispatch();

    /**
     * Cancel the pending installment
     *
     * @param {object} paymentArrangementInstallment Payment arrangement installment
     */
    const cancelPendingInstallment = paymentArrangementInstallment => {
        confirm({
            title: t(`messages.paymentArrangement.arrangement.installments.confirm.cancel.title`),
            content: '',
            onOk: () => {
                dispatch(
                    cancelPaymentArrangementInstallment(
                        organisationId,
                        props.paymentArrangementId,
                        paymentArrangementInstallment?.id
                    )
                );
            },
        });
    };

    /**
     * Handle the submit of payment installment form
     *
     * @param {object} values
     */
    const handleSubmit = values => {
        values[ID] = paymentArrangementInstallmentDetails?.id;
        dispatch(
            updatePaymentArrangementInstallment(
                organisationId,
                props.paymentArrangementId,
                paymentArrangementInstallmentDetails?.id,
                values,
                () => {
                    setOpenEditModal(false);
                    setPaymentArrangementInstallmentDetails('');
                }
            )
        );
    };

    /**
     * Render actions menus
     *
     * @param {object} paymentArrangementInstallment Payment arrangement installment
     */
    const renderActionMenus = paymentArrangementInstallment => {
        const items = [
            {
                label: t('messages.paymentArrangement.arrangement.installments.list.label.options.view'),
                onClick: () => {
                    setSelectedPaymentArrangementInstallmentIdForDetailsModal(paymentArrangementInstallment?.id);
                    setOpenViewModal(true);
                },
                disabled: false,
                requiredPermission: PAYMENT_ARRANGEMENT.INSTALLMENTS.VIEW,
            },
        ];

        if (paymentArrangementInstallment.status === PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_PENDING) {
            items.push(
                {
                    label: t('messages.paymentArrangement.arrangement.installments.list.label.options.pay'),
                    onClick: () => {
                        if (
                            paymentArrangement.status === PAYMENT_ARRANGEMENT_STATUS_APPROVED &&
                            paymentArrangementInstallment.id === paymentArrangementLatestPendingInstallmentId
                        ) {
                            setPaymentArrangementInstallmentDetails(paymentArrangementInstallment);
                            setOpenEditModal(true);
                        } else {
                            if (paymentArrangement.status !== 1) {
                                setInfoMessage(
                                    t(
                                        `messages.paymentArrangement.arrangement.installments.modal.message.payment_arrangement.approve.pay_installment`
                                    )
                                );
                            } else {
                                setInfoMessage(
                                    t(
                                        `messages.paymentArrangement.arrangement.installments.modal.message.pay_installment`
                                    )
                                );
                            }
                            setOpenMessageModal(true);
                        }
                    },
                    disabled:
                        paymentArrangementInstallment.id !== paymentArrangementLatestPendingInstallmentId ||
                        paymentArrangement.status !== 1,
                    requiredPermission: PAYMENT_ARRANGEMENT.INSTALLMENTS.PAY,
                },
                {
                    label: t('messages.paymentArrangement.arrangement.installments.list.label.options.cancel'),
                    onClick: () => {
                        if (
                            paymentArrangement.status === PAYMENT_ARRANGEMENT_STATUS_APPROVED &&
                            paymentArrangementInstallment.id === paymentArrangementLatestPendingInstallmentId
                        ) {
                            cancelPendingInstallment(paymentArrangementInstallment);
                        } else {
                            if (paymentArrangement.status !== 1) {
                                setInfoMessage(
                                    t(
                                        `messages.paymentArrangement.arrangement.installments.modal.message.payment_arrangement.approve.cancel_installment`
                                    )
                                );
                            } else {
                                setInfoMessage(
                                    t(
                                        `messages.paymentArrangement.arrangement.installments.modal.message.cancel_installment`
                                    )
                                );
                            }
                            setOpenMessageModal(true);
                        }
                    },
                    disabled:
                        paymentArrangementInstallment.id !== paymentArrangementLatestPendingInstallmentId ||
                        paymentArrangement.status !== 1,
                    requiredPermission: PAYMENT_ARRANGEMENT.INSTALLMENTS.CANCEL,
                }
            );
        }

        return (
            <Menu>
                {items.map((item, index) => (
                    <Menu.Item key={index} disabled={item.disabled}>
                        <PermissionGuard requiredPermission={item.requiredPermission}>
                            <span onClick={item.onClick} key={index}>
                                {item.label}
                            </span>
                        </PermissionGuard>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    /**
     * Format the payment arrangement installments data
     */
    const formatPaymentArrangementInstallmentsData = useCallback(
        () => {
            return paymentArrangementInstallments.map(paymentArrangementInstallment => {
                return {
                    ...paymentArrangementInstallment,
                    status: (
                        <div className="flex flex-col gap-2 items-start">
                            <Tag
                                color={get(
                                    paymentArrangementInstallmentStatusTagColors,
                                    paymentArrangementInstallment.status,
                                    0
                                )}
                            >
                                {get(
                                    find(PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_OPTIONS, {
                                        id: paymentArrangementInstallment.status,
                                    }),
                                    'label'
                                )}
                            </Tag>
                            {paymentArrangementInstallment?.status !== 1 ? (
                                <Tag
                                    className="flex-grow-0"
                                    color={get(
                                        paymentArrangementOrInstallmentPaymentStatusTagColors,
                                        paymentArrangementInstallment?.payment_status,
                                        0
                                    )}
                                    style={{
                                        fontWeight: 'bold',
                                        textAlign: 'right',
                                        float: 'right',
                                    }}
                                >
                                    {get(
                                        find(PAYMENT_ARRANGEMENT_OR_INSTALLMENT_PAYMENT_STATUS_OPTIONS, {
                                            id: paymentArrangementInstallment?.payment_status,
                                        }),
                                        'label'
                                    )}
                                </Tag>
                            ) : null}
                            {paymentArrangementInstallment?.is_late_paid ? (
                                <Tag color={paymentArrangementInstallmentLatePaidTagColor}>
                                    {t('messages.paymentArrangement.arrangement.installments.list.label.late_paid')}
                                </Tag>
                            ) : null}
                        </div>
                    ),
                    fee: props.getNumberFormattedWithPaymentArrangementCurrency(paymentArrangementInstallment.fee),
                    amount: props.getNumberFormattedWithPaymentArrangementCurrency(
                        paymentArrangementInstallment.amount
                    ),
                    total_amount: props.getNumberFormattedWithPaymentArrangementCurrency(
                        paymentArrangementInstallment.total_amount
                    ),
                    installment_number: (
                        <Tooltip title={paymentArrangementInstallment?.reschedule_reason}>
                            {t('messages.paymentArrangement.arrangement.installments.list.label.installment')}
                            {paymentArrangementInstallment.installment_number}
                            {paymentArrangementInstallment?.reschedule_reason ? (
                                <Icon
                                    type="info-circle"
                                    theme="outlined"
                                    style={{ marginLeft: '5px', verticalAlign: 'middle', color: 'rgb(206 184 143)' }}
                                />
                            ) : (
                                ''
                            )}
                        </Tooltip>
                    ),
                    payment_type: (
                        <Tag color="#5abcbf">
                            {t(
                                `messages.paymentArrangement.arrangement.installments.list.label.payment_type.${
                                    paymentArrangementInstallment.payment_type ===
                                    PAYMENT_ARRANGEMENT_PAYMENT_TYPE_UPFRONT
                                        ? 'upfront'
                                        : 'installment'
                                }`
                            )}
                        </Tag>
                    ),
                    actions: (
                        <Dropdown overlay={renderActionMenus(paymentArrangementInstallment)}>
                            <AntdButton style={{ borderRadius: 0 }}>
                                {t('messages.paymentArrangement.arrangement.installments.list.label.options.options')}
                                <Icon type="ellipsis" />
                            </AntdButton>
                        </Dropdown>
                    ),
                };
            });
        },
        [paymentArrangementInstallments] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Memoized payment arrangement installments data
     */
    const memoizedPaymentArrangementInstallmentsData = useMemo(() => formatPaymentArrangementInstallmentsData(), [
        formatPaymentArrangementInstallmentsData,
    ]);

    /**
     * Regenerate all pending installments
     */
    const regeneratePendingInstallments = () => {
        confirm({
            title: t(`messages.paymentArrangement.arrangement.installments.confirm.regenerate.title`),
            content: '',
            onOk: () => {
                dispatch(regeneratePaymentArrangementInstallments(organisationId, props.paymentArrangementId));
            },
        });
    };

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
            <div className={`search-filter-export-wrapper contact-list-container full-wrapper box-wrapper`}>
                {[PAYMENT_ARRANGEMENT_STATUS_APPROVED, PAYMENT_ARRANGEMENT_STATUS_PENDING].includes(
                    paymentArrangement?.status
                ) ? (
                    <section className="right-menu-wrapper reminders-container">
                        <InfoMessage
                            message={t(
                                'messages.paymentArrangement.arrangement.installments.info.regenerate_installments'
                            )}
                        />
                        <div className="right-align">
                            <PermissionGuard requiredPermission={PAYMENT_ARRANGEMENT.INSTALLMENTS.REGENERATE}>
                                <Button
                                    big
                                    filled
                                    style={{ fontSize: '14px' }}
                                    onClick={() => {
                                        regeneratePendingInstallments();
                                    }}
                                >
                                    {t(
                                        'messages.paymentArrangement.arrangement.installments.list.label.regenerate_installments'
                                    )}
                                </Button>
                            </PermissionGuard>
                        </div>
                    </section>
                ) : null}
                <ul className="selector-field show-elem invoices-wrapper">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>
                        <div className="invoices-search">
                            <SearchBlock
                                dataValues={[organisationId, props.paymentArrangementId]}
                                dispatchMethod={true}
                                getDataMethod={getPaymentArrangementInstallments}
                                isSearching={isSearching}
                                placeholder={t(
                                    `messages.paymentArrangement.arrangement.installments.search.placeholder`
                                )}
                                setIsSearching={setIsSearching}
                                setSearchParam={setSearchParam}
                            />
                        </div>
                    </li>
                </ul>
                <Table
                    className="table-1200"
                    columns={getPaymentArrangementInstallmentsTableColumns}
                    dataSource={memoizedPaymentArrangementInstallmentsData}
                    dataValues={[organisationId, props.paymentArrangementId]}
                    dispatchMethod={true}
                    getDataMethod={getPaymentArrangementInstallments}
                    isSearching={isSearching}
                    loading={loadingPaymentArrangementInstallments}
                    localeMessage={
                        [PAYMENT_ARRANGEMENT_STATUS_APPROVED, PAYMENT_ARRANGEMENT_STATUS_PENDING].includes(
                            paymentArrangement?.status
                        )
                            ? TABLE_NO_PAYMENT_ARRANGEMENT_PENDING_INSTALLMENTS_FOUND_MESSAGE
                            : TABLE_NO_PAYMENT_ARRANGEMENT_INSTALLMENTS_FOUND_MESSAGE
                    }
                    mobileClassName="table-1200"
                    paginationData={paymentArrangementInstallmentsPagination}
                    rowClassName="pointer"
                    rowKey="id"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    size="middle"
                    defaultColumnFilters={{
                        status: [PAYMENT_ARRANGEMENT_STATUS_APPROVED, PAYMENT_ARRANGEMENT_STATUS_PENDING].includes(
                            paymentArrangement?.status
                        )
                            ? 3
                            : '',
                    }}
                />

                <PaymentArrangementInstallmentDetailsModal
                    isOpen={openViewModal}
                    onCancel={() => {
                        setOpenViewModal(false);
                        setSelectedPaymentArrangementInstallmentIdForDetailsModal(null);
                    }}
                    paymentArrangementInstallmentId={selectedPaymentArrangementInstallmentIdForDetailsModal}
                    setSelectedPaymentArrangementInstallmentId={
                        setSelectedPaymentArrangementInstallmentIdForDetailsModal
                    }
                    getNumberFormattedWithPaymentArrangementCurrency={
                        props.getNumberFormattedWithPaymentArrangementCurrency
                    }
                />

                <Modal
                    footer={[]}
                    className="custom-form-modal text-left custom-pp-modal"
                    visible={openEditModal}
                    onCancel={() => {
                        setOpenEditModal(false);
                        setPaymentArrangementInstallmentDetails('');
                    }}
                    destroyOnClose
                >
                    {
                        <header style={{ fontSize: '22px' }}>
                            {t(`messages.paymentArrangement.arrangement.installments.edit.form.title`)}
                        </header>
                    }
                    <PaymentArrangementInstallmentEditForm
                        paymentArrangementInstallmentDetails={paymentArrangementInstallmentDetails}
                        getNumberFormattedWithPaymentArrangementCurrency={
                            props.getNumberFormattedWithPaymentArrangementCurrency
                        }
                        onSubmit={handleSubmit}
                        initialValues={{
                            exchange_rate:
                                get(paymentArrangementCurrencyDetails, 'exchange_rates.exchange_rate', 1) || 1,
                        }}
                    />
                </Modal>
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={
                        loadingPaymentArrangementInstallmentCancel || loadingPaymentArrangementInstallmentsRegeneration
                    }
                >
                    <InfoMessage
                        message={
                            loadingPaymentArrangementInstallmentCancel ||
                            loadingPaymentArrangementInstallmentsRegeneration
                                ? t(
                                      `messages.paymentArrangement.arrangement.installments.modal.message.${
                                          loadingPaymentArrangementInstallmentCancel ? 'cancel' : 'regenerate'
                                      }`
                                  )
                                : ''
                        }
                        showLoader={true}
                    />
                </Modal>
                <Modal
                    footer={
                        <Button
                            filled
                            key="organisation-reauthorization-back"
                            className="ant-btn green-bg-button"
                            onClick={() => setOpenMessageModal(false)}
                            style={{ marginBottom: '10px' }}
                        >
                            Ok
                        </Button>
                    }
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={openMessageModal}
                    onCancel={() => setOpenMessageModal(false)}
                >
                    <InfoMessage message={infoMessage} />
                </Modal>
            </div>
        </div>
    );
};

PaymentArrangementInstallments.propTypes = {
    getNumberFormattedWithPaymentArrangementCurrency: PropTypes.func,
    paymentArrangementId: PropTypes.string,
};

export default PaymentArrangementInstallments;

import * as constants from '../index';

/**
 * Defines success mapping keys in API response
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// _
export const _DEFAULT_API_DATA_RESPONSE_STRUCTURE = ['data', 'data'];
export const _DEFAULT_API_ACTION_DATA_RESPONSE_STRUCTURE = ['result', 'data', 'data'];

// A
export const API_RESPONSE_MESSAGE_STRUCTURE = ['data', 'message'];
export const ACCOUNT_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'account_types'];
export const ARRANGEMENT_DUE_PER_DAY_DATA = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'arrangement_due_data',
    'arrangement_due_per_day',
];
export const ARRANGEMENT_DUE_PER_DAY_VIEW_PORT_MAX_MIN_DATES = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'arrangement_due_data',
    'view_port_max_min_dates',
];
export const ARRANGEMENT_DUE_TOTAL = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'arrangement_due_data',
    'arrangement_due_total',
];
export const AUDIT_LOGS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'logs'];

// B
export const BANK_ACCOUNTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'accounts'];
export const BANK_ACCOUNT_TRANSACTIONS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'transactions'];

// C
export const CAMPAIGN = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'campaign'];
export const CAMPAIGN_NOTIFICATION = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'campaign_notification'];
export const CAMPAIGN_NOTIFICATION_DAYS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'campaign_notification_days'];
export const CAMPAIGN_NOTIFICATIONS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'campaign_notifications'];
export const CAMPAIGNS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'campaigns'];
export const CANCEL_ACCOUNT_SUBSCRIPTION = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'cancel_subscription'];
export const CASH_COLLECTED_AVERAGE_PAYMENT_DAYS = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'cash_collected_data',
    'average_payment_days',
];
export const CASH_COLLECTED_PER_DAY_DATA = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'cash_collected_data',
    'cash_collected_per_day',
];
export const CASH_COLLECTED_TOTAL = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'cash_collected_data',
    'cash_collected_total',
];
export const CASH_COLLECTED_PER_DAY_VIEW_PORT_MAX_MIN_DATES = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'cash_collected_data',
    'view_port_max_min_dates',
];
export const CHART_OF_ACCOUNT = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'chart_of_account'];
export const CHART_OF_ACCOUNT_TRANSACTIONS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'chart_of_account_transactions'];
export const CHART_OF_ACCOUNTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'chart_of_accounts'];
export const CHART_OF_ACCOUNT_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'chart_of_account_types'];
export const COMMUNICATION_LOG_STATUSES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'communication_log_statuses'];
export const COMMUNICATION_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'communication_types'];
export const COMMUNICATION_TEMPLATE = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'communication_template'];
export const COMMUNICATION_TEMPLATE_ID = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'scheduler',
    'communication_template_id',
];
export const COMMUNICATION_TEMPLATES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'communication_templates'];
export const COMMUNICATION_TEMPLATE_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'communication_template_types'];
export const CLIENT_SECRET_TOKEN = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'order', 'token'];
export const CLIENT_SECRET_TOKEN_UPDATE_ORDER = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'update_order', 'token'];
export const CONTACT_INVOICE_STATUS_TYPE_CONTACT_TYPE_COUNT = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'contact_invoice_status_type_data',
    'contacts_type_count',
];
export const CONTACT_INVOICE_STATUS_TYPE_INVOICE_STATUS_COUNT = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'contact_invoice_status_type_data',
    'invoices_status_count',
];
export const CONTACT_INVOICE_STATUS_TYPE_INVOICE_TYPE_COUNT = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'contact_invoice_status_type_data',
    'invoices_type_count',
];
export const CONTACT_INVOICE_STATUS_TYPE_OVERDUE_COUNT = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'contact_invoice_status_type_data',
    'overdue_count',
];
export const CONTACT_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'contact_types'];
export const COUNTRIES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'countries'];
export const COUNTRY = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'country'];
export const CURRENCIES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'currencies'];
export const GET_CUSTOM_BRANDING = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'custom_branding'];

// D
export const DATA = 'data';
export const DASHBOARD_DATA_ARRANGEMENT_DUE_PER_DAY = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'arrangement_due_per_day',
];
export const DASHBOARD_DATA_ARRANGEMENT_DUE_TOTAL = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'arrangement_due_total',
];
export const DASHBOARD_DATA_AVERAGE_PAYMENT_DAYS = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'average_payment_days',
];
export const DASHBOARD_DATA_CASH_COLLECTED_PER_DAY = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'cash_collected_per_day',
];
export const DASHBOARD_DATA_CASH_COLLECTED_TOTAL = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'payment_overview',
    'cash_collected_total',
];
export const DASHBOARD_DATA_CONTACT_TYPE_COUNT = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'contacts_type_count',
];
export const DASHBOARD_DATA_COUNT_AVAILABLE_MIN_DATE = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'count_data_available_min_date',
];
export const DASHBOARD_DATA_INVOICE_TYPE_COUNT = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'invoices_status_count',
];
export const DASHBOARD_DATA_INVOICE_STATUS_COUNT = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'invoices_status_count',
];
export const DASHBOARD_DATA_PAYMENT_ARRANGEMENT_COUNT = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'payment_arrangement_count',
];
export const DASHBOARD_DATA_PAYMENT_EXTENSION_COUNT = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'payment_extension_count',
];
export const DASHBOARD_DATA_PAYMENT_OVERVIEW = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'payment_overview',
];
export const DASHBOARD_DATA_ARRANGEMENT_DUE_PER_DAY_VIEW_PORT_MAX_MIN_DATES = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'view_port_max_min_dates',
    'arrangement_due_per_day',
];
export const DASHBOARD_DATA_CASH_COLLECTED_PER_DAY_VIEW_PORT_MAX_MIN_DATES = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'view_port_max_min_dates',
    'cash_collected_per_day',
];
export const DASHBOARD_DATA_INVOICE_CURRENCY_BREAKDOWN = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'dashboard_data',
    'invoice_currency_breakdown',
];
export const DEBT_AGE_DATA = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'debt_age_band_data', 'debt_age_per_day'];
export const DEBT_BAND_DATA = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'debt_age_band_data', 'debt_band_per_day'];
export const DEBTOR_ACCOUNTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'debtor_accounts'];

// E
export const EMAIL_VERIFIED = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'user_details', 'email_verified'];
export const EVENTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'events'];
export const EXPORT_DOWNLOAD_LINK = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'export_download_link'];
export const EXPORTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'exports'];

//F
export const EXPORT_FILE = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'file_export'];

// G
export const GET_ACCOUNT_COUPONS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'coupons'];
export const GET_ACCOUNTING_SOFTWARE_AUTH_URL = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'authorization_url'];
export const GET_ACCOUNTING_SOFTWARES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'accounting_softwares'];
export const GET_ACCOUNT_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'account_details'];
export const GET_ACCOUNT_DETAILS_FROM_INITIAL_DATA = [
    ..._DEFAULT_API_ACTION_DATA_RESPONSE_STRUCTURE,
    'initial_data',
    'account_details',
];
export const GET_ALL_PERMISSIONS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'permission_guards'];
export const GET_CAMPAIGN_DAYS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'scheduler_days'];
export const GET_COMMUNICATION_LOG_HISTORY = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'communication_log_history'];
export const GET_COMMUNICATION_TEMPLATES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'communication_templates'];
export const GET_CONTACTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'contacts'];
export const GET_CONTACT_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'contact_details'];
export const GET_CONTACT_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'contact_types'];
export const GET_CURRENCIES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'currencies'];
export const GET_DATE_BOUNDARY = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'date_boundary'];
export const GET_EXPORT_LINK = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'export_link'];
export const GET_EXPORTS_LIST = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'export_data'];
export const GET_FAT_ZEBRA_VERIFICATION_DATA = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE];
export const GET_INVOICE_CONTACTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'invoice_contacts'];
export const GET_INVOICE_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'invoice_types'];
export const GET_NOTES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'notes'];
export const GET_NOTE_STATUSES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'note_statuses'];
export const GET_NOTE_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'note_types'];
export const GET_NOTIFICATIONS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'notifications'];
export const GET_ACCOUNT_PERMISSIONS = [...GET_ACCOUNT_DETAILS, 'subscription', 'plan', 'permission_guards'];
export const GET_ACCOUNT_PERMISSIONS_FROM_INITIAL_DATA = [
    ...GET_ACCOUNT_DETAILS_FROM_INITIAL_DATA,
    'subscription',
    'plan',
    'permission_guards',
];
export const GET_SAVED_CARDS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'saved_cards'];
export const GET_SELECTED_ORGANISATION = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'initial_data',
    'selected_organisation',
];
export const GET_SELECTED_ORGANISATION_AFTER_DEMO_COMPANY_TRY = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'selected_organisation',
];
export const GET_SUBSCRIPTION_PLANS_ADDITIONAL_CHARGES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_gateway'];
export const GET_SUBSCRIPTION_PLANS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'plans'];
export const GET_SUBSCRIPTIONS_HISTORY = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'subscriptions'];
export const GET_USER_REGISTER_LOGIN_SELECTED_ORGANISATION = [
    constants.ACCOUNT_ID_PLACEHOLDER,
    'selected_organisation',
];
export const GET_INVOICES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'invoices'];
export const GET_INVOICE_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'invoice'];
export const GET_INVOICE_DETAILS_CONTACT_ID = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'invoice', 'contact_id', 'id'];
export const GET_INVOICE_DETAILS_ID = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'invoice', 'id'];
export const GET_INVOICE_STATUSES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'invoice_statuses'];
export const GET_REMINDER = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'reminder_plan'];
export const GET_REMINDER_ID = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'reminder_plan', 'id'];
export const GET_REMINDER_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'communication_types'];
export const GET_REMINDERS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'reminders'];
export const GET_REMINDER_COUNTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'notification_type_count'];
export const GET_ORGANISATION_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'organisation_details'];
export const GET_ORGANISATION_DETAILS_BY_SETTINGS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'organisation_settings'];
export const GET_ORGANISATION_DETAILS_ID = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'organisation_details', 'id'];
export const GET_ORGANISATIONS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'organisations'];
export const GET_ORGANISATION_COUNTRIES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'organisation_countries'];
export const GET_PAGINATION_CURRENT_PAGE = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'current_page'];
export const GET_PAGINATION_PAGE_SIZE = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'page_size'];
export const GET_PAGINATION_TOTAL_COUNT = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'total_count'];
export const GET_PAGINATION_TOTAL_PAGES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'total_pages'];
export const GET_PAGINATION_SLICED_CURRENT_PAGE = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'current_page'];
export const GET_PAGINATION_SLICED_PAGE_SIZE = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'page_size'];
export const GET_PAGINATION_SLICED_TOTAL_COUNT = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'total_count'];
export const GET_PAGINATION_SLICED_TOTAL_PAGES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'total_pages'];
export const GET_USER_ROLES_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'user_roles'];
export const GET_PAYMENT_CLIENT_TOKEN = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'client_token'];
export const GET_PAYMENT_GATEWAY_DEFAULT = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'default_payment_gateway_id'];
export const GET_PAYMENT_GATEWAY_DEFAULT_FOR_RECURRING_PAYMENTS = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'default_payment_gateway_id_for_recurring_payments',
];
export const GET_PAYMENT_GATEWAY_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_gateway'];
export const GET_PAYMENT_GATEWAYS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_gateways'];
export const GET_PAYMENTS_HISTORY = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'invoice_payments'];
export const GET_INVOICE_PAYMENT_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'invoice_payment'];
export const GET_PAYMENT_METHODS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_methods'];
export const GET_PAYMENT_METHOD_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_method'];
export const GET_PAYMENT_METHOD_STATUSES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_method_statuses'];
export const GET_PAYMENT_METHOD_SCHEMES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_method_schemes'];
export const GET_PAYMENT_METHOD_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_method_types'];
export const GET_PAYMENT_ORDER_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'types'];
export const GET_PAYMENT_SERVICE_GATEWAY_PROVIDERS = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'payment_gateway_providers',
];
export const GET_SETTINGS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'settings'];
export const GET_SUBSCRIPTION_STATUSES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'statuses'];
export const GET_SUBSCRIPTION_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'subscription_details'];
export const GET_SUBSCRIPTION_PLAN_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'plan_details'];
export const GET_REAUTHORIZE_ORGANISATIONS_COUNT = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'initial_data',
    'reauthorize_organisations_count',
];
export const GET_USER_REGISTER_LOGIN_REAUTHORIZE_ORGANISATIONS_COUNT = [
    constants.ACCOUNT_ID_PLACEHOLDER,
    'reauthorize_organisations_count',
];
export const GET_SCHEDULERS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'schedulers'];
export const GET_REMINDER_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'reminder_details'];

export const GET_SCHEDULER_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'scheduler'];
export const REMINDER_TEMPLATE_ID = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'reminder_details',
    'communication_template_id',
];
export const TEMPLATE_ID = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'scheduler', 'communication_template'];
export const GET_SETTINGS_FROM_INITIAL_DATA = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'initial_data', 'settings'];
export const GET_USER_DETAILS = [..._DEFAULT_API_ACTION_DATA_RESPONSE_STRUCTURE, 'user_details'];
export const GET_USER_ROLES = [..._DEFAULT_API_ACTION_DATA_RESPONSE_STRUCTURE, 'user_details', 'roles'];
export const GET_USER_ACCOUNT_DETAILS = [
    ..._DEFAULT_API_ACTION_DATA_RESPONSE_STRUCTURE,
    'user_details',
    'accounts',
    constants.ACCOUNT_ID_PLACEHOLDER,
    'account_details',
];
export const GET_USER_AVATAR = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'user_details', 'avatar'];
export const GET_USER_ACCOUNT_CURRENT_ORGANISATION = [
    ..._DEFAULT_API_ACTION_DATA_RESPONSE_STRUCTURE,
    'user_details',
    'accounts',
    constants.ACCOUNT_ID_PLACEHOLDER,
    'default_organisation',
];
export const GET_USER_ACCOUNT_REAUTHORIZATION_REQUIRED_ORGANISATIONS = [
    ..._DEFAULT_API_ACTION_DATA_RESPONSE_STRUCTURE,
    'user_details',
    'accounts',
    constants.ACCOUNT_ID_PLACEHOLDER,
    'reauthorization_required_organisations',
];
export const GET_USER_DETAILS_FROM_INITIAL_DATA = [
    ..._DEFAULT_API_ACTION_DATA_RESPONSE_STRUCTURE,
    'initial_data',
    'user_details',
];
export const GET_ACCOUNTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'accounts'];
export const GET_COUPONS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'coupons'];
export const GET_ADMIN_DASHBOARD_PLAN_DATA = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE];
export const GET_PAYMENT_SERVICE_AUTHORIZATION_URL = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'authorization_url'];

// I
export const IMPORT_TEMPLATE_DOWNLOAD_LINK = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'import_template_link'];
export const INVENTORY_ITEM = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'inventory_item'];
export const INVENTORY_ITEM_TRANSACTIONS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'inventory_item_transactions'];
export const INVENTORY_ITEMS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'inventory_items'];
export const INVOICE_NEXT_INVOICE_NUMBER = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'next_invoice_number'];
export const INVOICE_PDF_DOWNLOAD_URL = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'invoice_pdf_link'];

// O
export const ORDER_CHARGES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'order_charges'];
export const ORDER_DOWNLOAD_INVOICE = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'order_pdf_url'];
export const ORDER_ID = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'order', 'id'];
export const ORDER_GET_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'order_details'];
export const ORDER_CONFIRM = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'confirm_order'];
export const ORDER_GET_STATUS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'status'];
export const ORDER_GET_STATUSES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'statuses'];
export const ORDER_GET_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'types'];
export const ORDER_PAYMENT_SECRET_TOKEN = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'order', 'token'];
export const ORDER_PAYMENT_SLUG = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'order', 'payment_status', 'slug'];
export const ORDER_UPDATE = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'update_order'];
export const ORDER_UPDATE_ORDER_ID = [...ORDER_UPDATE, 'id'];
export const ORDER_UPDATE_ORDER_PAYMENT_SECRET_TOKEN = [...ORDER_UPDATE, 'token'];
export const ORDER_UPDATE_ORDER_PAYMENT_SLUG = [...ORDER_UPDATE, 'payment_status', 'slug'];
export const ORDERS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'orders'];
export const ORDER_STATUS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'status', 'status'];
export const ORGANISATION_CURRENCIES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'organisation_currencies'];

export const OPEN_BANKING_EXPENSE_CATEGORIES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'yodlee_categories'];
export const OPEN_BANKING_PAYMENT_ANALYSIS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE];

// P
export const PAYMENT_ARRANGEMENT = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_arrangement'];
export const PAYMENT_ARRANGEMENT_INSTALLMENT = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'payment_arrangement_installment',
];
export const PAYMENT_ARRANGEMENT_INSTALLMENTS = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'payment_arrangement_installments',
];
export const PAYMENT_ARRANGEMENT_LATEST_PENDING_INSTALLMENT_ID = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'payment_arrangement_latest_pending_installment_id',
];
export const PAYMENT_ARRANGEMENT_NOTES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_arrangement_notes'];
export const PAYMENT_ARRANGEMENTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_arrangements'];
export const PAYMENT_PLAN = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_plan'];
export const PAYMENT_PLANS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_plans'];
export const PAYMENT_GET_ACCOUNTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_accounts'];
export const PAYMENT_GET_BANK_ACCOUNTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'payment_bank_accounts'];
export const PAYMENT_GET_STATUSES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'statuses'];
export const PHONE_NUMBER_VERIFIED = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'user_details', 'phone_number_verified'];
export const PLACEHOLDERS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'placeholders'];
export const PLACEHOLDER = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'placeholder'];
export const PLAN_ACCOUNTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'plan_accounts'];
export const PLAN_QUOTES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'plan_quotes'];

// R
export const REGISTRATION_ORDER_ID = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'order_id'];

// T
export const TAX = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'tax'];
export const TAX_TYPES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'tax_types'];
export const TAXES = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'taxes'];
export const TEMPLATE_DOWNLOAD_LINK = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'download_link'];

// U
export const UPDATE_ACCOUNT_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'update_account'];
export const UPDATE_ORDER = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'update_order'];
export const UPDATE_ORDER_ORDER_ID = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'update_order', 'id'];
export const USER_DETAILS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'user_details'];
export const USER_LOGIN_GET_ACCOUNTS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'user_details', 'accounts'];
export const USER_LOGIN_GET_ACCESS_TOKEN = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'access_token'];
export const USER_LOGIN_GET_TOKENS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'tokens'];
export const USER_LOGIN_IS_ADMIN = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'user_details', 'is_admin'];

// V
export const VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_ORGANISATION_DETAIL = [
    ..._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
    'organisation_details',
];

export const NOTIFICATION_PRIORITY_BADGE_MAPPING = {
    normal: 'processing',
    urgent: 'warning',
    'immediate-attention': 'error',
};

export const GET_USERS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'users'];

// Z
export const ZENDESK_SETTINGS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'zendesk_settings'];
export const ZENDESK_TICKET = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'zendesk_ticket'];
export const ZENDESK_TICKET_OPTIONS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'zendesk_ticket_options'];
export const ZENDESK_TICKETS = [..._DEFAULT_API_DATA_RESPONSE_STRUCTURE, 'zendesk_tickets'];

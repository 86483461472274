/**
 * Admin accounts reducer
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';
import { get } from 'lodash';
import * as successMappings from '../../../../constants/mappings/success';

const INITIAL_STATE = {
    isGraphDataLoading: true,
    planData: [],
};

export default function dashboardReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case actionTypes.GET_ADMIN_DASHBOARD_DATA_FAILURE:
            return {
                ...state,
                isGraphDataLoading: false,
                planData: [],
            };
        case actionTypes.GET_ADMIN_DASHBOARD_DATA_REQUEST:
            return {
                ...state,
                isGraphDataLoading: true,
                planData: [],
            };
        case actionTypes.GET_ADMIN_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                isGraphDataLoading: false,
                planData: get(action.result, successMappings.GET_ADMIN_DASHBOARD_PLAN_DATA),
            };
        default:
            return {
                ...state,
            };
    }
}

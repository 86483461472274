import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Icon, Menu, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import PaymentServiceInactiveBadge from '../PaymentServiceInactiveBadge';
import { PAYMENT_SERVICE } from 'components/PaymentGateways/constants';
import ServiceLabel from '../ServiceLabel';
import ProviderLabel from '../ProviderLabel';
import ReauthorizationRequired from '../ReauthorizationRequired';

/**
 * Registered service card
 * Renders registered payment service card
 *
 * @since 2.8.0
 */
export default function RegisteredServiceCard({
    account,
    handleDelete,
    handleEdit,
    handleReauthorize,
    handleToggleStatus,
    handleView,
    isActive,
    isNoLongerSupported,
    label,
    provider,
    reauthorize,
    slug,
}) {
    const { t } = useTranslation();

    // if provider exists, then use that logo, if not then use slug to get payment service logo
    const serviceLogo = provider ? provider?.logo : PAYMENT_SERVICE[slug]?.logo;

    const menu = (
        <Menu>
            <Menu.Item
                disabled={isNoLongerSupported}
                onClick={handleView}
                className="flex flex-row flex-start align-middle items-center"
            >
                <Icon type="eye" />
                <span>{t('customerMessages.paymentServices.action.view')}</span>
            </Menu.Item>
            {isNoLongerSupported ? null : (
                <Menu.Item onClick={handleToggleStatus} className="flex flex-row flex-start align-middle items-center">
                    <Icon type={isActive ? 'stop' : 'check-circle'} />
                    <span>
                        {isActive
                            ? t('customerMessages.paymentServices.action.disable')
                            : t('customerMessages.paymentServices.action.enable')}
                    </span>
                </Menu.Item>
            )}
            <Menu.Item
                disabled={isNoLongerSupported}
                onClick={handleEdit}
                className="flex flex-row flex-start align-middle items-center"
            >
                <Icon type="edit" />
                <span>{t('customerMessages.paymentServices.action.edit')}</span>
            </Menu.Item>
            {reauthorize ? (
                <Menu.Item onClick={handleReauthorize} className="flex flex-row flex-start align-middle items-center">
                    <Icon type="lock" />
                    <span>{t('customerMessages.paymentServices.action.reauthorize')}</span>
                </Menu.Item>
            ) : null}
            {handleDelete ? (
                <Menu.Item onClick={handleDelete} className="flex flex-row flex-start">
                    <Icon type="delete" />
                    <span>{t('customerMessages.paymentServices.action.delete')}</span>
                </Menu.Item>
            ) : null}
        </Menu>
    );

    return (
        <div
            className={` p-6 shadow-xl sm:max-w-lg rounded-lg min-w-min ${
                isNoLongerSupported || !isActive ? 'bg-gray-100' : 'bg-white'
            }`}
            style={{
                borderLeft: `5px solid ${PAYMENT_SERVICE[slug]?.accentColor}`,
            }}
        >
            <div className="flex flex-row gap-3">
                <div className="flex-none self-center">
                    <img src={serviceLogo} style={{ height: 50, width: 50 }} />
                </div>
                <div className="flex flex-col justify-start gap-2 flex-1 text-left truncate break-words w-16">
                    <div className="font-bold">{account}</div>

                    <div>{label}</div>
                    <div className="flex flex-row gap-1">
                        <ServiceLabel slug={slug} />
                        {provider ? <ProviderLabel name={provider?.name} color={provider?.color} /> : null}
                        {isActive ? null : <PaymentServiceInactiveBadge />}
                        {reauthorize ? <ReauthorizationRequired /> : null}
                    </div>
                </div>
                <div className="cursor-pointer">
                    {isNoLongerSupported ? (
                        <Tooltip title="We no longer support this payment service. Please contact administrator for more details.">
                            <Icon type="warning" style={{ color: 'red', marginRight: 10 }} />
                        </Tooltip>
                    ) : null}
                    <Dropdown overlay={menu}>
                        <Icon type="more" />
                    </Dropdown>
                </div>
            </div>
        </div>
    );
}

RegisteredServiceCard.propTypes = {
    account: PropTypes.string,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
    handleReauthorize: PropTypes.func,
    handleToggleStatus: PropTypes.func,
    handleView: PropTypes.any,
    isActive: PropTypes.bool,
    isNoLongerSupported: PropTypes.bool,
    label: PropTypes.string,
    provider: PropTypes.shape({
        color: PropTypes.string,
        is_active: PropTypes.bool,
        logo: PropTypes.string,
        name: PropTypes.string,
        slug: PropTypes.string,
    }),
    reauthorize: PropTypes.any,
    service: PropTypes.string,
    slug: PropTypes.string,
};

/**
 * Communication log history Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { get, find } from 'lodash';

import CustomButton from 'components/shared/Button';
import Loader from '../../shared/Loader';
import Table from '../../shared/ScrollableTable';
import { COMMUNICATION_LOG_TABLE_COLUMNS } from 'includes/constants/columns';
import { useCheckEditPermission, useDataFromStore } from 'includes/utils/hooks';
import './styles.scss';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useAccountId from 'includes/hooks/useAccountId';
import { getDefaultImageClass, getImageFromData } from 'includes/utils';
import HasEditPermission from '../../HasEditPermission';
import { getCommunicationLogStatuses } from 'includes/redux/actions/shared/communication';
import useCommunicationTypes from 'includes/hooks/useCommunicationTypes';

const contactAvatar45Key = 'CONTACT_AVATAR_45X45';
const contactAvatarOriginalKey = 'CONTACT_AVATAR_ORIGINAL';

const CommunicationLogHistory = props => {
    const { getDataMethod, getDetailsLink, pageTitle, breadcrumb } = props;
    const organisationId = useOrganisationId();
    const accountId = useAccountId();

    // get the communication log statuses
    const [communicationLogStatuses] = useDataFromStore({
        reducer: 'communication',
        dataKey: 'communicationLogStatuses',
        loadingKey: 'isCommunicationLogStatusesLoading',
        action: getCommunicationLogStatuses,
    });

    const communicationLogHistory = useSelector(state => state.communication.communicationLogHistory);
    const isAdmin = useSelector(state => state.account.isAdmin);
    const isCommunicationLogHistoryLoading = useSelector(state => state.communication.isCommunicationLogHistoryLoading);
    const paginationCommunicationLogHistory = useSelector(
        state => state.communication.paginationCommunicationLogHistory
    );

    const { t } = useTranslation();

    // check if user has permission
    useCheckEditPermission();

    const { data: communicationTypes, isLoading: isCommunicationTypesLoading } = useCommunicationTypes();

    //Format the table data
    const getFormattedTableData = useCallback(
        () =>
            communicationLogHistory.map(n => {
                const notificationType = find(communicationTypes, { id: n.type_id });
                const contactUrlSlug = get(n, 'contact.is_manager') ? 'contact-manager' : 'contact';
                return {
                    ...n,
                    name: get(n.contact, 'id') ? (
                        <span className="table-name-align">
                            <img
                                className={
                                    getDefaultImageClass(
                                        n.contact,
                                        contactAvatar45Key,
                                        contactAvatarOriginalKey,
                                        '45x45'
                                    ) + ' logo logo-45 '
                                }
                                src={getImageFromData(n.contact, contactAvatar45Key, contactAvatarOriginalKey)}
                                alt={n.contact.name}
                            />
                            <div>
                                <Link
                                    className="crud-link"
                                    to={
                                        isAdmin
                                            ? `/admin/accounts/organisation/${contactUrlSlug}/${accountId}/${organisationId}/${n.contact.id}`
                                            : `/${contactUrlSlug}/${n.contact.id}`
                                    }
                                >
                                    {n.contact.name}
                                </Link>
                            </div>
                        </span>
                    ) : (
                        n.to
                    ),
                    type_id: (
                        <div className="tag-linker table-button-wrapper">
                            <button className={get(notificationType, 'slug')}>{get(notificationType, 'type')}</button>
                        </div>
                    ),
                    status: get(find(communicationLogStatuses, { id: n.status_id }), 'status'),
                    sent_by: get(n.user, 'name'),
                    actions: (
                        <div className="blue-bg-button">
                            <span>
                                <HasEditPermission type="link" link={getDetailsLink(n)}>
                                    <CustomButton className="green-bg-button blue-bg-button" filled>
                                        View Details
                                    </CustomButton>
                                </HasEditPermission>
                            </span>
                        </div>
                    ),
                };
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            communicationTypes,
            communicationLogStatuses,
            accountId,
            organisationId,
            getDetailsLink,
            communicationLogHistory,
        ]
    );

    /**
     * Get localised text
     *
     * @param {string} path
     */
    const getLocaleText = path => t(`sharedMessages.communicationLogHistory.${path}`);

    /**
     * Memoized table data
     */
    const memoizedCommunicationLogHistoryTableData = useMemo(getFormattedTableData, [getFormattedTableData]);

    return (
        <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    {isAdmin && (
                        <Breadcrumb.Item>
                            <Link to={`/admin/accounts/organisations/${accountId}`}>
                                {getLocaleText('breadcrumb.organisations')}
                            </Link>
                        </Breadcrumb.Item>
                    )}
                    {breadcrumb}
                </Breadcrumb>
            </div>
            <header className={`reminders-header`}>
                <h2 className="page-title" style={{ marginBottom: '20px' }}>
                    {pageTitle}
                </h2>
            </header>
            <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper contact-list-container communication-log-wrapper">
                <section className={`reminders-container`}>
                    {communicationTypes && communicationLogStatuses ? (
                        <Table
                            rowKey="id"
                            columns={COMMUNICATION_LOG_TABLE_COLUMNS}
                            dataSource={memoizedCommunicationLogHistoryTableData}
                            size="middle"
                            loading={isCommunicationLogHistoryLoading || isCommunicationTypesLoading}
                            paginationData={paginationCommunicationLogHistory}
                            getDataMethod={getDataMethod}
                            className="table-1200"
                            mobileClassName="table-1200"
                            {...props}
                        />
                    ) : (
                        <Loader />
                    )}
                </section>
                {}
            </div>
        </div>
    );
};

CommunicationLogHistory.propTypes = {
    breadcrumb: PropTypes.arrayOf(PropTypes.node),
    getDetailsLink: PropTypes.func.isRequired,
    getDataMethod: PropTypes.func.isRequired,
    infoMessage: PropTypes.node,
    onDemandCampaign: PropTypes.bool,
    organisationId: PropTypes.string,
    pageTitle: PropTypes.string.isRequired,
    path: PropTypes.string,
    showBreadCrumb: PropTypes.bool,
    t: PropTypes.func,
};

CommunicationLogHistory.defaultProps = {
    infoMessage: null,
    breadcrumb: null,
};

export default CommunicationLogHistory;

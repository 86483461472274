/**
 * Connect Saga
 * Handles the connection of accounting softwares with PaycePaid app
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import the required modules
import * as actionTypes from '../../../../constants/actionTypes';
import * as constants from '../../../../constants';
import * as requestKeys from '../../../../constants/keys/request';
import * as responseKeys from '../../../../constants/keys/response';
import * as segmentAnalytics from '../../../../utils/segment';
import * as successMappings from '../../../../constants/mappings/success';
import * as successMessages from '../../../../constants/messages/success';
import * as utils from '../../../../utils';
import BaseSagaHandler from '../../core/base';
import ConnectApi from '../../../../services/customer/connect';
import { get } from 'lodash';
import { IntercomAPI } from 'react-intercom';
import { removeLocalStorage, setDataInLocalStorage } from '../../../../utils';
import { takeLatest, all, put, call } from 'redux-saga/effects';

/**
 * Accounting software Saga Class. Handles the accounting software related operations
 * */
class ConnectSaga extends BaseSagaHandler {
    /**
     * The Connect Watcher Saga
     * Watches the redux actions related to connecting accounting software and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *accountingSoftwareWatchers() {
        let context = this;
        yield all([
            yield takeLatest(actionTypes.GET_ACCOUNTING_SOFTWARE_AUTH_LINK_REQUEST, [
                context,
                'getAccountingSoftwareAuthLink',
            ]),
            yield takeLatest(actionTypes.VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_REQUEST, [
                context,
                'verifyAccountingSoftwareAuthorization',
            ]),
        ]);
    }

    /**
     * Get the accounting software auth link
     *
     * @param {object} payload Payload
     *
     * @yields {object} Accounting software auth link response
     */
    *getAccountingSoftwareAuthLink({ payload }) {
        try {
            const result = yield call(ConnectApi.getAuthorizationUrl, payload);
            const authorizationUrl = get(result, successMappings.GET_ACCOUNTING_SOFTWARE_AUTH_URL);
            yield put({ type: actionTypes.GET_ACCOUNTING_SOFTWARE_AUTH_LINK_SUCCESS, authorizationUrl });
            let data = {};
            data[constants.USER_ACCOUNTING_SOFTWARE_ID_KEY] = payload.accounting_software_id;
            utils.setDataInLocalStorage(data);
            utils.redirect(authorizationUrl, true);
        } catch (errors) {
            yield put({ type: actionTypes.GET_ACCOUNTING_SOFTWARE_AUTH_LINK_FAILURE, errors });
        }
    }

    /**
     * Verify the accounting software authorization
     *
     * @param {object} payload Payload
     *
     * @yields {object} Verification response
     */
    *verifyAccountingSoftwareAuthorization({ payload }) {
        try {
            const params = { ...payload };
            const result = yield call(ConnectApi.verifyAuthorization, params);

            utils.removeLocalStorage(constants.USER_ACCOUNTING_SOFTWARE_ID_KEY);

            yield put({
                type: actionTypes.SWITCHING_ORGANISATION_REQUEST,
            });

            let verifiedOrganisation = get(
                result,
                successMappings.VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_ORGANISATION_DETAIL
            );
            let verifiedOrganisationId = get(verifiedOrganisation, responseKeys.ID);

            // change the selected organisation id with the new connected organisation id
            setDataInLocalStorage({
                [constants.USER_SELECTED_ORGANISATION_ID_KEY]: verifiedOrganisationId,
            });
            removeLocalStorage(constants.ORGANISATION_PAYMENT_ACCOUNT_POPUP_SHOWN_KEY);

            //dispatch the selected organisation success action
            yield put({
                type: actionTypes.GET_SELECTED_ORGANISATION_SUCCESS,
                selectedOrganisation: verifiedOrganisation,
                selectedOrganisationId: verifiedOrganisationId,
                reauthorize: false,
            });

            if (get(params, requestKeys.REDIRECT_TO_ORGANISATION, false) === true) {
                yield put({ type: actionTypes.VERIFY_ORGANISATION_ACCOUNTING_SOFTWARE_AUTHORIZATION_SUCCESS, result });
            } else {
                yield put({ type: actionTypes.VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_SUCCESS, result });
                yield put({
                    type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                    message:
                        get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE) ||
                        get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE) ||
                        successMessages.ACCOUNTING_SOFTWARE_INTEGRATION_SUCCESS,
                    messageType: constants.SUCCESS,
                });

                // fire to event to update the users connected accounting software
                IntercomAPI('trackEvent', 'connected_accounting', {
                    name: get(verifiedOrganisation, 'accounting_software_data.name'),
                });

                // fire up analytics
                segmentAnalytics.connectedAccountingSoftware(verifiedOrganisation);

                //redirect to dashboard
                utils.redirect('/dashboard');
            }
        } catch (errors) {
            yield put({ type: actionTypes.VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_FAILURE, errors });
        }
    }
}

//export the Connect saga
export default new ConnectSaga().forkAllWatcherFunctions();

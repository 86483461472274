import { Dropdown, Icon } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BANK_ACCOUNT_TYPE } from 'components/customer/PaymentInformation/constants';
import BankAccountType from '../BankAccountType';

/**
 * Bank account view
 *
 * @returns
 * @since 2.8.0
 */
export default function BankAccount({ name, menu, number, type, code }) {
    const { t } = useTranslation();

    return (
        <div className="border border-solid border-gray-200 rounded-lg shadow-lg p-3 flex flex-row justify-between">
            <div className="text-left break-all">
                <div className="flex flex-row gap-1">
                    <span className="text-sm text-gray-400">{name}</span>
                </div>
                <div className="text-lg font-medium font-mono">{number}</div>
                <div className="text-sm text-gray-400">
                    {t('customerMessages.paymentMethod.bankAccount.form.label.bankCode')}:
                    <span className="font-mono text-gray-500 ml-1">{code}</span>
                </div>
                <div className="flex gap-1 mt-1">
                    <BankAccountType type={type} />
                </div>
            </div>

            {menu ? (
                <div className="cursor-pointer">
                    <Dropdown overlay={menu}>
                        <Icon type="more" />
                    </Dropdown>
                </div>
            ) : null}
        </div>
    );
}

BankAccount.propTypes = {
    code: PropTypes.string.isRequired,
    menu: PropTypes.any,
    name: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(BANK_ACCOUNT_TYPE)).isRequired,
};

/**
 * Add user Form
 * Renders the Add user form
 *
 * @version 1.0
 * @author ShinyRaj <shinyr@qburst.com>
 */

// import required modules
import { React, useEffect, useMemo } from '../../../includes/exports/react';
import { Form, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';

import Button from '../Button';
import * as constants from '../../../includes/constants';
import * as formValidations from '../../../includes/utils/form';
import FormField from '../FormField';
import * as requestKeys from '../../../includes/constants/keys/request';
import Loader from '../../shared/Loader';

const AddEditUserForm = props => {
    const isAdmin = props.isAdmin;

    const localeLabel = field => props.t(`customerMessages.editContact.form.input.label.${field}`);

    const adminRoles = useMemo(() => {
        return props?.userRoles?.filter(item => item?.slug === 'admin-manager' || item?.slug === 'admin');
    }, [props?.userRoles]);

    useEffect(() => {
        props.getUserRoles();
    }, []);

    return (
        <div>
            {props.isDetailsLoading || props?.isUserRolesLoading ? (
                <Loader />
            ) : (
                <div className="my-profile-detail">
                    <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                        <div className="profile-detail-upper">
                            <Field
                                type="avatar"
                                name={requestKeys.AVATAR}
                                label={localeLabel(requestKeys.AVATAR)}
                                imageName={props.t('customerMessages.editContact.form.input.label.profilePicture')}
                                isModalVisible={props.imagePickerVisible}
                                onSubmit={props.onImageSubmit}
                                toggleImagePicker={props.toggleImagePicker}
                                hasRemovedKey={requestKeys.HAS_DELETED_AVATAR}
                                reduxChange={props.change}
                                hasFeedback
                                component={FormField}
                                validate={[]}
                                imageSourceSizeKey={constants.IMAGE_254x254}
                                imageSourceOriginalKey={constants.IMAGE_ORIGINAL}
                                defaultImage={props.defaultImage}
                            />
                            <ul className="upper-right-detail">
                                <li>
                                    <Row gutter={16}>
                                        <Col span={16}>
                                            <Field
                                                type="text"
                                                name={requestKeys.FIRST_NAME}
                                                label={localeLabel(requestKeys.FIRST_NAME)}
                                                hasFeedback
                                                component={FormField}
                                                validate={[formValidations.required]}
                                            />
                                        </Col>
                                        <Col span={16}>
                                            <Field
                                                type="text"
                                                name={requestKeys.LAST_NAME}
                                                label={localeLabel(requestKeys.LAST_NAME)}
                                                hasFeedback
                                                component={FormField}
                                                validate={[formValidations.required]}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Field
                                                type="text"
                                                name={requestKeys.EMAIL}
                                                label={localeLabel(requestKeys.EMAIL)}
                                                hasFeedback
                                                className="form-control"
                                                component={FormField}
                                                validate={[formValidations.required, formValidations.email]}
                                            />
                                        </Col>
                                        <Col span={12} className="phone-field-wrapper">
                                            <Field
                                                type="phone"
                                                name={requestKeys.PHONE_NUMBER}
                                                label={localeLabel(requestKeys.PHONE_NUMBER)}
                                                hasFeedback
                                                className="form-control"
                                                component={FormField}
                                                validate={[formValidations.required, formValidations.phone]}
                                                reduxChange={props.change}
                                                onChange={value =>
                                                    props.change(requestKeys.PHONE_NUMBER, value ? value : null)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    {isAdmin && props.path !== 'accounts' && (
                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <Field
                                                    type="select"
                                                    name={requestKeys.ROLE}
                                                    label={localeLabel(requestKeys.ROLE)}
                                                    hasFeedback
                                                    component={FormField}
                                                    options={adminRoles?.map(role => ({
                                                        name: role?.role,
                                                        value: role?.id,
                                                    }))}
                                                    validate={[formValidations.required]}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </li>
                            </ul>
                        </div>
                        <hr />
                        <section className="profile-detail-lower">
                            <div className="details-wrapper">
                                <Row gutter={20}>
                                    <Col span={8}>
                                        <Field
                                            type="textarea"
                                            name={requestKeys.ADDRESS}
                                            label={localeLabel(requestKeys.ADDRESS)}
                                            hasFeedback
                                            className="form-group"
                                            component={FormField}
                                        />
                                    </Col>
                                    {props.isAdmin && (
                                        <>
                                            <Col span={4}>
                                                <Field
                                                    type="switch"
                                                    name={requestKeys.EMAIL_VERIFIED}
                                                    label={localeLabel(requestKeys.EMAIL_VERIFIED)}
                                                    className="form-control"
                                                    component={FormField}
                                                />
                                            </Col>
                                            <Col span={4}>
                                                <Field
                                                    type="switch"
                                                    name={requestKeys.PHONE_NUMBER_VERIFIED}
                                                    label={localeLabel(requestKeys.PHONE_NUMBER_VERIFIED)}
                                                    className="form-control"
                                                    component={FormField}
                                                />
                                            </Col>
                                            <Col span={4}>
                                                <Field
                                                    type="switch"
                                                    name={requestKeys.IS_TEST_USER}
                                                    label={localeLabel(requestKeys.IS_TEST_USER)}
                                                    className="form-control"
                                                    component={FormField}
                                                />
                                            </Col>
                                            <Col span={4}>
                                                <Field
                                                    type="number"
                                                    step="-1"
                                                    min="-1"
                                                    name={requestKeys.DAYS_TO_ENFORCE_AUTH}
                                                    label={localeLabel(requestKeys.DAYS_TO_ENFORCE_AUTH)}
                                                    className="form-control"
                                                    component={FormField}
                                                    validate={[formValidations.number]}
                                                    info={props.t('appCommonMessages.-1MeansUnlimited', {
                                                        key: localeLabel(requestKeys.DAYS_TO_ENFORCE_AUTH),
                                                    })}
                                                />
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </div>
                        </section>
                    </Form>
                    <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                        <Form.Item
                            className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}
                        >
                            <Button
                                htmlType="submit"
                                big
                                filled
                                loading={props.isDetailsUpdating}
                                submittingButtonLabel={props.t(
                                    `customerMessages.editContact.form.submit.buttonSubmittingLabel.${
                                        props.isAdd ? 'add' : 'edit'
                                    }`
                                )}
                                className="register-btn"
                            >
                                {props.t(
                                    `customerMessages.editContact.form.submit.buttonLabel.${
                                        props.isAdd ? 'add' : 'edit'
                                    }`
                                )}
                            </Button>
                        </Form.Item>
                        <div className="clearfix" />
                    </Form>
                </div>
            )}
        </div>
    );
};

/**
 * Proptypes
 */
AddEditUserForm.propTypes = {
    change: PropTypes.func,
    defaultImage: PropTypes.string,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    imagePickerVisible: PropTypes.bool,
    isAdd: PropTypes.bool,
    isDetailsUpdating: PropTypes.bool,
    onImageSubmit: PropTypes.func,
    detailsUpdatedMessage: PropTypes.string,
    t: PropTypes.func,
    toggleImagePicker: PropTypes.func,
    userNotExistInAccount: PropTypes.bool,
    isDetailsLoading: PropTypes.bool,
    resetErrors: PropTypes.func,
    path: PropTypes.string,
    isAdmin: PropTypes.bool,
    userRoles: PropTypes.array,
    isUserRolesLoading: PropTypes?.bool,
    getUserRoles: PropTypes.func,
};

export default reduxForm({
    form: constants.ADD_EDIT_USER_FORM,
})(withTranslation()(AddEditUserForm));

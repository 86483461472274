/**
 * Organisation currency Form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import Button from 'components/shared/Button';
import FormField from 'components/shared/FormField';
import PropTypes from 'prop-types';
import useCurrencies from 'includes/hooks/useCurrencies';

const OrganisationCurrencyForm = props => {
    const { t } = useTranslation();
    const { data: currencies } = useCurrencies(false);
    const loadingOrganisationCurrencyAddEdit = useSelector(
        state => state.currencies.loadingOrganisationCurrencyAddEdit
    );

    /**
     * Get the localized label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.organisationCurrencies.addEdit.form.input.label.${field}`);

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="select"
                            name={requestKeys.ORGANISATION_CURRENCY_ID}
                            label={getLocaleLabel(requestKeys.ORGANISATION_CURRENCY_ID)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            options={currencies.map(x => ({ value: x.id, name: `${x.name} [${x.code}]` }))}
                            disabled={!props.isAdd}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="text"
                            name={requestKeys.ORGANISATION_CURRENCY_EXCHANGE_RATE}
                            label={getLocaleLabel(requestKeys.ORGANISATION_CURRENCY_EXCHANGE_RATE)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.number]}
                        />
                    </Col>
                </Row>
            </Form>
            <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={loadingOrganisationCurrencyAddEdit}
                        submittingButtonLabel={t(
                            `messages.organisationCurrencies.addEdit.form.submit.buttonSubmittingLabel.${
                                props.isAdd ? 'add' : 'edit'
                            }`
                        )}
                        className="register-btn"
                    >
                        {t(
                            `messages.organisationCurrencies.addEdit.form.submit.buttonLabel.${
                                props.isAdd ? 'add' : 'edit'
                            }`
                        )}
                    </Button>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
};

/**
 * Proptypes
 */
OrganisationCurrencyForm.propTypes = {
    error: PropTypes.string,
    initialValues: PropTypes.object,
    isAdd: PropTypes.bool,
    handleSubmit: PropTypes.func,
};

export default reduxForm({
    form: constants.ORGANISATION_CURRENCY_FORM,
})(OrganisationCurrencyForm);

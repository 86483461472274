/**
 * Notes Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { createSlice } from '@reduxjs/toolkit';
import NotesApi from 'includes/services/notes';
import { getResponse, showApiErrors, showApiSuccess } from 'includes/utils/api';
import { getPaginationData } from '../utils';
import { NOTE_DATE, NOTE_FOLLOW_UP_DATE } from 'includes/constants/keys/request';
import { isFunction } from 'lodash';

// set the initial state
const initialState = {
    loadingNoteCrud: false,
    loadingNotes: false,
    notes: [],
    notesPagination: {},
};

// define the slice
const NotesSlice = createSlice({
    name: 'notesSlice',
    initialState,
    reducers: {
        setLoadingNoteCrud(state, action) {
            state.loadingNoteCrud = action.payload;
        },
        setLoadingNotes(state, action) {
            state.loadingNotes = action.payload;
        },
        setNotes(state, action) {
            state.notes = action.payload;
        },
        setNotesPagination(state, action) {
            state.notesPagination = getPaginationData(action, true);
        },
    },
});

/**
 * Add a note
 *
 * @param {object} values Form values
 * @param {object} noteParams accountId, organisationId, contactId, invoiceId
 * @param {Function} callback Callback function
 * @param {object} searchParams Search params. Default {}
 */
export const addNote = (
    values,
    { accountId, organisationId = '', contactId = '', invoiceId = '' },
    callback = '',
    searchParams = {}
) => async dispatch => {
    try {
        dispatch(NotesSlice.actions.setLoadingNoteCrud(true));
        const result = await NotesApi.addNote(values, accountId, organisationId, contactId, invoiceId, searchParams);
        dispatch(showApiSuccess(result));
        dispatch(NotesSlice.actions.setNotes(getResponse(result, 'GET_NOTES')));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (error) {
        dispatch(showApiErrors(error, 'NOTE_FORM_NAME', 'NOTES', '', '', '', [NOTE_DATE, NOTE_FOLLOW_UP_DATE]));
    } finally {
        dispatch(NotesSlice.actions.setLoadingNoteCrud(false));
    }
};

/**
 * Delete a note
 *
 * @param {string} noteId Note Id
 * @param {object} noteParams accountId, organisationId, contactId, invoiceId
 * @param {Function} callback Callback function
 */
export const deleteNote = (
    noteId,
    { accountId, organisationId = '', contactId = '', invoiceId = '' },
    callback = ''
) => async dispatch => {
    try {
        dispatch(NotesSlice.actions.setLoadingNoteCrud(true));
        const result = await NotesApi.deleteNote(noteId, accountId, organisationId, contactId, invoiceId);
        dispatch(showApiSuccess(result));
        dispatch(NotesSlice.actions.setNotes(getResponse(result, 'GET_NOTES')));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (error) {
        dispatch(showApiErrors(error, 'NOTE_FORM_NAME', 'NOTES', '', '', '', [NOTE_DATE, NOTE_FOLLOW_UP_DATE]));
    } finally {
        dispatch(NotesSlice.actions.setLoadingNoteCrud(false));
    }
};

/**
 * Get the notes
 *
 * @param {object} noteParams accountId, organisationId, contactId, invoiceId
 * @param {object} searchParams Search Params
 */
export const getNotes = (
    { accountId, organisationId = '', contactId = '', invoiceId = '' },
    searchParams
) => async dispatch => {
    try {
        dispatch(NotesSlice.actions.setLoadingNotes(true));
        const result = await NotesApi.getNotes(accountId, organisationId, contactId, invoiceId, searchParams);
        dispatch(NotesSlice.actions.setNotes(getResponse(result, 'GET_NOTES')));
    } finally {
        dispatch(NotesSlice.actions.setLoadingNotes(false));
    }
};

/**
 * Update a note
 *
 * @param {object} values Form values
 * @param {string} noteId Note Id
 * @param {object} noteParams accountId, organisationId, contactId, invoiceId
 * @param {Function} callback Callback function
 * @param {object} searchParams Search params. Default {}
 */
export const updateNote = (
    values,
    noteId,
    { accountId, organisationId = '', contactId = '', invoiceId = '' },
    callback = '',
    searchParams = {}
) => async dispatch => {
    try {
        dispatch(NotesSlice.actions.setLoadingNoteCrud(true));
        const result = await NotesApi.updateNote(
            values,
            noteId,
            accountId,
            organisationId,
            contactId,
            invoiceId,
            searchParams
        );
        dispatch(showApiSuccess(result));
        dispatch(NotesSlice.actions.setNotes(getResponse(result, 'GET_NOTES')));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (error) {
        dispatch(showApiErrors(error, 'NOTE_FORM_NAME', 'NOTES', '', '', '', [NOTE_DATE, NOTE_FOLLOW_UP_DATE]));
    } finally {
        dispatch(NotesSlice.actions.setLoadingNoteCrud(false));
    }
};

// export the reducer
export default NotesSlice.reducer;

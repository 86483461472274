/**
 * Default settings form Component
 *
 * @version 1.0
 */

//import required modules
import { React } from '../../../includes/exports/react';
import { Form, Row, Col } from 'antd';
import moment from 'moment-timezone';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Button from '../Button';
import * as constants from '../../../includes/constants';
import CommunicationSettings from '../../CommunicationSettings';
import {
    ORGANISATION_BUSINESS_DAYS,
    ORGANISATION_AUTO_COLLECT_PAYMENTS_ON_NON_BUSINESS_DAYS,
    ORGANISATION_AUTO_SEND_REMINDERS_ON_NON_BUSINESS_DAYS,
    REMINDER_NOTIFICATION_TIME,
    REMINDER_TIMEZONE,
    TIMEZONE,
} from 'includes/constants/keys/request';
import FormField from 'components/shared/FormField';
import { required } from 'includes/utils/form';

const DefaultSettingsForm = props => {
    /**
     * Get the timezone options
     *
     * @returns array Timezones
     */
    const getTimezoneOptions = () => {
        const timezones = moment.tz.names();

        return timezones.map(timezone => {
            return { value: timezone, name: timezone };
        });
    };

    return (
        <Form layout="vertical" className="" onSubmit={props.handleSubmit}>
            <CommunicationSettings
                showDefaultEmailSettings
                showDefaultSmsSettings
                initialValues={props.initialValues}
            />
            <Row gutter={16}>
                <Col span={12}>
                    <Field
                        type="time"
                        name={REMINDER_NOTIFICATION_TIME}
                        label="Time to send notification"
                        hasFeedback
                        className="form-control"
                        component={FormField}
                        timeFormat="HH:mm"
                        minuteStep={30}
                        required
                        validate={[required]}
                        reduxChange={props.change}
                    />
                </Col>
                <Col span={12}>
                    <Field
                        type="select"
                        name={REMINDER_TIMEZONE}
                        label="Reminder Timezone"
                        options={getTimezoneOptions()}
                        className="form-control"
                        component={FormField}
                        showSearch
                        required
                        validate={[required]}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Field
                        type="select"
                        name={TIMEZONE}
                        label="Timezone"
                        hasFeedback
                        options={getTimezoneOptions()}
                        className="form-control"
                        component={FormField}
                        showSearch
                        required
                        validate={[required]}
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Field
                        type="select"
                        name={ORGANISATION_BUSINESS_DAYS}
                        label="Business days"
                        hasFeedback
                        className="form-control multiselect-field"
                        component={FormField}
                        showSearch
                        required
                        validate={[required]}
                        options={constants.WEEK_DAYS_OPTIONS.map(o => ({
                            name: o.label,
                            value: o.id,
                        }))}
                        disableEmptyOption
                        mode="multiple"
                        info="Reminders will be auto send and payment will be auto collected on business days only. If you still want to prevent auto sending reminders and auto collecting payments on a particular business day, use event calendar"
                    />
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Field
                        type="switch"
                        name={ORGANISATION_AUTO_SEND_REMINDERS_ON_NON_BUSINESS_DAYS}
                        label="Auto send reminders on non-business days"
                        hasFeedback
                        className="form-control"
                        component={FormField}
                        info="Check this if you want to auto send reminders on non-business days"
                        infoAsToolTip
                    />
                </Col>
                <Col span={12}>
                    <Field
                        type="switch"
                        name={ORGANISATION_AUTO_COLLECT_PAYMENTS_ON_NON_BUSINESS_DAYS}
                        label="Auto collect payments on non-business days"
                        hasFeedback
                        className="form-control"
                        component={FormField}
                        info="Check this if you want to auto collect payments on non-business days"
                        infoAsToolTip
                    />
                </Col>
            </Row>
            <Button htmlType="submit" big className="mrgn-tp-25" loading={props.isOrganisationSettingsUpdating}>
                Save
            </Button>
            {props.error ? (
                <div className="has-error default-form-app-error">
                    <div className="ant-form-explain">{props.error}</div>
                </div>
            ) : (
                ''
            )}
        </Form>
    );
};

/**
 * Proptypes
 */
DefaultSettingsForm.propTypes = {
    allowCustomEmailSettings: PropTypes.bool,
    change: PropTypes.func,
    error: PropTypes.any,
    defaultDomain: PropTypes.string,
    defaultEmailDomain: PropTypes.string,
    disallowedFromEmailUsernames: PropTypes.array,
    disallowedSubdomains: PropTypes.array,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isOrganisationSettingsUpdating: PropTypes.bool,
    t: PropTypes.func,
};

export default reduxForm({ form: constants.DEFAULT_SETTINGS_FORM })(DefaultSettingsForm);

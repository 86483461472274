import React from 'react';
import { Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field, getFormValues } from 'redux-form';

import { PAYMENT_SERVICE_FORM } from 'includes/constants';
import {
    COMMON_PAYMENT_SERVICE_FORM_FIELDS,
    renderPaymentServiceFormField,
} from 'components/PaymentGateways/constants';
import i18n from 'includes/i18n';
import CkEditor from 'components/shared/CkEditor';
import * as formValidations from 'includes/utils/form';

/**
 * Payment Instruction Fields
 *
 * @since 2.8.0
 */
export default function PaymentInstructionFields() {
    const formValues = useSelector(state => getFormValues(PAYMENT_SERVICE_FORM)(state));

    const dispatch = useDispatch();

    return (
        <div>
            <Divider orientation="left">
                <span className="font-medium">General</span>
            </Divider>
            <div className="grid grid-cols-2 gap-3 text-left w-full">
                {COMMON_PAYMENT_SERVICE_FORM_FIELDS.filter(field => field.name !== 'pay_button_text').map(
                    renderPaymentServiceFormField
                )}
            </div>

            <Divider orientation="left">
                <span className="font-medium">Instruction</span>
            </Divider>
            <div>
                <Field
                    step="any"
                    name={'payment_instructions'}
                    label={i18n.t('customerMessages.paymentServices.form.label.paymentInstructions')}
                    hasFeedback
                    fieldKey="payment_instructions"
                    change={(field, value) => dispatch(change(PAYMENT_SERVICE_FORM, field, value))}
                    className="form-control"
                    message={formValues?.payment_instructions}
                    component={CkEditor}
                    validate={[formValidations.required]}
                    required
                />
            </div>
        </div>
    );
}

import React from 'react';
import PropTypes from 'prop-types';

/**
 * Renders a badge indicating the provider
 *
 * @since 2.8.0
 */
export default function ProviderLabel({ name, color }) {
    return (
        <span
            className={`bg-gray-100 text-xs font-medium me-2 px-2.5 py-0.5 rounded`}
            style={{
                color: color ?? 'black',
            }}
        >
            {name}
        </span>
    );
}

ProviderLabel.propTypes = {
    color: PropTypes.string,
    name: PropTypes.string,
};

/**
 * My account component
 * Handles the account view and functions related to account
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { React, connect, PropTypes, withTranslation } from '../../../includes/exports/react';
import './styles.scss';
import RedirectingPageLoaderSection from 'components/RedirectingPageLoaderSection';
import useRedirectToAuthPortalRoute from 'includes/hooks/useRedirectToAuthPortalRoute';
import { useSelector } from 'react-redux';

const MyAccount = props => {
    const accountDetails = useSelector(state => state.account.accountDetails);
    useRedirectToAuthPortalRoute(`account/${accountDetails?.id}`);

    return (
        <div className="home-content-wrapper my-profile-detail">
            <h2 className="page-title">{props.t('sharedMessages.myAccount.title')}</h2>
            <RedirectingPageLoaderSection />
        </div>
    );
};

MyAccount.propTypes = {
    t: PropTypes.func,
};

export default connect()(withTranslation()(MyAccount));

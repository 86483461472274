/**
 * Organisation Reducer
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';
import { get } from 'lodash';
import * as successMappings from '../../../../constants/mappings/success';
import { getLocalStorageValue, getPaginationData } from '../../../../utils/index';
import { USER_SELECTED_ORGANISATION_ID_KEY } from '../../../../constants';

const INITIAL_STATE = {
    apiRequestedOnLoad: false,
    arrangementDuePerDay: {},
    arrangementDuePerDayViewPortMaxMinDates: {},
    arrangementDueTotal: {},
    averagePaymentDays: 0,
    cashCollectedPerDay: {},
    cashCollectedPerDayViewPortMaxMinDates: {},
    cashCollectedTotal: {},
    countDataAvailableMinDate: 0,
    debtAgeData: {},
    debtBandData: {},
    debtorAccounts: [],
    debtorAccountsPagination: {},
    invoiceTypeCount: {},
    invoiceStatusCount: {},
    isArrangementDueDataLoading: false,
    isCashCollectedDataLoading: false,
    isContactInvoiceStatusTypeCountLoading: false,
    isDeletingOrganisation: false,
    isDetailsLoading: false,
    isGraphDataLoading: true,
    isLoading: true,
    isSwitchingOrganisation: false,
    loadingDebtorAccounts: false,
    message: '',
    organisations: [],
    overdueCount: 0,
    organisationDetails: {},
    paymentArrangementCount: 0,
    paymentExtensionCount: 0,
    reauthorizeOrganisationsCount: 0,
    selectedOrganisation: {},
    selectedOrganisationId: getLocalStorageValue(USER_SELECTED_ORGANISATION_ID_KEY),
    organisationCountries: [],
    isSyncingOrganisation: false,
    isCurrenciesLoading: false,
    currencies: [],
    paginationOrganisations: {},
    invoiceCurrencyBreakdown: {},
};

/**
 * Organisation Reducer Function
 * Handles the state changes
 *
 * @param   {object}    state   Current State
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function organisationReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case actionTypes.GET_ARRANGEMENT_DUE_DATA_FAILURE: {
            return {
                ...state,
                arrangementDue: {},
                arrangementDuePerDayViewPortMaxMinDates: {},
                isArrangementDueDataLoading: false,
            };
        }
        case actionTypes.GET_ARRANGEMENT_DUE_DATA_REQUEST:
            return {
                ...state,
                isArrangementDueDataLoading: true,
                arrangementDue: {},
                arrangementDuePerDayViewPortMaxMinDates: {},
            };
        case actionTypes.GET_ARRANGEMENT_DUE_DATA_SUCCESS:
            return {
                ...state,
                arrangementDueTotal: get(action.result, successMappings.ARRANGEMENT_DUE_TOTAL),
                arrangementDuePerDay: get(action.result, successMappings.ARRANGEMENT_DUE_PER_DAY_DATA, {}),
                arrangementDuePerDayViewPortMaxMinDates: get(
                    action.result,
                    successMappings.ARRANGEMENT_DUE_PER_DAY_VIEW_PORT_MAX_MIN_DATES,
                    {}
                ),
                isArrangementDueDataLoading: false,
            };
        case actionTypes.GET_CASH_COLLECTED_DATA_FAILURE: {
            return {
                ...state,
                averagePaymentDays: 0,
                cashCollectedPerDay: {},
                cashCollectedPerDayViewPortMaxMinDates: {},
                isCashCollectedDataLoading: false,
            };
        }
        case actionTypes.GET_CASH_COLLECTED_DATA_REQUEST:
            return {
                ...state,
                averagePaymentDays: 0,
                isCashCollectedDataLoading: true,
                cashCollectedPerDay: {},
                cashCollectedPerDayViewPortMaxMinDates: {},
            };
        case actionTypes.GET_CASH_COLLECTED_DATA_SUCCESS:
            return {
                ...state,
                averagePaymentDays: get(action.result, successMappings.CASH_COLLECTED_AVERAGE_PAYMENT_DAYS, 0),
                paymentOverview: {
                    ...state.paymentOverview,
                    total_paid_invoices: get(action.result, successMappings.CASH_COLLECTED_TOTAL),
                },
                cashCollectedPerDay: get(action.result, successMappings.CASH_COLLECTED_PER_DAY_DATA, {}),
                cashCollectedPerDayViewPortMaxMinDates: get(
                    action.result,
                    successMappings.CASH_COLLECTED_PER_DAY_VIEW_PORT_MAX_MIN_DATES,
                    {}
                ),
                cashCollectedTotal: get(action.result, successMappings.CASH_COLLECTED_TOTAL),
                isCashCollectedDataLoading: false,
            };
        case actionTypes.GET_CONTACT_INVOICE_STATUS_TYPE_DATA_FAILURE: {
            return {
                ...state,
                invoiceTypeCount: {},
                invoiceStatusCount: {},
                isContactInvoiceStatusTypeCountLoading: false,
            };
        }
        case actionTypes.GET_CONTACT_INVOICE_STATUS_TYPE_DATA_REQUEST:
            return {
                ...state,
                isContactInvoiceStatusTypeCountLoading: true,
                invoiceTypeCount: {},
                invoiceStatusCount: {},
            };
        case actionTypes.GET_CONTACT_INVOICE_STATUS_TYPE_DATA_SUCCESS:
            return {
                ...state,
                invoiceTypeCount: get(
                    action.result,
                    successMappings.CONTACT_INVOICE_STATUS_TYPE_INVOICE_TYPE_COUNT,
                    {}
                ),
                invoiceStatusCount: get(
                    action.result,
                    successMappings.CONTACT_INVOICE_STATUS_TYPE_INVOICE_STATUS_COUNT,
                    {}
                ),
                overdueCount: get(action.result, successMappings.CONTACT_INVOICE_STATUS_TYPE_OVERDUE_COUNT, 0),
                isContactInvoiceStatusTypeCountLoading: false,
            };
        case actionTypes.GET_DASHBOARD_DATA_FAILURE: {
            return {
                ...state,
                arrangementDuePerDay: {},
                arrangementDuePerDayViewPortMaxMinDates: {},
                averagePaymentDays: 0,
                cashCollectedPerDay: {},
                cashCollectedPerDayViewPortMaxMinDates: {},
                countDataAvailableMinDate: 0,
                invoiceTypeCount: {},
                invoiceStatusCount: {},
                paymentArrangementCount: 0,
                paymentExtensionCount: 0,
                paymentOverview: {},
                invoiceCurrencyBreakdown: {},
                isGraphDataLoading: false,
            };
        }
        case actionTypes.GET_DASHBOARD_DATA_REQUEST:
            return {
                ...state,
                isGraphDataLoading: true,
                arrangementDuePerDay: {},
                arrangementDuePerDayViewPortMaxMinDates: {},
                averagePaymentDays: 0,
                cashCollectedPerDay: {},
                cashCollectedPerDayViewPortMaxMinDates: {},
                countDataAvailableMinDate: 0,
                invoiceTypeCount: {},
                invoiceStatusCount: {},
                paymentArrangementCount: 0,
                paymentExtensionCount: 0,
                paymentOverview: {},
                invoiceCurrencyBreakdown: {},
            };
        case actionTypes.GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                arrangementDueTotal: get(action.result, successMappings.DASHBOARD_DATA_ARRANGEMENT_DUE_TOTAL),
                arrangementDuePerDay: get(action.result, successMappings.DASHBOARD_DATA_ARRANGEMENT_DUE_PER_DAY, {}),
                arrangementDuePerDayViewPortMaxMinDates: get(
                    action.result,
                    successMappings.DASHBOARD_DATA_ARRANGEMENT_DUE_PER_DAY_VIEW_PORT_MAX_MIN_DATES,
                    {}
                ),
                averagePaymentDays: get(action.result, successMappings.DASHBOARD_DATA_AVERAGE_PAYMENT_DAYS, 0),
                cashCollectedPerDay: get(action.result, successMappings.DASHBOARD_DATA_CASH_COLLECTED_PER_DAY, {}),
                cashCollectedPerDayViewPortMaxMinDates: get(
                    action.result,
                    successMappings.DASHBOARD_DATA_CASH_COLLECTED_PER_DAY_VIEW_PORT_MAX_MIN_DATES,
                    {}
                ),
                cashCollectedTotal: get(action.result, successMappings.DASHBOARD_DATA_CASH_COLLECTED_TOTAL),
                countDataAvailableMinDate: get(
                    action.result,
                    successMappings.DASHBOARD_DATA_COUNT_AVAILABLE_MIN_DATE,
                    0
                ),
                invoiceTypeCount: get(action.result, successMappings.DASHBOARD_DATA_INVOICE_TYPE_COUNT, {}),
                invoiceStatusCount: get(action.result, successMappings.DASHBOARD_DATA_INVOICE_STATUS_COUNT, {}),
                paymentArrangementCount: get(
                    action.result,
                    successMappings.DASHBOARD_DATA_PAYMENT_ARRANGEMENT_COUNT,
                    0
                ),
                paymentExtensionCount: get(action.result, successMappings.DASHBOARD_DATA_PAYMENT_EXTENSION_COUNT, 0),
                paymentOverview: get(action.result, successMappings.DASHBOARD_DATA_PAYMENT_OVERVIEW, {}),
                invoiceCurrencyBreakdown: get(
                    action.result,
                    successMappings.DASHBOARD_DATA_INVOICE_CURRENCY_BREAKDOWN,
                    {}
                ),
                isGraphDataLoading: false,
            };
        case actionTypes.GET_DEBT_AGE_DEBT_BAND_DATA_FAILURE: {
            return {
                ...state,
                debtAgeData: {},
                debtBandData: {},
                isGraphDataLoading: false,
            };
        }
        case actionTypes.GET_DEBT_AGE_DEBT_BAND_DATA_REQUEST: {
            return {
                ...state,
                isGraphDataLoading: true,
                debtAgeData: {},
                debtBandData: {},
            };
        }
        case actionTypes.GET_DEBT_AGE_DEBT_BAND_DATA_SUCCESS: {
            return {
                ...state,
                debtAgeData: get(action.result, successMappings.DEBT_AGE_DATA, {}),
                debtBandData: get(action.result, successMappings.DEBT_BAND_DATA, {}),
                isGraphDataLoading: false,
            };
        }
        case actionTypes.GET_DEBTOR_ACCOUNTS_FAILURE: {
            return {
                ...state,
                loadingDebtorAccounts: false,
            };
        }
        case actionTypes.GET_DEBTOR_ACCOUNTS_REQUEST: {
            return {
                ...state,
                loadingDebtorAccounts: true,
                debtorAccountsPagination: {},
                debtorAccounts: [],
            };
        }
        case actionTypes.GET_DEBTOR_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                debtorAccounts: get(action.result, successMappings.DEBTOR_ACCOUNTS, []),
                debtorAccountsPagination: getPaginationData(action),
                loadingDebtorAccounts: false,
            };
        }
        case actionTypes.SWITCHING_ORGANISATION_REQUEST: {
            return {
                ...state,
                isSwitchingOrganisation: true,
            };
        }
        case actionTypes.GET_SELECTED_ORGANISATION_SUCCESS: {
            return {
                ...state,
                selectedOrganisation: {
                    ...action.selectedOrganisation,
                    reauthorize: get(action, 'reauthorize', get(action, 'selectedOrganisation.reauthorize', false)),
                },
                selectedOrganisationId: action.selectedOrganisationId,
                isSwitchingOrganisation: false,
            };
        }
        case actionTypes.GET_ORGANISATIONS_FAILURE:
            return {
                ...state,
                organisations: [],
                message: '',
                isLoading: false,
                paginationOrganisations: {},
            };
        case actionTypes.GET_ORGANISATIONS_REQUEST:
            return {
                ...state,
                message: '',
                organisations: [],
                isLoading: true,
                paginationOrganisations: {},
            };
        case actionTypes.GET_ORGANISATION_BY_ACCID_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case actionTypes.GET_ORGANISATIONS_SUCCESS: {
            return {
                ...state,
                message: get(action.result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                organisations: get(action.result, successMappings.GET_ORGANISATIONS),
                paginationOrganisations: getPaginationData(action),
                isLoading: false,
            };
        }
        case actionTypes.GET_ORGANISATION_DETAILS_REQUEST: {
            return {
                ...state,
                isDetailsLoading: true,
            };
        }
        case actionTypes.GET_ORGANISATION_DETAILS_SUCCESS: {
            return {
                ...state,
                organisationDetails: get(action.result, successMappings.GET_ORGANISATION_DETAILS),
                selectedOrganisation: get(action.result, successMappings.GET_ORGANISATION_DETAILS),
                isDetailsLoading: false,
            };
        }
        case actionTypes.GET_ORGANISATION_DETAILS_FAILURE: {
            return {
                ...state,
                isDetailsLoading: false,
            };
        }
        case actionTypes.SET_SELECTED_ORGANISATION_AFTER_DELETE_SUCCESS: {
            return {
                ...state,
                selectedOrganisation: action.firstOrganisation,
                selectedOrganisationId: action.firstOrganisation.id,
                isLoading: false,
                isSwitchingOrganisation: false,
            };
        }
        case actionTypes.SET_SELECTED_ORGANISATION_FAILURE: {
            return {
                ...state,
                isLoading: false,
                isSwitchingOrganisation: false,
                isSyncingOrganisation: false,
            };
        }
        case actionTypes.SET_SELECTED_ORGANISATION_REQUEST: {
            return {
                ...state,
                isLoading: true,
                isSwitchingOrganisation: true,
                isSyncingOrganisation: false,
            };
        }
        case actionTypes.SET_SELECTED_ORGANISATION_SUCCESS: {
            return {
                ...state,
                selectedOrganisation: get(action.result, successMappings.GET_ORGANISATION_DETAILS, {}),
                selectedOrganisationId: get(action.result, successMappings.GET_ORGANISATION_DETAILS_ID),
                isLoading: false,
                isSwitchingOrganisation: false,
            };
        }
        case actionTypes.GET_REAUTHORIZE_ORGANISATIONS_COUNT_SUCCESS:
            return {
                ...state,
                reauthorizeOrganisationsCount: action.reauthorizeOrganisationsCount,
            };
        case actionTypes.ORGANISATION_REAUTHORIZATION_REQUIRED: {
            return {
                ...state,
                isSyncingOrganisation: false,
                selectedOrganisation: {
                    ...state.selectedOrganisation,
                    is_syncing: false,
                    reauthorize: true,
                },
            };
        }
        case actionTypes.ORGANISATION_UPDATE_REQUEST:
            return {
                ...state,
                isUpdating: true,
            };
        case actionTypes.ORGANISATION_UPDATE_SUCCESS:
            return {
                ...state,
                isUpdating: false,
                organisationDetails: get(action.result, successMappings.GET_ORGANISATION_DETAILS),
                selectedOrganisation:
                    get(action.result, successMappings.GET_ORGANISATION_DETAILS_ID) === state.selectedOrganisation.id
                        ? get(action.result, successMappings.GET_ORGANISATION_DETAILS)
                        : state.selectedOrganisation,
            };
        case actionTypes.ORGANISATION_UPDATE_FAILURE:
            return {
                ...state,
                isUpdating: false,
            };
        case actionTypes.ORGANISATION_DELETE_FAILURE:
            return {
                ...state,
                isDeletingOrganisation: false,
            };
        case actionTypes.ORGANISATION_DELETE_REQUEST:
            return {
                ...state,
                isDeletingOrganisation: true,
            };
        case actionTypes.ORGANISATION_DELETE_SUCCESS:
            return {
                ...state,
                organisations: get(action.result, successMappings.GET_ORGANISATIONS),
                isDeletingOrganisation: false,
            };
        case actionTypes.ORGANISATION_SELECTED_ORGANISATION_DATA_FETCHING_UPDATE: {
            return {
                ...state,
                selectedOrganisation: { ...state.selectedOrganisation, is_fetching_data: false },
            };
        }
        case actionTypes.GET_ALL_CONTACT_INVOICES_SUCCESS: {
            return {
                ...state,
                organisationDetails: get(action.result, successMappings.GET_ORGANISATION_DETAILS),
            };
        }
        case actionTypes.RESET_SELECTED_ORGANISATION_REQUEST: {
            return {
                ...state,
                selectedOrganisation: {},
                selectedOrganisationId: '',
            };
        }
        case actionTypes.SYNC_ORGANISATION_FAILURE: {
            return {
                ...state,
                isSyncingOrganisation: false,
                selectedOrganisation: { ...state.selectedOrganisation, reauthorize: action.shouldReauthorize },
            };
        }
        case actionTypes.SAVE_PAYMENT_ACCOUNT_SUCCESS: {
            return {
                ...state,
                selectedOrganisation: { ...state.selectedOrganisation, has_payment_account_set: true },
            };
        }
        case actionTypes.SYNC_ORGANISATION_REQUEST: {
            return {
                ...state,
                isSyncingOrganisation: true,
            };
        }
        case actionTypes.SYNC_ORGANISATION_COMPLETE: {
            return {
                ...state,
                isSyncingOrganisation: false,
                selectedOrganisation: {
                    ...state.selectedOrganisation,
                    is_syncing: false,
                    last_synced_date_time: action.organisation.last_synced_date_time,
                },
            };
        }
        case actionTypes.ORGANISATION_DATA_FETCHING_COMPLETE: {
            return {
                ...state,
                isSyncingOrganisation: false,
                selectedOrganisation: {
                    ...state.selectedOrganisation,
                    is_syncing: false,
                    last_synced_date_time: action.organisation.last_synced_date_time,
                },
            };
        }
        case actionTypes.EXPORT_ORGANISATION_REQUEST: {
            return { ...state, isExportLoading: true };
        }
        case actionTypes.EXPORT_ORGANISATION_SUCCESS: {
            return { ...state, isExportLoading: false };
        }
        case actionTypes.EXPORT_ORGANISATION_FAILURE: {
            return { ...state, isExportLoading: false };
        }
        case actionTypes.EXPORT_ACCOUNT_ORGANISATIONS_REQUEST: {
            return { ...state, isExportLoading: true };
        }
        case actionTypes.EXPORT_ACCOUNT_ORGANISATIONS_FAILURE: {
            return { ...state, isExportLoading: false };
        }
        case actionTypes.EXPORT_ACCOUNT_ORGANISATIONS_SUCCESS: {
            return { ...state, isExportLoading: false };
        }
        case actionTypes.UPDATE_ORGANISATION_SETTINGS_REQUEST:
            return {
                ...state,
                isOrganisationSettingsUpdating: true,
            };
        case actionTypes.UPDATE_ORGANISATION_SETTINGS_FAILURE:
            return {
                ...state,
                isOrganisationSettingsUpdating: false,
            };
        case actionTypes.UPDATE_ORGANISATION_SETTINGS_SUCCESS:
            return {
                ...state,
                isOrganisationSettingsUpdating: false,
                selectedOrganisation: get(action.result, successMappings.GET_ORGANISATION_DETAILS_BY_SETTINGS),
            };
        case actionTypes.GET_CURRENCIES_REQUEST:
            return {
                ...state,
                isCurrenciesLoading: true,
            };
        case actionTypes.GET_CURRENCIES_SUCCESS:
            return {
                ...state,
                isCurrenciesLoading: false,
                currencies: get(action.result, successMappings.GET_CURRENCIES),
            };
        case actionTypes.GET_CURRENCIES_FAILURE:
            return {
                ...state,
                isCurrenciesLoading: false,
            };
        case actionTypes.ORGANISATION_ADD_REQUEST:
            return {
                ...state,
                isCreatingOrganisation: true,
                isSwitchingOrganisation: true,
            };
        case actionTypes.ORGANISATION_ADD_FAILURE:
        case actionTypes.ORGANISATION_ADD_SUCCESS:
            return {
                ...state,
                isCreatingOrganisation: false,
                isSwitchingOrganisation: false,
            };
        case actionTypes.VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_FAILURE:
            return {
                ...state,
                isSyncingOrganisation: false,
            };
        case actionTypes.VERIFY_ACCOUNTING_SOFTWARE_AUTHORIZATION_REQUEST:
            return {
                ...state,
                isSyncingOrganisation: true,
            };
        default:
            return state;
    }
}

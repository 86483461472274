import i18n from 'includes/i18n';

/**
 * Get chart of account transaction columns
 */
export const getChartOfAccountTransactionsTableColumns = [
    {
        title: i18n.t('messages.chartOfAccounts.transactions.list.label.description'),
        dataIndex: 'description',
        key: 'description',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.chartOfAccounts.transactions.list.label.date'),
        dataIndex: 'date',
        key: 'date',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.chartOfAccounts.transactions.list.label.credit'),
        dataIndex: 'credit',
        key: 'credit',
    },
    {
        title: i18n.t('messages.chartOfAccounts.transactions.list.label.debit'),
        dataIndex: 'debit',
        key: 'debit',
    },
];

/**
 * Get chart of accounts columns
 */
export const getChartOfAccountsTableColumns = [
    {
        title: i18n.t('messages.chartOfAccounts.list.label.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.chartOfAccounts.list.label.code'),
        dataIndex: 'code',
        key: 'code',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.chartOfAccounts.list.label.accountType'),
        dataIndex: 'account_type_id',
        key: 'account_type_id',
        sorter: true,
    },
    {
        title: i18n.t('messages.chartOfAccounts.list.label.transactionType'),
        dataIndex: 'transaction_type',
        key: 'transaction_type',
        sorter: true,
    },
    {
        title: i18n.t('messages.chartOfAccounts.list.label.credit'),
        dataIndex: 'total_credit_amount',
        key: 'total_credit_amount',
        sorter: true,
    },
    {
        title: i18n.t('messages.chartOfAccounts.list.label.debit'),
        dataIndex: 'total_debit_amount',
        key: 'total_debit_amount',
        sorter: true,
    },
    {
        title: i18n.t('messages.chartOfAccounts.list.label.createdDate'),
        dataIndex: 'created_date',
        key: 'created_date',
        sorter: true,
    },
];

/**
 * Mobile Filter and Sorter component for Scrollable table
 * This is a component responsible for handling filtering and sorting in mobile view
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

import PropTypes from 'prop-types';
import { React, useState, useRef, useEffect } from '../../../includes/exports/react';
import { Button, Collapse, Icon, Input, Radio } from 'antd';
import get from 'lodash/get';

const { Panel } = Collapse;

const MobileFilterSortView = props => {
    const { filters, setFilters } = props;
    const { rangeFilters, setRangeFilters } = props;
    const [rangeFilterValues, setRangeFilterValues] = useState(rangeFilters);
    const [activeFilterPanel, setActiveFilterPanel] = useState('');

    const [activeCollapse, setActiveCollapse] = useState([]);

    const filterRef = useRef(null);
    const rangeFilterRef = useRef(null);
    const sortFieldRef = useRef(null);

    const sortOrderRef = useRef(null);

    // handle closing of filter popover
    const handleClickOutside = event => {
        if (
            [filterRef, rangeFilterRef, sortFieldRef, sortOrderRef].reduce((acc, currentVal) => {
                return acc && currentVal.current && !currentVal.current.contains(event.target);
            }, true)
        ) {
            setActiveFilterPanel('');
            setActiveCollapse([]);
        }
    };
    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <section className={`filter-sort-list ${props.mobileClassName}`}>
            <div className="two-sided-wrapper">
                <div className="full-wrapper">
                    {props.filterColumns.length > 0 && (
                        <div className="two-side-part" ref={filterRef}>
                            <Collapse
                                bordered={false}
                                expandIconPosition="right"
                                expandIcon={({ isActive }) => <Icon type="caret-down" rotate={isActive ? 90 : 0} />}
                                className="half-wrapper table-filter-collapse"
                                accordion
                                onChange={pan => setActiveCollapse(pan)}
                                activeKey={activeCollapse}
                            >
                                <Panel header="Filter by" key="filterBy" className="main-title">
                                    {props.filterColumns.map(column => {
                                        const columnFilterOptions = get(column, 'filters', []);
                                        const isActive = activeFilterPanel === column.dataIndex;
                                        return (
                                            columnFilterOptions.length > 0 && (
                                                <section
                                                    className={isActive ? 'dropdown-active' : ''}
                                                    key={column.dataIndex}
                                                >
                                                    <div
                                                        className="sub-filter"
                                                        onClick={() => {
                                                            setActiveFilterPanel(isActive ? '' : column.dataIndex);
                                                        }}
                                                    >
                                                        <label>{column.title}</label>
                                                        <Icon type="filter" className="custom-filter" />
                                                        <Icon type="caret-right" rotate={isActive ? -90 : 90} />
                                                    </div>
                                                    <Radio.Group
                                                        value={get(filters, column.dataIndex, '')}
                                                        options={columnFilterOptions.map(f => ({
                                                            ...f,
                                                            label: f.text,
                                                        }))}
                                                        className={isActive ? '' : 'hide-comp'}
                                                        onChange={e => {
                                                            setFilters({
                                                                ...filters,
                                                                [column.dataIndex]: e.target.value,
                                                            });
                                                            // setActiveFilterPanel('');
                                                        }}
                                                    />
                                                </section>
                                            )
                                        );
                                    })}
                                </Panel>
                            </Collapse>
                        </div>
                    )}
                    {props.rangFilterColumns.length > 0 && (
                        <div className="two-side-part" ref={rangeFilterRef}>
                            <Collapse
                                bordered={false}
                                expandIconPosition="right"
                                expandIcon={({ isActive }) => <Icon type="caret-down" rotate={isActive ? 90 : 0} />}
                                className="half-wrapper table-filter-collapse"
                                accordion
                                onChange={pan => setActiveCollapse(pan)}
                                activeKey={activeCollapse}
                            >
                                <Panel header="Range Filter by" key="rangeFilterBy" className="main-title">
                                    {props.rangFilterColumns.map(column => {
                                        const columnFilterOptions = get(column, 'rangeFilters', []);
                                        const rangeFilterType = get(column, 'rangeFilterType');
                                        const rangeFilterBetweenLabel = get(column, 'rangeFilterBetweenLabel');
                                        const isActive = activeFilterPanel === column.dataIndex;
                                        const rangeFilterCount = columnFilterOptions.length;
                                        return (
                                            columnFilterOptions.length > 0 && (
                                                <section
                                                    className={isActive ? 'dropdown-active' : ''}
                                                    key={column.dataIndex}
                                                >
                                                    <div
                                                        className="sub-filter"
                                                        onClick={() => {
                                                            setActiveFilterPanel(isActive ? '' : column.dataIndex);
                                                        }}
                                                    >
                                                        <label>
                                                            {column?.filterLabel ? column.filterLabel : column.title}
                                                        </label>
                                                        <Icon type="filter" className="custom-filter" />
                                                        <Icon type="caret-right" rotate={isActive ? -90 : 90} />
                                                    </div>
                                                    <Input.Group
                                                        compact
                                                        className={isActive ? '' : 'hide-range-filter'}
                                                    >
                                                        <span
                                                            style={{
                                                                width: '100%',
                                                                pointerEvents: 'none',
                                                                textAlign: 'center',
                                                                padding: '5px 0',
                                                            }}
                                                        >
                                                            {rangeFilterBetweenLabel || 'Between'}
                                                        </span>
                                                        {columnFilterOptions.map((rangeFilter, index) => (
                                                            <React.Fragment key={index}>
                                                                <Input
                                                                    type={rangeFilterType}
                                                                    value={get(rangeFilterValues, rangeFilter.key)}
                                                                    onChange={e =>
                                                                        setRangeFilterValues({
                                                                            ...rangeFilterValues,
                                                                            [rangeFilter.key]: e.target.value,
                                                                        })
                                                                    }
                                                                    placeholder={rangeFilter.label}
                                                                />
                                                                {rangeFilterCount > index + 1 && (
                                                                    <Input
                                                                        className="site-input-split"
                                                                        style={{
                                                                            borderLeft: 0,
                                                                            borderRight: 0,
                                                                            pointerEvents: 'none',
                                                                            textAlign: 'center',
                                                                        }}
                                                                        placeholder="&"
                                                                        disabled
                                                                    />
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </Input.Group>
                                                    <div className={isActive ? '' : 'hide-range-filter'}>
                                                        <Button
                                                            className="align-left"
                                                            type="link"
                                                            onClick={() => {
                                                                setRangeFilters({
                                                                    [column.dataIndex]: rangeFilterValues,
                                                                });
                                                            }}
                                                        >
                                                            OK
                                                        </Button>
                                                        <Button
                                                            className="align-right"
                                                            type="link"
                                                            onClick={() => {
                                                                setRangeFilterValues({});
                                                                setRangeFilters({});
                                                                setActiveFilterPanel('');
                                                                setActiveCollapse([]);
                                                            }}
                                                        >
                                                            Reset
                                                        </Button>
                                                    </div>
                                                </section>
                                            )
                                        );
                                    })}
                                </Panel>
                            </Collapse>
                        </div>
                    )}
                </div>
                {props.sorterColumns.length > 0 && (
                    <>
                        <div className="full-wrapper">
                            <div className="two-side-part" ref={sortFieldRef}>
                                <Collapse
                                    bordered={false}
                                    expandIconPosition="right"
                                    expandIcon={({ isActive }) => <Icon type="caret-down" rotate={isActive ? 90 : 0} />}
                                    className="half-wrapper align-right"
                                    accordion
                                    onChange={pan => setActiveCollapse(pan)}
                                    activeKey={activeCollapse}
                                >
                                    <Panel header="Sort by" key="sortBy" className="main-title">
                                        <Radio.Group
                                            options={props.sorterColumns.map(column => ({
                                                label: column.title,
                                                value: column.dataIndex,
                                            }))}
                                            onChange={e => props.setSortField(e.target.value)}
                                            value={props.sortField}
                                        />
                                    </Panel>
                                </Collapse>
                            </div>
                            <div className="two-side-part" ref={sortOrderRef}>
                                <Collapse
                                    bordered={false}
                                    expandIconPosition="right"
                                    expandIcon={({ isActive }) => <Icon type="caret-down" rotate={isActive ? 90 : 0} />}
                                    className="half-wrapper align-right"
                                    accordion
                                    onChange={pan => setActiveCollapse(pan)}
                                    activeKey={activeCollapse}
                                >
                                    <Panel header="Sort order" key="sortOrder" className="main-title">
                                        <Radio.Group
                                            value={props.sortOrder}
                                            options={[
                                                { label: 'Asc', value: 1 },
                                                { label: 'Desc', value: 0 },
                                            ]}
                                            onChange={e => props.setSortOrder(e.target.value)}
                                        />
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

MobileFilterSortView.propTypes = {
    activeFilterPanel: PropTypes.string,
    filterColumns: PropTypes.array,
    filters: PropTypes.object,
    forwardRef: PropTypes.object,
    mobileClassName: PropTypes.string,
    rangFilterColumns: PropTypes.array,
    rangeFilters: PropTypes.object,
    setActiveFilterPanel: PropTypes.func,
    setFilters: PropTypes.func,
    setRangeFilters: PropTypes.func,
    setSortField: PropTypes.func,
    setSortOrder: PropTypes.func,
    sortField: PropTypes.string,
    sortOrder: PropTypes.number,
    sorterColumns: PropTypes.array,
};

export default MobileFilterSortView;

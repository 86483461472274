/**
 * Reminders Reducer
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import { findIndex, get } from 'lodash';
import moment from 'moment';

import * as actionTypes from '../../../../constants/actionTypes';
import * as successMappings from '../../../../constants/mappings/success';
import { getPaginationData } from '../../../../utils';

const FILTERS = {
    campaignFilter: '',
    contactTypeFilter: '',
    dateFilter: moment(),
    dateFilterType: 'date',
    dateRangeFilter: [moment(), moment()],
    reminderTypeFilter: 'all',
};

const INITIAL_STATE = {
    campaignDays: [],
    campaignDetails: {},
    forceCallApiFromTable: false,
    isAddingCampaign: false,
    isCampaignDaysLoading: false,
    isCampaignStatusChanging: false,
    isLoading: false,
    isSendingOnDemandCampaign: false,
    isSendingReminder: false,
    isUpdatingCampaign: false,
    paginationCampaign: {},
    paginationReminders: {},
    reminders: [],
    scheduler: [],
    ...FILTERS,
};

/**
 * Reminder Reducer Function
 * Handles the state changes in reminder
 *
 * @param   {object}    state   Current State
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function reminderReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case actionTypes.ADD_CAMPAIGN_FAILURE:
            return {
                ...state,
                isAddingCampaign: false,
            };
        case actionTypes.ADD_CAMPAIGN_REQUEST:
            return {
                ...state,
                isAddingCampaign: true,
            };
        case actionTypes.ADD_CAMPAIGN_SUCCESS:
            return {
                ...state,
                isAddingCampaign: false,
            };
        case actionTypes.CHANGE_CAMPAIGN_STATUS_FAILURE:
            return {
                ...state,
                isCampaignStatusChanging: false,
            };
        case actionTypes.CHANGE_CAMPAIGN_STATUS_REQUEST:
            return {
                ...state,
                isCampaignStatusChanging: true,
            };
        case actionTypes.CHANGE_CAMPAIGN_STATUS_SUCCESS:
        case actionTypes.GET_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                scheduler: get(action.result, successMappings.GET_SCHEDULERS),
                paginationCampaign: getPaginationData(action),
                isLoading: false,
                isCampaignStatusChanging: false,
            };
        case actionTypes.GET_CAMPAIGNS_REQUEST:
            return {
                ...state,
                paginationCampaign: {},
                isLoading: true,
            };
        case actionTypes.GET_CAMPAIGNS_FAILURE:
            return {
                ...state,
                paginationCampaign: {},
                isLoading: false,
            };
        case actionTypes.GET_CAMPAIGN_DAYS_FAILURE:
            return {
                ...state,
                isCampaignDaysLoading: false,
            };
        case actionTypes.GET_CAMPAIGN_DAYS_REQUEST:
            return {
                ...state,
                isCampaignDaysLoading: true,
            };
        case actionTypes.GET_CAMPAIGN_DAYS_SUCCESS:
            return {
                ...state,
                campaignDays: get(action.result, successMappings.GET_CAMPAIGN_DAYS),
                isCampaignDaysLoading: false,
            };
        case actionTypes.GET_CAMPAIGN_DETAILS_FAILURE:
            return {
                ...state,
                isCampaignLoading: false,
            };
        case actionTypes.GET_CAMPAIGN_DETAILS_REQUEST:
            return {
                ...state,
                isCampaignLoading: true,
            };
        case actionTypes.GET_CAMPAIGN_DETAILS_SUCCESS:
            return {
                ...state,
                campaignDetails: get(action.result, successMappings.GET_SCHEDULER_DETAILS),
                isCampaignLoading: false,
            };
        case actionTypes.GET_INVOICE_DETAILS_REQUEST: {
            return {
                ...state,
                forceCallApiFromTable: true,
            };
        }
        case actionTypes.GET_REMINDERS_FAILURE:
            return {
                ...state,
                reminders: [],
                paginationReminders: {},
                isLoading: false,
            };
        case actionTypes.GET_REMINDERS_REQUEST:
            return {
                ...state,
                reminders: [],
                reminderCounts: {},
                paginationReminders: {},
                isLoading: true,
                forceCallApiFromTable: false,
            };
        case actionTypes.GET_REMINDERS_SUCCESS:
            return {
                ...state,
                reminders: get(action.result, successMappings.GET_REMINDERS),
                reminderCounts: get(action.result, successMappings.GET_REMINDER_COUNTS),
                paginationReminders: getPaginationData(action),
                isLoading: false,
            };
        case actionTypes.GET_REMINDER_DETAILS_FAILURE:
            return {
                ...state,
                isReminderLoading: false,
                reminderDetails: {},
            };
        case actionTypes.GET_REMINDER_DETAILS_REQUEST:
            return {
                ...state,
                isReminderLoading: true,
                forceCallApiFromTable: true,
            };
        case actionTypes.GET_REMINDER_DETAILS_SUCCESS:
            return {
                ...state,
                isReminderLoading: false,
                reminderDetails: get(action.result, successMappings.GET_REMINDER_DETAILS),
            };
        case actionTypes.SEND_ON_DEMAND_CAMPAIGN_FAILURE:
        case actionTypes.SEND_ON_DEMAND_CAMPAIGN_SUCCESS:
            return {
                ...state,
                isSendingOnDemandCampaign: false,
            };
        case actionTypes.SEND_ON_DEMAND_CAMPAIGN_REQUEST:
            return {
                ...state,
                isSendingOnDemandCampaign: true,
            };
        case actionTypes.SEND_REMINDER_FAILURE:
            return {
                ...state,
                isSendingReminder: false,
                isSavingReminder: false,
                isSaveAndSendingReminder: false,
            };
        case actionTypes.SEND_REMINDER_REQUEST:
            return {
                ...state,
                isSendingReminder: true,
            };
        case actionTypes.SEND_REMINDER_SUCCESS:
            // eslint-disable-next-line no-case-declarations
            let reminderIndex = findIndex(state.reminders, { id: get(action.result, successMappings.GET_REMINDER_ID) });
            // eslint-disable-next-line no-case-declarations
            let sentReminder = get(action.result, successMappings.GET_REMINDER);
            return {
                ...state,
                reminders: state.reminders.map((reminder, i) => (i === reminderIndex ? { ...sentReminder } : reminder)),
                isSendingReminder: false,
                isSavingReminder: false,
                isSaveAndSendingReminder: false,
            };
        case actionTypes.SET_REMINDER_FILTER:
            return {
                ...state,
                [action.payload.type]: action.payload.value,
            };
        case actionTypes.RESET_ORGANISATION_RELATED_DATA:
            return {
                ...state,
                campaignDetails: {},
                paginationCampaign: {},
                paginationReminders: {},
                reminders: [],
                scheduler: [],
            };
        case actionTypes.RESET_REMINDER_TYPE_AND_DATE_FILTER:
            return {
                ...state,
                forceCallApiFromTable: true,
                dateFilterType: FILTERS['dateFilterType'],
                dateRangeFilter: FILTERS['dateRangeFilter'],
                dateFilter: FILTERS['dateFilter'],
                reminderTypeFilter: FILTERS['reminderTypeFilter'],
                reminders: [],
            };
        case actionTypes.RESET_REMINDER_FILTER:
            return {
                ...state,
                ...FILTERS,
            };
        case actionTypes.UPDATE_CAMPAIGN_FAILURE:
        case actionTypes.UPDATE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                isUpdatingCampaign: false,
            };
        case actionTypes.UPDATE_CAMPAIGN_REQUEST:
            return {
                ...state,
                isUpdatingCampaign: true,
            };
        default:
            return state;
    }
}

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { get, find } from 'lodash';
import { Divider, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import useOrganisationId from 'includes/hooks/useOrganisationId';
import { getPaymentArrangement } from 'includes/slices/paymentArrangement';
import PaymentArrangementInvoices from 'pages/PaymentArrangements/Details/components/Invoices';
import Loader from 'components/Loader';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import { CURRENCY_ID } from 'includes/constants/keys/request';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import { CURRENCY_CODE } from 'includes/constants/keys/response';

export default function InvoicesListModal({ isOpen, paymentArrangementId, planType, onClose }) {
    const dispatch = useDispatch();
    const organisationId = useOrganisationId();
    const { data: currencies } = useOrganisationCurrencies();
    const getCurrencyFormattedAmount = useCurrencyFormatter();

    /**
     * Format number with payment arrangement currency
     *
     * @param {string|number} number  Number
     *
     * @returns {string} Formatted Number
     */
    const getNumberFormattedWithPaymentArrangementCurrency = number =>
        getCurrencyFormattedAmount(number, false, false, get(paymentArrangementCurrencyDetails, CURRENCY_CODE));

    const paymentArrangement = useSelector(state => state.paymentArrangement.paymentArrangement);
    const paymentArrangementCurrencyId = get(paymentArrangement, CURRENCY_ID);
    const paymentArrangementCurrencyDetails = find(currencies, { id: paymentArrangementCurrencyId }, '');

    useEffect(() => {
        if (isOpen && paymentArrangementId) {
            dispatch(getPaymentArrangement(organisationId, paymentArrangementId));
        }
    }, [organisationId, paymentArrangementId, isOpen]);

    const invoices = paymentArrangement.invoices ?? [];

    return (
        <Modal
            title="Invoices"
            width={920}
            closable
            className="my_profile_modal organisation-reauthorization-modal"
            footer={<Divider />}
            visible={isOpen}
            onCancel={onClose}
        >
            {paymentArrangement ? (
                <PaymentArrangementInvoices
                    invoices={invoices}
                    getNumberFormattedWithPaymentArrangementCurrency={getNumberFormattedWithPaymentArrangementCurrency}
                    planType={planType}
                />
            ) : (
                <Loader />
            )}
        </Modal>
    );
}

InvoicesListModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    paymentArrangementId: PropTypes.string,
    planType: PropTypes.string,
};

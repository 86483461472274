import { PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_OPTIONS } from 'includes/constants';
import i18n from 'includes/i18n';

/**
 * Get payment arrangement installments columns
 */
export const getPaymentArrangementInstallmentsTableColumns = [
    {
        title: i18n.t('messages.paymentArrangement.arrangement.installments.list.label.installment_number'),
        dataIndex: 'installment_number',
        key: 'installment_number',
    },
    {
        title: i18n.t('messages.paymentArrangement.arrangement.installments.list.label.payment_type.payment_type'),
        dataIndex: 'payment_type',
        key: 'payment_type',
    },
    {
        title: i18n.t('messages.paymentArrangement.arrangement.installments.list.label.total_amount'),
        dataIndex: 'total_amount',
        key: 'total_amount',
        sorter: true,
    },
    {
        title: i18n.t('messages.paymentArrangement.arrangement.installments.list.label.payment_date'),
        dataIndex: 'payment_date',
        key: 'payment_date',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('messages.paymentArrangement.arrangement.installments.list.label.plan_cancellation_date'),
        dataIndex: 'plan_cancellation_date',
        key: 'plan_cancellation_date',
        sorter: true,
    },
    {
        title: i18n.t('messages.paymentArrangement.arrangement.installments.list.label.status'),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        filterMultiple: false,
        filters: [{ id: '', label: 'All' }, ...PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_OPTIONS].map(status => ({
            text: status.label,
            value: status.id,
        })),
    },
    {
        title: i18n.t('messages.paymentArrangement.arrangement.installments.list.label.actions'),
        dataIndex: 'actions',
        key: 'actions',
    },
];

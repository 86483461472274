/**
 * Send test email listing Component
 *
 * @version 1.0
 */

//import required modules
import { React, useSelector, withTranslation } from '../../../includes/exports/react';
import PropTypes from 'prop-types';
import SendTestEmailForm from '../forms/SendTestEmail';
import { get } from 'lodash';
import { EMAIL } from '../../../includes/constants/keys/request';

/**
 * Default settings listing Component
 *
 * @returns    {string}
 */
const SendTestEmail = props => {
    const accountId = props.match.params.accountId || get(props.accountDetails, 'id');
    const organisationId = props.organisationId || props.match.params.organisationId;
    const isSendingTestEmail = useSelector(state => state.account.isSendingTestEmail);

    /**
     * Handle the submit of the sending test mail
     *
     * @param {object} values Form values
     */
    const handleSubmit = values => {
        props.sendTestEmail(values[EMAIL], accountId, organisationId);
    };
    /**
     * Render the component
     */
    return (
        <div className="my-profile-detail settings-edit-form edit-default-settings">
            <header>Send Test Email</header>
            <SendTestEmailForm enableReinitialize onSubmit={handleSubmit} loading={isSendingTestEmail} {...props} />
        </div>
    );
};
/**
 * Prop Types
 */
SendTestEmail.propTypes = {
    accountDetails: PropTypes.object,
    match: PropTypes.object,
    organisationId: PropTypes.string,
    sendTestEmail: PropTypes.func,
    t: PropTypes.func,
};
export default withTranslation()(SendTestEmail);

import i18n from 'includes/i18n';

/**
 * Get tax table columns
 */
export const getTaxTableColumns = [
    {
        title: i18n.t('messages.taxes.list.label.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.taxes.list.label.rate'),
        dataIndex: 'rate',
        key: 'rate',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.taxes.list.label.externalId'),
        dataIndex: 'external_id',
        key: 'external_id',
        sorter: true,
    },
    {
        title: i18n.t('messages.taxes.list.label.type'),
        dataIndex: 'tax_type_id',
        key: 'tax_type_id',
        sorter: true,
    },
    {
        title: i18n.t('messages.taxes.list.label.createdDate'),
        dataIndex: 'created_date',
        key: 'created_date',
        sorter: true,
    },
];

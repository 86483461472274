import { React, connect, withRouter } from '../../../../includes/exports/react';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import Button from '../../Button';
import * as responseKeys from '../../../../includes/constants/keys/response';
import { syncOrganisation } from '../../../../includes/redux/actions/customer/organisation';
import HasEditPermission from '../../../HasEditPermission';

const OrganisationSync = props => {
    const accountingSoftwareSlug = get(props.selectedOrganisation, ['accounting_software_data', 'slug']);
    return (
        <>
            <li key="organisation-sync" className="plan-details acc-software">
                {props.selectedOrganisationId && accountingSoftwareSlug && accountingSoftwareSlug !== 'manual' && (
                    <div className="popover-item">
                        <img
                            src={require(`../../../../assets/images/accounting_software/${accountingSoftwareSlug}.png`)}
                            alt="group"
                        />
                    </div>
                )}
                {get(props.selectedOrganisation, 'reauthorize') ? (
                    <HasEditPermission
                        onClick={() =>
                            props.history.push({
                                pathname: '/organisations/reauthorize',
                                state: {
                                    reauthorize: true,
                                    organisation: props.selectedOrganisation,
                                },
                            })
                        }
                    >
                        <Button filled className="blue-bg-button popover-action-btn">
                            {props.t('customerMessages.organisation.reauthorize')}
                        </Button>
                    </HasEditPermission>
                ) : (
                    <HasEditPermission onClick={() => props.syncOrganisation(props.selectedOrganisationId)}>
                        <Button
                            filled
                            className="blue-bg-button popover-action-btn"
                            loading={props.isSyncingOrganisation || props.selectedOrganisation.is_syncing}
                        >
                            {props.t(
                                props.isSyncingOrganisation || props.selectedOrganisation.is_syncing
                                    ? 'customerMessages.organisation.syncingButtonLabel'
                                    : 'customerMessages.organisation.syncButtonLabel'
                            )}
                        </Button>
                    </HasEditPermission>
                )}
            </li>
            <li key="last-synced" className="last-synced">
                <summary>
                    Last synced:{' '}
                    {get(props.selectedOrganisation, 'last_synced_date_time')
                        ? get(props.selectedOrganisation, 'last_synced_date_time')
                        : 'Never'}
                </summary>
            </li>
        </>
    );
};

OrganisationSync.propTypes = {
    history: PropTypes.object,
    isSyncingOrganisation: PropTypes.bool,
    selectedOrganisation: PropTypes.object,
    selectedOrganisationId: PropTypes.string,
    syncOrganisation: PropTypes.func,
    t: PropTypes.func,
};

//Connect to store
export default connect(
    state => ({
        isSyncingOrganisation: state.organisation.isSyncingOrganisation,
        selectedOrganisation: state.organisation.selectedOrganisation,
        selectedOrganisationId: state.organisation.selectedOrganisationId,
        defaultOrganisationLogo: get(state.settings.siteSettings, responseKeys.DEFAULT_ORGANISATION_LOGO_45X45),
    }),
    {
        syncOrganisation,
    }
)(withTranslation()(withRouter(OrganisationSync)));

/**
 * Debtor breadcrumb items
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import AccountsBreadCrumbItems from '../Accounts';
import OrganisationsBreadCrumbItems from '../Organisations';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DebtorAccountsBreadCrumbItems = () => {
    const accountId = useAccountId();
    const isAdminOrManager = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    const { t } = useTranslation();

    /**
     * Get the debtor accounts list link
     *
     * @returns {string} List link
     */
    const getListLink = () =>
        isAdminOrManager
            ? `/admin/accounts/organisation/debtor-accounts/${accountId}/${organisationId}`
            : `/debtor-accounts`;

    /**
     * Render the component
     */
    return (
        <>
            {isAdminOrManager ? (
                <>
                    <AccountsBreadCrumbItems showListBreadCrumb />
                    <OrganisationsBreadCrumbItems showListBreadCrumb />
                    <Breadcrumb.Item>
                        <Link to={getListLink()}>{t('messages.breadCrumbs.debtorAccounts.list')}</Link>
                    </Breadcrumb.Item>
                </>
            ) : null}
        </>
    );
};

export default DebtorAccountsBreadCrumbItems;

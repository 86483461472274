/**
 * Payment arrangement invoices Component
 *
 * @since 2.0.0
 */

//import required modules
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/shared/ScrollableTable';
import { getPaymentArrangementInvoicesColumns } from 'includes/tableColumns/paymentArrangementInvoices';
import useInvoiceLinkGenerator from 'includes/hooks/useInvoiceLinkGenerator';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useInvoiceStatuses from 'includes/hooks/useInvoiceStatuses';
import InvoiceStatusTag from 'components/InvoiceStatusTag';

const PaymentArrangementInvoices = props => {
    const { getNumberFormattedWithPaymentArrangementCurrency, invoices } = props;
    const getInvoiceDetailsLink = useInvoiceLinkGenerator();
    const paymentArrangement = useSelector(state => state.paymentArrangement.paymentArrangement);
    useInvoiceStatuses();

    /* Get the invoice link
     *
     * @param {object} paymentArrangementInvoice: Payment arrangement invoice
     *
     * @returns {string} Invoice Link
     */
    const getInvoiceLink = paymentArrangementInvoice =>
        getInvoiceDetailsLink(paymentArrangement?.contact?.id, paymentArrangementInvoice?.id);

    /**
     * Modify and return the payment arrangement invoices data
     */
    const getPaymentArrangementInvoicesData = () =>
        invoices.map(invoice => {
            return {
                ...invoice,
                invoice_number: (
                    <Link to={getInvoiceLink(invoice)} key={invoice.invoice_number} target="_blank">
                        {invoice.invoice_number}
                    </Link>
                ),
                days_overdue: parseInt(invoice.days_overdue) >= 0 ? `${invoice.days_overdue} days` : '',
                total_amount: getNumberFormattedWithPaymentArrangementCurrency(invoice.total_amount),
                amount_due: getNumberFormattedWithPaymentArrangementCurrency(invoice.amount_due),
                status: <InvoiceStatusTag id={invoice.status_id} />,
            };
        });

    /**
     * Memoized payment arrangement invoices data
     */
    const memoizedPaymentArrangementInvoicesData = useMemo(() => getPaymentArrangementInvoicesData(), [
        getPaymentArrangementInvoicesData,
    ]);

    return (
        <div className="invoice-line-items-table no-min-height-table">
            <Table
                columns={getPaymentArrangementInvoicesColumns(props.planType)}
                dataSource={memoizedPaymentArrangementInvoicesData}
                pagination={false}
                showMobileTableView={false}
            />
        </div>
    );
};

PaymentArrangementInvoices.propTypes = {
    getNumberFormattedWithPaymentArrangementCurrency: PropTypes.func,
    invoices: PropTypes.any,
    planType: PropTypes.string,
    t: PropTypes.func,
};

export default PaymentArrangementInvoices;

/**
 * Reporting form Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import { REPORTING } from 'includes/constants/permissions';
import useAccountId from 'includes/hooks/useAccountId';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { exportFile } from 'includes/slices/exports';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ReportExportForm from './components/Form';

const reportingMenus = [
    {
        name: 'Debt Age Report',
        slug: 'debt-age-report',
        showDateRange: false,
        permission: REPORTING.DEBT_AGE.EXPORT,
    },
    {
        name: 'Payment Terms Report',
        slug: 'payment-terms-report',
        showDateRange: true,
        permission: REPORTING.PAYMENT_TERMS.EXPORT,
    },
];

const ReportingExport = () => {
    const accountId = useAccountId(false);
    const dispatch = useDispatch();
    const organisationId = useOrganisationId();
    const [selectedMenu, setSelectedMenu] = useState(reportingMenus[0]);
    const { t } = useTranslation();

    /**
     * Handle the report export form submit
     *
     * @param {object} values Form values
     */
    const handleSubmit = values => {
        dispatch(exportFile(selectedMenu.slug, accountId, organisationId, '', '', values));
    };

    return (
        <div className="home-content-wrapper settings-wrapper">
            <h2 className="page-title">{t('titleAndMetas.reporting.pageTitle')}</h2>
            <div className="white-box-wrapper full-wrapper no-mob">
                <span className="settings-menu">
                    <div className="settings-list-wrapper">
                        <div className="menu-header">
                            <header>{t('messages.reporting.export.list.label.availableReports')}</header>
                            <img
                                src={require('../../../assets/images/transparent-right-arrow.svg')}
                                className="right-arrow right-arrow-unselected settings-menu-open"
                                alt="right-arrow"
                            />
                        </div>
                        <ul>
                            {reportingMenus.map(reportingMenu => (
                                <li
                                    className={`menu ${selectedMenu === reportingMenu.slug ? 'active-menu' : ''}`}
                                    key={reportingMenu.slug}
                                    onClick={() => setSelectedMenu(reportingMenu)}
                                >
                                    <div className="header">
                                        {reportingMenu.name}
                                        <img
                                            src={require('../../../assets/images/transparent-right-arrow.svg')}
                                            className="right-arrow right-arrow-unselected"
                                            alt="right-arrow"
                                        />
                                        <img
                                            src={require('../../../assets/images/transparent-right-arrow-white.svg')}
                                            className="right-arrow right-arrow-selected"
                                            alt="right-arrow"
                                        />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </span>
                <span className="settings-edit">
                    <ReportExportForm selectedMenu={selectedMenu} onSubmit={handleSubmit} />
                </span>
            </div>
        </div>
    );
};

export default ReportingExport;

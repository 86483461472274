import { Form } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import Button from 'components/shared/Button';
import FormField from 'components/shared/FormField';
import { PAYMENT_METHOD_FORM } from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import BankMandateSVG from 'assets/images/bank_mandate.svg';

/**
 * Edit card form
 *
 * Form used to edit card
 *
 * @since 2.8.0
 */
const EditMandateForm = reduxForm({
    form: PAYMENT_METHOD_FORM,
    enableReinitialize: true,
})(function({ bank, handleSubmit, isLoading, number: _number, onCancel, okLabel }) {
    const { t } = useTranslation();

    const number = useMemo(() => _number?.replaceAll('*', '•'), [_number]);

    return (
        <>
            <Form layout="vertical" onSubmit={handleSubmit}>
                <div className="col-span-3 sm:col-span-1 text-left mb-3">
                    <label>{t('customerMessages.paymentMethod.mandate.form.label.bank')}</label>
                    <div className="rounded-xl flex flex-row justify-between items-center text-lg">
                        <div className="font-mono">{bank}</div>
                    </div>
                </div>

                <div className="text-left mb-3">
                    <label>{t('customerMessages.paymentMethod.mandate.form.label.accountNumber')}</label>
                    <div className="rounded-xl flex flex-row justify-start items-center gap-2 text-lg">
                        <div className="font-mono">{number}</div>
                        <img style={{ height: 40, width: 40 }} src={BankMandateSVG} alt={'bank-mandate'} />
                    </div>
                </div>

                <div className="col-span-6 md:col-span-4 text-left">
                    <Field
                        type="text"
                        name="name"
                        label={t('customerMessages.paymentMethod.mandate.form.label.mandateName')}
                        className="form-control"
                        component={FormField}
                        validate={[formValidations.required]}
                        required
                    />
                </div>

                <div className="flex gap-3 justify-end">
                    <Button onClick={onCancel}>{t('customerMessages.paymentServices.form.action.cancel')}</Button>
                    <Button filled loading={isLoading} htmlType="submit">
                        {okLabel ?? 'Submit'}
                    </Button>
                </div>
            </Form>
        </>
    );
});

EditMandateForm.propTypes = {
    brand: PropTypes.string,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    number: PropTypes.string,
    okLabel: PropTypes.string,
    onCancel: PropTypes.func,
};

export default EditMandateForm;

import PropTypes from 'prop-types';
import React from 'react';

export default function WhiteBoxWrapper({ children, className, topActionSection = null, ...rest }) {
    return (
        <div className={`bg-white rounded-lg shadow-2xl text-black ${className}`} {...rest}>
            {topActionSection ? (
                <div className="border-b px-6 py-2 border-solid border-gray-200">{topActionSection}</div>
            ) : null}
            <div className="px-6 py-8">{children}</div>
        </div>
    );
}

WhiteBoxWrapper.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    topActionSection: PropTypes.oneOfType([PropTypes.node, null]),
};

export const debtAgeGraphOptions = {
    animationEnabled: true,
    theme: 'light2',
    colorSet: 'debtAgeColorset',
    dataPointWidth: 31,
    toolTip: {
        content: '{y}',
        borderThickness: 0,
        cornerRadius: 5,
        contentFormatter: e =>
            `<div class="chart-tooltip">${e.entries[0].dataPoint.y} ${
                e.entries[0].dataPoint.y > 1 ? 'accounts' : 'account'
            } with overdue of $${e.entries[0].dataPoint.amountShort}
            </div>`,
    },

    axisX: {
        title: 'Debt Age (days)',
        reversed: true,
        gridDashType: 'dot',
        gridThickness: 0.5,
        titleFontSize: 12,
        labelFontSize: 12,
        labelFontColor: '#97a4ba',
        margin: 55,
    },
    axisY: {
        title: 'Number of accounts',
        gridDashType: 'dot',
        gridThickness: 0.5,
        titleFontSize: 12,
        labelFontSize: 12,
        labelFontColor: '#97a4ba',
    },
    data: [
        {
            type: 'bar',
            dataPoints: [],
        },
    ],
};

import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';

import StripeForm from './components/Form';
import Loader from 'components/Loader';
import useStripePromise from 'includes/hooks/useStripePromise';

const StripePaymentElement = forwardRef(function StripePaymentElement(
    {
        amount,
        buttonLabel,
        currency = 'aud',
        externalSubmitButton = false,
        handleSubmit,
        isLoading,
        mode = 'payment',
        saveCardForLaterUse,
    },
    submitRef
) {
    const { stripePromise, isLoadingStripeDetails } = useStripePromise();
    const options = {
        ...(amount ? { amount, paymentMethodCreation: 'manual' } : {}),
        appearance: {
            rules: {
                '.Input': {
                    border: '1px solid #e5e5ea',
                },
                '.Input:hover': {
                    borderColor: '#8ace7d',
                },
            },
            theme: 'stripe',
            variables: {
                colorPrimary: '#8ace7d',
                borderRadius: '10px',
                blockLogoColor: 'dark',
            },
        },
        currency,
        loader: 'always',
        mode,
        ...(saveCardForLaterUse ? { setupFutureUsage: 'off_session' } : {}),
    };

    return (
        <div>
            {isLoadingStripeDetails ? (
                <Loader />
            ) : (
                <Elements stripe={stripePromise} options={options}>
                    <StripeForm
                        amount={amount}
                        buttonLabel={buttonLabel}
                        externalSubmitButton={externalSubmitButton}
                        handleSubmit={handleSubmit}
                        isLoading={isLoading}
                        mode={mode}
                        ref={submitRef}
                    />
                </Elements>
            )}
        </div>
    );
});

StripePaymentElement.propTypes = {
    amount: PropTypes.number,
    buttonLabel: PropTypes.string,
    currency: PropTypes.string,
    externalSubmitButton: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    mode: PropTypes.oneOf(['payment', 'setup', 'subscription']).isRequired,
    saveCardForLaterUse: PropTypes.bool,
    submitReference: PropTypes.object,
};

export default StripePaymentElement;

/**
 * Impact analytics functions
 *
 * @version 2.1.4
 */

// import required modules
// import { ENABLE_IMPACT_TRACKING } from 'includes/constants/config';

/**
 * Check if analytics can be tracked
 *
 * @returns {boolean} true|false
 */
export const canTrack = () => {
    if (isTrackingEnabled()) {
        if (typeof window.ire === 'function') {
            return true;
        }
    }
};

/**
 * Identify analytic method
 * This is fired on each and every page on route change
 *
 * @param {string} accountId Account id
 * @param {string} accountHashedEmail Account hashed email
 */
export const identify = (accountId, accountHashedEmail) => {
    if (canTrack() && accountId && accountHashedEmail) {
        window.ire('identify', { customerId: accountId, customerEmail: accountHashedEmail });
    }
};

/**
 * Check if impact tracking is enabled
 *
 * @returns  boolean    true|false
 */
export const isTrackingEnabled = () => false;

/**
 * Track conversion analytic method
 * This is fired when the user signs up for an account
 *
 * @param {string} accountId Account id
 * @param {string} accountHashedEmail Account hashed email
 * @param {string} orderId Order Id
 */
export const trackConversion = (accountId, accountHashedEmail, orderId) => {
    if (canTrack() && accountId && accountHashedEmail && orderId) {
        window.ire(
            'trackConversion',
            33755,
            {
                orderId: orderId,
                customerId: accountId,
                customerEmail: accountHashedEmail,
            },
            {
                verifySiteDefinitionMatch: true,
            }
        );
    }
};

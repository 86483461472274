/**
 * Chart of account transactions listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useMemo } from 'react';
import HasEditPermission from 'components/HasEditPermission';
import Table from 'components/shared/ScrollableTable';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { get } from 'lodash';
import { getChartOfAccountTransactions } from 'includes/slices/chartOfAccounts';
import { getChartOfAccountTransactionsTableColumns } from 'includes/tableColumns/chartOfAccounts';
import { TABLE_NO_CHART_OF_ACCOUNT_TRANSACTIONS_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useInvoiceLinkGenerator from 'includes/hooks/useInvoiceLinkGenerator';

const ChartOfAccountTransactions = () => {
    const { t } = useTranslation();
    const params = useParams();

    const { chartOfAccountId } = params;

    const chartOfAccountTransactions = useSelector(state => state.chartOfAccounts.chartOfAccountTransactions);
    const chartOfAccountTransactionsPagination = useSelector(
        state => state.chartOfAccounts.chartOfAccountTransactionsPagination
    );
    const loadingChartOfAccountTransactions = useSelector(
        state => state.chartOfAccounts.loadingChartOfAccountTransactions
    );
    const organisationId = useOrganisationId();
    const getInvoiceLink = useInvoiceLinkGenerator();

    /**
     * Format the chart of account transactions data
     */
    const formatChartOfAccountTransactionsData = useCallback(
        () =>
            chartOfAccountTransactions.map(chartOfAccountTransaction => {
                let credit, debit, descriptionKey;

                const invoiceId = get(chartOfAccountTransaction, 'invoice_id');
                const contactId = get(chartOfAccountTransaction, 'contact_id');
                const transaction_amount = get(chartOfAccountTransaction, 'transaction_amount');

                const contactName =
                    get(chartOfAccountTransaction, 'contact_business_name') ??
                    `${get(chartOfAccountTransaction, 'contact_first_name')} ${get(
                        chartOfAccountTransaction,
                        'contact_last_name'
                    )}`;

                if (get(chartOfAccountTransaction, 'transaction_is_payment')) {
                    credit = transaction_amount;
                    descriptionKey = 'paymentDescription';
                } else {
                    if (get(chartOfAccountTransaction, 'is_credit_note')) {
                        debit = transaction_amount;
                    } else {
                        credit = transaction_amount;
                    }
                    descriptionKey = 'lineItemDescription';
                }

                return {
                    credit: credit,
                    debit: debit,
                    date: get(chartOfAccountTransaction, 'transaction_date', ''),
                    description: (
                        <div className="business-name-avatar-wrapper">
                            <div className="action-wrapper">
                                {t(`messages.chartOfAccounts.transactions.list.item.${descriptionKey}`, {
                                    invoiceNumber: get(chartOfAccountTransaction, 'invoice_number'),
                                    contactName: contactName,
                                    lineItemName:
                                        get(chartOfAccountTransaction, 'transaction_name') ??
                                        get(chartOfAccountTransaction, 'transaction_description'),
                                })}
                                <span className="crud-wrapper">
                                    <HasEditPermission
                                        type="link"
                                        link={getInvoiceLink(contactId, invoiceId)}
                                        className="crud-link"
                                    >
                                        {t('messages.chartOfAccounts.crud.view')}
                                    </HasEditPermission>
                                </span>
                            </div>
                        </div>
                    ),
                };
            }),
        [chartOfAccountTransactions] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Memoized chart of account transactions data
     */
    const memoizedChartOfAccountTransactionsData = useMemo(() => formatChartOfAccountTransactionsData(), [
        formatChartOfAccountTransactionsData,
    ]);

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper payment-history-wrapper">
            <Table
                className="table-1200"
                columns={getChartOfAccountTransactionsTableColumns}
                dataSource={memoizedChartOfAccountTransactionsData}
                dataValues={[chartOfAccountId, organisationId]}
                dispatchMethod={true}
                getDataMethod={getChartOfAccountTransactions}
                loading={loadingChartOfAccountTransactions}
                mobileClassName="table-1200"
                paginationData={chartOfAccountTransactionsPagination}
                localeMessage={TABLE_NO_CHART_OF_ACCOUNT_TRANSACTIONS_FOUND_MESSAGE}
                rowClassName="pointer"
                rowKey="id"
                size="middle"
            />
        </div>
    );
};

export default ChartOfAccountTransactions;

/**
 * Notification Saga
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import the required modules
import * as actionTypes from '../../../../constants/actionTypes';
import * as constants from '../../../../constants';
import BaseSagaHandler from '../../core/base';
import NotificationApi from '../../../../services/shared/notification';
import { takeLatest, all, put, call } from 'redux-saga/effects';
import { UNKNOWN_ERROR } from '../../../../constants/messages/errors';

/**
 * Notification Saga Class. Handles the notification related operations
 * */
class NotificationSaga extends BaseSagaHandler {
    /**
     * The Notification Watcher Saga
     * Watches the redux actions related to notification and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *notificationWatchers() {
        let context = this;
        yield all([yield takeLatest(actionTypes.GET_NOTIFICATIONS_REQUEST, [context, 'getNotifications'])]);
    }

    /**
     * Get the notifications
     *
     * @param   {object}    action     Action
     *
     * @yields {object} Notifications response
     */
    *getNotifications(action) {
        try {
            const result = yield call(NotificationApi.getNotifications, action.payload);
            yield put({ type: actionTypes.GET_NOTIFICATIONS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_NOTIFICATIONS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }
}

//export the Notification saga
export default new NotificationSaga().forkAllWatcherFunctions();

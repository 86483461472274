import PageTitle from 'components/PageTitle';
import WhiteBoxWrapper from 'components/WhiteBoxWrapper';
import React from 'react';
import EnterprisePlanImage from 'assets/images/enterprise-plan.png';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

/**
 * Renders a message indicating the user that he/she has to contact support to upgrade plan
 *
 * @since 2.8.0
 */
export default function CustomPlanNoPlanUpgradeMessage() {
    const accountDetails = useSelector(state => state.account.accountDetails);

    const subscription = get(accountDetails, 'subscription');

    return (
        <div>
            <div className="my-8">
                <PageTitle>{subscription?.plan_name}</PageTitle>
            </div>
            <div className="container mx-auto">
                <WhiteBoxWrapper>
                    <div className="flex flex-row items-center">
                        <img className="mx-auto" src={EnterprisePlanImage} />
                        <p className="font-sans mt-4 leading-7">
                            Need more from your plan? Please reach out to your account manager, speak to us via the
                            online chat or email us at <a href="mailto:sales@paycepaid.com">sales@paycepaid.com</a> to
                            discuss upgrading your plan.
                        </p>
                    </div>
                </WhiteBoxWrapper>
            </div>
        </div>
    );
}

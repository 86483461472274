/**
 * Account API class.
 * Handles all api requests related to accounts
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class AccountApi extends BaseApiHandler {
    /**
     * Call the account coupons API
     *
     * @returns {object}                    API Response
     * */
    getAccountCoupons = ({ page_size, ...payload }) => {
        return this.axios.get(this.getApiUrl('PAYMENTS_ACCOUNTS_COUPONS', payload), {
            params: { page_size: page_size },
        });
    };

    /**
     * Call the accounts listing API
     *
     * @returns {object}                    API Response
     * */
    getAccounts = ({ searchParams }) => this.axios.get(this.getApiUrl('GET_ACCOUNTS'), { params: { ...searchParams } });

    /**
     * Call the accounts types listing API
     *
     * @returns {object}                    API Response
     * */
    getAccountTypes = payload => this.axios.get(this.getApiUrl('GET_ACCOUNTS_TYPES'), { params: { ...payload } });

    /**
     * Call the accounts listing API
     *
     * @returns {object}                    API Response
     * */
    getCoupons = ({ searchParams }) => this.axios.get(this.getApiUrl('COUPONS'), { params: { ...searchParams } });

    /**
     * Call the accounts details API
     *
     * @returns {object}                    API Response
     * */
    getAccountDetails = accountId => this.axios.get(this.getApiUrl('GET_ACCOUNT_DETAILS', accountId));

    /**
     * Call the assign coupon to multiple accounts API
     */
    addCouponToAccount = ({ coupon_id, ...restPayload }) =>
        this.axios.post(this.getApiUrl('COUPON_ACCOUNTS', { coupon_id: coupon_id }), restPayload);

    /**
     * Call the remove coupon from multiple accounts API
     */
    removeCouponFromAccount = ({ coupon_id, ...restPayload }) =>
        this.axios.delete(this.getApiUrl('COUPON_ACCOUNTS', { coupon_id: coupon_id }), {
            data: restPayload,
        });

    /**
     * Call the coupon Account listing API
     */
    getCouponAccounts = ({ searchParams, ...restPayload }) =>
        this.axios.get(this.getApiUrl('COUPON_ACCOUNTS', restPayload), { params: { ...searchParams } });

    /**
     * Send test mail API
     *
     * @param {string} accountId
     * @param {string}organisationId
     * @param {object} restPayload
     *
     * @returns {object} API Response
     */
    sendTestMail = ({ accountId, organisationId, ...restPayload }) =>
        this.axios.post(
            this.getApiUrl('ACCOUNT_SEND_TEST_MAIL', { account_id: accountId, organisation_id: organisationId }),
            restPayload
        );

    /**
     * Call the try demo company API
     *
     * @param {string} accountId Account Id
     * @param {object} payload obj Payload
     *
     * @returns {object} API Response
     */
    tryDemoCompany = (accountId, payload) =>
        this.axios.post(this.getApiUrl('TRY_DEMO_COMPANY', { account_id: accountId }), payload);

    /**
     * Call the update account details API
     *
     * @param {object}    obj     Email address activation code
     *
     * @returns {object}            API Response
     */
    updateAccountDetails = ({ accountId, accountDetails }) =>
        this.axios.patch(this.getApiUrl('UPDATE_ACCOUNT_DETAILS', { account_id: accountId }), accountDetails);

    /**
     * Add account details API
     *
     * @param {object}    obj     Email address activation code
     *
     * @returns {object}            API Response
     */
    addAccount = accountDetails => this.axios.post(this.getApiUrl('ADD_ACCOUNT'), accountDetails);

    /**
     * Delete account
     *
     * @returns {object}                    API Response
     * */
    deleteAccount = accountId => this.axios.delete(this.getApiUrl('GET_ACCOUNT_DETAILS', { account_id: accountId }));
}

export default new AccountApi();

/**
 * Communication actions
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import action types
import * as actionTypes from '../../../constants/actionTypes';

/**
 * Get communication log history
 *
 * @param {string} organisationId Organisation Id
 * @param {object} queryParams Query Params
 *
 * @returns {object}  Get communication log action
 */
export const getCommunicationLogHistory = (organisationId, queryParams) => ({
    type: actionTypes.GET_COMMUNICATION_LOG_HISTORY_REQUEST,
    payload: {
        organisationId,
        queryParams,
    },
});

/**
 * Get communication log history details
 *
 * @param {string} organisationId Organisation Id
 * @param {string} communicationLogHistoryId Communication log history id
 * @param {object} queryParams Query Params
 *
 * @returns {object}  Get communication log history details action
 */
export const getCommunicationLogHistoryDetails = (organisationId, communicationLogHistoryId, queryParams) => ({
    type: actionTypes.GET_COMMUNICATION_LOG_HISTORY_DETAILS_REQUEST,
    payload: {
        organisationId,
        communicationLogHistoryId,
        queryParams,
    },
});

/**
 * Get communication log statuses action
 *
 * @returns {object} Get communication log statuses action
 */
export const getCommunicationLogStatuses = () => ({
    type: actionTypes.GET_COMMUNICATION_LOG_STATUSES_REQUEST,
    payload: {
        page_size: 0,
    },
});

/**
 * Add communication template action
 *
 * @param {string} organisationId Organisation Id
 * @param {object} templateDetails Template details
 * @param {Function} callback Callback function
 *
 * @returns {object} Add communication template action
 */
export const addCommunicationTemplate = (organisationId, templateDetails, callback) => ({
    payload: { organisation_id: organisationId, templateDetails },
    type: actionTypes.ADD_COMMUNICATION_TEMPLATE_REQUEST,
    callback,
});

/**
 * Delete communication template action
 *
 * @param {string} organisationId Organisation Id
 * @param {string} templateId Template Id
 *
 * @returns {object} Delete communication template action
 */
export const deleteCommunicationTemplate = (organisationId, templateId) => ({
    type: actionTypes.DELETE_COMMUNICATION_TEMPLATE_REQUEST,
    payload: { organisationId, templateId },
});

/**
 * Get the communication template details action
 *
 * @param {string} organisationId Organisation Id
 * @param {string} templateId Template Id
 *
 * @returns {object} Get communication template details action
 */
export const getCommunicationTemplateDetails = (organisationId, templateId) => ({
    payload: { organisation_id: organisationId, template_id: templateId },
    type: actionTypes.GET_COMMUNICATION_TEMPLATE_DETAILS_REQUEST,
});

/**
 * Get the communication template preview action
 *
 * @param {object} payload Payload
 *
 * @returns {object} Get communication template preview action
 */
export const getCommunicationTemplatePreview = payload => ({
    type: actionTypes.GET_COMMUNICATION_TEMPLATE_PREVIEW_REQUEST,
    payload: { ...payload },
});

/**
 * Get communication templates action
 *
 * @param {string} organisationId Organisation Id
 * @param {string} communicationType Communication type
 * @param {object} searchParams Search Params
 * @param {boolean} allTemplates Whether to get all templates or not. Default false
 *
 * @returns {object} Get communication templates actions
 */
export const getCommunicationTemplates = (
    organisationId,
    communicationType = '',
    searchParams = {},
    allTemplates = false
) => ({
    type: allTemplates
        ? actionTypes.GET_ALL_COMMUNICATION_TEMPLATES_REQUEST
        : actionTypes.GET_COMMUNICATION_TEMPLATES_REQUEST,
    payload: {
        organisation_id: organisationId,
        searchParams,
        queryParams: { communication_type_id: communicationType },
    },
});

/**
 * Get communication template types action
 *
 * @param {string} organisationId Organisation Id
 * @param {object} queryParams Query Params
 *
 * @returns {object} Get communication template types action
 */
export const getCommunicationTemplateTypes = (organisationId, queryParams) => ({
    type: actionTypes.GET_COMMUNICATION_TEMPLATE_TYPES_REQUEST,
    payload: {
        organisationId,
        page_size: 0,
        ...queryParams,
    },
});

/**
 * Get communication types action
 *
 * @returns {object} Get communication types action
 */
export const getCommunicationTypes = () => ({
    type: actionTypes.GET_COMMUNICATION_TYPES_REQUEST,
    payload: { page_size: 0 },
});

/**
 * Update communication template action
 *
 * @param {object} templateDetails Template details
 * @param {Function} callback Callback function
 *
 * @returns {object} Add communication template action
 */
export const updateCommunicationTemplate = (templateDetails, callback) => ({
    payload: templateDetails,
    type: actionTypes.UPDATE_COMMUNICATION_TEMPLATE_REQUEST,
    callback,
});

/**
 * Payment arrangement notes list
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com>
 */

// import required modules
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './style/style.scss';
import { deletePaymentArrangementNote, getPaymentArrangementNotes } from 'includes/slices/paymentArrangement';
import { List, Comment, Pagination, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import PaymentArrangementNoteAddEditForm from 'pages/PaymentArrangements/Details/components/Notes/components/form';
import PropTypes from 'prop-types';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import UserAvatar from 'assets/images/user-avatar.svg';
import InfoMessage from 'components/shared/messages/InfoMessage';
import useUserId from 'includes/hooks/useUserId';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import { PAYMENT_ARRANGEMENT } from 'includes/constants/permissions';
import PermissionGuard from 'components/PermissionGuard';
import useHasRequiredPermission from 'includes/hooks/useHasRequiredPermission';

const { confirm } = Modal;

const PaymentArrangementNotes = props => {
    const [paymentArrangementNoteId, setPaymentArrangementNoteId] = useState('');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const organisationId = useOrganisationId();
    const hasListNotesPermission = useHasRequiredPermission(PAYMENT_ARRANGEMENT.NOTES.LIST);
    const userId = useUserId();
    const loadingPaymentArrangementNotes = useSelector(
        state => state.paymentArrangement.loadingPaymentArrangementNotes
    );
    const loadingPaymentArrangementNoteAddEdit = useSelector(
        state => state.paymentArrangement.loadingPaymentArrangementNoteAddEdit
    );
    const loadingPaymentArrangementNoteDelete = useSelector(
        state => state.paymentArrangement.loadingPaymentArrangementNoteDelete
    );
    const paymentArrangementNotes = useSelector(state => state.paymentArrangement.paymentArrangementNotes);
    const paymentArrangementNotesPagination = useSelector(
        state => state.paymentArrangement.paymentArrangementNotesPagination
    );
    const { currentPage, pageSize, totalCount, totalPages } = paymentArrangementNotesPagination;

    useEffect(() => {
        fetchPaymentArrangementNotes();
    }, []);

    const confirmAndDeletePaymentArrangementNote = paymentArrangementNoteId => {
        confirm({
            title: t(`messages.paymentArrangement.arrangement.note.confirm.delete.title`),
            content: '',
            onOk: () => {
                dispatch(
                    deletePaymentArrangementNote(organisationId, props.paymentArrangementId, paymentArrangementNoteId)
                );
            },
        });
    };
    /**
     * Fetch payment arrangement notes
     *
     * @param {number} page Page number. Default 1
     */
    const fetchPaymentArrangementNotes = (page = 1) => {
        if (hasListNotesPermission)
            dispatch(getPaymentArrangementNotes(organisationId, props.paymentArrangementId, { page: page }));
    };

    /**
     * Get the pagination data for the table
     *
     * @returns  {object|boolean}  Pagination Data if total number of pages greater than one else false
     */
    const getPaginationData = useCallback(() => {
        const paginationData = {
            position: 'both',
            onChange: fetchPaymentArrangementNotes,
        };

        if (totalPages > 1) {
            return {
                total: totalCount,
                current: currentPage,
                pageSize: pageSize,
                ...paginationData,
            };
        } else {
            return false;
        }
    }, [totalPages, totalCount, currentPage, pageSize]);

    /**
     * Set pagination data
     */
    const paginationData = useMemo(() => getPaginationData(), [getPaginationData]);

    /**
     * Render payment arrangement notes
     */
    const renderPaymentArrangementNotes = () =>
        paymentArrangementNotes.length > 0 ? (
            <List
                style={{ textAlign: 'left' }}
                className="comment-list"
                header={`${paymentArrangementNotes.length} notes`}
                itemLayout="horizontal"
                dataSource={paymentArrangementNotes}
                renderItem={paymentArrangementNote => (
                    <li>
                        {paymentArrangementNoteId === paymentArrangementNote.id ? (
                            <PaymentArrangementNoteAddEditForm
                                key="editForm"
                                loading={paymentArrangementNoteId && loadingPaymentArrangementNoteAddEdit}
                                paymentArrangementId={props.paymentArrangementId}
                                paymentArrangementNoteId={paymentArrangementNoteId}
                                paymentArrangementNoteValue={paymentArrangementNote.note}
                                setPaymentArrangementNoteId={setPaymentArrangementNoteId}
                            />
                        ) : (
                            <Comment
                                actions={
                                    paymentArrangementNote?.can_edit_delete
                                        ? paymentArrangementNote?.user?.id === userId
                                            ? [
                                                  <PermissionGuard
                                                      key="comment-list-reply-to-0"
                                                      requiredPermission={PAYMENT_ARRANGEMENT.NOTES.EDIT}
                                                  >
                                                      <span
                                                          onClick={() =>
                                                              setPaymentArrangementNoteId(paymentArrangementNote.id)
                                                          }
                                                      >
                                                          {t(`messages.paymentArrangement.arrangement.note.crud.edit`)}
                                                      </span>
                                                  </PermissionGuard>,
                                                  <span key="pipe">|</span>,
                                                  <PermissionGuard
                                                      key="comment-list-delete"
                                                      requiredPermission={PAYMENT_ARRANGEMENT.NOTES.DELETE}
                                                  >
                                                      <span
                                                          onClick={() =>
                                                              confirmAndDeletePaymentArrangementNote(
                                                                  paymentArrangementNote.id
                                                              )
                                                          }
                                                      >
                                                          {t(
                                                              `messages.paymentArrangement.arrangement.note.crud.delete`
                                                          )}
                                                      </span>
                                                  </PermissionGuard>,
                                              ]
                                            : {}
                                        : {}
                                }
                                author={paymentArrangementNote?.user?.name}
                                avatar={UserAvatar}
                                content={paymentArrangementNote.note}
                                datetime={paymentArrangementNote?.updated_date}
                                style={{ borderBottom: '1px solid #efeff4' }}
                            />
                        )}
                    </li>
                )}
            />
        ) : null;

    return (
        <div className="search-filter-export-wrapper contact-list-container full-wrapper box-wrapper">
            {loadingPaymentArrangementNotes ? (
                <Loader />
            ) : (
                <>
                    <PaymentArrangementNoteAddEditForm
                        key="addForm"
                        loading={!paymentArrangementNoteId && loadingPaymentArrangementNoteAddEdit}
                        paymentArrangementId={props.paymentArrangementId}
                        setPaymentArrangementNoteId={setPaymentArrangementNoteId}
                    />
                    <PermissionGuardLayout layout="section" requiredPermission={PAYMENT_ARRANGEMENT.NOTES.LIST}>
                        {renderPaymentArrangementNotes()}
                    </PermissionGuardLayout>
                    {paginationData && (
                        <div className="cs-table-wrapper">
                            <div className="custom-scrollble-table" style={{ minHeight: '40px' }}>
                                <Pagination size="small" {...paginationData} className="table-pagination" />
                            </div>
                        </div>
                    )}
                    <Modal
                        footer={[]}
                        className="my_profile_modal organisation-reauthorization-modal"
                        visible={loadingPaymentArrangementNoteDelete}
                    >
                        <InfoMessage
                            message={t('messages.paymentArrangement.arrangement.note.modal.message.delete')}
                            showLoader={true}
                        />
                    </Modal>
                </>
            )}
        </div>
    );
};

/**
 * Proptypes
 */
PaymentArrangementNotes.propTypes = {
    paymentArrangementId: PropTypes.string,
};

export default PaymentArrangementNotes;

import { useSelector } from 'react-redux';
import useIsAdminOrManager from './useIsAdminOrManager';

export default function useHasActiveAppSubscription() {
    const isAdmin = useIsAdminOrManager();

    const subscriptionDetails = useSelector(state => state?.account?.accountDetails?.subscription);

    // if user is admin, then has this permission
    if (isAdmin) return true;

    // otherwise check the subscription details to see if the user has a valid subscription or is under grace period
    return subscriptionDetails?.has_subscription || subscriptionDetails?.under_grace_period;
}

/**
 * API util functions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sbaarinath@paycepaid.com.au>
 */

import * as constants from '../constants';
import { get, isArray, isEmpty, isObject, omit } from 'lodash';
import * as errorMappings from '../constants/mappings/errors';
import { _ERROR } from '../constants';
import * as errorMessages from '../constants/messages/errors';
import * as successMappings from '../constants/mappings/success';
import * as utils from './index';
import { stopSubmit } from 'redux-form';
import { openNotificationModal } from '../slices/appNotifications';
import { REQUEST_PROCESSED_SUCCESSFULLY } from '../constants/messages/success';
import { UNKNOWN_ERROR } from '../constants/messages/errors';
import { PERMISSION_ERROR } from 'includes/constants/keys/response';

/**
 * Get all the errors in the API response
 * If there is no error response in the API, checks if any default error is set up for the API response in the client
 *
 * @param {object} errors API errors Default {}
 * @param {string} defaultApiErrorKey Default API Error Key Default ''
 * @param {string} defaultErrorKey Default Error Key Default ''
 * @param {Array} errorKeysToAddInDefaultError Error Keys to add in Default Error. Default []
 * @param {string} apiErrorKey API Error Key Default ''
 *
 * @returns {object}    apiErrors           API Errors
 */
export const getApiErrors = (
    errors = {},
    defaultApiErrorKey = '',
    defaultErrorKey = '',
    errorKeysToAddInDefaultError = [],
    apiErrorKey = '',
    excludeFormFields = []
) => {
    let apiDefaultError = '';

    if (!apiErrorKey) {
        apiErrorKey = constants.DEFAULT_API_ERROR_RESPONSE_STRUCTURE;
    }

    // get the api errors
    let apiErrors = get(errors, get(errorMappings, apiErrorKey, {}));
    let key = '';

    // loop through api errors and if any error has 0 index errors, add it to the default error key
    for (key in apiErrors) {
        // eslint-disable-next-line no-prototype-builtins
        if (apiErrors.hasOwnProperty(key)) {
            if (isArray(apiErrors[key][0])) {
                apiErrors._error = apiErrors[key][0];
            }
        }
    }

    if (errorKeysToAddInDefaultError && !get(apiErrors, _ERROR)) {
        let errorKeyToAddInDefaultError = '';
        for (errorKeyToAddInDefaultError of errorKeysToAddInDefaultError) {
            let errorKeyError = get(apiErrors, [errorKeyToAddInDefaultError, 0]);
            if (errorKeyError) {
                apiErrors._error = errorKeyError;
                break;
            }
        }
    }

    // get the api default error
    if (defaultApiErrorKey) {
        apiDefaultError = get(apiErrors, get(errorMappings, defaultApiErrorKey), '');
    }

    //if we have an error but the server returned no errors, set a default one
    if (isEmpty(apiErrors) && isEmpty(apiDefaultError)) {
        defaultErrorKey = defaultErrorKey ? defaultErrorKey : defaultApiErrorKey;
        apiDefaultError = defaultErrorKey
            ? get(errorMessages, defaultErrorKey, errorMessages.UNKNOWN_ERROR)
            : errorMessages.UNKNOWN_ERROR;
    }

    if (apiDefaultError) {
        if (!isObject(apiErrors)) apiErrors = {};

        apiErrors._error = apiDefaultError;
    }
    return omit(apiErrors, excludeFormFields);
};

/**
 * Get Parsed API Response
 *
 * @param {object} result API results
 * @param {string} key Key from which response to be parsed
 *
 * @returns {object} response Parsed API response
 */
export const getResponse = (result, key) => get(result, get(successMappings, key));

/**
 * Check if there is any authorization error in the response
 *
 * @param {object} response Response
 *
 * @returns {boolean} true or false
 */
export const hasAuthorizationError = response => {
    return !!(
        get(response, errorMappings.API_INACTIVE_AUTHORIZATION_TOKEN_ERROR_RESPONSE_STRUCTURE) ||
        get(response, errorMappings.API_INVALID_AUTHORIZATION_TOKEN_ERROR_RESPONSE_STRUCTURE)
    );
};

/**
 * Check if there is any permission error in the response
 *
 * @param {object} errors Errors
 * @param {string} defaultApiErrorKey Default API Error Key Default ''
 *
 * @returns {boolean} true or false
 */
export const hasPermissionError = (errors, defaultApiErrorKey = '') => {
    if (defaultApiErrorKey) {
        const errorMappingValue = get(errorMappings, defaultApiErrorKey);
        if (isArray(errorMappingValue) && !isEmpty(errorMappingValue)) {
            return !!get(errors, [...errorMappingValue, PERMISSION_ERROR, 0]);
        }
    } else {
        return !!get(errors, errorMappings.API_PERMISSION_ERROR_RESPONSE_STRUCTURE);
    }
};

/**
 * Show the API errors either as notification modal or notification message
 * If a form is defined then send the error messages to the form instead of as notification
 *
 * @param {object} errors API errors Default {}
 * @param {string} formName Form Name Default ''
 * @param {string} defaultApiErrorKey Default API Error Key Default ''
 * @param {string} defaultErrorKey Default Error Key Default ''
 * @param {Array} errorKeysToAddInDefaultError Error Keys to add in Default Error. Default []
 * @param {string} apiErrorKey API Error Key Default ''
 * @param {Array} additionalFormFields Additional form fields if any that needs to be included. Default []
 * @param {Array} excludeFormFields form fields to be excluded from being set to form errors. Default []
 *
 * @returns {object}    apiErrors           API Errors
 */
export const showApiErrors = (
    errors = {},
    formName = '',
    defaultApiErrorKey = '',
    defaultErrorKey = '',
    errorKeysToAddInDefaultError = [],
    apiErrorKey = '',
    additionalFormFields = [],
    excludeFormFields = []
) => async dispatch => {
    if (!hasAuthorizationError(errors)) {
        if (!hasPermissionError(errors)) {
            const apiErrors = getApiErrors(
                errors,
                defaultApiErrorKey,
                defaultErrorKey,
                errorKeysToAddInDefaultError,
                apiErrorKey,
                excludeFormFields
            );
            // check if there is any permission error in api errors
            if (hasPermissionError(apiErrors, defaultApiErrorKey)) {
                dispatch(
                    openNotificationModal(
                        get(apiErrors, [...get(errorMappings, defaultApiErrorKey), PERMISSION_ERROR, 0]),
                        false
                    )
                );
            } else {
                formName = formName ? get(constants, formName) : '';
                if (formName) {
                    dispatch(stopFormSubmit(apiErrors, formName, additionalFormFields));
                } else {
                    dispatch(openNotificationModal(get(apiErrors, '_error'), false));
                }
            }
        }
    }
};

/**
 * Show the API permission error if any
 *
 * @param {object} errors API errors Default {}
 * @param {string} defaultApiErrorKey Default API Error Key Default ''
 *
 * @returns {object} apiErrors API Errors
 */
export const showApiPermissionError = (errors = {}, defaultApiErrorKey = '') => async dispatch => {
    if (!hasAuthorizationError(errors)) {
        if (!hasPermissionError(errors)) {
            const apiErrors = getApiErrors(errors, defaultApiErrorKey);
            // check if there is any permission error in api errors
            if (hasPermissionError(apiErrors, defaultApiErrorKey)) {
                dispatch(
                    openNotificationModal(
                        get(apiErrors, [...get(errorMappings, defaultApiErrorKey), PERMISSION_ERROR, 0]),
                        false
                    )
                );
            }
        }
    }
};

/**
 * Show the success API modal
 *
 * @param {object} response API Response data Default {}
 */
export const showApiSuccess = (response = {}) => async dispatch => {
    dispatch(
        openNotificationModal(
            get(response, successMappings.API_RESPONSE_MESSAGE_STRUCTURE, REQUEST_PROCESSED_SUCCESSFULLY)
        )
    );
};

/**
 * Stop the form submit
 *
 * @param {object} apiErrors API errors
 * @param {string} formName Name of the form. Default ''
 * @param {Array} additionalFormFields Additional form fields if any that needs to be included. Default []
 *
 * @yields Notification modal
 */
export const stopFormSubmit = (apiErrors, formName = '', additionalFormFields = []) => async dispatch => {
    // get the form fields from store
    let formFields = utils.getValueFormStore([constants.FORM, formName, constants.FIELDS]);
    formFields = formFields ? Object.keys(formFields) : [];

    if (apiErrors && formFields && isArray(formFields)) {
        let key = '';

        // if additional form fields are defined, add to the form fields array
        if (additionalFormFields) {
            formFields.push(...additionalFormFields);
        }

        // loop through api errors and if any error has 0 index errors, add it to the default error key
        for (key in apiErrors) {
            // if the error key is not included in the form fields, dispatch it as the notification modal
            if (!formFields.includes(key)) {
                let responseErrorMessage = get(apiErrors, key, UNKNOWN_ERROR);
                if (isArray(responseErrorMessage)) {
                    responseErrorMessage = get(responseErrorMessage, 0, UNKNOWN_ERROR);
                }
                dispatch(openNotificationModal(responseErrorMessage, false));
                apiErrors._error = '';
                break;
            }
        }
        dispatch(stopSubmit(formName, apiErrors));
    }
};

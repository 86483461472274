/**
 * Email template Form fields
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import required modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { get } from 'lodash';
import { Row, Col, Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as formValidations from '../../../../includes/utils/form';
import * as requestKeys from '../../../../includes/constants/keys/request';
import Button from '../../Button';
import CkEditor from '../../CkEditor';
import FormField from '../../FormField';
import Loader from '../../Loader';
import { showNotificationPopup } from 'includes/redux/actions/shared/common';
import PermissionGuard from 'components/PermissionGuard';
import { REMINDER } from 'includes/constants/permissions';

const EmailFields = props => {
    const allPlaceholders = useSelector(state => state.placeholder.allPlaceholders);
    // text editor reference
    const editorRef = useRef();

    //subject field reference
    const subject = useRef(null);

    // placeholder button reference
    const subjectPlaceholderButton = useRef(null);

    // editor placeholder button reference
    const editorPlaceholderButton = useRef(null);

    //placeholder dropdown reference
    const placeholderList = useRef(null);

    //state to maintain visible status of placeholder list for subject
    const [subjectPlaceholderOpen, setSubjectPlaceholderOpen] = useState(false);

    //state to maintain visible status of placeholder list for subject
    const [editorPlaceholderOpen, setEditorPlaceholderOpen] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    // click event handler
    const handleClick = evt => {
        if (
            subjectPlaceholderButton &&
            subjectPlaceholderButton.current &&
            subjectPlaceholderButton.current.buttonNode.contains(evt.target)
        )
            setSubjectPlaceholderOpen(true);
        else if (
            editorPlaceholderButton &&
            editorPlaceholderButton.current &&
            editorPlaceholderButton.current.buttonNode.contains(evt.target)
        )
            setEditorPlaceholderOpen(true);
        else if (placeholderList.current && !placeholderList.current.contains(evt.target)) {
            setSubjectPlaceholderOpen(false);
            setEditorPlaceholderOpen(false);
        }
    };

    // effect to add event listner on mount and remove the same on unmount
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, []);

    const insertIntoSubject = placeholder => {
        if (!props.disableSubject) {
            props.change('subject', subject.current.value ? `${subject.current.value} ${placeholder}` : placeholder);
            setSubjectPlaceholderOpen(!subjectPlaceholderOpen);
        }
    };

    const insertIntoEditor = placeholder => {
        if (!props.disableBody) {
            const scope = get(editorRef, 'current.ref.current.ref.current.ck.editor', undefined);
            get(scope, 'insertText', () => {}).call(scope, `${placeholder}`);
            setEditorPlaceholderOpen(!editorPlaceholderOpen);
        }
    };

    const localeLabel = field => t(`customerMessages.campaign.form.input.label.${field}`);

    const dispatchNotificationPopup = message => dispatch(showNotificationPopup(message));

    const renderPlaceholderList = mode => (
        <div ref={placeholderList} className="placeholder-list">
            {Object.entries(allPlaceholders).map(([key, value]) => (
                <ul key={key}>
                    <li>
                        <div className="placeholder-key">{key}</div>
                        <ul>
                            {value &&
                                value.map(placeholder => (
                                    <li
                                        key={placeholder.key}
                                        onClick={() => {
                                            mode === 'editor'
                                                ? insertIntoEditor(placeholder.key)
                                                : insertIntoSubject(placeholder.key);
                                        }}
                                    >
                                        <div className="placeholder">{placeholder.name}</div>
                                    </li>
                                ))}
                        </ul>
                    </li>
                </ul>
            ))}
        </div>
    );
    return (
        <div>
            {props.isLoading ? (
                <Loader />
            ) : (
                <section>
                    <Row gutter={16} className="rel-pos settings-body">
                        {props.showCC ? (
                            <Col>
                                <Field
                                    type="select"
                                    mode="tags"
                                    className="select-tags-mode"
                                    options={
                                        props.contactCC
                                            ? props.contactCC.map(c => ({
                                                  name: `${c.first_name || ''} ${c.last_name || ''} (${c.email})`,
                                                  value: c.email,
                                              }))
                                            : []
                                    }
                                    tokenSeparators={[',']}
                                    name={requestKeys.CC}
                                    label={localeLabel(requestKeys.CC)}
                                    component={FormField}
                                />
                            </Col>
                        ) : null}
                        <Col span={5} className="right-align preview-placeholder-button-holder">
                            {!props.disableSubject && (
                                <section className="placeholder-wrapper">
                                    <Button className="blue-bg-button" forwardedRef={subjectPlaceholderButton}>
                                        <Icon type="bulb" />
                                        Placeholder
                                    </Button>
                                    {subjectPlaceholderOpen && renderPlaceholderList('subject')}
                                </section>
                            )}
                        </Col>
                        <Col
                            onClick={() =>
                                props.disableSubject
                                    ? dispatchNotificationPopup(
                                          t('customerMessages.reminders.send.notificationPopup.email')
                                      )
                                    : ''
                            }
                        >
                            <Field
                                ref={subject}
                                type="text"
                                name={requestKeys.SUBJECT}
                                label={localeLabel(requestKeys.SUBJECT)}
                                component={FormField}
                                validate={[formValidations.required]}
                                disabled={props.disableSubject}
                                required
                            />
                        </Col>
                    </Row>
                    <Row gutter={16} className="settings-body">
                        <Col span={10} className="right-align auto-width">
                            <div className="sm:flex sm:justify-end sm:gap-2">
                                {props.message ? (
                                    <PermissionGuard requiredPermission={REMINDER.PREVIEW}>
                                        <Button
                                            className="blue-bg-button preview-button"
                                            filled
                                            onClick={() => props.showPreview()}
                                        >
                                            <Icon type="eye" />
                                            Preview
                                        </Button>
                                    </PermissionGuard>
                                ) : null}
                                {!props.disableBody ? (
                                    <section className="placeholder-wrapper">
                                        <Button className="blue-bg-button" forwardedRef={editorPlaceholderButton}>
                                            <Icon type="bulb" />
                                            Placeholder
                                        </Button>
                                        {editorPlaceholderOpen && renderPlaceholderList('editor')}
                                    </section>
                                ) : null}
                            </div>
                        </Col>
                        <Col
                            className="email-body"
                            span={24}
                            onClick={() =>
                                props.disableBody
                                    ? dispatchNotificationPopup(
                                          t('customerMessages.reminders.send.notificationPopup.email')
                                      )
                                    : ''
                            }
                        >
                            <label htmlFor="name">
                                Body<span className="required-star">*</span>
                            </label>
                            <Field
                                ref={editorRef}
                                name="message"
                                label="Body"
                                component={CkEditor}
                                message={props.message}
                                setMessage={props.setMessage}
                                change={props.change}
                                validate={[formValidations.required]}
                                withRef
                                forwardRef
                                disabled={props.disableBody}
                            />
                        </Col>
                    </Row>
                </section>
            )}
        </div>
    );
};

EmailFields.defaultProps = {
    showCC: true,
};

EmailFields.propTypes = {
    change: PropTypes.func,
    ckeditor: PropTypes.object,
    contactCC: PropTypes.arrayOf(PropTypes.object),
    disable: PropTypes.bool,
    disableBody: PropTypes.bool,
    disableSubject: PropTypes.bool,
    isLoading: PropTypes.bool,
    message: PropTypes.string,
    reInitialize: PropTypes.bool,
    setMessage: PropTypes.func,
    showCC: PropTypes.bool,
    showPreview: PropTypes.func,
    subject: PropTypes.string,
    t: PropTypes.func,
};

export default EmailFields;

import { Icon } from 'antd';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

const COUNTRY_CODE_STRIPE_PRICING_URL_MAP = {
    AU: 'https://stripe.com/au/pricing',
    AT: 'https://stripe.com/en-at/pricing',
    BE: 'https://stripe.com/en-be/pricing',
    BR: 'https://stripe.com/en-br/pricing',
    BG: 'https://stripe.com/en-bg/pricing',
    CA: 'https://stripe.com/en-ca/pricing',
    HR: 'https://stripe.com/en-hr/pricing',
    CY: 'https://stripe.com/en-cy/pricing',
    CZ: 'https://stripe.com/en-cz/pricing',
    DK: 'https://stripe.com/en-dk/pricing',
    EE: 'https://stripe.com/en-ee/pricing',
    FI: 'https://stripe.com/en-fi/pricing',
    FR: 'https://stripe.com/en-fr/pricing',
    DE: 'https://stripe.com/en-de/pricing',
    GI: 'https://stripe.com/en-gi/pricing',
    GR: 'https://stripe.com/en-gr/pricing',
    HK: 'https://stripe.com/en-hk/pricing',
    HU: 'https://stripe.com/en-hu/pricing',
    IN: 'https://stripe.com/in/pricing',
    IE: 'https://stripe.com/ie/pricing',
    IT: 'https://stripe.com/en-it/pricing',
    JP: 'https://stripe.com/en-jp/pricing',
    LV: 'https://stripe.com/en-lv/pricing',
    LI: 'https://stripe.com/en-li/pricing',
    LT: 'https://stripe.com/en-lt/pricing',
    LU: 'https://stripe.com/en-lu/pricing',
    MY: 'https://stripe.com/en-my/pricing',
    MT: 'https://stripe.com/en-mt/pricing',
    MX: 'https://stripe.com/en-mx/pricing',
    NL: 'https://stripe.com/en-nl/pricing',
    NZ: 'https://stripe.com/nz/pricing',
    NO: 'https://stripe.com/en-no/pricing',
    PL: 'https://stripe.com/en-pl/pricing',
    PT: 'https://stripe.com/en-pt/pricing',
    RO: 'https://stripe.com/en-ro/pricing',
    SG: 'https://stripe.com/en-sg/pricing',
    SK: 'https://stripe.com/en-sk/pricing',
    SI: 'https://stripe.com/en-si/pricing',
    ES: 'https://stripe.com/en-es/pricing',
    SE: 'https://stripe.com/en-se/pricing',
    CH: 'https://stripe.com/en-ch/pricing',
    TH: 'https://stripe.com/en-th/pricing',
    AE: 'https://stripe.com/ae/pricing',
    GB: 'https://stripe.com/gb/pricing',
    US: 'https://stripe.com/us/pricing',
};

/**
 * Stripe OAuth connect modal description component
 *
 * @since 2.8.0
 */
export default function StripeConnectDescription() {
    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);

    const STRIPE_PROS = useMemo(() => {
        let stripePricingLink =
            COUNTRY_CODE_STRIPE_PRICING_URL_MAP[selectedOrganisation?.country?.code] ??
            COUNTRY_CODE_STRIPE_PRICING_URL_MAP.US; // Default to US if no match found

        return [
            {
                description: <>Customers can pay invoices using their credit card, debit card.</>,
                icon: <Icon type="credit-card" style={{ fontSize: 30 }} />,
                title: 'Fast and easy customer payments',
            },
            {
                description: (
                    <>
                        <div>Get more visibility over your cash flow.</div>
                        <b>Payouts take 2 days to appear in your bank account from the payment date.</b>
                    </>
                ),
                icon: <Icon type="eye" style={{ fontSize: 30 }} />,
                title: 'See payments in real time',
            },
            {
                description: (
                    <>
                        <div>
                            No monthly subscription costs, only pay per transaction. You can choose to pass on some or
                            all of the card processing fee to your customer.
                        </div>
                        <a href={stripePricingLink} target="_blank" rel="noreferrer">
                            Read about Stripe fees
                        </a>
                    </>
                ),
                icon: <Icon type="user-delete" style={{ fontSize: 30 }} />,
                title: 'Free, one time set up',
            },
        ];
    }, []);

    return (
        <div className="flex flex-col gap-3">
            {STRIPE_PROS.map(pro => (
                <div key={pro.title} className="flex items-center">
                    <div className="p-4">{pro.icon}</div>
                    <div>
                        <h4>{pro.title}</h4>
                        <div className="mt-0">{pro.description}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

/**
 * Placeholder Form
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import required modules
import { React, useState } from '../../../includes/exports/react';
import { Row, Col, Form } from 'antd';
import * as constants from '../../../includes/constants';
import * as formValidations from '../../../includes/utils/form';
import { Field, reduxForm } from 'redux-form';
import Button from '../../shared/Button';
import FormField from '../../shared/FormField';
import PropTypes from 'prop-types';
import Loader from '../../shared/Loader';
import { ID } from '../../../includes/constants/keys/response';
import { get } from 'lodash';

const PlaceholderForm = props => {
    const [keyChaged, setKeyChanged] = useState(false);
    const isAdd = !get(props.initialValues, ID);
    return (
        <div>
            {props.isPlaceholdersLoading ? (
                <Loader />
            ) : (
                <div>
                    <header>Placeholder</header>
                    <Form layout="vertical" className="edit-form edit-email-template" onSubmit={props.handleSubmit}>
                        <section className="profile-detail-lower">
                            <div className="details-wrapper">
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Field
                                            type="text"
                                            name="name"
                                            label="Name"
                                            hasFeedback
                                            className="form-control"
                                            component={FormField}
                                            validate={[formValidations.required]}
                                            onChange={e => {
                                                !keyChaged &&
                                                    props.change(
                                                        'key',
                                                        e.target.value.toLowerCase().replace(/\W/g, '_')
                                                    );
                                            }}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <Field
                                            type="text"
                                            name="key"
                                            label="Key"
                                            hasFeedback
                                            className="form-control"
                                            component={FormField}
                                            onChange={e =>
                                                e.target.value ? setKeyChanged(true) : setKeyChanged(false)
                                            }
                                            validate={[
                                                formValidations.required,
                                                formValidations.alphaNumericSmallAndUnderscore,
                                            ]}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col>
                                        <Field
                                            type="text"
                                            name="value"
                                            label="value"
                                            hasFeedback
                                            className="form-control"
                                            component={FormField}
                                            validate={[formValidations.required]}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={16} className={props.error ? ' has-error default-form-app-error' : ''}>
                                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                                    <Button
                                        htmlType="submit"
                                        big
                                        filled
                                        loading={props.isAddingPlaceholder || props.isUpdatingPlaceholder}
                                        submittingButtonLabel={props.t(
                                            `customerMessages.placeholders.form.submit.${
                                                isAdd ? 'add' : 'update'
                                            }.buttonSubmittingLabel`
                                        )}
                                        className="register-btn"
                                    >
                                        {props.t(
                                            `customerMessages.placeholders.form.submit.${
                                                isAdd ? 'add' : 'update'
                                            }.buttonSubmitLabel`
                                        )}
                                    </Button>
                                </Row>
                            </div>
                        </section>
                    </Form>
                </div>
            )}
        </div>
    );
};

/**
 * Proptypes
 */
PlaceholderForm.propTypes = {
    change: PropTypes.func,
    error: PropTypes.string,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isAddingPlaceholder: PropTypes.bool,
    isPlaceholdersLoading: PropTypes.bool,
    isUpdatingPlaceholder: PropTypes.bool,
    mode: PropTypes.string,
    openModal: PropTypes.bool,
    setOpenModal: PropTypes.func,
    t: PropTypes.func,
};

export default reduxForm({ form: constants.ADD_EDIT_PLACEHOLDER_FORM })(PlaceholderForm);

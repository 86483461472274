import { Button, Modal } from 'antd';
import FormField from 'components/shared/FormField';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import * as formValidations from 'includes/utils/form';
import { PAYMENT_PLAN_FORM_CHANGE_ALL_CATEGORY } from 'includes/constants';
import { useSelector } from 'react-redux';

const fieldValueSelector = formValueSelector(PAYMENT_PLAN_FORM_CHANGE_ALL_CATEGORY);
const maxValidation = formValidations.maxValue(100);
const minValidation = formValidations.minValue(0);

const ChangeAllFieldsModal = ({ onIncrease, onDecrease, isOpen, onCancel }) => {
    const changeBy = useSelector(state => fieldValueSelector(state, 'change_by'));

    const handleSubmit = type => () => {
        if (type === 'inc' && onIncrease) {
            onIncrease(Math.abs(changeBy));
        }
        if (type === 'dec' && onDecrease) onDecrease(-Math.abs(changeBy));
    };
    return (
        <Modal
            title="Change all values"
            visible={isOpen}
            centered={true}
            onCancel={onCancel}
            footer={
                <div className="flex gap-3 self-end ">
                    <Button key="reset-button" onClick={handleSubmit('inc')} className="ant-btn white-bg-button">
                        Increase
                    </Button>

                    <Button key="cancel-button" onClick={handleSubmit('dec')} className="ant-btn white-bg-button">
                        Decrease
                    </Button>
                </div>
            }
        >
            <Field
                type="number"
                className="text-left"
                name="change_by"
                placeholder="10"
                label="Change all fields by"
                component={FormField}
                min={0}
                max={100}
                required
                validate={[formValidations.number, maxValidation, minValidation]}
                addonAfter={<h6>%</h6>}
            />
        </Modal>
    );
};

/**
 * Proptypes
 */
ChangeAllFieldsModal.propTypes = {
    onIncrease: PropTypes.func,
    onDecrease: PropTypes.func,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default reduxForm({
    form: PAYMENT_PLAN_FORM_CHANGE_ALL_CATEGORY,
})(ChangeAllFieldsModal);

/**
 * Organisation edit Component
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

// import required modules
import { React } from '../../../includes/exports/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Modal } from 'antd';

import RequestQuoteForm from '../../shared/forms/RequestQuote';
import { requestQuote } from '../../../includes/redux/actions/shared/user';

const { info } = Modal;

/**
 * Request Quote
 * Renders the edit organisation form
 */
const RequestQuote = props => {
    /**
     * Submit handler function for handling edit contact form
     */
    const handleSubmit = values => {
        props.requestQuote(values, () => {
            props.history.push('/');
            info({
                title: 'Thank you',
                content: (
                    <div>We look forward to speaking to you real soon. We will be in touch in less than 24hours.</div>
                ),
            });
        });
    };

    return (
        <div className="home-content-wrapper edit-profile-wrapper">
            <h2 className="page-title">Request Quote</h2>
            <RequestQuoteForm onSubmit={handleSubmit} {...props} />
        </div>
    );
};

/**
 * Proptypes
 */
RequestQuote.propTypes = {
    history: PropTypes.object,
    requestQuote: PropTypes.func,
};

//Connect to store
export default connect(
    state => ({
        isLoading: state.user.isRequestingQuote,
    }),
    {
        requestQuote,
    }
)(withTranslation()(RequestQuote));

/**
 * useEnforceAddOrganisation
 * adds an additional check to see if an organisation is selected, if yes, calls the method,
 * else renders an info popup
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import React from 'react';
import { Modal } from 'antd';

import useOrganisationId from './useOrganisationId';
import { useHistory } from 'react-router';
import useIsDebtor from 'includes/hooks/useIsDebtor';

const { info } = Modal;

/**
 * Checks if organisation is present before calling the method
 * if its not present, renders an info modal
 *
 * @param {Function} method the callback to be called if a valid organisation is selected
 * @returns {Function}
 */
const useEnforceAddOrganisation = method => {
    const organisationId = useOrganisationId();
    const isDebtor = useIsDebtor();
    const history = useHistory();

    return (...args) => {
        if (organisationId) {
            method(...args);
        } else if (!isDebtor) {
            info({
                content: <div>Please add/select an organisation before continuing</div>,
                onOk: () => history.push('/organisation/add'),
            });
        }
    };
};

export default useEnforceAddOrganisation;

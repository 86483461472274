/**
 * User Subscription Listing Component
 * Handles the user subscription list view and functions related to loading user subscription listing
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules

import { React, connect, useMemo, Link, useCallback } from '../../../includes/exports/react';
import { Icon } from 'antd';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';

import Table from '../../shared/ScrollableTable';
import Button from '../../shared/Button';
import {
    getSubscriptionsHistory,
    getSubscriptionStatuses,
    exportAllSubscriptions,
} from '../../../includes/redux/actions/customer/subscriptionPlan';
import { useDataFromStore, useUpdateBusinessName } from '../../../includes/utils/hooks';
import { getSubscriptionColumns } from '../../../includes/constants/columns';
import './styles.scss';
import { getDefaultImageClass, getImageFromData, redirect } from '../../../includes/utils';
import ExportButton from '../../shared/Exports/button';
import PermissionGuard from 'components/PermissionGuard';
import { SUBSCRIPTION } from 'includes/constants/permissions';

/**
 * *  User Subscription Component
 */

const UserSubscription = props => {
    const accountLogo45Key = 'ACCOUNT_LOGO_45X45';
    const accountLogo254Key = 'ACCOUNT_LOGO_254X254';
    const accountLogoOriginalKey = 'ACCOUNT_LOGO_ORIGINAL';
    const fetchSubscriptionHistory = (searchParams = {}) => props.getSubscriptionsHistory(searchParams);

    // check if user has updated the business name
    useUpdateBusinessName();

    const [subscriptionStatuses] = useDataFromStore({
        reducer: 'subscription',
        dataKey: 'subscriptionStatuses',
        loadingKey: 'isSubscriptionStatusesLoading',
        action: getSubscriptionStatuses,
    });

    const { accountDetails, subscriptionsHistory, isAdmin } = props;

    // format subscriptions history table
    const formatSubscriptionHistoryTableData = useCallback(
        () =>
            subscriptionsHistory.map(subscriptionHistory => ({
                ...subscriptionHistory,
                plan: (
                    <div className="wrapped-content-text">
                        {subscriptionHistory.plan.name}
                        {!isAdmin && (
                            <span className="crud-wrapper">
                                <PermissionGuard requiredPermission={SUBSCRIPTION.VIEW}>
                                    <button
                                        className="crud-link"
                                        onClick={() => redirect(`/subscriptions/${subscriptionHistory.id}`)}
                                    >
                                        View
                                    </button>
                                </PermissionGuard>
                            </span>
                        )}
                    </div>
                ),
                status: get(find(subscriptionStatuses, { id: subscriptionHistory.status }), 'status'),
                account: (
                    <div className="table-name-align">
                        <img
                            className={
                                getDefaultImageClass(
                                    subscriptionHistory.account,
                                    accountLogo45Key,
                                    accountLogoOriginalKey,
                                    '45x45'
                                ) + ' logo logo-45 '
                            }
                            src={getImageFromData(
                                subscriptionHistory.account,
                                accountLogo45Key,
                                accountLogoOriginalKey
                            )}
                            alt={subscriptionHistory.account.name}
                        />
                        <div className="wrapped-content-text">
                            <span className="wrapped-content-text crud-wrapper">
                                {subscriptionHistory.account.name}
                            </span>
                            <span className="crud-wrapper">
                                <Link className="crud-link" to={`/admin/subscriptions/${subscriptionHistory.id}`}>
                                    View
                                </Link>
                            </span>
                        </div>
                    </div>
                ),
            })),
        [subscriptionsHistory, subscriptionStatuses] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const subscriptionHistoryTableData = useMemo(() => formatSubscriptionHistoryTableData(), [
        formatSubscriptionHistoryTableData,
    ]);

    const subscription = get(props, 'accountDetails.subscription');

    return (
        <div className="home-content-wrapper user-subscription-wrapper">
            <h2 className="page-title">User Subscription</h2>
            {!isAdmin && (
                <section className="white-box-wrapper box-wrapper full-wrapper user-subs-summary">
                    <summary className="left-pane">
                        <div className="profile-pic subscription-account-logo">
                            <img
                                className={
                                    getDefaultImageClass(
                                        accountDetails,
                                        accountLogo254Key,
                                        accountLogoOriginalKey,
                                        '77x77'
                                    ) + ' logo logo-45 '
                                }
                                src={getImageFromData(accountDetails, accountLogo254Key, accountLogoOriginalKey)}
                                alt={get(accountDetails, 'name', '')}
                            />
                        </div>
                        <div className="user-detail">
                            <div className="profile-name">{get(accountDetails, 'name', '')}</div>
                            {subscription && (
                                <div className="summary">
                                    <Icon type="calendar" />
                                    Plan subscribed on: {get(subscription, 'start_date', '')}
                                </div>
                            )}
                        </div>
                    </summary>
                    <summary className="right-align right-pane">
                        <div className="alert-info">
                            {get(subscription, 'expires_in')
                                ? 'Current plan will expire on'
                                : 'Your plan has expired on'}{' '}
                            - {get(subscription, 'expiry_date', '')}
                            {subscription?.plan?.is_custom ? null : (
                                <Button filled className="blue-bg-button upgrade-btn">
                                    <Link to="/plans">Upgrade</Link>
                                </Button>
                            )}
                        </div>
                        <div className="info subscription-plan-info">{get(subscription, 'plan_name')}</div>
                    </summary>
                </section>
            )}
            <section className="white-box-wrapper box-wrapper full-wrapper">
                {props.isAdmin && (
                    <div className="right-align">
                        <ExportButton exportType={'subscriptions'} />
                    </div>
                )}
                <Table
                    columns={getSubscriptionColumns(props)}
                    dataSource={subscriptionHistoryTableData}
                    dataValues={[]}
                    getDataMethod={fetchSubscriptionHistory}
                    paginationData={props.paginationSubscriptionsHistory}
                    rowKey="id"
                    size="middle"
                    loading={props.isSubscriptionsHistoryLoading}
                    {...props}
                />
            </section>
        </div>
    );
};

//prop types
UserSubscription.propTypes = {
    accountDetails: PropTypes.object,
    exportAllSubscriptions: PropTypes.func,
    getSubscriptionStatuses: PropTypes.func,
    getSubscriptionsHistory: PropTypes.func,
    isAdmin: PropTypes.bool,
    isExportingAllSubscriptions: PropTypes.bool,
    isSubscriptionsHistoryLoading: PropTypes.bool,
    subscriptionsHistory: PropTypes.array,
    paginationSubscriptionsHistory: PropTypes.object,
};

// connect to store
export default connect(
    state => ({
        accountDetails: state.account.accountDetails,
        subscriptionsHistory: state.subscription.subscriptionsHistory,
        isSubscriptionsHistoryLoading: state.subscription.isSubscriptionsHistoryLoading,
        isExportingAllSubscriptions: state.subscription.isExportingAllSubscriptions,
        roles: state.user.roles,
        isAdmin: state.account.isAdmin,
        paginationSubscriptionsHistory: state.subscription.paginationSubscriptionsHistory,
    }),
    {
        getSubscriptionsHistory,
        exportAllSubscriptions,
    }
)(UserSubscription);

/**
 * Table body component
 * This is a component responsible for rendering the table body
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

import PropTypes from 'prop-types';
import { get } from 'lodash';
import { React, useCallback, useMemo } from '../../../includes/exports/react';
import { Checkbox } from 'antd';

const TableBody = props => {
    const { tableData, rowKey, rowSelection, columns, handleCheckboxClick, isStatic } = props;

    // data key list to map data
    const getDataKeyList = useCallback(() => columns.map(c => c.dataIndex), [columns]);

    const dataKeyList = useMemo(() => getDataKeyList(), [getDataKeyList]);

    return (
        <tbody className={isStatic ? 'body-overflow-scroll' : ''}>
            {tableData.map(record => (
                <tr key={record[rowKey]}>
                    {rowSelection && (
                        <td className="row-check">
                            <Checkbox
                                checked={rowSelection.selectedRowKeys.includes(record[rowKey])}
                                id={record[rowKey]}
                                onChange={handleCheckboxClick}
                            />
                        </td>
                    )}
                    {dataKeyList.map((key, index) => (
                        <td key={`${key}-${index}`}>{get(record, key)}</td>
                    ))}
                </tr>
            ))}
            {tableData.length === 0 && (
                <tr>
                    <td colSpan={columns.length}>
                        <p className="empty-table-msg">{props.localeMessage}</p>
                    </td>
                </tr>
            )}
        </tbody>
    );
};

TableBody.propTypes = {
    columns: PropTypes.array,
    handleCheckboxClick: PropTypes.func,
    isStatic: PropTypes.bool,
    localeMessage: PropTypes.node,
    rowKey: PropTypes.string,
    rowSelection: PropTypes.object,
    tableData: PropTypes.array,
};

export default TableBody;

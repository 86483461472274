/**
 * Countries Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import CountriesApi from '../services/countries';
import createAppSlice from './base';
import { COUNTRIES, COUNTRY } from '../constants/mappings/success';
import { get } from 'lodash';

// set the initial state
const initialState = {
    countries: [],
    countryByIp: {},
    loadingCountries: false,
    loadingCountryByIp: false,
};

// define the slice
const CountriesSlice = createAppSlice('countriesSlice', initialState);
const { setData } = CountriesSlice.actions;

/**
 * Get the countries
 */
export const getCountries = () => async dispatch => {
    try {
        dispatch(setData('loadingCountries', true));
        const result = await CountriesApi.getCountries();
        dispatch(setData('countries', get(result, COUNTRIES)));
    } finally {
        dispatch(setData('loadingCountries'));
    }
};

/**
 * Get country by IP
 */
export const getCountryByIp = () => async dispatch => {
    try {
        dispatch(setData('loadingCountryByIp', true));
        const result = await CountriesApi.getCountryByIp();
        dispatch(setData('countryByIp', get(result, COUNTRY)));
    } finally {
        dispatch(setData('loadingCountryByIp'));
    }
};

// export the reducer
export default CountriesSlice.reducer;

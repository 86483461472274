/**
 * Organisation Settings Component
 * Handles the organisation settings view and functions related to loading organisation settings
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import { React, connect } from '../../../includes/exports/react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

/**
 * Organisation Settings List Component
 * Render the organisation settings list component
 *
 * @returns    {string}    Organisation settings listing view
 */
const SettingsList = props => {
    // settings menu list
    let settingsMenus = [
        {
            header: 'Default Settings',
            details: 'Manage settings',
            slug: 'settings',
        },
        {
            header: 'Email Template',
            details: 'Add and manage email templates',
            slug: 'email',
        },
        {
            header: 'SMS Template',
            details: 'Add and manage sms templates',
            slug: 'sms',
        },
        {
            header: 'IVR Template',
            details: 'Add and manage ivr templates',
            slug: 'ivr',
        },
        {
            header: 'WhatsApp Template',
            details: 'Add and manage WhatsApp templates',
            slug: 'whatsapp',
        },
        {
            header: 'Placeholders',
            details: 'Add and manage placeholders',
            slug: 'placeholder',
        },
        // {
        //     header: 'Template types',
        //     details: 'List of template types',
        //     slug: 'templateTypes',
        // },
    ];
    if (props.allowCustomEmailSettings) {
        settingsMenus.push({
            header: 'Send Test Email',
            details: 'Send Test Email to check email settings',
            slug: 'sendTestEmail',
        });
    }

    return (
        <div className="settings-list-wrapper">
            <div className="menu-header">
                <header>Settings List</header>
                <img
                    src={require('../../../assets/images/transparent-right-arrow.svg')}
                    className="right-arrow right-arrow-unselected settings-menu-open"
                    alt="right-arrow"
                />
            </div>
            <ul>
                {settingsMenus.map(({ slug, ...menu }) => (
                    <li
                        className={`menu ${props.selectedMenu === slug ? 'active-menu' : ''}`}
                        key={slug}
                        onClick={() => props.onChange(slug)}
                    >
                        <div className="header">
                            {menu.header}
                            <img
                                src={require('../../../assets/images/transparent-right-arrow.svg')}
                                className="right-arrow right-arrow-unselected"
                                alt="right-arrow"
                            />
                            <img
                                src={require('../../../assets/images/transparent-right-arrow-white.svg')}
                                className="right-arrow right-arrow-selected"
                                alt="right-arrow"
                            />
                        </div>
                        <div className="header-details">{menu.details}</div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

/**
 * Proptypes
 */
SettingsList.propTypes = {
    allowCustomEmailSettings: PropTypes.bool,
    selectedMenu: PropTypes.string,
    onChange: PropTypes.func,
};

// connect to store
export default connect()(withTranslation()(SettingsList));

/**
 * Placeholder actions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import action types
import * as actionTypes from '../../../constants/actionTypes';

/**
 * Add placeholder action
 *
 * @param {object} obj Input Data Object
 * @param {Function} callback Callback function
 *
 * @returns {object} Add placeholder action
 */
export const addPlaceholder = (obj, callback) => ({
    payload: obj,
    type: actionTypes.ADD_PLACEHOLDER_REQUEST,
    callback,
});

/**
 * Delete placeholder action
 *
 * @param {string} organisationId Organisation Id
 * @param {string} placeholderId Placeholder id
 *
 * @returns {object} Delete placeholder action
 */
export const deletePlaceholder = (organisationId, placeholderId) => ({
    type: actionTypes.DELETE_PLACEHOLDER_REQUEST,
    payload: { organisationId, placeholderId },
});

/**
 * Get all placeholders action
 *
 * @param {string} organisationId Organisation Id
 * @param {object} searchParams Search Params
 *
 * @returns {object} Get all placeholders action
 */
export const getAllPlaceholders = (organisationId, searchParams) => ({
    payload: {
        organisation_id: organisationId,
        searchParams,
    },
    type: actionTypes.GET_ALL_PLACEHOLDERS_REQUEST,
});

/**
 * Get placeholders action
 *
 * @param {string} organisationId Organisation Id
 * @param {object} searchParams Search Params
 *
 * @returns {object} Get placeholders action
 */
export const getPlaceholders = (organisationId, searchParams) => ({
    payload: {
        organisation_id: organisationId,
        searchParams,
    },
    type: actionTypes.GET_PLACEHOLDERS_REQUEST,
});

/**
 * Update placeholder action
 *
 * @param {object} obj Input Data Object
 * @param {Function} callback Callback function
 *
 * @returns {object} Update placeholder action
 */
export const updatePlaceholder = (obj, callback) => ({
    payload: obj,
    type: actionTypes.UPDATE_PLACEHOLDER_REQUEST,
    callback,
});

/**
 * Registration Form
 * Renders the registration form
 * This component is a shared component which can be used in different modules
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

import { React, connect } from '../../../includes/exports/react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import FormField from '../FormField';
import Button from '../Button';
import { email, phone, required } from '../../../includes/utils/form';
import * as constants from '../../../includes/constants';
import * as requestKeys from '../../../includes/constants/keys/request';
import CommunicationSettings from '../../CommunicationSettings';

const BusinessInfoEdit = props => {
    const { t } = props;

    // Set default values for mode of contact field
    const modesOfContact = [];
    ['email', 'call', 'sms'].forEach(mode => {
        if (get(props, `initialValues.contact_via_${mode}`)) modesOfContact.push(mode);
    });

    // Get localised label
    const localeLabel = field => t(`customerMessages.profile.businessInformation.form.input.label.${field}`);

    return (
        <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
            <div className="profile-detail-upper">
                <Field
                    type="avatar"
                    name={requestKeys.LOGO}
                    label={localeLabel(requestKeys.AVATAR)}
                    imageName={props.t('customerMessages.editContact.form.input.label.account_logo')}
                    isModalVisible={props.imagePickerVisible}
                    onSubmit={image => {
                        props.change(requestKeys.LOGO, image);
                        props.toggleImagePicker();
                    }}
                    hasRemovedKey={requestKeys.HAS_DELETED_LOGO}
                    toggleImagePicker={props.toggleImagePicker}
                    hasFeedback
                    component={FormField}
                    validate={[]}
                    reduxChange={props.change}
                    imageSourceSizeKey={constants.IMAGE_254x254}
                    imageSourceOriginalKey={constants.IMAGE_ORIGINAL}
                    defaultImage={props.defaultImage}
                />
                <ul className="upper-right-detail">
                    <li>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    name={requestKeys.PRIMARY_CONTACT_FIRST_NAME}
                                    label={localeLabel(requestKeys.PRIMARY_CONTACT_FIRST_NAME)}
                                    hasFeedback
                                    component={FormField}
                                    validate={[required]}
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    name={requestKeys.PRIMARY_CONTACT_LAST_NAME}
                                    label={localeLabel(requestKeys.PRIMARY_CONTACT_LAST_NAME)}
                                    hasFeedback
                                    component={FormField}
                                    validate={[required]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    name={requestKeys.NAME}
                                    label={localeLabel(requestKeys.BUSINESS_NAME)}
                                    hasFeedback
                                    className="form-control"
                                    component={FormField}
                                    validate={[required]}
                                />
                            </Col>
                            <Col span={12} className="phone-field-wrapper">
                                <Field
                                    type="phone"
                                    name={requestKeys.PRIMARY_CONTACT_PHONE_NUMBER}
                                    label={localeLabel(requestKeys.PHONE_NUMBER)}
                                    hasFeedback
                                    className="form-control"
                                    component={FormField}
                                    validate={[phone]}
                                    reduxChange={props.change}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    name={requestKeys.REGISTRATION_NUMBER}
                                    label={localeLabel(requestKeys.ABN)}
                                    className="form-control"
                                    component={FormField}
                                    validate={[]}
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    name={requestKeys.PRIMARY_CONTACT_EMAIL_ADDRESS}
                                    label={localeLabel(requestKeys.EMAIL)}
                                    hasFeedback
                                    className="form-control"
                                    component={FormField}
                                    validate={[required, email]}
                                />
                            </Col>
                        </Row>
                    </li>
                </ul>
            </div>
            <hr className="mrgn-tp-25" />
            <section className="profile-detail-lower">
                <div className="details-wrapper">
                    <Row gutter={16}>
                        <Col span={12}>
                            <Field
                                type="textarea"
                                name={requestKeys.POSTAL_ADDRESS}
                                label={localeLabel(requestKeys.POSTAL_ADDRESS)}
                                hasFeedback
                                className="form-group"
                                component={FormField}
                            />
                        </Col>

                        <Col span={12}>
                            <Field
                                type="select"
                                name="mode_of_contact"
                                label={localeLabel(requestKeys.MODE_OF_CONTACT)}
                                mode="multiple"
                                className="form-control"
                                component={FormField}
                                options={[
                                    { value: 'email', name: 'Email' },
                                    { value: 'call', name: 'Call' },
                                    { value: 'sms', name: 'SMS' },
                                ]}
                                input={{
                                    defaultValue: modesOfContact,
                                    onChange: value => {
                                        ['email', 'call', 'sms'].forEach(m => {
                                            if (value.indexOf(m) > -1) props.change(`contact_via_${m}`, true);
                                            else props.change(`contact_via_${m}`, false);
                                        });
                                    },
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Field
                                type="switch"
                                name={requestKeys.RECURRING_SUBSCRIPTION}
                                label={localeLabel(requestKeys.RECURRING_SUBSCRIPTION)}
                                hasFeedback
                                className="form-control"
                                component={FormField}
                                info={t(
                                    `customerMessages.profile.businessInformation.form.input.info.recurring_subscription`
                                )}
                            />
                        </Col>

                        {props.isAdmin ? (
                            <>
                                <Col span={8}>
                                    <Field
                                        type="switch"
                                        name={requestKeys.IS_TEST_ACCOUNT}
                                        label={localeLabel(requestKeys.IS_TEST_ACCOUNT)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                    />
                                </Col>
                                <Col span={8}>
                                    <Field
                                        type="switch"
                                        name={requestKeys.IS_DEBT_COLLECTION_ENABLED}
                                        label={localeLabel(requestKeys.IS_DEBT_COLLECTION_ENABLED)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                    />
                                </Col>
                            </>
                        ) : (
                            ''
                        )}
                    </Row>
                    <CommunicationSettings initialValues={props.initialValues} showAllowSettings={true} />
                </div>
            </section>
            <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                <Button htmlType="submit" big filled className="register-btn" loading={props.loading}>
                    {props.t('sharedMessages.editUser.form.submit.buttonLabel')}
                </Button>
            </Form.Item>
            <div className="clearfix" />
        </Form>
    );
};

//proptypes
BusinessInfoEdit.propTypes = {
    change: PropTypes.func,
    defaultImage: PropTypes.any,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    hideSubmitButton: PropTypes.bool,
    imagePickerVisible: PropTypes.bool,
    initialValues: PropTypes.object,
    isAdmin: PropTypes.bool,
    loading: PropTypes.bool,
    t: PropTypes.func,
    toggleImagePicker: PropTypes.func,
};

/**
 * Default Props
 */
BusinessInfoEdit.defaultProps = {
    hideSubmitButton: false,
};

const reduxFormWrappedBusinessInformation = reduxForm({
    form: constants.BUSINESS_FORM_NAME,
})(withTranslation()(BusinessInfoEdit));

export default connect(
    () => ({
        defaultImage: require('../../../assets/images/user.svg'),
    }),
    null
)(reduxFormWrappedBusinessInformation);

/**
 * Email communication log fields to show
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import React from 'react';
import PropTypes from 'prop-types';

const EmailCommunicationLogHistoryFields = ({ renderField }) => (
    <section>
        {renderField({ key: 'cc' })}
        {renderField({ key: 'bcc' })}
        {renderField({ key: 'reply_to' })}
        {renderField({ key: 'subject' })}
        {renderField({ key: 'message', isHtml: true, onSameLine: false })}
        {renderField({ key: 'delivered_date' })}
        {renderField({ key: 'opened_count' })}
        {renderField({ key: 'last_opened_date' })}
        {renderField({ key: 'last_opened_user_agent' })}
        {renderField({ key: 'clicked_count' })}
        {renderField({ key: 'last_clicked_date' })}
        {renderField({ key: 'last_clicked_user_agent' })}
        {renderField({ key: 'spam_report_count' })}
        {renderField({ key: 'last_spam_report_date' })}
        {renderField({ key: 'last_clicked_link', isLink: true, onSameLine: false })}
        {renderField({
            key: 'opened_dates',
            isList: true,
            onSameLine: false,
            relatedField: 'opened_user_agents',
        })}
        {renderField({
            key: 'clicked_dates',
            isList: true,
            onSameLine: false,
            relatedField: 'clicked_user_agents',
        })}
        {renderField({ key: 'spam_report_dates', isList: true, onSameLine: false })}
        {renderField({
            key: 'clicked_links',
            basicList: true,
            isLink: true,
            isList: true,
            onSameLine: false,
        })}
    </section>
);

EmailCommunicationLogHistoryFields.propTypes = {
    renderField: PropTypes.func.isRequired,
};

export default EmailCommunicationLogHistoryFields;

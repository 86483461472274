/**
 * Zendesk ticket details component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import React from 'react';
import ZendeskBreadCrumbItems from 'components/BreadCrumbItems/Zendesk';
import ZendeskIntegrationEnabled from 'components/Zendesk/IntegrationEnabled';
import ZendeskTicket from 'components/Zendesk/Tickets/Details';
import { Breadcrumb } from 'antd';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

/**
 * Zendesk ticket details component
 */
const ZendeskTicketDetails = () => {
    const { t } = useTranslation();
    const params = useParams();
    const { ticketId } = params;

    return (
        <ZendeskIntegrationEnabled>
            <div className="home-content-wrapper edit-campaign-wrapper">
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <ZendeskBreadCrumbItems showListBreadCrumb showDetailsBreadCrumb />
                    </Breadcrumb>
                </div>
                <h2 className="page-title">{t(`titleAndMetas.zendeskTicketDetails.pageTitle`)}</h2>
                <div className="white-box-wrapper box-wrapper mid-wrapper">
                    <ZendeskTicket ticketId={ticketId} />
                </div>
            </div>
        </ZendeskIntegrationEnabled>
    );
};

export default ZendeskTicketDetails;

import i18n from 'includes/i18n';

/**
 * Contact type columns
 *
 * @since 2.0.0
 *
 * @returns [array] columns Notes table columns
 */
export const contactTypesColumns = [
    {
        title: i18n.t('messages.contactTypes.list.label.type'),
        dataIndex: 'type',
        key: 'type',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('messages.contactTypes.list.label.unique_id'),
        dataIndex: 'unique_id',
        key: 'unique_id',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.contactTypes.list.label.contacts_count'),
        dataIndex: 'contacts_count',
        key: 'contacts_count',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.contactTypes.list.label.contacts_amount_due'),
        dataIndex: 'contacts_amount_due',
        key: 'contacts_amount_due',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.contactTypes.list.label.auto_reminders'),
        dataIndex: 'auto_reminders',
        key: 'auto_reminders',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.contactTypes.list.label.is_default'),
        dataIndex: 'is_default',
        key: 'is_default',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.contactTypes.list.label.actions'),
        dataIndex: 'actions',
        key: 'actions',
        collapseHeader: true,
    },
];

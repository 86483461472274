/**
 * Table header component
 * This is a component responsible for rendering table headers with sort indiactor and filters
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

import PropTypes from 'prop-types';
import { React } from '../../../includes/exports/react';
import { get, omit } from 'lodash';
import { Checkbox, Icon } from 'antd';

import Filter from './Filter';
import RangeFilter from './RangeFilter';
import i18n from 'includes/i18n';

const TableHeader = props => {
    const {
        rowSelection,
        filters,
        rangeFilters,
        setFilters,
        setRangeFilters,
        setActiveFilterPanel,
        sortField,
        sortOrder,
    } = props;

    const getSortOrderIcon = field => {
        if (sortField === field) return sortOrder === 1 ? 'caret-up' : 'caret-down';
        else return 'caret-up';
    };

    const toggleSortOrder = field => {
        props.setSortOrder(sortField === field ? 1 - sortOrder : 1);
        props.setSortField(field);
    };

    return (
        <thead>
            <tr>
                {rowSelection && (
                    <th className="row-check">
                        <Checkbox
                            checked={
                                props.allkeys.every(key => rowSelection.selectedRowKeys.includes(key)) &&
                                props.dataLength > 0
                            }
                            onChange={e => {
                                if (e.target.checked) {
                                    props.selectAllRows();
                                    props.setShowSelectAllMessage(true);
                                } else {
                                    props.setShowSelectAllMessage(false);
                                    rowSelection.onChange([]);
                                }
                            }}
                        />
                    </th>
                )}
                {props.columns.map(column => {
                    const columnFilterOptions = get(column, 'filters', []);
                    const columnRangeFilters = get(column, 'rangeFilters', []);
                    const rangeFilterType = get(column, 'rangeFilterType');
                    const rangeFilterBetweenLabel = get(column, 'rangeFilterBetweenLabel');
                    const defaultFilterValue = get(column, 'defaultFilteredValue', '');
                    const { sorter } = column;
                    return (
                        <th key={column.dataIndex} className="rel-pos">
                            <div className="table-header-wrapper">
                                <span
                                    onClick={() => {
                                        sorter && toggleSortOrder(column.dataIndex);
                                    }}
                                    className={`table-header-sorter-wrapper ${sorter ? 'pointer' : ''}`}
                                >
                                    <span className="table-header-title">{column.title}</span>
                                    {sorter && (
                                        <span
                                            className="table-header-sort-indicator"
                                            title={i18n.t('sharedMessages.table.sortBy', {
                                                columnName: column?.sortLabel ? column.sortLabel : column.title,
                                            })}
                                        >
                                            <Icon
                                                type={getSortOrderIcon(column.dataIndex)}
                                                className={
                                                    sortField === column.dataIndex
                                                        ? 'custom-sort-active'
                                                        : 'custom-sort-inactive'
                                                }
                                                title={i18n.t('sharedMessages.table.sortBy', {
                                                    columnName: column?.sortLabel ? column.sortLabel : column.title,
                                                })}
                                            />
                                        </span>
                                    )}
                                </span>
                                {columnFilterOptions.length > 0 && (
                                    <div
                                        className="table-header-filter-btn"
                                        title={i18n.t('sharedMessages.table.filterBy', {
                                            columnName: column?.filterLabel ? column.filterLabel : column.title,
                                        })}
                                    >
                                        <Icon
                                            type="filter"
                                            onClick={() => setActiveFilterPanel(column.dataIndex)}
                                            className="custom-filter"
                                            title={i18n.t('sharedMessages.table.filterBy', {
                                                columnName: column?.filterLabel ? column.filterLabel : column.title,
                                            })}
                                        />
                                        {props.activeFilterPanel === column.dataIndex && (
                                            <Filter
                                                value={get(filters, column.dataIndex, defaultFilterValue)}
                                                onSubmit={value => {
                                                    setFilters({ ...filters, [column.dataIndex]: value });
                                                    setActiveFilterPanel('');
                                                }}
                                                onReset={() => {
                                                    setFilters(omit(filters, column.dataIndex));
                                                    setActiveFilterPanel('');
                                                }}
                                                options={columnFilterOptions}
                                                forwardRef={props.forwardRef}
                                            />
                                        )}
                                    </div>
                                )}
                                {columnRangeFilters.length > 0 && (
                                    <div
                                        className="table-header-filter-btn"
                                        title={i18n.t('sharedMessages.table.filterBy', {
                                            columnName: column?.filterLabel ? column.filterLabel : column.title,
                                        })}
                                    >
                                        <Icon
                                            type="filter"
                                            onClick={() => setActiveFilterPanel(column.dataIndex)}
                                            className="custom-filter"
                                            title={i18n.t('sharedMessages.table.filterBy', {
                                                columnName: column?.filterLabel ? column.filterLabel : column.title,
                                            })}
                                        />
                                        {props.activeFilterPanel === column.dataIndex && (
                                            <RangeFilter
                                                values={get(rangeFilters, column.dataIndex, {})}
                                                onSubmit={values => {
                                                    setRangeFilters({ ...rangeFilters, [column.dataIndex]: values });
                                                    setActiveFilterPanel('');
                                                }}
                                                onReset={() => {
                                                    setRangeFilters(omit(rangeFilters, column.dataIndex));
                                                    setActiveFilterPanel('');
                                                }}
                                                rangeFilters={columnRangeFilters}
                                                rangeFilterType={rangeFilterType}
                                                rangeFilterBetweenLabel={rangeFilterBetweenLabel}
                                                forwardRef={props.forwardRef}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

TableHeader.propTypes = {
    activeFilterPanel: PropTypes.string,
    allkeys: PropTypes.array,
    columns: PropTypes.array,
    dataLength: PropTypes.number,
    filters: PropTypes.object,
    forwardRef: PropTypes.object,
    rangeFilters: PropTypes.object,
    rowSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    selectAllRows: PropTypes.func,
    setActiveFilterPanel: PropTypes.func,
    setFilters: PropTypes.func,
    setRangeFilters: PropTypes.func,
    setShowSelectAllMessage: PropTypes.func,
    setSortField: PropTypes.func,
    setSortOrder: PropTypes.func,
    sortField: PropTypes.string,
    sortOrder: PropTypes.number,
};

export default TableHeader;

/**
 * Reminders Component
 *
 * @author  Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 * @version 1.0
 */

//import required modules
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Switch, useLocation, useParams } from 'react-router-dom';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { COMMUNICATION_HISTORY_MINIMUM_AVAILABLE_DATE } from 'includes/constants/config';
import InfoMessage from 'components/shared/messages/InfoMessage';
import * as utils from 'includes/utils';
import RemindersList from './list';
import SendReminder from './send';
import CommunicationLogHistory from '../../shared/CommunicationLog';
import CommunicationLogHistoryDetails from '../../shared/CommunicationLog/details';
import { resetFilters } from 'includes/redux/actions/customer/reminder';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import {
    getCommunicationLogHistory,
    getCommunicationLogHistoryDetails,
    // getCommunicationLogStatuses,
} from 'includes/redux/actions/shared/communication';
// import { useDataFromStore } from 'includes/utils/hooks';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdmin from 'includes/hooks/useIsAdmin';
import { Breadcrumb } from 'antd';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import { REMINDER } from 'includes/constants/permissions';
import useOrganisationRequired from 'includes/hooks/useOrganisationRequired';

const ReminderRouter = props => {
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const organisationId = useOrganisationId();
    const { reminderId, communicationLogHistoryId } = useParams();
    const isAdmin = useIsAdmin();
    const location = useLocation();
    const { t } = useTranslation();

    useOrganisationRequired();

    // const communicationTypes = useSelector(state => state.communication.communicationTypes);

    // effect to reset filters when reminder module is unmounted
    useEffect(
        () => () => {
            let urlParams = utils.buildQueryParamsObject(get(props, 'history.location.search'));
            let shouldResetFilter = true;
            if (
                get(urlParams, 'reminder_sent') === 'true' ||
                get(props, 'history.location.pathname').indexOf('/invoice/') !== -1
            ) {
                shouldResetFilter = false;
            }
            if (shouldResetFilter) dispatch(resetFilters());
        },
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Fetch the communication log history
     */
    const fetchCommunicationHistoryLog = useCallback(
        // eslint-disable-next-line no-unused-vars
        (data = {}) => {
            if (organisationId) {
                dispatch(
                    getCommunicationLogHistory(organisationId, {
                        reminder_id: reminderId,
                        on_demand_campaign: false,
                        ...data,
                    })
                );
            }
        },
        [reminderId] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // fetch communication log details
    const fetchLogDetails = () => {
        dispatch(
            getCommunicationLogHistoryDetails(organisationId, communicationLogHistoryId, {
                reminder_id: reminderId,
            })
        );
    };

    const getLocaleText = path => t(`sharedMessages.communicationLogHistory.${path}`);

    const logHistoryBreadcrumb = [
        <Breadcrumb.Item key="reminders">
            <Link
                to={get(
                    location,
                    'state.from',
                    isAdmin ? `/admin/accounts/organisation/reminders/${accountId}/${organisationId}` : `/reminders`
                )}
            >
                {getLocaleText('breadcrumb.reminders')}
            </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key="reminder-history">{getLocaleText('breadcrumb.reminderHistory')}</Breadcrumb.Item>,
    ];

    //
    const logHistoryDetailsBreadcrumb = [
        <Breadcrumb.Item key="reminders">
            <Link
                to={get(
                    location,
                    'state.from',
                    isAdmin ? `/admin/accounts/organisation/reminders/${accountId}/${organisationId}` : `/reminders`
                )}
            >
                {getLocaleText('breadcrumb.reminders')}
            </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key="reminder-history">
            <Link
                to={
                    isAdmin
                        ? `/admin/accounts/organisation/reminders/${accountId}/${organisationId}/${reminderId}/history`
                        : `/reminders/${reminderId}/history`
                }
            >
                {getLocaleText('breadcrumb.reminderHistory')}
            </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key="reminder-history-details">
            {getLocaleText('breadcrumb.reminderHistoryDetails')}
        </Breadcrumb.Item>,
    ];

    // get communication log details link
    const getDetailsLink = useCallback(
        log => ({
            pathname: isAdmin
                ? `/admin/accounts/organisation/reminders/${accountId}/${organisationId}/${reminderId}/history/${log.id}`
                : `/reminders/${reminderId}/history/${log.id}`,
            state: {
                from: location.pathname,
                reminderFrom: get(
                    location,
                    'state.from',
                    isAdmin ? `/admin/accounts/organisation/reminders/${accountId}/${organisationId}` : `/reminders`
                ),
            },
        }),
        [location, isAdmin, accountId, organisationId, reminderId]
    );

    return (
        <Switch>
            <Route path="/admin/accounts/organisation/reminders/:accountId/:organisationId/:reminderId/history/:communicationLogHistoryId">
                <CommunicationLogHistoryDetails
                    fetchLog={fetchLogDetails}
                    breadcrumb={logHistoryDetailsBreadcrumb}
                    pageTitle={getLocaleText(`pageTitle.onDemandHistoryDetails`)}
                />
            </Route>
            <Route path="/admin/accounts/organisation/reminders/:accountId/:organisationId/:reminderId/history">
                <CommunicationLogHistory
                    getDataMethod={fetchCommunicationHistoryLog}
                    getDetailsLink={getDetailsLink}
                    pageTitle={t(`sharedMessages.communicationLogHistory.pageTitle.reminderHistory`)}
                    infoMessage={
                        <InfoMessage
                            message={getLocaleText('minimumAvailableDate', {
                                minAvailableDate: COMMUNICATION_HISTORY_MINIMUM_AVAILABLE_DATE,
                            })}
                        />
                    }
                    breadcrumb={logHistoryBreadcrumb}
                />
            </Route>
            <Route
                path="/admin/accounts/organisation/reminders/:accountId/:organisationId/:reminderId"
                component={SendReminder}
            />
            <Route path="/admin/accounts/organisation/reminders/:accountId/:organisationId" component={RemindersList} />
            <Route path="/reminders/:reminderId/history/:communicationLogHistoryId">
                <CommunicationLogHistoryDetails
                    fetchLogDetails={fetchLogDetails}
                    breadcrumb={logHistoryDetailsBreadcrumb}
                    pageTitle={getLocaleText(`pageTitle.reminderHistoryDetails`)}
                />
            </Route>
            <Route path="/reminders/:reminderId/history">
                <PermissionGuardLayout requiredPermission={REMINDER.HISTORY}>
                    <CommunicationLogHistory
                        getDataMethod={fetchCommunicationHistoryLog}
                        getDetailsLink={getDetailsLink}
                        pageTitle={t(`sharedMessages.communicationLogHistory.pageTitle.reminderHistory`)}
                        infoMessage={
                            <InfoMessage
                                message={getLocaleText('minimumAvailableDate', {
                                    minAvailableDate: COMMUNICATION_HISTORY_MINIMUM_AVAILABLE_DATE,
                                })}
                            />
                        }
                        breadcrumb={logHistoryBreadcrumb}
                    />
                </PermissionGuardLayout>
            </Route>
            <Route path="/reminders/:reminderId" component={SendReminder} />
            <Route path="/reminders" component={RemindersList} />
        </Switch>
    );
};

ReminderRouter.propTypes = {
    history: PropTypes.object,
    resetFilters: PropTypes.func,
};

export default ReminderRouter;

import React from 'react';
import { Modal } from 'antd';
import { Field, FieldArray } from 'redux-form';

import * as formValidations from 'includes/utils/form';
import CustomPaymentURLSVG from 'assets/images/custom-payment-url.svg';
import PaymentInstructionsSVG from 'assets/images/payment-instructions.svg';
import PayPalSVG from 'assets/images/paypal.svg';
import StripeSVG from 'assets/images/stripe.svg';
import FatzebraSVG from 'assets/images/fatzebra.svg';
import GoCardlessSVG from 'assets/images/gocardless.svg';
import MonoovaSVG from 'assets/images/monoova.svg';
import PayFurlSVG from 'assets/images/payfurl.svg';
import i18n from 'includes/i18n';
import FormField from 'components/shared/FormField';
import PaymentInstructionFields from './components/FormModal/components/PaymentInstructionFields';
import CustomPaymentURLFields from './components/FormModal/components/CustomPaymentURLFields';
import PayFurlFields from './components/FormModal/components/PayFurlFields';
import AdditionalChargesForCreditAndDebitCardsFormSection from './components/AdditionalChargesForCreditAndDebitCardsFormSection';
import { ADDITIONAL_TRANSACTION_CHARGES } from 'includes/constants';
import GoCardlessConnectDescription from './components/OAuthConnectPaymentServiceModal/components/GoCardlessConnectDescription';
import StripeConnectDescription from './components/OAuthConnectPaymentServiceModal/components/StripeConnectDescription';

/**
 * All payment service slug
 */
export const PAYMENT_SERVICE_SLUG = {
    CustomPaymentURL: 'custom-payment-url',
    FatZebra: 'fatzebra',
    GoCardless: 'gocardless',
    Monoova: 'monoova',
    PayFurl: 'payfurl',
    PaymentInstructions: 'payment-instructions',
    Paypal: 'paypal',
    Stripe: 'stripe',
};

/**
 * Object with payment service's slug as key and object containing details as value,
 */
export const PAYMENT_SERVICE = {
    [PAYMENT_SERVICE_SLUG.CustomPaymentURL]: {
        accentColor: '#C22F2F',
        connectDescription: i18n.t('customerMessages.paymentServices.service.custom-payment-url.connectDescription'),
        connectTitle: i18n.t('customerMessages.paymentServices.service.custom-payment-url.connectTitle'),
        defaultPaymentLabel: '',
        description: i18n.t('customerMessages.paymentServices.service.custom-payment-url.description'),
        formNote: null,
        loginLabel: i18n.t('customerMessages.paymentServices.service.custom-payment-url.login'),
        logo: CustomPaymentURLSVG,
        name: i18n.t('customerMessages.paymentServices.service.custom-payment-url.name'),
        signupLabel: '',
    },
    [PAYMENT_SERVICE_SLUG.FatZebra]: {
        accentColor: '#48dcfd',
        connectDescription: i18n.t('customerMessages.paymentServices.service.fatzebra.connectDescription'),
        connectTitle: i18n.t('customerMessages.paymentServices.service.fatzebra.connectTitle'),
        defaultPaymentLabel: 'Pay by FatZebra',
        description: i18n.t('customerMessages.paymentServices.service.fatzebra.description'),
        formNote: 'Users will not be able to save cards using this payment gateway.',
        loginLabel: i18n.t('customerMessages.paymentServices.service.fatzebra.login'),
        logo: FatzebraSVG,
        name: i18n.t('customerMessages.paymentServices.service.fatzebra.name'),
        signupLabel: '',
    },
    [PAYMENT_SERVICE_SLUG.GoCardless]: {
        accentColor: '#f1f252',
        connectDescription: <GoCardlessConnectDescription />,
        connectTitle: i18n.t('customerMessages.paymentServices.service.gocardless.connectTitle'),
        defaultPaymentLabel: 'Direct debit',
        description: i18n.t('customerMessages.paymentServices.service.gocardless.description'),
        formNote: null,
        loginLabel: i18n.t('customerMessages.paymentServices.service.gocardless.login'),
        logo: GoCardlessSVG,
        name: i18n.t('customerMessages.paymentServices.service.gocardless.name'),
        signupLabel: i18n.t('customerMessages.paymentServices.service.gocardless.signup'),
    },
    [PAYMENT_SERVICE_SLUG.Monoova]: {
        accentColor: '#663399',
        connectDescription: i18n.t('customerMessages.paymentServices.service.monoova.connectDescription'),
        connectTitle: i18n.t('customerMessages.paymentServices.service.monoova.connectTitle'),
        defaultPaymentLabel: 'Pay by credit card',
        description: i18n.t('customerMessages.paymentServices.service.monoova.description'),
        formNote: null,
        loginLabel: i18n.t('customerMessages.paymentServices.service.monoova.login'),
        logo: MonoovaSVG,
        name: i18n.t('customerMessages.paymentServices.service.monoova.name'),
        signupLabel: i18n.t('customerMessages.paymentServices.service.monoova.signup'),
    },
    [PAYMENT_SERVICE_SLUG.PayFurl]: {
        accentColor: '#A78BFA',
        connectDescription: i18n.t('customerMessages.paymentServices.service.payfurl.connectDescription'),
        connectTitle: i18n.t('customerMessages.paymentServices.service.payfurl.connectTitle'),
        defaultPaymentLabel: 'Pay by card',
        description: i18n.t('customerMessages.paymentServices.service.payfurl.description'),
        formNote: null,
        loginLabel: i18n.t('customerMessages.paymentServices.service.payfurl.login'),
        logo: PayFurlSVG,
        name: i18n.t('customerMessages.paymentServices.service.payfurl.name'),
        signupLabel: i18n.t('customerMessages.paymentServices.service.payfurl.signup'),
    },
    [PAYMENT_SERVICE_SLUG.PaymentInstructions]: {
        accentColor: '#51923B',
        connectDescription: i18n.t('customerMessages.paymentServices.service.payment-instructions.connectDescription'),
        connectTitle: i18n.t('customerMessages.paymentServices.service.payment-instructions.connectTitle'),
        defaultPaymentLabel: '',
        description: i18n.t('customerMessages.paymentServices.service.payment-instructions.description'),
        formNote: null,
        loginLabel: i18n.t('customerMessages.paymentServices.service.payment-instructions.login'),
        logo: PaymentInstructionsSVG,
        name: i18n.t('customerMessages.paymentServices.service.payment-instructions.name'),
        signupLabel: i18n.t('customerMessages.paymentServices.service.payment-instructions.signup'),
    },
    [PAYMENT_SERVICE_SLUG.Paypal]: {
        accentColor: '#0070e0',
        connectDescription: i18n.t('customerMessages.paymentServices.service.paypal.connectDescription'),
        connectTitle: i18n.t('customerMessages.paymentServices.service.paypal.connectTitle'),
        defaultPaymentLabel: '',
        description: i18n.t('customerMessages.paymentServices.service.paypal.description'),
        formNote: null,
        loginLabel: i18n.t('customerMessages.paymentServices.service.paypal.login'),
        logo: PayPalSVG,
        name: i18n.t('customerMessages.paymentServices.service.paypal.name'),
        signupLabel: i18n.t('customerMessages.paymentServices.service.paypal.signup'),
    },
    [PAYMENT_SERVICE_SLUG.Stripe]: {
        accentColor: '#6772e5',
        connectDescription: <StripeConnectDescription />,
        connectTitle: i18n.t('customerMessages.paymentServices.service.stripe.connectTitle'),
        defaultPaymentLabel: 'Pay by Stripe',
        description: i18n.t('customerMessages.paymentServices.service.stripe.description'),
        formNote: null,
        loginLabel: i18n.t('customerMessages.paymentServices.service.stripe.login'),
        logo: StripeSVG,
        name: i18n.t('customerMessages.paymentServices.service.stripe.name'),
        signupLabel: i18n.t('customerMessages.paymentServices.service.stripe.signup'),
    },
};

export const ADDITIONAL_TRANSACTION_CHARGES_REDUX_FORMFIELD = (
    <FieldArray component={AdditionalChargesForCreditAndDebitCardsFormSection} name={ADDITIONAL_TRANSACTION_CHARGES} />
);

export const PAYMENT_SERVICE_SPECIFIC_ADDITIONAL_TRANSACTION_CHARGES = {
    [PAYMENT_SERVICE_SLUG.FatZebra]: ADDITIONAL_TRANSACTION_CHARGES_REDUX_FORMFIELD,
    [getPaymentGatewaySlug(PAYMENT_SERVICE_SLUG.Stripe, true)]: ADDITIONAL_TRANSACTION_CHARGES_REDUX_FORMFIELD,
    [PAYMENT_SERVICE_SLUG.Stripe]: ADDITIONAL_TRANSACTION_CHARGES_REDUX_FORMFIELD,
};

/**
 * Array of common fields for the Payment service form
 */
export const COMMON_PAYMENT_SERVICE_FORM_FIELDS = [
    {
        name: 'account_name',
        type: 'text',
        label: i18n.t('customerMessages.paymentServices.form.label.accountName'),
        validate: [formValidations.required],
    },
    {
        name: 'label',
        type: 'text',
        label: i18n.t('customerMessages.paymentServices.form.label.paymentLabel'),
        validate: [formValidations.required],
    },
    {
        name: 'pay_button_text',
        type: 'text',
        label: i18n.t('customerMessages.paymentServices.form.label.payButtonNext'),
        validate: [formValidations.required],
    },
];

/**
 * Array of common fields for the Payment service form in admin
 */
export const ADMIN_COMMON_PAYMENT_SERVICE_FORM_FIELDS = [
    {
        name: 'name',
        type: 'text',
        label: i18n.t('customerMessages.paymentServices.form.label.name'),
        validate: [formValidations.required],
        additionalProps: {
            disabled: true,
        },
    },
    ...COMMON_PAYMENT_SERVICE_FORM_FIELDS,
];

/**
 * Array of common fields related to charges for the Payment service form
 */
export const PAYMENT_SERVICE_COMMON_CHARGES_FIELDS = [
    {
        name: 'gst',
        type: 'number',
        label: i18n.t('customerMessages.paymentServices.form.label.gst'),
        validate: [formValidations.required],
    },
    {
        name: 'transaction_charge',
        type: 'number',
        label: i18n.t('customerMessages.paymentServices.form.label.transactionCharge'),
        validate: [formValidations.required],
    },
    {
        name: 'transaction_charge_percentage',
        type: 'number',
        label: i18n.t('customerMessages.paymentServices.form.label.transactionChargePercentage'),
        validate: [formValidations.required],
    },
];

const SERVICE_CHARGE_FIELD = {
    name: 'service_charge',
    type: 'number',
    label: i18n.t('customerMessages.paymentServices.form.label.serviceCharge'),
    validate: [formValidations.required],
};

export const INTERNATIONAL_TRANSACTION_CHARGE_FIELDS = [
    {
        name: 'international_transaction_charge',
        type: 'number',
        label: i18n.t('customerMessages.paymentServices.form.label.internationalTransactionCharge'),
        validate: [formValidations.required],
    },
    {
        name: 'international_transaction_charge_percentage',
        type: 'number',
        label: i18n.t('customerMessages.paymentServices.form.label.internationalTransactionChargePercentage'),
        validate: [formValidations.required],
    },
];

export const PAYMENT_SERVICE_SPECIFIC_CHARGES_FIELDS = {
    [PAYMENT_SERVICE_SLUG.FatZebra]: [
        SERVICE_CHARGE_FIELD,
        ...PAYMENT_SERVICE_COMMON_CHARGES_FIELDS,
        ...INTERNATIONAL_TRANSACTION_CHARGE_FIELDS,
    ],
    [PAYMENT_SERVICE_SLUG.GoCardless]: [
        ...PAYMENT_SERVICE_COMMON_CHARGES_FIELDS,
        ...INTERNATIONAL_TRANSACTION_CHARGE_FIELDS,
        {
            name: 'max_transaction_charge',
            type: 'number',
            label: i18n.t('customerMessages.paymentServices.form.label.maxTransactionCharge'),
            validate: [formValidations.required],
        },
        {
            name: 'minimum_high_value_transaction_amount',
            type: 'number',
            label: i18n.t('customerMessages.paymentServices.form.label.minHighValueTransactionAmount'),
            validate: [formValidations.required],
        },
        {
            name: 'additional_transaction_charge_percentage',
            type: 'number',
            label: i18n.t('customerMessages.paymentServices.form.label.additionalTransactionChargePercentage'),
            validate: [formValidations.required],
        },
    ],
    [getPaymentGatewaySlug(PAYMENT_SERVICE_SLUG.GoCardless, true)]: [
        ...PAYMENT_SERVICE_COMMON_CHARGES_FIELDS,
        ...INTERNATIONAL_TRANSACTION_CHARGE_FIELDS,
        {
            name: 'max_transaction_charge',
            type: 'number',
            label: i18n.t('customerMessages.paymentServices.form.label.maxTransactionCharge'),
            validate: [formValidations.required],
        },
        {
            name: 'minimum_high_value_transaction_amount',
            type: 'number',
            label: i18n.t('customerMessages.paymentServices.form.label.minHighValueTransactionAmount'),
            validate: [formValidations.required],
        },
        {
            name: 'additional_transaction_charge_percentage',
            type: 'number',
            label: i18n.t('customerMessages.paymentServices.form.label.additionalTransactionChargePercentage'),
            validate: [formValidations.required],
        },
    ],
    [PAYMENT_SERVICE_SLUG.Monoova]: [
        ...PAYMENT_SERVICE_COMMON_CHARGES_FIELDS,
        ...INTERNATIONAL_TRANSACTION_CHARGE_FIELDS,
    ],
    [PAYMENT_SERVICE_SLUG.PayFurl]: [
        {
            name: 'service_charge',
            type: 'number',
            label: i18n.t('customerMessages.paymentServices.form.label.serviceCharge'),
            validate: [formValidations.required],
        },
        {
            name: 'gst',
            type: 'number',
            label: i18n.t('customerMessages.paymentServices.form.label.gst'),
            validate: [formValidations.required],
        },
        {
            name: 'transaction_charge',
            type: 'number',
            label: i18n.t('customerMessages.paymentServices.form.label.transactionCharge'),
            validate: [formValidations.required],
        },
        {
            name: 'transaction_charge_percentage',
            type: 'number',
            label: i18n.t('customerMessages.paymentServices.form.label.transactionChargePercentage'),
            validate: [formValidations.required],
        },
    ],
    [PAYMENT_SERVICE_SLUG.Stripe]: [
        ...PAYMENT_SERVICE_COMMON_CHARGES_FIELDS,
        ...INTERNATIONAL_TRANSACTION_CHARGE_FIELDS,
    ],
    [getPaymentGatewaySlug(PAYMENT_SERVICE_SLUG.Stripe, true)]: [
        ...PAYMENT_SERVICE_COMMON_CHARGES_FIELDS,
        ...INTERNATIONAL_TRANSACTION_CHARGE_FIELDS,
    ],
};

/**
 * Get payment gateway slug
 *
 * Appends "`_oauth`" to `slug` if `isConnectedViaOauth` is `true` and returns the string
 *
 * @param {string} slug default slug of payment gateway
 * @param {boolean} isConnectedViaOauth boolean indicating if the payment gateway was connected usin OAuth
 *
 * @returns string
 */
export function getPaymentGatewaySlug(slug, isConnectedViaOauth) {
    return `${slug}${isConnectedViaOauth ? '_oauth' : ''}`;
}

/**
 * Payment service specific fields
 *
 * key is `parent_slug` + `_oauth` if connected via oauth, else `parent_slug`
 */
export const PAYMENT_SERVICE_SPECIFIC_FIELDS = {
    [PAYMENT_SERVICE_SLUG.FatZebra]: [
        {
            name: 'public_key',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.sharedSecret'),
            validate: [formValidations.required],
            instructions: (
                <div className="flex flex-col gap-1">
                    <p>
                        Visit this <a href="https://fatzebra.com">site</a>
                    </p>
                </div>
            ),
        },
        {
            name: 'private_key',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.token'),
            validate: [formValidations.required],
        },
        {
            name: 'username',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.username'),
            validate: [formValidations.required],
        },
    ],
    [getPaymentGatewaySlug(PAYMENT_SERVICE_SLUG.GoCardless, true)]: [],
    [PAYMENT_SERVICE_SLUG.Monoova]: [
        {
            name: 'private_key',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.apiKey'),
            validate: [formValidations.required],
        },
    ],
    [PAYMENT_SERVICE_SLUG.PayFurl]: [],
    [PAYMENT_SERVICE_SLUG.Paypal]: [],
    [PAYMENT_SERVICE_SLUG.Stripe]: [
        {
            name: 'public_key',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.publishableKey'),
            validate: [formValidations.required],
        },
        {
            name: 'private_key',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.secretKey'),
            validate: [formValidations.required],
        },
    ],
    [getPaymentGatewaySlug(PAYMENT_SERVICE_SLUG.Stripe, true)]: [],
};

/**
 * Array of payment service specific fields for Payment service form in admin
 */
export const ADMIN_PAYMENT_SERVICE_SPECIFIC_FIELDS = {
    ...PAYMENT_SERVICE_SPECIFIC_FIELDS,
    [PAYMENT_SERVICE_SLUG.Stripe]: [
        {
            name: 'public_key',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.publishableKey'),
            validate: [formValidations.required],
        },
        {
            name: 'private_key',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.secretKey'),
            validate: [formValidations.required],
        },
        {
            name: 'webhook_secret_key',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.webhookSecretKey'),
            validate: [formValidations.required],
        },
        {
            name: 'partner_webhook_secret_key',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.partnerWebhookSecretKey'),
            validate: [formValidations.required],
        },
    ],
    [PAYMENT_SERVICE_SLUG.FatZebra]: [
        {
            name: 'public_key',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.sharedSecret'),
            validate: [formValidations.required],
            instructions: (
                <div className="flex flex-col gap-1">
                    <p>
                        Visit this <a href="https://fatzebra.com">site</a>
                    </p>
                </div>
            ),
        },
        {
            name: 'private_key',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.token'),
            validate: [formValidations.required],
        },
        {
            name: 'username',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.username'),
            validate: [formValidations.required],
        },
        {
            name: 'webhook_secret_key',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.webhookSecretKey'),
            validate: [formValidations.required],
        },
    ],
    [PAYMENT_SERVICE_SLUG.GoCardless]: [
        ...PAYMENT_SERVICE_SPECIFIC_FIELDS[getPaymentGatewaySlug(PAYMENT_SERVICE_SLUG.GoCardless, true)],
        {
            name: 'is_oauth_enabled',
            type: 'switch',
            label: i18n.t('customerMessages.paymentServices.form.label.isOauthEnabled'),
            validate: [],
        },
        {
            name: 'client_id',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.clientId'),
            validate: [formValidations.required],
        },
    ],
    [PAYMENT_SERVICE_SLUG.Stripe]: [
        ...PAYMENT_SERVICE_SPECIFIC_FIELDS[getPaymentGatewaySlug(PAYMENT_SERVICE_SLUG.Stripe, true)],
        {
            name: 'is_oauth_enabled',
            type: 'switch',
            label: i18n.t('customerMessages.paymentServices.form.label.isOauthEnabled'),
            validate: [],
        },
        {
            name: 'client_id',
            type: 'text',
            label: i18n.t('customerMessages.paymentServices.form.label.clientId'),
            validate: [formValidations.required],
        },
    ],
};

/**
 * Render payment service form field
 *
 * @param {object} field field details
 * @param {string} field.name field name
 * @param {string} field.type field type
 * @param {string} field.label field label
 * @param {Array} field.validate validate method array
 * @param {object} field.instructions field instructions
 *
 * @returns field JSX
 */
export const renderPaymentServiceFormField = field => (
    <div key={field.name}>
        <Field
            step="any"
            type={field.type}
            name={field.name}
            label={field.label}
            info={
                field.instructions ? (
                    <span>
                        See how to get it{' '}
                        <span
                            className="text-green-500 cursor-pointer"
                            onClick={() => Modal.info({ title: 'Instructions', content: field.instructions })}
                        >
                            here
                        </span>
                    </span>
                ) : null
            }
            hasFeedback
            className="form-control"
            component={FormField}
            validate={field.validate}
            required
            {...(field.additionalProps ?? {})}
        />
    </div>
);

/**
 * Custom render function field map
 *
 * overrides default field map to render all fields by the component
 */
export const PAYMENT_SERVICE_RENDER_FIELD_MAP = {
    [PAYMENT_SERVICE_SLUG.CustomPaymentURL]: CustomPaymentURLFields,

    [PAYMENT_SERVICE_SLUG.PayFurl]: PayFurlFields,

    [PAYMENT_SERVICE_SLUG.PaymentInstructions]: PaymentInstructionFields,
};

/**
 * Settings component
 * Handles the Admin settings view and functions related to admin settings
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

// import required modules
import { React, connect, PropTypes, useEffect, useState } from '../../../includes/exports/react';
import * as constants from '../../../includes/constants';
import * as requestKeys from '../../../includes/constants/keys/request';
import Loader from '../../shared/Loader';
import SettingsForm from '../forms/Settings';
import { change } from 'redux-form';
import { getSettings, updateSettings } from '../../../includes/redux/actions/admin/settings';

/**
 * Settings component
 */
const Settings = props => {
    const [imgaePickerVisible, setImagePickerVisible] = useState(false);
    const toggleImagePicker = () => setImagePickerVisible(!imgaePickerVisible);
    const { getSettings } = props;

    /**
     * Use Effect Hook to get settings
     */
    useEffect(() => {
        getSettings();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handle the settings form submit
     * Dispatches the settings form submit action
     *
     * @param   {Array}    values   Array of form values
     */
    const handleSubmit = values => {
        props.updateSettings(values);
    };

    return (
        <div>
            {props.isLoading ? (
                <Loader />
            ) : (
                <SettingsForm
                    onSubmit={handleSubmit}
                    onImageSubmit={image => {
                        props.setField(constants.SETTINGS_FORM_NAME, requestKeys.AVATAR, image);
                        toggleImagePicker();
                    }}
                    onCancel={toggleImagePicker}
                    imagePickerVisible={imgaePickerVisible}
                    toggleImagePicker={toggleImagePicker}
                    initialValues={props.initialValues}
                    {...props}
                />
            )}
        </div>
    );
};

/**
 *Prop types
 */

Settings.propTypes = {
    getSettings: PropTypes.func,
    initialValues: PropTypes.object,
    isLoading: PropTypes.bool,
    setField: PropTypes.func,
    settings: PropTypes.object,
    updateSettings: PropTypes.func,
};

export default connect(
    state => ({
        initialValues: state.adminSettings.initialValues,
        isLoading: state.adminSettings.isLoading,
        settings: state.adminSettings.settings,
    }),
    { getSettings, setField: change, updateSettings }
)(Settings);

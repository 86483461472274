/**
 * Organisation Payment account select Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useEffect } from 'react';
import './styles.scss';
import Button from '../../shared/Button';
import Loader from '../../shared/Loader';
import PropTypes from 'prop-types';
import useChartOfAccounts from 'includes/hooks/useChartOfAccounts';
import { Col, Modal, Row, Select } from 'antd';
import { find, get, isFunction } from 'lodash';
import { updateChartOfAccount } from 'includes/slices/chartOfAccounts';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { withTranslation } from 'react-i18next';

const { Option } = Select;

/**
 * Organisation Settings Component
 * Render the organisation settings component
 *
 * @returns    {string}    Organisation listing view
 */
const SelectOrganisationPaymentAccount = props => {
    const [chartOfAccountId, setChartOfAccountId] = useState();
    const [chartOfAccountError, setChartOfAccountError] = useState();
    const [showModal, setShowModal] = useState(true);
    const dispatch = useDispatch();
    const organisationId = useSelector(state => state.organisation.selectedOrganisationId);
    const loadingChartOfAccountSave = useSelector(state => state.chartOfAccounts.loadingChartOfAccountSave);
    const {
        data: chartOfAccounts,
        isLoading: loadingChartOfAccounts,
        reset: clearChartOfAccountsCache,
    } = useChartOfAccounts(false, 1);

    /**
     * Set the selected payment account id
     */
    useEffect(() => {
        if (chartOfAccounts.length) {
            let selectedChartOfAccountId = get(find(chartOfAccounts, { is_default_payment_account: true }), 'id');
            if (selectedChartOfAccountId) {
                setChartOfAccountId(selectedChartOfAccountId);
            }
        }
    }, [chartOfAccounts]);

    /**
     * Get the selected chart of account
     *
     * @returns {object} Selected chart of account
     */
    const getSelectedChartOfAccount = () => find(chartOfAccounts, { id: chartOfAccountId });

    /**
     * Render chart of account options
     */
    const renderChartOfAccountOptions = () => {
        return chartOfAccounts.map(chartOfAccount => {
            return (
                <Option value={chartOfAccount.id} key={chartOfAccount.id}>
                    {chartOfAccount.code} - {chartOfAccount.name}
                </Option>
            );
        });
    };

    /**
     * Save the chart of account payment account
     */
    const saveChartOfAccountPaymentAccount = () => {
        if (!chartOfAccountId) {
            setChartOfAccountError('Please select the payment account');
        } else {
            dispatch(
                updateChartOfAccount(
                    chartOfAccountId,
                    { ...getSelectedChartOfAccount(), transaction_type: 1, is_default_payment_account: true },
                    showErrorOrCloseModal,
                    organisationId
                )
            );
        }
    };

    /**
     * Show the error or close the modal
     */
    const showErrorOrCloseModal = () => {
        clearChartOfAccountsCache();
        setShowModal(false);
        if (
            props.setShowOrganisationSelectPaymentAccountModal &&
            isFunction(props.setShowOrganisationSelectPaymentAccountModal)
        ) {
            props.setShowOrganisationSelectPaymentAccountModal(false);
        }
    };

    /**
     * Render the component
     */
    return (
        <Modal
            footer={[]}
            className="my_profile_modal organisation-reauthorization-modal"
            visible={showModal}
            onCancel={() => {
                showErrorOrCloseModal();
            }}
        >
            {loadingChartOfAccounts ? (
                <Loader />
            ) : (
                <section>
                    <Row gutter={16} className="rel-pos settings-body">
                        <Col span={24} className="payment-account-title">
                            Select the Account to which payments should be made
                        </Col>
                        <Col span={24} className="payment-account-content">
                            <Select
                                onChange={value => {
                                    setChartOfAccountId(value);
                                }}
                                style={{ width: '100%' }}
                                defaultValue={get(getSelectedChartOfAccount(), 'id')}
                            >
                                {renderChartOfAccountOptions()}
                            </Select>
                        </Col>
                        <Button
                            onClick={() => {
                                saveChartOfAccountPaymentAccount();
                            }}
                            big
                            filled
                            className="green-bg-button payment-account-save-button"
                            submittingButtonLabel={props.t(
                                `customerMessages.organisation.paymentAccount.buttonLabel.saving`
                            )}
                            loading={loadingChartOfAccountSave}
                        >
                            {props.t(`customerMessages.organisation.paymentAccount.buttonLabel.save`)}
                        </Button>
                        <div className={chartOfAccountError ? ' has-error default-form-app-error' : ''}>
                            {chartOfAccountError ? <div className="ant-form-explain">{chartOfAccountError}</div> : ''}
                        </div>
                    </Row>
                </section>
            )}
        </Modal>
    );
};

SelectOrganisationPaymentAccount.propTypes = {
    setShowOrganisationSelectPaymentAccountModal: PropTypes.func,
    t: PropTypes.func,
};

export default withTranslation()(SelectOrganisationPaymentAccount);

import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/Button';
import PermissionGuard from 'components/PermissionGuard';
import { PAYMENT_BANK_ACCOUNT } from 'includes/constants/permissions';

/**
 * Description section component of bank accounts
 *
 * @since 2.8.0
 */
export default function Description({ onAddBankAccount }) {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col gap-3">
            <div>{t('customerMessages.paymentMethod.bankAccount.description')}</div>
            <PermissionGuard requiredPermission={PAYMENT_BANK_ACCOUNT.ADD}>
                <Button onClick={onAddBankAccount} className="mb-2 mx-auto">
                    {t('customerMessages.paymentMethod.action.addNewBankAccount')}
                </Button>
            </PermissionGuard>
        </div>
    );
}

Description.propTypes = {
    onAddBankAccount: PropTypes.func,
};

/**
 * Exports history object
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

import { createBrowserHistory } from 'history';
let history = createBrowserHistory();
export default history;

import PropTypes from 'prop-types';
import React from 'react';

export default function PermissionDependencyModalContentTable({ permissionLabels }) {
    return (
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3 rounded-s-lg">
                        Permission
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Depends On
                    </th>
                </tr>
            </thead>
            <tbody>
                {Object.entries(permissionLabels).map(([permission, dependents]) => (
                    <tr key={permission} className="bg-white dark:bg-gray-800">
                        <th
                            scope="row"
                            className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                            {permission}
                        </th>
                        <td className="px-6 py-4">{dependents.join(', ')}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

PermissionDependencyModalContentTable.propTypes = {
    permissionLabels: PropTypes.object,
};

import { PAYMENT_METHOD_STATUS } from 'includes/constants';
import usePaymentMethodStatuses from 'includes/hooks/usePaymentMethodStatuses';
import PropTypes from 'prop-types';
import React from 'react';
import { useMemo } from 'react';

const PAYMENT_METHOD_STATUS_CLASSNAME_MAP = {
    [PAYMENT_METHOD_STATUS.ACTIVE]: 'bg-green-100 text-green-800',
    [PAYMENT_METHOD_STATUS.CANCELLED]: 'bg-yellow-100 text-yellow-800',
    [PAYMENT_METHOD_STATUS.EXPIRED]: 'bg-red-100 text-red-800',
    [PAYMENT_METHOD_STATUS.FAILED]: 'bg-red-100 text-red-800',
    [PAYMENT_METHOD_STATUS.PENDING_SUBMISSION]: 'bg-indigo-100 text-indigo-800',
    [PAYMENT_METHOD_STATUS.REMOVED]: 'bg-red-200 text-red-800',
    [PAYMENT_METHOD_STATUS.RESUBMISSION_REQUESTED]: 'bg-purple-100 text-purple-800',
    [PAYMENT_METHOD_STATUS.SUBMITTED]: 'bg-blue-100 text-blue-800',
    [PAYMENT_METHOD_STATUS.SUSPENDED_BY_PAYER]: 'bg-gray-100 text-gray-800',
};

/**
 * Payment method status tag
 *
 * Render a tag indicating the status of payment method
 *
 * takes in `id` prop which is the status id
 *
 * @since 2.8.0
 */
export default function PaymentMethodStatus({ id }) {
    const { data: paymentMethodStatuses } = usePaymentMethodStatuses(true);

    const status = useMemo(() => {
        if (!id || !paymentMethodStatuses || paymentMethodStatuses.length === 0) return null;

        return paymentMethodStatuses.find(st => st.id === id);
    }, [id, paymentMethodStatuses]);

    return (
        <span
            className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded-full ${
                status ? PAYMENT_METHOD_STATUS_CLASSNAME_MAP[status.slug] : ''
            }`}
        >
            {status?.status}
        </span>
    );
}

PaymentMethodStatus.propTypes = {
    id: PropTypes.string,
};

/**
 * useCampaignNotificationDays
 * Get the campaign notification days
 *
 * @since 2.0.0
 */

import { CACHE_CAMPAIGN_NOTIFICATION_DAYS_KEY } from 'includes/constants';
import { CAMPAIGN_NOTIFICATION_DAYS } from 'includes/constants/mappings/success';
import { get } from 'lodash';
import CampaignApi from 'includes/services/campaigns';
import useCachedData from './useCachedData';
import useOrganisationId from 'includes/hooks/useOrganisationId';

/**
 * Get the campaign notification days
 *
 * @param {boolean} disableApiCall Whether to disable api call or not. Default false
 *
 * @returns object Campaign notification days data
 */
const useCampaignNotificationDays = (disableApiCall = false) => {
    const organisationId = useOrganisationId();
    return useCachedData(
        CACHE_CAMPAIGN_NOTIFICATION_DAYS_KEY,
        () => CampaignApi.getCampaignNotificationDays(organisationId, { page_size: 0 }),
        {
            selector: res => get(res, CAMPAIGN_NOTIFICATION_DAYS),
            disableApiCall: disableApiCall,
            dependsOnOrg: true,
        }
    );
};

export default useCampaignNotificationDays;

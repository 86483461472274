/**
 * User actions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import action types
import * as actionTypes from '../../../constants/actionTypes';

/**
 * Force rerender component request action
 *
 * @returns {object}            Force rerender component request  action
 */
export const forceReRenderComponentRequest = () => ({
    type: actionTypes.FORCE_RE_RENDER_COMPONENT_REQUEST,
});

/**
 * Force rerender component success action
 *
 * @returns {object}            Force rerender component success  action
 */
export const forceReRenderComponentSuccess = () => ({
    type: actionTypes.FORCE_RE_RENDER_COMPONENT_SUCCESS,
});

/**
 * Get initial data details action
 *
 * @param   {object}    obj     Payload
 *
 * @returns {object}            Get initial data details action
 */
export const getInitialData = obj => ({
    payload: obj,
    type: actionTypes.GET_INITIAL_DATA_REQUEST,
});

/**
 * Stop initial data loading action
 *
 * @returns {object}            Stop initial data loading action
 */
export const stopInitialDataLoading = () => ({
    type: actionTypes.GET_INITIAL_DATA_STOP_LOADING,
});

/**
 * Get all permissions
 *
 * @returns {object} Get all permissions action object
 */
export const getAllPermissions = () => ({
    type: actionTypes.GET_ALL_PERMISSIONS_REQUEST,
});

/**
 * Main Layout
 * Renders the child layouts
 * Calls the User API to get user details
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import the required modules
import { React, useEffect, useMemo } from '../includes/exports/react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import AppTitleAndMetas from '../components/shared/AppTitleAndMetas';
import LiveChat from '../components/shared/LiveChat';
import Loader from '../components/shared/Loader';
import * as settingsActions from '../includes/redux/actions/shared/settings';
import WebSocket from '../components/shared/WebSocket';
import { routes as adminRoutes } from '../includes/routes/admin';
import { routes as customerRoutes } from '../includes/routes/customer';
import { routes as debtCollectorRoutes } from '../includes/routes/debtCollector';
import {
    getLocalStorageValue,
    redirect,
    redirectToAuthPortal,
    removeLocalStorage,
    setDataInLocalStorage,
} from '../includes/utils';
import {
    REDIRECT_URL_AFTER_VERIFY,
    USER_CURRENT_ACCOUNT_KEY,
    USER_SELECTED_ORGANISATION_ID_KEY,
} from '../includes/constants';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { useCacheContext } from 'includes/contexts/cacheContext';
import { identify } from 'includes/utils/impact';
import { useSelector } from 'react-redux';
import UnderMaintenance from 'components/shared/UnderMaintenance';

const bypassEmailVerifiedValidationRoutes = [...adminRoutes, ...customerRoutes, ...debtCollectorRoutes]
    .filter(route => route.bypassEmailVerifiedValidation)
    .map(route => route.path);

const bypassPhoneNumberVerifiedValidationRoutes = [...adminRoutes, ...customerRoutes, ...debtCollectorRoutes]
    .filter(route => route.bypassPhoneNumberVerifiedValidation)
    .map(route => route.path);

/**
 * Main Layout
 * Renders the child layouts
 * Calls the User API to get user details
 */
const MainLayout = props => {
    const history = useHistory();
    const location = useLocation();
    const organisationId = useOrganisationId();
    const accountDetails = useSelector(state => state.account.accountDetails);
    const siteSettings = useSelector(state => state.settings.siteSettings);
    const { clearCache } = useCacheContext();

    const {
        getInitialData,
        errorMessage,
        routeSlug,
        initialDataLoading,
        isEmailVerified,
        isPhoneNumberVerified,
        match,
        currentAccountId,
    } = props;

    const accountDetailsId = accountDetails?.id;
    const accountDetailsHashedEmail = accountDetails?.hashed_email;

    useEffect(() => {
        if (accountDetailsId && accountDetailsHashedEmail) {
            identify(accountDetailsId, accountDetailsHashedEmail);
        }
    }, [location, accountDetailsId, accountDetailsHashedEmail]);

    /**
     * Get the account details of current viewing organisation
     */
    useEffect(() => {
        clearCache();
    }, [organisationId]);

    /**
     * Checks if two factor auth is enabled
     *
     * @since 2.6.0
     */
    const shouldRedirectToAuthPortal = useMemo(() => {
        if (get(siteSettings, 'portals') && currentAccountId) {
            if (
                (!get(props.userDetails, 'two_factor_auth_enabled', false) &&
                    get(props.userDetails, 'days_left_to_enforce_auth') === 0) ||
                (!isEmailVerified && !bypassEmailVerifiedValidationRoutes.includes(match.path)) ||
                (!isPhoneNumberVerified && !bypassPhoneNumberVerifiedValidationRoutes.includes(match.path))
            ) {
                return true;
            }
        }
    }, [currentAccountId, siteSettings]);

    /**
     * useEffect hook to call the Get User Details API on mount
     */
    useEffect(() => {
        if (window.location.pathname === '/auth') {
            const queryParameters = new URLSearchParams(location.search);

            const accountIdFromQueryParam = queryParameters.get('account_id') || '';

            const organisationIdFromQueryParams = queryParameters.get('organisation_id');

            if (accountIdFromQueryParam) {
                setDataInLocalStorage({ [USER_CURRENT_ACCOUNT_KEY]: accountIdFromQueryParam });
            }

            if (organisationIdFromQueryParams) {
                setDataInLocalStorage({ [USER_SELECTED_ORGANISATION_ID_KEY]: organisationIdFromQueryParams });
            }
        }
        getInitialData({ selected_organisation_id: props.selectedOrganisationId });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * redirect if the route is /auth
     */
    useEffect(() => {
        if (isEmpty(siteSettings)) return;

        const queryParameters = new URLSearchParams(location.search);

        if (window.location.pathname === '/auth') {
            const redirectUrl = queryParameters.get('redirect_url');
            if (redirectUrl) window.open(decodeURIComponent(redirectUrl), '_self');
            else window.open(window.location.origin, '_self');
        }

        if (!props.userDetails?.is_customer_user && !props.userDetails?.is_admin_or_manager) {
            redirectToAuthPortal();
        }

        if (props.userDetails?.is_admin_or_manager && window.location.pathname.split('/')[1] !== 'admin') {
            redirect('/admin/dashboard');
        }
    }, [siteSettings]);

    /**
     * hook to handle redirection to and from verify email/phone pages
     */
    useEffect(() => {
        if (shouldRedirectToAuthPortal) {
            setDataInLocalStorage({ [REDIRECT_URL_AFTER_VERIFY]: location.pathname });
            redirectToAuthPortal();
        } else {
            const redirectUrl = getLocalStorageValue(REDIRECT_URL_AFTER_VERIFY);
            if (redirectUrl && isEmailVerified && isPhoneNumberVerified) {
                removeLocalStorage(REDIRECT_URL_AFTER_VERIFY);
                history.replace(redirectUrl);
            }
        }
    }, [shouldRedirectToAuthPortal]); //eslint-disable-line react-hooks/exhaustive-deps

    // /**
    //  * useEffect hook to send out the redux action to show notification message
    //  */
    // useEffect(() => {
    //     if (errorMessage) {
    //         // show message
    //         message.error(errorMessage, 0);
    //     }
    // });

    /**
     * Render the component
     * Calls the layout specified in the route and renders it
     *
     * @returns {*}
     */
    return (
        <>
            <AppTitleAndMetas routeSlug={routeSlug} />
            {initialDataLoading ? (
                <div className="root-page-loader">
                    <Loader />
                </div>
            ) : errorMessage ? (
                <UnderMaintenance />
            ) : (
                <>
                    <WebSocket />
                    <LiveChat {...props} />
                    <props.layout {...props} />
                </>
            )}
        </>
    );
};

/**
 * Prop types
 */
MainLayout.propTypes = {
    accountDetails: PropTypes.object,
    currentAccountId: PropTypes.string,
    errorMessage: PropTypes.string,
    getInitialData: PropTypes.func,
    initialDataLoading: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isEmailVerified: PropTypes.bool,
    isPhoneNumberVerified: PropTypes.bool,
    match: PropTypes.object,
    requiredPermission: PropTypes.string,
    roles: PropTypes.object,
    routeSlug: PropTypes.string,
    selectedOrganisationId: PropTypes.string,
    stopInitialDataLoading: PropTypes.func,
    token: PropTypes.string,
    userDetails: PropTypes.object,
};

//Connect to store
export default connect(
    state => ({
        ...state.settings,
        accountDetails: state.account.accountDetails,
        token: state.user.token,
        selectedOrganisationId: state.organisation.selectedOrganisationId,
        userDetails: state.user.userDetails,
        isEmailVerified: get(state, 'user.userDetails.email_verified', false),
        isPhoneNumberVerified: get(state, 'user.userDetails.phone_number_verified', false),
        isAdmin: state.account.isAdmin,
        roles: state.user.roles,
        currentAccountId: state.account.currentAccountId,
    }),
    {
        ...settingsActions,
    }
)(withTranslation()(MainLayout));

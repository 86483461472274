/**
 * Notification Reducer
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';
import { getPaginationData } from '../../../../utils';

const initialState = {
    allNotifications: [],
    isLoading: true,
    notifications: [],
    notification: {},
    sendData: '',
    unreadCount: 0,
    paginationNotifications: {},
};
/**
 * Notification Reducer Function
 *
 * @param   {object}    state   Current State       Default is initialState
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function notificationReducer(state = initialState, action = {}) {
    switch (action.type) {
        case actionTypes.DELETE_ALL_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                allNotifications: [],
                notification: {},
                notifications: [],
                unreadCount: 0,
                paginationNotifications: {},
            };
        case actionTypes.GET_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                allNotifications: [],
                isLoading: false,
                paginationNotifications: {},
            };
        case actionTypes.GET_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                allNotifications: [],
                isLoading: true,
                paginationNotifications: {},
            };
        case actionTypes.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                allNotifications: action.result.data.data.notifications,
                paginationNotifications: getPaginationData(action),
                isLoading: false,
            };
        case actionTypes.RECEIVED_NOTIFICATION_FROM_SERVER:
            return {
                ...state,
                notification: action.notification,
                notifications: [action.notification, ...state.notifications],
                allNotifications: [action.notification, ...state.allNotifications],
                unreadCount: parseInt(state.unreadCount) + parseInt(action.unreadCount),
            };
        case actionTypes.RECEIVED_NOTIFICATIONS_FROM_SERVER:
            return {
                ...state,
                notifications: action.notifications,
                unreadCount: action.unreadCount,
            };
        case actionTypes.SEND_DATA_TO_SERVER:
            return {
                ...state,
                sendData: action.payload,
            };
        case actionTypes.UPDATE_ALL_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                allNotifications: action.allNotifications,
                unreadCount: action.unreadCount,
            };
        case actionTypes.UPDATE_NOTIFICATIONS_REQUEST:
            return {
                ...state,
                notifications: action.notifications,
                unreadCount: action.unreadCount,
            };
        default:
            return state;
    }
}

/**
 * Email preview Component
 *
 * @version 1.0
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import ShadowRoot from 'components/ShadowRoot';
import Loader from 'components/shared/Loader';

const Preview = ({ openPreview, setOpenPreview, preview, isLoadingPreview }) => {
    return (
        <Modal
            className="preview-modal custom-form custom-form-modal"
            visible={openPreview}
            footer={[]}
            onCancel={() => {
                setOpenPreview(false);
            }}
        >
            <header>Preview</header>
            {isLoadingPreview ? <Loader /> : <ShadowRoot className="shadow-modal-body" htmlContent={preview} />}
        </Modal>
    );
};

Preview.propTypes = {
    preview: PropTypes.string.isRequired,
    isLoadingPreview: PropTypes.bool,
    setOpenPreview: PropTypes.func,
    openPreview: PropTypes.bool,
};

export default Preview;

/**
 * Tax details Component
 * Renders the tax details
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useEffect } from 'react';
import { Breadcrumb, Tabs } from 'antd';
import { find, get } from 'lodash';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Loader from 'components/Loader';
import TaxDefaultImage from 'assets/images/tax-default-icon.png';
import TaxesBreadCrumbItems from 'components/BreadCrumbItems/Taxes';
import useTaxTypes from 'includes/hooks/useTaxTypes';
import { getTax } from 'includes/slices/taxes';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import PermissionGuard from 'components/PermissionGuard';
import { TAX } from 'includes/constants/permissions';
import { redirect } from 'includes/utils';
import AuditLogList from 'pages/AuditLogs/List';

const { TabPane } = Tabs;

const TaxDetails = () => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    useUpdateBusinessName();
    const { data: taxTypes, isLoading: loadingTaxTypes } = useTaxTypes();

    const { accountId, taxId } = params;
    const taxDetails = useSelector(state => state.taxes.tax);
    const isAdmin = useSelector(state => state.account.isAdmin);
    const loadingTax = useSelector(state => state.taxes.loadingTax);
    const selectedOrganisationId = useSelector(state => state.organisation.selectedOrganisationId);
    const organisationId = get(params, 'organisationId', selectedOrganisationId);

    /**
     * Get the tax
     */
    useEffect(() => {
        dispatch(getTax(taxId, organisationId));
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the tax edit link
     *
     * @returns {string} Edit link
     */
    const getEditLink = () =>
        isAdmin
            ? `/admin/accounts/organisation/taxes/tax/${accountId}/${organisationId}/${taxId}/edit`
            : `/taxes/tax/${taxId}/edit`;

    /**
     * Get the localised label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.taxes.addEdit.form.input.label.${field}`);

    return (
        <section>
            <div className="home-content-wrapper synced-name contact-details">
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <TaxesBreadCrumbItems showListBreadCrumb showDetailsBreadCrumb />
                    </Breadcrumb>
                </div>
                <h2 className="page-title">{t(`titleAndMetas.tax.pageTitle`)}</h2>
                <div className="white-box-wrapper mid-wrapper breadcrumb-spec details-wrapper">
                    {loadingTax || loadingTaxTypes ? (
                        <Loader />
                    ) : (
                        <>
                            <section className="details-list left-align">
                                <div className="details-profile-pic-wrapper">
                                    <img
                                        src={TaxDefaultImage}
                                        className="org-avatar contact-details-spec logo-placeholder"
                                        alt="tax pic"
                                    />
                                </div>
                                <div className="center-align">
                                    <PermissionGuard requiredPermission={TAX.EDIT}>
                                        <Button
                                            className="green-bg-button"
                                            big
                                            filled
                                            onClick={() => redirect(getEditLink())}
                                        >
                                            {t(`messages.chartOfAccounts.crud.edit`)}
                                        </Button>
                                    </PermissionGuard>
                                </div>
                            </section>
                            <section className="details-list right-align">
                                <div className="full-wrapper">
                                    <h3 className="profile-name">{get(taxDetails, 'name')}</h3>
                                </div>
                                <section className="detail-summary">
                                    <ul className="upper-right-detail">
                                        <li>
                                            <header>{getLocaleLabel('description')}</header>
                                            <div>{get(taxDetails, 'description')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('rate')}</header>
                                            <div>{`${get(taxDetails, 'rate', 0)}%`}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('external_id')}</header>
                                            <div>{get(taxDetails, 'external_id')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('tax_type_id')}</header>
                                            <div>
                                                {get(
                                                    find(taxTypes, {
                                                        id: get(taxDetails, 'tax_type_id'),
                                                    }),
                                                    'type'
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                </section>
                            </section>
                        </>
                    )}
                </div>
                {organisationId ? (
                    <div className="full-wrapper box-wrapper white-box-wrapper no-padding myaccount-content tab-wrapper">
                        <Tabs>
                            <TabPane key="audit-logs" tab="Audit Logs">
                                <AuditLogList customDataValues={{ tax_id: taxId }} showTitle={false} />
                            </TabPane>
                        </Tabs>
                    </div>
                ) : null}
            </div>
        </section>
    );
};

export default TaxDetails;

/**
 * Placeholder API class.
 * Handles all api requests related to placeholder
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import base api handler
import BaseApiHandler from '../base/api';
import { get } from 'lodash';
import { ID } from '../../constants/keys/request';
import { ORGANISATION_ID } from '../../constants/keys/request';

class PlaceholderApi extends BaseApiHandler {
    /**
     * Call the api to add placeholder
     *
     * @param {string} organisation_id Organisation id
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     * */
    addPlaceholder = ({ organisation_id, ...payload }) =>
        this.axios.post(
            this.getApiUrl(organisation_id ? 'PLACEHOLDERS_BY_ORGANISATION' : 'PLACEHOLDERS', { organisation_id }),
            payload
        );

    /**
     * Call the api to delete placeholder
     *
     * @param {string} placeholderId Placeholder id
     * @param {string} organisationId Organisation id
     *
     * @returns {object} API Response
     * */
    deletePlaceholder = ({ placeholderId, organisationId }) =>
        this.axios.delete(
            this.getApiUrl(organisationId ? 'PLACEHOLDER_ORGANISATION_UPDATE' : 'PLACEHOLDERS_UPDATE', {
                placeholder_id: placeholderId,
                organisation_id: organisationId,
            })
        );

    /**
     * Call the api to get the placeholders
     *
     * @param {object} searchParams Search Params
     * @param {object} restPayload Payload
     *
     * @returns {object} API Response
     * */
    getPlaceholders = ({ searchParams, ...restPayload }) => {
        let organisationId = get(restPayload, ORGANISATION_ID);
        return this.axios.get(
            this.getApiUrl(organisationId ? 'PLACEHOLDERS_BY_ORGANISATION' : 'PLACEHOLDERS', restPayload),
            {
                params: { ...searchParams },
            }
        );
    };

    /**
     * Call the api to update placeholder
     *
     * @param {string} organisation_id Organisation id
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     * */
    updatePlaceholder = ({ organisation_id, ...payload }) =>
        this.axios.patch(
            this.getApiUrl(organisation_id ? 'PLACEHOLDER_ORGANISATION_UPDATE' : 'PLACEHOLDERS_UPDATE', {
                placeholder_id: payload[ID],
                organisation_id: organisation_id,
            }),
            payload
        );
}

export default new PlaceholderApi();

/**
 * Order Reducer
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import get from 'lodash/get';
import * as actionTypes from '../../../../constants/actionTypes';
import * as successMappings from '../../../../constants/mappings/success';

const INITIAL_STATE = {
    isCreatingOrder: false,
    isOrderStatusesLoading: false,
    isOrderTypesLoading: false,
    isRetryingOrderPayment: false,
    isUpdatingOrder: false,
    orderDetails: {},
    ordersList: [],
    orderTypes: [],
    paginationOrders: {},
};

/**
 * Order Reducer Function
 * Handles the state changes in order
 *
 * @param   {object}    state   Current State
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function orderReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case actionTypes.CREATE_ORDER_FAILURE:
            return {
                ...state,
                isCreatingOrder: false,
            };
        case actionTypes.CREATE_ORDER_REQUEST:
            return {
                ...state,
                isCreatingOrder: true,
            };
        case actionTypes.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                clientSecretToken: get(action.result, successMappings.CLIENT_SECRET_TOKEN),
                orderId: get(action.result, successMappings.ORDER_ID),
                isCreatingOrder: false,
            };
        case actionTypes.GET_CONTACT_ORDER_DETAILS_FAILURE:
            return {
                ...state,
                isDetailsLoading: false,
            };
        case actionTypes.GET_CONTACT_ORDER_DETAILS_REQUEST:
            return {
                ...state,
                isDetailsLoading: true,
            };
        case actionTypes.GET_CONTACT_ORDER_DETAILS_SUCCESS:
            return {
                ...state,
                isDetailsLoading: false,
                orderDetails: action.orderDetails,
            };
        case actionTypes.GET_CONTACT_ORDER_STATUS_FAILURE:
        case actionTypes.GET_ORDER_STATUS_FAILURE:
            return {
                ...state,
                isOrderStatusLoading: false,
            };
        case actionTypes.GET_CONTACT_ORDER_STATUS_REQUEST:
        case actionTypes.GET_ORDER_STATUS_REQUEST:
            return {
                ...state,
                isOrderStatusLoading: true,
            };
        case actionTypes.GET_CONTACT_ORDER_STATUS_SUCCESS:
        case actionTypes.GET_ORDER_STATUS_SUCCESS:
            return {
                ...state,
                orderDetails: action.orderDetails,
                isOrderStatusLoading: false,
            };
        case actionTypes.RETRY_ORDER_PAYMENT_FAILURE:
            return {
                ...state,
                isCardAdding: false,
                isRetryingOrderPayment: false,
            };
        case actionTypes.RETRY_ORDER_PAYMENT_REQUEST:
            return {
                ...state,
                isCardAdding: false,
                isRetryingOrderPayment: true,
            };
        case actionTypes.RETRY_ORDER_PAYMENT_SUCCESS:
            return {
                ...state,
                isRetryingOrderPayment: false,
                isCardAdding: false,
                clientSecretToken: get(action.result, successMappings.CLIENT_SECRET_TOKEN_UPDATE_ORDER),
                orderId: get(action.result, successMappings.UPDATE_ORDER_ORDER_ID),
            };
        case actionTypes.UPDATE_ORDER_FAILURE:
            return {
                ...state,
                isUpdatingOrder: false,
            };
        case actionTypes.UPDATE_ORDER_REQUEST:
            return {
                ...state,
                isUpdatingOrder: true,
            };
        case actionTypes.UPDATE_ORDER_SUCCESS:
            return {
                ...state,
                orderDetails: get(action.result, successMappings.UPDATE_ORDER),
                isUpdatingOrder: false,
            };
        default:
            return state;
    }
}

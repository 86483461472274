/**
 * Settings API class.
 * Handles all api requests related to settings
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class SettingsApi extends BaseApiHandler {
    /**
     * Get all permissions
     */
    getAllPermissions = () => this.axios.get(this.getApiUrl('GET_ALL_PERMISSIONS'));

    /**
     * Call the settings API for getting the initial settings
     *
     * @returns {object}                    API Response
     */
    getInitialData = payload => this.axios.get(this.getApiUrl('SETTINGS_GET_INITIAL_DATA'), { params: { ...payload } });
}

export default new SettingsApi();

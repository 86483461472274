/**
 * Invoice campaign details history page
 *
 * @author Aravind JR <aravind@paycepaid.com.au>
 * @version 1.0
 */
import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';

import CommunicationLogHistoryDetails from 'components/shared/CommunicationLog/details.jsx';
import { getCommunicationLogHistoryDetails } from 'includes/redux/actions/shared/communication';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdmin from 'includes/hooks/useIsAdmin';
import { getInvoiceDetails } from 'includes/slices/invoice';

const InvoiceCampaignHistoryDetails = () => {
    const dispatch = useDispatch();
    const invoiceDetails = useSelector(state => state.invoices.invoiceDetails);
    const { t } = useTranslation();
    const organisationId = useOrganisationId();
    const { contactId, invoiceId, communicationLogHistoryId } = useParams();
    const accountId = useAccountId();
    const isAdmin = useIsAdmin();

    /**
     * Get localised text
     *
     * @param {string} path
     *
     * @returns {string} Localised string
     */
    const getLocaleText = path => t(`sharedMessages.communicationLogHistory.${path}`);

    // fetch invoice details to show the invoice number in breadcrumb
    useEffect(() => {
        dispatch(getInvoiceDetails(organisationId, contactId, invoiceId));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const breadcrumb = [
        <Breadcrumb.Item key="invoices">
            <Link to={isAdmin ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}` : `/invoices`}>
                {getLocaleText('breadcrumb.invoices')}
            </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key="invoice-number">
            <Link to={isAdmin ? '' : `/invoices/invoice/${contactId}/${invoiceId}`}>
                {get(invoiceDetails, 'invoice_number', 'Invoice')}
            </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key="on-demand-history">
            <Link
                to={
                    isAdmin
                        ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}/on-demand-campaign/history`
                        : `/invoices/invoice/${contactId}/${invoiceId}/campaign/history`
                }
            >
                {getLocaleText('breadcrumb.invoiceCampaignHistory')}
            </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key="details">{getLocaleText('breadcrumb.invoiceCampaignHistoryDetails')}</Breadcrumb.Item>,
    ];

    const fetchLogDetails = () => {
        dispatch(
            getCommunicationLogHistoryDetails(organisationId, communicationLogHistoryId, {
                on_demand_campaign: true,
            })
        );
    };

    return (
        <CommunicationLogHistoryDetails
            fetchLogDetails={fetchLogDetails}
            breadcrumb={breadcrumb}
            pageTitle={getLocaleText(`pageTitle.invoiceCampaignHistoryDetails`)}
        />
    );
};

export default InvoiceCampaignHistoryDetails;

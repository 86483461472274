/**
 * Accounting Software API class.
 * Handles all api requests related to accounting software
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class AccountingSoftwareApi extends BaseApiHandler {
    /**
     * Fetch list of accounting softwares
     *
     * @returns {object}                    API Response
     */
    getAccountingSoftwares = queryParams =>
        this.axios.get(this.getApiUrl('ACCOUNTING_SOFTWARES_GET_ALL'), { params: queryParams });
}

export default new AccountingSoftwareApi();

// import required modules
import { React } from '../../../../includes/exports/react';
import { Form, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useTranslation, withTranslation } from 'react-i18next';

import Button from '../../../shared/Button';
import * as constants from '../../../../includes/constants';
import * as formValidations from '../../../../includes/utils/form';
import FormField from '../../../shared/FormField';
import * as requestKeys from '../../../../includes/constants/keys/request';
import { useSelector } from 'react-redux';

/**
 * Add account form
 */
const AddAccountForm = props => {
    const { t } = useTranslation();

    const isLoadingAddAccount = useSelector(state => state.accounts.isLoadingAddAccount);

    const localeLabel = field => t(`adminMessages.accounts.form.input.label.${field}`);

    return (
        <div>
            <div className="my-profile-detail">
                <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                    <div className=" max-w-lg">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    name={requestKeys.FIRST_NAME}
                                    label={localeLabel(requestKeys.FIRST_NAME)}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    name={requestKeys.LAST_NAME}
                                    label={localeLabel(requestKeys.LAST_NAME)}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    name={requestKeys.COMPANY_NAME}
                                    label={localeLabel(requestKeys.COMPANY_NAME)}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    name={requestKeys.EMAIL}
                                    label={localeLabel(requestKeys.EMAIL)}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                />
                            </Col>
                            <Col span={12} className="phone-field-wrapper">
                                <Field
                                    type="phone"
                                    name={requestKeys.PHONE_NUMBER}
                                    label={localeLabel(requestKeys.PHONE_NUMBER)}
                                    hasFeedback
                                    className="form-control"
                                    component={FormField}
                                    validate={[formValidations.required, formValidations.phone]}
                                    reduxChange={props.change}
                                    onChange={value => props.change(requestKeys.PHONE_NUMBER, value ? value : null)}
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="select"
                                    name={requestKeys.LOCATION}
                                    label={localeLabel(requestKeys.LOCATION)}
                                    options={props?.countries?.map(o => ({
                                        name: o.name,
                                        value: o.id,
                                    }))}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Field
                                    name={requestKeys.PLAN}
                                    type="select"
                                    label={localeLabel(requestKeys.PLAN)}
                                    options={props?.subscriptionPlans?.map(o => ({
                                        name: o.label,
                                        value: o.id,
                                    }))}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                />
                            </Col>
                        </Row>
                    </div>
                </Form>
                <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                    <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                        <Button
                            htmlType="submit"
                            big
                            filled
                            loading={isLoadingAddAccount}
                            submittingButtonLabel={localeLabel('adding')}
                            className="register-btn"
                        >
                            {localeLabel('addAccount')}
                        </Button>
                    </Form.Item>
                    <div className="clearfix" />
                </Form>
            </div>
        </div>
    );
};

/**
 * Proptypes
 */
AddAccountForm.propTypes = {
    change: PropTypes.func,
    error: PropTypes.string,
    handleSubmit: PropTypes.func,
    subscriptionPlans: PropTypes.array,
    countries: PropTypes.array,
};

export default reduxForm({
    form: constants.ADD_ACCOUNT_FORM,
})(withTranslation()(AddAccountForm));

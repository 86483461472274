/**
 * useHasRequiredPermission
 * The hook reads the permissions list that the user has
 * and returns if the passed in permission is present
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */
import { useSelector } from 'react-redux';

import useIsAdminOrManager from './useIsAdminOrManager';

const useHasRequiredPermission = requiredPermission => {
    const isAdminOrManager = useIsAdminOrManager();
    const accountPermissions = useSelector(state => state.account.accountPermissions);

    const subscriptionDetails = useSelector(state => state?.account?.accountDetails?.subscription);

    const hasValidSubscription = isAdminOrManager
        ? true
        : subscriptionDetails?.has_subscription || subscriptionDetails?.under_grace_period;

    if (Array.isArray(requiredPermission)) {
        // Check if all required permissions are present in accountPermissions
        const hasAllPermissions = requiredPermission.find(permission =>
            accountPermissions[permission]?.requires_active_subscription
                ? accountPermissions[permission].requires_active_subscription && hasValidSubscription
                : !!accountPermissions[permission]
        );
        return !!hasAllPermissions;
    }

    const permissionObj = accountPermissions[requiredPermission];

    // return true if the user is a customer or customer manager or if requiredPermission is not passed
    if (isAdminOrManager || !requiredPermission) return true;

    if (permissionObj)
        return permissionObj.requires_active_subscription
            ? permissionObj.requires_active_subscription && hasValidSubscription
            : true;

    return false;
};

export default useHasRequiredPermission;

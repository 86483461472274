/**
 * Chart of accounts breadcrumb items
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import AccountsBreadCrumbItems from '../Accounts';
import OrganisationsBreadCrumbItems from '../Organisations';
import PropTypes from 'prop-types';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ChartOfAccountsBreadCrumbItems = props => {
    const accountId = useAccountId();
    const isAdminOrManager = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    const { t } = useTranslation();

    const isAdd = props.action === 'add';
    const chartOfAccountDetails = useSelector(state => state.chartOfAccounts.chartOfAccount);

    /**
     * Get the chart of accounts details link
     *
     * @param {string} chartOfAccountId Chart of account id
     *
     * @returns {string} Details link
     */
    const getDetailsLink = chartOfAccountId =>
        chartOfAccountId
            ? isAdminOrManager
                ? `/admin/accounts/organisation/chart-of-accounts/chart-of-account/${accountId}/${organisationId}/${chartOfAccountId}`
                : `/chart-of-accounts/chart-of-account/${chartOfAccountId}`
            : '#';

    /**
     * Get the chart of accounts list link
     *
     * @returns {string} List link
     */
    const getListLink = () =>
        isAdminOrManager
            ? `/admin/accounts/organisation/chart-of-accounts/${accountId}/${organisationId}`
            : `/chart-of-accounts`;

    /**
     * Render the component
     */
    return (
        <>
            {isAdminOrManager ? (
                <>
                    <AccountsBreadCrumbItems showListBreadCrumb />
                    <OrganisationsBreadCrumbItems showListBreadCrumb />
                </>
            ) : null}
            {props.showListBreadCrumb ? (
                <Breadcrumb.Item>
                    <Link to={getListLink()}>{t('messages.breadCrumbs.chartOfAccounts.list')}</Link>
                </Breadcrumb.Item>
            ) : null}
            {props.showDetailsBreadCrumb ? (
                <Breadcrumb.Item>
                    {props.showDetailsBreadCrumbAsLink ? (
                        <Link to={getDetailsLink(get(chartOfAccountDetails, 'id'))}>
                            {get(chartOfAccountDetails, 'name', t('messages.breadCrumbs.chartOfAccounts.details'))}
                        </Link>
                    ) : (
                        get(chartOfAccountDetails, 'name', t('messages.breadCrumbs.chartOfAccounts.details'))
                    )}
                </Breadcrumb.Item>
            ) : null}
            {props.showAddEditBreadCrumb ? (
                <Breadcrumb.Item>{t(`messages.breadCrumbs.chartOfAccounts.${isAdd ? 'add' : 'edit'}`)}</Breadcrumb.Item>
            ) : null}
        </>
    );
};

ChartOfAccountsBreadCrumbItems.propTypes = {
    action: PropTypes.string,
    showAddEditBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumbAsLink: PropTypes.bool,
    showListBreadCrumb: PropTypes.bool,
};

export default ChartOfAccountsBreadCrumbItems;

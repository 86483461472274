import PropTypes from 'prop-types';
import React from 'react';

/**
 * Contact type label
 *
 * @since 2.8.0
 */
export default function ContactTypeLabel({ label }) {
    return (
        <span className="bg-indigo-100 text-indigo-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">
            {label}
        </span>
    );
}

ContactTypeLabel.propTypes = {
    label: PropTypes.string,
};

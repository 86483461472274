/**
 * Payment arrangement details Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useEffect, useState } from 'react';
import { find, get, isEmpty } from 'lodash';
import { Breadcrumb, Dropdown, Icon, Modal, Menu, Tabs, Tag } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import PaymentArrangementDefaultImage from 'assets/images/payment-arrangement-plan.png';
import Loader from 'components/Loader';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import { redirect } from 'includes/utils';
import {
    getPaymentArrangement,
    getPaymentArrangementInstallments,
    getPaymentArrangementNotes,
    payPaymentArrangementInFull,
    updatePaymentArrangement,
} from 'includes/slices/paymentArrangement';
import useAccountId from 'includes/hooks/useAccountId';
import {
    PAYMENT_ARRANGEMENT_OR_INSTALLMENT_PAYMENT_STATUS_OPTIONS,
    PAYMENT_ARRANGEMENT_STATUS_APPROVED,
    PAYMENT_ARRANGEMENT_STATUS_CANCELLED,
    PAYMENT_ARRANGEMENT_STATUS_COMPLETED,
    PAYMENT_ARRANGEMENT_STATUS_OPTIONS,
    PAYMENT_ARRANGEMENT_STATUS_PENDING,
    PAYMENT_ARRANGEMENT_STATUS_REJECTED,
    PAYMENT_PLAN_FREQUENCY_TYPE_OPTIONS,
    PAYMENT_PLAN_PAYMENT_FREQUENCY_TYPE_OPTIONS,
} from 'includes/constants';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import { CURRENCY_CODE } from 'includes/constants/keys/response';
import PaymentArrangementBreadCrumbItems from 'components/BreadCrumbItems/PaymentArrangement';
import PaymentArrangementAddEditForm from 'pages/PaymentArrangements/Details/components/Form/AddEdit';
import PaymentArrangementInstallments from 'pages/PaymentArrangements/Details/components/Installments';
import PaymentArrangementNotes from 'pages/PaymentArrangements/Details/components/Notes';
import PaymentArrangementPayInFullForm from 'pages/PaymentArrangements/Details/components/Form/PayInFull';
import { CURRENCY_ID, ID } from 'includes/constants/keys/request';
import InfoMessage from 'components/shared/messages/InfoMessage';
import useToBEDateFormatter from 'includes/hooks/useToBEDateFormatter';
import moment from 'moment';
import { PropTypes } from 'includes/exports/react';
import PaymentArrangementInvoices from 'pages/PaymentArrangements/Details/components/Invoices';
import {
    paymentArrangementBrokenTagColor,
    paymentArrangementOrInstallmentPaymentStatusTagColors,
    paymentArrangementStatusTagColors,
} from 'includes/constants/tagColors';
import Reminders from 'components/customer/Reminders/list';
import useInvoiceStatuses from 'includes/hooks/useInvoiceStatuses';
import PermissionGuard from 'components/PermissionGuard';
import { PAYMENT_ARRANGEMENT } from 'includes/constants/permissions';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import AuditLogList from 'pages/AuditLogs/List';
import BankAccounts from 'components/BankAccounts';
import BankAccountTransactions from 'components/BankAccountTransactions';
import PaymentAnalysis from 'components/PaymentAnalysis';

const { TabPane } = Tabs;

const PaymentArrangementDetails = props => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const isAdminOrManager = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    const getCurrencyFormattedAmount = useCurrencyFormatter();
    const [openUpdateStatusFormModal, setOpenUpdateStatusFormModal] = useState(false);
    const [openPayInFullFormModal, setOpenPayInFullFormModal] = useState(false);
    const [openMessageModal, setOpenMessageModal] = useState(false);
    const { data: currencies, isLoading: loadingOrganisationCurrencies } = useOrganisationCurrencies();
    const convertDateToBEFormat = useToBEDateFormatter();
    const { isLoading: isInvoiceStatusesLoading } = useInvoiceStatuses();

    useUpdateBusinessName();

    const { paymentArrangementId } = params;
    const planType = props?.currentPaymentPlanType;
    const paymentArrangement = useSelector(state => state.paymentArrangement.paymentArrangement);
    const paymentArrangementCurrencyId = get(paymentArrangement, CURRENCY_ID);
    const paymentArrangementCurrencyDetails = find(currencies, { id: paymentArrangementCurrencyId }, '');
    const isAdmin = useSelector(state => state.account.isAdmin);
    const loadingPaymentArrangement = useSelector(state => state.paymentArrangement.loadingPaymentArrangement);

    /**
     * Get the payment arrangement plan
     */
    useEffect(() => {
        dispatch(getPaymentArrangement(organisationId, paymentArrangementId, checkPaymentArrangementPlanType));
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Check if the payment arrangement plan type and the retrieved payment arrangement plan type are same
     *
     * @param {object} paymentPlan Payment Plan
     */
    const checkPaymentArrangementPlanType = paymentArrangement => {
        if (paymentArrangement) {
            const paymentArrangementPlanType = paymentArrangement?.payment_plan?.type;
            let redirectToList = false;
            if (planType === 'arrangement' && paymentArrangementPlanType !== 2) {
                redirectToList = true;
            } else if (planType === 'extension' && paymentArrangementPlanType !== 1) {
                redirectToList = true;
            }
            if (redirectToList) {
                redirect(getListLink());
            }
        }
    };

    /**
     * Close Pay in full Form modal
     */
    const closePayInFullFormModal = () => setOpenPayInFullFormModal(false);

    /**
     * Close Update status Form modal
     */
    const closeUpdateStatusFormModal = () => setOpenUpdateStatusFormModal(false);

    /**
     * Get duration type value
     *
     * @param {string} key Key for duration
     * @param {string} typeKey Type key for duration
     * @param {Array} typeOptions Type options
     *
     * @returns {string} Duration type value
     */
    const getDurationTypeValue = (key, typeKey, typeOptions = []) => {
        let durationValue = get(paymentArrangement, key, 0);
        const durationTypeValue = get(paymentArrangement, typeKey, '');

        if ((typeOptions && durationTypeValue === 'b') || !key) {
            durationValue = '';
        }

        return `${durationValue} ${get(
            find(!isEmpty(typeOptions) ? typeOptions : PAYMENT_PLAN_FREQUENCY_TYPE_OPTIONS, {
                id: get(paymentArrangement, typeKey, ''),
            }),
            'label',
            ''
        )}`;
    };

    /* Get the contact link
     *
     * @returns {string} Contact Link
     */
    const getContactLink = () => {
        const urlNamespace = get(paymentArrangement, 'contact.is_manager') ? 'contact-manager' : 'contact';
        return isAdmin
            ? `/admin/accounts/organisation/${urlNamespace}/${accountId}/${organisationId}/${get(
                  paymentArrangement,
                  'contact.id'
              )}`
            : `/contact/${get(paymentArrangement, 'contact.id')}`;
    };

    /**
     * Get the payment arrangement list link
     *
     * @returns {string} List link
     */
    const getListLink = () => {
        const paymentArrangementOrExtensionBaseUrlPart =
            planType === 'arrangement' ? 'payment-arrangements' : 'payment-extensions';
        return isAdminOrManager
            ? `/admin/accounts/organisation/${paymentArrangementOrExtensionBaseUrlPart}/${accountId}/${organisationId}`
            : `/${paymentArrangementOrExtensionBaseUrlPart}`;
    };
    /**
     * Get the payment plan link
     *
     * @returns {string} Payment plan link
     */
    const getPlanLink = () =>
        isAdminOrManager
            ? `/admin/accounts/organisation/payment-plans/plan/${accountId}/${organisationId}/${get(
                  paymentArrangement,
                  'payment_plan.id'
              )}`
            : `/payment-plans/plan/${get(paymentArrangement, 'payment_plan.id')}`;

    /**
     * Get the localized label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.paymentArrangement.arrangement.addEdit.form.input.label.${field}`);

    /**
     * Format number with payment arrangement currency
     *
     * @param {string|number} number  Number
     *
     * @returns {string} Formatted Number
     */
    const getNumberFormattedWithPaymentArrangementCurrency = number =>
        getCurrencyFormattedAmount(number, false, false, get(paymentArrangementCurrencyDetails, CURRENCY_CODE));

    /**
     * Get Yes or No based on boolean value
     *
     * @param {string} key Key for which we need to retrieve the value
     *
     * @returns {string} Value
     */
    const getYesOrNo = key => getLocaleLabel(get(paymentArrangement, key) ? 'yes' : 'no');

    /**
     * Handle pay in full form submit
     *
     * @param {object} values Values
     */
    const handlePayInFullFormSubmit = values => {
        values[ID] = paymentArrangementId;
        dispatch(
            payPaymentArrangementInFull(organisationId, paymentArrangementId, values, () => {
                setOpenPayInFullFormModal(false);
                dispatch(getPaymentArrangementNotes(organisationId, paymentArrangementId));
                dispatch(
                    getPaymentArrangementInstallments(organisationId, paymentArrangementId, {
                        status: [PAYMENT_ARRANGEMENT_STATUS_APPROVED, PAYMENT_ARRANGEMENT_STATUS_PENDING].includes(
                            paymentArrangement?.status
                        )
                            ? 3
                            : '',
                    })
                );
            })
        );
    };

    /**
     * Handle update status form submit
     *
     * @param {object} values Values
     */
    const handleUpdateStatusFormSubmit = values => {
        values[ID] = paymentArrangementId;
        const brokenDate = values?.broken_date ? convertDateToBEFormat(values.broken_date) : null;

        dispatch(
            updatePaymentArrangement(
                organisationId,
                paymentArrangementId,
                { ...values, broken_date: brokenDate },
                () => {
                    setOpenUpdateStatusFormModal(false);
                    dispatch(getPaymentArrangementNotes(organisationId, paymentArrangementId));
                    dispatch(
                        getPaymentArrangementInstallments(organisationId, paymentArrangementId, {
                            status: [PAYMENT_ARRANGEMENT_STATUS_APPROVED, PAYMENT_ARRANGEMENT_STATUS_PENDING].includes(
                                paymentArrangement?.status
                            )
                                ? 3
                                : '',
                        })
                    );
                }
            )
        );
    };

    const menus = (
        <Menu>
            <Menu.Item key="update-status">
                <PermissionGuard requiredPermission={PAYMENT_ARRANGEMENT.EDIT}>
                    <span onClick={() => setOpenUpdateStatusFormModal(true)}>
                        {t(`messages.paymentArrangement.arrangement.crud.edit`)}
                    </span>
                </PermissionGuard>
            </Menu.Item>
            {paymentArrangement?.total_pending_installment_amount &&
            paymentArrangement?.total_pending_installment_amount > 0 ? (
                <Menu.Item key="pay-in-full" disabled={paymentArrangement?.status !== 1}>
                    <PermissionGuard requiredPermission={PAYMENT_ARRANGEMENT.PAY_FULL}>
                        <span
                            onClick={() =>
                                paymentArrangement.status === PAYMENT_ARRANGEMENT_STATUS_APPROVED
                                    ? setOpenPayInFullFormModal(true)
                                    : setOpenMessageModal(true)
                            }
                        >
                            {t(`messages.paymentArrangement.arrangement.crud.pay_in_full`)}
                        </span>
                    </PermissionGuard>
                </Menu.Item>
            ) : null}
        </Menu>
    );

    return (
        <section>
            <div className="home-content-wrapper synced-name contact-details">
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <PaymentArrangementBreadCrumbItems showListBreadCrumb showDetailsBreadCrumb />
                    </Breadcrumb>
                </div>
                <h2 className="page-title">
                    {t(
                        `titleAndMetas.${
                            planType === 'arrangement' ? 'paymentArrangement' : 'paymentExtension'
                        }.pageTitle`
                    )}
                </h2>
                <div className="white-box-wrapper mid-wrapper breadcrumb-spec details-wrapper">
                    {loadingPaymentArrangement || loadingOrganisationCurrencies || isInvoiceStatusesLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <section className="details-list left-align">
                                <div className="details-profile-pic-wrapper">
                                    <img
                                        src={PaymentArrangementDefaultImage}
                                        className="org-avatar contact-details-spec logo-placeholder"
                                        alt="payment arrangement pic"
                                    />
                                </div>
                                {![PAYMENT_ARRANGEMENT_STATUS_REJECTED, PAYMENT_ARRANGEMENT_STATUS_COMPLETED].includes(
                                    paymentArrangement?.status
                                ) ? (
                                    <div className="center-align">
                                        <PermissionGuard
                                            requiredPermission={[
                                                PAYMENT_ARRANGEMENT.EDIT,
                                                PAYMENT_ARRANGEMENT.PAY_FULL,
                                            ]}
                                        >
                                            <Dropdown overlay={menus}>
                                                <Button big filled>
                                                    {t(`messages.paymentArrangement.arrangement.crud.options`)}
                                                    <Icon type="down" />
                                                </Button>
                                            </Dropdown>
                                        </PermissionGuard>
                                    </div>
                                ) : null}
                                {paymentArrangement?.order?.id ? (
                                    <Link
                                        to={`/${
                                            isAdmin
                                                ? `/admin/accounts/organisation/orders/${accountId}/${organisationId}`
                                                : 'orders/order'
                                        }/${paymentArrangement?.order?.type_id}/${paymentArrangement?.order?.id}`}
                                        target="_blank"
                                    >
                                        <Button
                                            type="primary"
                                            style={{
                                                marginTop: '20px',
                                            }}
                                        >
                                            {getLocaleLabel('view_order')}
                                        </Button>
                                    </Link>
                                ) : null}
                            </section>
                            <section className="details-list right-align">
                                <section className="detail-summary">
                                    <ul className="upper-right-detail">
                                        <li>
                                            <header>{getLocaleLabel('payment_plan')}</header>
                                            <div>
                                                <Link to={getPlanLink()} target="_blank">
                                                    {get(paymentArrangement, 'payment_plan.name')}
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('contact')}</header>
                                            <div>
                                                <Link to={getContactLink()} target="_blank">
                                                    {get(paymentArrangement, 'contact.name')}
                                                </Link>
                                            </div>
                                        </li>
                                        {planType === 'arrangement' ? (
                                            <li>
                                                <header>{getLocaleLabel('payment_frequency')}</header>
                                                <div>
                                                    {get(
                                                        find(PAYMENT_PLAN_PAYMENT_FREQUENCY_TYPE_OPTIONS, {
                                                            id: paymentArrangement?.payment_frequency,
                                                        }),
                                                        'label'
                                                    )}
                                                </div>
                                            </li>
                                        ) : null}
                                        <li>
                                            <header>{getLocaleLabel('upfront_amount')}</header>
                                            <div>
                                                {getNumberFormattedWithPaymentArrangementCurrency(
                                                    get(paymentArrangement, 'upfront_amount')
                                                )}
                                            </div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('upfront_payment_date')}</header>
                                            <div>{get(paymentArrangement, 'upfront_payment_date')}</div>
                                        </li>
                                        <li>
                                            <header>
                                                {getLocaleLabel(
                                                    planType === 'arrangement'
                                                        ? 'next_payment_amount'
                                                        : 'payment_extension_amount'
                                                )}
                                            </header>
                                            <div>
                                                {getNumberFormattedWithPaymentArrangementCurrency(
                                                    get(paymentArrangement, 'next_payment_amount')
                                                )}
                                            </div>
                                        </li>
                                        {planType === 'arrangement' ? (
                                            <li>
                                                <header>{getLocaleLabel('next_payment_date')}</header>
                                                <div>{get(paymentArrangement, 'next_payment_date')}</div>
                                            </li>
                                        ) : (
                                            <li>
                                                <header>{getLocaleLabel('payment_extension_date')}</header>
                                                <div>
                                                    {getNumberFormattedWithPaymentArrangementCurrency(
                                                        get(paymentArrangement, 'payment_extension_date')
                                                    )}
                                                </div>
                                            </li>
                                        )}
                                        <li>
                                            <header>{getLocaleLabel('allow_auto_payment')}</header>
                                            <div>{getYesOrNo('allow_auto_payment')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('status')}</header>
                                            <div className=" flex items-start gap-1 flex-col">
                                                <Tag
                                                    color={get(
                                                        paymentArrangementStatusTagColors,
                                                        paymentArrangement?.status,
                                                        0
                                                    )}
                                                    style={{ color: '#FFF' }}
                                                >
                                                    {getDurationTypeValue(
                                                        '',
                                                        'status',
                                                        PAYMENT_ARRANGEMENT_STATUS_OPTIONS
                                                    )}
                                                </Tag>

                                                {get(paymentArrangement, 'analysis_id', null) ? (
                                                    <Tag
                                                        color={paymentArrangementOrInstallmentPaymentStatusTagColors[3]}
                                                        style={{ color: '#FFF' }}
                                                    >
                                                        {getLocaleLabel('open_banking')}
                                                    </Tag>
                                                ) : null}
                                            </div>
                                        </li>
                                        {paymentArrangement?.payment_status !== 1 ? (
                                            <li>
                                                <header>{getLocaleLabel('payment_status')}</header>
                                                <div>
                                                    <Tag
                                                        color={get(
                                                            paymentArrangementOrInstallmentPaymentStatusTagColors,
                                                            paymentArrangement?.payment_status,
                                                            0
                                                        )}
                                                        style={{ color: '#FFF' }}
                                                    >
                                                        {getDurationTypeValue(
                                                            '',
                                                            'payment_status',
                                                            PAYMENT_ARRANGEMENT_OR_INSTALLMENT_PAYMENT_STATUS_OPTIONS
                                                        )}
                                                    </Tag>
                                                </div>
                                            </li>
                                        ) : null}
                                        {parseInt(get(paymentArrangement, 'total_pending_installments', 0)) > 0 ? (
                                            <>
                                                <li>
                                                    <header>
                                                        {getLocaleLabel('total_pending_installment_amount')}
                                                    </header>
                                                    <div>
                                                        {getNumberFormattedWithPaymentArrangementCurrency(
                                                            get(paymentArrangement, 'total_pending_installment_amount')
                                                        )}
                                                    </div>
                                                </li>
                                                <li>
                                                    <header>{getLocaleLabel('next_installment')}</header>
                                                    <div>
                                                        {parseInt(get(paymentArrangement, 'total_installments', 0)) -
                                                            parseInt(
                                                                get(paymentArrangement, 'total_pending_installments', 0)
                                                            ) +
                                                            1}{' '}
                                                        of {get(paymentArrangement, 'total_installments', 0)}
                                                    </div>
                                                </li>
                                            </>
                                        ) : null}
                                        <li>
                                            <header>{getLocaleLabel('is_broken')}</header>
                                            <div>
                                                {get(paymentArrangement, 'is_broken') ? (
                                                    <Tag
                                                        color={paymentArrangementBrokenTagColor}
                                                        style={{ color: '#FFF' }}
                                                    >
                                                        {getLocaleLabel('broken')}
                                                    </Tag>
                                                ) : null}
                                            </div>
                                        </li>
                                        <li>
                                            <div></div>
                                        </li>
                                        {get(paymentArrangement, 'is_broken') ? (
                                            <li>
                                                <header>{getLocaleLabel('broken_date')}</header>
                                                <div>
                                                    {get(paymentArrangement, 'is_broken') &&
                                                    get(paymentArrangement, 'broken_date')
                                                        ? moment(get(paymentArrangement, 'broken_date')).format(
                                                              'DD-MM-YYYY'
                                                          )
                                                        : null}
                                                </div>
                                            </li>
                                        ) : null}
                                        {get(paymentArrangement, 'status') === PAYMENT_ARRANGEMENT_STATUS_CANCELLED &&
                                        get(paymentArrangement, 'cancelled_date') ? (
                                            <li>
                                                <header>{getLocaleLabel('cancelled_date')}</header>
                                                <div>
                                                    {moment(get(paymentArrangement, 'cancelled_date')).format(
                                                        'DD-MM-YYYY'
                                                    )}
                                                </div>
                                            </li>
                                        ) : null}
                                    </ul>
                                    <Modal
                                        footer={[]}
                                        className="custom-form-modal text-left"
                                        visible={openUpdateStatusFormModal}
                                        onCancel={closeUpdateStatusFormModal}
                                        destroyOnClose
                                    >
                                        <header>{getLocaleLabel(`edit.status`)}</header>
                                        <PaymentArrangementAddEditForm
                                            closeModalPopup={closeUpdateStatusFormModal}
                                            initialValues={{
                                                status: paymentArrangement?.status,
                                                is_broken: paymentArrangement?.is_broken,
                                                broken_date: paymentArrangement?.broken_date
                                                    ? moment(paymentArrangement.broken_date).format('DD-MM-YYYY')
                                                    : null,
                                            }}
                                            onSubmit={handleUpdateStatusFormSubmit}
                                        />
                                    </Modal>
                                    <Modal
                                        footer={[]}
                                        className="custom-form-modal text-left custom-pp-modal"
                                        visible={openPayInFullFormModal}
                                        onCancel={closePayInFullFormModal}
                                        destroyOnClose
                                    >
                                        <header>{getLocaleLabel(`edit.pay_in_full`)}</header>
                                        <PaymentArrangementPayInFullForm
                                            closeModalPopup={closePayInFullFormModal}
                                            getNumberFormattedWithPaymentArrangementCurrency={
                                                getNumberFormattedWithPaymentArrangementCurrency
                                            }
                                            onSubmit={handlePayInFullFormSubmit}
                                            initialValues={{
                                                exchange_rate:
                                                    get(
                                                        paymentArrangementCurrencyDetails,
                                                        'exchange_rates.exchange_rate',
                                                        1
                                                    ) || 1,
                                            }}
                                            planType={planType}
                                        />
                                    </Modal>
                                    <Modal
                                        footer={
                                            <Button
                                                filled
                                                key="organisation-reauthorization-back"
                                                className="ant-btn green-bg-button"
                                                onClick={() => setOpenMessageModal(false)}
                                                style={{ marginBottom: '10px' }}
                                            >
                                                Ok
                                            </Button>
                                        }
                                        className="my_profile_modal organisation-reauthorization-modal"
                                        visible={openMessageModal}
                                        onCancel={() => setOpenMessageModal(false)}
                                    >
                                        <InfoMessage
                                            message={t(
                                                `messages.paymentArrangement.arrangement.modal.message.pay_in_full`
                                            )}
                                        />
                                    </Modal>
                                </section>
                            </section>
                            <PaymentArrangementInvoices
                                invoices={get(paymentArrangement, 'invoices', [])}
                                getNumberFormattedWithPaymentArrangementCurrency={
                                    getNumberFormattedWithPaymentArrangementCurrency
                                }
                                planType={planType}
                            />
                        </>
                    )}
                </div>
                <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                    <Tabs>
                        <TabPane key="installments" tab="Installments">
                            <PermissionGuardLayout
                                layout="section"
                                requiredPermission={PAYMENT_ARRANGEMENT.INSTALLMENTS.LIST}
                            >
                                {paymentArrangement?.id ? (
                                    <PaymentArrangementInstallments
                                        paymentArrangementId={paymentArrangementId}
                                        getNumberFormattedWithPaymentArrangementCurrency={
                                            getNumberFormattedWithPaymentArrangementCurrency
                                        }
                                    />
                                ) : (
                                    <Loader />
                                )}
                            </PermissionGuardLayout>
                        </TabPane>
                        <TabPane key="notes" tab="Notes">
                            {paymentArrangement?.id ? (
                                <PaymentArrangementNotes paymentArrangementId={paymentArrangementId} />
                            ) : (
                                <Loader />
                            )}
                        </TabPane>
                        <TabPane key="reminders" tab="Reminders">
                            <Reminders
                                mode="payment_arrangement"
                                organisation={organisationId}
                                accountId={accountId}
                                campaignType={2}
                                paymentArrangementId={paymentArrangement?.id}
                                key="payment_arrangement_reminders_list"
                                showBreadCrumb={false}
                                custom
                            />
                        </TabPane>
                        <TabPane key="audit-logs" tab="Audit Logs">
                            <AuditLogList
                                customDataValues={{ payment_arrangement_id: paymentArrangementId }}
                                showTitle={false}
                            />
                        </TabPane>
                        {get(paymentArrangement, 'analysis_id', null) && (
                            <TabPane key="bank-account" tab="Bank Accounts">
                                {paymentArrangement?.is_accounts_deleted ? (
                                    `${get(paymentArrangement, 'contact.name')} has deleted this data.`
                                ) : (
                                    <BankAccounts
                                        contactId={get(paymentArrangement, 'contact.id')}
                                        paymentArrangementId={paymentArrangementId}
                                    />
                                )}
                            </TabPane>
                        )}
                        {get(paymentArrangement, 'analysis_id', null) && (
                            <TabPane key="account-transactions" tab="Account transactions">
                                {paymentArrangement?.is_accounts_deleted ? (
                                    `${get(paymentArrangement, 'contact.name')} has deleted this data.`
                                ) : (
                                    <BankAccountTransactions
                                        contactId={get(paymentArrangement, 'contact.id')}
                                        paymentArrangementId={paymentArrangementId}
                                    />
                                )}
                            </TabPane>
                        )}
                        {get(paymentArrangement, 'analysis_id', null) && (
                            <TabPane key="payment-analysis" tab="Analysis">
                                <PaymentAnalysis
                                    analysisId={get(paymentArrangement, 'analysis_id', null)}
                                    organisationId={organisationId}
                                    analysisType={get(paymentArrangement, 'analysis_calculation_type', 1)}
                                    isAccountDeleted={get(paymentArrangement, 'is_accounts_deleted', false)}
                                    getNumberFormattedWithPaymentArrangementCurrency={
                                        getNumberFormattedWithPaymentArrangementCurrency
                                    }
                                />
                            </TabPane>
                        )}
                    </Tabs>
                </div>
            </div>
        </section>
    );
};

PaymentArrangementDetails.propTypes = {
    currentPaymentPlanType: PropTypes.string,
};

export default PaymentArrangementDetails;

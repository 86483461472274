export const debtBandGraphOptions = {
    animationEnabled: true,
    theme: 'light2',
    colorSet: 'debtBandOptionsColorset',
    dataPointWidth: 31,
    toolTip: {
        content: '{y}',
        borderThickness: 0,
        cornerRadius: 5,
        contentFormatter: e =>
            `<div class="chart-tooltip">$${e.entries[0].dataPoint.amountShort} from ${e.entries[0].dataPoint.y} ${
                e.entries[0].dataPoint.y > 1 ? 'accounts' : 'account'
            }</div>`,
    },

    axisX: {
        title: 'Debt band ($)',
        reversed: true,
        gridDashType: 'dot',
        gridThickness: 0.5,
        titleFontSize: 12,
        labelFontSize: 12,
        labelFontColor: '#97a4ba',
    },
    axisY: {
        title: 'Number of accounts',
        gridDashType: 'dot',
        gridThickness: 0.5,
        titleFontSize: 12,
        labelWrap: true,
        labelFontSize: 12,
        labelFontColor: '#97a4ba',
    },
    legend: {
        horizontalAlign: 'right',
    },
    data: [
        {
            type: 'bar',
            dataPoints: [],
        },
    ],
};

/**
 * Inventory transactions listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useMemo } from 'react';
import HasEditPermission from 'components/HasEditPermission';
import Table from 'components/shared/ScrollableTable';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { find, get } from 'lodash';
import { getInventoryItemTransactions } from 'includes/slices/inventory';
import { getInventoryTransactionsTableColumns } from 'includes/tableColumns/inventory';
import { TABLE_NO_INVENTORY_ITEM_TRANSACTIONS_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useInvoiceLinkGenerator from 'includes/hooks/useInvoiceLinkGenerator';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import { CURRENCY_CODE } from 'includes/constants/keys/response';

const InventoryTransactions = () => {
    const { t } = useTranslation();
    const params = useParams();

    const { inventoryItemId } = params;

    const inventoryItemTransactions = useSelector(state => state.inventory.inventoryItemTransactions);
    const inventoryItemTransactionsPagination = useSelector(
        state => state.inventory.inventoryItemTransactionsPagination
    );
    const loadingInventoryItemTransactions = useSelector(state => state.inventory.loadingInventoryItemTransactions);
    const { data: currencies, isLoading: isOrganisationCurrenciesLoading } = useOrganisationCurrencies();
    const organisationId = useOrganisationId();
    const getNumberFormattedAsCurrency = useCurrencyFormatter();
    const getInvoiceLink = useInvoiceLinkGenerator();

    /**
     * Format number with invoice currency
     *
     * @param {string|number} number  Number
     * @param {object} invoice Invoice
     *
     * @returns {string} Formatted Number
     */
    const getNumberFormattedWithInvoiceCurrency = (number, inventoryItemTransaction) =>
        getNumberFormattedAsCurrency(
            number,
            false,
            false,
            get(find(currencies, { id: inventoryItemTransaction.currency_id }), CURRENCY_CODE)
        );

    /**
     * Format the inventory item transactions data
     */
    const formatInventoryItemTransactionsData = useCallback(
        () =>
            inventoryItemTransactions.map(inventoryItemTransaction => {
                let credit, debit;

                const invoiceNumber = get(inventoryItemTransaction, 'invoice_number');
                const invoiceId = get(inventoryItemTransaction, 'invoice_id');
                const contactId = get(inventoryItemTransaction, 'contact_id');
                const transactionAmount = getNumberFormattedWithInvoiceCurrency(
                    get(inventoryItemTransaction, 'transaction_amount', '0.00'),
                    inventoryItemTransaction
                );
                if (get(inventoryItemTransaction, 'is_credit_note')) {
                    debit = transactionAmount;
                } else {
                    credit = transactionAmount;
                }

                return {
                    credit: credit,
                    debit: debit,
                    date: get(inventoryItemTransaction, 'transaction_date'),
                    description: (
                        <div className="business-name-avatar-wrapper">
                            <div className="action-wrapper">
                                {t(`messages.inventory.transactions.list.item.description`, {
                                    invoiceNumber: invoiceNumber,
                                    lineItemName:
                                        get(inventoryItemTransaction, 'transaction_name') ??
                                        get(inventoryItemTransaction, 'transaction_description'),
                                })}
                                <span className="crud-wrapper">
                                    <HasEditPermission
                                        type="link"
                                        link={getInvoiceLink(contactId, invoiceId)}
                                        className="crud-link"
                                    >
                                        {t('messages.inventory.crud.view')}
                                    </HasEditPermission>
                                </span>
                            </div>
                        </div>
                    ),
                };
            }),
        [inventoryItemTransactions, currencies] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Memoized inventory transactions data
     */
    const memoizedInventoryItemTransactionData = useMemo(() => formatInventoryItemTransactionsData(), [
        formatInventoryItemTransactionsData,
    ]);

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper payment-history-wrapper">
            <Table
                className="table-1200"
                columns={getInventoryTransactionsTableColumns}
                dataSource={memoizedInventoryItemTransactionData}
                dataValues={[inventoryItemId, organisationId]}
                dispatchMethod={true}
                getDataMethod={getInventoryItemTransactions}
                loading={loadingInventoryItemTransactions || isOrganisationCurrenciesLoading}
                localeMessage={TABLE_NO_INVENTORY_ITEM_TRANSACTIONS_FOUND_MESSAGE}
                mobileClassName="table-1200"
                paginationData={inventoryItemTransactionsPagination}
                rowClassName="pointer"
                rowKey="id"
                size="middle"
            />
        </div>
    );
};

export default InventoryTransactions;

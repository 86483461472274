/**
 * Reset Password Form
 * Renders the reset password form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modules
import { React, Component } from '../../../includes/exports/react';
import { Form, Row, Col } from 'antd';
import Button from '../Button';
import * as constants from '../../../includes/constants';
import FormField from '../FormField';
import * as formValidations from '../../../includes/utils/form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import * as requestKeys from '../../../includes/constants/keys/request';
import Loader from '../Loader';

/**
 * Reset Password form Component
 */
class ResetPassword extends Component {
    /**
     * Proptypes
     */
    static propTypes = {
        error: PropTypes.any,
        handleSubmit: PropTypes.func,
        isValidatingResetPassword: PropTypes.bool,
        loading: PropTypes.bool,
        message: PropTypes.string,
        t: PropTypes.func,
    };

    /**
     * Locale the form label
     *
     * @param   {string}    field   Field for which locale is needed
     *
     * @returns {string}            Localised content
     */
    localeLabel = field => this.props.t(`sharedMessages.resetPassword.form.input.label.${field}`);

    /**
     * Locale the form place holder
     *
     * @param   {string}    field   Field for which the place holder locale is needed
     *
     * @returns {string}            Localised content
     */
    localePlaceholder = field => this.props.t(`sharedMessages.resetPassword.form.input.placeholder.${field}`);

    /**
     * Render the component
     *
     * @returns {*}
     */
    render() {
        return (
            <div className="reset-password-form form-wrapper custom-modal-format">
                <header className="title">{this.props.t(`sharedMessages.resetPassword.title`)}</header>
                {this.props.isValidatingResetPassword ? (
                    <Loader />
                ) : (
                    <>
                        <p className="desc-text">Enter your new password</p>
                        <Form onSubmit={this.props.handleSubmit}>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Field
                                        type="password"
                                        className="form-control"
                                        name={requestKeys.PASSWORD}
                                        label={this.localeLabel(requestKeys.PASSWORD)}
                                        hasFeedback
                                        component={FormField}
                                        validate={[
                                            formValidations.required,
                                            formValidations.passwordLength,
                                            formValidations.passwordLowerCaseCheck,
                                            formValidations.passwordNumberCheck,
                                            formValidations.passwordUpperCaseCheck,
                                            formValidations.passwordSpecialCharacterCheck,
                                        ]}
                                        placeholder={this.localePlaceholder(requestKeys.PASSWORD)}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Field
                                        type="password"
                                        className="form-control"
                                        name={requestKeys.CONFIRM_PASSWORD}
                                        label={this.localeLabel(requestKeys.CONFIRM_PASSWORD)}
                                        hasFeedback
                                        component={FormField}
                                        validate={[formValidations.required, formValidations.confirmPassword]}
                                        placeholder={this.localePlaceholder(requestKeys.CONFIRM_PASSWORD)}
                                    />
                                </Col>
                            </Row>
                            <div
                                className={
                                    'btn-wrap signin-btn-wrapper' +
                                    (this.props.error ? ' has-error default-form-app-error' : '')
                                }
                            >
                                {this.props.error ? <div className="ant-form-explain">{this.props.error}</div> : ''}
                                {this.props.message ? (
                                    <div className="ant-form-explain success-message-wrapper">{this.props.message}</div>
                                ) : (
                                    ''
                                )}
                                <Form.Item className="full-wrapper submit-registration-form">
                                    <Button
                                        htmlType="submit"
                                        big
                                        className="green-bg-button register-btn big-btn"
                                        loading={this.props.loading}
                                        submittingButtonLabel={this.props.t(
                                            'sharedMessages.resetPassword.form.submit.buttonSubmittingLabel'
                                        )}
                                    >
                                        {this.props.t('sharedMessages.resetPassword.form.submit.buttonLabel')}
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </>
                )}
            </div>
        );
    }
}

// Redux-Form wrapper
const reduxFormWrappedResetPassword = reduxForm({
    form: constants.RESET_PASSWORD_FORM_NAME,
})(withTranslation()(ResetPassword));

export default reduxFormWrappedResetPassword;

/**
 * Order Summary
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { get, find } from 'lodash';
import { useSelector } from 'react-redux';

import Button from '../../../Button';
import FormField from '../../../FormField';
import { required } from 'includes/utils/form';
import { ORDER_TYPE, PAYMENT_STATUS } from 'includes/constants';
import PermissionGuard from 'components/PermissionGuard';
import { ORDER } from 'includes/constants/permissions';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import OrderChargesUpdatedConfirmModalContent from '../../OrderChargesUpdatedConfirmModalContent';
import { useTranslation } from 'react-i18next';

const ORDER_DOWNLOAD_PERMISSION = {
    subscription: ORDER.SUBSCRIPTION.DOWNLOAD,
    invoice: ORDER.INVOICE.DOWNLOAD,
    'legal-letter': ORDER.LEGAL_LETTER.DOWNLOAD,
};

/**
 * Order Summary component
 */
const OrderTypeSummary = props => {
    const { orderDetails, isEditAction } = props;
    const orderTypeSlug = get(find(props.orderTypes, { id: orderDetails.type_id }), 'slug');
    const paymentStatus = find(props.paymentStatuses, { id: orderDetails.payment_status_id });
    const loadingOrderInvoiceDownload = useSelector(state => state.orders.loadingOrderInvoiceDownload);
    // const loadingOrderStatus = useSelector(state => state.orders.loadingOrderStatus);
    const isAdmin = useSelector(state => state.account.isAdmin);

    const { t } = useTranslation();

    const paymentFailed = [PAYMENT_STATUS.REQUIRES_ACTION, PAYMENT_STATUS.NOT_COLLECTED].includes(
        get(paymentStatus, 'slug')
    );
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    /**
     * Format the number as currency based on the order type
     *
     * @returns {string} Formatted number
     */
    const getNumberFormattedAsCurrencyByOrderType = number =>
        getNumberFormattedAsCurrency(number, false, false, get(orderDetails, 'currency.code'));

    /**
     * Get retry order URL
     */
    const getRetryOrderUrl = () => {
        switch (orderTypeSlug) {
            case ORDER_TYPE.INVOICE:
                return `/invoices/payment/${orderDetails.type}/${orderDetails.id}/${orderDetails.organisation.id_encoded}/${orderDetails.contact.id_encoded}/${orderDetails.invoices[0].id_encoded}/retry-payment`;
            // case ORDER_TYPE_LEGAL_LETTER_SLUG:
            //     // eslint-disable-next-line no-case-declarations
            //     const orderInvoiceIds = orderDetails.invoices.map(invoice => {
            //         return invoice.id;
            //     });
            //     return {
            //         pathname: `/legal/${orderDetails.type}/${orderDetails.id}/retry-payment`,
            //         params: { orderInvoiceIds: orderInvoiceIds },
            //     };
            case ORDER_TYPE.SUBSCRIPTION:
                return `/plan/${orderDetails.type}/${orderDetails.id}/retry-payment`;
            default:
                break;
        }
    };

    return (
        <>
            <h3 className="page-title total-count-header order-title-header">
                {props.getLocalisedContent(`${orderTypeSlug}.summary`)}
            </h3>
            {!paymentFailed && !isEditAction && (
                <PermissionGuard requiredPermission={ORDER_DOWNLOAD_PERMISSION[orderTypeSlug]}>
                    <Button
                        className="download-btn green-bg-button download-order-button"
                        loading={loadingOrderInvoiceDownload}
                        onClick={props.downloadOrder}
                    >
                        {props.getLocalisedContent(`download.${orderTypeSlug}`, true)}
                    </Button>
                </PermissionGuard>
            )}
            <ul className="">
                <li className="custom-form-like-field">
                    <header>{props.getLocalisedContent(`date`, true)}</header>
                    <div className="custom-input readonly">{get(orderDetails, 'created_date')}</div>
                </li>
                <li className="custom-form-like-field">
                    <header>{props.getLocalisedContent(`uniqueId`, true)}</header>
                    <div className="custom-input readonly">{get(orderDetails, 'unique_id')}</div>
                </li>
                {get(orderDetails, 'reference_id') && (
                    <li className="custom-form-like-field">
                        <header>{props.getLocalisedContent(`reference`, true)}</header>
                        <div className="custom-input readonly">{get(orderDetails, 'reference_id')}</div>
                    </li>
                )}
                {isEditAction ? (
                    <li className="custom-form-like-field">
                        <header>{props.getLocalisedContent(`paymentId`, true)}</header>
                        <Field
                            type="text"
                            name="payment_id"
                            className="form-control"
                            component={FormField}
                            validate={[required]}
                        />
                    </li>
                ) : (
                    get(orderDetails, 'payment_id') && (
                        <li className="custom-form-like-field">
                            <header>{props.getLocalisedContent(`paymentId`, true)}</header>
                            <div className="custom-input readonly">
                                <span>{get(orderDetails, 'payment_id')}</span>
                            </div>
                        </li>
                    )
                )}
                <li className="custom-form-like-field">
                    <header>{props.getLocalisedContent(`paymentStatus`, true)}</header>
                    {isEditAction ? (
                        <Field
                            type="select"
                            name="payment_status_id"
                            hasFeedback
                            className="form-control"
                            options={props.paymentStatuses.map(t => ({ name: t.status, value: t.id }))}
                            component={FormField}
                            validate={[required]}
                        />
                    ) : (
                        <>
                            <div className="custom-input readonly">
                                {get(paymentStatus, 'status')}
                                {paymentFailed && !isAdmin && (
                                    <Button filled className="green-bg-button status-check-button">
                                        <Link to={getRetryOrderUrl()}>
                                            {get(paymentStatus, 'slug') === PAYMENT_STATUS.NOT_COLLECTED
                                                ? 'Make Payment'
                                                : 'Retry Payment'}
                                        </Link>
                                    </Button>
                                )}
                            </div>
                            {get(paymentStatus, 'slug') === PAYMENT_STATUS.PROCESSING ? (
                                <p
                                    style={{
                                        marginTop: 5,
                                        marginLeft: 5,
                                        color: '#666',
                                        fontSize: 14,
                                        lineHeight: '18px',
                                    }}
                                >
                                    Your payment is in processing state. Payments may take up to 30 minutes to reflect.
                                    Please click on the check button on the order status to see the updated status of
                                    the payment
                                </p>
                            ) : null}
                        </>
                    )}
                </li>
                {get(orderDetails, 'total_refund_amount') > 0 && (
                    <>
                        <li className="custom-form-like-field">
                            <header>{props.getLocalisedContent(`total_refund_amount`, true)}</header>
                            <div className="custom-input readonly">${get(orderDetails, 'total_refund_amount')}</div>
                        </li>
                        {get(orderDetails, 'last_refund_reason') && (
                            <li className="custom-form-like-field">
                                <header>{props.getLocalisedContent(`refundReason`, true)}</header>
                                <div className="custom-input readonly refund-reason">
                                    {get(orderDetails, 'last_refund_reason')}
                                </div>
                            </li>
                        )}
                        {get(orderDetails, 'last_refund_date') && (
                            <li className="custom-form-like-field">
                                <header>{props.getLocalisedContent(`last_refund_date`, true)}</header>
                                <div className="custom-input readonly">{get(orderDetails, 'last_refund_date')}</div>
                            </li>
                        )}
                    </>
                )}
            </ul>
            <OrderChargesUpdatedConfirmModalContent
                transactionCharges={orderDetails}
                t={t}
                formatCurrency={getNumberFormattedAsCurrencyByOrderType}
            />
        </>
    );
};

// prop types
OrderTypeSummary.propTypes = {
    checkOrderStatus: PropTypes.func,
    downloadOrder: PropTypes.func,
    getLocalisedContent: PropTypes.func,
    isEditAction: PropTypes.bool,
    orderDetails: PropTypes.object,
    orderStatuses: PropTypes.array,
    orderTypes: PropTypes.array,
    paymentGateways: PropTypes.array,
    paymentStatuses: PropTypes.array,
};

export default OrderTypeSummary;

/**
 * Reset Password Form
 * Renders the reset password form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modules
import { React } from '../../../includes/exports/react';
import { Form, Row, Col } from 'antd';
import Button from '../Button';
import * as constants from '../../../includes/constants';
import FormField from '../FormField';
import * as formValidations from '../../../includes/utils/form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import * as requestKeys from '../../../includes/constants/keys/request';

/**
 * Activate Account form Component
 */
const ActivateAccountForm = props => {
    /**
     * Locale the form label
     *
     * @param   {string}    field   Field for which locale is needed
     *
     * @returns {string}            Localised content
     */
    const localeLabel = field => props.t(`sharedMessages.activateAccount.form.input.label.${field}`);

    /**
     * Locale the form place holder
     *
     * @param   {string}    field   Field for which the place holder locale is needed
     *
     * @returns {string}            Localised content
     */
    const localePlaceholder = field => props.t(`sharedMessages.activateAccount.form.input.placeholder.${field}`);

    return (
        <div className="reset-password-form form-wrapper custom-modal-format">
            <header className="title">{props.t(`sharedMessages.activateAccount.title`)}</header>
            <p className="desc-text">Set password for your new account.</p>
            <Form onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="password"
                            className="form-control"
                            name={requestKeys.PASSWORD}
                            label={localeLabel(requestKeys.PASSWORD)}
                            hasFeedback
                            component={FormField}
                            validate={[
                                formValidations.required,
                                formValidations.passwordLength,
                                formValidations.passwordLowerCaseCheck,
                                formValidations.passwordNumberCheck,
                                formValidations.passwordUpperCaseCheck,
                                formValidations.passwordSpecialCharacterCheck,
                            ]}
                            placeholder={localePlaceholder(requestKeys.PASSWORD)}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="password"
                            className="form-control"
                            name={requestKeys.CONFIRM_PASSWORD}
                            label={localeLabel(requestKeys.CONFIRM_PASSWORD)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required, formValidations.confirmPassword]}
                            placeholder={localePlaceholder(requestKeys.CONFIRM_PASSWORD)}
                        />
                    </Col>
                </Row>
                <div
                    className={'btn-wrap signin-btn-wrapper' + (props.error ? ' has-error default-form-app-error' : '')}
                >
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    {props.message ? (
                        <div className="ant-form-explain success-message-wrapper">{props.message}</div>
                    ) : (
                        ''
                    )}
                    <Form.Item className="full-wrapper submit-registration-form">
                        <Button
                            htmlType="submit"
                            big
                            className="green-bg-button register-btn big-btn"
                            loading={props.isActivatingAccount}
                            submittingButtonLabel={props.t(
                                'sharedMessages.activateAccount.form.submit.buttonSubmittingLabel'
                            )}
                        >
                            {props.t('sharedMessages.activateAccount.form.submit.buttonLabel')}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};
/**
 * Proptypes
 */
ActivateAccountForm.propTypes = {
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    loading: PropTypes.bool,
    message: PropTypes.string,
    isActivatingAccount: PropTypes.bool,
    t: PropTypes.func,
};

// Redux-Form wrapper
export default reduxForm({
    form: constants.ACTIVATE_ACCOUNT_FORM,
})(withTranslation()(ActivateAccountForm));

/**
 * Search Component
 *
 * @version 1.0
 */

//import required modules
import { React, PropTypes } from '../../../includes/exports/react';
import { Input } from 'antd';
import { isFunction } from 'lodash';
import { useDispatch } from 'react-redux';

const { Search } = Input;

/**
 * Search Block Component
 *
 * @returns    {string}    Search view
 */
const SearchBlock = props => {
    const dispatch = useDispatch();

    /**
     * Renders the export menus view
     */
    return (
        <Search
            placeholder={props.placeholder}
            name="searchParam"
            onSearch={value => {
                props.setSearchParam(value);
                props.setIsSearching(true);
                if (props.setTableFilters && isFunction(props.setTableFilters)) {
                    props.setTableFilters({ search_param: value, ...props.customDataValues });
                }
                if (props.dispatchMethod) {
                    const functionParams = props.filterEmpty ? props.dataValues.filter(el => !!el) : props.dataValues;
                    dispatch(
                        props.getDataMethod(...functionParams, { search_param: value, ...props.customDataValues })
                    );
                } else {
                    props.getDataMethod(
                        ...props.dataValues.filter(function(el) {
                            return el;
                        }),
                        { search_param: value, ...props.customDataValues }
                    );
                }
            }}
        />
    );
};

/**
 * Default props
 */
SearchBlock.defaultProps = {
    customDataValues: {},
    dataValues: [],
    dispatchMethod: false,
    filterEmpty: true,
    isSearching: false,
};

/**
 * Proptypes
 */
SearchBlock.propTypes = {
    customDataValues: PropTypes.object,
    dataValues: PropTypes.array,
    dispatchMethod: PropTypes.bool,
    filterEmpty: PropTypes.bool,
    getDataMethod: PropTypes.func,
    isSearching: PropTypes.bool,
    placeholder: PropTypes.string,
    setIsSearching: PropTypes.func,
    setSearchParam: PropTypes.func,
    setTableFilters: PropTypes.func,
};

export default SearchBlock;

/**
 * Admin Header Component
 * Handles the admin header view and functions to loading header
 * This component is a shared component which can be used in different modules
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

import { React, connect, Link, useState } from '../../../../includes/exports/react';
import { withTranslation } from 'react-i18next';
import { Popover, Badge, Icon, Modal } from 'antd';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import './styles.scss';

import * as userActions from '../../../../includes/redux/actions/shared/user';
import * as responseKeys from '../../../../includes/constants/keys/response';
import AppHeaderSecondaryNavNotification from '../../navigation/AppHeaderSecondarynav/notification';
import { clearLSAndRedirectToAuthPortalLogoutPage, getAuthPortalUrl } from 'includes/utils';
import { useSelector } from 'react-redux';

const { confirm } = Modal;

const AdminHeader = props => {
    const [navVisibleStatus, setNavVisibleStatus] = useState(false);
    const accountDetails = useSelector(state => state.account.accountDetails);
    const userAvatar =
        get(props.userDetails, responseKeys.USER_AVATAR_45X45) ||
        get(props.userDetails, responseKeys.USER_AVATAR_ORIGINAL) ||
        require('../../../../assets/images/user-avatar.svg');

    // const logout = () => props.logoutUser();
    const logout = () => clearLSAndRedirectToAuthPortalLogoutPage();

    const content = (
        <section className="main-menu-popover admin-main-menu">
            <Link to="/admin/my-profile">
                <div className="popover-item">
                    <span>My Profile</span>
                </div>
            </Link>
            <Link
                to=""
                onClick={() => {
                    const accountDetailsUrl = new URL(getAuthPortalUrl());
                    accountDetailsUrl.pathname = `account/${accountDetails?.id}`;
                    window.open(accountDetailsUrl.href, '_self');
                }}
            >
                <div className="popover-item">
                    <span>My Account</span>
                </div>
            </Link>
            <Link to="/admin/exports">
                <div className="popover-item">
                    <span>Your Exports</span>
                </div>
            </Link>
            <div className="popover-item" onClick={logout}>
                <span>Logout</span>
            </div>
        </section>
    );

    const menuItems = [
        {
            slug: 'notifications',
            popover: (
                <Popover
                    content={<AppHeaderSecondaryNavNotification />}
                    title=""
                    placement="bottomRight"
                    trigger="hover"
                    className="notification-menu profile-detail"
                >
                    <span>
                        <span className="menu-border mob-only">
                            <Badge count="5">
                                <div>
                                    <img
                                        className="menu-item mob-only inline"
                                        alt="organisations"
                                        src={require(`../../../../assets/images/notification-mob-icon.svg`)}
                                    />
                                </div>
                            </Badge>
                        </span>
                        <div className="icon-bg">
                            <Badge count={props.unreadCount}>
                                <img
                                    className="menu-item no-mob inline"
                                    alt="organisations"
                                    src={require(`../../../../assets/images/notification-icon.svg`)}
                                />
                                <img
                                    className={`menu-item active-icon desktop-highlight notification-highlight inline`}
                                    alt="organisations"
                                    src={require(`../../../../assets/images/notification-highlight-icon.svg`)}
                                />
                            </Badge>
                        </div>
                        <div className="title">Notifications</div>
                        <div className="underline"></div>
                    </span>
                </Popover>
            ),
            className: '',
        },
        {
            slug: 'my-profile',
            popover: (
                <Popover className="profile-detail" content={content} title="" placement="bottomRight" trigger="hover">
                    <div className="menu-border mob-only" style={{ border: 'none' }}>
                        <img src={userAvatar} alt="img" />
                    </div>
                    <div className="icon-bg no-mob" style={{ marginTop: '3px', marginBottom: '27px' }}>
                        <img src={userAvatar} alt="img" />
                    </div>
                    <div className="profile-name title">My Account</div>
                    <div className="underline"></div>
                </Popover>
            ),
            className: '',
        },
    ];

    return (
        <div className="admin-header-list-menu">
            <h1>
                <Link className="logo" to="/admin/dashboard" alt="logo" />
            </h1>
            <ul className="home-menu-wrapper right-nav">
                {menuItems.map(menu => (
                    <li
                        className={`menu-item ${props.selectedNav === menu.slug ? 'custom-menu-selected' : ''} ${
                            menu.className
                        }`}
                        key={menu.slug}
                        onClick={() => {
                            setNavVisibleStatus(!navVisibleStatus);
                        }}
                    >
                        {menu.popover}
                    </li>
                ))}
                <li className="mob-only logout-menu">
                    <Icon
                        type="logout"
                        onClick={() =>
                            confirm({
                                title: 'Are you sure you want to logout?',
                                okText: 'Logout',
                                onOk: logout,
                            })
                        }
                    />
                </li>
            </ul>
        </div>
    );
};

AdminHeader.propTypes = {
    location: PropTypes.object,
    t: PropTypes.func,
    logoutUser: PropTypes.func,
    userDetails: PropTypes.object,
    unreadCount: PropTypes.number,
    selectedNav: PropTypes.string,
};

//Connect to store
export default connect(
    state => ({
        userDetails: state.user.userDetails,
        unreadCount: state.notification.unreadCount,
    }),
    {
        ...userActions,
    }
)(withTranslation()(AdminHeader));

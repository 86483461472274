/**
 * Chart of account form Component
 * Renders the chart of account add/edit
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ChartOfAccountForm from './components/Form';
import ChartOfAccountsBreadCrumbItems from 'components/BreadCrumbItems/ChartOfAccounts';
import Loader from 'components/shared/Loader';
import useChartOfAccounts from 'includes/hooks/useChartOfAccounts';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { addChartOfAccount, getChartOfAccount, updateChartOfAccount } from 'includes/slices/chartOfAccounts';
import { redirect } from 'includes/utils';
import { useUpdateBusinessName } from 'includes/utils/hooks';

const ChartOfAccountAddEdit = props => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    useUpdateBusinessName();

    const { accountId, chartOfAccountId } = params;
    const chartOfAccountDetails = useSelector(state => state.chartOfAccounts.chartOfAccount);
    const isAdd = props.action === 'add';
    const isAdmin = useSelector(state => state.account.isAdmin);
    const loadingChartOfAccount = useSelector(state => state.chartOfAccounts.loadingChartOfAccount);
    const organisationId = useOrganisationId();
    const { reset: clearChartOfAccountsCache } = useChartOfAccounts(true);

    /**
     * Get the chart of account details on load
     */
    useEffect(() => {
        if (!isAdd) {
            dispatch(getChartOfAccount(chartOfAccountId, organisationId));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handle the submit of add/edit of chart of account
     */
    const handleSubmit = values => {
        isAdd
            ? dispatch(addChartOfAccount(values, redirectToDetailsPage, organisationId))
            : dispatch(
                  updateChartOfAccount(get(chartOfAccountDetails, 'id'), values, redirectToDetailsPage, organisationId)
              );
    };

    /**
     * Redirect to chart of account details page
     * Clear cache before redirection
     *
     * @param {string} chartOfAccountId Chart of account id
     */
    const redirectToDetailsPage = chartOfAccountId => {
        clearChartOfAccountsCache();
        redirect(
            isAdmin
                ? `/admin/accounts/organisation/chart-of-accounts/chart-of-account/${accountId}/${organisationId}/${chartOfAccountId}`
                : `/chart-of-accounts/chart-of-account/${chartOfAccountId}`
        );
    };

    return (
        <div className="home-content-wrapper edit-profile-wrapper">
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <ChartOfAccountsBreadCrumbItems
                        action={props.action}
                        showAddEditBreadCrumb
                        showDetailsBreadCrumb={!isAdd}
                        showDetailsBreadCrumbAsLink
                        showListBreadCrumb
                    />
                </Breadcrumb>
            </div>
            <h2 className="page-title">{t(`titleAndMetas.chartOfAccount${isAdd ? 'Add' : 'Edit'}.pageTitle`)}</h2>
            <div className="white-box-wrapper mid-wrapper">
                {loadingChartOfAccount ? (
                    <Loader tip={t('appCommonMessages.loadingMessage')} />
                ) : (
                    <ChartOfAccountForm
                        enableReinitialize
                        initialValues={isAdd ? {} : chartOfAccountDetails}
                        isAdd={isAdd}
                        onSubmit={handleSubmit}
                    />
                )}
            </div>
        </div>
    );
};

/**
 * Proptypes
 */
ChartOfAccountAddEdit.propTypes = {
    action: PropTypes.string,
};

export default ChartOfAccountAddEdit;

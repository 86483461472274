/**
 * Contact type Add/Edit Form
 *
 * @since 2.0.0
 */

// import required modules
import React from 'react';
import { CONTACT_TYPE_FORM_NAME } from 'includes/constants';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';
import { get } from 'lodash';
import { PropTypes } from 'prop-types';
import { required } from 'includes/utils/form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as requestKeys from 'includes/constants/keys/request';
import Button from 'components/Button';
import FormField from 'components/shared/FormField';

const AddEditContactTypeForm = props => {
    const { t } = useTranslation();
    const isContactTypeAddUpdateLoading = useSelector(state => state.contacts.isContactTypeAddUpdateLoading);

    /**
     * Locale the label.
     *
     * @param {string} field Field for which locale is to be generated.
     *
     * @since 2.0.0
     *
     * @returns {string} Generated Locale label.
     */
    const localeLabel = field => t(`messages.contactTypes.addEdit.form.input.label.${field}`);

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="text"
                            name={requestKeys.CONTACT_TYPE}
                            label={localeLabel(requestKeys.CONTACT_TYPE)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[required]}
                            required
                            disabled={!props.canEdit}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="textarea"
                            name={requestKeys.CONTACT_TYPE_DESCRIPTION}
                            label={localeLabel(requestKeys.CONTACT_TYPE_DESCRIPTION)}
                            className="form-control"
                            component={FormField}
                            disabled={!props.canEdit}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="switch"
                            name={requestKeys.CONTACT_TYPE_AUTO_REMINDERS}
                            label={localeLabel(requestKeys.CONTACT_TYPE_AUTO_REMINDERS)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            info={t(`messages.contactTypes.addEdit.form.input.info.auto_reminders`)}
                            infoAsToolTip
                            disabled={!props.canEdit}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="switch"
                            name={requestKeys.IS_DEFAULT}
                            label={localeLabel(requestKeys.IS_DEFAULT)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            info={t(`messages.contactTypes.addEdit.form.input.info.is_default`)}
                            infoAsToolTip
                            disabled={!props.canEdit}
                        />
                    </Col>
                </Row>
                {props.canEdit ? (
                    <Form.Item
                        className={`update-button no-margin center-align ${
                            props.error ? ' has-error default-form-app-error' : ''
                        }`}
                    >
                        {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                        <Button
                            htmlType="submit"
                            big
                            filled
                            loading={isContactTypeAddUpdateLoading}
                            submittingButtonLabel={t(
                                `messages.contactTypes.addEdit.form.submit.buttonSubmittingLabel.${
                                    get(props, ['initialValues', 'id']) ? 'edit' : 'add'
                                }`
                            )}
                            className="register-btn"
                        >
                            {t(
                                `messages.contactTypes.addEdit.form.submit.buttonLabel.${
                                    get(props, ['initialValues', 'id']) ? 'edit' : 'add'
                                }`
                            )}
                        </Button>
                    </Form.Item>
                ) : null}
            </Form>
        </div>
    );
};

/**
 * Proptypes.
 */
AddEditContactTypeForm.propTypes = {
    canEdit: PropTypes.bool,
    change: PropTypes.func,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    t: PropTypes.func,
};

export default reduxForm({
    form: CONTACT_TYPE_FORM_NAME,
})(AddEditContactTypeForm);

/**
 * Organisation Payment account select Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { React } from '../../../../includes/exports/react';
import HasEditPermission from '../../../HasEditPermission';
import { useState } from 'react';
import SelectOrgPaymentAccount from '../../../customer/Organisations/SelectOrganisationPaymentAccount';

const SelectOrganisationPaymentAccount = () => {
    const [showOrganisationSelectPaymentAccountModal, setShowOrganisationSelectPaymentAccountModal] = useState(false);
    return (
        <>
            <li key="select-org-payment-account" className="select-org-payment-account">
                <HasEditPermission onClick={() => setShowOrganisationSelectPaymentAccountModal(true)}>
                    Select Organisation Payment Account
                </HasEditPermission>
                {showOrganisationSelectPaymentAccountModal && (
                    <SelectOrgPaymentAccount
                        setShowOrganisationSelectPaymentAccountModal={setShowOrganisationSelectPaymentAccountModal}
                    />
                )}
            </li>
        </>
    );
};

export default SelectOrganisationPaymentAccount;

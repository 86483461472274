/**
 * Invoices API class.
 * Handles all api requests related to invoices
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';
import { getLocalStorageValue, getValueFormStore } from '../../utils';
import * as constants from '../../constants';

class InvoicesApi extends BaseApiHandler {
    /**
     * Call the add invoice API
     *
     * @param {object} invoiceDetails Invoice details
     * @param {object} restPayload Payload
     *
     * @returns {object} API Response
     */
    addInvoice = ({ invoiceDetails, ...restPayload }) =>
        this.axios.post(this.getApiUrl('INVOICES_CRUD', { ...restPayload }), invoiceDetails);

    /**
     * Call the add invoice payment API
     *
     * @param {string} organisationId Organisation Id
     * @param {string} contactId Contact Id
     * @param {string} invoiceId Invoice Id
     * @param {object} restPayload Payload
     *
     * @returns {object} API Response
     */
    addInvoicePayment = ({ organisationId, contactId, invoiceId, ...restPayload }) =>
        this.axios.post(
            this.getApiUrl('INVOICE_GET_PAYMENTS', {
                organisation_id: organisationId,
                contact_id: contactId,
                invoice_id: invoiceId,
            }),
            restPayload
        );

    /**
     * Call the delete invoice API
     *
     * @param {string} organisation_id Organisation id
     * @param {string} contact_id Contact id
     * @param {string} invoice_id Invoice id
     * @param {object} restParams Rest params
     *
     * @returns {object} API Response
     */
    deleteInvoice = ({ organisation_id, contact_id, invoice_id, ...restParams }) =>
        this.axios.delete(
            this.getApiUrl(restParams['multiDelete'] ? 'INVOICES_CRUD' : 'INVOICE_CRUD', {
                organisation_id: organisation_id,
                contact_id: contact_id,
                invoice_id: invoice_id,
            }),
            { params: restParams }
        );

    /**
     * Call the delete invoice payment API
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    deleteInvoicePayment = payload => this.axios.delete(this.getApiUrl('INVOICE_UPDATE_PAYMENT', payload));

    /**
     * Call the export all invoice payments api
     *
     * @param {object} searchParams Search Params
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    exportAllInvoicePayments = ({ searchParams, ...payload }) =>
        this.axios.get(this.getApiUrl('EXPORT_INVOICE_PAYMENTS_ALL', payload), { params: searchParams });

    /**
     * Call the export all invoices API
     *
     * @param {string} organisationId Organisation Id
     * @param {object} params Params
     * @param {string} invoiceId Invoice Ids
     *
     * @returns {object} API Response
     */
    exportAllInvoices = ({ organisationId, params, invoiceIds }) =>
        this.axios.post(
            this.getApiUrl('EXPORT_ALL_INVOICES', { organisation_id: organisationId }),
            { ids: invoiceIds },
            {
                params: { ...params },
            }
        );

    /**
     * Call the export invoice payments API
     *
     * @param {object} searchParams Search Params
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    exportInvoicePayments = ({ searchParams, ...payload }) =>
        this.axios.get(this.getApiUrl('EXPORT_INVOICE_PAYMENTS', payload), { params: searchParams });

    /**
     * Call the export invoices API
     *
     * @param {string} organisationId Organisation Id
     * @param {string} contactId Contact Id
     * @param {object}    params              Params
     *
     * @returns {object} API Response
     */
    exportInvoices = ({ organisationId, contactId, params }) =>
        this.axios.post(
            this.getApiUrl('EXPORT_INVOICES', { organisation_id: organisationId, contact_id: contactId }),
            // { ids: invoiceIds },
            {
                params: { ...params },
            }
        );

    /**
     * Call the contact invoices API
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getAllContactInvoices = payload =>
        this.axios.get(this.getApiUrl('INVOICES_GET_ALL_CONTACT_INVOICES', { ...payload }));

    /**
     * Call the invoice payments API
     *
     * @param {object} searchParams Search Params
     * @param {object} payload     Payload
     *
     * @returns {object} API Response
     */
    getAllInvoicePayments = ({ searchParams, ...payload }) =>
        this.axios.get(this.getApiUrl('GET_ALL_INVOICE_PAYMENTS', payload), { params: searchParams });

    /**
     * Call the contact invoices API
     *
     * @param {object} searchParams Search Params
     * @param {object} restPayload Payload
     *
     * @returns {object} API Response
     */
    getContactInvoices = ({ searchParams, ...restPayload }) =>
        this.axios.get(this.getApiUrl('INVOICES_GET_CONTACT_INVOICES', restPayload), { params: { ...searchParams } });

    /**
     * Call the invoice API
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getInvoiceDetails = payload => this.axios.get(this.getApiUrl('INVOICES_GET_INVOICE_DETAILS', payload));

    /**
     * Call the invoice payments API
     *
     * @param {object} searchParams Search Params
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getInvoicePayments = ({ searchParams, ...payload }) =>
        this.axios.get(this.getApiUrl('INVOICE_GET_PAYMENTS', payload), { params: searchParams });

    /**
     * Call the invoice payment details API
     *
     * @param {object} payload Payload
     * @param {string} payload.organisation_id Organisation ID
     * @param {string} payload.contact_id Contact ID
     * @param {string} payload.invoice_id Invoice ID
     * @param {string} payload.invoice_payment_id Invoice payment ID
     *
     * @returns {object} API Response
     */
    getInvoicePaymentDetails = payload => this.axios.get(this.getApiUrl('INVOICE_GET_PAYMENT_DETAILS', payload));

    /**
     * Call the invoice pdf download url API
     *
     * @param {object} searchParams Search Params
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getInvoicePdfDownloadUrl = ({ ...payload }) =>
        this.axios.get(this.getApiUrl('INVOICE_GET_PDF_DOWNLOAD_URL', payload));

    /**
     * Call the invoice statuses API
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getInvoiceStatuses = payload =>
        this.axios.get(this.getApiUrl('INVOICES_GET_INVOICE_STATUSES'), { params: { ...payload } });

    /**
     * Call the invoice types API
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getInvoiceTypes = payload => this.axios.get(this.getApiUrl('INVOICE_TYPES_GET_ALL'), { params: { ...payload } });

    /**
     * Call the invoices API
     *
     * @param {object} searchParams Search Params
     * @param {object} restPayload Payload
     *
     * @returns {object} API Response
     */
    getInvoices = ({ searchParams, ...restPayload }) => {
        const currentAccountId = getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY);
        const userRole = getValueFormStore(`user.roles.${currentAccountId}.slug`, '');

        const apiUrl =
            userRole === 'debt-collector'
                ? this.getApiUrl('INVOICES_DEBT_COLLECTOR_GET_ALL')
                : this.getApiUrl('INVOICES_GET_ALL', restPayload);
        return this.axios.get(apiUrl, { params: { ...searchParams } });
    };

    /**
     * Call the next invoice number API
     *
     * @param {object} payload Payload
     * @param {object} params Params. Default {}
     *
     * @returns {object} API Response
     */
    getNextInvoiceNumber = (payload, params) =>
        this.axios.get(this.getApiUrl('INVOICES_GET_NEXT_INVOICE_NUMBER', payload), { params: params });

    /**
     * Call the update invoice API
     *
     * @param {object} invoiceDetails Invoice details
     * @param {object} restPayload Payload
     *
     * @returns {object} API Response
     */
    updateInvoice = ({ invoiceDetails, ...restPayload }) =>
        this.axios.patch(this.getApiUrl('INVOICE_CRUD', { ...restPayload }), invoiceDetails);

    /**
     * Call the update invoice payment API
     *
     * @param {string} organisationId Organisation Id
     * @param {string} contactId Contact Id
     * @param {string} invoiceId Invoice Id
     * @param {string} paymentId Payment Id
     * @param {object} restPayload Payload
     *
     * @returns {object} API Response
     */
    updateInvoicePayment = ({ organisationId, contactId, invoiceId, paymentId, ...restPayload }) =>
        this.axios.patch(
            this.getApiUrl('INVOICE_UPDATE_PAYMENT', {
                organisation_id: organisationId,
                contact_id: contactId,
                invoice_id: invoiceId,
                payment_id: paymentId,
            }),
            restPayload
        );

    /**
     * Get Invoice Preview
     *
     * @param {object} details organisation_id, invoiceDetails
     *
     * @returns {object} API response
     */
    getInvoicePreview = ({ organisationId: organisation_id, invoiceDetails }) =>
        this.axios.post(this.getApiUrl('INVOICE_PREVIEW', { organisation_id }), invoiceDetails);

    /**
     * Update the status of the invoices
     *
     * @param {string}  organisation_id Organisation Id
     * @param {string}  contact_id Contact Id
     * @param {object} invoicesData Invoices Data
     *
     * @returns {object} API Response
     */
    updateInvoicesStatus = ({ organisation_id, contact_id, invoicesData }) =>
        this.axios.patch(
            this.getApiUrl(contact_id ? 'INVOICES_CONTACT_UPDATE_STATUS' : 'INVOICES_UPDATE_STATUS', {
                organisation_id,
                contact_id,
            }),
            invoicesData
        );

    /**
     * Update the type of the invoices
     *
     * @param {string}  organisation_id Organisation Id
     * @param {string}  contact_id Contact Id
     * @param {object} invoicesData Invoices Data
     *
     * @returns {object} API Response
     */
    updateInvoicesType = ({ organisation_id, contact_id, invoicesData }) =>
        this.axios.patch(
            this.getApiUrl(contact_id ? 'INVOICES_CONTACT_UPDATE_TYPE' : 'INVOICES_UPDATE_TYPE', {
                organisation_id,
                contact_id,
            }),
            invoicesData
        );
}

export default new InvoicesApi();

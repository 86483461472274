/**
 * Zendesk Tickets listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import ZendeskTickets from 'components/Zendesk/Tickets/List';
import ZendeskIntegrationEnabled from 'components/Zendesk/IntegrationEnabled';
import { Breadcrumb } from 'antd';
import ZendeskBreadCrumbItems from 'components/BreadCrumbItems/Zendesk';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import { useTranslation } from 'react-i18next';

const ZendeskTicketsList = () => {
    const isAdminOrManager = useIsAdminOrManager();
    const { t } = useTranslation();
    return (
        <ZendeskIntegrationEnabled>
            <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
                <h2 className="page-title">{t(`titleAndMetas.zendeskTickets.title`)}</h2>
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <ZendeskBreadCrumbItems showListBreadCrumb={isAdminOrManager} />
                    </Breadcrumb>
                </div>
                <div className="search-filter-export-wrapper contact-list-container full-wrapper box-wrapper white-box-wrapper">
                    <ZendeskTickets />
                </div>
            </div>
        </ZendeskIntegrationEnabled>
    );
};

export default ZendeskTicketsList;

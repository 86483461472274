/**
 * Campaign API class.
 * Handles all api requests related to campaign
 *
 * @since 2.0.0
 */

//import base api handler
import BaseApiHandler from './base/api';

class CampaignApi extends BaseApiHandler {
    /**
     * Add a campaign
     *
     * @param {string} organisationId Organisation id.
     * @param {object} payload Payload data to update
     *
     * @since 2.0.0
     *
     * @returns {object} API Response
     */
    addCampaign = (organisationId, payload) =>
        this.axios.post(this.getApiUrl('CAMPAIGNS_CRUD', { organisation_id: organisationId }), payload);

    /**
     * Add campaign notification
     *
     * @param {string} organisationId Organisation ID
     * @param {string} campaignId Campaign ID
     * @param {object} payload Payload
     *
     * @since 2.0.0
     *
     * @returns {object} API Response
     */
    addCampaignNotification = (organisationId, campaignId, payload) =>
        this.axios.post(
            this.getApiUrl('CAMPAIGN_NOTIFICATIONS_CRUD', {
                organisation_id: organisationId,
                campaign_id: campaignId,
            }),
            payload
        );

    /**
     * Delete the campaign
     *
     * @param {string} organisationId Organisation ID
     * @param {string} campaignId Campaign ID
     * @param {object} searchParams Search Params
     *
     * @since 2.0.0
     *
     * @returns {object} API Response
     */
    deleteCampaign = (organisationId, campaignId) =>
        this.axios.delete(
            this.getApiUrl('CAMPAIGN_CRUD', {
                organisation_id: organisationId,
                campaign_id: campaignId,
            })
        );

    /**
     * Delete a campaign notification
     *
     * @param {string} organisationId Organisation ID
     * @param {string} campaignId Campaign ID
     * @param {string} campaignNotificationId Campaign Notification ID
     *
     * @since 2.0.0
     *
     * @returns {object} API Response
     */
    deleteCampaignNotification = (organisationId, campaignId, campaignNotificationId) =>
        this.axios.delete(
            this.getApiUrl('CAMPAIGN_NOTIFICATION_CRUD', {
                organisation_id: organisationId,
                campaign_id: campaignId,
                campaign_notification_id: campaignNotificationId,
            })
        );

    /**
     * Get the campaign
     *
     * @param {string} organisationId Organisation ID
     * @param {string} campaignId Campaign ID
     * @param {object} searchParams Search Params
     *
     * @since 2.0.0
     *
     * @returns {object} API Response
     */
    getCampaign = (organisationId, campaignId, searchParams) =>
        this.axios.get(
            this.getApiUrl('CAMPAIGN_CRUD', {
                organisation_id: organisationId,
                campaign_id: campaignId,
            }),
            {
                params: searchParams,
            }
        );

    /**
     * Get the campaign notification days
     *
     * @param {string} organisationId Organisation ID
     * @param {object} searchParams Search Params
     *
     * @since 2.0.0
     *
     * @returns {object} API Response
     */
    getCampaignNotificationDays = (organisationId, searchParams) =>
        this.axios.get(
            this.getApiUrl('CAMPAIGN_NOTIFICATION_DAYS_CRUD', {
                organisation_id: organisationId,
            }),
            {
                params: searchParams,
            }
        );

    /**
     * Get the campaign notifications
     *
     * @param {string} organisationId Organisation ID
     * @param {string} campaignId Campaign ID
     * @param {object} searchParams Search Params
     *
     * @since 2.0.0
     *
     * @returns {object} API Response
     */
    getCampaignNotifications = (organisationId, campaignId, searchParams) =>
        this.axios.get(
            this.getApiUrl('CAMPAIGN_NOTIFICATIONS_CRUD', {
                organisation_id: organisationId,
                campaign_id: campaignId,
            }),
            {
                params: searchParams,
            }
        );

    /**
     * Get the campaigns
     *
     * @param {string} organisationId Organisation ID
     * @param {object} searchParams Search Params
     *
     * @since 2.0.0
     *
     * @returns {object} API Response
     */
    getCampaigns = (organisationId, searchParams) =>
        this.axios.get(
            this.getApiUrl('CAMPAIGNS_CRUD', {
                organisation_id: organisationId,
            }),
            {
                params: searchParams,
            }
        );

    /**
     * Update the campaign
     *
     * @param {string} organisationId Organisation ID
     * @param {string} campaignId Campaign ID
     * @param {object} payload Payload
     *
     * @since 2.0.0
     *
     * @returns {object} API Response
     */
    updateCampaign = (organisationId, campaignId, payload) =>
        this.axios.patch(
            this.getApiUrl('CAMPAIGN_CRUD', {
                organisation_id: organisationId,
                campaign_id: campaignId,
            }),
            payload
        );

    /**
     * Update the campaign notification
     *
     * @param {string} organisationId Organisation ID
     * @param {string} campaignId Campaign ID
     * @param {string} campaignNotificationId Campaign Notification ID
     * @param {object} payload Payload
     *
     * @since 2.0.0
     *
     * @returns {object} API Response
     */
    updateCampaignNotification = (organisationId, campaignId, campaignNotificationId, payload) =>
        this.axios.patch(
            this.getApiUrl('CAMPAIGN_NOTIFICATION_CRUD', {
                organisation_id: organisationId,
                campaign_id: campaignId,
                campaign_notification_id: campaignNotificationId,
            }),
            payload
        );
}

export default new CampaignApi();

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon } from 'antd';

import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useCurrencySymbol from 'includes/hooks/useCurrencySymbol';
import useToFEDateFormatter from 'includes/hooks/useToFEDateFormatter';
import CouponIcon from '../CouponIcon';
import CouponCode from '../../../CouponCode';
/**
 * Renders coupon with offer detail
 *
 * @since 2.8.0
 */
export default function Coupon({
    code,
    currencyCode,
    discountAmount,
    discountPercentage,
    isApplied = false,
    onApply,
    redeemedDate,
}) {
    const formatDate = useToFEDateFormatter(false, 'ddd, MMM D, YYYY');

    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    const getCurrencySymbol = useCurrencySymbol();

    const { t } = useTranslation();

    const currencySymbol = getCurrencySymbol(false, currencyCode);

    const isPercentage = discountPercentage > 0;

    const isRedeemed = Boolean(redeemedDate);

    const { btnStyle, btnText } = useMemo(() => {
        if (isRedeemed)
            return {
                btnStyle: 'bg-gray-100 text-gray-600',
                btnText: t('customerMessages.applyCouponDialog.coupon.buttonText.redeemed'),
            };

        if (isApplied)
            return {
                btnStyle: 'text-green-500',
                btnText: t('customerMessages.applyCouponDialog.coupon.buttonText.applied'),
            };

        return {
            btnStyle: 'text-blue-700 bg-blue-50 hover:bg-blue-100 transition-all duration-300 cursor-pointer',
            btnText: t('customerMessages.applyCouponDialog.coupon.buttonText.tapToApply'),
        };
    }, [isApplied, isRedeemed]);

    const { iconStyles, iconText, offText } = useMemo(() => {
        if (isPercentage)
            return {
                iconStyles: 'star-bg-orange',
                iconText: '%',
                offText: `${discountPercentage}%`,
            };

        return {
            iconStyles: 'star-bg-blue',
            iconText: currencySymbol,
            offText: getNumberFormattedAsCurrency(discountAmount, false, false, currencyCode),
        };
    }, [currencySymbol, discountAmount, discountPercentage, isPercentage]);

    return (
        <div className="flex flex-col rounded-xl shadow-xl border border-solid border-gray-100">
            <div className="flex flex-row items-center gap-3 text-left py-2 px-4">
                <div className="self-start mt-2">
                    <CouponIcon iconText={iconText} iconStyles={iconStyles} />
                </div>

                <div className="flex flex-col gap-2 flex-grow">
                    <div className="text-xl font-sans font-semibold">
                        {t('customerMessages.applyCouponDialog.coupon.offText', { offText })}
                    </div>
                    <div className="w-fit">
                        <CouponCode name={code} />
                    </div>
                    {isRedeemed ? (
                        <div className="text-gray-400">
                            {t('customerMessages.applyCouponDialog.coupon.redeemedOn', {
                                redeemedDate: formatDate(redeemedDate),
                            })}
                        </div>
                    ) : null}
                </div>
            </div>

            <div
                className={`flex flex-row justify-center items-center gap-2 border-t border-solid border-gray-200 rounded-b-xl text-lg tracking-widest py-2 ${btnStyle}`}
                {...(isRedeemed || isApplied ? {} : { onClick: onApply })}
            >
                {isApplied ? <Icon type="check" /> : null}
                <span className="uppercase">{btnText}</span>
            </div>
        </div>
    );
}

Coupon.propTypes = {
    code: PropTypes.string,
    currencyCode: PropTypes.string,
    discountAmount: PropTypes.number,
    discountPercentage: PropTypes.number,
    isApplied: PropTypes.bool,
    isRedeemed: PropTypes.bool,
    onApply: PropTypes.func,
    redeemedDate: PropTypes.string,
};

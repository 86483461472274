import i18n from 'includes/i18n';

/**
 * Get zendesk tickets table columns
 */
export const getTicketsTableColumns = [
    {
        title: i18n.t('messages.zendesk.list.label.subject'),
        dataIndex: 'subject',
        key: 'subject',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.zendesk.list.label.ticketEventType'),
        dataIndex: 'ticket_event_type',
        key: 'ticket_event_type',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.zendesk.list.label.ticketPriority'),
        dataIndex: 'ticket_priority',
        key: 'ticket_priority',
        sorter: true,
    },
    {
        title: i18n.t('messages.zendesk.list.label.ticketStatus'),
        dataIndex: 'ticket_status',
        key: 'ticket_status',
        sorter: true,
    },
    {
        title: i18n.t('messages.zendesk.list.label.ticketType'),
        dataIndex: 'ticket_type',
        key: 'ticket_type',
        sorter: true,
    },
    {
        title: i18n.t('messages.zendesk.list.label.ticketCreatedOrUpdatedDate'),
        dataIndex: 'ticket_created_or_updated_date',
        key: 'ticket_created_or_updated_date',
        sorter: true,
    },
    {
        title: i18n.t('messages.zendesk.list.label.actions'),
        dataIndex: 'actions',
        key: 'actions',
        collapseHeader: true,
    },
];

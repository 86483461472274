/**
 * Sms template Form fields
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import required modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { Row, Col, Icon } from 'antd';
import { useTranslation } from 'react-i18next';

import * as formValidations from '../../../../includes/utils/form';
import Button from '../../Button';
import FormField from '../../../shared/FormField';
import Loader from '../../../shared/Loader';
import { showNotificationPopup } from 'includes/redux/actions/shared/common';
import PermissionGuard from 'components/PermissionGuard';
import { REMINDER } from 'includes/constants/permissions';

const SmsFields = props => {
    const allPlaceholders = useSelector(state => state.placeholder.allPlaceholders);
    // default status
    const [placeholderOpen, setPlaceholderOpen] = useState(false);

    //message reference
    const message = useRef(null);

    //placeholder dropdown reference
    const placeholderList = useRef(null);

    // placeholder button reference
    const placeholderButton = useRef(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    //insert placeholder
    const insertPlaceholder = placeholder => {
        props.change('message', message.current.value ? `${message.current.value} ${placeholder}` : placeholder);
        setPlaceholderOpen(!placeholderOpen);
    };

    // click event handler
    const handleClick = evt => {
        if (placeholderButton && placeholderButton.current && placeholderButton.current.buttonNode.contains(evt.target))
            setPlaceholderOpen(true);
        else setPlaceholderOpen(false);
    };

    // effect to add event listener on mount and remove the same on unmount
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, []);

    //render placeholder list
    const renderPlaceholderList = (
        <div ref={placeholderList} className="placeholder-list">
            {Object.entries(allPlaceholders).map(([key, value]) => (
                <ul key={key}>
                    <li>
                        <div className="placeholder-key">{key}</div>
                        <ul>
                            {value &&
                                value.map(placeholder => {
                                    return (
                                        <li key={placeholder.key} onClick={() => insertPlaceholder(placeholder.key)}>
                                            <div className="placeholder">{placeholder.name}</div>
                                        </li>
                                    );
                                })}
                        </ul>
                    </li>
                </ul>
            ))}
        </div>
    );

    return (
        <div>
            {props.isLoading ? (
                <Loader />
            ) : (
                <section>
                    <div className="details-wrapper">
                        <Row gutter={16} className="settings-body">
                            <Col span={40} className="right-align auto-width preview-placeholder-button-holder">
                                {!props.disableBody && (
                                    <section className="placeholder-wrapper">
                                        <Button
                                            className="blue-bg-button"
                                            onClick={() => setPlaceholderOpen(!placeholderOpen)}
                                            forwardedRef={placeholderButton}
                                        >
                                            <Icon type="bulb" />
                                            Placeholder
                                        </Button>
                                        {placeholderOpen && renderPlaceholderList}
                                    </section>
                                )}
                                {props.message ? (
                                    <PermissionGuard requiredPermission={REMINDER.PREVIEW}>
                                        <Button
                                            className="blue-bg-button preview-button"
                                            filled
                                            onClick={() => props.showPreview()}
                                        >
                                            <Icon type="eye" />
                                            Preview
                                        </Button>
                                    </PermissionGuard>
                                ) : (
                                    ''
                                )}
                            </Col>
                            <Col
                                className="template-textarea"
                                onClick={() =>
                                    props.disableBody
                                        ? dispatch(
                                              showNotificationPopup(
                                                  t('customerMessages.reminders.send.notificationPopup.sms')
                                              )
                                          )
                                        : ''
                                }
                            >
                                <Field
                                    ref={message}
                                    type="textarea"
                                    name="message"
                                    label="Message"
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    onChange={e => {
                                        props.setMessage(e.target.value);
                                    }}
                                    disabled={props.disableBody}
                                    required
                                />
                                <div className="textarea-counter">
                                    <Icon type="info-circle" />
                                    This message may be split into multiple sms
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            )}
        </div>
    );
};

SmsFields.propTypes = {
    change: PropTypes.func,
    disableBody: PropTypes.bool,
    isDetailsUpdating: PropTypes.bool,
    isLoading: PropTypes.bool,
    message: PropTypes.string,
    setMessage: PropTypes.func,
    showPreview: PropTypes.func,
    t: PropTypes.func,
};

export default SmsFields;

/**
 * Organisation currency Form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import Button from 'components/shared/Button';
import FormField from 'components/shared/FormField';
import PropTypes from 'prop-types';

const PaymentArrangementAddEditForm = props => {
    const { t } = useTranslation();
    const loadingPaymentArrangementAddEdit = useSelector(
        state => state.paymentArrangement.loadingPaymentArrangementAddEdit
    );
    const [paymentArrangementStatus, setPaymentArrangementStatus] = useState(props?.initialValues?.status);
    const [paymentArrangementIsBroken, setPaymentArrangementIsBroken] = useState(props?.initialValues?.is_broken);

    /**
     * Get the localized label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.paymentArrangement.arrangement.addEdit.form.input.label.${field}`);

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="select"
                            name={requestKeys.PAYMENT_ARRANGEMENT_STATUS}
                            label={getLocaleLabel(requestKeys.PAYMENT_ARRANGEMENT_STATUS)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            options={constants.PAYMENT_ARRANGEMENT_STATUS_OPTIONS.map(x => ({
                                value: x.id,
                                name: x.label,
                            }))}
                            onChange={value => setPaymentArrangementStatus(value)}
                            required
                            disabled={
                                ![
                                    constants.PAYMENT_ARRANGEMENT_STATUS_APPROVED,
                                    constants.PAYMENT_ARRANGEMENT_STATUS_PENDING,
                                ].includes(paymentArrangementStatus)
                            }
                            info={t(`messages.paymentArrangement.arrangement.note.info.notify_debtor_on_status_change`)}
                        />
                    </Col>
                </Row>
                {![
                    constants.PAYMENT_ARRANGEMENT_STATUS_REJECTED,
                    constants.PAYMENT_ARRANGEMENT_STATUS_COMPLETED,
                ].includes(paymentArrangementStatus) ? (
                    <>
                        <Row gutter={16}>
                            <Col>
                                <Field
                                    type="switch"
                                    name={requestKeys.PAYMENT_ARRANGEMENT_IS_BROKEN}
                                    label={getLocaleLabel(requestKeys.PAYMENT_ARRANGEMENT_IS_BROKEN)}
                                    hasFeedback
                                    component={FormField}
                                    onChange={value => setPaymentArrangementIsBroken(value)}
                                />
                            </Col>
                        </Row>
                        {paymentArrangementIsBroken ? (
                            <Row gutter={16}>
                                <Col>
                                    <Field
                                        type="date"
                                        name={requestKeys.PAYMENT_ARRANGEMENT_BROKEN_DATE}
                                        label={getLocaleLabel(requestKeys.PAYMENT_ARRANGEMENT_BROKEN_DATE)}
                                        hasFeedback
                                        component={FormField}
                                        validate={[formValidations.date, formValidations.required]}
                                        reduxChange={props.change}
                                        required
                                    />
                                </Col>
                            </Row>
                        ) : null}
                    </>
                ) : null}
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="textarea"
                            name={requestKeys.PAYMENT_ARRANGEMENT_NOTE}
                            label={getLocaleLabel(requestKeys.PAYMENT_ARRANGEMENT_NOTE)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="switch"
                            name={requestKeys.PAYMENT_ARRANGEMENT_NOTE_NOTIFY_DEBTOR}
                            label={getLocaleLabel(requestKeys.PAYMENT_ARRANGEMENT_NOTE_NOTIFY_DEBTOR)}
                            hasFeedback
                            component={FormField}
                            info={t(`messages.paymentArrangement.arrangement.note.info.notify_debtor`)}
                            infoAsToolTip
                        />
                    </Col>
                </Row>
            </Form>
            <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={loadingPaymentArrangementAddEdit}
                        submittingButtonLabel={t(
                            `messages.paymentArrangement.arrangement.addEdit.form.submit.buttonSubmittingLabel.edit`
                        )}
                        className="register-btn"
                    >
                        {t(`messages.paymentArrangement.arrangement.addEdit.form.submit.buttonLabel.edit`)}
                    </Button>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
};

/**
 * Proptypes
 */
PaymentArrangementAddEditForm.propTypes = {
    change: PropTypes.func,
    error: PropTypes.string,
    initialValues: PropTypes.object,
    isAdd: PropTypes.bool,
    handleSubmit: PropTypes.func,
};

export default reduxForm({
    form: constants.PAYMENT_ARRANGEMENT_FORM,
})(PaymentArrangementAddEditForm);

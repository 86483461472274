/**
 * Aggregated Transactions component
 * This component renders aggregated transactions list as an accordion, which when expanded shows a list of transactions falling under that category
 *
 * @since 2.8.0
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import Transactions from '../Transactions';
import './styles.scss';
import Loader from 'components/Loader';
import { formatNumberAsCurrency } from 'includes/utils';
import { getBankAccountTransactionsByCategory } from 'includes/slices/openBanking';
import useOrganisationId from 'includes/hooks/useOrganisationId';

/**
 * Component that renders aggregated transactions list
 *
 * @since 2.8.0
 */
export default function AggregatedTransactions({
    contactId,
    selectedAccountId,
    transactions,
    isLoading,
    paymentArrangementId,
}) {
    const dispatch = useDispatch();

    const organisationId = useOrganisationId();

    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(-1);

    /**
     * Selected category from `transactions` prop selected using `selectedCategoryIndex`
     */
    const selectedCategory = useMemo(() => {
        if (selectedCategoryIndex === -1 || transactions.length === 0) return null;

        return transactions[selectedCategoryIndex];
    }, [selectedCategoryIndex]);

    /**
     * function responsible for making get request for transactions
     *
     * @since 2.8.0
     */
    const fetchBankAccountTransactionsByCategory = useCallback(
        (data = {}) => {
            dispatch(
                getBankAccountTransactionsByCategory(organisationId, {
                    contact_id: contactId,
                    ...(selectedAccountId === 'all' ? {} : { bank_account_id: selectedAccountId }),
                    ...(selectedCategory
                        ? {
                              category: encodeURIComponent(selectedCategory?.category),
                              currency: encodeURIComponent(selectedCategory?.currency),
                          }
                        : {}),
                    ...data,
                    payment_arrangement_id: paymentArrangementId,
                })
            );
        },
        [dispatch, organisationId, contactId, selectedAccountId, selectedCategory]
    );

    const isBankAccountTransactionsByCategoryLoading = useSelector(
        state => state.openBanking.isBankAccountTransactionsByCategoryLoading
    );
    const bankAccountTransactionsByCategory = useSelector(state => state.openBanking.bankAccountTransactionsByCategory);
    const bankAccountTransactionsByCategoryPagination = useSelector(
        state => state.openBanking.bankAccountTransactionsByCategoryPagination
    );

    /**
     * Initialize fetch transactions
     *
     * @since 2.8.0
     */
    useEffect(() => {
        fetchBankAccountTransactionsByCategory();
    }, []);

    /**
     * If `isLoading` prop is `true` then fallback to loading UI
     */
    if (isLoading) return <Loader />;

    return (
        <div className="aggregated-bank-account-transactions-wrapper">
            <Collapse
                activeKey={selectedCategoryIndex}
                accordion
                expandIconPosition="right"
                onChange={index => setSelectedCategoryIndex(index ?? -1)}
                destroyInactivePanel
            >
                {transactions.map((cat, index) => (
                    <Collapse.Panel
                        header={cat.category}
                        key={index}
                        extra={
                            <div className="green-text">{formatNumberAsCurrency(cat.total_amount, cat.currency)}</div>
                        }
                    >
                        <Transactions
                            fetchBankAccountTransactions={fetchBankAccountTransactionsByCategory}
                            isLoading={isBankAccountTransactionsByCategoryLoading}
                            transactions={bankAccountTransactionsByCategory}
                            paginationData={bankAccountTransactionsByCategoryPagination}
                        />
                    </Collapse.Panel>
                ))}
            </Collapse>
        </div>
    );
}

AggregatedTransactions.propTypes = {
    /**
     * Contact ID
     */
    contactId: PropTypes.string,
    /**
     * Loading status
     */
    isLoading: PropTypes.bool,
    /**
     * Selected account ID
     */
    selectedAccountId: PropTypes.string,
    /**
     * Transactions grouped by category
     */
    transactions: PropTypes.arrayOf(PropTypes.any),
    /**
     * Pagination data
     */
    paginationData: PropTypes.any,
    /**
     * Payment arrangement ID
     */
    paymentArrangementId: PropTypes.string,
};

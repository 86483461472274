import { Breadcrumb } from 'antd';
import PageTitle from 'components/PageTitle';
import PaymentServiceDetailsSection from 'components/PaymentServiceDetailsSection';
import { ORDER_TYPE } from 'includes/constants';
import { getPaymentGatewayDetails } from 'includes/slices/paymentGateways';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

/**
 * App payment gateway details
 *
 * @since 2.8.0
 */
export default function AppPaymentGatewayDetails() {
    const { paymentGatewayId } = useParams();

    const dispatch = useDispatch();

    const paymentGatewayDetails = useSelector(state => state.paymentGateways.paymentGatewayDetails);

    const loadingPaymentGatewayDetails = useSelector(state => state.paymentGateways.loadingPaymentGatewayDetails);

    useEffect(() => {
        dispatch(getPaymentGatewayDetails(paymentGatewayId));
    }, [paymentGatewayId]);

    return (
        <div className="container mx-auto">
            <div className="tab-arrow-link breadcumb-spec">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/admin/payment-gateways">Payment gateways</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Payment gateway details</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="my-4">
                <PageTitle>Payment service details</PageTitle>
            </div>
            <PaymentServiceDetailsSection
                orderTypeSlug={ORDER_TYPE.SUBSCRIPTION}
                organisationId={null}
                paymentServiceId={paymentGatewayId}
                paymentServiceDetails={paymentGatewayDetails}
                isLoading={loadingPaymentGatewayDetails}
            />
        </div>
    );
}

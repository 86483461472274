import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TimeAgo from 'react-timeago';
import ReactHtmlParser from 'react-html-parser';
import { Badge, Icon, Modal, Popover } from 'antd';
import { withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { cloneDeep, findIndex, get } from 'lodash';

import {
    deleteAllNotifications,
    sendDataToServer,
    updateAllNotifications,
    updateNotifications,
} from '../../../../includes/redux/actions/shared/notification';
import * as responseKeys from '../../../../includes/constants/keys/response';
import { NOTIFICATION_PRIORITY_BADGE_MAPPING } from '../../../../includes/constants/mappings/success';
import './styles.scss';
import * as requestKeys from '../../../../includes/constants/keys/request';
import { USER_ROLE_ADMIN, USER_ROLE_ADMIN_MANAGER, WS_ACTION } from '../../../../includes/constants';
import { forceReRenderComponentRequest } from '../../../../includes/redux/actions/shared/settings';
import { useCurrentRoleSlug } from '../../../../includes/utils/hooks';
import useZendeskSettings from 'includes/hooks/useZendeskSettings';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import { stripHTMLTags } from 'includes/utils';

const defaultNotificationIcon = require('../../../../assets/images/notification-icon.svg');

const { info, error } = Modal;

const AppHeaderSecondaryNavNotification = props => {
    const isAdmin = props.isAdmin;
    const currentRoleSlug = useCurrentRoleSlug();
    const { reset: clearZendeskSettingsCache } = useZendeskSettings(true);
    const {
        notifications,
        updateNotifications,
        updateAllNotifications,
        sendDataToServer,
        allNotifications,
        deleteAllNotifications,
    } = props;

    const { reset: clearOrganisationCurrenciesCache } = useOrganisationCurrencies(true);

    /**
     * Get notification link
     *
     * @returns {string} Notification link
     */
    const getNotificationLink = () => {
        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return `/admin/notifications`;
            default:
                return `/notifications`;
        }
    };

    /**
     * Handle the click on the notification row
     *
     * @param   {object}    event    Event
     *
     * @returns  {boolean}   true
     */
    const handleNotificationClick = useCallback(
        (notification = '', actionType = '') => {
            let showNotificationPopup = false;
            if (actionType === requestKeys.NOTIFICATION_DELETE_ALL) {
                deleteAllNotifications();
                sendDataToServer({
                    type: actionType,
                });
            } else {
                let processNotificationClick = true;
                if (actionType === requestKeys.NOTIFICATION_MARK_AS_READ) {
                    showNotificationPopup = true;
                    const isRead = get(notification, responseKeys.NOTIFICATION_HAS_READ);
                    if (isRead) {
                        processNotificationClick = false;
                    }
                }

                if (processNotificationClick) {
                    // update notifications in dropdown
                    let notificationsClone = cloneDeep(notifications);
                    let unreadCount = props.unreadCount;

                    if (notificationsClone) {
                        if (notification) {
                            const notificationIndex = findIndex(notificationsClone, { id: notification.id });
                            if (notificationIndex !== -1) {
                                notificationsClone[notificationIndex][responseKeys.NOTIFICATION_HAS_READ] = true;
                                if (unreadCount > 0) {
                                    unreadCount = unreadCount - 1;
                                }
                            }
                        } else {
                            notificationsClone = notificationsClone.map(notificationClone => ({
                                ...notificationClone,
                                has_read: actionType === requestKeys.NOTIFICATION_MARK_ALL_AS_READ ? true : false,
                            }));
                            unreadCount = 0;
                        }
                        updateNotifications(notificationsClone, unreadCount);
                    }

                    // update notification in notifications page
                    let allNotificationsClone = cloneDeep(allNotifications);

                    if (allNotificationsClone) {
                        if (notification) {
                            const allNotificationIndex = findIndex(allNotificationsClone, { id: notification.id });
                            if (allNotificationIndex !== -1) {
                                allNotificationsClone[allNotificationIndex][responseKeys.NOTIFICATION_HAS_READ] = true;
                            }
                        } else {
                            allNotificationsClone = allNotificationsClone.map(allNotificationClone => ({
                                ...allNotificationClone,
                                has_read: actionType === requestKeys.NOTIFICATION_MARK_ALL_AS_READ ? true : false,
                            }));
                        }
                        updateAllNotifications(allNotificationsClone, unreadCount);
                    }

                    // update notification read status to server
                    sendDataToServer({
                        notification_id: notification ? notification.id : '',
                        type: actionType,
                    });
                }
            }

            if (showNotificationPopup) {
                renderNotificationPopup(notification);
            }
        },
        [allNotifications, notifications] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // render notification popup
    const renderNotificationPopup = notification => {
        // handle corresponding action
        let notificationProps = {
            title: ReactHtmlParser(notification.title),
            content: ReactHtmlParser(notification.description),
            centered: true,
            maskClosable: true,
        };
        let notificationShownFlag = false;
        switch (notification.action) {
            case WS_ACTION.ORGANISATION_DATA_FETCHING_COMPLETE:
            case WS_ACTION.ORGANISATION_SYNC_COMPLETE:
            case WS_ACTION.ORGANISATION_DATA_SYNCING_COMPLETE:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Refresh data',
                    onOk: () => {
                        clearOrganisationCurrenciesCache();
                        props.history.push('/dashboard');
                        forceReRenderComponent('/dashboard');
                    },
                };
                break;
            case WS_ACTION.ACCOUNT_SUBSCRIPTION_CANCELLED:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Reload',
                    onOk: () => window.location.replace('/'),
                };
                break;
            case WS_ACTION.ACCOUNT_SUBSCRIPTION_LIMIT_REACHED:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Upgrade subscription',
                    onOk: () => props.history.push('/plans'),
                };
                break;
            case WS_ACTION.ACCOUNT_SUBSCRIPTION_UPDATE_COMPLETE:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Reload',
                    onOk: () => window.location.replace('/'),
                };
                break;
            case WS_ACTION.ORGANISATION_REAUTHORIZATION_REQUIRED:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Reauthorize',
                    onOk: () =>
                        props.history.push({
                            pathname: '/organisations/reauthorize',
                            state: {
                                reauthorize: true,
                                organisation: props.selectedOrganisation,
                            },
                        }),
                };
                break;
            case WS_ACTION.ORGANISATION_LEGAL_PAYMENT_COMPLETE:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Orders',
                    onOk: () => props.history.push({ pathname: '/orders', state: { typeSlug: 'legal-letter' } }),
                };
                break;
            case WS_ACTION.ORGANISATION_INVOICE_PAYMENT_RECEIVED:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Orders',
                    onOk: () =>
                        props.history.push({
                            pathname: '/invoice-orders',
                        }),
                };
                break;
            case WS_ACTION.FILE_EXPORTED_SUCCESSFULLY:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Exports',
                    onOk: () => props.history.push(isAdmin ? '/admin/exports' : '/exports'),
                };
                break;
            case WS_ACTION.ORGANISATION_PAYMENT_ARRANGEMENT_REQUEST:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Payment arrangements',
                    onOk: () => props.history.push('/payment-arrangements'),
                };
                break;
            case WS_ACTION.ORGANISATION_PAYMENT_EXTENSION_REQUEST:
                notificationProps = {
                    ...notificationProps,
                    okText: 'Payment extensions',
                    onOk: () => props.history.push('/payment-extensions'),
                };
                break;
            case WS_ACTION.FILE_EXPORT_FAILED:
                error(notificationProps);
                notificationShownFlag = true;
                break;
            case WS_ACTION.ORGANISATION_ZENDESK_CONNECTION_FAILURE:
                clearZendeskSettingsCache();
                error(notificationProps);
                notificationShownFlag = true;
                break;
            case WS_ACTION.REMINDER_SCHEDULING_COMPLETE:
                notificationProps = {
                    ...notificationProps,
                    okText: 'See Reminders',
                    onOk: () => {
                        props.history.push('/reminders');
                        forceReRenderComponent('/reminders');
                    },
                };
                break;
            default:
                notificationProps = { ...notificationProps, okText: 'Dismiss' };
        }
        !notificationShownFlag && info(notificationProps);
    }; // eslint-disable-line react-hooks/exhaustive-deps

    const { forceReRenderComponentRequest, location } = props;
    /**
     * Force component rerender action request
     * Makes the request to set the prop to make the component rerender
     *
     * @param   {string}    link    Link to redirect
     */
    const forceReRenderComponent = link => {
        // send the request only if the current path and the link are same
        // if they are different, the component will automatically rerender and we don't need to force
        if (location.pathname === link) {
            forceReRenderComponentRequest();
        }
    }; // eslint-disable-line react-hooks/exhaustive-deps

    const notificationOptions = (
        <div>
            <div
                style={{ padding: 5, cursor: 'pointer' }}
                onClick={() => handleNotificationClick('', requestKeys.NOTIFICATION_MARK_ALL_AS_READ)}
            >
                <Icon type="check" style={{ display: 'inline', fontSize: 16, fontWeight: 'bold' }} />
                <span style={{ position: 'relative', top: 3, left: 5 }}>Mark all as read</span>
            </div>
            <div
                style={{ padding: 5, cursor: 'pointer' }}
                onClick={() => handleNotificationClick('', requestKeys.NOTIFICATION_DELETE_ALL)}
            >
                <Icon type="delete" style={{ display: 'inline', fontSize: 16, fontWeight: 'bold' }} />
                <span style={{ position: 'relative', top: 3, left: 5 }}>Delete all</span>
            </div>
        </div>
    );

    /**
     * Modify and return the contact data
     */
    const formatNotificationsData = useCallback(() => {
        // take the latest 10 notifications only
        const notificationHtml = notifications.slice(0, 10).map(notification => {
            const notificationBadgeType = get(
                NOTIFICATION_PRIORITY_BADGE_MAPPING,
                get(notification, [responseKeys.NOTIFICATION_TYPE, responseKeys.NOTIFICATION_TYPE_SLUG]),
                'default'
            );

            const notificationImage =
                get(notification, [responseKeys.NOTIFICATION_IMAGE, responseKeys._45X45]) || defaultNotificationIcon;

            return (
                <li
                    key={notification.id}
                    data-id={notification.id}
                    onClick={() => handleNotificationClick(notification, requestKeys.NOTIFICATION_MARK_AS_READ)}
                    title={stripHTMLTags(get(notification, responseKeys.NOTIFICATION_DESCRIPTION))}
                    className={!get(notification, responseKeys.NOTIFICATION_HAS_READ) ? 'unread-notification' : ''}
                >
                    <div>
                        <Badge status={notificationBadgeType} data-id={notification.id}>
                            <div data-id={notification.id} className="notification-img-wrapper">
                                <img src={notificationImage} alt="Icon" data-id={notification.id} />
                            </div>
                        </Badge>
                        <span className="details" data-id={notification.id}>
                            <p className="notification-title" data-id={notification.id}>
                                {stripHTMLTags(get(notification, responseKeys.NOTIFICATION_TITLE))}
                            </p>
                            <p className="notification-description" data-id={notification.id}>
                                {stripHTMLTags(get(notification, responseKeys.NOTIFICATION_DESCRIPTION))}
                            </p>
                            <div className="time" data-id={notification.id}>
                                <Icon type="clock-circle" data-id={notification.id} />
                                <TimeAgo date={get(notification, responseKeys.CREATED_DATE)} />
                            </div>
                        </span>
                    </div>
                </li>
            );
        });
        return (
            <div>
                <ul className={notificationHtml.length > 0 ? 'notifications-dropdown' : 'no-data-text'}>
                    {notificationHtml.length > 0 ? notificationHtml : 'No notifications'}
                </ul>
                {notificationHtml.length > 0 ? (
                    <div className="see-all-link" style={{ float: 'left', width: '100%' }}>
                        <Link to={getNotificationLink()} style={{ float: 'left', marginTop: 5 }}>
                            See All
                        </Link>
                        <Popover
                            content={notificationOptions}
                            title=""
                            trigger="hover"
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                        >
                            <span style={{ float: 'right' }}>
                                <Icon type="ellipsis" style={{ display: 'inline', fontSize: 30, fontWeight: 'bold' }} />
                            </span>
                        </Popover>
                    </div>
                ) : null}
            </div>
        ); // eslint-disable-next-line
    }, [notifications, handleNotificationClick]);

    //memoized notifications data
    const notificationsData = useMemo(() => formatNotificationsData(), [formatNotificationsData]);

    return <ul>{notificationsData}</ul>;
};

AppHeaderSecondaryNavNotification.propTypes = {
    allNotifications: PropTypes.array,
    deleteAllNotifications: PropTypes.func,
    defaultOrganisationLogo: PropTypes.string,
    forceReRenderComponentRequest: PropTypes.func,
    history: PropTypes.object,
    isAdmin: PropTypes.bool,
    location: PropTypes.object,
    notifications: PropTypes.array,
    selectedOrganisation: PropTypes.object,
    sendDataToServer: PropTypes.func,
    unreadCount: PropTypes.number,
    updateAllNotifications: PropTypes.func,
    updateNotifications: PropTypes.func,
};

//Connect to store
export default connect(
    state => ({
        ...state.notification,
        defaultOrganisationLogo: state.settings.defaultOrganisationLogo,
        forceReRenderComponent: state.settings.forceReRenderComponent,
        isAdmin: state.account.isAdmin,
        roles: state.user.roles,
        selectedOrganisation: state.organisation.selectedOrganisation,
    }),
    {
        forceReRenderComponentRequest,
        sendDataToServer,
        updateAllNotifications,
        updateNotifications,
        deleteAllNotifications,
    }
)(withTranslation()(withRouter(AppHeaderSecondaryNavNotification)));

/**
 * Redux Organisations action
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import action types
import * as actionTypes from '../../../constants/actionTypes';

/**
 * Get arrangement due data Action
 *
 * @param   {object}    organisationId     Organisation Id
 * @param   {Array}     dateList           List of from and to date
 *
 *
 * @returns {object}                        Get Organisation dashboard data action
 */
export const getArrangementDueData = (organisationId, dateList) => ({
    payload: { organisationId: organisationId, from_date: dateList[0], to_date: dateList[1] },
    type: actionTypes.GET_ARRANGEMENT_DUE_DATA_REQUEST,
});

/**
 * Get cash collected data Action
 *
 * @param   {object}    organisationId     Organisation Id
 * @param   {Array}     dateList           List of from and to date
 *
 * @returns {object}                        Get Organisation dashboard data action
 */
export const getCashCollectedData = (organisationId, dateList) => ({
    payload: { organisationId: organisationId, from_date: dateList[0], to_date: dateList[1] },
    type: actionTypes.GET_CASH_COLLECTED_DATA_REQUEST,
});

/**
 * Get contact invoice status and type data action
 *
 * @param   {string}    organisationId   Organisation id
 * @returns {object}                     Get contact invoice status and type data action
 */
export const getContactInvoiceStatusTypeData = organisationId => ({
    payload: { organisationId: organisationId },
    type: actionTypes.GET_CONTACT_INVOICE_STATUS_TYPE_DATA_REQUEST,
});

/**
 * Get dashboard data Action
 *
 * @param   {object}    organisationId      Organisation Id
 *
 * @returns {object}                        Get Organisation dashboard data action
 */
export const getDashboardData = organisationId => ({
    payload: { organisationId: organisationId },
    type: actionTypes.GET_DASHBOARD_DATA_REQUEST,
});

/**
 * Get debt band and age action
 *
 * @param   {string}    organisationId   Organisation id
 * @returns {object}                     Get debt band and age action
 */
export const getDebtAgeAndDebtBand = organisationId => ({
    payload: { organisationId: organisationId },
    type: actionTypes.GET_DEBT_AGE_DEBT_BAND_DATA_REQUEST,
});

/**
 * Get debtor accounts data action
 *
 * @param {string} organisationId Organisation Id
 * @param {object} searchParams Search Params
 *
 * @returns {object} Get debtor accounts data
 */
export const getDebtorAccounts = (organisationId, searchParams = {}) => ({
    payload: { organisationId: organisationId, searchParams },
    type: actionTypes.GET_DEBTOR_ACCOUNTS_REQUEST,
});

/**
 * Get organisations Action
 *
 * @param   {object}    obj     Payload
 * @returns {object}            Get Organisations action
 */
export const getOrganisations = obj => ({
    payload: obj,
    type: actionTypes.GET_ORGANISATIONS_REQUEST,
});

/**
 * Get organisations Action
 *
 * @param   {object}    obj     Payload
 * @returns {object}            Get Organisations action
 */
export const getOrganisationsByAccountId = (accountId, searchParams) => ({
    payload: {
        account_id: accountId,
        searchParams,
    },
    type: actionTypes.GET_ORGANISATION_BY_ACCID_REQUEST,
});

/**
 * Get organisation details Action
 *
 * @param   {string}    organisationId      Payload
 * @param {boolean} setSelectedOrganisationAfterFetching Set selected organisation after fetching. Default false
 * @returns {object}                        Get Organisations details action
 */
export const getOrganisationDetails = (organisationId, setSelectedOrganisationAfterFetching = false) => ({
    type: actionTypes.GET_ORGANISATION_DETAILS_REQUEST,
    payload: {
        organisationId,
        setSelectedOrganisationAfterFetching: setSelectedOrganisationAfterFetching,
    },
});

/**
 * Organisation data fetching complete action
 *
 * @param   {object}    organisation      Payload
 *
 * @returns {object}                    Organisation data fetching complete action
 */
export const organisationDataFetchingComplete = organisation => ({
    organisation: organisation,
    type: actionTypes.ORGANISATION_DATA_FETCHING_COMPLETE,
});

/**
 * Organisation Reauthorization required action
 *
 * @returns {object}                       Set Selected Organisations action
 */
export const organisationReauthorizationRequired = () => ({
    type: actionTypes.ORGANISATION_REAUTHORIZATION_REQUIRED,
});

/**
 * Remove dashboard data loading Action
 *
 * @returns {object}   Remove debt age and debt band loading Action
 */
export const removeDashboardDataLoading = () => ({
    type: actionTypes.GET_DASHBOARD_DATA_FAILURE,
});

/**
 * Remove debt age and debt band loading Action
 *
 * @returns {object}   Remove debt age and debt band loading Action
 */
export const removeDebtAgeAndDebtBandLoading = () => ({
    type: actionTypes.GET_DEBT_AGE_DEBT_BAND_DATA_FAILURE,
});

/**
 * Reset selected organisation Action
 *
 * @returns {object}  Reset Selected Organisations action
 */
export const resetSelectedOrganisation = () => ({
    type: actionTypes.RESET_SELECTED_ORGANISATION_REQUEST,
});

/**
 * Set selected organisation Action
 *
 * @param   {string}    organisationId      Payload
 * @returns {object}                        Set Selected Organisations action
 */
export const setSelectedOrganisation = organisationId => ({
    type: actionTypes.SET_SELECTED_ORGANISATION_REQUEST,
    payload: {
        organisationId,
    },
});

/**
 * Sync Organisation Action
 *
 * @param   {string}    organisationId      Payload
 * @returns {object}                        Set Selected Organisations action
 */
export const syncOrganisation = organisationId => ({
    type: actionTypes.SYNC_ORGANISATION_REQUEST,
    payload: {
        organisationId,
    },
});

/**
 * Sync Organisation Complete Action
 *
 * @param   {object}    organisation        Organisation
 *
 * @returns {object}                        Set Selected Organisations action
 */
export const syncOrganisationComplete = organisation => ({
    organisation: organisation,
    type: actionTypes.SYNC_ORGANISATION_COMPLETE,
});

/**
 * Update organisation details Action
 *
 * @param   {object}    obj     Payload
 * @param {Function} callback Callback function
 *
 * @returns {object}            Update Organisations action
 */
export const updateOrganisation = (obj, organisationId, accountId, callback) => ({
    type: actionTypes.ORGANISATION_UPDATE_REQUEST,
    payload: {
        organisationDetails: obj,
        organisation_id: organisationId,
    },
    callback: callback,
    accountId: accountId,
});

/**
 * Delete organisation details Action
 *
 * @param {string} organisationId Organisation Id
 * @param {string} accountId Account Id
 * @param {boolean} isAdmin Whether the user is admin or not
 *
 * @returns {object}            Delete Organisations action
 */
export const deleteOrganisation = (organisationId, accountId, isAdmin = false) => ({
    type: actionTypes.ORGANISATION_DELETE_REQUEST,
    payload: {
        organisation_id: organisationId,
    },
    isAdmin: isAdmin,
    accountId: accountId,
});

/* Add organisationAction
 *
 * @param   {object}    obj     Payload
 * @param {function} callback Callback function
 *
 * @returns {object}            Add Organisations action
 */
export const addOrganisation = (obj, callback) => ({
    type: actionTypes.ORGANISATION_ADD_REQUEST,
    payload: {
        organisationDetails: obj,
    },
    callback: callback,
});

/**
 * Get coubtries list action
 *
 * @returns {object}    Get countries list action
 */
export const exportOrganisation = params => ({
    type: actionTypes.EXPORT_ORGANISATION_REQUEST,
    payload: params,
});

/**
 * Selected organisation data fetching state update
 *
 * @returns {object}   Selected organisation data fetching state updte
 */
export const organisationSelectedOrganisationDataFetchingUpdate = () => ({
    type: actionTypes.ORGANISATION_SELECTED_ORGANISATION_DATA_FETCHING_UPDATE,
});

/**
 * export organisation by accountId
 */
export const exportAccountOrganisation = accountId => ({
    type: actionTypes.EXPORT_ORGANISATION_REQUEST,
    payload: {
        accountId,
    },
});

/**
 * Update organisation settings action
 *
 * @param {string} organisationId
 */
export const updateOrganisationSettings = (organisationId, organisationSettings) => ({
    type: actionTypes.UPDATE_ORGANISATION_SETTINGS_REQUEST,
    payload: {
        organisation_id: organisationId,
        ...organisationSettings,
    },
});

/**
 * export account wise organisations data
 *
 */
export const exportAccountOrganisations = (accountId, params) => ({
    type: actionTypes.EXPORT_ACCOUNT_ORGANISATIONS_REQUEST,
    payload: { accountId, params },
});

/**
 * get currencies list
 *
 */
export const getCurrencies = () => ({
    type: actionTypes.GET_CURRENCIES_REQUEST,
    payload: { page_size: 0 },
});

/**
 * Reset  organisation related data action
 *
 * @returns {object} Reset organisation related data action
 */
export const resetOrganisationRelatedData = () => ({
    type: actionTypes.RESET_ORGANISATION_RELATED_DATA,
});

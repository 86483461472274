import { Breadcrumb } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import PageTitle from 'components/PageTitle';
import WhiteBoxWrapper from 'components/WhiteBoxWrapper';
import BankAccounts from 'components/customer/PaymentInformation/components/BankAccounts';
import useAccountId from 'includes/hooks/useAccountId';

/**
 * Bank accounts listing for a particular account
 *
 * @returns Bank accounts view for admin
 *
 * @since 2.8.0
 */
export default function AccountBankAccounts() {
    const accountId = useAccountId();

    return (
        <div>
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/admin/accounts">Account</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={`/admin/accounts/organisations/${accountId}`}>Organisations</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>Bank accounts</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="mb-5">
                <PageTitle>Bank Accounts</PageTitle>
            </div>
            <WhiteBoxWrapper>
                <BankAccounts />
            </WhiteBoxWrapper>
        </div>
    );
}

/**
 * Inventory API class.
 * Handles all api requests related to inventory
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

//import base api handler
import BaseApiHandler from './base/api';

class InventoryApi extends BaseApiHandler {
    /**
     * Add an inventory item
     *
     * @param {string} organisationId organisation Id
     * @param {object} details inventory item details
     *
     * @returns {object} API Response
     */
    addInventoryItem = (organisationId, details) =>
        this.axios.post(
            this.getApiUrl('INVENTORY_ITEMS_ORGANISATION_CRUD', { organisation_id: organisationId }),
            details
        );

    /**
     * Delete an inventory item
     *
     * @param {string} organisationId organisation Id
     * @param {string} itemId item Id
     *
     * @returns {object} API Response
     */
    deleteInventoryItem = (organisationId, itemId) =>
        this.axios.delete(
            this.getApiUrl('INVENTORY_ITEM_ORGANISATION_CRUD', {
                organisation_id: organisationId,
                inventory_item_id: itemId,
            })
        );

    /**
     * Get the inventory item
     *
     * @param {string} organisationId organisation Id
     * @param {string} itemId item Id
     *
     * @returns {object} API Response
     */
    getInventoryItem = (organisationId, itemId) =>
        this.axios.get(
            this.getApiUrl('INVENTORY_ITEM_ORGANISATION_CRUD', {
                organisation_id: organisationId,
                inventory_item_id: itemId,
            })
        );

    /**
     * Get the inventory items
     *
     * @param {string} organisationId organisation Id
     * @param {object} searchParams Search Params
     *
     * @returns {object} API Response
     */
    getInventoryItems = (organisationId, searchParams) =>
        this.axios.get(this.getApiUrl('INVENTORY_ITEMS_ORGANISATION_CRUD', { organisation_id: organisationId }), {
            params: searchParams,
        });

    /**
     * Get the inventory item transactions
     *
     * @param {string} inventoryItemId Inventory Item Id
     * @param {string} organisationId Organisation ID
     * @param {object} searchParams Search Params. Default ''
     *
     * @returns {object} API Response
     */
    getInventoryItemsTransactions = (inventoryItemId, organisationId, searchParams = '') =>
        this.axios.get(
            this.getApiUrl('INVENTORY_ITEM_ORGANISATION_TRANSACTIONS', {
                inventory_item_id: inventoryItemId,
                organisation_id: organisationId,
            }),
            {
                params: searchParams,
            }
        );

    /**
     * Update the inventory item
     *
     * @param {string} organisationId organisation Id
     * @param {string} itemId item Id
     * @param {object} itemDetails item details
     *
     * @returns {object} API Response
     */
    updateInventoryItem = (organisationId, itemId, itemDetails) =>
        this.axios.patch(
            this.getApiUrl('INVENTORY_ITEM_ORGANISATION_CRUD', {
                organisation_id: organisationId,
                inventory_item_id: itemId,
            }),
            itemDetails
        );
}

export default new InventoryApi();

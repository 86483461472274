import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import Button from 'components/Button';
import { useOrderTypes, usePaymentStatuses } from 'includes/utils/hooks';
import { ORDER_TYPE, PAYMENT_STATUS } from 'includes/constants';
import OrderSummary from '../OrderSummary';
import PaymentGateway from 'components/PaymentGateway';
import useInternalPaymentGateways from 'includes/hooks/useInternalPaymentGateways';
import { showAppNotificationModal } from 'includes/redux/actions/shared/appNotification';
import { useDispatch } from 'react-redux';

/**
 * Retry payment section
 * This component takes in `paymentStatusId` and checks if retry payment section should be enabled and renders out the UI.
 *
 * @since 2.8.0
 */
export default function RetryPaymentSection({
    amount,
    appliedCoupon,
    createOrder,
    isLoadingPayButton,
    setIsLoadingPayButton,
    onApplyCoupon,
    orderDetails,
    paymentGatewayId,
    paymentStatusId,
    paymentMethodCountryId,
    selectedMethod,
    setSelectedMethod,
    selectedMethodType,
    setSelectedPaymentGateway,
}) {
    const [isRetryPaymentSectionVisible, setIsRetryPaymentSectionVisible] = useState(false);

    const { data: _paymentGateways } = useInternalPaymentGateways(true);

    const [orderTypes] = useOrderTypes();

    const orderTypeSlug = useMemo(() => {
        if (orderTypes.length === 0) return null;

        return find(orderTypes, { id: orderDetails?.type_id })?.slug;
    }, [orderTypes]);

    const dispatch = useDispatch();

    const paymentGateways = useMemo(() => {
        return _paymentGateways.filter(pg => !pg.reauthorize);
    }, [_paymentGateways]);

    const selectedPaymentGateway = useMemo(() => {
        if (!paymentGateways || paymentGateways.length === 0) return null;

        return find(paymentGateways, { id: paymentGatewayId });
    }, [paymentGateways]);

    // TODO: REMOVE THIS
    const setErrorMessage = useState()[1];

    const [paymentStatuses] = usePaymentStatuses();

    const toggleRetryPaymentSection = () => setIsRetryPaymentSectionVisible(curr => !curr);

    const paymentStatus = useMemo(() => {
        if (!paymentStatusId || paymentStatuses.length === 0) return null;

        return find(paymentStatuses, { id: paymentStatusId });
    }, [paymentStatusId]);

    const label = useMemo(() => {
        if (!paymentStatus || !orderTypeSlug) return null;

        if (orderTypeSlug !== ORDER_TYPE.SUBSCRIPTION) return null;

        let _label = null;

        switch (paymentStatus.slug) {
            case PAYMENT_STATUS.FAILED: {
                _label = 'Retry payment';
                break;
            }
            case PAYMENT_STATUS.NOT_COLLECTED:
            case PAYMENT_STATUS.PENDING: {
                _label = 'Pay now';
                break;
            }
            default:
                break;
        }
        return _label;
    }, [paymentStatus]);

    if (!label) return null;

    return (
        <>
            <div className="flex justify-end border-t px-6 py-4 border-solid border-gray-200">
                <Button filled onClick={toggleRetryPaymentSection}>
                    <span>{label}</span>
                </Button>
            </div>
            <div className={`text-left transition-all duration-300 ${isRetryPaymentSectionVisible ? 'p-6' : 'h-0'}`}>
                <div className={isRetryPaymentSectionVisible ? 'pb-8' : 'hidden'}>
                    <h1 className="text-xl mb-8">Order Summary</h1>
                    <div className={`grid grid-cols-1 gap-8`}>
                        <div>
                            <OrderSummary
                                appliedCoupon={appliedCoupon}
                                onApplyCoupon={onApplyCoupon}
                                paymentGatewayCountryId={selectedPaymentGateway?.country_id}
                                paymentMethodCountryId={paymentMethodCountryId}
                                paymentGatewayId={paymentGatewayId}
                                paymentMethodId={selectedMethod}
                                planName={orderDetails?.plan?.type}
                                planType={orderDetails?.plan?.subscription}
                                selectedPlan={orderDetails?.plan?.id}
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <PaymentGateway
                                amount={amount}
                                isLoadingPayButton={isLoadingPayButton}
                                setErrorMessage={setErrorMessage}
                                setSelectedMethod={setSelectedMethod}
                                createOrder={createOrder}
                                onShowSuccessMessage={() => {
                                    dispatch(showAppNotificationModal('success', 'Subscribed successfully.'));
                                }}
                                orderTypeId={orderDetails.type_id}
                                setIsLoadingPayButton={setIsLoadingPayButton}
                                setSelectedPaymentGateway={setSelectedPaymentGateway}
                                selectedPaymentGatewayId={paymentGatewayId}
                                selectedMethod={selectedMethod}
                                selectedMethodType={selectedMethodType}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

RetryPaymentSection.propTypes = {
    amount: PropTypes.number,
    appliedCoupon: PropTypes.object,
    createOrder: PropTypes.func,
    isLoadingPayButton: PropTypes.bool,
    onApplyCoupon: PropTypes.func,
    orderDetails: PropTypes.object,
    paymentGatewayId: PropTypes.string,
    paymentMethodCountryId: PropTypes.string,
    paymentStatusId: PropTypes.string,
    selectedMethod: PropTypes.string,
    selectedMethodType: PropTypes.string,
    setIsLoadingPayButton: PropTypes.func,
    setSelectedMethod: PropTypes.func,
    setSelectedPaymentGateway: PropTypes.func,
};

/**
 * CkEditor component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import { React, PropTypes } from '../../../includes/exports/react';
import CKEditor from 'ckeditor4-react';
import { Form } from 'antd';

/**
 * CkEditor Component
 */
class CkEditor extends React.Component {
    render() {
        const { error, touched, warning } = this.props.meta;
        const fieldError = touched && (error || warning);
        const validateStatus = fieldError ? 'error' : 'success';
        return (
            <Form.Item validateStatus={validateStatus} help={fieldError} className="ck-editor-block">
                {this.props.disabled && <span className="ck-editor-disabled-overlay-container">&nbsp;</span>}
                <CKEditor
                    onBeforeLoad={CKEDITOR => (CKEDITOR.disableAutoInline = true)}
                    config={{
                        versionCheck: false,
                        readOnly: this.props.disabled,
                        extraPlugins: 'justify,font,colorbutton',
                        toolbarGroups: [
                            { name: 'document', groups: ['mode', 'document', 'doctools'] },
                            { name: 'clipboard', groups: ['clipboard', 'undo'] },
                            { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
                            { name: 'forms', groups: ['forms'] },
                            '/',
                            { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                            { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
                            { name: 'links', groups: ['links'] },
                            { name: 'insert', groups: ['insert'] },
                            '/',
                            { name: 'styles', groups: ['styles'] },
                            { name: 'colors', groups: ['colors'] },
                            { name: 'tools', groups: ['tools'] },
                            { name: 'others', groups: ['others'] },
                        ],
                    }}
                    ref={node => (this.ck = node)}
                    data={this.props.message}
                    onChange={event => {
                        const data = event.editor.getData();
                        if (this.props.setMessage) this.props.setMessage(data);
                        this.props.change(this.props.fieldKey ?? 'message', data);
                    }}
                />
            </Form.Item>
        );
    }
}

CkEditor.propTypes = {
    change: PropTypes.func,
    disabled: PropTypes.bool,
    fieldKey: PropTypes.string,
    forwardedRef: PropTypes.object,
    message: PropTypes.string,
    meta: PropTypes.object,
    setMessage: PropTypes.func,
};

export default CkEditor;

/**
 * Add/Edit Plan Form
 * Renders the Add/Edit Plan form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form, Row, Col } from 'antd';
import { useSelector } from 'react-redux';

import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import Button from '../Button';
import FormField from '../FormField';
import SuccessMessage from '../../shared/messages/SuccessMessage';
import PermissionManager from 'components/PermissionManager';
import useCountries from 'includes/hooks/useCountries';
import Loader from 'components/Loader';
import useCurrencies from 'includes/hooks/useCurrencies';
import { find, get } from 'lodash';
import { CURRENCY_CODE } from 'includes/constants/keys/response';
import useCurrencySymbol from 'includes/hooks/useCurrencySymbol';
import { numberParse } from 'pages/Invoices/utils';

const fieldValueSelector = formValueSelector(constants.ADD_EDIT_PLAN_FORM);

const AddEditPlanForm = reduxForm({
    form: constants.ADD_EDIT_PLAN_FORM,
})(props => {
    const { t } = useTranslation();
    /**
     * Generate the label from language file
     *
     * @param   {string}    field   Field for which locale is to be generated
     *
     * @returns {string}    Generated Locale label
     */
    const localeLabel = field => t(`adminMessages.plans.form.input.label.${field}`);

    const setAllowed = perms => {
        props.change(requestKeys.PERMISSIONS, perms);
    };

    const { isAdd } = props;

    const allowed = useSelector(state => fieldValueSelector(state, requestKeys.PERMISSIONS) || []);
    const allPermissions = useSelector(state => state.settings.permissions);
    const isUpdating = useSelector(state => state.subscription.isUpdating);
    const { data: countries, isLoading: loadingCountries } = useCountries();
    const { data: currencies, isLoading: loadingCurrencies } = useCurrencies();
    const getCurrencySymbol = useCurrencySymbol();
    const [currencyId, setCurrencyId] = useState(props.initialValues?.currency_id);
    const [currencySymbol, setCurrencySymbol] = useState();

    useEffect(() => {
        if (currencyId) {
            const planCurrencyCode = get(find(currencies, { id: currencyId }), CURRENCY_CODE, '');
            setCurrencySymbol(getCurrencySymbol(false, planCurrencyCode));
        }
    }, [currencyId]);

    return (
        <div className="my-profile-detail">
            {loadingCountries || loadingCurrencies ? (
                <Loader />
            ) : (
                <>
                    <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                        {props.detailsUpdatedMessage && <SuccessMessage message={props.detailsUpdatedMessage} />}

                        <div className="profile-detail-upper">
                            <Field
                                type="avatar"
                                name={requestKeys.PLAN_IMAGE}
                                label={localeLabel(requestKeys.PLAN_IMAGE)}
                                isModalVisible={props.imagePickerVisible}
                                imageName={localeLabel(requestKeys.PLAN_IMAGE)}
                                onSubmit={props.onImageSubmit}
                                hasRemovedKey={requestKeys.HAS_DELETED_IMAGE}
                                reduxChange={props.change}
                                toggleImagePicker={props.toggleImagePicker}
                                hasFeedback
                                component={FormField}
                                validate={[]}
                                imageSourceSizeKey={constants.IMAGE_254x254}
                                imageSourceOriginalKey={constants.IMAGE_ORIGINAL}
                                defaultImage={props.defaultImage}
                                minSize={50}
                            />
                            <ul className="upper-right-detail">
                                <li style={{ maxWidth: '100%' }}>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Field
                                                type="text"
                                                name={requestKeys.PLAN_TYPE}
                                                label={localeLabel(requestKeys.PLAN_TYPE)}
                                                hasFeedback
                                                component={FormField}
                                                validate={[formValidations.required]}
                                                info="This will be shown as plan name to users"
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Field
                                                type="text"
                                                name={requestKeys.PLAN_LABEL}
                                                label={localeLabel(requestKeys.PLAN_LABEL)}
                                                hasFeedback
                                                component={FormField}
                                                validate={[formValidations.required]}
                                                info="This will be shown as plan name in admin plan listing page"
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Field
                                                type="text"
                                                name={requestKeys.PLAN_SUBTITLE}
                                                label={localeLabel(requestKeys.PLAN_SUBTITLE)}
                                                hasFeedback
                                                component={FormField}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Field
                                                type="textarea"
                                                name={requestKeys.PLAN_DESCRIPTION}
                                                label={localeLabel(requestKeys.PLAN_DESCRIPTION)}
                                                hasFeedback
                                                component={FormField}
                                            />
                                        </Col>
                                    </Row>
                                </li>
                            </ul>
                        </div>
                        <hr />
                        <section className="profile-detail-lower">
                            <div className="details-wrapper">
                                <Row gutter={24}>
                                    <Col span={4}>
                                        <Field
                                            type="select"
                                            name={requestKeys.PLAN_COUNTRY_ID}
                                            label={localeLabel(requestKeys.PLAN_COUNTRY_ID)}
                                            hasFeedback
                                            options={countries.map(c => ({ name: c.name, value: c.id }))}
                                            className="form-control"
                                            component={FormField}
                                            validate={[formValidations.required]}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            type="select"
                                            name={requestKeys.PLAN_CURRENCY_ID}
                                            label={localeLabel(requestKeys.PLAN_CURRENCY_ID)}
                                            hasFeedback
                                            options={currencies.map(c => ({ name: c.name, value: c.id }))}
                                            className="form-control"
                                            component={FormField}
                                            validate={[formValidations.required]}
                                            required
                                            onChange={value => setCurrencyId(value)}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            type="switch"
                                            name={requestKeys.PLAN_IS_GLOBAL}
                                            label={localeLabel(requestKeys.PLAN_IS_GLOBAL)}
                                            className="form-control"
                                            component={FormField}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            addonBefore={currencySymbol}
                                            name={requestKeys.PLAN_COST}
                                            label={localeLabel(requestKeys.PLAN_COST)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={1}
                                            max={2147483647}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            addonBefore={currencySymbol}
                                            name={requestKeys.PLAN_YEARLY_COST}
                                            label={localeLabel(requestKeys.PLAN_YEARLY_COST)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={1}
                                            max={2147483647}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="text"
                                            name={requestKeys.PLAN_VALIDITY}
                                            label={localeLabel(requestKeys.PLAN_VALIDITY)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={1}
                                            max={2147483647}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            name={requestKeys.PLAN_GRACE_PERIOD}
                                            label={localeLabel(requestKeys.PLAN_GRACE_PERIOD)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={0}
                                            max={2147483647}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            name={requestKeys.PLAN_FREE_CALL}
                                            label={localeLabel(requestKeys.PLAN_FREE_CALL)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={-1}
                                            max={2147483647}
                                            info={t('appCommonMessages.-1MeansUnlimited', {
                                                key: localeLabel(requestKeys.PLAN_FREE_CALL),
                                            })}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            name={requestKeys.PLAN_FREE_EMAIL}
                                            label={localeLabel(requestKeys.PLAN_FREE_EMAIL)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={-1}
                                            max={2147483647}
                                            info={t('appCommonMessages.-1MeansUnlimited', {
                                                key: localeLabel(requestKeys.PLAN_FREE_EMAIL),
                                            })}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            name={requestKeys.PLAN_FREE_SMS}
                                            label={localeLabel(requestKeys.PLAN_FREE_SMS)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={-1}
                                            max={2147483647}
                                            info={t('appCommonMessages.-1MeansUnlimited', {
                                                key: localeLabel(requestKeys.PLAN_FREE_SMS),
                                            })}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            name={requestKeys.PLAN_FREE_WHATSAPP}
                                            label={localeLabel(requestKeys.PLAN_FREE_WHATSAPP)}
                                            parse={numberParse}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={-1}
                                            max={2147483647}
                                            info={t('appCommonMessages.-1MeansUnlimited', {
                                                key: localeLabel(requestKeys.PLAN_FREE_WHATSAPP),
                                            })}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            name={requestKeys.PLAN_MAX_ORGANISATIONS}
                                            label={localeLabel(requestKeys.PLAN_MAX_ORGANISATIONS)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={-1}
                                            max={2147483647}
                                            info={t('appCommonMessages.-1MeansUnlimited', {
                                                key: localeLabel(requestKeys.PLAN_MAX_ORGANISATIONS),
                                            })}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            name={requestKeys.PLAN_MAX_CONTACT_TYPES}
                                            label={localeLabel(requestKeys.PLAN_MAX_CONTACT_TYPES)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={-1}
                                            max={2147483647}
                                            info={t('appCommonMessages.-1MeansUnlimited', {
                                                key: localeLabel(requestKeys.PLAN_MAX_CONTACT_TYPES),
                                            })}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            name={requestKeys.PLAN_MAX_PAYMENT_ARRANGEMENT_PLANS}
                                            label={localeLabel(requestKeys.PLAN_MAX_PAYMENT_ARRANGEMENT_PLANS)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={-1}
                                            max={2147483647}
                                            info={t('appCommonMessages.-1MeansUnlimited', {
                                                key: localeLabel(requestKeys.PLAN_MAX_PAYMENT_ARRANGEMENT_PLANS),
                                            })}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            name={requestKeys.PLAN_MAX_PAYMENT_EXTENSION_PLANS}
                                            label={localeLabel(requestKeys.PLAN_MAX_PAYMENT_EXTENSION_PLANS)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={-1}
                                            max={2147483647}
                                            info={t('appCommonMessages.-1MeansUnlimited', {
                                                key: localeLabel(requestKeys.PLAN_MAX_PAYMENT_EXTENSION_PLANS),
                                            })}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            name={requestKeys.PLAN_MAX_PAYMENT_ARRANGEMENTS}
                                            label={localeLabel(requestKeys.PLAN_MAX_PAYMENT_ARRANGEMENTS)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={-1}
                                            max={2147483647}
                                            info={t('appCommonMessages.-1MeansUnlimited', {
                                                key: localeLabel(requestKeys.PLAN_MAX_PAYMENT_ARRANGEMENTS),
                                            })}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            name={requestKeys.PLAN_MAX_PAYMENT_EXTENSIONS}
                                            label={localeLabel(requestKeys.PLAN_MAX_PAYMENT_EXTENSIONS)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={-1}
                                            max={2147483647}
                                            info={t('appCommonMessages.-1MeansUnlimited', {
                                                key: localeLabel(requestKeys.PLAN_MAX_PAYMENT_EXTENSIONS),
                                            })}
                                            required
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            step="any"
                                            type="number"
                                            name={requestKeys.PLAN_MAX_USERS}
                                            label={localeLabel(requestKeys.PLAN_MAX_USERS)}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.number]}
                                            min={-1}
                                            max={2147483647}
                                            info={t('appCommonMessages.-1MeansUnlimited', {
                                                key: localeLabel(requestKeys.PLAN_MAX_USERS),
                                            })}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={4}>
                                        <Field
                                            type="switch"
                                            name={requestKeys.PLAN_ALLOW_INTERNATIONAL_CALLS}
                                            label={localeLabel(requestKeys.PLAN_ALLOW_INTERNATIONAL_CALLS)}
                                            className="form-control"
                                            component={FormField}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            type="switch"
                                            name={requestKeys.PLAN_ALLOW_INTERNATIONAL_SMS}
                                            label={localeLabel(requestKeys.PLAN_ALLOW_INTERNATIONAL_SMS)}
                                            className="form-group"
                                            component={FormField}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            type="switch"
                                            name={requestKeys.PLAN_ALLOW_ZENDESK_INTEGRATION}
                                            label={localeLabel(requestKeys.PLAN_ALLOW_ZENDESK_INTEGRATION)}
                                            className="form-group"
                                            component={FormField}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            type="switch"
                                            name={requestKeys.PLAN_ALLOW_ZENDESK_INTEGRATION}
                                            label={localeLabel(requestKeys.PLAN_ALLOW_ZENDESK_INTEGRATION)}
                                            className="form-group"
                                            component={FormField}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            type="switch"
                                            name={requestKeys.PLAN_CUSTOM_BRANDING}
                                            label={localeLabel(requestKeys.PLAN_CUSTOM_BRANDING)}
                                            className="form-group"
                                            component={FormField}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={4}>
                                        <Field
                                            type="switch"
                                            name={requestKeys.IS_CUSTOM}
                                            label={localeLabel(requestKeys.IS_CUSTOM)}
                                            className="form-group"
                                            component={FormField}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            type="switch"
                                            name={requestKeys.PLAN_IS_MOST_POPULAR}
                                            label={localeLabel(requestKeys.PLAN_IS_MOST_POPULAR)}
                                            className="form-group"
                                            component={FormField}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Field
                                            type="switch"
                                            name={requestKeys.PLAN_CAN_PURCHASE}
                                            label={localeLabel(requestKeys.PLAN_CAN_PURCHASE)}
                                            className="form-control"
                                            component={FormField}
                                        />
                                    </Col>
                                </Row>
                            </div>
                            <PermissionManager
                                permissionSet={allPermissions}
                                allowed={allowed}
                                updateAllowed={setAllowed}
                            />
                        </section>
                    </Form>
                    <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                        <Form.Item
                            className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}
                        >
                            {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                            <Button
                                htmlType="submit"
                                big
                                filled
                                loading={isUpdating}
                                submittingButtonLabel={t(
                                    `customerMessages.organisationForm.form.submit.buttonSubmittingLabel.${
                                        isAdd ? 'add' : 'edit'
                                    }`
                                )}
                                className="register-btn"
                            >
                                {t(
                                    `customerMessages.organisationForm.form.submit.buttonLabel.${
                                        isAdd ? 'add' : 'edit'
                                    }`
                                )}
                            </Button>
                        </Form.Item>
                        <div className="clearfix" />
                    </Form>
                </>
            )}
        </div>
    );
});

/**
 * Proptypes
 */
AddEditPlanForm.propTypes = {
    defaultImage: PropTypes.string,
    detailsUpdatedMessage: PropTypes.string,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    imagePickerVisible: PropTypes.bool,
    isAdd: PropTypes.bool,
    onImageSubmit: PropTypes.func,
    t: PropTypes.func,
    toggleImagePicker: PropTypes.func,
};

export default AddEditPlanForm;

/**
 * useInvoiceLinkGenerator
 *
 * Returns a helper/utility function that'll generate the URL for invoice details page based on
 * the current user role and organisation
 *
 * @version 1.0
 * @author Aravind JR <aravind@paycepaid.com.au>
 */

import useAccountId from './useAccountId';
import useOrganisationId from './useOrganisationId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useIsDebtor from 'includes/hooks/useIsDebtor';

/**
 * useInvoiceLinkGenerator
 *
 * Returns a helper/utility function that'll generate the URL for invoice details page based on
 * the current user role and organisation
 *
 * @returns {Function} {(contactId: string, invoiceId: string, isAddEditMode: boolean, isRepeatingInvoice: boolean) => string}
 */
const useInvoiceLinkGenerator = () => {
    const organisationId = useOrganisationId();
    const accountId = useAccountId();
    const isAdminOrManager = useIsAdminOrManager();
    const isDebtor = useIsDebtor();

    return (contactId, invoiceId, isAddEditMode = false, isRepeatingInvoice = false) => {
        if (isAddEditMode) {
            const invoiceSubPath = `invoices/${isRepeatingInvoice ? 'recurring' : 'invoice'}`;
            return isAdminOrManager
                ? `/admin/accounts/organisation/${invoiceSubPath}/${accountId}/${organisationId}/${contactId}/${invoiceId}/edit`
                : `/${invoiceSubPath}/${contactId}/${invoiceId}/edit`;
        } else {
            if (isDebtor) {
                return `/debtor/invoice/${contactId}/${invoiceId}`;
            } else if (isAdminOrManager) {
                return `/admin/accounts/organisation/invoices/invoice/${accountId}/${organisationId}/${contactId}/${invoiceId}`;
            } else {
                return `/invoices/invoice/${contactId}/${invoiceId}`;
            }
        }
    };
};

export default useInvoiceLinkGenerator;

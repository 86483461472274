/**
 * Communication Settings Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { React, useState } from '../../includes/exports/react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Row, Col } from 'antd';
import { withTranslation } from 'react-i18next';
import get from 'lodash/get';
import FormField from '../shared/FormField';
import * as requestKeys from '../../includes/constants/keys/request';
import * as formValidations from '../../includes/utils/form';
import { useSelector } from 'react-redux';

const smsSenderIdMaxValidation = formValidations.maxLength(10);

const CommunicationSettings = props => {
    const { t } = props;
    const [emailType, setEmailType] = useState(get(props.initialValues, 'communication_settings.email_setting_type'));
    const [allowCustomEmailSettings, setAllowCustomEmailSettings] = useState(
        get(props.initialValues, 'allow_custom_email_settings')
    );
    const [allowCustomIvrSettings, setAllowCustomIvrSettings] = useState(
        get(props.initialValues, 'allow_custom_ivr_settings')
    );
    const [allowCustomSmsSettings, setAllowCustomSmsSettings] = useState(
        get(props.initialValues, 'allow_custom_sms_settings')
    );
    const [allowCustomWhatsAppSettings, setAllowCustomWhatsAppSettings] = useState(
        get(props.initialValues, 'allow_custom_whatsapp_settings')
    );

    const isAdmin = useSelector(state => state.account.isAdmin);
    const defaultSettings = useSelector(state => state.settings);
    const defaultEmailDomain = get(defaultSettings, 'siteSettings.default_email_domain', '');

    // Get localised label
    const localeLabel = field => t(`customerMessages.profile.businessInformation.form.input.label.${field}`);

    return (
        <>
            {isAdmin && props.showAllowSettings ? (
                <>
                    <hr className="mrgn-tp-25" />
                    <Row gutter={16}>
                        <Col span={8}>
                            <Field
                                type="switch"
                                name={requestKeys.ALLOW_CUSTOM_EMAIL_SETTINGS}
                                label={localeLabel(requestKeys.ALLOW_CUSTOM_EMAIL_SETTINGS)}
                                hasFeedback
                                className="form-control"
                                component={FormField}
                                onChange={value => {
                                    setAllowCustomEmailSettings(value);
                                }}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                ''
            )}
            {allowCustomEmailSettings ? (
                <>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Field
                                type="select"
                                name={requestKeys.EMAIL_SETTING_TYPE}
                                label={localeLabel(requestKeys.EMAIL_SETTING_TYPE)}
                                options={[
                                    { name: 'Sendgrid API', value: 'sendgrid_api' },
                                    { name: 'SMTP', value: 'smtp' },
                                ]}
                                className="form-control"
                                component={FormField}
                                onChange={id => {
                                    setEmailType(id);
                                }}
                            />
                        </Col>
                        {emailType && (
                            <Col span={12}>
                                {emailType === 'sendgrid_api' ? (
                                    <Field
                                        type="text"
                                        name={requestKeys.EMAIL_PRIVATE_KEY}
                                        label={localeLabel(requestKeys.EMAIL_PRIVATE_KEY)}
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        required
                                    />
                                ) : (
                                    <Field
                                        type="switch"
                                        name={requestKeys.EMAIL_SMTP_AUTHENTICATION}
                                        label={localeLabel(requestKeys.EMAIL_SMTP_AUTHENTICATION)}
                                        className="form-control"
                                        component={FormField}
                                    />
                                )}
                            </Col>
                        )}
                    </Row>
                    {emailType && emailType === 'smtp' && (
                        <>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Field
                                        type="text"
                                        name={requestKeys.EMAIL_SMTP_HOST}
                                        label={localeLabel(requestKeys.EMAIL_SMTP_HOST)}
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        required
                                    />
                                </Col>
                                <Col span={12}>
                                    <Field
                                        type="number"
                                        step="1"
                                        name={requestKeys.EMAIL_SMTP_PORT}
                                        label={localeLabel(requestKeys.EMAIL_SMTP_PORT)}
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Field
                                        type="text"
                                        name={requestKeys.EMAIL_SMTP_USERNAME}
                                        label={localeLabel(requestKeys.EMAIL_SMTP_USERNAME)}
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        required
                                    />
                                </Col>
                                <Col span={12}>
                                    <Field
                                        type="password"
                                        autocomplete="off"
                                        name={requestKeys.EMAIL_SMTP_PASSWORD}
                                        label={localeLabel(requestKeys.EMAIL_SMTP_PASSWORD)}
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        info="The password is stored in plain text"
                                        required
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                    <Row gutter={16}>
                        <Col span={12}>
                            <Field
                                type="text"
                                name={requestKeys.EMAIL_FROM_NAME}
                                label={localeLabel(requestKeys.EMAIL_FROM_NAME)}
                                className="form-control"
                                component={FormField}
                                info={props.t(`customerMessages.profile.businessInformation.form.input.info.fromName`)}
                            />
                        </Col>
                        <Col span={12}>
                            <Field
                                type="text"
                                name={requestKeys.EMAIL_FROM}
                                label={localeLabel(requestKeys.EMAIL_FROM)}
                                className="form-control"
                                component={FormField}
                                validate={[formValidations.email]}
                                info={
                                    props.t(`customerMessages.organisationSettings.form.input.info.fromEmail.info1`) +
                                    (emailType &&
                                        ' ' +
                                            props.t(
                                                `customerMessages.profile.businessInformation.form.input.info.fromEmail.info2`
                                            ))
                                }
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <p style={{ marginTop: 20, marginBottom: 20, color: 'grey', fontWeight: 'bold' }}>
                                {t(`customerMessages.profile.businessInformation.form.input.info.message`)}
                            </p>
                        </Col>
                    </Row>
                    {isAdmin && <hr className="mrgn-tp-25" />}
                </>
            ) : props.showDefaultEmailSettings ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.EMAIL_FROM_NAME}
                            label={localeLabel(requestKeys.EMAIL_FROM_NAME)}
                            className="form-control"
                            component={FormField}
                            info={props.t(`customerMessages.profile.businessInformation.form.input.info.fromName`)}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.EMAIL_FROM_USERNAME}
                            label={localeLabel(requestKeys.EMAIL_FROM_USERNAME)}
                            className="form-control"
                            component={FormField}
                            addonAfter={!allowCustomEmailSettings && `@${defaultEmailDomain}`}
                        />
                    </Col>
                </Row>
            ) : (
                ''
            )}
            {isAdmin ? (
                <>
                    {props.showAllowSettings ? (
                        <Row gutter={16}>
                            <Col span={8}>
                                <Field
                                    type="switch"
                                    name={requestKeys.ALLOW_CUSTOM_IVR_SETTINGS}
                                    label={localeLabel(requestKeys.ALLOW_CUSTOM_IVR_SETTINGS)}
                                    hasFeedback
                                    className="form-control"
                                    component={FormField}
                                    onChange={value => {
                                        setAllowCustomIvrSettings(value);
                                    }}
                                />
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    {allowCustomIvrSettings ? (
                        <>
                            {!props.showAllowSettings && (
                                <div style={{ fontSize: 18, marginTop: 15, marginBottom: 15 }}>
                                    {localeLabel(requestKeys.IVR_SETTINGS)}
                                </div>
                            )}
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.IVR_FROM_NUMBER}
                                        label={localeLabel(requestKeys.IVR_FROM_NUMBER)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        required
                                    />
                                </Col>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.IVR_FROM_USERNAME}
                                        label={localeLabel(requestKeys.IVR_FROM_USERNAME)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.IVR_PRIVATE_KEY}
                                        label={localeLabel(requestKeys.IVR_PRIVATE_KEY)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        info="How to create API Key: https://www.twilio.com/blog/better-twilio-authentication-csharp-twilio-api-keys"
                                    />
                                </Col>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.IVR_SECRET_KEY}
                                        label={localeLabel(requestKeys.IVR_SECRET_KEY)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                    />
                                </Col>
                            </Row>
                            <hr className="mrgn-tp-25" />
                        </>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                ''
            )}
            {isAdmin ? (
                <>
                    {props.showAllowSettings ? (
                        <Row gutter={16}>
                            <Col span={8}>
                                <Field
                                    type="switch"
                                    name={requestKeys.ALLOW_CUSTOM_SMS_SETTINGS}
                                    label={localeLabel(requestKeys.ALLOW_CUSTOM_SMS_SETTINGS)}
                                    hasFeedback
                                    className="form-control"
                                    component={FormField}
                                    onChange={value => {
                                        setAllowCustomSmsSettings(value);
                                    }}
                                />
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    {allowCustomSmsSettings ? (
                        <>
                            {!props.showAllowSettings && (
                                <div style={{ fontSize: 18, marginTop: 15, marginBottom: 15 }}>
                                    {localeLabel(requestKeys.SMS_SETTINGS)}
                                </div>
                            )}
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.SMS_SENDER_ID}
                                        label={localeLabel(requestKeys.SMS_SENDER_ID)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        validate={[smsSenderIdMaxValidation, formValidations.alphaNumeric]}
                                        maxLength={10}
                                        info="Make sure you have registered this sender id against the twilio number, otherwise SMS won't be sent."
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.SMS_FROM_NUMBER}
                                        label={localeLabel(requestKeys.SMS_FROM_NUMBER)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        required
                                    />
                                </Col>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.SMS_FROM_USERNAME}
                                        label={localeLabel(requestKeys.SMS_FROM_USERNAME)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.SMS_PRIVATE_KEY}
                                        label={localeLabel(requestKeys.SMS_PRIVATE_KEY)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        info="How to create API Key: https://www.twilio.com/blog/better-twilio-authentication-csharp-twilio-api-keys"
                                    />
                                </Col>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.SMS_SECRET_KEY}
                                        label={localeLabel(requestKeys.SMS_SECRET_KEY)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                    />
                                </Col>
                            </Row>
                            <hr className="mrgn-tp-25" />
                        </>
                    ) : (
                        ''
                    )}
                </>
            ) : props.showDefaultSmsSettings ? (
                <Row gutter={16}>
                    <Col span={8}>
                        <Field
                            type="text"
                            name={requestKeys.SMS_SENDER_ID}
                            label={localeLabel(requestKeys.SMS_SENDER_ID)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[smsSenderIdMaxValidation, formValidations.alphaNumeric]}
                            maxLength={10}
                            disabled
                            info="Sender ID is no longer available by default. If you want to use sender id for masking your SMS, please contact administrator"
                        />
                    </Col>
                </Row>
            ) : (
                ''
            )}
            {isAdmin ? (
                <>
                    {props.showAllowSettings ? (
                        <Row gutter={16}>
                            <Col span={8}>
                                <Field
                                    type="switch"
                                    name={requestKeys.ALLOW_CUSTOM_WHATSAPP_SETTINGS}
                                    label={localeLabel(requestKeys.ALLOW_CUSTOM_WHATSAPP_SETTINGS)}
                                    hasFeedback
                                    className="form-control"
                                    component={FormField}
                                    onChange={value => {
                                        setAllowCustomWhatsAppSettings(value);
                                    }}
                                />
                            </Col>
                        </Row>
                    ) : (
                        ''
                    )}
                    {allowCustomWhatsAppSettings ? (
                        <>
                            {!props.showAllowSettings && (
                                <div style={{ fontSize: 18, marginTop: 15, marginBottom: 15 }}>
                                    {localeLabel(requestKeys.WHATSAPP_SETTINGS)}
                                </div>
                            )}
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.WHATSAPP_FROM_IDENTIFIER}
                                        label={localeLabel(requestKeys.WHATSAPP_FROM_IDENTIFIER)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        validate={[smsSenderIdMaxValidation, formValidations.alphaNumeric]}
                                        maxLength={10}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.WHATSAPP_FROM_NAME}
                                        label={localeLabel(requestKeys.WHATSAPP_FROM_NAME)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        required
                                    />
                                </Col>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.WHATSAPP_FROM_USERNAME}
                                        label={localeLabel(requestKeys.WHATSAPP_FROM_USERNAME)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.WHATSAPP_PRIVATE_KEY}
                                        label={localeLabel(requestKeys.WHATSAPP_PRIVATE_KEY)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        // info="How to create API Key: https://www.twilio.com/blog/better-twilio-authentication-csharp-twilio-api-keys"
                                    />
                                </Col>
                                <Col span={8}>
                                    <Field
                                        type="text"
                                        name={requestKeys.WHATSAPP_SECRET_KEY}
                                        label={localeLabel(requestKeys.WHATSAPP_SECRET_KEY)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                    />
                                </Col>
                            </Row>
                            <hr className="mrgn-tp-25" />
                        </>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                ''
            )}
        </>
    );
};

//proptypes
CommunicationSettings.propTypes = {
    initialValues: PropTypes.object,
    isAdmin: PropTypes.bool,
    showAllowSettings: PropTypes.bool,
    showDefaultEmailSettings: PropTypes.bool,
    showDefaultSmsSettings: PropTypes.bool,
    t: PropTypes.func,
};

/**
 * Default Props
 */
CommunicationSettings.defaultProps = {
    showAllowSettings: false,
};

export default withTranslation()(CommunicationSettings);

/**
 * Campaign notification add edit form
 *
 */

// import required modules
import React, { useCallback, useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { find, get, isEmpty } from 'lodash';
import { Form, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    getCommunicationTemplateDetails,
    getCommunicationTemplatePreview,
} from 'includes/redux/actions/shared/communication';
import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import Button from 'components/shared/Button';
import EmailFields from 'components/shared/Templates/Email/EmailFields';
import FormField from 'components/shared/FormField';
import IvrFields from 'components/shared/Templates/Ivr/IvrFields';
import Preview from 'components/shared/Templates/Preview';
import PropTypes from 'prop-types';
import Skeleton from 'components/Skeleton';
import SmsFields from 'components/shared/Templates/Sms/SmsFields';
import useAccountId from 'includes/hooks/useAccountId';
import useCampaignNotificationDays from 'includes/hooks/useCampaignNotificationDays';
import useCommunicationTemplates from 'includes/hooks/useCommunicationTemplates';
import useCommunicationTemplateTypes from 'includes/hooks/useCommunicationTemplateTypes';
import useCommunicationTypes from 'includes/hooks/useCommunicationTypes';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useCampaignNotificationDayDescription from 'includes/hooks/useCampaignNotificationDayDescription';
import WhatsAppFields from 'components/shared/Templates/WhatsApp/WhatsAppFields';

const CampaignNotificationAddEditForm = props => {
    const [message, setMessage] = useState(get(props, 'initialValues.message', ''));
    const [openPreview, setOpenPreview] = useState(false);
    const [selectedNotificationTypeId, setSelectedNotificationTypeId] = useState('');
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [selectedTemplateTypeId, setSelectedTemplateTypeId] = useState('');
    const { change } = props;
    const { data: communicationTemplates, isLoading: loadingCommunicationTemplates } = useCommunicationTemplates();
    const { data: communicationTypes, isLoading: loadingCommunicationTypes } = useCommunicationTypes();
    const { t } = useTranslation();
    const {
        data: campaignNotificationDays,
        isLoading: loadingCampaignNotificationDays,
    } = useCampaignNotificationDays();
    const getCampaignNotificationDayDescription = useCampaignNotificationDayDescription();
    const {
        data: communicationTemplateTypes,
        isLoading: loadingCommunicationTemplateTypes,
    } = useCommunicationTemplateTypes();
    const accountId = useAccountId();
    const communicationTemplateDetails = useSelector(state => state.communication.templateDetails);
    const dispatch = useDispatch();
    const isLoadingCommunicationTemplateDetails = useSelector(state => state.communication.isLoadingDetails);
    const isLoadingPreview = useSelector(state => state.communication.isLoadingPreview);
    const loadingCampaignNotificationAddEdit = useSelector(state => state.campaigns.loadingCampaignNotificationAddEdit);
    const notificationTypeId = get(props, `initialValues.${requestKeys.NOTIFICATION_TYPE_ID}`, '');
    const organisationId = useOrganisationId();
    const templateId = get(props, `initialValues.${requestKeys.COMMUNICATION_TEMPLATE_ID}`, '');
    const templatePreviewContent = useSelector(state => state.communication.preview);
    const templateTypeId = get(props, `initialValues.${requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID}`, '');

    /**
     * Use effect to set the default values
     */
    useEffect(() => {
        notificationTypeId && setSelectedNotificationTypeId(notificationTypeId);
        templateTypeId && setSelectedTemplateTypeId(templateTypeId);
        templateId && setSelectedTemplateId(templateId);
    }, [notificationTypeId, templateId, templateTypeId]);

    /**
     * Change the current values on template change
     */
    const changeCurrentValues = useCallback(() => {
        change(requestKeys.NAME, get(communicationTemplateDetails, 'name', ''));
        change(requestKeys.SUBJECT, get(communicationTemplateDetails, 'subject', ''));
        change(requestKeys.MESSAGE, get(communicationTemplateDetails, 'message', ''));
        setMessage(get(communicationTemplateDetails, 'message', ''));
        change(requestKeys.TEMPLATE_TYPE, get(communicationTemplateDetails, 'template_type', ''));
    }, [communicationTemplateDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use effect to change the current template values on template change
     */
    useEffect(() => {
        changeCurrentValues();
    }, [changeCurrentValues]);

    useEffect(() => {
        if (selectedTemplateId) {
            dispatch(getCommunicationTemplateDetails(organisationId, selectedTemplateId));
        } else {
            change(requestKeys.SUBJECT, '');
            change(requestKeys.MESSAGE, '');
            setMessage('');
        }
    }, [selectedTemplateId]);

    /**
     * Locale the form label
     *
     * @param {string}  field Field for which locale is needed
     *
     * @returns {string} Localised content
     */
    const localeLabel = field => t(`messages.campaigns.campaignNotifications.addEdit.form.input.label.${field}`);

    /**
     * Show the template preview
     */
    const showPreview = () => {
        if (message) {
            setOpenPreview(true);
            dispatch(
                getCommunicationTemplatePreview({
                    account_id: accountId,
                    organisation_id: organisationId,
                    body: message,
                    type: get(find(communicationTypes, { id: selectedNotificationTypeId }), 'slug', ''),
                    scheduler_id: get(props, ['initialValues', 'id'], ''),
                })
            );
        }
    };

    /**
     * Get the form fields by notification type
     */
    const getFormFields = () => {
        if (selectedNotificationTypeId && selectedTemplateTypeId && selectedTemplateId) {
            const type = get(find(communicationTypes, { id: selectedNotificationTypeId }), 'slug', '');
            switch (type) {
                case 'email':
                    return (
                        <EmailFields
                            {...props}
                            setMessage={setMessage}
                            message={message}
                            showPreview={showPreview}
                            disable
                            disableBody
                            disableSubject
                            showCC={false}
                        />
                    );
                case 'ivr':
                    return (
                        <IvrFields
                            {...props}
                            setMessage={setMessage}
                            message={message}
                            showPreview={showPreview}
                            disable
                            disableBody
                        />
                    );
                case 'sms':
                    return (
                        <SmsFields
                            {...props}
                            setMessage={setMessage}
                            message={message}
                            showPreview={showPreview}
                            disable
                            disableBody
                        />
                    );
                case 'whatsapp':
                    return (
                        <WhatsAppFields
                            {...props}
                            setMessage={setMessage}
                            message={message}
                            showPreview={showPreview}
                            disable
                            disableBody
                        />
                    );
                default:
                    return null;
            }
        }
    };

    /**
     * Get the template types that has communication templates
     *
     * @returns {Array}  Template Types
     */
    const getTemplateTypesWithTemplates = () => {
        const templateTypeFilterValue =
            props.campaignType === 1
                ? constants.COMMUNICATION_TEMPLATE_TYPE_INVOICE_TEMPLATE_TYPE
                : constants.COMMUNICATION_TEMPLATE_TYPE_PAYMENT_ARRANGEMENT_TEMPLATE_TYPE;
        return communicationTemplateTypes
            .filter(x => {
                const templatesByTemplateType = get(communicationTemplates, [selectedNotificationTypeId, x.id]);
                return (
                    !isEmpty(templatesByTemplateType) &&
                    x.slug !== 'on-demand-campaign' &&
                    x.template_type === templateTypeFilterValue
                );
            })
            .map(x => {
                return {
                    value: x.id,
                    name: x.type,
                };
            });
    };

    return (
        <div>
            {loadingCommunicationTemplateTypes ||
            loadingCommunicationTemplates ||
            loadingCommunicationTypes ||
            loadingCampaignNotificationDays ? (
                <Skeleton paragraph={10} />
            ) : (
                <>
                    <Form layout="vertical" className="" onSubmit={props.handleSubmit}>
                        <Row gutter={16} className="form-first-row">
                            <Col span={12}>
                                <Field
                                    type="select"
                                    className="form-control"
                                    name={requestKeys.DAY_ID}
                                    label={localeLabel(requestKeys.DAY)}
                                    options={campaignNotificationDays.map(x => ({
                                        value: x.id,
                                        name: getCampaignNotificationDayDescription(
                                            x,
                                            '',
                                            props.getAdditionalDayDescriptionText(x.day)
                                        ),
                                    }))}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    required
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="select"
                                    className="form-control"
                                    name={requestKeys.NOTIFICATION_TYPE_ID}
                                    label={localeLabel(requestKeys.NOTIFICATION_TYPE)}
                                    onChange={value => {
                                        setSelectedNotificationTypeId(value);
                                        setSelectedTemplateTypeId('');
                                        setSelectedTemplateId('');
                                        change(requestKeys.COMMUNICATION_TEMPLATE_ID, '');
                                        change(requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID, '');
                                        change(requestKeys.SUBJECT, '');
                                        change(requestKeys.MESSAGE, '');
                                        setMessage('');
                                    }}
                                    options={communicationTypes.map(x => ({ value: x.id, name: x.type }))}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            {selectedNotificationTypeId && (
                                <Col span={12}>
                                    <Field
                                        type="select"
                                        className="form-control"
                                        name={requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID}
                                        label={localeLabel(requestKeys.TEMPLATE_TYPE)}
                                        hasFeedback
                                        options={getTemplateTypesWithTemplates()}
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        onChange={id => {
                                            setSelectedTemplateTypeId(id);
                                            setSelectedTemplateId('');
                                            change(requestKeys.SUBJECT, '');
                                            change(requestKeys.MESSAGE, '');
                                            setMessage('');
                                            change(requestKeys.COMMUNICATION_TEMPLATE_ID, '');
                                        }}
                                        required
                                    />
                                </Col>
                            )}
                            {selectedNotificationTypeId && selectedTemplateTypeId && (
                                <>
                                    <Col span={12}>
                                        <Field
                                            type="select"
                                            className="form-control"
                                            name={requestKeys.COMMUNICATION_TEMPLATE_ID}
                                            label={localeLabel(requestKeys.COMMUNICATION_TEMPLATE)}
                                            options={get(
                                                communicationTemplates,
                                                [selectedNotificationTypeId, selectedTemplateTypeId],
                                                []
                                            ).map(x => ({
                                                value: x.id,
                                                name: x.name,
                                            }))}
                                            onChange={id => {
                                                setSelectedTemplateId(id);
                                                if (id) {
                                                    dispatch(getCommunicationTemplateDetails(organisationId, id));
                                                } else {
                                                    change(requestKeys.SUBJECT, '');
                                                    change(requestKeys.MESSAGE, '');
                                                    setMessage('');
                                                }
                                            }}
                                            hasFeedback
                                            component={FormField}
                                            validate={[formValidations.required]}
                                            required
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                        {selectedNotificationTypeId && selectedTemplateTypeId && selectedTemplateId ? (
                            isLoadingCommunicationTemplateDetails ? (
                                <Skeleton paragraph={4} />
                            ) : (
                                <>
                                    {getFormFields()}
                                    <Row gutter={16} className="form-first-row">
                                        <Col span={12}>
                                            <Field
                                                type="switch"
                                                className="form-control"
                                                name={requestKeys.IS_ACTIVE}
                                                label={localeLabel(requestKeys.IS_ACTIVE)}
                                                hasFeedback
                                                component={FormField}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Form.Item
                                        className={`campaign-btn-wrapper ${
                                            props.error ? ' has-error default-form-app-error' : ''
                                        }`}
                                    >
                                        {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                                        <Button
                                            htmlType="submit"
                                            big
                                            filled
                                            className="green-bg-button"
                                            submittingButtonLabel={t(
                                                `messages.campaigns.campaignNotifications.addEdit.form.submit.buttonSubmittingLabel.${
                                                    props.isAdd ? 'add' : 'edit'
                                                }`
                                            )}
                                            loading={loadingCampaignNotificationAddEdit}
                                        >
                                            {t(
                                                `messages.campaigns.campaignNotifications.addEdit.form.submit.buttonLabel.${
                                                    props.isAdd ? 'add' : 'edit'
                                                }`
                                            )}
                                        </Button>
                                    </Form.Item>
                                </>
                            )
                        ) : null}
                    </Form>
                    <Preview
                        {...props}
                        openPreview={openPreview}
                        setOpenPreview={setOpenPreview}
                        preview={templatePreviewContent}
                        isLoadingPreview={isLoadingPreview}
                    />
                </>
            )}
        </div>
    );
};

/**
 * Proptypes
 */
CampaignNotificationAddEditForm.propTypes = {
    campaignType: PropTypes.number,
    change: PropTypes.func,
    error: PropTypes.string,
    getAdditionalDayDescriptionText: PropTypes.func,
    initialValues: PropTypes.object,
    isAdd: PropTypes.bool,
    handleSubmit: PropTypes.func,
};

export default reduxForm({
    form: constants.CAMPAIGN_NOTIFICATION_FORM_NAME,
})(CampaignNotificationAddEditForm);

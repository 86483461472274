/**
 * Xero Reducer
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import { LOCAL_STORAGE_VALUE_CHANGED } from '../../../../constants/actionTypes';

/**
 * Connect Reducer Function
 * Handles the state changes
 *
 * @param   {object}    state   Current State
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function connectReducer(state = {}, action = {}) {
    switch (action.type) {
        case LOCAL_STORAGE_VALUE_CHANGED:
            return {
                ...state,
                valueChanged: true,
                localStorageChanges: action.localStorageChanges.payload,
            };
        default:
            return state;
    }
}

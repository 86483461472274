/**
 * Bank Account component
 *
 * @since 2.8.0
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';

import './styles.scss';

const { Text, Title } = Typography;

/**
 * Component to render a single bank account
 *
 * @since 2.8.0
 */
export default function BankAccount({ provider, accountName, accountType, balance }) {
    return (
        <section className="bank-account-card">
            <div className="bank-account-card__text-details">
                <Text disabled>{provider}</Text>
                <Title className="bank-account-card__main-detail" level={4}>
                    {accountName}
                </Title>
                <Text disabled>{accountType}</Text>
            </div>
            <div className="bank-account-card__balance-section">
                <Text disabled>Balance</Text>
                <Title className="bank-account-card__main-detail green-text" level={4}>
                    {balance}
                </Title>
            </div>
        </section>
    );
}

BankAccount.propTypes = {
    accountName: PropTypes.string.isRequired,
    accountType: PropTypes.string.isRequired,
    balance: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
};

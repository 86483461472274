/**
 * Tax API class.
 * Handles all api requests related to tax
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import base api handler
import BaseApiHandler from './base/api';

class TaxesApi extends BaseApiHandler {
    /**
     * Add tax
     *
     * @param {object} taxDetails tax details
     * @param {string} organisationId organisation Id. Default ''
     *
     * @returns {object} API Response
     */
    addTax = (taxDetails, organisationId = '') =>
        this.axios.post(
            this.getApiUrl(this._getApiType(organisationId, true), { organisation_id: organisationId }),
            taxDetails
        );

    /**
     * Delete tax
     *
     * @param {string} taxId tax ID
     * @param {string} organisationId organisation Id. Default ''
     *
     * @returns {object} API Response
     */
    deleteTax = (taxId, organisationId = '') =>
        this.axios.delete(
            this.getApiUrl(this._getApiType(organisationId), {
                tax_id: taxId,
                organisation_id: organisationId,
            })
        );

    /**
     * Get tax
     *
     * @param {string} taxId tax ID
     * @param {string} organisationId organisation Id. Default ''
     *
     * @returns {object} API Response
     */
    getTax = (taxId, organisationId = '') =>
        this.axios.get(
            this.getApiUrl(this._getApiType(organisationId), {
                tax_id: taxId,
                organisation_id: organisationId,
            })
        );

    /**
     * Get taxes
     *
     * @param {object} searchParams Search Params
     * @param {string} organisationId organisation Id. Default ''
     *
     * @returns {object} API Response
     */
    getTaxes = (searchParams, organisationId = '') =>
        this.axios.get(this.getApiUrl(this._getApiType(organisationId, true), { organisation_id: organisationId }), {
            params: searchParams,
        });

    /**
     * Get Tax types
     *
     * @returns {object} API Response
     */
    getTaxTypes = () => this.axios.get(this.getApiUrl('TAX_TYPES'));

    /**
     * Update Tax
     *
     * @param {string} taxId tax Id
     * @param {object} taxDetails Tax details
     * @param {string} organisationId organisation Id. Default ''
     *
     * @returns {object} API Response
     */
    updateTax = (taxId, taxDetails, organisationId = '') =>
        this.axios.patch(
            this.getApiUrl(this._getApiType(organisationId), { tax_id: taxId, organisation_id: organisationId }),
            taxDetails
        );

    /**
     * Get the tax api type
     *
     * @param {string} organisationId Organisation Id. Default ''
     * @param {boolean} multiple. Whether to get api type for taxes or tax. Default false
     *
     * @returns {string} apiType API Type
     */
    _getApiType = (organisationId = '', multiple = false) => {
        let apiType = '';
        if (multiple) {
            apiType = organisationId ? 'TAXES_ORGANISATION_CRUD' : 'TAXES_CRUD';
        } else {
            apiType = organisationId ? 'TAX_ORGANISATION_CRUD' : 'TAX_CRUD';
        }
        return apiType;
    };
}

export default new TaxesApi();

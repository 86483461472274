/**
 * Campaign Form
 *
 * @since 2.0.0
 */

// import required modules
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import Button from 'components/shared/Button';
import FormField from 'components/shared/FormField';
import PropTypes from 'prop-types';
import useContactTypes from 'includes/hooks/useContactTypes';

const CampaignForm = props => {
    const { t } = useTranslation();
    const loadingCampaignAddEdit = useSelector(state => state.campaigns.loadingCampaignAddEdit);
    const { data: contactTypes } = useContactTypes();

    /**
     * Get the localized label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @since 2.0.0
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.campaigns.addEdit.form.input.label.${field}`);

    /**
     * Get campaign type options
     *
     * @returns
     */
    const getCampaignTypeOptions = () =>
        props.campaignType === constants.CAMPAIGN_TYPE_REMINDER
            ? [constants.CAMPAIGN_REMINDER_CAMPAIGN_TYPE_OPTION]
            : [constants.CAMPAIGN_BROKEN_CAMPAIGN_TYPE_OPTION];

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.CAMPAIGN_NAME}
                            label={getLocaleLabel(requestKeys.CAMPAIGN_NAME)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="textarea"
                            name={requestKeys.CAMPAIGN_DESCRIPTION}
                            label={getLocaleLabel(requestKeys.CAMPAIGN_DESCRIPTION)}
                            hasFeedback
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="select"
                            name={requestKeys.CAMPAIGN_CAMPAIGN_TYPE}
                            label={getLocaleLabel(requestKeys.CAMPAIGN_CAMPAIGN_TYPE)}
                            options={getCampaignTypeOptions().map(t => ({ name: t.label, value: t.id }))}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                            disableEmptyOption
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="select"
                            mode="multiple"
                            name={requestKeys.CAMPAIGN_CONTACT_TYPE_IDS}
                            label={getLocaleLabel(requestKeys.CAMPAIGN_CONTACT_TYPE_IDS)}
                            options={contactTypes.map(t => ({ name: t.type, value: t.id }))}
                            hasFeedback
                            component={FormField}
                            className={'multiple-select-block'}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="switch"
                            name={requestKeys.IS_DEFAULT}
                            label={getLocaleLabel(requestKeys.IS_DEFAULT)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            info={t(`messages.campaigns.addEdit.form.input.info.is_default`)}
                            infoAsToolTip
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="switch"
                            name={requestKeys.CAMPAIGN_SEND_MULTIPLE_REMINDERS}
                            label={getLocaleLabel(requestKeys.CAMPAIGN_SEND_MULTIPLE_REMINDERS)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            info={t(`messages.campaigns.addEdit.form.input.info.send_multiple_reminders`)}
                            infoAsToolTip
                        />
                    </Col>
                </Row>
            </Form>
            <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={loadingCampaignAddEdit}
                        submittingButtonLabel={t(
                            `messages.campaigns.addEdit.form.submit.buttonSubmittingLabel.${
                                props.isAdd ? 'add' : 'edit'
                            }`
                        )}
                        className="register-btn"
                    >
                        {t(`messages.campaigns.addEdit.form.submit.buttonLabel.${props.isAdd ? 'add' : 'edit'}`)}
                    </Button>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
};

/**
 * Proptypes
 */
CampaignForm.propTypes = {
    campaignType: PropTypes.number,
    error: PropTypes.string,
    initialValues: PropTypes.object,
    isAdd: PropTypes.bool,
    handleSubmit: PropTypes.func,
};

export default reduxForm({
    form: constants.CAMPAIGN_FORM_NAME,
})(CampaignForm);

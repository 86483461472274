/**
 * My Profile Component
 * Renders the my profile
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modoules
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';

// import Button from '../Button';
import * as responseKeys from '../../../includes/constants/keys/response';
import * as userActions from '../../../includes/redux/actions/shared/user';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import useRedirectToAuthPortalRoute from 'includes/hooks/useRedirectToAuthPortalRoute';
import RedirectingPageLoaderSection from 'components/RedirectingPageLoaderSection';

/**
 * My Profile
 * Renders the my profile form
 */
const MyProfile = props => {
    useRedirectToAuthPortalRoute('my-profile');

    return (
        <div className="home-content-wrapper my-profile-detail">
            <h2 className="page-title">{props.t('sharedMessages.editUser.title')}</h2>
            <RedirectingPageLoaderSection />
        </div>
    );
};

/**
 * Proptypes
 */
MyProfile.propTypes = {
    defaultUserAvatar: PropTypes.string,
    error: PropTypes.any,
    isResendingEmail: PropTypes.bool,
    isResendingSms: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    resendEmail: PropTypes.bool,
    resendSms: PropTypes.bool,
    hasError: PropTypes.bool,
    message: PropTypes.string,
    resendVerificationEmail: PropTypes.func,
    resendVerificationSms: PropTypes.func,
    resetUserMessage: PropTypes.func,
    t: PropTypes.func,
    userDetails: PropTypes.object,
    verifyPhoneNumber: PropTypes.func,
    isAdmin: PropTypes.bool,
};

export default connect(
    state => ({
        ...state.user,
        defaultUserAvatar: get(state.settings.siteSettings, responseKeys.DEFAULT_USER_AVATAR_254x254),
    }),
    {
        ...userActions,
    }
)(withTranslation()(MyProfile));

/* eslint-disable react/jsx-key */
/**
 * Admin Sidebar Component
 * Handles the admin sidebar view and functions to loading sidebar
 * This component is a shared component which can be used in different modules
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

import { React, useEffect, useState, Link, connect, useMemo } from '../../../../includes/exports/react';
import { Menu } from 'antd';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { toggleSidebar } from '../../../../includes/redux/actions/admin/settings';
import * as responseKeys from '../../../../includes/constants/keys/response';
import { isMobile } from '../../../../includes/utils';
import { useWindowWidth } from '@react-hook/window-size';
import './style.scss';

const { SubMenu } = Menu;

const AdminSidebar = props => {
    const { t, userDetails } = props;
    const width = useWindowWidth();

    const userAvatar = useMemo(
        () =>
            get(userDetails, responseKeys.USER_AVATAR_45X45) ||
            get(userDetails, responseKeys.USER_AVATAR_ORIGINAL) ||
            require('../../../../assets/images/user-avatar.svg'),
        [userDetails]
    );

    const menuItemSlugs = [
        {
            slug: 'dashboard',
            subMenu: [],
            path: 'dashboard',
        },
        {
            slug: 'accounts',
            subMenu: [],
            path: 'accounts',
        },
        {
            slug: 'subscriptions',
            subMenu: [],
            path: 'subscriptions',
        },
        {
            slug: 'coupons',
            subMenu: [],
            path: 'coupons',
        },
        {
            slug: 'users',
            subMenu: [],
            path: 'users',
        },
        {
            slug: 'plans',
            subMenu: ['plans', 'quotes'],
            path: 'plans',
        },
        {
            slug: 'audit-logs',
            subMenu: [],
            path: 'audit-logs',
        },
        {
            slug: 'event-calendar',
            subMenu: [],
            path: 'event-calendar',
        },
        {
            slug: 'payment-gateways',
            subMenu: [],
            path: 'payment-gateways',
        },
        {
            slug: 'notification',
            subMenu: [],
            path: 'notifications',
            className: 'no-mob',
        },
        {
            slug: 'account',
            icon: userAvatar,
            subMenu: ['my-profile', 'my-account', 'exports'],
            className: 'no-mob',
        },
    ];

    const toggleSidebar = status => isMobile() && props.toggleSidebar(status);

    const [currentNav, setCurrentNav] = useState(props.location.pathname.split('/').slice(2));

    useEffect(() => {
        const pathname = props.location.pathname.split('/').slice(2);
        setCurrentNav(pathname);
    }, [props.location.pathname]);

    return (
        <div className="home-sidebar-wrapper">
            <Menu
                mode="inline"
                defaultOpenKeys={width > 767 ? [currentNav[0]] : []}
                className="admin-menu admin-sidebar-menu-item"
            >
                {menuItemSlugs.map(menu =>
                    menu.subMenu.length > 0 ? (
                        <SubMenu
                            key={menu.slug}
                            className={menu.className ? menu.className : ''}
                            title={
                                <div className="icon-bg">
                                    <img
                                        className="menu-item no-mob inline"
                                        alt={menu.slug}
                                        src={menu.icon || require(`../../../../assets/images/${menu.slug}-icon.svg`)}
                                    />
                                    <img
                                        className={`menu-item active-icon desktop-highlight ${menu.slug}-highlight inline`}
                                        alt={menu.slug}
                                        src={
                                            menu.icon ||
                                            require(`../../../../assets/images/${menu.slug}-highlight-admin-icon.svg`)
                                        }
                                    />
                                    <span className="title sidebar-title">{t(`adminMessages.menus.${menu.slug}`)}</span>
                                </div>
                            }
                        >
                            {menu.subMenu.map(sub => (
                                <Menu.Item
                                    key={sub}
                                    className={`menu-item admin-sidebar-menu-item ${sub}-icon ${
                                        typeof currentNav[1] !== 'undefined' &&
                                        currentNav[0] === menu.path &&
                                        currentNav[1] === sub
                                            ? 'custom-menu-selected'
                                            : ''
                                    }`}
                                >
                                    <Link
                                        to={`/admin${menu.path ? `/${menu.path}` : ''}/${sub}`}
                                        onClick={() => toggleSidebar(true)}
                                    >
                                        <div className="icon-bg">
                                            <span className="title sidebar-title">
                                                {t(`adminMessages.menus.${sub}`)}
                                            </span>
                                        </div>
                                    </Link>
                                </Menu.Item>
                            ))}
                        </SubMenu>
                    ) : (
                        <Menu.Item
                            key={menu.slug}
                            className={`menu-item admin-sidebar-menu-item ${menu.className ? menu.className : ''} ${
                                menu.slug
                            }-icon ${currentNav[0] === menu.path ? 'custom-menu-selected' : ''}`}
                        >
                            <Link to={`/admin/${menu.path}`} onClick={() => toggleSidebar(true)}>
                                <div className="icon-bg">
                                    <img
                                        className="menu-item no-mob inline"
                                        alt={menu}
                                        src={require(`../../../../assets/images/${menu.slug}-icon.svg`)}
                                    />
                                    <img
                                        className={`menu-item active-icon desktop-highlight ${menu.slug}-highlight inline`}
                                        alt={menu}
                                        src={require(`../../../../assets/images/${menu.slug}-highlight-admin-icon.svg`)}
                                    />
                                    <span className="title sidebar-title">{t(`adminMessages.menus.${menu.slug}`)}</span>
                                </div>
                            </Link>
                        </Menu.Item>
                    )
                )}
            </Menu>
        </div>
    );
};

AdminSidebar.propTypes = {
    location: PropTypes.object,
    selectedNav: PropTypes.string,
    t: PropTypes.func,
    toggleSidebar: PropTypes.func,
    userDetails: PropTypes.object,
};

export default connect(
    state => ({
        userDetails: state.user.userDetails,
        unreadCount: state.notification.unreadCount,
    }),
    { toggleSidebar }
)(withTranslation()(AdminSidebar));

/**
 * useCampaigns
 * Get the campaigns
 *
 * @since 2.0.0
 */

import { CACHE_CAMPAIGNS_KEY } from 'includes/constants';
import { CAMPAIGNS } from 'includes/constants/mappings/success';
import { get } from 'lodash';
import CampaignApi from 'includes/services/campaigns';
import useCachedData from './useCachedData';
import useOrganisationId from 'includes/hooks/useOrganisationId';

/**
 * Get the campaigns
 *
 * @param {boolean} disableApiCall Whether to disable api call or not. Default false
 *
 * @returns object Campaigns data
 */
const useCampaigns = (disableApiCall = false) => {
    const organisationId = useOrganisationId();
    return useCachedData(
        CACHE_CAMPAIGNS_KEY,
        () => CampaignApi.getCampaigns(organisationId, { page_size: 0, campaign_type: 'all' }),
        {
            selector: res => get(res, CAMPAIGNS),
            disableApiCall: disableApiCall,
            dependsOnOrg: true,
        }
    );
};

export default useCampaigns;

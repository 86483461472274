/**
 * Permission Guard Layout
 * Checks if the user has permission to
 *
 * @version 1.0
 * @author Aravind JR <aravind@paycepaid.com>
 */
import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { Button, Layout } from 'antd';

import useHasRequiredPermission from 'includes/hooks/useHasRequiredPermission';
import { redirect } from 'includes/utils';
import useUserHasAccessPermission from 'includes/hooks/useUserHasAccessPermission';
import { useCurrentRoleSlug } from 'includes/utils/hooks';
import { USER_ROLE_CUSTOMER } from 'includes/constants';
import useHasActiveAppSubscription from 'includes/hooks/useHasActiveSubscription';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';

const { Content } = Layout;

const borderStyleClassesDict = {
    page: 'rounded-lg shadow-lg',
    section: '',
};

function FallbackUI({ className, message, show, title, upgradeBtnNode }) {
    if (!show) return null;

    return (
        <Content>
            <div className={`container m-auto my-14 p-10 bg-white ${className}`}>
                {/* Should we change the heading */}
                <h1 className="text-4xl text-left">{title}</h1>
                <p className="mt-4 text-left">{message}</p>
                {upgradeBtnNode ? <div className="flex justify-start pt-7">{upgradeBtnNode}</div> : null}
            </div>
        </Content>
    );
}

FallbackUI.propTypes = {
    className: PropTypes.string,
    message: PropTypes.string,
    show: PropTypes.bool,
    title: PropTypes.string,
    upgradeBtnNode: PropTypes.node,
};

/**
 * Check if a user has access to view this component.
 * First checks if the user has a valid subscription, then checks for permissions.
 * If the user is a customer-admin and doesn't have a valid subscription, then show an upgrade message
 * If the user is not a customer-admin but doesn't have a valid subscription, Show
 */
const PermissionGuardLayout = ({
    requiredPermission,
    children,
    layout,
    showFallbackUI = false,
    sectionErrorMessage,
}) => {
    const hasActiveSubscription = useHasActiveAppSubscription();
    const hasPlanPermission = useHasRequiredPermission(requiredPermission);
    const hasAccessPermission = useUserHasAccessPermission(requiredPermission);
    const borderStyleClasses = borderStyleClassesDict[layout] ?? borderStyleClassesDict['page'];
    const currentRoleSlug = useCurrentRoleSlug();

    /**
     * Returns an error message
     * If `sectionErrorMessage` exists, then return it.
     * If the user is customer-admin, return a message asking the user to subscribe, else return a message asking to contact admin
     *
     * @returns error message
     */
    const getNoPlanPermissionSectionErrorMessage = () => {
        if (sectionErrorMessage) return sectionErrorMessage;

        if (currentRoleSlug === USER_ROLE_CUSTOMER) return 'Please upgrade to access this feature.';

        return "Your plan doesn't have access to this feature. Please contact administrator to get it upgraded.";
    };

    /**
     * Renders an upgrade/subscribe button based on whether or not the user has active subscription and the role slug is customer-admin.
     *
     * @returns button node | null
     */
    const renderInactiveSubscriptionSectionUpgradeButton = () => {
        if (currentRoleSlug !== USER_ROLE_CUSTOMER) return null;

        return (
            <Button className="green-bg-button ml-0" onClick={() => redirect('/plans')} big filled>
                {hasActiveSubscription ? 'Upgrade' : 'Subscribe'}
            </Button>
        );
    };

    const isAdmin = useIsAdminOrManager();

    const upgradeBtnNode = renderInactiveSubscriptionSectionUpgradeButton();

    // if user is admin or `requiredPermission` is not passed, directly return the children
    if (!requiredPermission || isAdmin) return children;

    if (!hasPlanPermission) {
        if (layout === 'page' && currentRoleSlug !== USER_ROLE_CUSTOMER) return <Redirect to={'/page-not-found'} />;
        else {
            return (
                <FallbackUI
                    className={borderStyleClasses}
                    message={getNoPlanPermissionSectionErrorMessage()}
                    show={showFallbackUI || currentRoleSlug === USER_ROLE_CUSTOMER}
                    title={'Upgrade to access'}
                    upgradeBtnNode={upgradeBtnNode}
                />
            );
        }
    }

    if (!hasAccessPermission) {
        if (currentRoleSlug === USER_ROLE_CUSTOMER) {
            return (
                <FallbackUI
                    className={borderStyleClasses}
                    message={'Please upgrade your plan to access this feature'}
                    show={showFallbackUI || currentRoleSlug === USER_ROLE_CUSTOMER}
                    title={'Upgrade plan'}
                    upgradeBtnNode={upgradeBtnNode}
                />
            );
        } else {
            if (layout === 'page') return <Redirect to={'/page-not-found'} />;
            return (
                <FallbackUI
                    className={borderStyleClasses}
                    message={'You do not have access to this feature. Please contact administrator.'}
                    show={showFallbackUI}
                    title={'No permission'}
                    upgradeBtnNode={null}
                />
            );
        }
    }

    return children;
};

PermissionGuardLayout.propTypes = {
    children: PropTypes.node,
    layout: PropTypes.oneOf(['page', 'section']),
    requiredPermission: PropTypes.string.isRequired,
    sectionErrorMessage: PropTypes.string,
    showFallbackUI: PropTypes.bool,
};

PermissionGuardLayout.defaultProps = {
    layout: 'page',
};

export default PermissionGuardLayout;

/**
 * Invoices Component
 * Handles the invoices listing page
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import { Breadcrumb, Dropdown, Icon, Menu, Modal, Tabs } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import PermissionGuard from 'components/PermissionGuard';
import TableComponent from './components/Table';
import Button from 'components/shared/Button';
import CanvasJSReact from 'assets/js/canvasjs.react';
import DebtAge from 'components/shared/graphs/DebtAge';
import DebtBand from 'components/shared/graphs/DebtBand';
import InfoMessage from 'components/shared/messages/InfoMessage';
import OverviewCard from 'components/shared/OverviewCard';
import { useCurrentRoleSlug, useUpdateBusinessName, useViewingAccountType } from 'includes/utils/hooks';
import {
    deleteInvoice,
    getInvoices,
    removeInvoicesLoading,
    removeInvoiceStatusesLoading,
} from 'includes/slices/invoice';
import {
    getContactInvoiceStatusTypeData,
    getDebtAgeAndDebtBand,
    getOrganisationDetails,
    removeDebtAgeAndDebtBandLoading,
} from 'includes/redux/actions/customer/organisation';
import { Dropdown as OnDemandCampaignDropDown } from 'components/OnDemandCampaign';
import OnDemandCampaignModal from 'components/OnDemandCampaign';
import './styles.scss';
import ExportButton from 'components/shared/Exports/button';
import { DEBTOR_ACCOUNT_TYPE_SLUG, USER_ROLE_DEBTOR } from 'includes/constants';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useAccountId from 'includes/hooks/useAccountId';
import useEnforceAddOrganisation from 'includes/hooks/useEnforceAddOrganisation';
import { redirect } from 'includes/utils';
import { DASHBOARD, INVOICE, RECURRING_INVOICE } from 'includes/constants/permissions';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import useCommunicationTemplateTypes from 'includes/hooks/useCommunicationTemplateTypes';
import useUserHasAccessPermission from 'includes/hooks/useUserHasAccessPermission';

let CanvasJS = CanvasJSReact.CanvasJS;
let CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet('debtBandOptionsColorset', ['#858ff3']);
CanvasJS.addColorSet('debtAgeColorset', ['#f5c64a']);

const { TabPane } = Tabs;
const { confirm, error } = Modal;
const TABS = {
    INVOICES: 'invoices',
    REPEATING_INVOICES: 'repeating_invoices',
};

const Invoices = props => {
    const [activeTab, setActiveTab] = useState(TABS.INVOICES);
    const [invoiceIds, setInvoiceIds] = useState([]);
    const [showOnDemandCampaignModal, setShowOnDemandCampaignModal] = useState(false);
    const [tableFilters, setTableFilters] = useState({});
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const invoices = useSelector(state => state.invoices.invoices);
    const detailsUpdatedMessage = useSelector(state => state.invoices.detailsUpdatedMessage);
    const paginationInvoices = useSelector(state => state.invoices.paginationInvoices);
    const invoiceStatusCount = useSelector(state => state.organisation.invoiceStatusCount);
    const invoiceTypeCount = useSelector(state => state.organisation.invoiceTypeCount);
    const isAdmin = useSelector(state => state.account.isAdmin);
    const isContactInvoiceStatusTypeCountLoading = useSelector(
        state => state.organisation.isContactInvoiceStatusTypeCountLoading
    );
    const isGraphDataLoading = useSelector(state => state.organisation.isGraphDataLoading);
    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);
    const overdueCount = useSelector(state => state.organisation.overdueCount);
    const debtAgeData = useSelector(state => state.organisation.debtAgeData);
    const debtBandData = useSelector(state => state.organisation.debtBandData);
    const { data: communicationTemplateTypes } = useCommunicationTemplateTypes();
    const organisationId = useOrganisationId();
    const accountId = useAccountId();

    // Fetch graph data
    const getGraphData = () => {
        if (organisationId) {
            if (showGraphAndCount) {
                dispatch(getContactInvoiceStatusTypeData(organisationId));
                dispatch(getDebtAgeAndDebtBand(organisationId));
            }
        }
    };

    const composeFetchInvoices = (isRepeatingInvoices = false) => searchParams => {
        dispatch(
            getInvoices(organisationId, {
                ...searchParams,
                invoice_cat_type: isRepeatingInvoices ? 'is_repeating_invoice' : '',
            })
        );
    };

    const fetchInvoices = useEnforceAddOrganisation(composeFetchInvoices());
    const fetchRecurringInvoices = useEnforceAddOrganisation(composeFetchInvoices(true));

    /**
     * Delete invoice
     *
     * @param {string} invoiceContactId Id of the invoice contact
     * @param {string} invoiceId Id of the invoice to be deleted
     */
    const composeDispatchDeleteInvoice = (isRepeatingInvoices = false) => (invoiceContactId, invoiceId) => {
        confirm({
            title: t('customerMessages.invoice.confirm.deleteInvoice'),
            content: '',
            onOk: () => {
                dispatch(
                    deleteInvoice(organisationId, invoiceContactId, invoiceId, {
                        invoice_cat_type: isRepeatingInvoices ? 'is_repeating_invoice' : '',
                    })
                );
            },
        });
    };

    /**
     * Handles delete of multiple invoices
     */
    const handleDeleteMultipleInvoices = () => {
        if (invoiceIds.length === 0) {
            error({
                title: '',
                content: getLocale('messages.selectAtleastOneInvoiceTitle'),
            });
        } else {
            confirm({
                title: t('customerMessages.invoice.confirm.deleteInvoices'),
                content: '',
                onOk: () => {
                    const invoiceData = invoiceIds.map(value => `${value}`).join(',');
                    dispatch(
                        deleteInvoice(
                            organisationId,
                            '',
                            '',
                            {
                                invoice_ids: invoiceData,
                                multiDelete: true,
                                invoice_cat_type: activeTab === TABS.REPEATING_INVOICES ? 'is_repeating_invoice' : '',
                            },
                            getGraphData,
                            true
                        )
                    );
                },
            });
        }
    };

    // Initiates call to fetch graph data when an invoice is updated
    useEffect(() => {
        if (organisationId) {
            getGraphData();
        } else {
            dispatch(removeInvoicesLoading());
            dispatch(removeInvoiceStatusesLoading());
            dispatch(removeDebtAgeAndDebtBandLoading());
        }
    }, [detailsUpdatedMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    // check if user has updated the business name
    useUpdateBusinessName();

    const { showOnDemandCampaign } = props;

    const currentRoleSlug = useCurrentRoleSlug();
    const viewingAccountType = useViewingAccountType();

    const showGraphAndCount = useUserHasAccessPermission(DASHBOARD.GRAPH.PAYMENT_OVERVIEW);

    // Fetch organisation details on organisationId change
    useEffect(() => {
        get(selectedOrganisation, 'id') !== organisationId && dispatch(getOrganisationDetails(organisationId));
    }, [organisationId]); // eslint-disable-line react-hooks/exhaustive-deps

    const getLocale = path => t(`customerMessages.invoice.${path}`);

    /**
     * Get the filter params
     *
     * @returns {object} Filter params
     */
    const getFilterParams = () => {
        const params =
            invoiceIds.includes('all') && showOnDemandCampaign
                ? {
                      ...tableFilters,
                      page_size: 0,
                  }
                : {};
        params.on_demand_campaign = true;
        return params;
    };

    const disableEditControls = currentRoleSlug === USER_ROLE_DEBTOR || viewingAccountType === DEBTOR_ACCOUNT_TYPE_SLUG;

    const addInvoiceMenu = (
        <Menu>
            <Menu.Item key="add-invoice">
                <PermissionGuard requiredPermission={INVOICE.ADD}>
                    <span
                        onClick={() => {
                            redirect(
                                isAdmin
                                    ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}/on-demand-campaign/history/`
                                    : `/invoices/add`
                            );
                        }}
                    >
                        New Invoice
                    </span>
                </PermissionGuard>
            </Menu.Item>
            <Menu.Item key="send-on-demand-campaign">
                <PermissionGuard requiredPermission={RECURRING_INVOICE.ADD}>
                    <span
                        onClick={() =>
                            redirect(
                                isAdmin
                                    ? `/admin/accounts/organisation/import/invoices/${accountId}/${organisationId}/`
                                    : '/invoices/recurring/add'
                            )
                        }
                    >
                        New Recurring Invoice
                    </span>
                </PermissionGuard>
            </Menu.Item>
        </Menu>
    );

    const commonTableProps = {
        accountId,
        disableEditControls,
        fetchInvoices: fetchInvoices,
        deleteInvoice: composeDispatchDeleteInvoice(),
        getGraphData,
        getLocale,
        handleDeleteMultipleInvoices,
        invoiceIds,
        setInvoiceIds,
        setShowOnDemandCampaignModal,
        setTableFilters,
        showActionButtons: props.showActionButtons,
        showGraphAndCount,
        showOnDemandCampaign,
        showOnDemandCampaignModal,
        tableFilters,
    };

    /**
     * Get the on-demand contact names for whom we need to send campaign
     *
     * @returns {Array} contactNames Array of contact names
     */
    const { contactNames, contactIds } = useMemo(() => {
        const contactNames = new Set(),
            contactIds = new Set();
        if (invoiceIds.includes('all')) {
            contactNames.add('All Contacts');
        } else {
            invoices.forEach(
                ({ id, contact_id: { business_name: contactName, name: contactFullName, id: contactId } }) => {
                    if (invoiceIds.includes(id)) {
                        contactIds.add(contactId);
                        contactNames.add(contactName ? contactName : contactFullName);
                    }
                }
            );
        }

        return { contactNames: [...contactNames], contactIds: [...contactIds] };
    }, [invoiceIds]); // eslint-disable-line react-hooks/exhaustive-deps

    // method to keep with OnDemandCampaign modal component's API
    const getToText = () => contactNames.join(', ');

    return (
        <div className="home-content-wrapper accounts-wrapper invoices-wrapper">
            {isAdmin && (
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={`/admin/accounts/organisations/${accountId}`}>
                                {getLocale('breadcrumb.organisations')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{getLocale('breadcrumb.invoices')}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            <h2 className="page-title">{t('customerMessages.invoice.title')}</h2>
            {showGraphAndCount ? (
                <>
                    <div className="count-wrapper">
                        <OverviewCard
                            loading={isContactInvoiceStatusTypeCountLoading}
                            contents={[
                                {
                                    value: overdueCount,
                                    title: getLocale('overview.overdueAccounts'),
                                },
                                {
                                    value: get(invoiceTypeCount, 'manual', 0),
                                    title: getLocale('overview.manual'),
                                },
                                {
                                    value: get(invoiceTypeCount, 'automatic', 0),
                                    title: getLocale('overview.automated'),
                                },
                                {
                                    value: get(invoiceStatusCount, 'paid', 0),
                                    title: getLocale('overview.paidInFull'),
                                },
                            ]}
                        />
                    </div>
                    <div className="box-wrapper white-box-wrapper full-wrapper">
                        <h3 className="page-title total-count-header">Total Count and Amount</h3>
                        <DebtBand
                            canvasJSChart={CanvasJSChart}
                            debtBandData={debtBandData}
                            infoMessage={InfoMessage}
                            isGraphDataLoading={isGraphDataLoading}
                        />
                        <DebtAge
                            canvasJSChart={CanvasJSChart}
                            debtAgeData={debtAgeData}
                            infoMessage={InfoMessage}
                            isGraphDataLoading={isGraphDataLoading}
                        />
                    </div>
                </>
            ) : (
                ''
            )}
            <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                <section className="right-menu-wrapper">
                    <div className="right-align">
                        {showOnDemandCampaign && !disableEditControls && activeTab !== TABS.REPEATING_INVOICES && (
                            <OnDemandCampaignDropDown
                                accountId={accountId}
                                organisationId={organisationId}
                                showModal={setShowOnDemandCampaignModal}
                                isAdmin={isAdmin}
                            />
                        )}
                        {organisationId && (
                            <>
                                <PermissionGuard requiredPermission={INVOICE.EXPORT}>
                                    <ExportButton
                                        exportType="invoices"
                                        queryParams={{ invoice_ids: invoiceIds, ...tableFilters }}
                                    />
                                </PermissionGuard>
                                {get(selectedOrganisation, 'is_manual', '') &&
                                props.showActionButtons &&
                                !disableEditControls ? (
                                    <span>
                                        <PermissionGuard requiredPermission={INVOICE.IMPORT}>
                                            <Button
                                                className="green-bg-button"
                                                filled
                                                onClick={() =>
                                                    redirect(
                                                        isAdmin
                                                            ? `/admin/accounts/organisation/import/invoices/${accountId}/${organisationId}/`
                                                            : '/invoice-import'
                                                    )
                                                }
                                            >
                                                {getLocale('action.importInvoices')} <Icon type="upload" />
                                            </Button>
                                        </PermissionGuard>
                                        {!isAdmin && (
                                            <PermissionGuard requiredPermission={[RECURRING_INVOICE.ADD, INVOICE.ADD]}>
                                                <Dropdown overlay={addInvoiceMenu}>
                                                    <Button
                                                        big
                                                        filled
                                                        submittingButtonLabel={t(
                                                            'sharedMessages.exports.button.submittingLabel'
                                                        )}
                                                        // loading={isExportLoading}
                                                    >
                                                        Add Invoice
                                                        <Icon type="down" />
                                                    </Button>
                                                </Dropdown>
                                            </PermissionGuard>
                                        )}
                                    </span>
                                ) : null}
                            </>
                        )}
                    </div>
                </section>
                <Tabs activeKey={activeTab} onChange={setActiveTab} destroyInactiveTabPane style={{ width: '100%' }}>
                    <TabPane key={TABS.INVOICES} tab="Invoices">
                        <PermissionGuardLayout requiredPermission={INVOICE.LIST} layout="section">
                            <TableComponent {...commonTableProps} />
                        </PermissionGuardLayout>
                    </TabPane>
                    <TabPane key={TABS.REPEATING_INVOICES} tab="Recurring Invoices">
                        <PermissionGuardLayout requiredPermission={RECURRING_INVOICE.LIST} layout="section">
                            <TableComponent
                                {...commonTableProps}
                                isRepeatingInvoices
                                fetchInvoices={fetchRecurringInvoices}
                                deleteInvoice={composeDispatchDeleteInvoice(true)}
                            />
                        </PermissionGuardLayout>
                    </TabPane>
                </Tabs>
                <OnDemandCampaignModal
                    contactCC={[]} // intentionally left empty
                    contactIds={contactIds}
                    contactNames={contactNames}
                    getToText={getToText}
                    getFilterParams={getFilterParams}
                    invoiceIds={invoiceIds}
                    setInvoiceIds={setInvoiceIds}
                    setShowOnDemandCampaignModal={setShowOnDemandCampaignModal}
                    showOnDemandCampaignModal={showOnDemandCampaignModal}
                    templateTypes={communicationTemplateTypes
                        .filter(function(x) {
                            return x.slug === 'on-demand-campaign';
                        })
                        .map(x => ({
                            value: x.id,
                            name: x.type,
                        }))}
                    totalCount={get(paginationInvoices, 'totalCount', 0)}
                />
            </div>
        </div>
    );
};

Invoices.defaultProps = {
    contactId: '',
    showActionButtons: true,
    showOnDemandCampaign: true,
};

Invoices.propTypes = {
    organisation: PropTypes.string,
    path: PropTypes.string,
    showActionButtons: PropTypes.bool,
    showOnDemandCampaign: PropTypes.bool,
};

export default Invoices;

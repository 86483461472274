import { Icon, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Field } from 'redux-form';

import {
    CARD_BRANDS,
    CARD_BRAND_LOGO_MAP,
    CARD_BRAND_NAME_MAP,
} from 'components/customer/PaymentInformation/constants';
import FormField from 'components/shared/FormField';
import { ADDITIONAL_CHARGE_APPLY_FOR_OPTIONS } from 'includes/constants';
import { APPLY_FOR, BRAND_NAME, TRANSACTION_CHARGE } from 'includes/constants/keys/request';
import * as formValidations from 'includes/utils/form';

import './styles.scss';

export default function AdditionalChargesForCreditAndDebitCardsFormSection({ fields }) {
    // a map of all brands with brand name as key and array of apply_for option values already added in the form
    const brandAppliedForMap = useMemo(() => {
        const newBrandAppliedForMap = new Map();

        (fields.getAll() || []).forEach(field => {
            const existingValue = newBrandAppliedForMap.get(field.brand_name);

            if (!field.brand_name || !field.apply_for) return;

            if (existingValue) {
                newBrandAppliedForMap.set(field.brand_name, [...existingValue, field.apply_for]);
                return;
            }

            newBrandAppliedForMap.set(field.brand_name, [field.apply_for]);
        });

        return newBrandAppliedForMap;
    }, [fields]);

    /**
     *
     * @param {string} brand brand name
     * @param {string} appliedFor current apply_for value
     * @returns array of options
     */
    const getApplyForOptions = (brand, appliedFor) => {
        const appliedForList = brandAppliedForMap.get(brand) || [];

        return ADDITIONAL_CHARGE_APPLY_FOR_OPTIONS.filter(option => {
            return option.value === appliedFor || !appliedForList.includes(option.value);
        }).map(option => ({
            value: option.value,
            name: option.label,
        }));
    };

    return (
        <div className="mb-3">
            {fields.length === 0 ? (
                <div>No additional charges applied.</div>
            ) : (
                fields.map((additional_charges, index) => (
                    <div
                        key={additional_charges}
                        className="additional-charge-field-wrapper border-b border-solid border-gray-200 p-3 gap-2 justify-between items-center"
                    >
                        <div className="grid grid-cols-1 sm:grid-cols-11 flex-grow-1 gap-2 text-left">
                            <div className="sm:col-span-3">
                                <Field
                                    type="select"
                                    component={FormField}
                                    name={`${additional_charges}.${BRAND_NAME}`}
                                    label="Card brand"
                                    dropdownMatchSelectWidth={false}
                                    options={Object.values(CARD_BRANDS)
                                        .filter(
                                            brand =>
                                                brandAppliedForMap.get(brand)?.length !==
                                                    ADDITIONAL_CHARGE_APPLY_FOR_OPTIONS.length ||
                                                fields.get(index)?.brand_name === brand
                                        )
                                        .map(brand => ({
                                            name: (
                                                <div className="flex flex-row gap-1">
                                                    <img src={CARD_BRAND_LOGO_MAP[brand]} style={{ width: 20 }} />
                                                    <div>{CARD_BRAND_NAME_MAP[brand]}</div>
                                                </div>
                                            ),
                                            value: brand,
                                        }))}
                                    validate={[formValidations.required]}
                                />
                            </div>

                            <div className="sm:col-span-3">
                                <Field
                                    parse={value => parseFloat(value, 10)}
                                    type="number"
                                    component={FormField}
                                    name={`${additional_charges}.${TRANSACTION_CHARGE}`}
                                    label="Transaction charge %"
                                    validate={[formValidations.required]}
                                />
                            </div>

                            <div className="sm:col-span-3">
                                <Field
                                    type="select"
                                    component={FormField}
                                    name={`${additional_charges}.${APPLY_FOR}`}
                                    dropdownMatchSelectWidth={false}
                                    label="Apply for"
                                    options={getApplyForOptions(
                                        fields.get(index)?.brand_name,
                                        fields.get(index)?.apply_for
                                    )}
                                    validate={[formValidations.required]}
                                />
                            </div>
                            <div className="sm:col-span-2 flex sm:justify-end justify-center items-center">
                                <Tooltip title="Remove">
                                    <Icon
                                        type="delete"
                                        style={{ fontSize: 16, color: 'red' }}
                                        onClick={() => fields.remove(index)}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                ))
            )}

            {fields.length === Object.values(CARD_BRANDS).length ? null : (
                <button
                    className="w-full my-3 px-3 py-2 rounded-lg hover:bg-lime-100 hover:text-lime-500 transition-all duration-300 border-0"
                    onClick={() => fields.push({})}
                    type="button"
                >
                    + Add additional charges
                </button>
            )}
        </div>
    );
}

AdditionalChargesForCreditAndDebitCardsFormSection.propTypes = {
    fields: PropTypes.shape({
        get: PropTypes.func,
        getAll: PropTypes.func,
        length: PropTypes.number,
        map: PropTypes.func,
        push: PropTypes.func,
        remove: PropTypes.func,
    }),
};

/**
 * Currencies API class.
 * Handles all api requests related to currencies
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import base api handler
import BaseApiHandler from './base/api';

class CurrenciesApi extends BaseApiHandler {
    /**
     * Add new organisation currency
     *
     * @param {string} organisationId Organisation ID
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    addOrganisationCurrency = (organisationId, payload) =>
        this.axios.post(this.getApiUrl('CURRENCIES_ORGANISATION_CRUD', { organisation_id: organisationId }), payload);

    /**
     * Delete organisation currency
     *
     * @param {string} organisationId Organisation ID
     * @param {string} currencyId Currency ID
     *
     * @returns {object} API Response
     */
    deleteOrganisationCurrency = (organisationId, currencyId) =>
        this.axios.delete(
            this.getApiUrl('CURRENCY_ORGANISATION_CRUD', { organisation_id: organisationId, currency_id: currencyId })
        );

    /**
     * Get the currencies
     *
     * @returns {object} API Response
     */
    getCurrencies = () => this.axios.get(this.getApiUrl('CURRENCIES_CRUD'));

    /**
     * Get the organisation currencies
     *
     * @param {string} organisationId Organisation ID
     *
     * @returns {object} API Response
     */
    getOrganisationCurrencies = organisationId =>
        this.axios.get(this.getApiUrl('CURRENCIES_ORGANISATION_CRUD', { organisation_id: organisationId }));

    /**
     * Update organisation currency
     *
     * @param {string} organisationId Organisation ID
     * @param {string} currencyId Currency ID
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    updateOrganisationCurrency = (organisationId, currencyId, payload) =>
        this.axios.patch(
            this.getApiUrl('CURRENCY_ORGANISATION_CRUD', { organisation_id: organisationId, currency_id: currencyId }),
            payload
        );
}

export default new CurrenciesApi();

/**
 * Permission Block
 *
 * This component renders a block that represents the permission
 * which can be dragged to either allowed or restricted columns
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */
import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as BanIcon } from 'assets/images/ban.svg';
import { ReactComponent as PlusIcon } from 'assets/images/plus-circle.svg';
import { snakeCaseToWords } from 'includes/utils';

const Permission = ({ columnId, handleClick, index, isGroupActive, item, snapshot }) => {
    const buttonProps = {
        allowed: {
            className: 'text-red-500 hover:text-red-300',
            label: (
                <BanIcon width={25} className={`transition duration-300 transform text-red-500 hover:text-red-250`} />
            ),
        },
        restricted: {
            className: 'text-green-500 hover:text-green-300',
            label: (
                <PlusIcon
                    width={25}
                    className={`transition duration-300 transform text-green-500 hover:text-green-400`}
                />
            ),
        },
    }[columnId];

    return (
        <div
            className={`grid grid-cols-3 grid-flow-col rounded-md shadow-sm p-4 m-2 transition-all duration-200  w-auto permission-block ${
                isGroupActive ? '' : 'max-h-0 p-0 m-0'
            }`}
            style={{
                backgroundColor: snapshot.isDragging ? '#d3f1cd' : 'white',
            }}
        >
            <div className={`flex flex-col gap-2 col-span-2  ${isGroupActive ? '' : 'hidden'}`}>
                <h3 className="text-md text-left bold">{item.label}</h3>
                <p className="text-sm text-gray-500 text-left">{item.description}</p>
                <div className="flex gap-1">
                    <span className="bg-gray-400 text-gray-100 rounded-full py-1 px-2 w-max capitalize text-sm">
                        {snakeCaseToWords(item.group)}
                    </span>
                    {item.has_all_plan_access_by_default ? (
                        <p className="bg-gray-400 text-gray-100 rounded-full py-1 px-2 w-max capitalize text-sm">
                            Default
                        </p>
                    ) : null}
                </div>
            </div>
            <div className="flex justify-end items-center h-auto">
                {item.has_all_plan_access_by_default ? null : (
                    <button
                        className={`text-center mr-2 ${buttonProps.className} rounded-lg ${
                            isGroupActive ? '' : 'hidden'
                        }`}
                        onClick={() => handleClick(columnId, index)}
                    >
                        {buttonProps.label}
                    </button>
                )}
            </div>
        </div>
    );
};

Permission.propTypes = {
    columnId: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    isGroupActive: PropTypes.bool,
    item: PropTypes.shape({
        description: PropTypes.any,
        group: PropTypes.string,
        has_all_plan_access_by_default: PropTypes.string,
        label: PropTypes.any,
    }),
    snapshot: PropTypes.shape({
        isDragging: PropTypes.any,
    }),
};

export default Permission;

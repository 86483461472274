/**
 * Common actions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { SHOW_NOTIFICATION_MODAL } from '../../../constants/actionTypes';
import { DEFAULT_APP_NOTIFICATION_TYPE } from '../../../constants';

/**
 * Show notification popup action
 *
 * @param {string} message Message to be shown in the popup
 * @param {string} messageType Message type
 *
 * @returns {object} Show notification popup action object
 */
export const showNotificationPopup = (message, messageType = DEFAULT_APP_NOTIFICATION_TYPE) => ({
    type: SHOW_NOTIFICATION_MODAL,
    message: message,
    messageType: messageType,
});

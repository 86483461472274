/**
 * HOC components for wrapping manual organisation components
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import { get } from 'lodash';
import { PropTypes } from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const FeatureAvailableOnlyForManualOrganisation = ({ children, ...props }) => {
    const { t } = useTranslation();
    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);
    const isManualOrganisation = get(selectedOrganisation, 'is_manual', false);
    const { featureAvailableForManualOrganisationsOnly } = props;

    /**
     * Render the component
     */
    return featureAvailableForManualOrganisationsOnly && !isManualOrganisation ? (
        <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
            <div className="tab-arrow-link breadcrumb-spec">&nbsp;</div>
            <h2>&nbsp;</h2>
            <div className="white-box-wrapper mid-wrapper breadcrumb-spec details-wrapper">
                <span className="feature-available-for-manual">
                    {t('messages.common.featureAvailableOnlyForManualOrganisation')}{' '}
                </span>
            </div>
        </div>
    ) : (
        <>{children}</>
    );
};

FeatureAvailableOnlyForManualOrganisation.propTypes = {
    children: PropTypes.any,
    featureAvailableForManualOrganisationsOnly: PropTypes.bool,
};

export default FeatureAvailableOnlyForManualOrganisation;

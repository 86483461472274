/**
 * Chart of account details Component
 * Renders the chart of account details
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useEffect } from 'react';
import { find, get } from 'lodash';
import { Tabs, Breadcrumb } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import ChartOfAccountDefaultImage from 'assets/images/chart-of-account-default-image.png';
import ChartOfAccountsBreadCrumbItems from 'components/BreadCrumbItems/ChartOfAccounts';
import ChartOfAccountTransactions from './components/Transactions';
import Loader from 'components/Loader';
import useChartOfAccountTypes from 'includes/hooks/useChartOfAccountTypes';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { getChartOfAccount } from 'includes/slices/chartOfAccounts';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import { redirect } from 'includes/utils';
import PermissionGuard from 'components/PermissionGuard';
import { CHART_OF_ACCOUNT } from 'includes/constants/permissions';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import AuditLogList from 'pages/AuditLogs/List';

const { TabPane } = Tabs;

const ChartOfAccountDetails = () => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    useUpdateBusinessName();
    const { data: chartOfAccountTypes, isLoading: loadingChartOfAccountTypes } = useChartOfAccountTypes();

    const { accountId, chartOfAccountId } = params;
    const chartOfAccountDetails = useSelector(state => state.chartOfAccounts.chartOfAccount);
    const isAdmin = useSelector(state => state.account.isAdmin);
    const loadingChartOfAccount = useSelector(state => state.chartOfAccounts.loadingChartOfAccount);
    const organisationId = useOrganisationId();

    /**
     * Get the chart of account
     */
    useEffect(() => {
        dispatch(getChartOfAccount(chartOfAccountId, organisationId));
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the chart of accounts edit link
     *
     * @returns {string} Edit link
     */
    const getEditLink = () =>
        isAdmin
            ? `/admin/accounts/organisation/chart-of-accounts/chart-of-account/${accountId}/${organisationId}/${chartOfAccountId}/edit`
            : `/chart-of-accounts/chart-of-account/${chartOfAccountId}/edit`;

    /**
     * Get the localized label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.chartOfAccounts.addEdit.form.input.label.${field}`);

    /***
     * Get transaction account type
     *
     * @param {number} transactionType Transaction type
     *
     * @returns {string} Transaction type value
     */
    const getTransactionAccountType = transactionType =>
        transactionType === 1 ? 'Payment' : transactionType === 2 ? 'Sales' : '';

    return (
        <section>
            <div className="home-content-wrapper synced-name contact-details">
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <ChartOfAccountsBreadCrumbItems showListBreadCrumb showDetailsBreadCrumb />
                    </Breadcrumb>
                </div>
                <h2 className="page-title">{t(`titleAndMetas.chartOfAccount.pageTitle`)}</h2>
                <div className="white-box-wrapper mid-wrapper breadcrumb-spec details-wrapper">
                    {loadingChartOfAccount || loadingChartOfAccountTypes ? (
                        <Loader />
                    ) : (
                        <>
                            <section className="details-list left-align">
                                <div className="details-profile-pic-wrapper">
                                    <img
                                        src={ChartOfAccountDefaultImage}
                                        className="org-avatar contact-details-spec logo-placeholder"
                                        alt="chart of account pic"
                                    />
                                </div>
                                <div className="center-align">
                                    <PermissionGuard requiredPermission={CHART_OF_ACCOUNT.EDIT}>
                                        <Button
                                            className="green-bg-button"
                                            big
                                            filled
                                            onClick={() => redirect(getEditLink())}
                                        >
                                            {t(`messages.chartOfAccounts.crud.edit`)}
                                        </Button>
                                    </PermissionGuard>
                                </div>
                            </section>
                            <section className="details-list right-align">
                                <div className="full-wrapper">
                                    <h3 className="profile-name">{get(chartOfAccountDetails, 'name')}</h3>
                                </div>
                                <section className="detail-summary">
                                    <ul className="upper-right-detail">
                                        <li>
                                            <header>{getLocaleLabel('description')}</header>
                                            <div>{get(chartOfAccountDetails, 'description')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('code')}</header>
                                            <div>{get(chartOfAccountDetails, 'code')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('external_id')}</header>
                                            <div>{get(chartOfAccountDetails, 'external_id')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('account_number')}</header>
                                            <div>{get(chartOfAccountDetails, 'account_number')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('account_type_id')}</header>
                                            <div>
                                                {get(
                                                    find(chartOfAccountTypes, {
                                                        id: get(chartOfAccountDetails, 'account_type_id'),
                                                    }),
                                                    'type'
                                                )}
                                            </div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('transaction_type')}</header>
                                            <div>
                                                {getTransactionAccountType(
                                                    get(chartOfAccountDetails, 'transaction_type')
                                                )}
                                            </div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('credits')}</header>
                                            <div>{get(chartOfAccountDetails, 'total_credit_amount')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('debits')}</header>
                                            <div>{get(chartOfAccountDetails, 'total_debit_amount')}</div>
                                        </li>
                                    </ul>
                                </section>
                            </section>
                        </>
                    )}
                </div>
                {organisationId ? (
                    <div className="full-wrapper box-wrapper white-box-wrapper no-padding myaccount-content tab-wrapper">
                        <Tabs>
                            <TabPane key="transactions" tab={t('messages.chartOfAccounts.transactions.transactions')}>
                                <PermissionGuardLayout
                                    requiredPermission={CHART_OF_ACCOUNT.TRANSACTION.LIST}
                                    layout="section"
                                >
                                    <ChartOfAccountTransactions />
                                </PermissionGuardLayout>
                            </TabPane>
                            <TabPane key="audit-logs" tab="Audit Logs">
                                <AuditLogList
                                    customDataValues={{ chart_of_account_id: chartOfAccountId }}
                                    showTitle={false}
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                ) : null}
            </div>
        </section>
    );
};

export default ChartOfAccountDetails;

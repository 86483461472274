import { Form } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';

import {
    CARD_BRAND_LOGO_MAP,
    CARD_BRAND_NAME_MAP,
    GENERIC_CARD_LOGO,
} from 'components/customer/PaymentInformation/constants';
import Button from 'components/shared/Button';
import FormField from 'components/shared/FormField';
import { PAYMENT_METHOD_FORM } from 'includes/constants';
import { getYearsRange } from 'includes/utils';
import * as formValidations from 'includes/utils/form';

const EXPIRY_MONTH_OPTIONS = Array.from({ length: 12 }, (_, i) => i + 1);

const EXPIRY_YEARS_OPTIONS = getYearsRange(50);

/**
 * Edit card form
 *
 * Form used to edit card
 *
 * @since 2.8.0
 */
const EditCardForm = reduxForm({
    form: PAYMENT_METHOD_FORM,
    enableReinitialize: true,
})(function({ brand, handleSubmit, isLoading, number: _number, onCancel, okLabel }) {
    const { t } = useTranslation();

    const number = useMemo(() => _number?.replaceAll('*', '•'), [_number]);

    return (
        <>
            <Form layout="vertical" onSubmit={handleSubmit}>
                <div className="col-span-3 sm:col-span-1 text-left mb-3">
                    <label>{t('customerMessages.paymentMethod.card.form.label.cardNumber')}</label>
                    <div className="flex flex-row justify-start items-center gap-2 text-lg">
                        <div className="font-mono">{number ?? CARD_BRAND_NAME_MAP[brand]}</div>
                        <img
                            style={{ height: 40, width: 40 }}
                            src={CARD_BRAND_LOGO_MAP[brand] ?? GENERIC_CARD_LOGO}
                            alt={CARD_BRAND_NAME_MAP[brand]}
                        />
                    </div>
                </div>

                <div className="grid grid-cols-6 gap-2">
                    <div className="col-span-6 md:col-span-4 text-left">
                        <Field
                            type="text"
                            name="name"
                            label={t('customerMessages.paymentMethod.card.form.label.nameOnCard')}
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </div>

                    <div className="col-span-6 md:col-span-1 text-left">
                        <Field
                            type="select"
                            name="expiry_month"
                            label={t('customerMessages.paymentMethod.card.form.label.expiryMonth')}
                            options={EXPIRY_MONTH_OPTIONS.map(o => ({
                                name: o,
                                value: o,
                            }))}
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </div>

                    <div className="col-span-6 md:col-span-1 text-left">
                        <Field
                            type="select"
                            name="expiry_year"
                            label={t('customerMessages.paymentMethod.card.form.label.expiryYear')}
                            options={EXPIRY_YEARS_OPTIONS}
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </div>
                </div>
                <div className="flex gap-3 justify-end">
                    <Button onClick={onCancel}>{t('customerMessages.paymentServices.form.action.cancel')}</Button>
                    <Button filled loading={isLoading} htmlType="submit">
                        {okLabel ?? 'Submit'}
                    </Button>
                </div>
            </Form>
        </>
    );
});

EditCardForm.propTypes = {
    brand: PropTypes.string,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    number: PropTypes.string,
    okLabel: PropTypes.string,
    onCancel: PropTypes.func,
};

export default EditCardForm;

/**
 * Coupon account add component
 * Handles adding coupon to account
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import {
    React,
    connect,
    Link,
    PropTypes,
    useCallback,
    useMemo,
    useState,
    withTranslation,
} from '../../../../includes/exports/react';
import * as errorMessages from '../../../../includes/constants/messages/errors';
import Loader from '../../../shared/Loader';
import ReactHtmlParser from 'react-html-parser';
import SearchBlock from '../../../shared/SearchBlock';
import Table from '../../../shared/ScrollableTable';
import { ACCOUNT_TYPE } from '../../../../includes/constants/keys/request';
import { Breadcrumb, Modal } from 'antd';
import { CUSTOMER_ACCOUNT_TYPE_SLUG } from '../../../../includes/constants';
import { addCouponToAccount, getAccounts } from '../../../../includes/redux/actions/shared/account';
import { getDefaultImageClass, getImageFromData } from '../../../../includes/utils';
import { COUPON_ACCOUNTS_LIST_TABLE_COLUMNS } from '../../../../includes/constants/columns';

const { confirm } = Modal;

/**Coupon Accounts accounts add component
 */
const CouponAccountsAdd = props => {
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const accountLogo45Key = 'ACCOUNT_LOGO_45X45';
    const accountLogoOriginalKey = 'ACCOUNT_LOGO_ORIGINAL';
    const { accounts } = props;
    const { couponId } = props.match.params;

    /**
     * Add coupon to account
     * Call the API for adding coupon to the account
     *
     * @param   {string}    accountId    Account Id
     */
    const addCouponToAccount = accountId => {
        props.addCouponToAccount({ account_id: accountId, coupon_id: couponId }, couponError => {
            confirm({
                cancelText: props.t('appCommonMessages.no'),
                content: ReactHtmlParser(couponError),
                okText: props.t('appCommonMessages.yes'),
                title: props.t('adminMessages.couponAccounts.coupon.addCoupon'),
                onOk: () => {
                    props.addCouponToAccount({ account_id: accountId, coupon_id: couponId, confirm: true });
                },
            });
        });
    };

    /**
     * Format the coupon accounts add table data
     */
    const formatCouponAccountsAddTableData = useCallback(
        () =>
            accounts
                ? accounts.map(account => ({
                      ...account,
                      name: (
                          <>
                              <div className="table-name-align">
                                  <img
                                      className={
                                          getDefaultImageClass(
                                              account,
                                              accountLogo45Key,
                                              accountLogoOriginalKey,
                                              '45x45'
                                          ) + ' logo logo-45 '
                                      }
                                      src={getImageFromData(account, accountLogo45Key, accountLogoOriginalKey)}
                                      alt={account.name}
                                  />
                                  <div className="wrapped-content-text">
                                      <span className="wrapped-content-text crud-wrapper">{account.name}</span>
                                      <span className="crud-wrapper">
                                          <button
                                              className="crud-link"
                                              onClick={() => addCouponToAccount(account.id)}
                                              title={props.t('adminMessages.couponAccounts.coupon.addCouponTooltip')}
                                          >
                                              {props.t('adminMessages.couponAccounts.coupon.addCoupon')}
                                          </button>
                                      </span>
                                  </div>
                              </div>
                          </>
                      ),
                  }))
                : [],
        [accounts] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Memoized coupon accounts data
     */
    const memoizedCouponAccountsAddData = useMemo(() => formatCouponAccountsAddTableData(), [
        formatCouponAccountsAddTableData,
    ]);

    return (
        <div className="home-content-wrapper accounts-wrapper admin-accounts">
            <div className="tab-arrow-link">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={'/admin/coupons'}>{props.t('adminMessages.couponAccounts.breadcrumb.coupons')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {props.t(`adminMessages.couponAccounts.breadcrumb.addCouponAccounts`)}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                <section>
                    <div className="right-align">
                        <SearchBlock
                            customDataValues={{ [ACCOUNT_TYPE]: CUSTOMER_ACCOUNT_TYPE_SLUG }}
                            getDataMethod={props.getAccounts}
                            isSearching={isSearching}
                            placeholder={props.t('adminMessages.accounts.search.placeholder')}
                            setIsSearching={setIsSearching}
                            setSearchParam={setSearchParam}
                        />
                    </div>
                </section>
                <Table
                    columns={COUPON_ACCOUNTS_LIST_TABLE_COLUMNS}
                    customDataValues={{ [ACCOUNT_TYPE]: CUSTOMER_ACCOUNT_TYPE_SLUG }}
                    dataSource={memoizedCouponAccountsAddData}
                    getDataMethod={props.getAccounts}
                    isSearching={isSearching}
                    loading={props.isLoading}
                    localeMessage={errorMessages.TABLE_NO_ACCOUNTS_FOUND_MESSAGE}
                    paginationData={props.paginationAccounts}
                    rowClassName="pointer"
                    rowKey="id"
                    size="middle"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    {...props}
                />
                <Modal
                    className="my_profile_modal organisation-reauthorization-modal"
                    closable={false}
                    maskClosable={false}
                    visible={props.isAddingCouponToAccount}
                    footer={null}
                >
                    <Loader tip={props.t('adminMessages.couponAccounts.coupon.loader.addCoupon')} />
                </Modal>
            </div>
        </div>
    );
};
/**
 *Prop types
 */
CouponAccountsAdd.propTypes = {
    accounts: PropTypes.array,
    addCouponToAccount: PropTypes.func,
    getAccounts: PropTypes.func,
    history: PropTypes.object,
    isAddingCouponToAccount: PropTypes.bool,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    paginationAccounts: PropTypes.object,
    t: PropTypes.func,
};
export default connect(
    state => ({
        ...state.account,
        isAddingCouponToAccount: state.account.isAddingCouponToAccount,
        isLoading: state.account.isLoading,
    }),
    {
        addCouponToAccount,
        getAccounts,
    }
)(withTranslation()(CouponAccountsAdd));

// noinspection RegExpRedundantEscape,RegExpSingleCharAlternation,RegExpUnnecessaryNonCapturingGroup

/**
 * Form Helpers
 * Defines the validations and form related operations
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

import * as constants from '../constants';
import i18n from '../i18n';
import moment from 'moment';
import { getValueFormStore } from './index';
import { get, isArray, isEmpty, includes } from 'lodash';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';

/**
 * Alphanumeric validation
 * Do the alphanumeric validation on field
 *
 * @param {string} value field value
 * @param {object} props Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const alphaNumeric = (value, ...props) =>
    value && !/^[0-9a-zA-Z]+$/.test(value)
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_ALPHANUMERIC_ERROR_KEY],
              {
                  subdomain: value,
              },
              props[1]
          )
        : undefined;

/**
 * Alphanumeric lower case and underscore validation
 * Allows only a-z, 0-9 and  underscore
 *
 * @param {string} value field value
 * @param {object} props Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const alphaNumericSmallAndUnderscore = (value, ...props) =>
    value && !/^[a-z0-9_]*$/.test(value)
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_ALPHANUMERIC_LOWER_CASE_UNDERSCORE_ERROR_KEY],
              {},
              props[1]
          )
        : undefined;

/**
 * Alphanumeric, hyphen and underscore validation
 * Allows only A-Z, a-z, 0-9, - and _
 *
 * @param {string} value field value
 * @param {object} props Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const alphaNumericHyphenAndUnderscore = (value, ...props) =>
    value && !/^[A-za-z0-9_-]*$/.test(value)
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_ALPHANUMERIC_HYPHEN_UNDERSCORE_ERROR_KEY],
              {},
              props[1]
          )
        : undefined;
/**
 * Confirm Password validation
 *
 * @param {string} value Input value
 * @param {object} allValues All values in the input
 * @param {object} props Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const confirmPassword = (value, allValues, ...props) =>
    value !== allValues.password
        ? renderFieldError(
              props[1],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_PASSWORD_DO_NOT_MATCH_ERROR_KEY],
              {},
              props[0]
          )
        : undefined;

/**
 * Date validation
 *
 * @param {string} value Input value
 * @param {object} props Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const date = (value, ...props) =>
    value && !moment(value, 'DD-MM-YYYY', true).isValid()
        ? renderFieldError(props[2], constants.INVALID_FIELD_ERROR_KEY, [], {}, props[1])
        : undefined;

/**
 * Email validation
 *
 * @param {string} value Input value
 * @param {object} props Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
/* eslint-disable */
export const email = (value, ...props) =>
    value &&
    !/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
        value
    )
        ? renderFieldError(props[2], constants.INVALID_FIELD_ERROR_KEY, [], {}, props[1])
        : undefined;
/* eslint-enable */

/**
 * Convert object to form data
 *
 * @param {object} obj Object to convert to form data
 * @param {Array} jsonKeys Keys for which values are need to be converted to Json
 *
 * @returns {object}  Converted formdata
 */
export const objectToFormData = (obj, jsonKeys = []) => {
    let formData = new FormData();

    function appendFormData(data, root) {
        root = root || '';
        if (data instanceof File) {
            formData.append(root, data);
        } else if (jsonKeys && isArray(jsonKeys) && typeof root === 'string' && jsonKeys.includes(root)) {
            formData.append(root, JSON.stringify(data));
        } else if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                appendFormData(data[i], root);
            }
        } else {
            if (typeof data === 'object' && data) {
                for (let key in data) {
                    // noinspection JSUnfilteredForInLoop
                    appendFormData(data[key], key);
                }
            } else {
                if (data !== null && typeof data !== 'undefined') {
                    formData.append(root, data);
                }
            }
        }
    }

    appendFormData(obj);

    return formData;
};

// to do -modify this validation - Sabari
/**
 * File required validation
 * Do the required validation on file field
 *
 * @param {number} min Minimum number of files required
 * @param {number} max Maximum number of files allowed
 *
 * @returns {string}    Error message if validation fails
 */
export const fileRequired = (min = 1, max = 1) => (value, ...props) => {
    if (value) {
        if (typeof value === 'object') {
            let numberOfFiles = Object.keys(value).length;
            if (numberOfFiles < min) {
                return renderFieldError(
                    constants.INVALID_FIELD_MIN_FILES_ERROR_KEY,
                    constants.INVALID_FIELD_ERROR_KEY,
                    [],
                    {
                        min: min,
                    }
                );
            } else if (numberOfFiles > max) {
                return renderFieldError(
                    constants.INVALID_FIELD_MAX_FILES_ERROR_KEY,
                    constants.INVALID_FIELD_ERROR_KEY,
                    [],
                    {
                        max: max,
                    }
                );
            }
        }
    } else {
        return renderFieldError(props[2], constants.REQUIRED_FIELD_ERROR_KEY);
    }
};

/**
 * Get the form field error
 *
 * @param {string} formName Form Name
 * @param {string} fieldName Field Name
 *
 * @returns {string} Form Field Error
 */
export const getFormFieldError = (formName, fieldName) =>
    getValueFormStore([constants.FORM, formName, constants.SUBMIT_ERRORS, fieldName, 0]);

/**
 * Max length check validation
 *
 * @param {number} max Maximum input length
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const maxLength = max => (value, ...props) =>
    value && value.length > max
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_MAX_LENGTH_ERROR_KEY],
              {
                  max: max,
              },
              props[1]
          )
        : undefined;

/**
 * Max value check validation
 *
 * @param {number} max  Maximum value
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const maxValue = max => (value, ...props) =>
    value && value > max
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_MAX_VALUE_ERROR_KEY],
              {
                  max: max,
              },
              props[1]
          )
        : undefined;

/**
 * Min length check validation
 *
 * @param {number} min Minimum input length
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const minLength = min => (value, ...props) =>
    value && value.length < min
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_MIN_LENGTH_ERROR_KEY],
              {
                  min: min,
              },
              props[1]
          )
        : undefined;

/**
 * Min value check validation
 *
 * @param {number} min Minimum value
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const minValue = min => (value, ...props) =>
    value && value < min
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_MIN_VALUE_ERROR_KEY],
              {
                  min: min,
              },
              props[1]
          )
        : undefined;

/**
 * Number check validation
 *
 * @param {string} value Input value
 * @param {object} props Props
 *
 * @returns {string} Error message if validation fails
 */
export const number = (value, ...props) =>
    value && isNaN(Number(value))
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_NUMBER_ERROR_KEY],
              {},
              props[1]
          )
        : undefined;

/**
 * Password length check validation
 *
 * @param {string} value Input value
 * @param {object}  props Field Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const passwordLength = (value, ...props) =>
    value && !(value.length >= constants.PASSWORD_MIN_LENGTH && value.length <= constants.PASSWORD_MAX_LENGTH)
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_MIN_MAX_ERROR_KEY],
              {
                  min: constants.PASSWORD_MIN_LENGTH,
                  max: constants.PASSWORD_MAX_LENGTH,
              },
              props[1]
          )
        : undefined;

/**
 * Password lower case check validation
 *
 * @param {string} value Input value
 * @param {object}  props Field Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const passwordLowerCaseCheck = (value, ...props) =>
    value && !/([a-z]+)/g.test(value)
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_PASSWORD_LOWER_CASE_ERROR_KEY],
              {},
              props[1]
          )
        : undefined;

/**
 * Password number check validation
 *
 * @param {string} value Input value
 * @param {object}  props Field Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const passwordNumberCheck = (value, ...props) =>
    value && !/([0-9]+)/g.test(value)
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_PASSWORD_NUMBER_ERROR_KEY],
              {},
              props[1]
          )
        : undefined;

/**
 * Password special character check validation
 *
 * @param {string} value Input value
 * @param {object}  props Field Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const passwordSpecialCharacterCheck = (value, ...props) =>
    value && !/([!@#$&*_.-]+)/g.test(value)
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_PASSWORD_SPECIAL_CHARACTER_ERROR_KEY],
              { specialCharacters: constants.PASSWORD_SPECIAL_CHARACTERS },
              props[1]
          )
        : undefined;

/**
 * Password upper case check validation
 *
 * @param {string} value Input value
 * @param {object}  props Field Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const passwordUpperCaseCheck = (value, ...props) =>
    value && !/([A-Z]+)/g.test(value)
        ? renderFieldError(
              props[2],
              constants.INVALID_FIELD_ERROR_KEY,
              [constants.INVALID_FIELD_PASSWORD_UPPER_CASE_ERROR_KEY],
              {},
              props[1]
          )
        : undefined;

// noinspection JSValidateTypes
/**
 * Phone Number validation
 *
 * @param {string} value Input value
 * @param {object}  props Field Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const phone = (value, ...props) => {
    if (value) {
        if (!isValidPhoneNumber(value)) {
            return renderFieldError(props[2], constants.INVALID_FIELD_ERROR_KEY, '', {}, props[1]);
        } else {
            const parsedNumber = parsePhoneNumber(value);
            if (parsedNumber) {
                const countryCode = get(parsedNumber, 'country');
                if (!includes(constants.PHONE_NUMBER_ALLOWED_COUNTRIES, countryCode)) {
                    return renderFieldError(props[2], constants.NOT_SUPPORTED_FIELD_ERROR_KEY, '', {}, props[1]);
                }
            }
        }
    }
    return undefined;
};

/**
 * Render the field level error
 *
 * @param {object|string} fieldName Field Name
 * @param {string} parentFieldKey Validation Parent Field key
 * @param {Array|string} parentFieldSubKeys Validation Parent Field keys Default []
 * @param {object} params Params for error rendering Default {}
 * @param {object} formProps Form props
 *
 * @returns {Function} Field Error localised
 */
const renderFieldError = (fieldName, parentFieldKey, parentFieldSubKeys = [], params = {}, formProps = {}) => {
    fieldName = sanitizeFormFieldName(fieldName);
    const parentFieldSubKeysJoined = !isEmpty(parentFieldSubKeys) ? `.${parentFieldSubKeys.join('.')}` : '';
    const formName = get(formProps, 'form', '').replace(/_form.*$/, '_form');
    let defaultMessageKeys = [];
    if (parentFieldSubKeysJoined) {
        defaultMessageKeys.push(`fieldErrors.common.${parentFieldKey}.default${parentFieldSubKeysJoined}`);
    }
    defaultMessageKeys.push(`fieldErrors.common.${parentFieldKey}.default.default`);
    return i18n.t(
        [
            `fieldErrors.${formName}.${parentFieldKey}.${fieldName}${parentFieldSubKeysJoined}`,
            `fieldErrors.common.${parentFieldKey}.${fieldName}${parentFieldSubKeysJoined}`,
            ...defaultMessageKeys,
        ],
        params
    );
};

/**
 * Required validation
 * Do the required validation on field
 *
 * @param {string} value Input value
 * @param {object}  props Field Props
 *
 * @returns {function(*=): string|undefined} Validation message
 */
export const required = (value, ...props) =>
    !isEmpty(value) || typeof value === 'number'
        ? undefined
        : renderFieldError(props[2], constants.REQUIRED_FIELD_ERROR_KEY, '', {}, props[1]);

/**
 * Sanitize the form field name
 * Remove square brackets and everything inside square brackets
 * Fields with square bracket in name would be a field in array of fields
 *
 * @param {string} fieldName Field Name
 *
 * @returns {string} Updated Field Name
 */
const sanitizeFormFieldName = fieldName => fieldName?.replace(/ *\[[^\]]*]/g, '');

/**
 * URL validation
 *
 * @param {string}  value       Input value
 * @param {Array}   props       Parameters
 *
 * @returns {string}    Error message if validation fails
 */
/* eslint-disable */
export const url = (value, ...props) =>
    value &&
    !/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/.test(
        value
    )
        ? renderFieldError(props[2], constants.INVALID_FIELD_ERROR_KEY, [], {}, props[1])
        : undefined;
/* eslint-enable */

/**
 * Organisation Settings Component
 * Handles the organisation settings view and functions related to loading organisation settings
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import { React, connect, PropTypes, useState, useMemo } from '../../../includes/exports/react';
import AddEditPlaceholder from '../../shared/Placeholders/AddEditPlaceholder';
import AddEditTemplate from '../../../components/shared/Templates/addEdit';
import {
    addCommunicationTemplate,
    deleteCommunicationTemplate,
    getCommunicationTemplates,
    updateCommunicationTemplate,
} from '../../../includes/redux/actions/shared/communication';
import DefaultSettings from '../../shared/DefaultSettings';
import Templates from '../../shared/Templates';
import get from 'lodash/get';
import Placeholders from '../../shared/Placeholders';
import SettingsList from '../../shared/SettingsList';
import { Collapse } from 'antd';
import { updateOrganisationSettings } from '../../../includes/redux/actions/customer/organisation';
import { useCheckEditPermission } from '../../../includes/utils/hooks';
import { useWindowWidth } from '@react-hook/window-size';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import SendTestEmail from '../../shared/SendTestEmail';
import { sendTestEmail } from '../../../includes/redux/actions/shared/account';
import { TEMPLATE } from 'includes/constants/permissions';

const { Panel } = Collapse;

/**
 * Organisation Settings Component
 * Render the organisation settings component
 *
 * @returns    {string}    Organisation listing view
 */
const OrganisationSettings = props => {
    const width = useWindowWidth();
    const [selectedMenu, setSelectedMenu] = useState('settings');
    const [initialValues, setInitialValues] = useState();
    const [listView, setListView] = useState(true);
    const allowCustomEmailSettings = get(props.accountDetails, 'allow_custom_email_settings', false);

    useCheckEditPermission();

    const menuChange = selectedMenu => {
        setSelectedMenu(selectedMenu);
        // reset to list view
        setListView(true);
    };
    // to strip '$', '{', and '}' from placeholder key
    const initialValuesForForm = useMemo(
        () => ({ ...initialValues, key: get(initialValues, 'key', '').replace(/[${}]/g, '') }),
        [initialValues]
    );

    /**
     * Render the template based on slug
     *
     * @param {string} menu Menu slug
     */
    const renderTemplates = (menu = '') => {
        const menuSlug = menu || selectedMenu;
        const permissionSet = {
            email: TEMPLATE.EMAIL,
            ivr: TEMPLATE.IVR,
            sms: TEMPLATE.SMS,
            whatsapp: TEMPLATE.WHATSAPP,
        }[menuSlug];
        switch (menuSlug) {
            case 'email':
            case 'ivr':
            case 'sms':
                return listView ? (
                    <Templates
                        {...props}
                        setListView={setListView}
                        setInitialValues={setInitialValues}
                        type={menuSlug}
                        permissionSet={permissionSet}
                        key={menuSlug}
                    />
                ) : (
                    <AddEditTemplate
                        {...props}
                        setListView={setListView}
                        initialValues={initialValuesForForm}
                        type={menuSlug}
                    />
                );
            case 'whatsapp':
                return listView ? (
                    <Templates
                        {...props}
                        setListView={setListView}
                        setInitialValues={setInitialValues}
                        type={menuSlug}
                        permissionSet={permissionSet}
                        disableTemplateDelete
                    />
                ) : (
                    <AddEditTemplate
                        {...props}
                        setListView={setListView}
                        initialValues={initialValuesForForm}
                        type={menuSlug}
                    />
                );
            case 'placeholder':
                return listView ? (
                    <Placeholders {...props} setListView={setListView} setInitialValues={setInitialValues} />
                ) : (
                    <AddEditPlaceholder
                        {...props}
                        setListView={setListView}
                        organisationId={props.organisationId}
                        initialValues={initialValuesForForm}
                    />
                );
            case 'settings':
                return <DefaultSettings {...props} />;
            case 'sendTestEmail':
                return allowCustomEmailSettings ? <SendTestEmail {...props} /> : null;
            default:
                return null;
        }
    };

    const getLocale = path => props.t(`customerMessages.organisationSettings.${path}`);

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper settings-wrapper">
            <h2 className="page-title">{getLocale('pageTitle')}</h2>
            {width > 991 ? (
                <div className="white-box-wrapper full-wrapper no-mob">
                    <span className="settings-menu">
                        <SettingsList
                            selectedMenu={selectedMenu}
                            onChange={menuChange}
                            allowCustomEmailSettings={allowCustomEmailSettings}
                        />
                    </span>
                    <span className="settings-edit">{renderTemplates()}</span>
                </div>
            ) : (
                <section className="white-box-wrapper mob-only">
                    <Collapse
                        className="collapsible-settings-menu"
                        accordion
                        expandIcon={() => (
                            <img
                                src={require('../../../assets/images/transparent-right-arrow.svg')}
                                alt="right-arrow"
                            />
                        )}
                        expandIconPosition="right"
                        destroyInactivePanel
                        activeKey={[selectedMenu]}
                        onChange={pan => menuChange(pan || '')}
                    >
                        <Panel header={getLocale('panel.default')} key="settings">
                            {renderTemplates('settings')}
                        </Panel>
                        <Panel header={getLocale('panel.emailTemplates')} key="email">
                            {renderTemplates('email')}
                        </Panel>
                        <Panel header={getLocale('panel.smsTemplates')} key="sms">
                            {renderTemplates('sms')}
                        </Panel>
                        <Panel header={getLocale('panel.ivrTemplates')} key="ivr">
                            {renderTemplates('ivr')}
                        </Panel>
                        <Panel header={getLocale('panel.whatsappTemplates')} key="whatsapp">
                            {renderTemplates('whatsapp')}
                        </Panel>
                        <Panel header={getLocale('panel.placeholders')} key="placeholder">
                            {renderTemplates('placeholder')}
                        </Panel>
                        {allowCustomEmailSettings ? (
                            <Panel header={getLocale('panel.default')} key="sendTestEmail">
                                {renderTemplates('sendTestEmail')}
                            </Panel>
                        ) : null}
                    </Collapse>
                </section>
            )}
        </div>
    );
};
/**
 * Prop Types
 */
OrganisationSettings.propTypes = {
    accountDetails: PropTypes.object,
    organisationId: PropTypes.string,
    t: PropTypes.func,
    types: PropTypes.array,
};
// connect to store
export default connect(
    state => ({
        ...state.communication,
        organisationId: state.organisation.selectedOrganisationId,
        types: state.communication.communicationTypes,
        templates: state.communication.communicationTemplates,
        templateTypes: state.communication.templateTypes,
        isLoading: state.communication.isLoading,
        isTypesLoading: state.communication.isTypesLoading,
        isTemplateTypesLoading: state.communication.isTemplateTypesLoading,
        isLoadingDetails: state.communication.isLoadingDetails,
        isLoadingAdd: state.communication.isLoadingAdd,
        defaultSettings: state.organisation.selectedOrganisation,
        isOrganisationSettingsUpdating: state.organisation.isOrganisationSettingsUpdating,
        userDetails: state.user.userDetails,
        accountDetails: state.account.accountDetails,
        defaultDomain: get(state, 'settings.siteSettings.default_domain', ''),
        disallowedSubdomains: get(state, 'settings.siteSettings.disallowed_subdomains', []),
        disallowedFromEmailUsernames: get(state, 'settings.siteSettings.disallowed_from_email_usernames', []),
        defaultEmailDomain: get(state, 'settings.siteSettings.default_email_domain', ''),
    }),
    {
        addCommunicationTemplate,
        deleteCommunicationTemplate,
        getCommunicationTemplates,
        sendTestEmail,
        updateCommunicationTemplate,
        updateOrganisationSettings,
    }
)(withTranslation()(OrganisationSettings));

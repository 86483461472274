/**
 * Payment overview graph Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import React from 'react';
import { PropTypes } from 'prop-types';
import { get, isEmpty } from 'lodash';
import { withTranslation } from 'react-i18next';

import Loader from '../../../shared/Loader';
import { paymentOverviewGraphOptions } from '../../../../includes/charts/paymentOverview';
import { ORGANISATION_IS_FETCHING_DATA } from '../../../../includes/constants/keys/response';
import { DASHBOARD } from 'includes/constants/permissions';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useCurrencySymbol from 'includes/hooks/useCurrencySymbol';

/**
 * Payment overview graph Component
 */
const PaymentOverview = props => {
    const getCurrencySymbol = useCurrencySymbol();
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    /**
     * Get the payment overview graph options
     * Adds the data points
     **/
    const getPaymentOverviewGraphOptions = () => {
        const currencySymbol = getCurrencySymbol();

        if (!isEmpty(props.paymentOverview)) {
            paymentOverviewGraphOptions.subtitles[0].text =
                'PART PAID ' +
                getNumberFormattedAsCurrency(props.paymentOverview.part_paid_invoices.amount_short) +
                ' from ' +
                parseInt(props.paymentOverview.part_paid_invoices.count) +
                ' accounts';
            paymentOverviewGraphOptions.data[0].dataPoints = [
                { name: props.collectedText, y: parseFloat(props.paymentOverview.full_paid_invoices.amount) },
                { name: 'Part Paid', y: parseFloat(props.paymentOverview.part_paid_invoices.amount) },
                { name: 'Overdue', y: parseFloat(props.paymentOverview.overdue_invoices.amount) },
            ];
            paymentOverviewGraphOptions.toolTip.content = `<b>{name}:</b> ${currencySymbol}{y} (#percent%)`;
        }

        return paymentOverviewGraphOptions;
    };

    /**
     * Check organisation data fetching status
     *
     * @returns {boolean}
     */
    const isFetchingData = () => !!get(props.selectedOrganisation, ORGANISATION_IS_FETCHING_DATA);

    const renderGraphView = () => {
        if (props.isGraphDataLoading) {
            return <Loader />;
        }
        if (isEmpty(props.paymentOverview)) {
            return (
                <props.infoMessage
                    message={
                        isFetchingData()
                            ? props.t('customerMessages.dashboard.fetchingDataMessage')
                            : props.t('appCommonMessages.noDataToShow')
                    }
                    showLoader={isFetchingData()}
                />
            );
        }
        return <props.canvasJSChart options={getPaymentOverviewGraphOptions()} />;
    };

    return (
        <div className={props.customClass ? props.customClass : `box-wrapper full-wrapper white-box-wrapper`}>
            <div className="box-title-wrapper">
                <h3 className="page-title">Payment Overview</h3>
            </div>
            <PermissionGuardLayout requiredPermission={DASHBOARD.GRAPH.PAYMENT_OVERVIEW} layout="section">
                {renderGraphView()}
            </PermissionGuardLayout>
        </div>
    );
};

/**
 * Proptypes
 */
PaymentOverview.propTypes = {
    canvasJsChart: PropTypes.func,
    collectedText: PropTypes.string,
    customClass: PropTypes.string,
    isGraphDataLoading: PropTypes.bool,
    infoMessage: PropTypes.func,
    paymentOverview: PropTypes.object,
    selectedOrganisation: PropTypes.object,
    t: PropTypes.func,
};

export default withTranslation()(PaymentOverview);

import { get } from 'lodash';

import { CACHE_INTERNAL_PAYMENT_GATEWAYS_KEY } from 'includes/constants';
import useCachedData from './useCachedData';
import PaymentGatewaysApi from 'includes/services/shared/paymentGateways';
import { GET_PAYMENT_GATEWAYS } from 'includes/constants/mappings/success';

/**
 * useInternalPaymentGateways
 *
 * fetches, caches and returns internal payment gateways
 *
 * @since 2.8.0
 */
export default function useInternalPaymentGateways(disableApiCall = false) {
    return useCachedData(
        CACHE_INTERNAL_PAYMENT_GATEWAYS_KEY,
        () => PaymentGatewaysApi.getPaymentGateways(undefined, undefined, { is_internal: true }),
        {
            selector: res => get(res, GET_PAYMENT_GATEWAYS),
            disableApiCall,
        }
    );
}

import { Icon, Menu } from 'antd';
import { find } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import BankMandateSVG from 'assets/images/bank_mandate.svg';
import PaymentMethodDefaultSVG from 'assets/images/payment-method-default.svg';
import PaymentMethodType from 'components/PaymentGateway/components/PaymentMethodType';
import PaymentMethodStatus from 'components/customer/PaymentInformation/components/PaymentMethods/components/PaymentMethodStatus';
import { PAYMENT_METHOD_STATUS } from 'includes/constants';
import usePaymentMethodStatuses from 'includes/hooks/usePaymentMethodStatuses';
import PaymentMethodBase from '../PaymentMethodBase';
import PaymentScheme from '../PaymentScheme';
import CountryLabel from 'components/CountryLabel';

/**
 * Payment method mandate
 *
 * Responsible for rendering a Card UI of a bank mandate
 *
 * @since 2.8.0
 */
export default function PaymentMethodMandate({
    accountHolderName,
    bankName,
    countryId,
    expiryDate: _expiryDate,
    isDefault,
    mandateName,
    onDelete,
    onEdit,
    onMakeDefault,
    onViewDetails,
    schemeId,
    statusId,
    typeId,
}) {
    const { data: paymentMethodStatuses } = usePaymentMethodStatuses(true);

    const status = useMemo(() => {
        if (!paymentMethodStatuses || paymentMethodStatuses.length === 0) return null;

        return find(paymentMethodStatuses, { id: statusId });
    }, [statusId]);

    // Memoise the card's expiry status and formatted string
    const { isExpired, expiryDate } = useMemo(() => {
        if (_expiryDate) {
            const date = moment(_expiryDate, 'YYYY-MM-DD').endOf('month');
            return { isExpired: date.isBefore(moment(new Date())), expiryDate: date.format('DD/MM/YYYY') };
        }

        return { isExpired: false, expiryDate: null };
    }, [_expiryDate]);

    const { t } = useTranslation();

    const icon = <img style={{ height: 50, width: 50 }} src={BankMandateSVG} alt={bankName} />;

    const description = (
        <div className="">
            <div>{mandateName}</div>
            <div className="uppercase text-sm text-gray-400">
                {bankName}{' '}
                <span className="capitalize">
                    <CountryLabel id={countryId} />
                </span>
            </div>
        </div>
    );

    const validThruSection = expiryDate ? (
        <div className={`flex flex-col ${isExpired ? 'text-red-500' : ''}`}>
            <div className="text-xs">
                {isExpired
                    ? t('customerMessages.paymentMethod.mandate.expiredOn', { date: expiryDate })
                    : t('customerMessages.paymentMethod.mandate.expiresOn', { date: expiryDate })}
            </div>
        </div>
    ) : null;

    const tags = (
        <>
            <PaymentMethodType id={typeId} />
            <PaymentScheme id={schemeId} />
            <PaymentMethodStatus id={statusId} />
        </>
    );

    const isCancelled = status?.slug === PAYMENT_METHOD_STATUS.CANCELLED;

    return (
        <PaymentMethodBase
            accountHolderName={accountHolderName}
            description={description}
            icon={icon}
            overlayMenu={
                <Menu>
                    {status?.slug === PAYMENT_METHOD_STATUS.ACTIVE && !isDefault ? (
                        <Menu.Item
                            onClick={() => onMakeDefault()}
                            disabled={isExpired}
                            className="flex flex-row gap-1 flex-start align-middle items-center"
                        >
                            <img src={PaymentMethodDefaultSVG} />
                            <span>{t('customerMessages.paymentMethod.action.makeDefault')}</span>
                        </Menu.Item>
                    ) : null}
                    <Menu.Item onClick={onViewDetails} className="flex flex-row flex-start align-middle items-center">
                        <Icon type="eye" />
                        <span>{t('customerMessages.paymentMethod.action.view')}</span>
                    </Menu.Item>
                    <Menu.Item
                        onClick={onEdit}
                        disabled={isExpired || isCancelled}
                        className="flex flex-row flex-start align-middle items-center"
                    >
                        <Icon type="edit" />
                        <span>{t('customerMessages.paymentMethod.action.edit')}</span>
                    </Menu.Item>
                    <Menu.Item
                        onClick={onDelete}
                        disabled={isCancelled}
                        className="flex flex-row flex-start items-center"
                    >
                        <Icon type="stop" />
                        <span>{t('customerMessages.paymentMethod.action.cancel')}</span>
                    </Menu.Item>
                </Menu>
            }
            tags={tags}
            validity={validThruSection}
        />
    );
}

PaymentMethodMandate.propTypes = {
    accountHolderName: PropTypes.string,
    bankName: PropTypes.string,
    countryId: PropTypes.string,
    expiryDate: PropTypes.string,
    isDefault: PropTypes.bool,
    mandateName: PropTypes.string,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onMakeDefault: PropTypes.func,
    onViewDetails: PropTypes.func,
    schemeId: PropTypes.string,
    statusId: PropTypes.string,
    typeId: PropTypes.string,
};

import { CACHE_PAYMENT_METHOD_SCHEMES } from 'includes/constants';
import { GET_PAYMENT_METHOD_SCHEMES } from 'includes/constants/mappings/success';
import PaymentGatewaysApi from 'includes/services/shared/paymentGateways';
import { get } from 'lodash';
import useCachedData from './useCachedData';

/**
 * Get payment method schemes
 *
 * @param {boolean} disableApiCall Whether to disable api call or not. Default false
 *
 * @returns object Currencies
 *
 * @since 2.8.0
 */
export default function usePaymentMethodSchemes(disableApiCall = false) {
    return useCachedData(CACHE_PAYMENT_METHOD_SCHEMES, () => PaymentGatewaysApi.getPaymentMethodSchemes(), {
        selector: res => get(res, GET_PAYMENT_METHOD_SCHEMES),
        disableApiCall: disableApiCall,
        dependsOnOrg: false,
    });
}

/**
 * useTaxTypes
 * Fetch tax types and caches it
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import TaxesAPI from 'includes/services/taxes';
import useCachedData from './useCachedData';
import { CACHE_TAX_TYPES_KEY } from '../constants';
import { get } from 'lodash';
import { TAX_TYPES } from 'includes/constants/mappings/success';

/**
 * Fetches tax types if cached else call the API and caches the result
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useTaxTypes = () => {
    return useCachedData(CACHE_TAX_TYPES_KEY, () => TaxesAPI.getTaxTypes(), {
        selector: res => get(res, TAX_TYPES),
        dependsOnOrg: false,
    });
};

export default useTaxTypes;

import { useMemo } from 'react';
import { find } from 'lodash';

import useInternalPaymentGateways from './useInternalPaymentGateways';
import { PAYMENT_GATEWAY } from 'includes/constants';

/**
 * Internal Stripe details hook
 * Used to retrieve internal Stripe payment gateway details
 *
 * @returns object containing keys `stripeDetails` which will contain the internal Stripe payment gateway details and `isLoading` with the loading status
 *
 * @since 2.8.0
 */
export default function useInternalStripeDetails() {
    const { data: paymentGateways, isLoading } = useInternalPaymentGateways();

    const stripeDetails = useMemo(() => {
        if (!paymentGateways) return null;

        return find(paymentGateways, { slug: PAYMENT_GATEWAY.STRIPE });
    }, [paymentGateways]);

    return { stripeDetails, isLoading };
}

import { React } from '../../../includes/exports/react';
import { Icon } from 'antd';

import './styles.scss';

const UnderMaintenance = () => (
    <div className="page-wrapper">
        <div className="paycepaid-logo">
            <img
                src={require('../../../assets/images/paycepaid-logo.svg')}
                alt="Paycepaid"
                style={{ width: 300, maxWidth: '90%' }}
            />
        </div>

        <div className="error-page">
            <span className="error-bg">
                <img src={require('../../../assets/images/under-maintenance.svg')} alt="error" />
            </span>
            <span className="error-msg-wrapper right-align">
                <p className="error-msg-title">
                    Under Maintenance <Icon type="setting" className="fnt-sz-50" spin theme="filled" />
                </p>
                <p className="error-msg">
                    Sorry for the inconvenience but {"we're"} performing some maintenance at the moment. If you need to
                    you can always contact us on <a href="mailto:support@paycepaid.com">support@paycepaid.com</a>,
                    otherwise {"we'll"} be back online shortly.
                </p>
            </span>
        </div>
    </div>
);

export default UnderMaintenance;

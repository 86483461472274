/**
 * Segment analytics functions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modules
import { get } from 'lodash';

/**
 * Add user analytic method
 * This is fired when a new user is added to the account
 */
export const addUser = () => {
    track('User Added');
};

/**
 * Cash collected analytic method
 * This is fired when the contact pays an invoice through PaycePaid Payment Page
 *
 * @param {object}  cashCollected    Cash Collected
 */
export const cashCollected = cashCollected => {
    track('Cash Collected', {
        amount: cashCollected,
    });
};

/**
 * Dashboard data analytic method
 * This is fired when the customer dashboard loads
 *
 * @param   {string}    userUniqueId        User Unique Id
 * @param   {object}    dashboardData       Dashboard data
 */
export const dashboardData = (userUniqueId, dashboardData) => {
    identify(userUniqueId, {
        cash_collected: get(dashboardData, 'payment_overview.full_paid_invoices.amount', 0),
        part_paid: get(dashboardData, 'payment_overview.part_paid_invoices.amount', 0),
        overdue_amount: get(dashboardData, 'payment_overview.overdue_invoices.amount', 0),
        overdue_accounts: get(dashboardData, 'overdue_count', 0),
        automated_campaigns: get(dashboardData, 'contacts_type_count.automatic', 0),
        manual_campaigns: get(dashboardData, 'contacts_type_count.manual', 0),
    });
};

/**
 * Purchased analytic method
 * This is fired when the user purchases a subscription
 *
 * @param {object}  planDetails    Plan Details
 */
export const purchasedSubscription = (planDetails = {}) => {
    track('Subscription Paid', {
        type: get(planDetails, 'name'),
        amount: get(planDetails, 'cost'),
    });
};

/**
 * Connect accounting software analytic method
 * This is fired when the user connects an accounting software
 *
 * @param {object}  organisation    Organisation
 */
export const connectedAccountingSoftware = (organisation = {}) => {
    track('Accounting Connected', {
        type: get(organisation, 'accounting_software_data.name'),
    });
};

/**
 * Sends the analytic data over to segment using identify
 *
 * @param {string}  key         Identify Key
 * @param {object}  metadata    Identify Metadata
 */
export const identify = (key, metadata = {}) => {
    if (isTrackingEnabled()) {
        window.analytics.identify(key, metadata);
    }
};

/**
 * Check if segment tracking is enabled
 * If we have a value defined in REACT_APP_SEGMENT_ANALYTICS_ID in ENV variable trackig is enabled
 *
 * @returns  boolean    true|false
 */
export const isTrackingEnabled = () => {
    // Not used anymore
    return false;
};

/**
 * Sign up analytic method
 * This is fired when the user signup
 *
 * @param {object}  userdata    Userdata
 * @param {string}  accountId   Account ID
 */
export const signup = (userdata = {}, accountId = '') => {
    track('Trial Signup');
    identify(get(userdata, 'unique_id'), {
        name: get(userdata, 'full_name'),
        email: get(userdata, 'email'),
        trial_ends: get(userdata, ['accounts', accountId, 'account_details', 'subscription', 'expiry_date']),
    });
};

/**
 * Sends the analytic data over to segment using track
 *
 * @param {string}  key         Tracking Key
 * @param {object}  metadata    Tracking Metadata
 */
export const track = (key, metadata = {}) => {
    if (isTrackingEnabled()) {
        window.analytics.track(key, metadata);
    }
};

/**
 * Custom Hooks
 * This file contains custom hooks used in this application
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

import React, { useCallback, useState, useEffect, useRef } from 'react';
import { debounce, get, isArray, isEmpty } from 'lodash';
import { Icon, Modal } from 'antd';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import * as constants from '../constants';
import i18n from '../i18n';
import { getAllPlaceholders } from '../redux/actions/shared/placeholder';
import { getCampaignDays } from '../redux/actions/customer/reminder';
import {
    getCommunicationTemplates,
    getCommunicationTemplateTypes,
    getCommunicationTypes,
} from '../redux/actions/shared/communication';
import { redirect, getLocalStorageValue, setDataInLocalStorage } from './index';
import { tryDemoCompany } from '../redux/actions/shared/account';
import {
    ORGANISATION_PAYMENT_ACCOUNT_POPUP_SHOWN_KEY,
    USER_CURRENT_ACCOUNT_KEY,
    USER_ROLE_CUSTOMER,
    USER_ROLE_CUSTOMER_MANAGER,
} from '../constants';
import InfoMessage from '../../components/shared/messages/InfoMessage';
import { getPaymentStatuses } from '../slices/payments';
import { getOrderStatuses, getOrderTypes } from '../slices/orders';
import { getPaymentGateways } from '../slices/paymentGateways';

const { confirm, info } = Modal;

/**
 * Fetch the data from store. If Data doesn't exist in store, fetch the data from api
 *
 * @param {string} reducer Reducer to check for data
 * @param {string} dataKey Data key to check
 * @param {string} loadingKey Loading key
 * @param {Function} action Action method
 * @param {boolean} refresh Whether to refresh the data even if exists in store
 * @param {Array|string} dataToSend Data to be send in the API
 * @param {boolean} doCallApi Whether to call API if data doesn't exist in store. Default true
 */
export const useDataFromStore = ({ reducer, dataKey, loadingKey, action, refresh, dataToSend, doCallApi = true }) => {
    const dataFromStore = useSelector(state => get(state, [reducer, dataKey]), shallowEqual);
    const isLoading = useSelector(state => get(state, [reducer, loadingKey]), shallowEqual);

    const dispatch = useDispatch();

    // effect to fetch data from API if not present in store
    useEffect(() => {
        if (doCallApi) {
            if ((isEmpty(dataFromStore) && !isLoading) || refresh) {
                if (!isArray(dataToSend)) {
                    let data = [];
                    if (dataToSend) {
                        data.push(dataToSend);
                    }
                    dataToSend = data; // eslint-disable-line react-hooks/exhaustive-deps
                }
                dispatch(action(...dataToSend));
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return [dataFromStore, isLoading];
};

/**
 * Show the error pop up
 *
 * @param {object} errorPopupConfig Popup configuration
 * @param {boolean} dep Dependency
 */
export const useErrorPopup = (errorPopupConfig, dep = false) => {
    useEffect(() => {
        dep && Modal.error(errorPopupConfig);
    }, [dep]); // eslint-disable-line react-hooks/exhaustive-deps
};

/**
 * Hook to check if the component is on mounting state or already mounted
 *
 * @returns {boolean}
 */
export const useIsMount = () => {
    const isMountRef = useRef(false);
    useEffect(() => {
        isMountRef.current = true;
        return () => {
            isMountRef.current = false;
        };
    }, []);
    return isMountRef.current;
};

/**
 * Check if the business name of the account is updated or not
 * Show error pop up if business name is not updates
 */
export const useUpdateBusinessName = () => {
    let isBusinessNameUpdated = useSelector(
        state => get(state, ['account', 'accountDetails', 'business_name_updated']),
        shallowEqual
    );

    const roles = useSelector(state => get(state, ['user', 'roles']), shallowEqual);
    const currentAccountId = getLocalStorageValue(USER_CURRENT_ACCOUNT_KEY);
    const userRole = get(roles, [currentAccountId, 'slug'], '');

    // if the user is admin or admin manager return
    if ([constants.USER_ROLE_ADMIN, constants.USER_ROLE_ADMIN_MANAGER].includes(userRole)) {
        isBusinessNameUpdated = true;
    }

    let userId = useSelector(state => get(state, ['user', 'userDetails', 'id']), shallowEqual);
    let userEmailVerified = useSelector(state => get(state, ['user', 'userDetails', 'email_verified']), shallowEqual);
    let userPhoneNumberVerified = useSelector(
        state => get(state, ['user', 'userDetails', 'phone_number_verified']),
        shallowEqual
    );

    if (!userId || !userEmailVerified || !userPhoneNumberVerified) {
        isBusinessNameUpdated = true;
    }

    useErrorPopup(
        {
            title: 'Update business name',
            content: 'Please update your business name in the My Account section to continue.',
            icon: <Icon type="smile" style={{ color: '#8ace7d' }} theme="outlined" />,
            onOk: () => redirect('/edit-business-info'),
            okText: 'My Account',
            closable: false,
            maskClosable: false,
            keyboard: false,
        },
        !isBusinessNameUpdated
    );
};

/**
 * Check if the has edit permission or not
 */
export const useCheckEditPermission = () => {
    // const roles = useSelector(state => get(state, ['user', 'roles']), shallowEqual);

    // const subscriptionDetails = useSelector(
    //     state => get(state, ['account', 'accountDetails', 'subscription']),
    //     shallowEqual
    // );

    // const currentAccountId = getLocalStorageValue(USER_CURRENT_ACCOUNT_KEY);

    // const currentAccountRole = get(roles, [currentAccountId, 'slug'], '');

    const hasValidSubscription = true;

    useErrorPopup(
        {
            title: 'Subscription upgrade required',
            content: 'Please upgrade your subscription and then continue with this action.',
            onOk: () => redirect('/plans'),
            okText: 'Subscription plans',
            closable: false,
            maskClosable: false,
            keyboard: false,
        },
        !hasValidSubscription
    );
};

/**
 * Fetch the communication data like communication template types, communication types and communication templates
 * from store. If Data doesn't exist in store, fetch the data from api
 *
 * @param {string} organisationId Organisation Id
 * @param {boolean} loadCommunicationTemplates Whether to load communication templates. Default true
 */
export const useCommunicationDataFromStore = (organisationId, loadCommunicationTemplates = true) => {
    // get the communication template types
    useDataFromStore({
        reducer: 'communication',
        dataKey: 'templateTypes',
        loadingKey: 'isTemplateTypesLoading',
        action: getCommunicationTemplateTypes,
        dataToSend: organisationId ? organisationId : null,
    });

    // get the communication types
    useDataFromStore({
        reducer: 'communication',
        dataKey: 'communicationTypes',
        loadingKey: 'isTypesLoading',
        action: getCommunicationTypes,
    });

    // get the communication templates
    useDataFromStore({
        reducer: 'communication',
        dataKey: 'allCommunicationTemplates',
        action: getCommunicationTemplates,
        loadingKey: 'isAllCommunicationTemplatesLoading',
        dataToSend: [organisationId, '', { page_size: 0, get_defaults: true, group_by_template_type: true }, true],
        doCallApi: loadCommunicationTemplates,
    });
};

/**
 * Fetch the placeholder data from store. If Data doesn't exist in store, fetch the data from api
 *
 * @param {string} organisationId Organisation Id
 */
export const usePlaceholderDataFromStore = organisationId => {
    // get the placeholders
    useDataFromStore({
        reducer: 'placeholder',
        dataKey: 'allPlaceholders',
        action: getAllPlaceholders,
        loadingKey: 'isAllPlaceholdersLoading',
        dataToSend: [organisationId, { page_size: 0 }],
    });
};

/**
 * Fetch the campaign days from store. If Data doesn't exist in store, fetch the data from api
 */
export const useCampaignDaysDataFromStore = () => {
    // get the campaign days
    useDataFromStore({
        reducer: 'reminder',
        dataKey: 'campaignDays',
        loadingKey: 'isCampaignDaysLoading',
        action: getCampaignDays,
    });
};

/**
 * Show an add organisation popup to show the user that they need to add the organisation before accessing the page
 *
 * @param {string} organisationId
 */
export const useAddOrganisationPopup = organisationId => {
    let isAdmin = useSelector(state => get(state, ['account', 'isAdmin']), shallowEqual);
    useErrorPopup(
        {
            title: 'Add Organisation',
            content: 'You need to add an organisation before you can view the campaigns',
            onOk: () => redirect('/organisation/add'),
            okText: 'Add Organisation',
            closable: false,
            maskClosable: false,
            keyboard: false,
        },
        !organisationId && !isAdmin
    );
};

/**
 * Show the try demo company popup
 *
 * @param {string} accountId Account Id
 */
export const useTryDemoCompanyPopup = accountId => {
    const [isDemoCompanyModalOpen, setIsDemoCompanyModalOpen] = useState(true);
    const currentUserRole = useCurrentRoleSlug();

    let isNotificationModalOpen = useSelector(
        state => get(state, ['appNotification', 'isNotificationModalOpen']),
        shallowEqual
    );
    let isBusinessNameUpdated = useSelector(
        state => get(state, ['account', 'accountDetails', 'business_name_updated']),
        shallowEqual
    );

    let userEmailVerified = useSelector(state => get(state, ['user', 'userDetails', 'email_verified']), shallowEqual);
    let userPhoneNumberVerified = useSelector(
        state => get(state, ['user', 'userDetails', 'phone_number_verified']),
        shallowEqual
    );

    let demoCompanyOptions = useSelector(
        state => get(state, ['account', 'accountDetails', 'demo_company']),
        shallowEqual
    );

    const subscriptionExpiresIn = useSelector(
        state => get(state, ['account', 'accountDetails', 'subscription', 'expires_in'], ''),
        shallowEqual
    );
    const dispatch = useDispatch();

    const showLoadingModal = () =>
        info({
            className: 'demo-company-please-wait-modal',
            content: <InfoMessage message={i18n.t('sharedMessages.demoCompany.pleaseWait')} showLoader={true} />,
            icon: <></>,
            okButtonProps: { disabled: true, className: 'demo-company-modal-ok-hidden' },
            closable: false,
            maskClosable: false,
            keyboard: false,
        });

    useEffect(() => {
        if (
            isBusinessNameUpdated &&
            currentUserRole === USER_ROLE_CUSTOMER &&
            userEmailVerified &&
            userPhoneNumberVerified &&
            !isNotificationModalOpen &&
            get(demoCompanyOptions, 'can_try') &&
            get(demoCompanyOptions, 'show_try') &&
            !get(demoCompanyOptions, 'tried') &&
            subscriptionExpiresIn &&
            isDemoCompanyModalOpen
        ) {
            confirm({
                title: i18n.t('sharedMessages.demoCompany.title'),
                content: i18n.t('sharedMessages.demoCompany.content'),
                okText: i18n.t('sharedMessages.demoCompany.button.try'),
                cancelText: i18n.t(`sharedMessages.demoCompany.button.later`),
                visible: isDemoCompanyModalOpen,
                onOk: () => {
                    const loadingModal = showLoadingModal();
                    dispatch(tryDemoCompany(accountId, true, false, loadingModal));
                    setIsDemoCompanyModalOpen(false);
                },
                onCancel: () => {
                    const loadingModal = showLoadingModal();
                    dispatch(tryDemoCompany(accountId, false, true, loadingModal, subscriptionExpiresIn));
                    setIsDemoCompanyModalOpen(false);
                },
                closable: false,
                maskClosable: false,
                keyboard: false,
            });
        }
    }, [isNotificationModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps
};

/**
 * Check if the payment account has been set up for the organisation
 * Show popup to select the payment account if not set up
 *
 * @param {Function} callback Callback function onOk Click
 */
export const useUpdateOrganisationPaymentAccount = callback => {
    let organisationPaymentAccountSetup = useSelector(
        state => get(state, ['organisation', 'selectedOrganisation', 'has_payment_account_set']),
        shallowEqual
    );

    let isBusinessNameUpdated = useSelector(
        state => get(state, ['account', 'accountDetails', 'business_name_updated']),
        shallowEqual
    );

    const roles = useSelector(state => get(state, ['user', 'roles']), shallowEqual);
    const currentAccountId = getLocalStorageValue(USER_CURRENT_ACCOUNT_KEY);
    const hasOrganisationPaymentAccountPopupShown = getLocalStorageValue(ORGANISATION_PAYMENT_ACCOUNT_POPUP_SHOWN_KEY);
    const userRole = get(roles, [currentAccountId, 'slug'], '');

    // if the user is admin or admin manager or debtor return
    if ([constants.USER_ROLE_ADMIN, constants.USER_ROLE_ADMIN_MANAGER, constants.USER_ROLE_DEBTOR].includes(userRole)) {
        organisationPaymentAccountSetup = true;
    }

    let isManualOrganisation = useSelector(
        state => get(state, ['organisation', 'selectedOrganisation', 'is_manual']),
        shallowEqual
    );
    let userId = useSelector(state => get(state, ['user', 'userDetails', 'id']), shallowEqual);
    let userEmailVerified = useSelector(state => get(state, ['user', 'userDetails', 'email_verified']), shallowEqual);
    let userPhoneNumberVerified = useSelector(
        state => get(state, ['user', 'userDetails', 'phone_number_verified']),
        shallowEqual
    );
    let selectedOrganisationId = useSelector(
        state => get(state, ['organisation', 'selectedOrganisationId']),
        shallowEqual
    );

    let isFetchingData = useSelector(
        state => get(state, ['organisation', 'selectedOrganisation', 'is_fetching_data']),
        shallowEqual
    );

    if (
        !selectedOrganisationId ||
        isFetchingData ||
        isManualOrganisation ||
        !userId ||
        !userEmailVerified ||
        !userPhoneNumberVerified ||
        !isBusinessNameUpdated ||
        hasOrganisationPaymentAccountPopupShown
    ) {
        organisationPaymentAccountSetup = true;
    }

    // set a local storage key so that we don't need to show the popup in this session
    if (!organisationPaymentAccountSetup) {
        setDataInLocalStorage({ [constants.ORGANISATION_PAYMENT_ACCOUNT_POPUP_SHOWN_KEY]: true });
    }

    useErrorPopup(
        {
            title: 'Select Organisation Payment Account',
            content: 'Please select the payment account to receive payments on your accounting software.',
            icon: <Icon type="smile" style={{ color: '#8ace7d' }} theme="outlined" />,
            onOk: () => {
                callback(true);
            },
            okText: 'Set Payment Account',
            closable: false,
            maskClosable: false,
            keyboard: false,
        },
        !organisationPaymentAccountSetup
    );
};

/**
 * Add required script when a component is mounted
 *
 * @param {string}      url URL of the script to be loaded
 * @param {Function}    callback callback to call after script is loaded
 */
export const useScript = (url, callback) => {
    useEffect(() => {
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);
        script.onload = function() {
            callback(true);
        };

        return () => {
            document.body.removeChild(script);
        };
    }, [url]); // eslint-disable-line react-hooks/exhaustive-deps
};

/**
 * Get the order statuses
 */
export const useOrderStatuses = () => {
    return useDataFromStore({
        reducer: 'orders',
        dataKey: 'orderStatuses',
        loadingKey: 'loadingOrderStatuses',
        action: getOrderStatuses,
    });
};

/**
 * Get the order types
 */
export const useOrderTypes = () => {
    return useDataFromStore({
        reducer: 'orders',
        dataKey: 'orderTypes',
        loadingKey: 'loadingOrderTypes',
        action: getOrderTypes,
    });
};

/**
 * Get the payment gateways
 *
 * @param {string} organisationId Organisation Id
 */
export const usePaymentGateways = organisationId => {
    return useDataFromStore({
        reducer: 'paymentGateways',
        dataKey: 'paymentGateways',
        loadingKey: 'loadingPaymentGateways',
        action: getPaymentGateways,
        dataToSend: organisationId,
    });
};

/**
 * Get the payment statuses
 */
export const usePaymentStatuses = () => {
    return useDataFromStore({
        reducer: 'payments',
        dataKey: 'paymentStatuses',
        loadingKey: 'loadingPaymentStatuses',
        action: getPaymentStatuses,
    });
};

/**
 * Get current user role slug
 */
export const useCurrentRoleSlug = () => {
    const roles = useSelector(state => get(state, ['user', 'roles']), shallowEqual);
    const currentAccountId = getLocalStorageValue(USER_CURRENT_ACCOUNT_KEY);
    return get(roles, [currentAccountId, 'slug'], '');
};

/**
 * Get viewing account type
 */
export const useViewingAccountType = () => {
    const viewingAccount = useSelector(state => state.account.userAccountDetails);
    return get(viewingAccount, 'type_role', '');
};

/**
 * Restrict the customer manager from accessing the page
 * Redirect to page not found if the user is customer manager
 *
 * @param {boolean} isTrue Boolean condition
 */
export const useRestrictCustomerManager = (isTrue = true) => {
    const currentRoleSlug = useCurrentRoleSlug();
    //effect to redirect user to page not found, if the user is a customer manager
    useEffect(() => {
        if (currentRoleSlug === USER_ROLE_CUSTOMER_MANAGER && isTrue) {
            redirect('/page-not-found');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

/**
 * Wraps the passed fetch methods in a debounce method to restrict API calls while
 * the user is typing and initiates an API call when done
 *
 *
 * @param {Promise} method
 * @param {object} config
 * @param {Array} deps
 *
 * @returns {Array} [debouncedQuery, options]
 */

export const useAutoComplete = (
    method,
    {
        selector = res => res,
        initialSearchString = '',
        callback = () => {},
        disableInitialTrigger = false,
        initialOptions,
    },
    deps = []
) => {
    const [options, setOptions] = useState(initialOptions ? initialOptions : []);
    const [isLoading, setIsLoading] = useState(false);

    const fetchOptions = async (searchParam = '') => {
        setIsLoading(true);
        const res = await method(searchParam);
        // .then(res => {
        try {
            const newOptions = selector(res);
            setOptions(newOptions);
            callback(newOptions);
        } finally {
            setIsLoading(false);
        }
        // });
    };

    const delayedQuery = useCallback(
        debounce(val => fetchOptions(val), 500),
        [setOptions, ...deps]
    );

    useEffect(() => {
        if (!disableInitialTrigger) delayedQuery(initialSearchString); // initial search with the given value
    }, [initialSearchString]); // eslint-disable-line react-hooks/exhaustive-deps

    return [delayedQuery, options, isLoading];
};

import { React } from '../../../includes/exports/react';
import Loader from '../../shared/Loader';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const OverviewCard = props => {
    return (
        /* stylelint-disable-next-line */
        <section className={`white-box-wrapper ${props.className}`} style={{ width: props.width }}>
            <ul>
                {props.contents.map(content => (
                    <li key={content.id || content.title}>
                        {props.loading ? (
                            <Loader className="overview-card-loader" />
                        ) : (
                            <>
                                <b className={`count ${get(content, 'className')}`} title={get(content, 'hoverValue')}>
                                    {content.value}
                                </b>
                                <div className={`count-info ${get(content, 'className')}`}>{content.title}</div>
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </section>
    );
};

OverviewCard.propTypes = {
    contents: PropTypes.array,
    className: PropTypes.string,
    loading: PropTypes.bool,
    width: PropTypes.string,
};

OverviewCard.defaultProps = {
    className: '',
    contents: [],
    loading: false,
};

export default OverviewCard;

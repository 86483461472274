/**
 * Events Slice
 *
 * @version 2.2.0
 */

import { EVENTS } from 'includes/constants/mappings/success';
import EventsApi from 'includes/services/events';
import { showApiErrors, showApiSuccess } from 'includes/utils/api';
import { get, isFunction } from 'lodash';
import createAppSlice from './base';

// set the initial state
const initialState = {
    loadingEvents: false,
    loadingEventsAddEdit: false,
    loadingEventsDelete: false,
    events: [],
};

// define the slice
const EventsSlice = createAppSlice('eventsSlice', initialState);
const { setData } = EventsSlice.actions;

/**
 * Add events
 *
 * @param {Array} events Events
 * @param {str} organisationId Organisation Id. Default ''
 * @param {Function} callback Callback function. Default ''
 */
export const addEvents = (events, organisationId = '', callback = '') => async dispatch => {
    try {
        dispatch(setData('loadingEventsAddEdit', true));
        const result = await EventsApi.addEvents(events, organisationId);
        dispatch(setData('events', get(result, EVENTS)));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'EVENT_CALENDAR_FORM', 'EVENTS_CRUD'));
    } finally {
        dispatch(setData('loadingEventsAddEdit'));
    }
};

/**
 * Delete events
 *
 * @param {Array} eventIds Event Ids
 * @param {str} organisationId Organisation Id. Default ''
 * @param {Function} callback Callback function. Default ''
 */
export const deleteEvents = (eventIds, organisationId = '', callback = '') => async dispatch => {
    try {
        dispatch(setData('loadingEventsDelete', true));
        const result = await EventsApi.deleteEvents(eventIds.toString(), organisationId);
        dispatch(setData('events', get(result, EVENTS)));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'EVENT_CALENDAR_FORM', 'EVENTS_CRUD'));
    } finally {
        dispatch(setData('loadingEventsDelete'));
    }
};

/**
 * Get events
 *
 * @param {str} organisationId Organisation Id. Default ''
 * @param {object} searchParams Search Params. Default {}
 */
export const getEvents = (organisationId = '', searchParams = {}) => async dispatch => {
    try {
        dispatch(setData('loadingEvents', true));
        const result = await EventsApi.getEvents(organisationId, searchParams);
        dispatch(setData('events', get(result, EVENTS)));
    } finally {
        dispatch(setData('loadingEvents'));
    }
};

/**
 * Update events
 *
 * @param {Array} events Events
 * @param {str} organisationId Organisation Id. Default ''
 * @param {Function} callback Callback function. Default ''
 */
export const updateEvents = (events, organisationId = '', callback = '') => async dispatch => {
    try {
        dispatch(setData('loadingEventsAddEdit', true));
        const result = await EventsApi.updateEvents(events, organisationId);
        dispatch(setData('events', get(result, EVENTS)));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'EVENT_CALENDAR_FORM', 'EVENTS_CRUD'));
    } finally {
        dispatch(setData('loadingEventsAddEdit'));
    }
};

// export the reducer
export default EventsSlice.reducer;

/**
 * Inventory add/edit Component
 * Renders the inventory add/edit
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

// import required modules
import React, { useEffect } from 'react';
import InventoryBreadCrumbItems from 'components/BreadCrumbItems/Inventory';
import InventoryItemForm from './components/Form';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { addInventoryItem, getInventoryItem, updateInventoryItem } from 'includes/slices/inventory';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUpdateBusinessName } from 'includes/utils/hooks';

const InventoryItemAddEdit = props => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();

    useUpdateBusinessName();

    const { accountId, inventoryItemId } = params;
    const inventoryItem = useSelector(state => state.inventory.inventoryItem);
    const isAdd = props.action === 'add';
    const isAdmin = useSelector(state => state.account.isAdmin);
    const loadingInventoryItem = useSelector(state => state.inventory.loadingInventoryItem);
    const organisationId = useOrganisationId();

    /**
     * Get the inventory item details on load
     */
    useEffect(() => {
        if (!isAdd) {
            dispatch(getInventoryItem(organisationId, inventoryItemId));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the inventory item details url
     *
     * @param {string} inventoryItemId Inventory Item Id
     *
     * @returns {string}
     */
    const getDetailsUrl = inventoryItemId =>
        isAdmin
            ? `/admin/accounts/organisation/inventory/inventory-item/${accountId}/${organisationId}/${inventoryItemId}`
            : `/inventory/inventory-item/${inventoryItemId}`;

    /**
     * Handle the submit of add/edit of inventory item
     */
    const handleSubmit = values => {
        isAdd
            ? dispatch(addInventoryItem(organisationId, values, getDetailsUrl))
            : dispatch(updateInventoryItem(organisationId, get(inventoryItem, 'id'), values, getDetailsUrl));
    };

    return (
        <div className="home-content-wrapper edit-profile-wrapper">
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <InventoryBreadCrumbItems
                        action={props.action}
                        showAddEditBreadCrumb
                        showDetailsBreadCrumb={!isAdd}
                        showDetailsBreadCrumbAsLink
                        showListBreadCrumb
                    />
                </Breadcrumb>
            </div>
            <h2 className="page-title">{t(`titleAndMetas.inventoryItem${isAdd ? 'Add' : 'Edit'}.pageTitle`)}</h2>
            <div className="white-box-wrapper mid-wrapper">
                {loadingInventoryItem ? (
                    <Loader tip={t('appCommonMessages.loadingMessage')} />
                ) : (
                    <InventoryItemForm
                        enableReinitialize
                        initialValues={isAdd ? { discount_type: 1 } : inventoryItem}
                        isAdd={isAdd}
                        onSubmit={handleSubmit}
                    />
                )}
            </div>
        </div>
    );
};

/**
 * Proptypes
 */
InventoryItemAddEdit.propTypes = {
    action: PropTypes.string,
    match: PropTypes.object,
};

export default InventoryItemAddEdit;

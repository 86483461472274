/**
 * Transaction type label component
 * Renders a label representing the type of transaction
 *
 * @since 2.8.0
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

import { TRANSACTIONS_BASE_TYPE } from 'includes/constants';

/**
 * A mapping from type of transaction to color
 *
 * @since 2.8.0
 */
const TYPE_COLOR_MAP = {
    [TRANSACTIONS_BASE_TYPE.CREDIT]: 'green',
    [TRANSACTIONS_BASE_TYPE.DEBIT]: 'red',
};

/**
 * Renders a label representing the type of transaction
 *
 * @since 2.8.0
 */
export default function TransactionTypeLabel({ type }) {
    return <Tag color={TYPE_COLOR_MAP[type] ?? ''}>{type || 'UNKNOWN'}</Tag>;
}

TransactionTypeLabel.propTypes = {
    type: PropTypes.string,
};

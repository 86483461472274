/**
 * Campaigns listing Component.
 *
 * @since 2.0.0
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import { Breadcrumb, Icon, Modal, Tooltip } from 'antd';
import { CAMPAIGN } from 'includes/constants/permissions';
import { CAMPAIGN_TYPE_OPTIONS, CAMPAIGN_TYPE_REMINDER } from 'includes/constants';
import { campaignsColumns } from 'includes/tableColumns/campaigns';
import { deleteCampaign, getCampaigns } from 'includes/slices/campaigns';
import { find, get } from 'lodash';
import { redirect } from 'includes/utils';
import { TABLE_NO_CAMPAIGNS_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import CampaignsBreadCrumbItems from 'components/BreadCrumbItems/Campaigns';
import InfoMessage from 'components/shared/messages/InfoMessage';
import PermissionGuard from 'components/PermissionGuard';
import PropTypes from 'prop-types';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import useAccountId from 'includes/hooks/useAccountId';
import useContactTypes from 'includes/hooks/useContactTypes';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useCampaigns from 'includes/hooks/useCampaigns';
import CampaignAddEdit from 'pages/Campaigns/AddEdit';
import useOrganisationRequired from 'includes/hooks/useOrganisationRequired';

const { confirm } = Modal;

/**
 * Campaigns listing component
 *
 * @since 2.0.0
 */
const CampaignsList = props => {
    const [isSearching, setIsSearching] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [showAddCampaignModal, setShowAddCampaignModal] = useState(false);
    const organisationId = useOrganisationId();
    const shouldDisableApisWithoutOrganisationId = !organisationId ? true : false;
    const { data: contactTypes, isLoading: loadingContactTypes } = useContactTypes(
        shouldDisableApisWithoutOrganisationId
    );
    const { t } = useTranslation();
    const accountId = useAccountId();
    const campaigns = useSelector(state => state.campaigns.campaigns);
    const campaignsPagination = useSelector(state => state.campaigns.campaignsPagination);
    const dispatch = useDispatch();
    const isAdminOrManager = useIsAdminOrManager();
    const loadingCampaignDelete = useSelector(state => state.campaigns.loadingCampaignDelete);
    const loadingCampaigns = useSelector(state => state.campaigns.loadingCampaigns);
    const { reset: clearCampaignsCache } = useCampaigns(true);

    useOrganisationRequired();

    /**
     * Delete campaign
     *
     * @param {string} campaignId Id of the campaign to be deleted.
     *
     * @since 2.0.0
     */
    const deleteCampaignById = campaignId => {
        confirm({
            title: t('messages.campaigns.confirm.delete.title'),
            content: '',
            onOk: () => {
                clearCampaignsCache();
                dispatch(deleteCampaign(organisationId, campaignId));
            },
        });
    };

    /**
     * Format the table data
     *
     * @since 2.0.0
     */
    const getFormattedTableData = useCallback(
        () =>
            campaigns.map(campaign => {
                return {
                    ...campaign,
                    name: (
                        <div className="business-name-avatar">
                            <span>{campaign.name}</span>
                            {campaign.is_default ? (
                                <Tooltip title={getLocaleText('list.info.name')}>
                                    <span style={{ marginLeft: 5, color: '#666' }}>
                                        <Icon type="info-circle" theme="filled" />
                                    </span>
                                </Tooltip>
                            ) : null}
                        </div>
                    ),
                    is_default: t(`messages.${campaign.is_default ? 'yes' : 'no'}`),
                    campaign_type: get(
                        find(CAMPAIGN_TYPE_OPTIONS, {
                            id: campaign.campaign_type,
                        }),
                        'label'
                    ),
                    contact_type_ids: campaign.contact_type_ids
                        ? campaign.contact_type_ids
                              .map(contact_type_id =>
                                  get(
                                      find(contactTypes, {
                                          id: contact_type_id,
                                      }),
                                      'type'
                                  )
                              )
                              .join(', ')
                        : '',
                    send_multiple_reminders: t(`messages.${campaign.send_multiple_reminders ? 'yes' : 'no'}`),
                    actions: (
                        <div className="blue-bg-button">
                            <PermissionGuard requiredPermission={CAMPAIGN.VIEW}>
                                <span>
                                    <Button
                                        className="green-bg-button blue-bg-button"
                                        filled
                                        onClick={() => redirect(getViewLink(campaign?.id))}
                                    >
                                        {getLocaleText('crud.view')}
                                    </Button>
                                </span>
                            </PermissionGuard>
                            <PermissionGuard requiredPermission={CAMPAIGN.DELETE}>
                                <span>
                                    <Button
                                        className="green-bg-button blue-bg-button"
                                        filled
                                        onClick={() => deleteCampaignById(campaign.id)}
                                    >
                                        {getLocaleText('crud.delete')}
                                    </Button>
                                </span>
                            </PermissionGuard>
                        </div>
                    ),
                };
            }),
        [campaigns, contactTypes] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Get the locale text
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @since 2.0.0
     *
     * @returns {string} Locale test
     */
    const getLocaleText = path => t(`messages.campaigns.${path}`);

    /**
     * Get the campaign view link
     *
     * @param {string} campaignId Campaign Id
     *
     * @since 2.0.0
     *
     * @returns {string} View Link
     */
    const getViewLink = campaignId => {
        if (props.campaignType === CAMPAIGN_TYPE_REMINDER) {
            return isAdminOrManager
                ? `/admin/accounts/organisation/campaigns/campaign/${accountId}/${organisationId}/${campaignId}`
                : `/campaigns/campaign/${campaignId}`;
        } else {
            return isAdminOrManager
                ? `/admin/accounts/organisation/payment-plans/plan/${accountId}/${organisationId}/${props.paymentPlanId}/campaigns/campaign/${campaignId}`
                : `/payment-plans/plan/${props.paymentPlanId}/campaigns/campaign/${campaignId}`;
        }
    };

    /**
     * Get memoized campaigns table data
     *
     * @since 2.0.0
     */
    const memoizedCampaignsData = useMemo(() => getFormattedTableData(), [getFormattedTableData]);

    return (
        <div className="home-content-wrapper contacts-wrapper">
            {props.showTitleAndBreadCrumb ? (
                <>
                    <h2 className="page-title">{t('titleAndMetas.campaigns.title')}</h2>
                    <div className="tab-arrow-link breadcrumb-spec">
                        <Breadcrumb>
                            <CampaignsBreadCrumbItems showListBreadCrumb={isAdminOrManager} />
                        </Breadcrumb>
                    </div>
                </>
            ) : null}
            <div className={`full-wrapper box-wrapper ${props.showTitleAndBreadCrumb ? 'white-box-wrapper' : ''}`}>
                <section className="right-menu-wrapper">
                    <div className="right-align">
                        <PermissionGuard requiredPermission={CAMPAIGN.ADD}>
                            {organisationId && (
                                <Button big filled onClick={() => setShowAddCampaignModal(true)}>
                                    {getLocaleText('crud.add')}
                                </Button>
                            )}
                        </PermissionGuard>
                    </div>
                </section>
                <section>
                    <ul className="selector-field">
                        <li>
                            <div className="right-align">
                                <SearchBlock
                                    customDataValues={{
                                        campaign_type: props.campaignType,
                                        payment_plan_id: props.paymentPlanId,
                                    }}
                                    dataValues={[organisationId]}
                                    dispatchMethod
                                    getDataMethod={organisationId ? getCampaigns : null}
                                    isSearching={isSearching}
                                    placeholder={getLocaleText(`search.placeholder`)}
                                    setIsSearching={setIsSearching}
                                    setSearchParam={setSearchParam}
                                />
                            </div>
                        </li>
                    </ul>
                </section>
                <hr />
                <Table
                    className="table-1200"
                    columns={campaignsColumns}
                    customDataValues={{ campaign_type: props.campaignType, payment_plan_id: props.paymentPlanId }}
                    dataSource={memoizedCampaignsData}
                    dataValues={[organisationId]}
                    dispatchMethod
                    getDataMethod={organisationId ? getCampaigns : null}
                    isSearching={isSearching}
                    loading={loadingCampaigns || loadingContactTypes}
                    localeMessage={TABLE_NO_CAMPAIGNS_FOUND_MESSAGE}
                    mobileClassName="table-1200"
                    paginationData={campaignsPagination}
                    rowClassName="pointer"
                    rowKey="id"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    size="middle"
                />
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={loadingCampaignDelete}
                >
                    <InfoMessage message={t(`messages.campaigns.modal.message.delete`)} showLoader={true} />
                </Modal>
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal on-demand-campaign-modal"
                    visible={showAddCampaignModal}
                    onClose={() => setShowAddCampaignModal(false)}
                    onCancel={() => setShowAddCampaignModal(false)}
                >
                    <CampaignAddEdit
                        campaignType={props.campaignType}
                        isAdd={true}
                        paymentPlanId={props.paymentPlanId}
                    />
                </Modal>
            </div>
        </div>
    );
};

CampaignsList.defaultProps = {
    campaignType: 1,
    paymentPlanId: '',
    showTitleAndBreadCrumb: true,
};

CampaignsList.propTypes = {
    campaignType: PropTypes.number,
    paymentPlanId: PropTypes.string,
    t: PropTypes.func,
    showTitleAndBreadCrumb: PropTypes.bool,
};

export default CampaignsList;

/**
 * Tax add/edit Component
 * Renders the tax add/edit
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

// import required modules
import React, { useEffect } from 'react';
import Loader from 'components/shared/Loader';
import PropTypes from 'prop-types';
import TaxesBreadCrumbItems from 'components/BreadCrumbItems/Taxes';
import TaxForm from './components/Form';
import useOrganisationId from '../../../includes/hooks/useOrganisationId';
import useTaxes from 'includes/hooks/useTaxes';
import { addTax, getTax, updateTax } from 'includes/slices/taxes';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import { useTranslation } from 'react-i18next';

const TaxAddEdit = props => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();

    useUpdateBusinessName();

    const { accountId, taxId } = params;
    const { reset: clearTaxesCache } = useTaxes(true);
    const organisationId = useOrganisationId();
    const isAdd = props.action === 'add';
    const isAdmin = useSelector(state => state.account.isAdmin);
    const loadingTax = useSelector(state => state.taxes.loadingTax);
    const taxDetails = useSelector(state => state.taxes.tax);

    /**
     * Get the tax item details on load
     */
    useEffect(() => {
        if (!isAdd) {
            dispatch(getTax(taxId, organisationId));
        }
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the taxes details link
     *
     * @param {string} taxId Tax id
     *
     * @returns {string} Details link
     */
    const getDetailsLink = taxId => {
        clearTaxesCache();
        return isAdmin
            ? `/admin/accounts/organisation/taxes/tax/${accountId}/${organisationId}/${taxId}`
            : `/taxes/tax/${taxId}`;
    };

    /**
     * Handle the submit of add/edit of inventory item
     */
    const handleSubmit = values => {
        isAdd
            ? dispatch(addTax(values, getDetailsLink, organisationId))
            : dispatch(updateTax(get(taxDetails, 'id'), values, getDetailsLink, organisationId));
    };

    return (
        <div className="home-content-wrapper edit-profile-wrapper">
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <TaxesBreadCrumbItems
                        action={props.action}
                        showAddEditBreadCrumb
                        showDetailsBreadCrumb={!isAdd}
                        showDetailsBreadCrumbAsLink
                        showListBreadCrumb
                    />
                </Breadcrumb>
            </div>
            <h2 className="page-title">{t(`titleAndMetas.tax${isAdd ? 'Add' : 'Edit'}.pageTitle`)}</h2>
            <div className="white-box-wrapper mid-wrapper">
                {loadingTax ? (
                    <Loader tip={t('appCommonMessages.loadingMessage')} />
                ) : (
                    <TaxForm
                        enableReinitialize
                        initialValues={isAdd ? {} : taxDetails}
                        isAdd={isAdd}
                        onSubmit={handleSubmit}
                        organisationId={organisationId}
                    />
                )}
            </div>
        </div>
    );
};

/**
 * Proptypes
 */
TaxAddEdit.propTypes = {
    action: PropTypes.string,
    match: PropTypes.object,
};

export default TaxAddEdit;

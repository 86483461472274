/**
 * Zendesk settings Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import Skeleton from 'components/Skeleton';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import ZendeskSettingsForm from 'pages/Zendesk/Settings/components/Form/component';
import { DEFAULT, EMAIL, IVR, SMS, WHATSAPP, ZENDESK_TAGS } from 'includes/constants/keys/request';
import { get, isEmpty } from 'lodash';
import { saveSettings } from 'includes/slices/zendesk';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    ZENDESK_CREATE_TICKET_DEFAULT_VALUE,
    ZENDESK_TICKET_DEFAULT_PRIORITY,
    ZENDESK_TICKET_DEFAULT_STATUS,
    ZENDESK_TICKET_DEFAULT_TYPE,
} from 'includes/constants';
import useZendeskSettings from 'includes/hooks/useZendeskSettings';
import ZendeskIntegrationEnabled from 'components/Zendesk/IntegrationEnabled';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import { Breadcrumb } from 'antd';
import ZendeskBreadCrumbItems from 'components/BreadCrumbItems/Zendesk';

/**
 * Zendesk settings  Component
 */
const ZendeskSettings = props => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isAdminOrManager = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    const {
        data: zendeskSettings,
        isLoading: loadingSettings,
        update: updateZendeskSettingsCache,
    } = useZendeskSettings();

    /**
     * Get the default values of the form
     */
    const getFormDefaults = () => {
        return isEmpty(zendeskSettings)
            ? {
                  email_create_ticket: ZENDESK_CREATE_TICKET_DEFAULT_VALUE,
                  email_ticket_status: get(
                      ZENDESK_TICKET_DEFAULT_STATUS,
                      EMAIL,
                      get(ZENDESK_TICKET_DEFAULT_STATUS, DEFAULT)
                  ),
                  email_ticket_priority: ZENDESK_TICKET_DEFAULT_PRIORITY,
                  email_ticket_type: ZENDESK_TICKET_DEFAULT_TYPE,
                  ivr_create_ticket: ZENDESK_CREATE_TICKET_DEFAULT_VALUE,
                  ivr_ticket_status: get(
                      ZENDESK_TICKET_DEFAULT_STATUS,
                      IVR,
                      get(ZENDESK_TICKET_DEFAULT_STATUS, DEFAULT)
                  ),
                  ivr_ticket_priority: ZENDESK_TICKET_DEFAULT_PRIORITY,
                  ivr_ticket_type: ZENDESK_TICKET_DEFAULT_TYPE,
                  sms_create_ticket: ZENDESK_CREATE_TICKET_DEFAULT_VALUE,
                  sms_ticket_status: get(
                      ZENDESK_TICKET_DEFAULT_STATUS,
                      SMS,
                      get(ZENDESK_TICKET_DEFAULT_STATUS, DEFAULT)
                  ),
                  sms_ticket_priority: ZENDESK_TICKET_DEFAULT_PRIORITY,
                  sms_ticket_type: ZENDESK_TICKET_DEFAULT_TYPE,
                  whatsapp_create_ticket: ZENDESK_CREATE_TICKET_DEFAULT_VALUE,
                  whatsapp_ticket_status: get(
                      ZENDESK_TICKET_DEFAULT_STATUS,
                      WHATSAPP,
                      get(ZENDESK_TICKET_DEFAULT_STATUS, DEFAULT)
                  ),
                  whatsapp_ticket_priority: ZENDESK_TICKET_DEFAULT_PRIORITY,
                  whatsapp_ticket_type: ZENDESK_TICKET_DEFAULT_TYPE,
              }
            : {};
    };

    /**
     * Save the zendesk settings
     *
     * @param {object} values Form Values
     */
    const saveZendeskSettings = values => {
        const tags = values?.tags;
        if (!tags) {
            values[ZENDESK_TAGS] = null;
        }
        dispatch(saveSettings(values, organisationId, updateZendeskSettingsCache));
    };

    return (
        <ZendeskIntegrationEnabled>
            <div className="home-content-wrapper edit-profile-wrapper">
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <ZendeskBreadCrumbItems showSettingsBreadCrumb={isAdminOrManager} />
                    </Breadcrumb>
                </div>
                <h2 className="page-title">{t('titleAndMetas.zendeskSettings.title')}</h2>
                <div className="white-box-wrapper mid-wrapper">
                    {loadingSettings ? (
                        <Skeleton title={false} active paragraph={{ rows: 6 }} />
                    ) : (
                        <ZendeskSettingsForm
                            {...props}
                            initialValues={{ ...getFormDefaults(), ...zendeskSettings }}
                            onSubmit={saveZendeskSettings}
                            enableReinitialize
                        />
                    )}
                </div>
            </div>
        </ZendeskIntegrationEnabled>
    );
};

export default ZendeskSettings;

import useHasRequiredPermission from './useHasRequiredPermission';
import useUserHasAccessPermission from './useUserHasAccessPermission';

/**
 * useGetPermissionWrappedFunction
 *
 * Returns a wrapped function which will only be executed if required permission is available to the logged in user
 * Takes an optional fallback function, which will be evoked if permission is not available
 *
 * @param {string} requiredPermission Required permission to check
 * @param {Function} baseFunction Function to execute if permission exists
 * @param {Function} fallbackFunction Fallback function to run if permission doesn't exist. Default () => {}
 * @returns wrapped function
 *
 * @since 2.8.0
 */
export default function useGetPermissionWrappedFunction(requiredPermission, baseFunction, fallbackFunction = () => {}) {
    const hasPlanPermission = useHasRequiredPermission(requiredPermission);
    const hasAccessPermission = useUserHasAccessPermission(requiredPermission);

    if (!requiredPermission) return baseFunction;

    if (hasPlanPermission) {
        if (hasAccessPermission) {
            return baseFunction;
        } else {
            return fallbackFunction;
        }
    }

    return fallbackFunction;
}

/**
 * Organisation Saga
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import the required modules
import { takeLatest, all, put, call, select } from 'redux-saga/effects';
import { get, isEmpty, isFunction } from 'lodash';
import { stopSubmit } from 'redux-form';
import OrganisationApi from '../../../../services/customer/organisation';
import BaseSagaHandler from '../../core/base';
import * as actionTypes from '../../../../constants/actionTypes';
import * as constants from '../../../../constants';
import { ORGANISATION_DELETE_DEFAULT_ERROR, UNKNOWN_ERROR } from '../../../../constants/messages/errors';
import { setDataInLocalStorage, redirect, getLocalStorageValue, removeLocalStorage } from '../../../../utils';
import * as successMappings from '../../../../constants/mappings/success';
import * as errorMappings from '../../../../constants/mappings/errors';
import * as segmentAnalytics from '../../../../utils/segment';
import { GET_SELECTED_ORGANISATION_SUCCESS } from '../../../../constants/actionTypes';
import * as responseKeys from 'includes/constants/keys/response';

/**
 * Organisation Saga Class. Handles the organisation related operations
 * */
class OrganisationSaga extends BaseSagaHandler {
    /**
     * The Organisation Watcher Saga
     * Watches the redux actions related to organisation and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *organisationWatchers() {
        let context = this;
        yield all([
            yield takeLatest(actionTypes.GET_ARRANGEMENT_DUE_DATA_REQUEST, [context, 'getArrangementDueData']),
            yield takeLatest(actionTypes.GET_CASH_COLLECTED_DATA_REQUEST, [context, 'getCashCollectedData']),
            yield takeLatest(actionTypes.GET_CONTACT_INVOICE_STATUS_TYPE_DATA_REQUEST, [
                context,
                'getContactInvoiceStatusTypeData',
            ]),
            yield takeLatest(actionTypes.GET_DASHBOARD_DATA_REQUEST, [context, 'getDashboardData']),
            yield takeLatest(actionTypes.GET_DEBT_AGE_DEBT_BAND_DATA_REQUEST, [context, 'getDebtAgeAndDebtBandData']),
            yield takeLatest(actionTypes.GET_DEBTOR_ACCOUNTS_REQUEST, [context, 'getDebtorAccounts']),
            yield takeLatest(actionTypes.GET_ORGANISATIONS_REQUEST, [context, 'getOrganisations']),
            yield takeLatest(actionTypes.GET_ORGANISATION_BY_ACCID_REQUEST, [context, 'getOrganisationByAccId']),
            yield takeLatest(actionTypes.GET_ORGANISATION_DETAILS_REQUEST, [context, 'getOrganisationDetails']),
            yield takeLatest(actionTypes.SET_SELECTED_ORGANISATION_REQUEST, [context, 'setSelectedOrganisation']),
            yield takeLatest(actionTypes.ORGANISATION_UPDATE_REQUEST, [context, 'updateOrganisation']),
            yield takeLatest(actionTypes.ORGANISATION_DELETE_REQUEST, [context, 'deleteOrganisation']),
            yield takeLatest(actionTypes.ORGANISATION_ADD_REQUEST, [context, 'addOrganisation']),
            yield takeLatest(actionTypes.SYNC_ORGANISATION_REQUEST, [context, 'syncOrganisation']),
            yield takeLatest(actionTypes.EXPORT_ORGANISATION_REQUEST, [context, 'exportOrganisation']),
            yield takeLatest(actionTypes.UPDATE_ORGANISATION_SETTINGS_REQUEST, [context, 'updateOrganisationSettings']),
            yield takeLatest(actionTypes.EXPORT_ACCOUNT_ORGANISATIONS_REQUEST, [context, 'exportAccountOrganisations']),
            yield takeLatest(actionTypes.GET_CURRENCIES_REQUEST, [context, 'getCurrencies']),
        ]);
    }

    /**
     * Get the arrangement due data
     *
     * Fetches the arrangement due data
     */
    *getArrangementDueData(action) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.getArrangementDueData, action.payload);

            //dispatch the arrangement due data success action
            yield put({
                type: actionTypes.GET_ARRANGEMENT_DUE_DATA_SUCCESS,
                result,
            });
        } catch (errors) {
            yield put({ type: actionTypes.GET_ARRANGEMENT_DUE_DATA_FAILURE, errors });
        }
    }

    /**
     * Get Contact Invoice Status Type Data
     *
     * Fetches the contact and invoice type and status count data
     */
    *getContactInvoiceStatusTypeData(action) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.getContactInvoiceStatusTypeData, action.payload);

            //dispatch the success action
            yield put({
                type: actionTypes.GET_CONTACT_INVOICE_STATUS_TYPE_DATA_SUCCESS,
                result,
            });
        } catch (errors) {
            yield put({ type: actionTypes.GET_CONTACT_INVOICE_STATUS_TYPE_DATA_FAILURE, errors });
        }
    }

    /**
     * Get the cash collected data
     *
     * Fetches the cash collected data
     */
    *getCashCollectedData(action) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.getCashCollectedData, action.payload);

            //dispatch the cash collected data success action
            yield put({
                type: actionTypes.GET_CASH_COLLECTED_DATA_SUCCESS,
                result,
            });
        } catch (errors) {
            yield put({ type: actionTypes.GET_CASH_COLLECTED_DATA_FAILURE, errors });
        }
    }

    /**
     * Get the dashboard data
     *
     * Fetches data required in dashboard
     */
    *getDashboardData(action) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.getDashboardData, action.payload);

            const user = yield select(this.getUser);
            segmentAnalytics.dashboardData(user.unique_id, get(result, 'data.data.dashboard_data'));

            //dispatch the dashboard data success action
            yield put({
                type: actionTypes.GET_DASHBOARD_DATA_SUCCESS,
                result,
            });
        } catch (errors) {
            yield put({ type: actionTypes.GET_DASHBOARD_DATA_FAILURE, errors });
        }
    }

    /**
     * Get the debt band and debt age data
     *
     * Fetches debt band and debt age band graph data
     */
    *getDebtAgeAndDebtBandData(action) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.getDebtAgeAndDebtBandData, action.payload);

            //dispatch the debt age and debt band data success action
            yield put({
                type: actionTypes.GET_DEBT_AGE_DEBT_BAND_DATA_SUCCESS,
                result,
            });
        } catch (errors) {
            yield put({ type: actionTypes.GET_DEBT_AGE_DEBT_BAND_DATA_FAILURE, errors });
        }
    }

    /**
     * Get the debtor accounts
     *
     * Fetches debtor accounts
     */
    *getDebtorAccounts(action) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.getDebtorAccounts, action.payload);

            //dispatch the debt age and debt band data success action
            yield put({
                type: actionTypes.GET_DEBTOR_ACCOUNTS_SUCCESS,
                result,
            });
        } catch (errors) {
            yield put({ type: actionTypes.GET_DEBTOR_ACCOUNTS_FAILURE, errors });
        }
    }

    /**
     * Get the organisations
     *
     * Fetches organisation list
     */
    *getOrganisations(action) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.getOrganisations, action.payload);

            //dispatch the organisations success action
            yield put({
                type: actionTypes.GET_ORGANISATIONS_SUCCESS,
                result,
            });
        } catch (errors) {
            yield put({ type: actionTypes.GET_ORGANISATIONS_FAILURE, errors });
        }
    }
    /**
     * Get the organisations by account ID
     *
     * Fetches organisations of specified account ID
     */
    *getOrganisationByAccId(action) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.getOrganisationsByAccId, action.payload);

            //dispatch the organisations success action
            yield put({
                type: actionTypes.GET_ORGANISATIONS_SUCCESS,
                result,
            });
        } catch (errors) {
            yield put({ type: actionTypes.GET_ORGANISATIONS_FAILURE, errors });

            //dispatch the notification about organisation retrieval error action
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get organisation details
     *
     * Fetches organisation details
     */
    *getOrganisationDetails({ payload }) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.getOrganisationDetails, payload);

            //dispatch the organisations success action
            yield put({ type: actionTypes.GET_ORGANISATION_DETAILS_SUCCESS, result });

            if (get(payload, 'setSelectedOrganisationAfterFetching', false)) {
                let selectedOrganisation = get(result, successMappings.GET_ORGANISATION_DETAILS);
                let selectedOrganisationId = get(selectedOrganisation, responseKeys.ID);
                yield put({
                    type: GET_SELECTED_ORGANISATION_SUCCESS,
                    selectedOrganisation: selectedOrganisation,
                    selectedOrganisationId: selectedOrganisationId,
                });
            }
        } catch (errors) {
            yield put({ type: actionTypes.GET_ORGANISATION_DETAILS_FAILURE, errors });

            //dispatch the notification about organisation retrieval error action
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Set selected organisation
     *
     * Fetch organisation details and set newly selected organisation ID to redux store and localstorage
     */
    *setSelectedOrganisation({ payload }) {
        // get the details of the new selected organisation
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.getOrganisationDetails, payload);

            removeLocalStorage(constants.ORGANISATION_PAYMENT_ACCOUNT_POPUP_SHOWN_KEY);
            setDataInLocalStorage({ [constants.USER_SELECTED_ORGANISATION_ID_KEY]: payload.organisationId });
            yield put({ type: actionTypes.SET_SELECTED_ORGANISATION_SUCCESS, result });
            yield put({ type: actionTypes.RESET_ORGANISATION_RELATED_DATA });
            redirect('/dashboard');

            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: 'Organisation switched.',
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            yield put({ type: actionTypes.SET_SELECTED_ORGANISATION_FAILURE, errors });

            //dispatch the notification about organisation retrieval error action
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get ther user from state
     *
     * @param   {object}    state    State Object
     *
     * @yields  {object}    User Details
     */
    getUser = state => state.user.userDetails;

    /**
     * Add organisation
     *
     * Create new organisation
     */
    *addOrganisation({ payload, callback }) {
        try {
            const result = yield call(OrganisationApi.addOrganisation, payload);
            const addedOrganisationId = get(result, successMappings.GET_ORGANISATION_DETAILS_ID);
            if (addedOrganisationId) {
                setDataInLocalStorage({ [constants.USER_SELECTED_ORGANISATION_ID_KEY]: addedOrganisationId });
                yield put({ type: actionTypes.SET_SELECTED_ORGANISATION_SUCCESS, result });
            }

            yield put({ type: actionTypes.ORGANISATION_ADD_SUCCESS, result });

            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            if (callback && isFunction(callback)) {
                callback();
            }
            redirect('/organisations');
        } catch (errors) {
            //dispatch the errors to the form
            yield put(
                stopSubmit(
                    constants.EDIT_ORGANISATION_FORM_NAME,
                    this.getApiErrors(
                        errors,
                        constants.ORGANISATION_ADD_DEFAULT_ERROR,
                        constants.ORGANISATION_ADD_DEFAULT_ERROR
                    )
                )
            );

            yield put({ type: actionTypes.ORGANISATION_ADD_FAILURE, errors });
        }
    }

    /**
     * Update organisation details
     *
     * Updates organisation details
     */
    *updateOrganisation({ payload, accountId, callback }) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.updateOrganisation, payload);

            yield put({ type: actionTypes.ORGANISATION_UPDATE_SUCCESS, result });

            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            if (callback && isFunction(callback)) {
                callback();
            }

            if (accountId) {
                redirect(`/admin/accounts/organisation/${accountId}/${payload.organisation_id}`);
            } else {
                redirect(`/organisation/${payload.organisation_id}`);
            }
        } catch (errors) {
            //dispatch the errors to the form
            yield put(
                stopSubmit(
                    constants.EDIT_ORGANISATION_FORM_NAME,
                    this.getApiErrors(
                        errors,
                        constants.ORGANISATION_EDIT_DEFAULT_ERROR,
                        constants.ORGANISATION_EDIT_DEFAULT_ERROR
                    )
                )
            );

            yield put({ type: actionTypes.ORGANISATION_UPDATE_FAILURE, errors });
        }
    }

    /**
     * Delete organisation
     *
     * Deletes specified organisation
     */
    *deleteOrganisation({ payload, accountId, isAdmin }) {
        try {
            //call the organisation delete API
            const result = yield call(OrganisationApi.deleteOrganisation, payload);

            const organisations = get(result, successMappings.GET_ORGANISATIONS);
            const currentOrganisationId = getLocalStorageValue(constants.USER_SELECTED_ORGANISATION_ID_KEY);
            let retrievedOrganisationIds = [];
            if (organisations) {
                retrievedOrganisationIds = organisations.map(organisation => {
                    return organisation.id;
                });
            }

            if (isAdmin) {
                redirect(`/admin/accounts/organisations/${accountId}`);
            } else {
                if (isEmpty(retrievedOrganisationIds)) {
                    removeLocalStorage(constants.USER_SELECTED_ORGANISATION_ID_KEY);
                    yield put({ type: actionTypes.SET_SELECTED_ORGANISATION_SUCCESS });
                    yield put({ type: actionTypes.RESET_ORGANISATION_RELATED_DATA });
                    redirect('/dashboard');
                } else if (!retrievedOrganisationIds.includes(currentOrganisationId)) {
                    const firstOrganisation = organisations[0];
                    setDataInLocalStorage({
                        [constants.USER_SELECTED_ORGANISATION_ID_KEY]: retrievedOrganisationIds[0],
                    });
                    yield put({ type: actionTypes.SET_SELECTED_ORGANISATION_AFTER_DELETE_SUCCESS, firstOrganisation });
                    yield put({ type: actionTypes.RESET_ORGANISATION_RELATED_DATA });
                }
            }

            yield put({ type: actionTypes.ORGANISATION_DELETE_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            const errorMessage = get(
                errors,
                errorMappings.ORGANISATION_DELETE_ERROR,
                ORGANISATION_DELETE_DEFAULT_ERROR
            );
            yield put({ type: actionTypes.ORGANISATION_DELETE_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: errorMessage,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Sync the organisation
     *
     * Syncs organisation details
     */
    *syncOrganisation({ payload }) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.syncOrganisation, payload);

            //dispatch the organisation sync success action
            yield put({ type: actionTypes.SYNC_ORGANISATION_SUCCESS, result });

            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            let reauthorization_error = get(
                errors,
                errorMappings.SYNC_ORGANISATION_REAUTHORIZATION_NEEDED_ERROR_MESSAGE
            );
            let error = reauthorization_error
                ? reauthorization_error
                : get(errors, errorMappings.SYNC_ORGANISATION_ERROR_MESSAGE);

            yield put({
                type: actionTypes.SYNC_ORGANISATION_FAILURE,
                errors,
                shouldReauthorize: !!reauthorization_error,
            });

            //dispatch the notification about organisation retrieval error action
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: error ? error : UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Export organisation data
     *
     * Exports organisation details as a file
     */
    *exportOrganisation({ payload }) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.exportOrganisation, payload);

            //dispatch the organisation data export success action
            yield put({
                type: actionTypes.EXPORT_ORGANISATION_SUCCESS,
                result,
            });

            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            yield put({ type: actionTypes.EXPORT_ORGANISATION_FAILURE, errors });
        }
    }

    /**
     * Export organisation data under an account
     *
     * Export details of all organisation under a specified account
     */
    *exportAccountOrganisations({ payload }) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.exportAccountOrganisations, payload);

            //dispatch the accounts data export success action
            yield put({
                type: actionTypes.EXPORT_ACCOUNT_ORGANISATIONS_SUCCESS,
                result,
            });

            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            yield put({ type: actionTypes.EXPORT_ACCOUNT_ORGANISATIONS_FAILURE, errors });
        }
    }

    /**
     * Update organisation settings
     *
     * Updates organisation settings
     */
    *updateOrganisationSettings({ payload }) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.updateOrganisationSettings, payload);

            //dispatch the organisation settings update success action
            yield put({ type: actionTypes.UPDATE_ORGANISATION_SETTINGS_SUCCESS, result });

            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            yield this.stopFormSubmit(errors, constants.DEFAULT_SETTINGS_FORM, 'ORGANISATION_SETTINGS_DEFAULT_ERROR');
            yield put({ type: actionTypes.UPDATE_ORGANISATION_SETTINGS_FAILURE, errors });
        }
    }

    /**
     * Get currency list
     *
     * fetches the currency list
     */
    *getCurrencies({ payload }) {
        try {
            //call the API and get the results
            const result = yield call(OrganisationApi.getCurrencies, payload);

            //dispatch the organisation settings update success action
            yield put({ type: actionTypes.GET_CURRENCIES_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_CURRENCIES_SUCCESS, errors });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: get(errors, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.ERROR,
            });
        }
    }
}

//export the Organisation saga
export default new OrganisationSaga().forkAllWatcherFunctions();

/**
 * Chart of Account Form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React from 'react';
import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import Button from 'components/shared/Button';
import FormField from 'components/shared/FormField';
import PropTypes from 'prop-types';
import useChartOfAccountTypes from 'includes/hooks/useChartOfAccountTypes';
import useTaxes from 'includes/hooks/useTaxes';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ChartOfAccountForm = props => {
    const { t } = useTranslation();
    const loadingChartOfAccountAddEdit = useSelector(state => state.chartOfAccounts.loadingChartOfAccountAddEdit);
    const { data: taxes } = useTaxes();
    const { data: chartOfAccountTypes } = useChartOfAccountTypes();
    /**
     * Get the localised label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.chartOfAccounts.addEdit.form.input.label.${field}`);

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.CHART_OF_ACCOUNT_NAME}
                            label={getLocaleLabel(requestKeys.CHART_OF_ACCOUNT_NAME)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.CHART_OF_ACCOUNT_CODE}
                            label={getLocaleLabel(requestKeys.CHART_OF_ACCOUNT_CODE)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="select"
                            name={requestKeys.CHART_OF_ACCOUNT_ACCOUNT_TYPE_ID}
                            label={getLocaleLabel(requestKeys.CHART_OF_ACCOUNT_ACCOUNT_TYPE_ID)}
                            options={chartOfAccountTypes.map(t => ({ name: t.type, value: t.id }))}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="select"
                            name={requestKeys.CHART_OF_ACCOUNT_TAX_ID}
                            label={getLocaleLabel(requestKeys.CHART_OF_ACCOUNT_TAX_ID)}
                            options={taxes.map(t => ({ name: t.name, value: t.id }))}
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="select"
                            name={requestKeys.CHART_OF_ACCOUNT_TRANSACTION_TYPE}
                            label={getLocaleLabel(requestKeys.CHART_OF_ACCOUNT_TRANSACTION_TYPE)}
                            hasFeedback
                            component={FormField}
                            options={[
                                {
                                    name: getLocaleLabel(requestKeys.CHART_OF_ACCOUNT_PAYMENT_ACCOUNT),
                                    value: 1,
                                },
                                {
                                    name: getLocaleLabel(requestKeys.CHART_OF_ACCOUNT_SALES_ACCOUNT),
                                    value: 2,
                                },
                            ]}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.CHART_OF_ACCOUNT_ACCOUNT_NUMBER}
                            label={getLocaleLabel(requestKeys.CHART_OF_ACCOUNT_ACCOUNT_NUMBER)}
                            hasFeedback
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.EXTERNAL_ID}
                            label={getLocaleLabel(requestKeys.EXTERNAL_ID)}
                            hasFeedback
                            component={FormField}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="textarea"
                            name={requestKeys.CHART_OF_ACCOUNT_DESCRIPTION}
                            label={getLocaleLabel(requestKeys.CHART_OF_ACCOUNT_DESCRIPTION)}
                            hasFeedback
                            component={FormField}
                        />
                    </Col>
                </Row>
            </Form>
            <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={loadingChartOfAccountAddEdit}
                        submittingButtonLabel={t(
                            `messages.chartOfAccounts.addEdit.form.submit.buttonSubmittingLabel.${
                                props.isAdd ? 'add' : 'edit'
                            }`
                        )}
                        className="register-btn"
                    >
                        {t(`messages.chartOfAccounts.addEdit.form.submit.buttonLabel.${props.isAdd ? 'add' : 'edit'}`)}
                    </Button>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
};

/**
 * Proptypes
 */
ChartOfAccountForm.propTypes = {
    error: PropTypes.string,
    initialValues: PropTypes.object,
    isAdd: PropTypes.bool,
    handleSubmit: PropTypes.func,
};

export default reduxForm({
    form: constants.CHART_OF_ACCOUNT_FORM,
})(ChartOfAccountForm);

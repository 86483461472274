/**
 * useContactTypes
 * Get the contact types
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { CACHE_CONTACT_TYPES_KEY } from 'includes/constants';
import { CONTACT_TYPES } from 'includes/constants/mappings/success';
import { get } from 'lodash';
import ContactsApi from 'includes/services/customer/contact';
import useCachedData from './useCachedData';
import useOrganisationId from 'includes/hooks/useOrganisationId';

/**
 * Get the currencies
 *
 * @param {boolean} disableApiCall Whether to disable api call or not. Default false
 *
 * @returns object Currencies
 */
const useContactTypes = (disableApiCall = false) => {
    const organisationId = useOrganisationId();
    return useCachedData(
        CACHE_CONTACT_TYPES_KEY,
        () => ContactsApi.getContactTypes(organisationId, { page_size: 0, get_contact_type_count: false }),
        {
            selector: res => get(res, CONTACT_TYPES),
            disableApiCall: disableApiCall,
            dependsOnOrg: true,
        }
    );
};

export default useContactTypes;

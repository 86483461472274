/**
 * Placeholder listing Component
 *
 * @version 1.0
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Breadcrumb, Modal } from 'antd';

import * as errorMessages from '../../../includes/constants/messages/errors';
import Button from '../Button';
import SearchBlock from '../../shared/SearchBlock';
import Table from '../../shared/ScrollableTable';
import { getPlaceholderTable } from '../../../includes/constants/columns';
import { deletePlaceholder, getPlaceholders } from '../../../includes/redux/actions/shared/placeholder';
import InfoMessage from '../messages/InfoMessage';
import PermissionGuard from 'components/PermissionGuard';
import { PLACEHOLDER } from 'includes/constants/permissions';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';

const { confirm } = Modal;

/**
 * Placeholder listing Component
 */
const Placeholders = props => {
    // default states
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const { isAdmin, placeholders, setListView, setInitialValues } = props;
    const organisationId = props.organisationId || props.match.params.organisationId;
    const { accountId } = props.match.params;

    /**
     * Delete placeholder
     *
     * @param {string} placeholderId Placeholder id
     */
    const deletePlaceholder = placeholderId => {
        confirm({
            title: 'Are you sure you want to delete this placeholder?',
            content: '',
            onOk: () => {
                props.deletePlaceholder(props.organisationId, placeholderId);
            },
        });
    };

    /**
     * Format the placeholder data to display in table
     */
    const formatplaceholdersData = useCallback(() => {
        const placeholderData = Object.entries(placeholders).map(([key, value]) => {
            return value.map(pl => {
                return {
                    ...pl,
                    name: (
                        <span className="wrapped-content-text crud-wrapper">
                            {pl.name}
                            {pl.is_custom && (
                                <div>
                                    <PermissionGuard requiredPermission={PLACEHOLDER.EDIT}>
                                        <span
                                            onClick={() => {
                                                setListView(false);
                                                setInitialValues(pl);
                                            }}
                                            className="crud-link"
                                        >
                                            Edit
                                        </span>
                                    </PermissionGuard>

                                    <PermissionGuard requiredPermission={PLACEHOLDER.DELETE}>
                                        <span onClick={() => deletePlaceholder(pl.id)} className="crud-link">
                                            Delete
                                        </span>
                                    </PermissionGuard>
                                </div>
                            )}
                        </span>
                    ),
                    group: key,
                };
            });
        });
        return [].concat.apply([], placeholderData);
    }, [placeholders]); // eslint-disable-line react-hooks/exhaustive-deps

    // memoize the placeholder data
    const memoizedPlaceholdersData = useMemo(() => formatplaceholdersData(), [formatplaceholdersData]);

    return (
        <div className="my-profile-detail settings-edit-form">
            <header>Placeholders</header>
            {isAdmin && organisationId && (
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={`/admin/accounts/organisations/${accountId}`}>
                                {props.t(`customerMessages.placeholders.breadCrumb.organisations`)}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {props.t(`customerMessages.placeholders.breadCrumb.placeholders`)}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            <div className="search-filter-export-wrapper">
                <ul className="selector-field">
                    <li className="left-align-text">
                        <PermissionGuard requiredPermission={PLACEHOLDER.ADD}>
                            <Button
                                className="white-bg-button"
                                onClick={() => {
                                    props.setListView(false);
                                    props.setInitialValues();
                                }}
                            >
                                {props.t('customerMessages.placeholders.breadCrumb.add')}
                            </Button>
                        </PermissionGuard>
                    </li>
                    <li>
                        <div className="right-align">
                            <SearchBlock
                                dataValues={[props.organisationId]}
                                getDataMethod={props.getPlaceholders}
                                isSearching={isSearching}
                                placeholder={props.t('sharedMessages.placeholders.search.placeholder')}
                                setIsSearching={setIsSearching}
                                setSearchParam={setSearchParam}
                            />
                        </div>
                    </li>
                </ul>
            </div>
            <PermissionGuardLayout requiredPermission={PLACEHOLDER.LIST} layout="section">
                <Table
                    dataValues={[props.organisationId]}
                    rowKey="id"
                    localeMessage={errorMessages.TABLE_NO_PLACEHOLDERS_FOUND_MESSAGE}
                    loading={props.isPlaceholdersLoading}
                    columns={getPlaceholderTable(props)}
                    dataSource={memoizedPlaceholdersData}
                    paginationData={props.paginationPlaceholders}
                    getDataMethod={props.getPlaceholders}
                    isSearching={isSearching}
                    searchParam={searchParam}
                    rowClassName="pointer"
                    setIsSearching={setIsSearching}
                    size="middle"
                    {...props}
                />
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={props.isDeletingPlaceholder}
                >
                    <InfoMessage
                        message={props.t('customerMessages.placeholders.loading.message.deletingPlaceholder')}
                        showLoader={true}
                    />
                </Modal>
            </PermissionGuardLayout>
        </div>
    );
};

Placeholders.propTypes = {
    deletePlaceholder: PropTypes.func,
    getPlaceholders: PropTypes.func,
    isAdmin: PropTypes.bool,
    isDeletingPlaceholder: PropTypes.bool,
    isPlaceholdersLoading: PropTypes.bool,
    match: PropTypes.object,
    organisationId: PropTypes.string,
    paginationPlaceholders: PropTypes.object,
    placeholders: PropTypes.object,
    setInitialValues: PropTypes.func,
    setListView: PropTypes.func,
    t: PropTypes.func,
    updatePlaceholder: PropTypes.func,
};

// connect to store
export default connect(
    state => ({
        isAdmin: state.account.isAdmin,
        isDeletingPlaceholder: state.placeholder.isDeletingPlaceholder,
        isPlaceholdersLoading: state.placeholder.isPlaceholdersLoading,
        paginationPlaceholders: state.placeholder.paginationPlaceholders,
        placeholderGroup: state.placeholder.placeholderGroup,
        placeholders: state.placeholder.placeholders,
    }),
    {
        deletePlaceholder,
        getPlaceholders,
    }
)(withTranslation()(Placeholders));

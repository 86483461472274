/**
 * Payments Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { get } from 'lodash';

import PaymentsApi from '../services/shared/payments';
import * as successMappings from '../constants/mappings/success';
import { showNotificationModal } from './appNotifications';
import createAppSlice from './base';

// set the initial state
const initialState = {
    isLoadingPaymentBankAccounts: false,
    isAddingPaymentAccount: false,
    isLoadingPaymentStatuses: false,
    isUpdatingPaymentAccount: false,
    isDeletingPaymentBankAccount: false,
    paymentBankAccounts: [],
    paymentStatuses: [],
};

// define the slice
const PaymentsSlice = createAppSlice('paymentsSlice', initialState);

const { setData } = PaymentsSlice.actions;

/**
 * Add payment bank account
 *
 * @param {object} details Bank account details
 * @callback callback callback to be called after API success
 */
export const addPaymentBankAccount = (details, callback) => async dispatch => {
    try {
        dispatch(setData('isAddingPaymentAccount', true));
        const result = await PaymentsApi.addPaymentBankAccount(details);
        dispatch(setData('paymentBankAccounts', get(result, successMappings.PAYMENT_GET_BANK_ACCOUNTS)));
        callback();
    } finally {
        dispatch(setData('isAddingPaymentAccount', false));
    }
};

/**
 * Get the payment bank accounts
 */
export const getPaymentBankAccounts = (params = {}) => async dispatch => {
    try {
        dispatch(setData('isLoadingPaymentBankAccounts', true));
        const result = await PaymentsApi.getPaymentBankAccounts(params);
        dispatch(setData('paymentBankAccounts', get(result, successMappings.PAYMENT_GET_BANK_ACCOUNTS)));
    } finally {
        dispatch(setData('isLoadingPaymentBankAccounts', false));
    }
};

/**
 * Get the payment statuses
 */
export const getPaymentStatuses = () => async dispatch => {
    try {
        dispatch(setData('isLoadingPaymentStatuses', true));
        const result = await PaymentsApi.getPaymentStatuses();
        dispatch(setData('paymentStatuses', get(result, successMappings.PAYMENT_GET_STATUSES)));
    } finally {
        dispatch(setData('isLoadingPaymentStatuses', false));
    }
};

/**
 * Update payment bank accounts
 *
 * @param {string} paymentBankAccountId Bank account ID
 * @param {object} payload Payload
 * @callback callback to be called after API success
 * @param {object} config config object
 * @param {boolean} config.showSuccessMessage determines if the success message from API response is to be displayed or not. Default `true`.
 */
export const updatePaymentBankAccount = (
    paymentBankAccountId,
    payload,
    callback = () => {},
    config = { showSuccessMessage: true, unsetLoader: () => {} }
) => async dispatch => {
    try {
        dispatch(setData('isUpdatingPaymentAccount', true));
        const result = await PaymentsApi.updatePaymentBankAccount(paymentBankAccountId, payload);
        dispatch(setData('paymentBankAccounts', get(result, successMappings.PAYMENT_GET_BANK_ACCOUNTS)));
        if (config.showSuccessMessage) dispatch(showNotificationModal(result));
        callback();
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, 'PAYMENT_BANK_ACCOUNT_SAVE_ERROR'));
    } finally {
        dispatch(setData('isUpdatingPaymentAccount', false));
        config.unsetLoader();
    }
};

/**
 * Delete payment bank accounts
 *
 * @param {string} paymentBankAccountId Bank account ID
 */
export const deletePaymentBankAccount = paymentBankAccountId => async dispatch => {
    try {
        dispatch(setData('isDeletingPaymentBankAccount', true));
        const result = await PaymentsApi.deletePaymentBankAccount(paymentBankAccountId);
        dispatch(setData('paymentBankAccounts', get(result, successMappings.PAYMENT_GET_BANK_ACCOUNTS)));
        dispatch(showNotificationModal(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, 'PAYMENT_BANK_ACCOUNT_SAVE_ERROR'));
    } finally {
        dispatch(setData('isDeletingPaymentBankAccount', false));
    }
};

// export the reducer
export default PaymentsSlice.reducer;

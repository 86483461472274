/**
 * FatZebra Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { createSlice } from '@reduxjs/toolkit';
import jsonp from 'jsonp';
import { appendQueryParams } from '../utils';
import { get, isFunction } from 'lodash';
import { UNKNOWN_ERROR } from '../constants/messages/errors';
import PaymentGatewayApi from '../services/shared/paymentGateways';
import * as successMappings from '../constants/mappings/success';
import {
    FAT_ZEBRA_API_URL,
    FAT_ZEBRA_ERRORS,
    FAT_ZEBRA_RESPONSE_CODE,
    FAT_ZEBRA_RETURN_PATH,
    FAT_ZEBRA_TOKEN,
    FAT_ZEBRA_VERIFICATION_TOKEN,
} from '../constants/keys/response';
import { openNotificationModal, showNotificationModal } from './appNotifications';

// set the initial state
const initialState = { loading: false };

// define the slice
const FatZebraSlice = createSlice({
    name: 'fatZebraSlice',
    initialState,
    reducers: {
        endLoading(state) {
            state.loading = false;
        },
        setLoading(state) {
            state.loading = true;
        },
    },
});

/**
 * Get the verification data for verifying the fatzebra card
 *
 * @param {string} organisationId Organisation id
 *
 * @returns {object} Verification Data
 */
const getVerificationData = async organisationId => {
    try {
        const result = await PaymentGatewayApi.getFatZebraVerificationData(organisationId);
        return get(result, successMappings.GET_FAT_ZEBRA_VERIFICATION_DATA);
    } catch (errors) {
        return false;
    }
};

/**
 * Verify the Credit card using FatZebra Direct Post API
 *
 * @param {object} cardDetails Card Details
 * @param {Function} callback Callback function
 * @param {string} organisationId Organisation Id
 */
export const verifyCard = (cardDetails, callback, organisationId) => async dispatch => {
    try {
        dispatch(FatZebraSlice.actions.setLoading());

        // get the data for card verification from the server
        const verificationData = await getVerificationData(organisationId);

        if (!verificationData) {
            dispatch(showNotificationModal({}, false));
        }

        // call the card verification API
        jsonp(
            appendQueryParams(get(verificationData, FAT_ZEBRA_API_URL), {
                ...cardDetails,
                ...{
                    return_path: get(verificationData, FAT_ZEBRA_RETURN_PATH),
                    verification: get(verificationData, FAT_ZEBRA_VERIFICATION_TOKEN),
                },
            }),
            {},
            (err, data) => {
                let responseCode = get(data, FAT_ZEBRA_RESPONSE_CODE);
                let error = '';

                if (responseCode === 1) {
                    const cardToken = get(data, FAT_ZEBRA_TOKEN);
                    if (cardToken) {
                        if (isFunction(callback)) {
                            callback(cardToken);
                        }
                    } else {
                        error = UNKNOWN_ERROR;
                    }
                } else if (responseCode === 97) {
                    error = get(data, [FAT_ZEBRA_ERRORS, 0]);
                } else {
                    error = UNKNOWN_ERROR;
                }
                // if there is an error dispatch the card error action
                if (error) {
                    dispatch(openNotificationModal(error, false));
                }
                dispatch(FatZebraSlice.actions.endLoading());
            }
        );
    } catch (err) {
        dispatch(openNotificationModal(UNKNOWN_ERROR, false));
        dispatch(FatZebraSlice.actions.endLoading());
    }
};

// export the reducer
export default FatZebraSlice.reducer;

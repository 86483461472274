/**
 * Default settings listing Component
 *
 * @version 1.0
 */

//import required modules
import { React, Link, withTranslation } from '../../../includes/exports/react';
import PropTypes from 'prop-types';
import BlockUI from 'react-block-ui';
import { Row, Col, Icon } from 'antd';
import DefaultSettingsForm from '../forms/DefaultSettingsForm';
import './styles.scss';
import Button from '../../shared/Button';
import Loader from '../../shared/Loader';
import { get } from 'lodash';

/**
 * Default settings listing Component
 *
 * @returns    {string}
 */
const DefaultSettings = props => {
    const handleSubmit = values => {
        props.updateOrganisationSettings(props.organisationId, values);
    };
    const allowCustomEmailSettings = get(props.accountDetails, 'allow_custom_email_settings', false);
    const allowCustomIvrSettings = get(props.accountDetails, 'allow_custom_ivr_settings', false);
    const allowCustomSmsSettings = get(props.accountDetails, 'allow_custom_sms_settings', false);

    const allowCustomBranding = props.isAdmin
        ? true
        : get(props, 'accountDetails.subscription.plan.custom_branding', false);
    const isCustomPlan = get(props, 'accountDetails.subscription.plan.is_custom', false);

    /**
     * Render the component
     */
    return (
        <div className="my-profile-detail settings-edit-form edit-default-settings">
            <header>Default Settings</header>
            {props.isDetailsLoading ? (
                <Loader />
            ) : (
                <BlockUI
                    blocking={!allowCustomBranding}
                    keepInView
                    loader={
                        <div className="white-box-wrapper box-wrapper mid-wrapper">
                            <Row type="flex" justify="center" align="middle">
                                <Col span={6}>
                                    <Icon type="lock" className="lock-icon" />
                                </Col>
                                <Col span={16} pull={2}>
                                    <p className="upgrade-message">
                                        {isCustomPlan
                                            ? 'Your plan do not allow access to these features. Please contact administrator for upgrade'
                                            : 'Please upgrade to unlock these features'}
                                    </p>{' '}
                                    {!isCustomPlan && (
                                        <Button big filled className="upgrade-button">
                                            <Link to="/plans"> Upgrade</Link>
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    }
                >
                    <DefaultSettingsForm
                        {...props}
                        initialValues={{
                            allow_custom_email_settings: allowCustomEmailSettings,
                            allow_custom_ivr_settings: allowCustomIvrSettings,
                            allow_custom_sms_settings: allowCustomSmsSettings,
                            ...props.defaultSettings,
                        }}
                        enableReinitialize
                        onSubmit={handleSubmit}
                        t={props.t}
                    />
                </BlockUI>
            )}
        </div>
    );
};
/**
 * Prop Types
 */
DefaultSettings.propTypes = {
    accountDetails: PropTypes.object,
    defaultSettings: PropTypes.object,
    isAdmin: PropTypes.bool,
    isDetailsLoading: PropTypes.bool,
    organisationId: PropTypes.string,
    t: PropTypes.func,
    updateOrganisationSettings: PropTypes.func,
};
export default withTranslation()(DefaultSettings);

import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import useInternalStripeDetails from './useInternalStripeDetails';

/**
 * useStripePromise
 *
 * To be used where  you need to access the Stripe object directly.
 * returns object containing `stripePromise` and `isLoadingStripeDetails` keys
 */
export default function useStripePromise() {
    const [stripePromise, setStripePromise] = useState(null);

    const { stripeDetails, isLoading: isLoadingStripeDetails } = useInternalStripeDetails();

    useEffect(() => {
        if (!stripeDetails) return;

        setStripePromise(loadStripe(stripeDetails.public_key));
    }, [stripeDetails]);

    return { stripePromise, isLoadingStripeDetails };
}

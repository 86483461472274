import React, { useState } from 'react';
import { Divider } from 'antd';
import { Field } from 'redux-form';

import * as formValidations from 'includes/utils/form';
import {
    COMMON_PAYMENT_SERVICE_FORM_FIELDS,
    renderPaymentServiceFormField,
} from 'components/PaymentGateways/constants';
import i18n from 'includes/i18n';
import FormField from 'components/shared/FormField';

/**
 * Custom Payment URL form
 *
 * @since 2.8.0
 */
export default function CustomPaymentURLFields() {
    const [isCustomUrlInfoVisible, setIsCustomUrlInfoVisible] = useState(false);

    const toggleIsCustomUrlInfoVisible = () => setIsCustomUrlInfoVisible(state => !state);
    return (
        <div>
            <Divider orientation="left">
                <span className="font-medium">General</span>
            </Divider>
            <div className="grid grid-cols-2 gap-3 text-left w-full">
                {COMMON_PAYMENT_SERVICE_FORM_FIELDS.map(renderPaymentServiceFormField)}
            </div>

            <Divider orientation="left">
                <span className="font-medium">Gateway details</span>
            </Divider>
            <div className="grid grid-cols-2 gap-3 text-left w-full">
                <div className="col-span-2">
                    <Field
                        step="any"
                        type={'text'}
                        info={
                            <div>
                                <div>
                                    Contact you payment service provider for the required URL.{' '}
                                    <span
                                        className="text-green-500 cursor-pointer"
                                        onClick={toggleIsCustomUrlInfoVisible}
                                    >
                                        {isCustomUrlInfoVisible ? 'Hide details' : 'Show details'}
                                    </span>
                                </div>
                                {isCustomUrlInfoVisible ? (
                                    <div className="mt-3 flex flex-col gap-3">
                                        <span className="font-bold">These placeholders are:</span>
                                        <span>
                                            {
                                                '[INVOICE_NUMBER], [CURRENCY], [AMOUNT_DUE], [PAYMENT_AMOUNT] & [INVOICE_EXTERNAL_ID]'
                                            }
                                        </span>
                                        <span className="font-bold">An example URL might look like:</span>
                                        <span>
                                            {
                                                'https://www.paymentservice.com/?invoiceNo=[INVOICE_NUMBER|&currency=[CURRENCY]&amount=[AMOUNT_DUE]&paymentAmount=[PAYMENT_AMOUNT]&invoiceExternalId=[INVOICE_EXTERNAL_ID]'
                                            }
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        }
                        name={'custom_payment_url'}
                        label={i18n.t('customerMessages.paymentServices.form.label.customPaymentUrl')}
                        hasFeedback
                        className="form-control"
                        component={FormField}
                        validate={[formValidations.required, formValidations.url]}
                        required
                    />
                </div>
            </div>
        </div>
    );
}

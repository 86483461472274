/**
 * useIsAdmin
 * extracts and returns the admin status from store
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import useIsUserRole from './useIsUserRole';

/**
 * Get whether the current user is admin or not from the store
 *
 * @returns {boolean} true|false
 */
const useIsAdmin = () => {
    return useIsUserRole('is_admin');
};

export default useIsAdmin;

import { get } from 'lodash';
import { CACHE_PAYMENT_METHOD_STATUSES } from 'includes/constants';
import { GET_PAYMENT_METHOD_STATUSES } from 'includes/constants/mappings/success';
import PaymentGatewaysApi from 'includes/services/shared/paymentGateways';
import useCachedData from './useCachedData';

/**
 * Get payment method statuses
 *
 * @param {boolean} disableApiCall Whether to disable api call or not. Default false
 *
 * @returns object payment method statuses
 */
const usePaymentMethodStatuses = (disableApiCall = false) => {
    return useCachedData(CACHE_PAYMENT_METHOD_STATUSES, () => PaymentGatewaysApi.getPaymentMethodStatuses(), {
        selector: res => get(res, GET_PAYMENT_METHOD_STATUSES),
        disableApiCall: disableApiCall,
    });
};

export default usePaymentMethodStatuses;

/**
 * Bank Accounts component
 * This component fetches and renders a user's bank accounts
 *
 * @since 2.8.0
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import BankAccount from './components/BankAccount';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { formatNumberAsCurrency } from 'includes/utils';
import useBankAccounts from 'includes/hooks/useBankAccounts';
import Loader from 'components/Loader';

/**
 * Component to render bank accounts
 *
 * @since 2.8.0
 */
export default function BankAccounts({ contactId, paymentArrangementId }) {
    const organisationId = useOrganisationId();

    const { bankAccounts, isBankAccountsLoading } = useBankAccounts(organisationId, contactId, paymentArrangementId);

    if (isBankAccountsLoading) return <Loader />;
    return (
        <Row gutter={24}>
            {bankAccounts.map(account => (
                <Col key={account.id} xs={24} md={12}>
                    <BankAccount
                        accountName={account.account_name}
                        accountType={account.account_type}
                        balance={formatNumberAsCurrency(account.balance_amount, account.balance_currency)}
                        provider={account.provider_name}
                    />
                </Col>
            ))}
        </Row>
    );
}

BankAccounts.propTypes = {
    contactId: PropTypes.string,
    paymentArrangementId: PropTypes.string,
};

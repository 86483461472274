/**
 * Plan Payment Form Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import React, { useEffect, useMemo, useCallback } from 'react';
import { Form, Radio, Alert, Modal } from 'antd';
import { Field, formValueSelector, getFormValues, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { find, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import FormField from '../../../../shared/FormField';
import * as formValidations from '../../../../../includes/utils/form';
import './styles.scss';
import * as constants from '../../../../../includes/constants';
import Loader from '../../../Loader';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useCurrencies from 'includes/hooks/useCurrencies';
import PaymentGateway from 'components/PaymentGateway';
import {
    COUNTRY_ID,
    COUPON_ID,
    EMAIL,
    MANDATE_NAME,
    NAME,
    ORDER,
    ORDER_TYPE,
    PAYMENT_GATEWAY,
    PAYMENT_GATEWAY_ID,
    PAYMENT_METHOD_ID,
    PAYMENT_METHOD_TYPE_ID,
    PLAN_ID,
    PLAN_TYPE,
    SAVE_CARD_FOR_LATER_USE,
    SCHEME_ID,
    SUBSCRIPTION,
    TOKEN,
    UPGRADE_SUBSCRIPTION,
} from 'includes/constants/keys/request';
import OrderSummary from 'components/OrderDetails/components/OrderSummary';
import useSubscriptionPlans from 'includes/hooks/useSubscriptionPlan';
import { useOrderTypes } from 'includes/utils/hooks';
import OrdersApi from 'includes/services/orders';
import {
    ORDER_EXISTING_ERROR,
    ORDER_SUBSCRIPTION_ERROR,
    ORDER_TRANSACTION_CHARGE_ERROR,
} from 'includes/constants/mappings/errors';
import OrderChargesUpdatedConfirmModalContent from 'components/shared/Orders/OrderChargesUpdatedConfirmModalContent';
import { useTranslation } from 'react-i18next';
import { showApiErrors } from 'includes/utils/api';
import { useHistory } from 'react-router';
import useInternalPaymentGateways from 'includes/hooks/useInternalPaymentGateways';
import { showAppNotificationModal } from 'includes/redux/actions/shared/appNotification';

const subscriptionFormSelector = formValueSelector(constants.SUBSCRIPTION_PAYMENT_FORM_NAME);
const subscriptionFromAllValuesSelector = getFormValues(constants.SUBSCRIPTION_PAYMENT_FORM_NAME);

// renders radio group to select if to pay annually of monthly
const SubscriptionTypeRadioGroup = props => {
    const { error, touched, warning } = props.meta;
    const fieldError = touched && (error || warning);
    const validateStatus = fieldError ? 'error' : 'success';

    return (
        <Form.Item validateStatus={validateStatus} help={fieldError}>
            <Radio.Group
                className=""
                {...props.input}
                disabled={!get(props, 'selectedPlanDetails.id') || props.disabled}
            >
                <Radio value={constants.MONTHLY}>
                    Monthly Subscription
                    <span className="right-align">
                        <b>{props.getNumberFormattedWithPlanCurrency(get(props, 'selectedPlanDetails.cost'))}</b> /
                        month
                    </span>
                </Radio>
                <Radio value={constants.YEARLY}>
                    Annual Subscription
                    <span className="right-align">
                        <b>{props.getNumberFormattedWithPlanCurrency(get(props, 'selectedPlanDetails.yearly_cost'))}</b>{' '}
                        / year
                    </span>
                </Radio>
            </Radio.Group>
        </Form.Item>
    );
};

SubscriptionTypeRadioGroup.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    selectedPlanDetails: PropTypes.object,
    disabled: PropTypes.bool,
    getNumberFormattedWithPlanCurrency: PropTypes.func,
};

// Payment form
const PlanPaymentForm = props => {
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    const history = useHistory();

    const { t } = useTranslation();

    const [orderTypes] = useOrderTypes();

    const dispatch = useDispatch();

    const subscriptionOrderType = useMemo(() => {
        if (!orderTypes) return null;

        return find(orderTypes, { slug: constants.ORDER_TYPE.SUBSCRIPTION });
    }, [orderTypes]);

    const { data: subscriptionPlans, isLoading: isSubscriptionPlansLoading } = useSubscriptionPlans();

    const { data: currencies, isLoading: loadingCurrencies } = useCurrencies();
    const selectedPlanDetails = find(subscriptionPlans, { id: props.selectedPlan });
    const getSubscriptionPlanOptions = useCallback(
        () =>
            subscriptionPlans
                .filter(plan => plan.can_purchase)
                .map(plan => ({
                    value: plan.id,
                    name: `${plan.type}`,
                })),
        [subscriptionPlans]
    );

    const { data: paymentGateways } = useInternalPaymentGateways(true);

    const subscriptionPlanOptions = useMemo(() => getSubscriptionPlanOptions(), [getSubscriptionPlanOptions]);

    const selectedMethodToken = useSelector(state => subscriptionFormSelector(state, TOKEN));

    const selectedMethodType = useSelector(state => subscriptionFormSelector(state, PAYMENT_METHOD_TYPE_ID));

    const paymentGatewayId = useSelector(state => subscriptionFormSelector(state, PAYMENT_GATEWAY_ID));

    const appliedCoupon = useSelector(state => subscriptionFormSelector(state, COUPON_ID));

    const paymentMethodCountryId = useSelector(state => subscriptionFormSelector(state, COUNTRY_ID));

    const formValues = useSelector(state => subscriptionFromAllValuesSelector(state));

    const orderCharges = useSelector(state => state.orders.orderCharges);

    const accountDetails = useSelector(state => state.account.accountDetails);

    const selectedPaymentGateway = useMemo(() => {
        if (!paymentGateways || paymentGateways.length === 0) return null;

        return find(paymentGateways, { id: paymentGatewayId });
    }, [paymentGateways]);

    const { selectedPlan, isLoadingPayButton, paymentGatewayError, setIsLoadingPayButton, setErrorMessage } = props;

    useEffect(() => {
        props.change(NAME, `${accountDetails.primary_contact_first_name} ${accountDetails.primary_contact_last_name}`);
        props.change(EMAIL, accountDetails.primary_contact_email);
    }, [accountDetails]);

    /**
     * Get number formatted with default currency
     *
     * @param {string|number} number Number
     *
     * @returns {string} Formatted number
     */
    const getNumberFormattedWithPlanCurrency = number =>
        getNumberFormattedAsCurrency(
            number,
            false,
            false,
            get(find(currencies, { id: selectedPlanDetails?.currency_id }), 'code')
        );

    /**
     * Payment gateway submit handler
     *
     * @param {string} cardToken Card Token
     **/
    const handleSubmit = () => {
        props.submit(constants.SUBSCRIPTION_PAYMENT_FORM_NAME);
    };

    const handleCreateOrder = async (
        reinitializePaymentFlow,
        selectedPaymentMethod,
        confirmationParams = { subscription: false, order: false, transactionCharges: false }
    ) => {
        let response = null;

        try {
            const {
                subscription: confirmSubscription,
                order: orderConfirm,
                transactionCharges,
                ...rest
            } = confirmationParams;
            const body = {
                ...formValues,
                token: selectedPaymentMethod,
                confirm: confirmSubscription,
                order_confirm: orderConfirm,
                transaction_charges_confirm: transactionCharges,
                ...rest,
            };
            response = await OrdersApi.createOrder(subscriptionOrderType.id, body);
            return { response, error: undefined };
        } catch (errors) {
            const subscriptionError = get(errors, ORDER_SUBSCRIPTION_ERROR);
            const existingOrderError = get(errors, ORDER_EXISTING_ERROR);
            const transactionChargeError = get(errors, ORDER_TRANSACTION_CHARGE_ERROR);

            if (subscriptionError) {
                Modal.confirm({
                    title: subscriptionError.title,
                    content: subscriptionError.description,
                    onCancel: () => {
                        setIsLoadingPayButton(false);
                    },
                    onOk: () => {
                        // recursively call `handleCreateOrder` with `confirmationParams.subscription` as `true`
                        reinitializePaymentFlow({
                            ...confirmationParams,
                            subscription: true,
                            token: selectedPaymentMethod, // for gocardless to identify that billing request ID has already been created
                        });
                    },
                });
                return { response: null, error: subscriptionError };
            }

            if (existingOrderError) {
                Modal.confirm({
                    title: 'Duplicate Order or Order Already exists',
                    content: (
                        <div className="flex flex-col gap-2">
                            <div>{existingOrderError.message}</div>
                            <div>
                                If you wish to see the existing order, click{' '}
                                <a
                                    onClick={() => {
                                        history.push(
                                            `/orders/order/${subscriptionOrderType.id}/${existingOrderError.order_id}`
                                        );
                                        Modal.destroyAll();
                                    }}
                                    className="mr-1"
                                >
                                    here
                                </a>
                                to view the details or click on continue to create a new one.
                            </div>
                        </div>
                    ),
                    onCancel: () => {
                        setIsLoadingPayButton(false);
                    },
                    okText: 'Continue',
                    onOk: () => {
                        // recursively call `handleCreateOrder` with `confirmationParams.order` as `true`
                        reinitializePaymentFlow({
                            ...confirmationParams,
                            order: true,
                        });
                    },
                });
                return { response: null, error: existingOrderError };
            }

            if (transactionChargeError) {
                Modal.confirm({
                    title: 'International Card Fee',
                    content: (
                        <OrderChargesUpdatedConfirmModalContent
                            errorMessage={transactionChargeError.message}
                            formatCurrency={getNumberFormattedWithPlanCurrency}
                            t={t}
                            transactionCharges={transactionChargeError.transaction_charges}
                        />
                    ),
                    onCancel: () => {
                        setIsLoadingPayButton(false);
                    },
                    onOk: () => {
                        // recursively call `handleCreateOrder` with `confirmationParams.transactionCharges` as `true`
                        reinitializePaymentFlow({
                            ...confirmationParams,
                            transactionCharges: true,
                        });
                    },
                });
                return { response: null, error: transactionChargeError };
            }

            dispatch(
                showApiErrors(errors, null, 'ORDER_CREATE_ERROR_MESSAGE', 'order', [
                    COUNTRY_ID,
                    COUPON_ID,
                    MANDATE_NAME,
                    ORDER,
                    ORDER_TYPE,
                    PAYMENT_GATEWAY_ID,
                    PAYMENT_GATEWAY,
                    PAYMENT_METHOD_ID,
                    PLAN_ID,
                    PLAN_TYPE,
                    SAVE_CARD_FOR_LATER_USE,
                    SCHEME_ID,
                    SUBSCRIPTION,
                    TOKEN,
                    UPGRADE_SUBSCRIPTION,
                ])
            );
            setIsLoadingPayButton(false);
            return { response: null, error: errors };
        }
    };

    return (
        <div className="home-content-wrapper make-payment-wrapper">
            <h2 className="page-title">Subscription Payment</h2>
            <Form layout="vertical" className="" onSubmit={props.handleSubmit}>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
                    <section className="bg-white box-wrapper plan-details-form rounded-lg shadow-lg py-8 px-10">
                        <h3 className="page-title">Account Details</h3>
                        {isSubscriptionPlansLoading ? (
                            <Loader />
                        ) : (
                            <>
                                <Field
                                    type="text"
                                    name={NAME}
                                    label="Name"
                                    className="form-control"
                                    component={FormField}
                                    validate={formValidations.required}
                                />
                                <Field
                                    type="text"
                                    name={EMAIL}
                                    label="Email"
                                    className="form-control"
                                    component={FormField}
                                    validate={[formValidations.required]}
                                />
                            </>
                        )}
                    </section>
                    <section className="bg-white box-wrapper plan-details-form rounded-lg shadow-lg py-8 px-10">
                        <h3 className="page-title">Plan Details</h3>
                        {isSubscriptionPlansLoading ? (
                            <Loader />
                        ) : (
                            <>
                                <Field
                                    type="select"
                                    name="plan_id"
                                    label="Selected Plan"
                                    className="form-control"
                                    disabled={!!props.initialValues.id}
                                    options={subscriptionPlanOptions}
                                    component={FormField}
                                    validate={formValidations.required}
                                />
                                <label>Subscription Type</label>
                                <Field
                                    name="plan_type"
                                    className="form-control"
                                    disabled={!!props.initialValues.id}
                                    selectedPlanDetails={selectedPlanDetails}
                                    component={SubscriptionTypeRadioGroup}
                                    validate={[formValidations.required]}
                                    submitErrors={props.submitErrors}
                                    getNumberFormattedWithPlanCurrency={getNumberFormattedWithPlanCurrency}
                                />
                            </>
                        )}
                    </section>
                </div>
                <div className="flex flex-col gap-4">
                    <section className="bg-white box-wrapper order-summary rounded-lg shadow-lg py-8 px-10 col-span-2">
                        <h3 className="page-title total-count-header full-width-title">Order Summary</h3>
                        {isSubscriptionPlansLoading || loadingCurrencies ? (
                            <Loader />
                        ) : (
                            <>
                                <OrderSummary
                                    appliedCoupon={appliedCoupon}
                                    onApplyCoupon={coupon => props.change(COUPON_ID, coupon)}
                                    paymentGatewayCountryId={selectedPaymentGateway?.country_id}
                                    paymentMethodCountryId={paymentMethodCountryId}
                                    paymentMethodId={selectedMethodToken}
                                    paymentGatewayId={paymentGatewayId}
                                    planName={selectedPlanDetails?.type}
                                    planType={props.planType}
                                    selectedPlan={selectedPlan}
                                />
                            </>
                        )}
                    </section>
                    <section className="bg-white full-wrapper rounded-lg shadow-lg py-8 px-10 col-span-2">
                        <div className="box-wrapper payment-details-wrapper">
                            <h3 className="page-title">Payment Details</h3>
                            <PaymentGateway
                                amount={orderCharges?.total_amount_in_cents ? orderCharges?.total_amount_in_cents : 100}
                                handleSubmit={handleSubmit}
                                isLoadingPayButton={isLoadingPayButton}
                                setErrorMessage={setErrorMessage}
                                setSelectedMethod={method => {
                                    props.change(TOKEN, method.id);
                                    props.change(PAYMENT_METHOD_TYPE_ID, method.method_type_id);
                                    props.change(PAYMENT_GATEWAY_ID, method.payment_gateway_id);
                                    props.change(COUNTRY_ID, method.country_id);
                                }}
                                createOrder={handleCreateOrder}
                                onShowSuccessMessage={() => {
                                    dispatch(showAppNotificationModal('success', 'Subscribed successfully.'));
                                }}
                                orderFormValues={formValues}
                                orderTypeId={subscriptionOrderType?.id}
                                setIsLoadingPayButton={props.setIsLoadingPayButton}
                                setSelectedPaymentGateway={cardToken => props.change(PAYMENT_GATEWAY_ID, cardToken)}
                                selectedPaymentGatewayId={paymentGatewayId}
                                selectedMethod={selectedMethodToken}
                                selectedMethodType={selectedMethodType}
                            />
                        </div>
                        {paymentGatewayError || props.error ? (
                            <Alert message={paymentGatewayError || props.error} type="error" />
                        ) : null}
                    </section>
                </div>
            </Form>
        </div>
    );
};

PlanPaymentForm.propTypes = {
    appliedCoupon: PropTypes.string,
    change: PropTypes.func,
    clientSecretToken: PropTypes.string,
    coupons: PropTypes.array,
    error: PropTypes.string,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isCreatingOrder: PropTypes.bool,
    isLoading: PropTypes.bool,
    isLoadingPayButton: PropTypes.bool,
    isRetryingOrderPayment: PropTypes.bool,
    isSavedCardsLoading: PropTypes.bool,
    orderId: PropTypes.string,
    orderType: PropTypes.string,
    paymentGateway: PropTypes.object,
    paymentGatewayError: PropTypes.string,
    planType: PropTypes.string,
    saveCard: PropTypes.func,
    saveCardError: PropTypes.func,
    savedCards: PropTypes.array,
    selectedPlan: PropTypes.string,
    setErrorMessage: PropTypes.func,
    setIsLoadingPayButton: PropTypes.func,
    submit: PropTypes.func,
    submitErrors: PropTypes.object,
};

export default reduxForm({
    form: constants.SUBSCRIPTION_PAYMENT_FORM_NAME,
})(PlanPaymentForm);

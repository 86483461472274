/**
 * Contact Details Component
 *
 * @version 1.0
 */

//import required modules
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { find, get, isEmpty } from 'lodash';
import { Tabs, Breadcrumb, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../shared/Button';
import Loader from '../../shared/Loader';
import { getContactDetails } from '../../../includes/slices/contact';
import ExportButton from 'components/shared/Exports/button';
import Invoices from 'pages/Invoices/List/components/Table';
import Reminders from '../Reminders/list';
import * as responseKeys from '../../../includes/constants/keys/response';
import './details-styles.scss';
import { ACCOUNTING_SOFTWARE_DEEP_LINKS } from '../../../includes/constants/deepLinks';
import { multiStringReplace, redirect } from '../../../includes/utils';
import { ORDER_TYPE, USER_ROLE_ADMIN, USER_ROLE_ADMIN_MANAGER } from '../../../includes/constants';
import { useCurrentRoleSlug, useOrderTypes } from '../../../includes/utils/hooks';
import Contacts from './index';
import NotesList from '../../Notes/List';
import { deleteInvoice, getContactInvoices } from 'includes/slices/invoice';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useAccountId from 'includes/hooks/useAccountId';
import useAllowZendeskIntegration from 'includes/hooks/useAllowZendeskIntegration';
import ZendeskTickets from 'components/Zendesk/Tickets/List';
import useToFEDateFormatter from 'includes/hooks/useToFEDateFormatter';
import PermissionGuard from 'components/PermissionGuard';
import {
    AUDIT_LOG,
    CONTACT,
    CONTACT_MANAGER,
    INVOICE,
    NOTE,
    ORDER,
    PAYMENT_ARRANGEMENT,
    PAYMENT_EXTENSION,
    REMINDER,
    ZENDESK,
} from 'includes/constants/permissions';
import PaymentArrangementsList from 'pages/PaymentArrangements/List';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import AuditLogList from 'pages/AuditLogs/List';
import OrdersTable from 'components/shared/Orders/Table';
import { getOrders } from 'includes/slices/orders';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import useContactTypes from 'includes/hooks/useContactTypes';
import ContactTypeLabel from 'components/ContactTypeLabel';

const { TabPane } = Tabs;
const { confirm } = Modal;

/**
 * Render the contact details
 */

const ContactDetails = props => {
    const { contactId } = useParams();
    const [invoiceIds, setInvoiceIds] = useState([]);
    const [tableFilters, setTableFilters] = useState({});

    const accountId = useAccountId();
    const organisationId = useOrganisationId();
    const currentRoleSlug = useCurrentRoleSlug();
    const allowZendeskIntegration = useAllowZendeskIntegration();
    const convertDateStringToFEFormat = useToFEDateFormatter(true);
    const convertDateOfBirthToFEFormat = useToFEDateFormatter(true, 'DD/MM/YYYY');
    const { t } = useTranslation();

    const accountingSoftwareName = useSelector(state =>
        get(state.organisation, ['selectedOrganisation', 'accounting_software_data', 'name'])
    );
    const accountingSoftwareSlug = useSelector(state =>
        get(state.organisation, ['selectedOrganisation', 'accounting_software_data', 'slug'])
    );
    const contactDetails = useSelector(state => state.contacts.contactDetails);
    const isDetailsLoading = useSelector(state => state.contacts.isDetailsLoading);
    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);
    const isAdmin = useSelector(state => state.account.isAdmin);

    const { isContactManager } = props;

    const urlNamespace = isContactManager ? 'contact-manager' : 'contact';
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    const dispatch = useDispatch();
    const history = useHistory();
    const { data: contactTypes } = useContactTypes();

    useEffect(() => {
        if ('is_manual' in selectedOrganisation && isContactManager && !selectedOrganisation.is_manual)
            history.replace('/page-not-found');
    }, [selectedOrganisation]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * use effect hook
     */
    useEffect(() => {
        dispatch(getContactDetails(organisationId, contactId));
    }, [contactId]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the contact avatar
     * Checks if the contact has an avatar of the specified dimension, if not get default avatar
     */
    const getContactAvatar = () => {
        return get(contactDetails, responseKeys.USER_AVATAR_254x254) || require('../../../assets/images/user.svg');
    };

    /**
     * Get contact details link based on logged in user's role
     *
     * @param {object} contact contact object
     * @param {boolean} isManager Whether contact manager or not. Default true
     *
     * @returns {string} Contact Details link
     */
    const getContactDetailsLink = (contact, isManager = true) => {
        let contactUrlSlug = isManager ? 'contact-manager' : 'contact';
        let contactId = get(contact, isManager ? 'parent_contact.id' : 'id');

        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return `/admin/accounts/organisation/${contactUrlSlug}/${accountId}/${organisationId}/${contactId}`;
            default:
                return `/${contactUrlSlug}/${contactId}`;
        }
    };

    /**
     * Check if the default image is loaded
     *
     *
     * @returns  boolean true|false
     */
    const isDefaultImageLoaded = () => {
        return !get(contactDetails, responseKeys.USER_AVATAR_254x254);
    };

    const breadcrumbLocale = path => t(`customerMessages.editContact.breadcrumb.${path}`);

    const getLocale = path => t(`customerMessages.contactDetails.${path}`);
    const childContacts = get(contactDetails, 'child_contacts');

    const composeFetchContactInvoices = (isRepeatingInvoices = false) => searchParams => {
        if (isRepeatingInvoices) {
            searchParams['invoice_cat_type'] = 'is_repeating_invoice';
        }
        dispatch(getContactInvoices(organisationId, contactId, searchParams));
    };

    /**
     * Delete invoice
     */
    const composeDispatchDeleteInvoice = (isRepeatingInvoices = false) => (invoiceContactId, invoiceId) => {
        confirm({
            title: t('customerMessages.invoice.confirm.deleteInvoice'),
            content: '',
            onOk: () => {
                dispatch(
                    deleteInvoice(organisationId, invoiceContactId, invoiceId, {
                        invoice_cat_type: isRepeatingInvoices ? 'is_repeating_invoice' : '',
                        contact_invoice: true,
                    })
                );
            },
        });
    };

    const [orderTypes] = useOrderTypes();

    /**
     * Find and memoise subscription order type
     */
    const orderType = useMemo(() => {
        if (orderTypes.length === 0) return null;

        return find(orderTypes, { slug: ORDER_TYPE.INVOICE });
    }, [orderTypes]);

    /**
     * Get data method - to be passed in to the orders table
     */
    const getDataMethod = useCallback(
        params => {
            if (!orderType) return;

            const newParams = {
                ...params,
                contact_id: contactId,
                organisation_id: organisationId,
            };

            if (isAdmin) newParams['account_id'] = accountId;

            dispatch(getOrders(orderType.id, { ...newParams }));
        },
        [accountId, orderType, contactId]
    );

    const contactTypeLabel = get(find(contactTypes, { id: contactDetails?.type }), 'type');

    return (
        <section>
            <div className="home-content-wrapper synced-name contact-details">
                {isAdmin && (
                    <div className="tab-arrow-link breadcrumb-spec">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to={`/admin/accounts/organisations/${accountId}`}>
                                    {breadcrumbLocale('organisations')}
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link
                                    to={`/admin/accounts/organisation/${
                                        isContactManager ? 'contact-managers' : 'contacts'
                                    }/${accountId}/${organisationId}`}
                                >
                                    {breadcrumbLocale(isContactManager ? 'contactManagers' : 'contacts')}
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {breadcrumbLocale(isContactManager ? 'contactManagerDetails' : 'contactDetails')}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                )}
                <h2 className="page-title">{getLocale(isContactManager ? 'contactManagerTitle' : 'title')}</h2>
                <div className="white-box-wrapper mid-wrapper breadcrumb-spec details-wrapper">
                    {isDetailsLoading ? (
                        <Loader />
                    ) : (
                        <>
                            <section className="details-list left-align">
                                <div
                                    className={
                                        'details-profile-pic-wrapper' +
                                        (isDefaultImageLoaded() ? ' is-default-image-loaded' : '')
                                    }
                                >
                                    <img
                                        src={getContactAvatar()}
                                        className={
                                            get(contactDetails, responseKeys.USER_AVATAR_254x254)
                                                ? 'org-avatar contact-details-spec'
                                                : 'org-avatar contact-details-spec logo-placeholder'
                                        }
                                        alt="profile-pic"
                                    />
                                </div>
                                {get(selectedOrganisation, 'external_short_code') &&
                                    get(ACCOUNTING_SOFTWARE_DEEP_LINKS, [accountingSoftwareSlug, 'contact']) &&
                                    get(contactDetails, 'external_id') && (
                                        <div className="center-align">
                                            <a
                                                className="accounting-software-external-link"
                                                href={multiStringReplace(
                                                    get(ACCOUNTING_SOFTWARE_DEEP_LINKS, [
                                                        accountingSoftwareSlug,
                                                        'contact',
                                                    ]),
                                                    {
                                                        short_code: get(selectedOrganisation, 'external_short_code'),
                                                        contact_id: get(contactDetails, 'external_id'),
                                                    }
                                                )}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                View in {accountingSoftwareName}
                                            </a>
                                        </div>
                                    )}

                                <PermissionGuard
                                    requiredPermission={isContactManager ? CONTACT_MANAGER.EDIT : CONTACT.EDIT}
                                >
                                    <div className="center-align">
                                        <Button
                                            className="green-bg-button"
                                            big
                                            filled
                                            onClick={() =>
                                                redirect(
                                                    isAdmin
                                                        ? `/admin/accounts/organisation/${urlNamespace}s/${accountId}/${organisationId}/${contactId}`
                                                        : `/edit-${urlNamespace}/${contactId}`
                                                )
                                            }
                                        >
                                            Edit
                                        </Button>
                                    </div>
                                </PermissionGuard>
                            </section>
                            <section className="details-list right-align">
                                <div className="full-wrapper">
                                    <h3 className="profile-name">
                                        {get(contactDetails, 'first_name', '')} {get(contactDetails, 'last_name', '')}
                                    </h3>
                                    <div className="sub-detail calendar-bg">
                                        {get(contactDetails, 'line_of_business')}
                                    </div>
                                </div>
                                <section className="detail-summary">
                                    <ul className="upper-right-detail">
                                        <li>
                                            <header>{getLocale('details.businessName')}</header>
                                            <div>{get(contactDetails, 'business_name', '')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.email')}</header>
                                            <div>{get(contactDetails, 'email', 'N/A')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.mobileNumber')}</header>
                                            <div>{get(contactDetails, 'mobile_number')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.phoneNumber')}</header>
                                            <div>{get(contactDetails, 'phone_number')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.dateOfBirth')}</header>
                                            <div>
                                                {convertDateOfBirthToFEFormat(get(contactDetails, 'date_of_birth'))}
                                            </div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.contactType')}</header>
                                            <div>
                                                <ContactTypeLabel label={contactTypeLabel} />
                                            </div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.postalAddress')}</header>
                                            <div>{get(contactDetails, 'postal_address')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.physicalAddress')}</header>
                                            <div>{get(contactDetails, 'physical_address')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.customer')}</header>
                                            <div>{get(contactDetails, 'is_customer') ? 'Yes' : 'No'}</div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.supplier')}</header>
                                            <div>{get(contactDetails, 'is_supplier') ? 'Yes' : 'No'}</div>
                                        </li>
                                        {get(selectedOrganisation, 'is_manual', false) && (
                                            <li>
                                                <header>{getLocale('details.externalId')}</header>
                                                <div>{get(contactDetails, 'external_id')}</div>
                                            </li>
                                        )}
                                        <li>
                                            <header>{getLocale('details.registrationNumber')}</header>
                                            <div>{get(contactDetails, 'registration_number')}</div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.status')}</header>
                                            <div>
                                                {get(contactDetails, 'is_deleted') ? 'Archived/Inactive' : 'Active'}
                                            </div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.contractStartDate')}</header>
                                            <div>
                                                {convertDateStringToFEFormat(
                                                    get(contactDetails, 'contract_start_date')
                                                )}
                                            </div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.contractEndDate')}</header>
                                            <div>
                                                {convertDateStringToFEFormat(get(contactDetails, 'contract_end_date'))}
                                            </div>
                                        </li>
                                        <li>
                                            <header>
                                                {getLocale('details.contactAmountDue')}
                                                <br></br>
                                                <span style={{ textTransform: 'none' }}>
                                                    {getLocale('details.contactAmountInfo')}
                                                </span>
                                            </header>
                                            <div>
                                                <span
                                                    title={getNumberFormattedAsCurrency(
                                                        contactDetails?.contact_amount_due?.amount
                                                    )}
                                                >
                                                    {getNumberFormattedAsCurrency(
                                                        contactDetails?.contact_amount_due?.amount_short
                                                    )}
                                                </span>
                                            </div>
                                        </li>
                                        {get(contactDetails, ['invoice_contact_amount_due', 'amount'], 0) > 0 && (
                                            <li>
                                                <header>
                                                    {getLocale('details.invoiceContactAmountDue')}
                                                    <br></br>
                                                    <span style={{ textTransform: 'none' }}>
                                                        {getLocale('details.invoiceContactAmountInfo')}
                                                    </span>
                                                </header>
                                                <div>
                                                    <span
                                                        title={getNumberFormattedAsCurrency(
                                                            contactDetails?.invoice_contact_amount_due?.amount
                                                        )}
                                                    >
                                                        {getNumberFormattedAsCurrency(
                                                            contactDetails?.invoice_contact_amount_due?.amount_short
                                                        )}
                                                    </span>
                                                </div>
                                            </li>
                                        )}
                                        {get(contactDetails, ['amount_due', 'amount'], 0) > 0 &&
                                            get(contactDetails, ['invoice_contact_amount_due', 'amount'], 0) > 0 && (
                                                <li>
                                                    <header>{getLocale('details.amountDue')}</header>
                                                    <div>
                                                        <span
                                                            title={getNumberFormattedAsCurrency(
                                                                contactDetails?.amount_due?.amount
                                                            )}
                                                        >
                                                            {getNumberFormattedAsCurrency(
                                                                contactDetails?.amount_due?.amount_short
                                                            )}
                                                        </span>
                                                    </div>
                                                </li>
                                            )}
                                        <li>
                                            <header>
                                                {getLocale('details.contactAmountPaid')}
                                                <br></br>
                                                <span style={{ textTransform: 'none' }}>
                                                    {getLocale('details.contactAmountInfo')}
                                                </span>
                                            </header>
                                            <div>
                                                <span
                                                    title={getNumberFormattedAsCurrency(
                                                        contactDetails?.contact_amount_paid?.amount
                                                    )}
                                                >
                                                    {getNumberFormattedAsCurrency(
                                                        contactDetails?.contact_amount_paid?.amount_short
                                                    )}
                                                </span>
                                            </div>
                                        </li>
                                        {get(contactDetails, ['invoice_contact_amount_paid', 'amount'], 0) > 0 && (
                                            <li>
                                                <header>
                                                    {getLocale('details.invoiceContactAmountPaid')}
                                                    <br></br>
                                                    <span style={{ textTransform: 'none' }}>
                                                        {getLocale('details.invoiceContactAmountInfo')}
                                                    </span>
                                                </header>
                                                <div>
                                                    <span
                                                        title={getNumberFormattedAsCurrency(
                                                            contactDetails?.invoice_contact_amount_paid?.amount
                                                        )}
                                                    >
                                                        {getNumberFormattedAsCurrency(
                                                            contactDetails?.invoice_contact_amount_paid?.amount_short
                                                        )}
                                                    </span>
                                                </div>
                                            </li>
                                        )}
                                        {get(contactDetails, ['amount_paid', 'amount'], 0) > 0 &&
                                            get(contactDetails, ['invoice_contact_amount_paid', 'amount'], 0) > 0 && (
                                                <li>
                                                    <header>{getLocale('details.amountPaid')}</header>
                                                    <div>
                                                        <span
                                                            title={getNumberFormattedAsCurrency(
                                                                contactDetails?.amount_paid?.amount
                                                            )}
                                                        >
                                                            {getNumberFormattedAsCurrency(
                                                                contactDetails?.amount_paid?.amount_short
                                                            )}
                                                        </span>
                                                    </div>
                                                </li>
                                            )}
                                        <li>
                                            <header>{getLocale('details.brokenArrangementCount')}</header>
                                            <div>
                                                <span>{contactDetails?.broken_arrangement_count}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.lateArrangementPaymentCount')}</header>
                                            <div>
                                                <span>{contactDetails?.late_arrangement_payment_count}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <header>{getLocale('details.daysOverdue')}</header>
                                            <div>
                                                <span>
                                                    {contactDetails?.oldest_invoice_days_overdue > 0
                                                        ? contactDetails?.oldest_invoice_days_overdue
                                                        : ''}
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className="upper-right-detail single-col-detail">
                                        {!isEmpty(get(contactDetails, 'parent_contact')) && (
                                            <li>
                                                <header>{getLocale('details.manager')}</header>
                                                <div>
                                                    <Link to={getContactDetailsLink(contactDetails)}>
                                                        {get(contactDetails, 'parent_contact.name')}
                                                    </Link>
                                                </div>
                                            </li>
                                        )}
                                        {!isEmpty(childContacts) && (
                                            <li>
                                                <header>{getLocale('details.child')}</header>
                                                <div>
                                                    {childContacts.map((childContact, index) => {
                                                        return (
                                                            <Link
                                                                key={get(childContact, 'id')}
                                                                to={getContactDetailsLink(childContact, false)}
                                                            >
                                                                {get(childContact, 'name')}
                                                                {index < childContacts.length - 1 ? ', ' : ''}
                                                            </Link>
                                                        );
                                                    })}
                                                </div>
                                            </li>
                                        )}
                                    </ul>
                                </section>
                            </section>
                        </>
                    )}
                </div>
                <div className="full-wrapper box-wrapper white-box-wrapper no-padding myaccount-content tab-wrapper">
                    <Tabs destroyInactiveTabPane>
                        {isContactManager ? (
                            <TabPane key="contacts" tab="Contacts">
                                {isDetailsLoading ? (
                                    <Loader />
                                ) : (
                                    <Contacts
                                        mode="contact-manager-contacts"
                                        contactManageId={contactId}
                                        history={props.history}
                                        match={props.match}
                                        showActionButtons={false}
                                    />
                                )}
                            </TabPane>
                        ) : null}
                        <TabPane key="invoices" tab="Invoices">
                            <PermissionGuardLayout requiredPermission={INVOICE.LIST} showFallbackUI>
                                {isDetailsLoading ? (
                                    <Loader />
                                ) : (
                                    <div className="invoices-wrapper">
                                        <ExportButton
                                            exportType="contact-invoices"
                                            contactId={contactId}
                                            queryParams={{ invoice_ids: invoiceIds, ...tableFilters }}
                                        />
                                        <Invoices
                                            invoiceIds={invoiceIds}
                                            setInvoiceIds={setInvoiceIds}
                                            fetchInvoices={composeFetchContactInvoices()}
                                            deleteInvoice={composeDispatchDeleteInvoice()}
                                            setTableFilters={setTableFilters}
                                            tableFilters={tableFilters}
                                            contactId={contactId}
                                            history={props.history}
                                            organisation={organisationId}
                                            accountId={accountId}
                                            showOnDemandCampaign={false}
                                            showActionButtons={true}
                                        />
                                    </div>
                                )}
                            </PermissionGuardLayout>
                        </TabPane>
                        <TabPane key="recurring-invoices" tab="Recurring Invoices">
                            <PermissionGuardLayout requiredPermission={INVOICE.LIST} showFallbackUI>
                                {isDetailsLoading ? (
                                    <Loader />
                                ) : (
                                    <div className="invoices-wrapper">
                                        <ExportButton
                                            exportType="contact-invoices"
                                            contactId={contactId}
                                            queryParams={{ invoice_ids: invoiceIds, ...tableFilters }}
                                        />
                                        <Invoices
                                            invoiceIds={invoiceIds}
                                            setInvoiceIds={setInvoiceIds}
                                            fetchInvoices={composeFetchContactInvoices(true)}
                                            deleteInvoice={composeDispatchDeleteInvoice(true)}
                                            setTableFilters={setTableFilters}
                                            tableFilters={tableFilters}
                                            contactId={contactId}
                                            history={props.history}
                                            organisation={organisationId}
                                            accountId={accountId}
                                            showOnDemandCampaign={false}
                                            showActionButtons={true}
                                            isRepeatingInvoices={true}
                                        />
                                    </div>
                                )}
                            </PermissionGuardLayout>
                        </TabPane>

                        <TabPane key="reminders" tab="Reminders">
                            <PermissionGuardLayout requiredPermission={REMINDER.LIST} showFallbackUI>
                                {isDetailsLoading ? (
                                    <Loader />
                                ) : (
                                    <Reminders
                                        mode="contact"
                                        contactId={contactId}
                                        history={props.history}
                                        accountId={accountId}
                                        organisation={organisationId}
                                    />
                                )}
                            </PermissionGuardLayout>
                        </TabPane>

                        <TabPane key="notes" tab="Notes">
                            <PermissionGuardLayout requiredPermission={NOTE.LIST} showFallbackUI>
                                <NotesList />
                            </PermissionGuardLayout>
                        </TabPane>

                        {allowZendeskIntegration ? (
                            <TabPane key="zendesk-tickets" tab="Zendesk Tickets">
                                <PermissionGuardLayout requiredPermission={ZENDESK.TICKET.LIST} showFallbackUI>
                                    <ZendeskTickets contactId={contactId} />
                                </PermissionGuardLayout>
                            </TabPane>
                        ) : null}

                        <TabPane key="payment-arrangement" tab="Payment Arrangements">
                            <PermissionGuardLayout requiredPermission={PAYMENT_ARRANGEMENT.LIST} showFallbackUI>
                                <PaymentArrangementsList
                                    contactId={contactId}
                                    showTitle={false}
                                    currentPaymentPlanType="arrangement"
                                />
                            </PermissionGuardLayout>
                        </TabPane>

                        <TabPane key="payment-extension" tab="Payment Extensions">
                            <PermissionGuardLayout requiredPermission={PAYMENT_EXTENSION.LIST} showFallbackUI>
                                <PaymentArrangementsList
                                    contactId={contactId}
                                    showTitle={false}
                                    currentPaymentPlanType="extension"
                                />
                            </PermissionGuardLayout>
                        </TabPane>

                        <TabPane key="audit-logs" tab="Audit Logs">
                            <PermissionGuardLayout requiredPermission={AUDIT_LOG.LIST} showFallbackUI>
                                <AuditLogList customDataValues={{ contact_id: contactId }} showTitle={false} />
                            </PermissionGuardLayout>
                        </TabPane>

                        <Tabs.TabPane key="contacts-orders-table" tab="Orders">
                            <PermissionGuardLayout requiredPermission={ORDER.INVOICE.LIST} showFallbackUI>
                                <OrdersTable
                                    getDataMethod={getDataMethod}
                                    isSearching={false}
                                    setIsSearching={() => {}}
                                    typeSlug={ORDER_TYPE.INVOICE}
                                />
                            </PermissionGuardLayout>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </section>
    );
};

ContactDetails.propTypes = {
    history: PropTypes.object,
    isContactManager: PropTypes.bool,
    match: PropTypes.object,
    t: PropTypes.func,
};

export default ContactDetails;

/**
 * Connect API class.
 * Handles all api requests related to Connecting accounting software
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class ConnectApi extends BaseApiHandler {
    /**
     * Call the api to get authorization url
     *
     * @param {string} accounting_software_id Accounting software id
     * @param {string} organisation_id Organisation Id Default ''
     *
     * @returns {object} API Response
     * */
    getAuthorizationUrl = ({ accounting_software_id, organisation_id = '' }) =>
        this.axios.get(
            this.getApiUrl('ACCOUNTING_SOFTWARES_GET_AUTHORIZATION', {
                accounting_software_id: accounting_software_id,
            }),
            organisation_id ? { params: { organisation_id: organisation_id } } : {}
        );

    /**
     * Call the api to verify user authorization
     *
     * @param {object} credentials  Credentials
     *
     * @returns {object} API Response
     * */
    verifyAuthorization = credentials =>
        this.axios.post(this.getApiUrl('ACCOUNTING_SOFTWARES_VERIFY_AUTHORIZATION'), credentials);
}

export default new ConnectApi();

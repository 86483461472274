/**
 * Login Header
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import the required modules
import { React, Link } from '../../../../includes/exports/react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import Button from '../../Button';
import { WORDPRESS_URL } from '../../../../includes/constants/config';

/**
 * Login Header Component
 */
const LoginHeader = props => {
    return (
        <section className="header-signin-register-wrapper common-header-wrapper">
            <h1>
                <a className="logo" href={WORDPRESS_URL} /> {/* eslint-disable-line */}
            </h1>
            <Button className="sign-in header-right-content" big id="popover-button">
                <Link to="/">{props.t('customerMessages.header.register')}</Link>
            </Button>
        </section>
    );
};

/**
 * Proptypes
 */
LoginHeader.propTypes = {
    t: PropTypes.func,
};

export default withTranslation()(LoginHeader);

/**
 * Create subscription form
 *
 * @version 2.8.0
 */

import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';

import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import Button from 'components/shared/Button';
import FormField from 'components/shared/FormField';
import { CREATE_SUBSCRIPTION_FORM } from 'includes/constants';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import moment from 'moment';

const CreateSubscriptionForm = props => {
    const isCreatingSubscriptionPlan = useSelector(state => state.subscription.isCreatingSubscriptionPlan);
    /**
     * Get the arrangement due disabled dates to prevent the user from picking the dates from range picker
     **/
    const getDisabledDates = current => {
        if (current) {
            return moment().add(-1, 'days') >= current;
        }
    };
    /**
     * Generate the label from language file
     *
     * @param   {string}    field   Field for which locale is to be generated
     *
     * @returns {string}    Generated Locale label
     */
    const localeLabel = field => props.t(`adminMessages.planAccounts.subscription.form.input.label.${field}`);
    return (
        <div className="my-profile-detail" style={{ marginTop: 20 }}>
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="switch"
                            name={requestKeys.IS_TRIAL_PLAN}
                            label={localeLabel(requestKeys.IS_TRIAL_PLAN)}
                            hasFeedback
                            component={FormField}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="date"
                            name={requestKeys.SUBSCRIPTION_END_DATE}
                            label={localeLabel(requestKeys.SUBSCRIPTION_END_DATE)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.date]}
                            reduxChange={props.change}
                            className="form-control"
                            disabledDate={getDisabledDates}
                        />
                    </Col>
                </Row>
            </Form>
            <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={isCreatingSubscriptionPlan}
                        submittingButtonLabel={props.t(
                            `adminMessages.planAccounts.subscription.form.submit.buttonSubmittingLabel`
                        )}
                        className="register-btn"
                    >
                        {props.t(`adminMessages.planAccounts.subscription.form.submit.buttonLabel`)}
                    </Button>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
};

/**
 * Proptypes
 */
CreateSubscriptionForm.propTypes = {
    change: PropTypes.func,
    error: PropTypes.string,
    handleSubmit: PropTypes.func,
    isAdd: PropTypes.bool,
    t: PropTypes.func,
};

export default reduxForm({
    form: CREATE_SUBSCRIPTION_FORM,
})(withTranslation()(CreateSubscriptionForm));

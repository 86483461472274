/**
 * Payment Plan Form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React, { useEffect, useMemo, useState } from 'react';
import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import Button from 'components/shared/Button';
import FormField from 'components/shared/FormField';
import PropTypes from 'prop-types';
import { Field, Fields, formValueSelector, reduxForm } from 'redux-form';
import { Form, Row, Col, Collapse, Typography, Card, Modal } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import './styles.scss';
import { useCallback } from 'react';
import ChangeAllFieldsModal from './components/ChangeAllModal';
const { Title } = Typography;

const fieldValueSelector = formValueSelector(constants.PAYMENT_PLAN_FORM);

const { confirm } = Modal;

const maxValidation = formValidations.maxValue(100);
const minValidation = formValidations.minValue(0);

/**
 * A Function to render category and percentage input fields of a list of categories
 */
// eslint-disable-next-line react/display-name
const renderDetailCategoryList = ({
    categories,
    exceptionPercentageValues,
    exceptionCategoriesMap,
    defaultCategoryValuesMap,
}) => (
    <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
        {categories?.map((detailCategory, i2) => (
            // eslint-disable-next-line
            <div>
                <div
                    className={`category-chip category-chip-${
                        exceptionPercentageValues[detailCategory?.id] != exceptionCategoriesMap[detailCategory?.id]
                            ? 'new-value'
                            : defaultCategoryValuesMap[detailCategory?.id]
                            ? 'changed'
                            : 'normal'
                    }`}
                    key={i2}
                >
                    {detailCategory?.name}
                    <Field
                        type="number"
                        min={0}
                        max={100}
                        key={i2}
                        name={`exception_percentage_list.${detailCategory?.id}`}
                        component={'input'}
                        validate={[formValidations.required, maxValidation, minValidation]}
                    />
                    %
                </div>
                {(exceptionPercentageValues[detailCategory?.id] > 100 ||
                    exceptionPercentageValues[detailCategory?.id] < 0) && (
                    <p className="text-sm text-red-600 m-0">
                        {exceptionPercentageValues[detailCategory?.id] > 100 && 'You cannot enter value more than 100'}
                        {exceptionPercentageValues[detailCategory?.id] < 0 && 'You cannot enter value less than 0'}
                    </p>
                )}
            </div>
        ))}
    </div>
);

const PaymentPlanForm = props => {
    const { t } = useTranslation();

    const loadingPaymentPlanAddEdit = useSelector(state => state.paymentArrangement.loadingPaymentPlanAddEdit);
    const [overdueRangeType, setOverdueRangeType] = useState(get(props.initialValues, 'overdue_range_type', 'b'));
    const [upfrontAmountDependentFieldsRequired, setUpfrontAmountDependentFieldsRequired] = useState(true);
    const disabled = get(props.initialValues, 'payment_arrangement_or_extension_count', 0) > 0;
    const [activeCollapse, setActiveCollapse] = useState([0]);

    const isOpenBankingEnabled = useSelector(state =>
        fieldValueSelector(state, requestKeys.PAYMENT_PLAN_OPEN_BANKING_ENABLED)
    );

    const openBankingCalculationType = useSelector(state =>
        fieldValueSelector(state, requestKeys.PAYMENT_PLAN_OPEN_BANKING_CALCULATION_TYPE)
    );

    const [isOpen, setIsOpen] = useState(false);
    const [isBulkChangeModalOpen, setIsBulkChangeModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState();

    const expenseCategories = useSelector(state => state.openBanking.expenseCategories);

    const exceptionPercentageValues = useSelector(state => fieldValueSelector(state, 'exception_percentage_list'));

    /**
     * Get the localized label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.paymentArrangement.plans.addEdit.form.input.label.${field}`);

    // /**
    //  * Show detail categories modal
    //  */
    const handleModalOpen = cat => () => {
        setIsOpen(true);
        setSelectedCategory(cat);
    };

    // /**
    //  * Close detail categories modal
    //  */
    const handleModalClose = () => {
        setIsOpen(false);
    };

    // /**
    //  * A function to convert category array to map
    //  */
    const flatCategories = (categories, flatMap = {}) => {
        categories.map(category => {
            if (category.master_level_category) {
                flatCategories(category.master_level_category, flatMap);
                return;
            }
            if (category.detail_level_category) {
                flatCategories(category.detail_level_category, flatMap);
                return;
            }

            flatMap[category?.id ?? category?.yodlee_category_id] = category?.exception_percentage;
        });
        return flatMap;
    };

    // /**
    //  * Convert expense categories array to map (from yodlee categories api)
    //  */
    const exceptionCategoriesMap = useMemo(() => (expenseCategories ? flatCategories(expenseCategories) : {}), [
        expenseCategories,
    ]);

    // /**
    //  * Default category values from payment plan details api converted to map
    //  */
    const defaultCategoryValuesMap = useMemo(
        () => flatCategories(get(props.initialValues, 'updated_exception_percentage_list', [])),
        [props.initialValues]
    );

    useEffect(() => {
        if (!exceptionCategoriesMap) return;
        Object.keys(defaultCategoryValuesMap).map(id => {
            exceptionCategoriesMap[id] = defaultCategoryValuesMap[id];
        });
        props.change('exception_percentage_list', exceptionCategoriesMap);
    }, [exceptionCategoriesMap]);

    /**
     * A function that converts data to the required API format and then calls the onSubmit function.
     */
    const handleOnSubmit = useCallback(
        values => {
            props.onSubmit({
                ...values,
                exceptionCategoriesMap,
            });
        },
        [exceptionCategoriesMap]
    );

    // /**
    //  * Update values for all detail level categories within a sub-category.
    //  * Used for both increasing and decreasing values.
    //  */
    const handleBulkChange = useCallback(
        (categories = []) => (changeBy = 0) => {
            if (!selectedCategory) return;
            categories.map(item => {
                if (
                    exceptionPercentageValues[item?.id] !== undefined &&
                    parseInt(`${exceptionPercentageValues[item?.id]}`) + changeBy >= 0 &&
                    parseInt(`${exceptionPercentageValues[item?.id]}`) + changeBy <= 100
                ) {
                    props.change(
                        `exception_percentage_list.${item?.id}`,
                        parseInt(`${exceptionPercentageValues[item?.id]}`) + changeBy
                    );
                }
            });
            if (isBulkChangeModalOpen) {
                setIsBulkChangeModalOpen(false);
            }
        },
        [selectedCategory, exceptionPercentageValues, props, isBulkChangeModalOpen]
    );

    // /**
    //  * Reset all values in a sub-category to the system default.
    //  */
    const resetToDefault = useCallback(
        (categories = []) => () => {
            if (!selectedCategory) return;
            categories.map(item => {
                props.change(`exception_percentage_list.${item?.id}`, item?.exception_percentage);
            });
        },
        [selectedCategory, exceptionCategoriesMap, exceptionCategoriesMap, props]
    );

    // /**
    //  * Show reset category confirmation modal
    //  */
    const handleReset = () => {
        confirm({
            cancelText: 'No',
            content: t(`messages.paymentArrangement.plans.modal.reset.sub_title`),
            okText: 'Yes',
            title: t(`messages.paymentArrangement.plans.modal.reset.title`),
            onOk: resetToDefault(selectedCategory?.detail_level_category ?? []),
        });
    };

    return (
        <div className="my-profile-detail">
            <ChangeAllFieldsModal
                isOpen={isBulkChangeModalOpen}
                disabled={disabled}
                onCancel={() => {
                    setIsBulkChangeModalOpen(false);
                }}
                onIncrease={handleBulkChange(selectedCategory?.detail_level_category)}
                onDecrease={handleBulkChange(selectedCategory?.detail_level_category)}
            />
            <Modal
                title={selectedCategory?.name ?? ''}
                visible={isOpen}
                onOk={handleModalClose}
                onCancel={handleModalClose}
                centered={false}
                width={'100%'}
                footer={
                    <div className="flex justify-between">
                        <div className=" text-left d-grid">
                            <div className="flex p-0 items-end category-notes">
                                <div className="normal" />
                                <p>{getLocaleLabel('notes.system_default')}</p>
                            </div>
                            <div className="flex p-0 items-end category-notes">
                                <div className="changed" />
                                <p>{getLocaleLabel('notes.edited')}</p>
                            </div>
                            <div className="flex p-0 items-end category-notes">
                                <div className="new-value" />
                                <p> {getLocaleLabel('notes.new_values')}</p>
                            </div>
                        </div>
                        <div className="flex gap-3 self-end ">
                            <button
                                key="decrease-button"
                                className="crud-link"
                                onClick={() => {
                                    setIsBulkChangeModalOpen(true);
                                }}
                            >
                                Change all values
                            </button>

                            <Button key="reset-button" onClick={handleReset} className="ant-btn green-bg-button">
                                {getLocaleLabel('reset')}
                            </Button>

                            <Button key="cancel-button" onClick={handleModalClose} className="ant-btn white-bg-button">
                                {getLocaleLabel('cancel')}
                            </Button>

                            <Button
                                key="apply-button"
                                onClick={handleModalClose}
                                filled
                                className="ant-btn green-bg-button"
                            >
                                {getLocaleLabel('apply')}
                            </Button>
                        </div>
                    </div>
                }
            >
                <Fields
                    names={['exception_percentage_list']}
                    categories={selectedCategory?.detail_level_category ?? []}
                    exceptionPercentageValues={exceptionPercentageValues}
                    exceptionCategoriesMap={exceptionCategoriesMap}
                    defaultCategoryValuesMap={defaultCategoryValuesMap}
                    component={renderDetailCategoryList}
                />
            </Modal>
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.PAYMENT_PLAN_NAME}
                            label={getLocaleLabel(requestKeys.PAYMENT_PLAN_NAME)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.PAYMENT_PLAN_FEE}
                            label={getLocaleLabel(requestKeys.PAYMENT_PLAN_FEE)}
                            disabled={disabled}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.number]}
                            normalize={val => {
                                if (/[+-]?([0-9]*[.])?[0-9]+/.test(val)) {
                                    const [whole, decimal] = val.split('.');
                                    if (decimal?.length > 1) return `${whole}.${decimal.substring(0, 2)}`;
                                }
                                return val;
                            }}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            className="upfront-amount-slider-wrapper"
                            getTooltipPopupContainer={triggerNode => triggerNode}
                            type="slider"
                            range={true}
                            name={requestKeys.PAYMENT_PLAN_UPFRONT_AMOUNT}
                            label={getLocaleLabel(requestKeys.PAYMENT_PLAN_UPFRONT_AMOUNT)}
                            disabled={disabled}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                            reduxChange={(fieldName, fieldValue) => {
                                props.change(fieldName, fieldValue);
                                if (fieldValue[0] > 0 || fieldValue[1] > 0) {
                                    setUpfrontAmountDependentFieldsRequired(true);
                                } else {
                                    setUpfrontAmountDependentFieldsRequired(false);
                                }
                            }}
                            tooltipVisible={true}
                            tooltipPlacement="bottom"
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.PAYMENT_PLAN_MAX_UPFRONT_AMOUNT_DURATION}
                            label={getLocaleLabel(requestKeys.PAYMENT_PLAN_MAX_UPFRONT_AMOUNT_DURATION)}
                            disabled={disabled}
                            className="form-control with-pre-tab item-has-add-on-block"
                            hasFeedback
                            component={FormField}
                            validate={[
                                upfrontAmountDependentFieldsRequired ? formValidations.required : () => {},
                                formValidations.number,
                            ]}
                            required={upfrontAmountDependentFieldsRequired}
                            addonAfter={
                                <Field
                                    name={requestKeys.PAYMENT_PLAN_MAX_UPFRONT_AMOUNT_DURATION_TYPE}
                                    type="select"
                                    disabled={disabled}
                                    component={FormField}
                                    options={constants.PAYMENT_PLAN_FREQUENCY_DAY_TYPE_OPTION.map(o => ({
                                        name: o.label,
                                        value: o.id,
                                    }))}
                                    disableEmptyOption
                                    validate={[formValidations.required]}
                                />
                            }
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={overdueRangeType === 'b' ? 8 : 12}>
                        <Field
                            name={requestKeys.PAYMENT_PLAN_OVERDUE_RANGE_TYPE}
                            label={getLocaleLabel(requestKeys.PAYMENT_PLAN_OVERDUE_RANGE_TYPE)}
                            type="select"
                            disabled={disabled}
                            component={FormField}
                            options={constants.PAYMENT_PLAN_OVERDUE_TYPE_OPTIONS.map(o => ({
                                name: o.label,
                                value: o.id,
                            }))}
                            disableEmptyOption
                            validate={[formValidations.required]}
                            onChange={value => setOverdueRangeType(value)}
                        />
                    </Col>
                    {overdueRangeType === 'ge' || overdueRangeType === 'b' ? (
                        <Col span={overdueRangeType === 'b' ? 8 : 12}>
                            <Field
                                type="text"
                                name={requestKeys.PAYMENT_PLAN_OVERDUE_RANGE_FROM}
                                label={getLocaleLabel(
                                    overdueRangeType === 'b'
                                        ? requestKeys.PAYMENT_PLAN_OVERDUE_RANGE_FROM
                                        : requestKeys.PAYMENT_PLAN_OVERDUE_GREATER_THAN
                                )}
                                disabled={disabled}
                                hasFeedback
                                component={FormField}
                                validate={[formValidations.required, formValidations.number]}
                                className="form-control with-pre-tab"
                                required
                            />
                        </Col>
                    ) : null}
                    {overdueRangeType === 'le' || overdueRangeType === 'b' ? (
                        <Col span={overdueRangeType === 'b' ? 8 : 12}>
                            <Field
                                type="text"
                                name={requestKeys.PAYMENT_PLAN_OVERDUE_RANGE_TO}
                                label={getLocaleLabel(
                                    overdueRangeType === 'b'
                                        ? requestKeys.PAYMENT_PLAN_OVERDUE_RANGE_TO
                                        : requestKeys.PAYMENT_PLAN_OVERDUE_LESS_THAN
                                )}
                                disabled={disabled}
                                hasFeedback
                                component={FormField}
                                validate={[formValidations.required, formValidations.number]}
                                className="form-control with-pre-tab"
                                required
                            />
                        </Col>
                    ) : null}
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        {props.isExtensionPlan ? (
                            <Field
                                type="text"
                                name={requestKeys.PAYMENT_PLAN_MAX_PAYMENT_DATE_EXTENSION_DURATION}
                                label={getLocaleLabel(requestKeys.PAYMENT_PLAN_MAX_PAYMENT_DATE_EXTENSION_DURATION)}
                                className="form-control with-pre-tab item-has-add-on-block"
                                hasFeedback
                                disabled={disabled}
                                component={FormField}
                                validate={[formValidations.required, formValidations.number]}
                                required
                                addonAfter={
                                    <Field
                                        name={requestKeys.PAYMENT_PLAN_MAX_PAYMENT_DATE_EXTENSION_DURATION_TYPE}
                                        type="select"
                                        disabled={disabled}
                                        component={FormField}
                                        options={constants.PAYMENT_PLAN_FREQUENCY_TYPE_OPTIONS.map(o => ({
                                            name: o.label,
                                            value: o.id,
                                        }))}
                                        disableEmptyOption
                                        validate={[formValidations.required]}
                                    />
                                }
                            />
                        ) : (
                            <Field
                                type="text"
                                name={requestKeys.PAYMENT_PLAN_MAX_DURATION}
                                label={getLocaleLabel(requestKeys.PAYMENT_PLAN_MAX_DURATION)}
                                className="form-control with-pre-tab item-has-add-on-block"
                                hasFeedback
                                disabled={disabled}
                                component={FormField}
                                validate={[formValidations.required, formValidations.number]}
                                required
                                addonAfter={
                                    <Field
                                        name={requestKeys.PAYMENT_PLAN_MAX_DURATION_TYPE}
                                        type="select"
                                        disabled={disabled}
                                        component={FormField}
                                        options={constants.PAYMENT_PLAN_FREQUENCY_TYPE_OPTIONS.map(o => ({
                                            name: o.label,
                                            value: o.id,
                                        }))}
                                        disableEmptyOption
                                        validate={[formValidations.required]}
                                    />
                                }
                            />
                        )}
                    </Col>
                    {!props.isExtensionPlan ? (
                        <Col span={12}>
                            <Field
                                name={requestKeys.PAYMENT_PLAN_PAYMENT_FREQUENCY_TYPE}
                                label={getLocaleLabel(requestKeys.PAYMENT_PLAN_PAYMENT_FREQUENCY_TYPE)}
                                type="select"
                                disabled={disabled}
                                component={FormField}
                                options={constants.PAYMENT_PLAN_PAYMENT_FREQUENCY_TYPE_OPTIONS.map(o => ({
                                    name: o.label,
                                    value: o.id,
                                }))}
                                disableEmptyOption
                                validate={[formValidations.required]}
                                required
                            />
                        </Col>
                    ) : null}
                </Row>
                {!props.isExtensionPlan ? (
                    <Row gutter={16}>
                        <Col span={12}>
                            <Field
                                type="text"
                                className="form-control with-pre-tab item-has-add-on-block"
                                name={requestKeys.PAYMENT_PLAN_MAX_FIRST_PAYMENT_DATE_DURATION}
                                label={getLocaleLabel(requestKeys.PAYMENT_PLAN_MAX_FIRST_PAYMENT_DATE_DURATION)}
                                disabled={disabled}
                                hasFeedback
                                component={FormField}
                                validate={[formValidations.required, formValidations.number]}
                                required
                                addonAfter={
                                    <Field
                                        name={requestKeys.PAYMENT_PLAN_MAX_FIRST_PAYMENT_DATE_DURATION_TYPE}
                                        type="select"
                                        disabled={disabled}
                                        component={FormField}
                                        options={constants.PAYMENT_PLAN_FREQUENCY_DAY_TYPE_OPTION.map(o => ({
                                            name: o.label,
                                            value: o.id,
                                        }))}
                                        disableEmptyOption
                                        validate={[formValidations.required]}
                                    />
                                }
                            />
                        </Col>
                        <Col span={12}>
                            <Field
                                type="text"
                                className="form-control with-pre-tab item-has-add-on-block"
                                name={requestKeys.PAYMENT_PLAN_MAX_UNPAID_INSTALLMENT_CANCEL_DURATION}
                                label={getLocaleLabel(requestKeys.PAYMENT_PLAN_MAX_UNPAID_INSTALLMENT_CANCEL_DURATION)}
                                disabled={disabled}
                                hasFeedback
                                component={FormField}
                                validate={[formValidations.required, formValidations.number]}
                                required
                                addonAfter={
                                    <Field
                                        name={requestKeys.PAYMENT_PLAN_MAX_UNPAID_INSTALLMENT_CANCEL_DURATION_TYPE}
                                        type="select"
                                        disabled={disabled}
                                        component={FormField}
                                        options={constants.PAYMENT_PLAN_UNPAID_INSTALLMENT_CANCEL_TYPE_OPTIONS.map(
                                            o => ({
                                                name: o.label,
                                                value: o.id,
                                            })
                                        )}
                                        disableEmptyOption
                                        validate={[formValidations.required]}
                                    />
                                }
                            />
                        </Col>
                    </Row>
                ) : null}
                <Row gutter={16}>
                    <Col span={6}>
                        <Field
                            type="switch"
                            name={requestKeys.PAYMENT_PLAN_ALLOW_NOTES}
                            label={getLocaleLabel(requestKeys.PAYMENT_PLAN_ALLOW_NOTES)}
                            hasFeedback
                            component={FormField}
                            info={t(
                                `messages.paymentArrangement.plans.addEdit.form.info.${requestKeys.PAYMENT_PLAN_ALLOW_NOTES}`
                            )}
                        />
                    </Col>
                    <Col span={6}>
                        <Field
                            type="switch"
                            name={requestKeys.PAYMENT_PLAN_AUTO_APPROVE}
                            label={getLocaleLabel(requestKeys.PAYMENT_PLAN_AUTO_APPROVE)}
                            hasFeedback
                            component={FormField}
                        />
                    </Col>
                    <Col span={6}>
                        <Field
                            type="switch"
                            name={requestKeys.PAYMENT_PLAN_IS_ENABLED}
                            label={getLocaleLabel(requestKeys.PAYMENT_PLAN_IS_ENABLED)}
                            hasFeedback
                            component={FormField}
                        />
                    </Col>
                    {!props.isExtensionPlan && (
                        <Col span={6}>
                            <Field
                                type="switch"
                                name={requestKeys.PAYMENT_PLAN_OPEN_BANKING_ENABLED}
                                label={getLocaleLabel(requestKeys.PAYMENT_PLAN_OPEN_BANKING_ENABLED)}
                                hasFeedback
                                component={FormField}
                            />
                        </Col>
                    )}
                </Row>
                {!props.isExtensionPlan && isOpenBankingEnabled && (
                    <Row gutter={16}>
                        <Col span={12}>
                            <Field
                                type="number"
                                name={requestKeys.PAYMENT_PLAN_OPEN_BANKING_BUFFER}
                                label={getLocaleLabel(requestKeys.PAYMENT_PLAN_OPEN_BANKING_BUFFER)}
                                hasFeedback
                                component={FormField}
                                defaultValue={true}
                                // disabled={disabled}
                                defaultChecked={true}
                                validate={[formValidations.required, formValidations.number]}
                                className="form-control with-pre-tab"
                                info="The Buffer is subtracted from the final affordability calculation to allow for unexpected expenses."
                                infoAsToolTip
                            />
                        </Col>
                        <Col span={12}>
                            <Field
                                name={requestKeys.PAYMENT_PLAN_OPEN_BANKING_CALCULATION_TYPE}
                                type="select"
                                // disabled={disabled}
                                label={getLocaleLabel(requestKeys.PAYMENT_PLAN_OPEN_BANKING_CALCULATION_TYPE)}
                                component={FormField}
                                options={constants.OPEN_BANKING_CALCULATION_TYPE.map(o => ({
                                    name: o.label,
                                    value: o.value,
                                }))}
                                defaultValue={2}
                                disableEmptyOption
                                info={'The buffer will be subtracted from the total of either calculation methods'}
                                validate={[]}
                            />
                        </Col>
                    </Row>
                )}

                {!props.isExtensionPlan && isOpenBankingEnabled && openBankingCalculationType === 2 && (
                    <div>
                        <Title level={4}>{getLocaleLabel('expense_categories')}</Title>
                        <Collapse
                            className="text-left"
                            bordered={false}
                            expandIconPosition="right"
                            accordion
                            onChange={pan => setActiveCollapse(pan)}
                            activeKey={activeCollapse}
                        >
                            {expenseCategories?.map((mainCategory, i) => (
                                <Collapse.Panel key={i} header={mainCategory?.name}>
                                    <Row>
                                        {mainCategory?.master_level_category.map((subCategory, j) => (
                                            <Col span={8} className="m-2" key={j}>
                                                <Card className="category-card" onClick={handleModalOpen(subCategory)}>
                                                    <p>{subCategory?.name}</p>
                                                    <div className="category-chip-group  ">
                                                        {subCategory?.detail_level_category
                                                            ?.slice(0, 5)
                                                            .map((detailCategory, i2) => (
                                                                <div
                                                                    // className="category-chip category-chip-normal flex-grow"
                                                                    className={`category-chip flex-grow category-chip-${
                                                                        exceptionPercentageValues &&
                                                                        exceptionPercentageValues[detailCategory?.id] !=
                                                                            exceptionCategoriesMap[detailCategory?.id]
                                                                            ? 'new-value'
                                                                            : defaultCategoryValuesMap &&
                                                                              defaultCategoryValuesMap[
                                                                                  detailCategory?.id
                                                                              ]
                                                                            ? 'changed'
                                                                            : 'normal'
                                                                    }`}
                                                                    key={i2}
                                                                >
                                                                    {detailCategory?.name} &nbsp;{' '}
                                                                    {(exceptionPercentageValues &&
                                                                        exceptionPercentageValues[
                                                                            detailCategory?.id
                                                                        ]) ??
                                                                        detailCategory?.exception_percentage}
                                                                    %
                                                                </div>
                                                            ))}

                                                        {subCategory?.detail_level_category.length > 5 && (
                                                            <div className="category-chip category-chip-normal">
                                                                ...
                                                            </div>
                                                        )}
                                                    </div>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                        <Row>
                            <Col span={6}></Col>
                        </Row>
                    </div>
                )}
            </Form>
            <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit(handleOnSubmit)}>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={loadingPaymentPlanAddEdit}
                        submittingButtonLabel={t(
                            `messages.paymentArrangement.plans.addEdit.form.submit.buttonSubmittingLabel.${
                                props.isAdd ? 'add' : 'edit'
                            }`
                        )}
                        className="register-btn"
                    >
                        {t(
                            `messages.paymentArrangement.plans.addEdit.form.submit.buttonLabel.${
                                props.isAdd ? 'add' : 'edit'
                            }`
                        )}
                    </Button>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
};

/**
 * Proptypes
 */
PaymentPlanForm.propTypes = {
    change: PropTypes.func,
    error: PropTypes.string,
    initialValues: PropTypes.object,
    isAdd: PropTypes.bool,
    isExtensionPlan: PropTypes.bool,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default reduxForm({
    form: constants.PAYMENT_PLAN_FORM,
})(PaymentPlanForm);

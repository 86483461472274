/**
 * Connects the sagas, reducers to the store
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import the required modules
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
// import { createStore, applyMiddleware, compose } from 'redux';
import clientMiddleware from '../middlewares/clientMiddleware';
import rootReducers from '../reducers/core';
import rootSagas from '../sagas/core';

// initialize the saga middle ware and store
const sagaMiddleware = createSagaMiddleware();
// const composeEnhancer =
//     process.env.NODE_ENV === 'production' ? compose : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(rootReducers, composeEnhancer(applyMiddleware(sagaMiddleware, clientMiddleware)));

const store = configureStore({ reducer: rootReducers, middleware: [sagaMiddleware, clientMiddleware] });

sagaMiddleware.run(rootSagas);

// export the store
export default store;

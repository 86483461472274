/**
 * Date and Range Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au?>
 */

//import required modules
import React, { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { DatePicker, Select, Icon } from 'antd';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

/**
 * Date and Range component
 */
const DateAndRange = props => {
    const { date, dateRange, setDate, setDateRange } = props;
    const [dateFilter, setDateFilter] = useState(props.filterType || 'date-range');
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    /**
     * Toggle the date picker
     */
    const toggleDatePicker = () => setDatePickerOpen(!datePickerOpen);

    return (
        <>
            <span className="menu" style={{ marginRight: 10 }}>
                {props.t(`messages.notes.list.label.filterBy`)}
                <Select
                    value={dateFilter}
                    onChange={filter => {
                        setDateFilter(filter);
                        setDate(filter === 'date' ? moment() : '');
                        setDateRange(filter === 'date-range' ? [moment(), moment()] : [null, null]);
                    }}
                    style={{ marginLeft: 10, minWidth: 145 }}
                >
                    <Option value="date">{props.t(`messages.notes.list.label.date`)}</Option>
                    <Option value="date-range">{props.t(`messages.notes.list.label.dateRange`)}</Option>
                </Select>
            </span>
            {dateFilter === 'date' ? (
                <>
                    <span className="menu">
                        <Button type="link" onClick={() => setDate(moment(date).subtract(1, 'day'))}>
                            {'<<'}
                        </Button>
                        <span>
                            <b style={{ fontSize: 14, color: '#666' }}>
                                {datePickerOpen ? (
                                    <DatePicker
                                        onChange={newdate => {
                                            toggleDatePicker();
                                            setDate(newdate);
                                        }}
                                        value={date}
                                        showToday
                                        open={datePickerOpen}
                                    />
                                ) : date && date.isSame(moment(), 'day') ? (
                                    props.t(`messages.notes.list.label.today`)
                                ) : (
                                    <span onClick={toggleDatePicker}>{date && date.format('DD-MM-YYYY')}</span>
                                )}
                            </b>
                        </span>
                        <Button type="link" onClick={() => setDate(moment(date).add(1, 'days'))}>
                            {'>>'}
                        </Button>
                        <Button type="link" onClick={toggleDatePicker} className="calender-button">
                            <Icon type="calendar" />
                        </Button>
                    </span>
                </>
            ) : (
                <RangePicker
                    value={dateRange}
                    onChange={range => setDateRange(range)}
                    size="small"
                    format="DD-MM-YYYY"
                />
            )}
        </>
    );
};

DateAndRange.propTypes = {
    date: PropTypes.any,
    dateRange: PropTypes.any,
    filterType: PropTypes.string,
    setDate: PropTypes.func,
    setDateRange: PropTypes.func,
    t: PropTypes.func,
};

export default withTranslation()(withRouter(DateAndRange));

import i18n from 'includes/i18n';

/**
 * Campaign notifications columns
 *
 * @since 2.0.0
 */
export const campaignNotificationsColumns = [
    {
        title: i18n.t('messages.campaigns.campaignNotifications.list.label.day_id'),
        dataIndex: 'day_id',
        key: 'day_id',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('messages.campaigns.campaignNotifications.list.label.notification_type_id'),
        dataIndex: 'notification_type_id',
        key: 'notification_type_id',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('messages.campaigns.campaignNotifications.list.label.communication_template_type_id'),
        dataIndex: 'communication_template_type_id',
        key: 'communication_template_type_id',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('messages.campaigns.campaignNotifications.list.label.is_active'),
        dataIndex: 'is_active',
        key: 'is_active',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('messages.campaigns.campaignNotifications.list.label.actions'),
        dataIndex: 'actions',
        key: 'actions',
        collapseHeader: true,
    },
];

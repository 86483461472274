/**
 * Export button Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import {
    PropTypes,
    React,
    useDispatch,
    useSelector,
    withRouter,
    withTranslation,
} from '../../../includes/exports/react';
import { Dropdown, Icon, Menu } from 'antd';
import { get } from 'lodash';
import Button from '../../shared/Button';
import { exportFile } from '../../../includes/slices/exports';
import PermissionGuard from 'components/PermissionGuard';
import { CONTACT, CONTACT_MANAGER, INVENTORY, INVOICE, ORGANISATION } from 'includes/constants/permissions';

const ExportButton = props => {
    const dispatch = useDispatch();
    const isExportLoading = useSelector(state => state.exports.loadingExport);
    const organisationId =
        useSelector(state => state.organisation.selectedOrganisationId) || props.match.params.organisationId;
    const accountDetails = useSelector(state => state.account.accountDetails);
    const accountId = props.accountId || get(props.match.params, 'accountId') || get(accountDetails, 'id');

    /**
     * Export the data file
     */
    const exportDataFile = () => {
        dispatch(
            exportFile(props.exportType, accountId, organisationId, props.contactId, props.invoiceId, props.queryParams)
        );
    };

    const requiredPermission = {
        invoices: INVOICE.EXPORT,
        'invoice-invoice-payments': INVOICE.PAYMENT.EXPORT,
        'invoice-payments': INVOICE.PAYMENT.EXPORT,
        organisations: ORGANISATION.EXPORT,
        contacts: CONTACT.EXPORT,
        'contact-invoices': CONTACT.EXPORT,
        'contact-managers': CONTACT_MANAGER.EXPORT,
        inventory: INVENTORY.EXPORT,
    }[props.exportType];

    /**
     * Define the export menus
     */
    const menus = (
        <Menu>
            <Menu.Item key="1">
                <PermissionGuard requiredPermission={requiredPermission} fullWidth>
                    <span
                        onClick={() => {
                            exportDataFile();
                        }}
                    >
                        {props.t('sharedMessages.exports.csv')}
                    </span>
                </PermissionGuard>
            </Menu.Item>
        </Menu>
    );

    return props.type === 'link' ? (
        <span
            className="crud-link"
            onClick={() => {
                exportDataFile();
            }}
        >
            {isExportLoading
                ? props.t('sharedMessages.exports.button.submittingLabel')
                : props.t('sharedMessages.exports.button.label')}
        </span>
    ) : (
        <PermissionGuard requiredPermission={requiredPermission}>
            <Dropdown overlay={menus}>
                <Button
                    big
                    filled
                    submittingButtonLabel={props.t('sharedMessages.exports.button.submittingLabel')}
                    loading={isExportLoading}
                >
                    {props.t('sharedMessages.exports.button.label')}
                    <Icon type="down" />
                </Button>
            </Dropdown>
        </PermissionGuard>
    );
};

ExportButton.propTypes = {
    accountId: PropTypes.string,
    contactId: PropTypes.string,
    exportType: PropTypes.string,
    invoiceId: PropTypes.string,
    match: PropTypes.object,
    queryParams: PropTypes.any,
    t: PropTypes.func,
    type: PropTypes.string,
};

export default withRouter(withTranslation()(ExportButton));

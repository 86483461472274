/**
 * Currencies Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import CurrenciesApi from '../services/currencies';
import createAppSlice from './base';
import { CURRENCIES, ORGANISATION_CURRENCIES } from '../constants/mappings/success';
import { get, isFunction } from 'lodash';
import { getPaginationData } from 'includes/utils';
import { showApiErrors, showApiSuccess } from 'includes/utils/api';

// set the initial state
const initialState = {
    currencies: [],
    loadingCurrencies: false,
    loadingOrganisationCurrencies: false,
    loadingOrganisationCurrencyAddEdit: false,
    loadingOrganisationCurrencyDelete: false,
    organisationCurrencies: [],
    organisationCurrenciesPagination: {},
};

// define the slice
const CurrenciesSlice = createAppSlice('currenciesSlice', initialState);
const { setData } = CurrenciesSlice.actions;

/**
 * Add new organisation currency
 *
 * @param {string} organisationId Organisation ID
 * @param {object} payload Payload
 * @param {Function} callback Callback function
 */
export const addOrganisationCurrency = (organisationId, payload, callback) => async dispatch => {
    try {
        dispatch(setData('loadingOrganisationCurrencyAddEdit', true));
        const result = await CurrenciesApi.addOrganisationCurrency(organisationId, payload);
        if (callback && isFunction(callback)) {
            callback();
        }
        dispatch(setData('organisationCurrencies', get(result, ORGANISATION_CURRENCIES)));
        dispatch(setData('organisationCurrenciesPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
    } catch (errors) {
        dispatch(showApiErrors(errors, 'ORGANISATION_CURRENCY_FORM', 'ORGANISATION_CURRENCIES_ERROR'));
    } finally {
        dispatch(setData('loadingOrganisationCurrencyAddEdit'));
    }
};

/**
 * Delete organisation currency
 *
 * @param {string} organisationId Organisation ID
 * @param {string} currencyId Currency ID
 * @param {Function} callback Callback function
 */
export const deleteOrganisationCurrency = (organisationId, currencyId, callback) => async dispatch => {
    try {
        dispatch(setData('loadingOrganisationCurrencyDelete', true));
        const result = await CurrenciesApi.deleteOrganisationCurrency(organisationId, currencyId);
        if (callback && isFunction(callback)) {
            callback();
        }
        dispatch(setData('organisationCurrencies', get(result, ORGANISATION_CURRENCIES)));
        dispatch(setData('organisationCurrenciesPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'ORGANISATION_CURRENCIES_ERROR'));
    } finally {
        dispatch(setData('loadingOrganisationCurrencyDelete'));
    }
};

/**
 * Get the currencies
 */
export const getCurrencies = () => async dispatch => {
    try {
        dispatch(setData('loadingCurrencies', true));
        const result = await CurrenciesApi.getCurrencies();
        dispatch(setData('currencies', get(result, CURRENCIES)));
    } finally {
        dispatch(setData('loadingCurrencies'));
    }
};

/**
 * Get the organisation currencies
 *
 * @param {string} organisationId Organisation ID
 */
export const getOrganisationCurrencies = organisationId => async dispatch => {
    try {
        dispatch(setData('loadingOrganisationCurrencies', true));
        const result = await CurrenciesApi.getOrganisationCurrencies(organisationId);
        dispatch(setData('organisationCurrencies', get(result, ORGANISATION_CURRENCIES)));
        dispatch(setData('organisationCurrenciesPagination', getPaginationData({ result })));
    } finally {
        dispatch(setData('loadingOrganisationCurrencies'));
    }
};

/**
 * Update organisation currency
 *
 * @param {string} organisationId Organisation ID
 * @param {string} currencyId Currency ID
 * @param {object} payload Payload
 * @param {Function} callback Callback function
 */
export const updateOrganisationCurrency = (organisationId, currencyId, payload, callback) => async dispatch => {
    try {
        dispatch(setData('loadingOrganisationCurrencyAddEdit', true));
        const result = await CurrenciesApi.updateOrganisationCurrency(organisationId, currencyId, payload);
        if (callback && isFunction(callback)) {
            callback();
        }
        dispatch(setData('organisationCurrencies', get(result, ORGANISATION_CURRENCIES)));
        dispatch(setData('organisationCurrenciesPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
    } catch (errors) {
        dispatch(showApiErrors(errors, 'ORGANISATION_CURRENCY_FORM', 'ORGANISATION_CURRENCIES_ERROR'));
    } finally {
        dispatch(setData('loadingOrganisationCurrencyAddEdit'));
    }
};

// export the reducer
export default CurrenciesSlice.reducer;

/**
 * Mobile Collapse view for Scrollable table
 * This is a component responsible fmobile view which renders as a collapse view
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

import PropTypes from 'prop-types';
import { React } from '../../../includes/exports/react';
import { Checkbox, Collapse, Icon } from 'antd';
import find from 'lodash/find';
import { get } from 'lodash';

const { Panel } = Collapse;

const MobileTableCollapse = props => {
    const { rowSelection, rowKey, columns, collapseHeaderKeys, tableData } = props;
    return (
        <div className={`custom-scrollble-table-collapse ${props.mobileClassName}`}>
            {tableData.length > 0 ? (
                <Collapse
                    bordered={false}
                    expandIconPosition="right"
                    expandIcon={({ isActive }) => <Icon type="caret-down" rotate={isActive ? 90 : 0} />}
                    className="full-wrapper"
                >
                    {tableData.map(record => (
                        <Panel
                            header={
                                <section className="details-wrapper details-list">
                                    <div className="action-spec">
                                        {rowSelection && (
                                            <Checkbox
                                                onClick={e => e.stopPropagation()}
                                                checked={rowSelection.selectedRowKeys.includes(record[rowKey])}
                                                id={record[rowKey]}
                                                onChange={props.handleCheckboxClick}
                                            />
                                        )}
                                        {/* <Icon type="warning" className="table-warning-icon" /> */}
                                    </div>
                                    <div className="detail-summary">
                                        <ul>
                                            {collapseHeaderKeys.map(key => (
                                                <li key={key}>
                                                    <header>{find(columns, { dataIndex: key }).title}</header>
                                                    <div className="detail">{get(record, key)}</div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </section>
                            }
                            className="white-box-wrapper box-wrapper no-border"
                            key={record.id}
                        >
                            <div>
                                {
                                    <section className="details-wrapper details-list">
                                        <div className="detail-summary">
                                            <ul>
                                                {columns
                                                    .filter(c => !collapseHeaderKeys.includes(c.dataIndex))
                                                    .map(c => (
                                                        <li key={c.dataIndex} className="rel-pos">
                                                            <header>{c.title}</header>
                                                            <div className="detail">{get(record, c.dataIndex)}</div>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </section>
                                }
                            </div>
                        </Panel>
                    ))}
                </Collapse>
            ) : (
                !props.loading && <p className="empty-table-msg">{props.localeMessage}</p>
            )}
        </div>
    );
};

MobileTableCollapse.propTypes = {
    collapseHeaderKeys: PropTypes.array.isRequired,
    columns: PropTypes.array,
    handleCheckboxClick: PropTypes.func,
    loading: PropTypes.bool,
    localeMessage: PropTypes.node,
    mobileClassName: PropTypes.string,
    rowKey: PropTypes.string,
    rowSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    tableData: PropTypes.array,
};

MobileTableCollapse.defaultProps = {
    collapseHeaderKeys: [],
    columns: [],
    mobileClassName: '',
};

export default MobileTableCollapse;

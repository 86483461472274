/**
 * Dashboard Component
 * Handles the dashboard page view and functions related to loading dashboard
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';

import CanvasJSReact from '../../../assets/js/canvasjs.react';
import ArrangementDue from '../../shared/graphs/ArrangementDue';
import CashCollected from '../../shared/graphs/CashCollected';
import InfoMessage from '../../shared/messages/InfoMessage';
import {
    getArrangementDueData,
    getCashCollectedData,
    getDashboardData,
    removeDashboardDataLoading,
    organisationSelectedOrganisationDataFetchingUpdate,
} from '../../../includes/redux/actions/customer/organisation';
import OverviewCard from '../../shared/OverviewCard';
import PaymentOverview from '../../shared/graphs/PaymentOverview';
import { forceReRenderComponentSuccess } from '../../../includes/redux/actions/shared/settings';
import {
    useCurrentRoleSlug,
    useTryDemoCompanyPopup,
    useUpdateBusinessName,
    useUpdateOrganisationPaymentAccount,
    useViewingAccountType,
} from '../../../includes/utils/hooks';
import './styles.scss';
import SelectOrgPaymentAccount from '../Organisations/SelectOrganisationPaymentAccount';
import { DEBTOR_ACCOUNT_TYPE_SLUG } from '../../../includes/constants';
import { setUserAccountId } from '../../../includes/redux/actions/shared/account';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import InvoiceCurrencyBreakdown from 'components/shared/graphs/InvoiceCurrencyBreakdown';

let CanvasJS = CanvasJSReact.CanvasJS;
let CanvasJSChart = CanvasJSReact.CanvasJSChart;

CanvasJS.addColorSet('customColorSet1', [
    //colorSet Array
    '#858ff3',
    '#ff5d7c',
    '#f5c64a',
]);

/**
 * Dashboard Component
 */
const Dashboard = props => {
    const [showOrganisationSelectPaymentAccountModal, setShowOrganisationSelectPaymentAccountModal] = useState(false);
    const organisationId = props.match.params.organisationId || props.selectedOrganisationId;
    const { accountId } = props.match.params;
    const {
        isAdmin,
        forceReRenderComponent,
        organisationSelectedOrganisationDataFetchingUpdate,
        forceReRenderComponentSuccess,
        t,
    } = props;
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    // check if user has updated the business name
    useUpdateBusinessName();

    // try the demo company
    useTryDemoCompanyPopup(props.currentAccountId);

    // check if the payment account has been set up
    useUpdateOrganisationPaymentAccount(setShowOrganisationSelectPaymentAccountModal);

    /**
     * Use Effect hook to set user account id
     */
    useEffect(() => {
        if (accountId) {
            props.setUserAccountId(accountId);
        }
    }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use Effect hook
     */
    useEffect(() => {
        fetchDashboardData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use Effect hook with force re-render component hook
     */
    useEffect(() => {
        if (forceReRenderComponent) {
            fetchDashboardData();
            organisationSelectedOrganisationDataFetchingUpdate();
            forceReRenderComponentSuccess();
        }
    }, [forceReRenderComponent]); // eslint-disable-line react-hooks/exhaustive-deps

    const { getDashboardData, removeDashboardDataLoading } = props;
    /**
     * Fetch the dashboard data
     */
    const fetchDashboardData = () => {
        if (organisationId) getDashboardData(organisationId);
        else removeDashboardDataLoading();
    };

    /**
     * Get localised message
     *
     * @param {string} path
     */
    const getLocaleText = path => t(`customerMessages.dashboard.${path}`);

    const currentRoleSlug = useCurrentRoleSlug();
    const viewingAccountType = useViewingAccountType();

    const renderOverview = () => {
        if (!currentRoleSlug) return null;

        return (
            <>
                <OverviewCard
                    loading={props.isGraphDataLoading}
                    contents={[
                        {
                            value: getNumberFormattedAsCurrency(
                                get(props, ['paymentOverview', 'full_paid_invoices', 'amount_short'], 0)
                            ),
                            hoverValue: getNumberFormattedAsCurrency(
                                get(props, ['paymentOverview', 'full_paid_invoices', 'amount'], 0)
                            ),
                            title: getLocaleText('overviewCard.totalCashCollected'),
                        },
                        {
                            value: getNumberFormattedAsCurrency(
                                get(props, ['paymentOverview', 'part_paid_invoices', 'amount_short'], 0)
                            ),
                            hoverValue: getNumberFormattedAsCurrency(
                                get(props, ['paymentOverview', 'part_paid_invoices', 'amount'], 0)
                            ),
                            title: getLocaleText('overviewCard.partPaid'),
                        },
                        {
                            value: getNumberFormattedAsCurrency(
                                get(props, ['paymentOverview', 'overdue_invoices', 'amount_short'], 0)
                            ),
                            hoverValue: getNumberFormattedAsCurrency(
                                get(props, ['paymentOverview', 'overdue_invoices', 'amount'], 0)
                            ),
                            title: getLocaleText('overviewCard.overdueAmount'),
                            className: 'red-text',
                        },
                        {
                            value: get(props, ['paymentOverview', 'overdue_invoices', 'count'], 0),
                            title: getLocaleText('overviewCard.overdueAccounts'),
                        },
                        {
                            value: getNumberFormattedAsCurrency(
                                get(props, ['paymentOverview', 'debt_invoices', 'amount_short'], 0)
                            ),
                            hoverValue: get(props, ['paymentOverview', 'debt_invoices', 'amount'], 0),
                            title: getLocaleText('overviewCard.totalDebt'),
                        },
                    ]}
                    className="half-wrapper"
                />
                <OverviewCard
                    loading={props.isGraphDataLoading}
                    contents={[
                        {
                            value: get(props.invoiceStatusCount, 'paid', 0),
                            title: getLocaleText('overviewCard.paidInFull'),
                        },
                        {
                            value: get(props.invoiceStatusCount, 'disputed', 0),
                            title: getLocaleText('overviewCard.disputedAccounts'),
                            className: 'red-text',
                        },
                        {
                            value: get(props.invoiceStatusCount, 'settled', 0),
                            title: getLocaleText('overviewCard.settledCount'),
                        },
                        {
                            value: props.paymentArrangementCount,
                            title: getLocaleText('overviewCard.paymentArrangementCount'),
                        },
                        {
                            value: props.paymentExtensionCount,
                            title: getLocaleText('overviewCard.paymentExtensionCount'),
                        },
                    ]}
                    className="half-wrapper right-align"
                />
            </>
        );
    };

    /**
     * Render the dashboard
     *
     * @returns    {string}    Dashboard view
     */
    return (
        <div className="home-content-wrapper dashboard-wrapper">
            {isAdmin && (
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={`/admin/accounts/organisations/${accountId}`}>Organisations</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            <h2 className="page-title">{t('customerMessages.dashboard.title')}</h2>
            <div className="count-wrapper">{renderOverview()}</div>
            {viewingAccountType === DEBTOR_ACCOUNT_TYPE_SLUG ? (
                <PaymentOverview
                    canvasJSChart={CanvasJSChart}
                    infoMessage={InfoMessage}
                    paymentOverview={props.paymentOverview}
                    isGraphDataLoading={props.isGraphDataLoading}
                    selectedOrganisation={props.selectedOrganisation}
                    collectedText={'Collected'}
                />
            ) : (
                <div className="two-element-grid">
                    <PaymentOverview
                        canvasJSChart={CanvasJSChart}
                        infoMessage={InfoMessage}
                        paymentOverview={props.paymentOverview}
                        isGraphDataLoading={props.isGraphDataLoading}
                        selectedOrganisation={props.selectedOrganisation}
                        collectedText={'Collected'}
                        customClass="box-wrapper white-box-wrapper half-wrapper"
                    />
                    <InvoiceCurrencyBreakdown
                        canvasJSChart={CanvasJSChart}
                        infoMessage={InfoMessage}
                        invoiceCurrencyBreakdown={props.invoiceCurrencyBreakdown}
                        isGraphDataLoading={props.isGraphDataLoading}
                        selectedOrganisation={props.selectedOrganisation}
                    />
                </div>
            )}

            <div className="two-element-grid">
                <CashCollected
                    averagePaymentDays={props.averagePaymentDays}
                    canvasJSChart={CanvasJSChart}
                    cashCollectedPerDay={props.cashCollectedPerDay}
                    cashCollectedPerDayViewPortMaxMinDates={props.cashCollectedPerDayViewPortMaxMinDates}
                    cashCollectedTotal={props.cashCollectedTotal}
                    countDataAvailableMinDate={props.countDataAvailableMinDate}
                    getCashCollectedData={props.getCashCollectedData}
                    infoMessage={InfoMessage}
                    isCashCollectedDataLoading={props.isCashCollectedDataLoading}
                    isGraphDataLoading={props.isGraphDataLoading}
                    selectedOrganisation={props.selectedOrganisation}
                    selectedOrganisationId={organisationId}
                    paymentOverview={props.paymentOverview}
                />
                <ArrangementDue
                    canvasJSChart={CanvasJSChart}
                    arrangementDueTotal={props.arrangementDueTotal}
                    arrangementDuePerDay={props.arrangementDuePerDay}
                    arrangementDuePerDayViewPortMaxMinDates={props.arrangementDuePerDayViewPortMaxMinDates}
                    getArrangementDueData={props.getArrangementDueData}
                    infoMessage={InfoMessage}
                    isArrangementDueDataLoading={props.isArrangementDueDataLoading}
                    isGraphDataLoading={props.isGraphDataLoading}
                    selectedOrganisation={props.selectedOrganisation}
                    selectedOrganisationId={organisationId}
                />
            </div>

            {showOrganisationSelectPaymentAccountModal && (
                <SelectOrgPaymentAccount
                    setShowOrganisationSelectPaymentAccountModal={setShowOrganisationSelectPaymentAccountModal}
                />
            )}
        </div>
    );
};

/**
 * Proptypes
 */
Dashboard.propTypes = {
    arrangementDuePerDay: PropTypes.object,
    arrangementDuePerDayViewPortMaxMinDates: PropTypes.object,
    arrangementDueTotal: PropTypes.object,
    averagePaymentDays: PropTypes.number,
    cashCollectedPerDay: PropTypes.object,
    cashCollectedPerDayViewPortMaxMinDates: PropTypes.object,
    cashCollectedTotal: PropTypes.object,
    countDataAvailableMinDate: PropTypes.number,
    currentAccountId: PropTypes.string,
    forceReRenderComponent: PropTypes.bool,
    forceReRenderComponentSuccess: PropTypes.func,
    getArrangementDueData: PropTypes.func,
    getCashCollectedData: PropTypes.func,
    getDashboardData: PropTypes.func,
    invoiceStatusCount: PropTypes.object,
    isAdmin: PropTypes.bool,
    isArrangementDueDataLoading: PropTypes.bool,
    isCashCollectedDataLoading: PropTypes.bool,
    isGraphDataLoading: PropTypes.bool,
    match: PropTypes.object,
    organisationSelectedOrganisationDataFetchingUpdate: PropTypes.func,
    path: PropTypes.string,
    paymentArrangementCount: PropTypes.number,
    paymentExtensionCount: PropTypes.number,
    paymentOverview: PropTypes.object,
    invoiceCurrencyBreakdown: PropTypes.object,
    removeDashboardDataLoading: PropTypes.func,
    selectedOrganisation: PropTypes.object,
    selectedOrganisationId: PropTypes.string,
    setUserAccountId: PropTypes.func,
    t: PropTypes.func,
};

/**
 * Connect to store
 */
export default connect(
    state => ({
        ...state.organisation,
        currentAccountId: state.account.currentAccountId,
        forceReRenderComponent: state.settings.forceReRenderComponent,
        roles: state.user.roles,
        isAdmin: state.account.isAdmin,
    }),
    {
        forceReRenderComponentSuccess,
        getArrangementDueData,
        getCashCollectedData,
        getDashboardData,
        organisationSelectedOrganisationDataFetchingUpdate,
        removeDashboardDataLoading,
        setUserAccountId,
    }
)(withTranslation()(Dashboard));

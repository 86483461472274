/**
 * Zendesk API class.
 * Handles all api requests related to zendesk
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import base api handler
import BaseApiHandler from './base/api';

class ZendeskApi extends BaseApiHandler {
    /**
     * Get settings related to an organisation
     *
     * @param {string} organisationId organisation Id.
     *
     * @returns {object} API Response
     */
    getSettings = organisationId =>
        this.axios.get(this.getApiUrl('ZENDESK_SETTINGS', { organisation_id: organisationId }));

    /**
     * Get ticket details related to an organisation
     *
     * @param {string} organisationId organisation Id
     * @param {string} ticketId Ticket Id
     *
     * @returns {object} API Response
     */
    getTicket = (organisationId, ticketId) =>
        this.axios.get(this.getApiUrl('ZENDESK_TICKET', { organisation_id: organisationId, ticket_id: ticketId }));

    /**
     * Get zendesk ticket options
     *
     * @returns {object} API Response
     */
    getTicketOptions = () => this.axios.get(this.getApiUrl('ZENDESK_TICKET_OPTIONS'));

    /**
     * Get tickets related to an organisation
     *
     * @param {string} organisationId Organisation Id
     * @param {object} searchParams Search Params. Default {}
     *
     * @returns {object} API Response
     */
    getTickets = (organisationId, searchParams = {}) =>
        this.axios.get(this.getApiUrl('ZENDESK_TICKETS', { organisation_id: organisationId }), {
            params: searchParams,
        });

    /**
     * Save settings related to an organisation
     *
     * @param {object} settings Settings
     * @param {string} organisationId organisation Id.
     *
     * @returns {object} API Response
     */
    saveSettings = (settings, organisationId) =>
        this.axios.post(this.getApiUrl('ZENDESK_SETTINGS', { organisation_id: organisationId }), settings);
}

export default new ZendeskApi();

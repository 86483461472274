/**
 * IVR communication log fields to show
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import React from 'react';
import PropTypes from 'prop-types';

const IvrCommunicationLogHistoryFields = ({ renderField }) => {
    return (
        <section>
            {renderField({ key: 'message', onSameLine: false })}
            {renderField({ key: 'call_duration' })}
            {renderField({ key: 'called_country' })}
            {renderField({ key: 'called_state' })}
            {renderField({ key: 'called_city' })}
        </section>
    );
};

IvrCommunicationLogHistoryFields.propTypes = {
    renderField: PropTypes.func,
};

export default IvrCommunicationLogHistoryFields;

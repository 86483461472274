/**
 * Exports API class.
 * Handles all api requests related to export
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import base api handler
import BaseApiHandler from '../base/api';
import { DEFAULT, EXPORT_FILE_API_MAPPINGS, EXPORT_FILE_TYPE_MAPPINGS } from '../../constants/keys/request';
import { get } from 'lodash';

class ExportsApi extends BaseApiHandler {
    /**
     * Delete an export
     *
     * @param {string} exportId Export Id
     *
     * @returns {object} API Response
     */
    deleteExport = exportId => this.axios.delete(this.getApiUrl('EXPORTS_FILE_DOWNLOAD', { export_id: exportId }));

    /**
     * Get Export download link
     *
     * @param {string} exportId Export Id
     *
     * @returns {object} API Response
     */
    getExportDownloadLink = exportId =>
        this.axios.get(this.getApiUrl('EXPORTS_FILE_DOWNLOAD', { export_id: exportId }));

    /**
     * Get the exports
     *
     * @param {string} accountId Account Id
     *
     * @returns {object} API Response
     */
    getExports = accountId => this.axios.get(this.getApiUrl('EXPORTS_LIST', { account_id: accountId }));

    /**
     * Call the export file API
     *
     * @param {string} exportType Export Type
     * @param {string} accountId Account Id
     * @param {string} organisationId Organisation Id
     * @param {string} contactId Contact Id
     * @param {string} invoiceId Invoice Id
     * @param {object} queryParams Query Params
     *
     * @returns {object} API Response
     */
    exportFile = (exportType, accountId, organisationId, contactId, invoiceId, queryParams) => {
        return this.axios.post(
            this.getApiUrl(get(EXPORT_FILE_API_MAPPINGS, exportType, get(EXPORT_FILE_API_MAPPINGS, DEFAULT)), {
                export_type: get(EXPORT_FILE_TYPE_MAPPINGS, exportType, exportType),
                account_id: accountId,
                organisation_id: organisationId,
                contact_id: contactId,
                invoice_id: invoiceId,
            }),
            queryParams
        );
    };
}

export default new ExportsApi();

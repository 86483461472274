/**
 * useUserId
 * extracts and returns the user id from store
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { useSelector } from 'react-redux';

/**
 * Get the user Id
 *
 * @returns {string} User Id
 */
const useUserId = () => {
    const userDetails = useSelector(state => state.user.userDetails);
    return userDetails?.id;
};

export default useUserId;

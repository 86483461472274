import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deletePaymentGateway, getPaymentGateways, updatePaymentGatewayDetails } from 'includes/slices/paymentGateways';
import PaymentGateways from 'components/PaymentGateways';
import {
    ADMIN_COMMON_PAYMENT_SERVICE_FORM_FIELDS,
    ADMIN_PAYMENT_SERVICE_SPECIFIC_FIELDS,
    PAYMENT_SERVICE_COMMON_CHARGES_FIELDS,
    PAYMENT_SERVICE_SPECIFIC_CHARGES_FIELDS,
} from 'components/PaymentGateways/constants';
import { redirect } from 'includes/utils';

/**
 * App payment gateways
 *
 * Renders all payment gateways used throughout the app
 * Only for `admin` view
 *
 * @since 2.8.0
 */
export default function AppPaymentGateways() {
    const [selectedPaymentServiceForEditing, setSelectedPaymentServiceForEditing] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPaymentGateways());
    }, []);

    const organisationPaymentGateways = useSelector(state => state.paymentGateways.paymentGateways);

    const isUpdating = useSelector(state => state.paymentGateways.isUpdating);

    const isDeleting = useSelector(state => state.paymentGateways.isDeleting);

    /**
     * Handle form submit
     *
     * @param {object} values form values
     */
    const handlePaymentServiceFormSubmit = values => {
        const paymentServiceId = values.id;

        dispatch(
            updatePaymentGatewayDetails(paymentServiceId, null, null, values, () =>
                setSelectedPaymentServiceForEditing(null)
            )
        );
    };

    const handlePaymentServiceView = paymentServiceId => {
        redirect(`/admin/payment-gateway/${paymentServiceId}`);
    };

    return (
        <div className="mt-8">
            <PaymentGateways
                formFields={{
                    COMMON: ADMIN_COMMON_PAYMENT_SERVICE_FORM_FIELDS,
                    PAYMENT_SERVICE_SPECIFIC: ADMIN_PAYMENT_SERVICE_SPECIFIC_FIELDS,
                    PAYMENT_SERVICE_CHARGES: PAYMENT_SERVICE_COMMON_CHARGES_FIELDS,
                    PAYMENT_SERVICE_SPECIFIC_CHARGES: PAYMENT_SERVICE_SPECIFIC_CHARGES_FIELDS,
                }}
                handlePaymentServiceFormSubmit={handlePaymentServiceFormSubmit}
                handleView={handlePaymentServiceView}
                isDeleting={isDeleting}
                isUpdating={isUpdating}
                onDelete={paymentGatewayId => dispatch(deletePaymentGateway(paymentGatewayId))}
                paymentGateways={organisationPaymentGateways}
                selectedPaymentServiceForEditing={selectedPaymentServiceForEditing}
                setSelectedPaymentServiceForEditing={setSelectedPaymentServiceForEditing}
            />
        </div>
    );
}

AppPaymentGateways.propTypes = {};

/**
 * Send Reminder Form
 * Renders the send reminder form
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */
//import required modules
import { React, useState, useEffect, useCallback, Link } from '../../../includes/exports/react';
import { Form, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, FormSection, reduxForm } from 'redux-form';
import { get, find, isEmpty } from 'lodash';
import Button from '../Button';
import * as constants from '../../../includes/constants';
import * as formValidations from '../../../includes/utils/form';
import FormField from '../FormField';
import * as requestKeys from '../../../includes/constants/keys/request';
import EmailFields from '../../shared/Templates/Email/EmailFields';
import IvrFields from '../../shared/Templates/Ivr/IvrFields';
import SmsFields from '../../shared/Templates/Sms/SmsFields';
import Preview from '../../shared/Templates/Preview';
import { getDefaultImageClass, getImageFromData } from '../../../includes/utils';
import { DAY_ID } from '../../../includes/constants/keys/request';
import useAllowZendeskIntegration from 'includes/hooks/useAllowZendeskIntegration';
import ZendeskEventTypeFields from 'pages/Zendesk/Settings/components/Form/components/EventTypeFields';
import { CURRENCY_CODE, ZENDESK_SETTINGS } from 'includes/constants/keys/response';
import PermissionGuard from 'components/PermissionGuard';
import { REMINDER } from 'includes/constants/permissions';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import useCampaignNotificationDays from 'includes/hooks/useCampaignNotificationDays';
import useCommunicationTemplateTypes from 'includes/hooks/useCommunicationTemplateTypes';
import useCommunicationTypes from 'includes/hooks/useCommunicationTypes';
import useCampaigns from 'includes/hooks/useCampaigns';
import useCampaignNotificationDayDescription from 'includes/hooks/useCampaignNotificationDayDescription';
import WhatsAppFields from '../Templates/WhatsApp/WhatsAppFields';

/**
 * Send Reminder form Component
 */
const SendReminder = props => {
    // default states
    const [message, setMessage] = useState(get(props, 'initialValues.message', ''));
    const [openPreview, setOpenPreview] = useState(false);
    const [selectedNotificationTypeId, setSelectedNotificationTypeId] = useState('');
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [selectedTemplateTypeId, setSelectedTemplateTypeId] = useState('');
    const allowZendeskIntegration = useAllowZendeskIntegration();
    const getNumberFormattedAsCurrency = useCurrencyFormatter();
    const { data: currencies } = useOrganisationCurrencies();
    // set the initial values
    const notificationTypeId = get(props, `initialValues.${requestKeys.NOTIFICATION_TYPE_ID}`, '');
    const templateTypeId = get(props, `initialValues.${requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID}`, '');
    const templateId = get(props, `initialValues.${requestKeys.COMMUNICATION_TEMPLATE_ID}`, '');

    const { templateDetails, change, isAdmin } = props;
    const contactAvatar45Key = 'CONTACT_AVATAR_45X45';
    const contactAvatarOriginalKey = 'CONTACT_AVATAR_ORIGINAL';
    const organisationId = props.organisationId
        ? props.organisationId
        : get(props, 'reminderDetails.organisation_id', '');

    const { data: campaignNotificationDays } = useCampaignNotificationDays();
    const getCampaignNotificationDayDescription = useCampaignNotificationDayDescription();
    const { data: communicationTemplateTypes } = useCommunicationTemplateTypes();
    const { data: communicationTypes } = useCommunicationTypes();
    const { data: campaigns } = useCampaigns();

    /**
     * Change the current values on template change
     */
    const changeCurrentValues = useCallback(() => {
        change(requestKeys.NAME, get(templateDetails, 'name', ''));
        change(requestKeys.SUBJECT, get(templateDetails, 'subject', ''));
        change(requestKeys.MESSAGE, get(templateDetails, 'message', ''));
        setMessage(get(templateDetails, 'message', ''));
        change(requestKeys.TEMPLATE_TYPE, get(templateDetails, 'template_type', ''));
    }, [templateDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use effect to set the default values
     */
    useEffect(() => {
        notificationTypeId && setSelectedNotificationTypeId(notificationTypeId);
        templateTypeId && setSelectedTemplateTypeId(templateTypeId);
        templateId && setSelectedTemplateId(templateId);
    }, [notificationTypeId, templateId, templateTypeId]);

    /**
     * Use effect to change the current template values on template change
     */
    useEffect(() => {
        changeCurrentValues();
    }, [changeCurrentValues]);

    /**
     * Get the contact details row
     *
     * @param {boolean} is_invoice_contact Whether we are rendering the row for invoice contact. Default False
     */
    const getContactDetailsRow = (is_invoice_contact = false) => {
        const hasInvoiceContact = !isEmpty(get(props, 'reminderDetails.invoice_contact'));
        const contact = !is_invoice_contact
            ? get(props, 'reminderDetails.contact')
            : get(props, 'reminderDetails.invoice_contact');
        const contactUrlSlug = get(contact, 'is_manager') ? 'contact-manager' : 'contact';

        return !hasInvoiceContact && is_invoice_contact ? (
            ''
        ) : (
            <Row gutter={16} className="send-to">
                <b>{hasInvoiceContact && is_invoice_contact ? 'Contact' : 'To'}: </b>{' '}
                <img
                    className={
                        getDefaultImageClass(contact, contactAvatar45Key, contactAvatarOriginalKey, '45x45') +
                        ' logo logo-45 reminder-details-avatar'
                    }
                    src={getImageFromData(contact, contactAvatar45Key, contactAvatarOriginalKey)}
                    alt={get(contact, 'name')}
                />
                <Link
                    className="crud-link"
                    to={
                        isAdmin
                            ? `/admin/accounts/organisation/${contactUrlSlug}/${
                                  props.accountId
                              }/${organisationId}/${get(contact, 'id')}`
                            : `/${contactUrlSlug}/${get(contact, 'id')}`
                    }
                >
                    {`${get(contact, 'name', '')} `}
                </Link>
                <b>
                    {`<${
                        get(find(communicationTypes, { id: selectedNotificationTypeId }), 'slug') === 'email'
                            ? get(contact, 'email')
                            : get(contact, 'mobile_number')
                    }>`}
                </b>
            </Row>
        );
    };
    /**
     * Locale the form label
     *
     * @param {string} field Field for which locale is needed
     *
     * @returns {string} Localised content
     */
    const localeLabel = field => props.t(`customerMessages.reminders.addEdit.form.input.label.${field}`);

    /**
     * Show the template preview
     */
    const showPreview = () => {
        if (message) {
            setOpenPreview(true);
            const invoiceId = get(props, 'reminderDetails.invoice.id', '');
            const contactId = get(props, 'reminderDetails.contact.id', '');
            const paymentArrangementId = get(props, 'reminderDetails.payment_arrangement_id', '');
            props.getCommunicationTemplatePreview({
                account_id: props.accountId,
                organisation_id: organisationId,
                contact_id: contactId,
                invoice_id: invoiceId,
                payment_arrangement_id: paymentArrangementId,
                body: message,
                type: get(find(communicationTypes, { id: selectedNotificationTypeId }), 'slug', ''),
                scheduler_id: get(props, 'reminderDetails.scheduler_id', ''),
            });
        }
    };

    /**
     * Get the form fields by notification type
     */
    const getFormFieldsByNotificationType = () => {
        if (selectedNotificationTypeId && selectedTemplateTypeId && selectedTemplateId) {
            const type = get(find(communicationTypes, { id: selectedNotificationTypeId }), 'slug', '');
            switch (type) {
                case 'email':
                    return (
                        <EmailFields
                            {...props}
                            contactCC={get(props, 'initialValues.contact_cc', [])}
                            setMessage={setMessage}
                            message={message}
                            showPreview={showPreview}
                            disable
                        />
                    );
                case 'ivr':
                    return (
                        <IvrFields
                            {...props}
                            setMessage={setMessage}
                            message={message}
                            showPreview={showPreview}
                            disable
                            disableBody
                        />
                    );
                case 'sms':
                    return (
                        <SmsFields
                            {...props}
                            setMessage={setMessage}
                            message={message}
                            showPreview={showPreview}
                            disable
                        />
                    );
                case 'whatsapp':
                    return (
                        <WhatsAppFields
                            {...props}
                            setMessage={setMessage}
                            message={message}
                            showPreview={showPreview}
                            disable
                            disableBody
                        />
                    );
                default:
                    return null;
            }
        }
    };

    /**
     * Get the zendesk form fields by notification type
     */
    const getZendeskFormFieldsByNotificationType = () => {
        if (allowZendeskIntegration && selectedNotificationTypeId && selectedTemplateTypeId && selectedTemplateId) {
            const type = get(find(communicationTypes, { id: selectedNotificationTypeId }), 'slug', '');
            if (type) {
                return (
                    <>
                        <hr className="mrgn-tp-25" />
                        <FormSection name={ZENDESK_SETTINGS}>
                            <ZendeskEventTypeFields eventType={type} includeTagsField />
                        </FormSection>
                    </>
                );
            }
        }
    };

    /**
     * Render the component
     *
     * @returns {*}
     */
    return (
        <div className="edit-reminder-wrapper">
            <Form layout="vertical" className="">
                <Row gutter={16} className="send-to">
                    <b>
                        Reminder:{' '}
                        {getCampaignNotificationDayDescription(
                            find(campaignNotificationDays, { id: get(props.initialValues, DAY_ID) }),
                            get(find(campaigns, { id: get(props.initialValues, 'campaign_id') }), 'campaign_type')
                        )}
                    </b>
                </Row>
                <Row gutter={16} className="send-to">
                    <b>Campaign: {get(find(campaigns, { id: get(props.initialValues, 'campaign_id') }), 'name')}</b>
                </Row>
                {getContactDetailsRow()}
                {getContactDetailsRow(true)}
                {!get(props, 'reminderDetails.payment_arrangement_id') ? (
                    <Row gutter={16} className="send-to">
                        <b>Invoice: </b>{' '}
                        <Link
                            className="crud-link"
                            to={
                                isAdmin
                                    ? `/admin/accounts/organisation/invoices/invoice/${
                                          props.accountId
                                      }}/${organisationId}/${organisationId}}/${get(
                                          props,
                                          'reminderDetails.contact.id'
                                      )}/${get(props, 'reminderDetails.invoice.id')}`
                                    : `/invoice/${get(props, 'reminderDetails.contact.id')}/${get(
                                          props,
                                          'reminderDetails.invoice.id'
                                      )}`
                            }
                        >
                            {`${get(props, 'reminderDetails.invoice.number', '')} `}
                        </Link>{' '}
                        Amount Due:{' '}
                        <b>
                            {getNumberFormattedAsCurrency(
                                get(props, 'reminderDetails.invoice.amount_due'),
                                false,
                                false,
                                get(
                                    find(currencies, { id: get(props, 'reminderDetails.invoice.currency_id') }),
                                    CURRENCY_CODE
                                )
                            )}
                        </b>{' '}
                        Due Date: <b> {get(props, 'reminderDetails.invoice.due_date')}</b>
                    </Row>
                ) : null}
                <Row gutter={16}>
                    <Col
                        span={12}
                        onClick={() =>
                            props.showNotificationPopup(
                                props.t('customerMessages.reminders.send.notificationPopup.notificationType')
                            )
                        }
                    >
                        <Field
                            type="select"
                            className="form-control"
                            name={requestKeys.NOTIFICATION_TYPE_ID}
                            label={localeLabel(requestKeys.NOTIFICATION_TYPE)}
                            options={communicationTypes.map(x => ({ value: x.id, name: x.type }))}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            disabled
                            required
                        />
                    </Col>
                    {selectedNotificationTypeId && (
                        <Col
                            span={12}
                            onClick={() =>
                                props.showNotificationPopup(
                                    props.t('customerMessages.reminders.send.notificationPopup.templateType')
                                )
                            }
                        >
                            <Field
                                type="select"
                                className="form-control"
                                name={requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID}
                                label={localeLabel(requestKeys.TEMPLATE_TYPE)}
                                hasFeedback
                                options={communicationTemplateTypes.map(x => ({
                                    value: x.id,
                                    name: x.type,
                                }))}
                                component={FormField}
                                validate={[formValidations.required]}
                                disabled
                                required
                            />
                        </Col>
                    )}
                </Row>
                <Row gutter={16}>
                    {selectedNotificationTypeId && selectedTemplateTypeId && (
                        <Col
                            span={12}
                            onClick={() =>
                                props.showNotificationPopup(
                                    props.t('customerMessages.reminders.send.notificationPopup.template')
                                )
                            }
                        >
                            <Field
                                type="select"
                                className="form-control"
                                name={requestKeys.COMMUNICATION_TEMPLATE_ID}
                                label={localeLabel(requestKeys.COMMUNICATION_TEMPLATE)}
                                options={[
                                    {
                                        value: templateDetails?.id,
                                        name: templateDetails?.name,
                                    },
                                ]}
                                hasFeedback
                                component={FormField}
                                validate={[formValidations.required]}
                                disabled
                                required
                            />
                        </Col>
                    )}
                </Row>
                {getFormFieldsByNotificationType()}
                {getZendeskFormFieldsByNotificationType()}
                {selectedNotificationTypeId &&
                    selectedTemplateTypeId &&
                    selectedTemplateId &&
                    get(props, 'initialValues.is_active') &&
                    !props.isLoading && (
                        <Form.Item
                            className={`campaign-btn-wrapper ${props.error ? ' has-error default-form-app-error' : ''}`}
                        >
                            {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                            <PermissionGuard requiredPermission={REMINDER.SEND}>
                                <Button
                                    name="save"
                                    onClick={props.handleSubmit(values =>
                                        props.onSubmit({ ...values, action: 'send' })
                                    )}
                                    filled
                                    className="green-bg-button"
                                    loading={props.loading}
                                    submittingButtonLabel={props.t(
                                        'customerMessages.reminders.addEdit.form.submit.buttonSubmittingLabel'
                                    )}
                                >
                                    {props.t('customerMessages.reminders.addEdit.form.submit.buttonLabel')}
                                </Button>
                            </PermissionGuard>
                        </Form.Item>
                    )}
            </Form>
            <Preview {...props} openPreview={openPreview} setOpenPreview={setOpenPreview} />
        </div>
    );
};

/**
 * Proptypes
 */
SendReminder.propTypes = {
    accountId: PropTypes.string,
    allCommunicationTemplates: PropTypes.object,
    campaignDays: PropTypes.array,
    change: PropTypes.func,
    disable: PropTypes.bool,
    disableBody: PropTypes.bool,
    error: PropTypes.any,
    getCommunicationTemplateDetails: PropTypes.func,
    getCommunicationTemplatePreview: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    loading: PropTypes.bool,
    loginFailed: PropTypes.bool,
    notificationTypes: PropTypes.array,
    onSubmit: PropTypes.func,
    organisationId: PropTypes.string,
    showNotificationPopup: PropTypes.func,
    t: PropTypes.func,
    templateDetails: PropTypes.object,
    togglePopover: PropTypes.func,
    types: PropTypes.array,
    untouch: PropTypes.func,
};

// Default props
SendReminder.defaultProps = {
    accountId: '',
    campaignDays: [],
    notificationTypes: [],
};

// Redux-Form wrapper
export default reduxForm({
    form: constants.SEND_REMINDER_FORM,
})(withTranslation()(SendReminder));

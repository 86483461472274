/**
 * Edit payment arrangement installment form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com>
 */

// import required modules
import { React, PropTypes, withTranslation } from 'includes/exports/react';
import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col, Tag } from 'antd';
import { find, get } from 'lodash';
import useOrganisationCurrency from 'includes/hooks/useOrganisationCurrency';
import { CURRENCY_CODE, ID } from 'includes/constants/keys/response';
import { useSelector } from 'react-redux';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import FormField from 'components/shared/FormField';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { paymentArrangementStatusTagColors } from 'includes/constants/tagColors';
import PaymentArrangementInvoices from 'pages/PaymentArrangements/Details/components/Invoices';
/**
 * Payment arrangement pay in full form
 */
const PaymentArrangementPayInFullForm = props => {
    const { t } = useTranslation();
    const organisationCurrencyId = useOrganisationCurrency(ID);
    const organisationCurrencyCode = useOrganisationCurrency(CURRENCY_CODE);
    const paymentArrangement = useSelector(state => state.paymentArrangement.paymentArrangement);
    const loadingPaymentArrangementPayInFull = useSelector(
        state => state.paymentArrangement.loadingPaymentArrangementPayInFull
    );
    const paymentArrangementCurrencyId = get(paymentArrangement, requestKeys.CURRENCY_ID);
    const { data: organisationCurrencies } = useOrganisationCurrencies();
    const paymentArrangementCurrencyDetails = find(organisationCurrencies, { id: paymentArrangementCurrencyId }, '');
    const paymentArrangementCurrencyCode = get(paymentArrangementCurrencyDetails, CURRENCY_CODE);

    /**
     * Get value from payment arrangement installment details by key
     *
     * @param {string} key Key for which value is to be retrieved
     * @param {*} defaultValue. Default value
     *
     * @returns {string} Value
     */
    const getValue = (key, defaultValue = '') => get(paymentArrangement, key, defaultValue);

    /**
     * Locale label
     *
     * @param {string} key Key to grt localized text
     *
     * @returns {string} Localized text
     */
    const localeLabel = key => t(`messages.paymentArrangement.arrangement.payInFull.form.input.label.${key}`);

    return (
        <div className="my-profile-detail" style={{ marginTop: '20px' }}>
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <label htmlFor="name">{localeLabel('amount')}:</label>
                        <div style={{ fontSize: '40px', fontWeight: 'bold' }}>
                            {props.getNumberFormattedWithPaymentArrangementCurrency(
                                getValue('total_pending_installment_amount')
                            )}
                        </div>
                    </Col>
                    <Col span={12}>
                        <Tag
                            color={get(paymentArrangementStatusTagColors, getValue('status'))}
                            style={{
                                width: '60%',
                                fontWeight: 'bold',
                                textAlign: 'right',
                                float: 'right',
                            }}
                        >
                            {get(
                                find(constants.PAYMENT_ARRANGEMENT_STATUS_OPTIONS, {
                                    id: getValue('status'),
                                }),
                                'label'
                            )}
                        </Tag>
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginBottom: '20px' }}>
                    <Col span={24}>
                        <PaymentArrangementInvoices
                            getNumberFormattedWithPaymentArrangementCurrency={
                                props.getNumberFormattedWithPaymentArrangementCurrency
                            }
                            invoices={getValue('invoices', [])}
                            planType={props.planType}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="date"
                            name={requestKeys.PAYMENT_ARRANGEMENT_FULL_PAYMENT_RECEIVED_DATE}
                            label={localeLabel(requestKeys.PAYMENT_ARRANGEMENT_FULL_PAYMENT_RECEIVED_DATE)}
                            hasFeedback
                            reduxChange={props.change}
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required, formValidations.date]}
                            required
                        />
                    </Col>
                </Row>
                {paymentArrangementCurrencyId !== organisationCurrencyId ? (
                    <Row gutter={16}>
                        <Col>
                            <Field
                                type="text"
                                name={requestKeys.EXCHANGE_RATE}
                                label={localeLabel(requestKeys.EXCHANGE_RATE)}
                                hasFeedback
                                className="form-control"
                                component={FormField}
                                validate={[formValidations.required, formValidations.number]}
                                required
                                addonBefore={`${organisationCurrencyCode} 1  = ${paymentArrangementCurrencyCode} `}
                                info={get(paymentArrangementCurrencyDetails, 'exchange_rates.last_updated_date', '')}
                            />
                        </Col>
                    </Row>
                ) : null}
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="text"
                            name={requestKeys.EXTERNAL_ID}
                            label={localeLabel(requestKeys.EXTERNAL_ID)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="text"
                            name={requestKeys.REFERENCE_NUMBER}
                            label={localeLabel(requestKeys.REFERENCE_NUMBER)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="textarea"
                            name={requestKeys.REFERENCE}
                            label={localeLabel(requestKeys.REFERENCE)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Form.Item className={`update-button no-margin center-align `}>
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={loadingPaymentArrangementPayInFull}
                        submittingButtonLabel={t(
                            `messages.paymentArrangement.arrangement.payInFull.form.submit.buttonSubmittingLabel.edit`
                        )}
                        className="register-btn"
                    >
                        {props.t(`messages.paymentArrangement.arrangement.payInFull.form.submit.buttonLabel.edit`)}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

/**
 * Proptypes.
 */
PaymentArrangementPayInFullForm.propTypes = {
    change: PropTypes.func,
    exchangeRateText: PropTypes.string,
    getNumberFormattedWithPaymentArrangementCurrency: PropTypes.func,
    handleSubmit: PropTypes.func,
    planType: PropTypes.string,
    t: PropTypes.func,
};

export default reduxForm({
    form: constants.PAYMENT_ARRANGEMENT_PAY_IN_FULL_FORM_NAME,
})(withTranslation()(PaymentArrangementPayInFullForm));

import i18n from 'includes/i18n';

/**
 * Campaigns columns
 *
 * @since 2.0.0
 */
export const campaignsColumns = [
    {
        title: i18n.t('messages.campaigns.list.label.name'),
        dataIndex: 'name',
        key: 'name',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('messages.campaigns.list.label.campaign_type'),
        dataIndex: 'campaign_type',
        key: 'campaign_type',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('messages.campaigns.list.label.contact_type_ids'),
        dataIndex: 'contact_type_ids',
        key: 'contact_type_ids',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.campaigns.list.label.is_default'),
        dataIndex: 'is_default',
        key: 'is_default',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.campaigns.list.label.send_multiple_reminders'),
        dataIndex: 'send_multiple_reminders',
        key: 'send_multiple_reminders',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.campaigns.list.label.actions'),
        dataIndex: 'actions',
        key: 'actions',
        collapseHeader: true,
    },
];

/**
 * Dashboard Component
 * Handles the dashboard page view and functions related to loading dashboard for debt collector
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import required modules
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as errorMessages from 'includes/constants/messages/errors';
import SearchBlock from '../../shared/SearchBlock';
import Table from '../../shared/ScrollableTable';
import { DEBT_COLLECTOR_INVOICES_TABLE } from 'includes/constants/columns';
import { getInvoices } from 'includes/slices/invoice';
import './styles.scss';

/**
 * Dashboard component
 */
const Dashboard = () => {
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const invoices = useSelector(state => state.invoices.invoices);
    const isLoading = useSelector(state => state.invoices.isLoading);
    const paginationInvoices = useSelector(state => state.invoices.paginationInvoices);

    /**
     * Format invoices data
     */
    const formatInvoicesData = () => {
        return invoices.map(invoice => {
            return {
                ...invoice,
                amount_due: <span title={invoice.amount_due.amount}>{invoice.amount_due.amount_short}</span>,
                amount_paid: <span title={invoice.amount_paid.amount}>{invoice.amount_paid.amount_short}</span>,
                business_name: (
                    <>
                        <div className="business-name-avatar-wrapper">
                            <img
                                className="logo logo-45"
                                src={require('../../../assets/images/invoice_avatar.svg')}
                                alt="invoice-avatar"
                            />
                            <div>
                                <Link
                                    to={`/debt-collector/invoice/${invoice.organisation.id}/${invoice.contact_id.id}/${invoice.id}`}
                                >
                                    {invoice.contact_id.business_name}
                                </Link>
                                <span className="crud-wrapper">
                                    <Link
                                        className="crud-link"
                                        to={`/debt-collector/invoice/${invoice.organisation.id}/${invoice.contact_id.id}/${invoice.id}`}
                                    >
                                        View
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </>
                ),
            };
        });
    };

    /**
     * Get memoized invoice data
     */
    const memoizedInvoicesData = useMemo(() => formatInvoicesData(), [invoices]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="details-wrapper">
            <section>
                <div className="home-content-wrapper synced-name contact-details details-wrapper">
                    <h2 className="page-title">Invoices</h2>
                    <div className="full-wrapper box-wrapper white-box-wrapper breadcrumb-spec">
                        <>
                            <ul className="selector-field show-elem">
                                <li>
                                    <div className="search-block-debt-wrapper">
                                        <SearchBlock
                                            dataValues={[-1]}
                                            getDataMethod={(organisationId, searchParams) =>
                                                dispatch(getInvoices(organisationId, searchParams))
                                            }
                                            isSearching={isSearching}
                                            placeholder={t('customerMessages.invoice.search.placeholder')}
                                            setIsSearching={setIsSearching}
                                            setSearchParam={setSearchParam}
                                        />
                                    </div>
                                </li>
                            </ul>
                            <Table
                                columns={DEBT_COLLECTOR_INVOICES_TABLE}
                                dataSource={memoizedInvoicesData}
                                dataValues={[-1]}
                                getDataMethod={(organisationId, searchParams) =>
                                    dispatch(getInvoices(organisationId, searchParams))
                                }
                                isSearching={isSearching}
                                loading={isLoading}
                                localeMessage={errorMessages.TABLE_NO_INVOICES_FOUND_MESSAGE}
                                rowClassName="pointer"
                                rowKey="id"
                                searchParam={searchParam}
                                setIsSearching={setIsSearching}
                                paginationData={paginationInvoices}
                                size="middle"
                            />
                        </>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Dashboard;

/**
 * Audit log details component
 *
 * @version 2.2.0
 */

import React from 'react';
import './styles.scss';
import { Col, List, Row, Tag } from 'antd';
import { get, isArray, isEmpty } from 'lodash';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { auditLogStatusTagColors } from 'includes/constants/tagColors';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import { AUDIT_LOG } from 'includes/constants/permissions';

const AuditLogDetails = props => {
    const { t } = useTranslation();

    /**
     * Get localized text
     *
     * @param {string} path
     *
     * @returns {string} Localized string
     */
    const getLocaleText = path => t(`messages.auditLogs.details.fieldName.${path}`);

    /**
     * Get the value from the audit log details
     *
     * @param {string} key Key for which we need to retrieve the value
     *
     * @returns {string} key Key for which we need to retrieve the value
     */
    const getValueFromAuditLogDetails = key => {
        return get(props.auditLogDetails, key);
    };

    /**
     * Render the field
     *
     * @param {string} key Key for which the field is to be shown
     * @param {boolean} onSameLine Whether we need to show content in same line or different lines. Default true
     * @param {boolean} showIfEmpty Whether we need to show the field even if content is empty. Default false
     * @param {boolean} isList Whether the content should be rendered as list. Default false
     * @param {string} customLabelKey Custom label key. Default ''
     *
     * @returns Content
     */
    const renderField = ({ key, onSameLine = true, showIfEmpty = false, isList = false, customLabelKey = '' }) => {
        const value = getValueFromAuditLogDetails(key);
        const showField = value ? (isArray(value) && isEmpty(get(value, 0)) ? false : true) : !!showIfEmpty;
        return (
            showField && (
                <Row gutter={16} className="audit-log-history-field">
                    <Col>
                        <label htmlFor="name">{getLocaleText(`${customLabelKey ? customLabelKey : key}`)}: </label>
                        {isList ? (
                            renderList(value)
                        ) : onSameLine ? (
                            <b>{value}</b>
                        ) : (
                            <div className="ant-row ant-form-item">
                                <div className="ant-col ant-form-item-control-wrapper">
                                    <div className="ant-form-item-control">
                                        <div className="ant-form-item-children">{value}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Col>
                </Row>
            )
        );
    };

    /**
     * Render content as list
     *
     * @param {Array} values Value that needs to be shown as list
     *
     * @returns content
     */
    const renderList = values => {
        let listData = [];
        if (values) {
            listData = values
                .filter(value => value)
                .map(value => {
                    return {
                        value: value,
                    };
                });
        }
        return !isEmpty(listData) ? (
            <List
                className="basic-list"
                size="small"
                bordered
                dataSource={listData}
                renderItem={item => <List.Item>{item.value}</List.Item>}
                style={{ marginTop: '15px' }}
            />
        ) : null;
    };

    return (
        <PermissionGuardLayout requiredPermission={AUDIT_LOG.LIST} layout="section">
            <div className="box-wrapper mid-wrapper audit-log-details">
                <h3 style={{ margin: '16px 0', fontWeight: 'bold', fontSize: '18px' }}>{getLocaleText('details')}</h3>
                <div className="edit-reminder-wrapper">
                    <section>
                        {renderField({ key: 'account.name', customLabelKey: 'account' })}
                        {renderField({ key: 'performed_by.name', customLabelKey: 'performed_by' })}
                        {renderField({ key: 'browser' })}
                        {renderField({ key: 'ip_address' })}
                        <Row gutter={16} className="audit-log-history-field">
                            <Col>
                                <label htmlFor="name">{getLocaleText(`status`)}: </label>
                                <b>
                                    <Tag
                                        color={get(
                                            auditLogStatusTagColors,
                                            getValueFromAuditLogDetails('success_status') ? 1 : 2,
                                            0
                                        )}
                                    >
                                        {getValueFromAuditLogDetails('success_status')
                                            ? getLocaleText('success')
                                            : getLocaleText('failed')}
                                    </Tag>
                                </b>
                            </Col>
                        </Row>
                        {renderField({ key: 'performed_at', customLabelKey: 'activity_time' })}
                        {renderField({ key: 'activity_info' })}
                        {renderField({ key: 'resources', isList: true, customLabelKey: 'updates' })}
                    </section>
                </div>
            </div>
        </PermissionGuardLayout>
    );
};

AuditLogDetails.defaultProps = {
    auditLogDetails: {},
};

AuditLogDetails.propTypes = {
    auditLogDetails: PropTypes.object,
};

export default AuditLogDetails;

import { Elements, useStripe } from '@stripe/react-stripe-js';
import { Collapse, Modal } from 'antd';
import { find, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/Loader';
import { PAYMENT_GATEWAY } from 'includes/constants';
import { ORDER_GET_DETAILS } from 'includes/constants/mappings/success';
import useInternalPaymentGateways from 'includes/hooks/useInternalPaymentGateways';
import useInternalStripeDetails from 'includes/hooks/useInternalStripeDetails';
import useStripePromise from 'includes/hooks/useStripePromise';
import { confirmOrder } from 'includes/slices/orders';
import { getPaymentMethods } from 'includes/slices/paymentGateways';
import { redirect } from 'includes/utils';
import { showApiSuccess } from 'includes/utils/api';
import OtherPaymentMethods from './components/OtherPaymentMethods';
import SavedPaymentMethods from './components/SavedPaymentMethods';

const { Panel } = Collapse;

const customPanelStyle = {
    background: '#f7f7f7',
    borderRadius: 15,
    marginBottom: 18,
    border: 0,
    overflow: 'hidden',
};

const COLLAPSE_KEY = {
    SAVED_PAYMENT_METHODS: 'saved-payment-methods',
    OTHER_PAYMENT_OPTIONS: 'other-payment-options',
};

/**
 * Payment gateway component
 *
 * Component to render payment gateway.
 * Renders already saved card and allows using an unsaved card as well
 *
 * @since 2.8.0
 */
function PaymentGatewayUI({
    amount,
    createOrder,
    // handleSubmit,
    isLoadingPayButton,
    onShowSuccessMessage,
    orderTypeId,
    saveCardForLaterUse,
    // saveCardSection,
    selectedMethod,
    setSelectedMethod,
    // selectedMethodType,
    setErrorMessage,
    setIsLoadingPayButton,
    setSelectedPaymentGateway,
    selectedPaymentGatewayId,
}) {
    const [collapseActiveKey, setCollapseActiveKey] = useState(null);

    const { stripeDetails } = useInternalStripeDetails();

    const dispatch = useDispatch();

    const stripe = useStripe();

    const { data: _internalPaymentGateways } = useInternalPaymentGateways();

    const internalPaymentGateways = useMemo(() => {
        return _internalPaymentGateways.filter(pg => !pg.reauthorize);
    }, [_internalPaymentGateways]);

    const paymentMethods = useSelector(state => state.paymentGateways.paymentMethods);

    const isLoadingPaymentMethods = useSelector(state => state.paymentGateways.isLoadingPaymentMethods);

    useEffect(() => {
        dispatch(getPaymentMethods(null, { payments_allowed: true }));
    }, []);

    /**
     * set `collapseActiveKey` based on `paymentMethods`
     * if there are payment methods, then set it as `COLLAPSE_KEY.SAVED_PAYMENT_METHODS`
     * otherwise keep the default value (`COLLAPSE_KEY.OTHER_PAYMENT_OPTIONS`)
     */
    useEffect(() => {
        if (paymentMethods.length > 0) {
            setCollapseActiveKey(COLLAPSE_KEY.SAVED_PAYMENT_METHODS);
        } else {
            setCollapseActiveKey(COLLAPSE_KEY.OTHER_PAYMENT_OPTIONS);
        }
    }, [paymentMethods]);

    /**
     * When `collapseActiveKey` value changes, and is `COLLAPSE_KEY.SAVED_PAYMENT_METHODS` select either default payment method
     * if it exists or the first one
     * if `collapseActiveKey` is set to `COLLAPSE_KEY.OTHER_PAYMENT_METHODS` set the first internal payment gateway as selected payment gateway
     */
    useEffect(() => {
        if (!collapseActiveKey) return;

        if (collapseActiveKey === COLLAPSE_KEY.SAVED_PAYMENT_METHODS) {
            const defaultCard = find(paymentMethods, { is_default: true }) ?? paymentMethods[0];
            setSelectedMethod(defaultCard);
        } else if (internalPaymentGateways.length > 0) {
            setSelectedPaymentGateway(internalPaymentGateways[0]?.id);
        }
    }, [collapseActiveKey, paymentMethods, internalPaymentGateways]);

    /**
     * Check if stripeDetails is loaded and if yes, fetch payment methods
     */
    useEffect(() => {
        if (!stripeDetails) return;

        // dispatch(getPaymentMethods(stripeDetails.id));
        setSelectedPaymentGateway(stripeDetails.id);
    }, [stripeDetails]);

    /**
     * Handle error received from stripe
     *
     * @param {string} errorMessage Error string
     * @param {boolean} error Boolean indicating if the error has to be shown in a popup
     */
    const handleError = (errorMessage = '', showInPopup = false) => {
        setIsLoadingPayButton(false);
        if (showInPopup) Modal.error({ content: errorMessage });
        else setErrorMessage(errorMessage);
    };

    /**
     * Handle payment through Stripe using saved method
     *
     * @param {object} additionalParams additional params to be passed along in API request body
     */
    const handlePaymentThroughStripe = async additionalParams => {
        if (!stripe) return;

        setIsLoadingPayButton(true);

        const { response, error: createOrderError } = await createOrder(
            handlePaymentThroughStripe,
            selectedMethod,
            additionalParams
        );

        if (createOrderError) {
            return;
        }

        const {
            next_action: clientSecret,
            id: orderId,
            error_message: createOrderErrorMessage,
            payment_id: paymentId,
        } = get(response, ORDER_GET_DETAILS, {});

        // if an error occurred during create order, set error and exit
        if (createOrderErrorMessage) {
            handleError(createOrderErrorMessage, true);
            redirect(`/orders/order/${orderTypeId}/${orderId}`);
            return;
        }

        // Confirm the PaymentIntent using the details collected by the Payment Element
        const { error, paymentIntent } = await stripe.confirmPayment({
            clientSecret,
            redirect: 'if_required',
            confirmParams: {
                return_url: `${window.location.origin}/orders/order/${orderTypeId}/${orderId}`,
            },
        });

        // assuming confirmPayment call succeeded, set the `token` as `paymentIntent.id`
        let token = paymentIntent?.id;

        if (error) {
            // This point is only reached if there's an immediate error when
            // confirming the payment. Show the error to your customer and set `token` as `paymentId` from order details received from the create order API call
            handleError(error.message, true);
            token = paymentId;
            redirect(`/orders/order/${orderTypeId}/${orderId}`);
        }
        // Customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        dispatch(
            confirmOrder(
                orderTypeId,
                orderId,
                { id: orderId, payment_gateway_id: selectedPaymentGatewayId, token },
                () => redirect(`/orders/order/${orderTypeId}/${orderId}`),
                Boolean(error),
                onShowSuccessMessage
            )
        );
    };

    /**
     * Handle payment through GoCardless using saved method
     *
     * @param {object} additionalParams additional params to be passed along in API request body
     */
    const handlePaymentThroughGoCardless = async additionalParams => {
        setIsLoadingPayButton(true);
        const { response, error: createOrderError } = await createOrder(
            handlePaymentThroughGoCardless,
            selectedMethod,
            additionalParams
        );

        if (createOrderError) {
            return;
        }

        const { id: orderId, error_message: createOrderErrorMessage, payment_error: paymentErrorMessage } = get(
            response,
            ORDER_GET_DETAILS,
            {}
        );

        if (createOrderErrorMessage || paymentErrorMessage) {
            handleError(paymentErrorMessage ? paymentErrorMessage : createOrderErrorMessage);
            redirect(`/orders/order/${orderTypeId}/${orderId}`);
            return;
        }

        redirect(`/orders/order/${orderTypeId}/${orderId}`);
        setIsLoadingPayButton(false);
        if (onShowSuccessMessage) {
            onShowSuccessMessage();
        } else dispatch(showApiSuccess(response));
    };

    /**
     * Handle pay button click
     *
     * Finds the payment gateway based on `selectedPaymentGatewayId` and calls appropriate method to handle payment using that particular gateway.
     */
    const handlePayButtonClick = () => {
        const paymentGateway = find(internalPaymentGateways, { id: selectedPaymentGatewayId });

        if (!paymentGateway) return;

        switch (paymentGateway.slug) {
            case PAYMENT_GATEWAY.STRIPE: {
                handlePaymentThroughStripe();
                return;
            }
            case PAYMENT_GATEWAY.GOCARDLESS: {
                handlePaymentThroughGoCardless();
                return;
            }
        }
    };

    /**
     * Handle collapse panel change
     *
     * @param {*} key the collapse panel's key
     */
    const handleCollapsePanelChange = key => setCollapseActiveKey(key);

    return (
        <div>
            <Collapse accordion bordered={false} activeKey={collapseActiveKey} onChange={handleCollapsePanelChange}>
                {paymentMethods.length > 0 ? (
                    <Panel
                        header="Saved payment methods"
                        key={COLLAPSE_KEY.SAVED_PAYMENT_METHODS}
                        style={customPanelStyle}
                    >
                        {isLoadingPaymentMethods ? (
                            <Loader />
                        ) : (
                            <SavedPaymentMethods
                                handlePayButtonClick={handlePayButtonClick}
                                isLoadingPayButton={isLoadingPayButton}
                                onSelect={method => setSelectedMethod(method)}
                                selectedMethod={selectedMethod}
                            />
                        )}
                    </Panel>
                ) : null}
                <Panel header="Other payment options" key={COLLAPSE_KEY.OTHER_PAYMENT_OPTIONS} style={customPanelStyle}>
                    <OtherPaymentMethods
                        amount={amount}
                        createOrder={createOrder}
                        handleError={handleError}
                        handlePaymentThroughSavedMethod={handlePayButtonClick}
                        isLoadingPayButton={isLoadingPayButton}
                        onShowSuccessMessage={onShowSuccessMessage}
                        onSubmit={handlePayButtonClick}
                        orderTypeId={orderTypeId}
                        saveCardForLaterUse={saveCardForLaterUse}
                        setIsLoadingPayButton={setIsLoadingPayButton}
                        selectedPaymentGateway={selectedPaymentGatewayId}
                        setSelectedMethod={setSelectedMethod}
                        setSelectedPaymentGateway={setSelectedPaymentGateway}
                    />
                </Panel>
            </Collapse>
        </div>
    );
}

PaymentGatewayUI.propTypes = {
    amount: PropTypes.number,
    createOrder: PropTypes.func,
    handleSubmit: PropTypes.func,
    isLoadingPayButton: PropTypes.bool,
    onShowSuccessMessage: PropTypes.func,
    orderTypeId: PropTypes.string,
    saveCardForLaterUse: PropTypes.bool,
    saveCardSection: PropTypes.node,
    selectedCard: PropTypes.string,
    selectedMethod: PropTypes.string,
    selectedMethodType: PropTypes.string,
    selectedPaymentGatewayId: PropTypes.string,
    setErrorMessage: PropTypes.func,
    setIsLoadingPayButton: PropTypes.func,
    setSelectedMethod: PropTypes.func,
    setSelectedPaymentGateway: PropTypes.func,
    updateValueInRedux: PropTypes.func,
};

export default function PaymentGateway(props) {
    const { stripePromise } = useStripePromise();
    return (
        <Elements stripe={stripePromise}>
            <PaymentGatewayUI {...props} />
        </Elements>
    );
}

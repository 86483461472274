/**
 * Add/Edit Contact Form (for manual accounting software)
 * Renders the add/edit invoice form
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

// import required modules
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Divider, Icon, Dropdown, Menu, Modal, notification } from 'antd';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { find, get, trim } from 'lodash';
import { useHistory } from 'react-router-dom';

import PreviewModal from 'pages/Invoices/PreviewModal';
import Skeleton from 'components/Skeleton';
import ItemsList from './components/ItemsList';
import Button from 'components/shared/Button';
import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import FormField from 'components/shared/FormField';
import * as requestKeys from 'includes/constants/keys/request';
import { TAX_STATUS_OPTIONS } from 'includes/constants';
import { useAutoComplete } from 'includes/utils/hooks';
import ContactAPI from 'includes/services/customer/contact';
import { GET_CONTACTS } from 'includes/constants/mappings/success';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useTaxes from 'includes/hooks/useTaxes';
import { getItemTotalSplitUp, getTotalAmount, numberParse } from 'pages/Invoices/utils';
import useChartOfAccounts from 'includes/hooks/useChartOfAccounts';
import './styles.scss';
import OnDemandCampaignModal from 'components/OnDemandCampaign';
import useIsAdmin from 'includes/hooks/useIsAdmin';
import useAccountId from 'includes/hooks/useAccountId';
import ReactHtmlParser from 'react-html-parser';
import moment, { isMoment } from 'moment';
// import { sendOnDemandCampaign } from 'includes/redux/actions/customer/reminder';
import useDateToMoment from 'includes/hooks/useDateToMoment';
import { isString } from 'lodash';
import PermissionGuard from 'components/PermissionGuard';
import { INVOICE, RECURRING_INVOICE } from 'includes/constants/permissions';
import useCurrencySymbol from 'includes/hooks/useCurrencySymbol';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';

const { info: infoModal, error, warning } = Modal;
const { close: closeNotification, warning: warningNotification } = notification;
const valueMin1 = formValidations.minValue(1);
const CAMPAIGN_MODAL_MODES = {
    SEND: 'send',
    COLLECT_DETAILS: 'collect_details',
};

const ManualInvoiceForm = reduxForm({
    form: constants.INVOICE_FORM_NAME,
})(props => {
    const { t } = useTranslation();

    /**
     * Generate the label from language file
     *
     * @param   {string}    field   Field for which locale is to be generated
     * @returns {string}    Generated Locale label
     */
    const localeLabel = field => t(`customerMessages.editInvoice.form.input.label.${field}`);

    /**
     * Generate the info locale
     *
     * @param {string} field  Field for which locale is to be generated
     *
     * @returns {string} Generated Locale info
     */
    const localeInfo = field => t(`messages.invoices.addEdit.form.input.info.${field}`);

    // read initial values for the form passed on from the parent component
    const {
        initialValues: { dropDownInitalValues = {}, send_settings: sendSettings },
        isAdd,
        submitAction,
        handleSubmit,
        isRecurring,
        invoiceStatusSlug,
        showOnDemandCampaignModal,
        setShowOnDemandCampaignModal,
    } = props;
    const { data: currencies } = useOrganisationCurrencies();
    const isDraftInvoice = invoiceStatusSlug === constants.INVOICE_STATUS_DRAFT_SLUG;
    const isVoidedInvoice = invoiceStatusSlug === constants.INVOICE_STATUS_VOIDED_SLUG;
    const isWrittenOffInvoice = invoiceStatusSlug === constants.INVOICE_STATUS_WRITE_OFF_SLUG;
    const [currentEditingKey, setCurrentEditingKey] = useState(null);
    const [shouldRenderChildContact, setShouldRenderChildContact] = useState(false);
    const [isUpperContentLoading, setIsUpperContentLoading] = useState(true);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [hasDateChanged, setHasDateChanged] = useState(false);
    const [invoiceCurrencyCode, setInvoiceCurrencyCode] = useState(
        get(find(currencies, { id: get(props.initialValues, requestKeys.CURRENCY_ID) }), requestKeys.CURRENCY_CODE, '')
    );

    // campaignModalMode should be one of the value from CAMPAIGN_MODAL_MODES at all times
    const [campaignModalMode, setCampaignModalMode] = useState(CAMPAIGN_MODAL_MODES.SEND);

    const formValues = useSelector(state => state.form[constants.INVOICE_FORM_NAME].values);
    const isInvoiceUpdating = useSelector(state => state.invoices.isInvoiceUpdating);
    const isInvoiceAddLoading = useSelector(state => state.invoices.isInvoiceAddLoading);
    const accountId = useAccountId();
    const organisationId = useOrganisationId();
    const isAdmin = useIsAdmin();

    const getCurrencySymbol = useCurrencySymbol();
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    const history = useHistory();
    const dateToMoment = useDateToMoment();

    const {
        line_amount_type,
        line_items: items = [],
        contact_id: selectedContactId,
        [requestKeys.INVOICE_REPEATING_PERIOD_TYPE]: repeatingPeriodType,
        [requestKeys.INVOICE_REPEATING_DUE_DATE_DAY_TYPE]: repeatingDueDateType,
        id: invoiceId,
        child_invoice_state: childInvoiceState,
        issued_date: invoiceIssuedDate,
        next_invoice_date: nextInvoiceDate,
        due_date: invoiceDueDate,
    } = formValues;

    // effect to catch changes on invoice issue date or next invoice date
    useEffect(() => {
        if (hasDateChanged) {
            if (isRecurring) {
                const fieldName = isAdd ? requestKeys.INVOICE_ISSUED_DATE : requestKeys.INVOICE_NEXT_INVOICE_DATE;
                const fieldValue = isAdd ? invoiceIssuedDate : nextInvoiceDate;
                renderInfoIfInvoiceDateBeforeToday(fieldValue, fieldName);
            } else {
                renderInfoIfInvoiceDueDateBeforeIssuedDate();
            }
        }
    }, [invoiceIssuedDate, invoiceDueDate, nextInvoiceDate, hasDateChanged]);

    // effect to clear repeating_period when repeating_period_type is set to 'end of the month'
    useEffect(() => {
        if (repeatingPeriodType === 5) {
            props.change(requestKeys.INVOICE_REPEATING_PERIOD, null);
        }
    }, [repeatingPeriodType]); // eslint-disable-line react-hooks/exhaustive-deps

    // effect to clear due date day when repeating_due_date_type is set to 'same day of the invoice issued date'
    useEffect(() => {
        if (repeatingDueDateType === 4) {
            props.change(requestKeys.INVOICE_REPEATING_DUE_DATE_DAY, null);
        }
    }, [repeatingDueDateType]); // eslint-disable-line react-hooks/exhaustive-deps

    // fetch contacts for contacts dropdown
    const [contactQuery, contactOptions, _contactsLoading] = useAutoComplete(
        searchParam =>
            ContactAPI.getContacts(
                {
                    organisation_id: organisationId,
                    searchParams: { search_param: searchParam, is_all: true, get_archived: true },
                },
                organisationId
            ),
        {
            selector: res => get(res, GET_CONTACTS, []),
            disableInitialTrigger: !isAdd,
            initialOptions: dropDownInitalValues.contact,
        }
    );

    const cleanedContactOptions = useMemo(
        () =>
            contactOptions.map(x => ({
                name: trim(`${x.first_name} ${x.last_name}`) || x.business_name,
                value: x.id,
                type: x.is_manager ? 'Contact Manager' : 'Contact',
            })),
        [contactOptions]
    );

    /**
     * Get invoice currency symbol
     *
     * @returns {string} Currency Symbol
     */
    const getInvoiceCurrencySymbol = () => getCurrencySymbol(false, invoiceCurrencyCode);

    // fetch contacts for invoice contact dropdown
    const [childContactQuery, childContactOptions, _childContactsLoading] = useAutoComplete(
        searchParam =>
            ContactAPI.getContacts({
                organisation_id: organisationId,
                searchParams: { parent_contact_id: selectedContactId, search_param: searchParam },
            }),
        {
            selector: res => get(res, GET_CONTACTS, []),
            disableInitialTrigger: !isAdd,
            initialOptions: dropDownInitalValues.invoice_contact,
        },
        [selectedContactId]
    );

    const cleanedChildContactOptions = useMemo(
        () =>
            childContactOptions.map(x => ({
                name: trim(`${x.first_name} ${x.last_name}`) || x.business_name,
                value: x.id,
            })),
        [childContactOptions]
    );

    /**
     * Format number with invoice selected currency
     *
     * @param {string|number} number  Number
     *
     * @returns {string} Formatted Number
     */
    const getNumberFormattedWithCurrency = number =>
        getNumberFormattedAsCurrency(number, false, false, invoiceCurrencyCode);

    /**
     * set api loading state(isUpperContentLoading) done when all apis are done loading
     * this is used to show the skeleton loader on the upper section of the form
     */
    useEffect(() => {
        const allApisDone = [_contactsLoading, _childContactsLoading].every(x => !x);
        if (isUpperContentLoading && allApisDone) setIsUpperContentLoading(false);
    }, [_contactsLoading, _childContactsLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    const { data: taxes } = useTaxes();
    const { data: chartOfAccounts } = useChartOfAccounts(false, 1);

    useEffect(() => {
        if (selectedContactId) {
            const isManager = get(find(contactOptions, { id: selectedContactId }), 'is_manager', false);
            if (isManager) {
                setShouldRenderChildContact(true);
                childContactQuery();
                return;
            }
        }
        setShouldRenderChildContact(false);
    }, [selectedContactId, contactOptions]); //eslint-disable-line react-hooks/exhaustive-deps

    const itemTotals = useMemo(
        () =>
            items.map((_, index) => {
                const item = items[index];
                return getItemTotalSplitUp(item, line_amount_type, taxes);
            }),
        [items, line_amount_type, taxes]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const combinedTotal = useMemo(() =>
        itemTotals.reduce(
            (acc, curr) => ({
                total: acc.total + curr.subTotal,
                totalDiscount: acc.totalDiscount + curr.discount,
                totalTax: acc.totalTax + curr.tax,
            }),
            {
                total: 0,
                totalDiscount: 0,
                totalTax: 0,
            }
        )
    );

    /**
     * Check if invoice due date is before issued date
     */
    const renderInfoIfInvoiceDueDateBeforeIssuedDate = () => {
        if (isString(invoiceIssuedDate) && isString(invoiceDueDate)) {
            return;
        }
        const invoiceIssuedDateMoment = dateToMoment(invoiceIssuedDate);
        const invoiceDueDateMoment = dateToMoment(invoiceDueDate);
        if (invoiceIssuedDateMoment && invoiceDueDateMoment) {
            if (invoiceDueDateMoment.isBefore(invoiceIssuedDateMoment)) {
                warningNotification({
                    key: 'dueDateWarning',
                    description: '',
                    message: t('messages.invoices.modal.confirm.invoiceDueDate.message'),
                    duration: 0,
                });
            } else {
                closeNotification('dueDateWarning');
            }
        }
    };

    /**
     * Check if invoice date is before today
     *
     * @param {moment} fieldValue Invoice date
     * @param {string} fieldName Field Name
     */
    const renderInfoIfInvoiceDateBeforeToday = (fieldValue, fieldName) => {
        if (isMoment(fieldValue)) {
            if (fieldValue.isBefore(moment())) {
                infoModal({
                    title: '',
                    content: ReactHtmlParser(
                        t('messages.invoices.modal.confirm.invoiceDate.message', {
                            fieldLabel: localeLabel(fieldName),
                        })
                    ),
                });
            }
        }
    };

    /**
     * Renders the total tax section based on `line_amount_type`
     *
     * @returns {Node}
     */
    const renderTotalTax = () => {
        const tax = getNumberFormattedWithCurrency(combinedTotal.totalTax);
        switch (line_amount_type) {
            case 1:
                return (
                    <div className="total-card-row">
                        <span>Total Tax</span>
                        <span>{tax}</span>
                    </div>
                );
            case 2:
                return (
                    <div className="total-card-row">
                        <span>Includes Tax</span>
                        <span>{tax}</span>
                    </div>
                );
            default:
                return null;
        }
    };

    const onPreview = () => setIsPreviewOpen(true);

    const renderRepeatingFieldSection = () => {
        if (!isRecurring) return null;

        return isUpperContentLoading ? (
            <Skeleton title={false} active paragraph={{ rows: 3 }} />
        ) : (
            <>
                <div className="upper-section left-align-text repeating-section">
                    <div className="item-block">
                        {isAdd ? (
                            <Field
                                type="date"
                                name={requestKeys.INVOICE_ISSUED_DATE}
                                label={localeLabel(requestKeys.INVOICE_ISSUED_DATE)}
                                hasFeedback
                                className="form-control"
                                component={FormField}
                                validate={[formValidations.required, formValidations.date]}
                                reduxChange={(fieldName, fieldValue) => {
                                    props.change(fieldName, fieldValue);
                                    setHasDateChanged(true);
                                }}
                                required
                            />
                        ) : (
                            <Field
                                type="date"
                                name={requestKeys.INVOICE_NEXT_INVOICE_DATE}
                                label={localeLabel(requestKeys.INVOICE_NEXT_INVOICE_DATE)}
                                hasFeedback
                                className="form-control"
                                component={FormField}
                                validate={[formValidations.required, formValidations.date]}
                                reduxChange={(fieldName, fieldValue) => {
                                    props.change(fieldName, fieldValue);
                                    setHasDateChanged(true);
                                }}
                                required
                            />
                        )}
                    </div>
                    <div className="item-block">
                        <Field
                            className="form-control with-pre-tab repeating-invoice-item-has-add-on-block"
                            name={requestKeys.INVOICE_REPEATING_PERIOD}
                            label="Repeat transaction every"
                            disabled={repeatingPeriodType === 5}
                            min={1}
                            type="number"
                            parse={numberParse}
                            validate={
                                repeatingPeriodType !== 5
                                    ? [formValidations.required, formValidations.number, valueMin1]
                                    : []
                            }
                            addonAfter={
                                <Field
                                    name={requestKeys.INVOICE_REPEATING_PERIOD_TYPE}
                                    type="select"
                                    component={FormField}
                                    options={constants.REPEAT_FREQUENCY_UNIT_OPTIONS.map(o => ({
                                        name: o.label,
                                        value: o.id,
                                    }))}
                                    disableEmptyOption
                                    validate={[formValidations.required]}
                                />
                            }
                            component={FormField}
                            required
                        />
                    </div>
                    <div className="item-block">
                        <Field
                            className="form-control with-pre-tab repeating-invoice-item-has-add-on-block"
                            name={requestKeys.INVOICE_REPEATING_DUE_DATE_DAY}
                            label="Due date"
                            min={1}
                            type="number"
                            parse={numberParse}
                            disabled={repeatingDueDateType === 4}
                            validate={
                                repeatingDueDateType !== 4
                                    ? [formValidations.required, formValidations.number, valueMin1]
                                    : []
                            }
                            addonAfter={
                                <Field
                                    name={requestKeys.INVOICE_REPEATING_DUE_DATE_DAY_TYPE}
                                    type="select"
                                    component={FormField}
                                    options={constants.REPEATING_DUE_DATE_TYPE.map(o => ({
                                        name: o.label,
                                        value: o.id,
                                    }))}
                                    disableEmptyOption
                                    validate={[formValidations.required]}
                                />
                            }
                            component={FormField}
                            required
                        />
                    </div>
                    <div className="item-block">
                        <Field
                            type="date"
                            name={requestKeys.INVOICE_REPEATING_END_DATE}
                            label={localeLabel(requestKeys.INVOICE_REPEATING_END_DATE)}
                            hasFeedback
                            reduxChange={props.change}
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.date]}
                        />
                    </div>
                    <div className="item-block">
                        <Field
                            type="select"
                            name={requestKeys.INVOICE_REPEATING_CHILD_STATE}
                            options={constants.REPEATING_CHILD_STATE_OPTIONS}
                            disableEmptyOption
                            label="Invoice Child State"
                            hasFeedback
                            reduxChange={props.change}
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </div>
                    <div className="item-block">
                        <Field
                            type="text"
                            name={requestKeys.INVOICE_REPEATING_CHILD_INVOICE_NUMBER_PREFIX}
                            disableEmptyOption
                            label="Child invoice number prefix"
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required]}
                            info={localeInfo(requestKeys.INVOICE_REPEATING_CHILD_INVOICE_NUMBER_PREFIX)}
                            infoAsToolTip
                            required
                        />
                    </div>
                </div>
                <hr />
            </>
        );
    };

    const communicationTypes = useSelector(state => state.communication.communicationTypes);

    const contactDetails = useMemo(() => find(contactOptions, { id: selectedContactId }), [
        selectedContactId,
        contactOptions,
    ]);

    const contactCC = get(contactDetails, 'contact_cc', []);

    const contactName = get(contactDetails, 'name', 'N/A');

    /**
     * Returns the nstring to display in the to section of the CampaignModal
     *
     * @param {string} notificationType
     * @returns {string}
     */
    const getToText = notificationType => {
        if (!contactDetails) return 'N/A';

        const notificationTypeSlug = get(find(communicationTypes, { id: notificationType }), 'slug');
        if (!notificationTypeSlug) return contactDetails.name;

        const contactInfo = {
            email: contactDetails.email,
            ivr: contactDetails.mobile_number,
            sms: contactDetails.mobile_number,
        }[notificationTypeSlug];

        return `${contactDetails.name} ${contactInfo ? `<${contactInfo}>` : ''}`;
    };

    // Renders the no item error popup
    const renderMinItemErrorPopup = () => {
        error({
            title: 'Error',
            content: 'Please add at least one line item.',
        });
    };

    const campaignModalSubmitHandler = settings => {
        handleSubmit(data =>
            submitAction({ ...data, send_settings: { ...settings, organisation_id: organisationId } })
        )();
    };

    /**
     * Returns onCLick handler that opens campaign modal in `collect_details` mode
     *
     * @returns {Function}
     */
    const getCampaignModalInCollectDetailsModeHandler = () =>
        handleSubmit(() => {
            if (items.length === 0) {
                renderMinItemErrorPopup();
                return; // exit if there are no line items
            }
            setCampaignModalMode(CAMPAIGN_MODAL_MODES.COLLECT_DETAILS);
            setShowOnDemandCampaignModal(true);
        });

    /**
     * Handles click event of save button when isRecurring is `true`
     *
     * @returns {void}
     */
    const handleInvoiceSave = (draft = false, showCampaignModal = false) => {
        if (currentEditingKey !== null) {
            warning({
                title: 'Please save line item before continuing',
            });
            return;
        }
        if ((isRecurring && childInvoiceState === 3) || showCampaignModal) {
            getCampaignModalInCollectDetailsModeHandler()();
            return;
        }
        handleSubmit(data => {
            if (items.length === 0) {
                renderMinItemErrorPopup();
                return; // exit if there are no line items
            }
            submitAction({ ...data, send_settings: null, draft });
        })();
    };

    const getSaveButtonMenus = () => (
        <Menu>
            <Menu.Item onClick={() => handleInvoiceSave()}>Save</Menu.Item>
            {isAdd || invoiceStatusSlug === 'draft' ? (
                <Menu.Item onClick={() => handleInvoiceSave(true)}>Save as draft</Menu.Item>
            ) : null}
            {isRecurring ? null : <Menu.Item onClick={() => handleInvoiceSave(false, true)}>Save and send</Menu.Item>}
        </Menu>
    );

    // renders the save button in the form
    const renderSaveButton = () => {
        return (
            <Dropdown overlay={getSaveButtonMenus()}>
                <Button filled loading={isInvoiceUpdating || isInvoiceAddLoading}>
                    Save
                    <Icon type="down" />
                </Button>
            </Dropdown>
        );
    };

    // handle cancel button click
    const onCancel = () => {
        history.push(isAdmin ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}` : '/invoices');
    };

    return (
        <div className="my-profile-detail add-manual-invoice-wrapper">
            <PreviewModal
                isOpen={isPreviewOpen}
                onClose={() => setIsPreviewOpen(false)}
                invoiceDetails={{ ...formValues, is_repeating_invoice: isRecurring }}
            />
            <OnDemandCampaignModal
                contactCC={contactCC}
                contactIds={[selectedContactId]}
                contactNames={[contactName]}
                customSubmitHandler={
                    campaignModalMode === CAMPAIGN_MODAL_MODES.COLLECT_DETAILS ? campaignModalSubmitHandler : undefined
                }
                fillDefaultTemplateDetails
                getFilterParams={() => ({})} // intentionally left empty
                getToText={getToText}
                initialValues={sendSettings}
                invoiceIds={[invoiceId]}
                setInvoiceIds={() => {}} // intentionally left empty
                setShowOnDemandCampaignModal={setShowOnDemandCampaignModal}
                showOnDemandCampaignModal={showOnDemandCampaignModal}
                totalCount={1}
                sendButtonText={
                    campaignModalMode === CAMPAIGN_MODAL_MODES.COLLECT_DETAILS
                        ? isRecurring
                            ? t('messages.invoices.modal.sendInvoice.button.save')
                            : t('messages.invoices.modal.sendInvoice.button.saveAndSend')
                        : t('messages.invoices.modal.sendInvoice.button.send')
                }
            />
            <Form layout="vertical" className="edit-form">
                {isUpperContentLoading ? (
                    <Skeleton title={false} active paragraph={{ rows: 3 }} />
                ) : (
                    <>
                        <div className="upper-section left-align-text">
                            <div className="item-block">
                                <Field
                                    type="autocomplete"
                                    name={requestKeys.CONTACT_ID}
                                    label={localeLabel(requestKeys.CONTACT_ID)}
                                    groupBy="type"
                                    hasFeedback
                                    options={cleanedContactOptions}
                                    onSearch={value => {
                                        contactQuery(value);
                                    }}
                                    onChange={() => {
                                        props.untouch(requestKeys.INVOICE_CONTACT_ID);
                                        props.change(requestKeys.INVOICE_CONTACT_ID, null);
                                    }}
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    required
                                />
                            </div>
                            {shouldRenderChildContact ? (
                                <div className="item-block">
                                    <Field
                                        type="autocomplete"
                                        name={requestKeys.INVOICE_CONTACT_ID}
                                        label={localeLabel(requestKeys.INVOICE_CONTACT_ID)}
                                        hasFeedback
                                        options={cleanedChildContactOptions}
                                        onSearch={value => childContactQuery(value)}
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        required
                                    />
                                </div>
                            ) : null}
                            <div className="item-block">
                                <Field
                                    type="text"
                                    name={requestKeys.INVOICE_NUMBER}
                                    label={localeLabel(requestKeys.INVOICE_NUMBER)}
                                    hasFeedback
                                    className="form-control"
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    required
                                />
                            </div>
                            {isRecurring ? null : (
                                <>
                                    <div className="item-block">
                                        <Field
                                            type="date"
                                            name={requestKeys.INVOICE_ISSUED_DATE}
                                            label={localeLabel(requestKeys.INVOICE_ISSUED_DATE)}
                                            hasFeedback
                                            reduxChange={(fieldName, fieldValue) => {
                                                props.change(fieldName, fieldValue);
                                                setHasDateChanged(true);
                                            }}
                                            className="form-control"
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.date]}
                                            required
                                        />
                                    </div>
                                    <div className="item-block">
                                        <Field
                                            type="date"
                                            name={requestKeys.INVOICE_DUE_DATE}
                                            label={localeLabel(requestKeys.INVOICE_DUE_DATE)}
                                            hasFeedback
                                            reduxChange={(fieldName, fieldValue) => {
                                                props.change(fieldName, fieldValue);
                                                setHasDateChanged(true);
                                            }}
                                            className="form-control"
                                            component={FormField}
                                            validate={[formValidations.required, formValidations.date]}
                                            required
                                        />
                                    </div>
                                </>
                            )}

                            <div className="item-block">
                                <Field
                                    type="text"
                                    name={requestKeys.REFERENCE}
                                    label={localeLabel(requestKeys.REFERENCE)}
                                    className="form-control"
                                    component={FormField}
                                />
                            </div>
                            <div className="item-block">
                                <Field
                                    type="text"
                                    name={requestKeys.INVOICE_PURCHASE_ORDER}
                                    label={localeLabel(requestKeys.INVOICE_PURCHASE_ORDER)}
                                    className="form-control"
                                    component={FormField}
                                />
                            </div>
                            <div className="item-block">
                                <Field
                                    type="text"
                                    name={requestKeys.PAYMENT_TERMS}
                                    label={localeLabel(requestKeys.PAYMENT_TERMS)}
                                    hasFeedback
                                    className="form-control"
                                    component={FormField}
                                    info={localeInfo(requestKeys.PAYMENT_TERMS)}
                                    infoAsToolTip
                                />
                            </div>

                            <div className="item-block">
                                <Field
                                    type="select"
                                    className="form-control"
                                    name={requestKeys.LINE_AMOUNT_TYPE}
                                    label={localeLabel(requestKeys.LINE_AMOUNT_TYPE)}
                                    options={TAX_STATUS_OPTIONS.map(x => ({ value: x.id, name: x.label }))}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    required
                                    disableEmptyOption
                                />
                            </div>
                            <div className="item-block">
                                <Field
                                    type="select"
                                    className="form-control"
                                    name={requestKeys.CURRENCY_ID}
                                    label={localeLabel(requestKeys.CURRENCY_ID)}
                                    options={currencies.map(x => ({ value: x.id, name: x.name }))}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    required
                                    disableEmptyOption
                                    onChange={value =>
                                        setInvoiceCurrencyCode(
                                            get(find(currencies, { id: value }), requestKeys.CURRENCY_CODE, '')
                                        )
                                    }
                                />
                            </div>
                            <div className="item-block">
                                <Field
                                    type="select"
                                    className="form-control"
                                    name={requestKeys.PAYMENT_ACCOUNT_ID}
                                    label={localeLabel(requestKeys.PAYMENT_ACCOUNT_ID)}
                                    options={chartOfAccounts.map(x => ({ value: x.id, name: `[${x.code}] ${x.name}` }))}
                                    component={FormField}
                                    info={localeInfo(requestKeys.PAYMENT_ACCOUNT_ID)}
                                    infoAsToolTip
                                />
                            </div>
                            <div className="item-block">
                                <Field
                                    type="text"
                                    className="form-control"
                                    name={requestKeys.EXTERNAL_ID}
                                    label={localeLabel(requestKeys.EXTERNAL_ID)}
                                    component={FormField}
                                    info={localeInfo(requestKeys.EXTERNAL_ID)}
                                    infoAsToolTip
                                />
                            </div>
                            {isRecurring ? (
                                <>
                                    <div className="item-block item-block-no-content">&nbsp;</div>
                                    <div className="item-block item-block-no-content">&nbsp;</div>
                                </>
                            ) : null}
                            {!shouldRenderChildContact ? (
                                <div className="item-block item-block-no-content">&nbsp;</div>
                            ) : null}
                        </div>
                    </>
                )}
                <hr />

                {renderRepeatingFieldSection()}

                {isUpperContentLoading ? (
                    <Skeleton title={false} active paragraph={{ rows: 3 }} />
                ) : (
                    <FieldArray
                        name="line_items"
                        component={ItemsList}
                        itemTotals={itemTotals}
                        currentEditingKey={currentEditingKey}
                        setCurrentEditingKey={setCurrentEditingKey}
                        isRecurring={isRecurring}
                        touch={props.touch}
                        currencySymbol={getInvoiceCurrencySymbol()}
                        getNumberFormattedWithCurrency={getNumberFormattedWithCurrency}
                    />
                )}
                <hr />
                <div className="invoice-summary">
                    <div className="left-align-text">
                        {isUpperContentLoading ? (
                            <Skeleton title={false} active paragraph={{ rows: 3 }} />
                        ) : (
                            <Field
                                type="textarea"
                                className="form-control"
                                name={requestKeys.PAYMENT_INSTRUCTIONS}
                                label={localeLabel(requestKeys.PAYMENT_INSTRUCTIONS)}
                                hasFeedback
                                component={FormField}
                                info={localeInfo(requestKeys.PAYMENT_INSTRUCTIONS)}
                                infoAsToolTip
                            />
                        )}
                    </div>
                    <div className="total-card">
                        {isUpperContentLoading ? (
                            <Skeleton title={false} active paragraph={{ rows: 3 }} />
                        ) : (
                            <>
                                <div className="total-card-row">
                                    <span>{t('customerMessages.editInvoice.form.message.subTotal')}</span>
                                    <span>{getNumberFormattedWithCurrency(combinedTotal.total)}</span>
                                </div>
                                {combinedTotal.totalDiscount > 0 ? (
                                    <div className="total-card-row">
                                        <span>{t('customerMessages.editInvoice.form.message.discount')}</span>
                                        <span>{getNumberFormattedWithCurrency(combinedTotal.totalDiscount)}</span>
                                    </div>
                                ) : null}
                                {renderTotalTax()}
                                <Divider />
                                <div className="total-card-row">
                                    <span>{t('customerMessages.editInvoice.form.message.total')}</span>
                                    <span>
                                        {getNumberFormattedWithCurrency(
                                            getTotalAmount(
                                                combinedTotal.total,
                                                combinedTotal.totalDiscount,
                                                combinedTotal.totalTax,
                                                line_amount_type
                                            )
                                        )}
                                    </span>
                                </div>
                                <Divider />
                            </>
                        )}
                    </div>
                </div>
                <div className="action-wrapper">
                    {/* <Field
                        type="checkbox"
                        name={requestKeys.IS_RECURRING}
                        label="Make Recurring"
                        className="form-control"
                        component={FormField}
                    /> */}
                    {isUpperContentLoading ? null : (
                        <>
                            <div className="left-section">
                                <PermissionGuard
                                    requiredPermission={isRecurring ? RECURRING_INVOICE.PREVIEW : INVOICE.PREVIEW}
                                >
                                    <Button className="blue-bg-button" filled onClick={onPreview}>
                                        <Icon type="eye" />
                                        Preview
                                    </Button>
                                </PermissionGuard>
                                {isRecurring ||
                                isAdd ||
                                isDraftInvoice ||
                                isVoidedInvoice ||
                                isWrittenOffInvoice ? null : (
                                    <PermissionGuard requiredPermission={INVOICE.SEND}>
                                        <Button
                                            className="blue-bg-button"
                                            big
                                            filled
                                            onClick={() => setShowOnDemandCampaignModal(true)}
                                        >
                                            <Icon type="mail" />
                                            Send
                                        </Button>
                                    </PermissionGuard>
                                )}
                            </div>
                            <div className="right-section">
                                <Button className="cancel-button" style={{ flexBasis: '150px' }} onClick={onCancel}>
                                    Cancel
                                </Button>
                                {isVoidedInvoice || isWrittenOffInvoice ? null : renderSaveButton()}
                            </div>
                        </>
                    )}
                </div>
            </Form>
        </div>
    );
});

ManualInvoiceForm.propTypes = {
    change: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    submitAction: PropTypes.func.isRequired,
    touch: PropTypes.func,
    untouch: PropTypes.func,
};

export default ManualInvoiceForm;

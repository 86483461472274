/**
 * Note Statuses hooks
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { CACHE_NOTE_STATUSES_KEY } from 'includes/constants';
import { get } from 'lodash';
import { GET_NOTE_STATUSES } from 'includes/constants/mappings/success';
import NotesApi from 'includes/services/notes';
import useCachedData from './useCachedData';

/**
 * Fetches note statuses.
 * If cached get the data from cache else call the API and caches the result
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useNoteStatuses = () => {
    return useCachedData(CACHE_NOTE_STATUSES_KEY, () => NotesApi.getNoteStatuses(), {
        selector: res => get(res, GET_NOTE_STATUSES),
    });
};

export default useNoteStatuses;

/**
 * Skeleton component
 * Renders skeleton while API is being loaded
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import React from 'react';
import { Skeleton as AntSkeleton } from 'antd';

import './styles.scss';

const Skeleton = ({ className, ...restProps }) => (
    <AntSkeleton {...restProps} className={`placeholder-skeleton ${className}`} />
);

Skeleton.propTypes = { ...AntSkeleton.propTypes };

export default Skeleton;

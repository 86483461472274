/**
 * Accounts breadcrumbs
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AccountsBreadCrumbItems = props => {
    const { t } = useTranslation();

    /**
     * Render the component
     */
    return props.showListBreadCrumb ? (
        <Breadcrumb.Item>
            <Link to="/admin/accounts">{t('messages.breadCrumbs.accounts.list')}</Link>
        </Breadcrumb.Item>
    ) : null;
};

AccountsBreadCrumbItems.propTypes = {
    action: PropTypes.string,
    showAddEditBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumbAsLink: PropTypes.bool,
    showListBreadCrumb: PropTypes.bool,
};

export default AccountsBreadCrumbItems;

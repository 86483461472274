import PropTypes from 'prop-types';
import { React, connect, Link } from '../../../includes/exports/react';
import { Breadcrumb } from 'antd';
import { withTranslation } from 'react-i18next';
import InvoicePaymentsBlock from './payments';
import './styles.scss';
import { useUpdateBusinessName } from '../../../includes/utils/hooks';

const InvoicePayments = props => {
    const { accountId } = props.match.params;

    const getLocaleLabel = link => props.t(`customerMessages.paymentsHistory.${link}`);

    // check if user has updated the business name
    useUpdateBusinessName();

    return (
        <div className="home-content-wrapper payment-history-wrapper">
            {props.isAdmin && (
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={`/admin/accounts/organisations/${accountId}`}>
                                {getLocaleLabel('breadcrumb.organisations')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{getLocaleLabel('breadcrumb.payments')}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            <h2 className="page-title">{getLocaleLabel('title')}</h2>
            <div className="box-wrapper white-box-wrapper full-wrapper">
                <InvoicePaymentsBlock />
            </div>
        </div>
    );
};

InvoicePayments.propTypes = {
    isAdmin: PropTypes.bool,
    match: PropTypes.object,
    t: PropTypes.func,
};

export default connect(
    state => ({
        isAdmin: state.account.isAdmin,
    }),
    null
)(withTranslation()(InvoicePayments));

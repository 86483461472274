/**
 * Notes Add/Edit Form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React from 'react';
import { date } from 'includes/utils/form';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';
import { get, isEmpty } from 'lodash';
import { NOTE_FORM_NAME } from 'includes/constants';
import { PropTypes } from 'prop-types';
import { required } from 'includes/utils/form';
import { useSelector } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import * as requestKeys from 'includes/constants/keys/request';
import Button from 'components/Button';
import FormField from 'components/shared/FormField';
import useAccountUsers from 'includes/hooks/useAccountUsers';
import useNoteStatuses from 'includes/hooks/useNoteStatuses';
import useNoteTypes from 'includes/hooks/useNoteTypes';

const AddEditNoteFrom = props => {
    const { data: noteStatuses } = useNoteStatuses();
    const { data: noteTypes } = useNoteTypes();
    const { data: accountUsers } = useAccountUsers();

    const { t } = useTranslation();

    const loadingNoteCrud = useSelector(state => state.notes.loadingNoteCrud);

    /**
     * Locale the label.
     *
     * @param {string} field Field for which locale is to be generated.
     *
     * @returns {string} Generated Locale label.
     */
    const localeLabel = field => t(`messages.notes.form.input.label.${field}`);

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.NOTE_TITLE}
                            label={localeLabel(requestKeys.NOTE_TITLE)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[required]}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="select"
                            className="form-control no-single-selection-margin "
                            name={requestKeys.NOTE_USER_ID}
                            label={localeLabel(requestKeys.NOTE_USER_ID)}
                            hasFeedback
                            options={accountUsers.map(x => ({ value: x.id, name: x.full_name }))}
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="date"
                            name={requestKeys.NOTE_DATE}
                            label={localeLabel(requestKeys.NOTE_DATE)}
                            className="form-control"
                            dropdownClassName={`invoice-paid-on-datepicker`}
                            component={FormField}
                            reduxChange={props.change}
                            validate={[required, date]}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="date"
                            name={requestKeys.NOTE_FOLLOW_UP_DATE}
                            label={localeLabel(requestKeys.NOTE_FOLLOW_UP_DATE)}
                            className="form-control"
                            dropdownClassName={`invoice-paid-on-datepicker`}
                            component={FormField}
                            reduxChange={props.change}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="select"
                            className="form-control no-single-selection-margin "
                            name={requestKeys.NOTE_TYPE_ID}
                            label={localeLabel(requestKeys.NOTE_TYPE_ID)}
                            hasFeedback
                            options={
                                !isEmpty(noteTypes)
                                    ? Object.keys(noteTypes).map(key => ({
                                          value: parseInt(key),
                                          name: noteTypes[parseInt(key)],
                                      }))
                                    : []
                            }
                            component={FormField}
                            validate={[required]}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="select"
                            className="form-control no-single-selection-margin "
                            name={requestKeys.NOTE_STATUS_ID}
                            label={localeLabel(requestKeys.NOTE_STATUS_ID)}
                            hasFeedback
                            options={
                                !isEmpty(noteStatuses)
                                    ? Object.keys(noteStatuses).map(key => ({
                                          value: parseInt(key),
                                          name: noteStatuses[parseInt(key)],
                                      }))
                                    : []
                            }
                            component={FormField}
                            validate={[required]}
                            required
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="textarea"
                            name={requestKeys.NOTE_DESCRIPTION}
                            label={localeLabel(requestKeys.NOTE_DESCRIPTION)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[required]}
                            required
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="textarea"
                            name={requestKeys.NOTE_ACTION}
                            label={localeLabel(requestKeys.NOTE_ACTION)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="switch"
                            name={requestKeys.NOTE_STARRED}
                            label={localeLabel(requestKeys.NOTE_STARRED)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Form.Item
                    className={`update-button no-margin center-align ${
                        props.error ? ' has-error default-form-app-error' : ''
                    }`}
                >
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={loadingNoteCrud}
                        submittingButtonLabel={localeLabel(
                            `submit.button.${get(props, ['initialValues', 'id']) ? 'updating' : 'adding'}`
                        )}
                        className="register-btn"
                    >
                        {localeLabel(`submit.button.${get(props, ['initialValues', 'id']) ? 'update' : 'add'}`)}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

/**
 * Proptypes.
 */
AddEditNoteFrom.propTypes = {
    change: PropTypes.func,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    t: PropTypes.func,
};

export default reduxForm({
    form: NOTE_FORM_NAME,
})(withTranslation()(AddEditNoteFrom));

/**
 * Invoices breadcrumb items
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import AccountsBreadCrumbItems from '../Accounts';
import OrganisationsBreadCrumbItems from '../Organisations';
import PropTypes from 'prop-types';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useIsDebtor from 'includes/hooks/useIsDebtor';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useInvoiceLinkGenerator from 'includes/hooks/useInvoiceLinkGenerator';

const InvoicesBreadCrumbItems = ({
    action,
    showListBreadCrumb,
    showDetailsBreadCrumb,
    showDetailsBreadCrumbAsLink,
    showAddEditBreadCrumb,
    isRecurring,
}) => {
    const accountId = useAccountId();
    const isAdmin = useIsAdminOrManager();
    const isDebtor = useIsDebtor();
    const organisationId = useOrganisationId();
    const { t } = useTranslation();

    const invoiceDetails = useSelector(state => state.invoices.invoiceDetails);
    const getInvoiceDetailsLink = useInvoiceLinkGenerator();
    const isAdd = action === 'add';

    /**
     * Get the invoices list link
     *
     * @returns {string} List link
     */
    const getListLink = () =>
        isAdmin
            ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}`
            : isDebtor
            ? '/debtor/invoices'
            : `/invoices`;

    return (
        <Breadcrumb>
            {isAdmin ? (
                <>
                    <AccountsBreadCrumbItems showListBreadCrumb />
                    <OrganisationsBreadCrumbItems showListBreadCrumb />
                </>
            ) : null}
            {showListBreadCrumb ? (
                <Breadcrumb.Item>
                    <Link to={getListLink()}>{t('messages.breadCrumbs.invoices.list')}</Link>
                </Breadcrumb.Item>
            ) : null}
            {showDetailsBreadCrumb ? (
                <Breadcrumb.Item>
                    {showDetailsBreadCrumbAsLink ? (
                        <Link to={getInvoiceDetailsLink(invoiceDetails?.contact_id?.id, invoiceDetails?.id)}>
                            {get(invoiceDetails, 'invoice_number', t('messages.breadCrumbs.invoices.details'))}
                        </Link>
                    ) : (
                        get(invoiceDetails, 'invoice_number', t('messages.breadCrumbs.invoices.details'))
                    )}
                </Breadcrumb.Item>
            ) : null}
            {showAddEditBreadCrumb ? (
                <Breadcrumb.Item>
                    {t(`messages.breadCrumbs.invoices.${isAdd ? (isRecurring ? 'addRepeating' : 'add') : 'edit'}`)}
                </Breadcrumb.Item>
            ) : null}
        </Breadcrumb>
    );
};

InvoicesBreadCrumbItems.propTypes = {
    action: PropTypes.string,
    showAddEditBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumbAsLink: PropTypes.bool,
    showListBreadCrumb: PropTypes.bool,
    isRecurring: PropTypes.bool,
};

export default InvoicesBreadCrumbItems;

/**
 * Send Reminder Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import { React, connect, Link, PropTypes, useEffect, withTranslation } from '../../../includes/exports/react';
import Loader from '../../shared/Loader';
import SendReminderForm from '../../shared/forms/SendReminderForm';
import { Breadcrumb } from 'antd';
import { find, get } from 'lodash';
import {
    getCommunicationTemplateDetails,
    getCommunicationTemplatePreview,
} from '../../../includes/redux/actions/shared/communication';
import { sendReminder } from '../../../includes/redux/actions/customer/reminder';
import { showNotificationPopup } from '../../../includes/redux/actions/shared/common';
import { useCheckEditPermission, usePlaceholderDataFromStore } from '../../../includes/utils/hooks';
import { getReminderDetails } from '../../../includes/redux/actions/customer/reminder';
import { ORGANISATION_ID, ZENDESK_EVENT_TYPE_FIELDS, ZENDESK_TAGS } from '../../../includes/constants/keys/request';
import useZendeskSettings from 'includes/hooks/useZendeskSettings';
import useAllowZendeskIntegration from 'includes/hooks/useAllowZendeskIntegration';
import { ZENDESK_SETTINGS } from 'includes/constants/keys/response';
import useCampaignNotificationDays from 'includes/hooks/useCampaignNotificationDays';
import useCommunicationTypes from 'includes/hooks/useCommunicationTypes';
import useCommunicationTemplateTypes from 'includes/hooks/useCommunicationTemplateTypes';
import useCampaigns from 'includes/hooks/useCampaigns';

/**
 * Send Reminder component
 */
const SendReminder = props => {
    const { reminderId, accountId } = props.match.params;
    const organisationId = props.organisationId || props.match.params.organisationId;
    const { getReminderDetails, isAdmin } = props;
    const { data: zendeskSettings, isLoading: loadingSettings } = useZendeskSettings();
    const allowZendeskIntegration = useAllowZendeskIntegration();

    // check if user has permission
    useCheckEditPermission();

    // get the placeholders
    usePlaceholderDataFromStore(organisationId);

    const { isLoading: isCampaignDaysLoading } = useCampaignNotificationDays();
    const { isLoading: isCommunicationTemplateTypesLoading } = useCommunicationTemplateTypes();
    const { isLoading: isCommunicationTypesLoading } = useCommunicationTypes();
    const { isLoading: isCampaignsLoading } = useCampaigns();

    // use effect to fetch reminder details
    useEffect(() => {
        getReminderDetails(organisationId, reminderId);
    }, [reminderId]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the localised text
     *
     * @param {string} path
     */
    const getLocaleText = path => props.t(`customerMessages.reminders.addEdit.${path}`);

    /**
     * Get zendesk settings initial values
     */
    const getZendeskInitialValues = () => {
        const zendeskInitialValues = {};
        if (allowZendeskIntegration && zendeskSettings) {
            if (zendeskSettings.connected) {
                zendeskInitialValues[ZENDESK_SETTINGS] = {};
                const notificationType = get(
                    find(props.types, { id: get(props.reminderDetails, 'notification_type_id') }),
                    'slug',
                    ''
                );
                if (notificationType) {
                    // eslint-disable-next-line array-callback-return
                    ZENDESK_EVENT_TYPE_FIELDS.map(eventTypeField => {
                        const fieldName = notificationType + '_' + eventTypeField;
                        zendeskInitialValues[ZENDESK_SETTINGS][eventTypeField] = get(zendeskSettings, fieldName);
                    });
                    zendeskInitialValues[ZENDESK_SETTINGS][ZENDESK_TAGS] = get(zendeskSettings, ZENDESK_TAGS);
                }
            }
        }
        return zendeskInitialValues;
    };

    /**
     * Render the breadcrumb
     */
    const renderBreadcrumb = () => (
        <Breadcrumb>
            {isAdmin ? (
                <>
                    <Breadcrumb.Item>
                        <Link to={`/admin/accounts/organisations/${accountId}`}>
                            {getLocaleText('breadcrumb.organisations')}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link
                            to={get(
                                props,
                                'history.location.state.from',
                                `/admin/accounts/organisation/reminders/${accountId}/${organisationId}`
                            )}
                        >
                            {getLocaleText('breadcrumb.reminders')}
                        </Link>
                    </Breadcrumb.Item>
                </>
            ) : (
                <Breadcrumb.Item>
                    <Link to={get(props, 'history.location.state.from', '/reminders')}>
                        {getLocaleText('breadcrumb.reminders')}
                    </Link>
                </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>{getLocaleText('breadcrumb.editReminders')}</Breadcrumb.Item>
        </Breadcrumb>
    );

    /**
     * Send reminder to the contact
     *
     * @param {object} values Form Values
     */
    const sendReminder = values => {
        values[ORGANISATION_ID] = organisationId;
        if (!allowZendeskIntegration || (zendeskSettings && !zendeskSettings.connected)) {
            values[ZENDESK_SETTINGS] = null;
        }
        props.sendReminder(
            values,
            { organisation_id: organisationId },
            get(props, 'history.location.state.from', '/reminders')
        );
    };
    const contactCCData = props?.reminderDetails?.contact_cc || [];

    const initialValues = {
        ...props.reminderDetails,
        cc: contactCCData.flatMap(c => (c.email && c.include_in_emails === true ? c.email : '')).filter(e => e),
        ...getZendeskInitialValues(),
    };

    return (
        <div className="home-content-wrapper edit-campaign-wrapper">
            <div className="tab-arrow-link">{renderBreadcrumb()}</div>
            <h2 className="page-title">{getLocaleText('title')}</h2>
            <div className="white-box-wrapper box-wrapper mid-wrapper">
                {props.isReminderLoading ||
                loadingSettings ||
                isCampaignDaysLoading ||
                isCommunicationTemplateTypesLoading ||
                isCommunicationTypesLoading ||
                isCampaignsLoading ? (
                    <Loader />
                ) : (
                    <SendReminderForm
                        onSubmit={sendReminder}
                        loading={props.isSendingReminder}
                        initialValues={initialValues}
                        accountId={accountId}
                        {...props}
                    />
                )}
            </div>
        </div>
    );
};

// prop types
SendReminder.propTypes = {
    getReminderDetails: PropTypes.func,
    history: PropTypes.object,
    isAdmin: PropTypes.bool,
    isLoading: PropTypes.bool,
    isReminderLoading: PropTypes.bool,
    isSendingReminder: PropTypes.bool,
    match: PropTypes.object,
    organisationId: PropTypes.string,
    reminderDetails: PropTypes.object,
    sendReminder: PropTypes.func,
    t: PropTypes.func,
    templateDetails: PropTypes.object,
    types: PropTypes.array,
};

// connect to store
export default connect(
    state => ({
        ...state.reminder,
        allCommunicationTemplates: state.communication.allCommunicationTemplates,
        allPlaceholders: state.placeholder.allPlaceholders,
        isAdmin: state.account.isAdmin,
        isLoading: state.communication.isLoadingDetails,
        isLoadingPreview: state.communication.isLoadingPreview,
        isReminderLoading: state.reminder.isReminderLoading,
        isSaveAndSendingReminder: state.reminder.isSaveAndSendingReminder,
        isSavingReminder: state.reminder.isSavingReminder,
        isSendingReminder: state.reminder.isSendingReminder,
        isTemplateLoading: state.reminder.isTemplateLoading,
        organisationId: state.organisation.selectedOrganisationId,
        preview: state.communication.preview,
        reminderDetails: state.reminder.reminderDetails,
        roles: state.user.roles,
        templateDetails: state.communication.templateDetails,
        templateTypes: state.communication.templateTypes,
        types: state.communication.communicationTypes,
    }),
    {
        getReminderDetails,
        getCommunicationTemplateDetails,
        getCommunicationTemplatePreview,
        sendReminder,
        showNotificationPopup,
    }
)(withTranslation()(SendReminder));

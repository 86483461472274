import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'antd';

import PaymentGatewaysApi from 'includes/services/shared/paymentGateways';
import StripePaymentElement from 'components/StripePaymentElement';
import useInternalStripeDetails from 'includes/hooks/useInternalStripeDetails';
import { showApiErrors } from 'includes/utils/api';

/**
 * UI to render Stripe's payment element and add a card
 * collecting card details, validating, tokenizing and saving it to the backend
 * Uses Stripe
 *
 * @since 2.8.0
 */
export default function AddCardUsingStripe({ onClose, paymentMethodTypeId }) {
    const [isLoading, setIsLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState();

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { stripeDetails } = useInternalStripeDetails();

    const userDetails = useSelector(state => state.user.userDetails);
    /**
     * Method to set loading to false and set error message to state
     *
     * @param {string} error error message
     */
    const handleError = error => {
        setIsLoading(false);
        setErrorMessage(error.message);
    };

    /**
     * @param {object} event form submit event
     * @param {object} stripe Strip object
     * @param {object} elements Elements object
     * @returns
     */
    const handleSubmit = async (event, stripe, elements) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !stripeDetails) {
            // Stripe.js hasn't yet loaded. Or stripe details is not loaded from the backend
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();

        if (submitError) {
            setIsLoading(false);
            return;
        }

        let res = null;

        try {
            res = await PaymentGatewaysApi.getClientSecret({
                method_type_id: paymentMethodTypeId,
                payment_gateway_id: stripeDetails.id,
            });
        } catch (errors) {
            dispatch(
                showApiErrors(errors, null, 'PAYMENT_METHOD', '', [
                    'payment_gateway_id',
                    'payment_gateway',
                    'payment_method_type_id',
                ])
            );
            setIsLoading(false);
            return;
        }

        // Create the SetupIntent and obtain clientSecret

        const clientSecret = res?.data?.data?.payment_method?.next_action;

        // Confirm the SetupIntent using the details collected by the Payment Element
        const { error, setupIntent } = await stripe.confirmSetup({
            elements,
            clientSecret,
            redirect: 'if_required',
            confirmParams: {
                return_url: `${window.location.origin}/payment-information`,
                payment_method_data: {
                    billing_details: {
                        name: userDetails.full_name,
                    },
                },
            },
        });

        if (error) {
            // This point is only reached if there's an immediate error when
            // confirming the setup. Show the error to your customer (for example, payment details incomplete)
            handleError(error);
        } else {
            try {
                await PaymentGatewaysApi.addPaymentMethod({
                    method_type_id: paymentMethodTypeId,
                    payment_gateway_id: stripeDetails.id,
                    token: setupIntent.id,
                });
            } catch (errors) {
                dispatch(
                    showApiErrors(errors, null, 'PAYMENT_METHODS', '', [
                        'payment_gateway_id',
                        'payment_gateway',
                        'payment_method_type_id',
                        'payment_method',
                    ])
                );
                setIsLoading(false);
            }

            onClose();
            // Your customer is redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer is redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };
    return (
        <>
            <StripePaymentElement
                buttonLabel={t('customerMessages.paymentMethod.card.stripeAddForm.action.submit')}
                isLoading={isLoading}
                mode="setup"
                currency="aud"
                handleSubmit={handleSubmit}
            />
            {errorMessage && (
                <div className="text-left mt-2">
                    <Alert message={errorMessage} type="error" />
                </div>
            )}
        </>
    );
}

AddCardUsingStripe.propTypes = {
    onClose: PropTypes.func,
    paymentMethodTypeId: PropTypes.string,
};

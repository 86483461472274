/**
 * App Notifications Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { createSlice } from '@reduxjs/toolkit';
import { get, isArray, isString } from 'lodash';
import * as successMappings from '../constants/mappings/success';
import { ERROR, SUCCESS } from '../constants';
import * as constants from '../constants';
import * as errorMappings from '../constants/mappings/errors';
import * as errorMessages from '../constants/messages/errors';
import * as utils from '../utils';
import { stopSubmit } from 'redux-form';
import { getApiErrors, hasPermissionError } from '../utils/api';

// set the initial state
const initialState = {
    modalNotification: '',
    isNotificationModalOpen: false,
};

// define the slice
const AppNotificationsSlice = createSlice({
    name: 'appNotificationsSlice',
    initialState,
    reducers: {
        openNotificationModal(state, action) {
            state.modalNotification = action.payload;
        },
        setNotificationModalOpen(state) {
            state.isNotificationModalOpen = true;
        },
        setNotificationModalClosed(state) {
            state.isNotificationModalOpen = false;
        },
    },
});

/**
 * Open the notification modal
 *
 * @param {object} data Data to open Notification Modal
 * @param {boolean} isSuccess Whether to open success or error modal. Default true
 * @param {object} customProps Custom Props. Default {}
 */
export const openNotificationModal = (data, isSuccess = true, customProps = {}) => async dispatch => {
    dispatch(
        AppNotificationsSlice.actions.openNotificationModal({
            message: isString(data) ? data : get(data, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
            messageType: isSuccess ? SUCCESS : ERROR,
            ...customProps,
        })
    );
};

/**
 * Show the notification error modal
 * Get the error from the response and show in a notification modal
 * If the current error is related to permission, we don't need to invoke the notification modal error
 * since that will pop up an another notification modal from the client middle ware
 *
 * @param {object} data Data to show notification modal Default {}
 * @param {boolean} success Whether to show success or error modal. Default false
 * @param {string} key Key from which message needs to be taken Default ''
 * @param {string} defaultKey Default Key for message if the passed key does not have message Default ''
 * @param {string} apiErrorKey api key from which error needs to be taken Default ''
 * @param {object} customProps Custom Props. Default {}
 */
export const showNotificationModal = (
    data = {},
    success = true,
    key = '',
    defaultKey = '',
    apiErrorKey = '',
    customProps = {}
) => async dispatch => {
    let message = '';

    if (success) {
        message = get(data, successMappings.API_RESPONSE_MESSAGE_STRUCTURE);
    } else {
        // move forward if there is no permission error
        // permission errors will be handled in the middleware
        if (!hasPermissionError(data)) {
            if (!apiErrorKey) {
                apiErrorKey = constants.DEFAULT_API_ERROR_RESPONSE_STRUCTURE;
            }
            message = get(
                get(data, get(errorMappings, apiErrorKey, {})),
                get(errorMappings, key),
                get(errorMessages, defaultKey ? defaultKey : key, errorMessages.UNKNOWN_ERROR)
            );
        }
    }

    if (message) {
        dispatch(
            AppNotificationsSlice.actions.openNotificationModal({
                message: message,
                messageType: success ? constants.SUCCESS : constants.ERROR,
                ...customProps,
            })
        );
    }
};

/**
 * Stop the form submit
 * Get the error from the response and sends the errors to the form
 * If the current error is related to permission, we don't need to invoke the notification modal error
 * since that will pop up an another notification modal from the client middle ware
 * If there are any form fields specified and if there are any errors not in form fields,
 * show in notification modal instead of sending it to the form
 *
 * @param {object} errors API errors Default {}
 * @param {string} formName Name of the form. Default ''
 * @param {string} defaultApiErrorKey Default API Error Key Default ''
 * @param {string} defaultErrorKey Default Error Key Default ''
 * @param {Array} errorKeysToAddInDefaultError Error Keys to add in Default Error. Default []
 * @param {string} apiErrorKey API Error Key Default ''
 *
 * @yields Notification modal
 */
export const stopFormSubmit = (
    errors = {},
    formName = '',
    defaultApiErrorKey = '',
    defaultErrorKey = '',
    errorKeysToAddInDefaultError = [],
    apiErrorKey = ''
) => async dispatch => {
    // move forward if there is no permission error
    // permission errors will be handled in the middleware
    if (!hasPermissionError(errors)) {
        let apiErrors = getApiErrors(
            errors,
            defaultApiErrorKey,
            defaultErrorKey,
            errorKeysToAddInDefaultError,
            apiErrorKey
        );

        // get the form fields from store
        let formFields = utils.getValueFormStore([constants.FORM, formName, constants.FIELDS]);
        formFields = formFields ? Object.keys(formFields) : [];

        if (apiErrors && formFields && isArray(formFields)) {
            let key = '';

            // loop through api errors and if any error has 0 index errors, add it to the default error key
            for (key in apiErrors) {
                // if the error key is not included in the form fields, dispatch it as the notification modal
                if (!formFields.includes(key)) {
                    dispatch(
                        AppNotificationsSlice.actions.openNotificationModal({
                            message: get(apiErrors, key),
                            messageType: constants.ERROR,
                        })
                    );
                    apiErrors._error = '';
                    break;
                }
            }
        }

        dispatch(stopSubmit(formName, apiErrors));
    }
};

// export the reducer
export default AppNotificationsSlice.reducer;

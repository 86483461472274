import { Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EditCardForm from '../EditCardForm';
import EditMandateForm from '../EditMandateForm';
import usePaymentMethodTypes from 'includes/hooks/usePaymentMethodTypes';
import Loader from 'components/Loader';
import { PAYMENT_METHOD_TYPE } from 'includes/constants';
import './styles.scss';

/**
 * Payment method edit modal
 */
export default function PaymentMethodEditModal({ isLoading, onCancel, onSubmit, open, selectedPaymentMethod }) {
    const { t } = useTranslation();

    const { data: paymentMethodTypes } = usePaymentMethodTypes();

    const paymentMethodType = useMemo(() => {
        if (!paymentMethodTypes || paymentMethodTypes.length === 0 || !selectedPaymentMethod) return null;
        return paymentMethodTypes.find(type => type.id === selectedPaymentMethod?.method_type_id);
    }, [selectedPaymentMethod, paymentMethodTypes]);

    const renderModalContent = () => {
        if (!paymentMethodType) return <Loader />;

        switch (paymentMethodType.slug) {
            case PAYMENT_METHOD_TYPE.CREDIT_CARD:
            case PAYMENT_METHOD_TYPE.DEBIT_CARD:
                return (
                    <EditCardForm
                        brand={selectedPaymentMethod?.brand_name}
                        initialValues={{
                            ...selectedPaymentMethod,
                        }}
                        isLoading={isLoading}
                        number={selectedPaymentMethod?.account_number}
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                    />
                );
            case PAYMENT_METHOD_TYPE.MANDATE:
                return (
                    <EditMandateForm
                        bank={selectedPaymentMethod?.brand_name}
                        initialValues={{ ...selectedPaymentMethod }}
                        isLoading={isLoading}
                        number={selectedPaymentMethod?.account_number}
                        onSubmit={onSubmit}
                        onCancel={onCancel}
                    />
                );
        }
    };

    return (
        <Modal
            key={selectedPaymentMethod?.id} // to reset the form each time it is loaded
            visible={open}
            title={t('customerMessages.paymentMethod.editModalTitle')}
            footer={null}
            onCancel={onCancel}
            width={650}
        >
            {renderModalContent()}
        </Modal>
    );
}

PaymentMethodEditModal.propTypes = {
    isLoading: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    open: PropTypes.bool,
    selectedPaymentMethod: PropTypes.shape({
        account_number: PropTypes.string,
        brand_name: PropTypes.string,
        id: PropTypes.string,
        method_type_id: PropTypes.string,
        name: PropTypes.string,
    }),
};

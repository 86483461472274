/**
 * Payment Gateways Add/Edit component
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import { Link, React, useDispatch, useEffect, useSelector, withTranslation } from '../../../includes/exports/react';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';

import Loader from '../../shared/Loader';
import AddEditPaymentGatewaysForm from '../forms/AddEditPaymentGatewaysForm';
import { getPaymentGatewayDetails, updatePaymentGatewayDetails } from '../../../includes/slices/paymentGateways';
import { Breadcrumb } from 'antd';

const PaymentGatewayAddEdit = props => {
    const dispatch = useDispatch();

    const { accountId, organisationId, paymentGatewayId } = useParams();

    const history = useHistory();

    const isAdd = props.action === 'add';

    useEffect(() => {
        !isAdd && dispatch(getPaymentGatewayDetails(paymentGatewayId, accountId, organisationId));
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    const paymentGatewayDetails = useSelector(state => state.paymentGateways.paymentGatewayDetails);

    const isLoading = useSelector(state => state.paymentGateways.loadingPaymentGatewayDetails);

    const localeNamespace = 'test';

    const handleSubmit = values => {
        dispatch(
            updatePaymentGatewayDetails(paymentGatewayId, accountId, organisationId, values, () =>
                history.push(
                    accountId
                        ? organisationId
                            ? `/admin/accounts/organisation/payment-gateways/${accountId}/${organisationId}`
                            : `/admin/accounts/payment-gateways/${accountId}/`
                        : '/admin/payment-gateways'
                )
            )
        );
    };

    const initialValues = { ...paymentGatewayDetails, is_active: !!paymentGatewayDetails.is_active };

    return (
        <div className="home-content-wrapper edit-profile-wrapper">
            {/* <div className="tab-arrow-link">{renderBreadcrumb()}</div> */}
            <div className="tab-arrow-link breadcumb-spec">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to="/admin/accounts">Accounts</Link>
                    </Breadcrumb.Item>
                    {organisationId ? (
                        <>
                            <Breadcrumb.Item>
                                <Link to={`/admin/accounts/organisations/${accountId}`}>Organisations</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link
                                    to={`/admin/accounts/organisation/payment-gateways/${accountId}/${organisationId}`}
                                >
                                    Payment Gateways
                                </Link>
                            </Breadcrumb.Item>
                        </>
                    ) : null}
                    <Breadcrumb.Item>Payment Gateway</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h2 className="page-title">
                {props.t(
                    `customerMessages.editContact.pageTitle.${
                        isAdd ? `add${localeNamespace}` : `edit${localeNamespace}`
                    }`
                )}
            </h2>
            <div className="white-box-wrapper mid-wrapper">
                {isLoading ? (
                    <Loader tip={props.t('appCommonMessages.loadingMessage')} />
                ) : (
                    <AddEditPaymentGatewaysForm
                        initialValues={isAdd ? {} : initialValues}
                        onSubmit={handleSubmit}
                        isAdd={isAdd}
                    />
                )}
            </div>
        </div>
    );
};

PaymentGatewayAddEdit.propTypes = {
    action: PropTypes.string,
    t: PropTypes.func,
};

export default withTranslation()(PaymentGatewayAddEdit);

/**
 * Payment Gateways API class.
 * Handles all api requests related to payment gateway
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class PaymentGatewaysApi extends BaseApiHandler {
    /**
     * Add a card
     *
     * @param {string} paymentGatewayId Payment Gateway Id
     * @param {object} payload Payload to add card
     *
     * @returns {object} API Response
     */
    addCard = (paymentGatewayId, payload) =>
        this.axios.post(this.getApiUrl('PAYMENT_GATEWAY_CARDS', { payment_gateway_id: paymentGatewayId }), payload);

    /**
     * Get payment method statuses
     *
     * @returns {object} API Response
     */
    getPaymentMethodStatuses = () => this.axios.get(this.getApiUrl('PAYMENT_METHOD_STATUSES'));

    /**
     * Delete a Card
     *
     * @returns {object} API Response
     */
    deleteCard = (paymentGatewayId, cardId) =>
        this.axios.delete(
            this.getApiUrl('PAYMENT_GATEWAY_CRUD_CARDS', { payment_gateway_id: paymentGatewayId, card_id: cardId })
        );

    /**
     * Get FatZebra Verification data
     *
     * @param {string} organisationId Organisation Id
     *
     * @returns {object} API Response
     */
    getFatZebraVerificationData = organisationId =>
        this.axios.get(
            this.getApiUrl(
                organisationId
                    ? 'PAYMENT_GATEWAY_ORGANISATION_GET_FAT_ZEBRA_VERIFICATION_DATA'
                    : 'PAYMENT_GATEWAY_GET_FAT_ZEBRA_VERIFICATION_DATA',
                {
                    organisation_id: organisationId,
                }
            )
        );

    /**
     * Get the payment gateways
     *
     * @param {string} organisationId Organisation Id
     * @param {string} accountId Account Id
     *
     * @returns {object} API Response
     */
    getPaymentGateways = (organisationId, accountId, params = {}) =>
        this.axios.get(
            this.getApiUrl(organisationId ? 'PAYMENT_GATEWAYS_ORGANISATION' : 'PAYMENT_GATEWAYS', {
                organisation_id: organisationId,
            }),
            {
                params: {
                    // add account ID if passed
                    ...(accountId ? { account_id: accountId } : {}),
                    // spread rest of the params passed as args
                    ...params,
                },
            }
        );

    /**
     * Get the Saved Cards
     *
     * @param {string} paymentGatewayId Payment Gateway Id
     *
     * @returns {object} API Response
     */
    getSavedCards = paymentGatewayId =>
        this.axios.get(this.getApiUrl('PAYMENT_GATEWAY_CARDS', { payment_gateway_id: paymentGatewayId }));

    /**
     * Save Default Payment Gateway for recurring payments
     *
     * @param {string} paymentGatewayId  Payment Gateway Id
     * @param {object} payload  Payload
     *
     * @returns {object} API Response
     */
    saveDefaultPaymentGatewayForRecurringPayments = (paymentGatewayId, payload) =>
        this.axios.post(
            this.getApiUrl('PAYMENT_GATEWAY_SET_DEFAULT', { payment_gateway_id: paymentGatewayId }),
            payload
        );

    /**
     * Update a Card
     *
     * @param {string} paymentGatewayId  Payment Gateway Id
     * @param {string} cardId Card Id
     * @param {object} payload  Card details
     *
     * @returns {object} API Response
     */
    updateCard = (paymentGatewayId, cardId, payload) =>
        this.axios.patch(
            this.getApiUrl('PAYMENT_GATEWAY_CRUD_CARDS', { payment_gateway_id: paymentGatewayId, card_id: cardId }),
            payload
        );

    /**
     * Get payment gateway details
     *
     * @param {string} paymentGatewayId  Payment Gateway Id
     * @param {string} accountId  Account Id
     * @param {string} organisationId  Organisation Id
     *
     * @returns {object} API Response
     */
    getPaymentGatewayDetails = (paymentGatewayId, accountId, organisationId) =>
        this.axios.get(
            this.getApiUrl(
                accountId
                    ? organisationId
                        ? 'PAYMENT_GATEWAY_CRUD_ACCOUNT_ID_ORGANISATION_ID'
                        : 'PAYMENT_GATEWAY_CRUD_ACCOUNT_ID'
                    : 'PAYMENT_GATEWAY_CRUD',
                {
                    payment_gateway_id: paymentGatewayId,
                    account_id: accountId,
                    organisation_id: organisationId,
                }
            )
        );

    /**
     * Get organisation payment gateway details
     *
     * @param {string} paymentGatewayId Payment gateway ID
     * @param {string} organisationId Organisation ID
     *
     * @returns {object} API Response
     */
    getOrganisationPaymentGatewayDetails = (paymentGatewayId, organisationId) =>
        this.axios.get(
            this.getApiUrl('ORGANISATION_PAYMENT_GATEWAY_CRUD', {
                payment_gateway_id: paymentGatewayId,
                organisation_id: organisationId,
            })
        );

    /**
     * Update payment gateway details
     *
     * @param {string} paymentGatewayId  Payment Gateway Id
     * @param {string} accountId  Account Id
     * @param {string} organisationId  Organisation Id
     * @param {object} payload  Payment Gateway details object
     *
     * @returns {object} API Response
     */
    updatePaymentGatewayDetails = (paymentGatewayId, accountId, organisationId, payload) =>
        this.axios.patch(
            this.getApiUrl(
                accountId
                    ? organisationId
                        ? 'PAYMENT_GATEWAY_CRUD_ACCOUNT_ID_ORGANISATION_ID'
                        : 'PAYMENT_GATEWAY_CRUD_ACCOUNT_ID'
                    : 'PAYMENT_GATEWAY_CRUD',
                {
                    payment_gateway_id: paymentGatewayId,
                    account_id: accountId,
                    organisation_id: organisationId,
                }
            ),
            payload,
            {
                params: accountId ? { account_id: accountId } : {},
            }
        );

    /**
     * Delete payment gateway
     *
     * @param {string} paymentGatewayId  Payment Gateway Id
     *
     * @returns {object} API Response
     */
    deletePaymentGateway = paymentGatewayId =>
        this.axios.delete(this.getApiUrl('PAYMENT_GATEWAY_CRUD', { payment_gateway_id: paymentGatewayId }));

    /**
     * Get payments service authorization URL
     *
     * @param {string} paymentServiceId payment service ID
     * @param {string} organisationId organisation ID
     *
     * @returns {object} API Response
     */
    getPaymentServiceAuthorizationUrl = (paymentServiceId, organisationId, params = {}) =>
        this.axios.get(
            this.getApiUrl('PAYMENT_SERVICE_AUTHORIZATION', {
                payment_service_id: paymentServiceId,
                organisation_id: organisationId,
            }),
            { params }
        );

    /**
     * Patch payment gateway
     *
     * @param {string} paymentGatewayId payment gateway ID
     * @param {string} organisationId organisation ID
     * @param {string} details details
     *
     * @returns {object} API Response
     */
    updatePaymentGateway = (paymentGatewayId, organisationId, details, params = {}) =>
        this.axios.patch(
            this.getApiUrl('PAYMENT_SERVICE_EDIT', {
                payment_gateway_id: paymentGatewayId,
                organisation_id: organisationId,
            }),
            details,
            { params }
        );

    /**
     * Connect payment service
     *
     * @param {object} body object containing all details to connect to the payment service
     * @param {string} paymentServiceId payment service ID
     * @param {string} organisationId organisation ID
     *
     * @returns {object} API Response
     */
    connectPaymentService = (body, paymentServiceId, organisationId) =>
        this.axios.post(
            this.getApiUrl('PAYMENT_SERVICE_AUTHORIZATION', {
                payment_service_id: paymentServiceId,
                organisation_id: organisationId,
            }),
            body
        );

    /**
     * Add payment service
     *
     * @param {object} body object containing all details to connect to the payment service
     * @param {string} organisationId organisation ID
     *
     * @returns {object} API Response
     */
    addPaymentGateway = (body, organisationId) =>
        this.axios.post(
            this.getApiUrl('PAYMENT_GATEWAYS_ORGANISATION', {
                organisation_id: organisationId,
            }),
            body
        );

    /**
     * Delete payment service
     * TODO: combine `deletePaymentGateway` method which is being used in the admin portal
     *
     * @param {object} paymentGatewayId payment gateway ID
     * @param {string} organisationId organisation ID
     *
     * @returns {object} API Response
     */
    deletePaymentService = (organisationId, paymentGatewayId) =>
        this.axios.delete(
            this.getApiUrl('PAYMENT_SERVICE_EDIT', {
                organisation_id: organisationId,
                payment_gateway_id: paymentGatewayId,
            })
        );

    /**
     * Get payment service gateway providers
     *
     * @param {string} paymentServiceId Payment service ID
     *
     * @returns {object} API Response
     */
    getPaymentServiceGatewayProviders = paymentServiceId =>
        this.axios.get(
            this.getApiUrl('PAYMENT_SERVICE_GATEWAY_PROVIDERS', {
                payment_service_id: paymentServiceId,
            })
        );

    /**
     * Get payment client secret
     *
     * @param {object} body
     * @param {?string} body.country_id Country ID
     * @param {?string} body.mandate_name Mandate name
     * @param {string} body.method_type_id Payment method type ID
     * @param {string} body.payment_gateway_id Payment gateway ID
     * @param {?string} body.scheme_id Payment scheme ID
     *
     * @returns {object} API Response
     */
    getClientSecret = body => this.axios.post(this.getApiUrl('PAYMENT_METHODS'), body);

    /**
     * Add payment method
     *
     * @param {object} body
     * @param {?string} body.country_id Country ID
     * @param {?string} body.mandate_name Mandate name
     * @param {string} body.method_type_id Payment method type ID
     * @param {string} body.payment_gateway_id Payment gateway ID
     * @param {?string} body.scheme_id Payment scheme ID
     * @param {string} body.token Token
     *
     * @returns {object} API Response
     */
    addPaymentMethod = body => this.axios.post(this.getApiUrl('PAYMENT_METHODS'), body);

    /**
     * Update payment method
     *
     * @param {string} paymentMethodId Payment method ID
     * @param {string} details Payment method details
     *
     * @returns {object} API Response
     */
    updatePaymentMethod = (paymentMethodId, details) =>
        this.axios.patch(
            this.getApiUrl('PAYMENT_METHOD_CRUD', {
                payment_method_id: paymentMethodId,
            }),
            details
        );

    /**
     * Delete payment method
     *
     * @param {string} paymentMethodId Payment method ID
     *
     * @returns {object} API Response
     */
    deletePaymentMethod = paymentMethodId =>
        this.axios.delete(
            this.getApiUrl('PAYMENT_METHOD_CRUD', {
                payment_method_id: paymentMethodId,
            })
        );

    /**
     * Get payment methods
     *
     * @param {string | null} paymentGatewayId Payment gateway ID
     * @param {object} params Params
     *
     * @returns {object} API Response
     */
    getPaymentMethods = (paymentGatewayId, params) =>
        this.axios.get(this.getApiUrl('PAYMENT_METHODS'), {
            params: { ...(paymentGatewayId ? { payment_gateway_id: paymentGatewayId } : {}), ...params },
        });

    /**
     * Get payment method details
     *
     * @param {string | null} paymentMethodId Payment method ID
     *
     * @returns {object} API Response
     */
    getPaymentMethodDetails = paymentMethodId =>
        this.axios.get(this.getApiUrl('PAYMENT_METHOD_CRUD', { payment_method_id: paymentMethodId }));

    /**
     * Get payment method schemes
     *
     * @returns {object} API Response
     */
    getPaymentMethodSchemes = () => this.axios.get(this.getApiUrl('PAYMENT_METHOD_SCHEMES'));

    /**
     * get payment method types
     *
     * @returns {object} API response
     */
    getPaymentMethodTypes = () => this.axios.get(this.getApiUrl('PAYMENT_METHOD_TYPES'));
}

export default new PaymentGatewaysApi();

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    deletePaymentService,
    getOrganisationPaymentGateways,
    getPaymentGateways,
    updatePaymentService,
} from 'includes/slices/paymentGateways';
import PaymentGateways from 'components/PaymentGateways';
import {
    COMMON_PAYMENT_SERVICE_FORM_FIELDS,
    PAYMENT_SERVICE_COMMON_CHARGES_FIELDS,
    PAYMENT_SERVICE_SPECIFIC_CHARGES_FIELDS,
    PAYMENT_SERVICE_SPECIFIC_FIELDS,
} from 'components/PaymentGateways/constants';

/**
 * Organisation Payment Gateways
 *
 * Takes in `organisationId` as prop and fetches and renders all payment gateways setup in the organisation
 *
 * @since 2.8.0
 *
 */
export default function OrganisationPaymentGateways({ handleView, organisationId }) {
    const [selectedPaymentServiceForEditing, setSelectedPaymentServiceForEditing] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getOrganisationPaymentGateways(organisationId, undefined, { get_all_providers: true }));
        // to be used in payFURL fields component
        dispatch(getPaymentGateways(undefined, undefined, { get_all_providers: true }));
    }, []);

    const organisationPaymentGateways = useSelector(state => state.paymentGateways.organisationPaymentGateways);

    const isUpdating = useSelector(state => state.paymentGateways.isUpdating);

    const isDeleting = useSelector(state => state.paymentGateways.isDeleting);

    /**
     * Handle form submit
     *
     * @param {object} values form values
     */
    const handlePaymentServiceFormSubmit = values => {
        const paymentServiceId = values.id;

        dispatch(
            updatePaymentService(paymentServiceId, organisationId, values, () =>
                setSelectedPaymentServiceForEditing(null)
            )
        );
    };

    return (
        <PaymentGateways
            formFields={{
                COMMON: COMMON_PAYMENT_SERVICE_FORM_FIELDS,
                PAYMENT_SERVICE_SPECIFIC: PAYMENT_SERVICE_SPECIFIC_FIELDS,
                PAYMENT_SERVICE_CHARGES: PAYMENT_SERVICE_COMMON_CHARGES_FIELDS,
                PAYMENT_SERVICE_SPECIFIC_CHARGES: PAYMENT_SERVICE_SPECIFIC_CHARGES_FIELDS,
            }}
            handlePaymentServiceFormSubmit={handlePaymentServiceFormSubmit}
            handleView={handleView}
            isDeleting={isDeleting}
            isUpdating={isUpdating}
            onDelete={(paymentGatewayId, callback) =>
                dispatch(deletePaymentService(organisationId, paymentGatewayId, callback))
            }
            paymentGateways={organisationPaymentGateways}
            selectedPaymentServiceForEditing={selectedPaymentServiceForEditing}
            setSelectedPaymentServiceForEditing={setSelectedPaymentServiceForEditing}
        />
    );
}

OrganisationPaymentGateways.propTypes = {
    handleView: PropTypes.func,
    organisationId: PropTypes.string,
};

/**
 * Payment plans listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import { Breadcrumb, Dropdown, Icon, Menu, Modal, Tabs } from 'antd';
import { find, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import InfoMessage from 'components/shared/messages/InfoMessage';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { TABLE_NO_PAYMENT_PLANS_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import PermissionGuard from 'components/PermissionGuard';
import { redirect } from 'includes/utils';
import PaymentPlansBreadCrumbItems from 'components/BreadCrumbItems/PaymentPlans';
import useAccountId from 'includes/hooks/useAccountId';
import { deletePaymentPlan, getPaymentPlans } from 'includes/slices/paymentArrangement';
import { getPaymentPlansTableColumns } from 'includes/tableColumns/paymentPlans';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import { PAYMENT_ARRANGEMENT_PLANS } from 'includes/constants/permissions';
import { PAYMENT_ARRANGEMENT_PLAN_TYPE_ARRANGEMENT, PAYMENT_PLAN_FREQUENCY_TYPE_OPTIONS } from 'includes/constants';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import useOrganisationRequired from 'includes/hooks/useOrganisationRequired';

const { confirm } = Modal;
const { TabPane } = Tabs;

const PaymentPlansList = () => {
    useUpdateBusinessName();

    useOrganisationRequired();

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const organisationId = useOrganisationId();
    const isAdmin = useIsAdminOrManager();
    const getCurrencyFormattedAmount = useCurrencyFormatter();
    const paymentPlans = useSelector(state => state.paymentArrangement.paymentPlans);
    const paymentPlansPagination = useSelector(state => state.paymentArrangement.paymentPlansPagination);
    const loadingPaymentPlanDelete = useSelector(state => state.paymentArrangement.loadingPaymentPlanDelete);
    const loadingPaymentPlans = useSelector(state => state.paymentArrangement.loadingPaymentPlans);

    const [activeTab, setActiveTab] = useState('arrangement');
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    /**
     * Add payment plans menu
     */
    const addPaymentPlanMenus = () => (
        <Menu>
            <Menu.Item key="add-invoice">
                <PermissionGuard requiredPermission={PAYMENT_ARRANGEMENT_PLANS.ARRANGEMENT.ADD}>
                    <span onClick={() => redirect(getAddLink(true))}>
                        {getLocaleText('crud.addPaymentArrangementPlan')}
                    </span>
                </PermissionGuard>
            </Menu.Item>
            <Menu.Item key="add-invoice">
                <PermissionGuard requiredPermission={PAYMENT_ARRANGEMENT_PLANS.EXTENSION.ADD}>
                    <span onClick={() => redirect(getAddLink())}>{getLocaleText('crud.addPaymentExtensionPlan')}</span>
                </PermissionGuard>
            </Menu.Item>
        </Menu>
    );

    /**
     * Delete the payment plan
     *
     * @param {string} paymentPlanId Payment plan Id
     */
    const deleteArrangementPlan = paymentPlanId => {
        confirm({
            title: t(`messages.paymentArrangement.plans.confirm.delete.title`),
            content: '',
            onOk: () => {
                dispatch(deletePaymentPlan(organisationId, paymentPlanId));
            },
        });
    };

    /**
     * Format the payment plans data
     */
    const formatPaymentPlansData = useCallback(
        () =>
            paymentPlans.map(paymentPlan => {
                return {
                    ...paymentPlan,
                    name: (
                        <div className="business-name-avatar-wrapper">
                            <div className="action-wrapper">
                                {paymentPlan.name}
                                <span className="crud-wrapper">
                                    <PermissionGuard
                                        requiredPermission={
                                            paymentPlan.plan_type == PAYMENT_ARRANGEMENT_PLAN_TYPE_ARRANGEMENT
                                                ? PAYMENT_ARRANGEMENT_PLANS.ARRANGEMENT.VIEW
                                                : PAYMENT_ARRANGEMENT_PLANS.EXTENSION.VIEW
                                        }
                                    >
                                        <button
                                            className="crud-link"
                                            onClick={() => redirect(getViewLink(paymentPlan.id))}
                                        >
                                            {getLocaleText('crud.view')}
                                        </button>
                                    </PermissionGuard>
                                    <PermissionGuard
                                        requiredPermission={
                                            paymentPlan.plan_type == PAYMENT_ARRANGEMENT_PLAN_TYPE_ARRANGEMENT
                                                ? PAYMENT_ARRANGEMENT_PLANS.ARRANGEMENT.DELETE
                                                : PAYMENT_ARRANGEMENT_PLANS.EXTENSION.DELETE
                                        }
                                    >
                                        <button
                                            className="crud-link"
                                            onClick={() => deleteArrangementPlan(paymentPlan.id)}
                                        >
                                            {getLocaleText('crud.delete')}
                                        </button>
                                    </PermissionGuard>
                                </span>
                            </div>
                        </div>
                    ),
                    fee: getCurrencyFormattedAmount(paymentPlan.fee),
                    upfront_amount: `${paymentPlan.min_upfront_amount}-${paymentPlan.max_upfront_amount}%`,
                    overdue_range: `${
                        paymentPlan.overdue_range_type === 'le'
                            ? `Less than or equal to ${paymentPlan.overdue_range_to} day(s)`
                            : paymentPlan.overdue_range_type === 'ge'
                            ? `Greater than or equal to ${paymentPlan.overdue_range_from} day(s)`
                            : `${paymentPlan.overdue_range_from}-${paymentPlan.overdue_range_to} day(s)`
                    }`,
                    max_duration: `${paymentPlan.max_duration} ${get(
                        find(PAYMENT_PLAN_FREQUENCY_TYPE_OPTIONS, { id: paymentPlan.max_duration_type }),
                        'label'
                    )}`,
                    max_payment_date_extension_duration: `${paymentPlan.max_payment_date_extension_duration} ${get(
                        find(PAYMENT_PLAN_FREQUENCY_TYPE_OPTIONS, {
                            id: paymentPlan.max_payment_date_extension_duration_type,
                        }),
                        'label'
                    )}`,
                    auto_approve: getYesOrNo(paymentPlan, 'auto_approve'),
                    allow_notes: getYesOrNo(paymentPlan, 'allow_notes'),
                    is_enabled: getYesOrNo(paymentPlan, 'is_enabled'),
                };
            }),
        [paymentPlans] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Get the payment arrangement plan add link
     *
     * @param {boolean} isArrangement Whether the link is to be retrieved for arrangement. Default false
     *
     * @returns {string} Add Link
     */
    const getAddLink = (isArrangement = false) =>
        isAdmin
            ? `/admin/accounts/organisation/payment-plans/${accountId}/${organisationId}/${
                  isArrangement ? 'arrangement' : 'extension'
              }/add`
            : `/payment-plans/${isArrangement ? 'arrangement' : 'extension'}/add`;

    /**
     * Get the locale text
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @returns {string} Locale test
     */
    const getLocaleText = path => t(`messages.paymentArrangement.plans.${path}`);

    /**
     * Get the payment arrangement plan details view link
     *
     * @param {string} paymentPlanId Payment plan id
     *
     * @returns {string} View Link
     */
    const getViewLink = paymentPlanId =>
        isAdmin
            ? `/admin/accounts/organisation/payment-plans/plan/${accountId}/${organisationId}/${paymentPlanId}`
            : `/payment-plans/plan/${paymentPlanId}`;

    /**
     * Get Yes or No based on boolean value
     *
     * @param {object} paymentPlan Payment plan
     * @param {string} key Key for which we need to retrieve the value
     *
     * @returns {string} Value
     */
    const getYesOrNo = (paymentPlan, key) => getLocaleText(`list.label.${get(paymentPlan, key) ? 'yes' : 'no'}`);

    /**
     * Memoized payment plans data
     */
    const memoizedPaymentPlansData = useMemo(() => formatPaymentPlansData(), [formatPaymentPlansData]);

    const renderTable = () => (
        <Table
            className="table-1200"
            columns={getPaymentPlansTableColumns(activeTab)}
            customDataValues={{ plan_type: activeTab }}
            dataSource={memoizedPaymentPlansData}
            dataValues={[organisationId]}
            dispatchMethod={true}
            getDataMethod={organisationId ? getPaymentPlans : null}
            isSearching={isSearching}
            loading={loadingPaymentPlans}
            localeMessage={TABLE_NO_PAYMENT_PLANS_FOUND_MESSAGE}
            mobileClassName="table-1200"
            paginationData={paymentPlansPagination}
            rowClassName="pointer"
            rowKey="id"
            searchParam={searchParam}
            setIsSearching={setIsSearching}
            size="middle"
        />
    );
    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
            <h2 className="page-title">{t(`titleAndMetas.paymentPlans.title`)}</h2>
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <PaymentPlansBreadCrumbItems showListBreadCrumb={isAdmin} />
                </Breadcrumb>
            </div>
            <div className="search-filter-export-wrapper contact-list-container full-wrapper box-wrapper white-box-wrapper">
                <section className="right-menu-wrapper">
                    <div className="right-align">
                        <PermissionGuard
                            requiredPermission={[
                                PAYMENT_ARRANGEMENT_PLANS.ARRANGEMENT.ADD,
                                PAYMENT_ARRANGEMENT_PLANS.EXTENSION.ADD,
                            ]}
                        >
                            <Dropdown overlay={addPaymentPlanMenus}>
                                <Button big filled>
                                    {getLocaleText('crud.addPaymentPlan')}
                                    <Icon type="down" />
                                </Button>
                            </Dropdown>
                        </PermissionGuard>
                    </div>
                </section>
                <ul className="selector-field show-elem invoices-wrapper">
                    <li>&nbsp;</li>
                    <li>&nbsp;</li>
                    <li>
                        <div className="invoices-search">
                            <SearchBlock
                                customDataValues={{ plan_type: activeTab }}
                                dataValues={[organisationId]}
                                dispatchMethod={true}
                                getDataMethod={organisationId ? getPaymentPlans : null}
                                isSearching={isSearching}
                                placeholder={getLocaleText('search.placeholder')}
                                setIsSearching={setIsSearching}
                                setSearchParam={setSearchParam}
                            />
                        </div>
                    </li>
                </ul>
                <Tabs activeKey={activeTab} onChange={setActiveTab} destroyInactiveTabPane style={{ width: '100%' }}>
                    <TabPane key="arrangement" tab="Payment Arrangement Plans">
                        <PermissionGuardLayout
                            requiredPermission={PAYMENT_ARRANGEMENT_PLANS.ARRANGEMENT.LIST}
                            layout="section"
                            sectionErrorMessage="You do not have access to payment arrangement plans. Please contact your administrator to upgrade your plan."
                        >
                            {renderTable()}
                        </PermissionGuardLayout>
                    </TabPane>
                    <TabPane key="extension" tab="Payment Extension Plans">
                        <PermissionGuardLayout
                            requiredPermission={PAYMENT_ARRANGEMENT_PLANS.EXTENSION.LIST}
                            layout="section"
                            sectionErrorMessage="You do not have access to payment extension plans. Please contact your administrator to upgrade your plan."
                        >
                            {renderTable()}
                        </PermissionGuardLayout>
                    </TabPane>
                </Tabs>
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={loadingPaymentPlanDelete}
                >
                    <InfoMessage
                        message={t('messages.paymentArrangement.plans.modal.message.delete')}
                        showLoader={true}
                    />
                </Modal>
            </div>
        </div>
    );
};

export default PaymentPlansList;

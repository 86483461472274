import PropTypes from 'prop-types';
import OrdersTable from 'components/shared/Orders/Table';
import { ORDER_TYPE } from 'includes/constants';
import { getOrders } from 'includes/slices/orders';
import { useOrderTypes } from 'includes/utils/hooks';
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { find } from 'lodash';

export default function PaymentServiceOrdersTable({ orderTypeSlug, organisationId, paymentServiceId }) {
    const dispatch = useDispatch();
    const [orderTypes] = useOrderTypes();

    // const isAdmin = useSelector(state => state.account.isAdmin);

    /**
     * Find and memoise subscription order type
     */
    const orderType = useMemo(() => {
        if (orderTypes.length === 0) return null;

        return find(orderTypes, { slug: orderTypeSlug });
    }, [orderTypes]);
    /**
     * Get data method - to be passed in to the orders table
     */
    const getDataMethod = useCallback(
        params => {
            if (!orderType) return;

            const newParams = {
                ...params,
                // contact_id: contactId,
                ...(organisationId ? { organisation_id: organisationId } : {}),
                payment_gateway_id: paymentServiceId,
            };

            // if (isAdmin) newParams['account_id'] = accountId;

            dispatch(getOrders(orderType.id, { ...newParams }));
        },
        [orderType]
    );
    return (
        <OrdersTable
            getDataMethod={getDataMethod}
            isSearching={false}
            setIsSearching={() => {}}
            typeSlug={ORDER_TYPE.INVOICE}
        />
    );
}

PaymentServiceOrdersTable.propTypes = {
    orderTypeSlug: PropTypes.oneOf(Object.values(ORDER_TYPE)),
    organisationId: PropTypes.string,
    paymentServiceId: PropTypes.string,
};

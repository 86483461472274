/**
 * Plan Accounts component
 * Handles the Plans accounts view and functions related to Plan accounts
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import {
    React,
    connect,
    PropTypes,
    useCallback,
    useMemo,
    useState,
    withTranslation,
} from '../../../includes/exports/react';
import * as errorMessages from '../../../includes/constants/messages/errors';
import Loader from '../../shared/Loader';
import ReactHtmlParser from 'react-html-parser';
import SearchBlock from '../../shared/SearchBlock';
import Table from '../../shared/ScrollableTable';
import { Breadcrumb, Modal } from 'antd';
import { find, get } from 'lodash';
import { getDefaultImageClass, getImageFromData } from '../../../includes/utils';
import { Link } from 'react-router-dom';
import { PLAN_ACCOUNTS_TABLE_COLUMNS } from '../../../includes/constants/columns';
import {
    cancelAccountSubscription,
    getPlanAccounts,
    getSubscriptionStatuses,
} from '../../../includes/redux/actions/customer/subscriptionPlan';
import { useDataFromStore } from '../../../includes/utils/hooks';

const { confirm } = Modal;

/**
 * Plan accounts component
 */
const PlanAccounts = props => {
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const accountLogo45Key = 'ACCOUNT_LOGO_45X45';
    const accountLogoOriginalKey = 'ACCOUNT_LOGO_ORIGINAL';
    const { planId } = props.match.params;
    const [subscriptionStatuses] = useDataFromStore({
        reducer: 'subscription',
        dataKey: 'subscriptionStatuses',
        loadingKey: 'isSubscriptionStatusesLoading',
        action: getSubscriptionStatuses,
    });

    /**
     * Cancel the account subscription
     * Calls the webservice to cancel the subscription
     *
     * @param   {string}    subscriptionId    Subscription Id
     */
    const cancelSubscription = subscriptionId => {
        props.cancelAccountSubscription({ subscription_id: subscriptionId }, subscriptionError => {
            confirm({
                cancelText: props.t('appCommonMessages.no'),
                content: ReactHtmlParser(subscriptionError),
                okText: props.t('appCommonMessages.yes'),
                title: props.t('adminMessages.planAccounts.subscription.cancelSubscription'),
                onOk: () => {
                    props.cancelAccountSubscription({ subscription_id: subscriptionId, confirm: true });
                },
            });
        });
    };

    /**
     * Format the plan accounts table data
     */
    const formatPlanAccountsTableData = useCallback(
        () =>
            props.planAccounts
                ? props.planAccounts.map(planAccount => ({
                      ...planAccount,
                      account: (
                          <>
                              <div className="table-name-align">
                                  <img
                                      className={
                                          getDefaultImageClass(
                                              planAccount.account,
                                              accountLogo45Key,
                                              accountLogoOriginalKey,
                                              '45x45'
                                          ) + ' logo logo-45 '
                                      }
                                      src={getImageFromData(
                                          planAccount.account,
                                          accountLogo45Key,
                                          accountLogoOriginalKey
                                      )}
                                      alt={planAccount.account.name}
                                  />
                                  <div className="wrapped-content-text">
                                      <span className="wrapped-content-text crud-wrapper">
                                          {planAccount.account.name}
                                      </span>
                                      {get(find(subscriptionStatuses, { id: planAccount.status }), 'slug') ===
                                          'active' && (
                                          <span className="crud-wrapper">
                                              <span
                                                  className="crud-link"
                                                  onClick={() => cancelSubscription(planAccount.id)}
                                                  title={props.t(
                                                      'adminMessages.planAccounts.subscription.cancelSubscriptionTooltip'
                                                  )}
                                              >
                                                  {props.t(
                                                      'adminMessages.planAccounts.subscription.cancelSubscription'
                                                  )}
                                              </span>
                                          </span>
                                      )}
                                  </div>
                              </div>
                          </>
                      ),
                      status: get(find(subscriptionStatuses, { id: planAccount.status }), 'status'),
                  }))
                : [],
        [props.planAccounts, subscriptionStatuses] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Get the memoized data from plan accounts
     */
    const planAccountsData = useMemo(() => formatPlanAccountsTableData(), [formatPlanAccountsTableData]);

    return (
        <div className="home-content-wrapper accounts-wrapper admin-accounts">
            <div className="tab-arrow-link">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={'/admin/plans/plans'}>{props.t('adminMessages.planAccounts.breadcrumb.plans')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{props.t(`adminMessages.planAccounts.breadcrumb.planAccounts`)}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                <section>
                    <ul className="selector-field">
                        <li>
                            <div className="right-align">
                                <SearchBlock
                                    dataValues={[{ planId: planId }]}
                                    getDataMethod={props.getPlanAccounts}
                                    isSearching={isSearching}
                                    placeholder={props.t('adminMessages.planAccounts.search.placeholder')}
                                    setIsSearching={setIsSearching}
                                    setSearchParam={setSearchParam}
                                />
                            </div>
                        </li>
                    </ul>
                </section>
                <Table
                    columns={PLAN_ACCOUNTS_TABLE_COLUMNS}
                    dataSource={!props.isLoading ? planAccountsData : []}
                    dataValues={[{ planId: planId }]}
                    getDataMethod={props.getPlanAccounts}
                    isSearching={isSearching}
                    loading={props.isLoading || props.isSubscriptionStatusesLoading}
                    localeMessage={errorMessages.TABLE_NO_PLAN_ACCOUNTS_FOUND_MESSAGE}
                    paginationData={props.paginationPlanAccounts}
                    rowClassName="pointer"
                    rowKey="id"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    size="middle"
                    {...props}
                />
                <Modal
                    className="my_profile_modal organisation-reauthorization-modal"
                    closable={false}
                    maskClosable={false}
                    visible={props.isCancellingSubscription}
                    footer={null}
                >
                    <Loader tip={props.t('adminMessages.planAccounts.subscription.loader.cancellingSubscription')} />
                </Modal>
            </div>
        </div>
    );
};
/**
 *Prop types
 */
PlanAccounts.propTypes = {
    cancelAccountSubscription: PropTypes.func,
    getPlanAccounts: PropTypes.func,
    isCancellingSubscription: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSubscriptionStatusesLoading: PropTypes.bool,
    match: PropTypes.object,
    paginationPlanAccounts: PropTypes.object,
    planAccounts: PropTypes.array,
    t: PropTypes.func,
};
export default connect(
    state => ({
        isCancellingSubscription: state.subscription.isCancellingSubscription,
        isLoading: state.subscription.isPlanAccountsLoading,
        isSubscriptionStatusesLoading: state.subscription.isSubscriptionStatusesLoading,
        paginationPlanAccounts: state.subscription.paginationPlanAccounts,
        planAccounts: state.subscription.planAccounts,
    }),
    {
        cancelAccountSubscription,
        getPlanAccounts,
    }
)(withTranslation()(PlanAccounts));

/**
 * Ivr template Form fields
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import required modules
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Icon } from 'antd';
import { useTranslation } from 'react-i18next';

import * as formValidations from '../../../../includes/utils/form';
import * as requestKeys from '../../../../includes/constants/keys/request';
import Button from '../../Button';
import FormField from '../../../shared/FormField';
import Loader from '../../../shared/Loader';
import { showNotificationPopup } from 'includes/redux/actions/shared/common';
import PermissionGuard from 'components/PermissionGuard';
import { REMINDER } from 'includes/constants/permissions';

const IvrFields = props => {
    const allPlaceholders = useSelector(state => state.placeholder.allPlaceholders);
    // default status
    const [placeholderOpen, setPlaceholderOpen] = useState(false);

    //message reference
    const message = useRef(null);

    //placeholder dropdown reference
    const placeholderList = useRef(null);

    // placeholder button reference
    const placeholderButton = useRef(null);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    //insert placeholder
    const insertPlaceholder = placeholder => {
        props.change('message', message.current.value ? `${message.current.value} ${placeholder}` : placeholder);
        setPlaceholderOpen(!placeholderOpen);
    };

    const handleClick = evt => {
        if (placeholderButton && placeholderButton.current && placeholderButton.current.buttonNode.contains(evt.target))
            setPlaceholderOpen(true);
        else setPlaceholderOpen(false);
    };

    // effect to add event listener on mount and remove the same on unmount
    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, []);

    //render placeholder list
    const renderPlaceholderList = (
        <div ref={placeholderList} className="placeholder-list">
            {Object.entries(allPlaceholders).map(([key, value]) => (
                <>
                    <ul>
                        <li>
                            <div className="placeholder-key">{key}</div>
                            <ul>
                                {value &&
                                    value.map(placeholder => {
                                        return (
                                            <li
                                                key={placeholder.key}
                                                onClick={() => insertPlaceholder(placeholder.key)}
                                            >
                                                <div className="placeholder">{placeholder.name}</div>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </li>
                    </ul>
                </>
            ))}
        </div>
    );

    return (
        <div>
            {props.isLoading ? (
                <Loader />
            ) : (
                <section>
                    <div className="details-wrapper">
                        <Row gutter={16} className="settings-body auto-width">
                            <Col span={10} className="right-align auto-width preview-placeholder-button-holder">
                                <div className="sm:flex sm:justify-end sm:gap-2">
                                    {props.message ? (
                                        <PermissionGuard requiredPermission={REMINDER.PREVIEW}>
                                            <Button
                                                className="blue-bg-button preview-button"
                                                filled
                                                onClick={() => props.showPreview()}
                                            >
                                                <Icon type="eye" />
                                                Preview
                                            </Button>
                                        </PermissionGuard>
                                    ) : null}
                                    {!props.disableBody ? (
                                        <section className="placeholder-wrapper">
                                            <Button
                                                className="blue-bg-button"
                                                onClick={() => setPlaceholderOpen(!placeholderOpen)}
                                                forwardedRef={placeholderButton}
                                            >
                                                <Icon type="bulb" />
                                                Placeholder
                                            </Button>
                                            {placeholderOpen && renderPlaceholderList}
                                        </section>
                                    ) : null}
                                </div>
                            </Col>
                            <Col
                                onClick={() =>
                                    props.disableBody
                                        ? dispatch(
                                              showNotificationPopup(
                                                  t('customerMessages.reminders.send.notificationPopup.ivr')
                                              )
                                          )
                                        : ''
                                }
                            >
                                <Field
                                    ref={message}
                                    type="textarea"
                                    name={requestKeys.MESSAGE}
                                    label="Message"
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    className="template-textarea"
                                    onChange={e => {
                                        props.setMessage(e.target.value);
                                    }}
                                    disabled={props.disableBody}
                                    required
                                />
                            </Col>
                        </Row>
                    </div>
                </section>
            )}
        </div>
    );
};

IvrFields.propTypes = {
    change: PropTypes.func,
    disableBody: PropTypes.bool,
    isLoading: PropTypes.bool,
    message: PropTypes.string,
    setMessage: PropTypes.func,
    showPreview: PropTypes.func,
    t: PropTypes.func,
};

export default IvrFields;

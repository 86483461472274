/**
 * Export list Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { get } from 'lodash';

import Table from '../../shared/ScrollableTable';
import { EXPORTS_LIST_TABLE } from '../../../includes/constants/columns';
import Button from '../../shared/Button';
import { useUpdateBusinessName } from '../../../includes/utils/hooks';
import { deleteExport, getExportDownloadLink, getExports } from '../../../includes/slices/exports';
import PermissionGuard from 'components/PermissionGuard';
import { EXPORT } from 'includes/constants/permissions';

const Exports = props => {
    const dispatch = useDispatch();
    const [exportId, setExportId] = useState();
    const loadingDeleteExport = useSelector(state => state.exports.loadingDeleteExport);
    const loadingDownloadExportLink = useSelector(state => state.exports.loadingDownloadExportLink);
    const loadingExports = useSelector(state => state.exports.loadingExports);
    const exportsPagination = useSelector(state => state.exports.exportsPagination);
    const exports = useSelector(state => state.exports.exports);
    const accountDetails = useSelector(state => state.account.accountDetails);
    const accountId = get(props.match.params, 'accountId') || get(accountDetails, 'id');

    // check if user has updated the business name
    useUpdateBusinessName();

    // format table data
    const getFormattedTableData = () =>
        exports.map(item => ({
            ...item,
            actions: (
                <>
                    <div style={{ textAlign: 'center' }}>
                        <PermissionGuard requiredPermission={EXPORT.DELETE}>
                            <Button
                                className="blue-bg-button"
                                filled
                                loading={exportId === item.id && loadingDeleteExport}
                                submittingButtonLabel={props.t('sharedMessages.exports.deleteButton.submittingLabel')}
                                onClick={() => {
                                    setExportId(item.id);
                                    dispatch(deleteExport(item.id));
                                }}
                            >
                                {props.t('sharedMessages.exports.deleteButton.label')}
                            </Button>
                        </PermissionGuard>
                    </div>
                    {item.status === 'Finished' && (
                        <div style={{ textAlign: 'center', marginTop: 10 }}>
                            <PermissionGuard requiredPermission={EXPORT.DOWNLOAD}>
                                <Button
                                    className="blue-bg-button"
                                    filled
                                    loading={exportId === item.id && loadingDownloadExportLink}
                                    submittingButtonLabel={props.t(
                                        'sharedMessages.exports.downloadButton.submittingLabel'
                                    )}
                                    onClick={() => {
                                        setExportId(item.id);
                                        dispatch(getExportDownloadLink(item.id));
                                    }}
                                >
                                    {props.t('sharedMessages.exports.downloadButton.label')}
                                </Button>
                            </PermissionGuard>
                        </div>
                    )}
                </>
            ),
        }));

    return (
        <div className="home-content-wrapper">
            <h2 className="page-title">Exports</h2>
            <div className="full-wrapper box-wrapper white-box-wrapper">
                <Table
                    columns={EXPORTS_LIST_TABLE}
                    dataValues={[accountId]}
                    dataSource={getFormattedTableData()}
                    rowKey="id"
                    size="middle"
                    loading={loadingExports}
                    getDataMethod={getExports}
                    paginationData={exportsPagination}
                    dispatchMethod={true}
                    {...props}
                />
            </div>
        </div>
    );
};

/**
 * Proptypes
 */
Exports.propTypes = {
    match: PropTypes.object,
    t: PropTypes.func,
};

/**
 * Connect to store
 */
export default withTranslation()(Exports);

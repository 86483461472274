/**
 * Edit Organisation Form
 * Renders the edit organisation form
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

// import required modules
import { React } from '../../../includes/exports/react';
import { Form, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withTranslation } from 'react-i18next';
import Button from '../Button';
import * as constants from '../../../includes/constants';
import * as formValidations from '../../../includes/utils/form';
import FormField from '../FormField';
import * as requestKeys from '../../../includes/constants/keys/request';
import SuccessMessage from '../../shared/messages/SuccessMessage';
import { useDataFromStore } from '../../../includes/utils/hooks';
import { getAccountingSoftwares } from '../../../includes/redux/actions/shared/accountingSoftware';
import { get } from 'lodash';
import { MANUAL } from '../../../includes/constants';
import useCountries from 'includes/hooks/useCountries';
import useCurrencies from 'includes/hooks/useCurrencies';

const EditOrganisationForm = props => {
    const [accountingSoftwares] = useDataFromStore({
        reducer: 'accountingSoftware',
        dataKey: 'accountingSoftwares',
        loadingKey: 'isLoading',
        action: getAccountingSoftwares,
    });

    const { data: countries } = useCountries();
    const { data: currencies } = useCurrencies();

    /**
     * Generate the label from language file
     *
     * @param   {string}    field   Field for which locale is to be generated
     *
     * @returns {string}    Generated Locale label
     */
    const localeLabel = field => props.t(`customerMessages.organisationForm.form.input.label.${field}`);

    /**
     * Generate the info locale
     *
     * @param {string} field  Field for which locale is to be generated
     *
     * @returns {string} Generated Locale info
     */
    const localeInfo = field => props.t(`messages.organisations.addEdit.form.input.info.${field}`);

    const accountingSoftwareSlug = get(accountingSoftwares, [props.initialValues.accounting_software_id, 'slug']);

    const { isAdd } = props;

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                {props.detailsUpdatedMessage && <SuccessMessage message={props.detailsUpdatedMessage} />}
                <div className="profile-detail-upper">
                    <Field
                        type="avatar"
                        name={requestKeys.LOGO}
                        label={localeLabel(requestKeys.LOGO)}
                        isModalVisible={props.imagePickerVisible}
                        imageName={localeLabel(requestKeys.LOGO)}
                        onSubmit={props.onImageSubmit}
                        hasRemovedKey={requestKeys.HAS_DELETED_LOGO}
                        reduxChange={props.change}
                        toggleImagePicker={props.toggleImagePicker}
                        hasFeedback
                        component={FormField}
                        validate={[]}
                        imageSourceSizeKey={constants.IMAGE_254x254}
                        imageSourceOriginalKey={constants.IMAGE_ORIGINAL}
                        defaultImage={props.defaultImage}
                    />
                    <ul className="upper-right-detail">
                        <li>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Field
                                        type="text"
                                        name={requestKeys.NAME}
                                        label={localeLabel(requestKeys.NAME)}
                                        hasFeedback
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        required
                                    />
                                </Col>
                                <Col span={12}>
                                    <Field
                                        type="text"
                                        name={requestKeys.LEGAL_NAME}
                                        label={localeLabel(requestKeys.LEGAL_NAME)}
                                        hasFeedback
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12} className="phone-field-wrapper">
                                    <Field
                                        type="phone"
                                        name={requestKeys.MOBILE_NUMBER}
                                        label={localeLabel(requestKeys.MOBILE_NUMBER)}
                                        hasFeedback
                                        component={FormField}
                                        validate={[formValidations.phone]}
                                        reduxChange={props.change}
                                    />
                                </Col>
                                <Col span={12} className="phone-field-wrapper">
                                    <Field
                                        type="text"
                                        name={requestKeys.OFFICE_PHONE_NUMBER}
                                        label={localeLabel(requestKeys.OFFICE_PHONE_NUMBER)}
                                        hasFeedback
                                        component={FormField}
                                        reduxChange={props.change}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Field
                                        type="text"
                                        name={requestKeys.EMAIL}
                                        label={localeLabel(requestKeys.EMAIL)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.email]}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Field
                                        type="text"
                                        name={requestKeys.BUSINESS_NUMBER}
                                        label={localeLabel(requestKeys.BUSINESS_NUMBER)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        validate={[formValidations.required]}
                                        required
                                    />
                                </Col>
                            </Row>
                        </li>
                    </ul>
                </div>
                <hr />
                <section className="profile-detail-lower">
                    <div className="details-wrapper">
                        <Row gutter={16}>
                            <Col span={12} className="phone-field-wrapper">
                                <Field
                                    type="text"
                                    name={requestKeys.EXTERNAL_ID}
                                    label={localeLabel(requestKeys.EXTERNAL_ID)}
                                    hasFeedback
                                    className="form-group"
                                    component={FormField}
                                    disabled={!props.isAdd && accountingSoftwareSlug !== MANUAL}
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="switch"
                                    name={requestKeys.IS_DEFAULT}
                                    label={localeLabel(requestKeys.IS_DEFAULT)}
                                    className="form-control"
                                    component={FormField}
                                    info={props.t(`customerMessages.organisationForm.form.info.is_default`)}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12} className="phone-field-wrapper">
                                <Field
                                    type="phone"
                                    name={requestKeys.FAX_NUMBER}
                                    label={localeLabel(requestKeys.FAX_NUMBER)}
                                    hasFeedback
                                    className="form-group"
                                    component={FormField}
                                    reduxChange={props.change}
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    name={requestKeys.FINANCIAL_YEAR_END}
                                    label={localeLabel(requestKeys.FINANCIAL_YEAR_END)}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Field
                                    type="select"
                                    name={requestKeys.COUNTRY_ID}
                                    label={localeLabel(requestKeys.COUNTRY_ID)}
                                    hasFeedback
                                    options={countries.map(c => ({ name: c.name, value: c.id }))}
                                    className="form-control"
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    required
                                    disabled={!isAdd}
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="select"
                                    name={requestKeys.DEFAULT_CURRENCY_ID}
                                    label={localeLabel(requestKeys.DEFAULT_CURRENCY_ID)}
                                    hasFeedback
                                    className="form-group"
                                    options={currencies.map(c => ({
                                        name: c.name,
                                        value: c.id,
                                    }))}
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    required
                                    disabled={!isAdd}
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Field
                                    type="textarea"
                                    name={requestKeys.POSTAL_ADDRESS}
                                    label={localeLabel(requestKeys.POSTAL_ADDRESS)}
                                    hasFeedback
                                    className="form-control"
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    required
                                    info={localeInfo(requestKeys.POSTAL_ADDRESS)}
                                    infoAsToolTip
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="textarea"
                                    name={requestKeys.PHYSICAL_ADDRESS}
                                    label={localeLabel(requestKeys.PHYSICAL_ADDRESS)}
                                    hasFeedback
                                    className="form-group"
                                    component={FormField}
                                    info={localeInfo(requestKeys.PHYSICAL_ADDRESS)}
                                    infoAsToolTip
                                />
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Field
                                    type="textarea"
                                    name={requestKeys.PAYMENT_INSTRUCTIONS}
                                    label={localeLabel(requestKeys.PAYMENT_INSTRUCTIONS)}
                                    hasFeedback
                                    className="form-group"
                                    component={FormField}
                                    info={localeInfo(requestKeys.PAYMENT_INSTRUCTIONS)}
                                    infoAsToolTip
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    name={requestKeys.PAYMENT_TERMS}
                                    label={localeLabel(requestKeys.PAYMENT_TERMS)}
                                    hasFeedback
                                    className="form-group"
                                    component={FormField}
                                    info={localeInfo(requestKeys.PAYMENT_TERMS)}
                                    infoAsToolTip
                                />
                            </Col>
                        </Row>
                        {!isAdd && accountingSoftwareSlug !== MANUAL ? (
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Field
                                        type="select"
                                        name={requestKeys.ACCOUNTING_SOFTWARE}
                                        label={localeLabel(requestKeys.ACCOUNTING_SOFTWARE)}
                                        hasFeedback
                                        disabled
                                        options={Object.values(accountingSoftwares).map(s => ({
                                            name: s.name,
                                            value: s.id,
                                        }))}
                                        className="form-group"
                                        component={FormField}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Field
                                        type="switch"
                                        name={requestKeys.LOGIN_REGISTRATION_ENABLED}
                                        label={localeLabel(requestKeys.LOGIN_REGISTRATION_ENABLED)}
                                        hasFeedback
                                        className="form-group"
                                        component={FormField}
                                        infoAsToolTip
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Field
                                        type="switch"
                                        name={requestKeys.LOGIN_REGISTRATION_ENABLED}
                                        label={localeLabel(requestKeys.LOGIN_REGISTRATION_ENABLED)}
                                        hasFeedback
                                        className="form-group"
                                        component={FormField}
                                        infoAsToolTip
                                    />
                                </Col>
                            </Row>
                        )}
                    </div>
                </section>
            </Form>
            <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={props.isUpdating || props.isCreatingOrganisation}
                        submittingButtonLabel={props.t(
                            `customerMessages.organisationForm.form.submit.buttonSubmittingLabel.${
                                isAdd ? 'add' : 'edit'
                            }`
                        )}
                        className="register-btn"
                    >
                        {props.t(`customerMessages.organisationForm.form.submit.buttonLabel.${isAdd ? 'add' : 'edit'}`)}
                    </Button>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
};

/**
 * Proptypes
 */
EditOrganisationForm.propTypes = {
    accountingSoftwares: PropTypes.object,
    change: PropTypes.func,
    defaultImage: PropTypes.string,
    detailsUpdatedMessage: PropTypes.string,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    imagePickerVisible: PropTypes.bool,
    initialValues: PropTypes.object,
    isAdd: PropTypes.bool,
    isCreatingOrganisation: PropTypes.bool,
    isUpdating: PropTypes.bool,
    onImageSubmit: PropTypes.func,
    organisationCountries: PropTypes.array,
    t: PropTypes.func,
    toggleImagePicker: PropTypes.func,
};

export default reduxForm({
    form: constants.EDIT_ORGANISATION_FORM_NAME,
})(withTranslation()(EditOrganisationForm));

/**
 * Organisation Listing Component
 * Handles the organisation list view and functions related to loading organisation listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import { React, connect, useEffect, useState, useMemo, useCallback, Link } from '../../../includes/exports/react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { Button, Icon, Modal, Breadcrumb } from 'antd';
import Loader from '../../shared/Loader';
import { getOrganisationTableColumns } from '../../../includes/constants/columns';
import * as errorMessages from '../../../includes/constants/messages/errors';
import Table from '../../shared/ScrollableTable';
import {
    getOrganisations,
    setSelectedOrganisation,
    deleteOrganisation,
    getOrganisationsByAccountId,
    exportOrganisation,
    exportAccountOrganisations,
    resetOrganisationRelatedData,
} from '../../../includes/redux/actions/customer/organisation';
import { getAccountingSoftwares } from '../../../includes/redux/actions/shared/accountingSoftware';
import SearchBlock from '../../shared/SearchBlock';
import './styles.scss';
import {
    clearConnectState,
    getAccountingSoftwareAuthLink,
    verifyAccountingSoftwareAuthorization,
} from '../../../includes/redux/actions/customer/connect';
import * as responseKeys from '../../../includes/constants/keys/response';
import * as constants from '../../../includes/constants';
import * as utils from '../../../includes/utils';
import history from '../../../includes/exports/history';
import * as requestKeys from '../../../includes/constants/keys/request';
import SuccessMessage from '../../shared/messages/SuccessMessage';
import { useDataFromStore, useUpdateBusinessName, useViewingAccountType } from '../../../includes/utils/hooks';
import InfoMessage from '../../shared/messages/InfoMessage';
import { DEBTOR_ACCOUNT_TYPE_SLUG, XERO_ACCOUNTING_SOFTWARE_SLUG } from '../../../includes/constants';
import ExportButton from '../../shared/Exports/button';
import useAllowZendeskIntegration from 'includes/hooks/useAllowZendeskIntegration';
import PermissionGuard from 'components/PermissionGuard';
import { ORGANISATION } from 'includes/constants/permissions';
import useCountries from 'includes/hooks/useCountries';

const { confirm } = Modal;
/**
 * Organisation Listing Component
 * Render the organisation listing component
 *
 * @returns    {string}    Organisation listing view
 */
const Organisations = props => {
    /**
     * Set state
     */
    const [modalContent, setModalContent] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [tableFilters, setTableFilters] = useState({});
    const viewingAccountType = useViewingAccountType();
    const allowZendeskIntegration = useAllowZendeskIntegration();
    const { data: countries } = useCountries();

    const {
        getOrganisations,
        location,
        organisationReauthorizationSuccess,
        accountingSoftwareErrorMessage,
        isLoading,
        isAdmin,
    } = props;

    // check if user has updated the business name
    useUpdateBusinessName();

    // fetch supported accounting softwares list
    const [accountingSoftwaresList, isAccountingSoftwareLoading] = useDataFromStore({
        reducer: 'accountingSoftware',
        dataKey: 'accountingSoftwares',
        loadingKey: 'isLoading',
        action: getAccountingSoftwares,
    });

    const { accountId, reauthorize } = props.match.params;

    /**
     * Use Effect hook for organisation re-authorization success
     */
    useEffect(() => {
        if (organisationReauthorizationSuccess) {
            getOrganisations();
            setModalContent(getModalContent());
        }
    }, [organisationReauthorizationSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use Effect hook on loading complete
     */
    useEffect(() => {
        if (accountingSoftwareErrorMessage) {
            setModalContent(getModalContent());
        } else if (!isLoading && !isAccountingSoftwareLoading) {
            verifyOrganisationAuthorization();
        }
    }, [isAccountingSoftwareLoading, isLoading, accountingSoftwareErrorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use Effect hook on loading complete
     */
    useEffect(() => {
        if (reauthorize === 'reauthorize') {
            const organisation = get(location, ['state', 'organisation']);
            const shouldReauthorizeOrganisation = get(location, ['state', 'reauthorize']);

            if (shouldReauthorizeOrganisation && organisation) {
                reauthorizeOrganisation(organisation);
                history.replace('/organisations');
            }
        }
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use Effect hook to reset the organisation specific data on load if admin user
     */
    useEffect(() => {
        if (isAdmin) {
            props.resetOrganisationRelatedData();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Close the modal pop up
     */
    const closeModalPopup = () => {
        props.clearConnectState();
        setOpenModal(false);
    };

    /**
     * Delete organisation
     *
     * @param {string} organisationId Id of the organisation to be deleted
     * @param {string} accountingSoftwareSlug Accounting software slug
     */
    const deleteOrganisation = (organisationId, accountingSoftwareSlug) => {
        confirm({
            title: props.t(
                accountingSoftwareSlug === XERO_ACCOUNTING_SOFTWARE_SLUG
                    ? 'customerMessages.organisation.confirm.deleteXeroOrganisation'
                    : 'customerMessages.organisation.confirm.deleteOrganisation'
            ),
            content: '',
            onOk: () => {
                props.deleteOrganisation(organisationId, accountId, props.isAdmin);
            },
        });
    };

    /**
     * Get the content for the modal
     */
    const getModalContent = (accountingSoftwareId = '', isAuthorizing = false, force = false, organisation = '') => (
        <>
            {(!organisationReauthorizationSuccess || force) && (!accountingSoftwareErrorMessage || force) ? (
                <Loader />
            ) : (
                ''
            )}
            <div className="reauthorize-text">
                {props.organisationReauthorizationSuccess && !force ? (
                    <SuccessMessage message={props.t('customerMessages.organisation.verifyAuthorizationSuccess')} />
                ) : accountingSoftwareErrorMessage && !force ? (
                    <div className="has-error">
                        <p className="ant-form-explain">{accountingSoftwareErrorMessage}</p>
                    </div>
                ) : (
                    props.t(
                        isAuthorizing
                            ? 'customerMessages.organisation.verifyAuthorizationSubmittingButtonLabel'
                            : 'customerMessages.organisation.getReAuthorizationLinkLabel',
                        {
                            accountingSoftware: get(
                                accountingSoftwaresList,
                                [accountingSoftwareId, 'name'],
                                get(organisation, ['accounting_software_data', 'name'])
                            ),
                        }
                    )
                )}
            </div>
        </>
    );

    /**
     * Get the modal footer content
     */
    const getModalFooter = () => {
        return (
            <>
                {accountingSoftwareErrorMessage || organisationReauthorizationSuccess ? (
                    <Button
                        key="organisation-reauthorization-back"
                        className="ant-btn green-bg-button"
                        onClick={() => closeModalPopup()}
                    >
                        Ok
                    </Button>
                ) : (
                    ''
                )}
            </>
        );
    };

    /**
     * Get the organisation logo
     * Checks if the organisation has a logo of the specified dimension, if not check for original organisation logo
     * if not get the current default specified
     */
    const getOrganisationLogo = organisation => {
        return (
            get(organisation, responseKeys.ORGANISATION_LOGO_45X45) ||
            get(organisation, responseKeys.ORGANISATION_LOGO_ORIGINAL) ||
            require('../../../assets/images/organisation_avatar.svg')
        );
    };

    const renderAdminCrudLink = (organisationId, organisationIsManual) => {
        const getLocaleLabel = link => props.t(`adminMessages.organisations.list.table.crudLink.${link}`);
        return (
            <span>
                {[
                    {
                        link: 'organisation',
                        label: getLocaleLabel('edit'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation-dashboard',
                        label: getLocaleLabel('dashboard'),
                        showLink: true,
                    },
                    {
                        link: 'organisation/contacts',
                        label: getLocaleLabel('contacts'),
                        showLink: true,
                    },
                    {
                        link: 'organisation/contact-managers',
                        label: getLocaleLabel('contactManagers'),
                        isManualOrganisationLink: true,
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/contact-types',
                        label: getLocaleLabel('contactTypes'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/invoices',
                        label: getLocaleLabel('invoices'),
                        showLink: true,
                    },
                    {
                        link: 'organisation/campaigns',
                        label: getLocaleLabel('campaigns'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/reminders',
                        label: getLocaleLabel('reminders'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/email-templates',
                        label: getLocaleLabel('emailTemplates'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/sms-templates',
                        label: getLocaleLabel('smsTemplates'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/ivr-templates',
                        label: getLocaleLabel('ivrTemplates'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/placeholders',
                        label: getLocaleLabel('placeholders'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/payments',
                        label: getLocaleLabel('payments'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/orders',
                        label: getLocaleLabel('orders'),
                        showLink: true,
                    },
                    {
                        link: 'organisation/settings',
                        label: getLocaleLabel('settings'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/debtor-accounts',
                        label: getLocaleLabel('debtorAccounts'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/currencies',
                        label: getLocaleLabel('currencies'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/chart-of-accounts',
                        label: getLocaleLabel('chartOfAccounts'),
                        isManualOrganisationLink: true,
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/inventory',
                        label: getLocaleLabel('inventory'),
                        isManualOrganisationLink: true,
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/taxes',
                        label: getLocaleLabel('taxes'),
                        isManualOrganisationLink: true,
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/zendesk-settings',
                        label: getLocaleLabel('zendeskSettings'),
                        showLink: allowZendeskIntegration,
                    },
                    {
                        link: 'organisation/zendesk-tickets',
                        label: getLocaleLabel('zendeskTickets'),
                        showLink: allowZendeskIntegration,
                    },
                    {
                        link: 'organisation/payment-gateways',
                        label: getLocaleLabel('paymentGateways'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/payment-plans',
                        label: getLocaleLabel('paymentPlans'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/payment-arrangements',
                        label: getLocaleLabel('paymentArrangements'),
                        showLink: true,
                    },
                    {
                        link: 'organisation/payment-extensions',
                        label: getLocaleLabel('paymentExtensions'),
                        showLink: true,
                    },
                    {
                        link: 'organisation/event-calendar',
                        label: getLocaleLabel('eventCalendar'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                    {
                        link: 'organisation/audit-logs',
                        label: getLocaleLabel('auditLogs'),
                        showLink: viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG,
                    },
                ].map(x => {
                    if ((x.isManualOrganisationLink && !organisationIsManual) || !x.showLink) return null;

                    return (
                        <Link
                            className="crud-link"
                            key={x.link}
                            to={`/admin/accounts/${x.link}/${accountId}/${organisationId}`}
                        >
                            {x.label}
                        </Link>
                    );
                })}
                <Link className="crud-link" to={`/admin/accounts/bank-accounts/${accountId}/`}>
                    {'Bank accounts'}
                </Link>
            </span>
        );
    };

    const { organisations, selectedOrganisationId, t, setSelectedOrganisation } = props;

    /**
     * Modify and return the organisation data
     */
    const formatOrganisationsData = useCallback(() => {
        const accountingSoftwares = Object.values(accountingSoftwaresList);
        return organisations.map(organisation => {
            const isCurrentOrganisation = selectedOrganisationId === organisation.id;
            const accountingSoftwareSlug = get(
                accountingSoftwares.find(s => s.id === organisation.accounting_software),
                'slug',
                ''
            );
            return {
                ...organisation,
                accounting_software: get(
                    accountingSoftwares.find(s => s.id === organisation.accounting_software),
                    'name',
                    ''
                ),
                name: (
                    <>
                        <div className="table-name-align">
                            <img
                                className="logo logo-45"
                                src={getOrganisationLogo(organisation)}
                                alt={organisation.name}
                            />
                            <div className="wrapped-content-text">
                                {organisation.name}
                                {organisation.is_default && (
                                    <span title="Default Organisation. This organisation will be loaded by default, when you login">
                                        <Icon
                                            type="check"
                                            style={{
                                                color: 'green',
                                                marginLeft: '10px',
                                                fontSize: '15px',
                                            }}
                                        />
                                    </span>
                                )}
                                <span className="crud-wrapper">
                                    {isAdmin ? (
                                        renderAdminCrudLink(organisation.id, organisation.is_manual)
                                    ) : (
                                        <PermissionGuard requiredPermission={ORGANISATION.EDIT}>
                                            <span
                                                onClick={() => utils.redirect(`organisation/${organisation.id}`)}
                                                className="crud-link"
                                            >
                                                Edit
                                            </span>
                                        </PermissionGuard>
                                    )}
                                    {viewingAccountType !== DEBTOR_ACCOUNT_TYPE_SLUG ? (
                                        <PermissionGuard requiredPermission={ORGANISATION.DELETE}>
                                            <span
                                                className="crud-link"
                                                onClick={() => {
                                                    deleteOrganisation(organisation.id, accountingSoftwareSlug);
                                                }}
                                            >
                                                {props.t(
                                                    `customerMessages.organisation.list.table.actions.label.delete.${accountingSoftwareSlug}`
                                                )}
                                            </span>
                                        </PermissionGuard>
                                    ) : null}
                                </span>
                            </div>
                        </div>
                    </>
                ),
                action: (
                    <div className="action-wrapper">
                        {organisation.reauthorize && (
                            <Button
                                type="link"
                                onClick={() => reauthorizeOrganisation(organisation)}
                                className="organisation-action-btn"
                            >
                                {t('customerMessages.organisation.reauthorize')}
                            </Button>
                        )}
                        {!isCurrentOrganisation && (
                            <>
                                <Button
                                    type="link"
                                    onClick={() => setSelectedOrganisation(organisation.id)}
                                    className="organisation-action-btn"
                                >
                                    Switch
                                </Button>
                            </>
                        )}
                    </div>
                ),
                country: get(
                    countries.find(x => x.id === organisation.country),
                    'name',
                    ''
                ),
            };
        });
        // eslint-disable-next-line
    }, [organisations, accountingSoftwaresList, countries]);

    //memoized data
    const organisationsData = useMemo(() => formatOrganisationsData(), [formatOrganisationsData]);

    /**
     * Reauthorize organisation
     *
     * @param   {object}    organisation          Organisation
     */
    const reauthorizeOrganisation = organisation => {
        setModalContent(getModalContent(organisation.accounting_software, false, true, organisation));
        setOpenModal(true);

        let data = {};
        data[constants.USER_ACCOUNTING_SOFTWARE_ID_KEY] = organisation.accounting_software;
        data[constants.USER_REAUTHORIZING_ORGANISATION_KEY] = organisation.id;

        //set the data in local storage
        utils.setDataInLocalStorage(data);

        props.getAccountingSoftwareAuthLink({
            accounting_software_id: organisation.accounting_software,
            organisation_id: organisation.id,
        });
    };

    /**
     * verify the organisation authorization
     */
    const verifyOrganisationAuthorization = () => {
        let userReauthorizingOrganisation = utils.getLocalStorageValue(constants.USER_REAUTHORIZING_ORGANISATION_KEY);
        let accountingSoftwareId = utils.getLocalStorageValue(constants.USER_ACCOUNTING_SOFTWARE_ID_KEY);

        if (userReauthorizingOrganisation && accountingSoftwareId) {
            //build the json object from url
            let urlParams = utils.buildQueryParamsObject(props.location.search);

            // add code to oauth token
            urlParams.oauth_token = urlParams.code;

            // check if oauth token exists in the url params
            if (urlParams.oauth_token && utils.getLocalStorageValue(constants.USER_ACCOUNTING_SOFTWARE_ID_KEY)) {
                urlParams[requestKeys.ACCOUNTING_SOFTWARE_ID] = utils.getLocalStorageValue(
                    constants.USER_ACCOUNTING_SOFTWARE_ID_KEY
                );

                setModalContent(getModalContent('', true, true));
                setOpenModal(true);

                urlParams[requestKeys.REDIRECT_TO_ORGANISATION] = true;

                props.verifyAccountingSoftwareAuthorization(urlParams);

                utils.removeLocalStorage(constants.USER_REAUTHORIZING_ORGANISATION_KEY);
                utils.removeLocalStorage(constants.USER_ACCOUNTING_SOFTWARE_ID_KEY);
                history.replace('/organisations');
            }
        }
    };

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper listing-wrapper">
            <h2 className="page-title">{props.t('customerMessages.organisation.title')}</h2>
            {isAdmin && (
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/admin/accounts">Account</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Organisations</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                <section className="right-menu-wrapper">
                    <SearchBlock
                        dataValues={accountId ? [accountId] : []}
                        getDataMethod={accountId ? props.getOrganisationsByAccountId : props.getOrganisations}
                        isSearching={isSearching}
                        placeholder={props.t('customerMessages.organisation.search.placeholder')}
                        setIsSearching={setIsSearching}
                        setSearchParam={setSearchParam}
                        setTableFilters={setTableFilters}
                    />
                    <div className="right-align">
                        <ExportButton exportType={'organisations'} queryParams={{ ...tableFilters }} />
                    </div>
                </section>
                <Table
                    columns={getOrganisationTableColumns({
                        props,
                        accountingSoftwares: accountingSoftwaresList,
                    })}
                    collapseHeaderKeys={['name']}
                    dataSource={organisationsData}
                    dataValues={accountId ? [accountId] : []}
                    getDataMethod={accountId ? props.getOrganisationsByAccountId : props.getOrganisations}
                    isSearching={isSearching}
                    loading={isLoading}
                    localeMessage={errorMessages.TABLE_NO_ORGANISATIONS_FOUND_MESSAGE}
                    rowClassName="pointer"
                    rowKey="id"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    paginationData={props.paginationOrganisations}
                    size="middle"
                    setTableFilters={setTableFilters}
                    {...props}
                    className="accounts-table-admin"
                />
                <Modal
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={openModal}
                    onCancel={() => closeModalPopup()}
                    onOk={() => closeModalPopup()}
                    footer={getModalFooter()}
                >
                    {modalContent}
                </Modal>
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={props.isDeletingOrganisation}
                >
                    <InfoMessage
                        message={props.t('customerMessages.organisation.loading.message.deletingOrganisation')}
                        showLoader={true}
                    />
                </Modal>
            </div>
        </div>
    );
};

/**
 * Proptypes
 */
Organisations.propTypes = {
    accountingSoftwareErrorMessage: PropTypes.string,
    clearConnectState: PropTypes.func,
    connectToAccountingSoftwareLoading: PropTypes.bool,
    defaultOrganisationLogo: PropTypes.string,
    deleteOrganisation: PropTypes.func,
    exportAccountOrganisations: PropTypes.func,
    exportOrganisation: PropTypes.func,
    getAccountingSoftwareAuthLink: PropTypes.func,
    getAccountingSoftwares: PropTypes.func,
    getCountries: PropTypes.func,
    getOrganisations: PropTypes.func,
    getOrganisationsByAccountId: PropTypes.func,
    history: PropTypes.object,
    isAdmin: PropTypes.bool,
    isDeletingOrganisation: PropTypes.bool,
    isExportLoading: PropTypes.bool,
    isLoading: PropTypes.bool,
    location: PropTypes.object,
    match: PropTypes.object,
    message: PropTypes.string,
    organisationReauthorizationSuccess: PropTypes.bool,
    organisations: PropTypes.array,
    paginationOrganisations: PropTypes.object,
    resetOrganisationRelatedData: PropTypes.func,
    selectedOrganisationId: PropTypes.string,
    setSelectedOrganisation: PropTypes.func,
    t: PropTypes.func,
    userDetails: PropTypes.object,
    verifyAccountingSoftwareAuthorization: PropTypes.func,
    verifyAccountingSoftwareAuthorizationLoading: PropTypes.bool,
};

// connect to store
export default connect(
    state => ({
        accountingSoftwareErrorMessage: state.connect.accountingSoftwareErrorMessage,
        connectToAccountingSoftwareLoading: state.connect.connectToAccountingSoftwareLoading,
        defaultOrganisationLogo: get(state.settings.siteSettings, responseKeys.DEFAULT_ORGANISATION_LOGO_45X45),
        isAdmin: state.account.isAdmin,
        isDeletingOrganisation: state.organisation.isDeletingOrganisation,
        isExportLoading: state.organisation.isExportLoading,
        isLoading: state.organisation.isLoading,
        message: state.organisation.message,
        organisationCountries: state.organisation.organisationCountries,
        organisationReauthorizationSuccess: state.connect.organisationReauthorizationSuccess,
        organisations: state.organisation.organisations,
        paginationOrganisations: state.organisation.paginationOrganisations,
        roles: state.user.roles,
        selectedOrganisationId: state.organisation.selectedOrganisationId,
        userDetails: state.user.userDetails,
        verifyAccountingSoftwareAuthorizationLoading: state.connect.verifyAccountingSoftwareAuthorizationLoading,
    }),
    {
        clearConnectState,
        getOrganisations,
        setSelectedOrganisation,
        getAccountingSoftwareAuthLink,
        deleteOrganisation,
        getOrganisationsByAccountId,
        verifyAccountingSoftwareAuthorization,
        exportOrganisation,
        exportAccountOrganisations,
        resetOrganisationRelatedData,
    }
)(withTranslation()(Organisations));

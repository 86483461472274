/**
 * Full Sidebar Layout
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import the required modules
import { React, connect, useRef, useEffect } from '../includes/exports/react';
import { Layout, Icon } from 'antd';
import { PropTypes } from 'prop-types';

import { toggleSidebar } from '../includes/redux/actions/admin/settings';
import { isMobile } from '../includes/utils';
import { ChildrenContentLayout } from './Children';
import useAccountId from 'includes/hooks/useAccountId';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountDetails, resetUserAccountDetails } from 'includes/redux/actions/shared/account';
import {
    getOrganisationDetails,
    resetOrganisationRelatedData,
    resetSelectedOrganisation,
} from 'includes/redux/actions/customer/organisation';
import Loader from 'components/Loader';
import useZendeskSettings from 'includes/hooks/useZendeskSettings';

const { Header, Footer, Sider } = Layout;

const FullSidebarLayout = ({
    children,
    featureAvailableForManualOrganisationsOnly,
    siderCollapsed,
    toggleSidebar,
    ...sections
}) => {
    const siderRef = useRef(null);
    const accountId = useAccountId(true);
    const organisationId = useOrganisationId(true);
    const isAdmin = useIsAdminOrManager();
    const dispatch = useDispatch();
    const isAccountDetailsLoading = useSelector(state => state.account.isAccountDetailsLoading);
    const isOrganisationDetailsLoading = useSelector(state => state.organisation.isDetailsLoading);
    const currentViewingAccountDetails = useSelector(state => state.account.userAccountDetails);
    const selectedOrganisationId = useSelector(state => state.organisation.selectedOrganisationId);
    useZendeskSettings();

    /**
     * Get the account details of current viewing account
     */
    useEffect(() => {
        if (isAdmin) {
            if (accountId) {
                dispatch(getAccountDetails(accountId));
            } else {
                if (currentViewingAccountDetails) {
                    dispatch(resetUserAccountDetails());
                }
            }
        }
    }, [accountId]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the account details of current viewing organisation
     */
    useEffect(() => {
        if (isAdmin) {
            if (organisationId) {
                if (organisationId !== selectedOrganisationId) {
                    dispatch(getOrganisationDetails(organisationId, true));
                }
            } else {
                if (selectedOrganisationId) {
                    dispatch(resetOrganisationRelatedData());
                    dispatch(resetSelectedOrganisation());
                }
            }
        }
    }, [organisationId]); // eslint-disable-line react-hooks/exhaustive-deps

    const toggle = () => {
        toggleSidebar(!siderCollapsed);
    };

    // handle closing of sider
    const handleClickOutside = event => {
        if (siderRef.current && !siderRef.current.contains(event.target)) {
            isMobile() && toggleSidebar(true);
        }
    };

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header className="admin-header">{sections.header && sections.header}</Header>
            <Layout className="admin-content">
                <Header style={{ background: '#fff', padding: 0 }}>
                    <Icon
                        className="trigger"
                        type={siderCollapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={() => toggle()}
                    />
                </Header>
                <Sider className="admin-sidebar-custom" trigger={null} collapsible collapsed={siderCollapsed}>
                    <div ref={siderRef}>{sections.sidebar && sections.sidebar}</div>
                </Sider>
                <ChildrenContentLayout
                    featureAvailableForManualOrganisationsOnly={featureAvailableForManualOrganisationsOnly}
                >
                    {isAccountDetailsLoading || isOrganisationDetailsLoading ? <Loader /> : children}
                </ChildrenContentLayout>
            </Layout>
            <Footer className="admin-footer-wrapper">{sections.footer && sections.footer}</Footer>
        </Layout>
    );
};

FullSidebarLayout.propTypes = {
    children: PropTypes.node,
    featureAvailableForManualOrganisationsOnly: PropTypes.bool,
    siderCollapsed: PropTypes.bool,
    toggleSidebar: PropTypes.func,
};

export default connect(
    state => ({
        siderCollapsed: state.adminSettings.siderCollapsed,
    }),
    { toggleSidebar }
)(FullSidebarLayout);

export const arrangementsDueGraphOptions = {
    animationEnabled: true,
    zoomEnabled: true,
    axisY: {
        title: 'Amount ($)',
        includeZero: false,
        gridDashType: 'dot',
        gridThickness: 0.5,
        titleFontSize: 12,
        labelFontSize: 12,
        labelFontColor: '#97a4ba',
    },
    axisX: {
        gridDashType: 'dot',
        gridThickness: 0.5,
        titleFontSize: 12,
        labelFontSize: 12,
        labelFontColor: '#97a4ba',
        labelAngle: 150,
    },
    colorSet: 'customColorSet1',
    toolTip: {
        borderThickness: 0,
        content: '${amountShort} from {accounts} accounts on {x}', // eslint-disable-line
        cornerRadius: 5,
        fontFamily: 'Muli Regular',
    },
    legend: {
        verticalAlign: 'bottom',
        horizontalAlign: 'right',
        fontWeight: 'normal',
        fontSize: 12,
        fontColor: '#595964',
    },
    theme: 'light2',
    data: [
        {
            type: 'area',
            fillOpacity: 0.09,
            name: 'Arrangements Due',
            xValueType: 'dateTime',
            markerSize: 10,
            showInLegend: true,
            indexLabelFontFamily: 'Muli',
            indexLabelFontSize: 12,
            dataPoints: [],
        },
    ],
};

/**
 * Admin Header Component
 * Handles the admin header view and functions to loading header
 * This component is a shared component which can be used in different modules
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

import { React, connect, Link } from '../../../../includes/exports/react';
import { withTranslation } from 'react-i18next';
import { Menu, Icon, Popover } from 'antd';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import './styles.scss';

import * as userActions from '../../../../includes/redux/actions/shared/user';
import { clearLSAndRedirectToAuthPortalLogoutPage } from 'includes/utils';

const AdminHeader = props => {
    const userAvatar = get(props.userDetails, 'avatar', require('../../../../assets/images/user.svg'));

    const logout = () => clearLSAndRedirectToAuthPortalLogoutPage();

    const content = (
        <section className="main-menu-popover debt-collector-main-menu">
            <Link to="/debt-collector/my-profile">
                <div className="popover-item">
                    <span>My Profile</span>
                </div>
            </Link>
            <div className="popover-item" onClick={logout}>
                <span>Logout</span>
            </div>
        </section>
    );
    return (
        <div className="debt-collector-header-wrapper">
            <section className="common-header-wrapper">
                <h1>
                    <Link className="logo" to="/debt-collector/dashboard" alt="logo" />
                </h1>
                <Menu mode="horizontal" className="home-menu-wrapper" overflowedIndicator={<Icon type="align-right" />}>
                    <Menu.Item className="menu-item right-align profile-detail" key="my-account">
                        <Popover content={content} title="" placement="bottomRight" trigger="hover">
                            <img src={userAvatar} alt="profile-pic"></img>
                        </Popover>
                    </Menu.Item>
                </Menu>
            </section>
        </div>
    );
};

AdminHeader.propTypes = {
    location: PropTypes.object,
    t: PropTypes.func,
    logoutUser: PropTypes.func,
    userDetails: PropTypes.func,
};

//Connect to store
export default connect(null, {
    ...userActions,
})(withTranslation()(AdminHeader));

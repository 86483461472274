/**
 * Notification actions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import action types
import * as actionTypes from '../../../constants/actionTypes';

/**
 * Clear all notifications
 *
 * @returns {object} Clear all notifications action
 */
export const clearAllNotifications = () => ({
    type: actionTypes.CLEAR_ALL_NOTIFICATIONS_REQUEST,
});

/**
 * Delete all notifications
 *
 * @returns {object} Delete all notifications action
 */
export const deleteAllNotifications = () => ({
    type: actionTypes.DELETE_ALL_NOTIFICATIONS_REQUEST,
});

/**
 * Get notifications
 *
 * @param   {object}    payload         payload
 *
 * @returns {object}                     Notifications action
 */
export const getNotifications = payload => ({
    payload: payload,
    type: actionTypes.GET_NOTIFICATIONS_REQUEST,
});

/**
 * Received notification from server action
 *
 * @param   {object} notification Notification
 * @param   {number} unreadCount Unread Count Default 0
 *
 * @returns {object}                     Received notification from server action
 */
export const receivedNotificationFromServer = (notification, unreadCount = 0) => ({
    notification: notification,
    unreadCount: unreadCount,
    type: actionTypes.RECEIVED_NOTIFICATION_FROM_SERVER,
});

/**
 * Received notification from server action
 *
 * @param {object} notifications Notifications
 * @param {number} unreadCount Unread Count   Default 0
 *
 * @returns {object}                     Received notifications from server action
 */
export const receivedNotificationsFromServer = (notifications, unreadCount = 0) => ({
    notifications: notifications,
    unreadCount: unreadCount,
    type: actionTypes.RECEIVED_NOTIFICATIONS_FROM_SERVER,
});

/**
 * Send data to server action
 *
 * @param {object} obj Payload
 *
 * @returns {object} Send data to server action
 */
export const sendDataToServer = obj => ({
    payload: obj,
    type: actionTypes.SEND_DATA_TO_SERVER,
});

/**
 * Update the all notifications with the new set of all notifications
 *
 * @param {object} allNotifications Updated notifications
 * @param {int} unreadCount Unread count
 *
 * @returns {object} Update all notifications action
 */
export const updateAllNotifications = (allNotifications, unreadCount) => ({
    allNotifications: allNotifications,
    unreadCount: unreadCount,
    type: actionTypes.UPDATE_ALL_NOTIFICATIONS_REQUEST,
});

/**
 * Update the notifications with the new set of notifications
 *
 * @param {object} notifications Updated notifications
 * @param {int} unreadCount Unread count
 *
 * @returns {object} Update notifications action
 */
export const updateNotifications = (notifications, unreadCount) => ({
    notifications: notifications,
    unreadCount: unreadCount,
    type: actionTypes.UPDATE_NOTIFICATIONS_REQUEST,
});

/**
 * Placeholder Reducer
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';
import { get } from 'lodash';
import * as successMappings from '../../../../constants/mappings/success';
import { getPaginationData } from '../../../../utils';

const INITIAL_STATE = {
    allPlaceholders: {},
    isAddingPlaceholder: false,
    isAllPlaceholdersLoading: false,
    isDeletingPlaceholder: false,
    isPlaceholdersLoading: true,
    isUpdatingPlaceholder: false,
    placeholderGroup: [],
    placeholderDetails: {},
    placeholders: {},
};

/**
 * Placeholder Reducer Function
 * Handles the state changes in placeholders
 *
 * @param   {object}    state   Current State
 * @param   {object}    action  Current Action
 *
 * @returns {object}            Updated State
 */
export default function CommunicationReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case actionTypes.ADD_PLACEHOLDER_FAILURE:
            return {
                ...state,
                isAddingPlaceholder: false,
            };
        case actionTypes.ADD_PLACEHOLDER_REQUEST:
            return {
                ...state,
                isAddingPlaceholder: true,
            };
        case actionTypes.ADD_PLACEHOLDER_SUCCESS:
            return {
                ...state,
                message: get(action.result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                allPlaceholders: {},
                placeholders: get(action.result, successMappings.PLACEHOLDERS),
                paginationPlaceholders: getPaginationData(action),
                isAddingPlaceholder: false,
            };
        case actionTypes.DELETE_PLACEHOLDER_FAILURE:
            return {
                ...state,
                isDeletingPlaceholder: false,
            };
        case actionTypes.DELETE_PLACEHOLDER_REQUEST:
            return {
                ...state,
                isDeletingPlaceholder: true,
            };
        case actionTypes.DELETE_PLACEHOLDER_SUCCESS:
            return {
                ...state,
                message: get(action.result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                allPlaceholders: {},
                placeholders: get(action.result, successMappings.PLACEHOLDERS),
                paginationPlaceholders: getPaginationData(action),
                isPlaceholdersLoading: false,
                isDeletingPlaceholder: false,
            };
        case actionTypes.GET_ALL_PLACEHOLDERS_FAILURE:
            return {
                ...state,
                isAllPlaceholdersLoading: false,
            };
        case actionTypes.GET_ALL_PLACEHOLDERS_REQUEST:
            return {
                ...state,
                isAllPlaceholdersLoading: true,
            };
        case actionTypes.GET_ALL_PLACEHOLDERS_SUCCESS:
            return {
                ...state,
                placeholderGroup: Object.keys(get(action.result, successMappings.PLACEHOLDERS)),
                allPlaceholders: get(action.result, successMappings.PLACEHOLDERS),
                isAllPlaceholdersLoading: false,
            };
        case actionTypes.GET_PLACEHOLDERS_FAILURE:
            return {
                ...state,
                paginationPlaceholders: {},
                isPlaceholdersLoading: false,
            };
        case actionTypes.GET_PLACEHOLDERS_REQUEST:
            return {
                ...state,
                paginationPlaceholders: {},
                isPlaceholdersLoading: true,
            };
        case actionTypes.GET_PLACEHOLDERS_SUCCESS:
            return {
                ...state,
                message: get(action.result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                placeholders: get(action.result, successMappings.PLACEHOLDERS),
                paginationPlaceholders: getPaginationData(action),
                isPlaceholdersLoading: false,
            };
        case actionTypes.RESET_ORGANISATION_RELATED_DATA:
            return {
                ...state,
                allPlaceholders: {},
                placeholderGroup: [],
                placeholderDetails: {},
                placeholders: {},
            };
        case actionTypes.UPDATE_PLACEHOLDER_FAILURE:
            return {
                ...state,
                isUpdatingPlaceholder: false,
            };
        case actionTypes.UPDATE_PLACEHOLDER_REQUEST:
            return {
                ...state,
                isUpdatingPlaceholder: true,
            };
        case actionTypes.UPDATE_PLACEHOLDER_SUCCESS:
            return {
                ...state,
                message: get(action.result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                allPlaceholders: {},
                placeholders: get(action.result, successMappings.PLACEHOLDERS),
                paginationPlaceholders: getPaginationData(action),
                isUpdatingPlaceholder: false,
            };
        default:
            return state;
    }
}

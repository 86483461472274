/**
 * Zendesk Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */
import createAppSlice from './base';
import ZendeskApi from 'includes/services/zendesk';
import { get, isFunction } from 'lodash';
import { showApiErrors, showApiSuccess } from '../utils/api';
import { ZENDESK_SETTINGS, ZENDESK_TICKET, ZENDESK_TICKETS } from 'includes/constants/mappings/success';
import { getPaginationData } from 'includes/utils';

const initialState = {
    loadingSettings: false,
    loadingSave: false,
    loadingTicket: false,
    loadingTickets: false,
    settings: {},
    ticket: {},
    tickets: [],
    ticketsPagination: {},
};

// define the slice
const ZendeskSlice = createAppSlice('zendeskSlice', initialState);
const { setData } = ZendeskSlice.actions;

/**
 * Get zendesk settings for an organisation
 *
 * @param {string} organisationId Organisation Id
 */
export const getSettings = organisationId => async dispatch => {
    try {
        dispatch(setData('loadingSettings', true));
        const result = await ZendeskApi.getSettings(organisationId);
        dispatch(setData('settings', get(result, ZENDESK_SETTINGS)));
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'ZENDESK_SETTINGS'));
    } finally {
        dispatch(setData('loadingSettings', false));
    }
};

/**
 * Get zendesk ticket details for an organisation
 *
 * @param {string} organisationId Organisation Id
 * @param {string} ticketId Ticket Id
 */
export const getTicket = (organisationId, ticketId) => async dispatch => {
    try {
        dispatch(setData('loadingTicket', true));
        const result = await ZendeskApi.getTicket(organisationId, ticketId);
        dispatch(setData('ticket', get(result, ZENDESK_TICKET)));
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'ZENDESK_TICKET'));
    } finally {
        dispatch(setData('loadingTicket', false));
    }
};

/**
 * Get zendesk tickets for an organisation
 *
 * @param {string} organisationId Organisation Id
 * @param {object} searchParams Search Params. Default {}
 */
export const getTickets = (organisationId, searchParams = {}) => async dispatch => {
    try {
        dispatch(setData('loadingTickets', true));
        const result = await ZendeskApi.getTickets(organisationId, searchParams);
        dispatch(setData('tickets', get(result, ZENDESK_TICKETS)));
        dispatch(setData('ticketsPagination', getPaginationData({ result })));
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'ZENDESK_TICKETS'));
    } finally {
        dispatch(setData('loadingTickets', false));
    }
};

/**
 * Save zendesk settings for an organisation
 *
 * @param {object} settings Settings
 * @param {string} organisationId Organisation Id.
 * @param {Function} updateZendeskSettingsCache Update Setting cache
 */
export const saveSettings = (settings, organisationId, updateZendeskSettingsCache) => async dispatch => {
    try {
        dispatch(setData('loadingSave', true));
        const result = await ZendeskApi.saveSettings(settings, organisationId);
        dispatch(setData('settings', get(result, ZENDESK_SETTINGS)));
        dispatch(showApiSuccess(result));
        if (updateZendeskSettingsCache && isFunction(updateZendeskSettingsCache)) {
            updateZendeskSettingsCache(get(result, ZENDESK_SETTINGS));
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'ZENDESK_SETTINGS_FORM_NAME', 'ZENDESK_SETTINGS'));
    } finally {
        dispatch(setData('loadingSave', false));
    }
};

export default ZendeskSlice.reducer;

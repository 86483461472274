/**
 * Orders Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import OrdersApi from 'includes/services/orders';
import { get, isFunction } from 'lodash';
import * as successMappings from '../constants/mappings/success';
import { showNotificationModal, stopFormSubmit } from './appNotifications';
import { getPaginationData, redirect } from '../utils';
import { EDIT_ORDER_FORM_NAME, PAYMENT_STATUS, USER_ROLE_ADMIN, USER_ROLE_ADMIN_MANAGER } from '../constants';
import * as errorMappings from '../constants/mappings/errors';
import { ID, URL } from '../constants/keys/response';
import { setSecretToken } from './paymentGateways';
import createAppSlice from './base';
import { showApiErrors } from 'includes/utils/api';
import {
    COUPON_ID,
    ORDER_TYPE,
    PAYMENT_GATEWAY,
    PAYMENT_GATEWAY_ID,
    SAVE_CARD_FOR_LATER_USE,
    TOKEN,
} from 'includes/constants/keys/request';

// set the initial state
const initialState = {
    isGettingOrderCharges: false,
    loadingCreateOrder: false,
    loadingOrderDetails: false,
    loadingOrderInvoiceDownload: false,
    loadingOrders: false,
    loadingOrderStatus: false,
    loadingOrderStatuses: false,
    loadingOrderTypes: false,
    orderCharges: {},
    orderDetails: null,
    orders: [],
    orderStatuses: [],
    orderTypes: [],
};

// define the slice
const OrdersSlice = createAppSlice('ordersSlice', initialState, {
    endCreateOrderLoading(state) {
        state.loadingCreateOrder = false;
    },
    endOrderDetailsLoading(state) {
        state.loadingOrderDetails = false;
    },
    endOrderInvoiceDownloadLoading(state) {
        state.loadingOrderInvoiceDownload = false;
    },
    endOrdersLoading(state) {
        state.loadingOrders = false;
    },
    endOrderStatusLoading(state) {
        state.loadingOrderStatus = false;
    },
    endOrderStatusesLoading(state) {
        state.loadingOrderStatuses = false;
    },
    endOrderTypesLoading(state) {
        state.loadingOrderTypes = false;
    },
    endOrderUpdateLoading(state) {
        state.loadingUpdateOrder = false;
    },
    setCreateOrderLoading(state) {
        state.loadingCreateOrder = true;
    },
    setOrderDetails(state, action) {
        state.orderDetails = action.payload;
    },
    setOrderInvoiceDownloadLoading(state) {
        state.loadingOrderInvoiceDownload = true;
    },
    setOrderDetailsLoading(state) {
        state.orderDetails = null;
        state.loadingOrderDetails = true;
    },
    setOrders(state, action) {
        state.orders = action.payload;
    },
    setOrdersPagination(state, action) {
        state.ordersPagination = getPaginationData(action, true);
    },
    setOrdersLoading(state) {
        state.orders = [];
        state.ordersPagination = {};
        state.loadingOrders = true;
    },
    setOrderStatus(state, action) {
        state.orderDetails = {
            ...state.orderDetails,
            ...action.payload,
        };
    },
    setOrderStatusLoading(state) {
        state.loadingOrderStatus = true;
    },
    setOrderStatuses(state, action) {
        state.orderStatuses = action.payload;
    },
    setOrderStatusesLoading(state) {
        state.loadingOrderStatuses = true;
    },
    setOrderTypes(state, action) {
        state.orderTypes = action.payload;
    },
    setOrderTypesLoading(state) {
        state.loadingOrderTypes = true;
    },
    setOrderUpdateLoading(state) {
        state.loadingUpdateOrder = true;
    },
});

const { setData } = OrdersSlice.actions;

/**
 * Create an order
 *
 * @param {string} orderType Order Type
 * @param {string} orderId Order id
 * @param {object} payload Payload
 * @param {string} formName Form Name
 * @param {Function} getRedirectUrlCallback Redirect URL callback to get the URL to which we should redirect after successful order creation
 * @param {string|Function} callback Callback function
 * @param {boolean} retry Whether we are retrying or creating order. Default false
 */
export const createOrder = (
    orderType,
    orderId = '',
    payload,
    formName,
    getRedirectUrlCallback,
    callback = '',
    retry = false
) => async dispatch => {
    try {
        dispatch(OrdersSlice.actions.setCreateOrderLoading());
        const result = retry
            ? await OrdersApi.retryOrder(orderType, orderId, payload)
            : await OrdersApi.createOrder(orderType, payload);
        const orderDetailsId = retry
            ? get(result, successMappings.ORDER_UPDATE_ORDER_ID)
            : get(result, successMappings.ORDER_ID);
        const orderPaymentSlug = retry
            ? get(result, successMappings.ORDER_UPDATE_ORDER_PAYMENT_SLUG)
            : get(result, successMappings.ORDER_PAYMENT_SLUG);
        const secretToken = retry
            ? get(result, successMappings.ORDER_UPDATE_ORDER_PAYMENT_SECRET_TOKEN)
            : get(result, successMappings.ORDER_PAYMENT_SECRET_TOKEN);
        const orderDetailsUrl = getRedirectUrlCallback(orderType, orderDetailsId, payload);
        if (orderPaymentSlug !== PAYMENT_STATUS.REQUIRES_ACTION) {
            redirect(orderDetailsUrl);
            dispatch(showNotificationModal(result));
        } else {
            dispatch(OrdersSlice.actions.setOrderDetails({ [URL]: orderDetailsUrl }));
            dispatch(setSecretToken(secretToken));
        }
    } catch (errors) {
        // check if there is any subscription errors in the response
        const subscriptionError = get(errors, errorMappings.ORDER_SUBSCRIPTION_ERROR);
        if (subscriptionError && callback && isFunction(callback)) {
            callback(subscriptionError);
        } else {
            const errorKey = retry ? 'ORDER_RETRY_ERROR_MESSAGE' : 'ORDER_CREATE_ERROR_MESSAGE';
            dispatch(stopFormSubmit(errors, formName, errorKey, errorKey));
        }
    }
    dispatch(OrdersSlice.actions.endCreateOrderLoading());
};

/**
 * Download order invoice
 *
 * @param {string} orderType Order Type
 * @param {string} orderId Order Id. Default
 * @param {object} searchParams Search params. Default {}
 */
export const downloadOrderInvoice = (orderType, orderId, searchParams = {}) => async dispatch => {
    try {
        dispatch(OrdersSlice.actions.setOrderInvoiceDownloadLoading());
        const result = await OrdersApi.downloadOrderInvoice(orderType, orderId, searchParams);
        const downloadUrl = get(result, successMappings.ORDER_DOWNLOAD_INVOICE);
        if (downloadUrl) {
            window.open(downloadUrl, '_blank');
        }
        dispatch(showNotificationModal(result));
    } catch (errors) {
        dispatch(
            showNotificationModal(
                errors,
                false,
                'ORDER_DOWNLOAD_INVOICE_ERROR_MESSAGE',
                'ORDER_DOWNLOAD_INVOICE_DEFAULT_ERROR_MESSAGE'
            )
        );
    }
    dispatch(OrdersSlice.actions.endOrderInvoiceDownloadLoading());
};

/**
 * Get order details
 *
 * @param {string} orderType Order Type
 * @param {string} orderId Order Id
 * @param {string} currentRoleSlug Current User Role Slug. Default ''
 * @param {object} searchParams Search params. Default {}
 */
export const getOrderDetails = (orderType, orderId, currentRoleSlug = '', searchParams = {}) => async dispatch => {
    try {
        dispatch(OrdersSlice.actions.setOrderDetailsLoading());
        const result = await OrdersApi.getOrderDetails(orderType, orderId, searchParams);
        dispatch(OrdersSlice.actions.setOrderDetails(get(result, successMappings.ORDER_GET_DETAILS)));
    } catch (errors) {
        redirect(
            [USER_ROLE_ADMIN, USER_ROLE_ADMIN_MANAGER].includes(currentRoleSlug)
                ? '/admin/page-not-found'
                : '/page-not-found'
        );
    }
    dispatch(OrdersSlice.actions.endOrderDetailsLoading());
};

/**
 * Get order status
 *
 * @param {string} orderType Order Type
 * @param {string} orderId Order Id. Default
 * @param {object} searchParams Search params. Default {}
 */
export const getOrderStatus = (orderType, orderId, searchParams = {}) => async dispatch => {
    try {
        dispatch(OrdersSlice.actions.setOrderStatusLoading());
        const result = await OrdersApi.getOrderStatus(orderType, orderId, searchParams);
        dispatch(OrdersSlice.actions.setOrderStatus(get(result, successMappings.ORDER_GET_STATUS)));
    } catch (errors) {
        dispatch(
            showNotificationModal(errors, false, 'ORDER_STATUS_ERROR_MESSAGE', 'ORDER_STATUS_DEFAULT_ERROR_MESSAGE')
        );
    }
    dispatch(OrdersSlice.actions.endOrderStatusLoading());
};

/**
 * Get orders
 *
 * @param {string} orderType Order Type
 * @param {object} searchParams Search params. Default {}
 */
export const getOrders = (orderType, searchParams = {}) => async dispatch => {
    try {
        dispatch(OrdersSlice.actions.setOrdersLoading());
        const result = await OrdersApi.getOrders(orderType, searchParams);
        dispatch(OrdersSlice.actions.setOrders(get(result, successMappings.ORDERS)));
        dispatch(OrdersSlice.actions.setOrdersPagination(result));
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, 'ORDERS_ERROR_MESSAGE', 'ORDERS_DEFAULT_ERROR'));
    }
    dispatch(OrdersSlice.actions.endOrdersLoading());
};

/**
 * Get order statuses
 */
export const getOrderStatuses = () => async dispatch => {
    try {
        dispatch(OrdersSlice.actions.setOrderStatusesLoading());
        const result = await OrdersApi.getOrderStatuses({ page_size: 0 });
        dispatch(OrdersSlice.actions.setOrderStatuses(get(result, successMappings.ORDER_GET_STATUSES)));
        // eslint-disable-next-line no-empty
    } catch (errors) {}
    dispatch(OrdersSlice.actions.endOrderStatusesLoading());
};

/**
 * Get order types
 */
export const getOrderTypes = () => async dispatch => {
    try {
        dispatch(OrdersSlice.actions.setOrderTypesLoading());
        const result = await OrdersApi.getOrderTypes({ page_size: 0 });
        dispatch(OrdersSlice.actions.setOrderTypes(get(result, successMappings.ORDER_GET_TYPES)));
        // eslint-disable-next-line no-empty
    } catch (errors) {}
    dispatch(OrdersSlice.actions.endOrderTypesLoading());
};

/**
 * Update order
 *
 * @param {string} accountId Account Id
 * @param {string} orderType Order Type
 * @param {string} orderId Order Id
 * @param {string} organisationId Organisation id. Default ''
 * @param {object} payload Payload. Default {}
 */
export const updateOrder = (accountId, orderType, orderId, payload = {}) => async dispatch => {
    try {
        dispatch(OrdersSlice.actions.setOrderUpdateLoading());
        const result = await OrdersApi.updateOrder(orderId, orderType, payload);
        dispatch(OrdersSlice.actions.setOrderDetails(get(result, successMappings.ORDER_UPDATE)));
        dispatch(OrdersSlice.actions.setOrdersPagination(result));
        dispatch(showNotificationModal(result));
        redirect(`/admin/accounts/orders/${accountId}/${orderType}/${orderId}`);
    } catch (errors) {
        dispatch(
            stopFormSubmit(errors, EDIT_ORDER_FORM_NAME, 'ORDER_UPDATE_ERROR_MESSAGE', 'ORDERS_UPDATE_DEFAULT_ERROR')
        );
    }
    dispatch(OrdersSlice.actions.endOrderUpdateLoading());
};

/**
 * Get order charges
 *
 * @param {string} orderTypeId Order type ID
 * @param {object} paymentDetails Payment details
 */
export const getOrderCharges = (orderTypeId, paymentDetails) => async dispatch => {
    try {
        dispatch(setData('isGettingOrderCharges', true));
        const result = await OrdersApi.getOrderCharges(orderTypeId, paymentDetails);
        dispatch(setData('orderCharges', get(result, successMappings.ORDER_CHARGES)));
    } finally {
        dispatch(setData('isGettingOrderCharges', false));
    }
};

/**
 * Cancel order
 *
 * @param {string} orderTypeId order type ID
 * @param {string} orderId order ID
 */
export const cancelOrder = (orderTypeId, orderId, params = {}) => async dispatch => {
    try {
        dispatch(setData('isCancellingOrder', true));
        const result = await OrdersApi.cancelOrder(orderTypeId, orderId, params);
        dispatch(OrdersSlice.actions.setOrderDetails(get(result, successMappings.ORDER_GET_DETAILS)));
        dispatch(showNotificationModal(result, true));
    } catch (errors) {
        dispatch(showApiErrors(errors, undefined, undefined, undefined, [ORDER_TYPE]));
    } finally {
        dispatch(setData('isCancellingOrder', false));
    }
};

/**
 * Confirm order
 *
 * @param {string} orderTypeId Order type ID
 * @param {string} orderId Order ID
 * @param {object} payload payload
 * @callback callback
 * @param {boolean} hasError hasError
 * @param {Function} onShowSuccessMessage show success message callback
 */
export const confirmOrder = (
    orderTypeId,
    orderId,
    payload,
    callback = () => {},
    hasError = false,
    onShowSuccessMessage
) => async dispatch => {
    try {
        dispatch(setData('isConfirmingOrder', true));
        const result = await OrdersApi.confirmOrder(orderTypeId, orderId, payload);
        const orderDetails = get(result, successMappings.ORDER_CONFIRM);
        dispatch(OrdersSlice.actions.setOrderDetails(orderDetails));
        callback();
        // only show success message if `error_message` and `payment_error` keys are empty
        if (!hasError && !orderDetails.error_message && !orderDetails.payment_error) {
            if (onShowSuccessMessage) {
                onShowSuccessMessage();
            } else dispatch(showNotificationModal(result));
        }
    } catch (errors) {
        dispatch(
            showApiErrors(errors, undefined, undefined, undefined, [
                COUPON_ID,
                SAVE_CARD_FOR_LATER_USE,
                PAYMENT_GATEWAY_ID,
                PAYMENT_GATEWAY,
                ID,
                TOKEN,
                ORDER_TYPE,
            ])
        );
    } finally {
        dispatch(setData('isConfirmingOrder', false));
    }
};

export const setOrderDetails = payload => async dispatch => {
    dispatch(OrdersSlice.actions.setOrderDetails(payload));
};

// export the reducer
export default OrdersSlice.reducer;

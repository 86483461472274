/**
 * Payments API class.
 * Handles all api requests related to payments
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class PaymentsApi extends BaseApiHandler {
    /**
     * Add Payment bank account
     *
     * @param {object} body bank account details
     * @returns {object} API Response
     */
    addPaymentBankAccount = body => this.axios.post(this.getApiUrl('PAYMENTS_BANK_ACCOUNT_CREATE_LIST'), body);

    /**
     * Save Payment Bank Account Details
     *
     * @returns {object} API Response
     */
    deletePaymentBankAccount = paymentBankAccountId =>
        this.axios.delete(
            this.getApiUrl('PAYMENTS_BANK_ACCOUNT_UPDATE_DELETE', { payment_bank_account_id: paymentBankAccountId })
        );

    /**
     * Get Payment bank accounts
     *
     * @returns {object} API Response
     */
    getPaymentBankAccounts = (params = {}) =>
        this.axios.get(this.getApiUrl('PAYMENTS_BANK_ACCOUNT_CREATE_LIST'), { params });

    /**
     * Get the payment statuses
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getPaymentStatuses = payload => this.axios.get(this.getApiUrl('PAYMENTS_GET_STATUSES'), { params: { ...payload } });

    /**
     * Save Payment Bank Account Details
     *
     * @returns {object} API Response
     */
    updatePaymentBankAccount = (paymentBankAccountId, payload) =>
        this.axios.patch(
            this.getApiUrl('PAYMENTS_BANK_ACCOUNT_UPDATE_DELETE', { payment_bank_account_id: paymentBankAccountId }),
            payload
        );
}

export default new PaymentsApi();

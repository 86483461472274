/**
 * useOrganisationRequired
 * Checks if organisation has been added. If not, show info message
 *
 * @since 2.2.0.1
 */

import React from 'react';
import { Modal } from 'antd';

import useOrganisationId from './useOrganisationId';
import { useHistory } from 'react-router';

const { info } = Modal;

/**
 * Checks if organisation is present
 * if its not present, renders an info modal
 *
 * @param boolean showInfo: Whether to show info message. Default true
 */
const useOrganisationRequired = (showInfo = true) => {
    const organisationId = useOrganisationId();
    const history = useHistory();

    if ((!organisationId || organisationId === 'undefined' || organisationId === undefined) && showInfo) {
        info({
            content: <div>Please add/select an organisation before continuing</div>,
            onOk: () => history.push('/organisation/add'),
            keyboard: false,
            maskClosable: false,
        });
    }
};

export default useOrganisationRequired;

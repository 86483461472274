/**
 * Admin settings Saga
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import the required modules
import * as actionTypes from '../../../../constants/actionTypes';
import * as constants from '../../../../constants';
import * as successMappings from '../../../../constants/mappings/success';
import BaseSagaHandler from '../../core/base';
import SettingsApi from '../../../../services/admin/settings';
import { get } from 'lodash';
import { takeLatest, all, put, call } from 'redux-saga/effects';
import { UNKNOWN_ERROR } from '../../../../constants/messages/errors';

class AdminSettingsSaga extends BaseSagaHandler {
    /**
     * The Settings Watcher Saga
     * Watches the redux actions related to settings and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *settingsWatchers() {
        let context = this;
        yield all([
            yield takeLatest(actionTypes.GET_ADMIN_SETTINGS_REQUEST, [context, 'getSettings']),
            yield takeLatest(actionTypes.UPDATE_ADMIN_SETTINGS_REQUEST, [context, 'updateSettings']),
        ]);
    }

    /**
     * Get the admin settings
     *
     * @yields {object} Settings response
     */
    *getSettings() {
        try {
            const result = yield call(SettingsApi.getSettings);
            const settings = get(result, successMappings.GET_SETTINGS);
            Object.values(settings).forEach(item => {
                Object.entries(item).forEach(([field, q]) => {
                    if (q.dependent_fields) {
                        let dep_ans = {};
                        Object.entries(q.dependent_fields).forEach(([value, qIds]) => {
                            dep_ans[value] = qIds.map(qId => {
                                let qObj = item[qId];
                                delete item[qId];
                                return qObj;
                            });
                            item[field].dependent_fields = dep_ans;
                        });
                    }
                });
            });
            const settingsObj = Object.values(settings).reduce((a, x) => ({
                ...a,
                ...x,
            }));
            const initialValues = {};
            Object.values(settingsObj).forEach(x => {
                initialValues[x.id] = x.value;
            });
            yield put({ type: actionTypes.GET_ADMIN_SETTINGS_SUCCESS, settings, initialValues });
        } catch (errors) {
            yield put({ type: actionTypes.GET_ADMIN_SETTINGS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Update the settings
     *
     * @yields {object} Update settings response
     */
    *updateSettings() {
        try {
            const result = yield call(SettingsApi.updateSettings);
            yield put({ type: actionTypes.UPDATE_ADMIN_SETTINGS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.UPDATE_ADMIN_SETTINGS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }
}

//export the Settings saga
export default new AdminSettingsSaga().forkAllWatcherFunctions();

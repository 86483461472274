import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import Button from 'components/shared/Button';
import { BANK_ACCOUNT_FORM_NAME } from 'includes/constants';
import { Field, reduxForm } from 'redux-form';
import FormField from 'components/shared/FormField';
import * as formValidations from 'includes/utils/form';
import { getYearsRange } from 'includes/utils';
import { BANK_ACCOUNT_TYPE_OPTION } from '../../../../constants';

const EXPIRY_YEARS_OPTIONS = getYearsRange(50);

/**
 * Bank account form
 *
 * Form used to add/edit bank account
 *
 * @since 2.8.0
 */
function BankAccountForm({ handleSubmit, isLoading, onCancel, okLabel }) {
    const { t } = useTranslation();

    return (
        <>
            <Form layout="vertical" onSubmit={handleSubmit}>
                <div className="grid grid-cols-6 gap-2">
                    <div className="col-span-6 md:col-span-4 text-left">
                        <Field
                            type="text"
                            name="account_holder_name"
                            label={t('customerMessages.paymentMethod.bankAccount.form.label.accountHolderName')}
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </div>

                    <div className="col-span-6 md:col-span-2 text-left">
                        <Field
                            type="select"
                            name="account_holder_type"
                            label={t('customerMessages.paymentMethod.bankAccount.form.label.accountHolderType')}
                            options={BANK_ACCOUNT_TYPE_OPTION}
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </div>

                    <div className="col-span-6 md:col-span-3 text-left">
                        <Field
                            type="text"
                            name="account_number"
                            label={t('customerMessages.paymentMethod.bankAccount.form.label.accountNumber')}
                            options={EXPIRY_YEARS_OPTIONS}
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </div>

                    <div className="col-span-6 md:col-span-3 text-left">
                        <Field
                            type="text"
                            name="bank_code"
                            label={t('customerMessages.paymentMethod.bankAccount.form.label.bankCode')}
                            options={EXPIRY_YEARS_OPTIONS}
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </div>
                </div>
                <div className="flex gap-3 justify-end">
                    <Button onClick={onCancel}>{t('customerMessages.paymentServices.form.action.cancel')}</Button>
                    <Button filled loading={isLoading} htmlType="submit">
                        {okLabel ?? 'Submit'}
                    </Button>
                </div>
            </Form>
        </>
    );
}

BankAccountForm.propTypes = {
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    okLabel: PropTypes.string,
    onCancel: PropTypes.func,
};

const BankAccountFromWithRedux = reduxForm({
    form: BANK_ACCOUNT_FORM_NAME,
    enableReinitialize: true,
})(BankAccountForm);

/**
 * Bank account add/edit modal
 *
 * @since 2.8.0
 */
export default function BankAccountFormModal({ initialValues, isLoading, onCancel, onSubmit, open }) {
    const { t } = useTranslation();

    return (
        <Modal
            key={initialValues?.id}
            visible={open}
            title={
                initialValues.id
                    ? t('customerMessages.paymentMethod.bankAccount.form.title.edit')
                    : t('customerMessages.paymentMethod.bankAccount.form.title.add')
            }
            footer={null}
            onCancel={onCancel}
            width={650}
        >
            <BankAccountFromWithRedux
                key={open} // to reset form state
                initialValues={initialValues}
                isLoading={isLoading}
                onCancel={onCancel}
                okLabel={
                    initialValues?.id
                        ? t('customerMessages.paymentMethod.bankAccount.form.submit.save')
                        : t('customerMessages.paymentMethod.bankAccount.form.submit.add')
                }
                onSubmit={onSubmit}
            />
        </Modal>
    );
}

BankAccountFormModal.propTypes = {
    initialValues: PropTypes.object,
    isLoading: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    open: PropTypes.bool,
};

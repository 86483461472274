/**
 * Zendesk breadcrumb items
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import AccountsBreadCrumbItems from '../Accounts';
import OrganisationsBreadCrumbItems from '../Organisations';
import PropTypes from 'prop-types';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ZendeskBreadCrumbItems = props => {
    const accountId = useAccountId();
    const isAdminOrManager = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    const { t } = useTranslation();

    const ticket = useSelector(state => state.zendesk.ticket);

    /**
     * Get the zendesk tickets details link
     *
     * @param {string} ticketId Ticket id
     *
     * @returns {string} Details link
     */
    const getDetailsLink = ticketId =>
        ticketId
            ? isAdminOrManager
                ? `/admin/accounts/organisation/zendesk-tickets/zendesk-ticket/${accountId}/${organisationId}/${ticketId}`
                : `/zendesk-tickets/zendesk-ticket/${ticketId}`
            : '#';

    /**
     * Get the zendesk tickets list link
     *
     * @returns {string} List link
     */
    const getListLink = () =>
        isAdminOrManager
            ? `/admin/accounts/organisation/zendesk-tickets/${accountId}/${organisationId}`
            : `/zendesk-tickets`;

    /**
     * Render the component
     */
    return (
        <>
            {isAdminOrManager ? (
                <>
                    <AccountsBreadCrumbItems showListBreadCrumb />
                    <OrganisationsBreadCrumbItems showListBreadCrumb />
                </>
            ) : null}
            {props.showSettingsBreadCrumb ? (
                <Breadcrumb.Item>{t('messages.breadCrumbs.zendesk.settings')}</Breadcrumb.Item>
            ) : null}
            {props.showListBreadCrumb ? (
                <Breadcrumb.Item>
                    <Link to={getListLink()}>{t('messages.breadCrumbs.zendesk.list')}</Link>
                </Breadcrumb.Item>
            ) : null}
            {props.showDetailsBreadCrumb ? (
                <Breadcrumb.Item>
                    {props.showDetailsBreadCrumbAsLink ? (
                        <Link to={getDetailsLink(get(ticket, 'id'))}>
                            {get(ticket, 'name', t('messages.breadCrumbs.zendesk.details'))}
                        </Link>
                    ) : (
                        get(ticket, 'name', t('messages.breadCrumbs.zendesk.details'))
                    )}
                </Breadcrumb.Item>
            ) : null}
        </>
    );
};

ZendeskBreadCrumbItems.propTypes = {
    showAddEditBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumbAsLink: PropTypes.bool,
    showListBreadCrumb: PropTypes.bool,
    showSettingsBreadCrumb: PropTypes.bool,
};

export default ZendeskBreadCrumbItems;

/**
 * Upload Component
 * Handles the file uploads
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modules
import { React, useState } from '../../../includes/exports/react';
import { Upload, Icon } from 'antd';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import * as errorMessages from '../../../includes/constants/messages/errors';
import { ACCEPTED_IMAGE_MIME_TYPES } from '../../../includes/constants';

/**
 * Upload Component Class
 * Handles the file uploads
 */
const UploadInput = props => {
    const [fileList, setFileList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    /**
     * Call back for file upload
     * This is called before the file is uploaded
     * Return false since we need to upload the file manually
     *
     * @param file  {object}    File Object
     *
     * @return {boolean}    false
     */
    const beforeUpload = file => {
        // remove any error messages
        setFileUploadErrorMessage();

        // do the file validations
        doFileValidations(file);
        return false;
    };

    /**
     * Do the file validations
     * Sends error action if any error is encountered
     *
     * @param file  {object}    File Object
     *
     * @return      {boolean}   true
     */
    const doFileValidations = file => {
        // get the values passed as props
        let acceptedFileMimeTypes = get(props, 'accept');
        let fileMaxSize = get(props, 'maxSize');
        let fileMinSize = get(props, 'minSize');
        let maxFiles = get(props, 'maxFiles', 1);
        let files = [...fileList];

        // do the validation for accepted file types
        if (acceptedFileMimeTypes) {
            let allAcceptedFileTypesData = acceptedFileMimeTypes.split(',').map(item => item.trim());
            if (!allAcceptedFileTypesData.includes(file.type)) {
                setFileUploadErrorMessage(errorMessages.FILE_UPLOAD_FORMAT_NOT_SUPPORTED_ERROR);
                return false;
            }
        }

        // do the validation for accepted file max size
        if (fileMaxSize) {
            if (file.size / 1024 / 1024 < fileMaxSize) {
                setFileUploadErrorMessage(errorMessages.FILE_UPLOAD_MAX_FILE_SIZE_ERROR);
                return false;
            }
        }

        // do the validation for accepted file min size
        if (fileMinSize) {
            if (file.size / 1024 / 1024 > fileMinSize) {
                setFileUploadErrorMessage(errorMessages.FILE_UPLOAD_MIN_FILE_SIZE_ERROR);
                return false;
            }
        }

        // if number of files which can be uploaded is 1, we replace the old file with new file, else we do the
        // validation for number of files uploaded
        if (maxFiles === 1) {
            files = [];
        } else if (fileList.length + 1 > maxFiles) {
            setFileUploadErrorMessage(errorMessages.FILE_UPLOAD_MAX_FILES);
            return false;
        }

        // set up the file reader so that we can convert the file to base64
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            if (isImage(file)) {
                let image = new Image();
                image.src = reader.result;
                image.onload = function() {
                    if (this.width < props.minWidth && props.minWidth > 0) {
                        setFileUploadErrorMessage(
                            props.t('appCommonErrorMessages.fileUploadImageMinWidth', {
                                minWidth: props.minWidth,
                                width: this.width,
                            })
                        );
                        return false;
                    }

                    if (this.height < props.minHeight && props.minHeight > 0) {
                        setFileUploadErrorMessage(
                            props.t('appCommonErrorMessages.fileUploadImageMinHeight', {
                                minHeight: props.minHeight,
                                height: this.height,
                            })
                        );
                        return false;
                    }

                    file.thumbUrl = reader.result;
                    file.status = 'done';
                    const newList = [...files, file];
                    setFileList(newList);
                    props.onChange(newList);
                };
            } else {
                file.thumbUrl = reader.result;
                file.status = 'done';
                const newList = [...files, file];
                setFileList(newList);
                props.onChange(newList);
            }
        };

        return true;
    };

    /**
     * Call back for file remove
     * This is called when the file is removed
     *
     * @param file  {object}    File Object
     *
     * @return      {object}    File List
     */
    const onRemove = file => {
        // update the state with new file list
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);

        //call the on change of the input so that we can add this to redux form values
        props.onChange(newFileList);

        setFileList(newFileList);
        props.onRemove && props.onRemove();
    };

    /**
     * Check if uploaded file is an image
     *
     * @param   {object}    file    File Object
     */
    const isImage = file => {
        return ACCEPTED_IMAGE_MIME_TYPES.includes(file.type);
    };

    /**
     * Send the file upload error action if we encounter any errors during upload
     *
     * @param   {string}    message    Error Message
     */
    const setFileUploadErrorMessage = (message = '') => {
        setErrorMessage(message);
    };

    /**
     * Render the component
     *
     * @return {*}
     */
    // set the upload props
    const uploadProps = {
        accept: props.accept,
        beforeUpload: beforeUpload,
        fileList: fileList,
        listType: props.listType,
        onRemove: onRemove,
    };

    return (
        <>
            <Upload.Dragger name="files" {...uploadProps}>
                <p className="ant-upload-drag-icon">
                    {props.icon ? <Icon type={props.icon} /> : <Icon type="upload" />}
                </p>
                <p className="ant-upload-text">{props.t('sharedMessages.upload.tipMessage')}</p>
            </Upload.Dragger>
            {errorMessage ? (
                <div className="has-error">
                    <p className="ant-form-explain">{errorMessage}</p>
                </div>
            ) : (
                ''
            )}
            <div className="tip-message">
                <span>
                    {get(props, 'acceptExtensions')
                        ? props.t('appCommonMessages.allowedFileExtensions', {
                              extensions: get(props, 'acceptExtensions').join(',  '),
                          })
                        : ''}
                </span>
                <span>
                    {get(props, 'minWidth') > 0 || get(props, 'minHeight') > 0
                        ? props.t('appCommonMessages.imageMinWidthHeight', {
                              minHeight: get(props, 'minHeight'),
                              minWidth: get(props, 'minWidth'),
                          })
                        : ''}
                </span>
            </div>
        </>
    );
};

// default props
UploadInput.defaultProps = {
    minHeight: 0,
    minWidth: 0,
};

//define the proptypes
UploadInput.propTypes = {
    accept: PropTypes.string,
    acceptExtensions: PropTypes.array,
    icon: PropTypes.string,
    listType: PropTypes.string,
    minHeight: PropTypes.number,
    minWidth: PropTypes.number,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    t: PropTypes.func,
};

// connect the component to the store
export default withTranslation()(UploadInput);

/**
 * Defines error messages used across the app
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import translation module to translate error messages based on language selection
import i18n from '../../i18n';

// A
export const ACCOUNT_EDIT_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.accountEditDefaultError');
export const ADD_PLACEHOLDER_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.addPlaceholderDefaultError');

// B
export const BANK_ACCOUNT_DETAILS_UPDATE_DEFAULT_ERROR = i18n.t(
    'appCommonErrorMessages.bankAccountDetailsUpdateDefaultError'
);
// C
export const CHANGE_CAMPAIGN_STATUS_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.changeCampaignStatusDefaultError');
export const CHANGE_USER_STATUS_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.changeUserStatusDefaultError');
export const CONTACT_ADD_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.contactAddDefaultError');
export const CONTACT_ARCHIVE_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.contactArchiveDefaultError');
export const CONTACT_DELETE_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.contactDeleteDefaultError');
export const CONTACT_EDIT_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.contactAddDefaultError');
export const CONTACT_RESTORE_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.contactRestoreDefaultError');
export const CREATE_ORDER_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.createOrderError');
export const CREATE_SUBSCRIPTION_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.createSubscriptionDefaultError');

// D
export const DELETE_PLACEHOLDER_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.deletePlaceholderDefaultError');
export const DELETE_TEMPLATE_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.deleteTemplateDefaultError');

// E
export const EVENTS_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.eventsDefaultUser');
export const EVENTS_CRUD_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.eventsCrudDefaultUser');
export const EVENTS_DELETE_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.eventsDeleteDefaultUser');

// F
export const FAILED_TO_ADD_USER = i18n.t('appCommonErrorMessages.failedToAddUser');
export const FAT_ZEBRA_VERIFICATION_TOKEN_ERROR_MESSAGE = i18n.t(
    'appCommonErrorMessages.fatZebraVerificationTokenDefaultError'
);
export const FILE_UPLOAD_FORMAT_NOT_SUPPORTED_ERROR = i18n.t('appCommonErrorMessages.fileUploadFormatNotSupported');
export const FILE_UPLOAD_IMAGE_MIN_HEIGHT = i18n.t('appCommonErrorMessages.fileUploadImageMinHeight');
export const FILE_UPLOAD_IMAGE_MIN_WIDTH = i18n.t('appCommonErrorMessages.fileUploadImageMinWidth');
export const FILE_UPLOAD_MAX_FILE_SIZE_ERROR = i18n.t('appCommonErrorMessages.fileUploadFormatNotSupported');
export const FILE_UPLOAD_MAX_FILES = i18n.t('appCommonErrorMessages.fileUploadFormatNotSupported');
export const FILE_UPLOAD_MIN_FILE_SIZE_ERROR = i18n.t('appCommonErrorMessages.fileUploadFormatNotSupported');

// G
export const GET_CAMPAIGN_DAYS_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.getCampaignDaysDefaultError');
export const GET_CAMPAIGN_DETAILS_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.getCampaignDetailsDefaultError');
export const GET_CAMPAIGNS_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.getCampaignsDefaultError');
export const GET_INITIAL_DATA_FAILED = i18n.t('appCommonErrorMessages.initialDataRetrievalFailed');
export const GET_SOFTWARE_AUTHORIZATION_URL_DEFAULT_ERROR = i18n.t(
    'appCommonErrorMessages.getAccountingSoftwareAuthorizationUrlDefaultError'
);
export const GET_TEMPLATE_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.getTemplateDefaultError');
export const GET_USER_DETAILS_FAILED = i18n.t('appCommonErrorMessages.userDetailsRetrievalFailed');

// I
export const IMPORT_TEMPLATE_DOWNLOAD_LINK_ERROR = i18n.t(
    'appCommonErrorMessages.importTemplateDownloadLinkDefaultError'
);
export const INVOICE_ADD_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.invoiceAddDefaultError');
export const INVOICE_PAYMENT_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.invoicePaymentDefaultError');
export const INVOICE_PAYMENT_UPDATE_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.invoicePaymentUpdateDefaultError');
export const INVOICE_UPDATE_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.invoiceUpdateDefaultError');

// O
export const ORDER_DOWNLOAD_INVOICE_DEFAULT_ERROR_MESSAGE = i18n.t(
    'appCommonErrorMessages.orderDownloadInvoiceDefaultError'
);
export const ORDER_CREATE_ERROR_MESSAGE = i18n.t('appCommonErrorMessages.orderCreateDefaultError');
export const ORDER_DETAILS_DEFAULT_ERROR_MESSAGE = i18n.t('appCommonErrorMessages.orderDetailsDefaultError');
export const ORDER_RETRY_ERROR_MESSAGE = i18n.t('appCommonErrorMessages.orderRetryDefaultError');
export const ORDER_STATUS_DEFAULT_ERROR_MESSAGE = i18n.t('appCommonErrorMessages.orderStatusDefaultError');
export const ORDER_UPDATE_ERROR_MESSAGE = i18n.t('appCommonErrorMessages.orderUpdateDefaultError');
export const ORDERS_ERROR_MESSAGE = i18n.t('appCommonErrorMessages.ordersDefaultError');
export const ORGANISATION_DELETE_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.organisationDeleteDefaultError');
export const ORGANISATION_SETTINGS_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.organisationSettingsUpdateFailed');

// P
export const PAYMENT_ACCOUNT_SAVE_ERROR_MESSAGE = i18n.t('appCommonErrorMessages.paymentAccountSaveDefaultError');
export const PAYMENT_BANK_ACCOUNT_SAVE_ERROR = i18n.t('appCommonErrorMessages.paymentBankAccountSaveDefaultError');
export const PAYMENT_GATEWAY_SET_DEFAULT_ERROR_MESSAGE = i18n.t(
    'appCommonErrorMessages.paymentGatewaySetDefaultDefaultError'
);
// R
export const RESEND_ACTIVATION_EMAIL_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.resendActivationEmailDefaultError');
export const RESEND_VERIFICATION_EMAIL_DEFAULT_ERROR = i18n.t(
    'appCommonErrorMessages.resendVerificationEmailDefaultError'
);
export const RESEND_VERIFICATION_SMS_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.resendVerificationSmsDefaultError');
export const RESET_TWO_FACTOR_AUTHENTICATION_DEFAULT_ERROR = i18n.t(
    'appCommonErrorMessages.resetTwoAFactorAuthenticationDefaultError'
);

// S
export const SEND_ON_DEMAND_CAMPAIGN_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.sendOnDemandCampaignDefaultError');
export const SEND_REMINDER_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.sendReminderDefaultError');
export const SUBSCRIPTION_PLAN_ADD_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.subscriptionPlanAddDefaultError');
export const SUBSCRIPTION_PLAN_EDIT_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.subscriptionPlanEditDefaultError');
export const SYNC_ORGANISATION_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.syncOrganisationDefaultError');

// T
export const TABLE_NO_ACCOUNTS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noAccountsFound');
export const TABLE_NO_AUDIT_LOGS_FOUND = i18n.t('appCommonErrorMessages.noAuditLogsFound');
export const TABLE_NO_CAMPAIGN_NOTIFICATIONS_FOUND_MESSAGE = i18n.t(
    'appCommonErrorMessages.noCampaignNotificationsFound'
);
export const TABLE_NO_CAMPAIGNS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noCampaignsFound');
export const TABLE_NO_CHART_OF_ACCOUNTS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noChartOfAccountsFound');
export const TABLE_NO_CHART_OF_ACCOUNT_TRANSACTIONS_FOUND_MESSAGE = i18n.t(
    'appCommonErrorMessages.noChartOfAccountTransactionsFound'
);
export const TABLE_NO_CONTACTS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noContactsFound');
export const TABLE_NO_CONTACT_MANAGERS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noContactManagersFound');
export const TABLE_NO_CONTACT_TYPES_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noContactTypesFound');
export const TABLE_NO_COUPONS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noCouponsFound');
export const TABLE_NO_DEBTOR_ACCOUNTS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noDebtorAccountsFound');
export const TABLE_NO_DUE_INVOICES_MESSAGE = i18n.t('appCommonErrorMessages.noDueInvoices');
export const TABLE_NO_INVENTORY_ITEM_TRANSACTIONS_FOUND_MESSAGE = i18n.t(
    'appCommonErrorMessages.noInventoryItemTransactionsFound'
);
export const TABLE_NO_INVENTORY_ITEMS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noInventoryItemsFound');
export const TABLE_NO_INVOICE_PAYMENTS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noInvoicePaymentsFound');
export const TABLE_NO_INVOICES_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noInvoicesFound');
export const TABLE_NO_LOGS_FOUND = i18n.t('appCommonErrorMessages.noLogsFound');
export const TABLE_NO_NOTES_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noNotesFound');
export const TABLE_NO_NOTIFICATIONS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noNotificationsFound');
export const TABLE_NO_ORDERS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noOrdersFound');
export const TABLE_NO_ORGANISATION_CURRENCIES_FOUND_MESSAGE = i18n.t(
    'appCommonErrorMessages.noOrganisationCurrenciesFound'
);
export const TABLE_NO_ORGANISATIONS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noOrganisationsFound');
export const TABLE_NO_PAYMENT_ARRANGEMENTS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noPaymentArrangementsFound');
export const TABLE_NO_PAYMENT_ARRANGEMENT_INSTALLMENTS_FOUND_MESSAGE = i18n.t(
    'appCommonErrorMessages.noPaymentArrangementInstallmentsFound'
);
export const TABLE_NO_PAYMENT_ARRANGEMENT_PENDING_INSTALLMENTS_FOUND_MESSAGE = i18n.t(
    'appCommonErrorMessages.noPaymentArrangementPendingInstallmentsFound'
);
export const TABLE_NO_PAYMENT_EXTENSIONS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noPaymentExtensionsFound');
export const TABLE_NO_PAYMENT_PLANS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noPaymentPlansFound');
export const TABLE_NO_PAYMENT_GATEWAYS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noPaymentGatewaysFound');
export const TABLE_NO_PLACEHOLDERS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noPlaceholdersFound');
export const TABLE_NO_PLAN_ACCOUNTS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noPlanAccountsFound');
export const TABLE_NO_PLAN_QUOTES_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noPlanQuotesFound');
export const TABLE_NO_PLANS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noPlansFound');
export const TABLE_NO_TAXES_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noTaxesFound');
export const TABLE_NO_TEMPLATES_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noTemplatesFound');
export const TABLE_NO_USERS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noUsersFound');
export const TABLE_NO_ZENDESK_TICKETS_FOUND_MESSAGE = i18n.t('appCommonErrorMessages.noZendeskTicketsFound');
export const TRY_DEMO_COMPANY_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.tryDemoCompanyDefaultError');

// U
export const UNKNOWN_ERROR = i18n.t('appCommonErrorMessages.unknownError');
export const UPDATE_CAMPAIGN_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.updateCampaignDefaultError');
export const UPDATE_ORDER_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.updateOrderError');
export const UPDATE_PLACEHOLDER_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.updatePlaceholderDefaultError');
export const USER_CHANGE_PASSWORD_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.userChangePasswordDefaultError');
export const USER_FORGOT_PASSWORD_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.forgotPasswordDefaultError');
export const USER_LOG_OUT_FAILED_ERROR = i18n.t('appCommonErrorMessages.userLogOutFailedError');
export const USER_LOGIN_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.userLoginDefaultError');
export const USER_PROFILE_UPDATE_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.userProfileUpdateDefaultError');
export const USER_REGISTRATION_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.userRegistrationDefaultError');
export const USER_REGISTRATION_MEETING_NOT_SCHEDULED_ERROR = i18n.t(
    'appCommonErrorMessages.userRegistrationMeetingNotScheduledError'
);
export const USER_RESET_PASSWORD_FAILURE = i18n.t('appCommonErrorMessages.resetPasswordError');
export const USER_SESSION_TIMED_OUT = i18n.t('appCommonErrorMessages.userSessionTimedOutError');
export const UPDATE_TEMPLATE_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.updateTemplateDefaultError');

// V
export const VERIFY_PHONE_NUMBER_DEFAULT_ERROR = i18n.t('appCommonErrorMessages.verifyPhoneNumberDefaultError');
export const VERIFY_SOFTWARE_AUTHORIZATION_DEFAULT_ERROR = i18n.t(
    'appCommonErrorMessages.verifyAccountingSoftwareAuthorizationError'
);

/**
 * Activate Account Component
 * Activate Account for debt collector account
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

// import required modules
import { React, connect, useEffect } from '../../../includes/exports/react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import ActivateAccountForm from '../forms/ActivateAccountForm';
import { activateAccount } from '../../../includes/redux/actions/shared/user';
import { buildQueryParamsObject, redirect } from '../../../includes/utils';
import './styles.scss';

/**
 * Activate Account Component
 */
const ActivateAccount = props => {
    useEffect(() => {
        const urlParams = getFormInitialValues();
        (!urlParams.user_id || !urlParams.activation_code) && redirect('/');
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getFormInitialValues = () => {
        let urlParams = buildQueryParamsObject(props.history.location.search);
        return {
            user_id: urlParams.uuid,
            activation_code: urlParams.activation_code,
        };
    };
    /**
     * Handle the activate account form submit
     * Dispatches the activate account form submit action
     *
     * @param   {object}    values   Array of form values
     */
    const handleSubmit = values => {
        props.activateAccount(values);
    };

    return (
        <section>
            <div className="home-left-wrapper">
                <img src={require('../../../assets/images/reset-password-bg.png')} alt="reset-password" />
                <p className="home-left-intro-text">
                    Paycepaid is a <b>powerhouse</b> of tools right in <br />
                    your <b>pocket,</b> arming your business with <br />
                    everything it needs to completely automate your <br />
                    <span className="text-highlight">invoice-to-cash process.</span>
                </p>
            </div>
            <div>
                <ActivateAccountForm
                    onSubmit={handleSubmit}
                    initialValues={getFormInitialValues()}
                    loading={props.loading}
                    {...props}
                />
            </div>
        </section>
    );
};

/**
 * Prop Types
 */
ActivateAccount.propTypes = {
    history: PropTypes.object,
    loading: PropTypes.bool,
    activateAccount: PropTypes.func,
    t: PropTypes.func,
};

// connect the component to the store
export default connect(
    state => ({
        ...state.user,
    }),
    {
        activateAccount,
    }
)(withTranslation()(ActivateAccount));

import { Modal, Tabs } from 'antd';
import { find, intersection } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'components/Loader';
import { PAYMENT_SERVICE } from 'components/PaymentGateways/constants';
import { PAYMENT_GATEWAY, PAYMENT_METHOD_TYPE_ICON_MAP } from 'includes/constants';
import useInternalPaymentGateways from 'includes/hooks/useInternalPaymentGateways';
import usePaymentMethodTypes from 'includes/hooks/usePaymentMethodTypes';
import AddCardUsingStripe from '../AddCardUsingStripe';
import AddMandateUsingGoCardless from '../AddMandateUsingGoCardless';
import SelectButton from 'components/SelectButton';

/**
 * Add payment method modal
 *
 * Responsible for rendering payment method type selection, gateway selection if multiple are available and renders the selected payment gateway to add the payment method
 *
 * @since 2.8.0
 */
export default function AddPaymentMethodModal({ onCancel, open }) {
    const [selectedPaymentMethodType, setSelectedPaymentMethodType] = useState(null);

    const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(null);

    const { data: _internalPaymentGateways } = useInternalPaymentGateways();

    /**
     * Filter out payment gateways that require reauthorization
     */
    const internalPaymentGateways = useMemo(() => {
        return _internalPaymentGateways.filter(pg => !pg.reauthorize);
    }, [_internalPaymentGateways]);

    const { t } = useTranslation();

    const { data: paymentMethodTypes, isLoading: isPaymentMethodTypesLoading } = usePaymentMethodTypes();

    const handlePaymentMethodTypeChange = paymentMethodTypeId => {
        const selectedPaymentMethodType = find(paymentMethodTypes, { id: paymentMethodTypeId });
        setSelectedPaymentMethodType(selectedPaymentMethodType ?? null);
    };

    const supportedPaymentGateways = useMemo(() => {
        if (!selectedPaymentMethodType || internalPaymentGateways.length === 0) return null;

        const supportedPaymentGatewayIdsForPaymentMethod = selectedPaymentMethodType.supported_payment_gateways;

        const internalPaymentGatewayIds = internalPaymentGateways.map(pg => pg.id);

        const supportedPaymentGatewayIds = Array.from(
            intersection(internalPaymentGatewayIds, supportedPaymentGatewayIdsForPaymentMethod)
        );

        return supportedPaymentGatewayIds.map(id => find(internalPaymentGateways, { id }));
    }, [selectedPaymentMethodType]);

    useEffect(() => {
        if (supportedPaymentGateways?.length >= 1) setSelectedPaymentGateway(supportedPaymentGateways[0]);
    }, [supportedPaymentGateways]);

    const renderPaymentGatewayForm = paymentGatewaySlug => {
        if (!paymentGatewaySlug)
            return (
                <div className="flex flex-col-0 justify-center items-center h-full">
                    <div className="flex-grow-0">
                        {t('customerMessages.paymentMethod.info.pleaseSelectPaymentGateway')}
                    </div>
                </div>
            );

        switch (paymentGatewaySlug) {
            case PAYMENT_GATEWAY.STRIPE:
                return <AddCardUsingStripe onClose={onCancel} paymentMethodTypeId={selectedPaymentMethodType?.id} />;
            case PAYMENT_GATEWAY.GOCARDLESS:
                return (
                    <AddMandateUsingGoCardless paymentMethodTypeId={selectedPaymentMethodType?.id} onClose={onCancel} />
                );
        }
    };

    const renderAddPaymentMethodForm = () => {
        if (!selectedPaymentMethodType) {
            return null;
        }

        if (supportedPaymentGateways.length === 0)
            return (
                <div className="flex flex-col-0 justify-center items-center h-full">
                    <div className="flex-grow-0">
                        {t('customerMessages.paymentMethod.info.noSupportedPaymentGateway')}
                    </div>
                </div>
            );

        if (supportedPaymentGateways.length === 1) return renderPaymentGatewayForm(supportedPaymentGateways[0].slug);

        return (
            <div className="grid sm:grid-cols-3 gap-4">
                <div className="rounded-lg w-full overflow-x-scroll sm:overflow-x-visible col-span-2 sm:col-span-1 py-5 px-3">
                    <div className="flex flex-row w-full sm:flex-col gap-2">
                        {supportedPaymentGateways.map(pg => (
                            <SelectButton
                                key={pg.id}
                                isSelected={selectedPaymentGateway?.id === pg.id}
                                onClick={() => setSelectedPaymentGateway(pg)}
                            >
                                <div className="flex flex-row items-center gap-2 ">
                                    <img
                                        src={PAYMENT_SERVICE?.[pg.slug]?.logo}
                                        style={{ height: 30, width: 30 }}
                                        alt={pg.label}
                                    />
                                    <div className="font-sans text-bold">{`Set up ${selectedPaymentMethodType?.method_type}`}</div>
                                </div>
                            </SelectButton>
                        ))}
                    </div>
                </div>
                <div className="col-span-2">{renderPaymentGatewayForm(selectedPaymentGateway?.slug)}</div>
            </div>
        );
    };

    const renderModalContent = () => {
        if (isPaymentMethodTypesLoading || paymentMethodTypes?.length === 0) return <Loader />;

        return (
            <Tabs activeKey={selectedPaymentMethodType?.id} onChange={val => handlePaymentMethodTypeChange(val)}>
                {paymentMethodTypes.map(type => (
                    <Tabs.TabPane
                        key={type.id}
                        tab={
                            <div className="flex flex-row gap-2 justify-start after:content-none">
                                <img src={PAYMENT_METHOD_TYPE_ICON_MAP[type.slug]} alt="" style={{ width: 20 }} />
                                <div>{type.method_type}</div>
                            </div>
                        }
                    >
                        {renderAddPaymentMethodForm()}{' '}
                    </Tabs.TabPane>
                ))}
            </Tabs>
        );
    };

    // set first payment method as the default open one
    useEffect(() => {
        if (open && paymentMethodTypes.length > 0) setSelectedPaymentMethodType(paymentMethodTypes[0]);
    }, [open, paymentMethodTypes]);

    return (
        <Modal
            visible={open}
            title={t('customerMessages.paymentMethod.addPaymentMethod.title')}
            footer={null}
            onCancel={onCancel}
            width={900}
        >
            {renderModalContent()}
        </Modal>
    );
}

AddPaymentMethodModal.propTypes = {
    onCancel: PropTypes.func,
    open: PropTypes.bool,
};

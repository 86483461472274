/**
 * Redux accounts actions
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

import * as actionTypes from '../../../constants/actionTypes';

/**
 * Add coupon to accounts
 */
export const addCouponToAccount = (payload, callback) => ({
    payload: payload,
    type: actionTypes.ADD_COUPON_TO_ACCOUNT_REQUEST,
    callback,
});

/**
 * Get account coupons action
 *
 * @param   {string}    accountId         order ID
 */
export const getAccountCoupons = accountId => ({
    type: actionTypes.GET_ACCOUNT_COUPONS_REQUEST,
    payload: {
        account_id: accountId,
        page_size: 0,
    },
});

/**
 * Get Account details action
 *
 * @returns {object}            Account Details action
 */
export const getAccountDetails = accountId => ({
    type: actionTypes.GET_ACCOUNT_DETAILS_REQUEST,
    payload: {
        account_id: accountId,
    },
});

/**
 * Get accounts types data
 *
 * @returns {object}    Get account types listing action
 */
export const getAccountTypes = () => ({
    type: actionTypes.GET_ACCOUNT_TYPES_REQUEST,
    payload: { page_size: 0 },
});

/**
 * Get accounts data
 *
 * @returns {object}    Get account listing action
 */
export const getAccounts = searchParams => ({
    type: actionTypes.GET_ACCOUNTS_REQUEST,
    payload: { searchParams },
});

/**
 * Get account coupons data
 *
 * @returns {object}    Get account coupons listing action
 */
export const getCouponAccounts = (couponId, searchParams) => ({
    type: actionTypes.GET_COUPON_ACCOUNTS_REQUEST,
    payload: {
        coupon_id: couponId,
        searchParams,
    },
});

/**
 * Get coupons data
 *
 * @returns {object}    Get account listing action
 */
export const getCoupons = searchParams => ({
    type: actionTypes.GET_COUPONS_REQUEST,
    payload: { searchParams },
});

/**
 * remove coupon from accounts
 */
export const removeCouponFromAccount = payload => ({
    payload: payload,
    type: actionTypes.REMOVE_COUPON_FROM_ACCOUNT_REQUEST,
});

/**
 * Reset user Account details action
 *
 * @returns {object}  Reset user Account Details action
 */
export const resetUserAccountDetails = () => ({
    type: actionTypes.RESET_USER_ACCOUNT_DETAILS_REQUEST,
});

/**
 * Send Test Email
 *
 * @param {string} email Email ID
 * @param {string} accountId Account ID
 * @param {string} organisationId Organisation Id
 */
export const sendTestEmail = (email, accountId, organisationId) => ({
    payload: { email, accountId, organisationId },
    type: actionTypes.SEND_TEST_EMAIL_REQUEST,
});

/**
 * Set User Account Id
 *
 * @param {string} accountId Account Id
 */
export const setUserAccountId = accountId => ({
    accountId,
    type: actionTypes.SET_USER_ACCOUNT_ID,
});

/**
 * Try Demo Company action
 *
 * @param {string} accountId Account Id
 * @param {boolean} try_now Try now
 * @param {boolean} later Later
 * @param {Function} loadingModal Loading Modal function
 * @param {string} subscriptionExpiresIn Subscription Expires in
 * @param {boolean} callApi Whether to call api or not. Default true
 *
 * @returns {object} Try Demo Company action
 */
export const tryDemoCompany = (
    accountId,
    try_now = false,
    later = false,
    loadingModal,
    subscriptionExpiresIn = '',
    callApi = true
) => ({
    accountId,
    payload: {
        try_now,
        later,
    },
    subscriptionExpiresIn,
    loadingModal,
    callApi,
    type: actionTypes.TRY_DEMO_COMPANY_REQUEST,
});

/**
 * Update Account details action
 *
 * @returns {object}            Account Update action
 */
export const updateAccountDetails = (accountId, accountDetails) => ({
    type: actionTypes.UPDATE_ACCOUNT_DETAILS_REQUEST,
    payload: {
        accountId,
        accountDetails,
    },
});

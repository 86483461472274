/**
 * Campaign notifications listing Component.
 *
 * @since 2.0.0
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import { CAMPAIGN } from 'includes/constants/permissions';
import { CAMPAIGN_ID, ID } from 'includes/constants/keys/request';
import { campaignNotificationsColumns } from 'includes/tableColumns/campaignNotifications';
import { find, get } from 'lodash';
import { Button as AntdButton, Dropdown, Icon, Menu, Modal } from 'antd';
import { TABLE_NO_CAMPAIGN_NOTIFICATIONS_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    addCampaignNotification,
    deleteCampaignNotification,
    getCampaignNotifications,
    updateCampaignNotification,
} from 'includes/slices/campaigns';
import Button from 'components/Button';
import CampaignNotificationAddEditForm from 'pages/Campaigns/Details/components/Notifications/List/components/Form/AddEdit';
import InfoMessage from 'components/shared/messages/InfoMessage';
import PermissionGuard from 'components/PermissionGuard';
import PropTypes from 'prop-types';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import useCampaignNotificationDays from 'includes/hooks/useCampaignNotificationDays';
import useCommunicationTemplateTypes from 'includes/hooks/useCommunicationTemplateTypes';
import useCommunicationTypes from 'includes/hooks/useCommunicationTypes';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useCampaignNotificationDayDescription from 'includes/hooks/useCampaignNotificationDayDescription';

const { confirm } = Modal;

/**
 * Campaign notifications listing component
 *
 * @since 2.0.0
 */
const CampaignNotificationsList = props => {
    const [campaignNotificationDetails, setCampaignNotificationDetails] = useState();
    const [isSearching, setIsSearching] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const { data: communicationTypes, isLoading: loadingCommunicationTypes } = useCommunicationTypes();
    const { t } = useTranslation();
    const {
        data: campaignNotificationDays,
        isLoading: loadingCampaignNotificationDays,
    } = useCampaignNotificationDays();
    const getCampaignNotificationDayDescription = useCampaignNotificationDayDescription();
    const {
        data: communicationTemplateTypes,
        isLoading: loadingCommunicationTemplateTypes,
    } = useCommunicationTemplateTypes();
    const campaign = useSelector(state => state.campaigns.campaign);
    const campaignNotifications = useSelector(state => state.campaigns.campaignNotifications);
    const campaignNotificationsPagination = useSelector(state => state.campaigns.campaignNotificationsPagination);
    const dispatch = useDispatch();
    const loadingCampaignNotificationDelete = useSelector(state => state.campaigns.loadingCampaignNotificationDelete);
    const loadingCampaignNotifications = useSelector(state => state.campaigns.loadingCampaignNotifications);
    const organisationId = useOrganisationId();

    /**
     * Close Pay in full Form modal
     */
    const closeEditModal = () => {
        setOpenEditModal(false);
        setCampaignNotificationDetails('');
    };

    /**
     * Delete campaign notification
     *
     * @param {string} campaignNotificationId Id of the campaign notification to be deleted.
     *
     * @since 2.0.0
     */
    const deleteCampaignNotificationById = campaignNotificationId => {
        confirm({
            title: t('messages.campaigns.campaignNotifications.confirm.delete.title'),
            content: '',
            onOk: () => {
                dispatch(deleteCampaignNotification(organisationId, props.campaignId, campaignNotificationId));
            },
        });
    };

    /**
     * Render actions menus
     *
     * @param {object} campaignNotification Campaign notification
     */
    const renderActionMenus = campaignNotification => (
        <Menu>
            <Menu.Item>
                <PermissionGuard requiredPermission={CAMPAIGN.VIEW}>
                    <span
                        onClick={() => {
                            setCampaignNotificationDetails(campaignNotification);
                            setOpenEditModal(true);
                        }}
                    >
                        {getLocaleText('crud.edit')}
                    </span>
                </PermissionGuard>
            </Menu.Item>
            <Menu.Item>
                <PermissionGuard requiredPermission={CAMPAIGN.DELETE}>
                    <span onClick={() => deleteCampaignNotificationById(campaignNotification.id)}>
                        {getLocaleText('crud.delete')}
                    </span>
                </PermissionGuard>
            </Menu.Item>
        </Menu>
    );

    /**
     * Format the table data
     *
     * @since 2.0.0
     */
    const getFormattedTableData = useCallback(
        () =>
            campaignNotifications.map(campaignNotification => {
                const notificationType = find(communicationTypes, { id: campaignNotification.notification_type_id });
                return {
                    ...campaignNotification,
                    day_id: getCampaignNotificationDayDescription(
                        find(campaignNotificationDays, { id: campaignNotification.day_id }),
                        '',
                        props.additionalCampaignSuffixText
                    ),
                    notification_type_id: (
                        <div className="tag-linker table-button-wrapper">
                            <button className={notificationType?.slug}>{notificationType?.type}</button>
                        </div>
                    ),
                    communication_template_type_id: get(
                        find(communicationTemplateTypes, { id: campaignNotification.communication_template_type_id }),
                        'type'
                    ),
                    is_active: t(`messages.${campaignNotification.is_active ? 'active' : 'inactive'}`),
                    actions: (
                        <div className="blue-bg-button">
                            <Dropdown overlay={renderActionMenus(campaignNotification)}>
                                <AntdButton style={{ borderRadius: 0 }}>
                                    {getLocaleText('crud.options')}
                                    <Icon type="ellipsis" />
                                </AntdButton>
                            </Dropdown>
                        </div>
                    ),
                };
            }),
        [campaign, campaignNotifications, campaignNotificationDays, communicationTemplateTypes, communicationTypes] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Get the locale text
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @since 2.0.0
     *
     * @returns {string} Locale test
     */
    const getLocaleText = path => t(`messages.campaigns.campaignNotifications.${path}`);

    /**
     * Handle the campaign notification form submit
     *
     * @param {object} values Form values
     *
     * @since 2.0.0
     */
    const handleCampaignNotificationSubmit = values => {
        values[CAMPAIGN_ID] = props.campaignId;
        if (campaignNotificationDetails?.id) {
            values[ID] = campaignNotificationDetails?.id;
            dispatch(updateCampaignNotification(organisationId, props.campaignId, values[ID], values, closeEditModal));
        } else {
            dispatch(addCampaignNotification(organisationId, props.campaignId, values, closeEditModal));
        }
    };
    /**
     * Get memoized campaign notifications table data
     *
     * @since 2.0.0
     */
    const memoizedCampaignNotificationsData = useMemo(() => getFormattedTableData(), [getFormattedTableData]);

    return (
        <div className="home-content-wrapper contacts-wrapper">
            <div className="full-wrapper box-wrapper">
                <section className="right-menu-wrapper">
                    <div className="right-align">
                        <PermissionGuard requiredPermission={CAMPAIGN.ADD}>
                            <Button
                                big
                                filled
                                onClick={() => {
                                    setCampaignNotificationDetails('');
                                    setOpenEditModal(true);
                                }}
                            >
                                {getLocaleText('crud.add')}
                            </Button>
                        </PermissionGuard>
                    </div>
                </section>
                <section>
                    <ul className="selector-field">
                        <li>
                            <div className="right-align">
                                <SearchBlock
                                    dataValues={[organisationId, props.campaignId]}
                                    getDataMethod={getCampaignNotifications}
                                    isSearching={isSearching}
                                    placeholder={getLocaleText(`search.placeholder`)}
                                    setIsSearching={setIsSearching}
                                    setSearchParam={setSearchParam}
                                    dispatchMethod
                                />
                            </div>
                        </li>
                    </ul>
                </section>
                <hr />
                <Table
                    columns={campaignNotificationsColumns}
                    dataSource={memoizedCampaignNotificationsData}
                    dataValues={[organisationId, props.campaignId]}
                    getDataMethod={getCampaignNotifications}
                    isSearching={isSearching}
                    loading={
                        loadingCampaignNotifications ||
                        loadingCommunicationTemplateTypes ||
                        loadingCampaignNotificationDays ||
                        loadingCommunicationTypes
                    }
                    localeMessage={TABLE_NO_CAMPAIGN_NOTIFICATIONS_FOUND_MESSAGE}
                    paginationData={campaignNotificationsPagination}
                    rowClassName="pointer"
                    rowKey="id"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    size="middle"
                    className="table-1200"
                    mobileClassName="table-1200"
                    dispatchMethod
                />
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={loadingCampaignNotificationDelete}
                >
                    <InfoMessage
                        message={t(`messages.campaigns.campaignNotifications.modal.message.delete`)}
                        showLoader={true}
                    />
                </Modal>
                <Modal
                    footer={[]}
                    className="custom-form-modal text-left on-demand-campaign-modal"
                    visible={openEditModal}
                    onCancel={closeEditModal}
                    destroyOnClose
                >
                    <CampaignNotificationAddEditForm
                        initialValues={
                            campaignNotificationDetails?.id ? campaignNotificationDetails : { is_active: true }
                        }
                        onSubmit={handleCampaignNotificationSubmit}
                        isAdd={campaignNotificationDetails?.id ? false : true}
                        campaignType={campaign?.campaign_type}
                        getAdditionalDayDescriptionText={props.getAdditionalDayDescriptionText}
                    />
                </Modal>
            </div>
        </div>
    );
};

CampaignNotificationsList.propTypes = {
    additionalCampaignSuffixText: PropTypes.string,
    campaignId: PropTypes.string,
    getAdditionalDayDescriptionText: PropTypes.func,
    t: PropTypes.func,
};

export default CampaignNotificationsList;

/**
 * App Header Component
 * Handles the app header view and functions to loading header
 * This component is a shared component which can be used in different modules
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import the required modules
import { React, connect, Link } from '../../../../includes/exports/react';
import { withTranslation } from 'react-i18next';

import Logout from '../../Logout';
import Button from '../../Button';
import { PropTypes } from 'prop-types';

/**
 * App Header Component
 */
const AppHeader = props => {
    /**
     * Render the header
     *
     * @returns    {string}     Rendered content
     */
    const { token } = props;
    return (
        <section className="header-signin-register-wrapper common-header-wrapper">
            <h1>
                <Link to="/" className="logo" />
            </h1>
            {token ? (
                <Logout />
            ) : (
                <Button className="sign-in header-right-content" big id="popover-button">
                    <Link to="/login">{props.t('customerMessages.header.signInText')}</Link>
                </Button>
            )}
        </section>
    );
};

/**
 * Proptypes
 */
AppHeader.propTypes = {
    t: PropTypes.func,
    token: PropTypes.string,
};

export default connect(state => ({ token: state.user.token }))(withTranslation()(AppHeader));

/**
 * Bank Account Transactions component
 *
 * @since 2.8.0
 */
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Select, Switch, Typography } from 'antd';

import './styles.scss';
import Transactions from './components/Transactions';
import AggregatedTransactions from './components/AggregatedTransactions';
import { useDispatch, useSelector } from 'react-redux';
import useBankAccounts from 'includes/hooks/useBankAccounts';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { getBankAccountTransactions } from 'includes/slices/openBanking';

const { Text } = Typography;
const { Option } = Select;

/**
 * A default `all` option to be prepended to the account filter
 *
 * @since 2.8.0
 */
const DEFAULT_ACCOUNT_OPTION = {
    id: 'all',
    account_name: 'All accounts',
    account_type: '',
};

/**
 * Component to render bank account transactions of a user
 *
 * @since 2.8.0
 */
export default function BankAccountTransactions({ contactId, paymentArrangementId }) {
    const [isAggregatedView, setIsAggregatedView] = useState(true);

    const dispatch = useDispatch();

    const organisationId = useOrganisationId();

    const { bankAccounts, isBankAccountsLoading } = useBankAccounts(organisationId, contactId, paymentArrangementId);

    const bankAccountTransactions = useSelector(state => state.openBanking.bankAccountTransactions);
    const isBankAccountTransactionsLoading = useSelector(state => state.openBanking.isBankAccountTransactionsLoading);
    const bankAccountTransactionsPagination = useSelector(state => state.openBanking.bankAccountTransactionsPagination);

    /**
     * to prepend 'all contacts' option
     *
     * @since 2.8.0
     */
    const accounts = useMemo(() => {
        if (bankAccounts) return [DEFAULT_ACCOUNT_OPTION, ...bankAccounts];

        return [DEFAULT_ACCOUNT_OPTION];
    }, [bankAccounts]);

    const [selectedAccountId, setSelectedAccountId] = useState(accounts[0].id);

    /**
     * function responsible for making get request for transactions
     *
     * @since 2.8.0
     */
    const fetchBankAccountTransactions = useCallback(
        (data = {}) => {
            dispatch(
                getBankAccountTransactions(organisationId, {
                    is_aggregate: isAggregatedView ? 'true' : '',
                    payment_arrangement_id: paymentArrangementId,
                    contact_id: contactId,
                    ...(selectedAccountId === 'all' ? {} : { bank_account_id: selectedAccountId }),
                    ...data,
                })
            );
        },
        [dispatch, organisationId, contactId, selectedAccountId, isAggregatedView, paymentArrangementId]
    );

    /**
     * Initialize fetch
     *
     * @since 2.8.0
     */
    useEffect(() => {
        fetchBankAccountTransactions();
    }, [fetchBankAccountTransactions]);

    return (
        <div className="container">
            <div className="flex md:flex-row flex-col justify-center md:justify-end gap-4 bank-account-transactions-wrapper mb-5">
                <Select
                    dropdownMatchSelectWidth={false}
                    className="account-select"
                    value={selectedAccountId}
                    onChange={accId => setSelectedAccountId(accId)}
                    disabled={isBankAccountsLoading}
                >
                    {accounts.map(acc => (
                        <Option className="account-select__option" key={acc.id} value={acc.id}>
                            <Text>{acc.account_name}</Text>
                            <Text disabled className="account-select__type">
                                {acc.account_type}
                            </Text>
                        </Option>
                    ))}
                </Select>

                <div className="flex justify-center items-center gap-1">
                    <Text>Detailed</Text>
                    <Switch checked={isAggregatedView} onChange={checked => setIsAggregatedView(checked)} />
                    <Text>Aggregated</Text>
                </div>
            </div>
            {isAggregatedView ? (
                <AggregatedTransactions
                    contactId={contactId}
                    selectedAccountId={selectedAccountId}
                    transactions={bankAccountTransactions}
                    isLoading={isBankAccountTransactionsLoading}
                    paymentArrangementId={paymentArrangementId}
                />
            ) : (
                <Transactions
                    transactions={bankAccountTransactions}
                    isLoading={isBankAccountTransactionsLoading}
                    paginationData={bankAccountTransactionsPagination}
                    fetchBankAccountTransactions={(...params) => {
                        fetchBankAccountTransactions(...params);
                    }}
                />
            )}
        </div>
    );
}

BankAccountTransactions.propTypes = {
    contactId: PropTypes.string,
    paymentArrangementId: PropTypes.string,
};

// defines the columns used in tables

// import the i18n module
import {
    INVOICE_STATUS_DRAFT_SLUG,
    INVOICE_STATUS_OPEN_SLUG,
    INVOICE_STATUS_VOIDED_SLUG,
    INVOICE_STATUS_WRITE_OFF_SLUG,
} from '..';
import i18n from '../../i18n';

export const COMMUNICATION_LOG_TABLE_COLUMNS = [
    {
        title: i18n.t('sharedMessages.communicationLogHistory.list.table.fieldName.name'),
        dataIndex: 'name',
        key: 'name',
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.communicationLogHistory.list.table.fieldName.to'),
        dataIndex: 'to',
        key: 'to',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('sharedMessages.communicationLogHistory.list.table.fieldName.type_id'),
        dataIndex: 'type_id',
        key: 'type_id',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('sharedMessages.communicationLogHistory.list.table.fieldName.notifiedDate'),
        dataIndex: 'created_date',
        key: 'created_date',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('sharedMessages.communicationLogHistory.list.table.fieldName.status'),
        dataIndex: 'status',
        key: 'status',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('sharedMessages.communicationLogHistory.list.table.fieldName.deliveredDate'),
        dataIndex: 'delivered_date',
        key: 'delivered_date',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('sharedMessages.communicationLogHistory.list.table.fieldName.openedDate'),
        dataIndex: 'last_opened_date',
        key: 'last_opened_date',
        sorter: true,
    },
    {
        title: i18n.t('sharedMessages.communicationLogHistory.list.table.fieldName.callDuration'),
        dataIndex: 'call_duration',
        key: 'call_duration',
        className: 'center-align',
        sorter: true,
    },
    {
        title: i18n.t('sharedMessages.communicationLogHistory.list.table.fieldName.sentBy'),
        dataIndex: 'sent_by',
        key: 'sent_by',
    },
    {
        title: i18n.t('sharedMessages.communicationLogHistory.list.table.fieldName.actions'),
        dataIndex: 'actions',
        key: 'actions',
        className: 'center-align',
    },
];

//CONTACT_TABLE_COLUMNS
export const getContactTableColumnProps = ({ contactTypes }) => {
    return [
        {
            title: i18n.t('customerMessages.contact.list.table.fieldName.contactName'),
            dataIndex: 'first_name',
            key: 'first_name',
            sorter: true,
            collapseHeader: true,
        },
        {
            title: i18n.t('customerMessages.contact.list.table.fieldName.businessName'),
            dataIndex: 'business_name',
            key: 'business_name',
            sorter: true,
            collapseHeader: true,
        },
        {
            title: i18n.t('customerMessages.contact.list.table.fieldName.email'),
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            collapseHeader: true,
        },
        {
            title: i18n.t('customerMessages.contact.list.table.fieldName.amountPaid'),
            dataIndex: 'amount_paid',
            key: 'amount_paid',
            className: 'center-align',
            sorter: true,
        },
        {
            title: i18n.t('customerMessages.contact.list.table.fieldName.amountDue'),
            dataIndex: 'amount_due',
            key: 'amount_due',
            className: 'center-align',
            sorter: true,
            collapseHeader: true,
            rangeFilters: [
                {
                    label: i18n.t('customerMessages.contact.list.table.fieldName.amountDueFrom'),
                    key: 'amount_due_from',
                },
                {
                    label: i18n.t('customerMessages.contact.list.table.fieldName.amountDueTo'),
                    key: 'amount_due_to',
                },
            ],
            rangeFilterType: 'number',
        },
        {
            title: i18n.t('customerMessages.contact.list.table.fieldName.daysOverdue'),
            dataIndex: 'oldest_invoice_days_overdue',
            key: 'oldest_invoice_days_overdue',
            className: 'center-align',
            sorter: true,
            rangeFilters: [
                {
                    label: i18n.t('customerMessages.contact.list.table.fieldName.daysOverdueFrom'),
                    key: 'days_overdue_from',
                },
                {
                    label: i18n.t('customerMessages.contact.list.table.fieldName.daysOverdueTo'),
                    key: 'days_overdue_to',
                },
            ],
            rangeFilterType: 'number',
        },
        {
            title: i18n.t('customerMessages.contact.list.table.fieldName.contactType'),
            dataIndex: 'type',
            key: 'type',
            sorter: true,
            className: 'select-field-short',
            filterMultiple: false,
            filters: contactTypes.map(type => ({ text: type.type, value: type.id })),
        },
        {
            title: i18n.t('customerMessages.contact.list.table.fieldName.contactStatus'),
            dataIndex: 'is_trash',
            key: 'is_trash',
            className: 'select-field-short',
            filterMultiple: false,
            filters: [
                { text: 'Active', value: '' },
                { text: 'Archived', value: 1 },
            ],
        },
    ];
};

export const USER_ROLES_TABLE_COLUMNS = [
    {
        title: i18n.t('customerMessages.userRoles.list.table.fieldName.role'),
        dataIndex: 'role',
        key: 'role',
        sorter: true,
    },
    {
        title: i18n.t('customerMessages.userRoles.list.table.fieldName.created_date'),
        dataIndex: 'created_date',
        key: 'created_date',
        sorter: true,
    },
    {
        title: i18n.t('customerMessages.userRoles.list.table.fieldName.updated_date'),
        dataIndex: 'updated_date',
        key: 'updated_date',
        sorter: true,
    },
    {
        title: i18n.t('customerMessages.userRoles.list.table.fieldName.edit'),
        dataIndex: 'edit',
        key: 'edit',
    },
];

//INVOICES_TABLE_COLUMNS
export const getInvoicesTableColumns = ({ contactTypes, invoiceStatuses, isRepeatingInvoices }) => {
    let columns = [
        {
            title: i18n.t('customerMessages.invoice.list.table.fieldName.invoiceNumber'),
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            sorter: true,
            collapseHeader: true,
        },
        {
            title: i18n.t('customerMessages.invoice.list.table.fieldName.contactName'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            collapseHeader: true,
        },
    ];

    if (isRepeatingInvoices) {
        columns = columns.concat([
            {
                title: i18n.t('customerMessages.invoice.list.table.fieldName.nextInvoiceDate'),
                dataIndex: 'next_invoice_date',
                key: 'next_invoice_date',
                // sorter: true,
                // collapseHeader: true,
            },
            {
                title: i18n.t('customerMessages.invoice.list.table.fieldName.repeats'),
                dataIndex: 'repeats',
                key: 'repeats',
            },
            {
                title: i18n.t('customerMessages.invoice.list.table.fieldName.dueOn'),
                dataIndex: 'due_on',
                key: 'due_on',
            },
            {
                title: i18n.t('customerMessages.invoice.list.table.fieldName.endDate'),
                dataIndex: 'end_date',
                key: 'end_date',
            },
        ]);
    } else {
        columns = columns.concat([
            {
                title: i18n.t('customerMessages.invoice.list.table.fieldName.dueDate'),
                dataIndex: 'due_date',
                key: 'dueDate',
                sorter: true,
                collapseHeader: true,
            },
            {
                title: i18n.t('customerMessages.invoice.list.table.fieldName.daysOverdue'),
                dataIndex: 'days_overdue',
                key: 'days_overdue',
                className: 'center-align',
                sorter: true,
                rangeFilters: [
                    {
                        label: i18n.t('customerMessages.invoice.list.table.fieldName.daysOverdueFrom'),
                        key: 'days_overdue_from',
                    },
                    {
                        label: i18n.t('customerMessages.invoice.list.table.fieldName.daysOverdueTo'),
                        key: 'days_overdue_to',
                    },
                ],
                rangeFilterType: 'number',
            },
            {
                title: i18n.t('customerMessages.invoice.list.table.fieldName.amountPaid'),
                dataIndex: 'amount_paid',
                key: 'amount_paid',
                className: 'center-align',
                sorter: true,
            },
            {
                title: i18n.t('customerMessages.invoice.list.table.fieldName.amountDue'),
                dataIndex: 'amount_due',
                key: 'amount_due',
                className: 'center-align',
                sorter: true,
                collapseHeader: true,
                rangeFilters: [
                    {
                        label: i18n.t('customerMessages.invoice.list.table.fieldName.amountDueFrom'),
                        key: 'amount_due_from',
                    },
                    {
                        label: i18n.t('customerMessages.invoice.list.table.fieldName.amountDueTo'),
                        key: 'amount_due_to',
                    },
                ],
                rangeFilterType: 'number',
            },
        ]);
    }

    // if (!isRepeatingInvoices) {
    columns = columns.concat([
        {
            title: i18n.t('customerMessages.invoice.list.table.fieldName.contactType'),
            dataIndex: 'contact_type_id',
            key: 'type',
            sorter: true,
            className: 'select-field-short',
            filterMultiple: false,
            filters: contactTypes.map(type => ({ text: type.type, value: type.id })),
        },
    ]);
    // }
    columns = columns.concat([
        {
            title: i18n.t('customerMessages.invoice.list.table.fieldName.status'),
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            className: 'select-field-short',
            filterMultiple: false,
            filters: invoiceStatuses
                .filter(status =>
                    isRepeatingInvoices
                        ? status.slug === INVOICE_STATUS_OPEN_SLUG ||
                          status.slug === INVOICE_STATUS_DRAFT_SLUG ||
                          status.slug === INVOICE_STATUS_VOIDED_SLUG ||
                          status.slug === INVOICE_STATUS_WRITE_OFF_SLUG
                        : true
                )
                .map(status => ({ text: status.label, value: status.value })),
        },
    ]);

    return columns;
};

export const NOTIFICATION_TABLE_COLUMNS = [
    {
        title: i18n.t('sharedMessages.notifications.list.table.fieldName.imageBadge'),
        dataIndex: 'imageBadge',
        key: 'imageBadge',
        className: 'image-badge',
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.notifications.list.table.fieldName.content'),
        dataIndex: 'content',
        key: 'content',
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.notifications.list.table.fieldName.date'),
        dataIndex: 'created_date',
        key: 'created_date',
    },
];

//ORGANISATION_TABLE_COLUMNS
export const getOrganisationTableColumns = ({ props, accountingSoftwares }) => {
    let columns = [
        {
            title: i18n.t('customerMessages.organisation.list.table.fieldName.name'),
            dataIndex: 'name',
            key: 'name',
            collapseHeader: true,
            sorter: true,
        },
        {
            title: i18n.t('customerMessages.organisation.list.table.fieldName.accountingSoftware'),
            dataIndex: 'accounting_software',
            key: 'accounting_software',
            className: 'center-align',
            filterMultiple: false,
            collapseHeader: true,
            filters: Object.values(accountingSoftwares).map(a => ({ text: a.name, value: a.id })),
        },
        {
            title: i18n.t('customerMessages.organisation.list.table.fieldName.country'),
            dataIndex: 'country',
            key: 'country',
        },
        {
            title: i18n.t('customerMessages.organisation.list.table.fieldName.createdDate'),
            dataIndex: 'created_date',
            key: 'created_date',
            sorter: true,
            defaultSortOrder: 'descend',
        },
    ];
    !props.isAdmin &&
        columns.push({
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
        });
    return columns;
};

export const REMINDERS_TABLE_COLUMNS = [
    {
        title: i18n.t('customerMessages.reminders.list.table.fieldName.name'),
        dataIndex: 'name',
        key: 'name',
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.reminders.list.table.fieldName.task'),
        dataIndex: 'task',
        key: 'task',
    },
    {
        title: i18n.t('customerMessages.reminders.list.table.fieldName.notificationType'),
        dataIndex: 'notificationType',
        key: 'type',
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.reminders.list.table.fieldName.templateType'),
        dataIndex: 'templateType',
        key: 'templateType',
    },
    {
        title: i18n.t('customerMessages.reminders.list.table.fieldName.date'),
        dataIndex: 'notification_date',
        key: 'notification_date',
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.reminders.list.table.fieldName.notified_date'),
        dataIndex: 'notified_date',
        key: 'notified_date',
    },
    {
        title: i18n.t('customerMessages.reminders.list.table.fieldName.status'),
        dataIndex: 'notified_status',
        key: 'notified_status',
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.reminders.list.table.fieldName.actions'),
        dataIndex: 'actions',
        key: 'actions',
        className: 'center-align',
    },
];

//CAMPAIGN_TABLE_COLUMNS
export const getCampaignTableColumns = props => [
    {
        title: i18n.t('customerMessages.campaign.list.table.fieldName.day'),
        dataIndex: 'day_description',
        key: 'day_description',
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.campaign.list.table.fieldName.notificationType'),
        dataIndex: 'type',
        key: 'type',
        filterMultiple: false,
        filters: props.notificationTypes.map(type => ({ text: type.type, value: type.id })),
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.campaign.list.table.fieldName.templateType'),
        dataIndex: 'templateType',
        key: 'templateType',
    },
    {
        title: i18n.t('customerMessages.campaign.list.table.fieldName.status'),
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: i18n.t('customerMessages.campaign.list.table.fieldName.actions'),
        dataIndex: 'actions',
        key: 'actions',
    },
];

export const getAccountListColumns = accountTypes => [
    {
        title: i18n.t('adminMessages.accounts.list.table.fieldName.name'),
        dataIndex: 'name',
        key: 'name',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.accounts.list.table.fieldName.uniqueId'),
        dataIndex: 'unique_id',
        key: 'unique_id',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.accounts.list.table.fieldName.email'),
        dataIndex: 'primary_contact_email',
        key: 'primary_contact_email',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.accounts.list.table.fieldName.firstName'),
        dataIndex: 'primary_contact_first_name',
        key: 'primary_contact_first_name',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.accounts.list.table.fieldName.lastName'),
        dataIndex: 'primary_contact_last_name',
        key: 'primary_contact_last_name',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.accounts.list.table.fieldName.accountType'),
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        filterMultiple: false,
        filters: accountTypes
            ? accountTypes.map(accountType => ({ text: accountType.slug, value: accountType.slug }))
            : [],
    },
    {
        title: i18n.t('adminMessages.accounts.list.table.fieldName.createdDate'),
        dataIndex: 'created_date',
        key: 'created_date',
        sorter: true,
    },
];

export const COUPON_ACCOUNT_TABLE_COLUMNS = [
    {
        title: i18n.t('adminMessages.couponAccounts.list.table.fieldName.name'),
        dataIndex: 'name',
        key: 'name',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.couponAccounts.list.table.fieldName.discountAmount'),
        dataIndex: 'discount_amount',
        key: 'discount_amount',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.couponAccounts.list.table.fieldName.discountPercentage'),
        dataIndex: 'discount_percentage',
        key: 'discount_percentage',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.couponAccounts.list.table.fieldName.createdDate'),
        dataIndex: 'created_date',
        key: 'created_date',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.couponAccounts.list.table.fieldName.redeemedDate'),
        dataIndex: 'redeemed_date',
        key: 'redeemed_date',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.couponAccounts.list.table.fieldName.expiryDate'),
        dataIndex: 'expiry_date',
        key: 'expiry_date',
        sorter: true,
    },
];

export const COUPON_ACCOUNTS_LIST_TABLE_COLUMNS = [
    {
        title: i18n.t('adminMessages.couponAccounts.list.table.fieldName.name'),
        dataIndex: 'name',
        key: 'name',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.couponAccounts.list.table.fieldName.email'),
        dataIndex: 'primary_contact_email',
        key: 'primary_contact_email',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.couponAccounts.list.table.fieldName.firstName'),
        dataIndex: 'primary_contact_first_name',
        key: 'primary_contact_first_name',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.couponAccounts.list.table.fieldName.lastName'),
        dataIndex: 'primary_contact_last_name',
        key: 'primary_contact_last_name',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.couponAccounts.list.table.fieldName.createdDate'),
        dataIndex: 'created_date',
        key: 'created_date',
        sorter: true,
    },
];

export const COUPONS_TABLE_COLUMNS = [
    {
        title: i18n.t('adminMessages.coupons.list.table.fieldName.code'),
        dataIndex: 'code',
        key: 'code',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.coupons.list.table.fieldName.discount_amount'),
        dataIndex: 'discount_amount',
        key: 'discount_amount',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.coupons.list.table.fieldName.discount_percentage'),
        dataIndex: 'discount_percentage',
        key: 'discount_percentage',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.coupons.list.table.fieldName.created_date'),
        dataIndex: 'created_date',
        key: 'created_date',
        sorter: true,
    },
];

export const AUDIT_LOG_TABLE_COLUMNS = [
    {
        title: i18n.t('adminMessages.logs.list.table.fieldName.account'),
        dataIndex: 'account.name',
        key: 'account',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.logs.list.table.fieldName.activity_info'),
        dataIndex: 'activity_info',
        key: 'activity_info',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.logs.list.table.fieldName.activity_time'),
        dataIndex: 'performed_at',
        key: 'performed_at',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.logs.list.table.fieldName.ip_address'),
        dataIndex: 'ip_address',
        key: 'ip_address',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.logs.list.table.fieldName.user'),
        dataIndex: 'performed_by.name',
        key: 'performed_by',
        sorter: true,
    },
];

export const ORGANISATIONS_TABLE_COLUMNS = [
    {
        title: i18n.t('adminMessages.organisations.list.table.fieldName.name'),
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: i18n.t('adminMessages.organisations.list.table.fieldName.country'),
        dataIndex: 'country',
        key: 'country',
    },
    {
        title: i18n.t('adminMessages.organisations.list.table.fieldName.accounting_software'),
        dataIndex: 'accounting_software',
        key: 'accounting_software',
    },
];
export const getOrderTableColumns = (showOrganisationAndContact = false) => {
    let columns = [
        {
            title: i18n.t('sharedMessages.orders.list.table.fieldName.uniqueId'),
            dataIndex: 'unique_id',
            key: 'unique_id',
            collapseHeader: true,
            sorter: true,
        },
        {
            title: i18n.t('sharedMessages.orders.list.table.fieldName.paymentStatus'),
            dataIndex: 'payment_status',
            key: 'payment_status',
            collapseHeader: true,
        },
    ];

    if (showOrganisationAndContact) {
        columns.push(
            {
                title: i18n.t('sharedMessages.orders.list.table.fieldName.organisation'),
                dataIndex: 'organisation',
                key: 'organisation',
                sorter: false,
            },
            {
                title: i18n.t('sharedMessages.orders.list.table.fieldName.contact'),
                dataIndex: 'contact',
                key: 'contact',
                sorter: false,
            }
        );
    }

    columns.push(
        {
            title: i18n.t('sharedMessages.orders.list.table.fieldName.total'),
            dataIndex: 'total_amount',
            key: 'total_amount',
            sorter: true,
        },
        {
            title: i18n.t('sharedMessages.orders.list.table.fieldName.created_date'),
            dataIndex: 'created_date',
            key: 'created_date',
            sorter: true,
        },
        {
            title: i18n.t('sharedMessages.orders.list.table.fieldName.action'),
            dataIndex: 'action',
            key: 'action',
        }
    );

    return columns;
};
export const TEMPLATES_TABLE = [
    {
        title: i18n.t('sharedMessages.communication.templates.table.field.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.communication.templates.table.field.type'),
        dataIndex: 'type',
        key: 'type',
        sorter: true,
    },
];
export const getPlaceholderTable = props => [
    {
        title: i18n.t('sharedMessages.communication.placeholders.table.fieldName.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.communication.placeholders.table.fieldName.key'),
        dataIndex: 'key',
        key: 'key',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.communication.placeholders.table.fieldName.value'),
        dataIndex: 'value',
        key: 'value',
        sorter: true,
    },
    {
        title: i18n.t('sharedMessages.communication.placeholders.table.fieldName.group'),
        dataIndex: 'group',
        key: 'group',
        sorter: true,
        className: 'select-field-short',
        filterMultiple: false,
        filters: props.placeholderGroup.map(type => ({ text: type, value: type })),
    },
];
export const TEMPLATE_TYPES_TABLE = [
    {
        title: i18n.t('sharedMessages.communication.email.table.fieldName.type'),
        dataIndex: 'type',
        key: 'type',
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.communication.email.table.fieldName.label'),
        dataIndex: 'label',
        key: 'label',
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.communication.email.table.fieldName.slug'),
        dataIndex: 'slug',
        key: 'slug',
    },
];
export const USERS_TABLE = [
    {
        title: i18n.t('sharedMessages.users.table.fieldName.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.users.table.fieldName.email'),
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.users.table.fieldName.phoneNumber'),
        dataIndex: 'phone_number',
        key: 'phone_number',
        sorter: true,
    },
    {
        title: i18n.t('sharedMessages.users.table.fieldName.role'),
        dataIndex: 'role',
        key: 'role',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.users.table.fieldName.createdDate'),
        dataIndex: 'created_date',
        key: 'created_date',
        sorter: true,
    },
];

//INVOICES_TABLE_COLUMNS
export const DEBT_COLLECTOR_INVOICES_TABLE = [
    {
        title: i18n.t('customerMessages.invoice.list.table.fieldName.contactBusinessName'),
        dataIndex: 'business_name',
        key: 'business_name',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.invoice.list.table.fieldName.invoiceNumber'),
        dataIndex: 'invoice_number',
        key: 'invoice_number',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.invoice.list.table.fieldName.dueDate'),
        dataIndex: 'due_date',
        key: 'dueDate',
        sorter: true,
    },
    {
        title: i18n.t('customerMessages.invoice.list.table.fieldName.daysOverdue'),
        dataIndex: 'days_overdue',
        key: 'days_overdue',
        className: 'center-align',
        sorter: true,
    },
    {
        title: i18n.t('customerMessages.invoice.list.table.fieldName.amountPaid'),
        dataIndex: 'amount_paid',
        key: 'amount_paid',
        className: 'center-align',
        sorter: true,
    },
    {
        title: i18n.t('customerMessages.invoice.list.table.fieldName.amountDue'),
        dataIndex: 'amount_due',
        key: 'amount_due',
        className: 'center-align',
        sorter: true,
        collapseHeader: true,
    },
];

//SUBSCRIPTION_HISTORY
export const getSubscriptionColumns = props => {
    let columns = [
        {
            title: i18n.t('customerMessages.subscription.list.table.fieldName.plan'),
            dataIndex: 'plan',
            key: 'plan',
            collapseHeader: true,
        },
        {
            title: i18n.t('customerMessages.subscription.list.table.fieldName.start_date'),
            dataIndex: 'subscription_start_date',
            key: 'subscription_start_date',
            collapseHeader: true,
        },
        {
            title: i18n.t('customerMessages.subscription.list.table.fieldName.end_date'),
            dataIndex: 'subscription_end_date',
            key: 'subscription_end_date',
        },
        {
            title: i18n.t('customerMessages.subscription.list.table.fieldName.status'),
            dataIndex: 'status',
            key: 'status',
        },
    ];
    props.isAdmin &&
        columns.unshift({
            title: i18n.t('customerMessages.subscription.list.table.fieldName.account'),
            dataIndex: 'account',
            key: 'account',
            collapseHeader: true,
        });
    return columns;
};

export const PLANS_TABLE_COLUMNS = [
    {
        title: i18n.t('adminMessages.plans.list.table.fieldName.label'),
        dataIndex: 'label',
        key: 'label',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.plans.list.table.fieldName.country'),
        dataIndex: 'country_id',
        key: 'country_id',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.plans.list.table.fieldName.cost'),
        dataIndex: 'cost',
        key: 'cost',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.plans.list.table.fieldName.yearly_cost'),
        dataIndex: 'yearly_cost',
        key: 'yearly_cost',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.plans.list.table.fieldName.is_custom'),
        dataIndex: 'is_custom',
        key: 'is_custom',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.plans.list.table.fieldName.created_date'),
        dataIndex: 'created_date',
        key: 'created_date',
        collapseHeader: true,
        sorter: true,
    },
];

export const PLAN_ACCOUNTS_TABLE_COLUMNS = [
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.account'),
        dataIndex: 'account',
        key: 'name',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.uniqueId'),
        dataIndex: 'unique_id',
        key: 'unique_id',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.subscriptionStartDate'),
        dataIndex: 'subscription_start_date',
        key: 'subscription_start_date',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.subscriptionEndDate'),
        dataIndex: 'subscription_end_date',
        key: 'subscription_end_date',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.gracePeriodEndDate'),
        dataIndex: 'grace_period_end_date',
        key: 'grace_period_end_date',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.status'),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.createdDate'),
        dataIndex: 'created_date',
        key: 'created_date',
        collapseHeader: true,
        sorter: true,
    },
];

export const PLAN_ACCOUNTS_ADD_LIST_TABLE_COLUMNS = [
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.account'),
        dataIndex: 'name',
        key: 'name',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.uniqueId'),
        dataIndex: 'unique_id',
        key: 'unique_id',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.email'),
        dataIndex: 'primary_contact_email',
        key: 'primary_contact_email',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.firstName'),
        dataIndex: 'primary_contact_first_name',
        key: 'primary_contact_first_name',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.lastName'),
        dataIndex: 'primary_contact_last_name',
        key: 'primary_contact_last_name',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planAccounts.list.table.fieldName.createdDate'),
        dataIndex: 'created_date',
        key: 'created_date',
        sorter: true,
    },
];

export const PLAN_QUOTES_TABLE_COLUMNS = [
    {
        title: i18n.t('adminMessages.planQuotes.list.table.fieldName.firstName'),
        dataIndex: 'first_name',
        key: 'first_name',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planQuotes.list.table.fieldName.lastName'),
        dataIndex: 'last_name',
        key: 'last_name',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planQuotes.list.table.fieldName.email'),
        dataIndex: 'email',
        key: 'email',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planQuotes.list.table.fieldName.phone'),
        dataIndex: 'phone',
        key: 'phone',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planQuotes.list.table.fieldName.website'),
        dataIndex: 'website',
        key: 'website',
        sorter: true,
    },
    {
        title: i18n.t('adminMessages.planQuotes.list.table.fieldName.estimatedVolume'),
        dataIndex: 'estimated_volume',
        key: 'estimated_volume',
        sorter: true,
    },
];

export const EXPORTS_LIST_TABLE = [
    {
        title: i18n.t('sharedMessages.exports.list.table.fieldName.account'),
        dataIndex: 'account',
        key: 'account',
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.exports.list.table.fieldName.user'),
        dataIndex: 'user',
        key: 'user',
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.exports.list.table.fieldName.organisation'),
        dataIndex: 'organisation',
        key: 'organisation',
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.exports.list.table.fieldName.type'),
        dataIndex: 'type',
        key: 'type',
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.exports.list.table.fieldName.status'),
        dataIndex: 'status',
        key: 'status',
        collapseHeader: true,
    },
    {
        title: i18n.t('sharedMessages.exports.list.table.fieldName.created_date'),
        dataIndex: 'created_date',
        key: 'created_date',
    },
    {
        title: i18n.t('sharedMessages.exports.list.table.fieldName.actions'),
        dataIndex: 'actions',
        key: 'actions',
    },
];

export const INVOICE_PAYMENT_FORM_TABLE = [
    {
        title: 'Invoice No',
        dataIndex: 'invoice_number',
        width: 50,
        collapseHeader: true,
    },
    {
        title: 'Due Date',
        dataIndex: 'due_date',
        width: 120,
    },
    {
        title: 'Days overdue',
        dataIndex: 'days_overdue',
        width: 120,
        collapseHeader: true,
    },
    {
        title: 'Amount',
        dataIndex: 'total_amount',
        width: 120,
    },
    {
        title: 'Outstanding Balance',
        dataIndex: 'amount_due',
        width: 180,
        collapseHeader: true,
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
    },
    {
        title: 'Payment amount',
        dataIndex: 'pay',
    },
];

export const getInvoicePaymentTableColumns = (showExchangeRateColumn = false) => {
    let columns = [
        {
            title: i18n.t('customerMessages.paymentsHistory.list.table.fieldName.invoice_number'),
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            collapseHeader: true,
        },
        {
            title: i18n.t('customerMessages.paymentsHistory.list.table.fieldName.amount_paid'),
            dataIndex: 'amount_paid_formatted',
            key: 'amount_paid_formatted',
            collapseHeader: true,
        },
        {
            title: i18n.t('customerMessages.paymentsHistory.list.table.fieldName.paid_on'),
            dataIndex: 'paid_on',
            key: 'paid_on',
        },
        {
            title: i18n.t('customerMessages.paymentsHistory.list.table.fieldName.externalId'),
            dataIndex: 'external_id',
            key: 'external_id',
        },
        {
            title: i18n.t('customerMessages.paymentsHistory.list.table.fieldName.referenceNumber'),
            dataIndex: 'reference_number',
            key: 'reference_number',
        },
    ];

    if (showExchangeRateColumn) {
        columns.push({
            title: i18n.t('customerMessages.paymentsHistory.list.table.fieldName.exchangeRate'),
            dataIndex: 'exchange_rate',
            key: 'exchange_rate',
        });
    }

    columns.push({
        title: '',
        dataIndex: 'actions',
        key: 'actions',
    });

    return columns;
};

export const PAYMENT_GATEWAYS_TABLE = [
    {
        title: i18n.t('customerMessages.paymentGateways.list.table.fieldName.name'),
        dataIndex: 'name',
        key: 'name',
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.paymentGateways.list.table.fieldName.gst'),
        dataIndex: 'gst',
        key: 'gst',
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.paymentGateways.list.table.fieldName.service_charge'),
        dataIndex: 'service_charge',
        key: 'service_charge',
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.paymentGateways.list.table.fieldName.transaction_charge'),
        dataIndex: 'transaction_charge',
        key: 'transaction_charge',
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.paymentGateways.list.table.fieldName.transaction_charge_percentage'),
        dataIndex: 'transaction_charge_percentage',
        key: 'transaction_charge_percentage',
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.paymentGateways.list.table.fieldName.allow_saving_card'),
        dataIndex: 'allow_saving_card',
        key: 'allow_saving_card',
        collapseHeader: true,
    },
    {
        title: i18n.t('customerMessages.paymentGateways.list.table.fieldName.is_active'),
        dataIndex: 'is_active',
        key: 'is_active',
        collapseHeader: true,
    },
];

export const INVOICE_LINE_ITEMS_TABLE_COLUMNS = [
    {
        title: 'Item',
        dataIndex: 'name',
        key: 'name',
        collapseHeader: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'External ID',
        dataIndex: 'external_id',
        key: 'external_id',
    },
    {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        collapseHeader: true,
    },
    {
        title: 'Unit Price',
        dataIndex: 'unit_price',
        key: 'unit_price',
    },
    {
        title: 'Tax',
        dataIndex: 'tax_amount',
        key: 'tax_amount',
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        collapseHeader: true,
    },
];

export const getInvoiceAddEditTableColumns = isRecurring => {
    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            key: 'item',
            width: '10%',
            collapseHeader: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: isRecurring ? '10%' : '24%',
            collapseHeader: true,
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            width: '6%',
            collapseHeader: true,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            width: '10%',
            collapseHeader: true,
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            key: 'unit',
            width: '10%',
            collapseHeader: true,
        },
    ];

    if (isRecurring) {
        columns.push({
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            width: '7%',
        });
        columns.push({
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
            width: '7%',
        });
    }
    columns.push({
        title: 'Tax',
        dataIndex: 'tax',
        key: 'tax',
        width: '10%',
    });
    columns.push({
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
        width: '8%',
    });
    columns.push({
        title: '',
        dataIndex: 'delete',
        key: 'delete',
        width: '2%',
    });
    return columns;
};

export const BANK_ACCOUNT_TRANSACTIONS_COLUMNS = [
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
        collapseHeader: true,
        sorter: true,
    },
    {
        title: 'type',
        dataIndex: 'type',
        key: 'type',
        sorter: true,
    },
    {
        title: 'amount',
        dataIndex: 'amount',
        key: 'amount',
        sorter: true,
    },
];

export const INVOICE_STATUSES_COLUMNS = [
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        collapseHeader: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        collapseHeader: true,
    },
];

/**
 * Campaigns breadcrumb items
 *
 * @since 2.0.0
 */

//import required modules
import React from 'react';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AccountsBreadCrumbItems from '../Accounts';
import OrganisationsBreadCrumbItems from '../Organisations';
import PropTypes from 'prop-types';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import PaymentPlansBreadCrumbItems from 'components/BreadCrumbItems/PaymentPlans';
import { CAMPAIGN_TYPE_BROKEN, CAMPAIGN_TYPE_REMINDER } from 'includes/constants';

const CampaignsBreadCrumbItems = props => {
    const accountId = useAccountId();
    const isAdminOrManager = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    const { t } = useTranslation();

    const campaignDetails = useSelector(state => state.campaigns.campaign);

    /**
     * Get the campaign details link
     *
     * @param {string} campaignId Campaign id
     *
     * @returns {string} Details link
     */
    const getDetailsLink = campaignId => {
        if (campaignDetails?.campaign_type === CAMPAIGN_TYPE_REMINDER) {
            return isAdminOrManager
                ? `/admin/accounts/organisation/campaigns/campaign/${accountId}/${organisationId}/${campaignId}`
                : `/campaigns/campaign/${campaignId}`;
        } else {
            return isAdminOrManager
                ? `/admin/accounts/organisation/payment-plans/plan/${accountId}/${organisationId}/${props.paymentPlanId}/campaigns/campaign/${campaignId}`
                : `/payment-plans/plan/${props.paymentPlanId}/campaigns/campaign/${campaignId}`;
        }
    };

    /**
     * Get the campaigns list link
     *
     * @returns {string} List link
     */
    const getListLink = () => {
        const brokenArrangementCampaignListLinkPrefix =
            campaignDetails?.campaign_type === CAMPAIGN_TYPE_BROKEN ? `/payment-plans/plan/${props.paymentPlanId}` : '';
        return isAdminOrManager
            ? `/admin/accounts/organisation/campaigns/${accountId}/${organisationId}`
            : campaignDetails?.campaign_type === CAMPAIGN_TYPE_BROKEN
            ? brokenArrangementCampaignListLinkPrefix
            : `/campaigns`;
    };

    /**
     * Render the component
     */
    return (
        <>
            {isAdminOrManager && !props.paymentPlanId ? (
                <>
                    <AccountsBreadCrumbItems showListBreadCrumb />
                    <OrganisationsBreadCrumbItems showListBreadCrumb />
                </>
            ) : null}
            {props.showListBreadCrumb && !props.paymentPlanId ? (
                <Breadcrumb.Item>
                    <Link to={getListLink()}>{t('messages.breadCrumbs.campaigns.list')}</Link>
                </Breadcrumb.Item>
            ) : null}
            {props.paymentPlanId ? (
                <PaymentPlansBreadCrumbItems showListBreadCrumb showDetailsBreadCrumb showDetailsBreadCrumbAsLink />
            ) : null}
            {props.showDetailsBreadCrumb ? (
                <Breadcrumb.Item>
                    {props.showDetailsBreadCrumbAsLink ? (
                        <Link to={getDetailsLink(get(campaignDetails, 'id'))}>
                            {get(campaignDetails, 'name', t('messages.breadCrumbs.campaigns.details'))}
                        </Link>
                    ) : (
                        get(campaignDetails, 'name', t('messages.breadCrumbs.campaigns.details'))
                    )}
                </Breadcrumb.Item>
            ) : null}
            {props.showAddEditBreadCrumb ? (
                <Breadcrumb.Item>{t(`messages.breadCrumbs.campaigns.edit`)}</Breadcrumb.Item>
            ) : null}
        </>
    );
};

CampaignsBreadCrumbItems.propTypes = {
    paymentPlanId: PropTypes.string,
    showAddEditBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumbAsLink: PropTypes.bool,
    showListBreadCrumb: PropTypes.bool,
};

export default CampaignsBreadCrumbItems;

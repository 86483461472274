import PropTypes from 'prop-types';
import React from 'react';

import PaymentMethodType from '../PaymentMethodType';
import SelectButton from 'components/SelectButton';
import { Skeleton } from 'antd';
import PaymentScheme from 'components/customer/PaymentInformation/components/PaymentMethods/components/PaymentScheme';
import CountryLabel from 'components/CountryLabel';

/***
 * Component to render UI of payment method option
 *
 * @since 2.8.0
 */
export default function PaymentMethodOption({
    countryId,
    description,
    icon,
    isSelected,
    isLoading,
    onSelect,
    schemeId,
    title,
    typeId,
}) {
    return (
        <SelectButton className="text-left w-100 overflow-hidden" isSelected={isSelected} onClick={() => onSelect()}>
            {isLoading ? (
                <Skeleton avatar paragraph={{ rows: 0 }} />
            ) : (
                <>
                    <div className={`flex flex-col items-start sm:items-center sm:flex-row gap-2 relative`}>
                        <img
                            className="hidden sm:block"
                            height={50}
                            width={50}
                            style={{ height: 50, width: 50 }}
                            src={icon}
                        />

                        <div className="text-base font-sans">
                            <div>{title}</div>
                            <div className="text-sm">{description}</div>
                        </div>

                        <div className="flex items-start flex-row sm:ml-auto gap-1">
                            <div className="flex-grow-0">
                                <PaymentMethodType id={typeId} />
                            </div>
                            {schemeId ? (
                                <div className="flex-grow-0">
                                    <PaymentScheme id={schemeId} />
                                </div>
                            ) : null}
                            <div className="flex-grow-0">
                                <CountryLabel id={countryId} />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </SelectButton>
    );
}

PaymentMethodOption.propTypes = {
    countryId: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.object,
    isLoading: PropTypes.bool,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
    schemeId: PropTypes.string,
    title: PropTypes.string,
    typeId: PropTypes.string,
};

/**
 * User Saga
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import the required modules
import { React } from '../../../../exports/react';
import { call, takeLatest, all, put } from 'redux-saga/effects';
import { get, omit, isEmpty, isFunction } from 'lodash';
import { change, reset, stopSubmit } from 'redux-form';
import { Modal } from 'antd';
import UserApi from '../../../../services/shared/user';
import BaseSagaHandler from '../../core/base';
import * as actionTypes from '../../../../constants/actionTypes';
import * as constants from '../../../../constants';
import * as errorMappings from '../../../../constants/mappings/errors';
import * as errorMessages from '../../../../constants/messages/errors';
import * as requestKeys from '../../../../constants/keys/request';
import * as successMappings from '../../../../constants/mappings/success';
import * as successMessages from '../../../../constants/messages/success';
import * as utils from '../../../../utils';
import * as segmentAnalytics from '../../../../utils/segment';
import { getLocalStorageValue, getValueFormStore, redirect } from '../../../../utils';
import { VERIFY_PHONE_NUMBER_SUCCESS } from '../../../../constants/messages/success';
import { REDIRECT_URL_AFTER_VERIFY } from '../../../../constants';
import { showNotificationModal, stopFormSubmit } from 'includes/slices/appNotifications';
import { trackConversion } from 'includes/utils/impact';

const { error } = Modal;
/**
 * User Details Saga Class. Handles the user details related operations
 * */
class User extends BaseSagaHandler {
    /**
     * The User Watcher Saga
     * Watches the redux actions related to user and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *userWatchers() {
        let context = this;
        yield all([
            yield takeLatest(actionTypes.ACTIVATE_ACCOUNT_REQUEST, [context, 'activateAccount']),
            yield takeLatest(actionTypes.ADD_USER_REQUEST, [context, 'addUser']),
            yield takeLatest(actionTypes.CHANGE_USER_STATUS_REQUEST, [context, 'changeUserStatus']),
            yield takeLatest(actionTypes.DELETE_USER_REQUEST, [context, 'deleteUser']),
            yield takeLatest(actionTypes.FORGOT_USER_PASSWORD_REQUEST, [context, 'forgotPassword']),
            yield takeLatest(actionTypes.GET_FAQ_REQUEST, [context, 'getFAQ']),
            yield takeLatest(actionTypes.GET_USER_DETAILS_REQUEST, [context, 'getUserDetails']),
            yield takeLatest(actionTypes.GET_USER_PROFILE_DETAILS_REQUEST, [context, 'getUserProfileDetails']),
            yield takeLatest(actionTypes.GET_USER_ROLES, [context, 'getUserRoles']),
            yield takeLatest(actionTypes.GET_USER_ACCOUNT_ROLES, [context, 'getUserAccountRoles']),
            yield takeLatest(actionTypes.GET_USERS_REQUEST, [context, 'getUsers']),
            yield takeLatest(actionTypes.REQUEST_QUOTE_REQUEST, [context, 'requestQuote']),
            yield takeLatest(actionTypes.RESEND_ACTIVATION_EMAIL_REQUEST, [context, 'resendActivationEmail']),
            yield takeLatest(actionTypes.RESEND_VERIFICATION_EMAIL_REQUEST, [context, 'resendVerificationEmail']),
            yield takeLatest(actionTypes.RESEND_VERIFICATION_SMS_REQUEST, [context, 'resendVerificationSms']),
            yield takeLatest(actionTypes.RESET_TWO_FACTOR_AUTHENTICATION_REQUEST, [
                context,
                'resetTwoFactorAuthentication',
            ]),
            yield takeLatest(actionTypes.USER_CHANGE_PASSWORD_REQUEST, [context, 'changePassword']),
            yield takeLatest(actionTypes.USER_LOGIN_REQUEST, [context, 'login']),
            yield takeLatest(actionTypes.USER_LOGOUT_REQUEST, [context, 'logout']),
            yield takeLatest(actionTypes.USER_PROFILE_UPDATE_REQUEST, [context, 'updateUserProfile']),
            yield takeLatest(actionTypes.USER_REGISTRATION_MEETING_NOT_SCHEDULED, [
                context,
                'registerUserMeetingNotScheduled',
            ]),
            yield takeLatest(actionTypes.USER_REGISTRATION_REQUEST, [context, 'registerUser']),
            yield takeLatest(actionTypes.USER_RESET_PASSWORD_REQUEST, [context, 'resetPassword']),
            yield takeLatest(actionTypes.USER_VALIDATE_RESET_PASSWORD_REQUEST, [context, 'resetPassword']),
            yield takeLatest(actionTypes.USER_UPDATE_REQUEST, [context, 'updateUserDetails']),
            yield takeLatest(actionTypes.VERIFY_EMAIL_ADDRESS_REQUEST, [context, 'verifyEmailAddress']),
            yield takeLatest(actionTypes.VERIFY_PHONE_NUMBER_REQUEST, [context, 'verifyPhoneNumber']),
        ]);
    }

    /**
     * Change password
     * Calls the API for changing the user password
     * Sends response/notification based on the response success or failure
     */
    *changePassword(action) {
        try {
            const result = yield call(UserApi.changePassword, action.payload);
            yield put({ type: actionTypes.USER_CHANGE_PASSWORD_SUCCESS, result });

            //reset any errors set in form
            yield put(stopSubmit(constants.CHANGE_PASSWORD_FORM_NAME, {}));

            //reset the form
            yield put(reset(constants.CHANGE_PASSWORD_FORM_NAME));
        } catch (errors) {
            //dispatch the change password errors
            yield put(
                stopSubmit(
                    constants.CHANGE_PASSWORD_FORM_NAME,
                    this.getApiErrors(
                        errors,
                        constants.USER_CHANGE_PASSWORD_DEFAULT_ERROR,
                        constants.USER_CHANGE_PASSWORD_DEFAULT_ERROR
                    )
                )
            );

            //dispatch the change password  failure action
            yield put({ type: actionTypes.USER_CHANGE_PASSWORD_FAILURE, errors });
        }
    }

    /**
     * Change the user status
     */
    *changeUserStatus({ payload }) {
        try {
            const result = yield call(UserApi.changeUserStatus, payload);
            yield this.showNotificationModal(result, true);
            yield put({ type: actionTypes.CHANGE_USER_STATUS_SUCCESS, result });
        } catch (errors) {
            yield this.showNotificationModal(
                errors,
                false,
                constants.CHANGE_USER_STATUS_DEFAULT_ERROR,
                payload.status ? constants.ACTIVATE_USER_DEFAULT_ERROR : constants.DEACTIVATE_USER_DEFAULT_ERROR
            );
            yield put({ type: actionTypes.CHANGE_USER_STATUS_FAILURE });
        }
    }

    /**
     * Forgot password
     *
     * Reset password request
     */
    *forgotPassword({ payload }) {
        try {
            const result = yield call(UserApi.forgotPassword, payload);

            yield put({ type: actionTypes.FORGOT_USER_PASSWORD_SUCCESS, result });

            //reset any errors set in form
            yield put(stopSubmit(constants.FORGOT_PASSWORD_FORM_NAME, {}));

            //reset the form
            yield put(reset(constants.FORGOT_PASSWORD_FORM_NAME));
        } catch (errors) {
            //dispatch the login errors
            yield put(
                stopSubmit(
                    constants.FORGOT_PASSWORD_FORM_NAME,
                    this.getApiErrors(
                        errors,
                        constants.USER_FORGOT_PASSWORD_DEFAULT_ERROR,
                        constants.USER_FORGOT_PASSWORD_DEFAULT_ERROR
                    )
                )
            );
            yield put({ type: actionTypes.FORGOT_USER_PASSWORD_FAILURE, errors });
        }
    }

    /**
     * Get user profile details
     * Calls the API for getting user profile details
     * Sends response/notification based on the response success or failure
     */
    *getUserProfileDetails() {
        try {
            const result = yield call(UserApi.getUserProfileDetails);

            yield put({ type: actionTypes.GET_USER_PROFILE_DETAILS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_USER_PROFILE_DETAILS_FAILURE, errors });
        }
    }

    /**
     * Do the user login
     * Calls the login API
     *
     * @param   {object}    action    Login Action Object
     */
    *login({ payload, silent, callback }) {
        let isMultipleAccounts = false;

        try {
            payload['is_customer_portal_login'] = true;
            //call the API and get the results
            const result = yield call(UserApi.login, payload);
            const accounts = get(result, successMappings.USER_LOGIN_GET_ACCOUNTS, {});
            const isAdmin = get(result, successMappings.USER_LOGIN_IS_ADMIN);
            const roles = get(result, 'data.data.user_details.roles', {});
            const accessToken = get(result, successMappings.USER_LOGIN_GET_ACCESS_TOKEN, '');
            // const isPhoneVerified = get(result, successMappings.PHONE_NUMBER_VERIFIED);
            // const isEmailVerified = get(result, successMappings.EMAIL_VERIFIED);

            let data = {};
            let accountId = '';
            data[constants.USER_TOKEN_KEY] = accessToken;
            accountId = Object.keys(accounts)[0];
            data[constants.IS_ADMIN] = JSON.stringify(isAdmin);
            data[constants.USER_CURRENT_ACCOUNT_KEY] = !isMultipleAccounts ? accountId : '';
            data[constants.USER_ROLES_KEY] = JSON.stringify(roles);
            utils.removeLocalStorage(constants.PARTNER_REFERRAL_CODE_KEY);
            utils.setDataInLocalStorage(data);

            //const subdomain = get(accounts, [accountId, 'selected_organisation', 'subdomain']);

            //dispatch the settings success action
            yield put({ type: actionTypes.GET_SETTINGS_SUCCESS, result });

            const roleSlug = roles[accountId].slug;
            switch (roleSlug) {
                case constants.USER_ROLE_ADMIN_MANAGER:
                case constants.USER_ROLE_ADMIN: {
                    //dispatch the login success action
                    yield put({ type: actionTypes.USER_LOGIN_SUCCESS, result, roles, isAdmin: true });

                    utils.redirect('/admin/dashboard');
                    break;
                }

                case constants.USER_ROLE_DEBTOR:
                case constants.USER_ROLE_CUSTOMER_MANAGER:
                case constants.USER_ROLE_CUSTOMER: {
                    let selectedOrganisation = get(
                        accounts,
                        utils.replaceValueInArray(
                            successMappings.GET_USER_REGISTER_LOGIN_SELECTED_ORGANISATION,
                            constants.ACCOUNT_ID_PLACEHOLDER,
                            accountId
                        )
                    );

                    if (selectedOrganisation) {
                        //dispatch the selected organisation success action
                        yield put({
                            type: actionTypes.GET_SELECTED_ORGANISATION_SUCCESS,
                            selectedOrganisation: selectedOrganisation,
                            selectedOrganisationId: selectedOrganisation.id,
                        });
                    }

                    //dispatch the re-authorization organisation success action
                    yield put({
                        type: actionTypes.GET_REAUTHORIZE_ORGANISATIONS_COUNT_SUCCESS,
                        reauthorizeOrganisationsCount: get(
                            accounts,
                            utils.replaceValueInArray(
                                successMappings.GET_USER_REGISTER_LOGIN_REAUTHORIZE_ORGANISATIONS_COUNT,
                                constants.ACCOUNT_ID_PLACEHOLDER,
                                accountId
                            )
                        ),
                    });

                    // }
                    //dispatch the login success action
                    yield put({ type: actionTypes.USER_LOGIN_SUCCESS, result, roles });

                    // extract and set account permissions to the reducer
                    const permissions = utils.createPermissionDict(
                        get(result, [
                            ...successMappings._DEFAULT_API_DATA_RESPONSE_STRUCTURE,
                            'user_details',
                            'accounts',
                            accountId,
                            'account_details',
                            'subscription',
                            'plan',
                            'permission_guards',
                        ])
                    );
                    yield put({ type: actionTypes.SET_ACCOUNT_PERMISSIONS, permissions });
                    const dashboardRoute =
                        roleSlug === constants.USER_ROLE_DEBTOR
                            ? silent
                                ? window.location.pathname.indexOf('debtor') !== -1
                                    ? window.location.pathname
                                    : `/debtor${window.location.pathname}`
                                : '/debtor/dashboard'
                            : '/dashboard';
                    dashboardRoute && utils.redirect(isMultipleAccounts ? '/select-account' : dashboardRoute);

                    break;
                }

                case constants.USER_ROLE_DEBT_COLLECTOR: {
                    yield put({ type: actionTypes.USER_LOGIN_SUCCESS, result, roles });
                    utils.redirect('/debt-collector/dashboard');
                    break;
                }
                // NOTE: in case we need to handle debtor login separately
                // case constants.USER_ROLE_DEBTOR: {
                //     yield put({ type: actionTypes.USER_LOGIN_SUCCESS, result, roles });
                //     if (!silent) utils.redirect('/debtor/dashboard');
                //     break;
                // }
                default: {
                    break;
                }
            }

            yield put({
                type: actionTypes.SET_CURRENT_ACCOUNT_ID,
                accountId,
            });

            //dispatch the notification action
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: successMessages.USER_LOGGED_IN_SUCCESS,
                messageType: constants.SUCCESS,
            });

            callback();
        } catch (errors) {
            console.log(errors);
            //dispatch the login errors
            yield put(
                stopSubmit(
                    constants.LOGIN_FORM_NAME,
                    this.getApiErrors(
                        errors,
                        constants.USER_LOGIN_DEFAULT_ERROR,
                        constants.USER_LOGIN_DEFAULT_ERROR,
                        [],
                        '',
                        true
                    )
                )
            );

            //dispatch the login failure action
            yield put({ type: actionTypes.USER_LOGIN_FAILED, errors });
        }
    }

    /**
     * Do the Logout Operation
     *
     */
    *logout(action) {
        try {
            //Call logout Api
            if (!get(action, 'payload.authorizationFailed', false)) {
                UserApi.logout(
                    utils.getLocalStorageValue(constants.USER_TOKEN_KEY),
                    utils.getLocalStorageValue(constants.USER_LOCALE_KEY)
                );
            }

            //remove the tokens stored in local storage
            utils.removeLocalStorageAll([
                constants.USER_ACCOUNT_TOKENS_KEY,
                constants.USER_ACCOUNTING_SOFTWARE_ID_KEY,
                constants.USER_CURRENT_ACCOUNT_KEY,
                constants.USER_TOKEN_KEY,
                constants.USER_SELECTED_ORGANISATION_ID_KEY,
                constants.ORGANISATION_PAYMENT_ACCOUNT_POPUP_SHOWN_KEY,
                constants.REDIRECT_URL_AFTER_VERIFY,
            ]);

            const authorizationFailed = get(action, errorMappings.AUTHORIZATION_FAILED_ERROR);
            const authorizationFailedMessage = get(
                action,
                errorMappings.AUTHORIZATION_FAILED_ERROR_MESSAGE,
                errorMessages.USER_SESSION_TIMED_OUT
            );

            let message = successMessages.USER_LOGGED_OUT_SUCCESS;
            let messageType = constants.SUCCESS;

            if (authorizationFailed) {
                message = authorizationFailedMessage;
                messageType = constants.ERROR;
            }
            if (!action.payload.silent) {
                utils.redirect('/');
                //dispatch the logged out action
                yield put({
                    type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                    message: message,
                    messageType: messageType,
                });
            }

            //dispatch the stop loader action
            yield put({
                type: actionTypes.GET_INITIAL_DATA_STOP_LOADING,
            });
        } catch (Errors) {
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: errorMessages.USER_LOG_OUT_FAILED_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Do the Registration Operation
     *
     */
    *registerUser({ payload, setIsInSecondStep }) {
        try {
            const result = yield call(UserApi.registerUser, payload);
            const accessToken = get(result, successMappings.USER_LOGIN_GET_ACCESS_TOKEN, '');
            const accounts = get(result, successMappings.USER_LOGIN_GET_ACCOUNTS, {});
            const roles = get(result, 'data.data.user_details.roles', {});
            const orderId = get(result, successMappings.REGISTRATION_ORDER_ID);

            const data = {
                [constants.USER_TOKEN_KEY]: accessToken,
                [constants.USER_CURRENT_ACCOUNT_KEY]: Object.keys(accounts)[0],
                [constants.USER_ROLES_KEY]: JSON.stringify(roles),
            };

            if (payload.accounting_software && payload.accounting_software !== constants.ACCOUNTING_SOFTWARE_OTHER) {
                data[constants.USER_ACCOUNTING_SOFTWARE_ID_KEY] = payload.accounting_software;
            }

            utils.setDataInLocalStorage(data);

            let accountId = Object.keys(accounts)[0];

            if (accountId) {
                let selectedOrganisation = get(
                    accounts,
                    utils.replaceValueInArray(
                        successMappings.GET_USER_REGISTER_LOGIN_SELECTED_ORGANISATION,
                        constants.ACCOUNT_ID_PLACEHOLDER,
                        accountId
                    )
                );

                if (selectedOrganisation) {
                    //dispatch the selected organisation success action
                    yield put({
                        type: actionTypes.GET_SELECTED_ORGANISATION_SUCCESS,
                        selectedOrganisation: selectedOrganisation,
                        selectedOrganisationId: selectedOrganisation.id,
                    });
                }

                //dispatch the re-authorization organisation success action
                yield put({
                    type: actionTypes.GET_REAUTHORIZE_ORGANISATIONS_COUNT_SUCCESS,
                    reauthorizeOrganisationsCount: get(
                        accounts,
                        utils.replaceValueInArray(
                            successMappings.GET_USER_REGISTER_LOGIN_REAUTHORIZE_ORGANISATIONS_COUNT,
                            constants.ACCOUNT_ID_PLACEHOLDER,
                            accountId
                        )
                    ),
                });
            }

            yield put({
                type: actionTypes.SET_CURRENT_ACCOUNT_ID,
                accountId,
            });

            yield put({ type: actionTypes.USER_REGISTRATION_SUCCESS, result, roles });

            //dispatch the notification action
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message:
                    payload.accounting_software && payload.accounting_software !== constants.ACCOUNTING_SOFTWARE_OTHER
                        ? successMessages.USER_REGISTRATION_WITH_ACCOUNTING_SOFTWARE_SUCCESS
                        : successMessages.USER_REGISTRATION_SUCCESS,
                messageType: constants.SUCCESS,
            });

            if (accountId && utils.getLocalStorageValue(constants.PARTNER_REFERRAL_CODE_KEY)) {
                const accountDetails = get(accounts, [accountId, 'account_details']);
                // fire up impact tracking
                if (accountDetails?.id && accountDetails?.hashed_email && orderId) {
                    trackConversion(accountDetails?.id, accountDetails?.hashed_email, orderId);
                }
            }

            utils.removeLocalStorage(constants.PARTNER_REFERRAL_CODE_KEY);

            // fire up analytics
            // segmentAnalytics.signup(get(result, successMappings.USER_DETAILS), accountId);

            // redirect to connect page if the user has selected any accounting software other than other
            const planId = utils.getValueFormStore('subscription.selectedPlanId');

            if (payload.registration_type === 'debtor') {
                utils.redirect(`/debtor${window.location.pathname}`);
                return;
            }

            if (planId) {
                utils.redirect('/plan/buy', false, { plan_id: planId });
            } else if (
                payload.accounting_software &&
                payload.accounting_software !== constants.ACCOUNTING_SOFTWARE_OTHER
            ) {
                utils.redirect('/connect');
            } else {
                utils.redirect('/my-profile');
            }
        } catch (errors) {
            if (isFunction(setIsInSecondStep)) {
                setIsInSecondStep(false);
            }
            yield this.stopFormSubmit(
                errors,
                constants.REGISTRATION_FORM_NAME,
                constants.USER_REGISTRATION_DEFAULT_ERROR,
                constants.USER_REGISTRATION_DEFAULT_ERROR
            );
            yield put({ type: actionTypes.USER_REGISTRATION_FAILED, errors });
        }
    }

    /**
     * Send response action when meeting is not scheduled by the user
     */
    *registerUserMeetingNotScheduled() {
        yield put(
            stopSubmit(
                constants.REGISTRATION_FORM_NAME,
                this.getApiErrors('', '', constants.USER_REGISTRATION_MEETING_NOT_SCHEDULED_ERROR)
            )
        );
    }

    /**
     * Resend the activation email
     * Calls the API to resend activation email
     */
    *resendActivationEmail({ payload }) {
        try {
            const result = yield call(UserApi.resendActivationEmail, payload);
            yield this.showNotificationModal(result, true);
            yield put({ type: actionTypes.RESEND_ACTIVATION_EMAIL_SUCCESS, result });
        } catch (errors) {
            yield this.showNotificationModal(
                errors,
                false,
                constants.RESEND_ACTIVATION_EMAIL_DEFAULT_ERROR,
                constants.RESEND_ACTIVATION_EMAIL_DEFAULT_ERROR
            );
            yield put({ type: actionTypes.RESEND_ACTIVATION_EMAIL_FAILURE, errors });
        }
    }

    /**
     * Resend the verification email
     * Calls the API to resend verification email
     */
    *resendVerificationEmail() {
        try {
            const result = yield call(UserApi.resendVerificationEmail);
            yield put({ type: actionTypes.RESEND_VERIFICATION_EMAIL_SUCCESS, result });
            yield put(showNotificationModal(result, true));
        } catch (errors) {
            yield put(showNotificationModal(errors, false, constants.RESEND_VERIFICATION_EMAIL_ERROR));
            yield put({ type: actionTypes.RESEND_VERIFICATION_EMAIL_FAILURE, errors });
        }
    }

    /**
     * Resend the verification SMS
     * Calls the API to resend verification sms
     */
    *resendVerificationSms({ payload }) {
        try {
            const result = yield call(UserApi.resendVerificationSms, payload);
            yield put({ type: actionTypes.RESEND_VERIFICATION_SMS_SUCCESS, result });
            yield put(showNotificationModal(result, true));
        } catch (errors) {
            yield put(showNotificationModal(errors, false, constants.RESEND_VERIFICATION_SMS_ERROR));
            yield put({ type: actionTypes.RESEND_VERIFICATION_SMS_FAILURE, errors });
        }
    }

    /**
     * Password Reset
     *
     * Resets password
     */

    *resetPassword({ payload }) {
        const validate = get(payload, 'validate', false);
        try {
            const response = yield call(UserApi.resetPassword, payload);
            if (!validate) {
                //dispatch the notification action
                yield put({
                    type: actionTypes.SHOW_NOTIFICATION_MODAL,
                    message: get(response, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                    messageType: constants.SUCCESS,
                });
                utils.redirect('/login');
            }
            yield put({ type: actionTypes.USER_RESET_PASSWORD_SUCCESS, response });
            yield put({ type: actionTypes.USER_VALIDATE_RESET_PASSWORD_SUCCESS, response });
        } catch (errors) {
            yield this.stopFormSubmit(errors, constants.RESET_PASSWORD_FORM_NAME, 'RESET_PASSWORD_ERROR');
            if (validate) {
                utils.redirect('/login');
            }
            yield put({ type: actionTypes.USER_RESET_PASSWORD_FAILURE, errors });
            yield put({ type: actionTypes.USER_VALIDATE_RESET_PASSWORD_FAILURE, errors });
        }
    }

    /**
     * Reset the two factor authentication
     *
     * Calls the API to reset two factor authentication
     */
    *resetTwoFactorAuthentication({ payload }) {
        try {
            const result = yield call(UserApi.resetTwoFactorAuthentication, payload);
            yield this.showNotificationModal(result, true);
            yield put({ type: actionTypes.RESET_TWO_FACTOR_AUTHENTICATION_SUCCESS, result });
        } catch (errors) {
            yield this.showNotificationModal(
                errors,
                false,
                constants.RESET_TWO_FACTOR_AUTHENTICATION_DEFAULT_ERROR,
                constants.RESET_TWO_FACTOR_AUTHENTICATION_DEFAULT_ERROR
            );
            yield put({ type: actionTypes.RESET_TWO_FACTOR_AUTHENTICATION_FAILURE, errors });
        }
    }

    /**
     * Update the user profile
     * Calls the API for updating user profile
     * Sends response/notification based on the response success or failure
     */
    *updateUserProfile(action) {
        try {
            const result = yield call(UserApi.editUser, action.payload);
            yield put({ type: actionTypes.USER_PROFILE_UPDATE_SUCCESS, result });

            //reset any errors set in form
            yield put(stopSubmit(constants.EDIT_USER_FORM_NAME, {}));

            //reset the avatar value
            yield put(
                change(
                    constants.EDIT_USER_FORM_NAME,
                    requestKeys.AVATAR,
                    get(result, successMappings.GET_USER_AVATAR, '')
                )
            );

            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            const currentAccountId = getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY);
            if (['admin', 'admin-manager'].includes(getValueFormStore(`user.roles.${currentAccountId}.slug`, ''))) {
                redirect('/admin/my-profile');
            } else if (['debtor'].includes(getValueFormStore(`user.roles.${currentAccountId}.slug`, ''))) {
                redirect('/debtor/my-profile');
            } else {
                redirect('/my-profile');
            }
        } catch (errors) {
            // dispatch the profile update errors
            yield put(
                stopSubmit(
                    constants.EDIT_USER_FORM_NAME,
                    this.getApiErrors(errors, constants.USER_UPDATE_DEFAULT_ERROR, constants.USER_UPDATE_DEFAULT_ERROR)
                )
            );

            //dispatch the login failure action
            yield put({ type: actionTypes.USER_PROFILE_UPDATE_FAILURE, errors });
        }
    }

    /**
     * Verify the user phone number
     * Calls the verify phone number API
     *
     * @param   {object}    action    Verify Phone Number Action Object
     */
    *verifyPhoneNumber(action) {
        try {
            const result = yield call(UserApi.verifyPhoneNumber, action.payload);
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: VERIFY_PHONE_NUMBER_SUCCESS,
                messageType: constants.SUCCESS,
            });
            yield put({ type: actionTypes.VERIFY_PHONE_NUMBER_SUCCESS, result });
            //reset any errors set in form
            yield put(stopSubmit(constants.VERIFY_PHONE_NUMBER_FORM, {}));

            const returnUrlFromLS = utils.getLocalStorageValue(REDIRECT_URL_AFTER_VERIFY);
            // if there is a return url set, redirect to that path
            if (returnUrlFromLS) {
                utils.removeLocalStorage(REDIRECT_URL_AFTER_VERIFY);
                redirect(returnUrlFromLS);
            } else {
                redirect('/');
            }
            yield put(showNotificationModal(result, true));
        } catch (errors) {
            //dispatch the verify phone number errors
            yield put(
                stopFormSubmit(errors, constants.VERIFY_PHONE_NUMBER_FORM, constants.VERIFY_PHONE_NUMBER_DEFAULT_ERROR)
            );

            //dispatch the verify phone number failure action
            yield put({ type: actionTypes.VERIFY_PHONE_NUMBER_FAILURE, errors });
        }
    }

    /**
     * Verify email address
     *
     * Verifies email address
     */
    *verifyEmailAddress(action) {
        try {
            const result = yield call(UserApi.verifyEmailAddress, action.payload);
            yield put({ type: actionTypes.VERIFY_EMAIL_ADDRESS_SUCCESS, result });
        } catch (errors) {
            yield put(
                showNotificationModal(errors, false, constants.VERIFY_EMAIL_ERROR, '', '', {
                    onOk: () => {
                        redirect('/verify-email');
                    },
                })
            );
            yield put({ type: actionTypes.VERIFY_EMAIL_ADDRESS_FAILURE, errors });
        }
    }

    /**
     * Add user
     *
     * Creates new user
     */
    *addUser({ payload }) {
        try {
            const result = yield call(UserApi.addUser, payload);
            const currentAccountId = utils.getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY);

            yield put({ type: actionTypes.ADD_USER_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });

            // fire up analytics
            if (utils.getValueFormStore(`user.roles.${currentAccountId}.slug`, '') !== 'admin') {
                segmentAnalytics.addUser();
            }

            utils.getValueFormStore(`user.roles.${currentAccountId}.slug`, '') === 'admin'
                ? payload.isRole
                    ? utils.redirect('/admin/users')
                    : utils.redirect(`/admin/accounts/user/${payload.accountId}`)
                : utils.redirect('/users');
        } catch (errors) {
            yield put({ type: actionTypes.ADD_USER_FAILURE, errors });
            yield this.stopFormSubmit(errors, constants.ADD_EDIT_USER_FORM, 'USER_ADD_DEFAULT_ERROR');
        }
    }
    /**
     * Get users
     *
     * Fetches users list
     */
    *getUsers(action) {
        try {
            const result = yield call(UserApi.getUsers, action.payload);
            yield put({ type: actionTypes.GET_USERS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_USERS_FAILURE, errors });
        }
    }

    /**
     * Get UserRoles
     *
     * Fetches user roles list
     */
    *getUserRoles({ payload }) {
        try {
            const result = yield call(UserApi.getUserRoles, payload);
            yield put({ type: actionTypes.GET_USER_ROLES_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_USER_ROLES_FAILURE, errors });
        }
    }

    /**
     * Get UserRoles of an account
     *
     * Fetches user roles list of an account
     */
    *getUserAccountRoles({ payload }) {
        try {
            const result = yield call(UserApi.getUserAccountRoles, payload);
            yield put({ type: actionTypes.GET_USER_ACCOUNT_ROLES_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_USER_ACCOUNT_ROLES_FAILURE, errors });
        }
    }

    /**
     * Get user details
     *
     * Fetches user details
     */
    *getUserDetails(action) {
        try {
            const result = yield call(UserApi.getUserDetails, action.payload);
            let userDetails = get(result, successMappings.USER_DETAILS);
            const currentAccountId = utils.getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY);
            userDetails['role'] = get(userDetails, ['roles', currentAccountId, 'slug'], '');
            yield put({ type: actionTypes.GET_USER_DETAILS_SUCCESS, userDetails });
        } catch (errors) {
            yield put({ type: actionTypes.GET_USER_DETAILS_FAILURE, errors });
        }
    }

    /**
     * Delete user
     *
     * Deletes a user
     */
    *deleteUser(action) {
        try {
            const result = yield call(UserApi.deleteUser, action.payload);
            yield put({ type: actionTypes.DELETE_USER_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: get(errors, errorMappings.DELETE_USER_ERROR_RESPONSE_STRUCTURE),
                messageType: constants.ERROR,
            });
            yield put({ type: actionTypes.DELETE_USER_FAILURE, errors });
        }
    }
    /**
     * Update user details
     *
     * Updates user details
     */
    *updateUserDetails({ payload }) {
        try {
            const currentAccountId = utils.getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY);
            const result = yield call(UserApi.editUser, payload);

            yield put({ type: actionTypes.USER_UPDATE_SUCCESS, result });

            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });

            //reset the avatar value
            yield put(
                change(
                    constants.ADD_EDIT_USER_FORM,
                    requestKeys.AVATAR,
                    get(result, successMappings.GET_USER_AVATAR, '')
                )
            );
            utils.getValueFormStore(`user.roles.${currentAccountId}.slug`, '') === 'admin'
                ? payload.isRole
                    ? utils.redirect('/admin/users')
                    : utils.redirect(`/admin/accounts/user/${payload.accountId}`)
                : utils.redirect('/users');
        } catch (errors) {
            //dispatch the login failure action
            yield put({ type: actionTypes.USER_UPDATE_FAILURE, errors });
            yield this.stopFormSubmit(errors, constants.ADD_EDIT_USER_FORM, 'USER_UPDATE_DEFAULT_ERROR');
        }
    }

    /**
     * Request quote
     *
     * Requests a subscription plat quote
     */
    *requestQuote({ payload, callback }) {
        try {
            const result = yield call(UserApi.requestQuote, payload);

            yield put({ type: actionTypes.REQUEST_QUOTE_SUCCESS, result });

            callback && callback();
        } catch (errors) {
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: errorMessages.UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });

            yield put(stopSubmit(constants.REQUEST_QUOTE_FORM_NAME, errors.response.data.errors));
            yield put({ type: actionTypes.REQUEST_QUOTE_FAILURE, errors });
        }
    }

    /**
     * Get FAQ
     *
     * Fetches FAQs
     */
    *getFAQ() {
        try {
            const result = yield call(UserApi.getFAQ);

            yield put({ type: actionTypes.GET_FAQ_SUCCESS, result });
        } catch (errors) {
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: errorMessages.UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
            yield put({ type: actionTypes.GET_FAQ_FAILURE, errors });
        }
    }

    /**
     * Activate account
     *
     * Activate an account created by another user or admin
     */
    *activateAccount({ payload }) {
        try {
            const result = yield call(UserApi.activateAccount, payload);

            yield put({ type: actionTypes.ACTIVATE_ACCOUNT_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            utils.redirect('/');
        } catch (errors) {
            const formErrors = errors.response.data.errors;
            yield put(stopSubmit(constants.ACTIVATE_ACCOUNT_FORM, formErrors));

            const nonFieldErrors = omit(formErrors, ['password', 'confirm_password']);
            !isEmpty(nonFieldErrors) &&
                error({
                    title: 'Errors',
                    content: Object.values(nonFieldErrors).map(err => <p key={err[0]}>{err}</p>),
                });
            yield put({ type: actionTypes.ACTIVATE_ACCOUNT_FAILURE, errors });
        }
    }
}
//export the user saga
export default new User().forkAllWatcherFunctions();

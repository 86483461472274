/**
 * Payment arrangement installment invoices Component
 *
 * @since 2.0.0
 */

//import required modules
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/shared/ScrollableTable';
import useInvoiceLinkGenerator from 'includes/hooks/useInvoiceLinkGenerator';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { paymentArrangementInstallmentInvoicesColumns } from 'includes/tableColumns/paymentArrangementInstallmentInvoices';

const PaymentArrangementInstallmentInvoices = props => {
    const { getNumberFormattedWithPaymentArrangementCurrency, invoices } = props;
    const getInvoiceDetailsLink = useInvoiceLinkGenerator();
    const paymentArrangement = useSelector(state => state.paymentArrangement.paymentArrangement);

    /* Get the invoice link
     *
     * @param {object} paymentArrangementInvoice: Payment arrangement invoice
     *
     * @returns {string} Invoice Link
     */
    const getInvoiceLink = paymentArrangementInvoice =>
        getInvoiceDetailsLink(paymentArrangement?.contact?.id, paymentArrangementInvoice?.uuid);

    /**
     * Modify and return the payment arrangement installment invoices data
     */
    const getPaymentArrangementInstallmentInvoicesData = () =>
        invoices.map(invoice => ({
            ...invoice,
            invoice_number: (
                <Link to={getInvoiceLink(invoice)} key={invoice.invoice_number} target="_blank">
                    {invoice.invoice_number}
                </Link>
            ),
            amount: getNumberFormattedWithPaymentArrangementCurrency(invoice.amount),
        }));

    /**
     * Memoized payment arrangement installment invoices data
     */
    const memoizedPaymentArrangementInstallmentInvoicesData = useMemo(
        () => getPaymentArrangementInstallmentInvoicesData(),
        [getPaymentArrangementInstallmentInvoicesData]
    );

    return (
        <div className="invoice-line-items-table no-min-height-table">
            <Table
                columns={paymentArrangementInstallmentInvoicesColumns}
                dataSource={memoizedPaymentArrangementInstallmentInvoicesData}
                pagination={false}
                showMobileTableView={false}
            />
        </div>
    );
};

PaymentArrangementInstallmentInvoices.propTypes = {
    getNumberFormattedWithPaymentArrangementCurrency: PropTypes.func,
    invoices: PropTypes.any,
    t: PropTypes.func,
};

export default PaymentArrangementInstallmentInvoices;

import PageTitle from 'components/PageTitle';
import WhiteBoxWrapper from 'components/WhiteBoxWrapper';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { redirect } from 'includes/utils';
import React from 'react';
import NoOrganisationSVG from 'assets/images/no-organisation.svg';

/**
 * No organisation assigned page
 *
 * Page to display no organisation assigned message if a custom role user with no organisation assigned to him/her logs in
 *
 * @since 2.8.0
 */
export default function NoOrganisation() {
    const selectedOrganisationId = useOrganisationId();

    if (selectedOrganisationId) {
        redirect('/dashboard');
        return;
    }

    return (
        <div className="container mx-auto">
            <div className="my-7">
                <PageTitle>No organisations assigned</PageTitle>
            </div>

            <WhiteBoxWrapper className={'text-center'}>
                <img src={NoOrganisationSVG} alt="No organisation" className="mx-auto mb-4" />
                There are no organisations assigned to you. Please contact administrator.
            </WhiteBoxWrapper>
        </div>
    );
}

/**
 * Campaign component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';
import { get, find } from 'lodash';

import Button from '../../shared/Button';
import Table from '../../shared/ScrollableTable';
import { getCampaigns, changeCampaignStatus } from '../../../includes/redux/actions/customer/reminder';
import { getCampaignTableColumns } from '../../../includes/constants/columns';
import { getContactTypes } from '../../../includes/slices/contact';
import { useAddOrganisationPopup, useDataFromStore } from '../../../includes/utils/hooks';
import {
    getCommunicationTemplateTypes,
    getCommunicationTypes,
} from '../../../includes/redux/actions/shared/communication';
import './styles.scss';
import { redirect } from 'includes/utils';
import PermissionGuard from 'components/PermissionGuard';
import { CAMPAIGN } from 'includes/constants/permissions';

/**
 * Campaign Listing Component
 */
const Campaign = props => {
    const organisationId = props.organisationId || props.match.params.organisationId;
    const params = useParams();
    const { accountId } = params;
    const [selectedCampaignId, setSelectedCampaignId] = useState('');
    const { getCampaigns, isAdmin, notificationTypes } = props;

    // check if organisation is added
    useAddOrganisationPopup(organisationId);

    // get the template types
    useDataFromStore({
        reducer: 'communication',
        dataKey: 'templateTypes',
        loadingKey: 'isTemplateTypesLoading',
        action: getCommunicationTemplateTypes,
        dataToSend: organisationId ? organisationId : null,
    });

    // get the communication types
    useDataFromStore({
        reducer: 'communication',
        dataKey: 'communicationTypes',
        loadingKey: 'isTypesLoading',
        action: getCommunicationTypes,
    });

    /* Fetch scheduler
     */
    const fetchTableData = (data = {}) => {
        let searchParams = { ...data };
        getCampaigns({
            ...searchParams,
            organisation_id: organisationId,
            campaign_id: props.campaignId,
        });
    };

    const { scheduler, templateTypes, isCampaignStatusChanging, changeCampaignStatus, t } = props;

    /**
     * Get the formatted table data
     */
    const getFormattedTableData = useCallback(
        () =>
            scheduler.map(s => {
                const notificationType = find(notificationTypes, { id: s.notification_type_id });
                return {
                    ...s,
                    day_description: s.day_description,
                    status: t(`customerMessages.campaign.list.status.${s.is_active ? 'active' : 'inactive'}`),
                    type: (
                        <div className="tag-linker table-button-wrapper">
                            <button className={get(notificationType, 'slug')}>{get(notificationType, 'type')}</button>
                        </div>
                    ),
                    actions: (
                        <div className="campaign-actions">
                            <PermissionGuard requiredPermission={CAMPAIGN.EDIT}>
                                <Button
                                    className="blue-bg-button"
                                    filled
                                    onClick={() =>
                                        redirect(
                                            isAdmin
                                                ? organisationId
                                                    ? `/admin/accounts/edit-campaign/${accountId}/${organisationId}/${s.id}`
                                                    : `/admin/campaigns/edit-campaign/${s.id}`
                                                : `/reminders/campaign/${s.id}`
                                        )
                                    }
                                >
                                    {t('customerMessages.campaign.list.viewEdit')}
                                </Button>
                            </PermissionGuard>
                            <PermissionGuard requiredPermission={CAMPAIGN.ENABLE_DISABLE}>
                                <Button
                                    className="blue-bg-button"
                                    loading={selectedCampaignId === s.id && isCampaignStatusChanging}
                                    submittingButtonLabel={s.is_active ? 'Disabling' : 'Enabling'}
                                    onClick={() => {
                                        setSelectedCampaignId(s.id);
                                        changeCampaignStatus(s.id, !s.is_active, s.organisation_id);
                                    }}
                                >
                                    {t(`customerMessages.campaign.list.${s.is_active ? 'disable' : 'enable'}`)}
                                </Button>
                            </PermissionGuard>
                        </div>
                    ),
                    templateType: get(find(templateTypes, { id: s.communication_template_type_id }), 'type'),
                };
            }),
        [scheduler, notificationTypes, templateTypes, isCampaignStatusChanging] // eslint-disable-line react-hooks/exhaustive-deps
    );

    // memoize table data
    const campaignTableData = useMemo(() => getFormattedTableData(), [getFormattedTableData]);

    return (
        <div className="home-content-wrapper schedular-wrapper">
            {!props.campaignId && props.path === 'admin' && (
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={`/admin/accounts/organisations/${accountId}`}>
                                {props.t('customerMessages.campaign.breadcrumb.organisations')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{props.t('customerMessages.campaign.breadcrumb.campaigns')}</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            {!props.campaignId ? <h2 className="page-title">Notifications</h2> : ''}
            {(organisationId || isAdmin) && (
                <div className={`full-wrapper box-wrapper ${!props.campaignId ? 'white-box-wrapper' : ''}`}>
                    <section className="right-menu-wrapper">
                        <div className="right-align">
                            <PermissionGuard requiredPermission={CAMPAIGN.ADD}>
                                <Button
                                    className="green-bg-button"
                                    filled
                                    onClick={() =>
                                        redirect(
                                            isAdmin
                                                ? organisationId
                                                    ? `/admin/accounts/${accountId}/${organisationId}/add-campaign/`
                                                    : `/admin/campaigns/add-campaign/`
                                                : `/reminders/campaign/add-campaign`
                                        )
                                    }
                                >
                                    {/* {t('customerMessages.campaign.list.add')} */}
                                    Add Notification
                                </Button>
                            </PermissionGuard>
                        </div>
                    </section>
                    <Table
                        columns={getCampaignTableColumns(props)}
                        dataSource={campaignTableData}
                        rowKey="id"
                        size="middle"
                        loading={props.isLoading}
                        paginationData={props.paginationCampaign}
                        getDataMethod={fetchTableData}
                        {...props}
                    />
                </div>
            )}
        </div>
    );
};

/**
 * Default props
 */
Campaign.defaultProps = {
    contactTypes: [],
    notificationTypes: [],
    scheduler: [],
};

/**
 * Proptypes
 */
Campaign.propTypes = {
    campaignId: PropTypes.string,
    changeCampaignStatus: PropTypes.func,
    contactId: PropTypes.string,
    contactTypes: PropTypes.array,
    getCampaigns: PropTypes.func,
    getCommunicationTemplateTypes: PropTypes.func,
    getContactTypes: PropTypes.func,
    getReminderTypes: PropTypes.func,
    isAdmin: PropTypes.bool,
    isCampaignStatusChanging: PropTypes.bool,
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    notificationTypes: PropTypes.array,
    organisationId: PropTypes.string,
    paginationCampaign: PropTypes.object,
    path: PropTypes.string,
    scheduler: PropTypes.array,
    t: PropTypes.func,
    templateTypes: PropTypes.array,
};

/**
 * Connect to store
 */
export default connect(
    state => ({
        ...state.reminder,
        contactTypes: state.contacts.types,
        isAdmin: state.account.isAdmin,
        isInvoiceStatusesLoading: state.invoices.isInvoiceStatusesLoading,
        loginLoading: state.user.loginLoading,
        organisationId: state.organisation.selectedOrganisationId,
        roles: state.user.roles,
        templateTypes: state.communication.templateTypes,
        userDetails: state.user.userDetails,
        notificationTypes: state.communication.communicationTypes,
    }),
    {
        changeCampaignStatus,
        getCampaigns,
        getContactTypes,
    }
)(withTranslation()(Campaign));

/**
 * Templates Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import {
    React,
    useState,
    useMemo,
    connect,
    Link,
    useCallback,
    useEffect,
    useSelector,
} from '../../../includes/exports/react';
import { useDispatch } from 'react-redux';
import { get, find } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation, withTranslation } from 'react-i18next';
import { Modal, Breadcrumb, Skeleton } from 'antd';
import Button from '../Button';
import Table from '../ScrollableTable';
import './styles.scss';
import { TEMPLATES_TABLE } from '../../../includes/constants/columns';
import SearchBlock from '../SearchBlock';
import { useIsMount } from '../../../includes/utils/hooks';
import InfoMessage from '../messages/InfoMessage';
import PermissionGuard from 'components/PermissionGuard';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import useCommunicationTypes from 'includes/hooks/useCommunicationTypes';
import useCommunicationTemplateTypes from 'includes/hooks/useCommunicationTemplateTypes';
import useCommunicationTemplates from 'includes/hooks/useCommunicationTemplates';
import { WHATSAPP } from 'includes/constants/keys/request';
import { showNotificationPopup } from 'includes/redux/actions/shared/common';

const { confirm } = Modal;
/**
 * Templates Component
 * List the communication templates
 *
 * @returns    {string}    Email view
 */
const Templates = props => {
    // default states
    const [searchParam, setSearchParam] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { isAdmin, type, types, permissionSet } = props;
    const organisationId = props.organisationId || props.match.params.organisationId;
    const { accountId } = props.match.params;
    const isMount = useIsMount();
    const communicationTemplates = useSelector(state => state.communication.communicationTemplates);
    const {
        data: communicationTemplateTypes,
        isLoading: isCommunicationTemplateTypesLoading,
    } = useCommunicationTemplateTypes();
    const { data: communicationTypes, isLoading: isCommunicationTypesLoading } = useCommunicationTypes();
    const { reset: clearCommunicationTemplatesCache } = useCommunicationTemplates(false);
    const typeId = get(find(communicationTypes, { slug: type }), 'id');
    /**
     * Fetch the templates
     */
    useEffect(() => {
        // call the API if not on mount
        if (!isMount) {
            props.getCommunicationTemplates(organisationId, typeId);
        }
    }, [typeId]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Delete the communication template
     *
     * @param {string} templateId Template Id
     */
    const deleteTemplate = templateId => {
        confirm({
            title: props.t(`sharedMessages.communication.templates.table.message.confirmDelete`),
            content: '',
            onOk: () => {
                props.deleteCommunicationTemplate(organisationId, templateId);
                clearCommunicationTemplatesCache();
            },
        });
    };

    /**
     * Format the templates data
     */
    const formatTemplatesData = useCallback(() => {
        return communicationTemplates.map(template => {
            return {
                ...template,
                name: (
                    <div className="wrapped-content-text">
                        {template.name}
                        <span className=" crud-wrapper">
                            <PermissionGuard requiredPermission={permissionSet.EDIT}>
                                <span
                                    className="crud-link"
                                    onClick={() => {
                                        props.setListView(false);
                                        props.setInitialValues(template);
                                    }}
                                >
                                    {props.t(`sharedMessages.communication.templates.table.action.edit`)}
                                </span>
                            </PermissionGuard>

                            {!props.disableTemplateDelete &&
                            (template.is_custom || (isAdmin && !template.is_internal)) ? (
                                <PermissionGuard requiredPermission={permissionSet.DELETE}>
                                    <span onClick={() => deleteTemplate(template.id)} className="crud-link">
                                        {props.t(`sharedMessages.communication.templates.table.action.delete`)}
                                    </span>
                                </PermissionGuard>
                            ) : (
                                ''
                            )}
                        </span>
                    </div>
                ),
                type: get(find(communicationTemplateTypes, { id: template.template_type_id }), 'type'),
            };
        });
    }, [communicationTemplates, communicationTemplateTypes, types]); // eslint-disable-line react-hooks/exhaustive-deps

    // memoize template data
    const memoizedCommunicationTemplates = useMemo(() => formatTemplatesData(), [formatTemplatesData]);

    /**
     * Render the component
     */
    return (
        <div className={isAdmin ? 'white-box-wrapper full-wrapper' : ''}>
            {isAdmin && organisationId && (
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={`/admin/accounts/organisations/${accountId}`}>
                                {props.t(`sharedMessages.communication.templates.breadcrumb.organisations`)}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {props.t(`sharedMessages.communication.templates.${type}.templates`)}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            <div
                className={
                    isAdmin
                        ? 'my-profile-detail settings-edit-form admin-templates'
                        : 'my-profile-detail settings-edit-form'
                }
            >
                {!isAdmin && <header>{props.t(`sharedMessages.communication.templates.${type}.title`)}</header>}
                {isCommunicationTypesLoading || isCommunicationTemplateTypesLoading ? (
                    <Skeleton paragraph={5} />
                ) : typeId ? (
                    <>
                        <div className="search-filter-export-wrapper">
                            <ul className="selector-field">
                                <li style={{ textAlign: 'left' }}>
                                    <PermissionGuard requiredPermission={permissionSet.ADD}>
                                        <Button
                                            className="white-bg-button"
                                            onClick={() => {
                                                if (type === WHATSAPP) {
                                                    dispatch(
                                                        showNotificationPopup(
                                                            t(
                                                                'customerMessages.reminders.send.notificationPopup.whatsapp'
                                                            )
                                                        )
                                                    );
                                                    return;
                                                }
                                                props.setListView(false);
                                                props.setInitialValues({ type_id: typeId });
                                            }}
                                        >
                                            {props.t(`sharedMessages.communication.templates.table.action.add`)}
                                        </Button>
                                    </PermissionGuard>
                                </li>
                                <li>
                                    <div className="right-align">
                                        <SearchBlock
                                            dataValues={[organisationId, typeId]}
                                            getDataMethod={props.getCommunicationTemplates}
                                            isSearching={isSearching}
                                            placeholder={props.t(
                                                `sharedMessages.communication.templates.${type}.search`
                                            )}
                                            setIsSearching={setIsSearching}
                                            setSearchParam={setSearchParam}
                                            filterEmpty={false}
                                        />
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <PermissionGuardLayout requiredPermission={permissionSet.LIST} layout="section">
                            <Table
                                rowKey="id"
                                loading={props.isLoading || isCommunicationTemplateTypesLoading}
                                columns={TEMPLATES_TABLE}
                                dataSource={memoizedCommunicationTemplates}
                                dataValues={[organisationId, typeId]}
                                isSearching={isSearching}
                                paginationData={props.paginationCommunicationTemplates}
                                getDataMethod={props.getCommunicationTemplates}
                                searchParam={searchParam}
                                localeMessage={props.t(
                                    `sharedMessages.communication.templates.table.nothingFound.${type}`
                                )}
                                size="middle"
                                setIsSearching={setIsSearching}
                                filterEmpty={false}
                                {...props}
                            />
                        </PermissionGuardLayout>
                    </>
                ) : null}
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={props.isDeletingCommunicationTemplate}
                >
                    <InfoMessage
                        message={props.t('sharedMessages.communication.templates.loading.message.deletingTemplate')}
                        showLoader={true}
                    />
                </Modal>
            </div>
        </div>
    );
};

Templates.propTypes = {
    deleteCommunicationTemplate: PropTypes.func,
    getCommunicationTemplates: PropTypes.func,
    isAdmin: PropTypes.bool,
    isDeletingCommunicationTemplate: PropTypes.bool,
    isLoading: PropTypes.bool,
    isTypesLoading: PropTypes.bool,
    match: PropTypes.object,
    organisationId: PropTypes.string,
    paginationCommunicationTemplates: PropTypes.object,
    path: PropTypes.string,
    permissionSet: PropTypes.shape({
        ADD: PropTypes.string,
        DELETE: PropTypes.string,
        EDIT: PropTypes.string,
        LIST: PropTypes.string,
    }),
    setInitialValues: PropTypes.func,
    setListView: PropTypes.func,
    disableTemplateDelete: PropTypes.bool,
    t: PropTypes.func,
    templateTypes: PropTypes.array,
    templates: PropTypes.object,
    type: PropTypes.string,
    types: PropTypes.array,
};

// connect to store
export default connect(
    state => ({
        isAdmin: state.account.isAdmin,
        isDeletingCommunicationTemplate: state.communication.isDeletingCommunicationTemplate,
        isDetailsUpdating: state.communication.isDetailsUpdating,
        isLoading:
            state.communication.isLoading ||
            state.communication.isTypesLoading ||
            state.communication.isTemplateTypesLoading,
        paginationCommunicationTemplates: state.communication.paginationCommunicationTemplates,
    }),

    null
)(withTranslation()(Templates));

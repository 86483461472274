/**
 * Edit Campaign Component
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import { React, connect, Link, PropTypes, useEffect, withTranslation } from '../../../includes/exports/react';
import AddEditCampaignForm from '../../shared/forms/AddEditCampaignForm';
import Loader from '../../shared/Loader';
import ReactHtmlParser from 'react-html-parser';
import { addCampaign, getCampaignDetails, updateCampaign } from '../../../includes/redux/actions/customer/reminder';
import { Breadcrumb, Modal } from 'antd';
import { CONFIRM } from '../../../includes/constants';
import {
    getCommunicationTemplatePreview,
    getCommunicationTemplateDetails,
} from '../../../includes/redux/actions/shared/communication';
import { showNotificationPopup } from '../../../includes/redux/actions/shared/common';
import {
    useAddOrganisationPopup,
    useCampaignDaysDataFromStore,
    useCheckEditPermission,
    useCommunicationDataFromStore,
} from '../../../includes/utils/hooks';

const { confirm } = Modal;

const AddEditCampaign = props => {
    const { campaignId, accountId } = props.match.params;
    const organisationId = props.organisationId || props.match.params.organisationId;
    const { getCampaignDetails, isAdmin } = props;

    // check if organisation is added
    useAddOrganisationPopup(organisationId);

    // do permission check
    useCheckEditPermission();

    // get the campaign days
    useCampaignDaysDataFromStore();

    // get the communication data
    useCommunicationDataFromStore(organisationId);

    /**
     * Get the campaign details on load
     */
    useEffect(() => {
        if (props.action !== 'add') {
            isAdmin ? getCampaignDetails(campaignId) : getCampaignDetails(campaignId, organisationId);
        }
    }, [campaignId]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Confirm the campaign message from the server
     * Shows the confirm popup for the campaign
     *
     * @param {object} values Form values
     * @param {string} redirectUrl Redirect url
     * @param {string} campaignExistsMessage Campaign confirm message
     */
    const campaignConfirmAction = (values, redirectUrl, campaignExistsMessage) => {
        confirm({
            cancelText: props.t('appCommonMessages.no'),
            content: ReactHtmlParser(campaignExistsMessage),
            okText: props.t('appCommonMessages.yes'),
            title: props.t(`customerMessages.campaign.form.title.${props.action === 'add' ? 'add' : 'edit'}`),
            onOk: () => {
                values[CONFIRM] = true;
                if (props.action === 'add') {
                    props.addCampaign(organisationId, values, redirectUrl);
                } else {
                    props.updateCampaign(campaignId, organisationId, values, redirectUrl);
                }
            },
        });
    };

    /**
     * Submit handler
     * Submit the campaign form
     *
     * @param {object} values Form values
     */
    const handleSubmit = values => {
        const redirectUrl = isAdmin
            ? accountId
                ? `/admin/accounts/organisation/campaign/${accountId}/${organisationId}`
                : `/admin/campaigns`
            : `/reminders/campaign`;
        if (props.action === 'add') {
            props.addCampaign(organisationId, values, redirectUrl, campaignExistsMessage => {
                campaignConfirmAction(values, redirectUrl, campaignExistsMessage);
            });
        } else {
            props.updateCampaign(campaignId, organisationId, values, redirectUrl, campaignExistsMessage => {
                campaignConfirmAction(values, redirectUrl, campaignExistsMessage);
            });
        }
    };

    return (
        <div className="home-content-wrapper edit-campaign-wrapper">
            {(organisationId || isAdmin) && (
                <>
                    <div className="tab-arrow-link">
                        <Breadcrumb>
                            {!isAdmin && (
                                <Breadcrumb.Item>
                                    <Link to="/reminders">
                                        {props.t('customerMessages.campaign.breadcrumb.reminders')}
                                    </Link>
                                </Breadcrumb.Item>
                            )}
                            <Breadcrumb.Item>
                                <Link
                                    to={
                                        isAdmin
                                            ? accountId
                                                ? `/admin/accounts/organisation/campaign/${accountId}/${organisationId}`
                                                : `/admin/campaigns`
                                            : `/reminders/campaign`
                                    }
                                >
                                    {props.t('customerMessages.campaign.breadcrumb.campaigns')}
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                {props.action === 'add' ? 'Add Notification' : 'Edit Notification'}
                                {/* {
                                props.t(
                                    `customerMessages.campaign.breadcrumb.${
                                        props.action === 'add' ? 'addCampaign' : 'editCampaign'
                                    }`
                                )
                                } */}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    <h2 className="page-title">
                        {props.action === 'add' ? 'Add Notification' : 'Edit Notification'}
                        {/* {props.t(`customerMessages.campaign.form.title.${props.action === 'add' ? 'add' : 'edit'}`)} */}
                    </h2>
                    <div
                        className={`white-box-wrapper box-wrapper default-white-box-padding ${
                            isAdmin ? 'full-wrapper' : 'mid-wrapper'
                        } `}
                    >
                        {!props.isCampaignLoading && !props.isCampaignDaysLoading ? (
                            <AddEditCampaignForm
                                initialValues={props.action !== 'add' ? props.campaignDetails : ''}
                                onSubmit={handleSubmit}
                                accountId={accountId}
                                {...props}
                            />
                        ) : (
                            <Loader />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

// Prop types
AddEditCampaign.propTypes = {
    action: PropTypes.string,
    addCampaign: PropTypes.func,
    campaignDetails: PropTypes.object,
    campaignId: PropTypes.string,
    getAllPlaceholders: PropTypes.func,
    getCampaignDetails: PropTypes.func,
    getReminderTypes: PropTypes.func,
    initialValues: PropTypes.object,
    isAdmin: PropTypes.bool,
    isCampaignDaysLoading: PropTypes.bool,
    isCampaignLoading: PropTypes.bool,
    isLoading: PropTypes.bool,
    isUpdatingCampaign: PropTypes.bool,
    match: PropTypes.object,
    organisationId: PropTypes.string,
    t: PropTypes.func,
    templateDetails: PropTypes.object,
    updateCampaign: PropTypes.func,
    visible: PropTypes.bool,
};

// Connect to store
export default connect(
    state => ({
        ...state.reminder,
        allPlaceholders: state.placeholder.allPlaceholders,
        allCommunicationTemplates: state.communication.allCommunicationTemplates,
        isAdmin: state.account.isAdmin,
        isLoading: state.communication.isLoadingDetails,
        isLoadingPreview: state.communication.isLoadingPreview,
        notificationTypes: state.communication.communicationTypes,
        organisationId: state.organisation.selectedOrganisationId,
        preview: state.communication.preview,
        templateDetails: state.communication.templateDetails,
        templateTypes: state.communication.templateTypes,
        types: state.communication.communicationTypes,
    }),
    {
        addCampaign,
        getCampaignDetails,
        getCommunicationTemplateDetails,
        getCommunicationTemplatePreview,
        updateCampaign,
        showNotificationPopup,
    }
)(withTranslation()(AddEditCampaign));

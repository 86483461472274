/**
 * Imports Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { createSlice } from '@reduxjs/toolkit';
import ImportsApi from '../services/customer/imports';
import { get, isArray } from 'lodash';
import { showNotificationModal } from './appNotifications';
import { IMPORT_ERROR } from '../constants/mappings/errors';
import { redirect } from '../utils';
import * as successMappings from '../constants/mappings/success';
import { UNKNOWN_ERROR } from '../constants/messages/errors';

// set the initial state
const initialState = {
    importErrors: [],
    loadingImportFile: false,
    loadingImportTemplateDownloadLink: false,
};

// define the slice
const ImportsSlice = createSlice({
    name: 'importsSlice',
    initialState,
    reducers: {
        endImportFileLoading(state) {
            state.loadingImportFile = false;
        },
        endImportTemplateDownloadLinkLoading(state) {
            state.loadingImportTemplateDownloadLink = false;
        },
        resetImportErrors(state) {
            state.importErrors = [];
        },
        setImportErrors(state, action) {
            state.importErrors = action.payload;
        },
        setImportFileLoading(state) {
            state.loadingImportFile = true;
        },
        setImportTemplateDownloadLinkLoading(state) {
            state.loadingImportTemplateDownloadLink = true;
        },
    },
});

/**
 * Get the import template download link
 *
 * @param {string} importType Import Type
 */
export const getImportTemplateDownloadLink = importType => async dispatch => {
    try {
        dispatch(ImportsSlice.actions.setImportTemplateDownloadLinkLoading());
        const result = await ImportsApi.getImportTemplateDownloadLink(importType);
        // open the url in a new tab
        window.open(get(result, successMappings.IMPORT_TEMPLATE_DOWNLOAD_LINK), '_self');
    } catch (errors) {
        dispatch(showNotificationModal(errors, false, 'IMPORT_TEMPLATE_DOWNLOAD_LINK_ERROR'));
    } finally {
        dispatch(ImportsSlice.actions.endImportTemplateDownloadLinkLoading());
    }
};

/**
 * Import the file
 *
 * @param {string} organisationId Organisation Id
 * @param {FormData} formData Uploaded file
 * @param {string} importType Import Type
 * @param {string} redirectUrl Redirect URL
 */
export const importFile = (organisationId, formData, importType, redirectUrl) => async dispatch => {
    try {
        dispatch(ImportsSlice.actions.resetImportErrors());
        dispatch(ImportsSlice.actions.setImportFileLoading());
        const result = await ImportsApi.importFile(organisationId, formData, importType);
        dispatch(showNotificationModal(result));
        if (redirectUrl) {
            redirect(redirectUrl);
        }
    } catch (errors) {
        let importErrors = get(errors, IMPORT_ERROR, UNKNOWN_ERROR);
        if (!isArray(importErrors)) {
            importErrors = [importErrors];
        }
        dispatch(ImportsSlice.actions.setImportErrors(importErrors));
    } finally {
        dispatch(ImportsSlice.actions.endImportFileLoading());
    }
};

// export the reset errors action
export const { resetImportErrors } = ImportsSlice.actions;

// export the reducer
export default ImportsSlice.reducer;

/**
 * App Modal Notification Component
 * Handles the modal notification show across the app
 *
 * @version 1.0
 * @author Araind Rajan <aravindrajan@qburst.com>
 */

// import the required modules
import { React, connect, PropTypes, useEffect } from '../../../includes/exports/react';
import * as constants from '../../../includes/constants';
import ReactHtmlParser from 'react-html-parser';
import { get, isArray, isFunction } from 'lodash';
import { Modal } from 'antd';
import { notificationModalClosed } from '../../../includes/redux/actions/shared/appNotification';
import { useSelector } from 'react-redux';

/**
 * App Modal Notification Component
 */
const AppModalNotification = props => {
    const { modalNotification } = props;
    const modalNotificationData = useSelector(state => state.appNotifications.modalNotification);
    const modalNotificationContent = modalNotificationData || modalNotification;

    /**
     * On Component update render the notification
     */
    useEffect(() => {
        renderModal();
    }, [modalNotificationContent]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Render the modal notification message
     */
    const renderModal = () => {
        const modalNotification = modalNotificationContent;
        const content = get(modalNotification, 'message', []);
        const type = get(modalNotification, 'messageType', constants.DEFAULT_APP_NOTIFICATION_TYPE);
        const onOk = get(modalNotification, 'onOk');
        const onCancel = get(modalNotification, 'onCancel');
        if (modalNotificationContent) {
            Modal[type]({
                title: get(modalNotificationContent, 'title', ''),
                content: (
                    <div>
                        {isArray(content) ? (
                            content.map((c, i) => <p key={i}>{ReactHtmlParser(c)}</p>)
                        ) : (
                            <p>{ReactHtmlParser(content)}</p>
                        )}
                    </div>
                ),
                onCancel: () => {
                    if (onCancel && isFunction(onCancel)) {
                        onCancel();
                    }
                    props.notificationModalClosed();
                },
                onOk: () => {
                    if (onOk && isFunction(onOk)) {
                        onOk();
                    }
                    props.notificationModalClosed();
                },
                cancelText: get(modalNotificationContent, 'okText', constants.DEFAULT_APP_MODAL_CANCEL_TEXT),
                okText: get(modalNotificationContent, 'okText', constants.DEFAULT_APP_MODAL_OK_TEXT),
            });
        }
    };

    /**
     * Render the modal notification
     *
     * @returns    {string}    Notification view
     */
    return <React.Fragment />;
};

/**
 * Proptypes
 */
AppModalNotification.propTypes = {
    modalNotification: PropTypes.object,
    notificationModalClosed: PropTypes.func,
};

// connect the component to the store
export default connect(state => ({ modalNotification: state.appNotification.modalNotification }), {
    notificationModalClosed,
})(AppModalNotification);

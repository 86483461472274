/**
 * Contact types listing Component.
 *
 * @since 2.0.0
 */

//import required modules
import React, { useCallback, useMemo, useState } from 'react';
import { addContactType, deleteContactType, getContactTypes, updateContactType } from 'includes/slices/contact';
import { contactTypesColumns } from 'includes/tableColumns/contactTypes';
import { find } from 'lodash';
import { Breadcrumb, Icon, Modal, Tooltip } from 'antd';
import { TABLE_NO_CONTACT_TYPES_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddEditContactTypeForm from 'pages/ContactTypes/List/components/Form';
import Button from 'components/Button';
import ContactTypesBreadCrumbItems from 'components/BreadCrumbItems/ContactTypes';
import InfoMessage from 'components/shared/messages/InfoMessage';
import PropTypes from 'prop-types';
import SearchBlock from 'components/shared/SearchBlock';
import Table from 'components/shared/ScrollableTable';
import useContactTypes from 'includes/hooks/useContactTypes';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import PermissionGuard from 'components/PermissionGuard';
import { CONTACT_TYPE } from 'includes/constants/permissions';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useOrganisationRequired from 'includes/hooks/useOrganisationRequired';

const { confirm } = Modal;

/**
 * Contact types listing component
 *
 * @since 2.0.0
 */
const ContactTypesList = props => {
    const { t } = useTranslation();
    const organisationId = useOrganisationId();
    const [isSearching, setIsSearching] = useState(false);
    const [contactTypeId, setContactTypeId] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [searchParam, setSearchParam] = useState('');
    const isContactTypesLoading = useSelector(state => state.contacts.isContactTypesLoading);
    const isContactTypeDeleteLading = useSelector(state => state.contacts.isContactTypeDeleteLading);
    const paginationContactTypes = useSelector(state => state.contacts.paginationContactTypes);
    const contactTypes = useSelector(state => state.contacts.contactTypes);
    const dispatch = useDispatch();
    const { reset: clearContactTypesCache } = useContactTypes(true);
    const getCurrencyFormattedAmount = useCurrencyFormatter();

    useOrganisationRequired();

    /**
     * Close the modal
     *
     * @since 2.0.0
     */
    const closeModal = () => {
        setOpenModal(false);
        setContactTypeId('');
        clearContactTypesCache();
    };

    /**
     * Delete contact type
     *
     * @param {string} contactTypeId Id of the contact type to be deleted.
     *
     * @since 2.0.0
     */
    const deleteContactTypeById = contactTypeId => {
        confirm({
            title: t('messages.contactTypes.confirm.delete.title'),
            content: '',
            onOk: () => {
                dispatch(deleteContactType(organisationId, contactTypeId, clearContactTypesCache));
            },
        });
    };

    /**
     * Handle the submit of the contact type
     *
     * @param {object} values Form Values
     *
     * @since 2.0.0
     */
    const handleSubmit = values => {
        dispatch(
            contactTypeId
                ? updateContactType(organisationId, contactTypeId, values, closeModal)
                : addContactType(organisationId, values, closeModal)
        );
    };

    /**
     * Format the table data
     *
     * @since 2.0.0
     */
    const getFormattedTableData = useCallback(
        () =>
            contactTypes.map(contactType => {
                return {
                    ...contactType,
                    type: (
                        <div className="business-name-avatar">
                            <span>{contactType.type}</span>
                            {contactType.is_default && contactType.is_internal ? (
                                <Tooltip title={getLocaleText('list.info.type')}>
                                    <span style={{ marginLeft: 5 }}>
                                        <Icon type="info-circle" theme="filled" />
                                    </span>
                                </Tooltip>
                            ) : null}
                        </div>
                    ),
                    contacts_amount_due: getCurrencyFormattedAmount(contactType.contacts_amount_due),
                    is_default: t(`messages.${contactType.is_default && !contactType.is_internal ? 'yes' : 'no'}`),
                    auto_reminders: t(`messages.${contactType.auto_reminders ? 'yes' : 'no'}`),
                    actions: (
                        <div className="blue-bg-button">
                            <span>
                                <PermissionGuard requiredPermission={CONTACT_TYPE.EDIT}>
                                    <Button
                                        className="green-bg-button blue-bg-button"
                                        filled
                                        onClick={() => {
                                            setContactTypeId(contactType.id);
                                            setOpenModal(true);
                                        }}
                                    >
                                        {getLocaleText(`crud.${contactType.is_internal ? 'view' : 'edit'}`)}
                                    </Button>
                                </PermissionGuard>
                            </span>
                            {!contactType.is_internal ? (
                                <span>
                                    <PermissionGuard requiredPermission={CONTACT_TYPE.DELETE}>
                                        <Button
                                            className="green-bg-button blue-bg-button"
                                            filled
                                            onClick={() => deleteContactTypeById(contactType.id)}
                                        >
                                            {getLocaleText('crud.delete')}
                                        </Button>
                                    </PermissionGuard>
                                </span>
                            ) : null}
                        </div>
                    ),
                };
            }),
        [contactTypes] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Get the locale text
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @since 2.0.0
     *
     * @returns {string} Locale test
     */
    const getLocaleText = path => t(`messages.contactTypes.${path}`);

    /**
     * Get memoized contact types table data
     *
     * @since 2.0.0
     */
    const memoizedContactTypesData = useMemo(() => getFormattedTableData(), [getFormattedTableData]);

    /**
     * Get the contact type details
     *
     * @since 2.0.0
     */
    const contactTypeDetails = useMemo(() => find(contactTypes, { id: contactTypeId }));

    return (
        <div className="home-content-wrapper contacts-wrapper">
            <h2 className="page-title">{t('titleAndMetas.contactTypes.title')}</h2>
            <div className="tab-arrow-link breadcrumb-spec">
                <Breadcrumb>
                    <ContactTypesBreadCrumbItems />
                </Breadcrumb>
            </div>
            <div className="full-wrapper box-wrapper white-box-wrapper">
                <section className="right-menu-wrapper">
                    <div className="right-align">
                        {organisationId && (
                            <PermissionGuard requiredPermission={CONTACT_TYPE.ADD}>
                                <Button
                                    big
                                    filled
                                    onClick={() => {
                                        setContactTypeId('');
                                        setOpenModal(true);
                                    }}
                                >
                                    {getLocaleText('crud.add')}
                                </Button>
                            </PermissionGuard>
                        )}
                    </div>
                </section>
                <section>
                    <ul className="selector-field">
                        <li>
                            <div className="right-align">
                                <SearchBlock
                                    dataValues={[organisationId]}
                                    getDataMethod={organisationId ? getContactTypes : null}
                                    isSearching={isSearching}
                                    placeholder={getLocaleText(`search.placeholder`)}
                                    setIsSearching={setIsSearching}
                                    setSearchParam={setSearchParam}
                                    dispatchMethod
                                />
                            </div>
                        </li>
                    </ul>
                </section>
                <hr />
                <Table
                    columns={contactTypesColumns}
                    dataSource={memoizedContactTypesData}
                    dataValues={[organisationId]}
                    getDataMethod={organisationId ? getContactTypes : null}
                    isSearching={isSearching}
                    loading={isContactTypesLoading}
                    localeMessage={TABLE_NO_CONTACT_TYPES_FOUND_MESSAGE}
                    paginationData={paginationContactTypes}
                    rowClassName="pointer"
                    rowKey="id"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    size="middle"
                    className="table-1200"
                    mobileClassName="table-1200"
                    dispatchMethod
                />
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={isContactTypeDeleteLading}
                >
                    <InfoMessage message={t(`messages.contactTypes.modal.message.delete`)} showLoader={true} />
                </Modal>
                <Modal
                    footer={[]}
                    className="custom-form-modal text-left"
                    visible={openModal}
                    onCancel={closeModal}
                    destroyOnClose
                    maskClosable={false}
                    keyboard={false}
                >
                    <header>
                        {getLocaleText(
                            `crud.${
                                contactTypeDetails?.type ? (contactTypeDetails?.is_internal ? 'view' : 'edit') : 'add'
                            }`
                        )}
                    </header>
                    <AddEditContactTypeForm
                        {...props}
                        initialValues={contactTypeDetails}
                        onSubmit={handleSubmit}
                        canEdit={!contactTypeDetails?.is_internal}
                    />
                </Modal>
            </div>
        </div>
    );
};

ContactTypesList.propTypes = {
    t: PropTypes.func,
};

export default ContactTypesList;

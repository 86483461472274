import PropTypes from 'prop-types';
import React from 'react';

/**
 * Component to render detail with label
 */
export default function DetailsPageDetailSection({ label, value }) {
    return (
        <div>
            <div className="text-gray-400 uppercase text-sm">{label}</div>
            <div className="break-words">{value}</div>
        </div>
    );
}

DetailsPageDetailSection.propTypes = {
    label: PropTypes.string,
    value: PropTypes.node,
};

/**
 * Dashboard Component
 * Handles the dashboard page view and functions related to loading dashboard
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import required modules
import { React, connect, PropTypes, useEffect, withTranslation } from '../../../includes/exports/react';
import CanvasJSReact from '../../../assets/js/canvasjs.react';
import InfoMessage from '../../shared/messages/InfoMessage';
import Plan from '../../shared/graphs/Plan';
import { getAdminDashboardData } from '../../../includes/redux/actions/admin/dashboard';

let CanvasJS = CanvasJSReact.CanvasJS;
let CanvasJSChart = CanvasJSReact.CanvasJSChart;
CanvasJS.addColorSet('customColorSet1', [
    //colorSet Array
    '#858ff3',
    '#ff5d7c',
    '#f5c64a',
    '#21f594',
]);

/**
 * Admin Dashboard Component
 */
const Dashboard = props => {
    /**
     * Use Effect hook to fetch admin dashboard data
     */
    useEffect(() => {
        props.getAdminDashboardData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="home-content-wrapper dashboard-wrapper">
            <Plan
                canvasJSChart={CanvasJSChart}
                infoMessage={InfoMessage}
                isGraphDataLoading={props.isGraphDataLoading}
                planData={props.planData}
            />
        </div>
    );
};

/**
 * Proptypes
 */
Dashboard.propTypes = {
    getAdminDashboardData: PropTypes.func,
    isGraphDataLoading: PropTypes.bool,
    planData: PropTypes.array,
    t: PropTypes.func,
};

/**
 * Connect to store
 */
export default connect(
    state => ({
        ...state.dashboard,
    }),
    {
        getAdminDashboardData,
    }
)(withTranslation()(Dashboard));

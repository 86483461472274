/**
 * Request Quote Form
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

import { React } from '../../../includes/exports/react';
import { Form, Row, Col } from 'antd';
import Button from '../Button';
import * as constants from '../../../includes/constants';
import * as formValidations from '../../../includes/utils/form';
import FormField from '../FormField';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import * as requestKeys from '../../../includes/constants/keys/request';

/**
 * Request Quote form Component
 */
const RequestQuote = props => {
    /**
     * Locale the form label
     *
     * @param   {string}    field   Field for which locale is needed
     *
     * @returns {string}            Localised content
     */
    const localeLabel = field => props.t(`sharedMessages.requestQuote.form.input.label.${field}`);

    /**
     * Locale the form place holder
     *
     * @param   {string}    field   Field for which the place holder locale is needed
     *
     * @returns {string}            Localised content
     */
    const localePlaceholder = field => props.t(`sharedMessages.requestQuote.form.input.placeholder.${field}`);

    /**
     * Render the component
     *
     * @returns {*}
     */
    return (
        <div className="register-form form-wrapper">
            <h2 className="title">{props.t('sharedMessages.requestQuote.title')}</h2>
            <Form className="login-form" onSubmit={props.handleSubmit}>
                <div className="form-mini-title">Your details</div>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.FIRST_NAME}
                            label={localeLabel(requestKeys.FIRST_NAME)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            placeholder={localePlaceholder(requestKeys.FIRST_NAME)}
                        />
                    </Col>

                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.LAST_NAME}
                            label={localeLabel(requestKeys.LAST_NAME)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            placeholder={localePlaceholder(requestKeys.LAST_NAME)}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.EMAIL}
                            label={localeLabel(requestKeys.EMAIL)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required, formValidations.email]}
                            placeholder={localePlaceholder(requestKeys.EMAIL)}
                        />
                    </Col>

                    <Col span={12} className="phone-field-wrapper">
                        <Field
                            type="phone"
                            name={requestKeys.PHONE_NUMBER}
                            label={localeLabel(requestKeys.PHONE_NUMBER)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required, formValidations.phone]}
                            onChange={value => props.change(requestKeys.PHONE_NUMBER, value ? value : null)}
                            placeholder={localePlaceholder(requestKeys.PHONE_NUMBER)}
                            reduxChange={props.change}
                        />
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="text"
                            name={requestKeys.WEBSITE}
                            label={localeLabel(requestKeys.WEBSITE)}
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required, formValidations.url]}
                            placeholder={localePlaceholder(requestKeys.WEBSITE)}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            step="any"
                            type="number"
                            name={requestKeys.ESTIMATED_VOLUME}
                            label={localeLabel(requestKeys.ESTIMATED_VOLUME)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required]}
                            placeholder={localePlaceholder(requestKeys.ESTIMATED_VOLUME)}
                        />
                    </Col>
                </Row>
                <Form.Item
                    className={`full-wrapper submit-registration-form ${
                        props.error ? ' has-error default-form-app-error' : ''
                    }`}
                >
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={props.isLoading}
                        submittingButtonLabel={props.t('sharedMessages.requestQuote.form.submit.buttonSubmittingLabel')}
                        className="register-btn"
                    >
                        {props.t('sharedMessages.requestQuote.form.submit.buttonLabel')}
                    </Button>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
};

/**
 * Proptypes
 */
RequestQuote.propTypes = {
    change: PropTypes.func,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    isLoading: PropTypes.bool,
    loading: PropTypes.bool,
    t: PropTypes.func,
    untouch: PropTypes.func,
};

// Redux-Form wrapper
const reduxFormWrappedRegistration = reduxForm({
    form: constants.REQUEST_QUOTE_FORM_NAME,
})(withTranslation()(RequestQuote));

export default reduxFormWrappedRegistration;

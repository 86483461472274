/**
 * useBankAccounts hook
 * Fetches and returns bank accounts and API loading status in `bankAccounts` and `isBankAccountsLoading` key
 *
 * @since 2.8.0
 */
import { getBankAccounts } from 'includes/slices/openBanking';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/**
 * @typedef {object} BankAccountAPIResponseAndLoadingStatus
 * @property {Array.<object>} bankAccounts
 * @property {boolean} isBankAccountsLoading
 */

/**
 * Fetch bank accounts of a contact
 *
 * @param {string} organisationId Organisation ID
 * @param {string} contactId Contact ID
 * @param {string} paymentArrangementId Payment Arrangement ID
 *
 * @returns {BankAccountAPIResponseAndLoadingStatus}
 *
 * @since 2.8.0
 */
export default function useBankAccounts(organisationId, contactId, paymentArrangementId) {
    const dispatch = useDispatch();
    // dispatch call to fetch bank accounts
    useEffect(() => {
        dispatch(
            getBankAccounts(organisationId, { contact_id: contactId, payment_arrangement_id: paymentArrangementId })
        );
    }, [dispatch, contactId, organisationId]);

    const bankAccounts = useSelector(state => state.openBanking.bankAccounts);
    const isBankAccountsLoading = useSelector(state => state.openBanking.isBankAccountsLoading);

    return { bankAccounts, isBankAccountsLoading };
}

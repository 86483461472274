/**
 * API URL Constants
 * Specifies the api url's used
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// A
export const ACCOUNT_AUDIT_LOG = 'logs/account_id/';
export const ACCOUNT_SEND_TEST_MAIL = 'accounts/send-test-email/account_id/organisation_id/';
export const ACCOUNTING_SOFTWARES_GET_ALL = 'accounting-softwares/';
export const ACCOUNTING_SOFTWARES_GET_AUTHORIZATION = 'accounting-softwares/authorization/accounting_software_id/';
export const ACCOUNTING_SOFTWARES_VERIFY_AUTHORIZATION = 'accounting-softwares/authorization/verify/';
export const ACTIVATE_ACCOUNT = 'users/confirm-account/';
export const ADD_ACCOUNT = 'accounts/';
export const ADD_USER = 'users/add-customer-manager/';
export const AUDIT_LOGS = 'logs/audit-logs/';
export const AUDIT_LOGS_ACCOUNT = 'logs/audit-logs/account_id/';

// B
export const BANK_ACCOUNTS = 'open-banking/accounts/organisation_id/';
export const BANK_ACCOUNT_TRANSACTIONS = 'open-banking/account/transactions/organisation_id/';

// C
export const CAMPAIGN_CHANGE_STATUS = 'schedulers/status/';
export const CAMPAIGN_CRUD = 'campaigns/organisation_id/campaign_id/';
export const CAMPAIGN_GET_DAYS = 'schedulers/days/';
export const CAMPAIGN_NOTIFICATION_CRUD =
    'campaigns/notifications/organisation_id/campaign_id/campaign_notification_id/';
export const CAMPAIGN_NOTIFICATION_DAYS_CRUD = 'campaigns/notifications/days/organisation_id/';
export const CAMPAIGN_NOTIFICATIONS_CRUD = 'campaigns/notifications/organisation_id/campaign_id/';
export const CAMPAIGN_ORGANISATION_CHANGE_STATUS = 'schedulers/status/organisation_id/';
export const CAMPAIGNS_CRUD = 'campaigns/organisation_id/';
export const CHART_OF_ACCOUNT_CRUD = 'chart-of-accounts/chart-of-account/chart_of_account_id/';
export const CHART_OF_ACCOUNT_ORGANISATION_CRUD =
    'chart-of-accounts/chart-of-account/chart_of_account_id/organisation_id/';
export const CHART_OF_ACCOUNT_ORGANISATION_TRANSACTIONS =
    'chart-of-accounts/chart-of-account/transactions/chart_of_account_id/organisation_id/';
export const CHART_OF_ACCOUNT_TYPES = 'chart-of-accounts/types/';
export const CHART_OF_ACCOUNTS_CRUD = 'chart-of-accounts/';
export const CHART_OF_ACCOUNTS_ORGANISATION_CRUD = 'chart-of-accounts/organisation_id/';
export const COMMUNICATION_LOG_GET_HISTORY = 'logs/organisation_id/history/';
export const COMMUNICATION_LOG_GET_HISTORY_DETAILS = 'logs/organisation_id/history/communication_log_history_id/';
export const COMMUNICATION_LOG_STATUSES = 'logs/communication/statuses/';
export const COMMUNICATION_TEMPLATE_DETAILS = 'communication/templates/template/communication_template_id/';
export const COMMUNICATION_TEMPLATE_DETAILS_BY_ORGANISATION =
    'communication/templates/template/organisation_id/communication_template_id/';
export const COMMUNICATION_TEMPLATE_PREVIEW = 'communication/templates/preview/';
export const COMMUNICATION_TEMPLATE_TYPES = 'communication/templates/types/';
export const COMMUNICATION_TEMPLATE_TYPES_BY_ORGANISATION = 'communication/templates/types/organisation_id/';
export const COMMUNICATION_TEMPLATES = 'communication/templates/';
export const COMMUNICATION_TEMPLATES_ADD_ADMIN = 'communication/templates/admin/';
export const COMMUNICATION_TEMPLATES_BY_ORGANISATION_ID = 'communication/templates/organisation_id/';
export const COMMUNICATION_TEMPLATES_DELETE = 'communication/templates/template_id/';
export const COMMUNICATION_TYPES = 'communication/types/';
export const CONTACT_TYPE_CRUD = 'contacts/types/organisation_id/contact_type_id/';
export const CONTACT_TYPES_CRUD = 'contacts/types/organisation_id/';
export const CONTACT_TYPES_GET_ALL = 'contacts/types/organisation_id/';
export const CONTACTS_ADD_CONTACT = 'contacts/organisation_id/';
export const CONTACTS_GET_ALL = 'contacts/organisation_id/';
export const CONTACTS_GET_CONTACT_ADMIN = 'contacts/admin/contact_id/';
export const CONTACTS_GET_CONTACT_DETAILS = 'contacts/organisation_id/contact_id/';
export const CONTACTS_UPDATE_CONTACT = 'contacts/organisation_id/contact_id/';
export const COUNTRIES_CRUD = 'countries/';
export const COUNTRY_BY_IP = 'countries/find/';
export const COUPON_ACCOUNTS = 'coupons/coupon_id/accounts/';
export const COUPONS = 'coupons/';
export const CURRENCIES_CRUD = 'currencies/';
export const CURRENCIES_GET_ALL = '/currencies/';
export const CURRENCIES_ORGANISATION_CRUD = 'currencies/organisation/organisation_id/';
export const CURRENCY_ORGANISATION_CRUD = 'currencies/organisation/organisation_id/currency_id/';

//E
export const EVENTS = 'events/';
export const EVENTS_ORGANISATION = 'events/organisation_id/';
export const EXPORT_ACCOUNT_ORGANISATION = 'exports/account_id/organisations/';
export const EXPORT_ACCOUNTS = 'exports/accounts/account_id/';
export const EXPORT_ALL_INVOICES = 'exports/organisation_id/invoices/';
export const EXPORT_ALL_SUBSCRIPTIONS = 'exports/subscriptions/';
export const EXPORT_CONTACTS = 'exports/organisation_id/contacts/';
export const EXPORT_FILE_DOWNLOAD = 'exports/export_id/';
export const EXPORT_INVOICE_PAYMENTS = 'exports/organisation_id/contact_id/invoice_id/payments/';
export const EXPORT_INVOICE_PAYMENTS_ALL = 'exports/organisation_id/payments/';
export const EXPORT_INVOICES = 'exports/organisation_id/contact_id/invoices/';
export const EXPORT_ORGANISATION = 'exports/organisations/';
export const EXPORT_FILE_ACCOUNT = 'exports/export_type/account_id/';
export const EXPORT_FILE_ACCOUNT_ORGANISATION = 'exports/export_type/account_id/organisation_id/';
export const EXPORTS_FILE_DOWNLOAD = 'exports/export/export_id/';
export const EXPORT_FILE_ORGANISATIONS = 'exports/export_type/account_id/';
export const EXPORT_FILE_CONTACT_INVOICES = 'exports/export_type/account_id/organisation_id/contact_id/';
export const EXPORT_FILE_CONTACT_MANAGERS = 'exports/export_type/account_id/organisation_id/';
export const EXPORT_FILE_CONTACTS = 'exports/export_type/account_id/organisation_id/';
export const EXPORT_FILE_INVENTORY = 'exports/export_type/account_id/organisation_id/';
export const EXPORT_FILE_INVOICES = 'exports/export_type/account_id/organisation_id/';
export const EXPORT_FILE_INVOICE_PAYMENTS = 'exports/export_type/account_id/organisation_id/';
export const EXPORT_FILE_INVOICE_INVOICE_PAYMENTS =
    'exports/export_type/account_id/organisation_id/contact_id/invoice_id/';
export const EXPORT_FILE_SUBSCRIPTIONS = 'exports/export_type/account_id/';
export const EXPORTS_LIST = 'exports/account_id/';

//F
export const FAT_ZEBRA_VERIFICATION_CODE = 'fat-zebra/verfication-code';

// G
export const GET_ACCOUNT_DETAILS = 'accounts/account_id/';
export const GET_ACCOUNTS = 'accounts/';
export const GET_ACCOUNTS_TYPES = 'accounts/types/';
export const GET_ADMIN_PLAN_DATA = 'plans/statistics/';
export const GET_ALL_ACCOUNT_ORGANISATION_NOTIFICATIONS = 'notifications/account_id/organisation_id/';
export const GET_ALL_NOTIFICATIONS = 'notifications/';
export const GET_ALL_PERMISSIONS = 'permission-guards/';
export const GET_CURRENCIES = 'currencies/';
export const GET_CUSTOMER_MANAGER_USER = 'users/account_id/';
export const GET_FAQ = 'wp-json/custom/v1/all-posts?post_type=sp_faq';
export const GET_IMPORT_TEMPLATE = 'type/template/';
export const GET_ORGANISATIONS_BY_ACC_ID = 'organisations/account_id/organisations/';
export const GET_SETTINGS = 'settings/';
export const GET_USER_ROLES = 'users/roles/';
export const GET_USER_ACCOUNT_ROLES = 'users/roles/account/account_id/';

// I
export const IMPORT_FILE = 'imports/import_type/organisation_id/';
export const IMPORT_GET_TEMPLATE_DOWNLOAD_LINK = 'imports/download/template/import_type/';
export const INVENTORY_ITEMS_ORGANISATION_CRUD = 'inventory/items/organisation_id/';
export const INVENTORY_ITEM_ORGANISATION_CRUD = 'inventory/item/inventory_item_id/organisation_id/';
export const INVENTORY_ITEM_ORGANISATION_TRANSACTIONS =
    'inventory/item/transactions/inventory_item_id/organisation_id/';
export const INVOICE_CRUD = 'invoices/invoice/organisation_id/contact_id/invoice_id/';
export const INVOICE_PREVIEW = 'invoices/organisation_id/preview/';
export const INVOICE_TYPES_GET_ALL = 'invoices/types/';
export const INVOICES_ADD_INVOICE = 'invoices/organisation_id/contact_id/';
export const INVOICES_CRUD = 'invoices/organisation_id/';
export const INVOICES_DEBT_COLLECTOR_GET_ALL = 'invoices/';
export const INVOICES_EDIT_INVOICE = 'invoices/organisation_id/contact_id/invoice_id/';
export const INVOICES_GET_ALL = 'invoices/organisation_id/';
export const INVOICES_GET_ALL_CONTACT_INVOICES = 'contacts/organisation_id/contact_id/invoices/invoice_id/';
export const INVOICES_GET_CONTACT_INVOICES = 'invoices/organisation_id/contact_id/';
export const INVOICES_GET_CONTACT_INVOICES_ADMIN = 'invoices/admin/contact_id/';
export const INVOICES_GET_INVOICE_ADMIN = 'invoices/admin/contact_id/invoice_id/';
export const INVOICES_GET_INVOICE_DETAILS = 'invoices/invoice/organisation_id/contact_id/invoice_id/';
export const INVOICES_GET_INVOICE_STATUSES = 'invoices/statuses/';
export const INVOICES_GET_NEXT_INVOICE_NUMBER = 'invoices/next-invoice-number/organisation_id/';
export const INVOICES_UPDATE_INVOICE = 'invoices/invoice/organisation_id/contact_id/invoice_id/';
export const INVOICES_UPDATE_STATUS = 'invoices/invoice-status/organisation_id/';
export const INVOICES_CONTACT_UPDATE_STATUS = 'invoices/invoice-status/organisation_id/contact_id/';
export const INVOICES_UPDATE_TYPE = 'invoices/invoice-type/organisation_id/';
export const INVOICES_CONTACT_UPDATE_TYPE = 'invoices/invoice-type/organisation_id/contact_id/';

// N
export const NOTES_ACCOUNT = 'notes/account_id/';
export const NOTES_ACCOUNT_ORGANISATION = 'notes/account_id/organisation_id/';
export const NOTES_ACCOUNT_ORGANISATION_CONTACT = 'notes/account_id/organisation_id/contact_id/';
export const NOTES_ACCOUNT_ORGANISATION_CONTACT_INVOICE = 'notes/account_id/organisation_id/contact_id/invoice_id/';
export const NOTES_NOTE_ACCOUNT = 'notes/note/note_id/account_id/';
export const NOTES_NOTE_ACCOUNT_ORGANISATION = 'notes/note/note_id/account_id/organisation_id/';
export const NOTES_NOTE_ACCOUNT_ORGANISATION_CONTACT = 'notes/note/note_id/account_id/organisation_id/contact_id/';
export const NOTES_NOTE_ACCOUNT_ORGANISATION_CONTACT_INVOICE =
    'notes/note/note_id/account_id/organisation_id/contact_id/invoice_id/';
export const NOTE_STATUSES = 'notes/statuses/';
export const NOTE_TYPES = 'notes/types/';
export const NOTIFICATION_TYPES_GET_ALL = 'communication/types/';
export const NOTIFICATIONS_GET_ALL = 'schedulers/get-scheduler-plans/';
export const NOTIFIED_DAYS_GET_ALL = 'schedulers/get-notified-days/organisation_id/';

// O
export const ORDER_CRUD = 'orders/order/order_type_id/order_id/';
export const ORDER_DOWNLOAD = 'orders/order/download/order_type_id/order_id/';
export const ORDER_STATUS = 'orders/order/status/order_type_id/order_id/';
export const ORDERS_CRUD = 'orders/order_type_id/';

export const ORDER_CREATE_ORDER = 'orders/order_type_id/';
export const ORDER_DOWNLOAD_CONTACT_INVOICE = 'orders/order_type/download/order_id/organisation_id/contact_id/';
export const ORDER_DOWNLOAD_INVOICE = 'orders/order_type/download/order_id/';
export const ORDER_GET_INVOICE_ORDER_DETAILS =
    'orders/order_type/organisation/organisation_id/contact_id/order/order_id/';
export const ORDER_GET_STATUS = 'orders/order_type/order/order_id/status/';
export const ORDER_INVOICE_GET_STATUS =
    'orders/order_type/organisation/organisation_id/contact_id/order/order_id/status/';
export const ORDER_GET_DETAILS = 'orders/order_type/order/order_id/';
export const ORDER_GET_STATUSES = 'orders/statuses/';
export const ORDER_UPDATE_DETAILS = 'orders/order_type/order/order_id/';

export const ORDER = 'orders/order/order_type/order_id/';
export const ORDER_ORGANISATION = 'orders/order/order_type/order_id/organisation_id/';
// export const ORDER_DOWNLOAD = 'orders/order/download/order_type/order_id/';
// export const ORDER_DOWNLOAD_ORGANISATION = 'orders/order/download/order_type/order_id/organisation_id/';
// export const ORDER_STATUS = 'orders/order/status/order_type/order_id/';
export const ORDER_STATUS_ORGANISATION = 'orders/order/status/order_type/order_id/organisation_id/';
export const ORDER_STATUSES = 'orders/statuses/';
export const ORDER_TYPES = 'orders/types/';

export const ORDERS = 'orders/order_type/';
export const ORDERS_ORGANISATION = 'orders/order_type/organisation_id/';
export const ORDER_CHARGES = 'orders/order/charges/order_type_id/';
export const ORDER_CONFIRM = 'orders/order/confirm/order_type_id/order_id/';
export const ORDER_RETRY_PAYMENT = 'orders/order/payment/retry/order_type_id/order_id/';

export const ORGANISATION_COUNTRIES_GET_ALL = 'organisations/organisation-countries/';
export const ORGANISATION_GET_ORGANISATION_SETTINGS = 'organisations/settings/organisation_id/';
export const ORGANISATION_SYNC_ORGANISATION = 'organisations/sync/organisation_id/';
export const ORGANISATION_UPDATE_ORGANISATION_SETTINGS = 'organisations/settings/organisation_id/';
export const ORGANISATIONS_ADD_ORGANISATION = 'organisations/';
export const ORGANISATIONS_DELETE_ORGANISATION = 'organisations/organisation_id/';
export const ORGANISATIONS_EDIT_ORGANISATION = 'organisations/organisation_id/';
export const ORGANISATIONS_GET_ALL = 'organisations/';
export const ORGANISATIONS_GET_ARRANGEMENT_DUE_DATA = 'organisations/arrangement-due-data/organisation_id/';
export const ORGANISATIONS_GET_CASH_COLLECTED_DATA = 'organisations/cash-collected-data/organisation_id/';
export const ORGANISATIONS_GET_CONTACT_INVOICE_STATUS_TYPE_DATA =
    'organisations/contact-invoice-status-type-data/organisation_id/';
export const ORGANISATIONS_GET_DASHBOARD_DATA = 'organisations/dashboard-data/organisation_id/';
export const ORGANISATIONS_GET_DEBT_AGE_DEBT_BAND_DATA = 'organisations/debt-age-band-data/organisation_id/';
export const ORGANISATIONS_GET_DEBTOR_ACCOUNTS = 'organisations/debtor-accounts/organisation_id/';
export const ORGANISATIONS_GET_ORGANISATION = 'organisations/organisation_id/';

export const OPEN_BANKING_GET_EXPENSE_CATEGORIES = 'open-banking/yodlee-categories/';
export const OPEN_BANKING_GET_PAYMENT_ANALYSIS = 'open-banking/analysis/organisation_id/analysis_id/';
export const OPEN_BANKING_GET_PAYMENT_ANALYSIS_CATEGORIES =
    'open-banking/analysis/organisation_id/analysis_id/categories/';

// P
export const GET_ALL_INVOICE_PAYMENTS = 'invoices/payments/organisation_id/';
export const INVOICE_GET_PAYMENTS = 'invoices/payments/organisation_id/contact_id/invoice_id/';
export const INVOICE_GET_PAYMENT_DETAILS =
    'invoices/payments/organisation_id/contact_id/invoice_id/invoice_payment_id/';
export const INVOICE_GET_PDF_DOWNLOAD_URL = 'invoices/invoice/organisation_id/contact_id/invoice_id/download/';
export const INVOICE_UPDATE_PAYMENT = 'invoices/payments/organisation_id/contact_id/invoice_id/payment_id/';
export const PAYMENT_ARRANGEMENT_CRUD = 'payment-arrangements/arrangements/organisation_id/payment_arrangement_id/';
export const PAYMENT_ARRANGEMENT_PAY_IN_FULL_CRUD =
    'payment-arrangements/arrangements/pay/organisation_id/payment_arrangement_id/';
export const PAYMENT_ARRANGEMENTS_CRUD = 'payment-arrangements/arrangements/organisation_id/';
export const PAYMENT_ARRANGEMENT_INSTALLMENT_CRUD =
    'payment-arrangements/installments/organisation_id/payment_arrangement_id/payment_arrangement_installment_id/';
export const PAYMENT_ARRANGEMENT_INSTALLMENTS_CRUD =
    'payment-arrangements/installments/organisation_id/payment_arrangement_id/';
export const PAYMENT_ARRANGEMENT_NOTE_CRUD =
    'payment-arrangements/notes/organisation_id/payment_arrangement_id/payment_arrangement_note_id/';
export const PAYMENT_ARRANGEMENT_NOTES_CRUD = 'payment-arrangements/notes/organisation_id/payment_arrangement_id/';
export const PAYMENT_METHODS = 'payments/payment-methods/';
export const PAYMENT_METHOD_SCHEMES = 'payments/payment-methods/schemes/';
export const PAYMENT_METHOD_STATUSES = 'payments/payment-methods/statuses/';
export const PAYMENT_METHOD_TYPES = 'payments/payment-methods/types/';
export const PAYMENT_METHOD_CRUD = 'payments/payment-method/payment_method_id/';
export const PAYMENT_SERVICE_GATEWAY_PROVIDERS = 'payments/gateways/payment_service_id/providers/';
export const PAYMENT_SERVICE_AUTHORIZATION = 'payments/gateway/connect/payment_service_id/organisation_id/';
export const PAYMENT_SERVICE_EDIT = 'payments/gateway/payment_gateway_id/organisation_id/';
export const PAYMENT_PLAN_CRUD = 'payment-arrangements/plans/organisation_id/payment_plan_id/';
export const PAYMENT_PLANS_CRUD = 'payment-arrangements/plans/organisation_id/';
export const PAYMENT_GATEWAY_CARDS = 'payments/cards/payment_gateway_id/';
export const PAYMENT_GATEWAY_CRUD_CARDS = 'payments/cards/payment_gateway_id/card_id/';
export const PAYMENT_GATEWAY_GET_FAT_ZEBRA_VERIFICATION_DATA = 'payments/fatzebra/get-verification-data/';
export const PAYMENT_GATEWAY_ORGANISATION_GET_FAT_ZEBRA_VERIFICATION_DATA =
    'payments/fatzebra/get-verification-data/organisation_id/';
export const PAYMENT_GATEWAY_CRUD = 'payments/gateway/payment_gateway_id/';
export const ORGANISATION_PAYMENT_GATEWAY_CRUD = 'payments/gateway/payment_gateway_id/organisation_id/';
export const PAYMENT_GATEWAY_CRUD_ACCOUNT_ID = 'payments/gateway/payment_gateway_id/account_id/';
export const PAYMENT_GATEWAY_CRUD_ACCOUNT_ID_ORGANISATION_ID =
    'payments/gateway/payment_gateway_id/account_id/organisation_id/';
export const PAYMENT_GATEWAY_SET_DEFAULT = 'payments/gateway/recurring/payments/default/payment_gateway_id/';
export const PAYMENT_GATEWAY_GET_SAVED_CARDS = 'payments/saved-cards/payment_gateway_id/';
export const PAYMENT_GATEWAY_UPDATE_CARD = 'payments/update-card/';
export const PAYMENT_GATEWAYS = 'payments/gateways/';
export const PAYMENT_GATEWAYS_ORGANISATION = 'payments/gateways/organisation_id/';
export const PAYMENT_GATEWAYS_ORGANISATION_CRUD = 'payments/gateways/organisation_id/payment_gateway_id';

export const PAYMENTS_ACCOUNTS_COUPONS = 'coupons/account/account_id/';
export const PAYMENTS_BANK_ACCOUNT_CREATE_LIST = 'payments/bank-accounts/';
export const PAYMENTS_BANK_ACCOUNT_UPDATE_DELETE = 'payments/bank-account/payment_bank_account_id/';
export const PAYMENTS_CONTACT_ORDER_DETAILS =
    'orders/order_type/organisation/organisation_id/contact_id/order/order_id/';
export const PAYMENTS_CONTACT_ORDER_STATUS =
    'orders/order_type/organisation/organisation_id/contact_id/order/order_id/status/';
export const PAYMENTS_CREATE_ORDER = 'orders/order_type_id/';
export const PAYMENTS_DELETE_CARD = 'payments/delete-card/card_id/';
export const PAYMENTS_GET_ACCOUNTS = 'payments/payment-accounts/organisation_id/';
export const PAYMENTS_GET_ALL_CARDS = 'payments/saved-cards/';
export const PAYMENTS_GET_CLIENT_TOKEN = 'payments/get-client-token/';
export const PAYMENTS_GET_CONTACT_ORDER_DETAILS =
    'orders/order_type/organisation/organisation_id/contact_id/order/order_id/';
export const PAYMENTS_GET_FAT_ZEBRA_VERIFICATION_DATA = 'payments/fatzebra/get-verification-data/';
export const PAYMENTS_GET_ORDER_DETAILS = 'orders/order_type/order/order_id/';
export const PAYMENTS_GET_ORDER_STATUS = 'orders/order_type/order/order_id/status/';
export const PAYMENTS_GET_STATUSES = 'payments/statuses/';
export const PAYMENTS_ORDER_STATUSES_LIST = 'orders/statuses/';
export const PAYMENTS_ORDER_TYPES_LIST = 'orders/types/';
export const PAYMENTS_ORDERS_GET_ALL = 'orders/order_type/';
export const PAYMENTS_UPDATE_CARD = 'payments/update-card/';
export const PLACEHOLDER_ORGANISATION_UPDATE = 'placeholders/placeholder/placeholder_id/organisation_id/';
export const PLACEHOLDER_UPDATE = 'placeholders/placeholder/placeholder_id/';
export const PLACEHOLDERS = 'placeholders/';
export const PLACEHOLDERS_BY_ORGANISATION = 'placeholders/organisation_id/';

// R
export const REMINDER_TYPES_GET_ALL = 'schedulers/get-notification-types/';
export const REMINDERS_GET_ALL = 'reminders/organisation_id/';
export const REMINDERS_GET_DETAILS = 'reminders/organisation_id/reminder_id/';
export const REMINDERS_SEND_ON_DEMAND_CAMPAIGN = 'reminders/organisation_id/on-demand-campaign/send/';
export const REMINDERS_SEND_REMINDER = 'reminders/organisation_id/send/';
export const REQUEST_QUOTE = 'plans/quotes/';
export const REQUEST_QUOTE_LIST = 'plans/quotes/list/';

// S
export const SCHEDULER_GET_DETAILS = 'schedulers/scheduler/scheduler_id/';
export const SCHEDULER_ORGANISATION_GET_DETAILS = 'schedulers/scheduler/scheduler_id/organisation_id/';
export const SCHEDULERS = 'schedulers/';
export const SCHEDULERS_ORGANISATION_SCHEDULERS = 'schedulers/organisation_id/';
export const SETTINGS_GET_INITIAL_DATA = 'settings/get-initial-data/';
export const SUBSCRIPTION_DETAILS = 'subscriptions/subscription_id/';
export const SUBSCRIPTION_PLAN_ACCOUNTS_GET_ALL = 'plans/plan_id/accounts/';
export const SUBSCRIPTION_PLAN_GET_ALL = 'plans/';
export const SUBSCRIPTION_PLAN_GET_DETAILS = 'plans/plan_id/';
export const SUBSCRIPTION_STATUS_GET_ALL = 'subscriptions/statuses/';
export const SUBSCRIPTIONS_GET_ALL = 'subscriptions/';

// T
export const TRY_DEMO_COMPANY = 'accounts/try-demo-company/account_id/';
export const TAX_TYPES = 'taxes/types/';
export const TAXES_CRUD = 'taxes/';
export const TAXES_ORGANISATION_CRUD = 'taxes/organisation_id/';
export const TAX_CRUD = 'taxes/tax/tax_id/';
export const TAX_ORGANISATION_CRUD = 'taxes/tax/tax_id/organisation_id/';

// U
export const UPDATE_ACCOUNT_DETAILS = 'accounts/account_id/';
export const USER_CHANGE_PASSWORD = 'users/change-password/';
export const USER_CHANGE_STATUS = 'users/status/account_id/user_id/';
export const USER_EDIT_USER = 'users/account_id/user_id/';
export const USER_FORGOT_PASSWORD = 'users/forgot-password/';
export const USER_GET_PROFILE = 'users/profile/';
export const USER_GET_USER = 'users/account_id/user_id/';
export const USER_LOGIN = 'users/login/';
export const USER_LOGOUT = 'users/logout/';
export const USER_REGISTER = 'users/register/';
export const USER_RESEND_ACTIVATION_EMAIL = 'users/resend-activation-email/account_id/user_id/';
export const USER_RESEND_VERIFICATION_EMAIL = 'users/resend-verification-email/';
export const USER_RESEND_VERIFICATION_SMS = 'users/resend-verification-sms/';
export const USER_RESET_PASSWORD = 'users/reset-password/';
export const USER_RESET_TWO_FACTOR_AUTHENTICATION = 'users/disable-two-factor-auth/';
export const USER_VERIFY_EMAIL = 'users/verify-email/';
export const USER_VERIFY_PHONE_NUMBER = 'users/verify-phone-number/';

// Z
export const ZENDESK_SETTINGS = 'zendesk/settings/organisation_id/';
export const ZENDESK_TICKET = 'zendesk/tickets/organisation_id/ticket_id/';
export const ZENDESK_TICKET_OPTIONS = 'zendesk/ticket/options/';
export const ZENDESK_TICKETS = 'zendesk/tickets/organisation_id/';

/**
 * Change Password component
 * Renders the change password form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modules
import { React, Link } from '../../../includes/exports/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';

import ChangePasswordForm from '../forms/ChangePassword';
import { changeUserPassword } from '../../../includes/redux/actions/shared/user';
import { withTranslation } from 'react-i18next';
import SuccessMessage from '../../shared/messages/SuccessMessage';
import { getLocalStorageValue, modifyUrlBasedOnRole } from '../../../includes/utils';
import * as constants from '../../../includes/constants';
import { get } from 'lodash';

import './styles.scss';

/**
 * Change Password component
 * Renders the change password form
 */
const ChangePassword = props => {
    const currentAccountId = getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY);

    /**
     * Submit handler function for handling changing user password
     */
    const handleSubmit = values => props.changeUserPassword(values);

    /**
     * Render the component
     *
     * @returns {*}
     */
    return (
        <div className="home-content-wrapper">
            <div className="tab-arrow-link">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link
                            to={modifyUrlBasedOnRole(
                                '/my-profile',
                                get(props, ['roles', currentAccountId, 'slug'], '')
                            )}
                        >
                            {props.t('sharedMessages.changePassword.breadcrumb.myProfile')}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {props.t('sharedMessages.changePassword.breadcrumb.changePassword')}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h2 className="page-title">{props.t('sharedMessages.changePassword.title')}</h2>
            <section className="full-wrapper white-box-wrapper mid-wrapper change-password-wrapper">
                <section>
                    {props.passwordUpdated && <SuccessMessage message={props.passwordUpdatedMessage} />}
                    <ChangePasswordForm onSubmit={handleSubmit} {...props} />
                </section>
            </section>
        </div>
    );
};

/**
 * Proptypes
 */
ChangePassword.propTypes = {
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool,
    passwordUpdated: PropTypes.bool,
    passwordUpdatedMessage: PropTypes.string,
    t: PropTypes.func,
    changeUserPassword: PropTypes.func,
    userDetails: PropTypes.object,
};

//Connect to store
export default connect(
    state => ({
        ...state.user,
    }),
    {
        changeUserPassword,
    }
)(withTranslation()(ChangePassword));

import { PAYMENT_ARRANGEMENT_STATUS_OPTIONS } from 'includes/constants';
import i18n from 'includes/i18n';

/**
 * Get payment arrangement and extensions columns
 *
 * @param {string} planType Plan Type
 */
export const getPaymentArrangementsTableColumns = planType => {
    const paymentArrangementTableColumns = [
        {
            title: i18n.t('messages.paymentArrangement.arrangement.list.label.contact'),
            dataIndex: 'contact',
            key: 'contact',
            collapseHeader: true,
            sorter: true,
        },
        {
            title: i18n.t('messages.paymentArrangement.arrangement.list.label.upfront_payment_date'),
            dataIndex: 'upfront_payment_date',
            key: 'upfront_payment_date',
            sorter: true,
        },
        {
            title: i18n.t('messages.paymentArrangement.arrangement.list.label.upfront_amount'),
            dataIndex: 'upfront_amount',
            key: 'upfront_amount',
            sorter: true,
            collapseHeader: true,
        },
    ];

    if (planType === 'extension') {
        paymentArrangementTableColumns.push(
            ...[
                {
                    title: i18n.t('messages.paymentArrangement.arrangement.list.label.payment_extension_date'),
                    dataIndex: 'payment_extension_date',
                    key: 'payment_extension_date',
                },
                {
                    title: i18n.t('messages.paymentArrangement.arrangement.list.label.payment_extension_amount'),
                    dataIndex: 'next_payment_amount',
                    key: 'next_payment_amount',
                    sorter: true,
                },
            ]
        );
    } else {
        paymentArrangementTableColumns.push(
            ...[
                {
                    title: i18n.t('messages.paymentArrangement.arrangement.list.label.payment_frequency'),
                    dataIndex: 'payment_frequency',
                    key: 'payment_frequency',
                },
                {
                    title: i18n.t('messages.paymentArrangement.arrangement.list.label.next_payment_date'),
                    dataIndex: 'next_payment_date',
                    key: 'next_payment_date',
                    sorter: true,
                },
                {
                    title: i18n.t('messages.paymentArrangement.arrangement.list.label.next_payment_amount'),
                    dataIndex: 'next_payment_amount',
                    key: 'next_payment_amount',
                    sorter: true,
                },
            ]
        );
    }

    paymentArrangementTableColumns.push({
        title: i18n.t('messages.paymentArrangement.arrangement.list.label.status'),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        filterMultiple: false,
        filters: PAYMENT_ARRANGEMENT_STATUS_OPTIONS.map(status => ({ text: status.label, value: status.id })),
    });

    return paymentArrangementTableColumns;
};

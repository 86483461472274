/**
 * useSubscriptionPlans
 * Fetches subscription plans and caches it
 *
 */

import { get } from 'lodash';

import { GET_SUBSCRIPTION_PLANS } from 'includes/constants/mappings/success';
import { CACHE_SUBSCRIPTION_PLANS_KEY } from '../constants';
import SubscriptionPlanApi from 'includes/services/customer/subscriptionPlan';
import useCachedData from './useCachedData';

/**
 * Returns subscription plans if cached else call the API and caches the result
 *
 * @param {boolean} disableApiCall should disable API call
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useSubscriptionPlans = (disableApiCall = false) => {
    return useCachedData(
        CACHE_SUBSCRIPTION_PLANS_KEY,
        () => SubscriptionPlanApi.getSubscriptionPlans({ page_size: 0 }),
        {
            selector: res => {
                const subscriptionPlans = get(res, GET_SUBSCRIPTION_PLANS);
                return subscriptionPlans;
            },
            disableApiCall,
            dependsOnOrg: false,
        },
        []
    );
};

export default useSubscriptionPlans;

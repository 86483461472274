import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { getAccountCoupons } from 'includes/redux/actions/shared/account';
import { useTranslation } from 'react-i18next';
import Coupon from './components/Coupon';

/**
 * Dialog that fetches, displays and lets user apply a coupon
 *
 * @since 2.8.0
 */
export default function ApplyCouponDialog({ currencyCode, isOpen, onClose, onApply, selectedCoupon }) {
    const dispatch = useDispatch();

    const accountId = useSelector(state => state.account.currentAccountId);

    const coupons = useSelector(state => state.account.coupons);

    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getAccountCoupons(accountId));
    }, [accountId]);

    return (
        <Modal className="apply-coupon-modal custom-form-modal" visible={isOpen} onCancel={onClose} footer={[]}>
            <header>{t('customerMessages.applyCouponDialog.header')}</header>
            <div className="flex flex-col gap-6 mt-5">
                {coupons.length > 0 ? (
                    <>
                        {coupons.map(coupon => (
                            <Coupon
                                key={coupon.id}
                                code={coupon.code}
                                currencyCode={currencyCode}
                                discountAmount={coupon.discount_amount}
                                discountPercentage={coupon.discount_percentage}
                                isApplied={selectedCoupon === coupon.id}
                                onApply={() => onApply(coupon.id)}
                                redeemedDate={coupon.redeemed_date}
                            />
                        ))}
                    </>
                ) : (
                    t('customerMessages.applyCouponDialog.noCouponsAvailable')
                )}
            </div>
        </Modal>
    );
}

ApplyCouponDialog.propTypes = {
    currencyCode: PropTypes.string,
    isOpen: PropTypes.bool,
    onApply: PropTypes.func,
    onClose: PropTypes.func,
    selectedCoupon: PropTypes.string,
};

/**
 * Combines the reducers and exports it
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';

import { connectRouter } from '../../../exports/react';
import history from '../../../exports/history';
import * as reducers from '../../reducers';
import { USER_LOGOUT_REQUEST } from '../../../constants/actionTypes';

//combine the reducers
let appReducer = combineReducers({
    ...reducers,
    router: connectRouter(history),
    form: formReducer,
});

let rootReducers = (state, action) => {
    if (action.type === USER_LOGOUT_REQUEST) {
        state = {
            settings: { siteSettings: state.settings.siteSettings },
        };
    }
    return appReducer(state, action);
};

export default rootReducers;

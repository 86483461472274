/**
 * Payment plans breadcrumb items
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import { useParams } from 'react-router-dom';
import AccountsBreadCrumbItems from '../Accounts';
import OrganisationsBreadCrumbItems from '../Organisations';
import PropTypes from 'prop-types';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Breadcrumb } from 'antd';
import { get, find } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useIsDebtor from 'includes/hooks/useIsDebtor';
import { PAYMENT_ARRANGEMENT_PLAN_TYPE_EXTENSION } from 'includes/constants';

const PaymentArrangementBreadCrumbItems = props => {
    const accountId = useAccountId();
    const isAdminOrManager = useIsAdminOrManager();
    const isDebtor = useIsDebtor();
    const organisationId = useOrganisationId();
    const params = useParams();
    const { t } = useTranslation();
    const { contactId, invoiceId, paymentPlanId } = params;

    const paymentArrangement = useSelector(state => state.paymentArrangement.paymentArrangement);
    const paymentArrangementPlanType = paymentArrangement?.payment_plan?.type;
    const paymentArrangementInvoices = paymentArrangement?.invoices;

    /**
     * Get additional breadcrumb items based on invoice, contact or plan
     */
    const getAdditionalBreadCrumbItems = () => {
        if (invoiceId) {
            return (
                <Breadcrumb.Item>
                    <Link to={getInvoicesLink()}>{t('messages.breadCrumbs.invoices.list')}</Link>
                </Breadcrumb.Item>
            );
        } else if (contactId) {
            return (
                <Breadcrumb.Item>
                    <Link to={getContactsLink()}>{t('messages.breadCrumbs.contacts.list')}</Link>
                </Breadcrumb.Item>
            );
        } else if (paymentPlanId) {
            return (
                <Breadcrumb.Item>
                    <Link
                        to={
                            isAdminOrManager
                                ? `/accounts/organisation/payment-plans/${accountId}/${organisationId}`
                                : '/payment-plans'
                        }
                    >
                        {t('messages.breadCrumbs.paymentArrangement.plans.list')}
                    </Link>
                </Breadcrumb.Item>
            );
        }
    };

    /**
     * Get the contacts list link
     *
     * @returns {string} List link
     */
    const getContactsLink = () => {
        const urlNamespace = get(paymentArrangement, 'contact.is_manager') ? 'contact-manager' : 'contact';
        return isAdminOrManager
            ? `/admin/accounts/organisation/${urlNamespace}/${accountId}/${organisationId}`
            : isDebtor
            ? '/debtor/contacts'
            : `/${urlNamespace}`;
    };

    /**
     * Get the invoices list link
     *
     * @returns {string} List link
     */
    const getInvoicesLink = () =>
        isAdminOrManager
            ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}`
            : isDebtor
            ? '/debtor/invoices'
            : `/invoices`;

    /**
     * Get the payment arrangement list link
     *
     * @returns {string} List link
     */
    const getPaymentArrangementListLink = () => {
        const adminUrlNameSpace = isAdminOrManager ? `/admin/accounts/organisation` : '';
        const urlNamespace = isAdminOrManager ? `${accountId}/${organisationId}` : '';
        const slash = isAdminOrManager ? '/' : '';
        if (invoiceId) {
            return `${adminUrlNameSpace}/invoices/invoice/${urlNamespace}${slash}${contactId}/${invoiceId}`;
        } else if (contactId) {
            return `${adminUrlNameSpace}/contact/${urlNamespace}${slash}${contactId}`;
        } else if (paymentPlanId) {
            return `${adminUrlNameSpace}/payment-plans/plan/${urlNamespace}${slash}${paymentPlanId}`;
        } else {
            return `${adminUrlNameSpace}/payment-${
                paymentArrangementPlanType === PAYMENT_ARRANGEMENT_PLAN_TYPE_EXTENSION ? 'extensions' : 'arrangements'
            }${slash}${urlNamespace}`;
        }
    };

    /**
     * Get the payment arrangement list name
     *
     * @returns {string} List name
     */
    const getPaymentArrangementListName = () => {
        if (invoiceId) {
            return get(find(paymentArrangementInvoices, { id: invoiceId }), 'invoice_number');
        } else if (contactId) {
            return paymentArrangement?.contact?.name;
        } else if (paymentPlanId) {
            return paymentArrangement?.payment_plan?.name;
        } else {
            return t(
                `messages.breadCrumbs.paymentArrangement.${
                    paymentArrangementPlanType === PAYMENT_ARRANGEMENT_PLAN_TYPE_EXTENSION
                        ? 'extensions'
                        : 'arrangements'
                }.list`
            );
        }
    };

    /**
     * Render the component
     */
    return (
        <>
            {isAdminOrManager ? (
                <>
                    <AccountsBreadCrumbItems showListBreadCrumb />
                    <OrganisationsBreadCrumbItems showListBreadCrumb />
                </>
            ) : null}
            {props.showListBreadCrumb ? (
                <>
                    {getAdditionalBreadCrumbItems()}
                    <Breadcrumb.Item>
                        <Link to={getPaymentArrangementListLink()}>{getPaymentArrangementListName()}</Link>
                    </Breadcrumb.Item>
                </>
            ) : null}
            {props.showDetailsBreadCrumb ? (
                <Breadcrumb.Item>
                    Payment{' '}
                    {paymentArrangementPlanType === PAYMENT_ARRANGEMENT_PLAN_TYPE_EXTENSION
                        ? 'extension'
                        : 'arrangement'}{' '}
                    by {get(paymentArrangement, 'contact.name')}
                </Breadcrumb.Item>
            ) : null}
        </>
    );
};

PaymentArrangementBreadCrumbItems.propTypes = {
    action: PropTypes.string,
    planType: PropTypes.string,
    showAddEditBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumbAsLink: PropTypes.bool,
    showListBreadCrumb: PropTypes.bool,
};

export default PaymentArrangementBreadCrumbItems;

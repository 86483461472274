/**
 * Logs Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import LogsApi from 'includes/services/logs';
import createAppSlice from './base';
import { AUDIT_LOGS } from 'includes/constants/mappings/success';
import { get } from 'lodash';
import { getPaginationData } from 'includes/utils';

// set the initial state
const initialState = {
    auditLogs: [],
    auditLogsPagination: {},
    loadingAuditLogs: false,
};

// define the slice
const LogsSlice = createAppSlice('logsSlice', initialState);
const { setData } = LogsSlice.actions;

/**
 * Get the audit logs
 *
 * @param {string} accountId Account Id. Default ''
 * @param {object} searchParams. Search params. Default {}
 */
export const getAuditLogs = (accountId = '', searchParams = {}) => async dispatch => {
    try {
        dispatch(setData('loadingAuditLogs', true));
        const result = await LogsApi.getAuditLogs(accountId, searchParams);
        dispatch(setData('auditLogs', get(result, AUDIT_LOGS)));
        dispatch(setData('auditLogsPagination', getPaginationData({ result })));
    } finally {
        dispatch(setData('loadingAuditLogs'));
    }
};

// export the reducer
export default LogsSlice.reducer;

/**
 * Organisation edit Component
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

// import required modules
import { React, Link, useEffect, useState } from '../../../includes/exports/react';
import { connect } from 'react-redux';
import { get, isString } from 'lodash';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';

import { objectToFormData } from '../../../includes/utils/form';
import EditOrganisationForm from '../../shared/forms/EditOrganisation';
import * as requestKeys from '../../../includes/constants/keys/request';
import * as responseKeys from '../../../includes/constants/keys/response';
import {
    getOrganisationDetails,
    updateOrganisation,
    addOrganisation,
} from '../../../includes/redux/actions/customer/organisation';
import * as constants from '../../../includes/constants';
import Loader from '../../shared/Loader';
import { useUpdateBusinessName, useCheckEditPermission } from '../../../includes/utils/hooks';
import useCountries from 'includes/hooks/useCountries';
import useCurrencies from 'includes/hooks/useCurrencies';
import './style.scss';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';

/**
 * Edit Organisation
 * Renders the edit organisation form
 */
const EditOrganisation = props => {
    const isAdd = props.action === 'add';
    // state to handle profile image picker modal visibility
    const [imagePickerVisible, setImagePickerVisible] = useState(false);

    const toggleImagePicker = () => setImagePickerVisible(!imagePickerVisible);

    const { isLoading: loadingCountries } = useCountries(true);
    const { isLoading: loadingCurrencies } = useCurrencies(true);

    const { isAdmin, match, organisationDetails, getOrganisationDetails } = props;

    const { accountId } = match.params;
    const { organisationId } = match.params;
    const { reset: clearOrganisationCurrenciesCache } = useOrganisationCurrencies(true);

    // effect to fetch contact details
    useEffect(() => {
        if (get(organisationDetails, 'id') !== organisationId && !isAdd) getOrganisationDetails(organisationId);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Submit handler function for handling edit contact form
     */
    const handleSubmit = values => {
        // the values object may contain the url of the user avatar if no new avatar is uploaded,
        // we don't need it to submit with the form remove it from the object
        if (requestKeys.AVATAR in values && isString(get(values, requestKeys.AVATAR))) {
            delete values[requestKeys.AVATAR];
        }

        if (requestKeys.ORGANISATION_ACCOUNTING_SOFTWARE_DATA in values) {
            delete values[requestKeys.ORGANISATION_ACCOUNTING_SOFTWARE_DATA];
        }

        if (requestKeys.CURRENCY in values) {
            delete values[requestKeys.CURRENCY];
        }

        if (requestKeys.COUNTRY in values) {
            delete values[requestKeys.COUNTRY];
        }

        if (isAdd) {
            props.addOrganisation(
                objectToFormData({
                    ...values,
                }),
                clearOrganisationCurrenciesCache
            );
        } else {
            props.updateOrganisation(
                objectToFormData({
                    ...values,
                }),
                get(props, 'organisationDetails.id'),
                accountId,
                clearOrganisationCurrenciesCache
            );
        }
    };

    useUpdateBusinessName();

    useCheckEditPermission();

    const getLocale = path => props.t(`customerMessages.organisationForm.${path}`);

    return (
        <div className="home-content-wrapper edit-profile-wrapper">
            <div className="tab-arrow-link">
                {isAdmin ? (
                    <div className="tab-arrow-link breadcrumb-spec">
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to={`/admin/accounts/organisations/${accountId}`}>
                                    {getLocale('breadcrumb.organisations')}
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/admin/accounts/organisation/${accountId}/${organisationId}`}>
                                    {getLocale('breadcrumb.organisationDetails')}
                                </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{getLocale('breadcrumb.editOrganisation')}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                ) : (
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={'/organisations'}>{getLocale('breadcrumb.organisations')}</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {getLocale(`breadcrumb.${isAdd ? 'add' : 'edit'}Organisation`)}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                )}
            </div>
            <h2 className="page-title">{getLocale(`pageTitle.${isAdd ? 'add' : 'edit'}`)}</h2>
            <div className={isAdmin ? 'white-box-wrapper full-wrapper' : 'white-box-wrapper mid-wrapper'}>
                {props.isDetailsLoading || loadingCountries || loadingCurrencies ? (
                    <Loader />
                ) : (
                    <EditOrganisationForm
                        initialValues={
                            isAdd ? { [requestKeys.LOGIN_REGISTRATION_ENABLED]: true } : props.organisationDetails
                        }
                        onSubmit={handleSubmit}
                        onImageSubmit={image => {
                            props.setField(constants.EDIT_ORGANISATION_FORM_NAME, requestKeys.LOGO, image);
                            toggleImagePicker();
                        }}
                        onCancel={toggleImagePicker}
                        imagePickerVisible={imagePickerVisible}
                        toggleImagePicker={toggleImagePicker}
                        defaultImage={require('../../../assets/images/organisation_avatar.svg')}
                        isAdd={isAdd}
                        enableReinitialize
                        {...props}
                    />
                )}
            </div>
        </div>
    );
};

/**
 * Proptypes
 */
EditOrganisation.propTypes = {
    action: PropTypes.string,
    addOrganisation: PropTypes.func,
    defaultUserAvatar: PropTypes.string,
    error: PropTypes.any,
    getAccountingSoftwares: PropTypes.func,
    getContactDetails: PropTypes.func,
    getCountries: PropTypes.func,
    getOrganisationDetails: PropTypes.func,
    handleSubmit: PropTypes.func,
    isDetailsLoading: PropTypes.bool,
    isUpdating: PropTypes.bool,
    match: PropTypes.object,
    organisationDetails: PropTypes.object,
    organisationId: PropTypes.string,
    setField: PropTypes.func,
    t: PropTypes.func,
    updateOrganisation: PropTypes.func,
    userDetails: PropTypes.object,
    path: PropTypes.string,
    isAdmin: PropTypes.bool,
};

//Connect to store
export default connect(
    state => ({
        defaultOrganisationLogo: get(state.settings.siteSettings, responseKeys.DEFAULT_ORGANISATION_LOGO_45X45),
        organisationId: state.organisation.selectedOrganisationId,
        organisationDetails: state.organisation.organisationDetails,
        isUpdating: state.organisation.isUpdating,
        defaultUserAvatar: get(state.settings.siteSettings, responseKeys.DEFAULT_USER_AVATAR_254x254),
        isCreatingOrganisation: state.organisation.isCreatingOrganisation,
        isDetailsLoading: state.organisation.isDetailsLoading,
        detailsUpdatedMessage: state.contacts.detailsUpdatedMessage,
        userDetails: state.user.userDetails,
        roles: state.user.roles,
        isAdmin: state.account.isAdmin,
    }),
    {
        getOrganisationDetails,
        setField: change,
        updateOrganisation,
        addOrganisation,
    }
)(withTranslation()(EditOrganisation));

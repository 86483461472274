/**
 * Payment arrangement note add/edit Form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React, { useState } from 'react';
import './style/style.scss';
import { addPaymentArrangementNote, updatePaymentArrangementNote } from 'includes/slices/paymentArrangement';
import { Form, Row, Col, Comment, Input, Button, Checkbox, Tooltip, Icon } from 'antd';
import { isFunction, trim } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import UserAvatar from 'assets/images/user-avatar.svg';
import PermissionGuard from 'components/PermissionGuard';
import { PAYMENT_ARRANGEMENT } from 'includes/constants/permissions';

const { TextArea } = Input;

const PaymentArrangementNoteAddEditForm = props => {
    const { t } = useTranslation();
    const [noteValue, setNoteValue] = useState(props.paymentArrangementNoteValue);
    const [notifyDebtor, setNotifyDebtor] = useState(false);
    const [checked, setChecked] = useState(false);
    const [formError, setFormError] = useState(false);
    const dispatch = useDispatch();
    const organisationId = useOrganisationId();
    const userDetails = useSelector(state => state.user.userDetails);

    /**
     * Close the form edit block
     */
    const closeEditBlock = () => {
        setNoteValue('');
        setNotifyDebtor(false);
        setChecked(false);
        props.setPaymentArrangementNoteId('');
    };

    /**
     * Validate the form and submit
     */
    const validateAndSubmit = () => {
        const sanitizedValue = trim(noteValue);
        if (!sanitizedValue) {
            setFormError(t(`fieldErrors.payment_arrangement_note_form.required.note`));
        } else {
            setFormError();
            if (props.paymentArrangementNoteId) {
                dispatch(
                    updatePaymentArrangementNote(
                        organisationId,
                        props.paymentArrangementId,
                        props.paymentArrangementNoteId,
                        {
                            note: sanitizedValue,
                            page: props.currentPage,
                        },
                        closeEditBlock
                    )
                );
            } else {
                dispatch(
                    addPaymentArrangementNote(
                        organisationId,
                        props.paymentArrangementId,
                        {
                            note: sanitizedValue,
                            page: props.currentPage,
                            notify_debtor: notifyDebtor,
                        },
                        closeEditBlock
                    )
                );
            }
        }
    };

    return (
        <div className="my-profile-detail">
            <Row gutter={16}>
                <Col>
                    <Comment
                        author={userDetails?.full_name || 'User'}
                        avatar={UserAvatar}
                        content={
                            <div>
                                <Form.Item className={formError ? 'has-error' : ''}>
                                    <TextArea rows={4} onChange={e => setNoteValue(e.target.value)} value={noteValue} />
                                    {formError ? (
                                        <div
                                            className="ant-form-explain"
                                            style={{ marginTop: '5px', textAlign: 'left' }}
                                        >
                                            {formError}
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                    {!props.paymentArrangementNoteId ? (
                                        <Checkbox
                                            checked={checked}
                                            value={notifyDebtor}
                                            onChange={e => {
                                                setChecked(e.target.checked);
                                                setNotifyDebtor(e.target.checked ? true : false);
                                            }}
                                            style={{ float: 'left' }}
                                        >
                                            {t(
                                                `messages.paymentArrangement.arrangement.addEdit.form.input.label.notify_debtor`
                                            )}
                                            <Tooltip
                                                title={t(
                                                    `messages.paymentArrangement.arrangement.note.info.notify_debtor`
                                                )}
                                            >
                                                <span style={{ marginLeft: 5, color: '#92959a' }}>
                                                    <Icon type="info-circle" theme="filled" />
                                                </span>
                                            </Tooltip>
                                        </Checkbox>
                                    ) : null}
                                </Form.Item>
                                <Form.Item style={{ textAlign: 'left' }}>
                                    <PermissionGuard
                                        requiredPermission={
                                            props.paymentArrangementNoteId
                                                ? PAYMENT_ARRANGEMENT.NOTES.EDIT
                                                : PAYMENT_ARRANGEMENT.NOTES.ADD
                                        }
                                    >
                                        <Button
                                            loading={props.loading}
                                            onClick={validateAndSubmit}
                                            type="primary"
                                            style={{ fontSize: '14px' }}
                                            className="white-bg-button"
                                        >
                                            {t(
                                                `messages.paymentArrangement.arrangement.note.addEdit.form.submit.${
                                                    props.loading ? 'buttonSubmittingLabel' : 'buttonLabel'
                                                }.${props.paymentArrangementNoteId ? 'edit' : 'add'}`
                                            )}
                                        </Button>
                                    </PermissionGuard>
                                    <Button
                                        onClick={() => {
                                            if (!props.paymentArrangementNoteId) {
                                                setNoteValue('');
                                            }
                                            if (
                                                props.setPaymentArrangementNoteId &&
                                                isFunction(props.setPaymentArrangementNoteId)
                                            ) {
                                                props.setPaymentArrangementNoteId('');
                                            }
                                        }}
                                        type="link"
                                        style={{ fontSize: '14px' }}
                                    >
                                        {t(
                                            `messages.paymentArrangement.arrangement.note.addEdit.form.submit.buttonLabel.cancel`
                                        )}
                                    </Button>
                                </Form.Item>
                            </div>
                        }
                    />
                </Col>
            </Row>
        </div>
    );
};

/**
 * Proptypes
 */
PaymentArrangementNoteAddEditForm.propTypes = {
    currentPage: PropTypes.bool,
    loading: PropTypes.bool,
    paymentArrangementId: PropTypes.string,
    paymentArrangementNoteId: PropTypes.string,
    paymentArrangementNoteValue: PropTypes.string,
    setPaymentArrangementNoteId: PropTypes.func,
};

export default PaymentArrangementNoteAddEditForm;

/**
 * Contact API class.
 * Handles all api requests related to contacts
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class ContactApi extends BaseApiHandler {
    /**
     * Call the add contact API
     *
     * @param {string} organisation_id Organisation Id
     * @param {object} contactDetails Contact details
     *
     * @returns {object} API Response
     */
    addContact = ({ organisation_id, contactDetails }) =>
        this.axios.post(this.getApiUrl('CONTACTS_ADD_CONTACT', { organisation_id }), contactDetails);

    /**
     * Call the add contact type API
     *
     * @param {string} organisationId Organisation id
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    addContactType = (organisationId, payload) =>
        this.axios.post(this.getApiUrl('CONTACT_TYPES_CRUD', { organisation_id: organisationId }), payload);

    /**
     * Call the Apply contact type API
     *
     * @param {string} organisation_id Organisation Id
     * @param {Array} contacts Contacts
     *
     * @returns {object} API Response
     */
    applyContactType = ({ organisation_id, contacts }) =>
        this.axios.patch(this.getApiUrl('CONTACTS_GET_ALL', { organisation_id }), contacts);

    /**
     * Call the delete contact API
     *
     * @param {object} payload Payload for deleting contact
     *
     * @returns {object} API Response
     */
    deleteContact = ({ organisation_id, contact_id, ...restPayload }) =>
        this.axios.delete(this.getApiUrl('CONTACTS_GET_CONTACT_DETAILS', { organisation_id, contact_id }), {
            params: restPayload,
        });

    /**
     * Call the delete contact type API
     *
     * @param {string} organisationId Organisation id
     * @param {string} contactTypeId Contact type id
     *
     * @returns {object} API Response
     */
    deleteContactType = (organisationId, contactTypeId) =>
        this.axios.delete(
            this.getApiUrl('CONTACT_TYPE_CRUD', { organisation_id: organisationId, contact_type_id: contactTypeId })
        );

    /**
     * Call the export contacts API
     *
     * @param {string} organisationId Organisation Id
     * @param {object} params Params
     * @param {Array} contactIds Contact Ids
     *
     * @returns {object} API Response
     */
    exportContacts = ({ organisationId, params, contactIds }) =>
        this.axios.post(
            this.getApiUrl('EXPORT_CONTACTS', { organisation_id: organisationId }),
            { ids: contactIds },
            {
                params: { ...params },
            }
        );

    /**
     * Call the contact details API
     *
     * @param {object} params params
     *
     * @returns {object} API Response
     */
    getContactDetails = params => this.axios.get(this.getApiUrl('CONTACTS_GET_CONTACT_DETAILS', params));

    /**
     * Call the contact types API
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getContactTypes = (organisationId, searchParams) =>
        this.axios.get(this.getApiUrl('CONTACT_TYPES_CRUD', { organisation_id: organisationId }), {
            params: { ...searchParams },
        });

    /**
     * Call the contacts API
     *
     * @param {object} searchParams Search params
     * @param {object} restPayload Payload
     *
     * @returns {object} API Response
     */
    getContacts = ({ searchParams, ...restPayload }) =>
        this.axios.get(this.getApiUrl('CONTACTS_GET_ALL', restPayload), { params: { ...searchParams } });

    /**
     * Call the restore contact API
     *
     * @param {object} payload Payload for restoring contact
     *
     * @returns {object} API Response
     */
    restoreContact = ({ organisation_id, contact_id, ...restPayload }) =>
        this.axios.patch(this.getApiUrl('CONTACTS_UPDATE_CONTACT', { organisation_id, contact_id }), restPayload);

    /**
     * Call the Update contact details API
     *
     * @param {object} contactDetails Contact details
     * @param {object} restPayload Payload
     *
     * @returns {object} API Response
     */
    updateContactDetails = ({ contactDetails, ...restPayload }) =>
        this.axios.patch(this.getApiUrl('CONTACTS_UPDATE_CONTACT', restPayload), contactDetails);

    /**
     * Call the update contact type API
     *
     * @param {string} organisationId Organisation id
     * @param {string} contactTypeId Contact type id
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    updateContactType = (organisationId, contactTypeId, payload) =>
        this.axios.patch(
            this.getApiUrl('CONTACT_TYPE_CRUD', { organisation_id: organisationId, contact_type_id: contactTypeId }),
            payload
        );
}

export default new ContactApi();

/**
 * Internationalization Configurations
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { translationObject } from './locales';

const options = {
    interpolation: {
        escapeValue: false,
    },
    resources: translationObject,
    fallbackLng: 'en',
    ns: ['common'],
    defaultNS: 'common',
    react: {
        wait: false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
    },
};

i18n.use(LanguageDetector).init(options);

export default i18n;

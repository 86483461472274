import i18n from 'includes/i18n';
import AmexSVG from 'assets/images/card_brands/amex.svg';
import DinersSVG from 'assets/images/card_brands/diners.svg';
import DiscoverSVG from 'assets/images/card_brands/discover.svg';
import JcbSVG from 'assets/images/card_brands/jcb.svg';
import MastercardSVG from 'assets/images/card_brands/mastercard.svg';
import UnionPaySVG from 'assets/images/card_brands/unionpay.svg';
import VisaSVG from 'assets/images/card_brands/visa.svg';
import GenericSVG from 'assets/images/card_brands/generic.svg';

export const CARD_BRANDS = {
    AMEX: 'amex',
    DINERS: 'diners',
    DISCOVER: 'discover',
    JCB: 'jcb',
    MASTERCARD: 'mastercard',
    UNION_PAY: 'unionpay',
    VISA: 'visa',
};

export const CARD_BRAND_LOGO_MAP = {
    [CARD_BRANDS.AMEX]: AmexSVG,
    [CARD_BRANDS.DINERS]: DinersSVG,
    [CARD_BRANDS.DISCOVER]: DiscoverSVG,
    [CARD_BRANDS.JCB]: JcbSVG,
    [CARD_BRANDS.MASTERCARD]: MastercardSVG,
    [CARD_BRANDS.UNION_PAY]: UnionPaySVG,
    [CARD_BRANDS.VISA]: VisaSVG,
};

export const CARD_BRAND_NAME_MAP = {
    [CARD_BRANDS.AMEX]: i18n.t('customerMessages.paymentMethod.card.brand.amex'),
    [CARD_BRANDS.DINERS]: i18n.t('customerMessages.paymentMethod.card.brand.diners'),
    [CARD_BRANDS.DISCOVER]: i18n.t('customerMessages.paymentMethod.card.brand.discover'),
    [CARD_BRANDS.JCB]: i18n.t('customerMessages.paymentMethod.card.brand.jcb'),
    [CARD_BRANDS.MASTERCARD]: i18n.t('customerMessages.paymentMethod.card.brand.mastercard'),
    [CARD_BRANDS.UNION_PAY]: i18n.t('customerMessages.paymentMethod.card.brand.unionpay'),
    [CARD_BRANDS.VISA]: i18n.t('customerMessages.paymentMethod.card.brand.visa'),
};

export const GENERIC_CARD_LOGO = GenericSVG;

export const BANK_ACCOUNT_TYPE = {
    COMPANY: 'company',
    INDIVIDUAL: 'individual',
};

export const BANK_ACCOUNT_TYPE_OPTION = [
    { value: BANK_ACCOUNT_TYPE.COMPANY, name: i18n.t('customerMessages.paymentMethod.bankAccount.type.company') },
    { value: BANK_ACCOUNT_TYPE.INDIVIDUAL, name: i18n.t('customerMessages.paymentMethod.bankAccount.type.individual') },
];

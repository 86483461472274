/**
 * Templates  Component
 * Handles the templates view and functions related to templates
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import { TEMPLATE } from 'includes/constants/permissions';
import { React, connect, useState, withTranslation, PropTypes } from '../../../includes/exports/react';
//import EmailSettings from '../../shared/Email';
//import EmailTemplatesModal from '../../shared/Templates/Email';
import {
    addCommunicationTemplate,
    deleteCommunicationTemplate,
    getCommunicationTemplates,
    getCommunicationTemplateTypes,
    getCommunicationTypes,
    updateCommunicationTemplate,
} from '../../../includes/redux/actions/shared/communication';
import { useDataFromStore } from '../../../includes/utils/hooks';
import Templates from '../../shared/Templates';
import AddEditTemplate from '../../shared/Templates/addEdit';

/**
 * Communication Templates Component
 */
const CommunicationTemplates = props => {
    const [listView, setListView] = useState(true);
    const [initialValues, setInitialValues] = useState();
    const { type } = props;
    const { organisationId } = props.match.params;

    useDataFromStore({
        reducer: 'communication',
        dataKey: 'communicationTypes',
        loadingKey: 'isTypesLoading',
        action: getCommunicationTypes,
    });

    useDataFromStore({
        reducer: 'communication',
        dataKey: 'templateTypes',
        loadingKey: 'isTemplateTypesLoading',
        action: getCommunicationTemplateTypes,
        dataToSend: organisationId ? [organisationId] : ['', { get_all_template_types: !organisationId }],
    });

    const permissionSet = {
        email: TEMPLATE.EMAIL,
        ivr: TEMPLATE.IVR,
        sms: TEMPLATE.SMS,
    }[type];

    return listView ? (
        <Templates
            {...props}
            setListView={setListView}
            setInitialValues={setInitialValues}
            type={type}
            permissionSet={permissionSet}
        />
    ) : (
        <AddEditTemplate
            {...props}
            setListView={setListView}
            initialValues={initialValues}
            type={type}
            permissionSet={permissionSet}
        />
    );
};

/**
 * Prop Types
 */
CommunicationTemplates.propTypes = {
    match: PropTypes.object,
    type: PropTypes.string,
};

export default connect(
    state => ({
        isLoading: state.communication.isLoading,
        types: state.communication.communicationTypes,
        templates: state.communication.communicationTemplates,
        templateTypes: state.communication.templateTypes,
        isTypesLoading: state.communication.isTypesLoading,
        userDetails: state.user.userDetails,
    }),
    {
        addCommunicationTemplate,
        deleteCommunicationTemplate,
        getCommunicationTemplates,
        updateCommunicationTemplate,
    }
)(withTranslation()(CommunicationTemplates));

/**
 * Logs Slice
 *
 * @since 2.8.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import OpenBankingAPI from 'includes/services/openBanking';
import createAppSlice from './base';
import {
    BANK_ACCOUNTS,
    BANK_ACCOUNT_TRANSACTIONS,
    OPEN_BANKING_EXPENSE_CATEGORIES,
    OPEN_BANKING_PAYMENT_ANALYSIS,
} from 'includes/constants/mappings/success';
import { get } from 'lodash';
import { getPaginationData } from 'includes/utils';

// set the initial state
const initialState = {
    // account
    bankAccounts: [],
    isBankAccountsLoading: false,
    // transactions
    bankAccountTransactions: [],
    isBankAccountTransactionsLoading: false,
    bankAccountTransactionsPagination: {},
    // transactions by category
    bankAccountTransactionsByCategory: [],
    isBankAccountTransactionsByCategoryLoading: false,
    bankAccountTransactionsByCategoryPagination: {},
};

// define the slice
const OpenBankingSlice = createAppSlice('openBankingSlice', initialState);
const { setData } = OpenBankingSlice.actions;

/**
 * Get bank accounts
 *
 * @param {string} organisationId Organisation Id. Default ''
 * @param {object} searchParams. Search params. Default {}
 */
export const getBankAccounts = (organisationId = '', searchParams = {}) => async dispatch => {
    try {
        dispatch(setData('isBankAccountsLoading', true));
        const result = await OpenBankingAPI.getBankAccounts(organisationId, searchParams);
        dispatch(setData('bankAccounts', get(result, BANK_ACCOUNTS)));
    } finally {
        dispatch(setData('isBankAccountsLoading'));
    }
};

/**
 * Get bank account transactions
 *
 * @param {string} organisationId Organisation Id. Default ''
 * @param {object} searchParams. Search params. Default {}
 */
export const getBankAccountTransactions = (organisationId = '', searchParams = {}) => async dispatch => {
    try {
        dispatch(setData('isBankAccountTransactionsLoading', true));
        const result = await OpenBankingAPI.getBankAccountTransactions(organisationId, searchParams);
        dispatch(setData('bankAccountTransactions', get(result, BANK_ACCOUNT_TRANSACTIONS)));
        dispatch(setData('bankAccountTransactionsPagination', getPaginationData({ result })));
    } finally {
        dispatch(setData('isBankAccountTransactionsLoading'));
    }
};

/**
 * Get bank account transactions by category
 *
 * This is the same as `getBankAccountTransactions`. This is being used so that when in aggregated view of transactions and a category is selected, data is not overwritten.
 *
 * @param {string} organisationId Organisation Id. Default ''
 * @param {object} searchParams. Search params. Default {}
 */
export const getBankAccountTransactionsByCategory = (organisationId = '', searchParams = {}) => async dispatch => {
    try {
        dispatch(setData('isBankAccountTransactionsByCategoryLoading', true));
        const result = await OpenBankingAPI.getBankAccountTransactions(organisationId, searchParams);
        dispatch(setData('bankAccountTransactionsByCategory', get(result, BANK_ACCOUNT_TRANSACTIONS)));
        dispatch(setData('bankAccountTransactionsByCategoryPagination', getPaginationData({ result })));
    } finally {
        dispatch(setData('isBankAccountTransactionsByCategoryLoading'));
    }
};

/**
 * Get expense categories and percentages
 */
export const getExpenseCategories = () => async dispatch => {
    try {
        dispatch(setData('isExpenseCategoriesLoading', true));
        const result = await OpenBankingAPI.getExpenseCategories();
        dispatch(setData('expenseCategories', get(result, OPEN_BANKING_EXPENSE_CATEGORIES)));
    } finally {
        dispatch(setData('isExpenseCategoriesLoading'));
    }
};

/**
 * Get payment arrangement analysis
 *
 * @param {string} organisationId Organisation Id. Default ''
 * @param {string} analysisId Analysis Id. Default ''
 * @param {object} searchParams. Search params. Default {}
 */
export const getPaymentAnalysis = (organisationId = '', analysisId = '', searchParams = {}) => async dispatch => {
    try {
        dispatch(setData('isPaymentAnalysisLoading', true));
        const result = await OpenBankingAPI.getPaymentAnalysis(organisationId, analysisId, searchParams);
        dispatch(setData('paymentAnalysis', get(result, OPEN_BANKING_PAYMENT_ANALYSIS)));
    } finally {
        dispatch(setData('isPaymentAnalysisLoading'));
    }
};

/**
 * Get payment arrangement analysis categories
 *
 * @param {string} organisationId Organisation Id. Default ''
 * @param {string} analysisId Analysis Id. Default ''
 * @param {object} searchParams. Search params. Default {}
 */
export const getPaymentAnalysisCategories = (
    organisationId = '',
    analysisId = '',
    searchParams = {}
) => async dispatch => {
    try {
        dispatch(setData('isPaymentAnalysisCategoriesLoading', true));
        const result = await OpenBankingAPI.getPaymentAnalysisCategories(organisationId, analysisId, searchParams);
        dispatch(setData('paymentAnalysisCategories', get(result, OPEN_BANKING_PAYMENT_ANALYSIS)));
    } finally {
        dispatch(setData('isPaymentAnalysisCategoriesLoading'));
    }
};

// export the reducer
export default OpenBankingSlice.reducer;

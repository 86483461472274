/**
 * Invoices add/edit component
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { find, get } from 'lodash';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';

import ManualInvoiceForm from './components/Form';
import './styles.scss';
import {
    addInvoice,
    getInvoiceDetails,
    getInvoiceStatuses,
    getNextInvoiceNumber,
    updateInvoice,
} from 'includes/slices/invoice';
import Loader from 'components/Loader';
import InvoicesBreadCrumbItems from 'components/BreadCrumbItems/Invoices';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useInvoiceLinkGenerator from 'includes/hooks/useInvoiceLinkGenerator';
import { redirect } from 'includes/utils';
import { parseFloatOrZero } from '../utils';
import { useDataFromStore } from 'includes/utils/hooks';
import useToBEDateFormatter from 'includes/hooks/useToBEDateFormatter';
import useToFEDateFormatter from 'includes/hooks/useToFEDateFormatter';
import moment from 'moment';
import useOrganisationCurrency from 'includes/hooks/useOrganisationCurrency';
import { ID } from 'includes/constants/keys/response';
import ReactHtmlParser from 'react-html-parser';
import { INVOICE_STATUS_VOIDED_SLUG, INVOICE_STATUS_WRITE_OFF_SLUG } from 'includes/constants';

const AddEditInvoice = ({ action, isRecurring }) => {
    const { contactId, invoiceId } = useParams();
    const [isEditDisabled, setIsEditDisabled] = useState(false);
    const [showOnDemandCampaignModal, setShowOnDemandCampaignModal] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const invoiceDetails = useSelector(state => state.invoices.invoiceDetails);
    const isLoading = useSelector(state => state.invoices.isInvoiceDetailsLoading);
    const organisationId = useOrganisationId();
    const getInvoiceLink = useInvoiceLinkGenerator();
    const organisationCurrency = useOrganisationCurrency(ID);
    const isAdd = action === 'add';
    const convertDateStringToFEFormat = useToFEDateFormatter();
    const convertDateStringToBEAcceptableFormat = useToBEDateFormatter();
    const nextInvoiceNumber = useSelector(state => state.invoices.nextInvoiceNumber);
    const isNextInvoiceNumberLoading = useSelector(state => state.invoices.isNextInvoiceNumberLoading);

    const [invoiceStatuses] = useDataFromStore({
        reducer: 'invoices',
        dataKey: 'invoiceStatuses',
        loadingKey: 'isInvoiceStatusesLoading',
        action: getInvoiceStatuses,
    });

    const invoiceStatusSlug = get(find(invoiceStatuses, { id: invoiceDetails.status }), 'slug');

    // clean initial values before passing it down to form
    const initialValues = useMemo(() => {
        const contact = get(invoiceDetails, 'contact_id');
        const invoiceContact = get(invoiceDetails, 'invoice_contact_id');
        const cleanedDetails = {
            ...invoiceDetails,
            contact_id: get(invoiceDetails, 'contact_id.id', ''),
            invoice_contact_id: get(invoiceDetails, 'invoice_contact_id.id', ''),
            issued_date: convertDateStringToFEFormat(invoiceDetails.issued_date),
            due_date: convertDateStringToFEFormat(invoiceDetails.due_date),
            next_invoice_date: convertDateStringToFEFormat(invoiceDetails.next_invoice_date),
            repeating_end_date: convertDateStringToFEFormat(invoiceDetails.repeating_end_date),
            line_items: (invoiceDetails.line_items || []).map(item => ({
                ...item,
                _id: uuid(),
                first_touch: false,
                start_date: convertDateStringToFEFormat(item.start_date),
                end_date: convertDateStringToFEFormat(item.end_date),
            })),
            dropDownInitalValues: {
                // to trigger initial search name
                contact: contact ? [contact] : undefined,
                invoice_contact: invoiceContact ? [invoiceContact] : undefined,
            },
        };
        return cleanedDetails;
    }, [invoiceDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    // fetch invoice details
    useEffect(() => {
        if (!isAdd) {
            dispatch(
                getInvoiceDetails(organisationId, contactId, invoiceId, details => {
                    if (get(details, 'amount_paid.amount', 0) > 0) {
                        setIsEditDisabled(true);
                    }
                })
            );
        } else {
            dispatch(
                getNextInvoiceNumber(organisationId, { invoice_cat_type: isRecurring ? 'is_repeating_invoice' : '' })
            );
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isAdd && invoiceDetails) {
            if (invoiceDetails.is_repeating_invoice && !isRecurring) {
                redirect(getInvoiceLink(contactId, invoiceId, true, true));
            } else {
                redirect(getInvoiceLink(contactId, invoiceId, true, false));
            }
        }
    }, [invoiceDetails]); // eslint-disable-line react-hooks/exhaustive-deps
    /**
     * Get formatted invoice details before posting it
     * Also deletes unwanted data
     *
     * @param {object} details
     *
     * @returns {object} properly formatted invoice details
     */
    const cleanInvoiceDetails = details => {
        const formatDate = convertDateStringToBEAcceptableFormat;

        const parseLineItem = details => ({
            ...details,
            start_date: formatDate(details.start_date),
            end_date: formatDate(details.end_date),
            unit_price: parseFloatOrZero(details.unit_price),
            quantity: parseFloatOrZero(details.quantity),
            discount: parseFloatOrZero(details.discount),
        });

        const payload = {
            ...details,
            issued_date: formatDate(details.issued_date),
            due_date: formatDate(details.due_date),
            next_invoice_date: formatDate(details.next_invoice_date),
            repeating_end_date: formatDate(details.repeating_end_date),
            line_items: details.line_items.map(parseLineItem),
        };

        ['dropDownInitalValues', 'amount_credited', 'amount_due', 'amount_paid', 'contact'].forEach(key => {
            if (payload[key]) delete payload[key];
        });

        return payload;
    };

    const closeCampaignModal = () => setShowOnDemandCampaignModal(false);

    const handleSubmit = values => {
        values['is_repeating_invoice'] = isRecurring;

        if (invoiceId) {
            dispatch(
                updateInvoice(
                    organisationId,
                    contactId,
                    invoiceId,
                    cleanInvoiceDetails(values),
                    newContactId => redirect(getInvoiceLink(newContactId, invoiceId)),
                    closeCampaignModal
                )
            );
        } else {
            dispatch(
                addInvoice(
                    organisationId,
                    values['contact_id'],
                    cleanInvoiceDetails(values),
                    (contactId, invoiceId) => redirect(getInvoiceLink(contactId, invoiceId)),
                    closeCampaignModal
                )
            );
        }
    };

    /**
     * Returns the view based on `isLoading` and `isEditDisabled` variables
     *
     * @returns {Node} one of Loader, edit disabled message or Form
     */
    const renderView = () => {
        if (isLoading || isNextInvoiceNumberLoading) return <Loader />;

        return isEditDisabled ||
            invoiceStatusSlug === INVOICE_STATUS_VOIDED_SLUG ||
            invoiceStatusSlug === INVOICE_STATUS_WRITE_OFF_SLUG ? (
            <p className="edit-invoice-disabled-text">
                {ReactHtmlParser(
                    t(
                        `customerMessages.editInvoice.form.message.editDisabled.${
                            invoiceStatusSlug === INVOICE_STATUS_VOIDED_SLUG
                                ? 'isVoided'
                                : invoiceStatusSlug === INVOICE_STATUS_WRITE_OFF_SLUG
                                ? 'isWrittenOff'
                                : 'hasPayments'
                        }`,
                        { invoiceNumber: invoiceDetails?.invoice_number }
                    )
                )}
            </p>
        ) : (
            <ManualInvoiceForm
                isAdd={isAdd}
                onSubmit={handleSubmit}
                initialValues={
                    isAdd
                        ? {
                              line_amount_type: 1,
                              repeating_due_date_type: 2,
                              repeating_period_type: 3,
                              line_items: [],
                              child_invoice_state: 2,
                              issued_date: convertDateStringToFEFormat(moment()),
                              invoice_number: nextInvoiceNumber,
                              currency_id: organisationCurrency,
                          }
                        : initialValues
                }
                isRecurring={isRecurring}
                showOnDemandCampaignModal={showOnDemandCampaignModal}
                setShowOnDemandCampaignModal={setShowOnDemandCampaignModal}
                submitAction={handleSubmit}
                invoiceStatusSlug={invoiceStatusSlug}
            />
        );
    };

    return (
        <section>
            <div className="home-content-wrapper synced-name contact-details">
                <div className="tab-arrow-link breadcrumb-spec">
                    <InvoicesBreadCrumbItems
                        action={action}
                        showListBreadCrumb
                        showDetailsBreadCrumb={!isAdd}
                        showDetailsBreadCrumbAsLink
                        showAddEditBreadCrumb
                        isRecurring={isRecurring}
                    />
                </div>
                <h2 className="page-title">{isAdd ? (isRecurring ? 'Add Recurring' : 'Add') : 'Edit'} Invoice</h2>
                <div className="box-wrapper white-box-wrapper full-wrapper invoice-wrapper">{renderView()}</div>
            </div>
        </section>
    );
};

AddEditInvoice.propTypes = {
    action: PropTypes.string,
    isRecurring: PropTypes.bool,
};

AddEditInvoice.defaultProps = {
    isRecurring: false,
};

export default AddEditInvoice;

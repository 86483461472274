import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { find } from 'lodash';

import { usePaymentStatuses } from 'includes/utils/hooks';
import { PAYMENT_STATUS } from 'includes/constants';

const PAYMENT_STATUS_CLASSNAME_MAP = {
    [PAYMENT_STATUS.CANCELLED]: 'bg-red-200 text-red-800',
    [PAYMENT_STATUS.FAILED]: 'bg-red-100 text-red-800',
    [PAYMENT_STATUS.NOT_COLLECTED]: 'bg-gray-100 text-gray-800',
    [PAYMENT_STATUS.PARTIALLY_REFUNDED]: 'bg-pink-100 text-pink-800',
    [PAYMENT_STATUS.PROCESSING]: 'bg-blue-100 text-blue-800',
    [PAYMENT_STATUS.REFUNDED]: 'bg-pink-100 text-pink-800',
    [PAYMENT_STATUS.REQUIRES_ACTION]: 'bg-red-50 text-red-800',
    [PAYMENT_STATUS.REQUIRES_CONFIRMATION]: 'bg-red-50 text-red-500',
    [PAYMENT_STATUS.REQUIRES_PAYMENT_METHOD]: 'bg-red-50 text-red-500',
    [PAYMENT_STATUS.PENDING]: 'bg-yellow-100 text-yellow-800',
    [PAYMENT_STATUS.SUCCEEDED]: 'bg-green-100 text-green-800',
};

/**
 * Payment status label component
 *
 * Renders a tag depicting the status of the payment
 */
export default function PaymentStatus({ status: _status }) {
    const [paymentStatuses] = usePaymentStatuses();

    const status = useMemo(() => {
        if (!_status || paymentStatuses.length === 0) return null;

        return find(paymentStatuses, { id: _status });
    }, [_status]);

    if (!status) return null;

    return (
        <span
            className={`text-sm font-medium me-2 px-2.5 py-0.5 rounded ml-auto ${PAYMENT_STATUS_CLASSNAME_MAP[
                status.slug
            ] ?? 'cancelled'}`}
        >
            {status.status}
        </span>
    );
}

PaymentStatus.propTypes = {
    status: PropTypes.string,
};

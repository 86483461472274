/**
 * Invoice Line Items Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Table from 'components/shared/ScrollableTable';
import { INVOICE_LINE_ITEMS_TABLE_COLUMNS } from 'includes/constants/columns';

/**
 * Invoice line items Component
 */

const InvoiceLineItems = props => {
    const { getNumberFormattedWithInvoiceCurrency } = props;
    /**
     * Modify and return the invoice line items data
     */
    const getInvoiceLineItemsData = () =>
        props.invoiceLineItems.map(lineItem => ({
            ...lineItem,
            quantity: `${lineItem.quantity} ${lineItem.unit_of_measure}`,
            unit_price: getNumberFormattedWithInvoiceCurrency(lineItem.unit_price),
            tax_amount: getNumberFormattedWithInvoiceCurrency(lineItem.tax_amount),
            total: getNumberFormattedWithInvoiceCurrency(lineItem.total),
        }));

    /**
     * Memoized inventory data
     */
    const memoizedInvoiceLineItemData = useMemo(() => getInvoiceLineItemsData(), [getInvoiceLineItemsData]);

    return (
        <div className="invoice-line-items-table">
            <Table
                columns={INVOICE_LINE_ITEMS_TABLE_COLUMNS}
                dataSource={memoizedInvoiceLineItemData}
                pagination={false}
            />
        </div>
    );
};

InvoiceLineItems.propTypes = {
    getNumberFormattedWithInvoiceCurrency: PropTypes.func,
    invoiceLineItems: PropTypes.any,
    t: PropTypes.func,
};

export default InvoiceLineItems;

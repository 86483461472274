/**
 * Dashboard Saga
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import * as actionTypes from '../../../../constants/actionTypes';
import BaseSagaHandler from '../../core/base';
import DashboardApi from '../../../../services/admin/dashboard';
import { takeLatest, all, put, call } from 'redux-saga/effects';

class DashboardSaga extends BaseSagaHandler {
    /**
     * The dashboard Watcher Saga
     * Watches the redux actions related to dashboard and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *dashboardWatchers() {
        let context = this;
        yield all([yield takeLatest(actionTypes.GET_ADMIN_DASHBOARD_DATA_REQUEST, [context, 'getDashboardData'])]);
    }

    /**
     * Get the dashboard data
     *
     * @yields {object} Dashboard data response
     */
    *getDashboardData() {
        try {
            const result = yield call(DashboardApi.getPlanData);
            yield put({ type: actionTypes.GET_ADMIN_DASHBOARD_DATA_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_ADMIN_DASHBOARD_DATA_FAILURE, errors });
        }
    }
}
//export dashboard saga
export default new DashboardSaga().forkAllWatcherFunctions();

/**
 * useZendeskSettings
 * Fetch organisation zendesk settings and caches it
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import useCachedData from './useCachedData';
import useOrganisationId from './useOrganisationId';
import useHasRequiredPermission from './useHasRequiredPermission';
import ZendeskApi from 'includes/services/zendesk';
import { CACHE_ZENDESK_SETTINGS_KEY } from '../constants';
import { get } from 'lodash';
import { ZENDESK_SETTINGS } from 'includes/constants/mappings/success';
import { ZENDESK } from 'includes/constants/permissions';
import useAllowZendeskIntegration from 'includes/hooks/useAllowZendeskIntegration';

/**
 * Fetches zendesk settings if cached else call the API and caches the result
 *
 * @param {boolean} disableApiCall should disable API call
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useZendeskSettings = (disableApiCall = false) => {
    const organisationId = useOrganisationId();
    const allowZendeskIntegration = useAllowZendeskIntegration();
    const hasZendeskPermission = useHasRequiredPermission(ZENDESK.SETTINGS.VIEW);
    if (!allowZendeskIntegration || !hasZendeskPermission) {
        disableApiCall = true;
    }
    return useCachedData(
        CACHE_ZENDESK_SETTINGS_KEY,
        () => (organisationId ? ZendeskApi.getSettings(organisationId) : {}),
        {
            selector: res => get(res, ZENDESK_SETTINGS),
            disableApiCall,
            dependsOnOrg: true,
        }
    );
};

export default useZendeskSettings;

export const paymentOverviewGraphOptions = {
    animationEnabled: true,
    colorSet: 'customColorSet1',
    legend: {
        verticalAlign: 'bottom',
        horizontalAlign: 'center',
        fontWeight: 'normal',
        fontFamily: 'Muli Regular',
    },
    toolTip: {
        enabled: true,
        fontFamily: 'Muli Regular',
        content: '<b>{name}:</b> ${y} (#percent%)',
    },
    subtitles: [
        {
            verticalAlign: 'center',
            fontSize: 12,
            dockInsidePlotArea: true,
            // fontColor: '#FFE600  ',
            padding: 50,
            margin: 100,
            maxWidth: 200,
            fontFamily: 'Muli Regular',
        },
    ],
    data: [
        {
            type: 'doughnut',
            radius: 180,
            innerRadius: 100,
            showInLegend: true,
            indexLabel: '{name} - #percent%',
            indexLabelFontFamily: 'Muli Regular',
            dataPoints: [],
        },
    ],
};

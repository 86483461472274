/**
 * Edit payment arrangement installment form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com>
 */

// import required modules
import { React, PropTypes, withTranslation } from 'includes/exports/react';
import * as constants from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import * as requestKeys from 'includes/constants/keys/request';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col, Tag, Icon, Tooltip } from 'antd';
import { find, get } from 'lodash';
import useOrganisationCurrency from 'includes/hooks/useOrganisationCurrency';
import { CURRENCY_CODE, ID } from 'includes/constants/keys/response';
import { useSelector } from 'react-redux';
import useOrganisationCurrencies from 'includes/hooks/useOrganisationCurrencies';
import FormField from 'components/shared/FormField';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import {
    paymentArrangementInstallmentStatusTagColors,
    paymentArrangementInstallmentTypeTagColors,
    paymentArrangementOrInstallmentPaymentStatusTagColors,
} from 'includes/constants/tagColors';
import PaymentArrangementInstallmentInvoices from 'pages/PaymentArrangements/Details/components/Installments/components/Invoices';
/**
 * Edit payment arrangement installment form
 */
const PaymentArrangementInstallmentEditForm = props => {
    const { t } = useTranslation();
    const organisationCurrencyId = useOrganisationCurrency(ID);
    const organisationCurrencyCode = useOrganisationCurrency(CURRENCY_CODE);
    const paymentArrangement = useSelector(state => state.paymentArrangement.paymentArrangement);
    const loadingPaymentArrangementInstallmentEdit = useSelector(
        state => state.paymentArrangement.loadingPaymentArrangementInstallmentEdit
    );
    const paymentArrangementCurrencyId = get(paymentArrangement, requestKeys.CURRENCY_ID);
    const { data: organisationCurrencies } = useOrganisationCurrencies();
    const paymentArrangementCurrencyDetails = find(organisationCurrencies, { id: paymentArrangementCurrencyId }, '');
    const paymentArrangementCurrencyCode = get(paymentArrangementCurrencyDetails, CURRENCY_CODE);

    /**
     * Get value from payment arrangement installment details by key
     *
     * @param {string} key Key for which value is to be retrieved
     * @param {*} defaultValue. Default value
     *
     * @returns {string} Value
     */
    const getValue = (key, defaultValue = '') => get(props.paymentArrangementInstallmentDetails, key, defaultValue);

    /**
     * Locale label
     *
     * @param {string} key Key to grt localized text
     *
     * @returns {string} Localized text
     */
    const localeLabel = key => t(`messages.paymentArrangement.arrangement.installments.edit.form.input.label.${key}`);

    return (
        <div className="my-profile-detail" style={{ marginTop: '20px' }}>
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16} style={{ padding: '10px 0' }}>
                    <Col span={24}>
                        <Tag
                            color={get(paymentArrangementInstallmentTypeTagColors, getValue('payment_type'))}
                            style={{
                                fontWeight: 'bold',
                                marginBottom: '10px',
                            }}
                        >
                            {localeLabel(
                                getValue('payment_type') === constants.PAYMENT_ARRANGEMENT_PAYMENT_TYPE_UPFRONT
                                    ? 'payment_type.upfront'
                                    : 'payment_type.installment'
                            )}
                        </Tag>
                    </Col>
                    <Col span={24}>
                        <Tooltip
                            title={`# ${getValue('installment_number')} of ${paymentArrangement?.total_installments}`}
                        >
                            # {getValue('installment_number')} of {paymentArrangement?.total_installments}
                            {getValue('reschedule_reason') ? (
                                <Icon
                                    type="info-circle"
                                    theme="outlined"
                                    style={{ marginLeft: '5px', verticalAlign: 'middle', color: 'rgb(206 184 143)' }}
                                />
                            ) : (
                                ''
                            )}
                        </Tooltip>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <label htmlFor="name">{localeLabel('installment_amount')}:</label>
                        <div style={{ fontSize: '40px', fontWeight: 'bold' }}>
                            {props.getNumberFormattedWithPaymentArrangementCurrency(getValue('total_amount'))}
                        </div>
                        <div className="ant-form-item-children">
                            {getValue('fee') > 0
                                ? `(Amount: ${props.getNumberFormattedWithPaymentArrangementCurrency(
                                      getValue('amount')
                                  )} + Fee: ${props.getNumberFormattedWithPaymentArrangementCurrency(getValue('fee'))})`
                                : null}
                        </div>
                    </Col>
                    <Col span={12}>
                        <Tag
                            color={get(paymentArrangementInstallmentStatusTagColors, getValue('status'))}
                            style={{
                                width: '60%',
                                fontWeight: 'bold',
                                textAlign: 'right',
                                float: 'right',
                                marginBottom: '10px',
                            }}
                        >
                            {get(
                                find(constants.PAYMENT_ARRANGEMENT_INSTALLMENT_STATUS_OPTIONS, {
                                    id: getValue('status'),
                                }),
                                'label'
                            )}
                        </Tag>
                        {getValue('status') !== 1 ? (
                            <Tag
                                color={get(
                                    paymentArrangementOrInstallmentPaymentStatusTagColors,
                                    getValue('payment_status'),
                                    0
                                )}
                                style={{
                                    width: '60%',
                                    fontWeight: 'bold',
                                    textAlign: 'right',
                                    float: 'right',
                                }}
                            >
                                {get(
                                    find(constants.PAYMENT_ARRANGEMENT_OR_INSTALLMENT_PAYMENT_STATUS_OPTIONS, {
                                        id: getValue('payment_status'),
                                    }),
                                    'label'
                                )}
                            </Tag>
                        ) : null}
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginBottom: '20px' }}>
                    <Col span={24}>
                        <PaymentArrangementInstallmentInvoices
                            getNumberFormattedWithPaymentArrangementCurrency={
                                props.getNumberFormattedWithPaymentArrangementCurrency
                            }
                            invoices={getValue('invoices', [])}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="date"
                            name={requestKeys.PAYMENT_ARRANGEMENT_INSTALLMENT_PAYMENT_RECEIVED_DATE}
                            label={localeLabel(requestKeys.PAYMENT_ARRANGEMENT_INSTALLMENT_PAYMENT_RECEIVED_DATE)}
                            hasFeedback
                            reduxChange={props.change}
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required, formValidations.date]}
                            required
                        />
                    </Col>
                </Row>
                {paymentArrangementCurrencyId !== organisationCurrencyId ? (
                    <Row gutter={16}>
                        <Col>
                            <Field
                                type="text"
                                name={requestKeys.EXCHANGE_RATE}
                                label={localeLabel(requestKeys.EXCHANGE_RATE)}
                                hasFeedback
                                className="form-control"
                                component={FormField}
                                validate={[formValidations.required, formValidations.number]}
                                required
                                addonBefore={`${organisationCurrencyCode} 1  = ${paymentArrangementCurrencyCode} `}
                                info={get(paymentArrangementCurrencyDetails, 'exchange_rates.last_updated_date', '')}
                            />
                        </Col>
                    </Row>
                ) : null}
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="text"
                            name={requestKeys.EXTERNAL_ID}
                            label={localeLabel(requestKeys.EXTERNAL_ID)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="text"
                            name={requestKeys.REFERENCE_NUMBER}
                            label={localeLabel(requestKeys.REFERENCE_NUMBER)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col>
                        <Field
                            type="textarea"
                            name={requestKeys.REFERENCE}
                            label={localeLabel(requestKeys.REFERENCE)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Form.Item className={`update-button no-margin center-align `}>
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={loadingPaymentArrangementInstallmentEdit}
                        submittingButtonLabel={t(
                            `messages.paymentArrangement.arrangement.installments.edit.form.submit.buttonSubmittingLabel.edit`
                        )}
                        className="register-btn"
                    >
                        {props.t(
                            `messages.paymentArrangement.arrangement.installments.edit.form.submit.buttonLabel.edit`
                        )}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

/**
 * Proptypes.
 */
PaymentArrangementInstallmentEditForm.propTypes = {
    change: PropTypes.func,
    exchangeRateText: PropTypes.string,
    getNumberFormattedWithPaymentArrangementCurrency: PropTypes.func,
    handleSubmit: PropTypes.func,
    paymentArrangementInstallmentDetails: PropTypes.object,
    t: PropTypes.func,
};

export default reduxForm({
    form: constants.PAYMENT_ARRANGEMENT_INSTALLMENT_FORM_NAME,
})(withTranslation()(PaymentArrangementInstallmentEditForm));

import PropTypes from 'prop-types';
import React from 'react';

import Loader from 'components/Loader';

const SUBSCRIPTION_TYPE_CLASSNAME_MAP = {
    Yearly: 'bg-indigo-100 text-indigo-800',
    Monthly: 'bg-yellow-100 text-yellow-800',
};

/**
 * Order status
 *
 * Renders a tag depicting the status
 */
export default function SubscriptionType({ type }) {
    return (
        <span
            className={`text-xs font-medium me-2 px-2.5 py-0.5 rounded ${SUBSCRIPTION_TYPE_CLASSNAME_MAP[type] ??
                'cancelled'}`}
        >
            {type ?? <Loader />}
        </span>
    );
}

SubscriptionType.propTypes = {
    type: PropTypes.string,
};

import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { find } from 'lodash';
import { useSelector } from 'react-redux';

import Table from 'components/shared/ScrollableTable';
import Button from 'components/shared/Button';
import { ORDER_TYPE, USER_ROLE_ADMIN, USER_ROLE_ADMIN_MANAGER } from 'includes/constants';
import { useCurrentRoleSlug, useOrderTypes, usePaymentStatuses } from 'includes/utils/hooks';
import Loader from 'components/Loader';
import useCurrencies from 'includes/hooks/useCurrencies';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import PermissionGuard from 'components/PermissionGuard';
import useAccountId from 'includes/hooks/useAccountId';
import { ORDER } from 'includes/constants/permissions';
import { redirect } from 'includes/utils';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { useTranslation } from 'react-i18next';
import { getOrderTableColumns } from 'includes/constants/columns';
import { TABLE_NO_ORDERS_FOUND_MESSAGE } from 'includes/constants/messages/errors';
import PaymentStatus from 'components/PaymentStatus';
import moment from 'moment';

const ORDER_VIEW_PERMISSION = {
    subscription: ORDER.SUBSCRIPTION.VIEW,
    invoice: ORDER.INVOICE.VIEW,
    'legal-letter': ORDER.LEGAL_LETTER.VIEW,
};

/**
 * Orders table component
 * Responsible for fetching and rendering orders listing table
 *
 * @since 2.8.0
 */
export default function OrdersTable({ getDataMethod, isSearching, searchParam, setIsSearching, typeSlug }) {
    const isLoadingOrderTypes = useOrderTypes()[1];
    const [paymentStatuses] = usePaymentStatuses();
    const { data: currencies } = useCurrencies();
    const getNumberFormattedAsCurrency = useCurrencyFormatter();
    const orders = useSelector(state => state.orders.orders);
    const isLoadingOrders = useSelector(state => state.orders.loadingOrders);
    const ordersPagination = useSelector(state => state.orders.ordersPagination);
    const paymentGateways = useSelector(state => state.paymentGateways.paymentGateways);
    const loadingPaymentGateways = useSelector(state => state.paymentGateways.loadingPaymentGateways);
    const accountId = useAccountId(false);
    const currentRoleSlug = useCurrentRoleSlug();
    const organisationId = useOrganisationId();

    const { t } = useTranslation();

    /**
     * Format the number as currency based on the order type
     *
     * @param {number|string} number Number to be formatted
     * @param {object} order Order object
     *
     * @returns {string} Formatted number
     */
    const getNumberFormattedAsCurrencyByOrderType = (number, order) => {
        const currency = find(currencies, { id: order?.currency_id });
        return getNumberFormattedAsCurrency(number, false, false, currency?.code);
    };

    /**
     * Get Order View Link
     *
     * @param {object} order Order object
     *
     * @returns {string} Order View Link
     */
    const getOrderViewLink = order => {
        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return organisationId
                    ? `/admin/accounts/organisation/orders/${accountId}/${organisationId}/${order.type_id}/${order.id}`
                    : `/admin/accounts/orders/${accountId}/${order.type_id}/${order.id}`;
            default:
                return `/orders/order/${order.type_id}/${order.id}`;
        }
    };

    /**
     * Get the locale
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @returns {string} Localized text
     */
    const getLocale = path => t(`sharedMessages.orders.list.${path}`);

    /**
     * Format the orders data to show in the table
     */
    const formatOrdersData = useCallback(
        () =>
            orders.map(order => ({
                ...order,
                account: order.account?.name,
                payment_status: <PaymentStatus status={order.payment_status_id} />,
                amount: getNumberFormattedAsCurrencyByOrderType(order.amount, order),
                service_charge: getNumberFormattedAsCurrencyByOrderType(order.service_charge, order),
                total_amount: getNumberFormattedAsCurrencyByOrderType(order.total_amount, order),
                action: (
                    <div className="action-wrapper">
                        <PermissionGuard requiredPermission={ORDER_VIEW_PERMISSION[typeSlug]}>
                            <Button
                                type="link"
                                className="organisation-action-btn"
                                onClick={() => redirect(getOrderViewLink(order))}
                            >
                                {getLocale('table.action.view')}
                            </Button>
                        </PermissionGuard>
                    </div>
                ),
                created_date: new moment(order.created_date).format('DD/MM/YYYY'),
            })),
        [currencies, orders, paymentGateways, paymentStatuses, typeSlug] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const ordersData = useMemo(() => formatOrdersData(), [formatOrdersData]);

    const loading = isLoadingOrderTypes || loadingPaymentGateways;

    if (loading) return <Loader />;

    return (
        <Table
            columns={getOrderTableColumns(false)}
            className="table-1200"
            dataSource={ordersData}
            getDataMethod={getDataMethod}
            isSearching={isSearching}
            loading={loading || isLoadingOrders}
            localeMessage={TABLE_NO_ORDERS_FOUND_MESSAGE}
            mobileClassName="table-1200"
            paginationData={ordersPagination}
            rowClassName="pointer"
            rowKey="id"
            searchParam={searchParam}
            setIsSearching={setIsSearching}
            size="middle"
            filterEmpty={false}
        />
    );
}

OrdersTable.propTypes = {
    getDataMethod: PropTypes.func,
    isSearching: PropTypes.bool,
    searchParam: PropTypes.string,
    setIsSearching: PropTypes.func,
    typeSlug: PropTypes.oneOf([ORDER_TYPE.SUBSCRIPTION, ORDER_TYPE.INVOICE]),
};

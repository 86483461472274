/**
 * Campaign details Component
 *
 * @since 2.0.0
 */

//import required modules
import React, { useEffect } from 'react';
import { Breadcrumb, Tabs } from 'antd';
import { AUDIT_LOG, CAMPAIGN } from 'includes/constants/permissions';
import { CAMPAIGN_TYPE_BROKEN, CAMPAIGN_TYPE_OPTIONS, CAMPAIGN_TYPE_REMINDER } from 'includes/constants';
import { find, get, isEmpty } from 'lodash';
import { getCampaign } from 'includes/slices/campaigns';
import { redirect } from 'includes/utils';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import Button from 'components/Button';
import CampaignDefaultImage from 'assets/images/campaign.png';
import CampaignsBreadCrumbItems from 'components/BreadCrumbItems/Campaigns';
import Loader from 'components/Loader';
import PermissionGuard from 'components/PermissionGuard';
import useAccountId from 'includes/hooks/useAccountId';
import useContactTypes from 'includes/hooks/useContactTypes';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import CampaignNotificationsList from 'pages/Campaigns/Details/components/Notifications/List/component';
import { getPaymentPlan } from 'includes/slices/paymentArrangement';
import AuditLogList from 'pages/AuditLogs/List';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';

const { TabPane } = Tabs;

const CampaignDetails = () => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const isAdminOrManager = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    useUpdateBusinessName();

    const { campaignId, paymentPlanId } = params;
    const campaign = useSelector(state => state.campaigns.campaign);
    const paymentPlanDetails = useSelector(state => state.paymentArrangement.paymentPlan);
    const loadingCampaign = useSelector(state => state.campaigns.loadingCampaign);
    const loadingPaymentPlan = useSelector(state => state.paymentArrangement.loadingPaymentPlan);
    const { data: contactTypes, isLoading: loadingContactTypes } = useContactTypes();

    /**
     * Get the campaign
     */
    useEffect(() => {
        dispatch(getCampaign(organisationId, campaignId));
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the payment arrangement plan
     */
    useEffect(() => {
        if (
            campaign?.campaign_type === CAMPAIGN_TYPE_BROKEN &&
            campaign?.payment_plan_id &&
            isEmpty(paymentPlanDetails)
        ) {
            dispatch(getPaymentPlan(organisationId, campaign?.payment_plan_id));
        }
    }, [campaign]); //eslint-disable-line react-hooks/exhaustive-deps

    const getAdditionalDayDescriptionText = day => {
        if (
            campaign?.campaign_type === CAMPAIGN_TYPE_BROKEN &&
            paymentPlanDetails?.max_unpaid_installment_cancel_duration_type &&
            paymentPlanDetails?.max_unpaid_installment_cancel_duration
        ) {
            const dayDifference = parseInt(day) - parseInt(paymentPlanDetails?.max_unpaid_installment_cancel_duration);
            if (dayDifference === 0) {
                return ' (On plan cancellation date) ';
            } else if (dayDifference >= 1) {
                return ` (${dayDifference} days after plan cancellation date) `;
            }
        }
    };
    /**
     * Get the chart of accounts edit link
     *
     * @returns {string} Edit link
     */
    const getEditLink = () => {
        if (campaign?.campaign_type === CAMPAIGN_TYPE_REMINDER) {
            return isAdminOrManager
                ? `/admin/accounts/organisation/campaigns/campaign/${accountId}/${organisationId}/${campaignId}/edit`
                : `/campaigns/campaign/${campaignId}/edit`;
        } else {
            return isAdminOrManager
                ? `/admin/accounts/organisation/payment-plans/plan/${accountId}/${organisationId}/${campaign?.payment_plan_id}/campaigns/campaign/${campaignId}/edit`
                : `/payment-plans/plan/${campaign?.payment_plan_id}/campaigns/campaign/${campaignId}/edit`;
        }
    };

    /**
     * Get the localized label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.campaigns.addEdit.form.input.label.${field}`);

    return (
        <section>
            <div className="home-content-wrapper synced-name contact-details">
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <CampaignsBreadCrumbItems
                            showListBreadCrumb
                            showDetailsBreadCrumb
                            paymentPlanId={paymentPlanId}
                        />
                    </Breadcrumb>
                </div>
                <h2 className="page-title">{t(`titleAndMetas.campaign.pageTitle`)}</h2>
                <div className="white-box-wrapper mid-wrapper breadcrumb-spec details-wrapper">
                    {loadingCampaign || loadingContactTypes || loadingPaymentPlan ? (
                        <Loader />
                    ) : (
                        <>
                            <section className="details-list left-align">
                                <div className="details-profile-pic-wrapper">
                                    <img
                                        src={CampaignDefaultImage}
                                        className="org-avatar contact-details-spec logo-placeholder"
                                        alt="campaign-pic"
                                    />
                                </div>
                                <div className="center-align">
                                    <PermissionGuard requiredPermission={CAMPAIGN.EDIT}>
                                        <Button big filled onClick={() => redirect(getEditLink())}>
                                            {t(`messages.campaigns.crud.edit`)}
                                        </Button>
                                    </PermissionGuard>
                                </div>
                            </section>
                            <section className="details-list right-align">
                                <section className="detail-summary">
                                    <ul className="upper-right-detail">
                                        <li>
                                            <header>{getLocaleLabel('name')}</header>
                                            <div>{campaign?.name}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('description')}</header>
                                            <div>{campaign?.description}</div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('campaign_type')}</header>
                                            <div>
                                                {campaign?.campaign_type
                                                    ? get(
                                                          find(CAMPAIGN_TYPE_OPTIONS, {
                                                              id: campaign.campaign_type,
                                                          }),
                                                          'label'
                                                      )
                                                    : ''}
                                            </div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('contact_type_ids')}</header>
                                            <div>
                                                {campaign.contact_type_ids
                                                    ? campaign.contact_type_ids
                                                          .map(contact_type_id =>
                                                              get(
                                                                  find(contactTypes, {
                                                                      id: contact_type_id,
                                                                  }),
                                                                  'type'
                                                              )
                                                          )
                                                          .join(', ')
                                                    : ''}
                                            </div>
                                        </li>
                                        <li>
                                            <header>{getLocaleLabel('send_multiple_reminders')}</header>
                                            <div>
                                                {t(`messages.${campaign?.send_multiple_reminders ? 'yes' : 'no'}`)}
                                            </div>
                                        </li>
                                    </ul>
                                </section>
                            </section>
                        </>
                    )}
                </div>
                <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                    {loadingCampaign || loadingContactTypes || loadingPaymentPlan ? (
                        <Loader />
                    ) : (
                        <Tabs>
                            <TabPane key="notifications" tab="Notifications">
                                {campaignId ? (
                                    <CampaignNotificationsList
                                        campaignId={campaignId}
                                        getAdditionalDayDescriptionText={getAdditionalDayDescriptionText}
                                    />
                                ) : (
                                    <Loader />
                                )}
                            </TabPane>
                            <TabPane key="audit-logs" tab="Audit Logs">
                                <PermissionGuardLayout
                                    requiredPermission={AUDIT_LOG.LIST}
                                    layout="section"
                                    showFallbackUI
                                >
                                    <AuditLogList customDataValues={{ campaign_id: campaignId }} showTitle={false} />
                                </PermissionGuardLayout>
                            </TabPane>
                        </Tabs>
                    )}
                </div>
            </div>
        </section>
    );
};

export default CampaignDetails;

/**
 * useIsAdminOrManager
 * extracts and returns the admin or admin manager status from store
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import useIsUserRole from './useIsUserRole';

/**
 * Get whether the current user is admin or admin manager from the store
 *
 * @returns {boolean} true|false
 */
const useIsAdminOrManager = () => {
    return useIsUserRole('is_admin_or_manager');
};

export default useIsAdminOrManager;

import { find, get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Loader from 'components/Loader';
import { PAYMENT_SERVICE } from 'components/PaymentGateways/constants';
import StripePaymentElement from 'components/StripePaymentElement';
import { PAYMENT_GATEWAY } from 'includes/constants';
import useInternalPaymentGateways from 'includes/hooks/useInternalPaymentGateways';
import { redirect } from 'includes/utils';
import { useDispatch } from 'react-redux';
import { confirmOrder } from 'includes/slices/orders';
import { ORDER_GET_DETAILS } from 'includes/constants/mappings/success';
import GoCardlessAddMandateAndPay from 'components/GoCardlessAddMandateAndPay';
import SelectButton from 'components/SelectButton';

/**
 * Other payment methods
 *
 * Renders payment elements for new payment methods
 */
export default function OtherPaymentMethods({
    amount,
    createOrder,
    handleError,
    handlePaymentThroughSavedMethod,
    isLoadingPayButton,
    onShowSuccessMessage,
    orderTypeId,
    saveCardForLaterUse,
    selectedPaymentGateway,
    setIsLoadingPayButton,
    setSelectedMethod,
    setSelectedPaymentGateway,
}) {
    const { t } = useTranslation();

    const { data: paymentGateways, isLoading } = useInternalPaymentGateways(false);

    const dispatch = useDispatch();

    const handleSubmit = async (e, stripe, elements, additionalParams) => {
        if (!stripe) return;

        setIsLoadingPayButton(true);

        // // Trigger form validation and wallet collection

        const { error: submitError } = await elements.submit();
        if (submitError) {
            setIsLoadingPayButton(false);
            return;
        }
        const { error: paymentMethodCreationError, paymentMethod } = await stripe.createPaymentMethod({
            elements,
        });
        if (paymentMethodCreationError) {
            handleError(paymentMethodCreationError.message, true);
            return;
        }

        const { response, error: createOrderError } = await createOrder(
            additionalParams => handleSubmit(e, stripe, elements, additionalParams),
            paymentMethod.id,
            additionalParams
        );

        if (createOrderError) {
            return;
        }

        const {
            next_action: clientSecret,
            id: orderId,
            error_message: createOrderErrorMessage,
            payment_id: paymentId,
        } = get(response, ORDER_GET_DETAILS, {});

        // if an error occurred during create order, set error and exit
        if (createOrderErrorMessage) {
            handleError(createOrderErrorMessage, true);
            redirect(`/orders/order/${orderTypeId}/${orderId}`);
            return;
        }

        // Confirm the PaymentIntent using the details collected by the Payment Element
        const { error, paymentIntent } = await stripe.confirmPayment({
            clientSecret,
            redirect: 'if_required',
            confirmParams: {
                return_url: `${window.location.origin}/orders/order/${orderTypeId}/${orderId}`,
            },
        });

        // assuming confirmPayment call succeeded, set the `token` as `paymentIntent.id`
        let token = paymentIntent?.id;

        if (error) {
            // This point is only reached if there's an immediate error when
            // confirming the payment. Show the error to your customer and set `token` as `paymentId` from order details received from the create order API call
            handleError(error.message, true);
            token = paymentId;
            redirect(`/orders/order/${orderTypeId}/${orderId}`);
        }
        // Customer is redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer is redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
        dispatch(
            confirmOrder(
                orderTypeId,
                orderId,
                { id: orderId, payment_gateway_id: selectedPaymentGateway, token },
                () => redirect(`/orders/order/${orderTypeId}/${orderId}`),
                Boolean(error),
                onShowSuccessMessage
            )
        );
    };

    const renderPaymentGatewayForm = paymentGatewayId => {
        if (!paymentGatewayId)
            return (
                <div className="flex flex-col-0 justify-center items-center h-full">
                    <div className="flex-grow-0">
                        {t('customerMessages.paymentMethod.info.pleaseSelectPaymentGateway')}
                    </div>
                </div>
            );

        const paymentGateway = find(paymentGateways, { id: paymentGatewayId });

        switch (paymentGateway?.slug) {
            case PAYMENT_GATEWAY.STRIPE:
                return (
                    <StripePaymentElement
                        buttonLabel={'Pay now'}
                        currency="aud"
                        amount={amount}
                        // externalSubmitButton
                        handleSubmit={handleSubmit}
                        isLoading={isLoadingPayButton}
                        mode="payment"
                        // ref={stripeSubmitRef}
                        saveCardForLaterUse={saveCardForLaterUse}
                    />
                );
            case PAYMENT_GATEWAY.GOCARDLESS:
                return (
                    <GoCardlessAddMandateAndPay
                        createOrder={createOrder}
                        handleError={handleError}
                        handlePaymentThroughSavedMethod={handlePaymentThroughSavedMethod}
                        isLoading={isLoadingPayButton}
                        onClose={() => {}}
                        orderTypeId={orderTypeId}
                        setIsLoading={setIsLoadingPayButton}
                        setSelectedMethod={setSelectedMethod}
                    />
                );
            default:
                return null;
        }
    };

    if (isLoading) return <Loader />;

    if (paymentGateways.length === 1) return renderPaymentGatewayForm(paymentGateways[0].id);

    return (
        <div className="grid sm:grid-cols-3 gap-4">
            <div className="rounded-lg w-full overflow-x-scroll sm:overflow-x-visible col-span-2 sm:col-span-1 py-5 px-3">
                <div className="flex flex-row w-full sm:flex-col gap-2">
                    {paymentGateways.map(pg => (
                        <SelectButton
                            key={pg.id}
                            isSelected={selectedPaymentGateway === pg.id}
                            onClick={() => {
                                setSelectedPaymentGateway(pg.id);
                                setSelectedMethod({ id: null, payment_gateway_id: pg.id });
                            }}
                        >
                            <div className="flex flex-row items-center gap-2 ">
                                <img
                                    src={PAYMENT_SERVICE?.[pg.slug]?.logo}
                                    style={{ height: 30, width: 30 }}
                                    alt={pg.label}
                                />
                                <div className="font-sans text-bold">{pg.label}</div>
                            </div>
                        </SelectButton>
                    ))}
                </div>
            </div>
            <div className="col-span-2">{renderPaymentGatewayForm(selectedPaymentGateway)}</div>
        </div>
    );
}

OtherPaymentMethods.propTypes = {
    amount: PropTypes.number,
    createOrder: PropTypes.func,
    handleError: PropTypes.func,
    handlePaymentThroughSavedMethod: PropTypes.func,
    isLoadingPayButton: PropTypes.bool,
    onShowSuccessMessage: PropTypes.func,
    onSubmit: PropTypes.func,
    orderTypeId: PropTypes.string,
    saveCardForLaterUse: PropTypes.node,
    selectedPaymentGateway: PropTypes.string,
    setIsLoadingPayButton: PropTypes.func,
    setSelectedMethod: PropTypes.func,
    setSelectedPaymentGateway: PropTypes.func,
};

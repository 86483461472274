/**
 * Plans component
 * Handles the Plans view and functions related to Plans
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import { React, connect, PropTypes, useCallback, useMemo, withTranslation } from '../../../includes/exports/react';
import * as errorMessages from '../../../includes/constants/messages/errors';
import Button from '../../shared/Button';
import Table from '../../shared/ScrollableTable';
import { getDefaultImageClass, getImageFromData } from '../../../includes/utils';
import {
    deleteSubscriptionPlan,
    getSubscriptionPlans,
} from '../../../includes/redux/actions/customer/subscriptionPlan';
import { Link } from 'react-router-dom';
import { PLANS_TABLE_COLUMNS } from '../../../includes/constants/columns';
import Loader from '../../shared/Loader';
import { Modal } from 'antd';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useCountries from 'includes/hooks/useCountries';
import useCurrencies from 'includes/hooks/useCurrencies';
import { find, get } from 'lodash';
import { DEFAULT_CURRENCY_CODE } from 'includes/constants';

/**
 * Plans component
 */
const Plans = props => {
    const defaultPlanImage = require('../../../assets/images/starter-plan.png');
    const planImage45Key = 'PLAN_IMAGE_45X45';
    const planImagOriginalKey = 'PLAN_IMAGE_ORIGINAL';
    const getNumberFormattedAsCurrency = useCurrencyFormatter();
    const { data: countries, isLoading: loadingCountries } = useCountries();
    const { data: currencies, isLoading: loadingCurrencies } = useCurrencies();

    /**
     * Delete the plan
     *
     * @param   {string}    planId     Plan Id
     */
    const deletePlan = planId => {
        props.deleteSubscriptionPlan(planId);
    };

    /**
     * Format the number as currency based on the plan currency
     *
     * @param {number|string} number Number to be formatted
     * @param {object} plan Plan object
     *
     * @returns {string} Formatted number
     */
    const getNumberFormattedAsCurrencyByPlanCurrency = (number, plan) => {
        const currencyCode = get(find(currencies, { id: plan.currency_id }), 'code', DEFAULT_CURRENCY_CODE);
        return getNumberFormattedAsCurrency(number, false, false, currencyCode);
    };

    /**
     * Modify and return the plans data
     */
    const formatPlansData = useCallback(
        () =>
            props.subscriptionPlans.map(plan => ({
                ...plan,
                country_id: get(find(countries, { id: plan.country_id }), 'name'),
                cost: getNumberFormattedAsCurrencyByPlanCurrency(plan.cost, plan),
                yearly_cost: getNumberFormattedAsCurrencyByPlanCurrency(plan.yearly_cost, plan),
                is_custom: plan.is_custom ? 'Yes' : 'No',
                label: (
                    <>
                        <div className="table-name-align">
                            <img
                                className={
                                    getDefaultImageClass(plan, planImage45Key, planImagOriginalKey, '45x45') +
                                    ' logo logo-45 '
                                }
                                src={getImageFromData(plan, planImage45Key, planImagOriginalKey, defaultPlanImage)}
                                alt={plan.label || plan.type}
                            />
                            <div className="wrapped-content-text">
                                {plan.label || plan.type}
                                <span className="crud-wrapper">
                                    <Link className="crud-link" to={`/admin/plans/plans/edit-plan/${plan.id}`}>
                                        Edit
                                    </Link>
                                    <span
                                        className="crud-link"
                                        onClick={() => deletePlan(plan.id)}
                                        title={props.t('adminMessages.plans.deletePlan')}
                                    >
                                        {props.t('adminMessages.plans.deletePlan')}
                                    </span>
                                    <Link className="crud-link" to={`/admin/plans/plans/accounts/add/${plan.id}`}>
                                        Add Account
                                    </Link>
                                    <Link className="crud-link" to={`/admin/plans/plans/accounts/view/${plan.id}`}>
                                        View Accounts
                                    </Link>
                                </span>
                            </div>
                        </div>
                    </>
                ),
            })),
        [props.subscriptionPlans] // eslint-disable-line react-hooks/exhaustive-deps
    );

    //memoized data
    const plansData = useMemo(() => formatPlansData(), [formatPlansData]);

    return (
        <div className="home-content-wrapper accounts-wrapper admin-accounts">
            <div className="search-filter-export-wrapper full-wrapper box-wrapper white-box-wrapper">
                {loadingCountries || loadingCurrencies ? (
                    <Loader />
                ) : (
                    <>
                        <section className="right-menu-wrapper">
                            <div className="right-align">
                                <Link to="/admin/plans/plans/add">
                                    <Button big filled>
                                        {props.t(`adminMessages.plans.addPlan`)}
                                    </Button>
                                </Link>
                            </div>
                        </section>
                        <Table
                            columns={PLANS_TABLE_COLUMNS}
                            dataSource={!props.isLoading ? plansData : []}
                            dataValues={[]}
                            getDataMethod={props.getSubscriptionPlans}
                            loading={props.isLoading}
                            localeMessage={errorMessages.TABLE_NO_PLANS_FOUND_MESSAGE}
                            paginationData={props.paginationSubscriptionPlans}
                            rowClassName="pointer"
                            rowKey="id"
                            size="middle"
                            {...props}
                        />
                    </>
                )}
                <Modal
                    className="my_profile_modal organisation-reauthorization-modal"
                    closable={false}
                    maskClosable={false}
                    visible={props.isDeletingPlan}
                    footer={null}
                >
                    <Loader tip={props.t('adminMessages.plans.loader.deletingPlan')} />
                </Modal>
            </div>
        </div>
    );
};
/**
 *Prop types
 */
Plans.propTypes = {
    deleteSubscriptionPlan: PropTypes.func,
    getSubscriptionPlans: PropTypes.func,
    isDeletingPlan: PropTypes.bool,
    isLoading: PropTypes.bool,
    paginationSubscriptionPlans: PropTypes.object,
    subscriptionPlans: PropTypes.array,
    t: PropTypes.func,
};
export default connect(
    state => ({
        currentPage: state.subscription.currentPage,
        isDeletingPlan: state.subscription.isDeletingPlan,
        isLoading: state.subscription.isLoading,
        paginationSubscriptionPlans: state.subscription.paginationSubscriptionPlans,
        subscriptionPlans: state.subscription.subscriptionPlans,
    }),
    {
        deleteSubscriptionPlan,
        getSubscriptionPlans,
    }
)(withTranslation()(Plans));

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert, Divider, Form, Modal } from 'antd';
import { reduxForm, reset } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import * as constants from 'includes/constants';
import Button from 'components/Button';
import {
    COMMON_PAYMENT_SERVICE_FORM_FIELDS,
    PAYMENT_SERVICE_COMMON_CHARGES_FIELDS,
    PAYMENT_SERVICE_SPECIFIC_FIELDS,
    PAYMENT_SERVICE,
    getPaymentGatewaySlug,
    renderPaymentServiceFormField,
    PAYMENT_SERVICE_SPECIFIC_CHARGES_FIELDS,
    PAYMENT_SERVICE_SPECIFIC_ADDITIONAL_TRANSACTION_CHARGES,
} from 'components/PaymentGateways/constants';
import CountrySelectFormField from './components/CountrySelectFormField';
import Loader from 'components/Loader';
/**
 * Payment service form
 *
 * Form used to add/edit payment service
 *
 * @since 2.8.0
 */
function PaymentServiceForm({
    connectedViaOauth,
    formFields = {
        COMMON: COMMON_PAYMENT_SERVICE_FORM_FIELDS,
        PAYMENT_SERVICE_SPECIFIC_ADDITIONAL_TRANSACTION_CHARGES: PAYMENT_SERVICE_SPECIFIC_ADDITIONAL_TRANSACTION_CHARGES,
        PAYMENT_SERVICE_SPECIFIC: PAYMENT_SERVICE_SPECIFIC_FIELDS,
        PAYMENT_SERVICE_CHARGES: PAYMENT_SERVICE_COMMON_CHARGES_FIELDS,
        PAYMENT_SERVICE_SPECIFIC_CHARGES: PAYMENT_SERVICE_SPECIFIC_CHARGES_FIELDS,
    },
    handleReauthorize,
    handleSubmit,
    initialValues,
    isEdit,
    loading,
    onCancel,
    okLabel,
    RenderFieldsComponent,
    slug,
}) {
    const { t } = useTranslation();

    let paymentServiceSpecificMapKey = getPaymentGatewaySlug(slug, connectedViaOauth);

    const formNote = PAYMENT_SERVICE[paymentServiceSpecificMapKey]?.formNote;

    const paymentServiceSpecificFields = formFields.PAYMENT_SERVICE_SPECIFIC[paymentServiceSpecificMapKey] ?? [];

    const paymentServiceSpecificChargesFields =
        formFields.PAYMENT_SERVICE_SPECIFIC_CHARGES[paymentServiceSpecificMapKey] ?? formFields.PAYMENT_SERVICE_CHARGES;

    const additionalTransactionChargesSection =
        PAYMENT_SERVICE_SPECIFIC_ADDITIONAL_TRANSACTION_CHARGES[paymentServiceSpecificMapKey];

    /**
     * Renders form fields
     *
     * @returns form fields
     */
    const renderFormFields = () => {
        if (RenderFieldsComponent) return <RenderFieldsComponent isEdit={isEdit} />;

        return (
            <div>
                <Divider orientation="left">
                    <span className="font-medium">General</span>
                </Divider>
                <div className="grid grid-cols-2 gap-3 text-left w-full">
                    {formFields.COMMON.map(renderPaymentServiceFormField)}
                    <CountrySelectFormField disabled={isEdit} />
                </div>
                {paymentServiceSpecificFields.length > 0 ? (
                    <>
                        <Divider orientation="left">
                            <span className="font-medium">Gateway keys</span>
                        </Divider>
                        <div className="grid grid-cols-2 gap-3 text-left w-full">
                            {paymentServiceSpecificFields.map(renderPaymentServiceFormField)}
                        </div>
                    </>
                ) : null}

                <Divider orientation="left">
                    <span className="font-medium">Charges</span>
                </Divider>
                <div className="grid grid-cols-2 gap-3 text-left w-full">
                    {paymentServiceSpecificChargesFields.map(renderPaymentServiceFormField)}
                </div>

                {additionalTransactionChargesSection ? (
                    <>
                        <Divider orientation="left">
                            <span className="font-medium">Additional Charges</span>
                        </Divider>
                        {additionalTransactionChargesSection}
                    </>
                ) : null}
            </div>
        );
    };

    const renderReauthorizeAlert = () => {
        if (!initialValues?.reauthorize) return null;

        return (
            <Alert
                className="text-left"
                message={
                    <div>
                        There seems to be a configuration issue with your payment service. Please{' '}
                        {initialValues.connected_via_oauth ? (
                            <Link to="#" onClick={handleReauthorize}>
                                reauthorize
                            </Link>
                        ) : (
                            'verify your credentials'
                        )}
                        .
                    </div>
                }
                type="error"
            />
        );
    };

    return (
        <>
            <Form layout="vertical" className="edit-form" onSubmit={handleSubmit}>
                {renderReauthorizeAlert()}
                {renderFormFields()}
                {formNote ? <Alert className="text-left" type="warning" message={formNote} /> : null}
                <div className="flex gap-3 justify-end mt-2">
                    <Button onClick={onCancel}>{t('customerMessages.paymentServices.form.action.cancel')}</Button>
                    <Button filled loading={loading} htmlType="submit">
                        {okLabel}
                    </Button>
                </div>
            </Form>
        </>
    );
}

PaymentServiceForm.propTypes = {
    RenderFieldsComponent: PropTypes.func,
    connectedViaOauth: PropTypes.bool,
    formFields: PropTypes.shape({
        COMMON: PropTypes.array,
        PAYMENT_SERVICE_CHARGES: PropTypes.array,
        PAYMENT_SERVICE_SPECIFIC: PropTypes.object,
        PAYMENT_SERVICE_SPECIFIC_ADDITIONAL_TRANSACTION_CHARGES: PropTypes.any,
        PAYMENT_SERVICE_SPECIFIC_CHARGES: PropTypes.any,
    }),
    handleReauthorize: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.shape({
        connected_via_oauth: PropTypes.bool,
        reauthorize: PropTypes.bool,
    }),
    isEdit: PropTypes.bool,
    loading: PropTypes.bool,
    okLabel: PropTypes.string,
    onCancel: PropTypes.func,
    slug: PropTypes.string,
};

const PaymentServiceFromWithRedux = reduxForm({
    form: constants.PAYMENT_SERVICE_FORM,
    enableReinitialize: true,
})(PaymentServiceForm);

/**
 * Payment service form modal
 *
 * Modal containing payment service form modal
 *
 * @since 2.8.0
 */
export default function PaymentServiceFormModal({
    connectedViaOauth,
    formFields,
    handleReauthorize,
    initialValues,
    isEdit,
    loading,
    open,
    onOk,
    okLabel: _okLabel,
    onCancel,
    paymentServiceSlug,
    provider,
    RenderFieldsComponent,
}) {
    const { t } = useTranslation();

    const serviceLogo = provider?.logo ?? PAYMENT_SERVICE[paymentServiceSlug]?.logo;

    const okLabel = _okLabel ?? t('customerMessages.paymentServices.form.action.submit');

    const dispatch = useDispatch();

    useEffect(() => {
        if (!open) dispatch(reset(constants.PAYMENT_SERVICE_FORM));
    }, [open]);

    return (
        <Modal
            visible={open}
            title={
                <div className="flex items-center gap-2">
                    <img src={serviceLogo} height={20} width={20} alt={paymentServiceSlug} />
                    <span className="font-bold">
                        {initialValues?.account_name ?? PAYMENT_SERVICE[paymentServiceSlug]?.name}
                    </span>
                </div>
            }
            footer={null}
            onCancel={onCancel}
            width={900}
        >
            {open ? (
                <PaymentServiceFromWithRedux
                    connectedViaOauth={connectedViaOauth}
                    formFields={formFields}
                    handleReauthorize={handleReauthorize}
                    initialValues={initialValues}
                    isEdit={isEdit}
                    loading={loading}
                    okLabel={okLabel}
                    onSubmit={onOk}
                    onCancel={onCancel}
                    RenderFieldsComponent={RenderFieldsComponent}
                    slug={paymentServiceSlug}
                />
            ) : (
                <Loader />
            )}
        </Modal>
    );
}

PaymentServiceFormModal.propTypes = {
    RenderFieldsComponent: PropTypes.elementType,
    connectedViaOauth: PropTypes.bool,
    formFields: PropTypes.shape({
        COMMON: PropTypes.array,
        PAYMENT_SERVICE_CHARGES: PropTypes.array,
        PAYMENT_SERVICE_SPECIFIC: PropTypes.object,
    }),
    handleReauthorize: PropTypes.func,
    initialValues: PropTypes.shape({
        account_name: PropTypes.string,
    }),
    isEdit: PropTypes.bool,
    loading: PropTypes.bool,
    okLabel: PropTypes.string,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    open: PropTypes.bool,
    paymentServiceSlug: PropTypes.string,
    provider: PropTypes.shape({
        logo: PropTypes.string,
    }),
};

/**
 * useDidUpdate
 * componentDidUpdate implementation using hooks
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import { useEffect, useRef } from 'react';

/**
 * Check if the component has been updated or not
 *
 * @param {Function} fn Callback function
 * @param {Array} deps dependency array
 */
const useDidUpdate = (fn, deps) => {
    const didUpdate = useRef(false);
    useEffect(() => {
        if (didUpdate.current) fn();
        else didUpdate.current = true;
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useDidUpdate;

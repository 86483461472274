/**
 * Invoices add/edit sub-form component for line items
 *
 * this form is used to edit the line items
 * this component also contains the view for items while not being edited
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, getFormSubmitErrors, formValueSelector, getFormValues, stopSubmit } from 'redux-form';
import { Form, Row, Col, Icon, Divider } from 'antd';
import { find, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { ItemName } from '../fields';
import FormField from 'components/shared/FormField';
import Button from 'components/shared/Button';
import * as formValidations from 'includes/utils/form';
import { INVOICE_FORM_NAME, getDiscountTypeOptions, INVOICE_LINE_ITEM_TAX_TYPE_OPTIONS } from 'includes/constants';
import * as requestKeys from 'includes/constants/keys/request';
import useTaxes from 'includes/hooks/useTaxes';
import { getItemTotalSplitUp, getLineItemFormName, getTotalAmount, numberParse } from 'pages/Invoices/utils';
import useToFEDateFormatter from 'includes/hooks/useToFEDateFormatter';
import { useTranslation } from 'react-i18next';

// `import { updateSyncErrors } from 'redux-form/lib/actions';` wouldn't work for some reason
const { updateSyncErrors } = require('redux-form/lib/actions').default;

const valueMin0 = formValidations.minValue(0);
const formSubmitErrors = getFormSubmitErrors(INVOICE_FORM_NAME);

/**
 * Max length validation
 *
 * @returns {Function} Validation function
 */
const maxLength7 = formValidations.maxLength(7);

// Item edit Redux Form component

// form name will be passed from where the form is being called to avoid duplicate form(append unique id to the form name)
const ItemForm = reduxForm()(
    ({
        _id,
        change,
        currencySymbol,
        fields,
        firstTouch,
        handleSubmit,
        index,
        isRecurring,
        lineAmountType,
        rec,
        setCurrentEditingKey,
        touch,
        getNumberFormattedWithCurrency,
    }) => {
        const formName = getLineItemFormName(_id);
        const fieldValueSelector = formValueSelector(formName);
        const dispatch = useDispatch();
        const { t } = useTranslation();
        const getItemFormValues = getFormValues(formName);

        const itemDetails = useSelector(state => getItemFormValues(state));

        const taxId = useSelector(state => fieldValueSelector(state, requestKeys.INVOICE_LINE_ITEM_TAX_ID));
        const taxAmount = useSelector(state => fieldValueSelector(state, requestKeys.INVOICE_LINE_ITEM_TAX_AMOUNT));
        const taxType = itemDetails.tax_type;
        const discount = itemDetails.discount;

        useEffect(() => {
            if (taxType === 1) change(requestKeys.INVOICE_LINE_ITEM_TAX_ID, undefined);
            else change(requestKeys.INVOICE_LINE_ITEM_TAX_AMOUNT, undefined);
        }, [taxType]); // eslint-disable-line react-hooks/exhaustive-deps

        const { data: taxes } = useTaxes();

        // get parent form errors to set it as sync errors of the item form
        const { line_items: lineItemsSubmitErrors } = useSelector(state => formSubmitErrors(state));

        useEffect(() => {
            const itemErrors = get(lineItemsSubmitErrors, index, {});
            // if the form has error touch the fields and set the errors
            if (Object.keys(itemErrors).length > 0) {
                touch(
                    requestKeys.INVOICE_LINE_ITEM_NAME,
                    requestKeys.INVOICE_LINE_ITEM_DISCOUNT,
                    requestKeys.INVOICE_LINE_ITEM_QUANTITY,
                    requestKeys.INVOICE_LINE_ITEM_UNIT_PRICE,
                    requestKeys.INVOICE_LINE_ITEM_UNIT_OF_MEASURE,
                    requestKeys.INVOICE_LINE_ITEM_TAX_ID,
                    requestKeys.INVOICE_LINE_ITEM_END_DATE,
                    requestKeys.INVOICE_LINE_ITEM_START_DATE
                );
                dispatch(stopSubmit(formName, itemErrors));
            }
        }, [lineItemsSubmitErrors]); // eslint-disable-line react-hooks/exhaustive-deps

        const { subTotal, discount: discountAmount, tax } = getItemTotalSplitUp(itemDetails, lineAmountType, taxes);

        const itemTotalSplitUp = [
            {
                label: 'Sub Total',
                shouldRender: true,
                value: subTotal,
            },
            {
                label: 'Discount',
                shouldRender: discountAmount,
                value: discountAmount,
            },
            {
                label: 'Tax',
                shouldRender: Boolean(taxId) || taxAmount,
                value: tax,
            },
        ];

        const changeField = change;

        const { data: options } = useTaxes();

        /**
         * Generate the info locale
         *
         * @param {string} field  Field for which locale is to be generated
         *
         * @returns {string} Generated Locale info
         */
        const localeInfo = field => t(`messages.invoices.addEdit.form.input.info.${field}`);

        return (
            <Form
                layout="vertical"
                className="edit-form"
                onSubmit={e => {
                    e.stopPropagation();
                    handleSubmit(e);
                }}
            >
                <div className="item-details">
                    <div className="item-action-delete">
                        {fields.length > 1 ? (
                            <div
                                className="red-icon-text delete-line-item"
                                onClick={e => {
                                    e.stopPropagation();
                                    setCurrentEditingKey(null);
                                    fields.remove(index);
                                }}
                            >
                                <Icon type="delete" />
                                <span>Delete</span>
                            </div>
                        ) : null}
                    </div>
                    <div className="item-container">
                        <Field
                            className="form-control"
                            name={requestKeys.INVOICE_LINE_ITEM_NAME}
                            label="Item"
                            hasFeedback
                            component={ItemName}
                            changeField={changeField}
                            cacheId={_id}
                            rec={rec}
                            validate={[formValidations.required]}
                            required
                        />
                        <Field
                            className="form-control"
                            name={requestKeys.INVOICE_LINE_ITEM_DESCRIPTION}
                            label="Description"
                            type="text"
                            component={FormField}
                        />

                        <Row gutter={8}>
                            <Col span={12}>
                                <Field
                                    className="form-control"
                                    name={requestKeys.INVOICE_LINE_ITEM_QUANTITY}
                                    label="Quantity"
                                    type="number"
                                    min={0}
                                    step="any"
                                    parse={numberParse}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.number, valueMin0]}
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    className="form-control"
                                    name={requestKeys.INVOICE_LINE_ITEM_UNIT_PRICE}
                                    label="Price"
                                    type="number"
                                    step="any"
                                    parse={numberParse}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.number]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={24}>
                                <Field
                                    className="form-control"
                                    name={requestKeys.INVOICE_LINE_ITEM_UNIT_OF_MEASURE}
                                    label="Unit"
                                    type="text"
                                    component={FormField}
                                    maxLength={7}
                                    validate={[maxLength7]}
                                    info={localeInfo(requestKeys.INVOICE_LINE_ITEM_UNIT_OF_MEASURE)}
                                    infoAsToolTip
                                />
                            </Col>
                        </Row>
                        {lineAmountType === 3 ? null : (
                            <Row gutter={8}>
                                <Col span={9}>
                                    <Field
                                        className="form-control"
                                        label="Tax Type"
                                        type="select"
                                        name={requestKeys.INVOICE_LINE_ITEM_TAX_TYPE}
                                        component={FormField}
                                        options={INVOICE_LINE_ITEM_TAX_TYPE_OPTIONS}
                                        disableEmptyOption
                                    />
                                </Col>
                                <Col span={15}>
                                    {taxType === 1 ? (
                                        <Field
                                            className="form-control"
                                            name={requestKeys.INVOICE_LINE_ITEM_TAX_AMOUNT}
                                            label="Tax"
                                            type="number"
                                            step="any"
                                            parse={numberParse}
                                            component={FormField}
                                            rec={rec}
                                            validate={formValidations.number}
                                        />
                                    ) : (
                                        <Field
                                            className="form-control"
                                            name={requestKeys.INVOICE_LINE_ITEM_TAX_ID}
                                            label="Tax"
                                            type="select"
                                            options={options.map(x => ({
                                                value: x.id,
                                                name: `${x.name} (${x.rate}%)`,
                                            }))}
                                            component={FormField}
                                            rec={rec}
                                        />
                                    )}
                                </Col>
                            </Row>
                        )}
                        <Field
                            className="form-control with-pre-tab"
                            name={requestKeys.INVOICE_LINE_ITEM_DISCOUNT}
                            label="Discount"
                            min={0}
                            type="number"
                            step="any"
                            parse={numberParse}
                            addonBefore={
                                <Field
                                    name={requestKeys.INVOICE_LINE_ITEM_DISCOUNT_TYPE}
                                    type="select"
                                    disableEmptyOption
                                    component={FormField}
                                    options={getDiscountTypeOptions(currencySymbol).map(o => ({
                                        name: o.label,
                                        value: o.id,
                                    }))}
                                />
                            }
                            component={FormField}
                        />
                        {discount > 0 ? (
                            <Field
                                type="text"
                                name={requestKeys.INVOICE_LINE_ITEM_DISCOUNT_REASON}
                                label="Discount Reason"
                                component={FormField}
                            />
                        ) : null}
                        {isRecurring ? (
                            <Row gutter={8}>
                                <Col span={12}>
                                    <Field
                                        className="form-control line-item-date"
                                        name={requestKeys.INVOICE_LINE_ITEM_START_DATE}
                                        label="Start Date"
                                        type="date"
                                        reduxChange={changeField}
                                        component={FormField}
                                        validate={[formValidations.date]}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Field
                                        className="form-control line-item-date"
                                        label="End Date"
                                        type="date"
                                        reduxChange={changeField}
                                        name={requestKeys.INVOICE_LINE_ITEM_END_DATE}
                                        component={FormField}
                                        validate={[formValidations.date]}
                                    />
                                </Col>
                            </Row>
                        ) : null}
                    </div>
                    <Row gutter={8}>
                        <div className="item-total-update-wrapper">
                            <div className="item-total-split-up-wrapper">
                                {itemTotalSplitUp
                                    .filter(x => x.shouldRender)
                                    .map(s => (
                                        <Col key={s.label} span={24}>
                                            <div className="total-card-row">
                                                <div>{s.label}:</div>{' '}
                                                <div>{getNumberFormattedWithCurrency(s.value)}</div>
                                            </div>
                                        </Col>
                                    ))}
                                <Divider className="invoice-divider-line" />
                                <Col span={24}>
                                    <div className="total-card-row">
                                        <div>Total:</div>
                                        <div>
                                            {getNumberFormattedWithCurrency(
                                                getTotalAmount(subTotal, discountAmount, tax, lineAmountType)
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Divider className="invoice-divider-line hide-divider-line" />
                            </div>
                            <div className="item-action">
                                <div className="item-edit-button-block">
                                    <Button
                                        className="cancel-button"
                                        onClick={e => {
                                            e.stopPropagation();
                                            setCurrentEditingKey(null);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button className="item-form-submit-btn" htmlType="submit">
                                        {firstTouch ? 'Add' : 'Update'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </Form>
        );
    }
);
ItemForm.propTypes = {
    _id: PropTypes.any,
    change: PropTypes.any,
    currencySymbol: PropTypes.any,
    fields: PropTypes.shape({
        length: PropTypes.number,
        remove: PropTypes.func,
    }),
    firstTouch: PropTypes.any,
    handleSubmit: PropTypes.func,
    index: PropTypes.any,
    isRecurring: PropTypes.any,
    itemSubTotal: PropTypes.any,
    lineAmountType: PropTypes.number,
    rec: PropTypes.any,
    setCurrentEditingKey: PropTypes.func,
    touch: PropTypes.func,
};

// Item edit component
const Item = ({
    rec,
    editing,
    changeField,
    details,
    isRecurring,
    itemSubTotal,
    currencySymbol,
    lineAmountType,
    fields,
    setCurrentEditingKey,
    getNumberFormattedWithCurrency,
    index,
}) => {
    const { data: options } = useTaxes();
    const taxRate = find(options, { id: details.tax_id });
    const dispatch = useDispatch();
    const formName = getLineItemFormName(details._id);
    const convertDateToFEFormat = useToFEDateFormatter(true, null, true);
    const handleSubmit = values => {
        /**
         * NOTE: This is a hack since the actual validation of redux form is not being triggered without a value in it.
         * We need to fix it!
         */
        const validationRules = {
            [requestKeys.INVOICE_LINE_ITEM_NAME]: [formValidations.required],
            // [requestKeys.INVOICE_LINE_ITEM_QUANTITY]: [formValidations.required, formValidations.number, valueMin0],
            // [requestKeys.INVOICE_LINE_ITEM_UNIT_PRICE]: [formValidations.required, formValidations.number, valueMin0],
        };
        const errors = {};
        Object.entries(validationRules).forEach(([field, rules]) => {
            for (const rule of rules) {
                const res = rule(values[field], undefined, { form: formName }, field);
                if (res) {
                    errors[field] = res;
                    break;
                }
            }
        });

        if (Object.keys(errors).length > 0) {
            dispatch(updateSyncErrors(formName, errors));
        } else {
            changeField(`line_items.${index}`, { ...values, first_touch: false });
            setCurrentEditingKey(null);
        }
    };

    const getValidPeriodText = () => {
        const { start_date, end_date } = details;
        if (start_date && end_date) {
            return `${convertDateToFEFormat(start_date)} to
            ${convertDateToFEFormat(end_date)}`;
        } else if (start_date) return `Starts from ${convertDateToFEFormat(start_date)}`;
        else if (end_date) return `Ends on ${convertDateToFEFormat(end_date)}`;
        else return '';
    };

    const validPeriod = getValidPeriodText();

    return (
        <>
            {editing ? (
                <ItemForm
                    form={formName}
                    rec={rec}
                    _id={details._id}
                    firstTouch={details.first_touch}
                    isRecurring={isRecurring}
                    itemSubTotal={itemSubTotal}
                    currencySymbol={currencySymbol}
                    lineAmountType={lineAmountType}
                    fields={fields}
                    setCurrentEditingKey={setCurrentEditingKey}
                    index={index}
                    onSubmit={handleSubmit}
                    initialValues={{
                        ...details,
                        discount_type: get(details, 'discount_type', 1),
                        tax_type: get(details, 'tax_type', 2),
                    }}
                    getNumberFormattedWithCurrency={getNumberFormattedWithCurrency}
                />
            ) : (
                <div className="item-details">
                    <div className="item-action-delete">
                        {fields.length > 1 ? (
                            <div
                                className="red-icon-text delete-line-item"
                                onClick={e => {
                                    e.stopPropagation();
                                    setCurrentEditingKey(null);
                                    fields.remove(index);
                                }}
                            >
                                <Icon type="delete" />
                                <span>Delete</span>
                            </div>
                        ) : null}
                    </div>
                    <div className="item-list-container">
                        <div className="item-content-holder">
                            <div className="item-name">
                                {details.name}
                                <span className="item-validity-period">{validPeriod ? `(${validPeriod})` : ''}</span>
                            </div>
                            <div className="item-description">{details.description}</div>
                            {lineAmountType === 3 || !details.tax_id ? null : (
                                <div className="item-tax">{`${get(taxRate, 'name', details.tax_id)} (${get(
                                    taxRate,
                                    'rate',
                                    0
                                )}%)`}</div>
                            )}
                            <div className="item-unit-and-price">{`${details.quantity || 0} ${
                                details.unit_of_measure ? `(${details.unit_of_measure})` : ''
                            } x ${getNumberFormattedWithCurrency(details.unit_price)}`}</div>
                        </div>
                        <div className="item-action">
                            <div>&nbsp;</div>
                            <div className="item-edit-button-block">{itemSubTotal}</div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

Item.propTypes = {
    changeField: PropTypes.func,
    currencySymbol: PropTypes.any,
    details: PropTypes.shape({
        _id: PropTypes.any,
        description: PropTypes.any,
        discount: PropTypes.number,
        end_date: PropTypes.any,
        first_touch: PropTypes.any,
        name: PropTypes.any,
        quantity: PropTypes.number,
        start_date: PropTypes.any,
        tax_id: PropTypes.any,
        unit_of_measure: PropTypes.any,
        unit_price: PropTypes.any,
    }),
    editing: PropTypes.any,
    fields: PropTypes.shape({
        length: PropTypes.number,
        remove: PropTypes.func,
    }),
    getNumberFormattedWithCurrency: PropTypes.func,
    index: PropTypes.number,
    isRecurring: PropTypes.any,
    itemSubTotal: PropTypes.any,
    lineAmountType: PropTypes.number,
    rec: PropTypes.any,
    setCurrentEditingKey: PropTypes.func,
};

export default Item;

/**
 * Account users hook
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { CACHE_ACCOUNT_USERS_KEY } from 'includes/constants';
import { get } from 'lodash';
import { GET_USERS } from 'includes/constants/mappings/success';
import UserApi from 'includes/services/shared/user';
import useCachedData from './useCachedData';
import useAccountId from 'includes/hooks/useAccountId';

/**
 * Fetches account users
 * If cached get the data from cache else call the API and caches the result
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useAccountUsers = () => {
    const accountId = useAccountId(false);
    return useCachedData(
        CACHE_ACCOUNT_USERS_KEY,
        () => UserApi.getUsers({ account_id: accountId, searchParams: { page_size: 0, is_active: true } }),
        {
            selector: res => get(res, GET_USERS),
            dependsOnOrg: true,
        }
    );
};

export default useAccountUsers;

/**
 * Payment arrangement Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import PaymentArrangementApi from '../services/paymentArrangement';
import createAppSlice from './base';
import {
    PAYMENT_ARRANGEMENT,
    PAYMENT_ARRANGEMENT_INSTALLMENT,
    PAYMENT_ARRANGEMENT_INSTALLMENTS,
    PAYMENT_ARRANGEMENT_LATEST_PENDING_INSTALLMENT_ID,
    PAYMENT_ARRANGEMENT_NOTES,
    PAYMENT_ARRANGEMENTS,
    PAYMENT_PLAN,
    PAYMENT_PLANS,
} from '../constants/mappings/success';
import { get, isFunction } from 'lodash';
import { getPaginationData } from '../utils';
import { showApiErrors, showApiPermissionError, showApiSuccess } from '../utils/api';
import { ID } from '../constants/keys/response';

// set the initial state
const initialState = {
    paymentArrangement: {},
    paymentArrangementInstallment: {},
    paymentArrangementInstallments: [],
    paymentArrangementInstallmentsPagination: {},
    paymentArrangementLatestPendingInstallmentId: '',
    paymentArrangementNotes: [],
    paymentArrangementNotesPagination: {},
    paymentArrangements: [],
    paymentArrangementsPagination: {},
    paymentPlan: {},
    paymentPlans: [],
    paymentPlansPagination: {},
    loadingPaymentArrangement: false,
    loadingPaymentArrangementAddEdit: false,
    loadingPaymentArrangementInstallment: false,
    loadingPaymentArrangementInstallmentCancel: false,
    loadingPaymentArrangementInstallmentEdit: false,
    loadingPaymentArrangementInstallments: false,
    loadingPaymentArrangementInstallmentsRegeneration: false,
    loadingPaymentArrangementNoteAddEdit: false,
    loadingPaymentArrangementNoteDelete: false,
    loadingPaymentArrangementNotes: false,
    loadingPaymentArrangementPayInFull: false,
    loadingPaymentArrangements: false,
    loadingPaymentPlan: false,
    loadingPaymentPlanAddEdit: false,
    loadingPaymentPlanDelete: false,
    loadingPaymentPlans: false,
};

// define the slice
const PaymentArrangementSlice = createAppSlice('paymentArrangementSlice', initialState);
const { setData } = PaymentArrangementSlice.actions;

/**
 * Add a payment plan
 *
 * @param {string} organisationId Organisation Id.
 * @param {object} payload Payload
 * @param {Function} callback Callback
 */
export const addPaymentPlan = (organisationId, payload, callback) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentPlanAddEdit', true));
        const result = await PaymentArrangementApi.addPaymentPlan(organisationId, payload);
        const paymentPlanId = get(result, [...PAYMENT_PLAN, ID]);
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback(paymentPlanId);
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'PAYMENT_PLAN_FORM', 'PAYMENT_PLAN'));
    } finally {
        dispatch(setData('loadingPaymentPlanAddEdit'));
    }
};

/**
 * Add payment arrangement note
 *
 * @param {string} organisationId Organisation Id.
 * @param {string} paymentArrangementId Payment arrangement id
 * @param {object} payload Payload
 * @param {Function} callback Callback
 */
export const addPaymentArrangementNote = (
    organisationId,
    paymentArrangementId,
    payload,
    callback
) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangementNoteAddEdit', true));
        const result = await PaymentArrangementApi.addPaymentArrangementNote(
            organisationId,
            paymentArrangementId,
            payload
        );
        dispatch(setData('paymentArrangementNotes', get(result, PAYMENT_ARRANGEMENT_NOTES)));
        dispatch(setData('paymentArrangementNotesPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'PAYMENT_ARRANGEMENT_NOTE', '', ['note', 'parent_note_id']));
    } finally {
        dispatch(setData('loadingPaymentArrangementNoteAddEdit'));
    }
};

/**
 * Cancel the payment arrangement installment
 *
 * @param {string} organisationId Organisation Id
 * @param {string} paymentArrangementId Payment arrangement id
 * @param {string} paymentArrangementInstallmentId Payment arrangement installment id
 */
export const cancelPaymentArrangementInstallment = (
    organisationId,
    paymentArrangementId,
    paymentArrangementInstallmentId
) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangementInstallmentCancel', true));
        const result = await PaymentArrangementApi.cancelPaymentArrangementInstallment(
            organisationId,
            paymentArrangementId,
            paymentArrangementInstallmentId
        );
        dispatch(setData('paymentArrangement', get(result, PAYMENT_ARRANGEMENT)));
        dispatch(
            setData(
                'paymentArrangementLatestPendingInstallmentId',
                get(result, PAYMENT_ARRANGEMENT_LATEST_PENDING_INSTALLMENT_ID)
            )
        );
        dispatch(setData('paymentArrangementInstallments', get(result, PAYMENT_ARRANGEMENT_INSTALLMENTS)));
        dispatch(setData('paymentArrangementInstallmentsPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'PAYMENT_ARRANGEMENT_INSTALLMENT'));
    } finally {
        dispatch(setData('loadingPaymentArrangementInstallmentCancel'));
    }
};

/**
 * Delete payment arrangement note
 *
 * @param {string} organisationId Organisation Id.
 * @param {string} paymentArrangementId Payment arrangement id
 * @param {string} paymentArrangementNoteId Payment arrangement note id
 * @param {Function} callback Callback
 */
export const deletePaymentArrangementNote = (
    organisationId,
    paymentArrangementId,
    paymentArrangementNoteId,
    callback
) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangementNoteDelete', true));
        const result = await PaymentArrangementApi.deletePaymentArrangementNote(
            organisationId,
            paymentArrangementId,
            paymentArrangementNoteId
        );
        dispatch(setData('paymentArrangementNotes', get(result, PAYMENT_ARRANGEMENT_NOTES)));
        dispatch(setData('paymentArrangementNotesPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'PAYMENT_ARRANGEMENT_NOTE'));
    } finally {
        dispatch(setData('loadingPaymentArrangementNoteDelete'));
    }
};

/**
 * Delete payment plan
 *
 * @param {string} organisationId Organisation Id.
 * @param {string} paymentPlanId Payment plan id
 * @param {Function} callback Callback. Default () => {}
 */
export const deletePaymentPlan = (organisationId, paymentPlanId, callback = () => {}) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentPlanDelete', true));
        const result = await PaymentArrangementApi.deletePaymentPlan(organisationId, paymentPlanId);
        dispatch(setData('paymentPlans', get(result, PAYMENT_PLANS)));
        dispatch(setData('paymentPlansPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'PAYMENT_PLAN'));
    } finally {
        dispatch(setData('loadingPaymentPlanDelete', false));
    }
};

/**
 * Get the payment arrangement
 *
 * @param {string} organisationId Organisation Id
 * @param {string} paymentArrangementId Payment arrangement id
 * @param {Function} callback Callback function. Default ()=>{}
 */
export const getPaymentArrangement = (organisationId, paymentArrangementId, callback = () => {}) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangement', true));
        const result = await PaymentArrangementApi.getPaymentArrangement(organisationId, paymentArrangementId);
        dispatch(setData('paymentArrangement', get(result, PAYMENT_ARRANGEMENT)));
        if (callback && isFunction(callback)) {
            callback(get(result, PAYMENT_ARRANGEMENT));
        }
    } finally {
        dispatch(setData('loadingPaymentArrangement'));
    }
};

/**
 * Get the payment arrangement installment details
 *
 * @param {string} organisationId Organisation Id
 * @param {string} paymentArrangementId Payment arrangement id
 * @param {string} paymentArrangementInstallmentId Payment arrangement installment id
 * @param {Function} callback Callback function
 */
export const getPaymentArrangementInstallment = (
    organisationId,
    paymentArrangementId,
    paymentArrangementInstallmentId
) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangementInstallment', true));
        const result = await PaymentArrangementApi.getPaymentArrangementInstallment(
            organisationId,
            paymentArrangementId,
            paymentArrangementInstallmentId
        );
        dispatch(setData('paymentArrangementInstallment', get(result, PAYMENT_ARRANGEMENT_INSTALLMENT)));
    } finally {
        dispatch(setData('loadingPaymentArrangementInstallment'));
    }
};

/**
 * Get the payment arrangement installments
 *
 * @param {string} organisationId Organisation Id
 * @param {string} paymentArrangementId Payment arrangement id
 */
export const getPaymentArrangementInstallments = (
    organisationId,
    paymentArrangementId,
    searchParams = {}
) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangementInstallments', true));
        const result = await PaymentArrangementApi.getPaymentArrangementInstallments(
            organisationId,
            paymentArrangementId,
            searchParams
        );
        dispatch(
            setData(
                'paymentArrangementLatestPendingInstallmentId',
                get(result, PAYMENT_ARRANGEMENT_LATEST_PENDING_INSTALLMENT_ID)
            )
        );
        dispatch(setData('paymentArrangementInstallments', get(result, PAYMENT_ARRANGEMENT_INSTALLMENTS)));
        dispatch(setData('paymentArrangementInstallmentsPagination', getPaginationData({ result })));
    } finally {
        dispatch(setData('loadingPaymentArrangementInstallments'));
    }
};

/**
 * Get the payment arrangement notes
 *
 * @param {string} organisationId Organisation Id
 * @param {string} paymentArrangementId Payment arrangement id
 */
export const getPaymentArrangementNotes = (
    organisationId,
    paymentArrangementId,
    searchParams = {}
) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangementNotes', true));
        const result = await PaymentArrangementApi.getPaymentArrangementNotes(
            organisationId,
            paymentArrangementId,
            searchParams
        );
        dispatch(setData('paymentArrangementNotes', get(result, PAYMENT_ARRANGEMENT_NOTES)));
        dispatch(setData('paymentArrangementNotesPagination', getPaginationData({ result })));
    } finally {
        dispatch(setData('loadingPaymentArrangementNotes'));
    }
};

/**
 * Get the payment arrangements
 *
 * @param {string} organisationId Organisation Id
 * @param {object} searchParams Search Params. Default {}
 * @param {Function} callback Callback function. Default ()=>{}
 */
export const getPaymentArrangements = (organisationId, searchParams = {}, callback = () => {}) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangements', true));
        const result = await PaymentArrangementApi.getPaymentArrangements(organisationId, searchParams);
        dispatch(setData('paymentArrangements', get(result, PAYMENT_ARRANGEMENTS)));
        dispatch(setData('paymentArrangementsPagination', getPaginationData({ result })));
        if (callback && isFunction(callback)) {
            callback(get(result, PAYMENT_ARRANGEMENTS));
        }
    } finally {
        dispatch(setData('loadingPaymentArrangements'));
    }
};

/**
 * Get the payment plan
 *
 * @param {string} organisationId Organisation Id.
 * @param {string} paymentArrangementPlanId Payment arrangement plan id
 */
export const getPaymentPlan = (organisationId, paymentArrangementPlanId) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentPlan', true));
        const result = await PaymentArrangementApi.getPaymentPlan(organisationId, paymentArrangementPlanId);
        dispatch(setData('paymentPlan', get(result, PAYMENT_PLAN)));
    } catch (errors) {
        dispatch(showApiErrors(errors, 'PAYMENT_PLAN_FORM', 'PAYMENT_PLAN'));
    } finally {
        dispatch(setData('loadingPaymentPlan'));
    }
};

/**
 * Get the payment plans
 *
 * @param {string} organisationId Organisation Id
 * @param {object} searchParams Search Params. Default {}
 * @param {Function} callback Callback function. Default ()=>{}
 */
export const getPaymentPlans = (organisationId, searchParams = {}, callback = () => {}) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentPlans', true));
        const result = await PaymentArrangementApi.getPaymentPlans(organisationId, searchParams);
        dispatch(setData('paymentPlans', get(result, PAYMENT_PLANS)));
        dispatch(setData('paymentPlansPagination', getPaginationData({ result })));
        if (callback && isFunction(callback)) {
            callback(get(result, PAYMENT_PLANS));
        }
    } catch (errors) {
        dispatch(showApiPermissionError(errors, 'PAYMENT_PLANS'));
    } finally {
        dispatch(setData('loadingPaymentPlans'));
    }
};

/**
 * Pay the payment arrangement in full
 *
 * @param {string} organisationId Organisation Id
 * @param {string} paymentArrangementId Payment arrangement id
 * @param {object} payload Payload
 * @param {Function} callback Callback function
 */
export const payPaymentArrangementInFull = (
    organisationId,
    paymentArrangementId,
    payload,
    callback = () => {}
) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangementPayInFull', true));
        const result = await PaymentArrangementApi.payPaymentArrangementInFull(
            organisationId,
            paymentArrangementId,
            payload
        );
        dispatch(setData('paymentArrangement', get(result, PAYMENT_ARRANGEMENT)));
        dispatch(
            setData(
                'paymentArrangementLatestPendingInstallmentId',
                get(result, PAYMENT_ARRANGEMENT_LATEST_PENDING_INSTALLMENT_ID)
            )
        );
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'PAYMENT_ARRANGEMENT_PAY_IN_FULL_FORM_NAME', 'PAYMENT_ARRANGEMENT'));
    } finally {
        dispatch(setData('loadingPaymentArrangementPayInFull'));
    }
};

/**
 * Regenerate payment arrangement installments
 *
 * @param {string} organisationId Organisation Id
 * @param {string} paymentArrangementId Payment arrangement id
 */
export const regeneratePaymentArrangementInstallments = (organisationId, paymentArrangementId) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangementInstallmentsRegeneration', true));
        const result = await PaymentArrangementApi.regeneratePaymentArrangementInstallments(
            organisationId,
            paymentArrangementId
        );
        dispatch(setData('paymentArrangement', get(result, PAYMENT_ARRANGEMENT)));
        dispatch(
            setData(
                'paymentArrangementLatestPendingInstallmentId',
                get(result, PAYMENT_ARRANGEMENT_LATEST_PENDING_INSTALLMENT_ID)
            )
        );
        dispatch(setData('paymentArrangementInstallments', get(result, PAYMENT_ARRANGEMENT_INSTALLMENTS)));
        dispatch(setData('paymentArrangementInstallmentsPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'PAYMENT_ARRANGEMENT_INSTALLMENTS'));
    } finally {
        dispatch(setData('loadingPaymentArrangementInstallmentsRegeneration'));
    }
};

/**
 * Update the payment arrangement
 *
 * @param {string} organisationId Organisation Id.
 * @param {string} paymentArrangementId Payment arrangement id
 * @param {object} payload Payload
 * @param {Function} callback Callback function
 */
export const updatePaymentArrangement = (
    organisationId,
    paymentArrangementId,
    payload,
    callback = () => {}
) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangementAddEdit', true));
        const result = await PaymentArrangementApi.updatePaymentArrangement(
            organisationId,
            paymentArrangementId,
            payload
        );
        dispatch(setData('paymentArrangement', get(result, PAYMENT_ARRANGEMENT)));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'PAYMENT_ARRANGEMENT_FORM', 'PAYMENT_ARRANGEMENT'));
    } finally {
        dispatch(setData('loadingPaymentArrangementAddEdit'));
    }
};

/**
 * Update the payment arrangement installment
 *
 * @param {string} organisationId Organisation Id
 * @param {string} paymentArrangementId Payment arrangement id
 * @param {string} paymentArrangementInstallmentId Payment arrangement installment id
 * @param {object} payload Payload
 * @param {Function} callback Callback function
 */
export const updatePaymentArrangementInstallment = (
    organisationId,
    paymentArrangementId,
    paymentArrangementInstallmentId,
    payload,
    callback = () => {}
) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangementInstallmentEdit', true));
        const result = await PaymentArrangementApi.updatePaymentArrangementInstallment(
            organisationId,
            paymentArrangementId,
            paymentArrangementInstallmentId,
            payload
        );
        dispatch(setData('paymentArrangement', get(result, PAYMENT_ARRANGEMENT)));
        dispatch(
            setData(
                'paymentArrangementLatestPendingInstallmentId',
                get(result, PAYMENT_ARRANGEMENT_LATEST_PENDING_INSTALLMENT_ID)
            )
        );
        dispatch(setData('paymentArrangementInstallments', get(result, PAYMENT_ARRANGEMENT_INSTALLMENTS)));
        dispatch(setData('paymentArrangementInstallmentsPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'PAYMENT_ARRANGEMENT_INSTALLMENT_FORM_NAME', 'PAYMENT_ARRANGEMENT_INSTALLMENT'));
    } finally {
        dispatch(setData('loadingPaymentArrangementInstallmentEdit'));
    }
};

/**
 * Update payment arrangement note
 *
 * @param {string} organisationId Organisation Id.
 * @param {string} paymentArrangementId Payment arrangement id
 * @param {string} paymentArrangementNoteId Payment arrangement note id
 * @param {object} payload Payload
 * @param {Function} callback Callback
 */
export const updatePaymentArrangementNote = (
    organisationId,
    paymentArrangementId,
    paymentArrangementNoteId,
    payload,
    callback
) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentArrangementNoteAddEdit', true));
        const result = await PaymentArrangementApi.updatePaymentArrangementNote(
            organisationId,
            paymentArrangementId,
            paymentArrangementNoteId,
            payload
        );
        dispatch(setData('paymentArrangementNotes', get(result, PAYMENT_ARRANGEMENT_NOTES)));
        dispatch(setData('paymentArrangementNotesPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'PAYMENT_ARRANGEMENT_NOTE', '', ['note', 'parent_note_id']));
    } finally {
        dispatch(setData('loadingPaymentArrangementNoteAddEdit'));
    }
};

/**
 * Update the payment plan
 *
 * @param {string} organisationId Organisation Id.
 * @param {string} paymentPlanId Payment plan id
 * @param {object} payload Payload
 * @param {Function} callback Callback
 */
export const updatePaymentPlan = (organisationId, paymentPlanId, payload, callback) => async dispatch => {
    try {
        dispatch(setData('loadingPaymentPlanAddEdit', true));
        const result = await PaymentArrangementApi.updatePaymentPlan(organisationId, paymentPlanId, payload);
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback(paymentPlanId);
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, 'PAYMENT_PLAN_FORM', 'PAYMENT_PLAN'));
    } finally {
        dispatch(setData('loadingPaymentPlanAddEdit'));
    }
};

// export the reducer
export default PaymentArrangementSlice.reducer;

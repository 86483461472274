/**
 * Tag colors
 *
 * @since 2.0.0
 */

export const auditLogStatusTagColors = {
    0: 'default',
    1: '#87d068',
    2: '#f50',
};

export const invoiceStatusTagColors = {
    '1st-demand': '#79a4c8',
    '2nd-demand': '#af9d43',
    'debt-collection': '#ac8685',
    disputed: '#ac8685',
    draft: '#79a4c8',
    'final-demand': '#ea6965',
    hardship: '#fa423c',
    hold: '#ab2b27',
    legal: '#ac8685',
    aged: '#ea6965',
    open: '#79a4c8',
    paid: '#55d07f',
    settled: '#ffb703',
    voided: '#CF4D4C',
    'write-off': '#D00D0B',
};
export const paymentArrangementBrokenTagColor = '#ac1d0d';

export const paymentArrangementInstallmentLatePaidTagColor = '#EA6757';

export const paymentArrangementInstallmentStatusTagColors = {
    0: 'default',
    1: '#f50',
    2: '#87d068',
    3: '#108ee9',
};

export const paymentArrangementInstallmentTypeTagColors = {
    0: 'default',
    1: '#2db7f5',
    2: '#108ee9',
};

export const paymentArrangementOrInstallmentPaymentStatusTagColors = {
    0: 'default',
    1: '#108ee9',
    2: '#108ee9',
    3: '#87d068',
    4: '#f50',
};

export const paymentArrangementStatusTagColors = {
    0: 'default',
    1: '#87d068',
    2: '#f50',
    3: '#108ee9',
    4: '#f50',
    5: '#87d068',
};

import i18n from 'includes/i18n';

/**
 * Get payment plans columns
 *
 * @param {string} planType Plan Type
 */
export const getPaymentPlansTableColumns = planType => {
    return [
        {
            title: i18n.t('messages.paymentArrangement.plans.list.label.name'),
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            collapseHeader: true,
        },
        {
            title: i18n.t('messages.paymentArrangement.plans.list.label.fee'),
            dataIndex: 'fee',
            key: 'fee',
            sorter: true,
            collapseHeader: true,
        },
        {
            title: i18n.t('messages.paymentArrangement.plans.list.label.upfront_amount'),
            dataIndex: 'upfront_amount',
            key: 'upfront_amount',
        },
        {
            title: i18n.t('messages.paymentArrangement.plans.list.label.overdue_range'),
            dataIndex: 'overdue_range',
            key: 'overdue_range',
        },
        {
            title: i18n.t(
                `messages.paymentArrangement.plans.list.label.${
                    planType === 'arrangement' ? 'max_duration' : 'max_payment_date_extension_duration'
                }`
            ),
            dataIndex: planType === 'arrangement' ? 'max_duration' : 'max_payment_date_extension_duration',
            key: planType === 'arrangement' ? 'max_duration' : 'max_payment_date_extension_duration',
        },
        {
            title: i18n.t('messages.paymentArrangement.plans.list.label.auto_approve'),
            dataIndex: 'auto_approve',
            key: 'auto_approve',
            sorter: true,
        },
        {
            title: i18n.t('messages.paymentArrangement.plans.list.label.allow_notes'),
            dataIndex: 'allow_notes',
            key: 'allow_notes',
            sorter: true,
        },
        {
            title: i18n.t('messages.paymentArrangement.plans.list.label.is_enabled'),
            dataIndex: 'is_enabled',
            key: 'is_enabled',
            sorter: true,
        },
    ];
};

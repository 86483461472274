import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import useAllCountries from 'includes/hooks/useAllCountries';
import { find } from 'lodash';

/**
 * Country label
 *
 * Takes in `id` prop and renders a label with country name
 */
export default function CountryLabel({ id }) {
    const { data: countries } = useAllCountries();

    const country = useMemo(() => {
        if (!id || !countries || countries.length === 0) return null;

        return find(countries, { id });
    }, [id, countries]);

    return country ? (
        <span className={`bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full `}>
            {country.name}
        </span>
    ) : null;
}

CountryLabel.propTypes = {
    id: PropTypes.string,
};

/**
 * Reset Password Component
 * Handles the reset password view and related functions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modules
import { React, connect, useEffect } from '../../../includes/exports/react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { change } from 'redux-form';
import ResetPasswordForm from '../forms/ResetPassword';
import * as userActions from '../../../includes/redux/actions/shared/user';
import { buildQueryParamsObject, redirect } from '../../../includes/utils';
import './styles.scss';

/**
 * Reset Password Component
 */
const ResetPassword = props => {
    useEffect(() => {
        const urlParams = getFormInitialValues();
        if (!urlParams.user_id || !urlParams.password_reset_code) {
            redirect('/');
        } else {
            urlParams['validate'] = true;
            props.validateResetPassword(urlParams);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getFormInitialValues = () => {
        let urlParams = buildQueryParamsObject(props.history.location.search);
        return {
            user_id: urlParams.uuid,
            password_reset_code: urlParams.password_reset_code,
        };
    };
    /**
     * Handle the forgot password form submit
     * Dispatches the forgot password form submit action
     *
     * @param   {Array}    values   Array of form values
     */
    const handleSubmit = values => props.resetPassword(values);

    return (
        <section style={{ maxWidth: '1545px', margin: '0 auto' }}>
            <div className="home-left-wrapper">
                <p className="home-left-intro-text">
                    <Trans i18nKey="sharedMessages.resetPassword.paycepaidDescriptionText">
                        Paycepaid is a <b>powerhouse</b> of tools right in <br /> your <b>pocket,</b> arming your
                        business with <br /> everything it needs to completely automate your
                        <br /> <span className="text-highlight">invoice-to-cash process.</span>
                    </Trans>
                </p>
            </div>
            <div>
                <ResetPasswordForm
                    onSubmit={handleSubmit}
                    initialValues={getFormInitialValues()}
                    loading={props.isResettingPassword}
                    {...props}
                />
            </div>
        </section>
    );
};

/**
 * Prop Types
 */
ResetPassword.propTypes = {
    history: PropTypes.object,
    isResettingPassword: PropTypes.bool,
    isValidatingResetPassword: PropTypes.bool,
    loading: PropTypes.bool,
    resetPassword: PropTypes.func,
    t: PropTypes.func,
    validateResetPassword: PropTypes.func,
};

// connect the component to the store
export default connect(
    state => ({
        ...state.user,
    }),
    {
        ...userActions,
        change,
    }
)(withTranslation()(ResetPassword));

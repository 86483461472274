import { find } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import BankMandateSVG from 'assets/images/bank_mandate.svg';
import Button from 'components/Button';
import Loader from 'components/Loader';
import {
    CARD_BRAND_LOGO_MAP,
    CARD_BRAND_NAME_MAP,
    GENERIC_CARD_LOGO,
} from 'components/customer/PaymentInformation/constants';
import { PAYMENT_METHOD_TYPE } from 'includes/constants';
import usePaymentMethodTypes from 'includes/hooks/usePaymentMethodTypes';
import PaymentMethodOption from '../PaymentMethodOption';

/**
 * Saved payment methods
 *
 * Render saved payment methods and lets user select one of them to make payment
 *
 * @since 2.8.0
 */
export default function SavedPaymentMethods({ handlePayButtonClick, isLoadingPayButton, onSelect, selectedMethod }) {
    const paymentMethods = useSelector(state => state.paymentGateways.paymentMethods);

    const isLoadingPaymentMethods = useSelector(state => state.paymentGateways.isLoadingPaymentMethods);

    const { data: paymentMethodTypes } = usePaymentMethodTypes();

    const { t } = useTranslation();

    const getCardExpiryDateString = date => moment(date, 'YYYY-MM-DD').format('MM/YY');

    const getPaymentMethodOptionProps = method => {
        if (paymentMethodTypes.length === 0) return { isLoading: true };

        const type = find(paymentMethodTypes, { id: method.method_type_id });

        switch (type.slug) {
            case PAYMENT_METHOD_TYPE.MANDATE:
                return {
                    countryId: method.country_id,
                    description: t('customerMessages.paymentMethod.mandate.description', {
                        number: method.account_number.slice(-4),
                    }),
                    icon: BankMandateSVG,
                    isSelected: selectedMethod === method.id,
                    onSelect: () => onSelect(method),
                    schemeId: method.scheme_id,
                    title: method.name,
                    typeId: method.method_type_id,
                };
            case PAYMENT_METHOD_TYPE.CREDIT_CARD:
            case PAYMENT_METHOD_TYPE.DEBIT_CARD:
                return {
                    countryId: method.country_id,
                    description: t('customerMessages.paymentMethod.card.validThru', {
                        date: getCardExpiryDateString(method.end_date),
                    }),
                    icon: CARD_BRAND_LOGO_MAP[method.brand_name] ?? GENERIC_CARD_LOGO,
                    isSelected: selectedMethod === method.id,
                    onSelect: () => onSelect(method),
                    title: t('customerMessages.paymentMethod.card.description', {
                        brand: CARD_BRAND_NAME_MAP[method.brand_name],
                        number: method.account_number.slice(-4),
                    }),
                    typeId: method.method_type_id,
                };
        }
    };

    if (isLoadingPaymentMethods) return <Loader />;

    return (
        <div>
            <div className="flex flex-col gap-2">
                {paymentMethods.map(method => (
                    <PaymentMethodOption key={method.id} {...getPaymentMethodOptionProps(method)} />
                ))}
            </div>

            <div className="flex justify-center mt-5">
                <Button loading={isLoadingPayButton} onClick={handlePayButtonClick}>
                    Pay now
                </Button>
            </div>
        </div>
    );
}

SavedPaymentMethods.propTypes = {
    handlePayButtonClick: PropTypes.func,
    isLoadingPayButton: PropTypes.bool,
    onSelect: PropTypes.func,
    selectedMethod: PropTypes.string,
};

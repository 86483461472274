/**
 * Admin module routing
 * Handles the routing across admin module
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import required modules
import AdminSidebar from '../../../components/shared/Sidebar/AdminSidebar';
import AppFooter from '../../../components/shared/footer/AppFooter';
import ChangePassword from '../../../components/shared/ChangePassword';
import Dashboard from '../../../components/debtCollector/Dashboard';
import DebtCollectorHeader from '../../../components/shared/header/DebtCollectorHeader';
import EditProfile from '../../../components/shared/MyProfile/edit';
import InvoiceDetails from '../../../components/debtCollector/InvoiceDetails';
import MyProfile from '../../../components/shared/MyProfile';
import PageNotFound from '../../../components/shared/PageNotFound';
import RouteValidators from '../validators';
import { NoSidebarLayout } from '../../../layouts/NoSidebar';
import { USER_ROLE_DEBT_COLLECTOR } from '../../constants';

export const defaultAppFooter = AppFooter;
export const defaultAppHeader = DebtCollectorHeader;
export const defaultAppLayout = NoSidebarLayout;
export const defaultAppPageNotFound = PageNotFound;
export const defaultAppRoutePrefix = '/debt-collector';
export const defaultAppSidebar = AdminSidebar;
export const defaultAppValidator = [
    RouteValidators.isTokenExists,
    RouteValidators.isRoleValid(USER_ROLE_DEBT_COLLECTOR),
];
export const routes = [
    {
        path: '/change-password',
        component: ChangePassword,
        slug: 'changePassword',
    },
    {
        path: '/dashboard',
        component: Dashboard,
        exact: true,
        slug: 'dashboard',
    },
    {
        path: '/edit-profile',
        component: EditProfile,
        isPrivate: true,
        slug: 'editProfile',
    },
    {
        path: '/invoice/:organisationId/:contactId/:invoiceId',
        component: InvoiceDetails,
        slug: 'invoiceDetails',
    },
    {
        path: '/my-profile',
        component: MyProfile,
        exact: true,
        isPrivate: true,
        slug: 'myProfile',
    },
    //this route object should be the last one in the route for page not found to work correctly
    //add all the other routes above this route object
    {
        path: '',
        component: defaultAppPageNotFound,
        isPublic: true,
    },
];

/**
 * Defines the response keys used in the app
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// _
export const _45X45 = '45x45';
export const _254x254 = '254x254';
export const _ORIGINAL = 'original';

// A
export const ACCOUNT_LOGO_45X45 = 'logo.45x45';
export const ACCOUNT_LOGO_254x254 = 'logo.254x254';
export const ACCOUNT_LOGO_ORIGINAL = 'logo.original';

// C
export const CONTACT_AUTOMATIC_TYPE_SLUG = 'automatic';
export const CONTACT_AVATAR_45X45 = 'avatar.45x45';
export const CONTACT_AVATAR_254x254 = 'avatar.254x254';
export const CONTACT_AVATAR_ORIGINAL = 'avatar.original';
export const CREATED_DATE = 'created_date';
export const CURRENCY = 'currency';
export const CURRENCY_CODE = 'code';
export const CURRENCY_SYMBOL = 'symbol';

// D
export const DEFAULT_CONTACT_AVATAR_45X45 = 'default_contact_avatar.45x45';
export const DEFAULT_CONTACT_AVATAR_254x254 = 'default_contact_avatar.254x254';
export const DEFAULT_CONTACT_AVATAR_ORIGINAL = 'default_contact_avatar.original';
export const DEFAULT_ORGANISATION_LOGO_45X45 = 'default_organisation_logo.45x45';
export const DEFAULT_ORGANISATION_LOGO_254x254 = 'default_organisation_logo.254x254';
export const DEFAULT_ORGANISATION_LOGO_ORIGINAL = 'default_organisation_logo.original';
export const DEFAULT_USER_AVATAR_45X45 = 'default_user_avatar.45x45';
export const DEFAULT_USER_AVATAR_254x254 = 'default_user_avatar.254x254';
export const DEFAULT_USER_AVATAR_ORIGINAL = 'default_user_avatar.original';

// F
export const FAT_ZEBRA_API_URL = 'api_url';
export const FAT_ZEBRA_ERRORS = 'errors[]';
export const FAT_ZEBRA_RESPONSE_CODE = 'r';
export const FAT_ZEBRA_RETURN_PATH = 'return_path';
export const FAT_ZEBRA_TOKEN = 'token';
export const FAT_ZEBRA_VERIFICATION_TOKEN = 'verification_token';

// I
export const ID = 'id';
export const INACTIVE_AUTHORIZATION_TOKEN = 'inactive_authorization_token';
export const INVALID_AUTHORIZATION_TOKEN = 'invalid_authorization_token';

// M
export const MAX = 'max';
export const MIN = 'min';

// N
export const NOTIFICATION = 'notification';
export const NOTIFICATIONS = 'notifications';
export const NOTIFICATION_DESCRIPTION = 'description';
export const NOTIFICATION_HAS_READ = 'has_read';
export const NOTIFICATION_ID = 'id';
export const NOTIFICATION_IMAGE = 'image';
export const NOTIFICATION_LINK = 'link';
export const NOTIFICATION_ORGANISATION_ID = 'organisation_id';
export const NOTIFICATION_TIME_AGO = 'time_ago';
export const NOTIFICATION_TITLE = 'title';
export const NOTIFICATION_TYPE = 'type';
export const NOTIFICATION_TYPE_SLUG = 'slug';
export const NOTIFICATIONS_UNREAD_COUNT = 'unread_count';

// O
export const ORGANISATION_IS_FETCHING_DATA = 'is_fetching_data';
export const ORGANISATION_LOGO_45X45 = 'logo.45x45';
export const ORGANISATION_LOGO_254x254 = 'logo.254x254';
export const ORGANISATION_LOGO_ORIGINAL = 'logo.original';

// P
export const PERMISSION_ERROR = 'permission';
export const PLAN_IMAGE_45X45 = 'image.45x45';
export const PLAN_IMAGE_254x254 = 'image.254x254';
export const PLAN_IMAGE_ORIGINAL = 'image.original';

// T
export const TWO_FACTOR_AUTHENTICATION_NOT_VERIFIED = 'two_factor_authentication_not_verified';

// U
export const URL = 'url';
export const USER_ADDRESS = 'address';
export const USER_AVATAR = 'avatar';
export const USER_AVATAR_45X45 = 'avatar.45x45';
export const USER_AVATAR_254x254 = 'avatar.254x254';
export const USER_AVATAR_ORIGINAL = 'avatar.original';
export const USER_DATE_JOINED = 'joined_date';
export const USER_EMAIL = 'email';
export const USER_EMAIL_VERIFIED = 'email_verified';
export const USER_FIRST_NAME = 'first_name';
export const USER_FULL_NAME = 'full_name';
export const USER_LAST_LOGIN_DATE = 'last_login_date';
export const USER_LAST_NAME = 'last_name';
export const USER_PHONE_NUMBER = 'phone_number';
export const USER_PHONE_NUMBER_VERIFIED = 'phone_number_verified';

// Z
export const ZENDESK_SETTINGS = 'zendesk_settings';

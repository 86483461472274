import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import PaymentMethods from './components/PaymentMethods';
import BankAccounts from './components/BankAccounts';
import { useRestrictCustomerManager } from '../../../includes/utils/hooks';
import useInternalStripeDetails from 'includes/hooks/useInternalStripeDetails';
import Loader from 'components/Loader';
import './styles.scss';

const { TabPane } = Tabs;

/**
 * Payment information
 *
 * @since 2.2.0.3
 */
export default function PaymentInformation() {
    const { t } = useTranslation();

    const getLocaleText = path => t(`sharedMessages.myAccount.${path}`);

    useRestrictCustomerManager();

    const { stripeDetails, isLoading } = useInternalStripeDetails();

    return (
        <div className="home-content-wrapper my-account-detail tab-wrapper">
            <h2 className="page-title">Payment Information</h2>
            <div>
                <section className="white-box-wrapper box-wrapper mid-wrapper no-padding">
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <Tabs defaultActiveKey="credit-card" className="myaccount-content">
                            {stripeDetails?.allow_saving_card ? (
                                <TabPane key="credit-card" tab={getLocaleText('tabs.paymentMethods')}>
                                    <PaymentMethods />
                                </TabPane>
                            ) : null}
                            <TabPane key="bank-account" tab={getLocaleText('tabs.bankAccount')}>
                                <BankAccounts />
                            </TabPane>
                        </Tabs>
                    )}
                </section>
            </div>
        </div>
    );
}

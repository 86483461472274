/**
 * Client middleware
 * Clears the authentication token when the authorization header expires or goes to inactive, or browser redirection in case of error statuses
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import required modules
import { get } from 'lodash';
import { SHOW_NOTIFICATION_MODAL } from '../../constants/actionTypes';
import { ERROR } from '../../constants';
import * as errorMappings from '../../constants/mappings/errors';
import * as responseKeys from '../../constants/keys/response';
import { clearLSAndRedirectToAuthPortalLogoutPage, redirect, redirectToAuthPortal } from '../../utils';

/**
 * Client middleware
 */
const clientMiddleware = store => next => action => {
    if (typeof action === 'function') {
        return action(store.dispatch, store.getState);
    }
    // get the authorization token errors
    let apiErrors = get(action, errorMappings.DEFAULT_API_REDUCER_ERROR_RESPONSE_STRUCTURE);
    const errorStatusCode = get(action, ['errors', 'response', 'status']);

    if (apiErrors) {
        // check if we have invalid or inactive authorization tokens
        if (
            responseKeys.INACTIVE_AUTHORIZATION_TOKEN in apiErrors ||
            responseKeys.INVALID_AUTHORIZATION_TOKEN in apiErrors
        ) {
            // dispatch the logout action
            clearLSAndRedirectToAuthPortalLogoutPage();
            // return [
            //     store.dispatch(
            //         logoutUser({
            //             authorizationFailed: true,
            //             authorizationFailedMessage:
            //                 responseKeys.INACTIVE_AUTHORIZATION_TOKEN in apiErrors
            //                     ? get(apiErrors, [responseKeys.INACTIVE_AUTHORIZATION_TOKEN, 0])
            //                     : errorMessages.USER_SESSION_TIMED_OUT,
            //         })
            //     ),
            // ];
        } else if (responseKeys.TWO_FACTOR_AUTHENTICATION_NOT_VERIFIED in apiErrors) {
            redirectToAuthPortal();
        } else if (responseKeys.PERMISSION_ERROR in apiErrors) {
            // show error modal if permission error
            store.dispatch({
                type: SHOW_NOTIFICATION_MODAL,
                message: apiErrors.permission,
                messageType: ERROR,
            });
        } else if (errorStatusCode === 401) {
            // if the status is 400 redirect to unauthorized page
            redirect('/unauthorized');
            // if the status is 404 redirect to page-not-found page
        } else if (errorStatusCode === 404) {
            redirect('/page-not-found');
            // if the errors include under_maintenance error then redirect to under-maintenance page
        } // else if (authorizationTokenErrors.indexOf('under_maintenance') > -1) {
        //     redirect('/under-maintenance');
        // }
    }
    next(action);
};

export default clientMiddleware;

/**
 * useAllCountries
 * Get all countries
 *
 * @since 2.8.0
 */

import { CACHE_ALL_COUNTRIES_KEY } from 'includes/constants';
import { COUNTRIES } from 'includes/constants/mappings/success';
import { get } from 'lodash';
import CountriesApi from 'includes/services/countries';
import useCachedData from './useCachedData';

/**
 * Get all countries
 *
 * @param {boolean} disableApiCall Whether to disable api call or not. Default false
 *
 * @returns object Countries
 */
const useAllCountries = (disableApiCall = false) => {
    return useCachedData(CACHE_ALL_COUNTRIES_KEY, () => CountriesApi.getCountries({ get_all_countries: true }), {
        selector: res => get(res, COUNTRIES),
        disableApiCall: disableApiCall,
        dependsOnOrg: false,
    });
};

export default useAllCountries;

/**
 * Template Form
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import { React, useState } from '../../../includes/exports/react';
import { Row, Form, Col } from 'antd';
import * as constants from '../../../includes/constants';
import { get } from 'lodash';
import { Field, reduxForm } from 'redux-form';
import Button from '../../shared/Button';
import PropTypes from 'prop-types';
import EmailFields from '../Templates/Email/EmailFields';
import SmsFields from '../Templates/Sms/SmsFields';
import IvrFields from '../Templates/Ivr/IvrFields';
import WhatsAppFields from '../Templates/WhatsApp/WhatsAppFields';
import Preview from '../../shared/Templates/Preview';
import { ID } from '../../../includes/constants/keys/response';
import * as requestKeys from '../../../includes/constants/keys/request';
import FormField from '../FormField';
import * as formValidations from '../../../includes/utils/form';
import useCommunicationTemplateTypes from 'includes/hooks/useCommunicationTemplateTypes';

const TemplateForm = props => {
    const [focus, setFocus] = useState(false);
    const [message, setMessage] = useState(get(props, 'initialValues.message', ''));
    const [openPreview, setOpenPreview] = useState(false);
    const { data: communicationTemplateTypes } = useCommunicationTemplateTypes();
    const { isAdmin, type } = props;
    const isAdd = !get(props.initialValues, ID);

    /**
     * Get the form fields based on type
     */
    const getFormFields = () => {
        switch (type) {
            case 'email':
                return (
                    <EmailFields
                        {...props}
                        setMessage={setMessage}
                        setFocus={setFocus}
                        message={message}
                        focus={focus}
                        showPreview={showPreview}
                        showCC={false}
                    />
                );
            case 'ivr':
                return <IvrFields {...props} setMessage={setMessage} message={message} showPreview={showPreview} />;
            case 'sms':
                return <SmsFields {...props} setMessage={setMessage} message={message} showPreview={showPreview} />;
            case 'whatsapp':
                return (
                    <WhatsAppFields {...props} setMessage={setMessage} message={message} showPreview={showPreview} />
                );
            default:
                return null;
        }
    };

    const showPreview = () => {
        const organisationId = props.organisationId || props.match.params.organisationId;
        if (message) {
            setOpenPreview(true);

            props.getCommunicationTemplatePreview({
                organisation_id: organisationId,
                body: message,
                type: type,
            });
        }
    };
    return (
        <div className={isAdmin && 'white-box-wrapper full-wrapper'}>
            <div className="my-profile-detail">
                <div>
                    <Form layout="vertical" className=" edit-form edit-email-template" onSubmit={props.handleSubmit}>
                        <Row>
                            <Col span={12}>
                                <Field
                                    type="text"
                                    className="form-control"
                                    name={requestKeys.NAME}
                                    label={props.t(`sharedMessages.communication.templates.form.input.label.name`)}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="select"
                                    className="form-control"
                                    name={requestKeys.TEMPLATE_TYPE_ID}
                                    label={props.t(
                                        `sharedMessages.communication.templates.form.input.label.templateType`
                                    )}
                                    hasFeedback
                                    options={communicationTemplateTypes.map(x => ({
                                        value: x.id,
                                        name: x.type,
                                    }))}
                                    component={FormField}
                                    validate={[formValidations.required]}
                                />
                            </Col>
                        </Row>
                        {getFormFields()}
                        <Row>
                            <Button
                                htmlType="submit"
                                big
                                filled
                                className="save-template right-align"
                                loading={props.isAddingCommunicationTemplate || props.isUpdatingCommunicationTemplate}
                                submittingButtonLabel={props.t(
                                    `sharedMessages.communication.templates.form.submit.buttonSubmittingLabel.${
                                        isAdd
                                            ? 'add'
                                            : get(props, 'initialValues.is_custom', true) || props.isAdmin
                                            ? 'edit'
                                            : 'saveAsCopy'
                                    }`
                                )}
                            >
                                {props.t(
                                    `sharedMessages.communication.templates.form.submit.buttonLabel.${
                                        isAdd
                                            ? 'add'
                                            : get(props, 'initialValues.is_custom', true) || props.isAdmin
                                            ? 'edit'
                                            : 'saveAsCopy'
                                    }`
                                )}
                            </Button>
                        </Row>
                    </Form>
                </div>
                <Preview {...props} openPreview={openPreview} setOpenPreview={setOpenPreview} />
            </div>
        </div>
    );
};

// prop types
TemplateForm.propTypes = {
    change: PropTypes.func,
    getCommunicationTemplatePreview: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isAddingCommunicationTemplate: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isLoadingDetails: PropTypes.bool,
    isUpdatingCommunicationTemplate: PropTypes.bool,
    match: PropTypes.object,
    organisationId: PropTypes.string,
    t: PropTypes.func,
    type: PropTypes.string,
};

export default reduxForm({ form: constants.ADD_EDIT_TEMPLATE_FORM })(TemplateForm);

import i18n from 'includes/i18n';

/**
 * Get inventory table columns
 */
export const getInventoryTableColumns = [
    {
        title: i18n.t('messages.inventory.list.label.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.inventory.list.label.code'),
        dataIndex: 'code',
        key: 'code',
        sorter: true,
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.inventory.list.label.unitPrice'),
        dataIndex: 'unit_selling_price',
        key: 'unit_selling_price',
        sorter: true,
    },
    {
        title: i18n.t('messages.inventory.list.label.createdDate'),
        dataIndex: 'created_date',
        key: 'created_date',
        sorter: true,
    },
];

/**
 * Get inventory transaction table columns
 */
export const getInventoryTransactionsTableColumns = [
    {
        title: i18n.t('messages.inventory.transactions.list.label.description'),
        dataIndex: 'description',
        key: 'description',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.inventory.transactions.list.label.date'),
        dataIndex: 'date',
        key: 'date',
        collapseHeader: true,
    },
    {
        title: i18n.t('messages.inventory.transactions.list.label.credit'),
        dataIndex: 'credit',
        key: 'credit',
    },
    {
        title: i18n.t('messages.inventory.transactions.list.label.debit'),
        dataIndex: 'debit',
        key: 'debit',
    },
];

/**
 * Plan graph Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import { React } from '../../../../includes/exports/react';
import { isEmpty } from 'lodash';
import Loader from '../../../shared/Loader';
import { planGraphOptions } from '../../../../includes/charts/plan';
import { PropTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';

/**
 * Payment overview graph Component
 */
const Plan = props => {
    /**
     * Get the plan graph options
     * Adds the data points
     **/
    const getPlanGraphOptions = () => {
        planGraphOptions.data[0].dataPoints = [];
        if (!isEmpty(props.planData)) {
            props.planData.forEach(item =>
                planGraphOptions.data[0].dataPoints.push({ name: item.name, y: item.count })
            );
        }

        return planGraphOptions;
    };

    return (
        <div className="box-wrapper full-wrapper white-box-wrapper">
            <div className="box-title-wrapper">
                <h3 className="page-title">Plan Accounts</h3>
            </div>
            {props.isGraphDataLoading ? (
                <Loader />
            ) : !isEmpty(props.planData) ? (
                <props.canvasJSChart options={getPlanGraphOptions()} />
            ) : (
                <props.infoMessage message={props.t('appCommonMessages.noDataToShow')} />
            )}
        </div>
    );
};

/**
 * Proptypes
 */
Plan.propTypes = {
    canvasJsChart: PropTypes.func,
    isGraphDataLoading: PropTypes.bool,
    infoMessage: PropTypes.func,
    planData: PropTypes.array,
    t: PropTypes.func,
};

export default withTranslation()(Plan);

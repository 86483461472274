/**
 * Droppable Column
 *
 * This component renders a column UI for permission manager
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import Permission from './components/Permission';
import Separator from './components/Separator';

const DroppableColumn = ({
    columnId,
    column,
    handleClick,
    // handleSeparatorToggleButtonClick,
    toggleAllPermissionOfGroup,
}) => {
    const [active, setActive] = useState(null);

    const handleSeparatorToggleButtonClick = (columnId, groupId) => {
        setActive(active === groupId ? null : groupId);
    };

    return (
        <div className="flex flex-col m-5 bg-gray-200 items-center rounded-md" key={columnId}>
            <h2 className="text-lg pt-2">{column.name}</h2>
            <div className="w-full m-2 p-3">
                <Droppable droppableId={columnId} key={columnId}>
                    {provided => {
                        return (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="p-3 permission-column-wrapper mx-auto"
                            >
                                {column.items.map((item, index) => {
                                    const isActive = active === item.group;
                                    return (
                                        <Draggable
                                            key={item.id}
                                            draggableId={item.id}
                                            index={index}
                                            isDragDisabled={
                                                item.type === 'separator' || item.has_all_plan_access_by_default
                                            }
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        className={isActive || item.type === 'separator' ? 'mb-2' : ''}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                            userSelect: 'none',
                                                            minHeight:
                                                                isActive || item.type === 'separator' ? '50px' : 0,
                                                            ...provided.draggableProps.style,
                                                        }}
                                                    >
                                                        {item.type === 'separator' ? (
                                                            <Separator
                                                                columnId={columnId}
                                                                groupId={item.group}
                                                                handleClick={handleSeparatorToggleButtonClick}
                                                                isActive={isActive}
                                                                label={item.label}
                                                                toggleAll={toggleAllPermissionOfGroup}
                                                            />
                                                        ) : (
                                                            <Permission
                                                                columnId={columnId}
                                                                handleClick={handleClick}
                                                                index={index}
                                                                item={item}
                                                                snapshot={snapshot}
                                                                isGroupActive={isActive}
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            }}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </div>
        </div>
    );
};

DroppableColumn.propTypes = {
    column: PropTypes.shape({
        activeGroup: PropTypes.oneOfType([PropTypes.string, null]),
        items: PropTypes.shape({
            map: PropTypes.func,
        }),
        name: PropTypes.string,
    }).isRequired,
    columnId: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    handleSeparatorToggleButtonClick: PropTypes.func.isRequired,
    toggleAllPermissionOfGroup: PropTypes.any.isRequired,
};

export default DroppableColumn;

/**
 * Subscription Listing Component
 * Handles the subscription list view and functions related to loading subscription listing
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules

import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { find, get } from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import { connect, useSelector } from 'react-redux';

import { getSubscriptionPlans } from '../../../includes/redux/actions/customer/subscriptionPlan';
import Button from '../../shared/Button';
import './styles.scss';
import Loader from '../../shared/Loader';
import { useRestrictCustomerManager, useUpdateBusinessName } from '../../../includes/utils/hooks';
import StarterPlanImage from 'assets/images/starter-plan.png';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useCurrencies from 'includes/hooks/useCurrencies';
import PermissionGuard from 'components/PermissionGuard';
import { redirect } from 'includes/utils';
import CustomPlanNoPlanUpgradeMessage from 'components/CustomPlanNoPlanUpgradeMessage';

/**
 * Subscription Component
 */

const Subscription = props => {
    const getNumberFormattedAsCurrency = useCurrencyFormatter();
    const { data: currencies, isLoading: loadingCurrencies } = useCurrencies();
    // effect to fetch subscription plan details
    const { getSubscriptionPlans, subscriptionPlans } = props;

    const accountDetails = useSelector(state => state.account.accountDetails);

    // check if user has updated the business name
    useUpdateBusinessName();

    useRestrictCustomerManager();

    useEffect(() => {
        getSubscriptionPlans();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Format the number as currency based on the plan currency
     *
     * @param {number|string} number Number to be formatted
     * @param {object} order Order object
     *
     * @returns {string} Formatted number
     */
    const getNumberFormattedAsCurrencyByPlanCurrency = (number, plan) =>
        getNumberFormattedAsCurrency(
            number,
            false,
            false,
            get(find(currencies, { id: plan?.currency_id }), 'code', 'AUD')
        );

    // filter out starter plan
    const getSubscriptionPlansData = useCallback(
        () =>
            subscriptionPlans
                .filter(p => p.slug !== 'starter-plan')
                .map(plan => {
                    const isEnterprisePlan = plan.slug === 'enterprise-plan';
                    return (
                        <li className="white-box-wrapper word-wrap" key={plan.id}>
                            <div className="plan-intro pricing-card">
                                <div className="type-wrap">
                                    <h4>{plan.type}</h4>
                                    <div className="icon-block">
                                        <img src={get(plan, 'image.original') || StarterPlanImage} width="68" alt="" />
                                    </div>
                                </div>
                                {!isEnterprisePlan ? (
                                    <>
                                        <div className="pricing-wrap">
                                            <h3 className="pricing-h3">
                                                {getNumberFormattedAsCurrencyByPlanCurrency(plan.cost, plan)}
                                                <span className="month-span">/month</span>
                                            </h3>
                                        </div>
                                        <h3 className="pricing-h3">
                                            {getNumberFormattedAsCurrencyByPlanCurrency(
                                                plan.yearly_per_month_cost,
                                                plan
                                            )}
                                            <span className="month-span">/month billed annually </span>
                                        </h3>
                                        <p className="paragraph">{plan?.subtitle}</p>
                                    </>
                                ) : null}
                            </div>
                            <div className="plan-details-wrapper">
                                {!isEnterprisePlan ? (
                                    ReactHtmlParser(plan?.description)
                                ) : (
                                    <p className="subscription-plan-desc">Contact our sales team for a quote.</p>
                                )}
                            </div>
                            <PermissionGuard>
                                <div className="buy-plan-init-btn">
                                    <Button
                                        className="green-bg-button"
                                        filled
                                        onClick={() => {
                                            if (isEnterprisePlan) redirect('/request-quote');
                                            else redirect('/plan/buy', false, { plan_id: plan.id });
                                        }}
                                    >
                                        Choose Plan
                                    </Button>
                                </div>
                            </PermissionGuard>
                        </li>
                    );
                }),
        [subscriptionPlans]
    );

    //memoized subscription plan data
    const memoizedSubscriptionPlansData = useMemo(() => getSubscriptionPlansData(), [getSubscriptionPlansData]);

    const subscription = get(accountDetails, 'subscription');

    if (subscription?.plan?.is_custom) return <CustomPlanNoPlanUpgradeMessage />;

    return (
        <div className="home-content-wrapper subscription-wrapper">
            <h2 className="page-title">Subscription</h2>
            <section>
                {props.isLoading || loadingCurrencies ? (
                    <Loader />
                ) : (
                    <ul className="plan-list">
                        {subscriptionPlans?.length === 1 && <li>&nbsp;</li>}
                        {memoizedSubscriptionPlansData}
                        {subscriptionPlans?.length === 1 && <li>&nbsp;</li>}
                    </ul>
                )}
            </section>
        </div>
    );
};

Subscription.propTypes = {
    getSubscriptionPlans: PropTypes.func,
    isLoading: PropTypes.bool,
    subscriptionPlans: PropTypes.array,
};

export default connect(
    state => ({
        subscriptionPlans: state.subscription.subscriptionPlans,
        isLoading: state.subscription.isLoading,
    }),
    { getSubscriptionPlans }
)(Subscription);

// import required modules
import { React, connect, Link, useEffect } from '../../../includes/exports/react';
import { Breadcrumb } from 'antd';
import { useTranslation, withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useDataFromStore } from '../../../includes/utils/hooks';
import { getAccountTypes } from '../../../includes/redux/actions/shared/account';
import AddAccountForm from './componenets/AddAccountForm';
import { getSubscriptionPlans } from '../../../includes/redux/actions/customer/subscriptionPlan';
import Loader from 'components/shared/Loader';
import { useMemo } from 'react';
import useCountries from 'includes/hooks/useCountries';
import { useDispatch } from 'react-redux';
import { addAccount } from 'includes/slices/account';
import { redirect } from 'includes/utils';

/**
 * Add account Component
 */
const AddAccount = props => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    useDataFromStore({
        reducer: 'account',
        dataKey: 'accountTypes',
        loadingKey: 'isTypesLoading',
        action: getAccountTypes,
    });

    const { data: countries, isLoading: isCountriesLoading } = useCountries(false);

    const starterPlan = useMemo(() => {
        if (!props?.subscriptionPlans) return '';
        return props?.subscriptionPlans?.find(plan => plan?.slug === 'starter-plan')?.id ?? '';
    }, [props.subscriptionPlans]);

    useEffect(() => {
        props.getSubscriptionPlans({
            page_size: 0,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Handle the add account form submit
     */
    const handleSubmit = values => {
        dispatch(
            addAccount(values, () => {
                redirect(`/admin/accounts`);
            })
        );
    };

    const getLocale = path => t(`adminMessages.accounts.${path}`);

    return (
        <div className="home-content-wrapper edit-profile-wrapper">
            <div className="tab-arrow-link">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={`/admin/accounts`}>{getLocale('breadcrumb.accounts')}</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{getLocale(`breadcrumb.addAccount`)}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h2 className="page-title">{getLocale(`pageTitle.Add`)}</h2>
            <div className={'white-box-wrapper full-wrapper'}>
                {props.isLoading || isCountriesLoading ? (
                    <Loader />
                ) : (
                    <AddAccountForm
                        onSubmit={handleSubmit}
                        {...props}
                        countries={countries}
                        initialValues={{ plan: starterPlan }}
                    />
                )}
            </div>
        </div>
    );
};

AddAccount.propTypes = {
    addAccount: PropTypes.func,
    isLoading: PropTypes.bool,
    getSubscriptionPlans: PropTypes.func,
    subscriptionPlans: PropTypes.array,
};

// connect the component to the store
export default connect(
    state => ({
        accounts: state.account.accounts,
        accountTypes: state.account.accountTypes,
        isAdmin: state.account.isAdmin,
        subscriptionPlans: state.subscription.subscriptionPlans,
        isLoading: state.subscription.isLoading,
    }),
    {
        getSubscriptionPlans,
    }
)(withTranslation()(AddAccount));

/**
 * Chart of accounts API class.
 * Handles all api requests related to chart of accounts
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import base api handler
import BaseApiHandler from './base/api';

class ChartOfAccountsApi extends BaseApiHandler {
    /**
     * Add chart of account
     *
     * @param {object} payload Payload data to update
     * @param {string} organisationId Organisation id. Default ''
     *
     * @returns {object} API Response
     */
    addChartOfAccount = (payload, organisationId = '') =>
        this.axios.post(
            this.getApiUrl(this._getApiType(organisationId, true), {
                organisation_id: organisationId,
            }),
            payload
        );

    /**
     * Delete chart of account
     *
     * @param {string} chartOfAccountId Chart of account Id
     * @param {string} organisationId organisation Id. Default ''
     *
     * @returns {object} API Response
     */
    deleteChartOfAccount = (chartOfAccountId, organisationId = '') =>
        this.axios.delete(
            this.getApiUrl(this._getApiType(organisationId), {
                chart_of_account_id: chartOfAccountId,
                organisation_id: organisationId,
            })
        );

    /**
     * Get the chart of account
     *
     * @param {string} chartOfAccountId Chart of account Id
     * @param {string} organisationId Organisation ID. Default ''
     *
     * @returns {object} API Response
     */
    getChartOfAccount = (chartOfAccountId, organisationId = '') =>
        this.axios.get(
            this.getApiUrl(this._getApiType(organisationId), {
                chart_of_account_id: chartOfAccountId,
                organisation_id: organisationId,
            })
        );

    /**
     * Get the chart of accounts
     *
     * @param {object} searchParams Search Params
     * @param {string} organisationId Organisation ID
     *
     * @returns {object} API Response
     */
    getChartOfAccounts = (searchParams, organisationId = '') =>
        this.axios.get(
            this.getApiUrl(this._getApiType(organisationId, true), {
                organisation_id: organisationId,
            }),
            {
                params: searchParams,
            }
        );

    /**
     * Get the chart of account transactions
     *
     * @param {string} chartOfAccountId Chart of account Id
     * @param {string} organisationId Organisation ID
     * @param {object} searchParams Search Params. Default ''
     *
     * @returns {object} API Response
     */
    getChartOfAccountTransactions = (chartOfAccountId, organisationId, searchParams = '') =>
        this.axios.get(
            this.getApiUrl('CHART_OF_ACCOUNT_ORGANISATION_TRANSACTIONS', {
                chart_of_account_id: chartOfAccountId,
                organisation_id: organisationId,
            }),
            {
                params: searchParams,
            }
        );

    /**
     * Get the chart of account types
     *
     * @returns {object} API Response
     */
    getChartOfAccountTypes = () => this.axios.get(this.getApiUrl('CHART_OF_ACCOUNT_TYPES'));

    /**
     * Update the chart of account
     *
     * @param {string} chartOfAccountId Chart of account id
     * @param {object} payload Payload data to update
     * @param {string} organisationId Organisation id
     *
     * @returns {object} API Response
     */
    updateChartOfAccount = (chartOfAccountId, payload, organisationId = '') =>
        this.axios.patch(
            this.getApiUrl(this._getApiType(organisationId), {
                chart_of_account_id: chartOfAccountId,
                organisation_id: organisationId,
            }),
            payload
        );

    /**
     * Get the Chart of account api type
     *
     * @param {string} organisationId Organisation Id. Default ''
     * @param {boolean} multiple. Whether to get api type for accounts or account. Default false
     *
     * @returns {string} apiType API Type
     */
    _getApiType = (organisationId = '', multiple = false) => {
        let apiType = '';
        if (multiple) {
            apiType = organisationId ? 'CHART_OF_ACCOUNTS_ORGANISATION_CRUD' : 'CHART_OF_ACCOUNTS_CRUD';
        } else {
            apiType = organisationId ? 'CHART_OF_ACCOUNT_ORGANISATION_CRUD' : 'CHART_OF_ACCOUNT_CRUD';
        }
        return apiType;
    };
}

export default new ChartOfAccountsApi();

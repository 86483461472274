/**
 * Campaign add-edit form Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

// import required modules
import React, { useEffect } from 'react';
import { addCampaign, updateCampaign } from 'includes/slices/campaigns';
import { Breadcrumb, Modal } from 'antd';
import { getCampaign } from 'includes/slices/campaigns';
import { redirect } from 'includes/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CampaignForm from 'pages/Campaigns/AddEdit/components/Form';
import CampaignsBreadCrumbItems from 'components/BreadCrumbItems/Campaigns';
import Loader from 'components/shared/Loader';
import PropTypes from 'prop-types';
import useContactTypes from 'includes/hooks/useContactTypes';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useCampaigns from 'includes/hooks/useCampaigns';
import { CAMPAIGN_PAYMENT_PLAN_ID } from 'includes/constants/keys/request';
import { getPaymentPlan } from 'includes/slices/paymentArrangement';
import { isEmpty } from 'lodash';
import { CAMPAIGN_TYPE_BROKEN, CAMPAIGN_TYPE_REMINDER } from 'includes/constants';

const { confirm } = Modal;

const CampaignAddEdit = props => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();

    const { accountId, campaignId } = params;
    const campaignDetails = useSelector(state => state.campaigns.campaign);
    const paymentPlanDetails = useSelector(state => state.paymentArrangement.paymentPlan);
    const isAdminOrManager = useIsAdminOrManager();
    const loadingCampaign = useSelector(state => state.campaigns.loadingCampaign);
    const loadingPaymentPlan = useSelector(state => state.paymentArrangement.loadingPaymentPlan);
    const organisationId = useOrganisationId();
    const { isLoading: loadingContactTypes } = useContactTypes();
    const { reset: clearCampaignsCache } = useCampaigns(true);

    /**
     * Get the campaign details
     *
     * @since 2.0.0
     */
    useEffect(() => {
        if (!props.isAdd) {
            dispatch(getCampaign(organisationId, campaignId));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get the payment arrangement plan
     */
    useEffect(() => {
        if (
            campaignDetails?.campaign_type === CAMPAIGN_TYPE_BROKEN &&
            campaignDetails?.payment_plan_id &&
            isEmpty(paymentPlanDetails)
        ) {
            dispatch(getPaymentPlan(organisationId, campaignDetails?.payment_plan_id));
        }
    }, [campaignDetails]); //eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmitConfirm = (values, confirmMessage, isAdd = false) => {
        confirm({
            cancelText: t('messages.no'),
            content: confirmMessage,
            okText: t('messages.yes'),
            title: '',
            onOk: () => {
                values['confirm'] = true;
                isAdd
                    ? dispatch(addCampaign(organisationId, values, redirectToDetailsPage))
                    : dispatch(updateCampaign(organisationId, campaignDetails?.id, values, redirectToDetailsPage));
            },
        });
    };
    /**
     * Handle the submit of add/edit of campaign
     *
     * @since 2.0.0
     */
    const handleSubmit = values => {
        if (props.paymentPlanId) {
            values[CAMPAIGN_PAYMENT_PLAN_ID] = props.paymentPlanId;
        }
        props.isAdd
            ? dispatch(
                  addCampaign(organisationId, values, redirectToDetailsPage, confirmMessage =>
                      handleSubmitConfirm(values, confirmMessage, true)
                  )
              )
            : dispatch(
                  updateCampaign(organisationId, campaignDetails?.id, values, redirectToDetailsPage, confirmMessage =>
                      handleSubmitConfirm(values, confirmMessage)
                  )
              );
    };

    /**
     * Redirect to campaign details page
     *
     * @param {string} campaignId Campaign id
     * @param {number} campaignType Campaign type
     * @param {string} paymentPlanId Payment plan id. Default ''
     *
     * @since 2.0.0
     */
    const redirectToDetailsPage = (campaignId, campaignType, paymentPlanId = '') => {
        clearCampaignsCache();
        if (campaignType === CAMPAIGN_TYPE_REMINDER) {
            redirect(
                isAdminOrManager
                    ? `/admin/accounts/organisation/campaigns/campaign/${accountId}/${organisationId}/${campaignId}`
                    : `/campaigns/campaign/${campaignId}`
            );
        } else {
            redirect(
                isAdminOrManager
                    ? `/admin/accounts/organisation/payment-plans/plan/${accountId}/${organisationId}/${paymentPlanId}/campaigns/campaign/${campaignId}`
                    : `/payment-plans/plan/${paymentPlanId}/campaigns/campaign/${campaignId}`
            );
        }
    };

    /**
     * Render the campaign form
     */
    const renderCampaignForm = () =>
        loadingCampaign || loadingContactTypes || loadingPaymentPlan ? (
            <Loader tip={t('appCommonMessages.loadingMessage')} />
        ) : (
            <CampaignForm
                enableReinitialize
                initialValues={props.isAdd ? { campaign_type: props.campaignType } : campaignDetails}
                isAdd={props.isAdd}
                onSubmit={handleSubmit}
                campaignType={props?.campaignType || campaignDetails?.campaign_type}
            />
        );

    return props.isAdd ? (
        renderCampaignForm()
    ) : (
        <div className="home-content-wrapper edit-profile-wrapper">
            {!props.isAdd ? (
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <CampaignsBreadCrumbItems
                            showAddEditBreadCrumb
                            showDetailsBreadCrumb
                            showDetailsBreadCrumbAsLink
                            showListBreadCrumb
                            paymentPlanId={campaignDetails?.payment_plan_id}
                        />
                    </Breadcrumb>
                </div>
            ) : null}
            <h2 className="page-title">{t(`titleAndMetas.campaign${props.isAdd ? 'Add' : 'Edit'}.pageTitle`)}</h2>
            <div className="white-box-wrapper mid-wrapper">{renderCampaignForm()}</div>
        </div>
    );
};

CampaignAddEdit.defaultProps = {
    paymentPlanId: '',
};

CampaignAddEdit.propTypes = {
    campaignType: PropTypes.number,
    isAdd: PropTypes.bool,
    paymentPlanId: PropTypes.string,
};

export default CampaignAddEdit;

/**
 * useCurrencyFormatter
 * Format the number with organisation or account currency
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { CURRENCY, CURRENCY_CODE } from 'includes/constants/keys/response';
import { DEFAULT_CURRENCY_CODE } from 'includes/constants';
import { formatNumberAsCurrency } from 'includes/utils';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';

/**
 * Format the number with organisation or account currency
 *
 * @returns {Function} Function to format currency
 */
const useCurrencyFormatter = () => {
    const accountDetails = useSelector(state => state.account.accountDetails);
    const accountId = useAccountId();
    const isAdmin = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);
    const userAccountDetails = useSelector(state => state.account.userAccountDetails);

    const organisationCurrency = get(selectedOrganisation, CURRENCY);
    const accountCurrency = get(accountId && isAdmin ? userAccountDetails : accountDetails, CURRENCY);

    return (number, useAccountCurrency = false, showEmptyIfZero = false, currencyCode = '') => {
        if (showEmptyIfZero && !number) return '';

        const currency = currencyCode
            ? ''
            : useAccountCurrency || !organisationId
            ? accountCurrency
            : organisationCurrency;

        return formatNumberAsCurrency(
            number,
            currencyCode ? currencyCode : get(currency, CURRENCY_CODE, DEFAULT_CURRENCY_CODE)
        );
    };
};

export default useCurrencyFormatter;

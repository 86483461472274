/**
 * Invoice Saga
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import the required modules
import * as actionTypes from '../../../../constants/actionTypes';
import * as constants from '../../../../constants';
import * as successMappings from '../../../../constants/mappings/success';
import BaseSagaHandler from '../../core/base';
import InvoiceApi from '../../../../services/customer/invoice';
import { get, isFunction } from 'lodash';
import { redirect } from '../../../../utils';
import { stopSubmit } from 'redux-form';
import { takeLatest, all, put, call } from 'redux-saga/effects';
import { UNKNOWN_ERROR } from '../../../../constants/messages/errors';
import { PAID_ON } from '../../../../constants/keys/request';
import {
    DELETE_INVOICE_ERROR_RESPONSE_STRUCTURE,
    DELETE_INVOICE_PAYMENT_ERROR_RESPONSE_STRUCTURE,
} from '../../../../constants/mappings/errors';
import { setContactDetails } from '../../../../slices/contact';

/**
 * Invoice Saga Class. Handles the invoice related operations
 * */
class InvoiceSaga extends BaseSagaHandler {
    /**
     * The Invoice Watcher Saga
     * Watches the redux actions related to invoice and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *invoiceWatchers() {
        let context = this;
        yield all([
            yield takeLatest(actionTypes.ADD_INVOICE_PAYMENT_REQUEST, [context, 'addInvoicePayment']),
            yield takeLatest(actionTypes.ADD_INVOICE_REQUEST, [context, 'addInvoice']),
            yield takeLatest(actionTypes.APPLY_INVOICE_TYPE_REQUEST, [context, 'applyInvoiceType']),
            yield takeLatest(actionTypes.DELETE_INVOICE_REQUEST, [context, 'deleteInvoice']),
            yield takeLatest(actionTypes.DELETE_INVOICE_PAYMENT_REQUEST, [context, 'deleteInvoicePayment']),
            yield takeLatest(actionTypes.EXPORT_ALL_INVOICE_PAYMENTS_REQUEST, [context, 'exportAllInvoicePayments']),
            yield takeLatest(actionTypes.EXPORT_INVOICE_PAYMENTS_REQUEST, [context, 'exportInvoicePayments']),
            yield takeLatest(actionTypes.EXPORT_INVOICES_REQUEST, [context, 'exportInvoices']),
            yield takeLatest(actionTypes.GET_ALL_CONTACT_INVOICES_REQUEST, [context, 'getAllContactInvoices']),
            yield takeLatest(actionTypes.GET_ALL_INVOICE_PAYMENTS_REQUEST, [context, 'getAllInvoicePayments']),
            yield takeLatest(actionTypes.GET_CONTACT_INVOICES_REQUEST, [context, 'getContactInvoices']),
            yield takeLatest(actionTypes.GET_INVOICE_DETAILS_REQUEST, [context, 'getInvoiceDetails']),
            yield takeLatest(actionTypes.GET_INVOICE_PAYMENTS_REQUEST, [context, 'getInvoicePayments']),
            yield takeLatest(actionTypes.GET_INVOICE_PDF_DOWNLOAD_URL_REQUEST, [context, 'getInvoicePdfDownloadUrl']),
            yield takeLatest(actionTypes.GET_INVOICE_STATUSES_REQUEST, [context, 'getInvoiceStatuses']),
            yield takeLatest(actionTypes.GET_INVOICE_TYPES_REQUEST, [context, 'getInvoiceTypes']),
            yield takeLatest(actionTypes.GET_INVOICES_REQUEST, [context, 'getInvoices']),
            yield takeLatest(actionTypes.UPDATE_INVOICE_PAYMENT_REQUEST, [context, 'updateInvoicePayment']),
            yield takeLatest(actionTypes.UPDATE_INVOICE_REQUEST, [context, 'updateInvoice']),
        ]);
    }

    /**
     * Add invoice
     *
     * @param {object} payload Payload
     *
     * @yields {object} Add Invoice response
     */
    *addInvoice({ payload }) {
        try {
            const result = yield call(InvoiceApi.addInvoice, payload);
            yield put({ type: actionTypes.ADD_INVOICE_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            redirect('/invoices');
        } catch (errors) {
            yield put({ type: actionTypes.ADD_INVOICE_FAILURE, errors });
            yield put(
                stopSubmit(
                    constants.INVOICE_FORM_NAME,
                    this.getApiErrors(errors, constants.INVOICE_ADD_DEFAULT_ERROR, constants.INVOICE_ADD_DEFAULT_ERROR)
                )
            );
        }
    }

    /**
     * Add invoice payment
     *
     * @param {object} payload Payload
     * @param {Function} callback Callback function
     *
     * @yields {object} Add Invoice Payment response
     */
    *addInvoicePayment({ payload, callback }) {
        try {
            const result = yield call(InvoiceApi.addInvoicePayment, payload);
            yield put({ type: actionTypes.ADD_INVOICE_PAYMENT_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            callback && callback();
        } catch (errors) {
            yield put({ type: actionTypes.ADD_INVOICE_PAYMENT_FAILURE, errors });
            yield put(
                stopSubmit(
                    constants.EDIT_PAYMENT_HISTORY_FORM_NAME,
                    this.getApiErrors(
                        errors,
                        constants.INVOICE_PAYMENT_DEFAULT_ERROR,
                        constants.INVOICE_PAYMENT_DEFAULT_ERROR,
                        [PAID_ON]
                    )
                )
            );
        }
    }

    /**
     * Change the type of the invoice
     *
     * @param {object} payload Payload
     *
     * @yields {object} Apply invoice type response
     */
    *applyInvoiceType({ payload }) {
        try {
            const response = yield call(InvoiceApi.applyInvoiceType, payload);
            yield put({ type: actionTypes.APPLY_INVOICE_TYPE_SUCCESS, response });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(response, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            yield put({ type: actionTypes.APPLY_INVOICE_TYPE_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Delete invoice
     *
     * @param {Function} callback Callback function
     * @param {object} payload Payload
     *
     * @yields {object} Delete Invoice response
     */
    *deleteInvoice({ callback, payload }) {
        try {
            const result = yield call(InvoiceApi.deleteInvoice, payload);
            yield put({ type: actionTypes.DELETE_INVOICE_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            if (callback && isFunction(callback)) {
                callback();
            }
        } catch (errors) {
            yield put({ type: actionTypes.DELETE_INVOICE_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(errors, DELETE_INVOICE_ERROR_RESPONSE_STRUCTURE),
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Delete invoice payment
     *
     * @param {object} payload Payload
     *
     * @yields {object} Delete Invoice Payment response
     */
    *deleteInvoicePayment({ payload }) {
        try {
            const result = yield call(InvoiceApi.deleteInvoicePayment, payload);
            yield put({ type: actionTypes.DELETE_INVOICE_PAYMENT_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            yield put({ type: actionTypes.DELETE_INVOICE_PAYMENT_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(errors, DELETE_INVOICE_PAYMENT_ERROR_RESPONSE_STRUCTURE),
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Export all invoice payments
     * On success, open the invoice payment file in new tab
     *
     * @param {object} payload Payload
     *
     * @yields {object} Export all invoice payments response
     */
    *exportAllInvoicePayments({ payload }) {
        try {
            const result = yield call(InvoiceApi.exportAllInvoicePayments, payload);
            yield put({ type: actionTypes.EXPORT_ALL_INVOICE_PAYMENTS_SUCCESS, result });
            window.open(get(result, successMappings.EXPORT_FILE), '_blank');
        } catch (errors) {
            yield put({ type: actionTypes.EXPORT_ALL_INVOICE_PAYMENTS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Export invoice payments
     * On success, open the invoice payment file in new tab
     *
     * @param {object} payload Payload
     *
     * @yields {object} Export invoice  response
     */
    *exportInvoicePayments({ payload }) {
        try {
            const result = yield call(InvoiceApi.exportInvoicePayments, payload);
            yield put({ type: actionTypes.EXPORT_INVOICE_PAYMENTS_SUCCESS, result });
            window.open(get(result, successMappings.EXPORT_FILE), '_blank');
        } catch (errors) {
            yield put({ type: actionTypes.EXPORT_INVOICE_PAYMENTS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Export invoices
     * On success, open the invoices file in new tab
     *
     * @param {object} payload Payload
     *
     * @yields {object} Export invoices response
     */
    *exportInvoices({ payload }) {
        try {
            const result = payload.contactId
                ? yield call(InvoiceApi.exportInvoices, payload)
                : yield call(InvoiceApi.exportAllInvoices, payload);
            yield put({ type: actionTypes.EXPORT_INVOICES_SUCCESS, result });
            window.open(get(result, successMappings.EXPORT_FILE, ''), '_self');
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            yield put({ type: actionTypes.EXPORT_INVOICES_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get all invoices
     * *
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get all invoices response
     */
    *getAllContactInvoices({ payload }) {
        try {
            const result = yield call(InvoiceApi.getAllContactInvoices, payload);
            yield put(setContactDetails(get(result, successMappings.GET_CONTACT_DETAILS)));
            yield put({ type: actionTypes.GET_ALL_CONTACT_INVOICES_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_ALL_CONTACT_INVOICES_FAILURE, errors });
        }
    }

    /**
     * Get all invoice payments
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get all invoice payments response
     */
    *getAllInvoicePayments({ payload }) {
        try {
            const result = yield call(InvoiceApi.getAllInvoicePayments, payload);
            yield put({ type: actionTypes.GET_ALL_INVOICE_PAYMENTS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_ALL_INVOICE_PAYMENTS_FAILURE, errors });
        }
    }

    /**
     * Get invoices of a contact
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get all contact invoices response
     */
    *getContactInvoices({ payload }) {
        try {
            const result = yield call(InvoiceApi.getContactInvoices, payload);
            yield put({ type: actionTypes.GET_CONTACT_INVOICES_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_CONTACT_INVOICES_FAILURE, errors });
        }
    }

    /**
     * Get the details of an invoice
     *
     * @param {object} action Action
     *
     * @yields {object} Get invoice details response
     */
    *getInvoiceDetails(action) {
        try {
            const result = yield call(InvoiceApi.getInvoiceDetails, action.payload);
            yield put({ type: actionTypes.GET_INVOICE_DETAILS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_INVOICE_DETAILS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get invoice payments
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get invoice payments response
     */
    *getInvoicePayments({ payload }) {
        try {
            const result = yield call(InvoiceApi.getInvoicePayments, payload);
            yield put({ type: actionTypes.GET_INVOICE_PAYMENTS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_INVOICE_PAYMENTS_FAILURE, errors });
        }
    }

    /**
     * Get Invoice PDF Download URL
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get Invoice PDF download URL response
     */
    *getInvoicePdfDownloadUrl({ payload }) {
        try {
            const result = yield call(InvoiceApi.getInvoicePdfDownloadUrl, payload);
            const invoicePdfDownloadUrl = get(result, successMappings.INVOICE_PDF_DOWNLOAD_URL, '');
            if (invoicePdfDownloadUrl) {
                window.open(get(result, successMappings.INVOICE_PDF_DOWNLOAD_URL, ''), '_blank');
                yield put({
                    type: actionTypes.GET_INVOICE_PDF_DOWNLOAD_URL_SUCCESS,
                    result,
                });
                yield this.showNotificationModal(result, true);
            } else {
                throw actionTypes.GET_INVOICE_PDF_DOWNLOAD_URL_FAILURE;
            }
        } catch (errors) {
            yield this.showNotificationModal(errors, false, 'GET_INVOICE_PDF_DOWNLOAD_URL_ERROR');
            yield put({
                type: actionTypes.GET_INVOICE_PDF_DOWNLOAD_URL_FAILURE,
            });
        }
    }

    /**
     * Get invoices
     *
     * @param {object} action Action
     *
     * @yields {object} Get invoices response
     */
    *getInvoices(action) {
        try {
            const result = yield call(InvoiceApi.getInvoices, action.payload);
            yield put({ type: actionTypes.GET_INVOICES_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_INVOICES_FAILURE, errors });
        }
    }

    /**
     * Get invoice statuses
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get invoice statuses response
     */
    *getInvoiceStatuses({ payload }) {
        try {
            const result = yield call(InvoiceApi.getInvoiceStatuses, payload);
            let invoiceStatuses = get(result, successMappings.GET_INVOICE_STATUSES);
            invoiceStatuses = invoiceStatuses.map(status => ({ ...status, label: status.status, value: status.id }));
            yield put({ type: actionTypes.GET_INVOICE_STATUSES_SUCCESS, invoiceStatuses });
        } catch (errors) {
            yield put({ type: actionTypes.GET_INVOICE_STATUSES_FAILURE, errors });
        }
    }

    /**
     * Get invoice types
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get invoice types response
     */
    *getInvoiceTypes({ payload }) {
        try {
            const result = yield call(InvoiceApi.getInvoiceTypes, payload);
            let invoiceTypes = get(result, successMappings.GET_INVOICE_TYPES);
            invoiceTypes = invoiceTypes.map(type => ({ ...type, label: type.type, value: type.id }));
            yield put({ type: actionTypes.GET_INVOICE_TYPES_SUCCESS, invoiceTypes });
        } catch (errors) {
            yield put({ type: actionTypes.GET_INVOICE_TYPES_FAILURE, errors });
        }
    }

    /**
     * Update details of an invoice
     *
     * @param {object} payload Payload
     *
     * @yields {object} Update invoice details response
     */
    *updateInvoice({ payload, callback }) {
        try {
            const result = yield call(InvoiceApi.updateInvoice, payload);
            yield put({ type: actionTypes.UPDATE_INVOICE_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            callback();
        } catch (errors) {
            yield put({ type: actionTypes.UPDATE_INVOICE_FAILURE, errors });
            yield put(
                stopSubmit(
                    constants.INVOICE_FORM_NAME,
                    this.getApiErrors(
                        errors,
                        constants.INVOICE_UPDATE_DEFAULT_ERROR,
                        constants.INVOICE_UPDATE_DEFAULT_ERROR
                    )
                )
            );
        }
    }

    /**
     * Update payment details of an invoice
     *
     * @param {object} payload Payload
     * @param {Function} callback Callback function
     *
     * @yields {object} Update invoice payment response
     */
    *updateInvoicePayment({ payload, callback }) {
        try {
            const result = yield call(InvoiceApi.updateInvoicePayment, payload);
            yield put({ type: actionTypes.UPDATE_INVOICE_PAYMENT_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            callback && callback();
        } catch (errors) {
            yield put({ type: actionTypes.UPDATE_INVOICE_PAYMENT_FAILURE, errors });
            yield put(
                stopSubmit(
                    constants.EDIT_PAYMENT_HISTORY_FORM_NAME,
                    this.getApiErrors(
                        errors,
                        constants.INVOICE_PAYMENT_UPDATE_DEFAULT_ERROR,
                        constants.INVOICE_PAYMENT_UPDATE_DEFAULT_ERROR,
                        [PAID_ON]
                    )
                )
            );
        }
    }
}

//export the Invoice saga
export default new InvoiceSaga().forkAllWatcherFunctions();

/**
 * Order Payment Details Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import { React, PropTypes } from '../../../../../includes/exports/react';
import get from 'lodash/get';
import { CARD_NAME_MAPPING, PAYMENT_GATEWAY_MONOOVA_SLUG } from '../../../../../includes/constants';
import { find } from 'lodash';

/**
 * Invoice details component
 */
const OrderPaymentDetails = props => {
    const { orderDetails } = props;
    const paymentGateway = find(props.paymentGateways, { id: orderDetails.payment_gateway });

    return (
        <>
            {paymentGateway && (
                <li className="custom-form-like-field">
                    <header>{props.getLocalisedContent(`paymentMethod`, true)}</header>
                    <div className="custom-input readonly">
                        <span>{get(paymentGateway, 'label')}</span>
                    </div>
                </li>
            )}
            {get(orderDetails, 'payment_card.card_number') ? (
                <li className="custom-form-like-field">
                    <header>{props.getLocalisedContent(`paymentCard`, true)}</header>
                    <div className="custom-input readonly">
                        <span>{`${get(orderDetails, 'payment_card.card_number')}`}</span>
                        <div
                            className={`right-align card-brand ${
                                CARD_NAME_MAPPING[get(orderDetails, 'payment_card.brand')]
                            }`}
                        >
                            &nbsp;
                        </div>
                    </div>
                </li>
            ) : (
                get(paymentGateway, 'slug') === PAYMENT_GATEWAY_MONOOVA_SLUG && (
                    <li className="custom-form-like-field">
                        <header>How to make Payment</header>
                        <div className="readonly">
                            <ul style={{ marginLeft: '30px' }}>
                                <li style={{ listStyle: 'decimal' }}>Login to your Internet Banking Account</li>
                                <li style={{ listStyle: 'decimal' }}>Select Add a new payee.</li>
                                <li style={{ listStyle: 'decimal' }}>
                                    Under Add a new payment type, select BSB/Acc number.
                                </li>
                                <li style={{ listStyle: 'decimal' }}>Use the below details for transaction.</li>
                                <ul style={{ marginLeft: '30px' }}>
                                    <li style={{ listStyle: 'decimal' }}>
                                        Account Number:{' '}
                                        <b>{`${get(orderDetails, 'payment_method.customer_account_number')}`}</b>
                                    </li>
                                    <li style={{ listStyle: 'decimal' }}>
                                        BSB Number:{' '}
                                        <b>{`${get(orderDetails, 'payment_method.customer_bsb_number')}`}</b>
                                    </li>
                                    <li style={{ listStyle: 'decimal' }}>
                                        Email: <b>{`${get(orderDetails, 'payment_method.customer_email')}`}</b>
                                    </li>
                                    <li style={{ listStyle: 'decimal' }}>
                                        Reference Number:{' '}
                                        <b>{`${get(orderDetails, 'payment_method.customer_reference_number')}`}</b>
                                    </li>
                                </ul>
                            </ul>
                        </div>
                    </li>
                )
            )}
        </>
    );
};

// prop types
OrderPaymentDetails.propTypes = {
    getLocalisedContent: PropTypes.func,
    orderDetails: PropTypes.object,
    orderType: PropTypes.string,
    paymentGateways: PropTypes.array,
};

export default OrderPaymentDetails;

/**
 * Combines all the sagas and exports it to use in store
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required modules
import { all } from 'redux-saga/effects';
import * as sagas from '../index';

let allSagas = [];
let keys = Object.keys(sagas);
let key = '';
for (key of keys) {
    allSagas.push(sagas[key][0]);
}

/**
 * Root Saga method
 */
export default function* rootSaga() {
    yield all(allSagas);
}

import PropTypes from 'prop-types';
import React from 'react';
import DetailsPageDetailSection from 'components/DetailsPageDetailSection';
import Loader from 'components/Loader';
import {
    COMMON_PAYMENT_SERVICE_FORM_FIELDS,
    PAYMENT_SERVICE,
    PAYMENT_SERVICE_SLUG,
    PAYMENT_SERVICE_SPECIFIC_CHARGES_FIELDS,
    PAYMENT_SERVICE_SPECIFIC_FIELDS,
    getPaymentGatewaySlug,
} from 'components/PaymentGateways/constants';
import WhiteBoxWrapper from 'components/WhiteBoxWrapper';
import CountryLabel from 'components/CountryLabel';
import ServiceLabel from 'components/PaymentGateways/components/ServiceLabel';
import PaymentServiceInactiveBadge from 'components/PaymentGateways/components/PaymentServiceInactiveBadge';
import { ADDITIONAL_CHARGE_APPLY_FOR_OPTIONS, ORDER_TYPE } from 'includes/constants';
import { find } from 'lodash';
import {
    CARD_BRAND_LOGO_MAP,
    CARD_BRAND_NAME_MAP,
    GENERIC_CARD_LOGO,
} from 'components/customer/PaymentInformation/constants';
import ProviderLabel from 'components/PaymentGateways/components/ProviderLabel';
import { PAYFURL_CHARGES } from 'components/PaymentGateways/components/FormModal/components/PayFurlFields';
import { Tabs } from 'antd';
import PaymentServiceOrdersTable from 'components/PaymentServiceOrdersTable';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import { AUDIT_LOG } from 'includes/constants/permissions';
import AuditLogList from 'pages/AuditLogs/List';

import './styles.scss';

/**
 * Payment service details section
 *
 * @since 2.8.0
 */
export default function PaymentServiceDetailsSection({
    orderTypeSlug,
    organisationId,
    paymentServiceId,
    paymentServiceDetails,
    isLoading,
}) {
    const paymentGatewaySlug = getPaymentGatewaySlug(
        paymentServiceDetails?.parent_slug,
        paymentServiceDetails?.connected_via_oauth
    );

    const gatewayKeyFields = PAYMENT_SERVICE_SPECIFIC_FIELDS[paymentGatewaySlug] ?? [];

    const additionalCharges = paymentServiceDetails?.additional_transaction_charges ?? [];

    const provider = paymentServiceDetails?.provider;

    const chargesFields =
        paymentGatewaySlug === PAYMENT_SERVICE_SLUG.PayFurl
            ? PAYFURL_CHARGES
            : PAYMENT_SERVICE_SPECIFIC_CHARGES_FIELDS[paymentGatewaySlug] ?? [];

    const serviceLogo = provider ? provider?.logo : PAYMENT_SERVICE[paymentServiceDetails?.parent_slug]?.logo;

    return (
        <div className="flex flex-col gap-5">
            <WhiteBoxWrapper>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <div className="flex justify-between items-center">
                            <h3 className="font-semibold text-lg mb-4">General</h3>
                            <div>{paymentServiceDetails?.is_active ? null : <PaymentServiceInactiveBadge />}</div>
                        </div>
                        <div className="flex flex-col md:flex-row gap-2">
                            {serviceLogo ? (
                                <div className="flex flex-col gap-1 items-center">
                                    <img
                                        alt={paymentServiceDetails?.account_name}
                                        className="mx-8 mt-8 mb-3"
                                        src={serviceLogo}
                                        style={{ width: 50 }}
                                    />
                                    {provider ? <ProviderLabel name={provider?.name} color={provider?.color} /> : null}
                                    <ServiceLabel slug={paymentServiceDetails?.parent_slug} />
                                </div>
                            ) : null}
                            <div className="flex-grow grid grid-cols-2 md:grid-cols-4 gap-4 text-left my-5">
                                {COMMON_PAYMENT_SERVICE_FORM_FIELDS.map(field => {
                                    if (!paymentServiceDetails?.[field.name]) return null;

                                    return (
                                        <DetailsPageDetailSection
                                            key={field.name}
                                            label={field.label}
                                            value={paymentServiceDetails?.[field.name]}
                                        />
                                    );
                                })}
                                <DetailsPageDetailSection
                                    label={'Country'}
                                    value={<CountryLabel id={paymentServiceDetails?.country_id} />}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </WhiteBoxWrapper>

            {gatewayKeyFields.length > 0 ? (
                <WhiteBoxWrapper>
                    <div>
                        <h3 className="font-semibold text-lg mb-4">Gateway keys</h3>
                        <div className="flex-grow grid grid-cols-2 md:grid-cols-4 gap-4 text-left my-5">
                            {gatewayKeyFields.map(field => {
                                if (!paymentServiceDetails?.[field.name]) return null;

                                return (
                                    <DetailsPageDetailSection
                                        key={field.name}
                                        label={field.label}
                                        value={paymentServiceDetails?.[field.name]}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </WhiteBoxWrapper>
            ) : null}

            {provider ? (
                <WhiteBoxWrapper>
                    <div>
                        <h3 className="font-semibold text-lg mb-4">Gateway keys</h3>
                        <div className="flex-grow grid grid-cols-2 md:grid-cols-4 gap-4 text-left my-5">
                            {Object.entries(provider?.authentication_parameters).map(([field, { name }]) => {
                                if (!paymentServiceDetails?.extra_data?.authentication_parameters[field]) return null;

                                return (
                                    <DetailsPageDetailSection
                                        key={field}
                                        label={name}
                                        value={paymentServiceDetails?.extra_data?.authentication_parameters[field]}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </WhiteBoxWrapper>
            ) : null}

            {chargesFields.length > 0 ? (
                <WhiteBoxWrapper>
                    <div>
                        <h3 className="font-semibold text-lg mb-4">Charges</h3>
                        <div className="flex-grow grid grid-cols-2 md:grid-cols-4 gap-4 text-left my-5">
                            {chargesFields.map(field => {
                                if (!paymentServiceDetails?.[field.name]) return null;

                                return (
                                    <DetailsPageDetailSection
                                        key={field.name}
                                        label={field.label}
                                        value={paymentServiceDetails?.[field.name]}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </WhiteBoxWrapper>
            ) : null}

            {additionalCharges.length > 0 ? (
                <WhiteBoxWrapper>
                    <div>
                        <h3 className="font-semibold text-lg mb-4">Additional transaction charges</h3>
                        <div className="flex-grow grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 text-left my-5">
                            {additionalCharges.map(rec => {
                                const applyForLabel = find(ADDITIONAL_CHARGE_APPLY_FOR_OPTIONS, {
                                    value: rec.apply_for,
                                })?.label;

                                const brandLogo = CARD_BRAND_LOGO_MAP[rec.brand_name] ?? GENERIC_CARD_LOGO;

                                const brandName = CARD_BRAND_NAME_MAP[rec.brand_name] || 'Card';

                                const transactionCharge = rec.transaction_charge;

                                return (
                                    <div
                                        key={rec.brand_name}
                                        className="flex justify-between gap-1 rounded-md border border-solid border-gray-100 p-4"
                                    >
                                        <div>
                                            <h3 className="text-gray-400 uppercase text-sm">{applyForLabel}</h3>
                                            <p>{transactionCharge}%</p>
                                        </div>
                                        <div className="flex flex-col items-end">
                                            <img src={brandLogo} alt={brandName} style={{ width: 50 }} />
                                            <p className="text-sm text-right">{brandName}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </WhiteBoxWrapper>
            ) : null}

            <div className="full-wrapper box-wrapper white-box-wrapper tab-wrapper bottom-section full-width-100">
                <Tabs>
                    <Tabs.TabPane tab="Orders" key="1">
                        <PaymentServiceOrdersTable
                            orderTypeSlug={orderTypeSlug}
                            organisationId={organisationId}
                            paymentServiceId={paymentServiceId}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="2" tab="Audit Logs">
                        <PermissionGuardLayout requiredPermission={AUDIT_LOG.LIST} layout="section" showFallbackUI>
                            <AuditLogList
                                customDataValues={{ payment_gateway_id: paymentServiceId }}
                                showTitle={false}
                            />
                        </PermissionGuardLayout>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
}

PaymentServiceDetailsSection.propTypes = {
    isLoading: PropTypes.bool,
    orderTypeSlug: PropTypes.oneOf(Object.values(ORDER_TYPE)),
    organisationId: PropTypes.string,
    paymentServiceDetails: PropTypes.shape({
        account_name: PropTypes.string,
        additional_transaction_charges: PropTypes.array,
        connected_via_oauth: PropTypes.bool,
        country_id: PropTypes.string,
        extra_data: PropTypes.shape({
            authentication_parameters: PropTypes.object,
        }),
        is_active: PropTypes.bool,
        parent_slug: PropTypes.string,
        provider: PropTypes.shape({
            authentication_parameters: PropTypes.object,
            color: PropTypes.string,
            logo: PropTypes.string,
            name: PropTypes.string,
        }),
    }),
    paymentServiceId: PropTypes.string,
};

/**
 * Dashboard Component
 * Handles the dashboard page view and functions related to loading dashboard
 *
 * @version 1.0
 */

//import required modules
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { get, find } from 'lodash';
import { Icon, Cascader, Breadcrumb, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import * as errorMessages from 'includes/constants/messages/errors';
import * as responseKeys from 'includes/constants/keys/response';
import Button from '../../shared/Button';
import HasEditPermission from '../../HasEditPermission';
import InfoMessage from '../../shared/messages/InfoMessage';
import SearchBlock from '../../shared/SearchBlock';
import Table from '../../shared/ScrollableTable';
import { redirect } from 'includes/utils';
import { getContactInvoiceStatusTypeData } from 'includes/redux/actions/customer/organisation';
import { getContactTableColumnProps } from 'includes/constants/columns';
import { getOrganisationDetails } from 'includes/redux/actions/customer/organisation';
import { useCurrentRoleSlug, useUpdateBusinessName } from 'includes/utils/hooks';
import {
    applyContactType as _applyContactType,
    deleteContact as _deleteContact,
    getContacts,
    removeContactsLoading,
    restoreContact as _restoreContact,
} from 'includes/slices/contact';
import {
    MANUAL,
    MYOB_ESSENTIALS_SLUG,
    USER_ROLE_ADMIN,
    USER_ROLE_ADMIN_MANAGER,
    XERO_ACCOUNTING_SOFTWARE_SLUG,
} from 'includes/constants';
import './styles.scss';
import ExportButton from '../../shared/Exports/button';
import useEnforceAddOrganisation from 'includes/hooks/useEnforceAddOrganisation';
import PermissionGuard from 'components/PermissionGuard';
import { CONTACT, CONTACT_MANAGER } from 'includes/constants/permissions';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import useContactTypes from 'includes/hooks/useContactTypes';

const { confirm, error } = Modal;

/**
 * Contact Listing Component
 * Render the contact listing component
 *
 * @returns    {string}    Contact listing view
 */
const Contacts = props => {
    /**
     * Set state
     */
    const [searchParam, setSearchParam] = useState('');
    const [isArchivingContact, setIsArchivingContact] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const [contactIds, setContactIds] = useState([]);
    const [contactType, setContactType] = useState([]);
    const [tableFilters, setTableFilters] = useState({});

    const dispatch = useDispatch();
    const history = useHistory();
    const getNumberFormattedAsCurrency = useCurrencyFormatter();

    const detailsUpdatedMessage = useSelector(state => state.contacts.detailsUpdatedMessage);
    const contacts = useSelector(state => state.contacts.contacts);
    const paginationContacts = useSelector(state => state.contacts.paginationContacts);
    const isDeletingContact = useSelector(state => state.contacts.isDeletingContact);
    const isRestoringContact = useSelector(state => state.contacts.isRestoringContact);
    const isAdmin = useSelector(state => state.account.isAdmin);
    const isLoading = useSelector(state => state.contacts.isLoading);
    const _organisationId = useSelector(state => state.organisation.selectedOrganisationId);
    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);

    const currentRoleSlug = useCurrentRoleSlug();

    const organisationId = props.match.params.organisationId || _organisationId;
    const shouldDisableApisWithoutOrganisationId = !organisationId ? true : false;

    const { accountId } = props.match.params;

    const { isContactManager } = props;

    const fetchContacts = useEnforceAddOrganisation((organisationId, searchParams = {}) => {
        if (get(props, 'mode') === 'contact-manager-contacts' && get(props, 'contactManageId')) {
            searchParams['parent_contact_id'] = get(props, 'contactManageId');
        }
        if (isContactManager) searchParams.is_manager = true;
        dispatch(getContacts(organisationId, searchParams));
    });

    // to trigger API call when switched between contact and contact-manager
    useEffect(() => {
        fetchContacts(organisationId);
    }, [isContactManager]); // eslint-disable-line react-hooks/exhaustive-deps

    // check if user has updated the business name
    useUpdateBusinessName();

    useEffect(() => {
        if ('is_manual' in selectedOrganisation && isContactManager && !selectedOrganisation.is_manual)
            history.replace('/page-not-found');
    }, [selectedOrganisation]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use Effect hook
     */
    useEffect(() => {
        setContactIds([]);
        if (organisationId) {
            dispatch(getContactInvoiceStatusTypeData(organisationId));
        } else {
            dispatch(removeContactsLoading());
        }
        get(selectedOrganisation, 'id') !== organisationId && dispatch(getOrganisationDetails(organisationId));
    }, [organisationId]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use Effect hook
     */
    useEffect(() => {
        setContactIds([]);
        if (detailsUpdatedMessage) {
            if (organisationId) {
                dispatch(getContactInvoiceStatusTypeData(organisationId));
                // dispatch(getContacts(organisationId));
                fetchContacts(organisationId);
            } else {
                dispatch(removeContactsLoading());
            }
        }
    }, [detailsUpdatedMessage]); // eslint-disable-line react-hooks/exhaustive-deps

    const { data: contactTypes, isLoading: isContactTypesLoading } = useContactTypes(
        shouldDisableApisWithoutOrganisationId
    );

    const accountingSoftwareSlug = get(selectedOrganisation, ['accounting_software_data', 'slug']);

    /**
     * Apply the contact type for a contact or contact ids
     *
     * @param {Array} ids Array of contact ids
     * @param {string} type Contact type to be applied
     */
    const applyContactType = (ids = [], type) =>
        ids.length === 0
            ? error({
                  title: 'Error',
                  content: 'Please select at least one contact.',
              })
            : dispatch(
                  _applyContactType(organisationId, { ids: ids, type: type, is_manager: isContactManager }, () => {
                      setContactIds([]);
                  })
              );

    /**
     * Delete contact
     * Change the status of the contact to archive or inactive or permanently delete the contact
     *
     * @param {string} contactId Id of the contact to be deleted
     * @param {boolean} forceDelete Whether to force delete or not
     */
    const deleteContact = (contactId, forceDelete = false) => {
        const localePath = isContactManager ? 'ContactManager' : 'Contact';
        confirm({
            title: props.t(
                !forceDelete
                    ? `customerMessages.contact.confirm.archive${localePath}`
                    : `customerMessages.contact.confirm.delete${localePath}`
            ),
            content: '',
            onOk: () => {
                if (forceDelete) {
                    setIsArchivingContact(false);
                }
                dispatch(_deleteContact(organisationId, contactId, forceDelete));
            },
        });
    };

    const urlNamespace = isContactManager ? 'contact-manager' : 'contact';

    /**
     * Get contact view link based on logged in user's role
     *
     * @param {object} contact contact object
     */
    const getViewLink = contact => {
        switch (currentRoleSlug) {
            case USER_ROLE_ADMIN:
            case USER_ROLE_ADMIN_MANAGER:
                return `/admin/accounts/organisation/${urlNamespace}/${accountId}/${organisationId}/${contact.id}`;
            default:
                return `/${urlNamespace}/${contact.id}`;
        }
    };
    /**
     * Modify and return the contact data
     */
    const formatContactsData = useCallback(
        () =>
            contacts.map(contact => {
                const amountDue = contact.amount_due;
                const amountPaid = contact.amount_paid;

                return {
                    ...contact,
                    amount_due: (
                        <span title={getNumberFormattedAsCurrency(amountDue.amount)}>
                            {getNumberFormattedAsCurrency(amountDue.amount_short)}
                        </span>
                    ),
                    amount_paid: (
                        <span title={getNumberFormattedAsCurrency(amountPaid.amount)}>
                            {getNumberFormattedAsCurrency(amountPaid.amount_short)}
                        </span>
                    ),
                    oldest_invoice_days_overdue:
                        contact?.oldest_invoice_days_overdue > 0 ? contact?.oldest_invoice_days_overdue : '',
                    first_name: (
                        <>
                            <div className="business-name-avatar-wrapper" style={{ width: '250px' }}>
                                <img
                                    className="logo logo-45"
                                    src={
                                        get(contact, responseKeys.CONTACT_AVATAR_254x254) ||
                                        require('../../../assets/images/customer.svg')
                                    }
                                    alt="contact-avatar"
                                />
                                <div className="action-wrapper">
                                    <PermissionGuard
                                        requiredPermission={CONTACT.VIEW}
                                        fallback={`${contact.first_name} ${contact.last_name}`}
                                    >
                                        <button
                                            className="btn-link text-left"
                                            onClick={() => redirect(getViewLink(contact))}
                                        >
                                            {`${contact.first_name} ${contact.last_name}`}
                                        </button>
                                    </PermissionGuard>
                                    <span className="crud-wrapper">
                                        <PermissionGuard requiredPermission={CONTACT.VIEW}>
                                            <button
                                                className="crud-link"
                                                onClick={() => redirect(getViewLink(contact))}
                                            >
                                                {props.t('customerMessages.table.actions.label.view')}
                                            </button>
                                        </PermissionGuard>
                                        {accountingSoftwareSlug !== MYOB_ESSENTIALS_SLUG && (
                                            <>
                                                <PermissionGuard
                                                    requiredPermission={
                                                        isContactManager ? CONTACT_MANAGER.DELETE : CONTACT.DELETE
                                                    }
                                                >
                                                    <button
                                                        className="crud-link"
                                                        onClick={() => {
                                                            contact.is_deleted
                                                                ? restoreContact(contact.id)
                                                                : deleteContact(contact.id);
                                                        }}
                                                    >
                                                        {props.t(
                                                            contact.is_deleted
                                                                ? 'customerMessages.table.actions.label.restore'
                                                                : 'customerMessages.table.actions.label.archive'
                                                        )}
                                                    </button>
                                                </PermissionGuard>
                                                {accountingSoftwareSlug !== XERO_ACCOUNTING_SOFTWARE_SLUG &&
                                                    contact.is_deleted && (
                                                        <PermissionGuard requiredPermission={CONTACT.DELETE}>
                                                            <button
                                                                onClick={() => {
                                                                    deleteContact(contact.id, true);
                                                                }}
                                                                className="crud-link"
                                                            >
                                                                {props.t(
                                                                    'customerMessages.table.actions.label.deletePermanently'
                                                                )}
                                                            </button>
                                                        </PermissionGuard>
                                                    )}
                                            </>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </>
                    ),
                    business_name: (
                        <PermissionGuard requiredPermission={CONTACT.VIEW}>{contact.business_name}</PermissionGuard>
                    ),
                    action: amountDue.amount > 0 && (
                        <Link to={`/debtor/invoices/payment/${organisationId}/${contact.id}/contact-invoices`}>
                            Pay
                        </Link>
                    ),
                    type: get(find(contactTypes, { id: contact.type }), 'type'),
                    is_trash: contact.is_deleted ? 'Archived' : 'Active',
                };
            }),
        [contacts, contactTypes] // eslint-disable-line react-hooks/exhaustive-deps
    );

    /**
     * Get the locale text
     *
     * @param {string} path Path for which locale is to be retrieved
     *
     * @returns {string} Locale test
     */
    const getLocaleText = path => props.t(`customerMessages.contact.${path}`);

    /**
     * Memoized contacts data
     */
    const memoizedContactsData = useMemo(() => formatContactsData(), [formatContactsData]);

    /**
     * Restore a contact
     * Restore the contact from archived or inactive state
     *
     * @param {string} contactId Id of the contact to be deleted
     */
    const restoreContact = contactId => {
        confirm({
            title: props.t('customerMessages.contact.confirm.restoreContact'),
            content: '',
            onOk: () => {
                dispatch(_restoreContact(organisationId, contactId));
            },
        });
    };

    /**
     * Select row
     */
    const rowSelection = {
        selectedRowKeys: contactIds,
        onChange: selectedRowKeys => {
            setContactIds(selectedRowKeys);
        },
    };

    /**
     * Render the component
     */
    return (
        <div className="home-content-wrapper accounts-wrapper contacts-wrapper">
            {isAdmin && (
                <div className="tab-arrow-link breadcrumb-spec">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to={`/admin/accounts/organisations/${accountId}`}>
                                {getLocaleText('breadcrumb.organisations')}
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            {getLocaleText(`breadcrumb.${isContactManager ? 'contactManagers' : 'contacts'}`)}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            )}
            {get(props, 'mode') !== 'contact-manager-contacts' && (
                <h2 className="page-title">{getLocaleText(isContactManager ? 'contactManagerTitle' : 'title')}</h2>
            )}
            <div
                className={`search-filter-export-wrapper contact-list-container ${
                    get(props, 'mode') !== 'contact-manager-contacts'
                        ? 'full-wrapper box-wrapper white-box-wrapper'
                        : ''
                }`}
            >
                <section className="right-menu-wrapper">
                    <div className="right-align">
                        {organisationId && (
                            <>
                                <ExportButton
                                    exportType={isContactManager ? 'contact-managers' : 'contacts'}
                                    queryParams={{ contact_ids: contactIds, ...tableFilters }}
                                />
                                {props.showActionButtons && (
                                    <span>
                                        {get(selectedOrganisation, 'is_manual', '') && (
                                            <PermissionGuard
                                                requiredPermission={
                                                    isContactManager ? CONTACT_MANAGER.IMPORT : CONTACT.IMPORT
                                                }
                                            >
                                                <Button
                                                    className="green-bg-button"
                                                    filled
                                                    onClick={() =>
                                                        redirect(
                                                            isAdmin
                                                                ? isContactManager
                                                                    ? `/admin/accounts/organisation/import/contact-managers/${accountId}/${organisationId}`
                                                                    : `/admin/accounts/organisation/import/contacts/${accountId}/${organisationId}`
                                                                : isContactManager
                                                                ? '/contact-manager-import'
                                                                : '/contact-import'
                                                        )
                                                    }
                                                >
                                                    Import <Icon type="upload" />
                                                </Button>
                                            </PermissionGuard>
                                        )}
                                        {isContactManager ? (
                                            <PermissionGuard requiredPermission={CONTACT_MANAGER.ADD}>
                                                <Button
                                                    big
                                                    filled
                                                    onClick={() =>
                                                        redirect(
                                                            isAdmin
                                                                ? `/admin/accounts/organisation/contact-managers/${accountId}/${organisationId}/add`
                                                                : '/add-contact-manager'
                                                        )
                                                    }
                                                >
                                                    {getLocaleText('addContactManager')}
                                                </Button>
                                            </PermissionGuard>
                                        ) : (
                                            accountingSoftwareSlug === MANUAL && (
                                                <PermissionGuard requiredPermission={CONTACT.ADD}>
                                                    <Button
                                                        big
                                                        filled
                                                        onClick={() =>
                                                            redirect(
                                                                isAdmin
                                                                    ? `/admin/accounts/organisation/contacts/${accountId}/${organisationId}/add`
                                                                    : '/add-contact'
                                                            )
                                                        }
                                                    >
                                                        {getLocaleText('addContact')}
                                                    </Button>
                                                </PermissionGuard>
                                            )
                                        )}
                                    </span>
                                )}
                            </>
                        )}
                    </div>
                </section>
                <section>
                    <ul className="selector-field">
                        <li>
                            {props.showActionButtons && (
                                <span>
                                    <div className="selector-title">Contact Type</div>
                                    <Cascader
                                        className="cascader"
                                        options={contactTypes.map(contactType => ({
                                            label: contactType.type,
                                            value: contactType.id,
                                        }))}
                                        onChange={val => setContactType(val)}
                                    />{' '}
                                    <span className="apply-btn-wrapper">
                                        <HasEditPermission onClick={() => applyContactType(contactIds, contactType[0])}>
                                            <Button filled disabled={contactType.length === 0} className="apply-btn">
                                                {getLocaleText('applyButtonLabel')}
                                            </Button>
                                        </HasEditPermission>
                                    </span>
                                </span>
                            )}
                            <div className="right-align">
                                <SearchBlock
                                    dataValues={[organisationId]}
                                    getDataMethod={
                                        organisationId
                                            ? (...args) => fetchContacts(...args)
                                            : () => dispatch(removeContactsLoading())
                                    }
                                    isSearching={isSearching}
                                    placeholder={props.t(
                                        `customerMessages.contact.search.placeholder.${
                                            isContactManager ? 'contactManager' : 'contact'
                                        }`
                                    )}
                                    setIsSearching={setIsSearching}
                                    setSearchParam={setSearchParam}
                                    setTableFilters={setTableFilters}
                                />
                            </div>
                        </li>
                    </ul>
                </section>
                <hr />
                <Table
                    key={`${isContactManager}`}
                    enableSelectAll
                    rowSelection={rowSelection}
                    columns={getContactTableColumnProps({ contactTypes })}
                    collapseHeaderKeys={['business_name', 'email', 'amount_due']}
                    dataSource={memoizedContactsData}
                    dataValues={[organisationId]}
                    getDataMethod={(...args) =>
                        organisationId ? fetchContacts(...args) : dispatch(removeContactsLoading(...args))
                    }
                    isSearching={isSearching}
                    loading={isLoading || isContactTypesLoading}
                    localeMessage={
                        isContactManager
                            ? errorMessages.TABLE_NO_CONTACT_MANAGERS_FOUND_MESSAGE
                            : errorMessages.TABLE_NO_CONTACTS_FOUND_MESSAGE
                    }
                    paginationData={paginationContacts}
                    rowClassName="pointer"
                    rowKey="id"
                    searchParam={searchParam}
                    setIsSearching={setIsSearching}
                    setTableFilters={setTableFilters}
                    size="middle"
                    // {...props}
                    className="table-1200"
                    mobileClassName="table-1200"
                />
                <Modal
                    footer={[]}
                    className="my_profile_modal organisation-reauthorization-modal"
                    visible={isDeletingContact || isRestoringContact}
                >
                    <InfoMessage
                        message={props.t(
                            isRestoringContact
                                ? `customerMessages.contact.loading.message.restoring${
                                      isContactManager ? 'ContactManager' : 'Contact'
                                  }`
                                : isArchivingContact
                                ? `customerMessages.contact.loading.message.archiving${
                                      isContactManager ? 'ContactManager' : 'Contact'
                                  }`
                                : `customerMessages.contact.loading.message.deleting${
                                      isContactManager ? 'ContactManager' : 'Contact'
                                  }`
                        )}
                        showLoader={true}
                    />
                </Modal>
            </div>
        </div>
    );
};

/**
 * Proptypes
 */
Contacts.propTypes = {
    match: PropTypes.object,
    isContactManager: PropTypes.bool,
    showActionButtons: PropTypes.bool,
    showGraphAndCount: PropTypes.bool,
    t: PropTypes.func,
};

// default props
Contacts.defaultProps = {
    contactTypes: [],
    showActionButtons: true,
    showGraphAndCount: false,
};

export default withTranslation()(Contacts);

/**
 * Defines the deep links with the accounting software
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */
export const ACCOUNTING_SOFTWARE_DEEP_LINKS = {
    xero: {
        organisation: 'https://go.xero.com/organisationlogin/default.aspx?shortcode=short_code',
        contact:
            'https://go.xero.com/organisationlogin/default.aspx?shortcode=short_code&redirecturl=/Contacts/View/contact_id',
        invoice:
            'https://go.xero.com/organisationlogin/default.aspx?shortcode=short_code&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=invoice_id',
        payment:
            'https://go.xero.com/organisationlogin/default.aspx?shortcode=short_code&redirecturl=/Bank/ViewTransaction.aspx?bankTransactionID=payment_id',
    },
};

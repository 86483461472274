/**
 * Contact edit Component
 * Renders the contact edit
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

// import required modules
import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { get, isString, find } from 'lodash';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Breadcrumb } from 'antd';

import { objectToFormData } from 'includes/utils/form';
import AddEditContactForm from '../../shared/forms/AddEditContact';
import * as requestKeys from 'includes/constants/keys/request';
import { getContactDetails, updateContactDetails, addContact, getContacts } from 'includes/slices/contact';
import * as constants from 'includes/constants';
import { redirect } from 'includes/utils';
import { useUpdateBusinessName, useCheckEditPermission } from 'includes/utils/hooks';
import './details-styles.scss';
import Loader from '../../shared/Loader';
import { MANUAL } from 'includes/constants';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useToFEDateFormatter from 'includes/hooks/useToFEDateFormatter';
import useToBEDateFormatter from 'includes/hooks/useToBEDateFormatter';
import useContactTypes from 'includes/hooks/useContactTypes';

const AddEditContact = props => {
    // state to handle profile image picker modal visibility
    const [imagePickerVisible, setImagePickerVisible] = useState(false);

    const [assignedContactsNameMap, setAssignedContactsNameMap] = useState({});

    const accountingSoftwareSlug = useSelector(state =>
        get(state.organisation.selectedOrganisation, 'accounting_software_data.slug')
    );
    const allow_international_calls = useSelector(state =>
        get(state.account, 'accountDetails.subscription.plan.allow_international_calls')
    );
    const allow_international_sms = useSelector(state =>
        get(state.account, 'accountDetails.subscription.plan.allow_international_sms')
    );
    const contacts = useSelector(state => state.contacts.contacts);
    const contactDetails = useSelector(state => state.contacts.contactDetails);
    // const contactTypes = useSelector(state => state.contacts.types);
    // const defaultUserAvatar = useSelector(state =>
    //     get(state.settings.siteSettings, responseKeys.DEFAULT_USER_AVATAR_254x254)
    // );
    // const detailsUpdatedMessage = useSelector(state => state.contacts.detailsUpdatedMessage);
    const inputEmail = useSelector(state =>
        get(state, ['form', constants.EDIT_CONTACT_FORM_NAME, 'values', requestKeys.EMAIL])
    );
    const inputMobileNumber = useSelector(state =>
        get(state, ['form', constants.EDIT_CONTACT_FORM_NAME, 'values', requestKeys.MOBILE_NUMBER])
    );
    const inputParentContact = useSelector(state =>
        get(state, ['form', constants.EDIT_CONTACT_FORM_NAME, 'values', requestKeys.CONTACT_MANAGER])
    );
    const assignedContactsValue = useSelector(state =>
        state.form.edit_contact_form ? state.form.edit_contact_form.values[requestKeys.CHILD_CONTACTS] : []
    );
    const isAdmin = useSelector(state => state.account.isAdmin);
    const isDetailsUpdating = useSelector(state => state.contacts.isDetailsUpdating);
    const isDemoEmailUpdated = useSelector(state => get(state.contacts.contactDetails, 'is_demo_email_updated'));
    const isDemoMobileNumberUpdated = useSelector(state =>
        get(state.contacts.contactDetails, 'is_demo_mobile_number_updated')
    );
    const isLoading = useSelector(state => state.contacts.isDetailsLoading);
    const isTestAccount = useSelector(state => state.account.accountDetails.is_test_account);
    const organisationPhoneCode = useSelector(state => get(state.organisation, 'selectedOrganisation.phone_code'));
    const isManualOrganisation = useSelector(state => get(state.organisation, 'selectedOrganisation.is_manual', ''));

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const params = useParams();

    const organisationId = useOrganisationId();

    const convertDateStringToFEFormat = useToFEDateFormatter();

    const convertDateStringToBEAcceptableFormat = useToBEDateFormatter();

    const { isContactManager } = props;

    // auto complete for contact select
    const autoSuggestContacts = value =>
        dispatch(getContacts(organisationId, { search_param: value, is_manager: !isContactManager }));

    const { contactId, accountId } = params;

    const isAdd = props.action === 'add';

    const urlNamespace = isContactManager ? 'contact-manager' : 'contact';

    const toggleImagePicker = () => setImagePickerVisible(!imagePickerVisible);

    // effect to fetch contact details
    useEffect(() => {
        get(contactDetails, 'id') !== contactId && !isAdd && dispatch(getContactDetails(organisationId, contactId));
    }, [contactDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    // initially fetch contacts list for assigned_contacts field in the form
    useEffect(() => {
        dispatch(getContacts(organisationId, { is_manager: !isContactManager }));
    }, [organisationId]); // eslint-disable-line react-hooks/exhaustive-deps

    useUpdateBusinessName();

    useCheckEditPermission();

    const { data: contactTypes, isLoading: isContactTypesLoading } = useContactTypes();

    useEffect(() => {
        const newNameMap = {};
        if (get(contactDetails, 'child_contacts', []).length > 0) {
            contactDetails.child_contacts.forEach(c => {
                newNameMap[c.id] = c.name;
            });
            setAssignedContactsNameMap(newNameMap);
        }
    }, [contactDetails]);

    useEffect(() => {
        if ((assignedContactsValue || []).length > 0) {
            const newValues = { ...assignedContactsNameMap };
            assignedContactsValue &&
                assignedContactsValue.forEach(val => {
                    if (!(val in newValues)) {
                        const contact = find(contacts, { id: val });
                        newValues[val] =
                            get(contact, 'business_name') ||
                            `${get(contact, 'first_name')} ${get(contact, 'last_name')}`;
                    }
                });
            setAssignedContactsNameMap(newValues);
        }
    }, [assignedContactsValue]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Submit handler function for handling edit contact form
     */
    const handleSubmit = values => {
        // the values object may contain the url of the user avatar if no new avatar is uploaded,
        // we don't need it to submit with the form remove it from the object
        if (requestKeys.AVATAR in values && isString(get(values, requestKeys.AVATAR))) {
            delete values[requestKeys.AVATAR];
        }

        // the values object may contain the url of the user avatar if no new avatar is uploaded,
        // we don't need it to submit with the form remove it from the object
        if (requestKeys.PARENT_CONTACT in values) {
            delete values[requestKeys.PARENT_CONTACT];
        }

        // to remove the empty value from assigned_contacts list
        values[requestKeys.CHILD_CONTACTS] = values[requestKeys.CHILD_CONTACTS]
            ? values[requestKeys.CHILD_CONTACTS].filter(val => !!val)
            : [];

        // append manager = true for contact managers
        if (isContactManager) values.is_manager = true;

        const dateValues = {};

        // convert date to YYYY-MM-DD format (that's what the backend accepts)
        dateValues[requestKeys.CONTRACT_START_DATE] = convertDateStringToBEAcceptableFormat(
            values[requestKeys.CONTRACT_START_DATE]
        );
        dateValues[requestKeys.CONTRACT_END_DATE] = convertDateStringToBEAcceptableFormat(
            values[requestKeys.CONTRACT_END_DATE]
        );
        dateValues[requestKeys.CONTACT_DATE_OF_BIRTH] = convertDateStringToBEAcceptableFormat(
            values[requestKeys.CONTACT_DATE_OF_BIRTH]
        );

        const payload = objectToFormData({ ...values, ...dateValues }, [requestKeys.CONTACT_PERSONS]);

        isAdd
            ? dispatch(
                  addContact(payload, organisationId, () =>
                      redirect(
                          isAdmin
                              ? `/admin/accounts/organisation/${urlNamespace}s/${accountId}/${organisationId}`
                              : `/${urlNamespace}s`
                      )
                  )
              )
            : dispatch(
                  updateContactDetails(payload, get(contactDetails, 'id'), organisationId, () =>
                      redirect(
                          isAdmin
                              ? `/admin/accounts/organisation/${urlNamespace}/${accountId}/${organisationId}/${get(
                                    contactDetails,
                                    'id'
                                )}`
                              : `/${urlNamespace}/${get(contactDetails, 'id')}`
                      )
                  )
              );
    };

    const renderBreadcrumb = () => {
        let contactName = 'Contact'; // default

        if (contactDetails?.business_name) contactName = contactDetails?.business_name;
        else if (`${contactDetails.first_name || ''} ${contactDetails.last_name || ''}`.trim())
            contactName = `${contactDetails.first_name || ''} ${contactDetails.last_name || ''}`;

        const breadcrumbLocale = path => t(`customerMessages.editContact.breadcrumb.${path}`);
        const nameSpace = isContactManager ? 'ContactManager' : 'Contact';
        let breadCrumbList = [];
        if (isAdmin) {
            breadCrumbList = [
                <Breadcrumb.Item key="0">
                    <Link to={`/admin/accounts/organisations/${accountId}`}>{breadcrumbLocale('organisations')}</Link>
                </Breadcrumb.Item>,
                <Breadcrumb.Item key="1">
                    <Link
                        to={`/admin/accounts/organisation/${
                            isContactManager ? 'contact-managers' : 'contacts'
                        }/${accountId}/${organisationId}`}
                    >
                        {breadcrumbLocale(isContactManager ? 'contactManagers' : 'contacts')}
                    </Link>
                </Breadcrumb.Item>,
            ];
        } else {
            breadCrumbList = [
                <Breadcrumb.Item key="0">
                    <Link to={isContactManager ? '/contact-managers' : '/contacts'}>
                        {breadcrumbLocale(isContactManager ? 'contactManagers' : 'contacts')}
                    </Link>
                </Breadcrumb.Item>,
            ];
            if (!isAdd)
                breadCrumbList.push(
                    <Breadcrumb.Item key="1">
                        <Link to={`/${isContactManager ? 'contact-manager' : 'contact'}/${contactId}`}>
                            {contactName}
                        </Link>
                    </Breadcrumb.Item>
                );
            breadCrumbList.push(
                <Breadcrumb.Item key="2">
                    {breadcrumbLocale(isAdd ? `add${nameSpace}` : `edit${nameSpace}`)}
                </Breadcrumb.Item>
            );
        }
        return <Breadcrumb>{breadCrumbList}</Breadcrumb>;
    };

    const getFormProps = () => ({
        isDemoMobileNumberUpdated,
        inputMobileNumber,
        organisationPhoneCode,
        isTestAccount,
        allow_international_calls,
        allow_international_sms,
        isDemoEmailUpdated,
        inputEmail,
        isManualOrganisation,
        isDetailsUpdating,
        isContactManager,
        assignedContactsValue,
        assignedContactsNameMap,
        inputParentContact,
    });

    const localeNamespace = isContactManager ? 'ContactManager' : 'Contact';

    const initialValues = useMemo(() => {
        const newContactDetails = { ...contactDetails };
        delete newContactDetails.contact_cc;
        const {
            contract_start_date: contractStartDate,
            contract_end_date: contractEndDate,
            date_of_birth: dateOfBirth,
        } = newContactDetails;
        return {
            ...newContactDetails,
            child_contacts: newContactDetails.child_contacts ? newContactDetails.child_contacts.map(c => c.id) : [],
            parent_contact_id: get(newContactDetails, 'parent_contact.id', ''),
            parent_contact_init_option: {
                name: get(newContactDetails, 'parent_contact.name', ''),
                value: get(newContactDetails, 'parent_contact.id', ''),
            },
            contract_start_date: convertDateStringToFEFormat(contractStartDate),
            contract_end_date: convertDateStringToFEFormat(contractEndDate),
            date_of_birth: convertDateStringToFEFormat(dateOfBirth),
        };
    }, [contactDetails]);

    return (
        <div className="home-content-wrapper edit-profile-wrapper">
            <div className="tab-arrow-link">{renderBreadcrumb()}</div>
            <h2 className="page-title">
                {t(
                    `customerMessages.editContact.pageTitle.${
                        isAdd ? `add${localeNamespace}` : `edit${localeNamespace}`
                    }`
                )}
            </h2>
            <div className="white-box-wrapper mid-wrapper">
                {isLoading || isContactTypesLoading ? (
                    <Loader tip={t('appCommonMessages.loadingMessage')} />
                ) : accountingSoftwareSlug !== MANUAL && isAdd ? (
                    <h3>{t(`customerMessages.editContact.form.message.accountingSoftwareAddContactNotAllowed`)}</h3>
                ) : (
                    <AddEditContactForm
                        initialValues={isAdd ? {} : initialValues}
                        onSubmit={handleSubmit}
                        onImageSubmit={image => {
                            dispatch(change(constants.EDIT_CONTACT_FORM_NAME, requestKeys.AVATAR, image));
                            toggleImagePicker();
                        }}
                        onCancel={toggleImagePicker}
                        imagePickerVisible={imagePickerVisible}
                        toggleImagePicker={toggleImagePicker}
                        defaultImage={require('../../../assets/images/user-avatar.svg')}
                        isAdd={isAdd}
                        enableReinitialize
                        contactTypes={contactTypes}
                        autoSuggestContacts={autoSuggestContacts}
                        contacts={contacts}
                        {...getFormProps()}
                    />
                )}
            </div>
        </div>
    );
};

AddEditContact.propTypes = {
    action: PropTypes.string,
    isContactManager: PropTypes.bool,
};

export default AddEditContact;

/**
 * Edit Campaign Form
 * Renders the edit Campaign form
 * This component is a shared component which can be used in different modules
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */
//import required modules
import { React, useState, useEffect, useCallback } from '../../../includes/exports/react';
import { Form, Row, Col } from 'antd';
import { get, find, isEmpty } from 'lodash';
import Button from '../Button';
import * as constants from '../../../includes/constants';
import * as formValidations from '../../../includes/utils/form';
import FormField from '../FormField';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Field, reduxForm } from 'redux-form';
import * as requestKeys from '../../../includes/constants/keys/request';
import EmailFields from '../Templates/Email/EmailFields';
import IvrFields from '../../shared/Templates/Ivr/IvrFields';
import Preview from '../../shared/Templates/Preview';
import SmsFields from '../../shared/Templates/Sms/SmsFields';

/**
 * Add Edit Campaign form Component
 */
const AddEditCampaign = props => {
    const [message, setMessage] = useState(get(props, 'initialValues.message', ''));
    const [openPreview, setOpenPreview] = useState(false);
    const [selectedNotificationTypeId, setSelectedNotificationTypeId] = useState('');
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [selectedTemplateTypeId, setSelectedTemplateTypeId] = useState('');

    // set the initial values
    const notificationTypeId = get(props, `initialValues.${requestKeys.NOTIFICATION_TYPE_ID}`, '');
    const templateTypeId = get(props, `initialValues.${requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID}`, '');
    const templateId = get(props, `initialValues.${requestKeys.COMMUNICATION_TEMPLATE_ID}`, '');

    const { accountId, change, templateDetails } = props;
    const organisationId = props.organisationId
        ? props.organisationId
        : get(props, ['initialValues', 'organisation_id'], '');

    /**
     * Use effect to set the default values
     */
    useEffect(() => {
        notificationTypeId && setSelectedNotificationTypeId(notificationTypeId);
        templateTypeId && setSelectedTemplateTypeId(templateTypeId);
        templateId && setSelectedTemplateId(templateId);
    }, [notificationTypeId, templateId, templateTypeId]);

    /**
     * Change the current values on template change
     */
    const changeCurrentValues = useCallback(() => {
        change(requestKeys.NAME, get(templateDetails, 'name', ''));
        change(requestKeys.SUBJECT, get(templateDetails, 'subject', ''));
        change(requestKeys.MESSAGE, get(templateDetails, 'message', ''));
        setMessage(get(templateDetails, 'message', ''));
        change(requestKeys.TEMPLATE_TYPE, get(templateDetails, 'template_type', ''));
    }, [templateDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Use effect to change the current template values on template change
     */
    useEffect(() => {
        changeCurrentValues();
    }, [changeCurrentValues]);

    /**
     * Locale the form label
     *
     * @param {string}  field Field for which locale is needed
     *
     * @returns {string} Localised content
     */
    const localeLabel = field => props.t(`customerMessages.campaign.form.input.label.${field}`);

    /**
     * Show the template preview
     */
    const showPreview = () => {
        if (message) {
            setOpenPreview(true);
            props.getCommunicationTemplatePreview({
                account_id: accountId,
                organisation_id: organisationId,
                body: message,
                type: get(find(props.notificationTypes, { id: selectedNotificationTypeId }), 'slug', ''),
                scheduler_id: get(props, ['initialValues', 'id'], ''),
            });
        }
    };

    /**
     * Get the form fields by notification type
     */
    const getFormFields = () => {
        if (selectedNotificationTypeId && selectedTemplateTypeId && selectedTemplateId) {
            const type = get(find(props.notificationTypes, { id: selectedNotificationTypeId }), 'slug', '');
            switch (type) {
                case 'email':
                    return (
                        <EmailFields
                            {...props}
                            setMessage={setMessage}
                            message={message}
                            showPreview={showPreview}
                            disable
                            disableBody
                            disableSubject
                        />
                    );
                case 'ivr':
                    return (
                        <IvrFields
                            {...props}
                            setMessage={setMessage}
                            message={message}
                            showPreview={showPreview}
                            disable
                            disableBody
                        />
                    );
                case 'sms':
                    return (
                        <SmsFields
                            {...props}
                            setMessage={setMessage}
                            message={message}
                            showPreview={showPreview}
                            disable
                            disableBody
                        />
                    );
                default:
                    return null;
            }
        }
    };

    /**
     * Get the template types that has communication templates
     *
     * @returns {Array}  Template Types
     */
    const getTemplateTypesWithTemplates = () => {
        return get(props, 'templateTypes', [])
            .filter(x => {
                const templateTypeTemplates = get(get(props, 'allCommunicationTemplates'), [
                    selectedNotificationTypeId,
                    x.id,
                ]);
                return !isEmpty(templateTypeTemplates) && x.slug !== 'on-demand-campaign';
            })
            .map(x => {
                return {
                    value: x.id,
                    name: x.label,
                };
            });
    };

    return (
        <div>
            <Form layout="vertical" className="" onSubmit={props.handleSubmit}>
                <Row gutter={16} className="form-first-row">
                    <Col span={12}>
                        <Field
                            type="select"
                            className="form-control"
                            name={requestKeys.NOTIFICATION_TYPE_ID}
                            label={localeLabel(requestKeys.NOTIFICATION_TYPE)}
                            onChange={value => {
                                setSelectedNotificationTypeId(value);
                                setSelectedTemplateTypeId('');
                                setSelectedTemplateId('');
                                change(requestKeys.COMMUNICATION_TEMPLATE_ID, '');
                                change(requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID, '');
                                change(requestKeys.SUBJECT, '');
                                change(requestKeys.MESSAGE, '');
                                setMessage('');
                            }}
                            options={props.notificationTypes.map(x => ({ value: x.id, name: x.type }))}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                            required
                        />
                    </Col>
                    {selectedNotificationTypeId && (
                        <Col span={12}>
                            <Field
                                type="select"
                                className="form-control"
                                name={requestKeys.COMMUNICATION_TEMPLATE_TYPE_ID}
                                label={localeLabel(requestKeys.TEMPLATE_TYPE)}
                                hasFeedback
                                options={getTemplateTypesWithTemplates()}
                                component={FormField}
                                validate={[formValidations.required]}
                                onChange={id => {
                                    setSelectedTemplateTypeId(id);
                                    setSelectedTemplateId('');
                                    change(requestKeys.SUBJECT, '');
                                    change(requestKeys.MESSAGE, '');
                                    setMessage('');
                                    change(requestKeys.COMMUNICATION_TEMPLATE_ID, '');
                                }}
                                required
                            />
                        </Col>
                    )}
                </Row>
                <Row gutter={16}>
                    {selectedNotificationTypeId && selectedTemplateTypeId && (
                        <>
                            <Col span={12}>
                                <Field
                                    type="select"
                                    className="form-control"
                                    name={requestKeys.COMMUNICATION_TEMPLATE_ID}
                                    label={localeLabel(requestKeys.COMMUNICATION_TEMPLATE)}
                                    options={get(
                                        props,
                                        [
                                            'allCommunicationTemplates',
                                            selectedNotificationTypeId,
                                            selectedTemplateTypeId,
                                        ],
                                        []
                                    ).map(x => ({
                                        value: x.id,
                                        name: x.name,
                                    }))}
                                    onChange={id => {
                                        setSelectedTemplateId(id);
                                        if (id) {
                                            props.getCommunicationTemplateDetails(organisationId, id);
                                        } else {
                                            change(requestKeys.SUBJECT, '');
                                            change(requestKeys.MESSAGE, '');
                                            setMessage('');
                                        }
                                    }}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    required
                                />
                            </Col>
                            <Col span={12}>
                                <Field
                                    type="select"
                                    className="form-control"
                                    name={requestKeys.DAY_ID}
                                    label={localeLabel(requestKeys.DAY)}
                                    options={props.campaignDays.map(x => ({ value: x.id, name: x.description }))}
                                    hasFeedback
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    required
                                />
                            </Col>
                        </>
                    )}
                </Row>
                <Row gutter={16} className="initial-pos">
                    <span className="info">
                        {props.t('customerMessages.campaign.form.scheduledNotification')} <b>2:00 PM</b>.
                    </span>
                </Row>
                {getFormFields()}
                {selectedNotificationTypeId && selectedTemplateTypeId && selectedTemplateId && (
                    <Form.Item
                        className={`campaign-btn-wrapper ${props.error ? ' has-error default-form-app-error' : ''}`}
                    >
                        {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                        <Button
                            htmlType="submit"
                            big
                            filled
                            className="green-bg-button"
                            submittingButtonLabel={props.t(
                                `customerMessages.campaign.form.submit.${
                                    props.action === 'add' ? 'add' : 'update'
                                }.buttonSubmittingLabel`
                            )}
                            loading={props.isUpdatingCampaign || props.isAddingCampaign}
                        >
                            {props.t(
                                `customerMessages.campaign.form.submit.${
                                    props.action === 'add' ? 'add' : 'update'
                                }.buttonLabel`
                            )}
                        </Button>
                    </Form.Item>
                )}
            </Form>
            <Preview {...props} openPreview={openPreview} setOpenPreview={setOpenPreview} />
        </div>
    );
};

/**
 * Proptypes
 */
AddEditCampaign.propTypes = {
    accountId: PropTypes.string,
    action: PropTypes.string,
    campaignDays: PropTypes.array,
    change: PropTypes.func,
    allCommunicationTemplates: PropTypes.object,
    error: PropTypes.any,
    getCommunicationTemplateDetails: PropTypes.func,
    getCommunicationTemplatePreview: PropTypes.func,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
    isAddingCampaign: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isTemplateLoading: PropTypes.bool,
    isUpdatingCampaign: PropTypes.bool,
    loginFailed: PropTypes.bool,
    notificationTypes: PropTypes.array,
    organisationId: PropTypes.string,
    t: PropTypes.func,
    templateDetails: PropTypes.object,
    togglePopover: PropTypes.func,
    untouch: PropTypes.func,
};

// Default props
AddEditCampaign.defaultProps = {
    accountId: '',
    campaignDays: [],
    notificationTypes: [],
};

// Redux-Form wrapper
export default reduxForm({
    form: constants.ADD_EDIT_SCHEDULER_FORM_NAME,
})(withTranslation()(AddEditCampaign));

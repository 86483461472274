/**
 * useCachedData
 * Hook that returns the data if present in the cache or fetches it from the specified API,
 * can also specify it to be dependent on organisation (if the organisation changes, it'll refetch)
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import useDidUpdate from './useDidUpdate';
import useOrganisationId from './useOrganisationId';
import { useCacheContext } from 'includes/contexts/cacheContext';
import { useEffect } from 'react';
import { isArray } from 'lodash';

/**
 *
 * @param {string} key the key to cache the item
 * @param {Function} method Api method to fetch the data
 * @param {object} config Config data
 * @param {any} defaultDataValue Default data Value. Default []
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useCachedData = (
    key,
    method,
    { selector = res => res, disableApiCall = false, dependsOnOrg = false, additionalResetKeys = [] },
    defaultDataValue = []
) => {
    const loadingKey = `is${key}Loading`;
    const doneKey = `is${key}Done`;
    const orgKey = `organisation${key}`;
    const { getCachedValue, setCache, clearCache } = useCacheContext();

    const organisationId = useOrganisationId();
    const data = getCachedValue(key) || defaultDataValue;
    const isLoading = getCachedValue(loadingKey);
    const isDone = getCachedValue(doneKey);
    const currentCachedOrg = getCachedValue(orgKey);

    async function fetchData(forced = false) {
        if ((!isDone && !isLoading) || forced) {
            setCache(loadingKey, true);
            setCache(orgKey, organisationId);
            try {
                const result = await method();
                setCache(key, selector(result));
            } catch {
                setCache(key, []);
            } finally {
                setCache(doneKey, true);
                setCache(loadingKey, false);
            }
        }
    }

    useDidUpdate(() => {
        if (dependsOnOrg && currentCachedOrg !== organisationId && !disableApiCall) {
            // noinspection JSIgnoredPromiseFromCall
            refetch();
        }
    }, [organisationId]);

    useEffect(() => {
        if (!disableApiCall) {
            // noinspection JSIgnoredPromiseFromCall
            fetchData();
        }
    }, [isLoading, isDone]); // eslint-disable-line react-hooks/exhaustive-deps

    const reset = () => {
        clearCache(key);
        setCache(doneKey, false);
        if (additionalResetKeys && isArray(additionalResetKeys)) {
            additionalResetKeys.forEach(additionalResetKey => {
                clearCache(additionalResetKey);
                setCache(`is${additionalResetKey}Done`, false);
            });
        }
    };

    const refetch = () => fetchData(true);

    const update = data => setCache(key, data);

    return { data, isLoading, isDone, refetch, reset, update };
};

export default useCachedData;

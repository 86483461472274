/**
 * Accounts Saga
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import required modules
import { React } from '../../../../exports/react';
import * as actionTypes from '../../../../constants/actionTypes';
import * as constants from '../../../../constants';
import * as errorMappings from '../../../../constants/mappings/errors';
import * as responseKeys from '../../../../constants/keys/response';
import * as successMappings from '../../../../constants/mappings/success';
import AccountApi from '../../../../services/shared/account';
import BaseSagaHandler from '../../core/base';
import history from '../../../../exports/history';
import i18n from '../../../../i18n';
import ReactHtmlParser from 'react-html-parser';
import { get } from 'lodash';
import { getLocalStorageValue, getValueFormStore, redirect, setDataInLocalStorage } from '../../../../utils';
import { Modal } from 'antd';
import { reset, stopSubmit } from 'redux-form';
import { takeLatest, all, put, call } from 'redux-saga/effects';
import { UNKNOWN_ERROR } from '../../../../constants/messages/errors';

const { confirm } = Modal;

class AccountSaga extends BaseSagaHandler {
    /**
     * The Accounts Watcher Saga
     * Watches the redux actions related to accounts and invokes the specified saga
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga
     * (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *accountWatchers() {
        let context = this;
        yield all([
            yield takeLatest(actionTypes.ADD_COUPON_TO_ACCOUNT_REQUEST, [context, 'addCouponToAccount']),
            yield takeLatest(actionTypes.GET_ACCOUNT_COUPONS_REQUEST, [context, 'getAccountCoupons']),
            yield takeLatest(actionTypes.GET_ACCOUNT_DETAILS_REQUEST, [context, 'getAccountDetails']),
            yield takeLatest(actionTypes.GET_ACCOUNT_TYPES_REQUEST, [context, 'getAccountTypes']),
            yield takeLatest(actionTypes.GET_ACCOUNTS_REQUEST, [context, 'getAccounts']),
            yield takeLatest(actionTypes.GET_COUPON_ACCOUNTS_REQUEST, [context, 'getCouponAccounts']),
            yield takeLatest(actionTypes.GET_COUPONS_REQUEST, [context, 'getCoupons']),
            yield takeLatest(actionTypes.GET_USER_ACCOUNT_DETAILS_REQUEST, [context, 'getAccountDetails']),
            yield takeLatest(actionTypes.REMOVE_COUPON_FROM_ACCOUNT_REQUEST, [context, 'removeCouponFromAccount']),
            yield takeLatest(actionTypes.SEND_TEST_EMAIL_REQUEST, [context, 'sendTestEmail']),
            yield takeLatest(actionTypes.TRY_DEMO_COMPANY_REQUEST, [context, 'tryDemoCompany']),
            yield takeLatest(actionTypes.UPDATE_ACCOUNT_DETAILS_REQUEST, [context, 'updateAccountDetails']),
        ]);
    }

    /**
     * Add coupon to accounts
     *
     * @param {object} payload Payload
     * @param {Function} callback Callback function
     *
     * @yields {object} Add coupon to account response
     */
    *addCouponToAccount({ payload, callback }) {
        try {
            const result = yield call(AccountApi.addCouponToAccount, payload);
            yield put({ type: actionTypes.ADD_COUPON_TO_ACCOUNT_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            // redirect to plan accounts list page
            redirect(`/admin/coupons/${payload.coupon_id}`);
        } catch (errors) {
            const couponError = get(errors, errorMappings.COUPON_ADD_TO_ACCOUNT_COUPON_ERROR);
            if (couponError && callback) {
                callback(couponError);
            } else {
                const addCouponToAccountError = get(
                    errors,
                    errorMappings.COUPON_ADD_TO_ACCOUNT_ACCOUNT_ID_ERROR,
                    get(errors, errorMappings.COUPON_ADD_TO_ACCOUNT_DEFAULT_ERROR, UNKNOWN_ERROR)
                );
                yield put({
                    type: actionTypes.SHOW_NOTIFICATION_MODAL,
                    message: addCouponToAccountError,
                    messageType: constants.ERROR,
                });
            }
            yield put({ type: actionTypes.ADD_COUPON_TO_ACCOUNT_FAILURE, errors });
        }
    }

    /**
     * Get account coupons
     *
     * @param   {object}    payload    Payload
     *
     * @yields {object} Account coupons response
     */
    *getAccountCoupons({ payload }) {
        try {
            const result = yield call(AccountApi.getAccountCoupons, payload);
            yield put({ type: actionTypes.GET_ACCOUNT_COUPONS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_ACCOUNT_COUPONS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get account details
     *
     * Fetches account details
     */
    *getAccountDetails(action) {
        const currentAccountId = getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY);
        let successKey =
            currentAccountId === action.payload.account_id
                ? actionTypes.GET_ACCOUNT_DETAILS_SUCCESS
                : actionTypes.GET_USER_ACCOUNT_DETAILS_SUCCESS;
        try {
            const result = yield call(AccountApi.getAccountDetails, action.payload);
            yield put({ type: successKey, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_ACCOUNT_DETAILS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get all account types
     *
     * @param   {object}    action    Action
     *
     * @yields {object} Account types response
     */
    *getAccountTypes(action) {
        try {
            const result = yield call(AccountApi.getAccountTypes, action.payload);
            const accountTypes = get(result, successMappings.ACCOUNT_TYPES);
            yield put({ type: actionTypes.GET_ACCOUNT_TYPES_SUCCESS, accountTypes });
        } catch (errors) {
            yield put({ type: actionTypes.GET_ACCOUNT_TYPES_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get all the accounts
     *
     * @param   {object}    action    Action
     *
     * @yields {object} Accounts response
     */
    *getAccounts(action) {
        try {
            const result = yield call(AccountApi.getAccounts, action.payload);
            yield put({ type: actionTypes.GET_ACCOUNTS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_ACCOUNTS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get the accounts associated with a coupon
     *
     * @param   {object}    action    Action
     *
     * @yields {object} Get accounts in a coupon response
     */
    *getCouponAccounts(action) {
        try {
            const result = yield call(AccountApi.getCouponAccounts, action.payload);
            yield put({ type: actionTypes.GET_COUPON_ACCOUNTS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_COUPON_ACCOUNTS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Get coupons
     *
     * @param   {object}    action    Action
     *
     * @yields {object} Coupons response
     */
    *getCoupons(action) {
        try {
            const result = yield call(AccountApi.getCoupons, action.payload);
            yield put({ type: actionTypes.GET_COUPONS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_COUPONS_FAILURE, errors });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: UNKNOWN_ERROR,
                messageType: constants.ERROR,
            });
        }
    }

    /**
     * Remove coupon from account
     *
     * @param   {object}    payload    Payload
     *
     * @yields {object} Remove coupon from account response
     */
    *removeCouponFromAccount({ payload }) {
        try {
            const result = yield call(AccountApi.removeCouponFromAccount, payload);
            yield put({ type: actionTypes.REMOVE_COUPON_FROM_ACCOUNT_SUCCESS, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
        } catch (errors) {
            const removeCouponFromAccountError = get(
                errors,
                errorMappings.COUPON_REMOVE_FROM_ACCOUNT_ACCOUNT_ID_ERROR,
                get(
                    errors,
                    errorMappings.COUPON_REMOVE_FROM_ACCOUNT_COUPON_ERROR,
                    get(errors, errorMappings.COUPON_REMOVE_FROM_ACCOUNT_DEFAULT_ERROR, UNKNOWN_ERROR)
                )
            );
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: removeCouponFromAccountError,
                messageType: constants.ERROR,
            });
            yield put({ type: actionTypes.REMOVE_COUPON_FROM_ACCOUNT_FAILURE, errors });
        }
    }

    /**
     * Send Test Email
     *
     * @param {object} payload Payload
     *
     * @yields {object} Send test email response
     */
    *sendTestEmail({ payload }) {
        try {
            const result = yield call(AccountApi.sendTestMail, payload);
            yield put({ type: actionTypes.SEND_TEST_EMAIL_SUCCESS, result });
            yield put(reset(constants.SEND_TEST_EMAIL_FORM));
            yield this.showNotificationModal(result, true);
        } catch (errors) {
            yield this.showNotificationModal(
                errors,
                false,
                constants.SEND_TEST_MAIL_ERROR,
                constants.SEND_TEST_MAIL_ERROR
            );
            yield put({ type: actionTypes.SEND_TEST_EMAIL_FAILURE, errors });
        } finally {
            //reset any errors set in form
            yield put(stopSubmit(constants.SEND_TEST_EMAIL_FORM, {}));
        }
    }

    /**
     * Try the demo company
     *
     * @param   {object}    action    Action
     *
     * @yields {object} Update account details response
     */
    *tryDemoCompany({ accountId, payload, loadingModal, subscriptionExpiresIn, callApi }) {
        try {
            if (callApi) {
                const result = yield call(AccountApi.tryDemoCompany, accountId, payload);
                yield put({ type: actionTypes.TRY_DEMO_COMPANY_SUCCESS, result });
                if (payload.later) {
                    yield put({
                        type: actionTypes.SHOW_NOTIFICATION_MODAL,
                        message: i18n.t('sharedMessages.demoCompany.tryLater', {
                            days: subscriptionExpiresIn,
                        }),
                        messageType: constants.SUCCESS,
                        onOk: () => {
                            this._showAddOrganisationOrConnectAccountingSoftwarePopup();
                            redirect('/dashboard?demo_company_later=true');
                        },
                    });
                } else {
                    let selectedOrganisation = get(
                        result,
                        successMappings.GET_SELECTED_ORGANISATION_AFTER_DEMO_COMPANY_TRY
                    );
                    let selectedOrganisationId = get(selectedOrganisation, responseKeys.ID);
                    setDataInLocalStorage({
                        [constants.USER_SELECTED_ORGANISATION_ID_KEY]: selectedOrganisationId,
                    });
                    yield put({
                        type: actionTypes.GET_SELECTED_ORGANISATION_SUCCESS,
                        selectedOrganisation: selectedOrganisation,
                        selectedOrganisationId: selectedOrganisationId,
                    });
                    yield put({
                        type: actionTypes.GET_REAUTHORIZE_ORGANISATIONS_COUNT_SUCCESS,
                        reauthorizeOrganisationsCount: get(result, successMappings.GET_REAUTHORIZE_ORGANISATIONS_COUNT),
                    });
                    if (get(history, 'location.pathname') === '/dashboard') {
                        yield put({ type: actionTypes.FORCE_RE_RENDER_COMPONENT_REQUEST });
                    }
                    yield put({
                        type: actionTypes.SHOW_NOTIFICATION_MODAL,
                        message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                        messageType: constants.SUCCESS,
                        onOk: () => {
                            redirect('/dashboard?demo_company_tried=true');
                        },
                    });
                    redirect('/dashboard');
                }
            } else {
                yield put({
                    type: actionTypes.SHOW_NOTIFICATION_MODAL,
                    message: i18n.t('sharedMessages.demoCompany.tryLater', { days: subscriptionExpiresIn }),
                    messageType: constants.SUCCESS,
                    onOk: () => {
                        this._showAddOrganisationOrConnectAccountingSoftwarePopup();
                        redirect('/dashboard?demo_company_later=true');
                    },
                });
            }
            loadingModal.destroy();
        } catch (errors) {
            yield this.showNotificationModal(
                errors,
                false,
                constants.TRY_DEMO_COMPANY_DEFAULT_ERROR,
                constants.TRY_DEMO_COMPANY_DEFAULT_ERROR
            );
            yield put({ type: actionTypes.TRY_DEMO_COMPANY_FAILURE, errors });
            loadingModal.destroy();
        }
    }

    /**
     * Update the account details
     *
     * @param   {object}    action    Action
     *
     * @yields {object} Update account details response
     */
    *updateAccountDetails(action) {
        try {
            const currentAccountId = getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY);
            const result = yield call(AccountApi.updateAccountDetails, action.payload);
            let successKey =
                currentAccountId === action.payload.account_id
                    ? actionTypes.UPDATE_ACCOUNT_DETAILS_SUCCESS
                    : actionTypes.UPDATE_USER_ACCOUNT_DETAILS_SUCCESS;
            yield put({ type: successKey, result });
            yield put({
                type: actionTypes.SHOW_NOTIFICATION_MODAL,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            if (['admin', 'admin-manager'].includes(getValueFormStore(`user.roles.${currentAccountId}.slug`, ''))) {
                if (currentAccountId === action.payload.accountId) {
                    redirect('/admin/my-account');
                } else {
                    redirect('/admin/accounts/' + action.payload.accountId);
                }
            } else if (['debtor'].includes(getValueFormStore(`user.roles.${currentAccountId}.slug`, ''))) {
                redirect('/debtor/my-account');
            } else {
                redirect('/my-account');
            }
        } catch (errors) {
            //dispatch the errors to the form
            yield put(
                stopSubmit(
                    constants.BUSINESS_FORM_NAME,
                    this.getApiErrors(
                        errors,
                        constants.ACCOUNT_EDIT_DEFAULT_ERROR,
                        constants.ACCOUNT_EDIT_DEFAULT_ERROR
                    )
                )
            );
            yield put({ type: actionTypes.UPDATE_ACCOUNT_DETAILS_FAILURE, errors });
        }
    }

    /**
     * Show the add organisation or connect accounting software notification modal if the user has
     * not connected any organisation to the account
     */
    _showAddOrganisationOrConnectAccountingSoftwarePopup() {
        const organisationId = getLocalStorageValue(constants.USER_SELECTED_ORGANISATION_ID_KEY);
        if (!organisationId) {
            const addConnectConfirmPopup = confirm({
                title: '',
                content: ReactHtmlParser(i18n.t('sharedMessages.demoCompany.addOrgOrConnectAccountingContent')),
                okText: i18n.t('sharedMessages.demoCompany.button.addOrganisation'),
                cancelText: i18n.t('sharedMessages.demoCompany.button.connectAccountingSoftware'),
                icon: <></>,
                onOk: () => {
                    redirect('/organisation/add');
                    addConnectConfirmPopup.destroy();
                },
                onCancel: close => {
                    let isCancelTriggered = get(close, 'triggerCancel');
                    if (!isCancelTriggered) {
                        redirect('/connect');
                        addConnectConfirmPopup.destroy();
                    }
                },
                className: 'addorg-connect',
            });
        }
    }
}

//export account saga
export default new AccountSaga().forkAllWatcherFunctions();

/**
 * useOrganisationId
 * extracts and returns the organisationId in the URL
 * if it doesn't exist then returns the value `state.organisation.selectedOrganisationId` from store
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

/**
 * Get the organisation Id
 * Checks if the organisation id exists in params if not get from store
 *
 * @param {boolean} fromParamsOnly Whether to take organisation id from params only and not from the store. Default false
 *
 * @returns {string} Organisation Id
 */
const useOrganisationId = (fromParamsOnly = false) => {
    const { organisationId: organisationIdFromParams } = useParams();
    const organisationIdFromStore = useSelector(state => state.organisation.selectedOrganisationId);
    return fromParamsOnly ? organisationIdFromParams : organisationIdFromParams || organisationIdFromStore;
};

export default useOrganisationId;

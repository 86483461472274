/**
 * Default settings form Component
 *
 * @version 1.0
 */

//import required modules
import React from 'react';
import Button from 'components/Button';
import FormField from 'components/shared/FormField';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import ZendeskEventTypeFields from 'pages/Zendesk/Settings/components/Form/components/EventTypeFields';
import { email, required } from 'includes/utils/form';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col, Icon } from 'antd';
import { get, isEmpty, isObject } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    ZENDESK_CONNECTED,
    ZENDESK_CONNECTION_FAILED,
    ZENDESK_EMAIL,
    ZENDESK_ERROR_MESSAGE,
    ZENDESK_EVENT_TYPES,
    ZENDESK_NOT_CONNECTED,
    ZENDESK_SUBDOMAIN,
    ZENDESK_TAGS,
    ZENDESK_TOKEN,
} from 'includes/constants/keys/request';
import {
    ZENDESK_SETTINGS_FORM_NAME,
    ZENDESK_TICKET_DEFAULT_TAG,
    ZENDESK_TICKET_DEFAULT_TAG_BY_TYPE,
} from 'includes/constants';
import useZendeskTicketOptions from 'includes/hooks/useZendeskTicketOptions';
import Loader from 'components/Loader';
import PermissionGuard from 'components/PermissionGuard';
import { ZENDESK } from 'includes/constants/permissions';

const ZendeskSettingsForm = props => {
    const { t } = useTranslation();
    const { error, handleSubmit } = props;
    const loadingSave = useSelector(state => state.zendesk.loadingSave);
    const { data: ticketOptions, isLoading: loadingTicketOptions } = useZendeskTicketOptions();
    const eventTypes = get(ticketOptions, ZENDESK_EVENT_TYPES);

    const getConnectionStatus = (iconType, className, infoLabel, params = {}) => (
        <div className={className}>
            <Icon type={iconType} />
            <span style={{ marginLeft: 5 }}>{localeInfo(infoLabel, params)}</span>
        </div>
    );

    /**
     * Get the localised info
     *
     * @param {string} field Field for which info to be obtained
     * @param {object} params Params
     *
     * @returns {string} Localised info
     */
    const localeInfo = (field, params = {}) =>
        ReactHtmlParser(t(`messages.zendesk.settings.form.input.info.${field}`, params));

    /**
     * Get the localised label
     *
     * @param {string} field Field for which localised label to be obtained
     *
     * @returns {string} Localised label
     */
    const localeLabel = field => t(`messages.zendesk.settings.form.input.label.${field}`);

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" onSubmit={handleSubmit} className="edit-form">
                <Row gutter={24}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={ZENDESK_TOKEN}
                            label={localeLabel(ZENDESK_TOKEN)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[required]}
                            info={localeInfo(ZENDESK_TOKEN)}
                            required={true}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={ZENDESK_SUBDOMAIN}
                            label={localeLabel(ZENDESK_SUBDOMAIN)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[required]}
                            info={localeInfo(ZENDESK_SUBDOMAIN)}
                            infoAsToolTip
                            required={true}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={ZENDESK_EMAIL}
                            label={localeLabel(ZENDESK_EMAIL)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[required, email]}
                            info={localeInfo(ZENDESK_EMAIL)}
                            infoAsToolTip
                            required={true}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="select"
                            mode="tags"
                            className="select-tags-mode zendesk-tags"
                            name={ZENDESK_TAGS}
                            label={localeLabel(ZENDESK_TAGS)}
                            component={FormField}
                            info={localeInfo(ZENDESK_TAGS, {
                                defaultTag: ZENDESK_TICKET_DEFAULT_TAG,
                                defaultTagByEventType: ZENDESK_TICKET_DEFAULT_TAG_BY_TYPE,
                            })}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <label htmlFor="name">{localeLabel(ZENDESK_CONNECTED)}</label>
                        {get(props.initialValues, ZENDESK_CONNECTED)
                            ? getConnectionStatus('check-circle', 'connection-success', ZENDESK_CONNECTED)
                            : get(props.initialValues, ZENDESK_ERROR_MESSAGE)
                            ? getConnectionStatus('close-circle', 'connection-failed', ZENDESK_CONNECTION_FAILED, {
                                  connectError: get(props.initialValues, ZENDESK_ERROR_MESSAGE),
                              })
                            : getConnectionStatus('info-circle', 'not-connected', ZENDESK_NOT_CONNECTED)}
                    </Col>
                </Row>
                {loadingTicketOptions ? (
                    <Loader />
                ) : !isEmpty(eventTypes) && isObject(eventTypes) ? (
                    Object.keys(eventTypes).map(key => {
                        return (
                            <>
                                <hr className="mrgn-tp-25" />
                                <section className="profile-detail-lower">
                                    <ZendeskEventTypeFields
                                        forceShowEventFields
                                        eventType={eventTypes[key]}
                                        includeEventTypeInFieldName
                                    />
                                </section>
                            </>
                        );
                    })
                ) : null}
            </Form>
            <Form layout="vertical" className="edit-form-update" onSubmit={handleSubmit}>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {error ? <div className="ant-form-explain">{error}</div> : ''}
                    <PermissionGuard requiredPermission={ZENDESK.SETTINGS.SAVE}>
                        <Button
                            onClick={handleSubmit}
                            big
                            filled
                            loading={loadingSave}
                            submittingButtonLabel={t(`messages.zendesk.settings.form.button.submitting`)}
                            className="register-btn"
                        >
                            {t(`messages.zendesk.settings.form.button.submit`)}
                        </Button>
                    </PermissionGuard>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
};

ZendeskSettingsForm.propTypes = {
    error: PropTypes.string,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
};

export default reduxForm({ form: ZENDESK_SETTINGS_FORM_NAME })(ZendeskSettingsForm);

/**
 * Bank account information component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import { Alert, Divider, Icon, Menu, Modal } from 'antd';
import { groupBy } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/shared/Loader';
import {
    addPaymentBankAccount,
    deletePaymentBankAccount,
    getPaymentBankAccounts,
    updatePaymentBankAccount,
} from 'includes/slices/payments';
import BankAccount from './components/BankAccount';
import BankAccountFormModal from './components/BankAccountFormModal';
import Description from './components/Description';
import { useParams } from 'react-router-dom';
import useGetPermissionWrappedFunction from 'includes/hooks/useGetPermissionWrappedFunction';
import { PAYMENT_BANK_ACCOUNT } from 'includes/constants/permissions';
import { showPermissionDeniedModal } from 'components/PermissionGuard/component';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import { SUCCESS } from 'includes/constants';
import PaymentMethodDefaultSVG from 'assets/images/payment-method-default.svg';
import { showAppNotificationModal } from 'includes/redux/actions/shared/appNotification';

const { confirm } = Modal;

/**
 * Bank accounts list
 *
 * @returns
 * @since 2.8.0
 */
export default function BankAccounts() {
    const [selectedBankAccountIdForEditing, setSelectedBankAccountIdForEditing] = useState(null);

    const [isSettingDefaultBankAccount, setIsSettingDefaultBankAccount] = useState(false);

    const [isFormModalOpen, setIsFormModalOpen] = useState(false);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { accountId } = useParams();

    const isAdmin = useSelector(state => state.account.isAdmin);

    const isLoadingPaymentBankAccounts = useSelector(state => state.payments.isLoadingPaymentBankAccounts);

    const isAddingPaymentAccount = useSelector(state => state.payments.isAddingPaymentAccount);

    const isUpdatingPaymentAccount = useSelector(state => state.payments.isUpdatingPaymentAccount);

    const isDeletingPaymentBankAccount = useSelector(state => state.payments.isDeletingPaymentBankAccount);

    const bankAccounts = useSelector(state => state.payments.paymentBankAccounts);

    /**
     * memoise selected bank account details
     */
    const selectedBankAccountDetails = useMemo(() => {
        if (bankAccounts.length === 0 || !selectedBankAccountIdForEditing) return {};

        return bankAccounts.find(account => account.id === selectedBankAccountIdForEditing);
    }, [bankAccounts, selectedBankAccountIdForEditing]);

    const openFormModalForEditing = useGetPermissionWrappedFunction(
        PAYMENT_BANK_ACCOUNT.EDIT,
        bankAccountId => {
            if (bankAccountId) setSelectedBankAccountIdForEditing(bankAccountId);
            setIsFormModalOpen(true);
        },
        showPermissionDeniedModal
    );

    const openFormModalForAdding = useGetPermissionWrappedFunction(
        PAYMENT_BANK_ACCOUNT.ADD,
        () => setIsFormModalOpen(true),
        showPermissionDeniedModal
    );

    const permissionWrappedGetBankAccounts = useGetPermissionWrappedFunction(PAYMENT_BANK_ACCOUNT.LIST, () =>
        dispatch(getPaymentBankAccounts(accountId ? { account_id: accountId } : null))
    );

    /**
     * Method to close form modal and clear `selectedBankAccountIdForEditing`
     */
    const closeFormModal = () => {
        setIsFormModalOpen(false);
        setSelectedBankAccountIdForEditing(null);
    };

    // get the payment bank accounts
    useEffect(() => {
        permissionWrappedGetBankAccounts();
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    // handle form submit
    const handleSubmit = values => {
        if (selectedBankAccountIdForEditing) {
            dispatch(updatePaymentBankAccount(selectedBankAccountIdForEditing, values, closeFormModal));
        } else dispatch(addPaymentBankAccount(values, closeFormModal));
    };

    /**
     * Set default bank account
     *
     * @param {*} bankAccount bank account object
     */
    const setDefaultBankAccount = bankAccount => {
        setIsSettingDefaultBankAccount(true);
        dispatch(
            updatePaymentBankAccount(
                bankAccount.id,
                { ...bankAccount, is_default: true },
                () => dispatch(showAppNotificationModal(SUCCESS, 'Default bank account updated')),
                { showSuccessMessage: false, unsetLoader: () => setIsSettingDefaultBankAccount(false) }
            )
        );
    };

    /**
     * Render confirm modal and on confirm, delete the bank account
     *
     * @param {string} paymentBankAccountId payment gateway ID
     */
    const handleDelete = useGetPermissionWrappedFunction(
        PAYMENT_BANK_ACCOUNT.DELETE,
        paymentBankAccountId => {
            confirm({
                okButtonProps: { loading: isDeletingPaymentBankAccount },
                cancelText: t('customerMessages.paymentMethod.deletePaymentBankAccountModal.cancelText'),
                content: t('customerMessages.paymentMethod.deletePaymentBankAccountModal.content'),
                okText: t('customerMessages.paymentMethod.deletePaymentBankAccountModal.okText'),
                title: t('customerMessages.paymentMethod.deletePaymentBankAccountModal.title'),
                onOk: () => {
                    dispatch(deletePaymentBankAccount(paymentBankAccountId));
                },
            });
        },
        showPermissionDeniedModal
    );

    /**
     * group payment methods into default and other payment methods
     */
    const { defaultBankAccount, otherBankAccounts } = useMemo(() => {
        const grouped = groupBy(bankAccounts, account =>
            account.is_default ? 'defaultBankAccount' : 'otherBankAccounts'
        );

        return {
            defaultBankAccount: grouped.defaultBankAccount ?? [],
            otherBankAccounts: grouped.otherBankAccounts ?? [],
        };
    }, [bankAccounts]);

    if (isLoadingPaymentBankAccounts)
        return (
            <div className="p-7">
                <Loader />
            </div>
        );

    /**
     * Render menu node to be passed in to the `BankAccount` component
     *
     * @param {string} bankAccountId Bank account ID
     * @returns menu node
     */
    const getMenuNode = bankAccount => {
        if (isAdmin) return null;

        return (
            <Menu>
                {bankAccount.is_default ? null : (
                    <Menu.Item
                        onClick={() => setDefaultBankAccount(bankAccount)}
                        className="flex flex-row flex-start gap-1 align-middle"
                    >
                        <img src={PaymentMethodDefaultSVG} />
                        <span>{t('customerMessages.paymentMethod.action.makeDefault')}</span>
                    </Menu.Item>
                )}
                <Menu.Item
                    onClick={() => openFormModalForEditing(bankAccount.id)}
                    className="flex flex-row flex-start align-middle"
                >
                    <Icon type="edit" />
                    <span>{t('customerMessages.paymentMethod.action.edit')}</span>
                </Menu.Item>
                <Menu.Item onClick={() => handleDelete(bankAccount.id)} className="flex flex-row flex-start">
                    <Icon type="delete" />
                    <span>{t('customerMessages.paymentMethod.action.delete')}</span>
                </Menu.Item>
            </Menu>
        );
    };

    const renderDefaultPaymentMethodSection = () => {
        if (isAdmin) {
            if (!bankAccounts || bankAccounts.length === 0) return <div>No bank accounts added yet.</div>;

            return (
                <div>
                    <Divider>
                        <span className="text-md font-semibold text-gray-500 my-2">Primary bank account</span>
                    </Divider>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 justify-start">
                        {defaultBankAccount.map(account => (
                            <div key={account.id} className="h-fit-content">
                                <BankAccount
                                    code={account.bank_code}
                                    isDefault={account.is_default}
                                    key={account.id}
                                    name={account.account_holder_name}
                                    number={account.account_number}
                                    onDelete={() => handleDelete(account.id)}
                                    onEdit={() => openFormModalForEditing(account.id)}
                                    type={account.account_holder_type}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        return (
            <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                <div className={defaultBankAccount.length > 0 ? 'col-span-1' : 'col-span-2'}>
                    <Description onAddBankAccount={() => openFormModalForAdding()} />
                </div>
                {defaultBankAccount.length > 0 ? (
                    <div>
                        <Divider>
                            <span className="text-md font-semibold text-gray-500 my-2">
                                Primary payment bank account
                            </span>
                        </Divider>
                        <div className="grid grid-cols-1 gap-5 justify-start">
                            {defaultBankAccount.map(account => (
                                <div key={account.id} className="h-fit-content">
                                    <BankAccount
                                        code={account.bank_code}
                                        isDefault={account.is_default}
                                        key={account.id}
                                        name={account.account_holder_name}
                                        number={account.account_number}
                                        menu={getMenuNode(account)}
                                        type={account.account_holder_type}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <div className="p-5 px-9">
            <BankAccountFormModal
                initialValues={selectedBankAccountDetails}
                isLoading={isAddingPaymentAccount || isUpdatingPaymentAccount}
                onSubmit={handleSubmit}
                onCancel={closeFormModal}
                open={isFormModalOpen}
            />

            <Modal footer={null} visible={isSettingDefaultBankAccount}>
                <Loader />
                <div>Setting default payment bank account...</div>
            </Modal>

            <div className="flex flex-col gap-5">
                {renderDefaultPaymentMethodSection()}

                <PermissionGuardLayout
                    requiredPermission={PAYMENT_BANK_ACCOUNT.LIST}
                    layout="section"
                    showFallbackUI
                    sectionErrorMessage="You don't have permission to list payment bank accounts."
                >
                    {otherBankAccounts.length > 0 ? (
                        <div>
                            <Divider>
                                <span className="text-md font-semibold text-gray-500 my-2">
                                    Other payment bank accounts
                                </span>
                            </Divider>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 justify-start">
                                {otherBankAccounts.map(account => (
                                    <div key={account.id} className="h-fit-content">
                                        <BankAccount
                                            code={account.bank_code}
                                            isDefault={account.is_default}
                                            key={account.id}
                                            menu={getMenuNode(account)}
                                            name={account.account_holder_name}
                                            number={account.account_number}
                                            type={account.account_holder_type}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </PermissionGuardLayout>
            </div>

            {isAdmin ? null : (
                <div className="text-left mt-3">
                    <Alert
                        showIcon
                        type="warning"
                        message={t('customerMessages.paymentMethod.info.bankAccountUsage')}
                    />
                </div>
            )}
        </div>
    );
}

/**
 * Payment plans breadcrumb items
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React from 'react';
import AccountsBreadCrumbItems from '../Accounts';
import OrganisationsBreadCrumbItems from '../Organisations';
import PropTypes from 'prop-types';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Breadcrumb } from 'antd';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const PaymentPlansBreadCrumbItems = props => {
    const accountId = useAccountId();
    const isAdminOrManager = useIsAdminOrManager();
    const organisationId = useOrganisationId();
    const { t } = useTranslation();

    const isAdd = props.action === 'add';
    const paymentPlanDetails = useSelector(state => state.paymentArrangement.paymentPlan);

    /**
     * Get the payment plan details link
     *
     * @param {string} paymentPlanId Payment plan id
     *
     * @returns {string} Details link
     */
    const getDetailsLink = paymentPlanId =>
        paymentPlanId
            ? isAdminOrManager
                ? `/admin/accounts/organisation/payment-plans/plan/${accountId}/${organisationId}/${paymentPlanId}`
                : `/payment-plans/plan/${paymentPlanId}`
            : '#';

    /**
     * Get the payment plans list link
     *
     * @returns {string} List link
     */
    const getListLink = () =>
        isAdminOrManager
            ? `/admin/accounts/organisation/payment-plans/${accountId}/${organisationId}`
            : `/payment-plans`;

    /**
     * Render the component
     */
    return (
        <>
            {isAdminOrManager ? (
                <>
                    <AccountsBreadCrumbItems showListBreadCrumb />
                    <OrganisationsBreadCrumbItems showListBreadCrumb />
                </>
            ) : null}
            {props.showListBreadCrumb ? (
                <Breadcrumb.Item>
                    <Link to={getListLink()}>{t(`messages.breadCrumbs.paymentArrangement.plans.list`)}</Link>
                </Breadcrumb.Item>
            ) : null}
            {props.showDetailsBreadCrumb ? (
                <Breadcrumb.Item>
                    {props.showDetailsBreadCrumbAsLink ? (
                        <Link to={getDetailsLink(get(paymentPlanDetails, 'id'))}>
                            {get(
                                paymentPlanDetails,
                                'name',
                                t(`messages.breadCrumbs.paymentArrangement.plans.details`)
                            )}
                        </Link>
                    ) : (
                        get(paymentPlanDetails, 'name', t('messages.breadCrumbs.paymentArrangement.plans.details'))
                    )}
                </Breadcrumb.Item>
            ) : null}
            {props.showAddEditBreadCrumb ? (
                <Breadcrumb.Item>
                    {t(
                        `messages.breadCrumbs.paymentArrangement.plans.${
                            props.isArrangement ? 'arrangement' : 'extension'
                        }.${isAdd ? 'add' : 'edit'}`
                    )}
                </Breadcrumb.Item>
            ) : null}
        </>
    );
};

PaymentPlansBreadCrumbItems.propTypes = {
    action: PropTypes.string,
    isArrangement: PropTypes.bool,
    showAddEditBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumb: PropTypes.bool,
    showDetailsBreadCrumbAsLink: PropTypes.bool,
    showListBreadCrumb: PropTypes.bool,
};

export default PaymentPlansBreadCrumbItems;

import i18n from 'includes/i18n';
import { isEmpty } from 'lodash';

/**
 * Get notes table columns
 *
 * @param {object} noteTypes Note Types
 * @param {object} noteStatuses Note Statuses
 * @param {Array} accountUsers Account Users
 * @param {boolean} showActions Whether to show actions or note. Default true
 *
 * @returns [array] columns Notes table columns
 */
export const getNotesColumns = (noteTypes, noteStatuses, accountUsers = [], showActions = true) => {
    let columns = [
        {
            title: i18n.t('messages.notes.list.label.title'),
            dataIndex: 'title',
            key: 'title',
            collapseHeader: true,
            sorter: true,
        },
        {
            title: i18n.t('messages.notes.list.label.date'),
            dataIndex: 'date',
            key: 'date',
            sorter: true,
            collapseHeader: true,
        },
        {
            title: i18n.t('messages.notes.list.label.followUpDate'),
            dataIndex: 'follow_up_date',
            key: 'follow_up_date',
            sorter: true,
            collapseHeader: true,
        },
        {
            title: i18n.t('messages.notes.list.label.starred'),
            dataIndex: 'starred',
            key: 'starred',
            sorter: true,
            filterMultiple: false,
            filters: [
                { text: i18n.t('messages.notes.list.label.yes'), value: 1 },
                { text: i18n.t('messages.notes.list.label.no'), value: 0 },
            ],
            collapseHeader: true,
        },
        {
            title: i18n.t('messages.notes.list.label.user'),
            dataIndex: 'user',
            key: 'user',
            sorter: true,
            className: 'select-field-short',
            filterMultiple: false,
            filters: !isEmpty(accountUsers)
                ? accountUsers.map(accountUser => ({ text: accountUser.full_name, value: accountUser.id }))
                : [],
            collapseHeader: true,
        },
        {
            title: i18n.t('messages.notes.list.label.priority'),
            dataIndex: 'type',
            key: 'type',
            sorter: true,
            className: 'select-field-short',
            filterMultiple: false,
            filters: !isEmpty(noteTypes)
                ? Object.keys(noteTypes).map(key => ({ text: noteTypes[key], value: key }))
                : [],
            collapseHeader: true,
        },
        {
            title: i18n.t('messages.notes.list.label.status'),
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            className: 'select-field-short',
            filterMultiple: false,
            filters: !isEmpty(noteStatuses)
                ? Object.keys(noteStatuses).map(key => ({ text: noteStatuses[key], value: key }))
                : [],
            collapseHeader: true,
        },
    ];

    if (showActions) {
        columns.push({
            title: i18n.t('messages.notes.list.label.actions'),
            dataIndex: 'actions',
            key: 'actions',
            collapseHeader: true,
        });
    }

    return columns;
};

/**
 * On Demand campaign details history page
 *
 * @author Aravind JR <aravind@paycepaid.com.au>
 * @version 1.0
 */
import React from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { Breadcrumb } from 'antd';
import { useTranslation } from 'react-i18next';

import CommunicationLogHistoryDetails from 'components/shared/CommunicationLog/details.jsx';
import { getCommunicationLogHistoryDetails } from 'includes/redux/actions/shared/communication';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { Link } from 'includes/exports/react';
import useAccountId from 'includes/hooks/useAccountId';
import useIsAdmin from 'includes/hooks/useIsAdmin';

const OnDemandCampaignHistoryDetails = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const organisationId = useOrganisationId();
    const { communicationLogHistoryId } = useParams();
    const accountId = useAccountId();
    const isAdmin = useIsAdmin();

    /**
     * Get localised text
     *
     * @param {string} path
     *
     * @returns {string} Localised string
     */
    const getLocaleText = path => t(`sharedMessages.communicationLogHistory.${path}`);

    /**
     * Get the On-demand breadcrumb
     */
    const breadcrumb = [
        <Breadcrumb.Item key="invoices">
            <Link to={isAdmin ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}` : `/invoices`}>
                {getLocaleText('breadcrumb.invoices')}
            </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key="on-demand-history">
            <Link
                to={
                    isAdmin
                        ? `/admin/accounts/organisation/invoices/${accountId}/${organisationId}/on-demand-campaign/history`
                        : `/invoices/on-demand-campaign/history`
                }
            >
                {getLocaleText('breadcrumb.onDemandHistory')}
            </Link>
        </Breadcrumb.Item>,
        <Breadcrumb.Item key="details">{getLocaleText('breadcrumb.onDemandHistoryDetails')}</Breadcrumb.Item>,
    ];

    const fetchLogDetails = () => {
        dispatch(
            getCommunicationLogHistoryDetails(organisationId, communicationLogHistoryId, {
                on_demand_campaign: true,
            })
        );
    };

    return (
        <CommunicationLogHistoryDetails
            fetchLogDetails={fetchLogDetails}
            breadcrumb={breadcrumb}
            pageTitle={getLocaleText(`pageTitle.onDemandHistoryDetails`)}
        />
    );
};

export default OnDemandCampaignHistoryDetails;

/**
 * Admin module routing
 * Handles the routing across admin module
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import required modules
import AccountDetails from '../../../components/admin/AccountDetails';
import Accounts from '../../../components/admin/Accounts';
import AddEditCampaign from '../../../components/customer/Campaign/addEdit';
import AddEditContact from '../../../components/customer/Contacts/addEdit';
import AddEditInvoice from 'pages/Invoices/AddEdit';
import AddEditOrganisation from '../../../components/customer/OrganisationDetails/addEdit';
import AddEditPlan from '../../../components/admin/Plans/AddEdit';
import AddEditUser from '../../../components/customer/AddUser';
import AdminHeader from '../../../components/shared/header/AdminHeader';
import AdminSidebar from '../../../components/shared/Sidebar/AdminSidebar';
import AppFooter from '../../../components/shared/footer/AppFooter';
import AuditLogsList from 'pages/AuditLogs/List';
import AccountBankAccounts from 'pages/AccountBankAccounts';
import BusinessInfoEdit from '../../../components/customer/MyAccount/BusinessInfoEdit';
import Campaign from '../../../components/customer/Campaign';
import ChangePassword from '../../../components/shared/ChangePassword';
import ContactDetails from '../../../components/customer/Contacts/details';
import Contacts from '../../../components/customer/Contacts';
import CouponAccounts from '../../../components/admin/CouponAccounts';
import CouponAccountsAdd from '../../../components/admin/CouponAccounts/Add';
import Coupons from '../../../components/admin/Coupons';
import CustomerDashboard from '../../../components/customer/Dashboard';
import Dashboard from '../../../components/admin/Dashboard';
import EditProfile from '../../../components/shared/MyProfile/edit';
import Exports from '../../../components/shared/Exports';
import FullSidebarLayout from '../../../layouts/FullSidebar';
import InvoiceDetails from 'pages/Invoices/Details';
import InvoicePayments from '../../../components/customer/InvoicePayments';
import Invoices from 'pages/Invoices/List';
import MyProfile from '../../../components/shared/MyProfile';
import Notifications from '../../../components/shared/Notifications/';
import OrderDetails from '../../../components/shared/Orders/OrderDetails';
import Orders from '../../../components/shared/Orders';
import OrganisationDetails from '../../../components/customer/OrganisationDetails';
import Organisations from '../../../components/customer/Organisations';
import OrganisationSettings from '../../../components/admin/OrganisationSettings';
import PageNotFound from '../../../components/shared/PageNotFound';
import PaymentGateways from '../../../components/admin/PaymentGateways';
import PaymentGatewayAddEdit from '../../../components/admin/PaymentGateways/addEdit';
import Placeholders from '../../../components/admin/Placeholders';
import PlanAccounts from '../../../components/admin/PlanAccounts';
import PlanAccountsAdd from '../../../components/admin/PlanAccounts/Add';
import PlanQuotes from '../../../components/admin/PlanQuotes';
import Plans from '../../../components/admin/Plans';
import Reminders from '../../../components/customer/Reminders';
import RouteValidators from '../validators';
import Settings from '../../../components/admin/Settings';
import SubscriptionDetails from '../../../components/customer/Subscription/detail';
import Templates from '../../../components/admin/Templates';
import Users from '../../../components/customer/Users';
import UserSubscription from '../../../components/customer/Subscription';
import { USER_ROLE_ADMIN, USER_ROLE_ADMIN_MANAGER } from '../../constants';
import CommunicationLogHistoryDetails from '../../../components/shared/CommunicationLog/details';
// import CommunicationLogHistory from '../../../components/shared/CommunicationLog';
import Import from '../../../components/Import';
import FollowUps from '../../../pages/FollowUps';
import ChartOfAccountAddEdit from '../../../pages/ChartOfAccounts/AddEdit';
import ChartOfAccountDetails from '../../../pages/ChartOfAccounts/Details';
import ChartOfAccountsList from '../../../pages/ChartOfAccounts/List';
import InventoryList from '../../../pages/Inventory/List';
import InventoryDetails from '../../../pages/Inventory/Details';
import InventoryItemAddEdit from '../../../pages/Inventory/AddEdit';
import InvoiceCampaignHistory from 'pages/InvoiceCampaignHistory/List';
import TaxesList from '../../../pages/Taxes/List';
import TaxAddEdit from '../../../pages/Taxes/AddEdit';
import TaxDetails from '../../../pages/Taxes/Details';
import DebtorAccountsList from 'pages/DebtorAccounts/List';
import ZendeskTicketsList from 'pages/Zendesk/Tickets/List';
import ZendeskTicketDetails from 'pages/Zendesk/Tickets/Details';
import ZendeskSettings from 'pages/Zendesk/Settings';
import PermissionManager from 'components/PermissionManager';
import OrganisationCurrenciesList from 'pages/OrganisationCurrencies/List';
import ContactTypesList from 'pages/ContactTypes/List';
import CampaignsList from 'pages/Campaigns/List';
import CampaignDetails from 'pages/Campaigns/Details';
import CampaignAddEdit from 'pages/Campaigns/AddEdit';
import PaymentPlanDetails from 'pages/PaymentPlans/Details';
import PaymentPlanAddEdit from 'pages/PaymentPlans/AddEdit';
import PaymentPlansList from 'pages/PaymentPlans/List';
import PaymentArrangementDetails from 'pages/PaymentArrangements/Details';
import PaymentArrangementsList from 'pages/PaymentArrangements/List';
import EventsCalendar from 'pages/EventsCalendar/List';
import AddAccount from 'components/admin/AddAccount';
import AccountOrganisationPaymentGateways from 'pages/AccountOrganisationPaymentGateways';
import AppPaymentGateways from 'pages/AppPaymentGateways';
import OrganisationOrders from 'pages/OrganisationOrders';
import PaymentDetails from 'pages/PaymentDetails';
import AccountOrganisationPaymentServiceDetails from 'pages/AccountOrganisationPaymentGatewayDetails';
import AppPaymentGatewayDetails from 'pages/AppPaymentGatewayDetails';

export const defaultAppFooter = AppFooter;
export const defaultAppHeader = AdminHeader;
export const defaultAppLayout = FullSidebarLayout;
export const defaultAppPageNotFound = PageNotFound;
export const defaultAppRoutePrefix = '/admin';
export const defaultAppSidebar = AdminSidebar;
export const defaultAppValidator = [
    RouteValidators.isTokenExists,
    RouteValidators.isRoleValid([USER_ROLE_ADMIN, USER_ROLE_ADMIN_MANAGER]),
];
export const routes = [
    {
        path: '/',
        component: Dashboard,
        exact: true,
        isPrivate: true,
        slug: 'dashboard',
    },
    {
        path: '/permission-manager',
        component: PermissionManager,
        exact: true,
        isPrivate: true,
        slug: 'permission-manager',
    },
    {
        path: '/accounts',
        component: Accounts,
        exact: true,
        isPrivate: true,
        slug: 'accounts',
    },
    {
        path: '/accounts/add-account',
        component: AddAccount,
        exact: true,
        isPrivate: true,
        slug: 'accounts',
    },
    {
        path: '/accounts/:accountId',
        component: AccountDetails,
        exact: true,
        isPrivate: true,
        slug: 'accountDetails',
    },
    {
        path: '/accounts/billings/:accountId',
        component: Orders,
        exact: true,
        isPrivate: true,
        slug: 'accountBillings',
    },
    {
        path: '/accounts/bank-accounts/:accountId',
        component: AccountBankAccounts,
        exact: true,
        isPrivate: true,
        slug: 'accountBillings',
    },
    {
        path: '/accounts/add-user/:accountId',
        component: AddEditUser,
        componentProps: { action: 'add', path: 'accounts' },
        isPrivate: true,
        slug: 'addUser',
    },
    {
        path: '/accounts/organisation/contact/:accountId/:organisationId/:contactId',
        component: ContactDetails,
        isPrivate: true,
        slug: 'contactDetails',
    },
    {
        path: '/accounts/organisation/contact-manager/:accountId/:organisationId/:contactId',
        component: ContactDetails,
        componentProps: {
            isContactManager: true,
        },
        isPrivate: true,
        slug: 'contactManagerDetails',
    },
    {
        path: '/accounts/edit-campaign/:accountId/:organisationId/:campaignId',
        component: AddEditCampaign,
        componentProps: { path: 'admin' },
        isPrivate: true,
        slug: 'editCampaign',
    },
    {
        path: '/accounts/edit-organisation/:accountId/:organisationId',
        component: AddEditOrganisation,
        isPrivate: true,
        slug: 'editOrganisation',
    },
    {
        path: '/accounts/edit/:accountId',
        component: BusinessInfoEdit,
        exact: true,
        isPrivate: true,
        slug: 'editBusinessInfo',
    },
    {
        path: '/accounts/audit-logs/:accountId',
        component: AuditLogsList,
        isPrivate: true,
        slug: 'auditLogs',
        componentProps: {
            includeAccountId: true,
            includeOrganisationId: false,
            showFilter: true,
            useAccountFilter: true,
        },
    },
    {
        path: '/accounts/organisation/audit-logs/:accountId/:organisationId',
        component: AuditLogsList,
        componentProps: {
            showFilter: true,
        },
        isPrivate: true,
        slug: 'auditLogs',
    },
    {
        path: '/audit-logs',
        component: AuditLogsList,
        isPrivate: true,
        slug: 'auditLogs',
        componentProps: {
            includeAccountId: false,
            includeOrganisationId: false,
            showFilter: true,
            useAccountFilter: true,
        },
    },
    {
        path: '/accounts/organisation-dashboard/:accountId/:organisationId',
        component: CustomerDashboard,
        isPrivate: true,
        slug: 'customerDashboard',
        validators: [RouteValidators.isTokenExists],
    },
    {
        path: '/accounts/organisation/:accountId/:organisationId',
        component: OrganisationDetails,
        exact: true,
        isPrivate: true,
        slug: 'organisationDetails',
    },
    {
        path: '/accounts/organisation/campaigns/:accountId/:organisationId',
        component: CampaignsList,
        isPrivate: true,
        slug: 'campaigns',
        exact: true,
    },
    {
        path: '/accounts/organisation/campaigns/campaign/:accountId/:organisationId/:campaignId/edit',
        component: CampaignAddEdit,
        slug: 'campaignEdit',
        isPrivate: true,
        exact: true,
    },
    {
        path: '/accounts/organisation/campaigns/campaign/:accountId/:organisationId/:campaignId',
        component: CampaignDetails,
        slug: 'campaign',
        isPrivate: true,
        exact: true,
    },
    {
        path:
            '/accounts/organisation/payment-plans/plan/:accountId/:organisationId/:paymentPlanId/campaigns/campaign/:campaignId',
        component: CampaignDetails,
        slug: 'campaignDetails',
        isPrivate: true,
        //requiredPermission: CHART_OF_ACCOUNT.EDIT,
        exact: true,
    },
    {
        path:
            '/accounts/organisation/payment-plans/plan/:accountId/:organisationId/:paymentPlanId/campaigns/campaign/:campaignId/edit',
        component: CampaignAddEdit,
        slug: 'campaignEdit',
        isPrivate: true,
        //requiredPermission: CHART_OF_ACCOUNT.EDIT,
        exact: true,
    },
    {
        path: '/accounts/organisation/campaign/:accountId/:organisationId',
        component: Campaign,
        componentProps: {
            path: 'admin',
        },
        isPrivate: true,
        slug: 'campaign',
    },
    {
        path: '/accounts/organisation/currencies/:accountId/:organisationId',
        component: OrganisationCurrenciesList,
        exact: true,
        isPrivate: true,
        slug: 'currencies',
    },
    {
        path: '/accounts/organisation/chart-of-accounts/:accountId/:organisationId',
        component: ChartOfAccountsList,
        exact: true,
        isPrivate: true,
        slug: 'chartOfAccounts',
    },
    {
        path: '/accounts/organisation/chart-of-accounts/:accountId/:organisationId/add',
        component: ChartOfAccountAddEdit,
        componentProps: { action: 'add' },
        slug: 'chartOfAccountAdd',
        isPrivate: true,
        exact: true,
    },
    {
        path: '/accounts/organisation/chart-of-accounts/chart-of-account/:accountId/:organisationId/:chartOfAccountId',
        component: ChartOfAccountDetails,
        exact: true,
        isPrivate: true,
        slug: 'chartOfAccount',
    },
    {
        path:
            '/accounts/organisation/chart-of-accounts/chart-of-account/:accountId/:organisationId/:chartOfAccountId/edit',
        component: ChartOfAccountAddEdit,
        componentProps: { action: 'edit' },
        exact: true,
        isPrivate: true,
        slug: 'chartOfAccountEdit',
    },
    {
        path: '/accounts/organisation/contacts/:accountId/:organisationId',
        component: Contacts,
        exact: true,
        isPrivate: true,
        slug: 'contacts',
    },
    {
        path: '/accounts/organisation/contact-managers/:accountId/:organisationId',
        component: Contacts,
        componentProps: {
            isContactManager: true,
        },
        exact: true,
        isPrivate: true,
        slug: 'contact-managers',
    },
    {
        path: '/accounts/organisation/contact-types/:accountId/:organisationId',
        component: ContactTypesList,
        exact: true,
        isPrivate: true,
        slug: 'contact-types',
    },
    {
        path: '/accounts/organisation/contacts/:accountId/:organisationId/add',
        component: AddEditContact,
        componentProps: {
            action: 'add',
        },
        exact: true,
        isPrivate: true,
        slug: 'addContact',
    },
    {
        path: '/accounts/organisation/contact-managers/:accountId/:organisationId/add',
        component: AddEditContact,
        componentProps: {
            action: 'add',
            isContactManager: true,
        },
        exact: true,
        isPrivate: true,
        slug: 'addContactManager',
    },
    {
        path: '/accounts/organisation/contacts/:accountId/:organisationId/:contactId',
        component: AddEditContact,
        isPrivate: true,
        slug: 'editContact',
    },
    {
        path: '/accounts/organisation/contact-managers/:accountId/:organisationId/:contactId',
        component: AddEditContact,
        componentProps: {
            isContactManager: true,
        },
        isPrivate: true,
        slug: 'editContactManager',
    },
    {
        path: '/accounts/organisation/debtor-accounts/:accountId/:organisationId',
        component: DebtorAccountsList,
        exact: true,
        isPrivate: true,
        slug: 'debtorAccounts',
    },
    {
        path: '/accounts/organisation/email-templates/:accountId/:organisationId',
        component: Templates,
        componentProps: {
            path: 'admin',
            type: 'email',
        },
        isPrivate: true,
        slug: 'emailTemplates',
    },
    {
        path:
            '/accounts/organisation/invoices/:accountId/:organisationId/on-demand-campaign/history/:communicationLogHistoryId',
        component: CommunicationLogHistoryDetails,
        slug: 'CommunicationLogHistoryDetails',
        componentProps: {
            onDemandCampaign: true,
        },
    },
    {
        path:
            '/accounts/organisation/invoices/invoice/:accountId/:organisationId/:contactId/:invoiceId/on-demand-campaign/history',
        component: InvoiceCampaignHistory,
        slug: 'communicationLogHistory',
        componentProps: {
            onDemandCampaign: true,
        },
    },
    {
        path: '/accounts/organisation/import/contact-managers/:accountId/:organisationId',
        component: Import,
        componentProps: {
            action: 'contact-managers',
        },
        isPrivate: true,
        slug: 'importContactManager',
    },
    {
        path: '/accounts/organisation/import/contacts/:accountId/:organisationId',
        component: Import,
        componentProps: {
            action: 'contacts',
        },
        isPrivate: true,
        slug: 'importContacts',
    },
    {
        path: '/accounts/organisation/invoices/:accountId/:organisationId',
        component: Invoices,
        componentProps: {
            path: 'admin',
        },
        exact: true,
        isPrivate: true,
        slug: 'invoices',
    },
    {
        path: '/accounts/organisation/invoices/invoice/:accountId/:organisationId/:contactId/:invoiceId',
        component: InvoiceDetails,
        isPrivate: true,
        slug: 'invoiceDetails',
    },
    {
        path:
            '/accounts/organisation/invoices/payments/:accountId/:organisationId/:contactId/:invoiceId/:invoicePaymentId',
        component: PaymentDetails,
        isPrivate: true,
        // requiredPermissions: INVOICE.PAYMENT.VIEW,
        slug: 'invoicePaymentDetails',
    },
    {
        path: '/accounts/organisation/invoices/invoice/:accountId/:organisationId/:contactId/:invoiceId/edit',
        component: AddEditInvoice,
        // componentProps: {
        //     path: 'admin',
        // },
        exact: true,
        isPrivate: true,
        slug: 'editInvoice',
    },
    {
        path: '/accounts/organisation/invoices/recurring/:accountId/:organisationId/:contactId/:invoiceId/edit',
        component: AddEditInvoice,
        componentProps: {
            action: 'edit',
            isRecurring: true,
        },
        exact: true,
        isPrivate: true,
        slug: 'editRecurringInvoice',
    },
    {
        path: '/accounts/organisation/import/invoices/:accountId/:organisationId/',
        component: Import,
        componentProps: {
            action: 'invoices',
            link: 'invoices',
        },
        isPrivate: true,
        slug: 'importInvoice',
    },
    {
        path: '/accounts/organisation/import/invoice-payments/:accountId/:organisationId/',
        component: Import,
        componentProps: {
            action: 'invoice-payments',
        },
        isPrivate: true,
        slug: 'importInvoicePayments',
    },
    {
        path: '/accounts/organisation/inventory/:accountId/:organisationId',
        component: InventoryList,
        exact: true,
        isPrivate: true,
        slug: 'inventory',
    },
    {
        path: '/accounts/organisation/inventory/:accountId/:organisationId/add',
        component: InventoryItemAddEdit,
        componentProps: { action: 'add' },
        slug: 'inventoryItemAdd',
        isPrivate: true,
        exact: true,
    },
    {
        path: '/accounts/organisation/inventory/inventory-item/:accountId/:organisationId/:inventoryItemId',
        component: InventoryDetails,
        exact: true,
        isPrivate: true,
        slug: 'inventoryItem',
    },
    {
        path: '/accounts/organisation/inventory/inventory-item/:accountId/:organisationId/:inventoryItemId/edit',
        component: InventoryItemAddEdit,
        componentProps: { action: 'edit' },
        exact: true,
        isPrivate: true,
        slug: 'inventoryItemEdit',
    },
    {
        path: '/accounts/organisation/ivr-templates/:accountId/:organisationId',
        component: Templates,
        componentProps: {
            path: 'admin',
            type: 'ivr',
        },
        isPrivate: true,
        slug: 'ivrTemplates',
    },
    {
        path: '/accounts/organisation/payments/:accountId/:organisationId',
        component: InvoicePayments,
        exact: true,
        isPrivate: true,
        slug: 'invoicePayment',
    },
    {
        path: '/accounts/payment-gateways/:accountId',
        component: PaymentGateways,
        exact: true,
        isPrivate: true,
        slug: 'account-payment-gateways',
    },
    {
        path: '/accounts/payment-gateways/:accountId/:paymentGatewayId',
        component: PaymentGatewayAddEdit,
        exact: true,
        isPrivate: true,
        slug: 'account-payment-gateways-edit',
    },
    {
        path: '/accounts/organisation/payment-gateways/:accountId/:organisationId',
        component: AccountOrganisationPaymentGateways,
        exact: true,
        isPrivate: true,
        slug: 'organisation-payment-gateways',
    },
    {
        path: '/accounts/organisation/payment-gateways/:accountId/:organisationId/:paymentServiceId',
        component: AccountOrganisationPaymentServiceDetails,
        exact: true,
        isPrivate: true,
        slug: 'organisation-payment-gateways-view',
    },
    {
        path: '/accounts/organisation/placeholders/:accountId/:organisationId',
        component: Placeholders,
        isPrivate: true,
        slug: 'placeholders',
    },
    {
        path: '/accounts/organisation/reminder/followups/:accountId/:organisationId',
        component: FollowUps,
        isPrivate: true,
        slug: 'followups',
    },
    {
        path: '/accounts/organisation/reminders/:accountId/:organisationId',
        component: Reminders,
        componentProps: {
            path: 'admin',
        },
        isPrivate: true,
        slug: 'reminders',
    },
    {
        path: '/accounts/organisation/reminders/:organisationId',
        component: Reminders,
        componentProps: {
            path: 'admin',
        },
        isPrivate: true,
        slug: 'reminders',
    },
    {
        path: '/accounts/organisation/settings/:accountId/:organisationId',
        component: OrganisationSettings,
        isPrivate: true,
        slug: 'placeholders',
    },
    {
        path: '/accounts/organisation/sms-templates/:accountId/:organisationId',
        component: Templates,
        componentProps: {
            path: 'admin',
            type: 'sms',
        },
        isPrivate: true,
        slug: 'smsTemplates',
    },
    {
        path: '/accounts/organisations/:accountId',
        component: Organisations,
        exact: true,
        isPrivate: true,
        slug: 'organisations',
    },
    {
        path: '/accounts/user/:accountId',
        component: Users,
        componentProps: {
            path: 'accounts',
        },
        exact: true,
        isPrivate: true,
        slug: 'accounts',
    },
    {
        path: '/accounts/user/:accountId/:userId',
        component: AddEditUser,
        componentProps: {
            action: 'edit',
            path: 'accounts',
        },
        isPrivate: true,
        slug: 'editUser',
    },
    {
        path: '/change-password',
        component: ChangePassword,
        isPrivate: true,
        slug: 'changePassword',
    },
    {
        path: '/coupons',
        component: Coupons,
        exact: true,
        isPrivate: true,
        slug: 'coupons',
    },
    {
        path: '/coupons/:couponId',
        component: CouponAccounts,
        exact: true,
        isPrivate: true,
        slug: 'accountCoupons',
    },
    {
        path: '/coupons/add-accounts/:couponId',
        component: CouponAccountsAdd,
        exact: true,
        isPrivate: true,
        slug: 'accounts',
    },
    {
        path: '/dashboard',
        component: Dashboard,
        exact: true,
        isPrivate: true,
        slug: 'dashboard',
    },
    {
        path: '/edit-profile',
        component: EditProfile,
        isPrivate: true,
        slug: 'editProfile',
    },
    {
        path: '/exports',
        component: Exports,
        isPrivate: true,
        slug: 'exports',
    },
    {
        path: '/event-calendar',
        component: EventsCalendar,
    },
    {
        path: '/my-account',
        component: AccountDetails,
        exact: true,
        isPrivate: true,
        slug: 'myAccount',
    },
    {
        path: '/my-profile',
        component: MyProfile,
        exact: true,
        isPrivate: true,
        slug: 'myProfile',
    },
    {
        path: '/notifications',
        component: Notifications,
        isPrivate: true,
        slug: 'notifications',
    },
    {
        path: '/accounts/organisation/orders/:accountId/:organisationId/:orderType/:orderId',
        component: OrderDetails,
        isPrivate: true,
        slug: 'orderDetails',
    },
    {
        path: '/accounts/orders/:accountId/:orderType/:orderId',
        component: OrderDetails,
        isPrivate: true,
        slug: 'billingDetails',
    },
    {
        path: '/accounts/organisation/orders/:accountId/:organisationId/:orderType/:orderId/edit',
        component: OrderDetails,
        componentProps: {
            action: 'edit',
        },
        isPrivate: true,
        slug: 'orderDetailsEdit',
    },
    {
        path: '/accounts/organisation/orders/:accountId/:organisationId',
        component: OrganisationOrders,
        exact: true,
        isPrivate: true,
        slug: 'orders',
    },
    {
        path: '/accounts/orders/:accountId',
        component: Orders,
        exact: true,
        isPrivate: true,
        slug: 'orders',
    },
    {
        path: '/page-not-found',
        component: PageNotFound,
        slug: 'pageNotFound',
    },
    {
        path: '/plans/plans/accounts/add/:planId',
        component: PlanAccountsAdd,
        isPrivate: true,
    },
    {
        path: '/plans/plans/accounts/view/:planId',
        component: PlanAccounts,
        isPrivate: true,
    },
    {
        path: '/plans/plans/add',
        component: AddEditPlan,
        componentProps: {
            action: 'add',
        },
        exact: true,
        isPrivate: true,
        slug: 'addPlan',
    },
    {
        path: '/plans/plans/edit-plan/:planId',
        component: AddEditPlan,
        exact: true,
        isPrivate: true,
        slug: 'editPlan',
    },
    {
        path: '/plans/plans',
        component: Plans,
        isPrivate: true,
        slug: 'plans',
    },
    {
        path: '/plans/quotes',
        component: PlanQuotes,
        isPrivate: true,
        slug: 'planQuotes',
    },
    {
        path: '/settings',
        component: Settings,
        exact: true,
        isPrivate: true,
        slug: 'settings',
    },
    {
        path: '/subscriptions/:subscriptionId',
        component: SubscriptionDetails,
        isPrivate: true,
        slug: 'subscriptionDetails',
    },
    {
        path: '/subscriptions',
        component: UserSubscription,
        isPrivate: true,
        slug: 'subscriptions',
    },
    {
        path: '/accounts/organisation/taxes/:accountId/:organisationId',
        component: TaxesList,
        exact: true,
        isPrivate: true,
        slug: 'taxes',
    },
    {
        path: '/accounts/organisation/taxes/:accountId/:organisationId/add',
        component: TaxAddEdit,
        componentProps: { action: 'add' },
        slug: 'taxAdd',
        isPrivate: true,
        exact: true,
    },
    {
        path: '/accounts/organisation/taxes/tax/:accountId/:organisationId/:taxId',
        component: TaxDetails,
        exact: true,
        isPrivate: true,
        slug: 'tax',
    },
    {
        path: '/accounts/organisation/taxes/tax/:accountId/:organisationId/:taxId/edit',
        component: TaxAddEdit,
        componentProps: { action: 'edit' },
        exact: true,
        isPrivate: true,
        slug: 'taxEdit',
    },
    {
        path: '/users',
        component: Users,
        exact: true,
        isPrivate: true,
        slug: 'users',
    },
    {
        path: '/users/:accountId/:userId',
        component: AddEditUser,
        componentProps: {
            action: 'edit',
        },
        isPrivate: true,
        slug: 'editUser',
    },
    {
        path: '/users/add-user',
        component: AddEditUser,
        componentProps: {
            action: 'add',
        },
        isPrivate: true,
        slug: 'addUser',
    },
    {
        path: '/payment-gateways/:paymentGatewayId',
        component: AppPaymentGatewayDetails,
        isPrivate: true,
        slug: 'payment-gateways',
    },
    {
        path: '/payment-gateways',
        component: AppPaymentGateways,
        isPrivate: true,
        slug: 'payment-gateways',
    },
    {
        path: '/payment-gateway/add',
        component: PaymentGatewayAddEdit,
        componentProps: {
            action: 'add',
        },
        isPrivate: true,
        slug: 'payment-gateway-edit',
    },
    {
        path: '/payment-gateway/:paymentGatewayId',
        component: AppPaymentGatewayDetails,
        isPrivate: true,
        slug: 'payment-gateway-edit',
    },
    {
        path: '/accounts/organisation/zendesk-settings/:accountId/:organisationId',
        component: ZendeskSettings,
        exact: true,
        isPrivate: true,
        slug: 'zendeskSettings',
    },
    {
        path: '/accounts/organisation/zendesk-tickets/:accountId/:organisationId',
        component: ZendeskTicketsList,
        exact: true,
        isPrivate: true,
        slug: 'zendeskTickets',
    },
    {
        path: '/accounts/organisation/zendesk-tickets/zendesk-ticket/:accountId/:organisationId/:ticketId',
        component: ZendeskTicketDetails,
        exact: true,
        isPrivate: true,
        slug: 'zendeskTicketDetails',
    },
    {
        path: '/accounts/organisation/payment-plans/:accountId/:organisationId',
        component: PaymentPlansList,
        slug: 'paymentPlans',
        isPrivate: true,
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/accounts/organisation/payment-plans/:accountId/:organisationId/:planType/add',
        component: PaymentPlanAddEdit,
        componentProps: { action: 'add' },
        slug: 'paymentPlanAdd',
        isPrivate: true,
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/accounts/organisation/payment-plans/plan/:accountId/:organisationId/:paymentPlanId/edit',
        component: PaymentPlanAddEdit,
        componentProps: { action: 'edit' },
        slug: 'paymentPlanEdit',
        isPrivate: true,
        //requiredPermission: CHART_OF_ACCOUNT.EDIT,
        exact: true,
    },
    {
        path: '/accounts/organisation/payment-plans/plan/:accountId/:organisationId/:paymentPlanId',
        component: PaymentPlanDetails,
        slug: 'paymentPlan',
        isPrivate: true,
        //requiredPermission: CHART_OF_ACCOUNT.EDIT,
        exact: true,
    },
    {
        path: '/accounts/organisation/payment-arrangements/:accountId/:organisationId',
        component: PaymentArrangementsList,
        slug: 'paymentArrangements',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'arrangement', key: 'paymentArrangements' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/accounts/organisation/payment-arrangements/:accountId/:organisationId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'arrangement' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path:
            '/accounts/organisation/payment-arrangements/plan/:accountId/:organisationId/:paymentPlanId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'arrangement' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path:
            '/accounts/organisation/payment-arrangements/invoice/:accountId/:organisationId/:contactId/:invoiceId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'arrangement' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path:
            '/accounts/organisation/payment-arrangements/contact/:accountId/:organisationId/:contactId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'arrangement' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/accounts/organisation/payment-extensions/:accountId/:organisationId',
        component: PaymentArrangementsList,
        slug: 'paymentExtensions',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'extension', key: 'paymentExtensions' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/accounts/organisation/payment-extensions/:accountId/:organisationId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'extension' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path:
            '/accounts/organisation/payment-extensions/plan/:accountId/:organisationId/:paymentPlanId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'extension' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path:
            '/accounts/organisation/payment-extensions/invoice/:accountId/:organisationId/:contactId/:invoiceId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'extension' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path:
            '/accounts/organisation/payment-extensions/contact/:accountId/:organisationId/:contactId/:paymentArrangementId',
        component: PaymentArrangementDetails,
        slug: 'paymentArrangementDetails',
        isPrivate: true,
        componentProps: { currentPaymentPlanType: 'extension' },
        // requiredPermission: CHART_OF_ACCOUNT.LIST,
        exact: true,
    },
    {
        path: '/accounts/organisation/event-calendar/:accountId/:organisationId/',
        component: EventsCalendar,
    },
    //this route object should be the last one in the route for page not found to work correctly
    //add all the other routes above this route object
    {
        path: '',
        component: defaultAppPageNotFound,
        isPublic: true,
        noSidebar: true,
    },
];

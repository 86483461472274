/**
 * Default settings form Component
 *
 * @version 1.0
 */

//import required modules
import React from 'react';
import { Col, Row } from 'antd';
import { Field } from 'redux-form';
import { get, isEmpty, isObject, startCase } from 'lodash';
import { required } from 'includes/utils/form';
import { useTranslation } from 'react-i18next';
import { ZENDESK_TICKET_DEFAULT_TAG, ZENDESK_TICKET_DEFAULT_TAG_BY_TYPES } from 'includes/constants';
import FormField from 'components/shared/FormField';
import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import useZendeskTicketOptions from 'includes/hooks/useZendeskTicketOptions';
import {
    ZENDESK_ADD_COMMENT,
    ZENDESK_CREATE_TICKET,
    ZENDESK_TAGS_BY_EVENT_TYPE,
    ZENDESK_TAGS,
    ZENDESK_TICKET_IS_PUBLIC,
    ZENDESK_TICKET_PRIORITIES,
    ZENDESK_TICKET_PRIORITY,
    ZENDESK_TICKET_STATUS,
    ZENDESK_TICKET_STATUSES,
    ZENDESK_TICKET_TYPE,
    ZENDESK_TICKET_TYPES,
} from 'includes/constants/keys/request';
import useZendeskSettings from 'includes/hooks/useZendeskSettings';

const ZendeskEventTypeFields = props => {
    const { t } = useTranslation();
    const { eventType, includeEventTypeInFieldName, forceShowEventFields } = props;
    const { data: ticketOptions, isLoading: loadingTicketOptions } = useZendeskTicketOptions();
    const { data: zendeskSettings } = useZendeskSettings();

    /**
     * Convert ticket options to FE required options format
     *
     * @param {string} optionKey Option Key to take from ticket options
     *
     * @returns {Array} Ticket Option formatted
     */
    const convertTicketOptionsToFEOptions = optionKey => {
        const ticketTypeOptions = get(ticketOptions, optionKey);
        return !isEmpty(ticketTypeOptions) && isObject(ticketTypeOptions)
            ? Object.keys(ticketTypeOptions).map(key => ({
                  name: startCase(ticketTypeOptions[key]),
                  value: parseInt(key),
              }))
            : [];
    };
    /**
     * Get the localized info
     *
     * @param {string} field Field for which info to be obtained
     * @param {object} params Params
     *
     * @returns {string} Localized info
     */
    const localeInfo = (field, params = {}) =>
        ReactHtmlParser(t(`messages.zendesk.settings.form.input.info.${field}`, { eventType: eventType, ...params }));

    /**
     * Get the localized label
     *
     * @param {string} field Field for which localized label to be obtained
     *
     * @returns {string} Localized label
     */
    const localeLabel = field => t(`messages.zendesk.settings.form.input.label.${field}`);

    /**
     * Set the event type fields
     */
    const eventTypeFields = [
        {
            [ZENDESK_CREATE_TICKET]: {
                type: 'switch',
                info: localeInfo(ZENDESK_CREATE_TICKET),
            },
            [ZENDESK_ADD_COMMENT]: {
                type: 'switch',
                info: localeInfo(ZENDESK_ADD_COMMENT),
            },
        },
        {
            [ZENDESK_TICKET_IS_PUBLIC]: {
                type: 'switch',
                info: localeInfo(ZENDESK_TICKET_IS_PUBLIC),
            },
            [ZENDESK_TICKET_PRIORITY]: {
                type: 'select',
                options: convertTicketOptionsToFEOptions(ZENDESK_TICKET_PRIORITIES),
                required: true,
                info: localeInfo(ZENDESK_TICKET_PRIORITY),
            },
        },
        {
            [ZENDESK_TICKET_STATUS]: {
                type: 'select',
                options: convertTicketOptionsToFEOptions(ZENDESK_TICKET_STATUSES),
                required: true,
                info: localeInfo(ZENDESK_TICKET_STATUS),
            },
            [ZENDESK_TICKET_TYPE]: {
                type: 'select',
                options: convertTicketOptionsToFEOptions(ZENDESK_TICKET_TYPES),
                required: true,
                info: localeInfo(ZENDESK_TICKET_TYPE),
            },
        },
    ];

    return zendeskSettings?.connected || forceShowEventFields ? (
        <>
            <Row gutter={24} className="zendesk-reminder-default-title">
                {localeLabel(`${eventType}_reminder_defaults`)}
            </Row>
            {loadingTicketOptions ? (
                <Loader />
            ) : (
                eventTypeFields.map((eventTypeFieldData, index) => {
                    return (
                        <Row gutter={16} key={index}>
                            {Object.keys(eventTypeFieldData).map((eventTypeField, i) => (
                                <Col span={12} key={`${eventType}-${i}`}>
                                    <Field
                                        type={eventTypeFieldData[eventTypeField].type}
                                        name={
                                            includeEventTypeInFieldName
                                                ? eventType + '_' + eventTypeField
                                                : eventTypeField
                                        }
                                        label={localeLabel(eventType + '_' + eventTypeField)}
                                        hasFeedback
                                        className="form-control"
                                        component={FormField}
                                        validate={eventTypeFieldData[eventTypeField].required ? [required] : null}
                                        options={eventTypeFieldData[eventTypeField].options}
                                        disableEmptyOption
                                        info={eventTypeFieldData[eventTypeField].info}
                                        infoAsToolTip
                                        required={eventTypeFieldData[eventTypeField].required ? true : false}
                                    />
                                </Col>
                            ))}
                        </Row>
                    );
                })
            )}
            {props.includeTagsField ? (
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="select"
                            mode="tags"
                            className="select-tags-mode zendesk-tags"
                            name={ZENDESK_TAGS}
                            label={localeLabel(ZENDESK_TAGS)}
                            component={FormField}
                            info={localeInfo(ZENDESK_TAGS_BY_EVENT_TYPE, {
                                defaultTag: ZENDESK_TICKET_DEFAULT_TAG,
                                defaultTagByEventType: ZENDESK_TICKET_DEFAULT_TAG_BY_TYPES[eventType],
                            })}
                        />
                    </Col>
                </Row>
            ) : null}
        </>
    ) : null;
};

ZendeskEventTypeFields.propTypes = {
    eventType: PropTypes.string,
    includeEventTypeInFieldName: PropTypes.bool,
    includeTagsField: PropTypes.bool,
    forceShowEventFields: PropTypes.bool,
};

export default ZendeskEventTypeFields;

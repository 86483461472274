/**
 * Base API class.
 * Defines the basic things needed in all API requests
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import required module
import axios from 'axios';
import { get, isEmpty } from 'lodash';

import * as errorMappings from '../../constants/mappings/errors';
import * as responseKeys from '../../constants/keys/response';
import * as api from '../../constants/api';
import * as utils from '../../utils';
import * as constants from '../../constants';
import store from 'includes/redux/store';
import { SHOW_NOTIFICATION_MODAL } from 'includes/constants/actionTypes';
import { ACCESS_TOKEN_COOKIE_NAME, API_BASE_URL, API_VERSION_1, WORDPRESS_URL } from 'includes/constants/config';

/**
 * Base API Handler Class
 * Sets the basic data for API request
 */
export default class BaseApiHandler {
    /**
     * Constructor
     * Sets the default headers needed for the API request
     */
    constructor() {
        this.axios = axios.create({});
        this.axios.defaults.headers.common['Accept-Language'] = utils.getLocalStorageValue(constants.USER_LOCALE_KEY);
        this.axios.interceptors.request.use(
            config => {
                return this.preRequestConfig(config);
            },
            error => {
                return Promise.reject(error);
            }
        );
        this.axios.interceptors.response.use(
            config => {
                return this.preRequestConfig(config);
            },
            error => {
                let apiErrors = get(error, errorMappings.DEFAULT_API_ERROR_RESPONSE_STRUCTURE);
                const errorStatusCode = get(error, ['response', 'status']);
                if (apiErrors) {
                    // check if we have invalid or inactive authorization tokens
                    if (
                        responseKeys.INACTIVE_AUTHORIZATION_TOKEN in apiErrors ||
                        responseKeys.INVALID_AUTHORIZATION_TOKEN in apiErrors
                    ) {
                        // redirect to logout page
                        utils.clearLSAndRedirectToAuthPortalLogoutPage();
                        // dispatch the logout action
                        // store.dispatch(
                        //     logoutUser({
                        //         authorizationFailed: true,
                        //         authorizationFailedMessage:
                        //             responseKeys.INACTIVE_AUTHORIZATION_TOKEN in apiErrors
                        //                 ? get(apiErrors, [responseKeys.INACTIVE_AUTHORIZATION_TOKEN, 0])
                        //                 : errorMessages.USER_SESSION_TIMED_OUT,
                        //     })
                        // );
                    } else if (responseKeys.TWO_FACTOR_AUTHENTICATION_NOT_VERIFIED in apiErrors) {
                        utils.redirectToAuthPortal();
                    } else if (responseKeys.PERMISSION_ERROR in apiErrors) {
                        // show error modal if permission error
                        store.dispatch({
                            type: SHOW_NOTIFICATION_MODAL,
                            message: apiErrors.permission,
                            messageType: constants.ERROR,
                        });
                    } else if (errorStatusCode === 401) {
                        // if the status is 400 redirect to unauthorized page
                        utils.redirect('/unauthorized');
                        // if the status is 404 redirect to page-not-found page
                    } else if (errorStatusCode === 404) {
                        utils.redirect('/page-not-found');
                        // if the errors include under_maintenance error then redirect to under-maintenance page
                    } // else if (authorizationTokenErrors.indexOf('under_maintenance') > -1) {
                    //     redirect('/under-maintenance');
                    // }
                }
                return Promise.reject(error);
            }
        );
    }

    /**
     * Get the API Url for request
     * Construct the API URL based on the version and api type
     *
     * @param {string} apiType API to call
     * @param {object} replaceParams Replace Params Default {}
     * @param {string} version API VersionDefault API_VERSION_1
     *
     * @returns  {string}    apiUrl     API URL
     */
    getApiUrl(apiType, replaceParams = {}, version = API_VERSION_1) {
        //get the api url slug
        let apiUrlSlug = api[apiType];

        //if there are any replace params specified, replace it
        if (!isEmpty(replaceParams)) {
            apiUrlSlug = utils.multiStringReplace(apiUrlSlug, replaceParams);
        }
        return `${API_BASE_URL}${version}/${apiUrlSlug}`;
    }

    /**
     * Get the WordPress API Url for request
     * Construct the API URL based on the version and api type
     *
     * @param {string} apiType API to call
     * @param {object} replaceParams Replace Params Default {}
     *
     * @returns  {string}    apiUrl     API URL
     */
    getWpApiUrl(apiType, replaceParams = {}) {
        //get the api url slug
        let apiUrlSlug = api[apiType];

        //if there are any replace params specified, replace it
        if (!isEmpty(replaceParams)) {
            apiUrlSlug = utils.multiStringReplace(apiUrlSlug, replaceParams);
        }
        return `${WORDPRESS_URL}/${apiUrlSlug}`;
    }

    /**
     * Modify the request before making API call
     *
     * @param {object} config Config
     *
     * @returns {object} config Config
     */
    preRequestConfig(config) {
        const accessToken = utils.getCookieValue(ACCESS_TOKEN_COOKIE_NAME);
        const accountId = utils.getLocalStorageValue(constants.USER_CURRENT_ACCOUNT_KEY);
        const userAccountId = utils.getValueFormStore('account.userAccountDetails.id');

        if (accessToken) {
            config.headers['Authorization'] = `Token ${accessToken}`;
        }

        if (accountId) {
            config.headers['x-pp-account-id'] = accountId;
        }

        if (userAccountId) {
            config.headers['x-pp-user-account-id'] = userAccountId;
        }

        return config;
    }
}

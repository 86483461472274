/**
 * Utility functions
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import history module
import { DOMAIN } from '../constants/config';
import Cookies from 'universal-cookie';
import { get, find } from 'lodash';
import moment from 'moment';

import { PORTAL_AUTH_SLUG, USER_ROLE_ADMIN, USER_ROLE_ADMIN_MANAGER, USER_ROLE_DEBT_COLLECTOR } from '../constants';
import history from '../exports/history';
import store from '../../includes/redux/store';
import * as successMappings from '../constants/mappings/success';
import * as responseKeys from '../constants/keys/response';

/**
 * Create a JSON object from the query parameters
 *
 * @param {string} url  Url to Parse
 *
 * @returns {JSON}    params    params as a object hash
 */
export const buildQueryParamsObject = url => {
    let params = {};

    if (url) {
        url.slice(1) // remove "?"
            .split(/&/) // split at "&"
            .map(paramString => paramString.split(/=/)) // split individual query parameter at "="
            .forEach(([key, value]) => {
                params[key] = value;
            }); // set the value in params object
    }
    return params;
};

/**
 * Append query params to the passed url and return
 *
 * @param {string} url  URL to Parse
 * @param {object} params  Params to be appended
 *
 * @returns {JSON}    params    params as a object hash
 */
export const appendQueryParams = (url, params) => {
    let appendedUrlString = `${url}?`;
    Object.entries(params).forEach(([key, value]) => {
        if (appendedUrlString[appendedUrlString.length - 1] !== '?') appendedUrlString += '&';
        appendedUrlString += `${key}=${value}`;
    });
    return appendedUrlString;
};

/**
 * Convert object errors to array errors
 *
 * @param   {Array|object}    errors   Errors
 *
 * @returns {Array}           Array of errors
 */
export function convertObjectErrorsToArray(errors) {
    return Object.values(errors);
}

/**
 * Get the value stored in cookie for a key
 *
 * @param   {string}    key     Key to get value from cookie
 *
 * @returns {string}            Cookie value
 */
export function getCookieValue(key) {
    return new Cookies().get(key);
}

/**
 * Get Default image class
 *
 * @param   {object}    data                            Data Object
 * @param   {string}    imageDefaultDimensionKey        Image Default dimension key
 * @param   {string}    imageOriginalDimensionKey       Image Original dimension key
 * @param   {string}    dimension                       Image Dimension
 *
 * @returns  boolean|string                             true|''
 */
export function getDefaultImageClass(data, imageDefaultDimensionKey, imageOriginalDimensionKey, dimension) {
    if (isDefaultImageLoaded(data, imageDefaultDimensionKey, imageOriginalDimensionKey)) {
        return 'is-default-image-' + dimension + '-loaded';
    }

    return '';
}

/**
 * Get the value stored in key in local storage
 *
 * @param   {string}    key     Key to get value from local storage
 *
 * @returns {string}            Local Storage Value
 */
export function getLocalStorageValue(key) {
    return localStorage.getItem(key);
}

/**
 * Get the image from the data object
 *
 * @param   {object}    data                            Data Object
 * @param   {string}    imageDefaultDimensionKey        Image Default dimension key
 * @param   {string}    imageOriginalDimensionKey       Image Original dimension key
 * @param   {string}    defaultImage                    Default Image
 *
 * @returns  {string}    Image parse from data or default image
 */
export function getImageFromData(data, imageDefaultDimensionKey, imageOriginalDimensionKey, defaultImage = '') {
    // set a default image if not supplied
    if (!defaultImage) {
        defaultImage = require('../../assets/images/user-avatar.svg');
    }

    return (
        get(data, get(responseKeys, imageDefaultDimensionKey)) ||
        get(data, get(responseKeys, imageOriginalDimensionKey)) ||
        defaultImage
    );
}

/**
 * Get the pagination data from the API response
 *
 * @param {object} action     Action Object
 * @param {boolean} fromSlice Whether the call to this function is made from slice or not. Default false
 *
 * @returns {object} pagination Data
 */
export function getPaginationData(action, fromSlice = false) {
    if (fromSlice) {
        return {
            currentPage: get(action.payload, successMappings.GET_PAGINATION_SLICED_CURRENT_PAGE),
            pageSize: get(action.payload, successMappings.GET_PAGINATION_SLICED_PAGE_SIZE),
            totalCount: get(action.payload, successMappings.GET_PAGINATION_SLICED_TOTAL_COUNT),
            totalPages: get(action.payload, successMappings.GET_PAGINATION_SLICED_TOTAL_PAGES),
        };
    } else {
        return {
            currentPage: get(action.result, successMappings.GET_PAGINATION_CURRENT_PAGE),
            pageSize: get(action.result, successMappings.GET_PAGINATION_PAGE_SIZE),
            totalCount: get(action.result, successMappings.GET_PAGINATION_TOTAL_COUNT),
            totalPages: get(action.result, successMappings.GET_PAGINATION_TOTAL_PAGES),
        };
    }
}

/**
 * Get the current year
 *
 * @returns {number}    Year
 */
export function getYear() {
    return new Date().getFullYear();
}

/**
 * Check if the default image is loaded
 * Check whether the passed data object has an image
 *
 * @param   {object}    data                            Data Object
 * @param   {string}    imageDefaultDimensionKey        Image Default dimension key
 * @param   {string}    imageOriginalDimensionKey       Image Original dimension key
 *
 * @returns  boolean true|false
 */
export function isDefaultImageLoaded(data, imageDefaultDimensionKey, imageOriginalDimensionKey) {
    return !get(
        data,
        get(responseKeys, imageDefaultDimensionKey),
        get(data, get(responseKeys, imageOriginalDimensionKey))
    );
}

/**
 * Replace the contents in a string with another set of strings
 * Allows replace of multiple contents in the string
 *
 * @param   {string}    str         String to Replaces
 * @param   {object}    mapObject   Mapped Key:Value Pairs to replace
 *
 * @returns {string}                Updated String
 */
export function multiStringReplace(str, mapObject) {
    //if mapped object is empty return the string
    if (!mapObject) return str;

    let re = new RegExp(Object.keys(mapObject).join('|'), 'gi');
    return str.replace(re, function(matched) {
        return mapObject[matched];
    });
}

/**
 * Redirect to the specified location
 *
 * @param   {string}    location        Location to redirect
 * @param   {boolean}   isExternalUrl   Whether the redirect is for external url
 * @param   {*}         state           State data
 */
export function redirect(location, isExternalUrl = false, state = {}) {
    if (isExternalUrl === true) {
        window.location.href = location;
    } else {
        history.push(location, state);
    }
}

/**
 * Remove the cookie
 *
 * @param   {string}    key     cookie to remove
 */
export function removeCookie(key) {
    new Cookies().remove(key);
}

/**
 * Remove all the cookies
 */
export function removeCookies(keys) {
    let cookie = new Cookies();
    // eslint-disable-next-line no-unused-vars
    for (let key of keys) {
        cookie.remove(key);
    }
}

/**
 * Remove the local storage value
 *
 * @param   {string}    key    Key to remove from local storage
 */
export function removeLocalStorage(key) {
    localStorage.removeItem(key);
}

/**
 * Remove the local storage values in the specified keys
 *
 * @param   {Array}    keys    Keys to remove from local storage
 */
export function removeLocalStorageAll(keys) {
    // eslint-disable-next-line no-unused-vars
    for (let key of keys) {
        localStorage.removeItem(key);
    }
}

/**
 * Set the passed data in cookie
 *
 * @param   {object}    data    Data to set in cookie
 */
export function setCookie(data) {
    const keys = Object.keys(data);
    let cookie = new Cookies();

    // eslint-disable-next-line no-unused-vars
    for (let key of keys) {
        cookie.set(key, data[key], { path: '/', domain: DOMAIN });
    }
}

/**
 * Set the passed data in local storage
 *
 * @param   {object}    data    Data to set in local storage
 */
export function setDataInLocalStorage(data) {
    const keys = Object.keys(data);

    // eslint-disable-next-line no-unused-vars
    for (let key of keys) {
        localStorage.setItem(key, data[key]);
    }
}

/* Get the value at the passed path from the redux store
 *
 * @param   {string}    path        Path to in the redux store
 * @param   {*}         fallback    Fallback value if the value is undefined
 *
 * @returns {*}         value at the specified path
 */
export function getValueFormStore(path, fallback) {
    return get(store.getState(), path, fallback);
}

/*
 * Replace a value in array with the specified value
 *
 * @param   {Array}    input        Input Array
 * @param   {mixed}   value         value which needs to be searched
 * @param   {mixed}   newValue      value which needs to be replaced
 *
 * @returns {Array}       Updated array
 */
export function replaceValueInArray(input, key, value) {
    return input.map(function(item) {
        return item === key ? value : item;
    });
}

/**
 * Returns array of years from current date to supplied number range
 */
export const getYearsRange = (range = 10) => {
    const years = [];
    const dateStart = moment();
    const dateEnd = moment().add(range, 'y');
    while (dateEnd.diff(dateStart, 'years') >= 0) {
        let val = dateStart.format('YYYY');
        years.push({ value: val, name: val });
        dateStart.add(1, 'year');
    }
    return years;
};

/**
 * Returns localised currency from number
 *
 * @param {number} num
 * @param {string} locale
 * @param {string} style
 * @param {string} currency
 */
export const formatShortAmountToLocaleCurrency = (num = 0, locale = 'en-AU', style = 'currency', currency = 'AUD') => {
    let formattedCurrency = num;
    if (num < 10000) {
        formattedCurrency = parseFloat(formattedCurrency);
        formattedCurrency = formattedCurrency.toLocaleString(locale, { style, currency });
    } else {
        formattedCurrency = '$' + formattedCurrency;
    }

    return formattedCurrency;
};

/**
 * Returns localised currency from number
 *
 * @param {number} num
 * @param {string} locale
 * @param {string} style
 * @param {string} currency
 */
export const getLocaleCurrency = (num = 0, locale = 'en-AU', style = 'currency', currency = 'AUD') => {
    num = parseFloat(num);
    return num.toLocaleString(locale, { style, currency });
};

/**
 * Modify URL based on current user's role
 *
 * @param {string} url url to be modified
 * @param {string} role current user's role slug
 */
export const modifyUrlBasedOnRole = (url, role) => {
    switch (role) {
        case USER_ROLE_ADMIN:
        case USER_ROLE_ADMIN_MANAGER:
            return `/admin${url}`;
        case USER_ROLE_DEBT_COLLECTOR:
            return `/debt-collector${url}`;
        default:
            return url;
    }
};

/**
 * Checks if the current screen is mobile
 *
 * @param {string} breakpoint breakpoint - default value is 767
 */
export const isMobile = (breakpoint = 767) => window.innerWidth < breakpoint;

/**
 * Checks if the given HTML element has a parent with given class name
 *
 * @param {HTMLElement} element the node to be checked
 * @param {*} classname CSS classname to be looked for
 * @returns {boolean}
 */
export function hasSomeParentWithClass(element, classname) {
    if (!element) return;

    if (typeof element.className === 'string' && (element.className || '').split(' ').indexOf(classname) >= 0)
        return true;
    return element.parentNode ? hasSomeParentWithClass(element.parentNode, classname) : false;
}

/**
 * Check if number is odd
 *
 * @param {number} n Number
 *
 * @returns {boolean} true|false
 */
export const isOdd = n => Math.abs(n % 2) === 1;

/**
 * Convert date string format
 * Convert from one format to another format
 *
 * @param {string} dateString Date string to be converted
 * @param {string} currentFormat Current date format. Default 'DD-MM-YYYY'
 * @param {string} requiredFormat Format to which date is to be converted. Default 'YYYY-MM-DD'
 *
 * @returns {string | null} date string in acceptable format or `null` if empty
 */
export const convertDateStringFormat = (dateString, currentFormat = 'DD-MM-YYYY', requiredFormat = 'YYYY-MM-DD') =>
    dateString ? moment(dateString, currentFormat).format(requiredFormat) : null;

/**
 * Replace spaces in the content with the replace character
 *
 * @param {string} content Content to be replaced
 * @param {string} replaceCharacter. Replace character. Default '-'
 * @param {boolean} toLower. Whether to make the string lower after conversion. Default true
 *
 * @returns {string} content. Updated content
 */
export const replaceSpaces = (content, replaceCharacter = '-', toLower = true) => {
    if (content) {
        content = content.replace(/\s+/g, replaceCharacter);
    }

    if (content && toLower) {
        content = content.toLowerCase();
    }

    return content;
};

export const snakeCaseToWords = str => str.replace(/_/g, ' ');

/**
 * Creates a dict(from the API response) to make permission lookup faster
 *
 * @param {Array} permissionArray
 * @returns
 */
export const createPermissionDict = permissionArray => {
    try {
        const permissionObj = permissionArray.reduce((map, obj) => ((map[obj.permission] = obj), map), {});
        return permissionObj;
    } catch {
        return {};
    }
};

/**
 * Get a list of missing permission labels
 *
 * @param {Array} dependentPermissions Permissions that are required for other permissions to work
 * @param {Array} allowedPermissions Permissions that are currently allowed
 * @param {Array} permissionSet All Permissions object list
 * @returns {Array}
 */
export const getMissingPermissionLabels = (dependentPermissions, allowedPermissions, permissionSet) => {
    const allowedPermissionObjects = allowedPermissions
        .filter(x => find(permissionSet, { permission: x }))
        .map(x => find(permissionSet, { permission: x }));
    const allowedPermissionsSet = new Set(allowedPermissions);

    const missingPermissionLabels = new Map();

    allowedPermissionObjects.forEach(permObj => {
        permObj.dependent_permission.forEach(dp => {
            if (allowedPermissionsSet.has(dp)) return;

            const dpObj = find(permissionSet, { permission: dp });

            missingPermissionLabels.set(
                permObj.label,
                missingPermissionLabels.get(permObj.label)
                    ? [...missingPermissionLabels.get(permObj.label), dpObj.label]
                    : [dpObj.label]
            );
        });
    });

    return Object.fromEntries(missingPermissionLabels.entries());
};

/**
 * Get the user locale
 *
 * @returns string User Locale
 */
export const getUserLocale = () =>
    navigator.userLanguage ||
    (navigator.languages && navigator.languages.length && navigator.languages[0]) ||
    navigator.language ||
    navigator.browserLanguage ||
    navigator.systemLanguage ||
    'en';

/**
 * Get the number formatted using user locale
 *
 * @param {number} number Number to be formatted
 *
 * @returns string Formatted number
 */
export const getLocaleNumber = number => {
    const isNan = isNaN(number);
    const formattedNumber = new Intl.NumberFormat('en', {
        maximumFractionDigits: 2,
    }).format(isNan ? 0 : number);
    return isNan ? formattedNumber.replaceAll('0.00', number) : formattedNumber;
};

/**
 * Format number as currency
 *
 * @param {number} number Number to be formatted as currency
 * @param string currencyCode. Currency Code. Default AUD
 *
 * @returns string Number formatted with currency
 */
export const formatNumberAsCurrency = (number, currencyCode) => {
    const isNan = isNaN(number);
    const formattedNumber = new Intl.NumberFormat('en', {
        style: 'currency',
        currency: currencyCode ?? 'AUD',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 2,
    }).format(isNan ? 0 : number);
    return isNan ? formattedNumber.replaceAll('0.00', number) : formattedNumber;
};

/**
 * Get currency symbol based on user locale
 *
 * @returns string Currency Symbol
 */
export const getCurrencySymbol = (currencyCode = 'AUD') => {
    return new Intl.NumberFormat('en', {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'symbol',
        maximumFractionDigits: 2,
    })
        .format(0)
        .replaceAll('0.00', '')
        .replaceAll('0', '')
        .trim();
};

/**
 * Redirect to specified URL with query params
 *
 * @param   {string}    url     URL to be redirected to
 * @param   {object}    params  object containing key-value pairs of all params
 * @param   {string}    target  window.open target
 *
 */
export const redirectToURLWithParams = (url, params = {}, target = '_self') => {
    const urlObj = new URL(url);
    Object.entries(params).forEach(([key, value]) => {
        urlObj.searchParams.append(key, value);
    });

    window.open(urlObj.href, target);
};

/**
 * Get the auth portal url
 *
 * @returns {string} Auth portal url
 */
export const getAuthPortalUrl = () => {
    return getValueFormStore(
        `settings.siteSettings.portals.${PORTAL_AUTH_SLUG}.url`,
        window.location.protocol + '//' + window.location.host + '/'
    );
};

/**
 * Logout user
 * Redirects user to auth portal's logout page and clears out local storage
 *
 */
export const clearLSAndRedirectToAuthPortalLogoutPage = () => {
    const authPortalUrl = getAuthPortalUrl();
    window.localStorage.clear();
    window.open(`${authPortalUrl}logout`, '_self');
};

/**
 * Redirect user to auth portal
 *
 */
export const redirectToAuthPortal = () => {
    const authPortalUrl = getAuthPortalUrl();
    window.open(`${authPortalUrl}`, '_self');
};

/**
 * Strip HTML tags in string
 *
 * To be used when string contains HTML tags and UI only needs the string
 *
 * @param   {string}    string  string possibly containing HTML tags
 *
 * @returns {string}    stripped string
 */
export const stripHTMLTags = string => {
    let doc = new DOMParser().parseFromString(string, 'text/html');
    return doc.body.textContent || '';
};

// TODO: remove dependency for this method and the method itself
/**
 * Get tailwind card selection classes
 *
 * To be used to get tailwind classes for card elements based on `isSelected` arg
 *
 * @param {boolean} isSelected if the card is selected or not
 *
 */
export const getTailwindBaseStylesForCardSelectElement = (isSelected = false) => {
    let classnames = 'border-2 border-solid cursor-pointer hover:border-lime-300 transition-all duration-300 ';

    if (isSelected) classnames += 'border-lime-500 bg-lime-200 bg-opacity-20';
    else classnames += 'border-gray-200 bg-white';

    return classnames;
};

/**
 * Pad string
 *
 * pad string with char passed
 *
 * @param {string} string string
 * @param {string} char [char=' '] char to be used as padding
 * @param {number} len [len=1] - number of times to repeat char
 */
export const padString = (string, char = ' ', len = 1) => {
    return Array(len + 1).join(char) + string;
};

/**
 * Communication Saga
 *
 * @version 1.0
 * @author Shiny Raj <shinyr@qburst.com>
 */

//import the required modules
import * as actionTypes from '../../../../constants/actionTypes';
import * as constants from '../../../../constants';
import * as successMappings from '../../../../constants/mappings/success';
import BaseSagaHandler from '../../core/base';
import CommunicationApi from '../../../../services/shared/communication';
import { call, takeLatest, all, put } from 'redux-saga/effects';
import { get, isFunction } from 'lodash';

/**
 * Communication Saga Class. Handles the communication related operations
 * */
class Communication extends BaseSagaHandler {
    /**
     * The Communication Watcher Saga
     * Watches the redux actions related to communication and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *communicationWatchers() {
        let context = this;
        yield all([
            yield takeLatest(actionTypes.ADD_COMMUNICATION_TEMPLATE_REQUEST, [context, 'addCommunicationTemplate']),
            yield takeLatest(actionTypes.DELETE_COMMUNICATION_TEMPLATE_REQUEST, [
                context,
                'deleteCommunicationTemplate',
            ]),
            yield takeLatest(actionTypes.GET_ALL_COMMUNICATION_TEMPLATES_REQUEST, [
                context,
                'getCommunicationTemplates',
            ]),
            yield takeLatest(actionTypes.GET_COMMUNICATION_LOG_HISTORY_REQUEST, [
                context,
                'getCommunicationLogHistory',
            ]),
            yield takeLatest(actionTypes.GET_COMMUNICATION_LOG_HISTORY_DETAILS_REQUEST, [
                context,
                'getCommunicationLogHistoryDetails',
            ]),
            yield takeLatest(actionTypes.GET_COMMUNICATION_LOG_STATUSES_REQUEST, [
                context,
                'getCommunicationLogStatuses',
            ]),
            yield takeLatest(actionTypes.GET_COMMUNICATION_TEMPLATE_DETAILS_REQUEST, [
                context,
                'getCommunicationTemplateDetails',
            ]),
            yield takeLatest(actionTypes.GET_COMMUNICATION_TEMPLATE_PREVIEW_REQUEST, [
                context,
                'getCommunicationTemplatePreview',
            ]),
            yield takeLatest(actionTypes.GET_COMMUNICATION_TEMPLATE_TYPES_REQUEST, [
                context,
                'getCommunicationTemplateTypes',
            ]),
            yield takeLatest(actionTypes.GET_COMMUNICATION_TEMPLATES_REQUEST, [context, 'getCommunicationTemplates']),
            yield takeLatest(actionTypes.GET_COMMUNICATION_TYPES_REQUEST, [context, 'getCommunicationTypes']),
            yield takeLatest(actionTypes.UPDATE_COMMUNICATION_TEMPLATE_REQUEST, [
                context,
                'updateCommunicationTemplate',
            ]),
        ]);
    }

    /**
     * Add communication template
     *
     * @param {object} payload Payload
     * @param {Function} callback Callback function
     *
     * @yields {object} Add communication template response
     */
    *addCommunicationTemplate({ payload, callback }) {
        try {
            const result = yield call(CommunicationApi.addCommunicationTemplate, payload);
            if (callback && isFunction(callback)) {
                callback();
            }
            yield put({
                type: actionTypes.SHOW_APP_NOTIFICATION_MESSAGE,
                message: get(result, successMappings.API_RESPONSE_MESSAGE_STRUCTURE),
                messageType: constants.SUCCESS,
            });
            yield put({ type: actionTypes.ADD_COMMUNICATION_TEMPLATE_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.ADD_COMMUNICATION_TEMPLATE_FAILURE, errors });
            yield this.stopFormSubmit(
                errors,
                constants.ADD_EDIT_TEMPLATE_FORM,
                constants.ADD_TEMPLATE_DEFAULT_ERROR,
                constants.ADD_TEMPLATE_DEFAULT_ERROR
            );
        }
    }

    /**
     * Delete communication template
     *
     * @param {object} payload Payload
     *
     * @yields {object} Delete communication template response
     */
    *deleteCommunicationTemplate({ payload }) {
        try {
            const result = yield call(CommunicationApi.deleteCommunicationTemplate, payload);
            yield put({ type: actionTypes.DELETE_COMMUNICATION_TEMPLATE_SUCCESS, result });
            yield this.showNotificationModal(result, true);
        } catch (errors) {
            yield this.showNotificationModal(
                errors,
                false,
                constants.DELETE_TEMPLATE_DEFAULT_ERROR,
                constants.DELETE_TEMPLATE_DEFAULT_ERROR
            );
            yield put({ type: actionTypes.DELETE_COMMUNICATION_TEMPLATE_FAILURE, errors });
        }
    }

    /**
     * Get the communication log history
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get communication log  response
     */
    *getCommunicationLogHistory({ payload }) {
        try {
            const result = yield call(CommunicationApi.getCommunicationLogHistory, payload);
            yield put({ type: actionTypes.GET_COMMUNICATION_LOG_HISTORY_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_COMMUNICATION_LOG_HISTORY_FAILURE, errors });
        }
    }

    /**
     * Get the communication history details
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get communication history details response
     */
    *getCommunicationLogHistoryDetails({ payload }) {
        try {
            const result = yield call(CommunicationApi.getCommunicationLogHistoryDetails, payload);
            yield put({ type: actionTypes.GET_COMMUNICATION_LOG_HISTORY_DETAILS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_COMMUNICATION_LOG_HISTORY_DETAILS_FAILURE, errors });
        }
    }

    /**
     * Get communication log statuses
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get communication log statuses response
     */
    *getCommunicationLogStatuses({ payload }) {
        try {
            const result = yield call(CommunicationApi.getCommunicationLogStatuses, payload);
            yield put({ type: actionTypes.GET_COMMUNICATION_LOG_STATUSES_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_COMMUNICATION_LOG_STATUSES_FAILURE, errors });
        }
    }

    /**
     * Get communication templates
     *
     * @param {object} action Action
     *
     * @yields {object} Get communication templates response
     */
    *getCommunicationTemplates(action) {
        try {
            const result = yield call(CommunicationApi.getCommunicationTemplates, action.payload);
            yield put({
                type:
                    action.type === actionTypes.GET_ALL_COMMUNICATION_TEMPLATES_REQUEST
                        ? actionTypes.GET_ALL_COMMUNICATION_TEMPLATES_SUCCESS
                        : actionTypes.GET_COMMUNICATION_TEMPLATES_SUCCESS,
                result,
            });
        } catch (errors) {
            yield put({
                type:
                    action.type === actionTypes.GET_ALL_COMMUNICATION_TEMPLATES_REQUEST
                        ? actionTypes.GET_ALL_COMMUNICATION_TEMPLATES_FAILURE
                        : actionTypes.GET_COMMUNICATION_TEMPLATES_FAILURE,
                errors,
            });
        }
    }

    /**
     * Get communication template details
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get communication template details response
     */
    *getCommunicationTemplateDetails({ payload }) {
        try {
            const result = yield call(CommunicationApi.getCommunicationTemplateDetails, payload);
            yield put({ type: actionTypes.GET_COMMUNICATION_TEMPLATE_DETAILS_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_COMMUNICATION_TEMPLATE_DETAILS_FAILURE, errors });
            yield this.showNotificationModal(
                errors,
                false,
                constants.GET_TEMPLATE_DEFAULT_ERROR,
                constants.GET_TEMPLATE_DEFAULT_ERROR
            );
        }
    }

    /**
     * Get communication template preview
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get communication template preview response
     */
    *getCommunicationTemplatePreview({ payload }) {
        try {
            const result = yield call(CommunicationApi.getCommunicationTemplatePreview, payload);
            yield put({ type: actionTypes.GET_COMMUNICATION_TEMPLATE_PREVIEW_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_COMMUNICATION_TEMPLATE_PREVIEW_FAILURE, errors });
        }
    }

    /**
     * Get communication template types
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get communication template types response
     */
    *getCommunicationTemplateTypes({ payload }) {
        try {
            const result = yield call(CommunicationApi.getCommunicationTemplateTypes, payload);
            let templateTypes = get(result, successMappings.COMMUNICATION_TEMPLATE_TYPES);
            templateTypes = templateTypes.map(type => ({ ...type, label: type.type, value: type.id }));
            yield put({
                type: actionTypes.GET_COMMUNICATION_TEMPLATE_TYPES_SUCCESS,
                templateTypes,
            });
        } catch (errors) {
            yield put({ type: actionTypes.GET_COMMUNICATION_TEMPLATE_TYPES_FAILURE, errors });
        }
    }

    /**
     * Get communication types
     *
     * @param {object} payload Payload
     *
     * @yields {object} Get communication types response
     */
    *getCommunicationTypes({ payload }) {
        try {
            const result = yield call(CommunicationApi.getCommunicationTypes, payload);
            yield put({ type: actionTypes.GET_COMMUNICATION_TYPES_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.GET_COMMUNICATION_TYPES_FAILURE, errors });
        }
    }

    /**
     * Update communication template
     *
     * @param {object} payload Payload
     * @param {Function} callback Callback function
     *
     * @yields {object} Get communication types response
     */
    *updateCommunicationTemplate({ payload, callback }) {
        try {
            const result = yield call(CommunicationApi.updateCommunicationTemplate, payload);
            if (callback && isFunction(callback)) {
                callback();
            }
            yield this.showNotificationModal(result, true);
            yield put({ type: actionTypes.UPDATE_COMMUNICATION_TEMPLATE_SUCCESS, result });
        } catch (errors) {
            yield put({ type: actionTypes.UPDATE_COMMUNICATION_TEMPLATE_FAILURE, errors });
            yield this.stopFormSubmit(
                errors,
                constants.ADD_EDIT_TEMPLATE_FORM,
                constants.UPDATE_TEMPLATE_DEFAULT_ERROR,
                constants.UPDATE_TEMPLATE_DEFAULT_ERROR
            );
        }
    }
}
//export the communication saga
export default new Communication().forkAllWatcherFunctions();

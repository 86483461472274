/**
 * WhatsApp template Form fields
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com>
 */

//import required modules
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field } from 'redux-form';
import { Row, Col, Icon } from 'antd';
import { useTranslation } from 'react-i18next';

import * as formValidations from '../../../../includes/utils/form';
import Button from '../../Button';
import FormField from '../../../shared/FormField';
import Loader from '../../../shared/Loader';
import { showNotificationPopup } from 'includes/redux/actions/shared/common';
import PermissionGuard from 'components/PermissionGuard';
import { REMINDER } from 'includes/constants/permissions';

const WhatsAppFields = props => {
    //message reference
    const message = useRef(null);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    return (
        <div>
            {props.isLoading ? (
                <Loader />
            ) : (
                <section>
                    <div className="details-wrapper">
                        <Row gutter={16} className="settings-body">
                            <Col span={40} className="right-align auto-width preview-placeholder-button-holder">
                                {props.message ? (
                                    <PermissionGuard requiredPermission={REMINDER.PREVIEW}>
                                        <Button
                                            className="blue-bg-button preview-button"
                                            filled
                                            onClick={() => props.showPreview()}
                                        >
                                            <Icon type="eye" />
                                            Preview
                                        </Button>
                                    </PermissionGuard>
                                ) : (
                                    ''
                                )}
                            </Col>
                            <Col
                                className="template-textarea"
                                onClick={e => {
                                    dispatch(
                                        showNotificationPopup(
                                            t('customerMessages.reminders.send.notificationPopup.whatsapp')
                                        )
                                    );
                                    e.stopPropagation();
                                }}
                            >
                                <Field
                                    ref={message}
                                    type="textarea"
                                    name="message"
                                    label="Message"
                                    component={FormField}
                                    validate={[formValidations.required]}
                                    onChange={e => {
                                        props.setMessage(e.target.value);
                                    }}
                                    disabled
                                    required
                                />
                            </Col>
                        </Row>
                    </div>
                </section>
            )}
        </div>
    );
};

WhatsAppFields.propTypes = {
    change: PropTypes.func,
    disableBody: PropTypes.bool,
    isDetailsUpdating: PropTypes.bool,
    isLoading: PropTypes.bool,
    message: PropTypes.string,
    setMessage: PropTypes.func,
    showPreview: PropTypes.func,
    t: PropTypes.func,
};

export default WhatsAppFields;

/**
 * Payment Gateway Add/Edit form
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

// import required modules
import { React, PropTypes, withTranslation, useSelector } from '../../../includes/exports/react';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';

import * as constants from '../../../includes/constants';
import * as formValidations from '../../../includes/utils/form';
import * as requestKeys from '../../../includes/constants/keys/request';
import Button from '../../shared/Button';
import FormField from '../../shared/FormField';

const PaymentGatewayAddEditForm = props => {
    const isUpdating = useSelector(state => state.paymentGateways.loadingPaymentGatewayDetailsUpdate);
    /**
     * Generate the label from language file
     *
     * @param   {string}    field   Field for which locale is to be generated
     *
     * @returns {string}    Generated Locale label
     */
    const localeLabel = field => props.t(`adminMessages.paymentGateways.form.input.label.${field}`);

    return (
        <div className="my-profile-detail">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.NAME}
                            label={localeLabel(requestKeys.NAME)}
                            disabled
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.ACCOUNT_NAME}
                            label={localeLabel(requestKeys.ACCOUNT_NAME)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.LABEL}
                            label={localeLabel(requestKeys.LABEL)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.PAY_BUTTON_TEXT}
                            label={localeLabel(requestKeys.PAY_BUTTON_TEXT)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.USERNAME}
                            label={localeLabel(requestKeys.USERNAME)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[]}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.CLIENT_ID}
                            label={localeLabel(requestKeys.CLIENT_ID)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[]}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.PUBLIC_KEY}
                            label={localeLabel(requestKeys.PUBLIC_KEY)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[]}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.PRIVATE_KEY}
                            label={localeLabel(requestKeys.PRIVATE_KEY)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[]}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="text"
                            name={requestKeys.WEBHOOK_SECRET_KEY}
                            label={localeLabel(requestKeys.WEBHOOK_SECRET_KEY)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[]}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            step="any"
                            type="number"
                            name={requestKeys.SERVICE_CHARGE}
                            label={localeLabel(requestKeys.SERVICE_CHARGE)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required]}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            step="any"
                            type="number"
                            name={requestKeys.TRANSACTION_CHARGE}
                            label={localeLabel(requestKeys.TRANSACTION_CHARGE)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            step="any"
                            type="number"
                            name={requestKeys.TRANSACTION_CHARGE_PERCENTAGE}
                            label={localeLabel(requestKeys.TRANSACTION_CHARGE_PERCENTAGE)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            validate={[formValidations.required]}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            step="any"
                            type="number"
                            name={requestKeys.GST}
                            label={localeLabel(requestKeys.GST)}
                            hasFeedback
                            component={FormField}
                            validate={[formValidations.required]}
                        />
                    </Col>
                    <Col span={12}>
                        <Field
                            type="switch"
                            name={requestKeys.IS_ACTIVE}
                            label={localeLabel(requestKeys.IS_ACTIVE)}
                            className="form-group"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Field
                            type="switch"
                            name={requestKeys.IS_OAUTH_ENABLED}
                            label={localeLabel(requestKeys.IS_OAUTH_ENABLED)}
                            className="form-group"
                            component={FormField}
                        />
                    </Col>
                </Row>
            </Form>
            <Form layout="vertical" className="edit-form-update" onSubmit={props.handleSubmit}>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <Button
                        htmlType="submit"
                        big
                        filled
                        loading={isUpdating}
                        submittingButtonLabel={props.t(
                            `customerMessages.editContact.form.submit.buttonSubmittingLabel.${
                                props.isAdd ? 'add' : 'edit'
                            }`
                        )}
                        className="register-btn"
                    >
                        {props.t(
                            `customerMessages.editContact.form.submit.buttonLabel.${props.isAdd ? 'add' : 'edit'}`
                        )}
                    </Button>
                </Form.Item>
                <div className="clearfix" />
            </Form>
        </div>
    );
};

/**
 * Proptypes
 */
PaymentGatewayAddEditForm.propTypes = {
    t: PropTypes.func,
    isAdd: PropTypes.bool,
    error: PropTypes.string,
    handleSubmit: PropTypes.func,
};

export default reduxForm({
    form: constants.PAYMENT_GATEWAY_FORM,
})(withTranslation()(PaymentGatewayAddEditForm));

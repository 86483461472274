/**
 * Debt age graph Component
 *
 * @version 1.0
 */

//import required modules
import { React } from '../../../../includes/exports/react';
import { debtAgeGraphOptions } from '../../../../includes/charts/debtAge';
import { isEmpty } from 'lodash';
import Loader from '../../../shared/Loader';
import PropTypes from 'prop-types';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';

/**
 * Debt Age graph Component
 */
const DebtAge = props => {
    const getNumberFormattedAsCurrency = useCurrencyFormatter();
    /**
     * Get the debt age graph options
     * Adds the data points
     **/
    const getDebtAgeGraphOptions = () => {
        if (!isEmpty(props.debtAgeData)) {
            let countOfAccounts = 0;
            debtAgeGraphOptions.data[0].dataPoints = Object.keys(props.debtAgeData)
                .reverse()
                .map(function(key) {
                    if (parseInt(props.debtAgeData[key].accounts.count) > countOfAccounts) {
                        countOfAccounts = parseInt(props.debtAgeData[key].accounts.count);
                    }
                    return {
                        amount: parseInt(props.debtAgeData[key].accounts.amount),
                        amountShort: props.debtAgeData[key].accounts.amount_short,
                        label: props.debtAgeData[key].label,
                        y: parseInt(props.debtAgeData[key].accounts.count),
                    };
                });

            debtAgeGraphOptions.toolTip = {
                borderThickness: 0,
                cornerRadius: 5,
                contentFormatter: e =>
                    `<div class="chart-tooltip">${e.entries[0].dataPoint.y} ${
                        e.entries[0].dataPoint.y > 1 ? 'accounts' : 'account'
                    } with overdue of ${getNumberFormattedAsCurrency(e.entries[0].dataPoint.amountShort)}
                    </div>`,
            };
            // if count of accounts are less than 5, set interval to 1
            if (countOfAccounts < 5) {
                debtAgeGraphOptions.axisY.interval = 1;
            }
        }
        return debtAgeGraphOptions;
    };

    /**
     * Render the component
     */
    return (
        <div className="half-wrapper right-align debt-age-wrapper">
            {props.isGraphDataLoading ? (
                <Loader />
            ) : !isEmpty(props.debtAgeData) ? (
                <props.canvasJSChart options={getDebtAgeGraphOptions()} />
            ) : (
                <props.infoMessage message="No Data to show" />
            )}
        </div>
    );
};

/**
 * Proptypes
 */
DebtAge.propTypes = {
    debtAgeData: PropTypes.object,
    isGraphDataLoading: PropTypes.bool,
    t: PropTypes.func,
};

export default DebtAge;

/**
 * Customer Nav Header Component
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

//import the required modules
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import get from 'lodash/get';

import * as responseKeys from '../../../../includes/constants/keys/response';
import NavHeader from '../NavHeader';
import OrganisationSync from './OrganisationSync';
import TryDemoCompany from './TryDemoCompany';
import SelectOrganisationPaymentAccount from './SelectOrganisationPaymentAccount';
import Button from '../../Button';
import PermissionGuard from 'components/PermissionGuard';
import {
    ACCOUNT_INFORMATION,
    ACCOUNTING_SOFTWARE,
    ORGANISATION,
    REMINDER,
    DASHBOARD,
    CONTACT,
    CAMPAIGN,
    CONTACT_MANAGER,
    DEBTOR_ACCOUNT,
    EXPORT,
    FOLLOWUPS,
    ZENDESK,
    CHART_OF_ACCOUNT,
    INVENTORY,
    TAX,
    USER,
    ORGANISATION_CURRENCY,
    CONTACT_TYPE,
    PAYMENT_ARRANGEMENT,
    SUBSCRIPTION,
    AUDIT_LOG,
    REPORTING,
    ORDER,
    PAYMENT_GATEWAY,
} from 'includes/constants/permissions';
import { clearLSAndRedirectToAuthPortalLogoutPage, getAuthPortalUrl, redirect } from 'includes/utils';
import { useCurrentRoleSlug } from 'includes/utils/hooks';
import { PORTAL_DEBT_COLLECTION_SLUG, USER_ROLE_CUSTOMER } from 'includes/constants';
import useUserHasAccessPermission from 'includes/hooks/useUserHasAccessPermission';
import ExternalSVG from 'assets/images/external.svg';

const CustomerNavHeader = () => {
    const [navVisibleStatus, changeNavVisibleStatus] = useState(false);
    const [openPopover, setOpenPopover] = useState('');
    const userHasAccessToChartOfAccountEdit = useUserHasAccessPermission(CHART_OF_ACCOUNT.EDIT);

    const selectedOrganisation = useSelector(state => state.organisation.selectedOrganisation);
    const userDetails = useSelector(state => state.user.userDetails);
    const accountDetails = useSelector(state => state.account.accountDetails);
    const selectedOrganisationId = useSelector(state => state.organisation.selectedOrganisationId);
    const currentRoleSlug = useCurrentRoleSlug();

    const setNavVisibleStatus = status => {
        setOpenPopover('');
        changeNavVisibleStatus(status);
    };

    const siteSettings = useSelector(state => state.settings.siteSettings);

    const { debtCollectorPortalLabel, debtCollectorPortalUrl } = useMemo(() => {
        if (!siteSettings || !accountDetails) return { debtCollectorPortalLabel: '', debtCollectorPortalUrl: '' };

        const portal = siteSettings.portals[PORTAL_DEBT_COLLECTION_SLUG];
        const portalUrlObj = new URL(`${portal.url}auth`);
        portalUrlObj.searchParams.append('account_id', accountDetails?.id ?? '');

        return { debtCollectorPortalLabel: portal.name, debtCollectorPortalUrl: portalUrlObj.href };
    }, [siteSettings]);

    const sideNavs = [
        'my-profile',
        'my-account',
        'users',
        'orders',
        'exports',
        'subscription',
        'logout',
        'plans',
        'connect',
    ];

    /**
     * Get the user avatar
     * Checks if the user has an avatar of the specified 45x45 dimension, if not check for original user avatar
     * if not get the current default specified
     */
    const getUserAvatar = () => {
        return (
            get(userDetails, responseKeys.USER_AVATAR_45X45) ||
            get(userDetails, responseKeys.USER_AVATAR_ORIGINAL) ||
            require('../../../../assets/images/user-avatar.svg')
        );
    };

    /**
     * Get the organisation logo
     * Checks if the organisation has a logo of the specified dimension, if not check for original organisation logo
     * if not get the current default specified
     */
    const getOrganisationLogo = organisation =>
        get(organisation, responseKeys.ORGANISATION_LOGO_45X45) ||
        get(organisation, responseKeys.ORGANISATION_LOGO_ORIGINAL) ||
        require('../../../../assets/images/organisation_avatar.svg');

    const subscription = get(accountDetails, 'subscription');
    const tryDemoCompanySubmenu = () => {
        const demoCompanyOptions = get(accountDetails, 'demo_company');
        const subscriptionExpiresIn = get(accountDetails, ['subscription', 'expires_in'], '');
        const isBusinessNameUpdated = get(accountDetails, ['business_name_updated'], '');
        const isTestAccount = get(accountDetails, 'is_test_account', false);

        if (
            (isBusinessNameUpdated &&
                get(demoCompanyOptions, 'can_try') &&
                !get(demoCompanyOptions, 'tried') &&
                subscriptionExpiresIn) ||
            isTestAccount
        ) {
            return [
                {
                    slug: 'try-demo-company',
                    children: <TryDemoCompany key="try-demo-company-submenu" />,
                },
            ];
        }

        return [];
    };

    const accountingSoftwareSlug = get(selectedOrganisation, ['accounting_software_data', 'slug']);

    const organisationSettingSubMenu = selectedOrganisationId
        ? [
              {
                  slug: 'orgSettings',
                  title: 'Organisation Settings',
                  path: '/organisation/settings',
                  requiredPermission: ORGANISATION.SETTINGS.EDIT,
              },
          ]
        : [];

    const reportingSubmenu = selectedOrganisationId
        ? [
              {
                  slug: 'reporting',
                  title: 'Reporting',
                  path: '/reporting',
                  hasEditPermissionCheck: true,
                  requiredPermission: REPORTING.LIST,
              },
          ]
        : [];

    const organisationZendeskSubMenu = selectedOrganisationId
        ? [
              {
                  slug: 'zendesk-settings',
                  title: 'Zendesk Settings',
                  path: '/zendesk-settings',
                  requiredPermission: ZENDESK.SETTINGS.VIEW,
              },
              {
                  slug: 'zendesk-tickets',
                  title: 'Zendesk Tickets',
                  path: '/zendesk-tickets',
                  requiredPermission: ZENDESK.TICKET.LIST,
              },
          ]
        : [];

    const organisationChartOfAccountsSubMenu =
        selectedOrganisationId &&
        accountingSoftwareSlug &&
        accountingSoftwareSlug !== 'manual' &&
        userHasAccessToChartOfAccountEdit
            ? [
                  {
                      slug: 'selectOrganisationPaymentAccount',
                      children: <SelectOrganisationPaymentAccount key="select-organisation-payment-account-submenu" />,
                  },
              ]
            : [];

    const organisationSyncSubMenu =
        selectedOrganisationId && accountingSoftwareSlug && accountingSoftwareSlug !== 'manual'
            ? [
                  {
                      slug: 'sync',
                      children: <OrganisationSync key="organisation-sync-submenu" />,
                  },
              ]
            : [];

    const organisationSubMenus = selectedOrganisationId
        ? [
              {
                  slug: 'ordId',
                  children: (
                      <div key="organisation-name" className="organisation-popover">
                          <div className="org-details">
                              <span className="ant-avatar ant-avatar-circle ant-avatar-image">
                                  <img src={getOrganisationLogo(selectedOrganisation)} alt="profile-pic" />
                              </span>
                              <span className="org-name">{selectedOrganisation.name}</span>
                          </div>
                      </div>
                  ),
              },
              {
                  slug: 'details',
                  children: (
                      <li key="organisation-details" onClick={() => setNavVisibleStatus(false)}>
                          <PermissionGuard requiredPermission={ORGANISATION.VIEW} className="menu-item-bg">
                              <span onClick={() => redirect(`/organisation/${selectedOrganisationId}`)}>Details</span>
                          </PermissionGuard>
                      </li>
                  ),
                  requiredPermission: ORGANISATION.VIEW,
              },
          ]
        : [];

    /**
     * Prepends auth portal base URL and opens it in the same tab
     *
     * @param   {string}    route   Route to be opened
     */
    const prependAuthPortalURLAndOpen = route => {
        const url = new URL(getAuthPortalUrl());
        url.pathname = route;
        window.open(url.href, '_self');
    };

    /**
     * Check if user role is customer admin, if yes, render content or return null
     *
     * @param {*} children
     * @returns
     */
    const renderIfCustomerAdminRole = children => {
        if (currentRoleSlug !== USER_ROLE_CUSTOMER) return null;
        else return children;
    };

    const accountMenu = (
        <ul className="main-menu-popover sub-menu-list" onClick={() => setNavVisibleStatus(false)}>
            <li>
                <Link to="/my-profile">My Profile</Link>
            </li>
            {renderIfCustomerAdminRole(
                <li>
                    <PermissionGuard requiredPermission={ACCOUNT_INFORMATION.VIEW}>
                        <span
                            onClick={() => {
                                prependAuthPortalURLAndOpen(`account/${accountDetails?.id}`);
                            }}
                        >
                            My Account
                        </span>
                    </PermissionGuard>
                </li>
            )}
            {renderIfCustomerAdminRole(
                <li>
                    <PermissionGuard requiredPermission={ACCOUNT_INFORMATION.VIEW}>
                        <span onClick={() => redirect('/payment-information')}>Payment Information</span>
                    </PermissionGuard>
                </li>
            )}
            {renderIfCustomerAdminRole(
                <li>
                    <PermissionGuard requiredPermission={USER.LIST}>
                        <span onClick={() => prependAuthPortalURLAndOpen('users')}>Users</span>
                    </PermissionGuard>
                </li>
            )}
            {renderIfCustomerAdminRole(
                <li>
                    <Link to="/billings">Billings</Link>
                </li>
            )}
            <li>
                <PermissionGuard requiredPermission={EXPORT.LIST} fullWidth>
                    <span onClick={() => redirect('/exports')}>Your Exports</span>
                </PermissionGuard>
            </li>
            {renderIfCustomerAdminRole(
                <li>
                    <PermissionGuard requiredPermission={SUBSCRIPTION.LIST} fullWidth>
                        <Link to="/subscription">Subscription</Link>
                    </PermissionGuard>
                </li>
            )}
            {accountDetails?.is_debt_collection_enabled ? (
                <li>
                    <a href={debtCollectorPortalUrl} style={{ display: 'flex', alignItems: 'center' }}>
                        {debtCollectorPortalLabel}
                        <img style={{ height: 20, width: 20, marginLeft: 5 }} src={ExternalSVG} alt={'bank-mandate'} />
                    </a>
                </li>
            ) : null}
            <li onClick={() => clearLSAndRedirectToAuthPortalLogoutPage()}>Logout</li>
            <PermissionGuard requiredPermission={SUBSCRIPTION.LIST} fullWidth>
                <li className="plan-details subscription-plan-details-container">
                    <div>
                        <div className="popover-item">
                            <span className="popover-item-details">
                                <b>Current Plan: </b>
                                {subscription?.plan_name ? subscription?.plan_name : 'No active plan'}
                            </span>
                        </div>
                        {subscription?.plan_name ? (
                            <>
                                <div className="popover-item">
                                    <span className="popover-item-details">
                                        <b>Expires in: </b>
                                        {subscription.expires_in ? `${subscription.expires_in}` : 'Expired'}
                                    </span>
                                </div>
                                {subscription.grace_period_expires_in && !subscription.expires_in ? (
                                    <div className="popover-item">
                                        <span className="popover-item-details">
                                            <b>Grace Period Expires in: </b>
                                            {`${subscription.grace_period_expires_in}`}
                                        </span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                    {subscription?.plan?.is_custom ? null : (
                        <Button filled className="blue-bg-button popover-action-btn">
                            <Link to="/plans">Upgrade</Link>
                        </Button>
                    )}
                </li>
            </PermissionGuard>
        </ul>
    );

    const menuItemSlugs = [
        {
            slug: 'dashboard',
            title: 'Dashboard',
            path: '/dashboard',
            requiredPermission: DASHBOARD.VIEW,
            subMenu: [],
        },
        {
            slug: 'reminders',
            title: 'Reminders',
            subMenu: [
                {
                    slug: 'reminders',
                    title: 'Reminders',
                    path: '/reminders',
                    requiredPermission: REMINDER.LIST,
                },
                {
                    slug: 'campaigns',
                    title: 'Campaigns',
                    path: '/campaigns',
                    requiredPermission: CAMPAIGN.LIST,
                },
                {
                    slug: 'followups',
                    title: 'Follow Ups',
                    path: '/reminders/followups',
                    requiredPermission: FOLLOWUPS.LIST,
                },
            ],
        },
        {
            slug: 'organisations',
            title: 'Organisation',
            subMenu: [
                ...organisationSubMenus,
                ...organisationSyncSubMenu,
                {
                    slug: 'addOrg',
                    title: 'Add Organisation',
                    path: '/organisation/add',
                    requiredPermission: ORGANISATION.ADD,
                },
                ...tryDemoCompanySubmenu(),
                {
                    slug: 'connAccSoftware',
                    title: 'Connect Accounting Software',
                    path: '/connect',
                    // hasEditPermissionCheck: true,
                    requiredPermission: ACCOUNTING_SOFTWARE.CONNECT,
                },
                {
                    slug: 'switchOrg',
                    children: (
                        <li key="switch-organisations" onClick={() => setNavVisibleStatus(false)}>
                            <PermissionGuard requiredPermission={ORGANISATION.LIST}>
                                <span onClick={() => redirect('/organisations')}>Switch Organisation</span>
                            </PermissionGuard>
                        </li>
                    ),
                    requiredPermission: ORGANISATION.LIST,
                    path: '/organisations',
                },
                ...organisationChartOfAccountsSubMenu,
                ...organisationSettingSubMenu,
                {
                    slug: 'paymentPlans',
                    title: 'Payment Plans',
                    path: '/payment-plans',
                    //requiredPermission: CHART_OF_ACCOUNT.LIST,
                },
                {
                    slug: 'paymentArrangements',
                    title: 'Payment Arrangements',
                    path: '/payment-arrangements',
                    requiredPermission: PAYMENT_ARRANGEMENT.LIST,
                },
                {
                    slug: 'paymentExtensions',
                    title: 'Payment Extensions',
                    path: '/payment-extensions',
                    //requiredPermission: CHART_OF_ACCOUNT.LIST,
                },
                {
                    slug: 'paymentServices',
                    title: 'Payment Services',
                    path: '/payment-services',
                    requiredPermission: PAYMENT_GATEWAY.LIST,
                },
                {
                    slug: 'invoiceOrders',
                    title: 'Invoice Orders',
                    path: '/invoice-orders',
                    requiredPermission: ORDER.INVOICE.LIST,
                },
                ...reportingSubmenu,
                {
                    slug: 'debtorAccounts',
                    title: 'Debtor Accounts',
                    path: '/debtor-accounts',
                    requiredPermission: DEBTOR_ACCOUNT.LIST,
                },
                {
                    slug: 'currencies',
                    title: 'Currencies',
                    path: '/currencies',
                    requiredPermission: ORGANISATION_CURRENCY.LIST,
                },
                {
                    slug: 'taxes',
                    title: 'Taxes',
                    path: '/taxes',
                    requiredPermission: TAX.LIST,
                },
                {
                    slug: 'chartOfAccounts',
                    title: 'Chart of Accounts',
                    path: '/chart-of-accounts',
                    requiredPermission: CHART_OF_ACCOUNT.LIST,
                },
                {
                    slug: 'inventory',
                    title: 'Inventory',
                    path: '/inventory',
                    requiredPermission: INVENTORY.LIST,
                },
                {
                    slug: 'eventCalendar',
                    children: (
                        <li key="event-calendar">
                            <Link to="/event-calendar">Event Calendar</Link>
                        </li>
                    ),
                    path: '/organisations',
                },
                ...organisationZendeskSubMenu,
                {
                    slug: 'auditLogs',
                    children: (
                        <li key="audit-logs">
                            <Link to={`/audit-logs/${selectedOrganisationId}`}>Audit Logs</Link>
                        </li>
                    ),
                    path: '/organisations',
                    requiredPermission: AUDIT_LOG.LIST,
                },
            ],
        },
        {
            slug: 'contacts',
            title: 'Contacts',
            subMenu: [
                {
                    slug: 'contacts',
                    title: 'Contacts',
                    path: '/contacts',
                    requiredPermission: CONTACT.LIST,
                },
            ],
            // path: '/contacts',
        },
        {
            slug: 'invoices',
            title: 'Invoices',
            subMenu: [
                {
                    slug: 'invoices',
                    title: 'Invoices',
                    path: '/invoices',
                },
                {
                    slug: 'payments',
                    title: 'Payments',
                    path: '/payments',
                },
                {
                    slug: 'invoiceStatuses',
                    title: 'Statuses',
                    path: '/invoice-statuses',
                },
            ],
        },
    ];

    if (get(selectedOrganisation, 'is_manual')) {
        menuItemSlugs[3].subMenu.push({
            slug: 'contact-managers',
            title: 'Contact Managers',
            path: '/contact-managers',
            requiredPermission: CONTACT_MANAGER.LIST,
        });
    }

    menuItemSlugs[3].subMenu.push({
        slug: 'contact-types',
        title: 'Contact Types',
        path: '/contact-types',
        requiredPermission: CONTACT_TYPE.LIST,
    });

    return (
        <NavHeader
            menuItemSlugs={menuItemSlugs}
            sideNavs={sideNavs}
            openPopover={openPopover}
            setOpenPopover={setOpenPopover}
            setNavVisibleStatus={setNavVisibleStatus}
            navVisibleStatus={navVisibleStatus}
            userAvatar={getUserAvatar()}
            accountMenu={accountMenu}
        />
    );
};

export default CustomerNavHeader;

import { Alert, Divider, Modal } from 'antd';
import { find, get, groupBy } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Loader from 'components/Loader';
import {
    deletePaymentMethod,
    getPaymentMethods,
    setPaymentMethodAsDefault,
    updatePaymentMethod,
} from 'includes/slices/paymentGateways';
import AddPaymentMethodModal from './components/AddPaymentMethodModal';
import Description from './components/Description';
import PaymentMethod from './components/PaymentMethod';
import PaymentMethodEditModal from './components/PaymentMethodEditModal';
import usePaymentMethodStatuses from 'includes/hooks/usePaymentMethodStatuses';
import usePaymentMethodTypes from 'includes/hooks/usePaymentMethodTypes';
import usePaymentMethodSchemes from 'includes/hooks/usePaymentMethodSchemes';
import { redirect } from 'includes/utils';
import { openNotificationModal } from 'includes/slices/appNotifications';
import useGetPermissionWrappedFunction from 'includes/hooks/useGetPermissionWrappedFunction';
import { PAYMENT_METHOD } from 'includes/constants/permissions';
import { showPermissionDeniedModal } from 'components/PermissionGuard/component';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';

const { confirm } = Modal;

/**
 * Payment methods list
 *
 * @since 2.8.0
 */
export default function PaymentMethods() {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);

    const [isSettingDefaultPaymentMethod, setIsSettingDefaultPaymentMethod] = useState(false);

    const [selectedPaymentMethodIdForEditing, setSelectedPaymentMethodIdForEditing] = useState(null);

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const paymentMethods = useSelector(state => state.paymentGateways.paymentMethods);

    const isLoadingPaymentMethods = useSelector(state => state.paymentGateways.isLoadingPaymentMethods);

    const isUpdatingPaymentMethods = useSelector(state => state.paymentGateways.isUpdatingPaymentMethods);

    const isDeletingPaymentMethods = useSelector(state => state.paymentGateways.isDeletingPaymentMethods);

    const permissionWrappedGetPaymentMethods = useGetPermissionWrappedFunction(PAYMENT_METHOD.LIST, () =>
        dispatch(getPaymentMethods())
    );

    const handlePaymentMethodEdit = useGetPermissionWrappedFunction(
        PAYMENT_METHOD.EDIT,
        methodId => setSelectedPaymentMethodIdForEditing(methodId),
        showPermissionDeniedModal
    );

    /**
     * ---------------- begin ----------------
     * These hooks are called here to initiate the API.
     * The data is consumed in `PaymentStatus`, `PaymentMethodType` and `PaymentScheme` components
     * with `disableApiCall` param set to `true` to avoid multiple API calls
     *
     */
    usePaymentMethodStatuses();

    const { data: paymentMethodTypes } = usePaymentMethodTypes();

    usePaymentMethodSchemes();
    /**
     * ---------------- end ----------------
     */

    useEffect(() => {
        permissionWrappedGetPaymentMethods();
    }, []);

    /**
     * Open card add modal
     */
    const handleAddCard = () => {
        setIsAddModalOpen(true);
    };

    /**
     * Takes in string date received from the backend and return an object with keys `expiry_month` and `expiry_year`
     * To be used to format before sending it to BE and set in the form
     *
     * @param {string} dateString Date in the format YYYY-MM-DD
     * @returns object with keys `expiry_month` and `expiry_year`
     */
    const getExpiryDateAsFormValues = dateString => {
        const date = moment(dateString, 'YYYY-MM-DD');

        return {
            expiry_month: date.month() + 1,
            expiry_year: date.year(),
        };
    };

    /**
     * Memoised selected payment method details
     */
    const selectedPaymentMethod = useMemo(() => {
        if (!selectedPaymentMethodIdForEditing || paymentMethods.length === 0) return null;

        const paymentMethod = paymentMethods.find(method => method.id === selectedPaymentMethodIdForEditing);
        const processedPaymentMethod = { ...paymentMethod };

        return { ...processedPaymentMethod, ...getExpiryDateAsFormValues(paymentMethod.end_date) };
    }, [selectedPaymentMethodIdForEditing, paymentMethods]);

    /**
     * group payment methods into default and other payment methods
     */
    const { defaultPaymentMethod, otherPaymentMethods } = useMemo(() => {
        const grouped = groupBy(paymentMethods, method =>
            method.is_default ? 'defaultPaymentMethod' : 'otherPaymentMethods'
        );

        return {
            defaultPaymentMethod: grouped.defaultPaymentMethod ?? [],
            otherPaymentMethods: grouped.otherPaymentMethods ?? [],
        };
    }, [paymentMethods]);

    /**
     * Handle form submit of payment method edit modal
     *
     * @param {object} values
     */
    const onEditFormSubmit = values => {
        dispatch(
            updatePaymentMethod(selectedPaymentMethodIdForEditing, values, () =>
                setSelectedPaymentMethodIdForEditing(null)
            )
        );
    };

    /**
     * Render confirm modal and on confirm, delete the payment method
     *
     * @param {string} paymentMethodId payment method ID
     * @param {string} paymentMethodTypeId payment method type ID. Used to render appropriate message in confirm dialog
     */
    const handleDelete = useGetPermissionWrappedFunction(
        PAYMENT_METHOD.DELETE,
        (paymentMethodId, paymentMethodTypeId) => {
            const paymentMethodTypeSlug = get(find(paymentMethodTypes, { id: paymentMethodTypeId }), 'slug');
            confirm({
                okButtonProps: { loading: isDeletingPaymentMethods },
                cancelText: t('customerMessages.paymentMethod.deletePaymentMethodModal.cancelText'),
                content: t(`customerMessages.paymentMethod.deletePaymentMethodModal.content.${paymentMethodTypeSlug}`),
                okText: t(`customerMessages.paymentMethod.deletePaymentMethodModal.okText.${paymentMethodTypeSlug}`),
                title: t(`customerMessages.paymentMethod.deletePaymentMethodModal.title.${paymentMethodTypeSlug}`),
                onOk: () => {
                    dispatch(deletePaymentMethod(paymentMethodId));
                },
            });
        },
        showPermissionDeniedModal
    );

    /**
     * Handle view payment method details
     */
    const handleViewDetails = useGetPermissionWrappedFunction(
        PAYMENT_METHOD.VIEW,
        id => redirect(`/payment-information/payment-method/${id}`),
        showPermissionDeniedModal
    );

    /**
     * Makes an API request to update the method as default
     *
     * @param {object} method payment method details
     */
    const handleMakeDefault = method => {
        setIsSettingDefaultPaymentMethod(true);
        dispatch(
            setPaymentMethodAsDefault(
                method.id,
                {
                    ...method,
                    ...getExpiryDateAsFormValues(method.end_date),
                    is_default: true,
                },
                () => {
                    dispatch(openNotificationModal('Primary payment method updated'));
                    setIsSettingDefaultPaymentMethod(false);
                },
                () => setIsSettingDefaultPaymentMethod(false)
            )
        );
    };

    /**
     * if `isLoadingPaymentMethods` or `isDeletingPaymentMethods` is `true` render Loader
     */
    if (isLoadingPaymentMethods || isDeletingPaymentMethods)
        return (
            <div className="p-7">
                <Loader />
            </div>
        );

    return (
        <div className="p-5 px-9">
            <PaymentMethodEditModal
                isLoading={isUpdatingPaymentMethods}
                onSubmit={onEditFormSubmit}
                onCancel={() => setSelectedPaymentMethodIdForEditing(null)}
                open={Boolean(selectedPaymentMethod)}
                selectedPaymentMethod={selectedPaymentMethod}
            />

            <Modal footer={null} visible={isSettingDefaultPaymentMethod}>
                <Loader />
                <div>Setting primary payment method...</div>
            </Modal>

            <div className="flex flex-col gap-5">
                <div className="grid md:grid-cols-2 grid-cols-1 gap-4">
                    <div className={defaultPaymentMethod.length > 0 ? 'col-span-1' : 'col-span-2'}>
                        <Description onAddCard={handleAddCard} />
                    </div>
                    {defaultPaymentMethod.length > 0 ? (
                        <div>
                            <Divider>
                                <span className="text-md font-semibold text-gray-500 my-2">Primary payment method</span>
                            </Divider>
                            <div className="grid grid-cols-1 gap-5 justify-start">
                                {defaultPaymentMethod.map(method => (
                                    <div key={method.id} className="h-fit-content">
                                        <PaymentMethod
                                            details={method}
                                            onDelete={() => handleDelete(method.id, method.method_type_id)}
                                            onEdit={() => handlePaymentMethodEdit(method.id)}
                                            onViewDetails={() => handleViewDetails(method.id)}
                                            onMakeDefault={() => handleMakeDefault(method)}
                                            typeId={method.method_type_id}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </div>

                <PermissionGuardLayout
                    requiredPermission={PAYMENT_METHOD.LIST}
                    layout="section"
                    showFallbackUI
                    sectionErrorMessage="You don't have permission to list payment methods."
                >
                    {otherPaymentMethods.length > 0 ? (
                        <div>
                            <Divider>
                                <span className="text-md font-semibold text-gray-500 my-2">Other payment methods</span>
                            </Divider>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 justify-start">
                                {otherPaymentMethods.map(method => (
                                    <div key={method.id} className="h-fit-content">
                                        <PaymentMethod
                                            details={method}
                                            onDelete={() => handleDelete(method.id, method.method_type_id)}
                                            onEdit={() => handlePaymentMethodEdit(method.id)}
                                            onViewDetails={() => handleViewDetails(method.id)}
                                            onMakeDefault={() => handleMakeDefault(method)}
                                            typeId={method.method_type_id}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                </PermissionGuardLayout>
            </div>

            <AddPaymentMethodModal
                open={isAddModalOpen}
                onCancel={() => {
                    dispatch(getPaymentMethods());
                    setIsAddModalOpen(false);
                }}
            />

            <div className="text-left mt-3">
                <Alert
                    showIcon
                    type="warning"
                    message={t('customerMessages.paymentMethod.info.defaultPaymentMethodUsage')}
                />
            </div>
        </div>
    );
}

/**
 * Permission guard
 *
 * Receives a required permission and only allows click/hover event action if the user has the required permission
 *
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 * @version 1.0
 */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import useHasRequiredPermission from 'includes/hooks/useHasRequiredPermission';
import { redirect } from 'includes/utils';
import useUserHasAccessPermission from 'includes/hooks/useUserHasAccessPermission';
import { useCurrentRoleSlug } from 'includes/utils/hooks';
import { USER_ROLE_CUSTOMER } from 'includes/constants';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';

const { error } = Modal;

export const errorModal = ({ title, content, onOk = () => {}, okText = 'Ok' }) => {
    error({
        title,
        content,
        onOk,
        okText,
        closable: true,
        maskClosable: true,
    });
};

/**
 * Opens the permission denied modal
 */
export const showPermissionDeniedModal = () => {
    errorModal({
        title: 'Upgrade To Access',
        content: 'You will need to upgrade your plan to access this feature.',
        onOk: () => redirect('/plans'),
        okText: 'Upgrade',
    });
};

const PermissionGuard = props => {
    const { requiredPermission, children, fullWidth, fallback } = props;
    const isAdminOrManager = useIsAdminOrManager();
    const hasPlanPermission = useHasRequiredPermission(requiredPermission);
    const hasAccessPermission = useUserHasAccessPermission(requiredPermission);
    const currentRoleSlug = useCurrentRoleSlug();

    const hasNoPlanPermissionProps = useMemo(() => {
        if (currentRoleSlug === USER_ROLE_CUSTOMER)
            return {
                message: 'Please upgrade your plan to access this feature.',
                title: 'Upgrade to access',
                okBtnText: 'Upgrade',
                onOk: () => redirect('/plans'),
            };

        return {
            message:
                "Your current subscription doesn't allow access to this feature. Please contact administrator to get it upgraded.",
            title: 'Upgrade to access',
            okBtnText: 'Ok',
            onOk: () => {},
        };
    }, [currentRoleSlug]);

    const noPermissionProps = useMemo(() => {
        if (currentRoleSlug === USER_ROLE_CUSTOMER)
            return {
                message: 'Please upgrade to access this feature.',
                title: 'Upgrade plan',
                okBtnText: 'Upgrade',
                onOk: () => redirect('/plans'),
            };

        return {
            message: 'You do not have access to this feature. Please contact administrator.',
            title: 'No permission',
            okBtnText: 'Ok',
            onOk: () => {},
        };
    }, [currentRoleSlug]);

    /**
     * Intercepts the event bubbling and checks if the user has the required permission
     *
     * @param {React.MouseEvent} e
     */
    const handleEvent = e => {
        if (!hasPlanPermission) {
            errorModal({
                title: hasNoPlanPermissionProps.title,
                content: hasNoPlanPermissionProps.message,
                onOk: hasNoPlanPermissionProps.onOk,
                okText: hasNoPlanPermissionProps.okBtnText,
            });
            e.stopPropagation();
            return;
        }

        if (!hasAccessPermission) {
            errorModal({
                title: noPermissionProps.title,
                content: noPermissionProps.message,
                onOk: noPermissionProps.onOk,
                okText: noPermissionProps.okBtnText,
            });
            e.stopPropagation();
            return;
        }
    };

    const upgradeUI = (
        <span
            onClickCapture={handleEvent}
            style={{
                display: 'inline-block',
            }}
            className={fullWidth ? 'w-full h-full' : ''}
            onClick={handleEvent}
        >
            {children}
        </span>
    );

    const fallbackUI = fallback ? fallback : upgradeUI;

    // if user is admin or an admin manager, then show the child component as normal, no checking is required
    if (isAdminOrManager || !requiredPermission) return children;

    if (hasPlanPermission) {
        if (hasAccessPermission) {
            return children;
        } else {
            return fallbackUI;
        }
    }

    return currentRoleSlug === USER_ROLE_CUSTOMER ? upgradeUI : fallbackUI;
};

PermissionGuard.propTypes = {
    children: PropTypes.node.isRequired,
    fullWidth: PropTypes.bool,
    requiredPermission: PropTypes.string.isRequired || PropTypes.array,
    fallback: PropTypes.element,
};

PermissionGuard.defaultProps = {
    fullWidth: false,
};

export default PermissionGuard;

import { Alert, Modal } from 'antd';
import { find, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from 'components/Button';
import PaymentStatus from 'components/PaymentStatus';
import PermissionGuard from 'components/PermissionGuard';
import Loader from 'components/shared/Loader';
import OrderChargesUpdatedConfirmModalContent from 'components/shared/Orders/OrderChargesUpdatedConfirmModalContent';
import {
    ORDER_TYPE,
    PAYMENT_ARRANGEMENT_PAYMENT_TYPE_UPFRONT,
    PAYMENT_ARRANGEMENT_PLAN_TYPE_ARRANGEMENT,
    PAYMENT_ARRANGEMENT_PLAN_TYPE_EXTENSION,
    PAYMENT_STATUS,
} from 'includes/constants';
import {
    COUNTRY_ID,
    COUPON_ID,
    PAYMENT_GATEWAY,
    PAYMENT_GATEWAY_ID,
    PAYMENT_METHOD_TYPE_ID,
    SAVE_CARD_FOR_LATER_USE,
    SCHEME_ID,
    TOKEN,
    ORDER_TYPE as ORDER_TYPE_KEY,
    PAYMENT_METHOD_ID,
    MANDATE_NAME,
    UPGRADE_SUBSCRIPTION,
    PLAN_TYPE,
    PLAN_ID,
    SUBSCRIPTION,
    RETRY_ORDER_PAYMENT,
} from 'includes/constants/keys/request';
import { ORDER_TRANSACTION_CHARGE_ERROR } from 'includes/constants/mappings/errors';
import { ORDER } from 'includes/constants/permissions';
import useCurrencies from 'includes/hooks/useCurrencies';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import useToFEDateFormatter from 'includes/hooks/useToFEDateFormatter';
import OrdersApi from 'includes/services/orders';
import { cancelOrder, downloadOrderInvoice } from 'includes/slices/orders';
import { formatNumberAsCurrency } from 'includes/utils';
import { showApiErrors } from 'includes/utils/api';
import { useOrderTypes, usePaymentStatuses } from 'includes/utils/hooks';
import DetailsPageDetailSection from '../DetailsPageDetailSection';
import ChargesTable from './components/ChargesTable';
import OrderPaymentMethod from './components/OrderPaymentMethod';
import RetryPaymentSection from './components/RetryPaymentSection';
import SubscriptionType from './components/SubscriptionType';
import './styles.scss';
import CopyToClipboard from 'components/CopyToClipboard';
import useAccountId from 'includes/hooks/useAccountId';
import { CARD_BRAND_NAME_MAP } from 'components/customer/PaymentInformation/constants';

const ORDER_DOWNLOAD_PERMISSION = {
    subscription: ORDER.SUBSCRIPTION.DOWNLOAD,
    invoice: ORDER.INVOICE.DOWNLOAD,
    'legal-letter': ORDER.LEGAL_LETTER.DOWNLOAD,
};

const ORDER_CANCEL_PERMISSION = {
    subscription: ORDER.SUBSCRIPTION.DELETE,
    invoice: ORDER.INVOICE.DELETE,
    'legal-letter': ORDER.LEGAL_LETTER.DELETE,
};

/**
 * Renders table row only if value is positive
 *
 * @since 2.8.0
 */
function RenderTableRowIfValueExists({
    columnsLength,
    currencyCode,
    effectiveSymbol = '+',
    label,
    subText = '',
    rowClassName = '',
    value = 0,
}) {
    if (value === 0) return null;

    return (
        <tr className={rowClassName}>
            {Array.from({ length: columnsLength - 2 }).map((_, index) => (
                <td key={index} className="py-4 px-6" />
            ))}
            <td className="py-4 px-6 text-right">
                {label}
                {subText ? <div className="text-sm text-gray-400">{subText}</div> : null}
            </td>
            <td className="py-4 px-6 text-right font-mono font-normal">{`${effectiveSymbol} ${formatNumberAsCurrency(
                value,
                currencyCode
            )}`}</td>
        </tr>
    );
}

RenderTableRowIfValueExists.propTypes = {
    columnsLength: PropTypes.number,
    currencyCode: PropTypes.string,
    effectiveSymbol: PropTypes.oneOf(['+', '-', '']),
    label: PropTypes.node,
    subText: PropTypes.node,
    rowClassName: PropTypes.string,
    value: PropTypes.number,
};

const REDUCER_ACTION = {
    SET_SELECTED_CARD: 'set_selected_card',
    SET_SELECTED_COUPON: 'set_selected_coupon',
    SET_SELECTED_PAYMENT_GATEWAY: 'set_selected_payment_gateway',
};

function retryPaymentReducer(state, action) {
    switch (action.type) {
        case REDUCER_ACTION.SET_SELECTED_CARD: {
            return {
                ...state,
                [TOKEN]: action.payload.id,
                [PAYMENT_METHOD_TYPE_ID]: action.payload.method_type_id,
                [PAYMENT_GATEWAY_ID]: action.payload.payment_gateway_id,
                [COUNTRY_ID]: action.payload.country_id,
            };
        }
        case REDUCER_ACTION.SET_SELECTED_COUPON: {
            return {
                ...state,
                [COUPON_ID]: action.payload,
            };
        }
        case REDUCER_ACTION.SET_SELECTED_PAYMENT_GATEWAY: {
            return {
                ...state,
                [PAYMENT_GATEWAY_ID]: action.payload,
            };
        }
    }
}

/**
 * Order details
 *
 * @since 2.8.0
 */
export default function OrderDetails({ details, isLoading }) {
    const [isLoadingPayButton, setIsLoadingPayButton] = useState(false);

    const [state, dispatchPaymentSection] = useReducer(retryPaymentReducer, { [TOKEN]: '', [PAYMENT_GATEWAY_ID]: '' });

    const { t } = useTranslation();

    const formatDate = useToFEDateFormatter(false, 'ddd, MMM D, YYYY');

    const [orderTypes] = useOrderTypes();

    const { data: currencies } = useCurrencies();

    const accountId = useAccountId();

    const [paymentStatuses] = usePaymentStatuses();

    const dispatch = useDispatch();

    const loadingOrderInvoiceDownload = useSelector(state => state.orders.loadingOrderInvoiceDownload);

    const isAdmin = useSelector(state => state.account.isAdmin);

    const isCancellingOrder = useSelector(state => state.orders.isCancellingOrder);

    const currencyId = details?.currency_id;

    const orderTypeId = details?.type_id;

    const paymentStatusId = details?.payment_status_id;

    const organisationId = useOrganisationId();

    /**
     * Find the currency code
     */
    const currencyCode = useMemo(() => {
        if (currencies.length === 0) return null;

        return get(find(currencies, { id: currencyId }), 'code');
    }, [currencies, currencyId]);

    /**
     * Find the payment status
     */
    const paymentStatusSlug = useMemo(() => {
        if (paymentStatuses?.length === 0) return null;

        return get(find(paymentStatuses, { id: paymentStatusId }), 'slug');
    }, [paymentStatusId, paymentStatuses]);

    /**
     * Find the order type slug
     */
    const orderType = useMemo(() => {
        if (orderTypes.length === 0) return null;

        return get(find(orderTypes, { id: orderTypeId }), 'slug');
    }, [orderTypes, currencyId]);

    /**
     * Order type columns
     */
    const ORDER_TYPE_COLUMNS_MAP = {
        [ORDER_TYPE.INVOICE]: [
            { id: 'number', name: '#', className: 'text-left w-2' },
            {
                id: 'invoiceNumber',
                name: t('sharedMessages.orders.details.label.invoiceNumber'),
                className: 'text-left',
            },
            {
                id: 'paymentId',
                name: t('sharedMessages.orders.details.label.paymentId'),
                className: 'text-left',
            },
            {
                id: 'amount',
                name: t('sharedMessages.orders.details.label.amountPaid'),
                className: 'text-right w-2 amount-paid-column',
            },
        ],
        [ORDER_TYPE.SUBSCRIPTION]: [
            { id: 'number', name: '#', className: 'text-left w-2' },
            { id: 'plan', name: t('sharedMessages.orders.details.label.plan'), className: 'text-left' },
            {
                id: 'charges',
                name: t('sharedMessages.orders.details.label.charges'),
                className: 'text-right w-2',
            },
        ],
    };

    const getPlanTypeVerbose = type => {
        switch (type) {
            case PAYMENT_ARRANGEMENT_PLAN_TYPE_ARRANGEMENT:
                return 'Payment arrangement';
            case PAYMENT_ARRANGEMENT_PLAN_TYPE_EXTENSION:
                return 'Payment extension';
            default:
                return '';
        }
    };

    /**
     * Returns a verbose string to indicate the type of payment- arrangement/extension, installment/upfront or upfront
     *
     * @param {object} invoice
     * @returns {string}
     */
    const getInvoiceSubtext = invoice => {
        const {
            payment_arrangement_data: arrangementData,
            payment_arrangement_installment_data: arrangementInstallmentData,
        } = invoice;

        // paid in full
        if (!isEmpty(arrangementData)) {
            return t('sharedMessages.orders.details.invoice.subtext.paidInFull', {
                planType: getPlanTypeVerbose(arrangementData.plan_type),
            });
        }

        if (!isEmpty(arrangementInstallmentData)) {
            // upfront payment
            if (arrangementInstallmentData.payment_type === PAYMENT_ARRANGEMENT_PAYMENT_TYPE_UPFRONT) {
                return t('sharedMessages.orders.details.invoice.subtext.upfrontPayment', {
                    planType: getPlanTypeVerbose(arrangementInstallmentData.plan_type),
                });
            }
            // installment payment
            if (arrangementInstallmentData.plan_type === PAYMENT_ARRANGEMENT_PLAN_TYPE_ARRANGEMENT)
                return t('sharedMessages.orders.details.invoice.subtext.paymentArrangementInstallmentPayment', {
                    number: arrangementInstallmentData.installment_number,
                });

            // extension payment
            if (arrangementInstallmentData.plan_type === PAYMENT_ARRANGEMENT_PLAN_TYPE_EXTENSION)
                return t('sharedMessages.orders.details.invoice.subtext.paymentExtensionInstallmentPayment');
        }

        // normal payment
        return '';
    };

    /**
     * Get invoice details link based on if role is admin or not
     *
     * @param {string} invoiceId Invoice ID
     * @returns invoice details link based on the role
     */
    const getInvoiceDetailsLink = invoiceId => {
        if (isAdmin)
            return `/admin/accounts/organisation/invoices/invoice/${accountId}/${organisationId}/${details.contact.id}/${invoiceId}`;

        return `/invoices/invoice/${details.contact.id}/${invoiceId}`;
    };

    /**
     * Get invoice details link based on if role is admin or not
     *
     * @param {string} invoiceId Invoice ID
     * @param {string} invoicePaymentId Invoice payment ID
     * @returns invoice details link based on the role
     */
    const getInvoicePaymentDetailsLink = (invoiceId, invoicePaymentId) => {
        if (isAdmin)
            return `/admin/accounts/organisation/invoices/payments/${accountId}/${organisationId}/${details.contact.id}/${invoiceId}/${invoicePaymentId}`;

        return `/invoices/payments/${organisationId}/${details.contact.id}/${invoiceId}/${invoicePaymentId}/`;
    };

    /**
     * Order type data
     */
    const ORDER_TYPE_DATA_MAP = {
        [ORDER_TYPE.INVOICE]: (details?.invoices || []).map((inv, index) => ({
            number: index + 1,
            invoiceNumber: (
                <div>
                    <div>
                        <Link to={getInvoiceDetailsLink(inv.id)}>{inv.number}</Link>
                    </div>
                    <div className="text-sm text-gray-400">{getInvoiceSubtext(inv)}</div>
                </div>
            ),
            paymentId: isEmpty(inv.invoice_payment_data) ? null : (
                <Link to={getInvoicePaymentDetailsLink(inv.id, inv.invoice_payment_data.id)}>
                    {inv.invoice_payment_data.unique_id}
                </Link>
            ),
            amount: formatNumberAsCurrency(inv.amount_paid, currencyCode),
        })),
        [ORDER_TYPE.SUBSCRIPTION]: [
            {
                number: 1,
                plan: (
                    <>
                        <span className="mr-1">{details?.plan?.type}</span>{' '}
                        <SubscriptionType type={details?.plan?.subscription} />
                    </>
                ),
                charges: formatNumberAsCurrency(details?.amount, currencyCode),
            },
        ],
    };

    /**
     * Download button click handler
     */
    const onDownloadOrderButtonClick = () => {
        // `organisationId` is only required for downloads of organisation orders
        const params = orderType === ORDER_TYPE.INVOICE ? { organisation_id: organisationId } : {};
        dispatch(downloadOrderInvoice(orderTypeId, details.id, params));
    };

    const onCancelButtonClick = () => {
        Modal.confirm({
            title: 'Cancel order',
            content: 'Are you sure you want to cancel the order?',
            onOk: () => dispatch(cancelOrder(orderTypeId, details.id, { organisation_id: organisationId })),
        });
    };

    /**
     * Create order method
     *
     * @param {*} reinitializePaymentFlow method to callback
     * @param {string} selectedPaymentMethod selected payment method
     * @param {object} confirmationParams confirmation params
     * @returns {object}
     */
    const createOrder = async (
        reinitializePaymentFlow,
        selectedPaymentMethod,
        confirmationParams = { subscription: false, order: false, transactionCharges: false }
    ) => {
        let response = null;
        try {
            const { transactionCharges, ...rest } = confirmationParams;
            response = await OrdersApi.retryOrderPayment(orderTypeId, details.id, {
                ...state,
                id: details.id,
                token: selectedPaymentMethod,
                transaction_charges_confirm: transactionCharges,
                ...rest,
            });
            return { response, error: undefined };
        } catch (errors) {
            const transactionChargeError = get(errors, ORDER_TRANSACTION_CHARGE_ERROR);
            if (transactionChargeError) {
                Modal.confirm({
                    title: 'Order charges updated',
                    content: (
                        <OrderChargesUpdatedConfirmModalContent
                            errorMessage={transactionChargeError.message}
                            formatCurrency={val => formatNumberAsCurrency(val, currencyCode)}
                            t={t}
                            transactionCharges={transactionChargeError.transaction_charges}
                        />
                    ),
                    onCancel: () => {
                        setIsLoadingPayButton(false);
                    },
                    onOk: () => {
                        // recursively call `handleRetryPayment` with `confirmationParams.transactionCharges` as `true`
                        reinitializePaymentFlow({
                            ...confirmationParams,
                            transactionCharges: true,
                            token: selectedPaymentMethod,
                        });
                    },
                });
                return { response: null, error: transactionChargeError };
            }
            dispatch(
                showApiErrors(errors, undefined, 'RETRY_ORDER_PAYMENT_ERROR_MESSAGE', undefined, [
                    COUNTRY_ID,
                    COUPON_ID,
                    MANDATE_NAME,
                    ORDER_TYPE_KEY,
                    PAYMENT_GATEWAY_ID,
                    PAYMENT_GATEWAY,
                    PAYMENT_METHOD_ID,
                    PLAN_ID,
                    PLAN_TYPE,
                    RETRY_ORDER_PAYMENT,
                    SAVE_CARD_FOR_LATER_USE,
                    SCHEME_ID,
                    SUBSCRIPTION,
                    TOKEN,
                    UPGRADE_SUBSCRIPTION,
                ])
            );
            setIsLoadingPayButton(false);
            return { response: null, error: errors };
        }
    };

    const columns = ORDER_TYPE_COLUMNS_MAP[orderType] ?? [];

    if (isLoading) return <Loader />;

    return (
        <div className="flex flex-col gap-4">
            {details?.payment_error && ![PAYMENT_STATUS.CANCELLED].includes(paymentStatusSlug) ? (
                <Alert type="error" message={details?.payment_error} className="text-left flex-grow-0" />
            ) : null}
            <div className="bg-white rounded-lg shadow-2xl text-black">
                <div className="flex justify-end gap-2 border-b px-6 py-2 border-solid border-gray-200">
                    <PermissionGuard requiredPermission={ORDER_DOWNLOAD_PERMISSION[orderType]}>
                        <Button
                            icon="download"
                            loading={loadingOrderInvoiceDownload}
                            onClick={onDownloadOrderButtonClick}
                        >
                            {t('sharedMessages.orders.details.button.download')}
                        </Button>
                    </PermissionGuard>

                    {[PAYMENT_STATUS.PENDING, PAYMENT_STATUS.FAILED, PAYMENT_STATUS.NOT_COLLECTED].includes(
                        paymentStatusSlug
                    ) ? (
                        <PermissionGuard requiredPermission={ORDER_CANCEL_PERMISSION[orderType]}>
                            <Button icon="stop" loading={isCancellingOrder} onClick={onCancelButtonClick}>
                                {t('sharedMessages.orders.details.button.cancel')}
                            </Button>
                        </PermissionGuard>
                    ) : null}
                </div>

                <div className="px-6 py-8">
                    <div className="flex flex-row justify-end">
                        <div className="flex flex-col justify-self-end gap-1">
                            <PaymentStatus status={details?.payment_status_id} />
                            <CopyToClipboard
                                value={details?.unique_id}
                                valueClassName="mt-1 text-black font-semibold font-mono"
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4 text-left my-5">
                        <DetailsPageDetailSection
                            label={t('sharedMessages.orders.details.label.name')}
                            value={
                                <div>
                                    <div>{details?.name}</div>
                                    <div className="text-sm text-gray-500">{details?.email}</div>
                                </div>
                            }
                        />
                        {isEmpty(details?.payment_details) ? null : (
                            <DetailsPageDetailSection
                                label={t('sharedMessages.orders.details.label.paymentMethod')}
                                value={
                                    <OrderPaymentMethod
                                        brand={details?.payment_details?.brand_name ?? ''}
                                        id={orderType === ORDER_TYPE.SUBSCRIPTION ? details?.payment_details?.id : null}
                                        number={details?.payment_details?.account_number ?? ''}
                                        type={details?.payment_details?.method_type ?? ''}
                                    />
                                }
                            />
                        )}
                        <DetailsPageDetailSection
                            label={t('sharedMessages.orders.details.label.date')}
                            value={formatDate(details?.created_date)}
                        />
                        <DetailsPageDetailSection
                            label={t('sharedMessages.orders.details.label.reference')}
                            value={<CopyToClipboard valueClassName="w-40 font-mono" value={details?.reference_id} />}
                        />
                        {details?.payment_id ? (
                            <DetailsPageDetailSection
                                label={t('sharedMessages.orders.details.label.paymentId')}
                                value={<CopyToClipboard valueClassName="w-30 font-mono" value={details?.payment_id} />}
                            />
                        ) : null}
                        {details?.cancelled_date ? (
                            <DetailsPageDetailSection
                                label={t('sharedMessages.orders.details.label.cancelledDate')}
                                value={formatDate(details?.cancelled_date)}
                            />
                        ) : null}
                        {details?.cancellation_reason ? (
                            <DetailsPageDetailSection
                                label={t('sharedMessages.orders.details.label.cancellationReason')}
                                value={details?.cancellation_reason}
                            />
                        ) : null}
                        {details?.last_refund_date ? (
                            <DetailsPageDetailSection
                                label={t('sharedMessages.orders.details.label.last_refund_date')}
                                value={formatDate(details?.last_refund_date)}
                            />
                        ) : null}
                        {details?.last_refund_reason ? (
                            <DetailsPageDetailSection
                                label={t('sharedMessages.orders.details.label.lastRefundReason')}
                                value={details?.last_refund_reason}
                            />
                        ) : null}
                    </div>

                    <ChargesTable
                        additionalRows={
                            <>
                                <RenderTableRowIfValueExists
                                    columnsLength={columns.length}
                                    currencyCode={currencyCode}
                                    effectiveSymbol=""
                                    label={t('sharedMessages.orders.details.label.subtotal')}
                                    value={details?.amount}
                                />

                                <RenderTableRowIfValueExists
                                    columnsLength={columns.length}
                                    currencyCode={currencyCode}
                                    effectiveSymbol="-"
                                    label={
                                        <>
                                            <div>
                                                {t('sharedMessages.orders.details.label.couponDiscount', {
                                                    charge: details?.coupon?.coupon_discount,
                                                })}
                                            </div>
                                            <div className="text-sm font-mono text-gray-400">
                                                {details?.coupon?.code}
                                            </div>
                                        </>
                                    }
                                    value={details?.coupon_discount}
                                />

                                <RenderTableRowIfValueExists
                                    columnsLength={columns.length}
                                    currencyCode={currencyCode}
                                    label={t('sharedMessages.orders.details.label.serviceCharge', {
                                        charge: details?.order_charges?.service_charge,
                                    })}
                                    subText={t('sharedMessages.orders.details.label.serviceChargeSubText', {
                                        charge: details?.order_charges?.service_charge,
                                    })}
                                    value={details?.service_charge}
                                />

                                <RenderTableRowIfValueExists
                                    columnsLength={columns.length}
                                    currencyCode={currencyCode}
                                    label={t('sharedMessages.orders.details.label.transactionCharge', {
                                        charge:
                                            details?.order_charges?.total_transaction_charge_without_additional_charges,
                                    })}
                                    subText={t('sharedMessages.orders.details.label.transactionChargeSubText', {
                                        charge:
                                            details?.order_charges?.total_transaction_charge_without_additional_charges,
                                    })}
                                    value={details?.transaction_charge_without_additional_charges}
                                />

                                <RenderTableRowIfValueExists
                                    columnsLength={columns.length}
                                    currencyCode={currencyCode}
                                    label={t('sharedMessages.orders.details.label.highValueTransactionCharge')}
                                    subText={t(
                                        'sharedMessages.orders.details.label.highValueTransactionChargeSubText',
                                        {
                                            amount: formatNumberAsCurrency(
                                                details?.order_charges.minimum_high_value_transaction_amount,
                                                currencyCode
                                            ),
                                            percentage:
                                                details?.order_charges?.additional_high_value_transaction_charge,
                                        }
                                    )}
                                    value={details?.additional_high_value_transaction_charge}
                                />

                                <RenderTableRowIfValueExists
                                    columnsLength={columns.length}
                                    currencyCode={currencyCode}
                                    label={t('sharedMessages.orders.details.label.additionalTransactionCharge', {
                                        charge: details?.order_charges?.total_transaction_charge,
                                    })}
                                    subText={t(
                                        'sharedMessages.orders.details.label.additionalTransactionChargeSubText',
                                        {
                                            brand: CARD_BRAND_NAME_MAP[details?.order_charges?.brand_name] ?? '',
                                            percentage:
                                                details?.order_charges
                                                    .additional_payment_method_brand_transaction_charge,
                                        }
                                    )}
                                    value={details?.additional_payment_method_brand_transaction_charge}
                                />

                                <RenderTableRowIfValueExists
                                    columnsLength={columns.length}
                                    currencyCode={currencyCode}
                                    label={t('sharedMessages.orders.details.label.gst', {
                                        charge: details?.order_charges?.gst,
                                    })}
                                    subText={t('sharedMessages.orders.details.label.gstSubText', {
                                        charge: details?.order_charges?.gst,
                                    })}
                                    rowClassName="border-b border-solid border-gray-200"
                                    value={details?.gst}
                                />
                            </>
                        }
                        columns={columns}
                        rows={ORDER_TYPE_DATA_MAP[orderType] ?? []}
                        totalAmount={formatNumberAsCurrency(details?.total_amount, currencyCode)}
                    />
                </div>

                <RetryPaymentSection
                    amount={details?.total_amount ? parseInt(details?.total_amount) : 100}
                    appliedCoupon={state[COUPON_ID]}
                    createOrder={createOrder}
                    isLoadingPayButton={isLoadingPayButton}
                    setIsLoadingPayButton={setIsLoadingPayButton}
                    onApplyCoupon={coupon =>
                        dispatchPaymentSection({
                            type: REDUCER_ACTION.SET_SELECTED_COUPON,
                            payload: coupon,
                        })
                    }
                    orderDetails={details}
                    paymentGatewayId={state[PAYMENT_GATEWAY_ID]}
                    paymentStatusId={details?.payment_status_id}
                    paymentMethodCountryId={state[COUNTRY_ID]}
                    selectedMethod={state[TOKEN]}
                    selectedMethodType={state[PAYMENT_METHOD_TYPE_ID]}
                    setSelectedMethod={method => {
                        dispatchPaymentSection({
                            type: REDUCER_ACTION.SET_SELECTED_CARD,
                            payload: method,
                        });
                    }}
                    setSelectedPaymentGateway={paymentGatewayId =>
                        dispatchPaymentSection({
                            type: REDUCER_ACTION.SET_SELECTED_PAYMENT_GATEWAY,
                            payload: paymentGatewayId,
                        })
                    }
                />
            </div>
        </div>
    );
}

OrderDetails.propTypes = {
    details: PropTypes.shape({
        amount: PropTypes.number,
        additional_high_value_transaction_charge: PropTypes.number,
        additional_payment_method_brand_transaction_charge: PropTypes.number,
        cancellation_reason: PropTypes.string,
        cancelled_date: PropTypes.string,
        contact: PropTypes.shape({
            id: PropTypes.any,
        }),
        coupon: PropTypes.object,
        coupon_discount: PropTypes.number,
        created_date: PropTypes.string,
        currency_id: PropTypes.string,
        email: PropTypes.string,
        gst: PropTypes.number,
        id: PropTypes.string,
        invoices: PropTypes.array,
        last_refund_date: PropTypes.string,
        last_refund_reason: PropTypes.string,
        name: PropTypes.string,
        order_charges: PropTypes.object,
        payment_details: PropTypes.shape({
            account_number: PropTypes.string,
            brand_name: PropTypes.string,
            id: PropTypes.string,
            method_type: PropTypes.string,
        }),
        payment_error: PropTypes.string,
        payment_gateway_label: PropTypes.string,
        payment_id: PropTypes.string,
        payment_status_id: PropTypes.string,
        plan: PropTypes.object,
        reference_id: PropTypes.string,
        service_charge: PropTypes.number,
        status_id: PropTypes.string,
        total_amount: PropTypes.number,
        transaction_charge_without_additional_charges: PropTypes.number,
        transaction_charge: PropTypes.number,
        type_id: PropTypes.string,
        unique_id: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
};

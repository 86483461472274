/**
 * Event add/edit form
 *
 * @since 2.0.0
 */

import React from 'react';
import { EVENT_CALENDAR_FORM } from 'includes/constants';
import { Field, reduxForm } from 'redux-form';
import { Form, Row, Col } from 'antd';
import { get } from 'lodash';
import { PropTypes } from 'prop-types';
import { required } from 'includes/utils/form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as requestKeys from 'includes/constants/keys/request';
import Button from 'components/Button';
import FormField from 'components/shared/FormField';

const AddEditEventForm = props => {
    const { t } = useTranslation();
    const loadingEventsAddEdit = useSelector(state => state.events.loadingEventsAddEdit);
    const loadingEventsDelete = useSelector(state => state.events.loadingEventsDelete);

    /**
     * Get the event id
     */
    const eventId = get(props, 'initialValues.id', '');

    /**
     * Get whether we are adding or updating
     */
    const isAdd = get(props, 'initialValues.is_add');

    /**
     * Generate the label from language file
     *
     * @param   {string}    field   Field for which locale is to be generated
     *
     * @returns {string}    Generated Locale label
     */
    const localeLabel = field => t(`messages.event_calendar.form.input.label.${field}`);

    return (
        <div className="">
            <Form layout="vertical" className="edit-form" onSubmit={props.handleSubmit}>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="text"
                            name={requestKeys.EVENT_NAME}
                            label={localeLabel(requestKeys.EVENT_NAME)}
                            hasFeedback
                            component={FormField}
                            validate={[required]}
                            required
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="textarea"
                            name={requestKeys.EVENT_DESCRIPTION}
                            label={localeLabel(requestKeys.EVENT_DESCRIPTION)}
                            hasFeedback
                            component={FormField}
                            validate={[required]}
                            required
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="switch"
                            name={requestKeys.EVENT_IS_HOLIDAY}
                            label={localeLabel(requestKeys.EVENT_IS_HOLIDAY)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="switch"
                            name={requestKeys.EVENT_AUTO_SEND_REMINDERS}
                            label={localeLabel(requestKeys.EVENT_AUTO_SEND_REMINDERS)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            info={t(`messages.event_calendar.form.input.info.${requestKeys.EVENT_AUTO_SEND_REMINDERS}`)}
                            infoAsToolTip
                        />
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Field
                            type="switch"
                            name={requestKeys.EVENT_AUTO_COLLECT_PAYMENTS}
                            label={localeLabel(requestKeys.EVENT_AUTO_COLLECT_PAYMENTS)}
                            hasFeedback
                            className="form-control"
                            component={FormField}
                            info={t(
                                `messages.event_calendar.form.input.info.${requestKeys.EVENT_AUTO_COLLECT_PAYMENTS}`
                            )}
                            infoAsToolTip
                        />
                    </Col>
                </Row>
                <Form.Item className={`update-button ${props.error ? ' has-error default-form-app-error' : ''}`}>
                    {props.error ? <div className="ant-form-explain">{props.error}</div> : ''}
                    <div className="calendar-event-submit-action-wrapper">
                        {!isAdd && (
                            <Button
                                loading={loadingEventsDelete}
                                submittingButtonLabel={t(`messages.event_calendar.label.button.submitting.delete`)}
                                className="action-btn"
                                onClick={() => props.deleteCalendarEvent(eventId, get(props, 'initialValues.date', ''))}
                            >
                                {t(`messages.event_calendar.label.button.submit.delete`)}
                            </Button>
                        )}
                        <Button
                            htmlType="submit"
                            filled
                            loading={loadingEventsAddEdit}
                            submittingButtonLabel={t(
                                `messages.event_calendar.label.button.submitting.${isAdd ? 'add' : 'update'}`
                            )}
                            className="action-btn"
                        >
                            {t(`messages.event_calendar.label.button.submit.${isAdd ? 'add' : 'update'}`)}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

/**
 * Proptypes.
 */
AddEditEventForm.propTypes = {
    canEdit: PropTypes.bool,
    change: PropTypes.func,
    deleteCalendarEvent: PropTypes.func,
    error: PropTypes.any,
    handleSubmit: PropTypes.func,
    initialValues: PropTypes.object,
};

export default reduxForm({
    form: EVENT_CALENDAR_FORM,
})(AddEditEventForm);

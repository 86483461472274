/**
 * Payment arrangement Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

import AccountApi from '../services/shared/account';
import createAppSlice from './base';
import { showApiErrors, showApiSuccess } from '../utils/api';
import * as constants from '../../includes/constants';
import { isFunction } from 'lodash';

// set the initial state
const initialState = {
    isLoadingAddAccount: false,
};

// define the slice
const AccountSlice = createAppSlice('accountsSlice', initialState);
const { setData } = AccountSlice.actions;

/**
 * Add an account
 *
 * @param {object} payload Payload
 * @param {Function} callback Callback
 */
export const addAccount = (payload, callback) => async dispatch => {
    try {
        dispatch(setData('isLoadingAddAccount', true));
        const result = await AccountApi.addAccount(payload);
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, constants.ADD_ACCOUNT_FORM));
    } finally {
        dispatch(setData('isLoadingAddAccount'));
    }
};

/**
 * Delete an account
 *
 * @param {object} accountId Account ID
 * @param {Function} callback Callback
 */
export const deleteAccount = (accountId, callback) => async dispatch => {
    try {
        dispatch(setData('isDeletingAccount', true));
        const result = await AccountApi.deleteAccount(accountId);
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, undefined, undefined, undefined, ['account']));
    } finally {
        dispatch(setData('isDeletingAccount'));
    }
};

// export the reducer
export default AccountSlice.reducer;

/**
 * Placeholders Component
 * Handles the placeholders view and functions related to placeholders
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

//import required modules
import { React, PropTypes, useState } from '../../../includes/exports/react';
import AddEditPlaceholder from '../../shared/Placeholders/AddEditPlaceholder';
import PlaceholdersList from '../../shared/Placeholders';

/**
 * Placeholder Component
 */
const Placeholders = props => {
    // default states
    const [listView, setListView] = useState(true);
    const [initialValues, setInitialValues] = useState({});
    const { organisationId } = props.match.params;

    return (
        <div className="white-box-wrapper box-wrapper full-wrapper">
            {listView ? (
                <PlaceholdersList
                    {...props}
                    setListView={setListView}
                    setInitialValues={setInitialValues}
                    organisationId={organisationId}
                />
            ) : (
                <AddEditPlaceholder
                    {...props}
                    setListView={setListView}
                    initialValues={initialValues}
                    organisationId={organisationId}
                />
            )}
        </div>
    );
};

/**
 * Prop Types
 */
Placeholders.propTypes = {
    match: PropTypes.any,
};

export default Placeholders;

/**
 * useInvoiceTypes
 * Fetches invoice types and caches it
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import { get } from 'lodash';

import { GET_INVOICE_TYPES } from 'includes/constants/mappings/success';
import { CACHE_INVOICE_TYPES_KEY } from '../constants';
import InvoicesAPI from 'includes/services/customer/invoice';
import useCachedData from './useCachedData';

/**
 * Returns invoice types if cached else call the API and caches the result
 *
 * @param {boolean} disableApiCall should disable API call
 *
 * @returns {object} returns `{ data, isLoading, refetch, reset, update }`
 */
const useInvoiceTypes = (disableApiCall = false) => {
    return useCachedData(CACHE_INVOICE_TYPES_KEY, () => InvoicesAPI.getInvoiceTypes({ page_size: 0 }), {
        selector: res => {
            const invoiceTypes = get(res, GET_INVOICE_TYPES);
            return invoiceTypes.map(type => ({ ...type, label: type.type, value: type.id }));
        },
        disableApiCall,
        dependsOnOrg: false,
    });
};

export default useInvoiceTypes;

/**
 * Taxes Slice
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */
import * as successMappings from 'includes/constants/mappings/success';
import createAppSlice from './base';
import TaxesApi from 'includes/services/taxes';
import { get, isFunction } from 'lodash';
import { getPaginationData, redirect } from 'includes/utils';
import { showApiErrors, showApiSuccess } from '../utils/api';
import { TAX } from 'includes/constants/mappings/success';
import { ID } from '../constants/keys/response';

const initialState = {
    loadingTax: false,
    loadingTaxAddUpdate: false,
    loadingTaxDelete: false,
    loadingTaxes: false,
    loadingTaxTypes: false,
    tax: {},
    taxes: [],
    taxesPagination: {},
    taxTypes: [],
};

// define the slice
const TaxesSlice = createAppSlice('taxSlice', initialState);
const { setData } = TaxesSlice.actions;

/**
 * Add tax
 *
 * @param {object} taxDetails Tax details
 * @param {Function} getRedirectUrl Get Redirect URL
 * @param {string} organisationId Organisation Id. Default ''
 */
export const addTax = (taxDetails, getRedirectUrl, organisationId = '') => async dispatch => {
    try {
        dispatch(setData('loadingTaxAddUpdate', true));
        const result = await TaxesApi.addTax(taxDetails, organisationId);
        const taxId = get(result, [...TAX, ID]);
        dispatch(showApiSuccess(result));
        redirect(getRedirectUrl(taxId));
    } catch (errors) {
        dispatch(showApiErrors(errors, 'TAX_FORM_NAME', 'TAX'));
    } finally {
        dispatch(setData('loadingTaxAddUpdate', false));
    }
};

/**
 * Delete tax
 *
 * @param {string} taxId Tax Id
 * @param {string} organisationId Organisation Id. Default ''
 * @param {Function} callback Callback function
 */
export const deleteTax = (taxId, organisationId = '', callback = () => {}) => async dispatch => {
    try {
        dispatch(setData('loadingTaxDelete', true));
        const result = await TaxesApi.deleteTax(taxId, organisationId);
        dispatch(setData('taxes', get(result, successMappings.TAXES)));
        dispatch(setData('taxesPagination', getPaginationData({ result })));
        dispatch(showApiSuccess(result));
        if (callback && isFunction(callback)) {
            callback();
        }
    } catch (errors) {
        dispatch(showApiErrors(errors, '', 'TAXES'));
    } finally {
        dispatch(setData('loadingTaxDelete', false));
    }
};

/**
 * Get the tax
 *
 * @param {string} taxId Tax Id
 * @param {string} organisationId Organisation Id. Default ''
 */
export const getTax = (taxId, organisationId = '') => async dispatch => {
    try {
        dispatch(setData('loadingTax', true));
        const result = await TaxesApi.getTax(taxId, organisationId);
        dispatch(setData('tax', get(result, successMappings.TAX)));
    } catch (errors) {
        dispatch(showApiErrors(errors, 'TAX_FORM_NAME', 'TAX'));
    } finally {
        dispatch(setData('loadingTax', false));
    }
};

/**
 * Get taxes
 *
 * @param {string} organisationId organisation Id. Default ''
 * @param {object} searchParams search params. Default {}
 */
export const getTaxes = (organisationId = '', searchParams = {}) => async dispatch => {
    try {
        dispatch(setData('loadingTaxes', true));
        const result = await TaxesApi.getTaxes(searchParams, organisationId);
        dispatch(setData('taxes', get(result, successMappings.TAXES)));
        dispatch(setData('taxesPagination', getPaginationData({ result })));
    } finally {
        dispatch(setData('loadingTaxes', false));
    }
};

/**
 * Get tax types
 */
export const getTaxTypes = () => async dispatch => {
    try {
        dispatch(setData('loadingTaxTypes', true));
        const result = await TaxesApi.getTaxTypes();
        dispatch(setData('taxTypes', get(result, successMappings.TAX_TYPES)));
    } finally {
        dispatch(setData('loadingTaxTypes', false));
    }
};

/**
 * Update tax
 *
 * @param {string} taxId Tax Id to update
 * @param {object} taxDetails Tax Details
 * @param {Function} getRedirectUrl GetURL to redirect after updating tax
 * @param {string} organisationId Organisation Id. Default ''
 */
export const updateTax = (taxId, taxDetails, getRedirectUrl, organisationId = '') => async dispatch => {
    try {
        dispatch(setData('loadingTaxAddUpdate', true));
        const result = await TaxesApi.updateTax(taxId, taxDetails, organisationId);
        dispatch(showApiSuccess(result));
        redirect(getRedirectUrl(taxId));
    } catch (errors) {
        dispatch(showApiErrors(errors, 'TAX_FORM_NAME', 'TAX'));
    } finally {
        dispatch(setData('loadingTaxAddUpdate', false));
    }
};

export default TaxesSlice.reducer;

/**
 * Invoice Preview Modal
 * Fetches and renders the preview of invoice using the passed in invoice details
 *
 * @version 1.0
 * @author Aravind Rajan <aravind@paycepaid.com.au>
 */

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'includes/exports/react';
import { Modal } from 'antd';

import ShadowRoot from 'components/ShadowRoot';
import Loader from 'components/shared/Loader';
import { getInvoicePreview, setPreview } from 'includes/slices/invoice';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import './styles.scss';

const PreviewModal = ({ isOpen, onClose, invoiceDetails }) => {
    const isInvoicePreviewLoading = useSelector(state => state.invoices.isPreviewLoading);
    const invoicePreview = useSelector(state => state.invoices.preview);
    const dispatch = useDispatch();
    const organisationId = useOrganisationId();

    useEffect(() => {
        if (isOpen) dispatch(getInvoicePreview(organisationId, invoiceDetails));
    }, [isOpen, invoiceDetails]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Modal
            className="invoice-preview-modal custom-form custom-form-modal"
            visible={isOpen}
            footer={[]}
            onCancel={() => {
                onClose();
                dispatch(setPreview(''));
            }}
        >
            <header>Preview</header>
            {isInvoicePreviewLoading ? (
                <Loader />
            ) : (
                <ShadowRoot className="shadow-modal-body" htmlContent={invoicePreview} />
            )}
        </Modal>
    );
};

PreviewModal.propTypes = {
    invoiceDetails: PropTypes.any,
    isOpen: PropTypes.any,
    onClose: PropTypes.func,
};

export default PreviewModal;

/**
 * Business Information edit
 * Renders the business information edit form
 *
 * @version 1.0
 * @author Aravind Rajan <aravindrajan@qburst.com>
 */

// import required modules
import { React, useEffect, useState, Link } from '../../../includes/exports/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'antd';
import { withTranslation } from 'react-i18next';
import { get, isString, omit } from 'lodash';
import { getAccountDetails, updateAccountDetails } from '../../../includes/redux/actions/shared/account';
import BusinessInfoEditForm from '../../shared/forms/BusinessInfoEditForm';
import Loader from '../../shared/Loader';
import * as requestKeys from '../../../includes/constants/keys/request';
import { objectToFormData } from '../../../includes/utils/form';
import { useCheckEditPermission, useRestrictCustomerManager } from '../../../includes/utils/hooks';

/**
 * Change Password component
 * Renders the change password form
 */
const BusinessInfoEdit = props => {
    const isAdmin = props.isAdmin;
    const accountId = isAdmin ? props.match.params.accountId : props.currentAccountId;
    const userAccountDetails = props.currentAccountId === accountId ? props.accountDetails : props.userAccountDetails;

    useRestrictCustomerManager();

    //effect to fetch account details
    useEffect(() => {
        if (accountId) {
            if (props.currentAccountId !== accountId && !isAdmin) {
                props.getAccountDetails(accountId);
            }
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // state to handle profile image picker modal visibility
    const [imagePickerVisible, setImagePickerVisible] = useState(false);

    const toggleImagePicker = () => setImagePickerVisible(!imagePickerVisible);

    useCheckEditPermission();

    /**
     * Submit handler function for handling changing user password
     */
    const handleSubmit = ({ ...values }) => {
        // the values object may contain the url of the user avatar if no new avatar is uploaded,
        // we don't need it to submit with the form remove it from the object
        if (requestKeys.LOGO in values && isString(get(values, requestKeys.LOGO))) {
            delete values[requestKeys.LOGO];
        }

        if (requestKeys.SUBSCRIPTION in values) {
            delete values[requestKeys.SUBSCRIPTION];
        }

        if (requestKeys.CURRENCY in values) {
            delete values[requestKeys.CURRENCY];
        }

        props.updateAccountDetails(accountId, objectToFormData(values));
    };
    /**
     * Render the component
     *
     * @returns {*}
     */
    return (
        <>
            <div className="home-content-wrapper edit-profile-wrapper">
                <div className="tab-arrow-link">
                    {isAdmin ? (
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/admin/accounts">Account</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                <Link to={`/admin/accounts/${accountId}`}>Account Details</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Edit Account Details</Breadcrumb.Item>
                        </Breadcrumb>
                    ) : (
                        <Breadcrumb>
                            <Breadcrumb.Item>
                                <Link to="/my-account">My Account</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>Edit Business Information</Breadcrumb.Item>
                        </Breadcrumb>
                    )}
                </div>
                <h2 className="page-title">{props.t('customerMessages.profile.businessInformation.title')}</h2>
                <div
                    className={
                        isAdmin
                            ? 'white-box-wrapper full-wrapper'
                            : 'white-box-wrapper box-wrapper mid-wrapper text-left'
                    }
                >
                    <div className="my-profile-detail my-account-profile">
                        {props.isAccountDetailsLoading ? (
                            <Loader />
                        ) : (
                            <BusinessInfoEditForm
                                initialValues={omit(
                                    userAccountDetails,
                                    get(userAccountDetails, 'isBusinessNameUpdated') ? [requestKeys.NAME] : []
                                )}
                                onSubmit={handleSubmit}
                                imagePickerVisible={imagePickerVisible}
                                toggleImagePicker={toggleImagePicker}
                                loading={props.isAccountDetailsUpdating}
                                defaultImage={require('../../../assets/images/organisation_avatar.svg')}
                                isAdmin={isAdmin}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

/**
 * Proptypes
 */
BusinessInfoEdit.propTypes = {
    accountDetails: PropTypes.object,
    currentAccountId: PropTypes.string,
    getAccountDetails: PropTypes.func,
    isAccountDetailsLoading: PropTypes.bool,
    isAccountDetailsUpdating: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isBusinessNameUpdated: PropTypes.bool,
    match: PropTypes.object,
    passwordUpdatedMessage: PropTypes.string,
    t: PropTypes.func,
    updateAccountDetails: PropTypes.func,
    userAccountDetails: PropTypes.object,
};

//Connect to store
export default connect(
    state => ({
        currentAccountId: state.account.currentAccountId,
        accountDetails: state.account.accountDetails,
        isAccountDetailsLoading: state.account.isAccountDetailsLoading,
        isAccountDetailsUpdating: state.account.isAccountDetailsUpdating,
        roles: state.user.roles,
        isAdmin: state.account.isAdmin,
        isBusinessNameUpdated: get(state, 'account.accountDetails.business_name_updated', false),
        userAccountDetails: state.account.userAccountDetails,
    }),
    {
        getAccountDetails,
        updateAccountDetails,
    }
)(withTranslation()(BusinessInfoEdit));

/**
 * Payment arrangement plan details Component
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@paycepaid.com.au>
 */

//import required modules
import React, { useEffect } from 'react';
import { find, get, isEmpty } from 'lodash';
import { Breadcrumb, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import PaymentArrangementPlanDefaultImage from 'assets/images/payment-arrangement-plan.png';
import Loader from 'components/Loader';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import { useUpdateBusinessName } from 'includes/utils/hooks';
import { redirect } from 'includes/utils';
import { getPaymentPlan } from 'includes/slices/paymentArrangement';
import useAccountId from 'includes/hooks/useAccountId';
import PaymentPlansBreadCrumbItems from 'components/BreadCrumbItems/PaymentPlans';
import {
    PAYMENT_ARRANGEMENT_PLAN_TYPE_ARRANGEMENT,
    PAYMENT_PLAN_FREQUENCY_TYPE_OPTIONS,
    PAYMENT_PLAN_PAYMENT_FREQUENCY_TYPE_OPTIONS,
    PAYMENT_PLAN_UNPAID_INSTALLMENT_CANCEL_TYPE_OPTIONS,
} from 'includes/constants';
import useCurrencyFormatter from 'includes/hooks/useCurrencyFormatter';
import PaymentArrangementsList from 'pages/PaymentArrangements/List';
import CampaignsList from 'pages/Campaigns/List';
import { PAYMENT_ARRANGEMENT_PLANS } from 'includes/constants/permissions';
import PermissionGuard from 'components/PermissionGuard';
import PermissionGuardLayout from 'layouts/PermissionGuardLayout';
import AuditLogList from 'pages/AuditLogs/List';

const { TabPane } = Tabs;

const PaymentPlanDetails = () => {
    const { t } = useTranslation();
    const params = useParams();
    const dispatch = useDispatch();
    const accountId = useAccountId();
    const organisationId = useOrganisationId();
    useUpdateBusinessName();

    const { paymentPlanId } = params;
    const paymentPlanDetails = useSelector(state => state.paymentArrangement.paymentPlan);
    const isAdmin = useSelector(state => state.account.isAdmin);
    const loadingPaymentPlan = useSelector(state => state.paymentArrangement.loadingPaymentPlan);
    const getCurrencyFormattedAmount = useCurrencyFormatter();
    const isArrangement = get(paymentPlanDetails, 'plan_type') === PAYMENT_ARRANGEMENT_PLAN_TYPE_ARRANGEMENT;

    /**
     * Get the payment arrangement plan
     */
    useEffect(() => {
        dispatch(getPaymentPlan(organisationId, paymentPlanId));
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Get duration type value
     *
     * @param {string} key Key for duration
     * @param {string} typeKey Type key for duration
     * @param {Array} typeOptions Type options
     *
     * @returns {string} Duration type value
     */
    const getDurationTypeValue = (key, typeKey, typeOptions = []) => {
        return `${get(paymentPlanDetails, key, 0)} ${get(
            find(!isEmpty(typeOptions) ? typeOptions : PAYMENT_PLAN_FREQUENCY_TYPE_OPTIONS, {
                id: get(paymentPlanDetails, typeKey, ''),
            }),
            'label',
            ''
        )}`;
    };

    /**
     * Get the payment arrangement plan edit link
     *
     * @returns {string} Edit link
     */
    const getEditLink = () =>
        isAdmin
            ? `/admin/accounts/organisation/payment-plans/plan/${accountId}/${organisationId}/${paymentPlanId}/edit`
            : `/payment-plans/plan/${paymentPlanId}/edit`;

    /**
     * Get the localized label
     *
     * @param {string} field Field for which locale is to be generated
     *
     * @returns {string} Generated Locale label
     */
    const getLocaleLabel = field => t(`messages.paymentArrangement.plans.addEdit.form.input.label.${field}`);

    /**
     * Get Yes or No based on boolean value
     *
     * @param {string} key Key for which we need to retrieve the value
     *
     * @returns {string} Value
     */
    const getYesOrNo = key => getLocaleLabel(get(paymentPlanDetails, key) ? 'yes' : 'no');

    return (
        <PermissionGuardLayout
            requiredPermission={
                isArrangement ? PAYMENT_ARRANGEMENT_PLANS.ARRANGEMENT.VIEW : PAYMENT_ARRANGEMENT_PLANS.EXTENSION.VIEW
            }
            layout="section"
        >
            <section>
                <div className="home-content-wrapper synced-name contact-details">
                    <div className="tab-arrow-link breadcrumb-spec">
                        <Breadcrumb>
                            <PaymentPlansBreadCrumbItems showListBreadCrumb showDetailsBreadCrumb />
                        </Breadcrumb>
                    </div>
                    <h2 className="page-title">{t(`titleAndMetas.paymentPlan.pageTitle`)}</h2>
                    <div className="white-box-wrapper mid-wrapper breadcrumb-spec details-wrapper">
                        {loadingPaymentPlan ? (
                            <Loader />
                        ) : (
                            <>
                                <section className="details-list left-align">
                                    <div className="details-profile-pic-wrapper">
                                        <img
                                            src={PaymentArrangementPlanDefaultImage}
                                            className="org-avatar contact-details-spec logo-placeholder"
                                            alt="payment arrangement plan pic"
                                        />
                                    </div>
                                    <div className="center-align">
                                        <PermissionGuard
                                            requiredPermission={
                                                isArrangement
                                                    ? PAYMENT_ARRANGEMENT_PLANS.ARRANGEMENT.EDIT
                                                    : PAYMENT_ARRANGEMENT_PLANS.EXTENSION.EDIT
                                            }
                                        >
                                            <Button
                                                className="green-bg-button"
                                                big
                                                filled
                                                onClick={() => redirect(getEditLink())}
                                            >
                                                {t(`messages.paymentArrangement.plans.crud.edit`)}
                                            </Button>
                                        </PermissionGuard>
                                    </div>
                                </section>
                                <section className="details-list right-align">
                                    <div className="full-wrapper">
                                        <h3 className="profile-name">{get(paymentPlanDetails, 'name')}</h3>
                                    </div>
                                    <section className="detail-summary">
                                        <ul className="upper-right-detail">
                                            <li>
                                                <header>{getLocaleLabel('fee')}</header>
                                                <div>
                                                    {getCurrencyFormattedAmount(get(paymentPlanDetails, 'fee', 0))}
                                                </div>
                                            </li>
                                            <li>
                                                <header>{getLocaleLabel('upfront_amount')}</header>
                                                <div>
                                                    {`${get(paymentPlanDetails, 'min_upfront_amount')}-${get(
                                                        paymentPlanDetails,
                                                        'max_upfront_amount'
                                                    )}%`}
                                                </div>
                                            </li>
                                            <li>
                                                <header>{getLocaleLabel('max_upfront_amount_duration')}</header>
                                                <div>
                                                    {getDurationTypeValue(
                                                        'max_upfront_amount_duration',
                                                        'max_upfront_amount_duration_type'
                                                    )}
                                                </div>
                                            </li>
                                            <li>
                                                <header>{getLocaleLabel('overdue_range')}</header>
                                                <div>{`${
                                                    get(paymentPlanDetails, 'overdue_range_type') === 'l'
                                                        ? `Less than  or equal to ${get(
                                                              paymentPlanDetails,
                                                              'overdue_range_to'
                                                          )} day(s)`
                                                        : get(paymentPlanDetails, 'overdue_range_type') === 'g'
                                                        ? `Greater than or equal to ${get(
                                                              paymentPlanDetails,
                                                              'overdue_range_from'
                                                          )} day(s)`
                                                        : `${get(paymentPlanDetails, 'overdue_range_from')}-${get(
                                                              paymentPlanDetails,
                                                              'overdue_range_to'
                                                          )} day(s)`
                                                }`}</div>
                                            </li>
                                            <li>
                                                <header>
                                                    {getLocaleLabel(
                                                        isArrangement
                                                            ? 'max_duration'
                                                            : 'max_payment_date_extension_duration'
                                                    )}
                                                </header>
                                                <div>
                                                    {getDurationTypeValue(
                                                        isArrangement
                                                            ? 'max_duration'
                                                            : 'max_payment_date_extension_duration',
                                                        isArrangement
                                                            ? 'max_duration_type'
                                                            : 'max_payment_date_extension_duration_type'
                                                    )}
                                                </div>
                                            </li>
                                            {isArrangement ? (
                                                <>
                                                    <li>
                                                        <header>{getLocaleLabel('payment_frequency_type')}</header>
                                                        <div>
                                                            {get(
                                                                find(PAYMENT_PLAN_PAYMENT_FREQUENCY_TYPE_OPTIONS, {
                                                                    id: paymentPlanDetails?.payment_frequency_type,
                                                                }),
                                                                'label',
                                                                ''
                                                            )}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <header>
                                                            {getLocaleLabel('max_first_payment_date_duration')}
                                                        </header>
                                                        <div>
                                                            {getDurationTypeValue(
                                                                'max_first_payment_date_duration',
                                                                'max_first_payment_date_duration_type'
                                                            )}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <header>
                                                            {getLocaleLabel('max_unpaid_installment_cancel_duration')}
                                                        </header>
                                                        <div>
                                                            {getDurationTypeValue(
                                                                'max_unpaid_installment_cancel_duration',
                                                                'max_unpaid_installment_cancel_duration_type',
                                                                PAYMENT_PLAN_UNPAID_INSTALLMENT_CANCEL_TYPE_OPTIONS
                                                            )}
                                                        </div>
                                                    </li>
                                                </>
                                            ) : null}
                                            <li>
                                                <header>{getLocaleLabel('allow_notes')}</header>
                                                <div>{getYesOrNo('allow_notes')}</div>
                                            </li>
                                            <li>
                                                <header>{getLocaleLabel('auto_approve')}</header>
                                                <div>{getYesOrNo('auto_approve')}</div>
                                            </li>
                                            <li>
                                                <header>{getLocaleLabel('is_enabled')}</header>
                                                <div>{getYesOrNo('is_enabled')}</div>
                                            </li>
                                        </ul>
                                    </section>
                                </section>
                            </>
                        )}
                    </div>
                    {loadingPaymentPlan ? null : (
                        <div className="full-wrapper box-wrapper white-box-wrapper no-padding myaccount-content tab-wrapper">
                            <Tabs>
                                <TabPane
                                    key={isArrangement ? 'payment-arrangement' : 'payment-extension'}
                                    tab={isArrangement ? 'Payment Arrangements' : 'Payment Extensions'}
                                >
                                    <PaymentArrangementsList
                                        currentPaymentPlanType={isArrangement ? 'arrangement' : 'extension'}
                                        paymentPlanId={paymentPlanId}
                                        showTitle={false}
                                    />
                                </TabPane>
                                <TabPane key="campaign" tab="Broken arrangement campaign">
                                    <CampaignsList
                                        campaignType={2}
                                        paymentPlanId={paymentPlanId}
                                        showTitleAndBreadCrumb={false}
                                    />
                                </TabPane>
                                <TabPane key="audit-logs" tab="Audit Logs">
                                    <AuditLogList
                                        customDataValues={{ payment_plan_id: paymentPlanId }}
                                        showTitle={false}
                                    />
                                </TabPane>
                            </Tabs>
                        </div>
                    )}
                </div>
            </section>
        </PermissionGuardLayout>
    );
};

export default PaymentPlanDetails;

/**
 * Local Storage Saga
 * Handles the operations related to local storage
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

//import the required modules
import * as actionTypes from '../../../../constants/actionTypes';
import BaseSagaHandler from '../../core/base';
import { takeLatest, all, put } from 'redux-saga/effects';

/**
 * LocalStorage Class. Handles the localstorage related operations
 * */
class LocalStorageSaga extends BaseSagaHandler {
    /**
     * The localStorage Watcher Saga
     * Watches the redux actions related to localStorage and invokes the specified saga
     *
     * takeLatest ensures that only the latest actions are caught and handled by the specified saga (canceling any previous saga task started previously if it's still running)
     *
     * all sagas take action as argument
     */
    *localStorageWatchers() {
        let context = this;
        yield all([yield takeLatest(actionTypes.LOCAL_STORAGE_VALUE_CHANGE, [context, 'localStorageValueChanged'])]);
    }

    /**
     * Dispatch LOCAL_STORAGE_VALUE_CHANGED action on local storage value change
     *
     */
    *localStorageValueChanged(action) {
        yield put({ type: actionTypes.LOCAL_STORAGE_VALUE_CHANGED, localStorageChanges: action });
    }
}

//export the local storage saga
export default new LocalStorageSaga().forkAllWatcherFunctions();

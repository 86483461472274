/**
 * App Footer Component
 * Handles the app footer view and functions related to loading footer
 * This component is a shared component which can be used in different modules
 *
 * @version 1.0
 * @author Sabarinath Thulasidharan <sabarinath@qburst.com>
 */

// import the required modules
import { React } from '../../../../includes/exports/react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

/**
 * App Footer Component
 */
export default function AppFooter() {
    const { t } = useTranslation();
    return (
        <div className="center-align">
            &copy; {moment().year()} <b>{t('sharedMessages.footer.paycePaidText')}</b>.{' '}
            {t('sharedMessages.footer.copyrightText')}
        </div>
    );
}

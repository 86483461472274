/**
 * Communication API class.
 * Handles all api requests related to communication
 *
 * @version 1.0
 * @author Shiny Raj <shiny@qburst.com>
 */

//import base api handler
import BaseApiHandler from '../base/api';

class CommunicationApi extends BaseApiHandler {
    /**
     * Call the add communication template API
     *
     * @param {string} organisation_id Organisation Id
     * @param {object} templateDetails Template details to add
     *
     * @returns {object} API Response
     */
    addCommunicationTemplate = ({ organisation_id, templateDetails }) =>
        this.axios.post(
            this.getApiUrl('COMMUNICATION_TEMPLATES_BY_ORGANISATION_ID', { organisation_id: organisation_id }),
            templateDetails
        );

    /**
     * Call the delete communication template API
     *
     * @param {string} organisation_id Organisation Id
     * @param {string} templateId Template id
     *
     * @returns {object} API Response
     */
    deleteCommunicationTemplate = ({ organisationId, templateId }) => {
        return this.axios.delete(
            this.getApiUrl('COMMUNICATION_TEMPLATE_DETAILS_BY_ORGANISATION', {
                communication_template_id: templateId,
                organisation_id: organisationId,
            })
        );
    };

    /**
     * Call the communication log  history API
     *
     * @param {string} organisationId Organisation Id
     * @param {object} queryParams Query Params
     *
     * @returns {object} API Response
     */
    getCommunicationLogHistory = ({ organisationId, queryParams }) =>
        this.axios.get(this.getApiUrl('COMMUNICATION_LOG_GET_HISTORY', { organisation_id: organisationId }), {
            params: queryParams,
        });

    /**
     * Call the communication log history details API
     *
     * @param {string} organisationId Organisation Id
     * @param {string} communicationLogHistoryId Reminder history Id
     * @param {object} queryParams Query Params
     *
     * @returns {object} API Response
     */
    getCommunicationLogHistoryDetails = ({ organisationId, communicationLogHistoryId, queryParams }) =>
        this.axios.get(
            this.getApiUrl('COMMUNICATION_LOG_GET_HISTORY_DETAILS', {
                organisation_id: organisationId,
                communication_log_history_id: communicationLogHistoryId,
            }),
            { params: queryParams }
        );

    /**
     * Call the api to get communication log statuses
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     * */
    getCommunicationLogStatuses = payload =>
        this.axios.get(this.getApiUrl('COMMUNICATION_LOG_STATUSES'), { params: payload });

    /**
     * Call the api to get communication template details
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getCommunicationTemplateDetails = payload =>
        this.axios.get(
            this.getApiUrl(
                payload.organisation_id
                    ? 'COMMUNICATION_TEMPLATE_DETAILS_BY_ORGANISATION'
                    : 'COMMUNICATION_TEMPLATE_DETAILS',
                { communication_template_id: payload.template_id, ...payload }
            )
        );

    /**
     * Call the api to get communication template preview
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     */
    getCommunicationTemplatePreview = payload => {
        return this.axios.post(this.getApiUrl('COMMUNICATION_TEMPLATE_PREVIEW'), { ...payload });
    };

    /**
     * Call the api to get communication template types
     *
     * @param {object} payload Payload
     *
     * @returns {object} API Response
     * */
    getCommunicationTemplateTypes = payload =>
        this.axios.get(this.getApiUrl('COMMUNICATION_TEMPLATE_TYPES'), {
            params: payload,
        });

    /**
     * Call the api to get communication templates
     *
     * @param {object} queryParams Query Params
     * @param {object} searchParams Search Params
     * @param {object} restPayload Payload
     *
     * @returns {object} API Response
     */
    getCommunicationTemplates = ({ queryParams, searchParams, ...restPayload }) => {
        return this.axios.get(this.getApiUrl('COMMUNICATION_TEMPLATES_BY_ORGANISATION_ID', restPayload), {
            params: { ...queryParams, ...searchParams },
        });
    };

    /**
     * Call the api to get communication types
     *
     * @param {object} queryParams Query Params
     *
     * @returns {object} API Response
     * */
    getCommunicationTypes = queryParams =>
        this.axios.get(this.getApiUrl('COMMUNICATION_TYPES'), { params: { ...queryParams } });

    /**
     * Call the api to update the communication template
     *
     * @param {object} templateDetails Template details to update
     *
     * @returns {object} API Response
     */
    updateCommunicationTemplate = templateDetails => {
        return this.axios.patch(
            this.getApiUrl('COMMUNICATION_TEMPLATE_DETAILS_BY_ORGANISATION', {
                communication_template_id: templateDetails.id,
                organisation_id: templateDetails.organisation_id,
            }),
            templateDetails
        );
    };
}

export default new CommunicationApi();

/**
 * Events Calendar Component.
 *
 * @since 2.0.0
 */

import React, { useEffect, useState } from 'react';
import { addEvents, deleteEvents, getEvents, updateEvents } from 'includes/slices/events';
import { Badge, Modal, Button as AntButton, Skeleton, Calendar } from 'antd';
import { find, get, isEmpty, times } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddEditEventForm from './components/Form';
import InfoMessage from 'components/shared/messages/InfoMessage';
import moment from 'moment';
import useOrganisationId from 'includes/hooks/useOrganisationId';
import './styles.scss';
import useIsAdminOrManager from 'includes/hooks/useIsAdminOrManager';
import useOrganisationRequired from 'includes/hooks/useOrganisationRequired';

const { confirm, error } = Modal;

const EventsCalendar = () => {
    const [isAddingEditingDeletingWeekends, setIsAddingEditingDeletingWeekends] = useState(false);
    const [isWeekendEdit, setIsWeekendEdit] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const today = moment();
    const [selectedDefaultDate, setSelectedDefaultDate] = useState(today);
    const [showDateDisabledPopup, setShowDateDisabledPopup] = useState(false);
    const isAdminOrManager = useIsAdminOrManager();
    const { t } = useTranslation();
    const closeModal = () => {
        setSelectedDate(null);
        setSelectedEventId(null);
    };
    const dispatch = useDispatch();
    const events = useSelector(state => state.events.events);
    const loadingEvents = useSelector(state => state.events.loadingEvents);
    const organisationId = useOrganisationId();

    useOrganisationRequired(isAdminOrManager ? false : true);

    useEffect(() => {
        const makeApiCall = !isAdminOrManager && !organisationId ? false : true;
        if (makeApiCall) {
            getEventsForTheMonthAndYear(today);
        }
    }, [organisationId]); // eslint-disable-line react-hooks/exhaustive-deps

    /**
     * Add or update calendar event
     *
     * @param {object} values Event values
     */
    const addOrUpdateCalendarEvent = values => {
        if (selectedEventId) {
            dispatch(updateEvents({ events: [values] }, organisationId, closeEditDeletePopup));
        } else {
            dispatch(addEvents({ events: [values] }, organisationId, closeEditDeletePopup));
        }
    };

    /**
     * Add events on all weekends of the month
     *
     * @param {object} values Form values
     */
    const addCalendarWeekendEvents = values => {
        let weekendEvents = [];
        let monthDate = moment(selectedDefaultDate).startOf('month');
        let hasWeekends = false;

        times(monthDate.daysInMonth(), function() {
            let dayNumber = monthDate.day();
            // sunday is 0 and saturday is 6 in moment js
            if (dayNumber === 0 || dayNumber === 6) {
                hasWeekends = true;
                let weekendEvent = Object.assign({}, values);
                weekendEvent.date = monthDate.format('YYYY-MM-DD');
                weekendEvents.push(weekendEvent);
            }
            monthDate.add(1, 'day');
        });

        if (hasWeekends) {
            dispatch(addEvents({ events: weekendEvents }, organisationId, closeEditDeletePopup));
        } else {
            setIsAddingEditingDeletingWeekends(true);
            setShowDateDisabledPopup(true);
        }
    };

    /**
     * Callback function to close the edit or delete popup
     */
    const closeEditDeletePopup = () => {
        setSelectedDate(null);
        setSelectedEventId(null);
        setIsWeekendEdit(false);
    };

    /**
     * Render the date cell of the calendar
     *
     * @param {Date} date Date for which event is to be retrieved
     *
     * @returns {*}
     */
    const dateCellRender = date => (
        <ul className="events" style={{ height: '100%', width: '100%' }} onClick={() => setSelectedDate(date)}>
            {getEventData(date).map(event => (
                <li key={event.name} className="event-calendar-cell" onClick={() => setSelectedEventId(event.id)}>
                    <Badge
                        color={event.is_holiday ? 'purple' : 'magenta'}
                        className={event.is_holiday && 'event-calendar-holiday'}
                        text={event.name}
                    />
                </li>
            ))}
        </ul>
    );

    /**
     * Delete teh calendar event
     *
     * @param {string} id Id of the event to be deleted
     * @param {string} date Date for which the event is deleted
     */
    const deleteCalendarEvent = (id, date) => {
        if (id) {
            // find all events in the date
            const eventsInDate = get(events, date);

            // find the event
            let event = find(eventsInDate, { id: id });

            if (event) {
                dispatch(deleteEvents([event.id], organisationId, closeEditDeletePopup));
            }
        }
    };

    /**
     * Get the event data
     *
     * @param {Date} date Date for which event is to be retrieved
     *
     * @returns {object} Event data
     */
    const getEventData = date => get(events, date ? date.format('YYYY-MM-DD') : '', []);

    /**
     * Get the events based on the current date and month
     *
     * @param {moment.Moment} date Current date
     */
    const getEventsForTheMonthAndYear = date => {
        const momentDate = moment(date);
        const month = momentDate.month();
        const year = momentDate.year();
        setSelectedDefaultDate(momentDate);
        dispatch(getEvents(organisationId, { month: month, year: year }));
    };

    /**
     * Get initial values for loading data in event calendar popup
     */
    const getInitialValues = () => {
        const selectedEvent = selectedEventId ? find(getEventData(selectedDate), { id: selectedEventId }) : '';
        return (
            selectedEvent || {
                is_holiday: false,
                date: selectedDate ? selectedDate.format('YYYY-MM-DD') : '',
                is_add: true,
                auto_send_reminders: true,
                auto_collect_payments: true,
            }
        );
    };

    /**
     * Render the edit modal
     */
    const renderEditModal = (visible, submitHandler, closeModal) => (
        <Modal
            className="custom-modal ant-calender-wrapper"
            visible={visible}
            destroyOnClose
            onCancel={() => {
                closeEditDeletePopup();
            }}
            footer={[]}
        >
            <AddEditEventForm
                initialValues={getInitialValues()}
                date={selectedDate}
                closeModal={closeModal}
                deleteCalendarEvent={deleteCalendarEvent}
                onSubmit={submitHandler}
                enableReinitialize
            />
        </Modal>
    );

    /**
     * Remove the weekend events for the current month
     */
    const removeWeekendEvents = () => {
        let weekendEvents = [];
        let monthDate = moment(selectedDefaultDate).startOf('month');
        let hasWeekends = false;

        times(monthDate.daysInMonth(), function() {
            let dayNumber = monthDate.day();
            // sunday is 0 and saturday is 6 in moment js
            if (dayNumber === 0 || dayNumber === 6) {
                hasWeekends = true;
                let currentMonthDate = monthDate.format('YYYY-MM-DD');
                // find all events in the date
                let eventsInDate = get(events, currentMonthDate);
                if (eventsInDate) {
                    eventsInDate.forEach(function(event) {
                        weekendEvents.push(event.id);
                    }, eventsInDate);
                }
            }
            monthDate.add(1, 'day');
        });

        if (hasWeekends) {
            if (!isEmpty(weekendEvents)) {
                confirm({
                    cancelText: t('appCommonMessages.no'),
                    content: 'Are you sure you want to remove all weekend events for this month?',
                    okText: t('appCommonMessages.yes'),
                    title: '',
                    onOk: () => {
                        dispatch(deleteEvents(weekendEvents, organisationId, closeEditDeletePopup));
                    },
                });
            } else {
                error({
                    content: 'No weekend events to remove',
                    title: '',
                });
            }
        } else {
            setIsAddingEditingDeletingWeekends(true);
            setShowDateDisabledPopup(true);
        }
    };

    return (
        <div className="home-content-wrapper calender-wrapper">
            <h2 className="page-title">Event Calendar</h2>
            <div className="white-box-wrapper box-wrapper full-wrapper">
                {loadingEvents ? (
                    <Skeleton paragraph={10} />
                ) : (
                    <>
                        <div className="align-right">
                            <span className="admin-label">Weekends</span>
                            <AntButton type="link" onClick={() => setIsWeekendEdit(true)}>
                                Select all weekends
                            </AntButton>
                            <span style={{ fontSize: '20px', color: '#000' }}> | </span>
                            <AntButton type="link" onClick={removeWeekendEvents}>
                                Remove all weekend events
                            </AntButton>
                        </div>
                        <InfoMessage message={t('messages.event_calendar.info.holidays')} />
                        <InfoMessage message={t('messages.event_calendar.info.click')} />
                        <Calendar
                            className="calendar full-wrapper"
                            dateCellRender={dateCellRender}
                            onPanelChange={d => getEventsForTheMonthAndYear(d)}
                            value={selectedDefaultDate}
                        />
                    </>
                )}
            </div>
            {renderEditModal(!!selectedDate, addOrUpdateCalendarEvent, closeModal)}
            {renderEditModal(isWeekendEdit, addCalendarWeekendEvents, () => setIsWeekendEdit(false))}
            <Modal
                cancelButtonProps={{ style: { display: 'none' } }}
                className="my_profile_modal organisation-reauthorization-modal"
                visible={showDateDisabledPopup}
                onCancel={() => {
                    setShowDateDisabledPopup(false);
                    setIsAddingEditingDeletingWeekends(false);
                }}
                onOk={() => {
                    setShowDateDisabledPopup(false);
                    setIsAddingEditingDeletingWeekends(false);
                }}
            >
                <InfoMessage
                    message={t(
                        `messages.event_calendar.modal.${
                            isAddingEditingDeletingWeekends ? 'noWeekendEvents' : 'dateDisabled'
                        }.message`
                    )}
                />
            </Modal>
        </div>
    );
};

export default EventsCalendar;

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray, FormSection, change, getFormValues, untouch } from 'redux-form';
import { Divider } from 'antd';
import { find, get } from 'lodash';

import {
    COMMON_PAYMENT_SERVICE_FORM_FIELDS,
    INTERNATIONAL_TRANSACTION_CHARGE_FIELDS,
    PAYMENT_SERVICE_SLUG,
    renderPaymentServiceFormField,
} from 'components/PaymentGateways/constants';
import { ADDITIONAL_TRANSACTION_CHARGES, PAYMENT_SERVICE_FORM } from 'includes/constants';
import * as formValidations from 'includes/utils/form';
import FormField from 'components/shared/FormField';
import { getPaymentServiceGatewayProviders } from 'includes/slices/paymentGateways';
import Loader from 'components/Loader';
import CountrySelectFormField from '../CountrySelectFormField';
import i18n from 'includes/i18n';
import AdditionalChargesForCreditAndDebitCardsFormSection from 'components/PaymentGateways/components/AdditionalChargesForCreditAndDebitCardsFormSection';

/**
 * Defining it here to avoid cyclic dependency
 */
export const PAYFURL_CHARGES = [
    {
        name: 'service_charge',
        type: 'number',
        label: i18n.t('customerMessages.paymentServices.form.label.serviceCharge'),
        validate: [formValidations.required],
    },
    {
        name: 'gst',
        type: 'number',
        label: i18n.t('customerMessages.paymentServices.form.label.gst'),
        validate: [formValidations.required],
    },
    {
        name: 'transaction_charge',
        type: 'number',
        label: i18n.t('customerMessages.paymentServices.form.label.transactionCharge'),
        validate: [formValidations.required],
    },
    {
        name: 'transaction_charge_percentage',
        type: 'number',
        label: i18n.t('customerMessages.paymentServices.form.label.transactionChargePercentage'),
        validate: [formValidations.required],
    },
];

/**
 * payFURL form Fields
 *
 * @since 2.8.0
 */
export default function PayFurlFields({ isEdit }) {
    const previousAuthParameters = useRef([]);

    const formValues = useSelector(state => getFormValues(PAYMENT_SERVICE_FORM)(state));

    const dispatch = useDispatch();

    const paymentGateways = useSelector(state => state.paymentGateways.paymentGateways);

    const paymentServiceGatewayProviders = useSelector(state => state.paymentGateways.paymentServiceGatewayProviders);

    const isLoadingProviders = useSelector(state => state.paymentGateways.isLoadingProviders);

    /**
     * Find payFURL's ID and use it to fetch providers
     */
    useEffect(() => {
        const payfurlId = get(find(paymentGateways, { parent_slug: PAYMENT_SERVICE_SLUG.PayFurl }), 'id');

        if (!payfurlId) return;

        dispatch(getPaymentServiceGatewayProviders(payfurlId));
    }, [paymentGateways]);

    const selectedProviderId = formValues?.provider?.id;

    const selectedProvider = useMemo(() => {
        return find(paymentServiceGatewayProviders, { id: selectedProviderId }) ?? null;
    }, [selectedProviderId, paymentServiceGatewayProviders]);

    /**
     * Reset auth parameters and update previous auth parameters
     */
    useEffect(() => {
        // Reset previous auth parameters when the provider changes
        previousAuthParameters.current.forEach(field => {
            dispatch(change(PAYMENT_SERVICE_FORM, `provider.authentication_parameters.${field}`, null));
            dispatch(untouch(PAYMENT_SERVICE_FORM, `provider.authentication_parameters.${field}`));
        });

        // update previousAuthParameters variable
        if (selectedProvider) previousAuthParameters.current = Object.keys(selectedProvider?.authentication_parameters);
    }, [selectedProviderId]);

    return (
        <div>
            <Divider orientation="left">
                <span className="font-medium">General</span>
            </Divider>
            <div className="grid grid-cols-2 gap-3 text-left w-full">
                {COMMON_PAYMENT_SERVICE_FORM_FIELDS.map(renderPaymentServiceFormField)}
                <CountrySelectFormField disabled={isEdit} />
            </div>

            <Divider orientation="left">
                <span className="font-medium">Payment Gateway Details</span>
            </Divider>

            {isLoadingProviders ? (
                <Loader />
            ) : (
                <FormSection name="provider">
                    <div className="grid grid-cols-2 gap-3 text-left w-full mb-5">
                        <div>
                            <Field
                                name="id"
                                type="select"
                                label="Provider"
                                options={paymentServiceGatewayProviders
                                    .filter(provider => provider.is_active)
                                    .map(provider => ({
                                        name: provider.name,
                                        value: provider.id,
                                    }))}
                                showSearch
                                filterOption={(input, opt) =>
                                    opt.props.children.toLowerCase().includes(input.toLowerCase())
                                }
                                component={FormField}
                                validate={[formValidations.required]}
                            />
                        </div>
                        {selectedProvider ? (
                            <img
                                height={100}
                                width={100}
                                className="self-center justify-self-center"
                                src={selectedProvider?.logo}
                                alt={selectedProvider?.name}
                            />
                        ) : null}
                    </div>

                    {selectedProvider?.authentication_parameters ? (
                        <FormSection
                            className="col-span-2 grid grid-cols-2 gap-3 text-left w-full mb-5"
                            name="authentication_parameters"
                        >
                            {Object.entries(selectedProvider?.authentication_parameters).map(
                                ([name, { name: label }]) =>
                                    renderPaymentServiceFormField({
                                        name,
                                        label,
                                        type: 'text',
                                        validate: [formValidations.required],
                                    })
                            )}
                        </FormSection>
                    ) : null}
                </FormSection>
            )}
            {selectedProvider ? (
                <>
                    <Divider orientation="left">
                        <span className="font-medium">Charges</span>
                    </Divider>
                    <div className="grid grid-cols-2 gap-3 text-left w-full">
                        {[...PAYFURL_CHARGES, ...INTERNATIONAL_TRANSACTION_CHARGE_FIELDS].map(
                            renderPaymentServiceFormField
                        )}
                    </div>

                    <Divider orientation="left">
                        <span className="font-medium">Additional Charges</span>
                    </Divider>
                    <FieldArray
                        component={AdditionalChargesForCreditAndDebitCardsFormSection}
                        name={ADDITIONAL_TRANSACTION_CHARGES}
                    />
                </>
            ) : null}
        </div>
    );
}

PayFurlFields.propTypes = {
    isEdit: PropTypes.bool,
};
